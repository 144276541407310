import React, {Component} from "react";
import { t, Trans } from '@lingui/macro';
import { I18n } from "@lingui/react";
import { printConsole } from '../helpers';


class SidePanelVideoTile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vdoheight: props.vdoheight ? props.vdoheight : 0,
            vdowidth: props.vdowidth ? props.vdowidth : 0,
            participant: props.participant ? props.participant : {}
        }
        this.videoRef = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.vdoheight !== this.props.vdoheight) {
            this.setState({ vdoheight: nextProps.vdoheight }, () => {
                this.updateVideoRef();                
            })
        }
        if(nextProps.vdowidth !== this.props.vdowidth) {
            this.setState({ vdowidth: nextProps.vdowidth });
        }
        if(nextProps.participant !== this.props.participant) {
            this.setState({ participant: nextProps.participant }, () => {
                //console.log(this.state.participant.stream);
                if (this.state.participant.stream) {
                    //console.log(this.videoRef.current);
                    if(this.videoRef.current){
                        //console.log("set video Reference..");
                        this.videoRef.current.srcObject = this.props.participant.stream
                    }                 
                }
            });
        } 
    }

    componentDidUpdate(prevProps, prevState) {
        //console.log(this.props.changedParticipant, prevState.changedParticipant);
        //TP-3213
        if(this.props.changedParticipant !== prevProps.changedParticipant && this.props.changedParticipant !== null){
            //TP-3736
            printConsole("draw the UI again when one participant is changed");
            this.updateOneVideoRef(this.props.changedParticipant); //TP-3677
        }
        if(this.state.participant.stream.getVideoTracks().length !== prevState.participant.stream.getVideoTracks().length){
            printConsole("Video tracks have been added or removed in participants stream");
            this.updateVideoRef();
        }
        if(this.state.participant !== prevState.participant /* || this.state.participant.stream.getVideoTracks() !== prevState.participant.stream.getVideoTracks() */) {
            printConsole("Participant has changed")
            this.updateOneVideoRef(this.state.participant); //TP-3677
        }
        if(this.state.vdowidth !== prevState.vdowidth) {
            printConsole("Video width has been changed")
            this.updateOneVideoRef(this.state.participant); //TP-3677
        }
        const {videoConnection} = this.props;
        /* if (videoConnection && videoConnection.peerConnection && prevProps.videoConnection && prevProps.videoConnection.peerConnection
            && videoConnection.peerConnection.peerConnection && prevProps.videoConnection.peerConnection.peerConnection
            && videoConnection.peerConnection.peerConnection.iceConnectionState !== prevProps.videoConnection.peerConnection.peerConnection.iceConnectionState 
            && videoConnection.peerConnection.peerConnection.iceConnectionState === "connected")
        {
            printConsole("Video connection state is changed....");
            this.updateVideoRef()
        } */
    }

    //TP-3213
    updateOneVideoRef = (pp) => {
        //console.log(pp.stream);
        if (pp.email === this.state.participant.email && pp.streams) {
            console.log("Update one participant stream");
            this.updateTimer = setTimeout(() => {
                //console.log(this.videoRef.current);
                if(this.videoRef.current){
                    //console.log("set video Reference..");
                    this.videoRef.current.srcObject = pp.streams[0]
                }
                clearTimeout(this.updateTimer);
            }, 1000);
            if (this.state.participant.email === this.props.user.email) {
                console.log("update my own preview...");
                this.upTimer = setTimeout(() => {
                    //console.log(this.videoRef.current);
                    if(this.videoRef.current){
                        //console.log("set video Reference..");
                        this.videoRef.current.srcObject = this.state.participant.stream
                    }
                    clearTimeout(this.upTimer);
                }, 1000);
            }
        } else if (pp.email === this.state.participant.email && pp.stream) {
            console.log("Update one participant stream");
            this.updateTimer = setTimeout(() => {
                //console.log(this.videoRef.current);
                if(this.videoRef.current){
                    //console.log("set video Reference..");
                    this.videoRef.current.srcObject = pp.stream
                }
                clearTimeout(this.updateTimer);
            }, 1000);
            if (this.state.participant.email === this.props.user.email) {
                console.log("update my own preview...");
                this.upTimer = setTimeout(() => {
                    //console.log(this.videoRef.current);
                    if(this.videoRef.current){
                        //console.log("set video Reference..");
                        this.videoRef.current.srcObject = this.state.participant.stream
                    }
                    clearTimeout(this.upTimer);
                }, 1000);
            }
        }
    }

    updateVideoRef = () => {
        const {participant} = this.state;
        //console.log(participant.stream);
        if (participant.stream) {
            //console.log(this.videoRef.current);
            if(this.videoRef.current){
                //console.log("set video Reference..");
                this.videoRef.current.srcObject = participant.stream
            }                 
        }
    }

    //onclick method for technician Grid View Tiles
    onClickTechMaximise = ({email, type}) => {
        this.props.onClickMaximize(email, type);
    }

    render(){
        const {vdoheight, vdowidth, participant} = this.state;
        let cardClassName = "side-panel-card";
        let tileClassName = "fit-cover ";
        if (!participant.stream) {
            cardClassName = "side-panel-card-logo";
            tileClassName = "fit-contain ";
        }
        //TP-3213
        let hasVideoTracks = false;
        if (participant.stream && participant.stream.getVideoTracks().length > 0) {
            participant.stream.getVideoTracks().forEach(track => {
                if (track.enabled === true)
                hasVideoTracks = true;                
            });
            //TP-5442
            if (participant.isThisMe === true) {
                participant.stream.getVideoTracks().forEach(track => {
                    track.addEventListener(
                        "ended",
                        (event) => {
                          console.log("My video is ended");
                          this.props.triggerDeviceErrorAndEndSession("camera");
                        },
                        false,
                    );
                });
            }
        } else if (this.videoRef && this.videoRef.current !== null && this.videoRef.current.srcObject && this.videoRef.current.srcObject.getVideoTracks().length > 0) {
            this.videoRef.current.srcObject.getVideoTracks().forEach(track => {
                if (track.enabled === true)
                hasVideoTracks = true;
            });
        }
        //TP-5441
        if (participant.stream && participant.stream.getAudioTracks().length > 0 && participant.isThisMe === true) {
            participant.stream.getAudioTracks().forEach(track => {
                track.addEventListener(
                    "ended",
                    (event) => {
                      console.log("My audio is ended");
                      this.props.triggerDeviceErrorAndEndSession("microphone");
                    },
                    false,
                );
            })
        } 
        //console.log(participant.email, hasVideoTracks)
        const yScroll = 10;
        const newVdoHeight = vdoheight - yScroll;
        const newVdoWidth = Math.floor(newVdoHeight * (16/9));

        //TP-3213 -- New dimension & position of Profile picture in disabled state on Grid View tiles
        let profileHeight = Math.floor((newVdoHeight * 50)/100) + "px"; let profileWidth = profileHeight;   
        let profileLeft = Math.floor((newVdoHeight - Math.floor((newVdoHeight * 90)/100))/2) + "px";
        let profileTop = Math.floor((newVdoHeight - Math.floor((newVdoHeight * 50)/100))/2) + "px";

        return (
            <div className={hasVideoTracks === true ? cardClassName : cardClassName + " side-panel-card-shadow"} style={{ position: "relative", height: vdoheight, width: vdowidth, marginLeft: "15px"}}>
                <div className="position-absolute w-100 text-center nameDiv" style={{ color: 'white', zIndex:1, top: '10px' }}>
                    { /*TP-3213*/hasVideoTracks === true ?
                            /*TP-3497*/ (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                            <img className="session-profile-pic logo-profile-pic  " src={participant.myPicture} alt='profile_logo' />
                            :
                            <img className="session-profile-pic logo-profile-pic  " src={"/profile.png"} alt='profile_logo' />
                        :
                        null
                    }
                    { participant.isexpert ?                                        
                        <>
                            <i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;
                            {participant.fullName}
                        </>
                        :
                        participant.isThisMe === true ?
                            "You" : participant.fullName
                    }
                </div>
                <div /**TP-4266*/ onClick={() => this.onClickTechMaximise({email: participant.email, type: participant.type}) } className="paddingVdo" style={{cursor: 'pointer', height: '100%', width: newVdoWidth+"px" /**TP-4330*/}}>
                    {/**TP-3213*/ hasVideoTracks === true ?
                        /**TP-4483 */
                        participant.isPaused === true ?
                            <>
                                <img className={tileClassName+" session-profile-pic-position video-stream"} width={newVdoWidth} height={newVdoHeight} src={participant.poster} alt="Stream frame" />
                                {/**TP-4572 */}
                                <span className={this.props.isSmallFormFactorDevice === true ? 'paused-icon paused-icon-grid fa-stack fa-1x' : 'paused-icon paused-icon-grid fa-stack fa-2x'}>                                    
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fas fa-video-slash fa-stack-1x font-awesome-red"></i>
                                </span>
                                <video width={newVdoWidth} height={newVdoHeight} ref={this.videoRef} autoPlay id={participant.stream.id} /**TP-4319*/ className="d-none" muted={participant.isThisMe} />
                            </>
                        :
                        <video width={newVdoWidth} height={newVdoHeight} ref={this.videoRef} autoPlay id={participant.stream.id} /**TP-4319*/ poster={'/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png'}
                            className={tileClassName+"video-stream"} muted={participant.isThisMe} />
                        :
                        (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                            <> {/**TP-3701 */}
                                <img className={tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={participant.myPicture} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                <video width={newVdoWidth} height={newVdoHeight} ref={this.videoRef} autoPlay id={participant.stream.id} /**TP-4319*/ className="d-none" muted={participant.isThisMe} />
                            </>
                            :
                            <> {/**TP-3701 */}
                                <img className={tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={"/profile.png"} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                <video width={newVdoWidth} height={newVdoHeight} ref={this.videoRef} autoPlay id={participant.stream.id} /**TP-4319*/ className="d-none" muted={participant.isThisMe} />
                            </>
                                                    
                    }                                            
                </div>      
            </div>
        )
    }
}

export default SidePanelVideoTile;