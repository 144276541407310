/**TP-3328 */
import React from 'react';
import { printConsole, checkDummyEmail, encryptData, formatMobile } from '../helpers';
import AuthService from '../AuthService'; //TP-3672
import './Groups.scss';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import SimpleReactValidator from 'simple-react-validator';
import ToggleCheckBox from '../ToggleCheckBox/ToggleCheckBox';
import MyEditor from '../PhotoEditor/Editor.js';
import './MyProfile.scss';
import { debounce } from 'lodash';

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

function isFileImage(file) {
    const acceptedImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
 
    return file && acceptedImageTypes.includes(file['type'])
}

const fileDialog = require('file-dialog');

//TP-3821 -- Different Resolution array ranges for different kinds of cameras
const ResolutionArrFor480p = [
    {width: {exact: 120}, height: {exact: 160}},
    {width: {exact: 320}, height: {exact: 240}},
    {width: {exact: 640}, height: {exact: 480}}
];

const ResolutionArrFor720p = [
    {width: {exact: 320}, height: {exact: 240}},
    {width: {exact: 640}, height: {exact: 480}},
    {width: {exact: 1280}, height: {exact: 720}}
];

const ResolutionArrFor1080p = [
    {width: {exact: 640}, height: {exact: 480}},
    {width: {exact: 1280}, height: {exact: 720}},
    {width: {exact: 1920}, height: {exact: 1080}}
];

const ResolutionArrFor4K = [
    {width: {exact: 1280}, height: {exact: 720}},
    {width: {exact: 1920}, height: {exact: 1080}},
    {width: {exact: 3840}, height: {exact: 2160}}
];

const ResolutionArrForMoreThan4K = [
    {width: {exact: 1280}, height: {exact: 720}},
    {width: {exact: 1920}, height: {exact: 1080}},
    {width: {exact: 3840}, height: {exact: 2160}},
    {width: {exact: 4096}, height: {exact: 2160}}
];

const ResolutionArrFor8K = [
    {width: {exact: 1280}, height: {exact: 720}},
    {width: {exact: 1920}, height: {exact: 1080}},
    {width: {exact: 3840}, height: {exact: 2160}},
    {width: {exact: 4096}, height: {exact: 2160}},
    {width: {exact: 7680}, height: {exact: 4320}}
];

//TP-3821
const ResolutionsToCheck = [
    {width: {exact: 120}, height: {exact: 160}},
    {width: {exact: 320}, height: {exact: 240}},
    {width: {exact: 640}, height: {exact: 480}},
    {width: {exact: 1280}, height: {exact: 720}},
    {width: {exact: 1920}, height: {exact: 1080}},
    {width: {exact: 3840}, height: {exact: 2160}},
    {width: {exact: 4096}, height: {exact: 2160}},
    {width: {exact: 7680}, height: {exact: 4320}}
];

var mid = ResolutionsToCheck.length-1;
//TP-3821
const streamoptionsmapping = [
    {value: "low", label: "Low", width: 320, height: 240},
    {value: "medium", label: "Medium", width: 640, height: 480},
    {value: "high", label: "High", width: 1280, height: 720}];

//TP-3794
const optionsmapping = [
    {value: "low", label: "Low"},
    {value: "medium", label: "Medium"},
    {value: "high", label: "High"}];

let sendSocketFlag = false;

class MyProfile extends React.Component{
    constructor(props){
        super(props);
        this.authService = new AuthService(); //TP-3672
        this.myStream = null; //TP-5500
        this.isMount = false; //TP-5500
        this.state = {
            showEditor: false,
            saveImage: false, //TP-3199
            changedImage: false,
            disableSavePhotoButton: true, //TP-3199
            disableUpdateButton: true, //TP-3328
            isPhotoSubmitLoading: false, //TP-3199
            imageUneditted: (props.myPicture !== undefined && props.myPicture !== null) ? props.myPicture : "/profile.png", //TP-3199
            imageFormat: "image/jpg",
            imageScaleValue: 1.2,
            optionsmap: streamoptionsmapping, //TP-3821 TP-3794
            isLoading: false,
            id: props.id,
            first_name: props.first_name,
            last_name: props.last_name,
            email: props.email,
            phonenumber: props.phonenumber,
            verified: props.verified,
            optional_email: "",
            showEmailVerifyLink: false, emailOtp: new Array(7).join(" "), emailVerified: false, emailOtpSent: false, wrongEmailOtp: false, disableEmailVerify: false, disableMobileVerify: false,
            showMobileVerifyLink: true, showMobileGenerateOTP: false, mobileOtp: new Array(7).join(" "), mobileVerified: false,  mobileOtpSent: false, wrongMobileOtp: false,
            emailOTPLimitReached: false, mobileOTPLimitReached: false, expiredEmailOTP: false, expiredMobileOTP: false, mobileCheckOngoing: false,
			password: '',   repeatPassword:'',
            myPicture: props.myPicture ? props.myPicture : "/profile.png",
            camera_on: props.camera_on ? props.camera_on : false, //TP-3794
            disable_notifications: props.disable_notifications ? props.disable_notifications : false, //TP-3794
            auto_connect: props.auto_connect ? props.auto_connect : false, //TP-3794
            streaming_quality: props.streaming_quality ? streamoptionsmapping.filter(o => o.value === props.streaming_quality)[0] : {value: "medium", label: "Medium"}, //TP-3821 TP-3794
            image_quality: props.image_quality ? optionsmapping.filter(o => o.value === props.image_quality)[0] : {value: "medium", label: "Medium"}, //TP-3794
            enable_auto_connect: props.enable_auto_connect,
            enable_user_video: props.enable_user_video,
            enable_streaming: props.enable_streaming,
            isDeleteEnabled: false, //TP-3506
            bodyMarginTop: 10, //TP-4067
            isLoading: false,
            maxResWidth: {exact: 1280}, maxResHeight: {exact: 720}, //TP-3821
            hideStreamingQuality: false, //TP-4664
            isEmailAlreadyRegistered: false, isMobileAlreadyRegistered: false,
            isIST: false //TP-6947
        }
        // initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: props.language,
            element: (message, className= "add-users-validation-msg") => <div className={className}>{message.replace(/field|The/g, '').replace(/otp/g, 'OTP').capitalize()}{/*TP-6271,6385,6359*/}</div>,
            validators: {
                emailValidate: {  // name the rule
                    message: 'Please enter a valid email address',
                    rule: (val, params, validator) => {
                        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return validator.helpers.testRegex(
                            String(val).toLowerCase(),
                            regex
                        );
                    },
                },
                phone: {
                    message: 'Enter a valid phone number',
                    rule: (val,params,validator) => {
                        // let telRegex= /^(?=.*[0-9])[- +#()0-9a-zA-Z*]+$/;
                        // let telRegex= /^[0-9\)\(+-\s]+$/g;//T32-472
                        let telRegex= /^\d+$/;//T32-472,TP-6152
                        return (validator.helpers.testRegex(val,telRegex));
                    }
                },
                confirmPassword: {  // name the rule
                    message: 'Password and Confirm Password do not match.',
                    rule: (val, params, validator) => {
                        return val === this.state.password ? true : false; 
                    },
                    required: true  // optional
                },
                min_length: {  // name the rule
                    rule: (val, params, validator) => {
                        return val.trim().length === 10; 
                    },
                },
                otp_length: {
                    rule: (val,params,validator) => {
                        return (val.replace(" ", "").length === 6);//first replace the empty space with empty string
                    }
                }
            }
		});
        //TP-4677 -- Re-initialize the "mid" value everytime this compenent gets mounted
        mid = ResolutionsToCheck.length-1;
    }

    componentWillReceiveProps (nextProps) {
        if(nextProps.id !== this.props.id) {
            this.setState({
                id: nextProps.id
            })
        }
        if(nextProps.first_name !== this.props.first_name) {
            this.setState({
                first_name: nextProps.first_name
            });
        }
        if(nextProps.last_name !== this.props.last_name) {
            this.setState({
                last_name: nextProps.last_name
            });
        }
        if(nextProps.email !== this.props.email) {
            this.setState({
                email: nextProps.email
            });
        }
        if(nextProps.phonenumber !== this.props.phonenumber) {
            this.setState({
                phonenumber: nextProps.phonenumber
            });
        }
        if(nextProps.verified !== this.props.verified) {
            this.setState({
                verified: nextProps.verified
            });
        }
        if(nextProps.myPicture !== this.props.myPicture) {
            this.setState({ 
                myPicture: nextProps.myPicture
            }, () => {
                //console.log(this.state.myPicture);
                /* if (this.state.myPicture !== "/profile.png")
                    this.updateSaveImageButton(); */
                this.updateMyProfilePicLoad(this.state.myPicture); //TP-3871 & TP-3794
            })
        }
        if(nextProps.enable_auto_connect !== this.props.enable_auto_connect) {
            this.setState({
                enable_auto_connect: nextProps.enable_auto_connect
            })
        }
        if(nextProps.enable_user_video !== this.props.enable_user_video) {
            this.setState({
                enable_user_video: nextProps.enable_user_video
            }, () => {
                if ((this.props.isexpert && (this.state.enable_user_video === "my_camera_enabled" || this.state.enable_user_video === "my_camera_enabled_with_prefs")) || (!this.props.isexpert && !this.props.isadmin)) {
                    //this.initGetMedia(); //TP-5500 TP-4665 TP-3821
                    //TP-5457 -- Calling the getUserMedia API again after an interval
                    this.mediaInterval = setTimeout(() => {
                        //TP-5500
                        if (this.isMount === true)
                            this.initGetMedia();
                        clearTimeout(this.mediaInterval)
                    }, 3000);
                }
            })
        }
        if(nextProps.enable_streaming !== this.props.enable_streaming) {
            this.setState({
                enable_streaming: nextProps.enable_streaming
            })
        }
        //TP-3871
        if(nextProps.streaming_quality !== this.props.streaming_quality) {
            /**TP-3821 */
            this.setState({
                streaming_quality: this.state.optionsmap.filter(o => o.value === nextProps.streaming_quality)[0]
            })
        }
        //TP-3871
        if(nextProps.image_quality !== this.props.image_quality) {
            this.setState({
                image_quality: optionsmapping.filter(o => o.value === nextProps.image_quality)[0]
            })
        }
        //TP-3916
        if(nextProps.camera_on !== this.props.camera_on) {
            this.setState({
                camera_on: nextProps.camera_on
            })
        }
        //TP-3916
        if(nextProps.auto_connect !== this.props.auto_connect) {
            this.setState({
                auto_connect: nextProps.auto_connect
            })
        }
    }

    //TP-3821
    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    initGetMedia = () => {
        let deviceId = "";
        var constraints = {
            "video": ResolutionsToCheck[mid]
        }
        /*TP-4660 if (this.getCookie("camera") !== "") {
            deviceId = this.getCookie("camera");
            constraints = {
                "video": {
                    width : ResolutionsToCheck[mid].width,
                    height : ResolutionsToCheck[mid].height,
                    deviceId: {exact: deviceId}
                }
                    
            }
        } */
        printConsole(`Constraints ------> ${JSON.stringify(constraints)}`);
        this.getMedia(constraints); 
    } 
    
    getMedia = (constraints)=> {
        navigator.mediaDevices.getUserMedia(constraints)
            .then(this.checkSuccess)
            .catch(this.checkError);
    }
    
    //TP-6947
    trackTimeZone = () => {
        try{
            //Set the timezone
            let timeZone = sessionStorage.getItem("timeZone");//check from session. If not there then save it
            if (!timeZone || timeZone !== Intl.DateTimeFormat().resolvedOptions().timeZone){
                timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                sessionStorage.setItem("timeZone", timeZone);
            }
            printConsole(`timezone for my profile- ${timeZone}`)
            if(timeZone === "Asia/Calcutta"){
                this.setState({isIST: true})// use this to hide the verify button as well as disable mobile number field
            }
        }catch(error){
            printConsole(`error in trackTimeZone for my profile ${error}`)
        }
    }

    checkSuccess = (stream) => {
        //TP-5500 -- Only process the stream if the current component is Mounted
        if (this.isMount) {
            printConsole(`Success for --> ${mid} ${JSON.stringify(ResolutionsToCheck[mid])}`);
            this.myStream = stream; //TP-5500
            this.setState({
                hideStreamingQuality: false, //TP-4664
                maxResWidth: ResolutionsToCheck[mid].width,
                maxResHeight: ResolutionsToCheck[mid].height
            }, () => {
                printConsole(this.state.maxResWidth);
                printConsole(this.state.maxResHeight);
                mid = ResolutionsToCheck.length-1;
    
                for (let track of stream.getTracks()) {
                    track.stop()
                }
                this.myStream = null; //TP-5500
    
                this.setStreamingQltyOptions();
            });
        } else {
            //Otherwise clear out all the tracks in the stream
            printConsole("Component has already unmounted so stopping all open tracks");
            if (stream) stream.getTracks().forEach(track => track.stop());
        }
    }

    checkError = (error) => {
        printConsole(`Failed for --> ${mid} ${JSON.stringify(ResolutionsToCheck[mid])}, " ", ${error}`);
        mid = mid - 1;
        if (mid > 0) this.initGetMedia();
        else {
            printConsole(error.name);
            //TP-4664
            if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError" /**TP-5431*/ || error.name === "NotAllowedError" || error.name === "PermissionDeniedError" || error.name === "NotReadableError" || error.name === "AbortError" || error.name === "TrackStartError" /**TP-5455*/) {
                this.setState({hideStreamingQuality: true}, () => {
                    //TP-5429 -- Whenever camera detection fails set the resolution as 0,0
                    this.setState({
                        maxResWidth: {exact: 0},
                        maxResHeight: {exact: 0}
                    }, () => {
                        printConsole(this.state.maxResWidth);
                        printConsole(this.state.maxResHeight);
                        mid = 0;       
                        this.props.setCameraResolution(0, 0);                        
                    });
                })
            } else {
                this.setState({hideStreamingQuality: false})
                this.setState({
                    maxResWidth: {exact: 1280},
                    maxResHeight: {exact: 720}
                }, () => {
                    printConsole(this.state.maxResWidth);
                    printConsole(this.state.maxResHeight);
                    mid = ResolutionsToCheck.length-1;        
                    this.setStreamingQltyOptions();
                });
                
            }
        }            
    }

    //TP-3821
    setStreamingQltyOptions = () => {
        const {maxResHeight, maxResWidth} = this.state;
        //let maxResHeight = {exact: 2160}, maxResWidth = {exact: 3840};
        let maxIndex = ResolutionsToCheck.length-1;
        if (maxResHeight.exact <= 480)
            maxIndex = ResolutionArrFor480p.findIndex(rr => rr.height.exact === 480);
        if (maxResHeight.exact === 720)
            maxIndex = ResolutionArrFor720p.findIndex(rr => rr.height.exact === 720);
        else if (maxResHeight.exact === 1080) 
            maxIndex = ResolutionArrFor1080p.findIndex(rr => rr.height.exact === 1080);
        else if (maxResWidth.exact === 3840)
            maxIndex = ResolutionArrFor4K.findIndex(rr => rr.width.exact === 3840);
        else if (maxResWidth.exact === 4096)
            maxIndex = ResolutionArrForMoreThan4K.findIndex(rr => rr.width.exact === 4096);
        else if (maxResWidth.exact === 7680)
            maxIndex = ResolutionArrFor8K.findIndex(rr => rr.width.exact === 7680);

        //maxIndex = ResolutionsToCheck.findIndex(rr => rr.height.exact === maxResHeight.exact);
        let minIndex = 0;
        const optionmappp = []
        const medium = Math.ceil(((maxIndex + 1) / 2) - 1) ;
        printConsole(`Medium index ${medium}`);

        function getOptions(rr, ii) {
            if (ii === minIndex){
                const optn = {value: "low", label: "Low Resolution: "+rr.height.exact+"p", width: rr.width.exact, height: rr.height.exact};
                optionmappp.push(optn);
            } else if (ii === medium) {
                let optn = {};
                if (rr.width.exact === 3840 || rr.width.exact === 4096)
                    optn = {value: "medium", label: "Medium Resolution: 4K", width: rr.width.exact, height: rr.height.exact};
                else if (rr.width.exact === 7680)
                    optn = {value: "medium", label: "Medium Resolution: 8K", width: rr.width.exact, height: rr.height.exact};
                else
                    optn = {value: "medium", label: "Medium Resolution: "+rr.height.exact+"p", width: rr.width.exact, height: rr.height.exact};
                optionmappp.push(optn);
            } else if (ii == maxIndex) {
                let optn = {};
                if (rr.width.exact === 3840 || rr.width.exact === 4096)
                    optn = {value: "high", label: "High Resolution: 4K", width: rr.width.exact, height: rr.height.exact};
                else if (rr.width.exact === 7680)
                    optn = {value: "high", label: "High Resolution: 8K", width: rr.width.exact, height: rr.height.exact};
                else
                    optn = {value: "high", label: "High Resolution: "+rr.height.exact+"p", width: rr.width.exact, height: rr.height.exact};
                optionmappp.push(optn);
            }
        }

        if (maxResHeight.exact <= 480)
            ResolutionArrFor480p.forEach((rr, ii) => getOptions(rr,ii));
        else if (maxResHeight.exact === 720)
            ResolutionArrFor720p.forEach((rr, ii) => getOptions(rr,ii));
        else if (maxResHeight.exact === 1080) 
            ResolutionArrFor1080p.forEach((rr, ii) => getOptions(rr,ii));
        else if (maxResWidth.exact === 3840)
            ResolutionArrFor4K.forEach((rr, ii) => getOptions(rr,ii));
        else if (maxResWidth.exact === 4096)
            ResolutionArrForMoreThan4K.forEach((rr, ii) => getOptions(rr,ii));
        else if (maxResWidth.exact === 7680)
            ResolutionArrFor8K.forEach((rr, ii) => getOptions(rr,ii));
        else
            ResolutionsToCheck.forEach((rr, ii) => getOptions(rr,ii))
        this.setState({optionsmap: optionmappp}, () => {
            this.props.setStreamingOptions(optionmappp);
            for(let y=0; y<this.state.optionsmap.length; y++){
                if (this.state.streaming_quality.value === this.state.optionsmap[y].value) {
                    this.setState({streaming_quality: this.state.optionsmap[y]});
                    break;
                }
            }
        });
    }

    getUserProfileSettings = () => {
		if(this.props.id){
            this.setState({ isLoading: true });
			let fetchString = 'user/getProfileData/';
            fetchString += this.props.id; //by account id
            fetchString += "/true"; //to fetch the image as well

            //TP-3672
            this.authService.fetch(fetchString, {
                method: 'get'
			})
			.then(response => response.json())
			.then(data => {
			 	if(data !== undefined && data !== null){
                    const [current_streaming] = this.state.optionsmap.filter(op => op.value === data.streaming_quality) //TP-4672
                    this.setState({
                        id: data.user_id,
                        camera_on: data.my_camera_on,
                        streaming_quality: current_streaming,
                        image_quality: {value: data.image_quality, label: data.image_quality.charAt(0).toUpperCase() + data.image_quality.slice(1)},
                        auto_connect: data.allow_auto_connect,
                        disable_notifications: data.disable_notifications,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        isLoading: false                        
                    }, () => {
                        this.props.loadUserProfile(data); //TP-3328
                        //TP-3506 -- Handled the "" for image when deleted
                        if (data.my_picture !== undefined && data.my_picture !== "" && data.my_picture !== null) {
                            this.updateImageUrl(data.my_picture);                            
                            this.setState({isDeleteEnabled: true})
                        } else if (data.my_picture === "" || data.my_picture === null) {
                            this.setState({isDeleteEnabled: false})
                        }
                        if (this.state.maxResWidth && this.state.maxResWidth.exact !== 0 && this.state.maxResHeight && this.state.maxResHeight.exact !== 0) {
                            this.state.optionsmap.forEach(op => {
                                //TP-5431 -- Handling for the camera not detected use cases
                                if(op.value === data.streaming_quality){
                                  this.props.setCameraResolution(op.width, op.height);
                                }
                            })
                        }
                        //this.initGetMedia();//TP-3821-- Call this again to fetch the latest streaming_quality options

                    });
			 	}
			})
			.catch(err => {
                this.setState({ isLoading: false });
                printConsole(err.message)
            })
		}
	}

    componentDidMount = () => {
        this.isMount = true; //TP-5500
        // TP-3199 -- to be removed later when the API integration for fetch Profile picture is to be done
        //this.getUserProfileSettings(); Commented out for -- TP-3794
        if (!this.mediaInterval && ((this.props.isexpert && (this.state.enable_user_video === "my_camera_enabled" || this.state.enable_user_video === "my_camera_enabled_with_prefs")) || (!this.props.isexpert && !this.props.isadmin))) {
            //TP-5457 -- Calling the getUserMedia API again after an interval
            this.mediaInterval = setTimeout(() => {
                //TP-5500
                this.initGetMedia();
                clearTimeout(this.mediaInterval)
            }, 3000);
        }
        //this.initGetMedia(); //TP-4665 TP-3821
        if (this.state.myPicture !== "/profile.png")
            this.updateMyProfilePicLoad(this.state.myPicture); //TP-3794
        document.addEventListener('mousedown', this.handleClickProfile, false); //TP-3506 -- for closing the dropdown when clicked on image preview
        //TP-4067
        this.setState({isLoading: true});
        this.sizingTimer = setTimeout(() => {
            this.resizeProfileBody();
            clearTimeout(this.sizingTimer);
            this.setState({isLoading: false});
        }, 2000);

        this.delayedCallback = debounce(this.checkEmailExists, 500);//TP-6082
        this.delayedMobileCallback = debounce(this.checkMobileExists, 500);//TP-6082
        this.trackTimeZone();//TP-6947
    }

    componentDidUpdate = (prevProps, prevState) => {
        /**TP-3950 */
        if(prevState.bSubmitted !== this.state.bSubmitted && this.state.bSubmitted) {
            this.triggerMessageTimer();
        } else if (prevState.bResponded !== this.state.bResponded && this.state.bResponded) {
            this.triggerMessageTimer();
        }
        if(prevProps.triggerResizeMyProfile !== this.props.triggerResizeMyProfile && this.props.triggerResizeMyProfile === true) {
            this.resizeProfileBody();//TP-4067
        }
    }

    //TP-6082
    checkEmailExists = (emailField) => {
        this.setState({ emailOtpSent: false, emailVerified: false, wrongEmailOtp: false, showEmailVerifyLink: false, emailOtp: new Array(7).join(" "), emailOTPLimitReached: false, expiredEmailOTP: false })
        let {email, optional_email, disableUpdateButton} = this.state;// since delay is there api call happening after state change so button showing even if blank email
        //TP-6152
        if(optional_email){
            email = optional_email //when email is optional
        }
        if (!this.validator.fieldValid(emailField) || !email)  {
			this.validator.showMessages();
			this.setState({ isEmailAlreadyRegistered: false });
			return
		};
        fetch(window._env_.REACT_APP_API_URL+'/checkEmailExists/' + email + '?type=standard', {
			method: 'GET',
			headers: {'Content-Type': 'application/json'}
		})
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
            let showEmailVerifyLink = false;
            if(!isExists && (this.props.email !== email)){
                showEmailVerifyLink= true;//only when new email and it doesn't exist
            }
            if(this.props.email === email){
                disableUpdateButton = false;//enable update button only when email is retyped
            }
			this.setState({
				isEmailAlreadyRegistered: isExists && (this.props.email !== email),
                showEmailVerifyLink, emailOtp: new Array(7).join(" "), disableUpdateButton
			});
		})
		.catch((error) => {
			printConsole(`checkEmailExists error ${error}`);
		})
    }

    //TP-6082
    checkMobileExists = (mobile,mobileField) => {
        this.setState({ mobileOtpSent: false, mobileVerified: false, wrongMobileOtp: false, showMobileGenerateOTP: false, mobileOtp: new Array(7).join(" "),
            mobileOTPLimitReached: false, expiredMobileOTP: false })
        let {disableUpdateButton} = this.state;
		if (!this.validator.fieldValid(mobileField)){
			this.validator.showMessages();
			this.setState({ isMobileAlreadyRegistered: false, mobileCheckOngoing: false });//TP-6421
			return
		};
        if(!mobile || mobile.length < 10){
            this.setState({mobileCheckOngoing: false})//TP-6421
            return;//TP-6152
        }
        fetch(window._env_.REACT_APP_API_URL+'/account_user/canRequestOTP/', {
			method: 'POST',
            body: JSON.stringify({
                mobile, type: "verify_mobile"
            }),
			headers: {'Content-Type': 'application/json'}
		})
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
            let showMobileGenerateOTP = false;//TP-6151
            if(!isExists && (this.props.phonenumber !== mobile)){
                showMobileGenerateOTP= true//only when new email and it doesn't exist
            }
            if(this.props.phonenumber === mobile){
                disableUpdateButton = false;//enable update button only when same mobile retyped
            }
			this.setState({
				isMobileAlreadyRegistered: isExists && (this.props.phonenumber !== mobile),
                showMobileGenerateOTP, mobileOtp: new Array(7).join(" "), disableUpdateButton
			});
		})
		.catch((error) => {
			printConsole(`checkMobileExists error ${error}`);
		})
        .finally(() => this.setState({mobileCheckOngoing: false}))//TP-6421
    }

    //TP-6082
    onSubmitGetVerifyOtp = (type, field) => {
        //check only mobile validation
        if (!this.validator.fieldValid(field)) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
		}
        let {email, optional_email, phonenumber, emailOtpSent, mobileOtpSent, wrongEmailOtp, wrongMobileOtp, emailOtp, mobileOtp, emailOTPLimitReached,
            mobileOTPLimitReached, expiredEmailOTP, expiredMobileOTP
        } = this.state;
        if(optional_email){
            email = optional_email //when email is optional
        }
        this.setState({ isLoading: true });
        //use it for email and mobile
        this.authService.fetch(`user/sendVerifyOtp`, {
            method: 'post',
            body: JSON.stringify({
                old_email: this.props.email, email, mobile: phonenumber, type
            })
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
        .then((data) => {
            if(type === "verify_email"){
                emailOtpSent = true;
                wrongEmailOtp = false;//TP-6147 for use case when otp entered wrong and generate otp clicked
                emailOtp = new Array(7).join(" ");
                emailOTPLimitReached = false;//TP-6272
                expiredEmailOTP = false; //TP-6272
            }else if(type === "verify_mobile"){
                mobileOtpSent = true;
                wrongMobileOtp = false;//TP-6147 for use case when otp entered wrong and generate otp clicked
                mobileOtp = new Array(7).join(" ");
                mobileOTPLimitReached = false;//TP-6272
                expiredMobileOTP = false;//TP-6272
            }
            if(data){
                this.setState(() => ({ 
                    emailOtp, mobileOtp, emailOtpSent, mobileOtpSent, wrongEmailOtp, wrongMobileOtp, isLoading: false, disableUpdateButton: true, //disable when existing mobile/email need to be verified
                    emailOTPLimitReached, mobileOTPLimitReached, expiredEmailOTP, expiredMobileOTP
                }));
            }
        })
        .catch ((error) => {
            printConsole(`error in onSubmitGetVerifyOtp ${error}`);
            //TP-6272
            if(error.status === 425){
                if(type === "verify_email"){
                    emailOTPLimitReached = true;
                    emailOtp = new Array(7).join(" ");//TP-6440
                }else{
                    mobileOTPLimitReached = true;
                    mobileOtp = new Array(7).join(" ");//TP-6440
                }
            }
            this.setState({ 
                bSubmitted: false, bResponded: false, showExpertWarning: true, isLoading: false, emailOtpSent, mobileOtpSent, emailOTPLimitReached,
                mobileOTPLimitReached, emailOtp, mobileOtp
            });
        })
    }

    //TP-6082
    onSubmitVerify = (type, field) => {
        //check only mobile validation
        if (!this.validator.fieldValid(field)) {
            this.validator.showMessages();
            this.forceUpdate();
            return;
        }
        let {verified, emailVerified, mobileVerified, emailOtp, mobileOtp, showEmailVerifyLink, showMobileGenerateOTP, emailOTPLimitReached,
            emailOtpSent, mobileOtpSent, wrongEmailOtp, wrongMobileOtp, mobileOTPLimitReached, expiredEmailOTP, expiredMobileOTP} = this.state;
        this.setState({ isLoading: true, disableEmailVerify: true, disableMobileVerify: true });//disable while submittting otp
        //use it for email and mobile
        this.authService.fetch(`user/verifyOtp`, {
            method: 'post',
            body: JSON.stringify({
                email: this.props.email, type, otp: type === "verify_email" ? parseInt(emailOtp) : parseInt(mobileOtp)
            })
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
        .then((data) => {
            if(type === "verify_email"){
                if(verified === "mobile"){
                    verified = "both"//mark it as both if only mobile is verified
                }
                emailVerified = true; emailOtp = new Array(7).join(" "); showEmailVerifyLink = false; emailOtpSent= false; wrongEmailOtp= false; emailOTPLimitReached = false;
                expiredEmailOTP = false;
            }else{
                if(verified === "email"){
                    verified = "both"//mark it as both if only email is verified
                }
                mobileVerified = true; mobileOtp = new Array(7).join(" "); showMobileGenerateOTP = false; mobileOtpSent= false; wrongMobileOtp= false; mobileOTPLimitReached = false;
                expiredMobileOTP = false;
            }
            if(data){
                this.setState(() => ({ 
                    emailVerified, mobileVerified, verified, emailOtp, mobileOtp, showEmailVerifyLink, showMobileGenerateOTP, emailOtpSent, mobileOtpSent,
                    isLoading: false, wrongEmailOtp, wrongMobileOtp, disableEmailVerify: false, disableMobileVerify: false, disableUpdateButton: false, //enable the button which was disabled
                    emailOTPLimitReached, mobileOTPLimitReached, expiredEmailOTP, expiredMobileOTP
                }), () => {
                    if(type === "verify_email" && emailVerified && (this.props.verified === "none" || this.props.verified === "mobile")){
                        this.resizeProfileBody();//TP-6284
                    }
                });
            }
        })
        .catch ((error) => {
            if(type === "verify_email"){
                emailOtpSent= false; wrongEmailOtp= true; emailOTPLimitReached = false; expiredEmailOTP = false;
                if(error.status === 403){
                    expiredEmailOTP = true
                    wrongEmailOtp= false
                }
            }else{
                mobileOtpSent= false; wrongMobileOtp= true; mobileOTPLimitReached = false; expiredMobileOTP = false
                if(error.status === 403){
                    expiredMobileOTP = true;
                    wrongMobileOtp = false;
                }
            }

            printConsole(`error in onSubmitVerify ${error}`);
            this.setState({ 
                bSubmitted: false, bResponded: false, showExpertWarning: true, isLoading: false, emailOtpSent, wrongEmailOtp, mobileOtpSent, wrongMobileOtp,
                disableEmailVerify: false, disableMobileVerify: false, emailOTPLimitReached, mobileOTPLimitReached, expiredEmailOTP, expiredMobileOTP
            });
        })
    }

    onSubmitSignout = () => {
        const myLocation = "Bangalore North++East";
        const encryptedEmail = encryptData(this.props.email);//FQ3-417
        this.authService.fetch('signout', { //TP-2028
          method: 'post',
          /* TP-2028 headers: {'Content-Type': 'application/json'}, */
          body: JSON.stringify({
            email: encryptedEmail,
            isencrypted: true,
            id: parseInt(this.props.id, 10),
            groupid: 0,
            geoLocation: myLocation
          })
        })
        .then(response => response.json())
        .then(data => {
            this.authService.logout();
        })
        .catch(err => printConsole(`error in onSubmitSignout ${err}`))
    }
    
    showLoader = () => {
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader">
                    <Loader
                        type="ThreeDots"
                        color="#23c97d"
                        height="70"	
                        width="70"
                    />
                </div>
            )
        }
        return '';
    }

    componentWillUnmount = () => {
        //TP-5500
        this.isMount = false;
        printConsole("My Profile will unmount");
        if (this.myStream !== null ) this.myStream.getTracks().forEach(track => track.stop());
        document.removeEventListener('mousedown', this.handleClickProfile, false); //TP-3506 -- for closing the dropdown when clicked on image preview
    }

    //TP-3950 -- timer to close the update API success or failure message
    triggerMessageTimer = () => {
        this.messageTimer = setTimeout(() => {
            if(this.state.retCode === 200)
                this.onCloseReturnSuccess();
            else
                this.onCloseReturnFailure();
            clearTimeout(this.messageTimer);
        }, 3000);//changed to 3 seconds
    }

    onChangeStreaminQuality = (newVal) => {
        //TP-4672 -- Only allow this dropdown state value to be changed when the newvalue is not equal to the previous value
        if (newVal.value !== this.state.streaming_quality.value) {
            //TP-3328
            this.setState({streaming_quality: newVal}, () => {
                if(this.state.disableUpdateButton === true)
                    this.setState({ disableUpdateButton: false });
            });
        }
    }

    onChangeImageQuality = (newVal) => {
        //TP-3328
        this.setState({image_quality: newVal}, () => {
            if(this.state.disableUpdateButton === true) 
                this.setState({ disableUpdateButton: false });
        });
    }

    onChangeForm = (name, i18n) => event => {
        //console.log(name,event);
		event.persist();
        let {verified, phonenumber, mobileCheckOngoing} = this.state, {sms_support} = this.props, disableUpdateButton = false;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        if (name === "email" || name === "optional_email" ){
            if(i18n){
                this.delayedCallback(i18n._(t`user_email`));//TP-6082
            }
            if(!value && verified === "both"){
                verified = "mobile"
            }else{
                verified = this.props.verified
            }
            disableUpdateButton = sms_support && !value ? false : true;//TP-6304 disable the button
        }
        if (name === "phonenumber") {
            phonenumber = formatMobile(value);//TP-6151
            if(i18n){
                this.delayedMobileCallback(phonenumber,i18n._(t`mobile`));//TP-6082,6278
            }
            if(!phonenumber && verified === "both"){
                verified = "email"
            }else{
                verified = this.props.verified
            }
            disableUpdateButton = !sms_support && !value ? false : true;//TP-6304 disable the button
            mobileCheckOngoing = true;//TP-6421
        }
		const stateChanges = {
            [name]: value,
            verified, phonenumber, mobileCheckOngoing,
            disableUpdateButton
		};
		this.setState(stateChanges,() => {
            //TP-6274
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                this.forceUpdate();
            }
        });
	}

    //TP-6213
    handleOTPChange = (e, elementId, index, type, i18n) => {
        let {emailOtp, mobileOtp} = this.state;
        let otp = type === "verify_email" ? emailOtp : mobileOtp
        //focus only when next element is there and value is not empty
        if(elementId && e.target.value.length){
            document.getElementById(elementId).focus();
        }

        const splitOTP = otp.split("");//split and replace index wise, required for deleting existing single character
        splitOTP[index] = e.target.value? e.target.value.trim().slice(-1) : " ";//take last char using slice and replace empty string with blank character
        otp = splitOTP.join("");//join and assign
        printConsole(`otp- ${otp}`);
        if(otp.trim() === ""){
            otp = new Array(7).join(" ");//TP-6359
        }
        emailOtp = type === "verify_email" ? otp : emailOtp
        mobileOtp = type === "verify_mobile" ? otp : mobileOtp

        this.setState({emailOtp, mobileOtp}, () => {
            if(type === "verify_email"){
                const field = i18n._(t`otp`)
                if(this.validator.fieldValid(field)){
                    this.onSubmitVerify(type, field)
                }
            }
            if(type === "verify_mobile"){
                const field = i18n._(t`mobileOtp`)
                if(this.validator.fieldValid(field)){
                    this.onSubmitVerify(type, field)
                }
            }
            //TP-6274
            if (!this.validator.allValid()) {
                this.validator.showMessages();
                this.forceUpdate();
            }
        });
    }

    //TP-6490
    clearOTP = (e, field, elementId) => {
        e.preventDefault();
        this.setState({[field]: new Array(7).join(" ")})
        if(elementId){
            document.getElementById(elementId).focus();
        }
    }

    updateSaveImageButton = () => {
        //TP-3820
        if (this.state.disableSavePhotoButton === true)
            this.setState({ disableSavePhotoButton: false });
    }
    
    onImgScaleChange = (e) => {
        //TP-3497
        try {
            this.setState({ imageScaleValue: parseFloat(e.target.value) }, () => {
                printConsole(this.state.imageUneditted);
                if (this.state.disableSavePhotoButton && this.state.imageUneditted.includes("profile.png") === false) 
                    this.updateSaveImageButton();
            });
        } catch (error) {
            printConsole(`error faced in onImgScaleChange method ${error}`);
        }
    }
    //TP-3794
    updateMyProfilePicLoad = (fileUrl) => {
        //TP-3497 & TP-3506
        try {
            const {imageFormat} = this.state;
            if (fileUrl !== undefined && fileUrl !== null && fileUrl !== "") { //TP-3497
                fileUrl = 'data:image/jpg;base64,'+ fileUrl;
            } else {
                //TP-3497
                fileUrl = "/profile.png";
            }
            let myImage = document.getElementById("my-profile-pic");
            
            if (myImage && myImage !== null)
                myImage.src = fileUrl;
            else {
                this.myprofileTime = setTimeout(() => {
                    let myImage = document.getElementById("my-profile-pic");
                    if (myImage && myImage !== null)
                        myImage.src = fileUrl;
                    clearTimeout(this.myprofileTime);
                }, 3000);
            }

            if (fileUrl !== undefined && fileUrl !== "" && fileUrl !== null) {                                         
                this.setState({isDeleteEnabled: true})
            } else if (fileUrl === "" || fileUrl === null) {
                this.setState({isDeleteEnabled: false})
            }
        
            //let stringLength = fileUrl.length - 'data:image/png;base64,'.length;
        
            //var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
            //var sizeInKb=sizeInBytes/1000;
            //console.log("Size of the file??", sizeInKb);        
            //console.log(url);
            
        } catch (error) {
            printConsole(`error faced in updateMyProfilePic method ${error}`);
        }
    }
    
    //TP-3199 -- set the image (in state) as the profile picture
    updateImageUrl = (fileUrl) => {
        //TP-3497 & TP-3506
        try {
            const {imageFormat} = this.state;
            let url;
            if (fileUrl && fileUrl !== null && fileUrl.includes("base64"))
                url = fileUrl.split(';base64,')[1];
            else if (fileUrl !== undefined && fileUrl !== null && fileUrl !== "") { //TP-3497
                url = fileUrl;
                fileUrl = 'data:image/jpg;base64,'+ fileUrl;
            } else {
                //TP-3497
                url = fileUrl;
                fileUrl = "/profile.png";
            }
            //this.setState({myPicture: url}, () => {});
            let imageEle = document.getElementById("profile-pic");
            let myImage = document.getElementById("my-profile-pic");
            /* if (imageEle && imageEle !== null)
                imageEle.src = fileUrl;
            else {
                this.profileTimer = setTimeout(() => {
                    let imageEle = document.getElementById("profile-pic");
                    if (imageEle && imageEle !== null)
                        imageEle.src = fileUrl;                
                    clearTimeout(this.profileTimer);
                }, 2500);
            } */
            if (myImage && myImage !== null)
                myImage.src = fileUrl;
            else {
                this.myprofileTime = setTimeout(() => {
                    let myImage = document.getElementById("my-profile-pic");
                    if (myImage && myImage !== null)
                        myImage.src = fileUrl;
                    clearTimeout(this.myprofileTime);
                }, 3000);
            }
        
            //let stringLength = fileUrl.length - 'data:image/png;base64,'.length;
        
            //var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
            //var sizeInKb=sizeInBytes/1000;
            //console.log("Size of the file??", sizeInKb);        
            //console.log(url);
            this.props.updateMyProfilePicture(url);
        } catch (error) {
            printConsole(`error faced in updateImageUrl method ${error}`);
        }        
    }

    onChangeImage = (val) => {
        if (this.state.changedImage === !val)
            this.setState({ changedImage: val });
    }
    
    onClickSaveProfileImage = (e) => {
        if (this.state.disableSavePhotoButton === true) return;
        this.setState({ isPhotoSubmitLoading: true }, () => {
            sendSocketFlag = true //TP-3385
            this.setState({saveImage : true, changedImage: false}, () => {
                this.onClickOpenPhotoEditor();
                this.setState({
                    saveImage: false,
                    disableUpdateButton: false,
                    imageScaleValue: 1.2  //TP-3497                
                }, () => {
                    this.onUpdateUserImage(e); //TP-3497,6275
                });
            })
        });
    }

    //TP-3497
    handleClickProfile = (e) => {
        //console.log(e.target);
        //console.log(e.target.parentNode);
        //console.log(e.target.parentElement)
        if(e.target.id && (e.target.id === "my-profile-pic" || e.target.id ===  "preview-icon")) {
            console.log("clicked on image preview")
        } else if (e.target.id && (e.target.id === "upload-image" || e.target.id === "delete-image")) {
            console.log("upload/delete image is clicked")
        } else {
            this.setState({showProfileOption: false});
        }
    }

    //TP-3497
    onClickUploadImage = () => {
        this.setState({showProfileOption: false});
        this.onClickSelectProfileImage();
        this.onClickOpenPhotoEditor();
    }

    //TP-3506
    onClickRemoveProfilePicture = (e) => {
        this.updateImageUrl("");
        this.setState({showProfileOption: false});
        this.setState({ isPhotoSubmitLoading: true }, () => {
            sendSocketFlag = true //TP-3385
            this.setState({saveImage : true, changedImage: false}, () => {
                //this.onClickOpenPhotoEditor();
                this.setState({
                    saveImage: false,
                    disableUpdateButton: false,
                    imageScaleValue: 1.2                 
                }, () => {
                    this.onUpdateUserImage(e); //TP-3497,6275
                });
            })
        });
    }
    
    onClickOpenPhotoEditor = () => {
        this.setState((prevState) => ({ 
            showEditor: !prevState.showEditor 
        }), () => {    
            //TP-4067 & TP-3328  
            this.resizeProfileBody();
            this.props.updateMyProfilePic(this.state.myPicture);//TP-3672
            if (this.state.showEditor === false) {
                this.setState({ 
                    imageScaleValue: 1.2, //TP-3497
                    disableSavePhotoButton: true,
                    imageUneditted: "/profile.png" 
                });
            }
        });
    }
    //TP-4067
    resizeProfileBody = () => {
        /**TP-3949 --  Handling of centered UI of My Profile page content, so no scrollbar shows*/
        let bodyMarginTop = "10", bodyHeight;
        if (document.getElementById("MyProfile") !== null && document.getElementById("MyProfileContent") !== null) {
            bodyHeight = document.getElementById("MyProfileContent").offsetHeight;
            //console.log(document.getElementById("MyProfile").offsetHeight, bodyHeight);
            bodyMarginTop = Math.floor((document.getElementById("MyProfile").offsetHeight - bodyHeight)/2 -20)//TP-6285 deduct the padding
            this.setState({ bodyMarginTop });
        } else if (document.getElementById("MyProfile") !== null) {
            bodyHeight = 400;//415.8 TP-6147 changing the height to lift the title a little 
            bodyMarginTop = Math.floor((document.getElementById("MyProfile").offsetHeight - bodyHeight)/2)
            this.setState({ bodyMarginTop });
        }
    }
    
    onClickSelectProfileImage = () => {
        fileDialog({ multiple: false, accept: '.jpg, .jpeg, .png' })
            .then( async (files) => {
                //console.log("files================>",files);
                const [file] = files;
                if (isFileImage(file) === true) {
                    //console.log(formatBytes(file.size, 3));
                    const splitedArray = file.name.split('.');
                    const fileUrl = URL.createObjectURL(file);
                    //console.log(fileUrl);
                    this.setState({imageUneditted: fileUrl, imageFormat: file['type']});
                    //let imageEle = document.getElementById("profile-pic");
                    //imageEle.src = fileUrl;
                } else {
                    this.setState({
                        retCode: 100, 
                        bResponded: true, 
                        errStrReturned: i18nMark('Please upload only .jpg, .jpeg, .png files as Profile Picture')
                    })
                }
            })
            .catch(error => {
                let imageEle = document.getElementById("profile-pic");
                imageEle.src = "/profile.png";
                let myImage = document.getElementById("my-profile-pic");
                myImage.src = "/profile.png";
            }) 
    }

    //TP-3672
    onClose = (e) => {
        if(e)//TP-3950
            e.preventDefault();
        this.getUserProfileSettings();//TP-4672
        this.props.checkMonthlyMinutes();//TP-6213 load user
        if(this.state.showEditor){
            this.onClickOpenPhotoEditor();//TP-6462
        }
        //TP-6147
        this.setState({
            email: this.props.email, phonenumber: this.props.phonenumber, optional_email: "" ,password: "", repeatPassword: "", isEmailAlreadyRegistered: false,
            showEmailVerifyLink: false, emailOtp: new Array(7).join(" "), emailVerified: false, emailOtpSent: false, wrongEmailOtp: false, isMobileAlreadyRegistered: false,
            showMobileVerifyLink: true, showMobileGenerateOTP: false, mobileOtp: new Array(7).join(" "), mobileVerified: false,  mobileOtpSent: false, wrongMobileOtp: false,
            emailOTPLimitReached: false, mobileOTPLimitReached: false, expiredEmailOTP: false, expiredMobileOTP: false
        })
        //TP-3871
        this.props.updateOpenGroup(true);//TP-4028
        this.props.onRouteChange('home'); //TP-4028
        //sessionStorage.setItem("pageName", 'Groups');//TP-1579
        //window.location.reload();
        //this.props.openPage('Groups', 'GroupsTab', '#2d3858');
    }

    onClickClose = (e) => {
        if (e)//TP-3950
            e.preventDefault();
		this.setState({ bSubmitted: false, bResponded: false }, () => {
            this.props.updateMyProfilePic(this.state.myPicture); //TP-3672
        });
    }

    //TP-3798
    onCloseReturnFailure = () => {
        this.onClickClose();//TP-3950
        this.getUserProfileSettings();
    }

    //TP-3798
    onCloseReturnSuccess = () => {
        this.onClickClose();//TP-3950
        this.onClose();//TP-3950
    }

    cancelPage = (e) => {
        e.preventDefault();
        sessionStorage.setItem("pageName", "Groups");
        window.location.reload();        
        //this.props.openPage('Groups', 'GroupsTab', '#2d3858')
    }

    onClickChangePassword = (e) => {
        e.preventDefault();
        this.props.updateChangePassword(true);
        //TP-3447
        //this.changeTimer = setTimeout(() => {
            //this.props.onRouteChange('change-password');
            this.props.openChangePassword(); //TP-5504
            //clearTimeout(this.changeTimer);
        //}, 1000);
    }

    onUpdateUser = (e) => {
        e.preventDefault();
        const {isEmailAlreadyRegistered, isMobileAlreadyRegistered, showMobileGenerateOTP, showEmailVerifyLink} = this.state;
		if (!this.validator.allValid() || isMobileAlreadyRegistered || isEmailAlreadyRegistered || showMobileGenerateOTP || showEmailVerifyLink) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
        /*TP-3328*/if (this.state.disableUpdateButton === true) return;
        this.setState({ isLoading: true });
        let userDetails = {};
        let {email, optional_email, phonenumber, verified} = this.state
        if(optional_email){
            email = optional_email //when email is optional
        }
        if((this.props.phonenumber !== phonenumber) && checkDummyEmail({verified, email })){
            email = `${phonenumber}@telepresenz.com`;//TP-6318
        }
        //console.log(this.state.myPicture);
        //TP-3506 -- Handled the payload of the API for the delete image flow
        if (this.state.myPicture !== null && this.state.myPicture !== undefined) {
            userDetails = {
                user_id: this.state.id,
                my_picture: this.state.myPicture.includes("profile.png") === true ? "" : this.state.myPicture , /**	TP-3705*/
                my_camera_on: this.state.camera_on,
                streaming_quality: /*TP-4670*/(this.state.streaming_quality) ? this.state.streaming_quality.value : "low",
                image_quality: this.state.image_quality.value,
                allow_auto_connect: this.state.auto_connect,
                disable_notifications: this.state.disable_notifications,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                phonenumber: this.state.phonenumber,//TP-6082
                verified,//TP-6082
                email: email.toLowerCase(),//TP-6082
                password: encryptData(this.state.password)//TP-6182
            };
        } else {
            userDetails = {
                user_id: this.state.id,
                my_camera_on: this.state.camera_on,
                streaming_quality: /**TP-4670*/ (this.state.streaming_quality) ? this.state.streaming_quality.value: "low",
                image_quality: this.state.image_quality.value,
                allow_auto_connect: this.state.auto_connect,
                disable_notifications: this.state.disable_notifications,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                phonenumber: this.state.phonenumber,//TP-6082
                verified,//TP-6082
                email: email.toLowerCase(),//TP-6082
                password: encryptData(this.state.password)//TP-6182
            };
        }
        //console.log(userDetails); return;
		//TP-3672
        this.authService.fetch('user/setProfileData', {
			method: 'put',
			body: JSON.stringify(userDetails)
		})
		.then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                return response;
			} else {
                throw response;
			}
		})
		.then(data => {
            //if(data !== null && data !== undefined){                
                //console.log(userDetails);
                //TP-4672 -- Disable the update form button when the changes are already submitted
                if(this.state.disableUpdateButton === false)
                    this.setState({ disableUpdateButton: true });                
                    
				// TP-3385 -- Broadcast the new-image-upload signal to all users 
				printConsole(`Broadcast the new-image-upload socket signal? ${sendSocketFlag}`);
                const json = {
                    userId: this.state.id,
                    updateTimeStamp: new Date()
                }
				if (sendSocketFlag === true) {
                    //TP-4028 -- Update My Profile picture in Users table
                    let userArr = [];
                    userArr.push(this.state.id);
                    this.props.updateUserIdUploadImage(userArr); //TP-3483
                    //this.props.updateMyProfilePicture(this.state.myPicture);
					printConsole(json);
					//TP-3672
                    this.authService.socket.emit('new-image-upload', json);
					sendSocketFlag = false;
					this.setState({
                        disableSavePhotoButton: true, 
                        isPhotoSubmitLoading: false,
						bSubmitted: false,
						isLoading: false,
						bResponded: true,
						errStrReturned: i18nMark('User details successfully updated!'),
                        emailVerified: false, mobileVerified: false, emailOtp: new Array(7).join(" "), mobileOtp: new Array(7).join(" "), showEmailVerifyLink: false, showMobileGenerateOTP: false, emailOtpSent: false, mobileOtpSent: false,
                        wrongEmailOtp: false, wrongMobileOtp: false, emailOTPLimitReached: false, mobileOTPLimitReached: false, expiredEmailOTP: false
					});
				} else {
					this.setState({
                        disableSavePhotoButton: true, 
                        isPhotoSubmitLoading: false,
						bSubmitted: false,
						isLoading: false,
						bResponded: true,
						errStrReturned: i18nMark('User details successfully updated!'),
                        emailVerified: false, mobileVerified: false, emailOtp: new Array(7).join(" "), mobileOtp: new Array(7).join(" "), showEmailVerifyLink: false, showMobileGenerateOTP: false, emailOtpSent: false, mobileOtpSent: false,
                        wrongEmailOtp: false, wrongMobileOtp: false, emailOTPLimitReached: false, mobileOTPLimitReached: false, expiredEmailOTP: false
					});
				}
                this.getUserProfileSettings();
                //TP-6082
                if(this.props.email !== email){
                    this.onSubmitSignout();
                }
                this.authService.socket.emit('user-details-updated', json);//TP-6431
                if(this.state.showEditor){
                    this.onClickOpenPhotoEditor();//TP-6462
                }
			//}
		})
		.catch((error) => {
			if (!error) {
                return;
			}
			if (error.status === 405) {
				this.setState({
					bSubmitted: false, bResponded: false, showExpertWarning: true, isLoading: false
                });
                return;
			}
			// output error in update user profile
            if(error.status === 401) {
                this.setState({errStrReturned: i18nMark('There is already a user registered with these details. Please resubmit with a different name.'), isLoading: false, bResponded: true });
            } 
            else if(error.status === 403) {
                this.setState({errStrReturned: i18nMark('Not Authorized'), isLoading: false, bResponded: true });
            }
			else if(error.status === 422){
				this.setState({errStrReturned: i18nMark('Failed to update user details. Please review the entries and resubmit again.'), isLoading: false, bResponded: true});
			}
			else if(error.status === 500){
				this.setState({errStrReturned: i18nMark('Exception error'), isLoading: false, bResponded: true });
			}
			else{
				this.setState({errStrReturned: i18nMark('Failed to update the user details.'), isLoading: false, bResponded: true});
			}
		})
	}

    //TP-6275
    onUpdateUserImage = (e) => {
        e.preventDefault();
		// if (!this.validator.allValid()) {
		// 	this.validator.showMessages();
		// 	// rerender to show messages for the first time
		// 	this.forceUpdate();
		// 	return;
		// }
        // /*TP-3328*/if (this.state.disableUpdateButton === true) return;
        this.setState({ isLoading: true });
        let userDetails = {};
        let {id, email, camera_on:my_camera_on, auto_connect:allow_auto_connect, disable_notifications, first_name, last_name, phonenumber, verified} = this.props
        //console.log(this.state.myPicture);
        //TP-3506 -- Handled the payload of the API for the delete image flow
        if (this.state.myPicture !== null && this.state.myPicture !== undefined) {
            userDetails = {
                user_id: id,
                my_picture: this.state.myPicture.includes("profile.png") === true ? "" : this.state.myPicture , /**	TP-3705*/
                my_camera_on,
                streaming_quality: /*TP-4670*/(this.state.streaming_quality) ? this.state.streaming_quality.value : "low",
                image_quality: this.state.image_quality.value,
                allow_auto_connect, disable_notifications,
                first_name, last_name
            };
        }
        //console.log(userDetails); return;
		//TP-3672
        this.authService.fetch('user/setProfileData', {
			method: 'put',
			body: JSON.stringify(userDetails)
		})
		.then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                return response;
			} else {
                throw response;
			}
		})
		.then(data => {
            //TP-4672 -- Disable the update form button when the changes are already submitted
            if(this.state.disableUpdateButton === false)
                this.setState({ disableUpdateButton: true });                
                
            // TP-3385 -- Broadcast the new-image-upload signal to all users 
            printConsole(`Broadcast the new-image-upload socket signal? ${sendSocketFlag}`);
            //TP-4028 -- Update My Profile picture in Users table
            let userArr = [];
            userArr.push(this.state.id);
            this.props.updateUserIdUploadImage(userArr); //TP-3483
            const json = {
                userId: this.state.id,
                updateTimeStamp: new Date()
            }
            printConsole(json);
            //TP-3672
            this.authService.socket.emit('new-image-upload', json);
            sendSocketFlag = false;
            this.setState({
                disableSavePhotoButton: true, 
                isPhotoSubmitLoading: false,
                bSubmitted: false,
                isLoading: false,
                bResponded: true,
                errStrReturned: i18nMark('User details successfully updated!'),
                emailVerified: false, mobileVerified: false, emailOtp: new Array(7).join(" "), mobileOtp: new Array(7).join(" "), showEmailVerifyLink: false, showMobileGenerateOTP: false, emailOtpSent: false, mobileOtpSent: false,
                wrongEmailOtp: false, wrongMobileOtp: false, emailOTPLimitReached: false, mobileOTPLimitReached: false, expiredEmailOTP: false
            });
            this.getUserProfileSettings();
		})
		.catch((error) => {
			if (!error) {
                return;
			}
			if (error.status === 405) {
				this.setState({
					bSubmitted: false, bResponded: false, showExpertWarning: true, isLoading: false
                });
                return;
			}
			// output error in update user profile
            if(error.status === 401) {
                this.setState({errStrReturned: i18nMark('There is already a user registered with these details. Please resubmit with a different name.'), isLoading: false, bResponded: true });
            } 
            else if(error.status === 403) {
                this.setState({errStrReturned: i18nMark('Not Authorized'), isLoading: false, bResponded: true });
            }
			else if(error.status === 422){
				this.setState({errStrReturned: i18nMark('Failed to update user details. Please review the entries and resubmit again.'), isLoading: false, bResponded: true});
			}
			else if(error.status === 500){
				this.setState({errStrReturned: i18nMark('Exception error'), isLoading: false, bResponded: true });
			}
			else{
				this.setState({errStrReturned: i18nMark('Failed to update the user details.'), isLoading: false, bResponded: true});
			}
		})
	}

    render() {
        this.validator.purgeFields();
        const { optionsmap, showProfileOption, isDeleteEnabled, /**TP-3506 */ bodyMarginTop, showEmailVerifyLink, showMobileGenerateOTP, disableEmailVerify,
            emailVerified, mobileVerified, emailOtp, mobileOtp, emailOtpSent, mobileOtpSent, wrongEmailOtp, wrongMobileOtp, disableMobileVerify, emailOTPLimitReached,
            mobileOTPLimitReached, isMobileAlreadyRegistered, isEmailAlreadyRegistered, expiredEmailOTP, expiredMobileOTP, mobileCheckOngoing, isIST
        } = this.state;
        const { isSmallFormFactor, isMobileDevice, isVerySmallDevice, sms_support, verified, email } = this.props;//TP-3746
        const splitOTP = emailOtp ? emailOtp.split("") : new Array(6).fill("");
        const splitMobileOTP = mobileOtp ? mobileOtp.split("") : new Array(6).fill("");
        //let bodyHeight = (window.innerHeight > 660) ? window.innerHeight - (5 * (window.innerHeight/100)/**TP-3950*/) : (window.innerHeight > 600) ?  window.innerHeight - (10 * (window.innerHeight/100)) : window.innerHeight - (15 * (window.innerHeight/100));
        let imageMarginTop = "20";
        //TP-6266, stop jitters
        // if (document.getElementById("left-column") !== null && document.getElementById("my-profile-pic") !== null) {
        //     let leftColumnHeight = document.getElementById("left-column").offsetHeight;
        //     imageMarginTop = Math.floor((leftColumnHeight - document.getElementById("my-profile-pic").offsetHeight)/2);
        // }
        let editorMarginTop = "10";
        if (document.getElementById("left-column") !== null && document.getElementsByClassName("photo-modal-body")[0] !== undefined) {
            let leftColumnHeight = document.getElementById("left-column").offsetHeight;
            editorMarginTop = Math.floor((leftColumnHeight - document.getElementsByClassName("photo-modal-body")[0].offsetHeight)/2);
        }
        let {disableSavePhotoButton} = this.state;
        let buttonCls = "";
        if (disableSavePhotoButton === true)
            buttonCls = "color-grey";        
        if(this.state.bSubmitted || this.state.bResponded){
                let colorText = '#485890';
                if(this.state.retCode !== 200) colorText = 'red';
                return(
                    <div className='modale opened'>
                        <div className='__modal-dialog'>
                            <form>
                                <div className="__modal-header">
                                    <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
                                </div>
                                <div className="__modal-footer flex-center">
                                    {/*TP-3950 <button className="btn-green" onClick={/**TP-3798 this.state.retCode === 200 ? (e) =>this.onCloseReturnSuccess(e) : (e) =>this.onCloseReturnFailure(e)}><Trans id='OK'/></button> */}
                                </div>
                            </form>
                        </div>
                    </div>
                );
        } else {
            //TP-3821
            let lastRowMarginTop = (this.props.isexpert && (this.state.enable_user_video === "my_camera_enabled" || (this.state.enable_user_video === "my_camera_enabled_with_prefs" && this.state.camera_on))) || (!this.props.isexpert && !this.props.isadmin) ? "3%" : "4%" //TP-4672
            //TP-3949 & TP-3672 -- New UI for the My Profile page
            return (
                <div id="MyProfileContent" className='w-100 justify-content-center align-items-center form-text-color p-2' style={isMobileDevice === true ? {} : {marginTop: bodyMarginTop + "px"}}>
                {/**TP-3950	TP-3746 */}
                <Row className="center" style={{marginTop: '0px'}}>
                    <h2>
                        <Trans>My Profile</Trans>
                    </h2>
                </Row>
                <br/><br/>{/**TP-6147 give a gap between title and body*/}
                <Row className={isMobileDevice === true ? " center mt-2": " mt-2"} style={/*TP-3746*/isMobileDevice === true ? {width: '80%'} : {}}>
                    <Col id="left-column" sm={isMobileDevice ? 12 : 3} > 
                        { this.state.showEditor ?
                            <div className={isMobileDevice ? "" : "margin-photo-editor" }>
                                <Row className={isMobileDevice ? "photo-modal-body justify-content-center " : "photo-modal-body justify-content-end"} style={isMobileDevice ? {} : {marginTop: editorMarginTop + "px"}}>                                    
                                    <div className={isMobileDevice ? "" : ""}>
                                        <MyEditor id="my_editor" updateSaveImageButton={this.updateSaveImageButton} 
                                            image={this.state.imageUneditted} onChangeImage={this.onChangeImage} 
                                            saveImage={this.state.saveImage} changedImage={this.state.changedImage}
                                            updateImageUrl={this.updateImageUrl} imageScaleValue={this.state.imageScaleValue} />
                                        <Row id="profile-controls" className="profile-controls position-relative">
                                            {/* <Col> 
                                                <i id="upload-icon" className="fa fa-upload upload-icon color-icon" onClick={this.onClickSelectProfileImage}></i>   
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="upload-icon">
                                                        <Trans>Upload Image</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    ''
                                                }
                                            </Col> */}
                                            <Col> {/**TP-3497 */}
                                                <i id="save-icon" className={"fas fa-save save-icon color-icon "+buttonCls} disabled={disableSavePhotoButton} onClick={this.onClickSaveProfileImage}></i>                                                                 
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="save-icon">
                                                        <Trans>Save Image</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    null
                                                }
                                            </Col>
                                            <Col>
                                                <i id="back-icon" className="fas fa-arrow-circle-left back-icon color-icon" onClick={() => this.onClickOpenPhotoEditor()}></i>                                    
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="back-icon">
                                                        <Trans>Go Back</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    null
                                                }
                                            </Col>                                                                                       
                                            {/* <Col>
                                                <i id="remove-icon" className="fas fa-trash remove-icon color-icon" onClick={() => this.onClickRemoveProfilePicture()}></i>                                    
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="remove-icon">
                                                        <Trans>Remove Image</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    ''
                                                }
                                            </Col> */}                                            
                                        </Row>
                                        <Row className={isMobileDevice ? "mt-5 justify-content-center position-relative" : "mt-5 justify-content-center position-relative"}>
                                            { this.state.disableSavePhotoButton === false /* || (this.props.myPicture !== undefined && this.props.myPicture !== null) */ ?
                                                <div className="scale-div input-wrapper margin-bottom-15">
                                                    <label><Trans>Zoom</Trans>: 
                                                        <input style={{width: "50%"}} type="range" min="1" max="10" defaultValue={this.state.imageScaleValue !== 1.2 ? this.state.imageScaleValue : 1.2} onChange={(e) => this.onImgScaleChange(e)}></input>
                                                    </label>
                                                </div>
                                                :
                                                null
                                            }
                                        </Row>
                                    </div>
                                         
                                </Row>
                                {/* <Row className="photo-modal-footer justify-content-center align-items-center">
                                    <button className="col-sm-2 btn-green" onClick={this.onClickSelectProfileImage}><Trans>Upload</Trans></button>
                                    <span className={isMobileDevice ? "col-sm-2" : "col-md-1"}></span>
                                    <button showSpinner={this.state.isPhotoSubmitLoading}  className={buttonCls} disabled={disableSavePhotoButton} onClick={this.onClickSaveProfileImage}><Trans>Save</Trans></button>
                                </Row> */}
                            </div>
                            :
                            <>
                                <Row className={isMobileDevice ? "justify-content-center align-items-center" : "justify-content-center" } style={isMobileDevice ? {} : {marginRight: "15%", marginTop: imageMarginTop + "px"}} >
                                    <img onClick={isDeleteEnabled === true ? () => this.setState({showProfileOption: true}) : () => this.onClickUploadImage()} id="my-profile-pic" className="my-profile-pic" src={"/profile.png"} alt='profile_logo' />
                                    <i id="preview-icon"  onClick={isDeleteEnabled === true ? () => this.setState({showProfileOption: true}) : () => this.onClickUploadImage()} className="fas fa-camera edit-icon"></i>                                    
                                </Row>
                                { isDeleteEnabled === true ?
                                    <div className="dropdown-content pointer position-dropdown-profile" style={{display:showProfileOption?"block":"none"}} /* onMouseLeave={() => this.setState({showProfileOption: false})} */>
                                        <a id="upload-image" onClick={() => this.onClickUploadImage()}>
                                            <Trans>Upload Image</Trans>
                                        </a>
                                        <a id="delete-image" onClick={(e) => this.onClickRemoveProfilePicture(e)}>
                                            <Trans>Delete Image</Trans>
                                        </a>
                                    </div>    
                                    :
                                    null
                                }                        
                            </>
                        }
                    </Col>
                    {/* //TP-6082 */}
                    <Col id="right-column" sm={isMobileDevice ? 12 : 9} >
                        <Row>
                            <Col sm={6}>
                                <Row className={isMobileDevice ? "mt-3 margin-bottom-15" : "margin-bottom-15 width-profile"}>
                                    <Col id="first_name" className="input-wrapper ">
                                        <I18n>
                                            {({ i18n }) => 
                                                <>
                                                    <input className='signup-input-field' name={i18n._(t`first_name`)} type="text" placeholder={i18n._(t`First Name`) + '*'} value={this.state.first_name} onChange={this.onChangeForm('first_name')} />
                                                    {this.validator.message(i18n._(t`first_name`), this.state.first_name, 'required|alpha_num_space')}
                                                </>
                                            }
                                        </I18n>
                                        {   isSmallFormFactor === false ? 
                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="first_name">
                                                    <Trans>Enter First Name</Trans>
                                                </UncontrolledTooltip>       
                                            :
                                                null
                                        }
                                    </Col>
                                </Row>
                                <Row className={isMobileDevice ? "mt-3 margin-bottom-15" : "mt-3 margin-bottom-15 width-profile"}>
                                    <>
                                    {
                                        !checkDummyEmail({verified, email }) ?
                                            <Col id="email" className="input-wrapper ">
                                                <I18n>
                                                    {({ i18n }) => 
                                                        <>
                                                            <input className='signup-input-field' name={i18n._(t`user_email`)} type="text" placeholder={i18n._(t`Email`) + (!sms_support ? '*' : '')} value={this.state.email} onChange={this.onChangeForm('email', i18n)}
                                                                disabled={(verified === "email" &&  this.state.phonenumber)? true : false}//TP-6517
                                                            />
                                                            {   !sms_support ? 
                                                                    this.validator.message(i18n._(t`user_email`), this.state.email, 'required|emailValidate')
                                                                :
                                                                    this.validator.message(i18n._(t`user_email`), this.state.email, 'emailValidate')
                                                            }
                                                            { isEmailAlreadyRegistered ? <div className="add-users-validation-msg"><Trans>This email is already registered.</Trans></div> : '' }	
                                                        </>											
                                                    }
                                                </I18n>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="email">
                                                        <Trans>Email</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    null
                                                }
                                            </Col>
                                        :
                                            <Col id="email" className="input-wrapper ">
                                                <I18n>
                                                    {({ i18n }) => 
                                                        <>
                                                            <input className='signup-input-field' name={i18n._(t`user_email`)} type="text" placeholder={i18n._(t`Email`)} value={this.state.optional_email} onChange={this.onChangeForm('optional_email', i18n)}/>
                                                            { this.validator.message(i18n._(t`user_email`), this.state.optional_email, 'emailValidate') }
                                                            { isEmailAlreadyRegistered ? <div className="add-users-validation-msg"><Trans>This email is already registered.</Trans></div> : '' }	
                                                        </>											
                                                    }
                                                </I18n>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="email">
                                                        <Trans>Email</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    null
                                                }
                                            </Col>
                                        }
                                        </>
                                </Row>
                                {
                                    <I18n>
                                        {({ i18n }) =>
                                            <>
                                                <Row className="mt-3">
                                                    {
                                                        (emailOtpSent || wrongEmailOtp || expiredEmailOTP) ?
                                                            <Col sm={7} className="mt-1">
                                                                <>
                                                                    <input className="mr-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitOTP[0]} type="text" id="first" onChange={(e) => this.handleOTPChange(e, "second", 0, "verify_email", i18n)}/>
                                                                    <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitOTP[1]} type="text" id="second" onChange={(e) => this.handleOTPChange(e, "third", 1, "verify_email", i18n)}/>
                                                                    <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitOTP[2]} type="text" id="third" onChange={(e) => this.handleOTPChange(e, "fourth", 2, "verify_email", i18n)}/>
                                                                    <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitOTP[3]} type="text" id="fourth" onChange={(e) => this.handleOTPChange(e, "fifth", 3, "verify_email", i18n)}/>
                                                                    <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitOTP[4]} type="text" id="fifth" onChange={(e) => this.handleOTPChange(e, "sixth", 4, "verify_email", i18n)}/>
                                                                    <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitOTP[5]} type="text" id="sixth" onChange={(e) => this.handleOTPChange(e, "verify", 5, "verify_email", i18n)}/>
                                                                    <a id="clearEmailOtp" className="mx-1" href="#" onClick={(e) => this.clearOTP(e, "emailOtp", "first")} style={{color: 'white'}}><i class="fas fa-redo"></i></a>
                                                                    {this.validator.message(i18n._(t`otp`), emailOtp.trim(), 'required|otp_length|integer', {className: "pl-2 profile-validation-msg", messages: {integer: i18n._(t`OTP must be digits`)}})}
                                                                    { (wrongEmailOtp && this.validator.fieldValid(i18n._(t`otp`))) ? <div className="profile-validation-msg pl-2"><Trans>Invalid OTP</Trans></div> : '' }	
                                                                    { (expiredEmailOTP && this.validator.fieldValid(i18n._(t`otp`))) ? <div className="profile-validation-msg pl-2"><Trans>Expired OTP</Trans></div> : '' }	{/** //TP-6272 */}
                                                                    { isSmallFormFactor === false ? 
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="clearEmailOtp">
                                                                            <Trans>Clear OTP</Trans>
                                                                        </UncontrolledTooltip>       
                                                                        :
                                                                        null
                                                                    }
                                                                </>
                                                            </Col>
                                                        :
                                                            <Col sm={7}></Col>
                                                    }
                                                    {/** TP-6327,6215,6213 */}
                                                    {
                                                        (showEmailVerifyLink || ((verified === "none" || verified === "mobile") && !checkDummyEmail({verified, email})) && !emailVerified && !isEmailAlreadyRegistered && this.validator.fieldValid(i18n._(t`user_email`)) && (this.state.email || this.state.optional_email))?
                                                            <Col sm={3}>
                                                                <button className="btn-green" id="verify" onClick={() => this.onSubmitGetVerifyOtp("verify_email", i18n._(t`user_email`))} disabled={disableEmailVerify}><Trans>Verify</Trans></button>
                                                            </Col>
                                                        :
                                                            <Col sm={3}></Col>
                                                    }
                                                </Row>
                                            </>
                                        }
                                    </I18n>
                                }
                                {emailOtpSent && !emailOTPLimitReached && !expiredEmailOTP ? <div className="pl-2 mt-4"><Trans>OTP has been sent to email</Trans></div>: null}{/*TP-6276,6277*/}
                                {emailVerified? <div className="pl-2"><Trans>Email is verified</Trans></div>: null}
                                {emailOTPLimitReached ? <div className="pl-2 mt-4"><Trans>Max. OTP requests reached, try after some time</Trans></div>: null}{/** //TP-6272 */}
                                {
                                    emailVerified && (verified === "none" || verified === "mobile")?
                                        <>
                                            <Row className={isMobileDevice ? "mt-3 margin-bottom-15" : "mt-3 margin-bottom-15 width-profile"}>
                                                <Col id="password" className="input-wrapper ">
                                                    <I18n>
                                                        {({ i18n }) =>
                                                        <>
                                                            <input className='signup-input-field' name={i18n._(t`password`)} type="password" placeholder={i18n._(t`Enter your new password here`)} value={this.state.password} onChange={this.onChangeForm('password')} />
                                                            {this.validator.message(i18n._(t`password`), this.state.password, 'required')}
                                                        </>
                                                        }
                                                    </I18n>
                                                </Col>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="password">
                                                        <Trans>Enter your new password here</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    null
                                                }
                                            </Row>
                                            <Row className={isMobileDevice ? "mt-3 margin-bottom-15" : "mt-3 margin-bottom-15 width-profile"}>
                                                <Col id="confirm_password" className="input-wrapper ">
                                                    <I18n>
                                                        {({ i18n }) =>
                                                        <>
                                                            <input className='signup-input-field margin-bottom-0' name={i18n._(t`repeatpassword`)} type="password" placeholder={i18n._(t`Re-enter the new password here`)} value={this.state.repeatPassword} onChange={this.onChangeForm('repeatPassword')} />
                                                            {this.validator.message(i18n._(t`confirm_password`), this.state.repeatPassword, 'confirmPassword')}
                                                        </>
                                                        }
                                                    </I18n>
                                                </Col>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="confirm_password">
                                                        <Trans>Re-enter the new password here</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    null
                                                }
                                            </Row>
                                        </>
                                    :
                                        null
                                }
                                {/*TP-3821 & TP-3950 TP-4664*/ (this.state.hideStreamingQuality === false && this.props.isexpert && (this.state.enable_user_video === "my_camera_enabled" || (this.state.enable_user_video === "my_camera_enabled_with_prefs" && this.state.camera_on))) || (!this.props.isexpert && !this.props.isadmin && this.state.hideStreamingQuality === false)  ?
                                    <Row className={isMobileDevice ? "mt-4 margin-bottom-15" : "mt-4 margin-bottom-15 width-profile"}>
                                        <Col id="streaming_quality" className="input-wrapper">                    
                                            <I18n>
                                                {({ i18n }) =>
                                                    <Select
                                                        placeholder={i18n._(t`Select Stream Quality`)} /**TP-4672*/
                                                        components={makeAnimated()}
                                                        value={this.state.streaming_quality}
                                                        onChange={this.onChangeStreaminQuality}
                                                        options={optionsmap}
                                                        isSearchable={isSmallFormFactor === true ? false : true} //TP-2892
                                                        className="text-black"
                                                    /> 
                                                }
                                            </I18n> 
                                            { isSmallFormFactor === false ? 
                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="streaming_quality">
                                                    <Trans>Select Stream Quality</Trans>
                                                </UncontrolledTooltip>       
                                                :
                                                null
                                            }  
                                        </Col> 
                                    </Row>    
                                    :
                                    /**TP-4664*/   (this.state.hideStreamingQuality && this.props.isexpert && (this.state.enable_user_video === "my_camera_enabled" || (this.state.enable_user_video === "my_camera_enabled_with_prefs" && this.state.camera_on))) || (!this.props.isexpert && !this.props.isadmin && this.state.hideStreamingQuality)  ?
                                        <Row className={isMobileDevice ? "mt-4 margin-bottom-15" : "mt-4 margin-bottom-15 width-profile"}>
                                            <Col id="streaming_quality" className="input-wrapper">                    
                                                <I18n>
                                                    {({ i18n }) => 
                                                        <>
                                                            <input disabled={true}
                                                            className='signup-input-field' name={i18n._(t`Select Streaming Quality`)} type="text" placeholder={i18n._(t`Select Streaming Quality`)} value="Low" /**TP-4670*/ />
                                                            <span className="nocameracls"><Trans>No camera is detected</Trans></span>                                                    
                                                        </>											
                                                    }
                                                </I18n>
                                            </Col> 
                                        </Row>
                                        :
                                        null
                                } 
                                {/**TP-3950*/ this.props.isadmin && !this.props.isexpert ?
                                    null
                                    :
                                        (this.state.enable_user_video === "my_camera_enabled" || this.state.enable_user_video === "my_camera_enabled_with_prefs") && this.state.enable_streaming === true ?
                                            <Row className={isMobileDevice ? "mt-4 margin-bottom-15" : "mt-4 margin-bottom-15 width-profile"}>
                                                <Col className="input-wrapper">
                                                    <I18n>
                                                        {({ i18n }) => 
                                                            <>
                                                                <ToggleCheckBox value={/*TP-3862*/this.state.enable_user_video === "my_camera_enabled" || this.props.isexpert === false /*TP-4401*/ ? true : this.state.camera_on} id={i18n._(t`camera_on`)} disableCheckBox={this.state.enable_user_video === "my_camera_enabled_with_prefs" && this.props.isexpert === true ? false : true} label="Allow My Video Feed" toggleCheckBox={this.onChangeForm('camera_on')} />
                                                                {this.state.enable_user_video === "my_camera_enabled_with_prefs" ?
                                                                    this.validator.message(i18n._(t`camera_on`), this.state.camera_on, 'required')
                                                                    :
                                                                    null
                                                                }                                    
                                                            </>											
                                                        }
                                                    </I18n>    
                                                </Col>
                                            </Row>
                                        :
                                            null
                                }
                            </Col>
                            <Col sm={6}>
                                <Row className={isMobileDevice ? "mt-3 margin-bottom-15" : "margin-bottom-15 width-profile"}>
                                    <Col id="last_name" className="input-wrapper">
                                        <I18n>
                                            {({ i18n }) => 
                                                <>
                                                    <input className='signup-input-field' name={i18n._(t`last_name`)} type="text" placeholder={i18n._(t`Last Name`)+ '*'} value={this.state.last_name} onChange={this.onChangeForm('last_name')} />
                                                    {this.validator.message(i18n._(t`last_name`), this.state.last_name, 'required|alpha_num_space')}
                                                </>
                                            }
                                        </I18n>
                                        { isSmallFormFactor === false ? 
                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="last_name">
                                                <Trans>Enter Last Name</Trans>
                                            </UncontrolledTooltip>       
                                            :
                                            null
                                        }
                                    </Col>
                                </Row>
                                <Row className={isMobileDevice ? "mt-3 margin-bottom-15" : "mt-3 margin-bottom-15 width-profile"}>
                                    {
                                        <>
                                            <Col id="mobile" className="input-wrapper ">
                                                <I18n>
                                                    {({ i18n }) => 
                                                        <>
                                                            <input className='signup-input-field' name={i18n._(t`mobile`)} type="text" placeholder={i18n._(t`Mobile`) + (sms_support ? '*' : '')} value={this.state.phonenumber} onChange={this.onChangeForm('phonenumber', i18n)}
                                                                disabled={((verified === "mobile" && !checkDummyEmail({verified, email})) || !isIST) ? true : false}//TP-6517
                                                            />
                                                            {sms_support ? 
                                                                this.validator.message(i18n._(t`mobile`), this.state.phonenumber, 'required|phone|min_length')
                                                                :
                                                                this.validator.message(i18n._(t`mobile`), this.state.phonenumber, 'phone|min_length')
                                                            }
                                                            { isMobileAlreadyRegistered ? <div className="add-users-validation-msg"><Trans>This mobile is already registered</Trans></div> : '' }	
                                                        </>											
                                                    }
                                                </I18n>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="mobile">
                                                        <Trans>Mobile</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    null
                                                }
                                            </Col>
                                        </>
                                    }
                                </Row>
                                {
                                    <I18n>
                                        {({ i18n }) =>
                                            <>
                                                <Row className="mt-3">
                                                    {
                                                        mobileOtpSent || wrongMobileOtp || expiredMobileOTP?
                                                            <Col sm={7} className="mt-1">
                                                                <input className="mr-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitMobileOTP[0]} type="text" id="mobile_otp_first" onChange={(e) => this.handleOTPChange(e, "mobile_otp_second", 0, "verify_mobile", i18n)}/>
                                                                <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitMobileOTP[1]} type="text" id="mobile_otp_second" onChange={(e) => this.handleOTPChange(e, "mobile_otp_third", 1, "verify_mobile", i18n)}/>
                                                                <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitMobileOTP[2]} type="text" id="mobile_otp_third" onChange={(e) => this.handleOTPChange(e, "mobile_otp_fourth", 2, "verify_mobile", i18n)}/>
                                                                <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitMobileOTP[3]} type="text" id="mobile_otp_fourth" onChange={(e) => this.handleOTPChange(e, "mobile_otp_fifth", 3, "verify_mobile", i18n)}/>
                                                                <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitMobileOTP[4]} type="text" id="mobile_otp_fifth" onChange={(e) => this.handleOTPChange(e, "mobile_otp_sixth", 4, "verify_mobile", i18n)}/>
                                                                <input className="mx-1 text-center profile-otp" name={i18n._(t`otp`)} value={splitMobileOTP[5]} type="text" id="mobile_otp_sixth" onChange={(e) => this.handleOTPChange(e, "verify_mobile", 5, "verify_mobile", i18n)}/>
                                                                <a id="clearMobileOtp" className="mx-1" href="#" onClick={(e) => this.clearOTP(e, "mobileOtp", "mobile_otp_first")} style={{color: 'white'}}><i class="fas fa-redo"></i></a>
                                                                {this.validator.message(i18n._(t`mobileOtp`), mobileOtp.trim(), 'required|otp_length|integer', {className: "pl-2 profile-validation-msg", messages: {integer: i18n._(t`Mobile OTP must be digits`)}})}
                                                                { (wrongMobileOtp && this.validator.fieldValid(i18n._(t`mobileOtp`))) ? <div className="pl-2 profile-validation-msg"><Trans>Invalid OTP</Trans></div> : null }{/*TP-6268*/}
                                                                { (expiredMobileOTP && this.validator.fieldValid(i18n._(t`mobileOtp`))) ? <div className="pl-2 profile-validation-msg"><Trans>Expired OTP</Trans></div> : null }{/** //TP-6272 */}
                                                                { isSmallFormFactor === false ? 
                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="clearMobileOtp">
                                                                        <Trans>Clear OTP</Trans>
                                                                    </UncontrolledTooltip>       
                                                                    :
                                                                    null
                                                                }
                                                            </Col>
                                                        :
                                                            <Col sm={7}></Col>
                                                    }
                                                    {/** TP-6327,6215 */}
                                                    {
                                                        (showMobileGenerateOTP || (verified === "none" || verified === "email") && !mobileVerified && this.state.phonenumber && !isMobileAlreadyRegistered && this.validator.fieldValid(i18n._(t`mobile`)) && !mobileCheckOngoing) && isIST?
                                                            <Col sm={3}>
                                                                <button className="btn-green" id ="verify_mobile" onClick={() => this.onSubmitGetVerifyOtp("verify_mobile", i18n._(t`mobile`))} disabled={disableMobileVerify}><Trans>Verify</Trans></button>
                                                            </Col>
                                                        :
                                                            <Col sm={3}></Col>
                                                    }
                                                </Row>
                                            </>
                                        }
                                    </I18n>
                                }
                                {mobileOtpSent && !mobileOTPLimitReached && !expiredMobileOTP? <div className="pl-2 mt-4"><Trans>OTP has been sent to mobile</Trans></div>: null}{/*TP-6276,6277*/}
                                {mobileVerified? <div className="pl-2"><Trans>Mobile is verified</Trans></div>: null}
                                {mobileOTPLimitReached ? <div className="pl-2 mt-4"><Trans>Max. OTP requests reached, try after some time</Trans></div>: null}{/** //TP-6272 */}
                                { /**TP-3950*/ this.props.isadmin && !this.props.isexpert ?
                                    null
                                    :                        
                                    this.state.enable_auto_connect === true ?
                                        <Row className={isMobileDevice ? "mt-3 margin-bottom-15" : "mt-3 margin-bottom-15 width-profile"}>
                                            <Col className="input-wrapper">
                                                <I18n>
                                                    {({ i18n }) => 
                                                        <>
                                                            <ToggleCheckBox value={this.state.auto_connect} id={i18n._(t`auto_connect`)} label="Allow Auto Connect" toggleCheckBox={this.onChangeForm('auto_connect')} />
                                                            {this.validator.message(i18n._(t`auto_connect`), this.state.auto_connect, 'required')}                                    
                                                        </>											
                                                    }
                                                </I18n> 
                                            </Col>
                                        </Row>
                                    :
                                    null
                                }
                            </Col>
                        </Row>
                        <Row className={(isMobileDevice) ? "mt-4 margin-bottom-15 center" : "margin-bottom-15"} style={(isMobileDevice === false && isSmallFormFactor === false) ? {marginTop: lastRowMarginTop} : isMobileDevice === true ? {} : {marginTop: "3%"}}>
                            <button className={isSmallFormFactor ? "btn-red col-sm-1" : "btn-red col-xs-1"} onClick={(e) => this.onClose(e)}><Trans>Close</Trans></button>
                            <span className={/*TP-3746*/isVerySmallDevice ? "col-sm-1": "col-sm-2"}></span>
                            {
                                !checkDummyEmail({verified, email}) && (verified === "both" || verified === "email")?
                                    <button className={isSmallFormFactor && isMobileDevice ? /*TP-3746*/isVerySmallDevice ? "btn-red col-sm-4" : "btn-red col-sm-3" : isSmallFormFactor ? "btn-red col-sm-2" : "btn-red col-xs-2"} onClick={(e) => this.onClickChangePassword(e)}><Trans>Change Password</Trans></button>
                                :
                                    null
                            }
                            <span className={/*TP-3746*/isVerySmallDevice ? "col-sm-1": "col-sm-2"}></span>
                            <button className={isSmallFormFactor ? "btn-green col-sm-1" : "btn-green col-sm-1"} disabled={this.state.disableUpdateButton} onClick={this.onUpdateUser}><Trans>Update</Trans></button>
                        </Row>
                    </Col>                    
                </Row>
                {this.showLoader()}
                </div>
            )
        }
    }
}

export default MyProfile;