import adapter from 'webrtc-adapter';
import { printConsole, printDevConsole } from '../helpers';

class WebrtcPeerConnection {
    constructor({ gotRemoteTrack, onClose, onConnectionStateChange, user, to, localStream, groupId, sessionSignal, turn_username, turn_password, sessionType}) {
        printConsole(`trying to create peerconnection with turn user name and password, ${turn_username}, ${turn_password}`);
        try{
            this.sessionSignal = sessionSignal;
            this.onClose = onClose;
            this.onConnectionStateChange = onConnectionStateChange;
            this.user = user
            this.localStream = localStream;
            this.to = to;
            this.groupId = groupId;
            this.displayMediaStream = localStream;
            this.isAnswer = false;
            this.sessionType = sessionType;
            this.peerConnection = window._env_.REACT_APP_APP_MODE === 'LAN' ? new RTCPeerConnection() : new RTCPeerConnection({
                iceServers: [{
                        //urls: 'stun:3.237.88.248:443'
                        urls: [
                            `stun:${window._env_.REACT_APP_TURN_URL}:3478`
                            /*"stun:bn-turn1.xirsys.com"
                            "stun:stun.l.google.com:19302"
                            'stun:stun1.l.google.com:19302',
                            'stun:stun2.l.google.com:19302',
                            'stun:stun3.l.google.com:19302',
                            'stun:stun4.l.google.com:19302',
                            'stun:stunserver.org',
                            'stun:3.237.88.248:443' */
                        ]
                    }, 
                    {   
                        username: (turn_username || ''),
                        credential: (turn_password || ''),
                        urls: [
                            `turn:${window._env_.REACT_APP_TURN_URL}:3478?transport=tcp`,  
                            `turn:${window._env_.REACT_APP_TURN_URL}:3478?transport=udp`,  
                            `turn:${window._env_.REACT_APP_TURN_URL}:5349?transport=tcp`,
                            `turn:${window._env_.REACT_APP_TURN_URL}:5349?transport=udp`
                        ]
    
                        /* username: "bLr98ooKD_tTcEe6KIM6yG7SzYjMnJRXgD-zrijRAYZ1N4uWUHZu5J2luUP1YbNaAAAAAF-LRXdjYXJlNGQ=",
                        credential: "b2a0ac04-10ae-11eb-95aa-0242ac140004",
                        urls: [
                            "turn:bn-turn1.xirsys.com:80?transport=udp",
                            "turn:bn-turn1.xirsys.com:3478?transport=udp",
                            "turn:bn-turn1.xirsys.com:80?transport=tcp",
                            "turn:bn-turn1.xirsys.com:3478?transport=tcp",
                            "turns:bn-turn1.xirsys.com:443?transport=tcp",
                            "turns:bn-turn1.xirsys.com:5349?transport=tcp"
                        ] */
                    }],
                sdpSemantics : "unified-plan" //TP-3624
                //bundlePolicy: "max-bundle"
                //constraints: {"optional": [ {'googIPv6': 'false'} ] }
                });//MB2-579
            this.senders = [];
            this.addlocalStream(localStream);
            this.peerConnection.onicecandidate = this.handleICECandidateEvent;
            this.peerConnection.onsignalingstatechange = this.handleSignalStateChangeEvent;
            this.peerConnection.oniceconnectionstatechange = this.handleICEConnectionStateChangeEvent;
            this.peerConnection.onconnectionstatechange = this.handleConnectionStateChangeEvent;
            this.peerConnection.onnegotiationneeded = this.handleNegotiationNeededEvent; //TP-5760
            this.peerConnection.ontrack = gotRemoteTrack;
            this.isScreenCast = false; //TP-5760
            this.stopScreenCast = false;
            this.failedPeerTimer = null; //TP-4522
            //this.peerConnection.addEventListener("track", gotRemoteTrack, false);
        }catch(error){
            printConsole(`Webrtc connection error, ${error}`)
        }
    }

    addlocalStream = (localStream) => {
        printConsole(`local stream added================>`)
        printConsole(localStream.getTracks())
        localStream.getTracks().forEach(track => this.senders.push(this.peerConnection.addTrack(track, localStream)));
    }

    tallySenders = () => {
        let results = {
          transportMissing: 0,
          connectionPending: 0,
          connected: 0,
          closed: 0,
          failed: 0,
          unknown: 0
        };
      
        let senderList = this.peerConnection.getSenders();
        senderList.forEach((sender) => {
          let transport = sender.transport;
      
          if (!transport) {
            results.transportMissing++;
          } else {
            switch(transport.state) {
              case "new":
              case "connecting":
                results.connectionPending++;
                break;
             case "connected":
                results.connected++;
                break;
             case "closed":
                results.closed++;
                break;
             case "failed":
                results.failed++;
                break;
             default:
                results.unknown++;
                break;
            }
          }
        });
        return results;
      }

    handleICECandidateEvent = event => {
        if (event.candidate) {
            this.sessionSignal.sendWebrtcIceCandidate({data:{
                candidate: event.candidate,
                to: this.to,
                groupId: this.groupId
            }});
        }
    };

    handleICEConnectionStateChangeEvent = event => {
        printConsole(`In ice connection state change ${this.peerConnection.iceConnectionState}`)
        switch (this.peerConnection.iceConnectionState) {
            case "closed":
            case "disconnected":
                // this.close();
                this.stopFailedTimer(); //TP-4522
                break;
            case "failed":
                this.startFailedTimer(); //TP-4522
                break;
            default:
                this.stopFailedTimer(); //TP-4522
        }
    };

    handleConnectionStateChangeEvent = event => {
        //TP-5733
        this.connectionTimer = setTimeout(() => {
            printConsole(`In connection state change ${this.peerConnection.connectionState}`)
            this.onConnectionStateChange({to: this.to, connectionState: this.peerConnection.connectionState});
            switch (this.peerConnection.connectionState) {
                case "connected":
                    this.stopFailedTimer(); //TP-4522
                    break;
                case "closed":
                    this.stopFailedTimer(); //TP-4522
                    this.close();
                    break;
                //TP-4064 -- commented out to resolve this issue 
                case "failed":
                    this.startFailedTimer(); //TP-4522
                    break;
                default:
                    this.stopFailedTimer(); //TP-4522
            }
            clearTimeout(this.connectionTimer);
        }, 10);
    };

    //TP-5760 -- New event handler for the "onnegotiationneeded" event of the peer-connection
    handleNegotiationNeededEvent = async event => {
        if (this.isScreenCast === true) {
            printConsole(`***********On negotiation needed event hit (start screencast)**************`);
            await this.peerConnection.setLocalDescription();
            this.screenTimer = setTimeout(() => {
                printConsole(`attempting local description, ${JSON.stringify(this.peerConnection.localDescription)}`);
                printConsole(`state, ${this.peerConnection.signalingState}`);
                printConsole(`Sending screen share offer to", ${this.to}`);
                this.sessionSignal.sendWebrtcConnectionOffer({data:{
                    offer: this.peerConnection.localDescription,
                    to: this.to,
                    groupId: this.groupId,
                    stream_id: this.localStream.id,
                    media_stream_meta_data: this.metaJson //TP-2578 
                }});
                //TP-5784 -- Added more console logs only for Dev
                this.peerConnection.getSenders().forEach(sender => {
                    printDevConsole(sender);
                })
                clearTimeout(this.screenTimer);
            }, 50);
                
            this.isScreenCast = false;
        } else if (this.stopScreenCast === true) {
            //TP-4861
            printConsole(`***********On negotiation needed event hit (start screencast)**************`);            
            await this.peerConnection.setLocalDescription()
            this.stopScreenTimer = setTimeout(() => {
                printConsole(`attempting local description, ${JSON.stringify(this.peerConnection.localDescription)}`);
                printConsole(`state, ${this.peerConnection.signalingState}`);
                printConsole(`Sending stop screen share offer to", ${this.to}`);
                this.sessionSignal.sendWebrtcConnectionOffer({data:{
                    offer: this.peerConnection.localDescription,
                    to: this.to,
                    groupId: this.groupId,
                    stream_id: this.localStream.id,
                    media_stream_meta_data: this.metaJson //TP-2578
                }});
                clearTimeout(this.stopScreenTimer);
            }, 50);
            this.stopScreenCast = false;
        }
    }

    handleSignalStateChangeEvent = () => {
        printConsole(`==========================================>In signal state change ${this.peerConnection.signalingState}`)
    };

    /**TP-5320 -- removeStream & addStream of RTC peerconnection APIs have been deprecated
    * hence replacing them with .removeTrack() & .addTrack() APIs
    */
    offerConnectionWhileScreenShare = ({mediaTracks, displayMediaStream, metaJson}) => {
        printConsole(`displayMediaStream in offerConnectionWhileScreenShare `);
        this.displayMediaStream = displayMediaStream;
        //console.log(displayMediaStream.getTracks());
        //this.peerConnection && this.peerConnection.removeStream(this.localStream);
        this.peerConnection && this.peerConnection.getSenders().forEach(sender => {
            printConsole(`remove sender tracks ${JSON.stringify(sender)}`);
            this.peerConnection.removeTrack(sender);                     
            this.senders = this.senders.filter(send => send !== sender);
        });
        this.senders =[];
        //this.peerConnection && this.peerConnection.addStream(displayMediaStream);
        this.peerConnection && mediaTracks.forEach((track) => {
            printConsole(track);
            this.senders.push(this.peerConnection.addTrack(track, displayMediaStream));            
            this.peerConnection.getSenders().forEach(sender => {
                printConsole(`sender tracks ${JSON.stringify(sender.track)}`);
            });
        });
        //console.log(displayMediaStream.getTracks());
        this.peerConnection && this.peerConnection
            .createOffer({
                iceRestart:false,
                offerToReceiveVideo: true,
                offerToReceiveAudio: true
            })
            .then(async (offer) => {
                printConsole(`attempting local description, ${JSON.stringify(offer)}`);
                printConsole(`state, ${this.peerConnection.signalingState}`);
                this.peerConnection.setLocalDescription(offer)  //TP-5760
                return offer;
            })
            .then((offer) => {
                printConsole(`Sending screen share offer to", ${this.to}`);
                //TP-3303
                if (metaJson === '') {
                    this.sessionSignal.sendWebrtcConnectionOffer({data:{
                        offer,
                        to: this.to,
                        groupId: this.groupId,
                        stream_id: displayMediaStream.id
                    }});
                } else {
                    this.sessionSignal.sendWebrtcConnectionOffer({data:{
                        offer,
                        to: this.to,
                        groupId: this.groupId,
                        stream_id: displayMediaStream.id,
                        media_stream_meta_data: metaJson /**TP-2578 */
                    }});
                }
            })
            .catch(err => {
                printConsole("Error in offerConnectionWhileScreenShare");
                console.error(err);
            });
    }

    //TP-2578
    /** TP-5320 -- removeStream & addStream of RTC peerconnection APIs have been deprecated
     * hence replacing them with .removeTrack() & .addTrack() APIs
     */
    offerConnectionWhileScreenSharePilot = ({displayTrack, displayMediaStream, metaJson}) => {
        //TP-5733
        printConsole(`displayMediaStream in offerConnectionWhileScreenSharePilot `);
        this.displayMediaStream = displayMediaStream;
        const userMediaStream = this.localStream;
        //TP-5760
        if (displayTrack) {
            printConsole(displayTrack);
            //TP-5784 -- Remove all the existing tracks before adding the new Screencast track
            this.peerConnection && this.peerConnection.getSenders().forEach(sender => {
                printConsole(`remove sender tracks `);
                printConsole(sender.track);
                this.peerConnection.removeTrack(sender);
                    
            });
            this.isScreenCast = true;
            //TP-5784 -- Add the Screencast track first and then the other tracks
            this.peerConnection && this.peerConnection.addTrack(displayTrack, this.localStream);    
            this.peerConnection && userMediaStream.getTracks().forEach((track) => {
                this.peerConnection.addTrack(track, this.localStream);
            });             
        }
    }

    //TP-2578
    /** TP-5320 -- removeStream & addStream of RTC peerconnection APIs have been deprecated
     * hence replacing them with .removeTrack() & .addTrack() APIs
     */
    offerConnectionWhileStopScreenSharePilot = ({userMediaStream, metaJson}) => {
        //TP-5733
        printConsole(`displayMediaStream in offerConnectionWhileStopScreenShare `);
        printConsole(this.displayMediaStream.getTracks());
        //this.localStream = userMediaStream; TP-4861
        printConsole(this.localStream.getTracks());
        //TP-4861    
        this.peerConnection && this.peerConnection.getSenders().forEach(sender => {
            printConsole(`remove sender tracks `);
            printConsole(sender.track);
            this.peerConnection.removeTrack(sender);
                
        });
        this.senders =[];//TP-5882, TP-5881
        //TP-4861
        this.stopScreenCast = true;
        this.peerConnection && userMediaStream.getTracks().forEach((track) => {
            this.peerConnection.addTrack(track, this.localStream);
        }); 
    }

    /** TP-5320 -- removeStream & addStream of RTC peerconnection APIs have been deprecated
     * hence replacing them with .removeTrack() & .addTrack() APIs
     */
    offerConnectionWhileStopScreenShare = ({audioMediaStream}) => {
        printConsole(`displayMediaStream in offerConnectionWhileStopScreenShare }`);
        printConsole(this.displayMediaStream.getTracks());
        this.localStream = audioMediaStream;
        printConsole(this.localStream.getTracks());
        //this.peerConnection && this.peerConnection.removeStream(this.displayMediaStream);
        this.peerConnection && this.senders.forEach(sender => {
            printConsole(`remove sender tracks ${JSON.stringify(sender.track)}`);
            this.peerConnection.removeTrack(sender);                     
            //this.senders = this.senders.filter(send => send !== sender);
        });
        this.senders =[];
        //console.log(this.senders);
        //this.peerConnection && this.peerConnection.addStream(this.localStream);
        this.peerConnection && this.localStream.getTracks().forEach((track) => {
            this.senders.push(this.peerConnection.addTrack(track, this.localStream));
            this.senders.forEach(sender => {
                printConsole(`sender tracks ${JSON.stringify(sender.track)}`);
            });
        });
        this.peerConnection && this.peerConnection
            .createOffer({
                iceRestart:true,
                offerToReceiveVideo: (!this.user.isexpert) ? false : true,
                offerToReceiveAudio: true
            })
            .then(async (offer) => {
                printConsole(`attempting local description, ${JSON.stringify(offer)}`);
                printConsole(`state, ${this.peerConnection.signalingState}`);
                await this.peerConnection.setLocalDescription(new RTCSessionDescription(offer))
                return offer;
            })
            .then((offer) => {
                printConsole(`Sending stop screen share offer to", ${this.to}`);
                this.sessionSignal.sendWebrtcConnectionOffer({data:{
                    offer,
                    to: this.to,
                    groupId: this.groupId,
                    stream_id: this.localStream.id
                }}); 
            })
            .catch(err => {
                printConsole("Error in offerConnectionWhileStopScreenShare");
                console.error(err);
            });
    }

    offerConnection = () => {
        printConsole(`this.localstream in offerConnection ${this.localStream}`);
        printConsole(this.localStream);
        printConsole(this.sessionType);
        this.isAnswer = false;
        this.peerConnection && this.peerConnection
            .createOffer({
                iceRestart:true,
                offerToReceiveVideo: /*TP-3052 & TP-3156 */(this.sessionType === true) ? true : (!this.user.isexpert) ? false : true,
                offerToReceiveAudio: true
            })
            .then(async (offer) => {
                this.offer = offer;
                printConsole(`attempting local description, ${JSON.stringify(offer)}`);
                printConsole(`state, ${this.peerConnection.signalingState}`);
                await this.peerConnection.setLocalDescription(new RTCSessionDescription(offer))
                return offer;
            })
            .then((offer) => {
                printConsole(`Sending offer to, ${this.to}`);
                this.sessionSignal.sendWebrtcConnectionOffer({data:{
                    offer,
                    to: this.to,
                    groupId: this.groupId,
                    stream_id: this.localStream.id
                }});
            })
            .catch(err => {
                printConsole("Error in handleNegotiationNeededEvent");
                console.error(err);
            });
    };

    /** TP-5320 -- removeStream & addStream of RTC peerconnection APIs have been deprecated
     * hence replacing them with .removeTrack() & .addTrack() APIs
     */
    connectionOfferWhileScreenShare = ({mediaTracks, displayMediaStream}) => {
        printConsole(`this.localstream in connectionOfferWhileScreenShare ${JSON.stringify(this.localStream)}`);
        //this.isAnswer = true;
        printConsole(`displayMediaStream in connectionOfferWhileScreenShare `);
        this.displayMediaStream = displayMediaStream;
        //console.log(displayMediaStream.getTracks());
        this.peerConnection && this.peerConnection.getSenders().forEach(sender => {
            printConsole(`remove sender tracks ${JSON.stringify(sender)}`);
            this.peerConnection.removeTrack(sender);
            //this.senders = this.senders.filter(send => send !== sender);
        });
        this.senders =[];
        //console.log(this.senders);
        //this.peerConnection && this.peerConnection.removeStream(this.localStream);
        //this.peerConnection && this.peerConnection.addStream(displayMediaStream);
        this.peerConnection && mediaTracks.forEach((track) => {
            printConsole(track);
            this.senders.push(this.peerConnection.addTrack(track, displayMediaStream));
            this.peerConnection.getSenders().forEach(sender => {
                printConsole(`sender tracks ${JSON.stringify(sender.track)}`);
            });
        });
        const {offer} = this;
        this.peerConnection && this.peerConnection
            .setRemoteDescription(offer)
            .then(() => this.peerConnection.createAnswer())
            .then(async (answer) => {
                printConsole(`attempting set local description remote, ${JSON.stringify(answer)}`);
                printConsole(`state, ${this.peerConnection.signalingState}`);
                await this.peerConnection.setLocalDescription(new RTCSessionDescription(answer));
                return answer;
            })
            .then((answer) => {
                printConsole(`Sending screen share answer to", ${this.to}`);
                this.sessionSignal.sendWebrtcConnectionAnswer({data:{
                    answer:answer,
                    to: this.to,
                    groupId: this.groupId,
                    stream_id: displayMediaStream.id
                }});
            })
            .catch(err => {
                printConsole("Error in connectionOfferWhileScreenShare");
                console.error(err);
            });
    }

    /** TP-5320 -- removeStream & addStream of RTC peerconnection APIs have been deprecated
     * hence replacing them with .removeTrack() & .addTrack() APIs
     */
    connectionOfferWhileStopScreenShare = ({audioMediaStream}) => {
        printConsole(`displayMediaStream in connectionOfferWhileStopScreenShare }`);
        printConsole(this.displayMediaStream.getTracks());
        this.localStream = audioMediaStream;
        printConsole(this.localStream.getTracks());
        this.peerConnection && this.peerConnection.getSenders().forEach(sender => {
            printConsole(`remove sender tracks ${JSON.stringify(sender)}`);
            this.peerConnection.removeTrack(sender);
            //this.senders = this.senders.filter(send => send !== sender);
        });
        this.senders =[];
        //console.log(this.senders);
        //this.peerConnection && this.peerConnection.removeStream(this.displayMediaStream);
        //this.peerConnection && this.peerConnection.addStream(this.localStream);
        this.peerConnection && this.localStream.getTracks().forEach((track) => {
            printConsole(track);
            this.senders.push(this.peerConnection.addTrack(track, this.localStream));
            this.peerConnection.getSenders().forEach(sender => {
                printConsole(`sender tracks ${JSON.stringify(sender.track)}`);
            });
        });
        const {offer} = this;
        this.peerConnection && this.peerConnection
            .setRemoteDescription(offer)
            .then(() => this.peerConnection.createAnswer())
            .then(async (answer) => {
                printConsole(`attempting set local description remote, ${JSON.stringify(answer)}`);
                printConsole(`state, ${this.peerConnection.signalingState}`);
                await this.peerConnection.setLocalDescription(new RTCSessionDescription(answer));
                return answer;
            })
            .then((answer) => {
                printConsole(`Sending stop screen share answer to", ${this.to}`);
                this.sessionSignal.sendWebrtcConnectionAnswer({data:{
                    answer:answer,
                    to: this.to,
                    groupId: this.groupId,
                    stream_id: this.localStream.id
                }});
            })
            .catch(err => {
                printConsole("Error in connectionOfferWhileStopScreenShare");
                console.error(err);
            });
    }

    connectionOffer = (data) => {
        printConsole(`this.localstream in connectionOffer ${JSON.stringify(this.localStream)}`);
        this.isAnswer = true;
        const {offer} = data;
        this.offer = offer;
        this.peerConnection && this.peerConnection
            .setRemoteDescription(offer)
            .then(() => this.peerConnection.createAnswer())
            .then(async (answer) => {
                printConsole(`attempting set local description remote, ${JSON.stringify(answer)}`);
                printConsole(`state, ${this.peerConnection.signalingState}`);
                await this.peerConnection.setLocalDescription(new RTCSessionDescription(answer));                
                return answer;
            })
            .then((answer) => {
                this.sessionSignal.sendWebrtcConnectionAnswer({data:{
                    answer:answer,
                    to: this.to,
                    groupId: this.groupId,
                    stream_id: this.localStream.id
                }});
            })
            .catch(err => {
                printConsole(`Error in connectionOffer`);
                console.error(err);
            });
    };

    connectionAnswer = ({ answer }) => {
        printConsole(`peer has answered`);
        //console.log(answer);
        printConsole(`In connection answer state, ${this.peerConnection.signalingState}`);
        this.peerConnection
            .setRemoteDescription(answer)
            .catch(err => {
                console.error(err);
                printConsole(`Error in connectionAnswer`);
            });
    };

    newICECandidate = ({ candidate }) => {
        printConsole(`${JSON.stringify(candidate)},new candidate`);
        this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate))
        .catch((error) => {
            printConsole(`error while adding ice candidate, ${error}`);
        })
    };

    muteMike = ({flag: muteFlag}) => {
        printConsole(`muting my mike, ${muteFlag}`)
        this.localStream.getAudioTracks()[0].enabled = muteFlag;
    }

    muteVideo = ({flag: muteFlag}) => {
        printConsole(`muting my video, ${muteFlag}`)
        this.localStream.getVideoTracks()[0].enabled = muteFlag;
    }

    addScreenShareTrack = ({displayMediaStream}) => {
        printConsole('adding the screen share track ');
        let mediaTracks = [];
        const {localStream} = this;
        const [videoTrack] = displayMediaStream.getVideoTracks();
        mediaTracks.push(videoTrack); //TP-5320 push the Screen share video track into the array
        const [audioTrack] = localStream.getAudioTracks();
        mediaTracks.push(audioTrack); //TP-5320 push the audio track into the array
        //TP-3303
        let metaJson = '';
        if (this.sessionType === true){
            //TP-2618
            metaJson = {
                id: videoTrack.id,
                type: "screen"
            };
        }
        this.offerConnectionWhileScreenShare({mediaTracks, displayMediaStream, metaJson}); //TP-5320 TP-3303
    }

    addScreenShareTrackPilot = ({displayMediaStream}) => {
        if (displayMediaStream !== null) {
            //TP-5760 TP-4502
            printConsole('adding the Expert audio & Video to the screen share track');
            const [displayTrack] = displayMediaStream.getVideoTracks();
            // A map from MediaStream IDs to arbitrary domain-specific metadata
            //TP-2618
            let metaJson = {
                id: displayTrack.id,
                type: "screen"
            };
            this.metaJson = metaJson;
            printConsole(metaJson);
            
            this.offerConnectionWhileScreenSharePilot({displayTrack, displayMediaStream, metaJson}); //TP-5320
        }
    }

    removeScreenShareTrack = ({audioMediaStream}) => {
        printConsole('removing the screen share track ');
        //if (this.isAnswer === false)
            this.offerConnectionWhileStopScreenShare({audioMediaStream});
        /* else 
            this.connectionOfferWhileStopScreenShare({audioMediaStream}); */
        //this.peerConnection && this.peerConnection.removeTrack(this.senders.find(sender => sender.track && sender.track.kind === 'video'));
        //this.senders.pop(this.senders.find(sender => sender.track.kind === 'video'));
    }
    
    removeScreenShareTrackPilot = ({userMediaStream}) => {
        printConsole('removing the screen share track for Pilot');
        let metaJson = {};
        this.metaJson = metaJson; //TP-4861
        this.offerConnectionWhileStopScreenSharePilot({userMediaStream, metaJson});
    }

    replaceVideoTrackWithScreenShare = ({displayMediaStream}) =>{
        printConsole('changing the video stream with screen share stream')
        this.senders.find(sender => sender.track.kind === 'video').replaceTrack(displayMediaStream.getTracks()[0]);
    }

    replaceScreenTrackWithVideo = () =>{
        printConsole('changing the screen share stream with video stream')
        this.senders.find(sender => sender.track.kind === 'video').replaceTrack(this.localStream.getTracks().find(track => track.kind === 'video'));
    }

    replaceVideoTrackWithAnotherVideo = ({displayMediaStream}) => {
        printConsole('changing the video stream with another camera video feed');
        printConsole(displayMediaStream.getTracks());
        this.senders.find(sender => sender.track.kind === 'video').replaceTrack(displayMediaStream.getTracks().find(track => track.kind === 'video'));
    }

    close = () => {
        const remoteUserEmail = this.to;
        this.peerConnection && this.peerConnection.close();
        this.peerConnection = null;

        this.onClose({remoteUserEmail});
    };

    //TP-4522
    startFailedTimer = () => {
        if (this.failedPeerTimer !== null) {
            printConsole(`Peer connection failed timer is already set`)
        }
        if (this.failedPeerTimer === null) {
            printConsole(`starting the 30 seconds timer when Iceconnection/Peerconnection state "Failed"`);
            this.failedPeerTimer = setTimeout(() => {
                this.stopFailedTimer(true);                
                this.close();
            }, 30000);
        }
    }

    //TP-4522
    stopFailedTimer = (timeout=false) => {
        if (this.failedPeerTimer !== null) {     
            if (timeout === false)
                printConsole(`Stop the 30 second Timer for Peerconnection since state changed back for either Ice-connection/Peerconnection`);
            else
                printConsole(`Stop the Failed peerconnection timer since 30 seconds is over and still connection was not established`);
            clearTimeout(this.failedPeerTimer);
            this.failedPeerTimer = null;
        }
    }
} 

export default WebrtcPeerConnection;