import React from 'react';
import './session.css';
import WebrtcExpert from './WebrtcExpert';
import WebrtcTech from './WebrtcTech';
import ActionRenderer from '../ActionRenderer/ActionRenderer';
import {uploadFile} from 'react-s3';
import { Trans } from '@lingui/macro';
import { I18n } from "@lingui/react";
import SessionSignal from './SessionSignal';
import AuthService from '../AuthService';
import { printConsole } from '../helpers';
import _ from 'lodash'
import Loader from '../Loader/Loader';
//import Reconnecting from '../Reconnecting/Reconnecting';
import {calculateDownloadSpeed, calculateUploadSpeed} from './Latency';
import { UncontrolledTooltip, Modal, ModalBody } from 'reactstrap';//TP-4315
import SessionIcon from './SessionIcon.jsx';

const fileTransferTimeout = 1;
//TP-2830
const clearAnnotateObj = "{\"version\":\"3.6.2\",\"objects\":[{\"originX\":\"left\",\"globalCompositeOperation\":\"source-over\",\"width\":0.01,\"type\":\"path\",\"strokeLineJoin\":\"round\",\"version\":\"3.6.2\",\"backgroundColor\":\"\",\"stroke\":\"grey\",\"transformMatrix\":null,\"scaleY\":1,\"height\":0.01,\"top\":471.49700000000001,\"originY\":\"top\",\"path\":[[\"M\",420.99700000000001,472.99700000000001],[\"L\",421.00299999999999,473.00299999999999]],\"left\":419.49700000000001,\"fillRule\":\"nonzero\",\"fill\":null,\"strokeWidth\":1,\"skewY\":0,\"strokeDashArray\":null,\"strokeLineCap\":\"round\",\"scaleX\":1,\"strokeMiterLimit\":10,\"angle\":0,\"visible\":true,\"flipX\":false,\"flipY\":false,\"opacity\":1,\"shadow\":null,\"clipTo\":null,\"paintFirst\":\"fill\",\"skewX\":0}]}";
let bCancelShare = false;
const fileDialog = require('file-dialog');
let assetsArray = [];
// instantiate the aws sdk
const AWS = require('aws-sdk');
AWS.config.update({
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
});
let webRtcObj = null;
const s3 = new AWS.S3();//instantiate s3
const protocol = window.location.protocol;
const hostname = window.location.host;
const fileUploadaccepted = '.xlsx, .mp3, .mp4, .csv, .mov, .jpg, .pdf, .png, .docx, .bmp, .tga, .exr';//TP-4315
const fileUploadrejected = '.obj, .mtl, .3ds, .fbx';//TP-4315

// function to convert uploaded file to byte array format
// Universal int8 array format
function fileToByteArray(file) {
    return new Promise((resolve, reject) => {
        try {
            let reader = new FileReader();
            let fileByteArray = [];
            reader.readAsArrayBuffer(file);
            reader.onloadend = (evt) => {
                if (evt.target.readyState == FileReader.DONE) {
                    let arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (let byte of array) {
                        fileByteArray.push(byte);
                    }
                }
                resolve(fileByteArray);
            }
        }
        catch (e) {
            reject(e);
        } 
    })
}

// function to convert uploaded filebuffer to Base64 URL format
// Uint8array format
function arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};


class WebrtcSession extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            sessionCmd: '', selectedAction: '', localAssets: [], externalAssets: [], fileLoading: false, showCancel: false,
            fileNameArr: [], filePathArr: [], removeArtifacts: false, assetRecipient: '',
            rotationChanges: '', opacityChanges: '', g_maxDivId : 'allVideos', groupInfo: [], fileData: null, setStartAssetsShare: false,
            annotation: {
                data : {
                    dataString: '',
                    dataPointer: '', //TP-2474
                    dataObj: null
                },
                recipient: ''
            },
            localRc: false, primaryExpert: '', bRemoteCapture: false, rcImgObj: '',
            bAnnotate: false, selectedCamera: '', selectedTelestrate: '',
            remoteChanges: {data: '', format: '', recipient: '', location: '', senderInfo: '' },rcStart: false,
            isHeaderFileShare: false, isOneToOneFileShare: false,
            maxDivParticipant: '', shapesSetting: '', bSelectShape: false, pencolor: 'red',
            socketId: '', hasMaxDivControls: false, isMaxDivSS: false, isSideBarSS: false, 
            sendExpertSS: false, sSParticipant:null,  participantList:null,
            downLoadSpeed: null, uploadSpeed: null, cameraLoading: false, shareFileName: '', is3DFile: false, isAudioFile: false,
            isPDFFile: false, redraw: false, fileName: '', isReconnecting: false,
            autoReconnectTimer: 0, enableGridView: false, isAnnotateOnWtRC: false, isAnnotateOnWtExpertSS: false, rcAnnotateStart: false,
            rotationArray: null, showControlRequestDialog: false, /**TP-1313*/ setBufferColorShape: false, setColorShape: false, /**TP-2377*/
            onResizeWPointer: false, /**TP-2560 */ annotateLoader: false, stopPointerWhenControlTransfer: false, /**TP-2824 */
            onResizeWAnnotate: false, /**TP-2959 */ myVideoEnable: true, /**TP-3737*/ triggershowRC: false, /**TP-4261*/ showError: false, /**TP-4315 */
            reRenderCanvas: false, /**TP-4585*/ isUndoDisabled: true, /**TP-4752*/ redrawPassiveCanvas: false, /**TP-4772*/ redrawPassiveWhenResized: false, /**TP-4930*/
            redrawTechCanvas: false, /**TP-4769*/ onResizeWText: false, /**TP-5073*/ cameraNotDetected: false, /**TP-5429*/ isCancelRCLoading: false, /**TP-5389*/
            isRCtriggered: false, /**TP-6065*/ closeRC: false /**TP-6170*/
        };
        this.authService = new AuthService();
        this.sessionSignal = new SessionSignal({
            userId: props.user.id, 
            firstName: props.user.firstname, 
            lastName: props.user.lastname, 
            email: props.user.email, 
            isexpert: props.user.isexpert
        },true, props.selectedGroupId);
        this.config = {
            bucketName: process.env.REACT_APP_S3_BUCKET,
            dirName: process.env.REACT_APP_S3_DIR, /* optional */
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        };  
        this.decodedUser = this.authService.getDecodedUser()
        webRtcObj = this;
    }

    componentDidMount() {
        const isSmallFormFactorDevice = this.checkSmallScreenDevice();
        this.setState({ isSmallFormFactorDevice });
        const {user} = this.props;
        if (user.isexpert)
            this.setState({cameraLoading: true});
        this.sessionSignal.listenerWebsocket(this.subscribeToGrpSignal);
        this.onCommandChange();
        this.props.updateShowHideHeaderActionButtons(true);
        this.setState({socketId: this.authService.socket.id})
        this.decodedUser && this.decodedUser.showuploadspeed && this.initcheckDownloadUploadSpeed();//Mb2-588
        // T32-155 -- During RC/Annotation turned on Screen capture
        // resizing handler for Primary expert screen
        window.addEventListener("resize", () => {
            const canvas = document.getElementById('canvas');
            if (this.props.user.isexpert && this.state.hasMaxDivControls && canvas !== '' && canvas !== null && canvas !== undefined) {
                if (this.state.bAnnotate){
                    this.setState({ onResizeWAnnotate: true }); //TP-2959
                    //TP-2377
                    this.setState({ setBufferColorShape: true }, () => { 
                        /**TP-5073 -- When resized while Text Annotation is ongoing*/
                        if (this.state.shapesSetting !== '' && this.state.shapesSetting.isTextActive === true) {
                            this.setState({ onResizeWText: true }, () => {
                                this.setState({bAnnotate: false}, () => {
                                    this.setState({ setColorShape: true }, () => {                                 
                                        this.setState({ setColorShape: false }, () => {
                                            this.setState({ bAnnotate: true }, () => {
                                                this.setState({ onResizeWText: false });                                                
                                            });
                                        });                             
                                    })
                                    
                                })
                                this.setState({ setBufferColorShape: false});
                            })                                            
                        } else {
                            this.setState({bAnnotate: false}, () => {
                                this.setState({ setColorShape: true }, () => {                                 
                                    this.setState({ setColorShape: false }, () => {
                                        this.setState({ bAnnotate: true }, () => {
                                            //TP-2560 -- Use cases 2 & 3
                                            if (this.state.shapesSetting !== '' && this.state.shapesSetting.isPointerActive === true) {
                                                this.setState({ onResizeWPointer: true }, () => {
                                                    this.setState({ onResizeWPointer: false });
                                                })                                            
                                            }
                                        });
                                    });                             
                                })
                                
                            })
                            this.setState({ setBufferColorShape: false});
                        }
                    })                    
                } 
            } else if (this.props.user.isexpert && !this.state.hasMaxDivControls && canvas !== '' && canvas !== null && canvas !== undefined) {
                //TP-4930 -- Handling of Passive expert's Canvas whenever screen is resized in Annotate/RC mode
                this.setState({ redrawPassiveWhenResized: true }, () => {
                    this.setState({ redrawPassiveWhenResized: false });
                })
            }
        });
    }

    componentWillUnmount = () => {
        const {user} = this.props;
        this.props.updateShowHideHeaderActionButtons(false);
        this.sessionSignal.removeWebsocket(this.subscribeToGrpSignal);
        this.decodedUser && this.decodedUser.showuploadspeed && this.stopcheckDownloadUploadSpeed();//MB2-588
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.sessionCmd !== this.state.sessionCmd){
            this.onCommandChange();
        }
        if (prevState.isSideBarSS !== this.state.isSideBarSS && this.state.bAnnotate === true && this.state.isSideBarSS === true) {
            printConsole("Annotations was already on when Side Bar Screen cast is clicked");
            this.setState({ isAnnotateOnWtExpertSS: true }, () => {
                this.setState({ isAnnotateOnWtExpertSS: false }); //TP-1283 & TP-1365
            })
        } else if (prevState.isMaxDivSS !== this.state.isMaxDivSS && this.state.bAnnotate === true && this.state.isMaxDivSS === true) {
            printConsole("Annotations was already on when Max-div Screen cast is clicked");
            this.setState({ isAnnotateOnWtExpertSS: true }, () => {
                this.setState({ isAnnotateOnWtExpertSS: false });//TP-1283 & TP-1365
            })
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.sessionCmd!==prevState.sessionCmd){
            return { sessionCmd: nextProps.sessionCmd };
        }else{
            return null;
        }
    }

    /**
     * This method will be triggered when there is a signal from a expert.
     */
    subscribeToGrpSignal = ({ type, data, unique_id, assetData, to, from, recipientSocketId }) => {
        if(recipientSocketId && (this.state.socketId !== recipientSocketId)){
            return;
        }
        //TP-2523
        //this.timer = setTimeout(()=> { 
            //printConsole("*************Receiving Signal on side **********");
            if (type === "SYNC_EXPERTS" && data.reason === "show_action") {
                printConsole({type, data, from, to})
            } /**TP-2549 */ else if (type === "ANNOTATE"){ 
                printConsole({type, data, unique_id, to, from});
            } else if (type !== "USER_SPEAKER_ON") {
                printConsole({ type, data, assetData, to, from, recipientSocketId });
                printConsole(`at ${performance.now()}`) //TP-4275
            }
            const updatedChanges = {};
            const { rcStart, rcImgObj, expertShareScreenMaxDiv, isLoading, bRemoteCapture, urlObj, hasMaxDivControls, maxDivParticipant } = this.state;
            //printConsole(expertShareScreenMaxDiv);
            const { dataString, dataObj, dataPointer /**TP-2474 */ } = this.state.annotation.data;
            //printConsole('webrtc session listner', from.email, type, to);
            if (type === 'SHARE_ARTIFACTS' && assetData) printConsole(`${assetData.format}, ${assetData.sequence}, ${assetData.total}, ${assetData.name}`)
            switch (type) {
                case 'ARTIFACTS':
                    if (expertShareScreenMaxDiv) break; 
                    updatedChanges.externalAssets = data && typeof data === 'string' && JSON.parse(data);
                    updatedChanges.removeArtifacts = false;
                    updatedChanges.assetRecipient = to;
                    updatedChanges.selectedAction = 'shareAsset';
                    break;
                case 'SHARE_ARTIFACTS':
                    // New implementation --
                    if (expertShareScreenMaxDiv) break; 
                    if (parseInt(assetData.total) > 0) {
                        assetsArray.push(assetData);
                    } if (/* parseInt(assetData.sequence) === parseInt(assetData.total)-1 || */ assetsArray.length === parseInt(assetData.total)) {
                        updatedChanges.isOneToOneFileShare = false;//TP-2378
                        updatedChanges.isHeaderFileShare = true;
                        updatedChanges.externalAssets = assetsArray;
                        updatedChanges.removeArtifacts = false;
                        updatedChanges.assetRecipient = to;
                        updatedChanges.shareFileName = assetsArray[0].name+'.'+assetsArray[0].format;
                        const [objFile] = assetsArray.filter(e => e.format === 'obj' || e.format === '3ds' || e.format === '3DS'|| e.format === 'FBX' || e.format === 'fbx');
                        if (objFile) updatedChanges.is3DFile = true;
                        else updatedChanges.is3DFile = false;
                        const [pdfFile] = assetsArray.filter(e => e.format === 'pdf' || e.format === 'PDF');
                        if (pdfFile) updatedChanges.isPDFFile = true;
                        else updatedChanges.isPDFFile = false;
                        const [audioFile] = assetsArray.filter(e => e.format === 'mp3');
                        if (audioFile) updatedChanges.isAudioFile = true;
                        else updatedChanges.isAudioFile = false;
                        if (hasMaxDivControls === true) updatedChanges.redraw = true;
                        updatedChanges.selectedAction = 'shareAsset';
                        assetsArray = [];
                    } 
                    //printConsole(updatedChanges.externalAssets);
                    break;
                case 'ROTATION':
                    if (expertShareScreenMaxDiv) break;
                    updatedChanges.rotationChanges = data;
                    updatedChanges.rotationArray = data;
                    updatedChanges.selectedAction = 'shareAsset';
                    break;
                case 'OPACITY':
                    if (this.props.user.isexpert) {
                        if (expertShareScreenMaxDiv) break;
                        updatedChanges.opacityChanges = data;
                        updatedChanges.selectedAction  = 'shareAsset';
                    }
                    break;
                case 'REMOVE_ARTIFACTS': 
                    break; /**TP-4261*/
                    if (expertShareScreenMaxDiv) break;
                    updatedChanges.isOneToOneFileShare = false;
                    updatedChanges.removeArtifacts = data;
                    updatedChanges.rotationChanges = '';
                    updatedChanges.rotationArray = null;
                    break;
                case 'ANNOTATE': 
                    //TP-4599
                    if (this.props.user.isexpert === true && maxDivParticipant.isPaused === true) break;
                    if (expertShareScreenMaxDiv) break;
                    if(_.isEmpty(data)){
                        updatedChanges.selectedTelestrate = '';
                        //updatedChanges.bAnnotate = false;//MB2-489
                    }else{
                        //TP-4528
                        if (this.state.isHeaderFileShare === true || this.props.user.isexpert === true) {
                            //TP-4646 -- For Passive expert first close the fileviewer then go ahead with drawing the canvas
                            this.closeFileViewer();
                        }
                        //TP-2523
                        updatedChanges.annotation = {data : {
                                dataString: (typeof data === 'string' && data.includes(":::") === false && data.includes("px") === true /**TP-5162*/) ? data: (dataString === clearAnnotateObj) ? "" : dataString,
                                dataPointer: (typeof data === 'string' && data.includes(":::") === true ) ? data: dataPointer, /**TP-2474 */
                                dataObj: (typeof data === 'object') ? data: (/*TP-5162*/ typeof data === 'string' && data.includes(":::") === false && data.includes("px") === false) ? JSON.parse(data) : dataObj}, recipient : to };
                        updatedChanges.selectedTelestrate = 'telestrate';
                        //updatedChanges.bAnnotate = true;//MB2-489
                    }
                    break;
                case 'START_LOCAL_RC':
                    //TP-4563
                    if (this.state.hasMaxDivControls === true && (this.state.isHeaderFileShare === true || this.state.isOneToOneFileShare === true)) {
                        this.closeFileViewer();
                    }
                    break;
                case 'REMOTECLICK':
                    //TP-4528
                    if (this.state.isHeaderFileShare === true) {
                        this.closeFileViewer();
                    }
                    //TP-6065
                    this.setState({ isRCtriggered: true }, () => {
                        //TP-4683
                        if ((this.props.audio_mode === "tech2Tech" || (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled")) /* && maxDivParticipant.email === this.props.user.email */) {
                            this.takePicTimer = setTimeout(() => {
                                updatedChanges.remoteChanges = {data, recipient:to, senderInfo: from };                                   
                                this.captureCloudRcImg({sender: from});
                                clearTimeout(this.takePicTimer);
                            }, 2000);
                        } else {
                            updatedChanges.remoteChanges = {data, recipient:to, senderInfo: from };                                   
                            this.captureCloudRcImg({sender: from});
                        }
                    })
                    break;
                case 'REMOTECLICKUPLOADED':
                    if (this.props.user.isexpert) {
                        updatedChanges.remoteChanges = {assetData: data, recipient: to, senderInfo: from};
                        this.timer = setTimeout(()=> {
                            this.onRcImgUploaded({assetData: data, recipient: to, senderInfo: from});
                            clearTimeout(this.timer);
                        }, fileTransferTimeout);
                    }   
                    break;
                case 'REMOTECLICKDATA':
                    //console.log("bRemoteCapture --", bRemoteCapture);
                    if (this.props.user.isexpert && bRemoteCapture === true && assetData && assetData.data && assetData.format) {
                        updatedChanges.remoteChanges = {assetData, recipient:to, senderInfo: from };
                        this.timer = setTimeout(()=> {
                            this.onRcImgUploaded({assetData, recipient:to, senderInfo: from });
                            clearTimeout(this.timer);
                        }, fileTransferTimeout);
                    } else if (this.props.user.isexpert && bRemoteCapture === false) {
                        //TP-2540 -- Clear out the Canvas if this signal still is received even after RC is cancelled by Expert
                        const parentel = document.getElementById("canvasDiv");
                        if (parentel !== null && parentel !== undefined) {
                        let first = parentel.firstElementChild; 
                            while (first) { 
                                first.remove(); 
                                first = parentel.firstElementChild; 
                            } 
                        }
                    }                  
                    break;
                case 'REMOTECAPTUREANNOTATE':
                    if(data){
                        if(rcStart === false && rcImgObj != '' ){
                            updatedChanges.rcStart = true;
                        }
                        updatedChanges.selectedCamera = 'remoteCapture';
                        updatedChanges.isLoading = false;
                        updatedChanges.bAnnotate = true;
                        updatedChanges.pencolor = "red";
                        updatedChanges.rcAnnotateStart = true;                    
                        updatedChanges.shapesSetting = {
                            isFreeDrawing: false, //TP-2586
                            isTextActive: false, //TP-4695
                            isRectActive: false,
                            isCircleActive : false,
                            isArrowActive: false,
                            isPointerActive: false, /**TP-2475 */
                            isErasorActive: false, /**TP-2474 */
                            isUndoActive: false /**TP-4696*/
                        }
                        // When expert RC going on the local rc has to be red on tech side, MB2-221
                        updatedChanges.localRc = true;
                    }else{
                        //TP-6065
                        this.setState({ isRCtriggered: false }, ()=> {
                            //if(rcImgObj !== '' && urlObj !== ''){
                                updatedChanges.annotation = { data, recipient:to };
                                updatedChanges.rcStart = false;
                                updatedChanges.rcAnnotateStart = false;  
                                updatedChanges.rcImgObj = '';
                                updatedChanges.urlObj = null;
                                updatedChanges.bRemoteCapture = false;
                                updatedChanges.bAnnotate = false;//MB2-489                        
                                updatedChanges.localRc = false;
                                updatedChanges.fileName = ''; 
                                //TP-5492
                                if (this.state.isLoading === true)  
                                    updatedChanges.isLoading = false;                     
                            //}
                            updatedChanges.selectedAction = '';
                            updatedChanges.selectedCamera = '';
                            updatedChanges.selectedTelestrate = '';
                            if (isLoading === true) updatedChanges.isLoading = false;
                            const {participantList} = this.state;
                            //TP-5607, TP-4483
                            if (!this.props.user.isexpert && participantList && participantList.length > 0) {
                                participantList.forEach(p => {
                                    if (this.props.audio_mode === "tech2Tech")
                                        this.sessionSignal.sendSignalVideoPaused(false, "", p.email, "png", "RC_ONGOING");
                                    else {
                                        if (p.isexpert)
                                            this.sessionSignal.sendSignalVideoPaused(false, "", p.email, "png", "RC_ONGOING");
                                    }
                                })
                            }
                        })
                    }
                    break;
                case "SYNC_EXPERTS":
                    this.processSignalSyncExperts({from, data});                
                    break;
                case "EXPERT_RC_CLICKED":
                    //TP-4599
                    if (this.props.user.isexpert === true && maxDivParticipant.isPaused === true) break;
                    //TP-4528
                    if (this.state.isHeaderFileShare === true) {
                        this.closeFileViewer();
                    }
                    //TP-6170
                    this.passRCTimer = setTimeout(() => {
                        console.log(this.state.urlObj, this.state.rcImgObj, this.state.bRemoteCapture, this.state.bAnnotate);
                        //TP-6620
                        if (this.state.urlObj === null && this.state.rcImgObj === '' /* && this.state.bRemoteCapture === true && this.state.bAnnotate === true */) {
                            printConsole("Reset the RC Max-view controls when control trasferred but no image..");
                            this.onClickAction({action: 'remoteCapture'});
                            /* this.setState({ closeRC: true }, () => {
                                this.setState({ closeRC: false });
                            }) */
                        }
                        clearTimeout(this.passRCTimer);
                    }, 6000);
                    this.processSignalExpertRCClicked();//MB2-556
                    break;
                case "EXPERT_RC_IMAGE_UPLOADED":
                    //TP-4599
                    if (this.props.user.isexpert === true && maxDivParticipant.isPaused === true) break;
                    updatedChanges.remoteChanges = {data, format:"", recipient:to, senderInfo: from };
                    this.processCloudRcImg({fileName:data});
                    break;
                case "EXPERT_RC_IMAGE_DATA":
                    //TP-4599
                    if (this.props.user.isexpert === true && maxDivParticipant.isPaused === true) break;
                    updatedChanges.remoteChanges = {data: assetData.data, format: assetData.format, recipient:to, senderInfo: from };                   
                    updatedChanges.rcAnnotateStart = true;
                    updatedChanges.bRemoteCapture = true;//TP-2724
                    this.timer = setTimeout(()=> {                        
                        this.processSocketRcImg(updatedChanges.remoteChanges);
                        clearTimeout(this.timer);
                    }, fileTransferTimeout); 
                    break;
                default:
                    break;
            }
            this.setState((prev) => ({
                ...prev,
                ...updatedChanges
            }));
            //clearTimeout(this.timer);
        //}, fileTransferTimeout);
    }

    //TP-4319
    resetTechnicianFeature = () => {
        //5052 -- Changed the order of the If condition to first check for RC and then Annotation to be reset in Technician
        if (this.state.rcStart === true /**TP-5052*/ || this.state.bRemoteCapture === true) {
            printConsole("Clear out the RC canvas on Technciian");
            this.setState({
                rcStart: false,
                rcAnnotateStart: false,  
                rcImgObj: '',
                urlObj: null,
                bRemoteCapture: false,
                bAnnotate: false,                 
                localRc: false,
                fileName: '',                    
                selectedAction: '',
                selectedCamera: '',
                selectedTelestrate: '',
            })            
        } else if (this.state.selectedTelestrate === "telestrate") {
            //TP-5052
            this.setState({ selectedTelestrate: '' })
        } else if(this.state.selectedAction === "shareAsset") {
            this.setState({
                isOneToOneFileShare: false,
                removeArtifacts: true,
                rotationChanges: '',
                rotationArray: null
            })
        }
        if (this.state.isLoading === true) this.setState({isLoading: false});
    }

    onCommandChange = () => {
        let {sessionCmd} = this.state;
        let updatedStateVar = {};
        printConsole(`....session cmd...., ${sessionCmd}`)
        switch (sessionCmd) {
            // case 'session_share':
            // case 'session_share_off':
            //     this.onClickAction('shareAssetAll');
            //     (selectedAction == 'shareAssetAll') ? this.props.updateFileShare(true) : this.props.updateFileShare(false);
            //     break;
            default:
                break;                
        }
        this.setState((prev) => ({
            ...prev,
            ...updatedStateVar
        }));
    }

    onClickAction = ({action}) => {
        printConsole(`Clicked============> ${action} at ${performance.now()}`); //TP-4275
        try{
            const { selectedAction, selectedCamera, selectedTelestrate  } = this.state;
            printConsole(`iiiiiiiiiiiiiiiiiiiiii ${this.state.selectedTelestrate}, sa, ${selectedAction}, sc,${selectedCamera}, ${action}`)
            if (action === 'telestrate') {
                this.setState({
                    selectedTelestrate: (selectedCamera === 'remoteCapture' ? '' : (action === selectedTelestrate ? '' : action))
                }, () => {
                    if(this.state.selectedTelestrate === ''){
                        if (selectedCamera === 'remoteCapture') {
                            this.setState({ selectedCamera: '', selectedTelestrate: '', selectedAction: ''});
                            this.onCloseAction({action: selectedCamera});
                        }else {
                            this.onCloseAction({action});
                        }
                    }else if(this.state.selectedTelestrate === 'telestrate') {
                        if (selectedAction !== '' ) {
                            this.setState({ selectedAction: ''}); 
                            this.onCloseAction({action: selectedAction});
                        }
                        this.onClickAnnotate();
                    }
                });
            } else if (action === 'remoteCapture') {
                this.setState({ selectedCamera: action === selectedCamera ? '' : action,
                    selectedTelestrate: action === selectedCamera ? '' : 'telestrate'
                }, () => {
                    if (this.state.selectedCamera === '') {
                        this.setState({ selectedAction: ''}); 
                        this.onCloseAction({action});
                    }
                    else if (this.state.selectedCamera === 'remoteCapture') {
                        if (selectedAction !== '' ) {
                            this.setState({ selectedAction: ''}); 
                            this.onCloseAction({action: selectedAction});
                        }
                        if (selectedTelestrate === 'telestrate') {
                            // this.setState({ selectedTelestrate: ''}); 
                            this.onCloseAction({action: selectedTelestrate});
                        }
                        this.onClickCamera();
                    }
                });
            } else if (action === 'localCapture') {
                this.setState({selectedAction: action === selectedAction ? '' : action }, () =>{
                    // this.state.localRc flag is required to check whether RC from expert is already going on MB2-221
                    if(this.state.selectedAction === 'localCapture' && !this.state.localRc){
                        this.setState({localRc: true},() => this.startLocalRc())
                    }else{
                        this.setState({localRc: false},() => this.startLocalRc())
                    }
                })
            }else{
                this.setState({ selectedAction: action === selectedAction ? '' : action }, () => {
                    if (this.state.selectedAction === '') this.onCloseAction({action});
                    // if (this.state.selectedAction === 'shareAssetAll') {
                    //     this.openFileUpload();
                    // }
                });
            }
        }catch(error){
            printConsole(error)
        }
    }

    /* Triggered when the previously Active Action button is Clicked */
    onCloseAction = ({action})=> {
        printConsole('onCloseAction');
        printConsole(action);
        switch (action) {
            case 'telestrate': 
                this.onClickAnnotate();
                this.onSendAnnotation({});
                this.closeAnnotationOnPassiveExperts(); //TP-5675
                this.enableButtons(false); //TP-4752
            break;
            case 'remoteCapture': 
                this.onClickCamera();
                this.enableButtons(false); //TP-4752
            default:
            break;
        }
    }

    setMaxDivId = ({g_maxDivId, maxDivParticipant}) => {
        this.setState({g_maxDivId, maxDivParticipant})
        if(this.props.user.isexpert && this.state.rcStart){
            this.setState({rcStart: false, isLoading: false });
        } else if(this.props.user.isexpert) {
            this.setState({ isLoading: false });
        }
    }

    setGroupInfo = ({groupInfo}) => {
        this.setState({groupInfo});
    }

    setPrimaryExpert = ({primaryExpert}) => {
        this.setState({primaryExpert});
        printConsole(`==================has rc started===================== ${this.state.rcStart}, ${this.state.rcImgObj}, ${this.state.selectedCamera}, ${this.state.isLoading}`)
        // MB2-686 for passive expert when expert_rc_clicked has come and before rcuploaded signal comes the primary expert went out of the call
        if(this.props.user.isexpert && this.state.rcStart){
            this.setState({rcStart: false, isLoading: false, selectedCamera : '' });
        }
        if(!this.props.user.isexpert && this.state.rcImgObj !== '' && this.state.selectedCamera === 'remoteCapture' && this.state.isLoading){
            this.setState({ rcImgObj: '', format: "", isLoading: false, selectedCamera : '' });
        } else if (!this.props.user.isexpert && this.state.isLoading) {
            this.setState({ localRc: false, rcImgObj: '', format: "", isLoading: false, selectedCamera : '' });
        } else if (!this.props.user.isexpert && this.state.localRc && this.state.rcImgObj === ''){
            this.setState({ localRc: false, selectedCamera : '' });
        }
    }

    setParticipant = ({participantList}) => {
        this.setState({participantList}, () => {
            this.props.onSetJoinees(participantList.length);
        })
    }

    //TP-3737 -- Set my current Video tile state (expert side)
    setMyVideoState = (val) => {
        this.setState({myVideoEnable: val});
    }

    // will be called when the first participant's track is received by the expert
    setRemoteTrackLoader = ({flag}) => {
        printConsole('======================the first track has been received==================');
        this.setState({ cameraLoading: false });
    }

    // will indicate whther this expert has max div control enabled
    setHasMaxDivControls = ({flag}) => {
        const {selectedAction, selectedCamera, selectedTelestrate, bRemoteCapture, bAnnotate, expertShareScreenMaxDiv, remoteChanges, localRc} = this.state;
        printConsole({selectedAction, selectedCamera, selectedTelestrate});
        printConsole(`bAnnotate:  ${bAnnotate}`);
        printConsole(`bRemote: ${this.state.bRemoteCapture}`);
        printConsole(`==================has rc started in setHasMaxDivControls===================== ${this.state.rcStart}`)
        let nameDiv = document.getElementsByClassName("text-center nameDiv");
        this.setState({hasMaxDivControls: flag}, () => {
            if (this.state.hasMaxDivControls && (bAnnotate || bRemoteCapture) && !expertShareScreenMaxDiv) {
                const {maxDivParticipant, aspectRatio, participantList} = this.state;
                printConsole("Current Maxview Technician details");
                printConsole(maxDivParticipant);
                const participantEmail = maxDivParticipant && maxDivParticipant.email ? maxDivParticipant.email : ""
                const participantIsExpert = maxDivParticipant && maxDivParticipant.isexpert;
                const experts = participantList.filter(p => p.isexpert);
                // Make the Name Div background darker
                if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                    const paramElem = document.getElementById(this.state.g_maxDivId);
                    //TP-5730
                    if (paramElem) {
                        const rect = paramElem.getBoundingClientRect();
                        //TP-5092
                        if (rect.width > 0)
                            nameDiv[0].style.maxWidth = rect.width + "px";
                        nameDiv[0].classList.add('annotate-webrtc');
                    }
                }
                this.setState({ pencolor: 'red', shapesSetting: {
                    isFreeDrawing: false, //TP-2586
                    isTextActive: false, //TP-4695
                    isRectActive: false,
                    isCircleActive : false,
                    isArrowActive: false,
                    isPointerActive: false, /**TP-2475 */
                    isErasorActive: false, /**TP-2586 */
                    isUndoActive: false /**TP-4696*/}
                }, () => {
                    if (participantEmail !== "")  
                        experts && experts.forEach((e) => {
                            this.sessionSignal.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: participantEmail, //TP-3893
                                ratio: aspectRatio,
                                annotation_active: this.state.bAnnotate,
                                annotate_color: this.state.pencolor,
                                shape_selected: "freehand",
                                torch_button_active: maxDivParticipant.torchActive,
                                zoom_button_active: parseInt(maxDivParticipant.zoomLevel) > 1 ? true : false,
                                zoom_level: parseInt(maxDivParticipant.zoomLevel),
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                                reason: "show_action"}, 
                            e.email);  // send the max div tech details
                        });    
                    //TP-2474 -- Multiexpert control transfer, showing the shape picker div   
                    this.setState({ bAnnotate: false }, ()=> {
                        this.setState({ bAnnotate: true });
                    })
                })

                // Clear annotations in the Technicians & other experts
                //this.onSendAnnotation(clearAnnotateObj);
                
            } else if (!this.state.hasMaxDivControls && (bAnnotate || bRemoteCapture)) {
                // T32-495-- Make the Passive expert canvas cursor non-drawable
                let canvasUpper = document.getElementsByClassName("upper-canvas")
                if (canvasUpper.length > 0) {
                    for (let i=0; i< canvasUpper.length; i++) {
                        canvasUpper[i].style.cursor = "pointer";
                    }
                }
                // Make the Name Div background transparent
                if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                    const paramElem = document.getElementById(this.state.g_maxDivId);
                    const rect = paramElem.getBoundingClientRect();
                    //TP-5092
                    if (rect.width > 0)
                        nameDiv[0].style.maxWidth = rect.width + "px";
                    nameDiv[0].classList.remove('annotate-webrtc');
                }
            }
            if (this.state.urlObj && this.state.hasMaxDivControls === false) {
                // Previous Primary expert who became a passive expert now
                printConsole("RC image loaded ====>");
                //printConsole(this.state.urlObj);
                let rcImgObj = this.state.rcImgObj;
                if (this.state.rcImgObj === '' || remoteChanges === undefined)
                    rcImgObj = this.state.urlObj;
                //printConsole(rcImgObj);
                this.setState({ rcImgObj, /* rcStart: true */ });
            }
            //TP-5686
            if (this.state.hasMaxDivControls && (this.state.isHeaderFileShare || this.state.isOneToOneFileShare)) {
                if (this.state.fileData === null && this.state.externalAssets.length > 0) {
                    this.state.externalAssets.forEach(asset => {
                        asset.data = asset.data.replace('data:'+asset.type+';base64,', '').trim();
                        this.setState({fileData: asset});
                    })
                }
            }
        })
        if (this.state.rcImgObj) {        
            printConsole("RC image downloaded===================>", this.state.rcImgObj);   
            let urlObj = this.state.rcImgObj;
            //TP-2474
            printConsole(`saved RC image format : ${remoteChanges.format}`);
            if (remoteChanges.format === "" /* || remoteChanges.format === undefined */) 
                urlObj = window.URL.createObjectURL(this.state.rcImgObj);
            printConsole(urlObj);
            this.setState({ urlObj, selectedAction: (selectedCamera === "remoteCapture") ? "remoteCapture" : "telestrate"}, () => {
                this.setState({bRemoteCapture: true, bAnnotate: true, isLoading: false});
            })
        } else if (this.state.rcStart && flag === true) {
            this.setState({rcStart: false, isLoading: false});
            //this.onClickCamera();
            if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                nameDiv[0].classList.remove('annotate-webrtc');
            }
            this.stopRemoteCaptureAnnotation();// stop RC if on
        } 
    }

    //will indicate whether screen share mode is ongoing
    //on Passive expert/Technician side
    setMaxDivSS = ({expertShareScreenMaxDiv}) => {
        this.setState({expertShareScreenMaxDiv});
    }

    // will indicate whether which ss is going on
    setSS = ({isSideBarSS, isMaxDivSS}) => {
        this.setState({isSideBarSS, isMaxDivSS})
    }

    setSSParams = ({ sendExpertSS, sSParticipant }) => {
        this.setState({ sendExpertSS, sSParticipant })
    }

    //Set or unset the bAnnotate flag
    setAnnotate = ({bAnnotate}) => {
        this.setState({ bAnnotate });
    }

    setRCStart = ({rcStart}) => {
        this.setState({ rcStart });
    }

    setTechAspectRatio = ({aspectRatio}) => {
        this.setState({ aspectRatio });
    }
    
    updateCancelShare = (flag) => {
        //console.log("update cancel share file");
        bCancelShare = flag;
        if (bCancelShare === false) {
            this.setState({
                assetRecipient: '',localAssets: [],externalAssets: [],
                isHeaderFileShare: false,
                isOneToOneFileShare: false,
                fileLoading: false,
                showCancel: false
            });
        }
    }

    onClickCancelShareAsset = () => {
        this.setState({ showCancel: false, fileLoading: false });
        const {participantList, maxDivParticipant} = this.state;
        const experts = participantList && participantList.filter(p => p.isexpert === true);
        //if (this.state.localAssets.length > 0) {
            bCancelShare = true;            
            //this.onCloseFileViewer();
            // T32-539
            participantList && participantList.forEach(p => {
                this.sessionSignal.sendSignalRemoveArtifacts(true, p.email);
            });
            this.setState({fileData: null});
            // T32-592 -- Enable the Passive expert's Hand Icons when 
            // File share is closed
            experts && experts.forEach(p => {
                this.sessionSignal.sendSignalSyncExperts({
                    show_grid: maxDivParticipant ? false : true,
                    max_view_technician: maxDivParticipant ? maxDivParticipant.email : '', //TP-3893
                    ratio: this.state.aspectRatio,
                    hand_icon_enabled: true,
                    header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                    reason: "show_action"}, 
                p.email);
            })          
            //if (bCancelShare == true) bCancelShare = false;
        //}
    }

    openFileUpload = (elemId) => {    
        this.setState({ setStartAssetsShare: true });
        fileDialog({ multiple: false, accept: fileUploadaccepted }) //TP-4315
        .then( async (files) => {
            printConsole(`files================>`);
            printConsole(files);
            // if (elemId === undefined) this.props.updateFileShare(true);
            const localAssets = Object.values(files);
            //TP-4315
            const [objFile] = localAssets.filter(e => e.name.endsWith('.obj') || e.name.endsWith('.3ds') ||  e.name.endsWith('.3DS') || e.name.endsWith('.FBX') || e.name.endsWith('.fbx'));
            const [mtlFile] = localAssets.filter(e => e.name.endsWith('.mtl'));
            if (objFile || mtlFile) {
                //console.log("3D files share is not allowed..");
                this.setState({
                    showError: true,
                    setStartAssetsShare: false,
                    assetRecipient: '',localAssets: [],externalAssets: [],
                    isHeaderFileShare: false,
                    isOneToOneFileShare: false,
                    showCancel: false,
                    fileLoading: false
                }, () => {
                    this.fftimer = setTimeout(() => {
                        this.setState({showError: false});
                        clearTimeout(this.fftimer);
                    }, 4000);
                })
                return;
            }
            this.setState({ localAssets, showCancel: true, fileLoading: true });//to indicate file share started
            if (localAssets.length > 0) {
                //TP-1444:Rs Added to fix opacity value should be reset default when new file shared
                this.setState((prevState)=>({opacityChanges:prevState.opacityChanges !=="0.60" ? "0.60":prevState.opacityChanges}));
                if (this.state.bAnnotate && !this.state.bRemoteCapture) {
                    const {maxDivParticipant, aspectRatio, participantList} = this.state;
                    this.sendAnnotationExpertSS(clearAnnotateObj);
                    this.resetAnnotationShapesColors(true); //TP-1178
                    if (maxDivParticipant)  {
                        const experts = participantList && participantList.filter(p => p.isexpert === true);
                        experts && experts.forEach((e) => {
                            this.sessionSignal.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: maxDivParticipant.email, //TP-3893
                                ratio: aspectRatio,
                                annotation_active: this.state.bAnnotate,
                                annotate_color: "red",
                                shape_selected: "freehand",
                                torch_button_active: maxDivParticipant.torchActive,
                                zoom_button_active: parseInt(maxDivParticipant.zoomLevel) > 1 ? true : false,
                                zoom_level: parseInt(maxDivParticipant.zoomLevel),
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                                reason: "show_action"}, 
                            e.email);  // send the max div tech details
                        });
                    }
                }
                const [objFile] = localAssets.filter(e => e.name.endsWith('.obj') || e.name.endsWith('.3ds') ||  e.name.endsWith('.3DS') || e.name.endsWith('.FBX') || e.name.endsWith('.fbx'));
                if (objFile) this.setState({is3DFile : true});
                else this.setState({is3DFile : false}) ;
                const [pdfFile] = localAssets.filter(e => e.name.endsWith('.pdf') || e.name.endsWith('.PDF'));
                if (pdfFile) this.setState({ isPDFFile : true });
                else this.setState({ isPDFFile : false });
                const [audioFile] = localAssets.filter(e => e.name.endsWith('.mp3'));
                if (audioFile) this.setState({ isAudioFile: true});
                else this.setState({ isAudioFile: false});
                elemId ? this.onShareAsset({localAssets}) : this.onShareAssetAll({localAssets});
            }else{
                this.setState({
                    setStartAssetsShare: false,
                    assetRecipient: '',localAssets: [],externalAssets: [],
                    isHeaderFileShare: false,
                    isOneToOneFileShare: false,
                    showCancel: false,
                    fileLoading: false
                })
            }
        });
    }

    //TP-841 -- Set the current opacity value for Primary expert
    setAssetOpacity = (val) => {
        const number = val/100;
        const opacity = number.toFixed(2);
        this.setState({opacityChanges: opacity}, () => {
            printConsole(`new set opacity is =====> ${this.state.opacityChanges}`);
        });
    }

    // upload file to local network in LAN
    uploadAssetFile = async (localAssets) => {
        const externalAssets = [];
        let retdata;
        for (const file of localAssets) {
            if (window._env_.REACT_APP_APP_MODE !== "LAN"){
                retdata = await this.s3FileUpload(file);
            }else{
                retdata = await this.localUpload(file)
            }
            this.setState(prevState => ({
                filePathArr: [...prevState.filePathArr,retdata.key],//used for local upload
                fileNameArr: [...prevState.fileNameArr,{Key:retdata.key}]//used for s3
            }));
            externalAssets.push({
                ...retdata
            });
        }
        return externalAssets;
    }

    // upload files to s3
    s3FileUpload = (file) => {
        return new Promise((resolve, reject) => {
            uploadFile(file, this.config)
            .then(retdata => {
                resolve({...retdata, type: 'AWS' });
            })
            .catch(err => {
                this.setState({isHeaderFileShare: false, isOneToOneFileShare: false})
                // this.props.updateFileShare(false);
                // console.error(err);
            })
        });
    }

    localUpload = (file) => {
        const data = new FormData();
        data.append("Content-Type", file.type);
        data.append('file', file);
        return new Promise((resolve, reject) => {
            // push selected file to server
            this.authService.fetch('upload/uploadAsset', {
                "method": "POST",
                body: data
            }, {
                data
            })
            .then(response => response.json())
            .then(retdata => {
                resolve({...retdata, type: 'LAN' });
            })
            .catch(err => {
                // this.props.updateFileShare(false);
                console.error(err);
            })
        })
    }

    // File share to all
    onShareAssetAll = async ({localAssets}) => {
        // no signal when side bar ss is going on
        const {participantList, maxDivParticipant, aspectRatio} = this.state;
        const experts = participantList && participantList.filter(p => p.isexpert === true);
        printConsole("Local Assets==========================> ");
        printConsole(localAssets);
        this.setState({ removeArtifacts: false }); //TP-2220
        if( typeof localAssets === 'object'){
            let maxPart = null;
            let shape_selected = "";
            if (maxDivParticipant !== '' && maxDivParticipant !== null) {
                const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, /**TP-2475 */isPointerActive, isTextActive} = this.state.shapesSetting;
                if (isFreeDrawing === true) shape_selected = "freehand";
                else if (isRectActive === true) shape_selected = "rectangle";
                else if (isCircleActive === true) shape_selected = "circle";
                else if (isArrowActive === true) shape_selected = "arrow";
                else if (isPointerActive === true) shape_selected = "pointer"; /**TP-2586 & TP-2475*/
                else if (isTextActive === true) shape_selected = "addText"; /**TP-4695*/
                else shape_selected = "freehand";
                [maxPart] = participantList && participantList.filter((p) => {
                    if (p.email === maxDivParticipant.email) {
                        return p;
                    }
                });
            }
            let index = -1;
            localAssets.forEach((e, i) => {
                if (e.name.endsWith('.obj')) index = i;
            });
            printConsole(`Index of .obj file: ${index}`);
            if (index > -1) {
                localAssets = array_move(localAssets, index, 0);
            }
            if (localAssets.length > 0) {
                let i = 0;
                for (const file of localAssets) {
                //for(let i=0; i<localAssets.length; i++) {                    
                    printConsole(file);
                    let reader = new FileReader();
                    reader.onloadend = function() {
                        i++;
                        //printConsole('RESULT: ', reader.result); .replace('data:image/png;base64,', '')
                        var srcData = arrayBufferToBase64(reader.result);
                        webRtcObj.setState({ setStartAssetsShare: false });
                        //var srcData = reader.result.replace('data:'+file.type+';base64,', '').trim();
                        printConsole("Base 64 image url: ", srcData);
                        //let byteArray = await fileToByteArray(file);
                        if(!srcData) {
                            printConsole("Image isn't available for sharing");
                            return;
                        } 
                        const splitedArray = file.name.split('.');
                        const format = splitedArray[splitedArray.length - 1].toLowerCase();
                        let name = splitedArray[0];
                        if (splitedArray.length > 2) {
                            splitedArray.pop();
                            name = splitedArray.join(".");
                        }   
                        //console.log(name);
                        const fileData = {
                            data: srcData,
                            format,
                            type: (format === "mtl") ? "application/octet-stream" : file.type,
                            sequence: i,
                            total: localAssets.length,
                            name
                        }
                        //filearr.push(fileData);
                        printConsole("fileData================>",fileData);
                        webRtcObj.setState({fileData, shareFileName: name+"."+format});
                        //if (!webRtcObj.state.isSideBarSS) {
                            //if(webRtcObj.state.isMaxDivSS){
                                // max div ss going on
                                /* const allExceptMaxParticipant = participantList && maxDivParticipant !== '' ? participantList.filter(p => p.email !== maxDivParticipant.email) : null
                                allExceptMaxParticipant && allExceptMaxParticipant.forEach(p => {
                                    webRtcObj.sessionSignal.sendSignalShareArtifacts(fileData, p.email);
                                });
                                experts && experts.forEach(p => {
                                    webRtcObj.sessionSignal.sendSignalSyncExperts({
                                        show_grid: maxDivParticipant ? false : true,
                                        max_view_technician: maxDivParticipant ? maxDivParticipant.email : '', //TP-3893
                                        ratio: aspectRatio,
                                        hand_icon_enabled: true,
                                        header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                                        reason: "show_action"}, 
                                    p.email);
                                }) */
                            //}else{
                                webRtcObj.sessionSignal.sendSignalShareArtifacts(fileData, '');
                                experts && experts.forEach(p => {
                                    webRtcObj.sessionSignal.sendSignalSyncExperts({
                                        show_grid: maxDivParticipant ? false : true,
                                        max_view_technician: maxDivParticipant ? maxDivParticipant.email : '', //TP-3893
                                        ratio: aspectRatio,
                                        annotation_active: webRtcObj.state.bAnnotate,
                                        annotate_color: webRtcObj.state.pencolor,
                                        shape_selected,
                                        torch_button_active: (maxPart !== null && maxPart.torch_enabled) ? maxPart.torchActive : false,
                                        zoom_button_active: maxPart !== null && (parseInt(maxPart.zoomLevel) > 1 || maxPart.zoom_enabled) ? true : false,
                                        zoom_level: (maxPart !== null && maxPart.zoom_enabled) ? parseInt(maxPart.zoomLevel) : 1,
                                        hand_icon_enabled: true,
                                        header_fileShare_active: true, //TP-2378
                                        reason: "show_action"}, 
                                    p.email);
                                })
                                /* let disabledEmails = participantList.map(p => {
                                    if (p.disableVideo === false && p.isexpert === false)
                                        return p.email;
                                    else if (p.isexpert === true)
                                        return p.email;
                                    else
                                        return;
                                });
                                const filteredEmails = disabledEmails.filter(function (el) {
                                    return el != undefined;
                                });          
                                printConsole(filteredEmails);
                                if (filteredEmails.length === participantList.length) {
                                    webRtcObj.sessionSignal.sendSignalShareArtifacts(fileData, '');
                                } else {
                                    filteredEmails && filteredEmails.forEach((f) => {
                                        webRtcObj.sessionSignal.sendSignalShareArtifacts(fileData, f);
                                    });
                                } */
                            //}
                        //}   
                    }
                    //reader.readAsDataURL(file);
                    reader.readAsArrayBuffer(file);
                }
            } 
            /* if(filearr.length === 0) {
                printConsole("No files uploaded");
                return ;
            } */            
            
        }
        this.setState({ selectedAction: 'shareAssetAll', isHeaderFileShare: true, showCancel: false, fileLoading: false });
    }

    // one-to-one File share
    onShareAsset = async ({localAssets})=>{
        try{
            const { participantList, maxDivParticipant, aspectRatio } = this.state;
            const experts = participantList && participantList.filter(p => p.isexpert === true);
            printConsole("Local Assets==========================> ");
            printConsole(localAssets);
            this.setState({ removeArtifacts: false }); //TP-2220
            if(maxDivParticipant !== '' && typeof localAssets === 'object') {
                const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, /**TP-2475*/ isPointerActive, isTextActive} = this.state.shapesSetting;
                let shape_selected = "";
                if (isFreeDrawing === true) shape_selected = "freehand";
                else if (isRectActive === true) shape_selected = "rectangle";
                else if (isCircleActive === true) shape_selected = "circle";
                else if (isArrowActive === true) shape_selected = "arrow";
                else if (isPointerActive === true) shape_selected = "pointer"; /**TP-2586 & TP-2475 */
                else if (isTextActive === true) shape_selected = "addText"; /**TP-4695*/
                else shape_selected = "freehand";
                let maxPart;
                [maxPart] = participantList && participantList.filter((p) => {
                    if (p.email === maxDivParticipant.email) {
                        return p;
                    }
                });
                let index = -1;
                localAssets.forEach((e, i) => {
                    if (e.name.endsWith('.obj')) index = i;
                });
                printConsole(`Index of .obj file: ${index}`);
                if (index > -1) {
                    localAssets = array_move(localAssets, index, 0);
                }
                //let filearr = [];
                if (localAssets.length > 0) {
                    let i = 0;
                    for (const file of localAssets) {
                    //for(let i=0; i<localAssets.length; i++) {                    
                        //printConsole(file);
                        let reader = new FileReader();
                        reader.onloadend = function() {
                            i++;
                            //printConsole('RESULT: ', reader.result); //.replace('data:image/png;base64,', '')
                            var srcData = arrayBufferToBase64(reader.result);
                            webRtcObj.setState({ setStartAssetsShare: false });
                            //var srcData = reader.result.replace('data:'+file.type+';base64,', '').trim();
                            //var srcData = reader.result.replace(/^data:image\/[a-z]+;base64,/, "").trim();
                            printConsole("Base 64 image url: ", srcData);
                            //let byteArray = await fileToByteArray(file);
                            if(!srcData) {
                                printConsole("Image isn't available for sharing");
                                return;
                            } 
                            const splitedArray = file.name.split('.');
                            const format = splitedArray[splitedArray.length - 1].toLowerCase();
                            let name = splitedArray[0];
                            if (splitedArray.length > 2) {
                                splitedArray.pop();
                                name = splitedArray.join(".");
                            }   
                            //console.log(name);
                            const fileData = {
                                data: srcData,
                                format,
                                type: (format === "mtl") ? "application/octet-stream" : file.type,
                                sequence: i,
                                total: localAssets.length,
                                name
                            }
                            //filearr.push(fileData);
                            printConsole("fileData================>",fileData);
                            webRtcObj.setState({fileData, shareFileName: name+"."+format});
                            //if (filearr.length === localAssets.length) {
                                //webRtcObj.setState({ selectedAction: 'shareAsset', isHeaderFileShare: true, isOneToOneFileShare: true, fileLoading: false}, () => {
                                    const toObject = maxDivParticipant.email;
                                    printConsole('sending artifacts signal to :: ',toObject);
                                    if (bCancelShare === false)
                                        /* !webRtcObj.state.isMaxDivSS && !webRtcObj.state.isSideBarSS && */ webRtcObj.sessionSignal.sendSignalShareArtifacts(fileData, toObject);//MB2-572
                                //});
                            //}
                            // TP-6 -- In Max-view File Share, share the file to all the Participating Passive experts as well
                            experts && experts.forEach(p => {
                                webRtcObj.sessionSignal.sendSignalShareArtifacts(fileData, p.email);
                                webRtcObj.sessionSignal.sendSignalSyncExperts({
                                    show_grid: maxDivParticipant ? false : true,
                                    max_view_technician: maxDivParticipant ? maxDivParticipant.email : '', //TP-3893
                                    ratio: aspectRatio,
                                    annotation_active: webRtcObj.state.bAnnotate,
                                    annotate_color: webRtcObj.state.pencolor,
                                    shape_selected,
                                    torch_button_active: (maxPart.torch_enabled) ? maxPart.torchActive : false,
                                    zoom_button_active: parseInt(maxPart.zoomLevel) > 1 || maxPart.zoom_enabled ? true : false,
                                    zoom_level: (maxPart.zoom_enabled) ? parseInt(maxPart.zoomLevel) : 1,
                                    hand_icon_enabled: true,
                                    header_fileShare_active: false, //TP-2378
                                    reason: "show_action"}, 
                                p.email);
                            })
                        }
                        //reader.readAsDataURL(file);
                        reader.readAsArrayBuffer(file);
                    }
                    this.setState({ selectedAction: 'shareAsset', isHeaderFileShare: true, isOneToOneFileShare: true, showCancel: false, fileLoading: false});
                } 
            }
        }catch(error){
            printConsole(error)
        }
    }

    /** Based on one to one or all */
    onSend3dRotation = (rotationChanges) => {
        try{
            // if primary expert then send out the signal MB-551
            if (this.props.user.isexpert && this.state.hasMaxDivControls){
                this.setState({ rotationArray : rotationChanges });
                const { maxDivParticipant, isOneToOneFileShare, participantList } = this.state;
                // If no max div pariticipant then expert in grid view. Also even when primary expert is on max div then also s/he can decide to go with side bar file upload.
                const toObject = (maxDivParticipant !== '' && maxDivParticipant !== undefined && isOneToOneFileShare) ? maxDivParticipant.email : '';
                if(!this.state.isSideBarSS){
                    if(this.state.isMaxDivSS){
                        // side bar file upload and max div screen share
                        if(toObject === ''){
                            const allExceptMaxParticipant = participantList ? participantList.filter(p => p.email !== maxDivParticipant.email) : null
                            allExceptMaxParticipant && allExceptMaxParticipant.forEach(p => {
                                this.sessionSignal.sendSignal3DRotation(rotationChanges, p.email);
                            });
                        }
                    }else{
                        // side bar file upload
                        this.sessionSignal.sendSignal3DRotation(rotationChanges, '');
                    }
                }
            }
        }catch(error){
            printConsole(error)
        }
    }

    // TP-841 -- to send the current File Viewer Opacity value to all the other users
    // in that WebRTC session
    onSendFileOpacity = (opacityChanges) => {
        try{
            // if primary expert then send out the signal MB-551
            if (this.props.user.isexpert && this.state.hasMaxDivControls){
                //this.setState({ rotationArray : opacityChanges });
                const { maxDivParticipant, isOneToOneFileShare, participantList } = this.state;
                const experts = participantList.filter(p => p.isexpert);
                // If no max div pariticipant then expert in grid view. Also even when primary expert is on max div then also s/he can decide to go with side bar file upload.
                const toObject = (maxDivParticipant !== '' && maxDivParticipant !== undefined && isOneToOneFileShare) ? maxDivParticipant.email : '';
                if(!this.state.isSideBarSS){
                    /* if(this.state.isMaxDivSS){
                        // side bar file upload and max div screen share
                        if(toObject === ''){
                            const allExceptMaxParticipant = participantList ? participantList.filter(p => p.email !== maxDivParticipant.email) : null
                            allExceptMaxParticipant && allExceptMaxParticipant.forEach(p => {
                                this.sessionSignal.sendSignalOpacity(opacityChanges, p.email);
                            });
                        }
                    }else{
                        // side bar file upload
                        this.sessionSignal.sendSignalOpacity(opacityChanges, '');
                    } */
                    if (experts) {
                        experts.forEach(e => {
                            this.sessionSignal.sendSignalOpacity(opacityChanges, e.email);
                        })
                    }
                }
            }
        }catch(error){
            printConsole(error)
        }
    }

    // To re-send the Shared files to only Max-div user
    sendSharedArtifactsMaxDiv = () => {
        const { participantList, maxDivParticipant, rotationArray, opacityChanges } = this.state;
        const experts = participantList.filter(p => p.isexpert);
        if (this.state.fileData !== null) {
            this.sessionSignal.sendSignalShareArtifacts(this.state.fileData, maxDivParticipant.email);
            experts && experts.forEach(e => {
                this.sessionSignal.sendSignalShareArtifacts(this.state.fileData, e.email);
            })
        }
        else {
            //printConsole(this.state.externalAssets);
            this.state.externalAssets && this.state.externalAssets.forEach(asset => {
                asset.data = asset.data.replace('data:'+asset.type+';base64,', '').trim();
                this.sessionSignal.sendSignalShareArtifacts(asset, maxDivParticipant.email);
                experts && experts.forEach(e => {
                    this.sessionSignal.sendSignalShareArtifacts(asset, e.email);
                })
            })
        }

        // If 3D file is shared then resend the last rotation change
        const [closeIcon] = document.getElementsByClassName('asset-3d');
        if (closeIcon && rotationArray !== null) {
            this.rotationTimer = setTimeout(() => {
                this.sessionSignal.sendSignal3DRotation(rotationArray, maxDivParticipant.email);
                experts && experts.forEach(e => {
                    this.sessionSignal.sendSignal3DRotation(rotationArray, e.email);
                })
                clearTimeout(this.rotationTimer)
            }, 3000);
        } else if (opacityChanges !== '') {
            //TP-841 If not 3D file share ongoing then send the current Opacity value 
            //this.sessionSignal.sendSignalOpacity(opacityChanges, maxDivParticipant.email); 
            experts && experts.forEach(e => {                
                this.sessionSignal.sendSignalOpacity(opacityChanges, e.email);
            });
        }
    }

    // To re-send the Shared files to all users in session
    sendArtifactsToAll = () => {
        const { participantList, rotationArray, opacityChanges } = this.state;
        const experts = participantList.filter(p => p.isexpert);
        if (this.state.fileData !== null) 
            participantList && participantList.forEach(p => {
                this.sessionSignal.sendSignalShareArtifacts(this.state.fileData, p.email);
            })
        else {
            //printConsole(this.state.externalAssets);
            this.state.externalAssets && this.state.externalAssets.forEach(asset => {
                asset.data = asset.data.replace('data:'+asset.type+';base64,', '').trim();
                participantList && participantList.forEach(p => {
                    this.sessionSignal.sendSignalShareArtifacts(asset, p.email);
                })
            })
        }

        // If 3D file is shared then resend the last rotation change
        const [closeIcon] = document.getElementsByClassName('asset-3d');
        if (closeIcon && rotationArray !== null) {
            this.rotationTimer = setTimeout(() => {
                this.sessionSignal.sendSignal3DRotation(rotationArray, '');
                clearTimeout(this.rotationTimer)
            }, 3000);
        } else if (opacityChanges !== '') {
            // If not 3D file share ongoing then send the current Opacity value 
            //this.sessionSignal.sendSignalOpacity(opacityChanges, ''); //TP-841
            experts && experts.forEach(e => {
                this.sessionSignal.sendSignalOpacity(opacityChanges, e.email);
            })
        }
    }
  
    closeFileViewer = (value=true) => {
        const [closeIcon, canvas] = document.getElementsByClassName('asset-3d');
        if (closeIcon) {                
            window.removeEventListener('mousemove', function(){});
            window.removeEventListener('touchmove', function(){});
                
            window.removeEventListener('mouseup', function(){});
            window.removeEventListener('touchend', function(){});

            window.removeEventListener('mousedown', function(){});
            window.removeEventListener('touchstart', function(){});

            /** remove both element from dom */
            closeIcon && closeIcon.parentNode && closeIcon.parentNode.removeChild(closeIcon);
            canvas && canvas.parentNode.removeChild(canvas);
        }
        this.onClose3dCanvas(value);        
    }

    resetAnnotationShapesColors = (flag) => {
        if (flag === true) {
            this.setState({ pencolor: 'red' },() => {
                    this.setState({shapesSetting :
                        {
                            isFreeDrawing: false, /**TP-2586 */
                            isTextActive: false, /**TP-4695 */
                            isRectActive: false,
                            isCircleActive: false,
                            isArrowActive: false,
                            isPointerActive: false, /**TP-2475 */
                            isErasorActive: false, /**TP-2586 */
                            isUndoActive: false /**TP-4696*/
                        }
                    });                                    
            });
        }
    }

    //TP-2838
    resetAnnotationShapedColorForShapeDisabled = (flag) => {
        if (flag === true) {
            this.setState({ pencolor: 'red' },() => {
                    this.setState({shapesSetting :
                        {
                            isFreeDrawing: true, /**TP-2586 */
                            isTextActive: false, /**TP-4695 */
                            isRectActive: false,
                            isCircleActive: false,
                            isArrowActive: false,
                            isPointerActive: false, /**TP-2475 */
                            isErasorActive: false, /**TP-2586 */
                            isUndoActive: false /**TP-4696 */
                        }
                    });                                    
            });
        }
    }

    onClose3dCanvas = (isGridView=false) => {
        printConsole("Close the 3D file");
        printConsole(`isGridView??? ${isGridView}`);
        const {user} = this.props;
        const { maxDivParticipant, isOneToOneFileShare, participantList, hasMaxDivControls } = this.state;
        let disabledEmails = participantList && participantList.map(p => {
            if (p.disableVideo === false && p.isexpert === false)
                return p.email;
            else if (p.isexpert === true)
                return p.email;
            else
                return;
        });
        const filteredEmails = disabledEmails.filter(function (el) {
            return el != undefined;
        });          
        //printConsole(filteredEmails);        
        const toObject = (maxDivParticipant !== '' && maxDivParticipant !== undefined && isOneToOneFileShare) ? maxDivParticipant.email : '';
        this.setState( prev => ({
            localAssets:[], externalAssets:[], isHeaderFileShare:false, isOneToOneFileShare:false,
            selectedAction: '', removeArtifacts: false, opacityChanges: (user.isexpert && isGridView === false && hasMaxDivControls) ?  "0.60": prev.opacityChanges,
            rotationChanges: '', rotationArray: (user.isexpert && hasMaxDivControls) ? null : prev.rotationArray         
        }),() => {
            // only for max div expert
            if(user.isexpert && hasMaxDivControls){
                //TP-1187- Added to sync opacity signal with other experts
                const experts = participantList && participantList.length > 0 && participantList.filter(p => p.isexpert === true);  
                if(this.state.opacityChanges === "0.60"){
                    experts && experts.forEach((exp)=>{
                        this.sessionSignal.sendSignalOpacity(this.state.opacityChanges, exp.email);
                    })
                }
                const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, /**TP-2475 */isPointerActive, isTextActive} = this.state.shapesSetting;
                let shape_selected = "";
                let maxPart = null;
                if (maxDivParticipant && maxDivParticipant !== '') {
                    if (isFreeDrawing === true) shape_selected = "freehand";
                    else if (isRectActive === true) shape_selected = "rectangle";
                    else if (isCircleActive === true) shape_selected = "circle";
                    else if (isArrowActive === true) shape_selected = "arrow";
                    else if (isPointerActive === true) shape_selected = "pointer"; /**TP-2586 & TP-2475 */
                    else if (isTextActive === true) shape_selected = "addText"; /**TP-4695*/
                    else shape_selected = "freehand";
                    [maxPart] = participantList && participantList.filter((p) => {
                        if (p.email === maxDivParticipant.email) {
                            return p;
                        }
                    });
                }
                //if(!this.state.isSideBarSS){
                    //if(this.state.isMaxDivSS){
                        // side bar file upload and max div screen share
                        /* if(toObject === ''){
                            const allExceptMaxParticipant = participantList ? participantList.filter(p => p.email !== maxDivParticipant.email) : null
                            // T32-539
                            this.setState({fileData: null});
                            allExceptMaxParticipant && allExceptMaxParticipant.forEach(p => {
                                this.sessionSignal.sendSignalRemoveArtifacts(true, p.email);
                            });
                            if (isGridView === true) return;
                            experts && experts.forEach(p => {
                                this.sessionSignal.sendSignalSyncExperts({
                                    show_grid: maxDivParticipant ? false : true,
                                    max_view_technician: maxDivParticipant ? maxDivParticipant.email : '', //TP-3893
                                    ratio: this.state.aspectRatio,
                                    hand_icon_enabled: true,
                                    header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                                    reason: "show_action"}, 
                                p.email);
                            })
                        
                        }else{ */
                            // side bar file upload
                            this.setState({fileData: null});
                            this.sessionSignal.sendSignalRemoveArtifacts(true, ''); //T32-539
                            if (isGridView === true) return;
                            experts && experts.forEach(p => {
                                this.sessionSignal.sendSignalSyncExperts({
                                    show_grid: maxDivParticipant ? false : true,
                                    max_view_technician: maxDivParticipant ? maxDivParticipant.email : '', //TP-3893
                                    ratio: this.state.aspectRatio,
                                    annotation_active: this.state.bAnnotate,
                                    annotate_color: this.state.pencolor,
                                    shape_selected,
                                    torch_button_active: (maxPart !== null && maxPart !== undefined && maxPart.torch_enabled) ? maxPart.torchActive : false,
                                    zoom_button_active: maxPart !== null && maxPart !== undefined &&  (parseInt(maxPart.zoomLevel) > 1 || maxPart.zoom_enabled) ? true : false,
                                    zoom_level: (maxPart !== null && maxPart !== undefined && maxPart.zoom_enabled) ? parseInt(maxPart.zoomLevel) : 1,
                                    hand_icon_enabled: true,
                                    header_fileShare_active: false, //TP-2378
                                    reason: "show_action"}, 
                                p.email);
                            })
                            /* if (filteredEmails.length === participantList.length) {
                                this.sessionSignal.sendSignalRemoveArtifacts(true, toObject);
                            } else {
                                filteredEmails && filteredEmails.forEach((f) => {
                                    this.sessionSignal.sendSignalRemoveArtifacts(true, f);
                                });
                            } */
                        //}
                        this.deleteFiles();
                

                if (this.state.bAnnotate && !this.state.bRemoteCapture) {
                    //TP-2838
                    if (!this.state.groupInfo.annotation_shape_enabled) 
                        this.resetAnnotationShapedColorForShapeDisabled(true);
                    else
                        this.resetAnnotationShapesColors(true);
                }
            } else if (!user.isexpert) {
                //TP-4261
                this.setState({triggershowRC: true}, () => {
                    this.setState({triggershowRC: false});
                })
            }
        });
    }

    // delete files based on app mode settings
    deleteFiles = () => {
        if (window._env_.REACT_APP_APP_MODE !== "LAN"){
            this.deleteS3AssetFiles();
        }else{
            this.deleteAssetFiles();
        }
    }

    // delete the s3 objects
    deleteS3AssetFiles = () => {
        const {fileNameArr} = this.state;
        s3.deleteObjects({
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Delete: {Objects: fileNameArr, Quiet: false }
            }, (err, data) => {
                if(err){
                    // console.error(err, err.stack); // an error occurred
                }else{
                    this.setState({filePathArr:[], fileNameArr: [] });
                    // console.info(`file deleted ${JSON.stringify(data)}`); // successful response
                }
            }
        );        
    }

    // delete the asset files
    deleteAssetFiles = () => {
        const {filePathArr} = this.state
        this.authService.fetch('deleteAsset', {
            method: 'POST',
            body: JSON.stringify({
                filePathArr
            })
        })
        .then(response => response.json())
        .then(() => {
            this.setState({filePathArr: [], fileNameArr: [] });
        })
        .catch(err => {
            this.setState({ filePathArr: [], fileNameArr: [] });
            console.error(err)
        })
    }

    // clearSessionToken = (groupId) => {
    //     this.authService.fetch('updateGroupToken', {
    //         method: 'post',
    //         body: JSON.stringify({
    //             groupId
    //         })
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         console.info('token cleared')
    //     })
    //     .catch(err => console.error(err.message))
    // }

    // NS2-425 to ensure session id is cleared
    cleanSessionToken = (groupId) => {
        this.authService.fetch('clearSessionToken', {
            method: 'post',
            body: JSON.stringify({
                groupId
            })
        })
            .then(response => {
                response.json()
            })
            .then(data => {
                printConsole('session cleared as no user feed has come to only expert');                
            })
            .catch(err => printConsole(err.message))
    }

    // check whether annotation going on for technician during screen share
    checkAnnotation = () => {
        if(this.state.selectedTelestrate){
            this.setState({selectedTelestrate: ''})
        }
    }

    //set the Annotation and related flags to default
    setMaxAnnotate = (flag) => {
        this.setState({bAnnotate: flag, urlObj:null, bSelectShape: false,
            pencolor: 'red', shapesSetting: ''});
    }

    //TP-2491
    setAnnotateDuringPrimaryExpertLeaving = (val) => {
        this.setState({ bAnnotate: val });
    }

    //TP-2824
    stopPointerWhPassiveBecomesPrimary = () => {
        this.setState({ stopPointerWhenControlTransfer: true }, () => {
            this.setState({ stopPointerWhenControlTransfer: false });
        })
    }

    //onClick function for Toggle of maxDiv Annotate button
    onClickAnnotate = () => {
        const { bRemoteCapture, bAnnotate } = this.state;
        if(bRemoteCapture){
            this.stopRemoteCaptureAnnotation();
        }else{
            let nameDiv = document.getElementsByClassName("text-center nameDiv");
            if (this.state.bAnnotate === true) {
                if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                    nameDiv[0].classList.remove('annotate-webrtc');
                }
            } else {
                // Make the Name Div background darker
                if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                    const paramElem = document.getElementById(this.state.g_maxDivId);
                    //TP-4548
                    if (paramElem && paramElem !== null) {
                        const rect = paramElem.getBoundingClientRect();
                        //TP-5092
                        if (rect.width > 0)
                            nameDiv[0].style.maxWidth = rect.width + "px";
                        nameDiv[0].classList.add('annotate-webrtc');
                    }
                }
            }
            this.setState({bRemoteCapture: false, bAnnotate: !bAnnotate, urlObj:null, bSelectShape: false,
                pencolor: 'red', shapesSetting: ''});
        }
    }

    // MB2-555 and MB2-556
    onSendAnnotation = (annotationChanges) => {
        try{
            if(!this.state.isSideBarSS){
                let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //TP-2549
                const { maxDivParticipant, participantList } = this.state;
                let experts = participantList && participantList.length > 0 && participantList.filter(p => p.isexpert);
                if(this.state.isMaxDivSS){
                    // only to the experts when max div screen share going on
                    experts && experts.length > 0 && experts.forEach((participant) => {
                        this.sessionSignal.sendSignalAnnotate(annotationChanges, participant.email, sixdigitsrandom); // send to each expert
                    });                 
                }else{
                    // to the max div technician and experts, when screen share going on
                    const toObject = maxDivParticipant.email;
                    this.sessionSignal.sendSignalAnnotate(annotationChanges, toObject, sixdigitsrandom); //TP-2549
                    experts && experts.length > 0 && experts.forEach((participant) => {
                        this.sessionSignal.sendSignalAnnotate(annotationChanges, participant.email, sixdigitsrandom); // send to each expert
                    });                 
                }
            }
        }catch(error){
            printConsole(error);
        }
    }

    sendAnnotationExpertSS = (annotationChanges) => {
        const { maxDivParticipant, participantList } = this.state;
        let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //TP-2549
        let experts = participantList && participantList.length > 0 && participantList.filter(p => p.isexpert);
        // to the max div technician and experts, when screen share going on
        const toObject = maxDivParticipant.email;
        this.sessionSignal.sendSignalAnnotate(annotationChanges, toObject, sixdigitsrandom); //TP-2549
        experts && experts.length > 0 && experts.forEach((participant) => {
            this.sessionSignal.sendSignalAnnotate(annotationChanges, participant.email, sixdigitsrandom); // send to each expert
        });
    }

    //TP-2474 -- Send the Annotation string/Object to all experts in that call
    sendAnnotationToOnlyExperts = (annotationChanges) => {
        const {participantList} = this.state;
        let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //TP-2549
        let experts = participantList && participantList.length > 0 && participantList.filter(p => p.isexpert);
        // Sending the annotation string & object to only the passive experts
        experts && experts.length > 0 && experts.forEach((participant) => {
            this.sessionSignal.sendSignalAnnotate(annotationChanges, participant.email, sixdigitsrandom); // send to each expert
        });
    }

    //TP-4737
    sendAnnotationToMaxViewUser = (annotationChanges) => {
        try{
            if(!this.state.isSideBarSS){
                let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //TP-2549
                const { maxDivParticipant } = this.state;
                if(!this.state.isMaxDivSS){
                    // to the max div technician and experts, when screen share going on
                    const toObject = maxDivParticipant.email;
                    this.sessionSignal.sendSignalAnnotate(annotationChanges, toObject, sixdigitsrandom);
                }
            }
        }catch(error){
            printConsole(error);
        }
    }

    //TP-4752
    enableButtons = (val) => {
        this.setState({isUndoDisabled: !val});
    }

    //TP-5675
    closeAnnotationOnPassiveExperts = () => {
        const { maxDivParticipant, participantList } = this.state;
        const experts = participantList && participantList.length > 0 && participantList.filter(p => p.isexpert === true);  
        experts && experts.forEach(p => {
            this.sessionSignal.sendSignalSyncExperts({
                show_grid: maxDivParticipant ? false : true,
                max_view_technician: maxDivParticipant ? maxDivParticipant.email : '', //TP-3893
                ratio: this.state.aspectRatio,
                annotation_active: false,
                hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                reason: "show_action"}, 
            p.email);  // send the max div tech details  
        });
    }

    //TP-4954
    clearAnnotationButtons = () => {
        this.setState({ bAnnotate: false,
            selectedTelestrate: ''
        }, () => {
            //TP-4500 -- Change the Passive expert's Shape and color Icons based on the incoming SYNC_EXPERTS Signal even when Screencast ongoing
            if (this.state.bAnnotate === true) {
                this.setState({ pencolor: 'red' },() => {
                    this.setState({shapesSetting : ''});                            
                });
            }
        });
    }

    onClickSetShapes = ({shapesSetting, bSelectShape, pencolor}) => {
        printConsole("set shapes/color is called");
        printConsole(`bSelectShape ${bSelectShape} & pencolor ${pencolor}`);
        printConsole(shapesSetting);
        this.setState({shapesSetting, bSelectShape, pencolor}, () => {
            const {participantList, maxDivParticipant, aspectRatio} = this.state;
            const {isFreeDrawing, isRectActive, isCircleActive, isArrowActive, isPointerActive, /**TP-2475 */isErasorActive, isTextActive} = this.state.shapesSetting;//TP-2586
            const experts = participantList.filter(p => p.isexpert);
            if (this.state.hasMaxDivControls && participantList.length >0 /**TP-4500*/) {
                let shape_selected = "";
                if (isFreeDrawing === true) shape_selected = "freehand";
                else if (isRectActive === true) shape_selected = "rectangle";
                else if (isCircleActive === true) shape_selected = "circle";
                else if (isArrowActive === true) shape_selected = "arrow";
                else if (isPointerActive === true) shape_selected = "pointer"; /**TP-2586 & TP-2475 */
                else if (isTextActive === true) shape_selected = "addText"; /**TP-4695*/
                //console.log("trigger Sync_experts from onClickSetShapes", this.state.bAnnotate);
                experts && experts.forEach(e => {
                    // T32-463 -- New Multi-expert Signal trigger implementation
                    this.sessionSignal.sendSignalSyncExperts({
                        show_grid: false,
                        max_view_technician: maxDivParticipant.email, //TP-3893
                        annotation_active: this.state.bAnnotate, 
                        zoom_button_active:  (maxDivParticipant.zoomLevel > 1) ? true : false,
                        zoom_level: maxDivParticipant.zoomLevel,
                        torch_button_active: maxDivParticipant.torchActive,
                        annotate_color: this.state.pencolor,
                        shape_selected,
                        hand_icon_enabled: true,
                        ratio: aspectRatio,
                        header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                        reason: "show_action"}, 
                    e.email);  // send the max div tech details                        
                })
                //TP-2474
                if (isErasorActive === true /**TP-4500*/ && (!this.state.isSideBarSS && !this.state.isMaxDivSS)) {
                    //TP-2474 -- Multi expert handling for Erasor feature being turned on by Primary expert
                    experts && experts.forEach(e => {
                        // T32-463 -- New Multi-expert Signal trigger implementation
                        this.sessionSignal.sendSignalSyncExperts({                            
                            reason: "canvas_erased"}, 
                        e.email);  // send the erasor turned on status to the Passive experts currently in session                        
                    })
                }
            }
        });
    }

    // start/stop remote capture
    onClickCamera = () => {
        let { bRemoteCapture, isLoading, bAnnotate } = this.state;
        let nameDiv = document.getElementsByClassName("text-center nameDiv");
        if(bRemoteCapture){
            printConsole("Close camera");              
            if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                nameDiv[0].classList.remove('annotate-webrtc');
            }
            this.stopRemoteCaptureAnnotation();// stop RC if on
            //turn the Grid View Icon as enabled after RC is stopped
            this.setState({enableGridView: true}, () => {
                this.setState({ enableGridView: false });
                this.setState({ selectedCamera: '', selectedTelestrate: '' });
                if (isLoading === true) {
                    //TP-5389 -- Show a different loader, during Cancel RC flow
                    this.setState({ isLoading: false }, () => {
                        this.setState({ isCancelRCLoading: true})
                        this.loaderTimer = setTimeout(() => {
                            this.setState({ isCancelRCLoading: false });  
                        }, 1500);
                    }); 
                }
            });
        }else{
            printConsole(`Start RC at ${performance.now()}`); //TP-4275
            if (bAnnotate === true) {
                printConsole("Annotations is already on when RC was clicked");
                //console.log("Clear out the Annotation Shapes settings to Default");
                //TP-2597 -- Handling of Shape picker icons when RC is clicked during Annotation
                this.setState({ isAnnotateOnWtRC: true }, () => {
                    this.setState({ shapesSetting: '' }, () => {
                        this.setState({ isAnnotateOnWtRC: false });
                    })                    
                })
            }
            // Make the Name Div background darker
            if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                const paramElem = document.getElementById(this.state.g_maxDivId);
                //TP-4532
                if (paramElem && paramElem !== null) {
                    const rect = paramElem.getBoundingClientRect();
                    //TP-5092
                    if (rect.width > 0)
                        nameDiv[0].style.maxWidth = rect.width + "px";
                    nameDiv[0].classList.add('annotate-webrtc');
                }
            }
            this.onRemoteClick();
        }
    }
    //MB2-556 
    stopRemoteCaptureAnnotation = () => {
        this.setState({ rcImgObj: '', format: ""},() =>{
            const { maxDivParticipant, participantList } = this.state;
            let experts = participantList && participantList.length > 0 && participantList.filter(p => p.isexpert);
            // to the max div technician and experts
            const toObject = maxDivParticipant.email;
            this.sessionSignal.sendSignalRCAnnotation(false, toObject)
            experts && experts.length > 0 && experts.forEach((participant) => {
                this.sessionSignal.sendSignalRCAnnotation(false, participant.email)
            });                 
            this.setState({bRemoteCapture: false, bAnnotate: false, urlObj: null}, () => {
                this.setState({ bSelectShape: false,
                    pencolor: 'red', shapesSetting: '', rcAnnotateStart: false });
            });
        });
    }

    //MB2-556 
    onRemoteClick = () => {
        try{
            const { maxDivParticipant, participantList } = this.state;
            let experts = participantList && participantList.length > 0 && participantList.filter(p => p.isexpert);
            const toObject = maxDivParticipant.email;
            this.sessionSignal.sendSignalRemoteCaptureClick( '', toObject );//max div technician
            experts && experts.length > 0 && experts.forEach((participant) => {
                this.sessionSignal.sendSignalExpertRCClicked('', participant.email); // send the rc is on to experts
            });                 
            this.setState({isLoading: true, bRemoteCapture: true});
        }catch(error){
            printConsole(error);
        }
    }

    // remote capture signals for passive expert
    processSignalExpertRCClicked = () => {
        // for NS2-141
        this.setState({rcStart: true, isLoading: true, selectedCamera : 'remoteCapture' });
    }

    getDateString = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${year}${month}${day}_${hour}${minutes}${seconds}`
    }

    // technician will capture image MB2-556
    captureCloudRcImg =  ({sender}) => {
        try {
            //console.log(this.state.isRCtriggered, this.state.bRemoteCapture);
            //TP-6065
            if (this.state.isRCtriggered === true) {
                printConsole(`trigger getImageData at ${performance.now()}`) //TP-4275
                // upload the image as a file to AWS
                //const {senderInfo} = this.state.remoteChanges;
                this.setState({ isLoading:true });
                const { g_maxDivId } = this.state;
                const {user} = this.props;
                let filearr = [];
                const imgData = this.getImgData({elemId: g_maxDivId});
                if(!imgData) {
                    this.setState({ isLoading:false }); 
                    return ;
                } 
                printConsole(`image data generated and sent ... at ${performance.now()}`) //TP-4275
                const {participantList} = this.state;
                //TP-4483
                if (participantList && participantList.length > 0) {
                    participantList.forEach(p => {
                        if (this.props.audio_mode === "tech2Tech")
                            this.sessionSignal.sendSignalVideoPaused(true, imgData, p.email, "png", "RC_ONGOING");
                        else {
                            if (p.isexpert)
                                this.sessionSignal.sendSignalVideoPaused(true, imgData, p.email, "png", "RC_ONGOING");
                        }
                    })
                }
                this.sessionSignal.sendSignalRCUploaded(imgData, sender.email, "png");
                //const today = this.getDateString();
                //let sFileName = `RCWA_${today}_${user.id}.png`;
                //const file = await this.urltoFile("data:image/png;base64," + imgData, sFileName, 'image/png');
                //filearr.push(file);
                //const uploadedFileInfo = await this.uploadAssetFile(filearr);// , config
                // get the frame image from the publisher stream
                this.setState({
                    format: "png",
                    rcImgObj: imgData,
                    selectedCamera: 'remoteCapture'                
                });
                // inform expert that the RC image is now ready to be downloaded
                /* if (window._env_.REACT_APP_APP_MODE !== "LAN"){
                    this.sessionSignal.sendSignalRCUploaded(imgData, senderInfo.email, "png");// sFileName
                }else{ */
                //}
            }

        }catch(e){
            printConsole(e);
        }
    }
    // get the canvas max div image
    getImgData = ({elemId}) => {
        const element = document.getElementById(elemId)
        if(elemId && element){
            let w, h, ratio;            
            ratio = element.videoWidth / element.videoHeight;
            w = element.videoWidth;
            h = Math.round(w * ratio);
            const canvas = document.createElement('canvas');
            canvas.width = w;
            canvas.height = h;
            canvas.style = { display: 'none', width: w + "px", height: h + "px" }
            //printConsole(canvas.width);
            //printConsole(canvas.height);
            document.body.appendChild(canvas);
            let imgData = null;
            try {
                try{
                    canvas.getContext('2d').imageSmoothingEnabled = true;
                    canvas.getContext('2d').imageSmoothingQuality = 'high';
                    canvas.getContext('2d').drawImage(element, 0, 0, w, h);
                }catch(error){
                    console.error('cannot draw image')
                }
                imgData = canvas.toDataURL('image/png');
            } catch (err) {
                canvas.parentNode.removeChild(canvas);
                printConsole(err)
                return null;
            }
            canvas.parentNode.removeChild(canvas);
            if (imgData === null || imgData === 'data:,') {
                printConsole('Cannot get image data yet');
                return null;
            }
            printConsole(`returning the image data at ${performance.now()}`); //TP-4275
            return imgData.replace('data:image/png;base64,', '').trim();
        }else{
            return null;
        }
    };
    
    urltoFile = async (url, filename, mimeType) => {
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename, {type:mimeType});})
        );    
    };

    // send the local rc signal
    startLocalRc = () => {
        const {primaryExpert, localRc} = this.state
        const toObject = primaryExpert.email;
        this.sessionSignal.sendSignalLocalRC( localRc , toObject );
    }

    // image captured and uploaded by technician. primary expert will process the image MB2-556
    onRcImgUploaded = ({assetData, recipient, senderInfo}) => {
        //printConsole("Remote capture image uploaded...");
        //const {assetData, recipient, senderInfo} = this.state.remoteChanges;
        let strFileName, format;
        if (typeof assetData === "object") {
            format = assetData.format;
            strFileName = assetData.data;
            printConsole("format=============>",format);

        } else {
            strFileName = window._env_.REACT_APP_APP_MODE !== "LAN" ? assetData : assetData[0].key 
            format = "";
        }
        
        if(strFileName.length <= 0){
            printConsole('remoteclickuploaded came back with empty filename');
            // close remote click mode
            this.onClickCamera();
        }else{
            this.setState({
                remoteChanges : {data: strFileName, format, recipient: recipient, senderInfo: senderInfo},
                selectedCamera : 'remoteCapture'
            }, ()=>{
                const {remoteChanges } = this.state;
                if (format !== "" && format !== undefined) {
                    if(this.state.hasMaxDivControls){
                        const {participantList} = this.state;
                        let experts = participantList && participantList.length > 0 && participantList.filter(p => p.isexpert);
                        experts && experts.length > 0 && experts.forEach((participant) => {
                            this.sessionSignal.sendSignalExpertRCUploaded(strFileName, participant.email, format);
                        });

                        this.sendSignalStartRCAnnotation({email: null});
                    }
                    this.processSocketRcImg(remoteChanges);
                }
                else {
                    if (window._env_.REACT_APP_APP_MODE !== "LAN"){
                        this.processCloudRcImg({fileName:remoteChanges.data});
                    }else{    
                        this.processLocalRCImg({fileName:remoteChanges.data, location: assetData[0].location});
                    }
                }
            });
        }
    } 

    processSocketRcImg = (remoteChanges) => {
        printConsole(`bRemoteCapture: ${this.state.bRemoteCapture}`);
        const { data, format } = remoteChanges;
        // condition to check who is the primary expert and has the max div control
        if(this.state.hasMaxDivControls){
            if (this.state.bRemoteCapture === false) return; //TP-1237
            printConsole("Primary expert image============>", data);            
            if(!this.state.isSideBarSS && !this.state.isMaxDivSS) this.startRemoteCaptureAnnotation();
            this.setState({bRemoteCapture: true, bAnnotate: true, fileName: data, format: format, urlObj: data, isLoading: false});
        }else{
            printConsole("Secondary expert image============>", data);  
            const shapesSetting = {
                isFreeDrawing: false, /**TP-2586 */
                isTextActive: false, /**TP-4695*/
                isRectActive: false,
                isCircleActive : false,
                isArrowActive: false,
                isPointerActive: false, /**TP-2475 */
                isErasorActive: false, /**TP-2474 */
                isUndoActive: false /**TP-4696 */
            }                      
            if (this.state.selectedCamera === 'remoteCapture') {
                this.setState({rcImgObj: data, format: format}, () => {
                    this.setState({bRemoteCapture: true, bAnnotate: true, 
                        pencolor: "red", shapesSetting, fileName: data, isLoading: false});// for passive expert
                });
            } else {
                this.setState({selectedCamera: 'remoteCapture', format: format}, ()=> {
                    this.setState({selectedCamera: 'remoteCapture', bRemoteCapture: true, bAnnotate: true, 
                    pencolor: "red", shapesSetting, rcImgObj: data, fileName: data, isLoading: false})
                })
            }
        }
    }
    // process the image and start annotation. Invoked for both the passive xperts and primary experts- MB2-556
    processCloudRcImg = ({fileName}) =>{
        try{
            printConsole("DownLoading image ============================>", fileName);
            // download the s3 file
            AWS.config.update(this.config);
            let s3 = new AWS.S3();
            s3.getObject({ Bucket: `${process.env.REACT_APP_S3_BUCKET}/${process.env.REACT_APP_S3_DIR}`, Key: fileName },(error, data) => {
                if (error != null) {
                    printConsole("Failed to retrieve a remote image object: " + error);
                }else{
                    let blob=new Blob([data.Body], {type: data.ContentType});
                    // condition to check who is the primary expert and has the max div control
                    if(this.state.hasMaxDivControls){
                        printConsole("Primary expert image============>", fileName);
                        const {participantList} = this.state;
                        let experts = participantList && participantList.length > 0 && participantList.filter(p => p.isexpert);
                        experts && experts.length > 0 && experts.forEach((participant) => {
                            this.sessionSignal.sendSignalExpertRCUploaded(fileName, participant.email, "");
                        });
                        let urlObj = URL.createObjectURL(blob);
                        if(!this.state.isSideBarSS && !this.state.isMaxDivSS) this.startRemoteCaptureAnnotation();
                        this.sendSignalStartRCAnnotation({email: null});
                        this.setState({bRemoteCapture: true, bAnnotate: true, fileName: fileName, urlObj, format: "", isLoading: false});
                    }else{
                        printConsole("Secondary expert image============>", fileName);                        
                        if (this.state.selectedCamera === 'remoteCapture') {
                            this.setState({rcImgObj: blob, format: "",}, () => {
                                this.setState({bRemoteCapture: true, fileName: fileName, isLoading: false});// for passive expert
                            });
                        } else {
                            this.setState({selectedCamera: 'remoteCapture', format: ""}, ()=> {
                                this.setState({selectedCamera: 'remoteCapture', bRemoteCapture: true, rcImgObj: blob, fileName: fileName, isLoading: false})
                            })
                        }
                    }
                }
            });
        }catch(error){
            printConsole(error)
        }
    }

    sendSignalStartRCAnnotation = ({email}) => {
        try {
            if(email === "") {
                this.sessionSignal.sendSignalRCAnnotation(true, email)
            } else {
                const experts = this.state.participantList.filter(p => p.isexpert);
                experts && experts.forEach(e => {
                    this.sessionSignal.sendSignalRCAnnotation(true, e.email);
                })
            }
            
        } catch (error) {
            printConsole("exception occured in sendSignalStartRCAnnotation method");
            printConsole(error);
        }
    }

    startRemoteCaptureAnnotation = () => {
        const { maxDivParticipant } = this.state;
        const toObject = maxDivParticipant.email;
        this.sessionSignal.sendSignalRCAnnotation(true, toObject)
    }

    processLocalRCImg = ({fileName, location}) => {
        //download remote file stored locally/incloud
        let path = protocol+'//'+hostname+'/';
        if (fileName.length > 0) {
            fetch(path+ location)
              .then(res => res.blob()) // Gets the response and returns it as a blob
              .then(blob => {
                // Here's where we get access to the blob
                const urlObj = URL.createObjectURL(blob);
                this.startRemoteCaptureAnnotation();
                this.sendSignalStartRCAnnotation({email: null});
                this.setState({bRemoteCapture: true, bAnnotate: true , urlObj, isLoading: false});
            });
        }
    }

    //For TP-1313 -- Setting the current show/hide state of the Primary expert's Request Dialog box
    setRequestMaxViewControl = (value) => {
        this.setState({ showControlRequestDialog: value });
    }

    processSignalSyncExperts = ({from, data}) => {
        switch (data.reason) {
            case "request_expert_sync":
                this.triggerExpertQueryMaxDiv({from});// Mb2-500
                break;
            case "show_action":
                this.triggerExpertViewControls({from, data});
                break;
            case "update_action":
                this.triggerExpertGridViewControls({from, data});
                break;
            case "request_expert_sync":
                this.triggerExpertQueryMaxDiv({from});// TP-1906
                break;
            case "max_view_fileviewer_sync":
                this.triggerExpertFileViewerSync({from, data}); //TP-4603
                break;
            default:                
        }
    }

    // handler for request_expert_sync- MB2-500
    triggerExpertQueryMaxDiv = ({from}) => {
        try{
            const {session_type, enable_user_video, /**TP-3679 */ audio_mode} = this.props;
            const {maxDivParticipant, remoteChanges, fileName, aspectRatio, participantList, rotationArray, opacityChanges} = this.state;
            printConsole("fileName===============>", fileName);
            const participantEmail = maxDivParticipant && maxDivParticipant.email ? maxDivParticipant.email : ""
            const participantIsExpert = maxDivParticipant && maxDivParticipant.isexpert;
            const strFileName = (remoteChanges.data !== '' && remoteChanges.data !== undefined) ? remoteChanges.data : fileName;
            printConsole("strFileName=================>",strFileName);
            if(this.state.hasMaxDivControls === true){
                const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, /**TP-2475 */ isPointerActive, isTextActive} = this.state.shapesSetting;
                let shape_selected = "";
                if (isFreeDrawing === true) shape_selected = "freehand";
                else if (isRectActive === true) shape_selected = "rectangle";
                else if (isCircleActive === true) shape_selected = "circle";
                else if (isArrowActive === true) shape_selected = "arrow";
                else if (isPointerActive === true) shape_selected = "pointer"; /**TP-2586 & TP-2475 */
                else if (isTextActive === true) shape_selected = "addText"; /**TP-4695*/
                
                // T32-463 -- New Multi-expert Signal trigger implementation
                this.sessionSignal.sendSignalSyncExperts({
                    isPrimary: true,
                    expertId: this.props.user.id,
                    email: this.props.user.email,
                    firstname: this.props.user.firstname,
                    lastname: this.props.user.lastname,
                    joinTimeStamp: this.props.user.joinTimeStamp,
                    reason: "primary_sync"},
                from.email);
                //TP-1547
                //printConsole(`participant list ---> ${JSON.stringify(participantList)}`);
                // Video turn off/on Signal to be sent to the newly joining expert 
                let payload = [];
                participantList && participantList.forEach(p => {
                    if (p!== undefined) {
                        let obj = {}
                        if (p.isexpert === false) {
                            obj = {
                                flag: p.disableVideo, 
                                userId: p.userId,
                                email: p.email
                            }
                        } else {
                            obj = {
                                flag: (session_type === "CM" || enable_user_video !== "my_camera_disabled") ? p.disableVideo : true, //TP-3679
                                userId: p.userId,
                                email: p.email
                            }
                        }
                        payload.push(obj);
                    }
                })
                printConsole(`payload for Video turn off signal --> ${JSON.stringify(payload)}`);
                if (payload && payload.length > 0)
                    this.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, from.email);

                //TP-3737 & TP-3532 -- New Socket signal to let the newly joining Expert to know this expert user's Video feed is turned on or not
                if (this.checkIfExpertCameraEnabled() === true) {
                    this.sessionSignal.sendSignalExpertVideoFeedEnabled(this.state.myVideoEnable, this.checkIfExpertCameraEnabled(), from.email); //TP-3683 & TP-3679                    
                } else {
                    this.sessionSignal.sendSignalExpertVideoFeedEnabled(this.checkIfExpertCameraEnabled(), this.checkIfExpertCameraEnabled(), from.email); //TP-3683 & TP-3679
                }
                // NS2-345 
                // These conditions are to take care of the situation when a Passive expert joins a session
                // with an already running RC in different stages.
                if(this.state.bRemoteCapture && strFileName === '' || strFileName == undefined){
                    this.sessionSignal.sendSignalSyncExperts({
                        show_grid: false,
                        max_view_technician: participantEmail, //TP-3893
                        ratio: aspectRatio,
                        annotation_active: this.state.bAnnotate, 
                        hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                        header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                        reason: "show_action"}, 
                    from.email);  // send the max div tech details                    
                    this.sessionSignal.sendSignalExpertRCClicked('', from.email); // send the rc is on
                }else if(this.state.bRemoteCapture && strFileName !== '' && strFileName !== undefined){
                    this.sessionSignal.sendSignalSyncExperts({
                        show_grid: false,
                        max_view_technician: participantEmail, //TP-3893
                        ratio: aspectRatio,
                        annotation_active: this.state.bAnnotate,
                        annotate_color: this.state.pencolor,
                        shape_selected,
                        hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                        header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                        reason: "show_action"}, 
                    from.email);  // send the max div tech details                     
                    this.sessionSignal.sendSignalExpertRCClicked('', from.email);
                    this.sessionSignal.sendSignalExpertRCUploaded(strFileName, from.email, remoteChanges.format);
                    this.sessionSignal.sendSignalRCAnnotation(true, from.email)
                } else if (this.state.bAnnotate && !this.state.bRemoteCapture) {
                    this.sessionSignal.sendSignalSyncExperts({
                        show_grid: false,
                        max_view_technician: participantEmail, //TP-3893
                        ratio: aspectRatio,
                        annotation_active: this.state.bAnnotate,
                        annotate_color: this.state.pencolor,
                        shape_selected,
                        hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                        header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                        reason: "show_action"}, 
                    from.email);  // send the max div tech details 
                } if (this.state.isSideBarSS || this.state.isMaxDivSS) {   
                    this.setState({sendExpertSS : true, sSParticipant: from}); 
                } else if ((this.state.isOneToOneFileShare || this.state.isHeaderFileShare) && (this.state.fileData !== null || this.state.externalAssets.length > 0)) {
                    // TP-6 -- this condition is to take care of the scenario when an new passive Expert joins a session
                    // while Share Assets is ongoing (from Side Bar/Max-view)
                    // When a new Passive expert joins his Hand Icon should be off
                    if (participantEmail !== "") { 
                        // T32-463 -- New Multi-expert Signal trigger implementation                        
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: participantEmail, //TP-3893
                            ratio: aspectRatio,
                            annotation_active: this.state.bAnnotate,
                            hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"}, 
                        from.email);  // send the max div tech details                             
                            
                    } else {
                        // T32-463 -- New Multi-expert Signal trigger implementation                    
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: true,
                            hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"},
                        from.email);  // send the "show_grid" value as true for Grid View Mode                        
                    }
                    if (this.state.fileData !== null)
                        !this.state.isSideBarSS && !this.state.isMaxDivSS && this.sessionSignal.sendSignalShareArtifacts(this.state.fileData, from.email);
                    else {
                        //printConsole(this.state.externalAssets);
                        !this.state.isSideBarSS && !this.state.isMaxDivSS && this.state.externalAssets.forEach(asset => {
                            asset.data = asset.data.replace('data:'+asset.type+';base64,', '').trim();
                            this.setState({fileData: asset}); //TP-5686
                            this.sessionSignal.sendSignalShareArtifacts(asset, from.email);
                        })

                    }                    
                    // If 3D file is shared then resend the last rotation change
                    const [closeIcon, canvas] = document.getElementsByClassName('asset-3d');
                    if (closeIcon && rotationArray !== null) {
                        this.rotationTimer = setTimeout(() => {
                            this.sessionSignal.sendSignal3DRotation(rotationArray, from.email);
                            clearTimeout(this.rotationTimer)
                        }, 3000);
                    } else if (opacityChanges !== '') {
                        // If not 3D file share ongoing then send the current Opacity value to 
                        // newly joining Passive expert
                        this.sessionSignal.sendSignalOpacity(opacityChanges, from.email); //TP-841
                    }
                } else{
                    // T32-567 -- this condition is to take care of the scenario when an new passive Expert joins a session, but RC isn't running.
                    if (participantEmail !== "") {
                        participantList && participantList.forEach((p) => {
                            if (p.email === participantEmail) {
                                this.sessionSignal.sendSignalSyncExperts({
                                    show_grid: false,
                                    max_view_technician: participantEmail, //TP-3893
                                    ratio: aspectRatio,
                                    annotation_active: this.state.bAnnotate,
                                    annotate_color: this.state.pencolor,
                                    shape_selected,
                                    torch_button_active: (p.torch_enabled) ? p.torchActive : false,
                                    zoom_button_active: parseInt(p.zoomLevel) > 1 || p.zoom_enabled ? true : false,
                                    zoom_level: (p.zoom_enabled) ? parseInt(p.zoomLevel) : 1,
                                    hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                                    header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                                    reason: "show_action"}, 
                                from.email);  // send the max div tech details 
                            }
                        });
                    } else {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: true,
                            hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            header_fileShare_active: (this.state.isHeaderFileShare && !this.state.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"}, 
                        from.email);  // send the show_grid flag as True for when in Grid View Mode                        
                    }
                }
                // Auto Sync of the Zoom & Torch button states with a newly joining Passive expert's screen
                if (this.state.groupInfo.zoom_enabled === true || this.state.groupInfo.torch_enabled === true) {
                    participantList && participantList.forEach((p) => {
                        if (p.torchActive === true || p.zoomLevel > 1) {
                            const obj = {                            
                                show_grid: true, 
                                email: p.email,                                    
                                torch_button_active: (p.torch_enabled) ? p.torchActive : false,
                                zoom_level: (p.zoom_enabled) ? parseInt(p.zoomLevel) : 1,
                                hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                                reason: "update_action"
                            }
                            this.sessionSignal.sendSignalSyncExperts(obj, from.email); // send the zoom & torch button states to the new Expert
                        }
                    })
                }
            }
        }catch(error){
            printConsole(error)
        }
    }

    //TP-4603
    triggerExpertFileViewerSync = ({from, data}) =>{
        if (this.state.hasMaxDivControls === false) {
            if (data.isMaxViewFileClose === true || this.state.isOneToOneFileShare === true)
            this.closeFileViewer();
        }
    }

    /**TP-5429*/
    setCameraNotDetected = (val) => {
        this.setState({ cameraNotDetected: val });
    }

    /**TP-3679 TP-3419 */
    checkIfExpertCameraEnabled = () => {
        const {enable_user_video, user} = this.props;
        let flag = false;
        if (enable_user_video === "my_camera_enabled" && this.state.cameraNotDetected === false /**TP-5429*/) {
            flag = true;
        } else if (enable_user_video === "my_camera_enabled_with_prefs" && user.my_camera_on === true && this.state.cameraNotDetected === false /**TP-5429*/) {
            flag = true;
        }
        return flag;
    }

    // T32-592 -- Handling the View Controls (start/stop) in the Passive expert
    // to Sync with the Primary expert
    triggerExpertViewControls = ({from, data}) => {
        const {show_grid, max_view_technician, annotation_active, annotate_color, shape_selected, header_fileShare_active} = data;
        if (this.state.isHeaderFileShare) this.setState({ isOneToOneFileShare: !header_fileShare_active });
        else this.setState({ isOneToOneFileShare: false });
        const {expertShareScreenMaxDiv} = this.state;
        if (expertShareScreenMaxDiv) {
            //TP-4497
            if (annotation_active !== undefined) {
                this.setState({ bAnnotate: annotation_active,
                    selectedTelestrate: '' /**TP-4954*/
                }, () => {
                    //TP-4500 -- Change the Passive expert's Shape and color Icons based on the incoming SYNC_EXPERTS Signal even when Screencast ongoing
                    if (this.state.bAnnotate === true) {
                        this.setState({ pencolor: annotate_color },() => {
                                switch (shape_selected) {
                                    case "arrow":
                                        this.setState({shapesSetting : 
                                            {
                                                isArrowActive: true,
                                                isFreeDrawing: false,
                                                isTextActive: false, /**TP-4695*/
                                                isRectActive: false,
                                                isCircleActive: false,
                                                isPointerActive: false, /**TP-2475 */
                                                isErasorActive: false, /**TP-2474 */
                                                isUndoActive: false /**TP-4696 */
                                            }
                                        });
                                        break;
                                    case "freehand":
                                        this.setState({shapesSetting :
                                            {
                                                isFreeDrawing: true,
                                                isTextActive: false, /**TP-4695*/
                                                isRectActive: false,
                                                isCircleActive: false,
                                                isArrowActive: false,
                                                isPointerActive: false, /**TP-2475 */
                                                isErasorActive: false, /**TP-2474 */
                                                isUndoActive: false /**TP-4696*/
                                            }
                                        });
                                        break;
                                    case "rectangle":
                                        this.setState({shapesSetting :
                                            {
                                                isFreeDrawing: false,
                                                isTextActive: false, /**TP-4695*/
                                                isRectActive: true,
                                                isCircleActive: false,
                                                isArrowActive: false,
                                                isPointerActive: false, /**TP-2475 */
                                                isErasorActive: false, /**TP-2474 */
                                                isUndoActive: false /**TP-4696*/
                                            }
                                        });
                                        break;
                                    case "circle":
                                        this.setState({shapesSetting :
                                            {
                                                isFreeDrawing: false,
                                                isTextActive: false, /**TP-4695*/
                                                isRectActive: false,
                                                isCircleActive: true,
                                                isArrowActive: false,
                                                isPointerActive: false, /**TP-2475 */
                                                isErasorActive: false, /**TP-2474 */
                                                isUndoActive: false /**TP-4696*/
                                            }
                                        });
                                        break; 
                                    /**TP-2586 */
                                    case "pointer":
                                        this.setState({shapesSetting :
                                            {
                                                isFreeDrawing: false,
                                                isTextActive: false, /**TP-4695*/
                                                isRectActive: false,
                                                isCircleActive: false,
                                                isArrowActive: false,
                                                isPointerActive: true, /**TP-2475 */
                                                isErasorActive: false, /**TP-2474 */
                                                isUndoActive: false /**TP-4696*/
                                            }
                                        });
                                        break; 
                                    //TP-4695 
                                    case "addText":
                                        this.setState({shapesSetting :
                                            {
                                                isFreeDrawing: false,
                                                isTextActive: true,
                                                isRectActive: false,
                                                isCircleActive: false,
                                                isArrowActive: false,
                                                isPointerActive: true, /**TP-2475 */
                                                isErasorActive: false, /**TP-2474 */
                                                isUndoActive: false
                                            }
                                        })   
                                        break;                          
                                    default:
                                    /**TP-2586 */
                                        this.setState({shapesSetting :
                                            {
                                                isFreeDrawing: false,
                                                isTextActive: false, /**TP-4695 */
                                                isRectActive: false,
                                                isCircleActive: false,
                                                isArrowActive: false,
                                                isPointerActive: false, /**TP-2475 */
                                                isErasorActive: false, /**TP-2474 */
                                                isUndoActive: false /**TP-4696 */
                                            }
                                        });
                                }    
                        });
                    }
                });
            } else if(show_grid === true && this.state.bAnnotate === true /* && max_view_technician === */) {
                //TP-5501 -- Use cases 3 & 4 with TP-5502
                this.setState({
                    bAnnotate: false,
                    selectedTelestrate: ''
                }, () => {
                    this.resetAnnotationShapesColors(true);
                });
            }
            return;
        }
        if (annotation_active !== undefined) {
            this.setState({
                bAnnotate: annotation_active,
                selectedTelestrate: annotation_active === true ? 'telestrate': ''
            });
            
            if (annotation_active === true) {                
                // Setting the Color & Shape selector Icon state in Passive expert
                if (annotate_color !== undefined && annotate_color !== ""){
                    this.setState({ pencolor: annotate_color },() => {
                        //TP-2586 --if (shape_selected !== undefined && shape_selected !== "") {
                            switch (shape_selected) {
                                case "arrow":
                                    //annotateShapesArray[4] = annotateShapesArray[3];
                                    this.setState({shapesSetting : 
                                        {
                                            isArrowActive: true,
                                            isFreeDrawing: false,
                                            isTextActive: false, /**TP-4695*/
                                            isRectActive: false,
                                            isCircleActive: false,
                                            isPointerActive: false, /**TP-2475 */
                                            isErasorActive: false, /**TP-2474 */
                                            isUndoActive: false /**TP-4696 */
                                        }
                                    });
                                    break;
                                case "freehand":
                                    //annotateShapesArray[4] = annotateShapesArray[0];
                                    this.setState({shapesSetting :
                                        {
                                            isFreeDrawing: true,
                                            isTextActive: false, /**TP-4695*/
                                            isRectActive: false,
                                            isCircleActive: false,
                                            isArrowActive: false,
                                            isPointerActive: false, /**TP-2475 */
                                            isErasorActive: false, /**TP-2474 */
                                            isUndoActive: false /**TP-4696*/
                                        }
                                    });
                                    break;
                                case "rectangle":
                                    //annotateShapesArray[4] = annotateShapesArray[2];
                                    this.setState({shapesSetting :
                                        {
                                            isFreeDrawing: false,
                                            isTextActive: false, /**TP-4695*/
                                            isRectActive: true,
                                            isCircleActive: false,
                                            isArrowActive: false,
                                            isPointerActive: false, /**TP-2475 */
                                            isErasorActive: false, /**TP-2474 */
                                            isUndoActive: false /**TP-4696*/
                                        }
                                    });
                                    break;
                                case "circle":
                                    //annotateShapesArray[4] = annotateShapesArray[1];
                                    this.setState({shapesSetting :
                                        {
                                            isFreeDrawing: false,
                                            isTextActive: false, /**TP-4695*/
                                            isRectActive: false,
                                            isCircleActive: true,
                                            isArrowActive: false,
                                            isPointerActive: false, /**TP-2475 */
                                            isErasorActive: false, /**TP-2474 */
                                            isUndoActive: false /**TP-4696*/
                                        }
                                    });
                                    break; 
                                /**TP-2586 */
                                case "pointer":
                                    //annotateShapesArray[4] = annotateShapesArray[1];
                                    this.setState({shapesSetting :
                                        {
                                            isFreeDrawing: false,
                                            isTextActive: false, /**TP-4695*/
                                            isRectActive: false,
                                            isCircleActive: false,
                                            isArrowActive: false,
                                            isPointerActive: true, /**TP-2475 */
                                            isErasorActive: false, /**TP-2474 */
                                            isUndoActive: false /**TP-4696*/
                                        }
                                    });
                                    break; 
                                //TP-4695 
                                case "addText":
                                    this.setState({shapesSetting :
                                        {
                                            isFreeDrawing: false,
                                            isTextActive: true,
                                            isRectActive: false,
                                            isCircleActive: false,
                                            isArrowActive: false,
                                            isPointerActive: true, /**TP-2475 */
                                            isErasorActive: false, /**TP-2474 */
                                            isUndoActive: false
                                        }
                                    })   
                                    break;                          
                                default:
                                /**TP-2586 */
                                    this.setState({shapesSetting :
                                        {
                                            isFreeDrawing: false,
                                            isTextActive: false, /**TP-4695 */
                                            isRectActive: false,
                                            isCircleActive: false,
                                            isArrowActive: false,
                                            isPointerActive: false, /**TP-2475 */
                                            isErasorActive: false, /**TP-2474 */
                                            isUndoActive: false /**TP-4696 */
                                        }
                                    });
                            }
                        //}

                    });
                }
            } else {
                // Setting the Color & Shape selector Icon state back to default when Annotation is stopped in Passive expert
                if (annotate_color !== undefined && annotate_color !== ""){
                    this.setState({ pencolor: annotate_color },() => {
                        // TP-2586 -- if (shape_selected !== undefined && shape_selected !== "") {
                            //annotateShapesArray[4] = annotateShapesArray[0];
                            this.setState({shapesSetting :
                                {
                                    isFreeDrawing: false, /**TP-2586 */
                                    isTextActive: false, /**TP-4695 */
                                    isRectActive: false,
                                    isCircleActive: false,
                                    isArrowActive: false,
                                    isPointerActive: false, /**TP-2475 */
                                    isErasorActive: false, /**TP-2474 */
                                    isUndoActive: false /**TP-4696*/
                                }
                            });                                    
                        //}
                    });
                }
            }
        } else if(show_grid === true && this.state.bAnnotate === true /* && max_view_technician === */) {
            //TP-5501 -- Use cases 3 & 4 with TP-5502
            this.setState({
                bAnnotate: false,
                selectedTelestrate: ''
            }, () => {
                this.resetAnnotationShapesColors(true);
            });
        }
    }

    // TP-207 -- Handling the Grid View Controls in the Passive expert to 
    // sync with the Primary expert's screen
    triggerExpertGridViewControls = ({from, data}) => {
        const {annotation_active} = data;
        const {expertShareScreenMaxDiv} = this.state;
        if (annotation_active !== undefined && !expertShareScreenMaxDiv) {
            this.setState({
                bAnnotate: annotation_active,
                selectedTelestrate: annotation_active === true ? 'telestrate': ''
            });
            //updatedChanges.selectedTelestrate = 'telestrate';
            //updatedChanges.bAnnotate = true;//MB2-489
        }        
    }
    
    startRCAnnotation = ({email}) => {
        const {fileName, remoteChanges} = this.state;
        printConsole(`filename -------> ${fileName}`);
        printConsole(remoteChanges);
        const strFileName = (remoteChanges.data !== '' && remoteChanges.data !== undefined) ? remoteChanges.data : fileName;
        //printConsole("strFileName");
        //printConsole(strFileName);
        //printConsole(remoteChanges.format);
        this.sessionSignal.sendSignalExpertRCUploaded(strFileName, email, remoteChanges.format);
        this.sessionSignal.sendSignalRCAnnotation(true, email)
    }

    //TP-2853
    updateAnnotateLoader = (val) => {
        //console.log(val, this.state.isLoading);
        /**TP-2959 */
        if (val === true && this.state.isLoading === true) return;
        this.setState({ annotateLoader: val });
    }

    //TP-2959
    updateResizeWAnnotate = (val) => {
        this.setState({ onResizeWAnnotate: val });
    }

    //TP-4585
    reRenderAnnotateCanvas = () => {
        if (this.state.hasMaxDivControls === true) {
            this.setState({ reRenderCanvas: true }, () => {
                this.setState({ reRenderCanvas: false });
            })
        }
    }

    //TP-4772
    triggerRerenderPassiveCanvas = () => {
        this.setState({ redrawPassiveCanvas: true }, () => {
            this.setState({ redrawPassiveCanvas: false });
        })
    }

    //TP-4769
    triggerRedrawTechCanvas = () => {
        this.setState({ redrawTechCanvas: true }, () => {
            this.setState({ redrawTechCanvas: false });
        })
    }

    /*startAutoReconnectTimer = () => {
        this.setState({
            autoReconnectTimer : 30
        },
        () => {
            this.reconnectTimer = setInterval(this.decrementTimerCounter.bind(this), 1000)
        });
    }

    decrementTimerCounter = () => {
        this.setState((prevState) =>({
            autoReconnectTimer: prevState.autoReconnectTimer - 1
        }),() => {
            if(this.state.autoReconnectTimer === 0){
                this.triggerReconnecting(false);
            }
        });
    }

    triggerReconnecting = (val) => {
        this.setState({ isReconnecting: val });
        if (val === true)
            this.startAutoReconnectTimer();
        else 
            this.setState({ triggerStopSession: true })
    }*/

    initcheckDownloadUploadSpeed = () => {
        this.downloadTimer = setInterval(this.checkDownloadSpeed.bind(this), 60000);
        this.uploadTimer = setInterval(this.checkUploadSpeed.bind(this), 60000);
    }

    stopcheckDownloadUploadSpeed = () => {
        clearInterval(this.downloadTimer);
        clearInterval(this.uploadTimer);
    }
    
    // MB2-588
    checkDownloadSpeed = async () => {
        try{
            const speedInMBPS = await calculateDownloadSpeed();
            this.setState({downLoadSpeed: speedInMBPS})
        }catch(error){
            printConsole(error)
        }
    }

    // MB2-588
    checkUploadSpeed = async () => {
        try {
            const speedInMBPS = await calculateUploadSpeed();
            this.setState({uploadSpeed: speedInMBPS})
        }catch(e){
            printConsole(e);
        }
    }

    checkSmallScreenDevice = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let flag = false;
        if (isiPad || isAndroid) {
            flag = true;
        }
        return flag;
    }

    render(){
        const {user, isSafari, isiOS, isAndroid} = this.props;
        const {selectedAction, assetRecipient, localAssets, externalAssets, removeArtifacts, isLoading, isCancelRCLoading,
        rotationChanges, opacityChanges, annotation, bAnnotate, g_maxDivId, isHeaderFileShare, isOneToOneFileShare,
        selectedTelestrate, selectedCamera, urlObj, bRemoteCapture, rcStart, rcImgObj, shapesSetting,
        bSelectShape, pencolor, showCancel, fileLoading, cameraLoading, hasMaxDivControls, remoteChanges, 
        isReconnecting, format, isAnnotateOnWtRC, isAnnotateOnWtExpertSS, rcAnnotateStart, is3DFile, isPDFFile, isAudioFile, redraw,
        rotationArray /**TP-2085*/, onResizeWPointer, onResizeWText, /**TP-5073*/ annotateLoader /**TP-2853*/, onResizeWAnnotate, /**TP-2959*/ isSmallFormFactorDevice,
        redrawTechCanvas /**TP-4769*/} = this.state;
        if(user.isexpert){
            return (
                <I18n>
                    {({ i18n }) => 
                        <>
                            { hasMaxDivControls && bRemoteCapture && isLoading ?
                                <span className="stop-rc fa-stack fa-3x" >
                                    <SessionIcon id="stopRC" circleClass="fas fa-circle fa-stack-2x"
                                        iconClass="fas fa-times fa-stack-1x" tooltipPlament="top"
                                        innerClass="tip-max no-pointer-events" tooltip="session.stopRC" tooltipStyle="Trans"
                                        onClickMethod = {() => this.onClickAction({action: 'remoteCapture'})} 
                                        isSmallFormFactorDevice />
                                    {/* <a id="stopRC" onClick={() => this.onClickAction({action: 'remoteCapture'})}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-times fa-stack-1x" ></i>
                                        <UncontrolledTooltip innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="stopRC">                                            
                                            <Trans>session.stopRC</Trans>                                            
                                        </UncontrolledTooltip>
                                    </a> */} 
                                </span>
                                :
                                ''
                            }
                            { hasMaxDivControls && showCancel === true ?
                                <span className="stop-rc fa-stack fa-3x" >
                                    <SessionIcon id="stopFileShare" circleClass="fas fa-circle fa-stack-2x"
                                        iconClass="fas fa-times fa-stack-1x" tooltipPlament="top"
                                        innerClass="tip-max no-pointer-events" tooltip="session.stopFileShare" tooltipStyle="Trans"
                                        onClickMethod = {() => this.onClickCancelShareAsset()} 
                                        isSmallFormFactorDevice />
                                    {/* <a id="stopFileShare" onClick={() => this.onClickCancelShareAsset()}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-times fa-stack-1x" ></i>
                                        <UncontrolledTooltip innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="stopFileShare">                                            
                                            <Trans>session.stopFileShare</Trans>                                            
                                        </UncontrolledTooltip>
                                    </a>  */}
                                </span>
                                :
                                ''
                            }
                            <Loader text="Swap to default camera" isLoading={cameraLoading} />
                            <Loader text="Loading RC image..." isLoading={isLoading} />
                            <Loader text="Canceling RC..." isLoading={isCancelRCLoading} /> {/**TP-5389*/}
                            <Loader text="Uploading...." isLoading={fileLoading} />
                            {/**TP-2853 */}
                            <Loader text="Annotation Canvas is starting up" isLoading={annotateLoader} />
                            <WebrtcExpert 
                                {...this.props}
                                setMaxDivId={this.setMaxDivId}// to set max div id
                                setGroupInfo={this.setGroupInfo} // to set the sessiondata
                                isHeaderFileShare={isHeaderFileShare}// To control toggling of menu file share button
                                isOneToOneFileShare={isOneToOneFileShare}// To control toggling of max div file share button
                                is3DFile={is3DFile} // To control show & Hide of the FileName & Close button
                                isPDFFile={isPDFFile} // To control the UI in case of PDF file rendering
                                isAudioFile={isAudioFile} // To hide the opacity controller for Audio files
                                openFileUpload={this.openFileUpload}// to be used in Webrtc sidebar
                                setAssetOpacity={this.setAssetOpacity} // to be triggerd by Primary expert only
                                onClose3dCanvas={this.onClose3dCanvas}// to be used in Webrtc sidebar
                                setStartAssetsShare={this.state.setStartAssetsShare} //to set the setStartAssetsShare flag to true/false (during recording)
                                onClickAction={this.onClickAction}// max div icon onclick method
                                onClickSetShapes={this.onClickSetShapes}// shapes support
                                bAnnotate={bAnnotate}// indicate annotation is on in StreamActions
                                bRemoteCapture={bRemoteCapture}// indicate rc is on in StreamActions
                                pencolor={pencolor} // indicate the changes in the Annotations Color
                                shapesSetting={shapesSetting} // indicate the changes in the Annotations Shape
                                fileData={this.state.fileData} // to send the shared file URL to storage for sharing to any new user that joins                                
                                rotationArray={this.state.rotationArray} // to send the 3D file's rotation changes for sharing to any new user that joins 
                                opacityChanges={this.state.opacityChanges} // to send the non 3D file's opacity changes for sharing to any new user joining
                                rcStart={rcStart} // indicate if RC has started or not on Passive Experts
                                setPrimaryExpert={this.setPrimaryExpert}// keep atrack of primary expert in webrtc expert
                                setHasMaxDivControls={this.setHasMaxDivControls}// used to indicate who has the max div control/passive expert
                                setRemoteTrackLoader={this.setRemoteTrackLoader}// used for hiding the loader when first track is received
                                setSS={this.setSS}// mark which ss going on side bar or max div in webrtc expert
                                setSSParams={this.setSSParams}// set the trigger screen share param as false
                                sendExpertSS={this.state.sendExpertSS}// to trigger the screen share for a single Participant
                                setMaxDivSS={this.setMaxDivSS} // to set the screen share mode on/off param on passive expert side
                                setMaxAnnotate={this.setMaxAnnotate} // to set the Annotation state to default when Screen share is stopped
                                setAnnotateDuringPrimaryExpertLeaving={this.setAnnotateDuringPrimaryExpertLeaving} //TP-2491 to set/unset the Annotate button when Primary expert leaves during RC mode
                                stopPointerWhPassiveBecomesPrimary={this.stopPointerWhPassiveBecomesPrimary} //TP-2824 when Passiv becomes Primary (disconnection) stop Pointer for all users
                                sSParticipant={this.state.sSParticipant}// to trigger screen share for this participant
                                setParticipant={this.setParticipant}// set the partcipant list to be invoked in updateJoinees webrtcsessionuser
                                setMyVideoState={this.setMyVideoState} //set my Video current state --TP-3737
                                setTechAspectRatio={this.setTechAspectRatio}// set the video resolution height/width ratio for the Max-view technician
                                checkAnnotation={this.checkAnnotation}// check annotation just ss starts and stop it
                                downLoadSpeed={this.state.downLoadSpeed}
                                uploadSpeed={this.state.uploadSpeed}
                                checkDownloadSpeed={this.checkDownloadSpeed}
                                checkUploadSpeed={this.checkUploadSpeed}
                                setAnnotate={this.setAnnotate} // setting/un-setting the bAnnotate flag from the Expert side
                                setRCStart={this.setRCStart} // setting/un-setting the rcStart flag from the Expert side
                                // clearSessionToken={this.clearSessionToken} // clear the session token for that group id
                                cleanSessionToken={this.cleanSessionToken} // clear out the session token when expert times out
                                enableGridView={this.state.enableGridView} // to make the Side Bar Grid Icon enabled after a feature is cleared out
                                startRCAnnotation={this.startRCAnnotation} // To trigger the RC annotation signals from new Primary expert
                                closeFileViewer={this.closeFileViewer} // To close the 3D/non-3D file share feature
                                sendSharedArtifactsMaxDiv={this.sendSharedArtifactsMaxDiv} // To re-send the Shared files to only Max-div user
                                sendArtifactsToAll={this.sendArtifactsToAll} // To re-send the Shared files to all users in session
                                shareFileName={this.state.shareFileName} // to send the Shared Assets name to the expert component
                                setRequestMaxViewControl={this.setRequestMaxViewControl} //TP-1313 -- to set the showControlRequestDialog flag to true/false
                                setBufferColorShape={this.state.setBufferColorShape} //TP-2377
                                setColorShape={this.state.setColorShape} //TP-2377
                                isAnnotateOnWtRC={this.state.isAnnotateOnWtRC} //TP-2597
                                updateAnnotateLoader={this.updateAnnotateLoader} //TP-2853
                                onResizeWAnnotate={onResizeWAnnotate} /*TP-2959*/
                                updateResizeWAnnotate={this.updateResizeWAnnotate} /**TP-2959*/
                                reRenderAnnotateCanvas={this.reRenderAnnotateCanvas} //TP-4585
                                isUndoDisabled={this.state.isUndoDisabled} //TP-4752
                                triggerRerenderPassiveCanvas={this.triggerRerenderPassiveCanvas} //TP-4772
                                clearAnnotationButtons={this.clearAnnotationButtons} //TP-4954
                                setCameraNotDetected={this.setCameraNotDetected} /**TP-5429*/
                                closeRC={this.state.closeRC} /**TP-6170*/
                            />
                            <ActionRenderer 
                                action={selectedAction || selectedTelestrate || selectedCamera} parentId="allVideos" canvasParent="canvasDiv" assetRec={assetRecipient} 
                                localAssets={localAssets} externalAssets={externalAssets} bCancelShare={bCancelShare} updateCancelShare={this.updateCancelShare} redraw={redraw} rotationChanges={rotationChanges} /**TP-2085*/ rotationArray={rotationArray} fnSend3dRotation={this.onSend3dRotation} opacityChanges={opacityChanges} fnSendFileOpacity={this.onSendFileOpacity} isSafari={isSafari} isiOS={isiOS} isAndroid={isAndroid}
                                i18n={i18n} fnClose3dCanvas={this.onClose3dCanvas} user={user} removeArtifacts={removeArtifacts} annotation={annotation} 
                                onSendAnnotation = {this.onSendAnnotation} sendAnnotationExpertSS = {this.sendAnnotationExpertSS} onSendAnnotationOnlyExpert = {this.sendAnnotationToOnlyExperts} /**TP-2474 */ sendAnnotationToMaxViewUser={this.sendAnnotationToMaxViewUser} /**TP-4737*/ enableButtons={this.enableButtons} /**TP-4752*/ redrawPassiveCanvas={this.state.redrawPassiveCanvas} /**TP-4772*/ redrawPassiveWhenResized={this.state.redrawPassiveWhenResized} /**TP-4930*/ bAnnotate = {bAnnotate} g_maxDivId={g_maxDivId} sideBar="sideBar" bShareAsset={isHeaderFileShare} stopPointerWhenControlTransfer={this.state.stopPointerWhenControlTransfer} /**TP-2824 */ reRenderCanvas={this.state.reRenderCanvas} /**TP-4585*/
                                urlObj = {urlObj} format = {format} isAnnotateOnWtRC = {isAnnotateOnWtRC} isAnnotateOnWtExpertSS = {isAnnotateOnWtExpertSS} rcAnnotateStart = {rcAnnotateStart} bRemoteCapture={bRemoteCapture} rcStart={rcStart} rcImgObj={rcImgObj} shapesSetting = {shapesSetting} onResizeWPointer={onResizeWPointer} /*TP-2560*/ onResizeWText={onResizeWText} /**TP-5073*/
                                bSelectShape ={bSelectShape} pencolor={pencolor} hasMaxDivControls = {hasMaxDivControls} updateAnnotateLoader={this.updateAnnotateLoader} /**TP-2853 */ isSmallFormFactorDevice={isSmallFormFactorDevice}
                            />
                            {/**TP-4315*/}
                            <Modal id="fileuploaderror" size="lg" isOpen={this.state.showError}>
                                <ModalBody className="w-100 text-center row justify-content-center align-items-center">
                                    <div className="__modal-body pb-0">
                                        <h2><Trans>File format not supported anymore.</Trans></h2>
                                    </div>
                                </ModalBody>
                            </Modal>
                        </>
                    }
                </I18n>
            )
        }else{
            return (
                <I18n>
                    {({ i18n }) => 
                        <>
                            {/* <Reconnecting text="Reconnecting" showAnimation={isReconnecting}></Reconnecting> */}
                            <Loader text="RC image sent to expert" isLoading={isLoading} loaderValues={{role: this.props.customerRoles.expert}} />
                            <WebrtcTech
                                {...this.props}
                                setMaxDivId={this.setMaxDivId}// to set max div id
                                setGroupInfo={this.setGroupInfo} // to set the sessiondata
                                isHeaderFileShare={isHeaderFileShare}// To control toggling of menu file share button
                                isOneToOneFileShare={isOneToOneFileShare}// To control toggling of max div file share button
                                is3DFile={is3DFile} // To control show & Hide of the FileName & Close button
                                isPDFFile={isPDFFile} // To control the UI in case of PDF file rendering
                                isAudioFile={isAudioFile} // To hide the opacity controller for Audio files
                                setPrimaryExpert={this.setPrimaryExpert}
                                onClickAction={this.onClickAction}
                                localRc={this.state.localRc}
                                rcStart={this.state.rcStart}//MB2-512
                                bAnnotate={this.state.bAnnotate}
                                externalAssets={this.state.externalAssets}
                                checkAnnotation={this.checkAnnotation}
                                setParticipant={this.setParticipant}
                                downLoadSpeed={this.state.downLoadSpeed}
                                uploadSpeed={this.state.uploadSpeed}
                                checkDownloadSpeed={this.checkDownloadSpeed}
                                checkUploadSpeed={this.checkUploadSpeed}
                                setMaxDivSS={this.setMaxDivSS} // to set the screen share mode on/off param on technician side
                                setRCStart={this.setRCStart} // setting/un-setting the rcStart flag from the Expert side
                                closeFileViewer={this.closeFileViewer} // To close the 3D/non-3D file share feature
                                shareFileName={this.state.shareFileName} // To send the Shared Asset's name to the technician component
                                triggershowRC={this.state.triggershowRC} //TP-4261 -- To start showing the LC icon on technician (if in Max-view of expert)
                                /* triggerReconnecting={this.triggerReconnecting} */ // to trigger the reconnecting UI on the technician
                                resetTechnicianFeature={this.resetTechnicianFeature}//TP-4319 -- to cleanly end all features when all experts leave the tech2tech enabled session
                                triggerRedrawTechCanvas={this.triggerRedrawTechCanvas} //TP-4769 -- to redraw the technician side canvas when Max-view user is changed
                            />
                            <ActionRenderer 
                                action={selectedAction || selectedTelestrate || selectedCamera} parentId="allVideos" canvasParent="canvasDiv" assetRec={assetRecipient} 
                                localAssets={localAssets} externalAssets={externalAssets} rotationChanges={rotationChanges} opacityChanges={opacityChanges} fnSend3dRotation={this.onSend3dRotation} isSafari={isSafari} isiOS={isiOS} isAndroid={isAndroid}
                                i18n={i18n} fnClose3dCanvas={this.onClose3dCanvas} user={user} removeArtifacts={removeArtifacts} annotation={annotation} 
                                onSendAnnotation = {this.onSendAnnotation} bAnnotate = {bAnnotate} g_maxDivId={g_maxDivId} sideBar="sideBar"
                                urlObj = {urlObj} bRemoteCapture={bRemoteCapture} rcStart={rcStart} rcAnnotateStart={rcAnnotateStart} rcImgObj={rcImgObj} shapesSetting = {shapesSetting}  onResizeWPointer={onResizeWPointer} /*TP-2560*/ onResizeWText={onResizeWText} /**TP-5073*/
                                redrawTechCanvas={redrawTechCanvas} /**TP-4769*/ bSelectShape ={bSelectShape} pencolor={pencolor} isSmallFormFactorDevice={isSmallFormFactorDevice}
                            />
                        </>
                    }
                </I18n>
            )
        }
    }
}

export default WebrtcSession;