import React from 'react';
import './Signin.scss';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import { printConsole } from '../helpers';
import {UncontrolledTooltip} from 'reactstrap';

class SigninForm extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            signInEmail: "", signInPassword: "", showMobileForm: props.displayMobileForm,
            timer: 0, smsCount: 0, isMobileLoginAllowed: false //TP-6479
        };
        this.inputReference = React.createRef();
    }

    componentWillUnmount() {
        if (this.otpTimer) {
          clearInterval(this.otpTimer);
        }
    }

    componentDidMount() {
        if(this.props.otpSend){
            this.setOTPTimer();
        }
        this.checkSmsButton();
        this.trackTimeZone();//TP-6479
    }

    componentDidUpdate(prevProps, prevState) {
        let storageSmsCount = typeof(Storage) !== "undefined" && sessionStorage.getItem("smsCount") ? parseInt(sessionStorage.getItem("smsCount")) : null;
        //otpsend is set to true and smscount is less than 3 start timer
        if(prevProps.otpSend !== this.props.otpSend && this.props.otpSend && (storageSmsCount && storageSmsCount < 3)){
            this.setOTPTimer();
        }
        if(prevProps.smsCount !== this.props.smsCount){
            this.checkSmsButton(storageSmsCount);//required to enable sms button using stopOTPTimer
        }
    }

    trackTimeZone = () => {
        try{
            //Set the timexone
            let timeZone = sessionStorage.getItem("timeZone");
            if (!timeZone || timeZone !== Intl.DateTimeFormat().resolvedOptions().timeZone){
                timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                sessionStorage.setItem("timeZone", timeZone);
            }
            printConsole(`timezone - ${timeZone}`)
            if(timeZone === "Asia/Calcutta"){
                this.setState({isMobileLoginAllowed: true})
            }
        }catch(error){
            printConsole(`error in trackTimeZone ${error}`)
        }
    }

    checkSmsButton = (storageSmsCount) => {
        if(storageSmsCount){
            this.setState({smsCount : parseInt(storageSmsCount)})//storage takes precedence over props because user can refresh page which will override the timer
        }else{
            this.setState({smsCount : this.props.smsCount})
        }
    }

    updateEmail = (event) => {
        /* this.setState({
            signInEmail: event.target.value
        }, () => { */
            this.props.onEmailChange(event.target.value);
        //})        
    }

    updatePassword = (event) => {
        /* this.setState({
            signInPassword: event.target.value
        }, () => { */
            this.props.onPasswordChange(event.target.value);
        //})
    }

    onClickSubmit = (event) => {
        event.preventDefault();
        this.props.onSubmitSignIn();
    }

    //TP-5751
    changeLoginForm = (e, flag) => {
        e.preventDefault();
        this.setState({ showMobileForm: flag }, () => {this.inputReference.current && this.inputReference.current.focus()});
        this.props.hideMobileForm(flag);
        if(!flag){
            this.props.showHideRegistrationForm(true);
        }
    }

    //TP-5751
    onClickRegistrationLink = (flag) => (event) => {
        if(event){
            event.preventDefault();
        }
        this.inputReference.current && this.inputReference.current.focus()        
        this.props.showHideRegistrationForm(flag)
    }

    //TP-5751
    onClickSubmitMobileLogin = (e) => {
        const {otpSend, isMobileRegistered, getOTPType} = this.props;
        const code = e.keyCode || e.which;
        if(code && code !== 13) { //13 is the enter keycode
            return
        }
        if(otpSend && !isMobileRegistered && getOTPType === "register"){
            this.props.registerMobile(e)
        }else if(otpSend){
            e.preventDefault();
            this.props.mobileLogin()
        }else{
            this.props.onSubmitGetOtp(e)
        }
    }

    //TP-5751
    setOTPTimer = () => {
        if(this.otpTimer){
            this.setState({timer: 0})
            this.clearOTPTimer();//TP-5884,5948 clear the previous timer if present, can happen during registration
        }
        this.otpTimer = setInterval(() => {
            this.setState((prevState) => ({timer: prevState.timer +1}), () => {
                if(this.state.timer >= 30){
                    this.setState({timer: 0})
                    this.clearOTPTimer();
                }
            })
        }, 1000);
    }

    clearOTPTimer = () => {
        clearInterval(this.otpTimer);
    }

    handleOTPChange = (e, elementId, index) => {
        let {otp} = this.props;
        //focus only when next element is there and value is not empty
        if(elementId && e.target.value.length){
            document.getElementById(elementId).focus();
        }
        const splitOTP = otp.split("");//split and replace index wise, required for deleting existing single character
        splitOTP[index] = e.target.value? e.target.value.trim().slice(-1) : " ";//take last char using slice and replace empty string with blank character
        otp = splitOTP.join("");//join and assign
        printConsole(`otp- ${otp}`);
        this.props.setOTP(otp);
        //detect enter on the last digit of otp 
        if(!elementId){
            const code = e.keyCode || e.which;
            if(code && code === 13) { //13 is the enter keycode
                this.onClickSubmitMobileLogin(e)
            }
        }
    }

    //TP-6409
    clearOTP = (e, elementId) => {
        e.preventDefault();
        const otp = new Array(7).join(" ");//TP-6453
        this.props.setOTP(otp);
        if(elementId){
            document.getElementById(elementId).focus();
        }
    }

    render = () => {
        const {showMobileForm, timer, smsCount, isMobileLoginAllowed} = this.state
        const {mobile, otpSend, otp, isMobileRegistered, isSmartVision, isSmallFormFactor, noInternet, canDoOTPLogin, canRequestOtp} = this.props;
        const splitOTP = otp ? otp.split("") : new Array(6);
        const hClasses = "text25 highlight-text";
        const disableGoButton = otpSend? (otp && !noInternet && canDoOTPLogin) : (mobile && !noInternet && canDoOTPLogin && canRequestOtp)
        this.props.validator.purgeFields();
        return (
            <>
                {
                    isMobileLoginAllowed ?
                        <>
                            <div className="panel-heading">
                                <div className="row">
                                    <div className={`col-6 text-center cursor-pointer white ${!showMobileForm ? hClasses : "signin-link-font-lg"}`} onClick={(e) => this.changeLoginForm(e, false)}>
                                        <Trans>Login with Email</Trans>
                                    </div>
                                    <div className={`col-6 text-center cursor-pointer white ${showMobileForm ? hClasses : "signin-link-font-lg"}`} onClick={(e) => this.changeLoginForm(e, true)}>
                                        <Trans>Login with Mobile</Trans>
                                    </div>
                                </div>
                            </div>

                            {
                                showMobileForm ?
                                    <>
                                        <div className="flex-center"> {/**TP-2320*/}
                                            <I18n>
                                                {({ i18n }) => 
                                                    <>
                                                        <input 
                                                            className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-input-field signin-input-font-lg mobileIcon" : "signin-input-field signin-input-font-md mobileIcon"} 
                                                            name={i18n._(t`mobile`)} type="tel" placeholder={i18n._(t`Enter your mobile`)} value={mobile} onChange={this.props.handleChange('mobile')} disabled={otpSend}
                                                            onKeyPress={(e)=> e.key === 'Enter' && this.props.onSubmitGetOtp(e)} ref={this.inputReference} 
                                                        />
                                                        {this.props.validator.message(i18n._(t`mobile`), mobile, 'required|phone|min:10|max:10')}
                                                    </>
                                                }
                                            </I18n>
                                        </div>
                                        <div className="row mt-1">
                                            <div className="col-6 forget-password-link">
                                                {window._env_.REACT_APP_APP_MODE !== "LAN" && otpSend?
                                                    <>
                                                        <a href="" onClick={this.props.onSubmitGetOtp} className={(timer > 0 || smsCount >= 3) ? "btn disabled" : ""}><Trans>Resend OTP</Trans></a>
                                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                                        {
                                                            timer > 0 ? <span className="white">{30 - parseInt(timer)}</span> : null
                                                        }
                                                    </>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div className="col-6 forget-password-link text-right">
                                                {window._env_.REACT_APP_APP_MODE !== "LAN" && isMobileRegistered?
                                                    <a href="" onClick={this.onClickRegistrationLink(false)}><Trans>Register</Trans></a>
                                                    :
                                                    otpSend?
                                                        null
                                                        :
                                                        <a href="" onClick={this.onClickRegistrationLink(true)}><Trans>Login if already registered</Trans></a>
                                                }
                                            </div>
                                        </div>
                                        {
                                            otpSend?
                                                <div className="flex-center">
                                                    <div className="d-flex flex-row justify-content-center mt-2">
                                                        <I18n>
                                                            {({ i18n }) => 
                                                                <>
                                                                    <input className="mx-2 text-center otp" name={i18n._(t`otp`)} value={splitOTP[0]} type="text" id="first" onChange={(e) => this.handleOTPChange(e, "second", 0)}/>
                                                                    <input className="mx-2 text-center otp" name={i18n._(t`otp`)} value={splitOTP[1]} type="text" id="second" onChange={(e) => this.handleOTPChange(e, "third", 1)}/>
                                                                    <input className="mx-2 text-center otp" name={i18n._(t`otp`)} value={splitOTP[2]} type="text" id="third" onChange={(e) => this.handleOTPChange(e, "fourth", 2)}/>
                                                                    <input className="mx-2 text-center otp" name={i18n._(t`otp`)} value={splitOTP[3]} type="text" id="fourth" onChange={(e) => this.handleOTPChange(e, "fifth", 3)}/>
                                                                    <input className="mx-2 text-center otp" name={i18n._(t`otp`)} value={splitOTP[4]} type="text" id="fifth" onChange={(e) => this.handleOTPChange(e, "sixth", 4)}/>
                                                                    <input className="mx-2 text-center otp" name={i18n._(t`otp`)} value={splitOTP[5]} type="text" id="sixth" onChange={(e) => this.handleOTPChange(e, "loginButton", 5)}/>
                                                                    <a id="clearEmailOtp" className="mx-1 mt-5" href="#" onClick={(e) => this.clearOTP(e, "first")} style={{color: 'white'}}><i className="fas fa-redo"></i></a>
                                                                    {this.props.validator.message(i18n._(t`otp`), otp.trim(), 'required|otp_length|integer', {messages: {integer: i18n._(t`OTP must be digits`)}})}{/**TP-6474 */}
                                                                    { isSmallFormFactor === false ? 
                                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="clearEmailOtp">
                                                                            <Trans>Clear OTP</Trans>
                                                                        </UncontrolledTooltip>       
                                                                        :
                                                                        null
                                                                    }
                                                                </>
                                                            }
                                                        </I18n>
                                                    </div>
                                                </div>
                                            :
                                                null
                                        }

                                        <div className="relative flex-center" style={window.innerWidth > 1559 ? {marginTop: '3%'} : {marginTop: '2%'}}>
                                            <div className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-go-text signin-go-font-lg": "signin-go-text signin-go-font-md"}><Trans id="signin.goButtonText" /></div>
                                            <input type="image"
                                                src="/go_check.png"
                                                id="loginButton"
                                                alt="Submit"
                                                disabled={ disableGoButton /* TP-4678 && this.props.login_attempts === 0 */ ? false : true }
                                                onClick={this.onClickSubmitMobileLogin} 
                                                onKeyDown={this.onClickSubmitMobileLogin}
                                                className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-check-icon signin-check-icon-font-lg" : "signin-check-icon signin-check-icon-font-md"} 
                                            />
                                        </div>
                                    </>
                                :
                                    <>
                                        <div className="flex-center"> {/**TP-2320*/}
                                            <I18n>
                                                {({ i18n }) => 
                                                    <>
                                                        <input 
                                                            className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-input-field signin-input-font-lg usernameIcon" : "signin-input-field signin-input-font-md usernameIcon"} 
                                                            type="email" 
                                                            name={i18n._(t`email-address`)}
                                                            id="email-address"
                                                            placeholder={i18n._(t`Username`)}
                                                            value={this.props.signInEmail}
                                                            autoFocus={true}
                                                            onChange={this.updateEmail.bind(this)} 
                                                            onKeyPress={this.props.enterPressedFromName}
                                                            ref={this.inputReference} 
                                                        />
                                                        {this.props.validator.message(i18n._(t`email-address`), this.props.signInEmail,
                                                        'required|emailValidate')}
                                                    </>
                                                }
                                            </I18n>
                                        
                                        </div>
                                        <div className="mv0 flex-center"> {/**TP-2320*/}
                                            <I18n>
                                                {({ i18n }) =>
                                                <>
                                                    <input 
                                                        className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-input-field signin-input-font-lg password-icon" : "signin-input-field signin-input-font-md password-icon"}
                                                        type="password" 
                                                        name={i18n._(t`password`)} 
                                                        id="password"
                                                        value={this.props.signInPassword}
                                                        placeholder={i18n._(t`Password`)}
                                                        onChange={this.updatePassword.bind(this)}
                                                        onKeyPress={this.props.enterPressedFromPassword}
                                                    />
                                                    {this.props.validator.message(i18n._(t`password`), this.props.signInPassword, 'required')}
                                                </>
                                                }
                                            </I18n>
                                        </div>
                                        { isSmartVision === false ?
                                            <div className="row">
                                                <div className="col-6"></div>
                                                <div className="col-6 forget-password-link text-right">
                                                    {window._env_.REACT_APP_APP_MODE !== "LAN" ?
                                                        <a href="#" onClick={(e) => this.props.onClickForgotPassword(e)}> <Trans id="signin.forgotPassword" /></a>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                            :
                                            null
                                        }

                                        <div className="relative flex-center" style={window.innerWidth > 1559 ? {marginTop: '3%'} : {marginTop: '2%'}}>
                                            <div className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-go-text signin-go-font-lg": "signin-go-text signin-go-font-md"}><Trans id="signin.goButtonText" /></div>
                                            <input type="image"
                                                src="/go_check.png"
                                                id="loginButton"
                                                alt="Submit"
                                                disabled={ this.props.signInEmail && this.props.signInPassword && !noInternet /* TP-4678 && this.props.login_attempts === 0 */ ? false : true }
                                                onClick={this.onClickSubmit.bind(this)} 
                                                onKeyPress={this.props.enterPressedFromPassword}
                                                className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-check-icon signin-check-icon-font-lg" : "signin-check-icon signin-check-icon-font-md"} 
                                            />
                                        </div> 
                                    </>

                            }
                        </>                     
                    :

                        <>
                            <div className="flex-center"> {/**TP-2320*/}
                                <I18n>
                                    {({ i18n }) => 
                                        <>
                                            <input 
                                                className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-input-field signin-input-font-lg usernameIcon" : "signin-input-field signin-input-font-md usernameIcon"} 
                                                type="email" 
                                                name={i18n._(t`email-address`)}
                                                id="email-address"
                                                placeholder={i18n._(t`Username`)}
                                                value={this.props.signInEmail}
                                                autoFocus={true}
                                                onChange={this.updateEmail.bind(this)} 
                                                onKeyPress={this.props.enterPressedFromName}
                                            />
                                            {this.props.validator.message(i18n._(t`email-address`), this.props.signInEmail,
                                            'required|emailValidate')}
                                        </>
                                    }
                                </I18n>
                            
                            </div>
                            <div className="mv0 flex-center"> {/**TP-2320*/}
                                <I18n>
                                    {({ i18n }) =>
                                    <>
                                        <input 
                                            className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-input-field signin-input-font-lg password-icon" : "signin-input-field signin-input-font-md password-icon"}
                                            type="password" 
                                            name={i18n._(t`password`)} 
                                            id="password"
                                            value={this.props.signInPassword}
                                            placeholder={i18n._(t`Password`)}
                                            onChange={this.updatePassword.bind(this)}
                                            onKeyPress={this.props.enterPressedFromPassword}
                                        />
                                        {this.props.validator.message(i18n._(t`password`), this.props.signInPassword, 'required')}
                                    </>
                                    }
                                </I18n>
                            </div>
                            {/**TP-6757 */}
                            { isSmartVision === false ?
                                <div className="row">
                                    <div className="col-6"></div>
                                    <div className="col-6 forget-password-link text-right">
                                        {window._env_.REACT_APP_APP_MODE !== "LAN" ?
                                            <a href="#" onClick={(e) => this.props.onClickForgotPassword(e)}> <Trans id="signin.forgotPassword" /></a>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                :
                                null
                            }
                            <div className="relative flex-center" style={window.innerWidth > 1559 ? {marginTop: '3%'} : {marginTop: '2%'}}>
                                <div className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-go-text signin-go-font-lg": "signin-go-text signin-go-font-md"}><Trans id="signin.goButtonText" /></div>
                                <input type="image"
                                    src="/go_check.png"
                                    id="loginButton"
                                    alt="Submit"
                                    disabled={ this.props.signInEmail && this.props.signInPassword && !this.props.noInternet /* TP-4678 && this.props.login_attempts === 0 */ ? false : true }
                                    onClick={this.onClickSubmit.bind(this)} 
                                    onKeyPress={this.props.enterPressedFromPassword}
                                    className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-check-icon signin-check-icon-font-lg" : "signin-check-icon signin-check-icon-font-md"} 
                                />
                            </div> 
                        </>                        
                }
            </>
        )
    }
}

export default SigninForm;