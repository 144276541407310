import React from 'react';
import { Form, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import '../Landing/Groups.scss';
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import CheckListTasks from './checkListTasks';//TP-2479
import Loader from '../Loader/Loader';
import FlashMessage from '../FlashMessage/FlashMessage';
import { printConsole } from '../helpers';
import { nanoid } from 'nanoid';

class ScanCheckListForms extends React.Component{
    constructor(props){
        super(props);
        this.authService = props.authService;
        /**TP-3222 */
        this.cardColor = {
            created: 'bg-created',
            assigned: 'bg-assigned',
            in_progress: 'bg-in-progress',
            finished: 'bg-finished',
            skipped: 'bg-finished'
        };
        this.state = {
            isLoading: false, retCode: 400, errStrReturned: '',	bSubmitted: false, bResponded: false,
            selectedJob:{accountId: props.accountId, reference_id: nanoid(),
                technician_id: null,
                job_type: 'scan_checklist_template'//check fetchjobs to switch to scan_checklist
            },
            groupUsers:[], selectedUser: {},
            selectedTechnician: '', pastTechnician: '',
            locations:[], selectedLocation: '',
            startDate: '', endDate: '',
            unFormatedStartDate: '', unFormatedEndDate: '',
            isJobExists: false, isJobReferenceIdExists: false,
            checkListTasks: [], //TP-3222
            themes: [{name: 'Yes/No/NA', id: 1}, {name: 'Pass/Fail/NA', id: 1}],
            unformattedCheckListTasks:[],
            isAddProcedure: true,
            isJobLoading: true,
            flashMsgText: "",
            showFlashMessage: false,
            flashLeft: "",
            flashTop: "",
            loadingText: "Loading",
            action: props.action,
            bShowSubTask: false, //TP-3222
            index: 0 //TP-3222
        }

		// initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({
            locale: props.language,
            autoForceUpdate: this,
            element: message => <div className="job-form-validation-msg">{message.startsWith('name', 4) ? message.replace(/field|The|/g, '').capitalize() : message.replace(/field|The|[0-9]/g, '').capitalize()}</div>,
            validators: {
                maxCharLength: {
                    rule: (val, params, validator) => {
                      return (val.length <= parseInt(params[0]))
                    }
                },
                minCharLength: {
                    rule: (val, params, validator) => {
                      return (val.length >= parseInt(params[0]))
                    }
                },                
                checkStartDate: {
                    message: 'Start date cannot be less than todays date.',
                    rule: (val, params, validator) => {
                        if(props.action !== 'edit_scan_checklist'){
                            return new Date(val) >= new Date();//TP-4898
                        }else{
                            return true;
                        }
                    },
                },
                beforEndDate: {
                    message: 'Start date cannot be greater than end date.',
                    rule: (val, params, validator) => {
                        return new Date(val) <= this.state.unFormatedEndDate;//TP-4898
                    },
                },
                checkEditStartDate:{
                    message: 'Cannot be less than existing start date.',
                    rule: (val, params, validator) => {
                        if(props.action !== 'edit_scan_checklist'){
                            return true;
                        }else{
                            return new Date(val) >= new Date(props.selectedJob.start_date);//TP-4898
                        }
                    },
                },
                afterStartDate: {
                    message: 'End date cannot be less than start date.',
                    rule: (val, params, validator) => {
                        return new Date(val) >= this.state.unFormatedStartDate;//TP-4898
                    },
                },
                afterStartDateForScheduler: {
                    message: 'Cannot be less than start date.',
                    rule: (val, params, validator) => {
                        return new Date(val) >= this.state.unFormatedStartDate;//TP-4898
                    },
                },
                alpha_num_dash_underscore: {
                    rule: (val, params, validator) => {
                        const regex = /^[A-Za-z0-9_-]+$/g;
                        return validator.helpers.testRegex(
							val,
							regex
						);
                    }
                },
                checkInterval: {
                    message: 'Interval is not valid',
                    rule: (val, params, validator) => {
                      return (val >= 1)
                    }
                },
            }
		});
        
    }

    componentDidMount(){
        this.setState(() => ({
            unFormatedStartDate: new Date(),
            unFormatedEndDate: new Date((new Date()).setDate((new Date()).getDate() +1)),
            startDate: new Date(),// MB2-175
            endDate: new Date((new Date()).setDate((new Date()).getDate() +1)),// MB2-175 time stamp also needs to be there in end date
        }),() => {
            this.fetchAllThemes()
            if(this.props.action=== 'add_scan_checklist' || this.props.action === 'edit_scan_checklist' || this.props.action === 'clone_scan_checklist_job'){
                if(this.props.action=== 'clone_scan_checklist_job' ){
                    // this.fetchJobs();
                    this.setDefaultName();
                }
                this.fetchAllTech();
                this.fetchLocation();
            }
        });
		this.delayedCallback = _.debounce(this.checkJobExists, 500);
		this.delayedCallbackForReferenceId = _.debounce(this.checkReferenceIdExists, 500);
    }

    fetchAllTech = () => {
        let fetchString = 'getusersByGroups/';
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data){
               this.setState({
                   groupUsers: data,
                });
            }
        })
        .catch(error => {
            this.setState({
                isJobLoading: false, bResponded: true, retCode: error.status, flashLeft: "45%", 
                flashTop: "", flashMsgText: "Failed to process request", showFlashMessage: true
            });
        })
    };

    fetchLocation = () => {
        let fetchString = 'jobs/getLocations/';
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data){
               this.setState(prevState => ({
                   locations: [...prevState.locations,...data],
                }));
            }
        })
        .catch(error => {
            this.setState({
                isJobLoading: false, bResponded: true, retCode: error.status, flashLeft: "45%", 
                flashTop: "", flashMsgText: "Failed to process request", showFlashMessage: true
            });
        })
    };

    fetchAllThemes = () => {
        let {selectedJob, action} = this.props;
        let fetchString = 'jobs/getCheckListThemes/';
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data){
                this.setState({
                   themes: data,
                },()=>{
                    if((action === 'edit_scan_checklist' || action === 'edit_scan_checklist_template' || action === 'clone_scan_checklist_job' || action === 'clone_scan_checklist_template') && selectedJob){
                        this.fetchAllCheckListTasks(selectedJob);
                    }else{
                        this.setState({isJobLoading:false});
                        const [theme] = data.filter(t => t.name === "Pass/Fail/NA")
                        const task = { sequence: 1, status: "created", task_instruction: "Scan 1", theme_id: theme.id};
                        this.setState(prevState => ({...prevState.selectedJob, checkListTasks: [task]}))
                    }
                });
            }
        })
        .catch(error => {
            this.setState({
                isJobLoading: false, bResponded: true, retCode: error.status, flashLeft: "45%", 
                flashTop: "", flashMsgText: "Failed to process request", showFlashMessage: true,
            });
        })
    };

    fetchAllCheckListTasks = (selectedJob) => {
        let fetchString = `jobs/getAllCheckListTasks/${selectedJob.id}`;
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data){
                this.setState({
                    unformattedCheckListTasks: data,
                    isJobLoading: false,
                });
            }
        })
        .catch(error => {
            this.setState({
                isJobLoading: false, bResponded: true, retCode: error.status, flashLeft: "45%", 
                flashTop: "", flashMsgText: "Failed to process request", showFlashMessage: true
            });
        })
        .finally(() => {
            this.initialiseJobForEdit(selectedJob);
            this.mapCheckListTasks()
        })
    };

    setDefaultName = () => {
        const jobName = "Work Order " + Date.now()
        this.setState( prevState => ({
           selectedJob: {...prevState.selectedJob, name: jobName, job_type: 'scan_checklist' }
        }),() => this.checkJobExists({jobName, jobField: "name"}));
    }

    fetchJobs = () => {
        let { isadmin, accountId} = this.props, jobs;

        let fetchString = 'jobs/joblist/';
        fetchString += accountId + '/';
        fetchString += isadmin;

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                jobs = data.records.filter(job => (!["scan_checklist_template"].includes(job.job_type) && !job.is_deleted))
                const jobName = "Work Order " + (jobs.length + 1)
                this.setState( prevState => ({
                   selectedJob: {...prevState.selectedJob, name: jobName, job_type: 'scan_checklist' }
                }),() => this.checkJobExists({jobName, jobField: "name"}));
            }
       })
       .catch(err => {
           printConsole(`error in fetchjob ${err.message}`)
       })
    }

    initialiseJobForEdit = (selectedJob) => {
        try{
            let {groupUsers, selectedTechnician, action, locations, selectedLocation } = this.state, job;
            if(action === 'clone_scan_checklist_job' || action === 'clone_scan_checklist_template'){
                job = {
                    ...this.state.selectedJob, description: selectedJob.description,
                    source_id: selectedJob.id //TP-3599
                };
            }else{
                var [user] = groupUsers.filter(st => st.user_id === selectedJob.technician_id)//TP-2678
                var [location] = locations.filter(l => l.id === selectedJob.location_id)//TP-2678
                job = {
                    ...selectedJob, technician_id: selectedJob.technician_id && user ? selectedJob.technician_id : null,
                };
            }

            let unFormatedStartDate= new Date(selectedJob.start_date || ''), unFormatedEndDate= new Date(selectedJob.end_date || '');
            this.setState({
                selectedJob: job, unFormatedStartDate, unFormatedEndDate,
                selectedTechnician: user ? {
                    value: user.user_id || '',
                    label: `${user.first_name} ${user.last_name}`
                } : selectedTechnician,
                selectedLocation: location ? {
                    value: location.id || '',
                    label: `${location.name}`
                } : selectedLocation
            });
        }catch(error){
            printConsole(`error in initialiseJobForEdit ${error}`)
        }
    }

    mapCheckListTasks = () =>{
        printConsole('start of map task')
        try{
            let {unformattedCheckListTasks, checkListTasks, action} = this.state;
            if(unformattedCheckListTasks && unformattedCheckListTasks.length > 0){
                //TP-3838,3812
                checkListTasks = unformattedCheckListTasks.filter(t => !t.sub_sequence).map((t) => {
                    if(action === 'clone_scan_checklist_job' || action === 'clone_scan_checklist_template' ){
                        return {
                            job_id: t.job_id, task_instruction: t.instruction, theme_id: t.theme_id,
                            collapse: true,
                            sequence: t.sequence,
                            isAdded: true
                        }
                    }else{
                        return {
                            id: t.id, job_id: t.job_id, task_instruction: t.instruction, theme_id: t.theme_id,
                            collapse: true,
                            sequence: t.sequence, status: t.status, checklist_task_assignee_id: t.checklist_task_assignee_id
                        }
                    }
                });
                this.setState({ checkListTasks },() =>{
                    printConsole('End of map task')
                    setTimeout(() => { this.checkIsAddProcedure(); }, 1000);
                })
            }else{
                setTimeout(() => { this.checkIsAddProcedure(); }, 1000);//TP-3449
            }

        }catch(error){
            printConsole(`mapCheckListTasks try catch error ${JSON.stringify(error)}`)
        }
    }

    checkJobExists = ({jobName, jobField, jobId}) => {
		if (!this.validator.fieldValid(jobField)){
			this.setState({ isJobExists: false });
			return
        };
        let fetchString = `jobs/checkJobExists/${jobName}`;
        if(jobId){
            fetchString += `/${jobId}`;//T32-197, pass the job id so that it can be passed to the query. This is optional and only used for edit
        }

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			this.setState({
				isJobExists: isExists
			}, () => this.checkIsAddProcedure());//Mb2-935
		})
		.catch((error) => {
            printConsole(`checkJobExists try catch error ${JSON.stringify(error)}`)
		})
    }

    checkReferenceIdExists = ({referenceId, jobField, jobId}) => {
        if (!this.validator.fieldValid(jobField)){
            this.setState({ isJobReferenceIdExists: false });
			return
        };
        let fetchString = 'jobs/checkReferenceIdExists/';
        fetchString += referenceId;
        if(jobId){
            fetchString += `/${jobId}`;//TP-795, pass the job id so that it can be passed to the query. This is optional and only used for edit
        }

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			this.setState({
				isJobReferenceIdExists: isExists
			}, () => this.checkIsAddProcedure());//Mb2-935
		})
		.catch((error) => {
            printConsole(`checkReferenceIdExists try catch error ${JSON.stringify(error)}`)
		})
    }

    onChangeJobForm = (fieldName) => ({ target }) => {
        const jobId = this.props.selectedJob ? this.props.selectedJob.id : null;//T32-197
        (fieldName === 'name') && this.delayedCallback({jobName:target.value, jobField:target.name, jobId});
        (fieldName === 'reference_id') && this.delayedCallbackForReferenceId({referenceId:target.value, jobField: 'identifier', jobId});//TP-795
        this.setState(prevState => ({
            selectedJob: {
                ...prevState.selectedJob,
                [fieldName]: (fieldName === "is_recurring") ? target.checked : target.value.trimStart().replace(/(<([^>]+)>)/ig,""),
                mode_of_recurrence: (fieldName === "is_recurring" && !target.checked) ? null : prevState.selectedJob.mode_of_recurrence//TP-4891
            }
        }),() =>{
            // check the add task button state
            this.checkIsAddProcedure();
        });
    };

    onChangeCheckListTask = (fieldName, index) => ({target}) => {
        let checkListTasks = [...this.state.checkListTasks];
        checkListTasks[index][fieldName] = target.value.trimStart().replace(/(<([^>]+)>)/ig,"")
        this.setState({ checkListTasks },() =>{
            this.checkIsAddProcedure();
        })
    }

    handleTechChange = (idx) => (selectedUser) => {
        let checkListTasks = [...this.state.checkListTasks];
        checkListTasks[idx]['selectedUser']= selectedUser;
        checkListTasks[idx]['user_id']= selectedUser.value;
        this.setState({ 
            checkListTasks,
        })
    }

    handleTechnicianChange = (selectedTechnician) => {
        let {selectedJob} = this.state;
        this.setState({ 
            pastTechnician: selectedJob.technician_id
        });
        selectedJob.technician_id = selectedTechnician.value;        
        if(selectedJob.status === 'draft'){
            selectedJob.status = 'created' //TP-2359
        }
        this.setState({ 
            selectedTechnician,
            selectedJob
        },()=>{
            this.checkIsAddProcedure();
        })
    }

    handleLocationChange = (selectedLocation) => {
        let {selectedJob} = this.state;
        selectedJob.location_id = selectedLocation.value;        
        this.setState({ 
            selectedLocation,   selectedJob
        },()=>{
            this.checkIsAddProcedure();
        })
    }

    handleThemeChange = (idx) => (selectedTheme) => {
        let checkListTasks = [...this.state.checkListTasks];
        checkListTasks[idx]['theme_id'] = selectedTheme.value;
        this.setState({ 
            checkListTasks,
        },() => {
            this.checkIsAddProcedure();// check status of addtask button
        })
    }

    getTechNameOptions = (i18n) => {
        let users = this.state.groupUsers;
        users = users.filter(obj => (!obj.isadmin && !obj.isexpert))
        const techNameOptions = users.map(p => {
            return {
                value: p.user_id,
                label: `${p.first_name} ${p.last_name}`
            }
        });
        return techNameOptions;
    }
    
    getLocationNameOptions = () => {
        let {locations} = this.state;
        const locationNameOptions = locations.map(p => {
            return {
                value: p.id,
                label: `${p.name}`
            }
        });
        return locationNameOptions;
    }

    themeNames = (obj, i18n) => {
        if(obj){
            let {value, label} = obj;
            switch (label) {
                case 'Pass/Fail/NA':
                    return {value,label: i18n._(t`Pass/Fail/NA`)};//TP-2480
                case 'Yes/No/NA':
                    return {value,label: i18n._(t`Yes/No/NA`)};//TP-2554
                default:
                    return '';
            } 
        }else{
            return '';
        }
    }

    getChecklistThemeNameOptions = (i18n) => {
        let {themes} = this.state;
        return themes.map(p => {
            let action = this.themeNames({value: p.id,label: p.name}, i18n)
            return action;
        });
    }

    formatDate = (str) => {
        let date = str,
            mnth = ("0" + (date.getMonth()+1)).slice(-2),
            day  = ("0" + date.getDate()).slice(-2);
        return [ date.getFullYear(), mnth, day ].join("-");
    }

    onStartDateChange = (date, e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        // let startDate = this.formatDate(date);
        const mode_of_recurrence = this.state.selectedJob && this.state.selectedJob.mode_of_recurrence ? JSON.parse(this.state.selectedJob.mode_of_recurrence) : {}
        let startDate = date;
        const no_of_occurences = this.noOfOccurences({startDate, endDate: mode_of_recurrence.recurrence_end_date, mode_of_recurrence })
        this.setState(prevState => ({
            startDate: startDate,
            unFormatedStartDate: date,
            selectedJob: {
                ...prevState.selectedJob,
                ['start_date']: startDate,
                mode_of_recurrence: prevState.selectedJob.is_recurring? JSON.stringify({...mode_of_recurrence, no_of_occurences }) : null//TP-4891
            }

        }),() => {
            this.checkIsAddProcedure();// check status of addtask button
        });
    }

    onEndDateChange = (date, e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        // let endDate = this.formatDate(date);
        let endDate = date;
        this.setState(prevState => ({
             endDate: endDate,
             unFormatedEndDate: date,
             selectedJob: {
				...prevState.selectedJob,
				['end_date']: endDate
            }
        }),() => {
            this.checkIsAddProcedure();// check status of addtask button
        });
    }

    onAddJob = (e) => {
        e.preventDefault();
        if (!this.validator.allValid() || this.state.isJobExists || this.state.isJobReferenceIdExists) {
            this.openInvalidProcedure(this.validator.fields);
			this.validator.showMessages();
			return;
		}

        const { selectedJob, startDate, endDate, checkListTasks } = this.state;
        selectedJob.start_date = selectedJob.start_date || startDate; selectedJob.end_date = selectedJob.end_date || endDate; selectedJob.checkListTasks = checkListTasks;
        selectedJob.technician_id = selectedJob.technician_id === 0 ? null : selectedJob.technician_id;//the validation is not triggering if set to '', or null

        if(checkListTasks.length === 0 && selectedJob.job_type !== 'scan_checklist_template'){
            selectedJob.status = 'draft'//TP-3445
        }

		this.setState({
            bSubmitted: true,
            errStrReturned: i18nMark('Submitting new work order details')
        });
        this.props.authService.fetch('jobs/addCheckListJob', {
            method: 'post',
            body: JSON.stringify(selectedJob)
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                this.props.fetchJobs();//reload the jobs
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(id => {
			if(id){
				this.setState({
                    bSubmitted: false,
                    // bResponded: true,//MB2-923
                    // errStrReturned: i18nMark('New Job successfully added!')//MB2-923
                });
                this.props.changeJobView({action:'', showJobForm: false});//MB2-923
			}
        })
        .catch((error) => {
            this.setState({
                bSubmitted: false,
                bResponded: true,
                retCode: error.status,
                errStrReturned: i18nMark('Failed to add the new work order. Please review the entries and resubmit.')
            });

        });
    };

    onEditJob = (e) => {
        e.preventDefault();
        if (!this.validator.allValid() || this.state.isJobExists || this.state.isJobReferenceIdExists) {
            this.openInvalidProcedure(this.validator.fields);
            this.validator.showMessages();
			return;
		}
        
        const { selectedJob, checkListTasks } = this.state;
        selectedJob.id = this.props.selectedJob.id; selectedJob.checkListTasks = checkListTasks;
        selectedJob.technician_id = selectedJob.technician_id === 0 ? null : selectedJob.technician_id;//the validation is not triggering if set to '', or null

        const job_data = {
            id: selectedJob.id, name: selectedJob.name, description: selectedJob.description,
            start_date: selectedJob.start_date, end_date: selectedJob.end_date,
            status: selectedJob.status, reference_id: selectedJob.reference_id,
            job_type: selectedJob.job_type//TP-3299
        }
        let json = {  
            type: "ASSIGNED_JOB_REFRESH",          
            to: selectedJob.technician_id,
            from: this.props.email,
            job_data: JSON.stringify(job_data),            
            refresh_type: "new_job"
        };
        if (selectedJob.status === "in_progress"){
            if (this.state.pastTechnician && this.state.pastTechnician !== selectedJob.technician_id){
                if(this.state.pastTechnician){
                    json.to = this.state.pastTechnician;
                    json.refresh_type = "job_reassigned";
                    this.authService.socket.emit('job-signal', json);
                }

                //TP-2958
                if(selectedJob.technician_id){
                    json.to = selectedJob.technician_id;
                    json.refresh_type = "new_job";
                    this.authService.socket.emit('job-signal', json);
                }
            } else {
                if(selectedJob.technician_id){
                    json.to = selectedJob.technician_id;
                    json.refresh_type = "job_edited";
                    this.authService.socket.emit('job-signal', json);
                }
            }
        } 
        //console.log(json);        
        if(checkListTasks.length === 0 && selectedJob.status === 'created' && selectedJob.job_type !== 'scan_checklist_template'){
            selectedJob.status = 'draft'//TP-3445 ignore status change for draft job when tech changes or assets changed
        }
        if(checkListTasks.length !== 0 && selectedJob.status === 'draft' && selectedJob.job_type !== 'scan_checklist_template'){
            selectedJob.status = 'created'//TP-3445 change the status to created
        }

		this.setState({
            bSubmitted: true,
            errStrReturned: i18nMark('Submitting work order details')
        });
        this.props.authService.fetch('jobs/editCheckListJob', {
            method: 'put',
            body: JSON.stringify(selectedJob)
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                this.props.fetchJobs();//reload the jobs
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(id => {
			if(id){
				this.setState({
                    bSubmitted: false,
                    bResponded: true,
                    errStrReturned: i18nMark('Work Order successfully updated!')
                });
            }
        })
        .catch((error) => {
            this.setState({
                retCode: error.status,
                errStrReturned: i18nMark('Failed to edit the work order. Please review the entries and resubmit.')
            });            
        });
    };

    deleteCheckListTask = async ({e, index}) => {
        try{
            e.preventDefault();
            let { checkListTasks } = this.state;
            let selectedCheckList = checkListTasks[index];
            selectedCheckList['isDelete'] = true //mark the one to be deleted
            checkListTasks.splice(index,1) // seperate it from the other tasks
            checkListTasks= checkListTasks.map((task, i) => {
                return {...task, sequence: (i+1)} // update the sequence of the tasks
            });
            checkListTasks.push(selectedCheckList) // push the to be deleted one
            if(selectedCheckList){
                this.props.authService.fetch(`jobs/deleteCheckListTask`, {
                    method: 'put',
                    body: JSON.stringify({checkListTasks})
                })
                .then(response => {
                    checkListTasks.pop(); // remove the deleted one added at the end
                    if(response.status === 200){
                        this.setState({ 
                            checkListTasks,
                            /**TP-3222 */
                            index: 0,
                            isAddProcedure: false, // status of addtask button
                            bShowSubTask: false
                        },() =>{ 
                            this.checkIsAddProcedure() 
                        });
                        return response.json();
                    } else {
                        throw new Error(response.body);
                    }
                })
                .catch((error) => {
                    this.setState({
                        bResponded: true, bSubmitted: false, errStrReturned: i18nMark('Failed to process request'),
                        retCode: error.status
                    });
                })
            }
        }catch(error){
            printConsole(`deleteCheckListTask try catch error ${JSON.stringify(error)}`)
        }
    }
    
    deleteNewCheckListTask = async ({e, index}) => {
        try{
            if(e){
                e.preventDefault();
            }
            let { checkListTasks } = this.state;
            checkListTasks.splice(index,1);
            if(Array.isArray(checkListTasks) && checkListTasks.length > 0){
                checkListTasks= checkListTasks.map((task, i) => {
                    return {...task, sequence: (i+1)} // update the sequence of the tasks
                });
            }
            this.setState({ 
				checkListTasks,
				index: 0,
                isAddProcedure: false, // status of addtask button
                bShowSubTask: false 
			},()=>{
                this.checkIsAddProcedure();
            });
        }catch(error){
            printConsole(`deleteNewCheckListTask try catch error ${JSON.stringify(error)}`)
        }
    }

	//TP-3222
    updateSelectedTask = (index) => {
        this.setState({index, bShowSubTask: true}, () => {
            let checkListTasks = [...this.state.checkListTasks] 
            if(checkListTasks.length>0){
                checkListTasks[index]['collapse'] = false;
                delete checkListTasks[index].isValid;//remove invalid state to re validate again
            }
            this.setState({
                checkListTasks
            });
        });
    }

    //TP-3222
    showNextSeries = (e) => {
        e.preventDefault();
        //Hide the "..." before showing the new set of Procedures
        e.target.parentNode.classList.add("d-none");

        //Show the next set of Procedures
        const index = parseInt(e.target.parentNode.id.split("-")[1]);
        const elements = document.querySelectorAll(`[id^="pro-${index}"]`);
        for(let ii=0; ii<elements.length; ii++) {
            elements[ii].classList.remove("d-none");
        }

        //Show the next "..." if there
        const nextBreakElem = document.querySelector(`[id^="break-${index+1}"]`);
        if (nextBreakElem) {
            nextBreakElem.classList.remove("d-none");
        }
    }

    //TP-3222 -- Invoke when user click to request another page.
    handleProcedureClick = (event) => {
        const newOffset = event.selected;
        console.log(
        `User requested page number ${event.selected}`
        );
        this.updateSelectedTask(newOffset);
    };
    
    addTask = (e) => {
        e.preventDefault();
        let checkListTasks = [...this.state.checkListTasks] 
        if(checkListTasks.length>0){
            checkListTasks[checkListTasks.length-1]['collapse'] = true; //TP-3222
        }  
        checkListTasks.push({
            task_instruction:'',
            collapse: false,
            sequence: (checkListTasks.length +1),
        });

        this.setState({
            checkListTasks,
			index: checkListTasks.length - 1, //TP-3222
            isAddProcedure: true // status of addtask button
        }, () => {
            // this.setState({ bShowSubTask: true }); //TP-3222,4004
        });
    }

    //TP-3222
    cancelSubTask = (e) => {
        e.preventDefault();
        let {checkListTasks, index} = this.state, isAddProcedure = false;
        this.openInvalidProcedure(this.validator.fields);
        if (!checkListTasks[index].id && !checkListTasks[index].isAdded){
            this.deleteNewCheckListTask({e,index})//delete the checklist task considering its a new one
        }

        if (checkListTasks[index] && !checkListTasks[index].id && checkListTasks[index].isValid === false && checkListTasks[index].isAdded) {
            isAddProcedure = true//disable update button if sub task was added but again opened and updated
        }

        if(checkListTasks[index] && checkListTasks[index].id && checkListTasks[index].isValid === false){
            isAddProcedure = true //disable update button if sub task invalid
        }
        this.setState({
            bShowSubTask: false,
            index: 0,
            isAddProcedure // status of addtask button
        });
    }

    //TP-3222
    updateTask = (e) => {
        e.preventDefault(); 
        if (!this.validator.allValid()) {
            this.validator.showMessages();
			return;
		}
        let {checkListTasks, index} = this.state;
        checkListTasks[index].isAdded = true; //TP-3491, mark the task as added
        this.setState({ bShowSubTask: false, checkListTasks });
    }
    
    collapseSubTask = (idx) => {
        let checkListTasks = [...this.state.checkListTasks]  
        checkListTasks = checkListTasks.map((task, i) => {
            if(idx === i){
                return {...task, collapse: !task.collapse}
            }else{
                return {...task, collapse: true}
            }
        }) 
        this.setState({ 
            checkListTasks
        })
    }

    /* this method will enable/disable the addtask button. This needs to be called in the callback
    of set state or else validator will give inconsistent result
    */
    checkIsAddProcedure = () => {
        if (!this.validator.allValid() || this.state.isJobExists || this.state.isJobReferenceIdExists) {
            this.validator.showMessages();
            this.openInvalidProcedure(this.validator.fields)//TP-4004, show the error text
            this.setState({isAddProcedure: true})
        }else{
            this.setState({isAddProcedure: false})
        }
    }

    openInvalidProcedure = (fields) => {
        let invalidFields = [], matches= [];
        for(let key in fields) {
            if(fields[key] === false && /\d/.test(key)){
                invalidFields.push(key)
            }
        }
        // loop through the invalid fields to find out the field name with number in it
        invalidFields.map((f) => {
            let matchArr = f.match(/\d+/g);
            matches.push(matchArr[0]); 
        })

        matches = [...new Set(matches)];
        let {checkListTasks} = this.state;
        for(let i = 0; i < matches.length; i++){
            // if not collapsed then showInvalidError is true
            if(checkListTasks[parseInt(matches[i])]['collapse']){
                checkListTasks[parseInt(matches[i])]['showInvalidError']= true
            }else{
                checkListTasks[parseInt(matches[i])]['showInvalidError']= false//once chnanges are made hide the already shown message
            }
            checkListTasks[parseInt(matches[i])]['isValid'] = false //TP-3450,3455 mark which is invalid
        }
        this.setState({checkListTasks})
    }

    //MB2-775 before closing the form check for any unsaved tasks reference material
    oncloseJobForm = async () => {
        this.props.changeJobView({action:'', showJobForm: false});
    }

    //Flash message specific methods
    updateShowMessage = (val) => {
        if (val === false)
            this.setState({ flashMsgText: "", showFlashMessage: val, flashLeft: "", flashTop: "" });
    }

    // TP-3520
    checkActionType = (action, selectedJob) => {
        if(selectedJob && selectedJob.id){
            return 'Edit'
        }else{
            return 'Add'
        }
    }

    render(){
        let {selectedTechnician, checkListTasks, isAddProcedure, selectedJob, action, index, selectedLocation} = this.state;
        const {isadmin, isSmallFormFactor, customer, total} = this.props;//TP-2721
        const isJobFinished = (selectedJob && selectedJob.status === 'finished');
        const showTech = (action === 'add_scan_checklist' || action === 'edit_scan_checklist' || action === 'clone_scan_checklist_job' )
        const header = document.getElementById("header_bar");
        const tabHeader = document.getElementById("jobsTab");
        //TP-3840
        let areaHeight = 0 + 'px';
        if (header && header !== null && tabHeader && tabHeader !== null)
            areaHeight = Math.round(window.innerHeight - header.offsetHeight - tabHeader.offsetHeight - 30- 20 - 20) + "px";//TP-3710 30-footer height, 20-mt3 height, 20-for the space at the bottom when multiple procedure are there
        this.validator.purgeFields();
    	if(this.state.bSubmitted || this.state.bResponded){
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
                <>
                    <div className='modale opened'>
                        <div className='__modal-dialog'>
                            <form>
                                <div className="__modal-header">
                                    <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
                                </div>
                                <div className="__modal-footer flex-center">
                                    <button className="btn-green" onClick={() =>this.props.changeJobView({action:'', showJobForm: false})}><Trans id='OK'/></button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <FlashMessage 
                        flashMsgText={this.state.flashMsgText} 
                        showMessage={this.state.showFlashMessage} 
                        flashLeft={this.state.flashLeft}
                        flashTop={this.state.flashTop}
                        updateShowMessage={this.updateShowMessage}
                        isTimerEnabled={true} /**TP-5986*/
                    />
                </>
			);
		} else {
            //TP-3222 -- Embedded forms page
			return(
                <>
                    <div className="formmodule center">
					    <div className='mt-3 form-color width-min col-6' style={{height: areaHeight}}>
                            <div className="formheader flex-center row">
                                {/* TP-2704 */}
                                <h2>
                                    <Trans id={this.checkActionType(action, selectedJob)}></Trans> <Trans>Work Order</Trans> {(action === 'add_scan_checklist_template' || action === 'edit_scan_checklist_template') ? <Trans>Template</Trans> : ""}
                                </h2>
                            </div>
                            <div className="formbody form-text-color position-sticky">
                                <Form>
                                    <div className="jobs-form-body pb-0">
                                        <Row className="margin-top-15 mb-2">
                                            <Col>
                                                <div id="job_name" className="input-wrapper">
                                                    <I18n>
                                                        {({ i18n }) =>
                                                            <>
                                                                <input className='signup-input-field' name={i18n._(t`name`)} type="text" placeholder={showTech? i18n._(t` Enter the work order name`) : i18n._(t` Enter the template name`)} value={selectedJob.name} onChange={this.onChangeJobForm('name')} disabled={isJobFinished} autoComplete="off"/>
                                                                { this.validator.message(i18n._(t`name`), selectedJob.name, 'required|maxCharLength:50') }
                                                                { this.state.isJobExists ? <div className="add-users-validation-msg"><Trans>There is a work order with this name.</Trans></div> : '' }
                                                            </>									
                                                        }
                                                    </I18n>
                                                    { this.props.isSmallFormFactor === false ? 
                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="job_name">
                                                            <>{showTech? <Trans>Work Order</Trans> : <Trans>Template</Trans>} <Trans>Name</Trans></>
                                                        </UncontrolledTooltip>       
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        {!showTech? 
                                            <Row className="mt-3">
                                                <Col>
                                                    <div id="job_desc"  className="input-wrapper">
                                                        <I18n>
                                                            {({ i18n }) =>
                                                            <>
                                                                <textarea rows={4} className='signup-input-field form-control' name={i18n._(t`description`)} 
                                                                    placeholder={showTech? i18n._(t` Enter the job Description`) : i18n._(t` Enter the template Description`)} 
                                                                    value={selectedJob.description} onChange={this.onChangeJobForm('description')} disabled={isJobFinished} autoComplete="off"
                                                                />
                                                                { this.validator.message(i18n._(t`description`), selectedJob.description, 'required') }

                                                            </>
                                                            }
                                                        </I18n>
                                                            { this.props.isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="job_desc">
                                                                    <>{showTech? <Trans>Work Order</Trans> : <Trans>Template</Trans>} <Trans>Description</Trans></>
                                                                </UncontrolledTooltip>       
                                                                :
                                                                null
                                                            }
                                                    </div>
                                                </Col>
                                            </Row>
                                            : null
                                        }
                                        <I18n>
                                            {({ i18n }) =>
                                                <Row className="mb-1">
                                                    {showTech? 
                                                        <>
                                                            <Col id="assign_tech" className="input-wrapper">
                                                                <Select
                                                                    value={selectedTechnician}
                                                                    onChange={this.handleTechnicianChange}
                                                                    placeholder={i18n._({ id: `Select A Technician`, values: {role: this.props.customerRoles.technician}})}
                                                                    components={makeAnimated()}
                                                                    options={this.getTechNameOptions(i18n)}
                                                                    isSearchable={isSmallFormFactor ? false : true}
                                                                    isDisabled={isJobFinished}
                                                                    className="text-black"
                                                                />
                                                                { this.props.isSmallFormFactor === false ? 
                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="assign_tech">
                                                                        <Trans values={{role: this.props.customerRoles.technician}}>Select A Technician</Trans>
                                                                    </UncontrolledTooltip>       
                                                                    :
                                                                    null
                                                                }
                                                                <input type="text" readOnly value={selectedJob.technician_id} name={i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}})} className="hidden"/>
                                                                {this.validator.message(i18n._({id: `technician`, values: {role: this.props.customerRoles.technician}}), selectedJob.technician_id, 'required')}
                                                            </Col>
                                                        
                                                            <Col id="assign_location" className="input-wrapper">
                                                                <Select
                                                                    value={selectedLocation}
                                                                    onChange={this.handleLocationChange}
                                                                    placeholder={i18n._(t` Select A Location`)}
                                                                    components={makeAnimated()}
                                                                    options={this.getLocationNameOptions(i18n)}
                                                                    isSearchable={isSmallFormFactor ? false : true}
                                                                    isDisabled={isJobFinished}
                                                                    className="text-black"
                                                                />
                                                                { this.props.isSmallFormFactor === false ? 
                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="assign_location">
                                                                        <Trans>Select A Location</Trans>
                                                                    </UncontrolledTooltip>       
                                                                    :
                                                                    null
                                                                }
                                                                <input type="text" readOnly value={selectedJob.location_id} name={i18n._(t`location`)} className="hidden"/>
                                                            </Col>
                                                        </>									
                                                    :
                                                        null
                                                    }
                                                </Row>
                                            }
                                        </I18n>
                                    </div>

                                    <div className="mt-3">
                                        {(action === 'edit_scan_checklist' || action === 'edit_scan_checklist_template' && selectedJob) ?
                                        <Row>
                                            <Col className="d-flex justify-content-center">
                                                <button className='btn-red job-button' onClick={() => this.oncloseJobForm()} ><Trans>Cancel</Trans></button>
                                            </Col>
                                            {/* {action === 'edit_scan_checklist_template' ?
                                                <Col className="d-flex justify-content-center">
                                                    <button className={isAddProcedure || isJobFinished ? "btn-grey job-button": "btn-blue job-button"} disabled={isAddProcedure || isJobFinished} onClick={(e) => this.addTask(e)}><Trans>Add</Trans> <Trans>New</Trans> <Trans>Safety Check</Trans></button>
                                                </Col>
                                            : null
                                            } */}
                                            <Col className="d-flex justify-content-center">
                                                <button className={isAddProcedure ? "btn-grey job-button": "btn-green job-button"} disabled={isAddProcedure} onClick={(e) =>this.onEditJob(e)}><Trans>Update</Trans></button>
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className="d-flex justify-content-center"><button className='btn-red job-button' onClick={() => this.oncloseJobForm()}><Trans>Cancel</Trans></button></Col>
                                            {/* {action === 'add_scan_checklist_template' ?
                                                <Col className="d-flex justify-content-center"><button className={isAddProcedure ? "btn-grey job-button": "btn-blue job-button"} disabled={isAddProcedure} onClick={(e) => this.addTask(e)}><Trans>Add</Trans> <Trans>New</Trans> <Trans>Safety Check</Trans></button></Col>
                                                : null
                                            } */}
                                            <Col className="d-flex justify-content-center"><button className={isAddProcedure ? "btn-grey job-button": "btn-green job-button"} disabled={isAddProcedure} onClick={(e) =>this.onAddJob(e)}><Trans>Create</Trans></button></Col>
                                        </Row>
                                        }
                                    </div>
                                </Form>
                            </div>
                        </div>
                        {/* {   false ?
                            <div className='mt-3 form-color width-min col-6' style={{height: areaHeight}}>
                                <div className="formheader flex-center row">
                                    <h2>
                                        <Trans id={this.checkActionType(action, selectedJob)}></Trans> <Trans>Safety Check</Trans>
                                    </h2>
                                </div>
                                <div className="mt-4">
                                    <CheckListTasks 
                                        checkListTasks={checkListTasks} 
                                        selectedSubTask = {checkListTasks[index]}
                                        idx={index}
                                        getChecklistThemeNameOptions={this.getChecklistThemeNameOptions}
                                        handleThemeChange={this.handleThemeChange}
                                        validator={this.validator}
                                        collapseSubTask={this.collapseSubTask}
                                        deleteCheckListTask={this.deleteCheckListTask}
                                        onChangeCheckListTask={this.onChangeCheckListTask}
                                        deleteNewCheckListTask={this.deleteNewCheckListTask}
                                        selectedJob={selectedJob}
                                        themeNames={this.themeNames}
                                        themes={this.state.themes}
                                        isSmallFormFactor={isSmallFormFactor} //TP-2947
                                        action = {action} //TP-5606
                                        customer = {customer}
                                    />
                                </div>
                            </div>
                            :
                            null
                        } */}
                    </div>
                    <Loader text={this.state.loadingText} isLoading={this.state.isJobLoading} />
                    <FlashMessage 
                        flashMsgText={this.state.flashMsgText} 
                        showMessage={this.state.showFlashMessage} 
                        flashLeft={this.state.flashLeft}
                        flashTop={this.state.flashTop}
                        updateShowMessage={this.updateShowMessage}
                        isTimerEnabled={true}
                    ></FlashMessage>
                </>
            );
		}
    }
}

export default ScanCheckListForms;