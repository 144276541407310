import React, { Fragment } from 'react';
import { t } from '@lingui/macro';
import { I18n } from "@lingui/react";

const defaultActions = (index, onActionClick, isAdmin, isExpert) => <Fragment>
    {
        (isAdmin && isExpert) || (isExpert && !isAdmin) || (!isExpert && !isAdmin) ?
        <I18n>
            {({ i18n }) =>
                <span className="icon-wrapper pointer" data-rh={i18n._(t`Start the session`)} onClick={() => onActionClick({action: 'play', index})}>
                    <i className="fas fa-video"></i>
                </span>
            }
        </I18n> : ''
    }
    { isAdmin ? (<Fragment>
        <I18n>
            {({ i18n }) =>
                <span data-rh={i18n._(t`Edit user details`)} className="icon-wrapper pointer" onClick={() => onActionClick({action: 'edit', index})}>
                    <i className="fas fa-edit"></i>
                </span>
            }
        </I18n>
        <I18n>
            {({ i18n }) =>
                <span data-rh={i18n._(t`Delete user`)} className="icon-wrapper pointer" onClick={() => onActionClick({action: 'delete', index})}>
                    <i className="far fa-trash-alt"></i>
                </span>
            }
        </I18n>
    </Fragment>) : ''
    }
</Fragment>;

const Actions = ({index, onActionClick, isAdmin, children, isExpert}) => (
    <Fragment>
        { children ? children : defaultActions(index, onActionClick, isAdmin, isExpert)}
    </Fragment>
);

export default Actions;