
import React from 'react';
import { Trans } from '@lingui/macro';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false,
    error: null,
    info: null
   };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    console.log('Error caught, error: , info: ', error, info);
  }

  render() {
    if (this.state.hasError)
      // You can render any custom fallback UI
      return <h1><Trans>Something went wrong.</Trans></h1>;
      
    return this.props.children;
  }
}

export default ErrorBoundary;