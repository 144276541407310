import React from 'react';
import { Row, Col, Card, CardBody, Collapse, UncontrolledTooltip } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { I18n } from "@lingui/react";
import fileImage from './image-file.png';
import vdoImg from './video-file.png';
import moment from 'moment';
import { printConsole, getHostAbbreviationForSocket } from '../helpers';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
const crypto = require('crypto');

class IncidentTable extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            editting_incident_id: "",
        }
    }

    //TP-7086
    maskedUrl = (url) => {
        try{
            if(url.includes("telepresenz-20-p2pfiles") || (window._env_.REACT_APP_APP_MODE === 'LAN')){
                // const randomString = url.replace(/https:\/\/telepresenz-20-p2pfiles.s3.us-east-2.amazonaws.com\//g, '');//get the file path without the slash
                const splitUrl = url.split('/'); let randomString, filePath, hostAbbr = getHostAbbreviationForSocket();
                if(url.includes(`/jobs/${hostAbbr}/`)){
                    randomString = splitUrl[splitUrl.length-3] + '/' + splitUrl[splitUrl.length-2] + '/' + encodeURI(splitUrl[splitUrl.length-1])
                    filePath = splitUrl[splitUrl.length-3] + '/' + splitUrl[splitUrl.length-2] + '/' + splitUrl[splitUrl.length-1]
                }else{
                    randomString = splitUrl[splitUrl.length-2] + '/' + encodeURI(splitUrl[splitUrl.length-1])
                    filePath = splitUrl[splitUrl.length-2] + '/' + splitUrl[splitUrl.length-1]
                }
                if(randomString && encodeURI(splitUrl[splitUrl.length-1]) === splitUrl[splitUrl.length-1]){
                    if(window._env_.REACT_APP_APP_MODE === 'LAN'){
                        return `wf/${filePath}`
                    }else{
                        let hash = crypto.createHash('md5').update(`${randomString}telepresenz-${window._env_.REACT_APP_ENV}`).digest("hex")
                        return `wf/${hash}/${filePath}`
                    }
                }else{
                    return url
                }
            }else{
                return url
            }
        }catch(error){
            printConsole(`error in maskedUrl ${error}`)
            return url
        }
    }

    closeIncidentView = () => {
        this.props.changeJobView({action:'', showJobForm: false});
    }

    setEditIncident = (id) => {
        this.setState({editting_incident_id : id})
    }

    render(){
        const {incidents, collapseIncidents, isSmallFormFactor, onChangeIncidentForm, onSubmitIncident, validator,
            errStrReturned, priority_options, severity_options, status_options, onChangeIncidentDropdown} = this.props;
        const { editting_incident_id } = this.state
        let index = 1, task_id = 0;
        const selectStyle = {
            option: (styles) => ({...styles, color: '#000'})     
        }
        return(
            <>
                <h3 className="text-center font-weight-bold">Incidents</h3>
                {
                    incidents.map((val, idx) => {
                        index = val.subtask_id !== task_id ? 1 : index + 1;
                        task_id = val.subtask_id
                        return(
                                <Card key={idx} className={"mt-1 text-black"}>
                                    <Row className="pointer card-header p-2">
                                        <div className="col-md-3" onClick={() => { collapseIncidents(idx) }}>
                                            <strong>{val.subtask_name && val.subtask_name.replace("Inspection of ", "")}</strong> : Incident {index}
                                        </div>
                                        <div className="col-md-6 text-center default-blue" onClick={() => { collapseIncidents(idx) }}>{editting_incident_id === val.id ? errStrReturned : ""}</div>
                                        <div className="col-2"></div>
                                        <div className="col">
                                            <div className='row'>
                                                <div className="col-md-6 mt-1" onClick={() => { collapseIncidents(idx) }}>
                                                    {
                                                        val.collapse ?
                                                            <i className="fas fa-angle-up float-right" aria-hidden="true"></i>
                                                        :
                                                            <i className="fas fa-angle-down float-right" aria-hidden="true"></i> 
                                                    }
                                                </div>
                                                <div className="col-md-6 mt-1">
                                                    {
                                                        val.field_changed ?
                                                            <i className="fas fa-save float-right" id={'update-incident-' + idx}
                                                                onClick={(e) => { onSubmitIncident({e, index: idx}); this.setEditIncident(val.id) }}
                                                            ></i>
                                                        :
                                                            <i className="fas fa-save float-right" id={'update-incident-' + idx}></i>
                                                    }
                                                    {isSmallFormFactor === false ? 
                                                        <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'update-incident-' + idx}>
                                                            Update Incident
                                                        </UncontrolledTooltip>       
                                                        :
                                                        ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <I18n>
                                        {({ i18n }) => 
                                            <Collapse isOpen={!val.collapse}>
                                                <CardBody>
                                                    <Row className="trans-table">

                                                        <div className='col-6'>
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr style={{height: "160px"}}>
                                                                        <td><Trans>Description</Trans></td>
                                                                        <td>
                                                                            <textarea
                                                                                rows={4}
                                                                                style={{resize:'none'}}
                                                                                name={i18n._(t`description`)+ idx}
                                                                                data-id={idx}
                                                                                id={`description-${idx}`}
                                                                                placeholder={i18n._(t` Description`)}
                                                                                className="signup-input-field form-control"
                                                                                onChange={onChangeIncidentForm('description')}
                                                                                value={val.description}
                                                                            />
                                                                            {   isSmallFormFactor === false ? 
                                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={`description-${idx}`}>
                                                                                        <Trans>Description</Trans>
                                                                                    </UncontrolledTooltip>
                                                                                :
                                                                                    null
                                                                            }
                                                                            { validator.message(i18n._(t`description`)+ idx, val.description, 'required') }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Reported By</td>
                                                                        <td>{val.reported_by}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Reported On</td>
                                                                        <td>{moment(val.created_on).format("DD MMMM YYYY, h:mm:ss a")}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Priority</td>
                                                                        <td>
                                                                            <Select
                                                                                value={val.selected_priority}
                                                                                onChange={onChangeIncidentDropdown({idx, fieldName: "priority", options: "priority_options", value_option: "selected_priority"})}
                                                                                components={makeAnimated()}
                                                                                placeholder={` Select Priority`}
                                                                                options={priority_options}
                                                                                isSearchable={isSmallFormFactor ? false : true}
                                                                                styles={selectStyle}
                                                                                id={`priority_list_`+ idx}
                                                                            />
                                                                            <input type="text" readOnly value={val.priority} name={`priority` + idx} className="hidden"/>
                                                                            { validator.message(`priority`+ idx, val.priority, 'required') }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Severity</td>
                                                                        <td>
                                                                            <Select
                                                                                value={val.selected_severity}
                                                                                onChange={onChangeIncidentDropdown({idx, fieldName: "severity", options: "severity_options", value_option: "selected_severity"})}
                                                                                components={makeAnimated()}
                                                                                placeholder={` Select Severity`}
                                                                                options={severity_options}
                                                                                isSearchable={isSmallFormFactor ? false : true}
                                                                                styles={selectStyle}
                                                                                id={`severity_list_`+ idx}
                                                                            />
                                                                            <input type="text" readOnly value={val.severity} name={`severity` + idx} className="hidden"/>
                                                                            { validator.message(`severity`+ idx, val.severity, 'required') }
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Status</td>
                                                                        <td>                                                                        
                                                                            <Select
                                                                                value={val.selected_status}
                                                                                onChange={onChangeIncidentDropdown({idx, fieldName: "status", options: "status_options", value_option: "selected_status"})}
                                                                                components={makeAnimated()}
                                                                                placeholder={` Select Status`}
                                                                                options={status_options}
                                                                                isSearchable={isSmallFormFactor ? false : true}
                                                                                styles={selectStyle}
                                                                                id={`status_list_`+ idx}
                                                                            />
                                                                            <input type="text" readOnly value={val.status} name={`status` + idx} className="hidden"/>
                                                                            { validator.message(`status`+ idx, val.severity, 'required') }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className='col-6'>
                                                            <Row className="d-flex align-items-center">
                                                                {
                                                                    val.image_urls ?
                                                                        <>
                                                                            {
                                                                                val.image_urls.map((i, j) => {
                                                                                    return(
                                                                                        <div key={j} className="col text-center box w-100 border border-dark pt-2">
                                                                                            {
                                                                                                i.type === "image" ?
                                                                                                    <a href={this.maskedUrl(i.url)} target="_blank"><img src={fileImage} /></a>
                                                                                                :
                                                                                                    <a href={this.maskedUrl(i.url)} target="_blank"><img src={vdoImg} /></a>
                                                                                            }
                                                                                            {
                                                                                                i.type === "image" ?
                                                                                                    <div>Image Evidence</div>
                                                                                                :
                                                                                                    <div>Video Evidence</div>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </>
                                                                    :
                                                                        null
                                                                }
                                                            </Row>
                                                            <Row>
                                                                {/* TZ-111 */}
                                                                {
                                                                    val.logs ?
                                                                        <>
                                                                            <h2 className="mt-3"><strong> Incident Logs:</strong></h2>
                                                                            <table className="table">
                                                                                <tr>
                                                                                    <th>Modified By</th>
                                                                                    <th>Modified On</th>
                                                                                    <th>Modified Fields</th>
                                                                                </tr>
                                                                                {
                                                                                    val.logs.map((i, j) => {
                                                                                        return(
                                                                                            <tr key={j}>
                                                                                                <td>{i.name || ""}</td>
                                                                                                <td>{i.updated_on? moment(i.updated_on).format("DD MMMM YYYY, h:mm:ss a") : ""}</td>
                                                                                                <td>{i.fields_changed? Object.keys(i.fields_changed).join(", ") : ""}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </table>
                                                                        </>

                                                                    :
                                                                        null
                                                                }
                                                            </Row>
                                                        </div>
                                                    </Row>
                                                </CardBody>
                                            </Collapse>
                                        }
                                    </I18n>
                                </Card>
    
                        )
                    })
                }
                <Row className="mt-3">
                    <Col className="d-flex justify-content-center"><button className='btn-red job-button' onClick={() => this.closeIncidentView()}><Trans>Back</Trans></button></Col>
                </Row>
            </>
        )
    }
}

export default IncidentTable;