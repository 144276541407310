import { MTLLoader, OBJLoader } from 'three-obj-mtl-loader';
import promisifyLoader from './PromisifyLoader';
import * as THREE from 'three';

export const  objectLoader = async ({ mtlFilePath, objFilePath, textureFilePath }) => {
    let objectToReturn;
    try {
        if (mtlFilePath) {
            // load mtl file
            const loader = promisifyLoader(new MTLLoader(), progressHandler);
            let materials = await loader.load(mtlFilePath);
            const objectLoader = promisifyLoader(new OBJLoader(), progressHandler);
            objectLoader.originalLoader.setMaterials(materials);
            objectToReturn = await objectLoader.load(objFilePath);
        } else {
            const objectLoader = promisifyLoader(new OBJLoader(), progressHandler);
            objectToReturn = await objectLoader.load(objFilePath);
        }
        /** Load all texture files */
        textureFilePath.forEach(path => {
            const textureLoader = new THREE.TextureLoader();
            const texture = textureLoader.load(path, progressHandler);
            objectToReturn.traverse((child) => {
                if (child instanceof THREE.Mesh && texture) {
                    child.material.map = texture;
                }
            });
        });
    } catch (error) {
        errorHandler(error);
    }

    function progressHandler(e) {
        console.log((e.loaded / e.total * 100 ) + '% loaded' );
    }

    function errorHandler(error) {
        console.log( 'An error happened', error);
        throw error;
    }

    return objectToReturn;
}

export default objectLoader;

