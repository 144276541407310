import React from 'react';
import './ConfirmModal.scss';
import Button from '../Button/Button';
import { Trans } from '@lingui/macro';

const ConfirmModal = (props) => (
    <div className='modale opened confirm-modal'>
        <div className='__modal-dialog'>
            <form>
                <div className="__modal-header">
                    <h2><Trans id={props.headerText}/></h2>
                    { props.primaryButton === "Delete after downloading" ?
                        <button type="button" className="close" style={{marginTop: "-9%"}} data-dismiss="modal" aria-label="Close" onClick={(e) => props.onClickClose(e)}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        :
                        ''
                    }
                </div>
                <div className="__modal-body">
                    { props.values === undefined || props.values === ""?
                        <h4>{props.message}</h4>
                        :
                        <h4><Trans id={props.message} values={{name: props.values}}></Trans></h4>
                    }
                   { props.children }
                </div>
                <div className={props.primaryButton === "Delete after downloading" ? "" : "__modal-footer flex-end"}>
                    <button className={props.secondaryButton === "Delete without downloading" ? "__btn align-center margin-b-10 btn-red": "btn-red btn-cancel"} onClick={(e) => props.onClickSencondary(e)}>
                        { props.values === undefined || props.values === ""?
                            props.secondaryButton
                            :
                            <Trans id={props.secondaryButton} values={{name: props.values}}></Trans>
                        }
                    </button>
                    <Button className={props.primaryButton === "Delete after downloading" ? "__btn align-center margin-b-10 btn-green": "btn-green"} disabled={props.disablePrimaryButton} showSpinner={props.isLoading} onClick={(e) => props.onClickPrimary(e)}>
                        { props.values === undefined || props.values === ""?
                            props.primaryButton
                            :
                            <Trans id={props.primaryButton} values={{name: props.values}}></Trans>
                        }
                    </Button>
                </div>
            </form>
        </div>
    </div>
);

export default ConfirmModal;