import React from 'react';
import AuthService from '../AuthService';
import Loader from 'react-loader-spinner';
import { decryptData, printConsole } from '../helpers';

class AutoCall extends React.Component {
	constructor(props){
        super(props);
		this.authService = new AuthService();
		this.state = {
            signInEmail: decryptData(this.props.email),
			signInGeoLocation: '',
			signInPassword: 'Remote@',
			isLoading: false,
        }
    }
    
    componentDidMount = () => {
		this.setState({isLoading: true});
        this.signInGuest();
    }

	showLoader = () => {
        if (!this.state.isLoading) return '';
        return(
            <div className="loader">
                <Loader
                    type="ThreeDots"
                    color="#23c97d"
                    height="70"	
                    width="70"
                />
            </div>
        );
    }

	signInGuest = () => {
        printConsole('in auto login');
        let userId = decryptData(this.props.userid);
		fetch(window._env_.REACT_APP_API_URL+'/signin', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				email: this.state.signInEmail,// here sign in email need to be converted to lowercase NS2-317
				geoLocation: this.state.signInGeoLocation,
				password: `${this.state.signInPassword}${this.state.signInEmail.substring(0, this.state.signInEmail.indexOf("@"))}`
			})
		})
		.then(response => {
			this.setState({ status: response.status });
			if(response.status === 200){
				return response.json();
            }else{
				throw response;
            }
		})
		.then(({ user, token }) => {
			if(user && token){
                this.setState({isLoading: false})
                this.authService.setToken(token);
                this.authService.socketInit();
                this.props.subscribeToOnlineNotification();
                this.props.loadUser(user);
                this.props.setUserForCall({userId});
                window.history.replaceState(null, null, '/');
                this.props.onRouteChange('home');
			}
		})
		.catch((error) => {
            this.setState({isLoading: false})
            window.history.replaceState(null, null, '/');
            this.props.onRouteChange('signout');
		})
    }

	render() {
		return(
			this.state.isLoading ? this.showLoader() : ''

		);
	}
}

export default AutoCall;