import React from 'react';
import { Row, Col, Card, CardBody, Collapse, UncontrolledTooltip } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { I18n } from "@lingui/react";
import '../Landing/Groups.scss';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import Loader from '../Loader/Loader';
import AuthService from '../AuthService';

class InspectionSubTask extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            fileLoading: false, fileToLarge: false,
            fileNameArr: [],
            invalidFile: false
        };
        this.cardColor = {
            created: 'bg-white',//TP-4103
            assigned: 'bg-white', //TP-3220
            in_progress: 'bg-white', //'bg-in-progress' TP-4870
            finished: 'bg-finished',
            skipped: 'bg-finished',
            undefined: 'bg-white' //TP-3220
        };
        this.authService = new AuthService();
    }
    
    procedureStatus = (status, i18n) => {
        switch (status) {
            case 'created':
                return i18n._(t`created`);
            case 'assigned':
                return i18n._(t`assigned`);
            case 'in_progress':
                return i18n._(t`in_progress`);
            case 'finished':
                return i18n._(t`finished`);
            case 'skipped':
                return i18n._(t`skipped`);
            default:
                break;
        } 
    }

    render(){
        const props = this.props;
        const isJobFinished = (props.selectedJob && (props.selectedJob.status === 'finished'));
        const selectStyle = {
            option: (styles) => ({...styles, color: '#000'})     
        }
        return(
            <>
                <Loader text="Uploading...." isLoading={this.state.fileLoading} />
                {
                    props.subTasks.map((val, idx)=> {
                        const isSubTaskReadOnly = (props.subTasks[idx].status === 'created' || props.subTasks[idx].status === 'assigned' || props.subTasks[idx].status === undefined);
                        return (
                            <I18n key={idx} >
                                {({ i18n }) => 
                                
                                    <Card className={this.cardColor[props.subTasks[idx].status] + " mt-1 text-black"}>
                                        <CardBody className="p-2">
                                            <Row  className="pointer">
                                                <div className="col-md-4" onClick={() => { props.collapseSubTask(idx) }}>
                                                    <Trans>Procedure</Trans> {idx+1}:
                                                </div>
                                                <div className="col-md-6 groups-form-validation-msg" onClick={() => { props.collapseSubTask(idx) }}>
                                                    {props.subTasks[idx].showInvalidError ? <Trans>One or more entries are invalid</Trans> : ''} 
                                                </div>

                                                {/* {props.subTasks[idx].status === "in_progress" || props.subTasks[idx].status === "assigned"?
                                                        <div className="col-md-1">
                                                            <span id={"icon-qrcode-entry-" + idx} className="icon-wrapper pointer float-right" onClick={() => { props.downloadQRCode(idx, "entry") }}>
                                                                <i className="fas fa-qrcode"></i>                                
                                                            </span>
                                                            {props.isSmallFormFactor === false ?
                                                                    <UncontrolledTooltip placement="top"  target={"icon-qrcode-entry-" + idx}>
                                                                        {
                                                                            <Trans>Download Entry QR Code</Trans>
                                                                        }
                                                                    </UncontrolledTooltip>
                                                                :
                                                                    null
                                                            }
                                                        </div>
                                                    :
                                                        <div className="col-md-1"></div>
                                                }

                                                {props.subTasks[idx].status === "in_progress" || props.subTasks[idx].status === "assigned"?
                                                        <div className="col-md-1">
                                                            <span id={"icon-qrcode-exit-" + idx} className="icon-wrapper pointer float-right" onClick={() => { props.downloadQRCode(idx, "exit") }}>
                                                                <i className="fas fa-qrcode"></i>                                
                                                            </span>
                                                            {props.isSmallFormFactor === false ?
                                                                    <UncontrolledTooltip placement="top"  target={"icon-qrcode-exit-" + idx}>
                                                                        {
                                                                            <Trans>Download Exit QR Code</Trans>
                                                                        }
                                                                    </UncontrolledTooltip>
                                                                :
                                                                    null
                                                            }
                                                        </div>
                                                    :
                                                        <div className="col-md-1"></div>
                                                } */}

                                                <div className="col-md-1" onClick={() => { props.collapseSubTask(idx) }}>

                                                    {
                                                        props.subTasks[idx].collapse ?
                                                        <i className="fas fa-angle-up float-right" aria-hidden="true"></i>
                                                        :
                                                        <i className="fas fa-angle-down float-right" aria-hidden="true"></i> 
                                                    }

                                                </div>
                                                <div className="col-md-1">
                                                    {   isSubTaskReadOnly ? 
                                                            <>
                                                                {props.subTasks[idx].id ?
                                                                    <I18n>
                                                                        {({ i18n }) =>
                                                                            <span className="pointer" onClick={(e) => props.deleteSubTask({e,index: idx})}>
                                                                                <i className="far fa-trash-alt red-trash" id={'deleteTask-' + idx}></i>
                                                                                { props.isSmallFormFactor === false ? 
                                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'deleteTask-' + idx}>
                                                                                        <Trans>Delete Task</Trans>
                                                                                    </UncontrolledTooltip>       
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </I18n>
                                                                : 
                                                                    <I18n>
                                                                        {({ i18n }) =>
                                                                            <span className="pointer" onClick={(e) => props.deleteNewSubTask({e,index: idx})}>
                                                                                <i className="far fa-trash-alt red-trash" id={'deleteTask-' + idx}></i>
                                                                                { props.isSmallFormFactor === false ? 
                                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'deleteTask-' + idx}>
                                                                                        <Trans>Delete Task</Trans>
                                                                                    </UncontrolledTooltip>       
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </span>
                                                                        } 
                                                                    </I18n>
                                                                }
                                                            </>
                                                        : null
                                                    }
                                                </div>
                                            </Row>
                                            <Collapse isOpen={!props.subTasks[idx].collapse}>
                                                <>
                                                    <Row>
                                                        <Col className="input-wrapper">
                                                            <Select
                                                                value={props.subTasks[idx].task_instruction ? {value: props.subTasks[idx].task_instruction, label: props.subTasks[idx].task_instruction} : ""}
                                                                onChange={props.handleOptionChange(idx, 'room')}
                                                                components={makeAnimated()}
                                                                placeholder={i18n._(t` Select Room`)}
                                                                options={props.getRooms()}
                                                                isSearchable={props.isSmallFormFactor ? false : true}
                                                                isDisabled={!isSubTaskReadOnly || isJobFinished}
                                                                styles={selectStyle}
                                                                id={`room_`+ idx}
                                                            />
                                                            <input type="text" readOnly value={props.subTasks[idx].task_instruction} name={i18n._(t`room`)+ idx} className="hidden"/>
                                                            { props.validator.message(i18n._(t`room`)+ idx, props.subTasks[idx].action_id, 'required') }
                                                        </Col>
                                                        <Col className="input-wrapper">
                                                            <Select
                                                                value={props.subTasks[idx].expected_result ? {value: props.subTasks[idx].expected_result, label: props.subTasks[idx].expected_result} : ""}
                                                                onChange={props.handleOptionChange(idx, 'discipline')}
                                                                components={makeAnimated()}
                                                                placeholder={i18n._(t` Select Discipline`)}
                                                                options={props.getDisciplines()}
                                                                isSearchable={props.isSmallFormFactor ? false : true}
                                                                isDisabled={!isSubTaskReadOnly || isJobFinished}
                                                                styles={selectStyle}
                                                                id={`discipline_`+ idx}
                                                            />
                                                            <input type="text" readOnly value={props.subTasks[idx].expected_result} name={i18n._(t`discipline`)+ idx} className="hidden"/>
                                                            { props.validator.message(i18n._(t`discipline`)+ idx, props.subTasks[idx].action_id, 'required') }
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3">
                                                        <Col className="input-wrapper">
                                                            <Select
                                                                value={props.actionNames(props.subTasks[idx].selectedAction, i18n)}
                                                                onChange={props.handleActionChange(idx)}
                                                                components={makeAnimated()}
                                                                placeholder={i18n._(t` Select Evidence Type`)}
                                                                options={props.getSubTaskActionNameOptions(i18n)}
                                                                isSearchable={props.isSmallFormFactor ? false : true}
                                                                isDisabled={true}
                                                                styles={selectStyle}
                                                                id={`evidence_list_`+ idx}
                                                            />
                                                            <input type="text" readOnly value={props.subTasks[idx].action_id} name={i18n._(t`action_name`)+ idx} className="hidden"/>
                                                            { props.validator.message(i18n._(t`action_name`)+ idx, props.subTasks[idx].action_id, 'required') }
                                                        </Col>
                                                        <Col className="input-wrapper ">
                                                            <input
                                                                type="number"
                                                                min={1}
                                                                name={i18n._(t`sequence`)+ idx}
                                                                data-id={idx}
                                                                placeholder={i18n._(t` Sequence`)}
                                                                className="signup-input-field mt-0"
                                                                onChange={props.onChangeJobForm('sequence')}
                                                                value={props.subTasks[idx].sequence} 
                                                                disabled={!isSubTaskReadOnly || isJobFinished}//TP-3951,4103
                                                            />
                                                            { props.validator.message(i18n._(t`sequence`)+ idx, props.subTasks[idx].sequence, 'required|numeric|checkSequence') }
                                                        </Col>
                                                    </Row>
                                                </>
                                            </Collapse>
                                        </CardBody>
                                    </Card>
                                }
                            </I18n>
                        )   
                    })
                }
            </>
        )
    }
}


export default InspectionSubTask;