import React, { Fragment } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import MessageModal from '../MessageModal/MessageModal';
import Button from '../Button/Button';
import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import { encryptData } from '../helpers';
export default class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            isLoading: false,
            msgColor: '#485890'
        }
        // initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: props.language,
			element: message => <div className="signin-validation-msg">{message.replace(/field/g, '').capitalize()}</div>,
			validators: {
				emailValidate: {  // name the rule
					message: 'Please enter a valid email address',
					rule: (val, params, validator) => {
						const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return validator.helpers.testRegex(
							String(val).toLowerCase(),
							regex
						);
					},
					required: true  // optional
				}
			}
		});
    }

    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
    };

    onSubmitResetPassword = async (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
        this.setState({ isLoading: true });
        let encryptedEmail = encryptData(this.state.email.toLowerCase());//FQ3-417
        fetch(`${window._env_.REACT_APP_API_URL}/forgot-password`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                email: encryptedEmail
            })
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response
                throw error;
            }
        })
        .then((data) => {
            if (data) {
                this.setState({
                    isLoading: false,
                    message: i18nMark('Reset link has been sent to your email id.'),
                    msgColor: '#485890'
                });
            }
        })
        .catch ((error) => {
            this.setState({
                isLoading: false,
                message: i18nMark('Email not found!'),
                msgColor: 'red'
            });
        })
    }

    onClickClose = (e) => {
        e.preventDefault();
        // this.setState({ message: '' });
        this.props.routeChange('signin');
    }

    goToLogin = (e) => {
        e.preventDefault();
        this.props.routeChange('signin');
    }

    render = () => {
        return (
            <Fragment>
                { 
                    this.state.message ? <MessageModal message={this.state.message} onClickClose={this.onClickClose}/> :
                    <div className='modale opened'>
                        <div className='__modal-dialog'>
                            <form>
                                <div className="__modal-header">
                                    <h2><Trans>Reset Password</Trans></h2>
                                </div>
                                <div className="__modal-body">
                                    <label><Trans>Email</Trans>:
                                        <div className="input-wrapper">
                                            <I18n>
                                            { ({ i18n }) =>
                                            <>
                                                <input className="signup-input-field" name={i18n._(t`user_email`)} type="text" placeholder={i18n._(t`Enter your email`)} value={this.state.email} onChange={this.handleChange('email')}
                                                onKeyPress={(e)=> e.key === 'Enter' && this.onSubmitResetPassword(e)}/>
                                                {this.validator.message(i18n._(t`user_email`), this.state.email, 'required|emailValidate')}	
                                            </>									
                                            }
                                            </I18n>
                                        </div>
                                    </label>
                                </div>
                                <div className="__modal-footer flex-end">
                                    <button style={{'marginRight': 10 }} className="btn-green btn-cancel" onClick={this.goToLogin}><Trans>Close</Trans></button>
                                    <Button showSpinner={this.state.isLoading} onClick={(e) => this.onSubmitResetPassword(e)}><Trans>Send Reset Link</Trans></Button>
                                </div>
                            </form>
                        </div>
                    </div>
                }
            </Fragment>
        )
    }
}