//created by Roopesh Singh
import JSZip from  'jszip';
import { saveAs } from 'file-saver';
import pdfMake from 'pdfmake/build/pdfmake';
import GenerateJobPDF from "./generateJobPDF";
import GenerateCheckListJobPDF from "./generateCheckListJobPDF";
import GenerateWOPDF from "./generateWOReport";
import { printConsole } from '../helpers';

export default async ({data, selectedJob, customer, callBackFunction, language}) => { 
    try{
        let filename = selectedJob.name, documentDefinition;
        const zip = new JSZip();
        const folder = zip.folder('assets')
        if(selectedJob.job_type  === 'checklist'){
            documentDefinition = await GenerateCheckListJobPDF({...data,selectedJob,customer, language});//TP-2653,2826
        }else if(selectedJob.job_type  === 'scan_checklist'){
            documentDefinition = await GenerateWOPDF({...data,selectedJob,customer, language});//TP-2653,2826
        }else{
            documentDefinition = await GenerateJobPDF({...data,selectedJob,customer, language});//TP-2765
        }
        const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    
        let jobsBlobPromise = new Promise(function(myResolve) {
            pdfDocGenerator.getBase64((data) => {
                myResolve(data);
            });
        });
        jobsBlobPromise.then(async response => {
            //folder.file(filename+'.pdf',response, { base64: true });
            zip.file(filename+'.pdf', response, { base64: true });
            let urls = [];
            if(selectedJob.job_type  === 'checklist' || selectedJob.job_type  === 'scan_checklist'){
                const checkListTasks = data.checkListTasks, modelAssets = data.modelAssets;
                urls = fetchUrlData(checkListTasks, modelAssets);//TP-2826
            }else{
                const subTasks = data.subTasks, modelAssets = data.modelAssets;
                urls = fetchUrlData(subTasks, modelAssets);
            }
            var timestamp = new Date().getTime();
            for (const url of urls) {
                //url is coming as undefined
                if(url){
                    const blobPromise =  fetch(url)    
                    .then(function (response) {              
                        if (response.status === 200 || response.status === 0) {
                            return Promise.resolve(response.blob());
                        } else {
                            return Promise.reject(new Error(response.statusText));
                        }
                    })
                    .catch(error => {
                        console.log(`the urls is unaccessible ${error}`)
                    })
                    const str = await blobPromise;//TP-3443
                    if(str){
                        const name = url.substring(url.lastIndexOf('/'))
                        folder.file(name.split('/').pop(), str)
                    }
                }
            }
        
            zip.generateAsync({type:"blob"},function updateCallback(metadata){
                if(metadata.percent === 100){
                    callBackFunction(false);
                } 
            })
            .then(blob => saveAs(blob, filename+'_'+timestamp+".zip"))
            .catch(e => console.log(e));
        
        })
    }catch(error){
        printConsole(`error in generateZip ${(error)}`)
    }
   
}

const fetchUrlData = (dataArr, assets) =>{
    let outputResult = []
    try{
        dataArr.forEach((item)=>{
                let taskData = item.task_data ? item.task_data: [];
                let referenceMaterial = item.reference_materials; 
                let externaleEvidence = item.external_evidence ? JSON.parse(item.external_evidence):null;
                let model_references = item.model_references ? item.model_references: null;
                const {incidents} = item;//TP-6966
                if(taskData.length > 0){
                    taskData.forEach((task) => {
                        const parsedTaskData = JSON.parse(task);
                        outputResult.push(parsedTaskData.url)
                    })
                }
    
                if(externaleEvidence && externaleEvidence){
                    let externalEvidenceUrls = getReferenceUrls(externaleEvidence,'external');
                    outputResult.push(...externalEvidenceUrls);
                }
                if(referenceMaterial && referenceMaterial.length){
                   let refMaterialUrls =  getReferenceUrls(referenceMaterial,'material');
                   outputResult.push(...refMaterialUrls)
                }

                //TP-2554
                if(model_references && model_references.length> 0){
                    let modelReferenceUrls =  getReferenceUrls(model_references,'model_reference');
                    outputResult.push(...modelReferenceUrls)
                }

                if(assets && assets.length> 0){
                    let assetReferenceUrls =  getReferenceUrls(assets,'asset_reference');
                    outputResult.push(...assetReferenceUrls)
                }
                //TP-851
                if(item.audio_link){
                    try {
                        let audioUrl = item.audio_link.map(audio=>JSON.parse(audio).url).pop();
                        if(audioUrl){
                            outputResult.push(audioUrl);
                        }                    
                    } catch (error) {
                        printConsole("Error found in fetchUrlData: ",error, "  while parsing te audio data");
                    }
                }
                //task/procedure can have multiple incidents. Each incidents can have multiple image urls
                for (let i = 0; i < incidents.length; i++){
                    //loop through the data to find the image. Each row will have the image as td
                    if(incidents[i].image_urls){
                        const images = incidents[i].image_urls;//multiple images
                        for(let index = 0; index < images.length; index++){
                            //{ url: 'https://s3.us-east-2.amazonaws.com/telepresenz-20-p2pfiles/jobs/dev/APK_38010_1701412424620.mp4', type: 'video' }
                            let parseData = images[index];
                            if(parseData.url){
                                outputResult.push(parseData.url);
                            }
                        }
                    }
                }
            })
        return outputResult
    }catch(error){
        printConsole(`error in fetchUrlData ${(error)}`)
        return outputResult;
    }
}

 

const getReferenceUrls = (dataArr,type) =>{
    let outputResult = [];
    dataArr.forEach((item)=>{
        if(type === 'material'){
            let fileInfo = item.file_info ? JSON.parse(item.file_info): null;
            if(fileInfo){
               outputResult.push(fileInfo.location)
           }
        }else{
            let location = item.location ? item.location:null;
            if(location){
               outputResult.push(location)
           } 
        }
        
    })
    return outputResult;
}

