import promisifyLoader from './PromisifyLoader';
import * as THREE from 'three';
import {TDSLoader} from './TDSLoader';

export const TDSObjectLoader = async ({ objFilePath, textureFilePath }) => {
    let objectToReturn;
    try {
        const _TDSLoader = promisifyLoader(new TDSLoader(), progressHandler);
        objectToReturn = await _TDSLoader.load(objFilePath);
        /** Load all texture files */
        textureFilePath.forEach(path => {
            const textureLoader = new THREE.TextureLoader();
            const texture = textureLoader.load(path, progressHandler);
            objectToReturn.traverse((child) => {
                if (child instanceof THREE.Mesh && texture) {
                    child.material.map = texture;
                }
            });
        });
    } catch (error) {
        errorHandler(error);
    }

    function progressHandler(e) {
        console.info((e.loaded / e.total * 100 ) + '% loaded' );
    }

    function errorHandler(error) {
        console.info( 'An error happened', error);
        throw error;
    }

    return objectToReturn;
}

export default TDSObjectLoader;

