import React from 'react';
import './ChatSidePanel.css';
import { t } from '@lingui/macro';
import { I18n } from "@lingui/react";
let sidePanel;
//TP-6828
function onKeyPress(e){
    if(e === null || e === undefined)
      return;
  
    let code = (e.keyCode ? e.keyCode : e.which);
    if (code === 13) { //'Enter' keycode
      let sendElem = document.getElementsByClassName('chat-search-box');
      if(sendElem && sendElem[0] && 
          (navigator.platform === 'iPad' || navigator.platform === 'iPhone' ||
          navigator.platform === 'Android' || navigator.platform === 'Linux armv7l')) 
        sendElem[0].blur();
        onSendSearchChat();
    }
}
//TP-6828
function onSendSearchChat(){
    let elemInput = document.getElementsByClassName('chat-search-box');
    if(elemInput[0] !== null && elemInput[0] !== undefined)
    {
      //console.log('calling session::onsendchatmsg', elemInput[0].value);
      if(elemInput[0].value !== '' && sidePanel !== null && sidePanel !== undefined){
        let strMsg = elemInput[0].value;
        strMsg = strMsg.trim();
        if(strMsg.length > 0)
            //sidePanel.props.onSendChatMsg(strMsg, sidePanel.props.bPrivate?sidePanel.props.elemId:null);
         
        elemInput[0].value = '';
      }
    }
    else
      console.log('calling session::onSendSearchChat with elemInput = ', elemInput);
}

class ChatSidePanel extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            participantList: props.participantList !== undefined && props.participantList.length > 0 ? props.participantList : [],          
        }
        sidePanel = this;
    
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.participantList !== this.props.participantList) {
            this.setState({ participantList: nextProps.participantList })
        }
    }

    render() {
        const {selectedUser} = this.props;
        const parentLeft = Math.floor(((window.innerWidth - 480)/2)  - 190)
        return (
            <div className='side-panel-parent' style={{height: this.props.chatHeight, left: parentLeft+'px'}}>
                <div className='sidepanel-topsection'>
                    <I18n>
                        {({ i18n }) => <input className='chat-search-box' 
                            placeholder={i18n._(t`chatSearch`)}
                            autoFocus={true}
                            onKeyPress={(event) => onKeyPress(event)}/>
                        }
                    </I18n> 
                    <span>
                        <I18n>
                            {({ i18n }) =>
                                <a title={i18n._(t`enterSearchChat`)} >
                                    <i className="fas fa-search searchchatbtn"></i>
                                </a>
                            }
                        </I18n>
                    </span>
                </div>
                <ul style={{ marginTop: "100px", width: "270px"}}>
                { this.state.participantList.map(parti => {
                    return (
                        <li id={"each-user-"+parti.email} className={selectedUser.email === parti.email ? "row no-gutters items profile-cursor panel panel-active": "row no-gutters items panel profile-cursor"} onClick={selectedUser.email === parti.email ? () => {return false} : () => this.props.updateUser(parti.email)}>
                            <span className="col-2"> 
                                {  parti.myPicture !== undefined && parti.myPicture !== null ?
                                    <img className="session-profile-pic logo-profile-pic-list profile-cursor" alt="profile_logo" src={parti.myPicture} ></img>
                                    :
                                    <img className="session-profile-pic logo-profile-pic-list profile-cursor" src={"/profile.png"} alt='profile_logo' />
                                }
                            </span>
                            <span className="col-7">
                                { parti.isexpert ?
                                    <><i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;</>
                                    :
                                    ''
                                }
                                {parti.first_name+" "+parti.last_name}
                            </span>
                            <span className="col-2">
                                <span className="lastseen-timecls">16:19</span>
                            </span>
                        </li>
                    )
                })}
                </ul>
            </div>
        )
    }
}

export default ChatSidePanel;