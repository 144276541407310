  import React, { Component } from "react";
  import noUiSlider from 'nouislider';
  import 'nouislider/dist/nouislider.css';
  import "./style.css";
  let rgSlider = null;

  const labels = [
    '0', '1', '2', '3', '4', '5'
  ];
  
  class RangeSlider extends Component {
    constructor(props){
      super(props);
      //console.log(props);
      this.state = {
          value: 50,
          min: 1,
          max: 1000,
      }      
      rgSlider = this;
    }
    componentWillReceiveProps(nextProps) {
      if (nextProps.startPosition !== this.props.startPosition) {
          this.setState({
              value: nextProps.startPosition
          })
      }
      if (nextProps.minimum !== this.props.minimum) {
        this.setState({
            min: nextProps.minimum
        })
      }
      if (nextProps.maximum !== this.props.maximum) {
        this.setState({
            max: nextProps.maximum  
        })
      }
    }

    createSlider = () => {
      var rangeSlider = document.getElementById('slider-range');

      noUiSlider.create(rangeSlider, {
          start: rgSlider.props.startPosition,
          step: 20,
          direction: 'rtl',
          orientation: 'vertical',
          connect: true,
          range: {
              'min': rgSlider.props.minimum,
              'max': rgSlider.props.maximum
          },
          /* tooltips: true, */
          pips: {
            mode: 'steps', 
            values: [rgSlider.props.minimum, 20, 40, 60, 80, rgSlider.props.maximum], 
            density: 20,
            filter: function() {
              return 20;
            }
          }
      });
      rangeSlider.noUiSlider.on('update', function (values, handle) {
        //rangeSliderValueElement.innerHTML = values[handle];
        rgSlider.changeRange(values[handle]);
        var handler = document.querySelectorAll('.noUi-handle.noUi-handle-lower');
        //console.log(handler);
          //Change the opacity of the handle of the slider based on the value          
          if (values[handle] > 80) {
            handler[0].style.background = "#302E2E";
          } else if (values[handle] <= 80 && values[handle] > 60) {
            handler[0].style.background = "#464444";
          } else if (values[handle] <= 60 && values[handle] > 40) {
            handler[0].style.background = "#55545480";
          } else if (values[handle] <= 40 && values[handle] > 20) {
            handler[0].style.background = "#7C7A7A80";
          } else if (values[handle] <= 20 && values[handle] > 0) {
            handler[0].style.background = "#ADAAAA80";
          } else {
            handler[0].style.background = "#DFDADA";
          }          
      });

      var pips = rangeSlider.querySelectorAll('.noUi-value');  
      var pipLabels = rangeSlider.querySelectorAll('.noUi-value.noUi-value-vertical');

      function clickOnPip () {
        var rangeSlider = document.getElementById('slider-range');
        var value = Number(this.getAttribute('data-value'));
        rangeSlider.noUiSlider.set(value);
      }   

      for (let i = 0; i < pips.length; i++) {
          pips[i].style.cursor = 'pointer';
          pips[i].addEventListener('click', clickOnPip);
      }

      for (let j= 0; j < pipLabels.length; j++) {
        //Set the labels of the pips
        pipLabels[j].innerHTML = "";
        //pipLabels[j].innerHTML = labels[j];
      }
    }

    clickOnPip = () => {
      var rangeSlider = document.getElementById('slider-range');
      var value = Number(this.getAttribute('data-value'));
      rangeSlider.noUiSlider.set(value);
    }

    componentDidMount = () => {
      this.createSlider();
    }

    componentDidUpdate =(prevProps, prevState) => {
      if (prevState.max !== this.state.max) {
        this.createSlider();
      }
    }

    changeRange = (value) => {
      //console.log(value);
      this.props.setCurrentValue(value);
    }
    
    render() {
      const rangeHeight = Math.floor((this.props.sliderHeight)*6/8);
      return (
        <div className="range-box" style={{left: this.props.sliderLeft, top: this.props.sliderTop, right: this.props.sliderRight, height: this.props.sliderHeight}}>
          <div id="slider-range" style={{height: rangeHeight}}></div>
        </div>
      )
    }
  }
  
export default RangeSlider;