//created by Roopesh Singh TP-501
import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import ImageNotFound from './no-image-icon.png';
import siteLogo from './GreenLogo.JPG';
import {localizedData} from '../../languages/localizedData'; //TP-4184
let fontStyle;
let globalLanguage = "en"; //TP-4184

//TP-4184 -- the languages we support
const includedLanguages = ['en', 'fr', 'de', 'pt', 'es', 'it', 'ja-JP', 'zh-CN', 'zh-cn', 'hi', 'or']

const base64Img = require('base64-img');
let statusObj = {'created': localizedData[globalLanguage]['Created'], 'assigned': localizedData[globalLanguage]['Assigned'],'in_progress': localizedData[globalLanguage]['In Progress'], 'finished': localizedData[globalLanguage]['Finished'], 'skipped': localizedData[globalLanguage]['Skipped']}

const _format = async (data) => {
    try{
        let dataArr = [];
        for(let i=0;i < data.length; i++){
            let task = data[i];
            let task_data,tableBody,parsedTaskData = {};
            let pageBreakObj = (i === data.length - 1) ? '' : {text: '',pageBreak: 'after'}
            let audioLink, audioText;

            switch(task.name){
                case "Pass/Fail/NA":
                case "Yes/No/NA":
                    task_data = task.task_data ? task.task_data : []
                    audioLink = audio_link({audio_link_data: task.audio_link}); audioText = audio_text({audio_link_data: task.audio_link});
                    tableBody = await imgDataLoop(task_data);
                    if(Array.isArray(task_data) && task_data.length> 0){
                        parsedTaskData = JSON.parse(task_data[0]);
                    }
                    dataArr.push([
                        {
                            text: `${localizedData[globalLanguage]["Scan"]}# ${task.sequence}`,
                            style: 'subheader',
                        },
                        {
                            style: 'tableExample',
                            color: '#444',
                            table: {
                                widths: [175, '*'],
                                body: [
                                    ...taskDetailsTable(task, parsedTaskData, audioText)
                                ]
                            },
                            layout: tableLayout()
                        },
                        {table: {
                            body: [
                                    [tableBody],
                                    [`${(tableBody && tableBody.length > 0) ? localizedData[globalLanguage]["Captured Evidence"] : ""}`],//TP-3887
                                ]
                            },
                            layout: 'noBorders',
                            alignment: "center"
                        },
                        pageBreakObj
                    ]);
                    break;
                default:
                dataArr.push('');
                break;
            }
        }
        return dataArr.flat();
    }catch(error){
        console.error(`error in _format method while constructiing pdf data ${error.message}`)
        return [];
    }
}

//TP-6528
const disclaimer = () => {
    try{
        return(
            [
                {text: '', pageBreak: 'after'},
                {text: `${localizedData[globalLanguage]["Addendum"]}`, style: "summaryHeader", alignment: 'center', fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 35 : 40, color: '#2d3858'},
                {text: `**${localizedData[globalLanguage]["Disclaimer header"]}**`, fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 14 : 16, bold: true, italics: true},
                {text: `${localizedData[globalLanguage]["Disclimer para 1"]}`, italics: true},
                {text: `${localizedData[globalLanguage]["Disclimer para 2"]}`, italics: true, margin: [0, 10, 0, 0]},
                {text: `${localizedData[globalLanguage]["Disclimer para 3"]}`, italics: true, margin: [0, 10, 0, 0]},
                {text: `${localizedData[globalLanguage]["Disclimer para 4"]}`, italics: true, margin: [0, 10, 0, 0]},
                {text: `${localizedData[globalLanguage]["Disclimer para 5"]}`, italics: true, margin: [0, 10, 0, 0]},
                {text: `${localizedData[globalLanguage]["Disclimer para 6"]}`, italics: true, margin: [0, 10, 0, 0]},
                {text: `${localizedData[globalLanguage]["Disclimer para 7"]}`, italics: true, margin: [0, 10, 0, 0]}
            ]
        )
    }catch(error){
        console.error(`error in disclaimer method while constructiing pdf data ${error.message}`)
    }
}

const taskDetailsTable = (task, parsedTaskData, audioText) => {
    let table = [];
    try{
        table.push([{text: `${localizedData[globalLanguage]["Scanned By"]}:`, style: `${classNameOfLeftTd(table)}`}, {text: getExecutedBy({task}), style: `${classNameOfRightTd(table)}`}]);
        table.push([{text: `\n`+localizedData[globalLanguage][`Time of Scan`]+`:`, style: `${classNameOfLeftTd(table)}`}, {text: `${getExecutionDuration({task})}\n ${moment(task.execution_start_time).isValid() ? moment(task.execution_start_time).format("DD MMMM YYYY, h:mm:ss a"): ''} - ${moment(task.execution_end_time).isValid() ? moment(task.execution_end_time).format("DD MMMM YYYY, h:mm:ss a"): ''}`, style: `${classNameOfRightTd(table)}`}]);

        if(task.call_recordings && task.call_recordings.length > 0){
            table.push([{text: `${localizedData[globalLanguage]["Live Inspection Detail(s)"]}:`, style: `${classNameOfLeftTd(table)}`}, {stack:
                [
                    {
                        ul: [
                                ...getMultipleParsedCallRecords({call_recordings: task.call_recordings})
                        ]
                    }
                ], style: `${classNameOfRightTd(table)}`}]);
        }
        table.push([{text: `${localizedData[globalLanguage]["Scan"]} ${localizedData[globalLanguage]["Device Details"]}:`, style: `${classNameOfLeftTd(table)}`},
            {
                text: `${parsedTaskData.device_manufacturer || ''} ${parsedTaskData.device_model || ''} ${parsedTaskData.serial_number || ''}`,
                style: `${classNameOfRightTd(table)}`
            }
        ]);
        table.push([{text: `${localizedData[globalLanguage]["Scan"]} ${localizedData[globalLanguage]["Location"]}:`, style: `${classNameOfLeftTd(table)}`},
            {
                text: `${parsedTaskData.coordinates || ''}`,
                style: `${classNameOfRightTd(table)}`
            }
        ]);

        table.push([{text: `${localizedData[globalLanguage]["Scan"]} ${localizedData[globalLanguage]["Conclusion"]}:`, style: `${classNameOfLeftTd(table)}`}, {text: `${audioText}`, style: `${classNameOfRightTd(table)}`, color: `${parsedTaskData && parsedTaskData.cl_observation === "Pass" ? "#73E831" : "#EE9A9B"}`}]);
        
        return table
    }catch(error){
        console.log('taskDetailsTable error',error)
        return table       
    }
}

const getMultipleParsedCallRecords = ({call_recordings}) => {
    let callRecordings = [];
    try{
        if(call_recordings){
            callRecordings = call_recordings.map((call_recording) => {
                const callRecordingData = call_recording ? JSON.parse(call_recording) : null
                if (callRecordingData.call_duration > 59){
                    const minDiff = Math.floor(callRecordingData.call_duration/60);
                    if(callRecordingData.call_end_time){
                        return {text: `${localizedData[globalLanguage]["Monitored by"]} ${callRecordingData.expert_first_name} ${callRecordingData.expert_last_name} ${localizedData[globalLanguage][" at "]} ${moment(callRecordingData.call_start_time).format("DD MMMM YYYY, h:mm:ss a")} ${localizedData[globalLanguage][" for "]} ${minDiff} ${localizedData[globalLanguage]["minutes"]}`}
                    }else{
                        return {text: `${localizedData[globalLanguage]["Being monitored by"]} ${callRecordingData.expert_first_name} ${callRecordingData.expert_last_name} ${localizedData[globalLanguage]["since"]} ${moment(callRecordingData.call_start_time).format("DD MMMM YYYY, h:mm:ss a")} (${localizedData[globalLanguage]["live"]})`}
                    }
                }else{
                    if(callRecordingData.call_end_time){
                        return {text: `${localizedData[globalLanguage]["Monitored by"]} ${callRecordingData.expert_first_name} ${callRecordingData.expert_last_name} ${localizedData[globalLanguage][" at "]} ${moment(callRecordingData.call_start_time).format("DD MMMM YYYY, h:mm:ss a")} ${localizedData[globalLanguage][" for "]} ${callRecordingData.call_duration} ${localizedData[globalLanguage]["seconds"]}`}
                    }else{
                        return {text: `${localizedData[globalLanguage]["Being monitored by"]} ${callRecordingData.expert_first_name} ${callRecordingData.expert_last_name} ${localizedData[globalLanguage]["since"]} ${moment(callRecordingData.call_start_time).format("DD MMMM YYYY, h:mm:ss a")} (${localizedData[globalLanguage]["live"]})`}
                    }
                }
            });
        }
        return callRecordings;
    }catch(error){
        console.log('getMultipleParsedCallRecords error', error)
    }
}

const audio_link = ({audio_link_data}) => {
    try {        
        if(Array.isArray(audio_link_data) && audio_link_data.length> 0){
            const parsedTaskData = JSON.parse(audio_link_data[0]);
            const audioOURL = parsedTaskData ? parsedTaskData.url:null;
            const audioName = audioOURL.substring(audioOURL.lastIndexOf('/')).substring(1);
            return 'assets/'+encodeURIComponent(audioName)
        }
        return '';
    }catch (error){
        console.error('get multiple Audio Parse Error:'+error);
        return '';
    }
}

//TP-2036
const audio_text = ({audio_link_data}) => {
    try {        
        if(Array.isArray(audio_link_data) && audio_link_data.length> 0){
            const parsedTaskData = JSON.parse(audio_link_data[0]);
            return parsedTaskData.comments;
        }
        return '';
    }catch (error){
        console.error('get multiple Audio text Parse Error:'+error);
        return '';
    }
}

const imgDataLoop = async (task_data) => {
    try{
        const tableBody = [];
        for (let index = 0; index < task_data.length; index++) {
                        
            let parseData = JSON.parse(task_data[index]);
            const url = parseData.url ? parseData.url : ''
            let imgData = await getImageDataUrl(url);
            let imgIndex = url.split('/').length -1;
            let imageLink = url.split('/')[imgIndex]
            if(url){
                tableBody.push({
                    image: imgData,
                    // link: parseData.url,
                    link: 'assets/'+encodeURIComponent(imageLink),
                    fit: [250, 200],
                    margin: 15,
                    alignment: 'left'
                });
            }else{
                tableBody.push('')
            }
        }
        return tableBody;
    }catch(err){
        console.error(`error in imgDataLoop `, err)
    }
};

const getImageDataUrl = (imageUrl) =>{
    try{
        let imgDataUrl;
        return new Promise((resolve, reject) => {
            base64Img.requestBase64(imageUrl, function(err, res, body) {
                if(imageUrl === '' || err || res.statusCode !== 200){
                    imgDataUrl = ImageNotFound;
                }else{
                    imgDataUrl = body;
                }
                resolve(imgDataUrl);
            });
        })
    }catch(err){
        console.error(`error in getImageDataUrl `, err)
    }
};

// Mb2-836
const getExecutionDuration = ({task}) => {
    try{
        if(moment(task.execution_start_time).isValid() && moment(task.execution_end_time).isValid()){
            const a = moment(task.execution_end_time), b = moment(task.execution_start_time);
            let timediff = Math.abs(a.diff(b))/1000 ;
            if (timediff > 0){
                if(timediff < 1){
                    const roundedTimeDiff = Math.round(timediff * 100) / 100
                    return (`${localizedData[globalLanguage]["Duration"]}: (` + roundedTimeDiff + ` ${localizedData[globalLanguage]["seconds"]})`);
                }else{
                    return (`${localizedData[globalLanguage]["Duration"]}: (` + Math.floor(timediff) + ` ${localizedData[globalLanguage]["seconds"]})`);
                }
            }else{
                return '';
            }
        }else{
            return ''
        }
    }catch(err){
        console.error(`error in getExecutionDuration`, err)
        return ''
    }
}

const getExecutedBy = ({task}) => {
    if(task.status === 'finished' || task.status === 'skipped'){
        let name;
        name = task.first_name || localizedData[globalLanguage]['Non'];
        name += ' ';
        name += task.last_name || localizedData[globalLanguage]['- existent'];//TP-5997
        name += task.is_offline_mode? ' ('+localizedData[globalLanguage]['offline']+')' : ' ('+localizedData[globalLanguage]['online']+')'
        return name;
    }else{
        return '';
    }
}

const showOriginalReferenceFileName = ({fileName}) => {
    try{
        let originalFileName = '';
        if (fileName){
            let splitedFileName = fileName.split(/_(.+)/);//TP-1058
            // splitedFileName.shift();originalFileName = splitedFileName.join('-');
            originalFileName = splitedFileName[1];
        }
        if(originalFileName){
            return originalFileName;
        }else{
            return ''
        }
    }catch(error){
        console.log('showOriginalFileName error', error)
        return ''
    }
}

const getAssetReferenceFiles = ({files, type}) => {
    let referenceMaterials = [];
    try{
        referenceMaterials = files.filter((file) => file.type === type).map((file) => {
            return {text: `${showOriginalReferenceFileName({fileName: file.file_name })}`, link: 'assets/'+encodeURIComponent(file.file_name)}//TP-2882
        });
        return referenceMaterials;
    }catch(error){
        console.log('getAssetReferenceFiles error', error)
    }
}

const tableLayout = () => {
    const layout = ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? {
        paddingTop: function(i, node) { return 0; },
        paddingBottom: function(i, node) { return 0; },
    } : {}

    return layout
}

// TP-3837
const getComment = (task) => {
    try{
        let comment = "";
        if (task.name === "Pass/Fail/NA" || task.name === "Yes/No/NA" ){
            comment = task.tech_comment? task.tech_comment : localizedData[globalLanguage]['NA']
        }else{
            comment = task.tech_comment? task.tech_comment : ''
        }    
        return comment
    }catch(error){
        console.log('getComment error',error)
        return ""
    }    
}    

//TP-3147
const jobStatusSummary = (job_status, totalExecutionTime) => {
    try{
        const totalExecutionTimeInSecs = Math.floor(totalExecutionTime/1000) ;
        const minutes = Math.floor(totalExecutionTimeInSecs / 60);
        const seconds = totalExecutionTimeInSecs - minutes * 60;

        if(job_status === 'in_progress'){
            return localizedData[globalLanguage]['Work order is not yet completed.']
        }else if(job_status === 'finished'){
            let str = localizedData[globalLanguage]['Work order was completed in'] + ((minutes > 0 ? ` ${minutes}`+localizedData[globalLanguage][` minutes and`] : '') + ` ${seconds}`+localizedData[globalLanguage][` seconds.`])
            return str;
        }else if(job_status === 'created'){
            return `${localizedData[globalLanguage]["Work order has not been assigned."]}`//TP-5945
        }else{
            return localizedData[globalLanguage]["Work order has been assigned but has not yet been started to be worked on."]
        }
    }catch(error){
        console.error(`errror in jobStatusSummary ${error.message}`)
        return ''
    }
}

//TP-4710
const classNameOfLeftTd = (table) => {
    if((table.length + 1) % 2 == 0){
        return "dataEvenRow"
    }else{
        return "dataOddRow"
    }
}

const classNameOfRightTd = (table) => {
    if((table.length +1) % 2 == 0){
        return "evenRow"
    }else{
        return "oddRow"
    }
}

const _summary = async (data) => {
    try{
        let tableHeaderFontSize = ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 15 : 17;
        let tableContentFontSize = ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 13 : 15;
        let task_data, parsedTaskData;
        let tableContent = [[
            { text: '#', style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize},
            { text: `${localizedData[globalLanguage]["Assignee"]}`, style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize},
            { text: `${localizedData[globalLanguage]["Scan"]} ${localizedData[globalLanguage]["Result"]}`, style: 'tableHeader', fillColor: '#7558CB', color:'#FFFFFF', fontSize: tableHeaderFontSize}
        ]];
        let totalExecutionTime = 0;
        for(let i=0; i < data.length; i++){
            // if(data[i].instruction && data[i].instruction.length > 50) {
            //     tInstruction = data[i].instruction.substring(0,50) + "...";
            // }else{
            //     tInstruction = data[i].instruction;
            // }
            let assignee = (data[i].first_name || `${localizedData[globalLanguage]["Non"]}`) + ' ' + (data[i].last_name || `${localizedData[globalLanguage]["- existent"]}`);
            task_data = data[i].task_data ? data[i].task_data : []
            if(Array.isArray(task_data) && task_data.length> 0){
                parsedTaskData = JSON.parse(task_data[0]);
            }
            let sequence = data[i].sequence;
            let styleClass = (i + 1)%2 === 0 ? 'evenRow' : 'oddRow'
            let audioText = audio_text({audio_link_data: data[i].audio_link});//TP-6253
            tableContent.push([
                    {
                        text: `${localizedData[globalLanguage]["Scan"]} ${sequence}`, style: styleClass, fontSize: tableContentFontSize
                    },
                    {
                        text: `${assignee}`, style: styleClass, fontSize: tableContentFontSize
                    },
                    {
                        text: `${audioText.length> 70 ? audioText.substring(0,70) + "..." : audioText}`, style: styleClass, fontSize: tableContentFontSize
                    }
                ])
            totalExecutionTime += moment(data[i].execution_start_time).isValid() && moment(data[i].execution_end_time).isValid() ? (moment(data[i].execution_end_time) - moment(data[i].execution_start_time)) : 0;//TP-6530
        }

        let summaryData = [
            {text: `${localizedData[globalLanguage]["Summary"]}`, style: "summaryHeader", fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 35 : 40, color: '#2d3858'},
            // {text : [{text: `${localizedData[globalLanguage]["Scheduled to start"]}:`, style:"header", italics: true}, {text: ` ${moment(data[0].job_start_date).format("DD MMMM YYYY, h:mm a")}`, style:'header', italics: true}]},//TP-4867
            // {text : [{text: `${localizedData[globalLanguage]["Scheduled to end"]}:`, style:"header", italics: true}, {text: ` ${moment(data[0].job_end_date).format("DD MMMM YYYY, h:mm a")}`, style:'header', italics: true}]},//TP-4867
            {text: jobStatusSummary(data[0].job_status, totalExecutionTime), style: 'statusSummary'},//TP-3147
            {
                columns: [
                    {
                        style: 'tableExample',
                        table: {
                            widths: [60, 120, "*"],//TP-6253
                            body: tableContent
                        }
                    },
                ]
            
            },
            {text: '',pageBreak: 'after'}
        ];

        return summaryData.flat();
    }catch(error){
        console.error(`errror in _summary ${error.message}`)
        return [];
    }
};

//TP-5945
const _summaryStatus = async (data) => {
    try{
        let totalExecutionTime = 0;
        let summaryData = [
            {text: `${localizedData[globalLanguage]["Summary"]}`, style: "summaryHeader", fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 35 : 40, color: '#2d3858'},
            {text: jobStatusSummary(data[0].job_status, totalExecutionTime), style: 'statusSummary'},//TP-3147
        ];

        return summaryData.flat();
    }catch(error){
        console.error(`errror in _summary ${error.message}`)
        return [];
    }
};

//TP-3993
const setFont = (language) => {
    if (language === "zh-cn" || language === "zh-CN" || language === "ja-JP"){
        fontStyle = "NotoSansSC"
    }else if(language === "hi"){
        fontStyle = "NotoSans";
    }else if(language === "or"){
        fontStyle = "NotoSansOriya";
    }else{
        fontStyle = "Roboto";
    }
}

const _introduction = async ({jobName, company_name, creater_name , job_created_on, last_updated_on, customer, location_name}) => {
    try{
        let introData = [], logo_url, headerFontSize = fontStyle === "NotoSansSC" ? 20 : 25,
        logoSize = fontStyle === "NotoSansSC" ? [150, 100] : [350, 300];
        if (customer.allow_custom_logo && customer.jobs_logo_url && customer.jobs_logo_url !== ''){
            logo_url = JSON.parse(customer.jobs_logo_url).location;//T32-10
        }
        let imgData = logo_url && logo_url !== '' ? await getImageDataUrl(logo_url) : await getImageDataUrl(siteLogo);
        //TZ-50
        const introArr = [];
        introArr.push(
            {text: `${jobName} ${localizedData[globalLanguage]["Report"]}\n\n`, style:'header', fontSize: headerFontSize, alignment: 'center'},
            {text: `${localizedData[globalLanguage]["Account Name"]}: ${company_name}\n\n`, style:'header', alignment: 'center'},
            {text: `${localizedData[globalLanguage]["Created by"]}: ${creater_name}\n\n`, style:'header', alignment: 'center'},
            {text: `${localizedData[globalLanguage]["Created On"]}: ${job_created_on}\n\n`, style:'header', alignment: 'center'},
            {text: `${localizedData[globalLanguage]["Updated On"]}: ${last_updated_on}\n\n`, style:'header', alignment: 'center'}
        )
        if(location_name){
            introArr.push({text: `${localizedData[globalLanguage]["Location"]}: ${location_name}\n\n`, style:'header', alignment: 'center'})
        }
        introData.push(
            {
                text:[
                    ...introArr
                ],
                margin: [ 0, 10, 0, 0 ]
            },
            {
                image: imgData,
                fit: logoSize,
                alignment: 'center',
                margin: [ 5, 10, 0, 0 ],
                pageBreak: 'after'
            }
        )
        return introData;
    }catch(error){
        console.log(error)
        return [];
    }
}

export default async ({checkListTasks, modelAssets:assets, selectedJob, customer, language}) => {
    try{
        //TP-4184
        globalLanguage = includedLanguages.includes(language) === false ? "en" : (language === "zh-cn" || language === "zh-CN" ) ? "zh-cn" : language;
        statusObj = {'created': localizedData[globalLanguage]['Created'], 'assigned': localizedData[globalLanguage]['Assigned'],'in_progress': localizedData[globalLanguage]['In Progress'], 'finished': localizedData[globalLanguage]['Finished'], 'skipped': localizedData[globalLanguage]['Skipped']}
        const data = checkListTasks;
        const {vfs} = vfsFonts.pdfMake;
        pdfMake.vfs = vfs;
        const fontBaseUrl = window._env_.REACT_APP_APP_MODE === "LAN" ? `https://${window._env_.REACT_APP_API_SOCKET_URL}/fonts` : "https://telepresenz-20-p2pfiles.s3.us-east-2.amazonaws.com"
        pdfMake.fonts = {
            NotoSans: {
                normal: `${fontBaseUrl}/NotoSans-Regular.ttf`,//TP-5907
                bold: `${fontBaseUrl}/NotoSans-Medium.ttf`,
                italics: `${fontBaseUrl}/NotoSans-Regular.ttf`,
                bolditalics: `${fontBaseUrl}/NotoSans-Medium.ttf`,
            },
            NotoSansOriya: {
                normal: `${fontBaseUrl}/NotoSansOriya-Regular.ttf`,//TP-5907
                bold: `${fontBaseUrl}/NotoSansOriya-Medium.ttf`,
                italics: `${fontBaseUrl}/NotoSansOriya-Regular.ttf`,
                bolditalics: `${fontBaseUrl}/NotoSansOriya-Medium.ttf`,
            },
            NotoSansSC: {
                normal: `${fontBaseUrl}/NotoSansSC-Regular.otf`,
                bold: `${fontBaseUrl}/NotoSansSC-Medium.otf`,
                italics: `${fontBaseUrl}/NotoSansSC-Regular.otf`,
                bolditalics: `${fontBaseUrl}/NotoSansSC-Medium.otf`
            },
            Roboto: {
                normal: 'Roboto-Regular.ttf',
                bold: 'Roboto-Medium.ttf',
                italics: 'Roboto-Italic.ttf',
                bolditalics: 'Roboto-Italic.ttf'
            }
        }
        setFont(language);
        //TP-502
        let downloaded_on =   moment(new Date()).format('DD MMMM YYYY HH:mm:ss');
        let generated_by = customer && customer.allow_company_name_as_report_author ? customer.company_name : 'Telepresenz ™';
        generated_by += (customer && customer.allow_company_name_as_report_author && customer.company_url && customer.company_url !== '' ? ` - ${customer.company_url}` : ''); 
        generated_by += " "+localizedData[globalLanguage]["on"]+" "+downloaded_on; 
        const summaryData = data.length > 0 ? ((data[0].job_status === "created" || data[0].status === "assigned") ? await _summaryStatus(data) : await _summary(data)) : "";//TP-5945
        let company_name = customer.company_name ? customer.company_name : '';
        let creater_name = (data[0].creater_fname || `${localizedData[globalLanguage]["Non"]}`) + ' ' + (data[0].creater_lname || `${localizedData[globalLanguage]["- existent"]}`);
        let job_created_on = data[0] ? moment(data[0].job_created_on).format("DD MMMM YYYY") : '';
        let last_updated_on = data[0] ? moment(data[data.length-1].job_updated_on).format("DD MMMM YYYY") : '';
        

        const location_name = (data[0] && data[0].location_name)//TZ-50
        const introData = await _introduction({jobName: selectedJob.name, company_name, creater_name, job_created_on, last_updated_on, customer, location_name});
        const formattedData = data.length > 0 ? ((data[0].job_status === "created" || data[0].status === "assigned") ? "" : await _format(data)) : "";//TP-5945
        const disclaimerData = disclaimer();

        const documentDefinition = {
                pageSize: 'A4',
                pageMargins: [ 40, 60, 40, 60 ],
                pageOrientation: 'landscape',
                header: function(currentPage, pageCount) {
                    if(currentPage !== 1 && currentPage !== 2){
                        return([
                            { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 800, y2: 40, lineWidth: 0.5 }] },
                            {text: '\n'},
                        ])
                    }else{
                        return null;
                    }
                },
                footer: function(currentPage, pageCount) {
                    if(currentPage !== 1 && currentPage !== 2){
                            return ([
                            { canvas: [{ type: 'line', x1: 40, y1: 10, x2: 800, y2: 10, lineWidth: 0.5 }] },
                            {
                                alignment: 'justify',
                                columns: [
                                    { text: localizedData[globalLanguage]['Proprietary & Confidential'], margin: [40, 5, 0, 0],fontSize: 10 },
                                    { text: localizedData[globalLanguage]['Page']+' | '+ currentPage.toString(), alignment: 'center', margin: [0, 5, 0, 0],fontSize: 10 },
                                    { text: `${company_name}`, alignment: 'right' , margin: [0, 5, 40, 0],fontSize: 10 },
                                ],
                            },

                        ])
                    }else if(currentPage === 1){
                        return {text: localizedData[globalLanguage][`Generated By`]+` ${generated_by}`, style:'tableHeader', margin: [0, 30, 0, 0], alignment: 'center', fontSize: 13};
                    }else{
                        return null;
                    }
                },
                // watermark: { text: 'test watermark', angle: 70, color: 'blue', opacity: 0.3, bold: true, italics: false  },
                content: [
                    ...introData,
                    ...summaryData,
                    ...formattedData,
                    ...disclaimerData
                ],
                defaultStyle: {
                    font: fontStyle
                },
                styles: {
                    header: {
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 14 : 16,
                    bold: true,
                    margin: [0, 0, 0, 0]
                },
                subheader: {
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 14 : 16,
                    bold: true,
                    margin: [0, 10, 0, 5],
                    color:'#2d3858'
                },
                tableExample: {
                    margin: [0, 5, 0, 15]
                },
                tableHeader: {
                    bold: true,
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 11 : 13,
                    color: 'black'
                },
                oddRow: {
                    fillColor: '#384571',
                    color:'#FFFFFF'
                },
                evenRow: {
                    fillColor: '#2D3858',
                    color:'#FFFFFF'
                },
                dataOddRow: {
                    bold: true,
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 11 : 13,
                    fillColor: '#384571',
                    color:'#FFFFFF'
                },
                dataEvenRow: {
                    bold: true,
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 11 : 13,
                    fillColor: '#2D3858',
                    color:'#FFFFFF'
                },
                blueFont: {
                    color:'#2d3858'
                },
                recordedLink: {
                    margin: [0, 10, 0, 5],
                },
                statusSummary: {
                    bold: true,
                    italics: true,
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 18 : 20,
                    color: '#2d3858',
                    margin: [0, 5, 0, 5],
                },
                summaryHeader: {
                    fontSize: ["NotoSansSC", "NotoSans", "NotoSansOriya"].includes(fontStyle) ? 14 : 16,
                    bold: true,
                    margin: [0, 0, 0, 5]
                },
            },            
        };

        return documentDefinition;
    }catch(error){
        console.error(`report not generated ${error.message}`)
        return {}
    }       
}

