import React from 'react';
import './Button.scss';

const Button = (props) => (
    <button style={props.style || {}} className={'__btn ' + props.className || ''} disabled={props.showSpinner || props.disabled} onClick={props.onClick}>
        { props.showSpinner ? <i className="fas fa-spinner fa-spin"></i> : '' }
        { props.children }
    </button>
);

export default Button;