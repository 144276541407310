import React from "react";
import './devices.css'; 
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

let otDeviceObj = null;
let g_deviceStreams = null;
var worker;

function testWorker() {
    if (typeof(Worker) !== "undefined") {
        if (typeof(worker) == "undefined") {
            worker = new Worker("Worker.js");
        }
        worker.onmessage = function(event) {
            //console.log(event.data);
            if (event.data === "Web Worker Camera Auto Detect Start"){
                otDeviceObj.startCameraDetection(1);
            }
        };
    } else {
        //console.log("Web Workers are not supported in your browser");
    }
}
function terminateWorker() { 
    worker.terminate();
    worker = undefined;
}

class OTDevices extends React.Component {
    // Initializing important variables
    constructor(props) {
        super(props);

        this.state = {
            hideCameraDialog: false,
            audioInputDevices: [],
            videoInputDevices: [],
            selectedAudio: "",
            selectedVideo: ""
        }
        otDeviceObj = this;
        g_deviceStreams = null;
    }

    componentDidMount = () => {
       // if (this.getCookie("camera") === "") {
            this.initCameraDetect();
        /* } else {
            this.props.openChangeCameraDialog();
        } */
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.triggerCameraDetect !== prevProps.triggerCameraDetect && this.props.triggerCameraDetect === true){
            this.startCameraDetection(2);
            this.onClickAudioOption(this.state.audioInputDevices[0]);
            this.onClickCameraOption(this.state.videoInputDevices[0]);
        }
        if (this.props.changeCamera !== prevProps.changeCamera){
            if (this.props.changeCamera === false) {
                this.startCameraDetection(3);
            }
            this.setState({ hideCameraDialog: this.props.changeCamera});
        }
    }    

    getDevices = async () => {
        const devices = await navigator.mediaDevices.enumerateDevices();
        //console.log("List of cameras ----");
        return devices;
    }

    initCameraDetect = () => {
        //console.log("Started a session---");
        if (this.getCookie("camera") !== "") {
            //console.log("Hide the dialog box");
            this.hideCameraDialog();
        } else {
            //console.log("Open dialog box!");
            this.openCameraDialog();
        }
        this.startCameraDetection(4);
        testWorker();
    }

    startCameraDetection = (param) => {
        //console.log("starting the Camera detection!");
        if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
            //console.log("Let's get the cameras connected")
            /* var gumStream;
            navigator.mediaDevices.getUserMedia(
                {
                    video: {
                        width: {
                        min: 1280,
                        },
                        height: {
                        min: 720,
                        }
                    }
                },
                function (stream) {
                    console.log("STreamCreated")
                    stream.getTracks().forEach(function(track) {
                        track.stop();
                    });
                },
                function (error) {
                    console.log('getUserMedia() error', error);
                }
            ); */
            
            let promiseD = this.getDevices();
            //console.log(promiseD);
            promiseD.then(function(devices) {
                //console.log(devices);
                let audioInputDevices = devices.filter(e => e.kind == "audioinput");
                let videoInputDevices = devices.filter(e => e.kind == "videoinput");
                if (videoInputDevices.length > 1 && param !== 1) {
                    for (var i = 0; i < audioInputDevices.length; i++) {
                        //console.log("audio input device: ", audioInputDevices[i].deviceId);
                    }
                    for (i = 0; i < videoInputDevices.length; i++) {
                        //console.log("video input device: ", videoInputDevices[i].deviceId);
                    } 
                }
                if (otDeviceObj.state.videoInputDevices.length === videoInputDevices.length && videoInputDevices.length === 1) {
                    otDeviceObj.hideCameraDialog();
                    return;
                }
                otDeviceObj.props.updateCameraDeviceCount(videoInputDevices.length);
                otDeviceObj.setState({ audioInputDevices, videoInputDevices });
                if (videoInputDevices.length === 1) {
                    otDeviceObj.onClickAudioOption(audioInputDevices[0]);
                    otDeviceObj.onClickCameraOption(videoInputDevices[0]);
                    //otDeviceObj.setDeviceCookie("camera", videoInputDevices[0].deviceId);
                    //otDeviceObj.setDeviceCookie("microphone", audioInputDevices[1].deviceId);
                } else if (videoInputDevices.length > 0) {
                    otDeviceObj.props.updateDefaultDevices(audioInputDevices[0].deviceId,videoInputDevices[0].deviceId);
                }
               /*  otDeviceObj.setState({ hideCameraDialog: false });
                otDeviceObj.props.openChangeCameraDialog(); */
            })
        }
    }

    onClickCameraSelector = () => {
        const cameraSelector = document.getElementById('cameraOptions');
        if (cameraSelector.classList.contains('active')) {
            cameraSelector.classList.remove('active');
        } else {
            cameraSelector.classList.add('active');
        }
    }

    setDeviceCookie = (name, val) => {
        // "username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        let cookieText = name+"="+val+"; path=/";
        //console.log(cookieText);
        document.cookie = cookieText;
    }

    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    onClickAudioSelector = () => {
        const audioSelector = document.getElementById('audioOptions');
        if (audioSelector.classList.contains('active')) {
            audioSelector.classList.remove('active');
        } else {
            audioSelector.classList.add('active');
        }
    }

    onClickCameraOption = (val) => {
        //console.log(val.deviceId);
        if (this.getCookie("camera") !== val.deviceId) {
            this.setDeviceCookie("camera", val.deviceId);
            this.setState({ selectedVideo: val.deviceId });            
            this.props.updateCameraSelected(val.deviceId);
        }
        if (document.getElementById('cameraSelected') !== null) {
            document.getElementById('cameraSelected').innerHTML = val.label;
            const cameraSelector = document.getElementById('cameraOptions');
            cameraSelector.classList.remove('active');
            const cindex = this.state.videoInputDevices.findIndex(v => v.deviceId === val.deviceId);
            const aindex = this.state.audioInputDevices.findIndex(v => v.deviceId === this.state.selectedAudio);
            if (aindex !== (cindex + 1) && this.state.audioInputDevices[cindex+1] !== undefined) {
                this.onClickAudioOption(this.state.audioInputDevices[cindex+1]);
            } else if (aindex !== (cindex + 1)) {
                this.onClickAudioOption(this.state.audioInputDevices[0]);
            }           
        }
        this.hideCameraDialog();
    }

    onClickAudioOption = (val) => {
        //console.log(val.deviceId);
        if (this.getCookie("microphone") !== val.deviceId) {
            this.setDeviceCookie("microphone", val.deviceId);
            this.setState({ selectedAudio: val.deviceId });
            this.props.updateMicSelected(val.deviceId);
        }
        if (document.getElementById('audioSelected') !== null) {
            document.getElementById('audioSelected').innerHTML = val.label;
            const audioSelector = document.getElementById('audioOptions');
            audioSelector.classList.remove('active');
        }
    } 

    stopCameraStreams() {
        if (g_deviceStreams !== null && g_deviceStreams !== undefined) {
            g_deviceStreams.getTracks().forEach(function(track) {
                track.stop();
            });
        }
    }

    componentWillUnmount = () => {
        terminateWorker();
        ///clearInterval(this.detectTimer);
        //this.stopCameraStreams();
    }

    hideCameraDialog = () => {
        this.setState({ hideCameraDialog: true });
        this.props.closeChangeCameraDialog();
    }

    openCameraDialog = () => {
        this.setState({ hideCameraDialog: false });
        this.props.openChangeCameraDialog();
    }

    render = () => {
        const { audioInputDevices, videoInputDevices } = this.state;
        const closeBtn = <button className="closeBtnModal" onClick={this.hideCameraDialog}>&times;</button>;
        if (videoInputDevices !== undefined && videoInputDevices.length > 1) {
            return (
                <>
                    <Modal size="md" isOpen={!this.state.hideCameraDialog} toggle={this.hideCameraDialog}>
                        <ModalHeader  toggle={this.hideCameraDialog} cssModule={{ 'modal-title': 'w-100 text-center' }} >Select Device</ModalHeader>
                        <ModalBody>
                            <div className="">
                                {/* <h2 className="heading2">Microphones:</h2>  */}
                                <div className="select-box">                                    
                                    <div id="audioOptions" className="options-container">
                                        {   
                                            audioInputDevices.map(val => {
                                                return (
                                                    <div key={val.deviceId} className="option">
                                                        <input type="radio" className="selectRadio" id={val.label} name="microphone" />
                                                        <label onClick={() => this.onClickAudioOption(val)} className="label" htmlFor={val.label}>{val.label}</label>
                                                    </div>
                                                )
                                            })
                                        }                                        
                                    </div>
                                    <div id="audioSelected" onClick={this.onClickAudioSelector} className="selected">
                                        Select Microphone
                                    </div>
                                </div>
                                
                            </div>
                            
                            <div className="">
                                {/* <h2 className="heading2">Cameras:</h2> */}
                                <div className="select-box">                                    
                                    <div id="cameraOptions" className="options-container">
                                        {   
                                            videoInputDevices.map(val => {
                                                return (
                                                    <div key={val.deviceId} className="option">
                                                        <input type="radio" className="selectRadio" id={val.label} name="microphone" />
                                                        <label onClick={() => this.onClickCameraOption(val)} className="label" htmlFor={val.label}>{val.label}</label>
                                                    </div>
                                                )
                                            })
                                        }                                        
                                    </div>
                                    <div id="cameraSelected" onClick={this.onClickCameraSelector} className="selected">
                                        Select Camera
                                    </div>
                                </div>                                
                            </div>
                            
                        </ModalBody>                        
                    </Modal>
                </>
            );
        }
        else {
            return null;
        }
    }

}

export default OTDevices;