import React from 'react';
import { Form, Row, UncontrolledTooltip, Col } from 'reactstrap';
import Select from 'react-select';
import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import '../Landing/Groups.scss';
import AuthService from '../AuthService';
import Loader from '../Loader/Loader';
import SimpleReactValidator from 'simple-react-validator';
import makeAnimated from 'react-select/lib/animated';
import { printConsole } from '../helpers';

const selectedTypeArr = [ "Normal", "Restricted", "Hazardous"]; //TP-2795

class AssetClassForm extends React.Component{
    constructor(props){
        super(props);
        this.authService = new AuthService();
        this.state = {
            isLoading: false,
            retCode: 400,
            errStrReturned: '',
			bSubmitted: false,
            bResponded: false,
            selectedAssetClass: props.selectedAssetClass ? props.selectedAssetClass : {account_id: props.accountId, author_id: props.userid, author_name: props.name, access: 'private'},
            isAssetClassLoading: false,
            action: '',
            loadingText: "Loading",
            typeOptions: [{label: 'Hazardous', value: 'hazardous'}, {label: 'Restricted', value: 'restricted'}, {label: 'Normal', value: 'normal'}],
            selectedType: {label: 'Normal', value: 'normal'},
        }
		// initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({
            locale: props.language,
            autoForceUpdate: this,
            element: message => <div className="job-form-validation-msg">{message.replace(/field|The|[0-9]/g, '').capitalize()}</div>
		});        
    }

    componentDidMount = () => {
        const {selectedAssetClass} = this.props;
        //TP-2795
        let typeSelected = {};
        if (selectedAssetClass && selectedAssetClass.type) {
            [typeSelected] = this.state.typeOptions.filter((o) => o.value === selectedAssetClass.type);
        }
        this.setState(() => ({
                selectedType: selectedAssetClass && selectedAssetClass.type ? typeSelected : {label: 'Normal', value: 'normal'}
            }
        ), () => {
            printConsole(this.state.selectedType);
        })
    }

    oncloseAssetClassForm = async () => {
        try{
            this.props.changeAssetClassView({showAssetClassForm: false});
        }catch(error){
            console.error(error);
            this.props.changeAssetClassView({showAssetClassForm: false});
        }
    }

    /**TP-2753 */
    processOptionsLanguage = (value, i18n) => {
        switch(value) {
            case "Hazardous":
                return i18n._(t`Hazardous`);
            case "Restricted":
                return i18n._(t`Restricted`);
            case "Normal":
                return i18n._(t`Normal`);
            default:
                return i18n._(t`Normal`);
        }
    }

    //TP-2795 -- Set the default selected type of Asset Class
    getSelectedType = (selectedType, i18n) => {
        return {
            value: selectedType.value,
            label: this.processOptionsLanguage(selectedType.label, i18n)
        }
    }

    /**TP-2753 */
    getTypeOptions = (i18n) => {
        return this.state.typeOptions.map(p => {
            return {
                value: p.value,
                label: this.processOptionsLanguage(p.label, i18n) //TP-2753
            }
        });
    }

    handleTypeChange = (selectedType) => {
        this.setState({ 
            selectedAssetClass: {...this.state.selectedAssetClass, type: selectedType.value},
            selectedType
        });
    }

    onChangeAssetClass = (fieldName) => ({ target }) => {
        const value = target.type === 'checkbox' ? target.checked ? 'private':'public' : target.value.trimStart();//TP-5417
		this.setState({
            selectedAssetClass: {...this.state.selectedAssetClass, [fieldName]: value}
        });
    }

    onAddAssetClass = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
			this.validator.showMessages();
			return;
		}

        const { selectedAssetClass } = this.state;
		this.setState({
            bSubmitted: true,
            errStrReturned: i18nMark('Submitting new asset class details')
        });
        this.authService.fetch('jobs/addAssetClass', {
            method: 'post',
            body: JSON.stringify(selectedAssetClass)
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                this.props.fetchAssetClass();//reload the asset class
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(id => {
			if(id){
				this.setState({
                    bSubmitted: false,
                });
                this.props.changeAssetClassView({showAssetClassForm: false});//MB2-923
			}
        })
        .catch((error) => {
            this.setState({
                bSubmitted: false,
                bResponded: true,
                retCode: error.status,
                errStrReturned: i18nMark('Failed to add the new asset class. Please review the entries and resubmit.')
            });

        });
    }

    onEditAssetClass = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
			return;
		}
        const { selectedAssetClass } = this.state;
		this.setState({
            bSubmitted: true,
            errStrReturned: i18nMark('Submitting asset class details')
        });
        this.authService.fetch('jobs/editAssetClass', {
            method: 'put',
            body: JSON.stringify(selectedAssetClass)
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                this.props.fetchAssetClass();//reload the asset class
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(id => {
			if(id){
				this.setState({
                    bSubmitted: false,
                    bResponded: true,
                    errStrReturned: i18nMark('Asset class successfully updated!')
                });
            }
        })
        .catch((error) => {
            this.setState({
                retCode: error.status,
                errStrReturned: i18nMark('Failed to edit the asset class. Please review the entries and resubmit.')
            });            
        });
    }
    
    render(){
        let {selectedAssetClass, selectedType} = this.state
        let {action, isSmallFormFactor} = this.props;
		if(this.state.bSubmitted || this.state.bResponded){
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
                <>
                    <div className='modale opened'>
                        <div className='__modal-dialog'>
                            <form>
                                <div className="__modal-header">
                                    <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
                                </div>
                                <div className="__modal-footer flex-center">
                                    <button className="btn-green" onClick={() =>this.props.changeAssetClassView({showAssetClassForm: false})}><Trans id='OK'/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
			);
		} else {
            //TP-3259 -- Embedded Form UI
			return(
                <>
                    <div className='formmodule center'>
					    <div className='dialog form-color width-min'>
                            <div className="formheader flex-center row">
                                <h2>
                                    <Trans id={(action === 'edit' && selectedAssetClass) ? 'Edit Asset Class': 'Add Asset Class'}></Trans>
                                </h2>
                            </div>
                            <div className="formbody form-text-color ">
                                <Form>
                                    <div className="jobs-form-body pb-0">
                                        <Row className="margin-top-15 mb-1">
                                            <Col id="class_name" className="input-wrapper">
                                                <I18n>
                                                    {({ i18n }) =>
                                                        <>
                                                            <input className='signup-input-field' name={i18n._(t`name`)} type="text" placeholder={i18n._(t` Enter the asset class name`)} value={selectedAssetClass.name} onChange={this.onChangeAssetClass('name')} autoComplete="off"/>
                                                            { this.validator.message(i18n._(t`name`), selectedAssetClass.name, 'required') }
                                                        </>									
                                                    }
                                                </I18n>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="class_name">
                                                        <Trans>Asset Class Name</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    ''
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3 mb-1">
                                            <Col id="desc" className="input-wrapper">
                                                <I18n>
                                                    {({ i18n }) =>
                                                        <input className='signup-input-field' name={i18n._(t`description`)} type="text" placeholder={i18n._(t` Enter the asset class Description`)} value={selectedAssetClass.description} onChange={this.onChangeAssetClass('description')} autoComplete="off"/>
                                                    }
                                                </I18n>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="desc">
                                                        <Trans>Description</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    ''
                                                }
                                            </Col>
                                        </Row>
                                        <I18n>
                                            {({ i18n }) =>
                                                <> 
                                                    <Row className="mt-3 mb-3">
                                                        <Col id="manufacturer" className="input-wrapper">
                                                            <input className='signup-input-field' type="text" value={selectedAssetClass.manufacturer} name={i18n._(t`manufacturer`)} placeholder={i18n._(t` Enter the asset class Manufacturer`)} onChange={this.onChangeAssetClass('manufacturer')} autoComplete="off"/>
                                                            { isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="manufacturer">
                                                                    <Trans>Manufacturer Name</Trans>
                                                                </UncontrolledTooltip>       
                                                                :
                                                                ''
                                                            }
                                                        </Col>
                                                        <Col id="model" className="input-wrapper">
                                                            <input className='signup-input-field' type="text" value={selectedAssetClass.model} name={i18n._(t`model`)} placeholder={i18n._(t` Enter the asset class Model`)} onChange={this.onChangeAssetClass('model')} autoComplete="off"/>
                                                            { isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="model">
                                                                    <Trans>Model Name</Trans>
                                                                </UncontrolledTooltip>       
                                                                :
                                                                ''
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </I18n>
                                        <I18n>
                                            {({ i18n }) =>
                                                <> 
                                                    <Row className="mt-3 mb-1">
                                                        <Col id="asset_type" className="input-wrapper">
                                                            <Select
                                                                value={ selectedTypeArr.indexOf(selectedType.label) !== -1  ? this.getSelectedType(selectedType, i18n): selectedType} /**TP-2795 */
                                                                onChange={this.handleTypeChange}
                                                                placeholder={i18n._(t` Select A Type`)}
                                                                components={makeAnimated()}
                                                                options={this.getTypeOptions(i18n)} /**TP-2753 */
                                                                isSearchable={isSmallFormFactor === false ? true : false}
                                                                className="text-black"
                                                            />                                                        
                                                            <input type="text" readOnly value={selectedAssetClass.type} name={i18n._(t`type`)} className="hidden"/>
                                                            { isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="asset_type">
                                                                    <Trans>Asset Type</Trans>
                                                                </UncontrolledTooltip>       
                                                                :
                                                                ''
                                                            }
                                                        </Col>
                                                        <Col className="input-wrapper">
                                                            {/* <label htmlFor="access" className="mr-3"></label><br/> */}
                                                            <div className="float-left" id="job-wrapper">
                                                                <ShowAccessSlider {...selectedAssetClass} onChangeAssetClass={this.onChangeAssetClass} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </I18n>
                                        <Row className="mt-3 mb-1">
                                            <Col id="comment" className="input-wrapper">
                                                <I18n>
                                                    {({ i18n }) =>
                                                        <input className='signup-input-field' name={i18n._(t`comments`)} type="text" placeholder={i18n._(t` Enter the asset class Comments`)} value={selectedAssetClass.comments} onChange={this.onChangeAssetClass('comments')} autoComplete="off"/>
                                                    }
                                                </I18n>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="comment">
                                                        <Trans>Comments</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    ''
                                                }
                                            </Col>
                                        </Row>                                    
                                    </div>			
                                    <div className="mt-2">
                                        {(action === 'edit' && selectedAssetClass) ?
                                        <Row>
                                            <Col className="d-flex justify-content-end">
                                                <button className="btn-red" onClick={(e) =>this.oncloseAssetClassForm(e)}><Trans>Close</Trans></button>
                                            </Col>
                                            <Col className="d-flex justify-content-left">
                                                <button className="btn-green" onClick={(e) =>this.onEditAssetClass(e)}><Trans>Update</Trans></button>
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className="col-md-6 d-flex justify-content-end">
                                                <button className="btn-red" onClick={(e) =>this.oncloseAssetClassForm(e)}><Trans>Close</Trans></button>
                                            </Col>
                                            <Col className="col-md-6 d-flex justify-content-left">
                                                <button className="btn-green" onClick={(e) =>this.onAddAssetClass(e)}><Trans>Create</Trans></button>
                                            </Col>
                                        </Row>
                                        }
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    {/**TP-2753 */}
                    <I18n>
                        {({ i18n }) =>
                            <Loader text={i18n._(t`${this.state.loadingText}`)} isLoading={this.state.isAssetClassLoading} />
                        }
                    </I18n>
                </>
            );
		}
    }
}

export default AssetClassForm;

const ShowAccessSlider = (props) => {
    return <div>
        <I18n>
        {({ i18n }) =>                                       
            <div className="pd-1">
                <label className="col-xs-3"><Trans>Public Access</Trans> {processLanguage(props.access, i18n)}&nbsp;</label>
                <label className="col-xs-4 col-xs-offset-2 switch">
                    <input type="checkbox" 
                        id="access" 
                        name="access" 
                        value={props.access && props.access === 'private'} 
                        checked={props.access && props.access === 'private'} 
                        // onChange={props.onChangeAssetClass('access')}
                        disabled={true}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
        }
        </I18n>
  </div>
}

const processLanguage = (access, i18n) => {
    if(access === "private") {
        return i18n._(t`Off`); 
    }else{
        return i18n._(t`On`)
    }
}