/**TP-3423 */
import React, {  Component  } from "react";
import './BubbleElement.scss';
import { Trans } from '@lingui/macro'; //TP-3657
import OnlineIndicator from '../OnlineIndicator/OnlineIndicator';
import { Row, Col} from 'reactstrap';

class BubbleElement extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }
    componentDidMount = () => {
        document.addEventListener('mousedown', this.handleClick, false);
    }
    
    componentWillUnmount = () => {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    closeAllBubbles = () => {
        const allBubbleEle = document.querySelectorAll('[id^="bubble-"]');
        if (allBubbleEle && allBubbleEle.length > 0) {
          for(let ii=0; ii<allBubbleEle.length; ii++) {
              allBubbleEle[ii].classList.add("d-none");
          }
        }
    }

    handleClick = (e) => {
        //if (e.target.type && (e.target.type === "text" || e.target.type === "range" || e.target.type === "number" || e.target.type === "password" || e.target.type === "checkbox")) return;
        //e.preventDefault(); //commented out to prevent focus issues on different input elements on other js files
        //console.log(e.target);
        //console.log(e.target.parentNode);
        //console.log(e.target.parentElement)
        if((e.target.id && (e.target.id.includes("bubble-") === true 
            || e.target.id.includes("bble-span-") || e.target.id.includes("bble-row-") 
            || e.target.id.includes("bble-col-left-") || e.target.id.includes("bble-col-right-")
            || e.target.id.includes("bble-col-row1-") || e.target.id.includes("bble-col-row2-") 
            || e.target.id.includes("bble-col-row3-"))) || e.target.parentNode.id.includes("bble-col-row2-") /**TP-3657 */
            || e.target.parentNode.id.includes("bble-col-row1-") || e.target.parentNode.id.includes("bble-col-left-") ) {
            //console.log("clicked inside the bubble....")
        } else if ((e.target.id && e.target.id.includes("logo-pic-") === true)
            || e.target.parentNode.id.includes("logo-pic-")) {
            //console.log("clicked any of the Profile pics ", e.target.id);
        } else if (!(e.target.type && (e.target.type === "text" || e.target.type === "range" || e.target.type === "number" || e.target.type === "password" || e.target.type === "checkbox"))) {
            this.closeAllBubbles();
        }
    }

    //TP-3657 -- to get the html for the User Role in the Bubble element
    getUserType = (row) => {
        let rowType, values;
        const {customerRoles} = this.props;
        //console.log(row);
        //TP-5931
        let classString = '';
        if (row.is_inactive) {
            classString = 'Inactive';
            rowType='Inactive'; values={};
        } else if(row.isclicked && row.isclicked === false) {
            classString = 'Invitee';
            rowType = 'Invitee'; values={};
        } else if (!row.isadmin && !row.isexpert) {
            classString = 'Technician';
            if (customerRoles.technician.toLowerCase() === "inspector" || customerRoles.technician.toLowerCase() === "observer" || customerRoles.technician.toLowerCase() === "technician") {
                rowType = customerRoles.technician; values={};
            }
            else {
                rowType = 'Technician Default'; values={role: customerRoles.technician};
            }
        } else if (row.isadmin && row.isexpert) {
            classString = 'admin-expert';
            if (customerRoles.admin.toLowerCase() === "admin" && customerRoles.expert.toLowerCase() === "expert") {
                rowType = 'Admin/Expert'; values={};
            } else if (customerRoles.admin.toLowerCase() === "admin" && customerRoles.expert.toLowerCase() === "supervisor") {
                rowType = 'Admin/Supervisor'; values={};
            } else {
                rowType = 'Admin/Expert Default'; values={role1: customerRoles.admin, role2: customerRoles.expert};
            }
        } else if (row.isexpert) {
            classString = 'Expert';
            if (customerRoles.expert.toLowerCase() === "expert" || customerRoles.expert.toLowerCase() === "supervisor") {
                rowType = customerRoles.expert; values={};
            } else {
                rowType = 'Expert Default'; values={role: customerRoles.expert};
            }
        } else if (row.isadmin) {
            classString = 'Admin';
            if (customerRoles.admin.toLowerCase() === "admin"){
                rowType = customerRoles.admin; values={};
            } else {
                rowType = 'Admin Default'; values={role: customerRoles.admin};
            }
        }
        return <span className={'bubble-user-type-flag '+ classString}>
            <Trans id={rowType} values={values} />
        </span>;
    }

    //TP-3875 -- Handled the shortening of the user name if it exceeds a max no.of characters
    editName = (value) => {
        if(value.length > 15) {
            return value.slice(0, 15) + "...";
        } else 
            return value;
    }

    render() {
        const props = this.props;
        const {row} = props;
        //TP-3875
        let showedName = row.fullName ? this.editName(row.fullName) : this.editName(row.first_name+" "+row.last_name);
        return (
            <div id={"bubble-" + props.index} className={"dot "+props.className}>
                <span id={"bble-span-" + props.index}>
                <Row id={"bble-row-" + props.index} className="row-div">
					<Col id={"bble-col-left-" + props.index} sm="6" /**TP-3484 */>
                        { /**TP-3533*/ row.myPicture !== undefined && row.myPicture !== null && row['myPicture'] !== "" && row['myPicture'].includes("undefined") === false ?
                            <img className="session-profile-pic" alt="profile_picture"  width="150" height="150" src={row.myPicture}></img>
                            :
                            <img className="session-profile-pic" alt="profile_picture"  width="150" height="150" src={"/profile.png"}></img>
                        }
					</Col>
					<Col id={"bble-col-right-" + props.index} sm="6" /**TP-3484 */ className="position-relative" /**TP-3657 */>
						<Row id={"bble-col-row1-" + props.index}>
                            { /**TP-3875*/ row.showOnlineIndicator ? 
                                <>
                                    <div className="col-sm-1">
                                        <OnlineIndicator isSmallFormFactor={true} index={props.idx} circleTop="8px" ringTop="4px" />
                                    </div>
                                    <div className="col-xs-1"></div>
                                    <div className="col-sm-10">
                                        { showedName ? 
                                            showedName 
                                            :
                                            ''
                                        }
                                    </div>
                                </>
                                :
                                showedName ? 
                                    showedName
                                    :
                                    ''                               
                            }
							
						</Row>
                        {/**TP-3657 */}
                        <Row id={"bble-col-row2-" + props.index} className="position-absolute" style={{marginTop: "10px", marginBottom: "10px"}}>
                            { row ?
                                this.getUserType(row)
                                :
                                ''
                            }
						</Row>
						<Row id={"bble-col-row3-" + props.index}>
							{row.email}
						</Row>
					</Col>
				</Row> 
                </span>
        
            </div>
        )
    }
}

export default BubbleElement;