import React from 'react';
import { Trans } from '@lingui/macro';
import {i18nMark } from "@lingui/react";
import '../Landing/Groups.scss';
import { printConsole } from '../helpers';
import Button from '../Button/Button';
import AuthService from '../AuthService';
const fileDialog = require('file-dialog');
const Papa = require('papaparse');

class WorkFlowTemplate extends React.Component{
    constructor(props){
        super(props);
        this.authService = new AuthService();
        this.state = {
            mode: 'upload_workflow_template',
            isLoading: false,
            retCode: 400,
            errStrReturned: '',
			bSubmitted: false,
            bResponded: false,
            subTaskActions: [],
            isJobLoading: true,
            flashMsgText: "",
            showFlashMessage: false,
            flashLeft: "",
            flashTop: "",
            jobs: {},
            subTasks: {}
        }
    }

    componentDidMount(){
        this.fetchAllSubTaskActions();
    }

    fetchAllSubTaskActions = () => {
        let fetchString = 'jobs/getSubTaskActions/';
        this.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                const subTaskActions = data.filter(action => action.command !== "Ctrl+A");//MB2-892
                this.setState({
                   subTaskActions: subTaskActions,
                });
            }
        })
        .catch(error => {
            this.setState({
                isJobLoading: false,
                bResponded: true,
                retCode: error.status,
                flashLeft: "45%", 
                flashTop: "",
                flashMsgText: "Failed to process request",
                showFlashMessage: true,
                //errStrReturned: i18nMark('Failed to process request.')
            });
        })
    };

    onDownloadJobCsvTemplate = (e) => {
        e.preventDefault();
        const save_link = document.createElementNS(
            "http://www.w3.org/1999/xhtml",
            "a"
        );
        save_link.href = '/workflowCsvTemplate.csv';
        save_link.download = 'workflowCsvTemplate.csv';
        document.body.append(save_link);
        save_link.click();
        document.body.removeChild(save_link)
    }

    onUploadJobs = (e) => {
        e.preventDefault();
        fileDialog({ multiple: false, accept: '.csv' })
        .then(([file]) => {
            this.setState({ isLoading: true });
            let numberOfLines = 0,  noOfRows = 1, isEmptyData, isCsvValid= true, skip = false, row_data= {},
            jobIdBlankRows= [], jobNameBlankRows= [], jobDescriptionBlankRows= [], jobStartDateBlankRows= [], jobEndDateBlankRows= [],
            taskInstructionBlankRows= [], taskResultBlankRows= [], taskEvidenceBlankRows= [], taskStartDateBlankRows= [], taskEndDateBlankRows= [],
            isJobIdBlank, isJobNameBlank, isDescriptionBlank, isjobStartDateBlank, isjobEndDateBlank, isTaskInstructionBlank, isTaskResultBlank, isTaskEvidenceBlank,
            isTaskStartDateBlank, isTaskEndDateBlank ;//TP-2356
            Papa.parse(file, {
                header : true,
                skipEmptyLines: true,
                escapeFormulae: true,
                step: (row, parser) => {
                    try{
                        parser.pause(); // pause the parser
                        noOfRows +=1;
                        [isEmptyData, skip, row_data, isJobIdBlank, isJobNameBlank, isDescriptionBlank, isjobStartDateBlank, isjobEndDateBlank, isTaskInstructionBlank,
                        isTaskResultBlank, isTaskEvidenceBlank, isTaskStartDateBlank, isTaskEndDateBlank] = this.findInvalidJobField({row: row.data[0]});
                        //push the row number to be shown later
                        if(isJobIdBlank) jobIdBlankRows.push(noOfRows); 
                        if(isJobNameBlank) jobNameBlankRows.push(noOfRows);
                        if(isDescriptionBlank) jobDescriptionBlankRows.push(noOfRows);
                        if(isjobStartDateBlank) jobStartDateBlankRows.push(noOfRows);
                        if(isjobEndDateBlank) jobEndDateBlankRows.push(noOfRows);

                        if(isTaskInstructionBlank) taskInstructionBlankRows.push(noOfRows); if(isTaskResultBlank) taskResultBlankRows.push(noOfRows);
                        if(isTaskEvidenceBlank) taskEvidenceBlankRows.push(noOfRows); if(isTaskStartDateBlank) taskStartDateBlankRows.push(noOfRows);
                        if(isTaskEndDateBlank) taskEndDateBlankRows.push(noOfRows);
                        // Now check object keys value, if it exists
                        if(!isEmptyData){
                            // check whether the row is empty
                            if(!skip){
                                numberOfLines += 1; this.mapJobAndTask({row_data});
                            }
                            parser.resume();
                        }else{
                            //some key or column data is missing, continue parsing to scan the csv in one go and collectively show message
                            isCsvValid = false;
                            parser.resume();
                        }
                    }catch(error){
                        printConsole('error while parsing csv', error);
                    }
                },
                complete: (results) => {
                    /** check whether if there are any errors in the result object, of found ignore the upload */
                    try{
                        if(results.errors.length > 0 || !isCsvValid){
                            let errorMsg = '', isNoColumnBlank = (jobIdBlankRows.length <= 0) && (jobNameBlankRows.length <= 0) && (jobDescriptionBlankRows.length <= 0) && (jobStartDateBlankRows.length <= 0)
                            && (jobEndDateBlankRows.length <= 0) && (taskInstructionBlankRows.length <= 0) && (taskResultBlankRows.length <= 0) && (taskEvidenceBlankRows.length <= 0)
                            && (taskStartDateBlankRows.length <= 0) && (taskEndDateBlankRows.length <= 0),

                            uniqueJobIdBlankRowMsg = '', jobNameBlankRowMsg = '', jobDescriptionBlankRowMsg = '', jobStartDateBlankRowMsg = '', jobEndDateBlankRowMsg = '',
                            taskInstructionBlankRowMsg = '', taskResultBlankRowMsg = '', taskEvidenceBlankRowMsg = '', taskStartDateBlankRowMsg = '', taskEndDateBlankRowMsg = '';

                            if(jobIdBlankRows.length > 0) errorMsg += `UniqueJobId is blank,`; uniqueJobIdBlankRowMsg = jobIdBlankRows.join(', ');;
                            
                            if(jobNameBlankRows.length > 0) errorMsg += `JobName is blank,`; jobNameBlankRowMsg = jobNameBlankRows.join(', ');;
                            
                            if(jobDescriptionBlankRows.length > 0) errorMsg += `JobDescription is blank,`; jobDescriptionBlankRowMsg = jobDescriptionBlankRows.join(', ');;
                            
                            if(jobStartDateBlankRows.length > 0) errorMsg += `JobStartDate is blank,`; jobStartDateBlankRowMsg = jobStartDateBlankRows.join(', ');;
                            
                            if(jobEndDateBlankRows.length > 0) errorMsg += `JobEndDate is blank,`; jobEndDateBlankRowMsg = jobEndDateBlankRows.join(', ');;
                            
                            if(taskInstructionBlankRows.length > 0) errorMsg += `ProcedureInstruction is blank,`; taskInstructionBlankRowMsg = taskInstructionBlankRows.join(', ');;
                            
                            if(taskResultBlankRows.length > 0) errorMsg += `ProcedureExpectedResult is blank,`; taskResultBlankRowMsg = taskResultBlankRows.join(', ');;
                            
                            // if(taskEvidenceBlankRows.length > 0) errorMsg += `EvidenceType is blank,`; taskEvidenceBlankRowMsg = taskEvidenceBlankRows.join(', ');;

                            if(taskStartDateBlankRows.length > 0) errorMsg += `ProcedureStartDate is blank,`; taskStartDateBlankRowMsg = taskStartDateBlankRows.join(', ');;

                            if(taskEndDateBlankRows.length > 0) errorMsg += `ProcedureEndDate is blank,`; taskEndDateBlankRowMsg = taskEndDateBlankRows.join(', ');;

                            let allBlankRows = jobIdBlankRows + jobNameBlankRows + jobDescriptionBlankRows + jobStartDateBlankRows + jobEndDateBlankRows + taskInstructionBlankRows + taskResultBlankRows +
                                                taskStartDateBlankRows + taskEndDateBlankRows;
                            let allBlankRowMsg = allBlankRows? allBlankRows.split('').join(', '): '';
                            this.setState({
                                isLoading: false, mode: 'message',
                                retCode: 400, uniqueJobIdBlankRowMsg, jobNameBlankRowMsg, jobDescriptionBlankRowMsg, jobStartDateBlankRowMsg, jobEndDateBlankRowMsg,
                                taskInstructionBlankRowMsg, taskResultBlankRowMsg, taskEvidenceBlankRowMsg, taskStartDateBlankRowMsg, taskEndDateBlankRowMsg, allBlankRowMsg,
                                errStrReturned: isNoColumnBlank ? i18nMark('Format is incorrect. Please verify against downloaded template and try again')
                                : i18nMark(`invalid job csv`), //i18nMark(`${errorMsg}`)
                                // errStrReturned: i18nMark('Format is incorrect. Please verify against downloaded template and try again'),
                                jobs: {}, subTasks: {}
                            });
                            return;//if error or csv file is invalid
                        }
                        this.uploadJobs();
                    }catch(error){
                        printConsole(`error after parsing of csv, ${error}`);
                    }
                },
                error: (e) => {
                    this.setState({
                        isLoading: false,
                        mode: 'message',
                        retCode: 400,
                        errStrReturned: i18nMark('Format is incorrect. Please verify against downloaded template and try again')
                    });
                }
            });
        })
        .catch(e => {
            this.setState({ isLoading: false });
            printConsole(e)
        })
    }

    findInvalidJobField = ({row}) => {
        let isEmptyData = false, skip = false, row_data = {}, isJobIdBlank, isJobNameBlank, isDescriptionBlank, isjobStartDateBlank, isjobEndDateBlank,
        isTaskInstructionBlank, isTaskResultBlank, isTaskEvidenceBlank, isTaskStartDateBlank, isTaskEndDateBlank;
        try{
            // we have to also find rows where multiple coulmns are blank so if condition and not if else condition
            if((row['UniqueJobId (Mandatory)'] === undefined || row['UniqueJobId (Mandatory)'].trim() === '') && (row['JobName (Mandatory)'] === undefined || row['JobName (Mandatory)'].trim() === '' ) &&
            (row['JobDescription (Mandatory)'] === undefined || row['JobDescription (Mandatory)'].trim() === '' ) && (row['JobStartDate(YYYY-MM-DD) (Mandatory)'] === undefined || row['JobStartDate(YYYY-MM-DD) (Mandatory)'].trim() === '' ) &&
            (row['JobEndDate(YYYY-MM-DD) (Mandatory)'] === undefined || row['JobEndDate(YYYY-MM-DD) (Mandatory)'].trim() === '' ) && (row['ProcedureInstruction (Mandatory)'] === undefined || row['ProcedureInstruction (Mandatory)'].trim() === '' ) &&
            (row['ProcedureExpectedResult (Mandatory)'] === undefined || row['ProcedureExpectedResult (Mandatory)'].trim() === '' ) && (row['EvidenceType (Optional)'] === undefined || row['EvidenceType (Optional)'].trim() === '' ) &&
            (row['ProcedureStartDate(YYYY-MM-DD) (Mandatory)'] === undefined || row['ProcedureStartDate(YYYY-MM-DD) (Mandatory)'].trim() === '' ) && (row['ProcedureEndDate(YYYY-MM-DD) (Mandatory)'] === undefined || row['ProcedureEndDate(YYYY-MM-DD) (Mandatory)'].trim() === '' )){
                    skip = true;// all the fields are blank
            }
            if((row['UniqueJobId (Mandatory)'] === undefined || row['UniqueJobId (Mandatory)'].trim() === '') && !skip){
                isEmptyData = true; isJobIdBlank= true;//only job id is blank
            }
            if((row['JobName (Mandatory)'] === undefined || row['JobName (Mandatory)'].trim() === '')  && !skip){
                isEmptyData = true; isJobNameBlank= true;//only job name is blank
            }
            if((row['JobDescription (Mandatory)'] === undefined || row['JobDescription (Mandatory)'].trim() === '')  && !skip){
                isEmptyData = true; isDescriptionBlank= true;// only description is blank
            }
            if((row['JobStartDate(YYYY-MM-DD) (Mandatory)'] === undefined || row['JobStartDate(YYYY-MM-DD) (Mandatory)'].trim() === '' )  && !skip){
                isEmptyData = true; isjobStartDateBlank= true;// only is start date is blank
            }
            if((row['JobEndDate(YYYY-MM-DD) (Mandatory)'] === undefined || row['JobEndDate(YYYY-MM-DD) (Mandatory)'].trim() === '' )  && !skip){
                isEmptyData = true; isjobEndDateBlank= true;// only is end date is blank
            }
            if((row['ProcedureInstruction (Mandatory)'] === undefined || row['ProcedureInstruction (Mandatory)'].trim() === '' )  && !skip){
                isEmptyData = true; isTaskInstructionBlank= true;
            }
            if((row['ProcedureExpectedResult (Mandatory)'] === undefined || row['ProcedureExpectedResult (Mandatory)'].trim() === '' )  && !skip){
                isEmptyData = true; isTaskResultBlank= true;
            }
            if((row['ProcedureStartDate(YYYY-MM-DD) (Mandatory)'] === undefined || row['ProcedureStartDate(YYYY-MM-DD) (Mandatory)'].trim() === '' )  && !skip){
                isEmptyData = true; isTaskStartDateBlank= true;
            }
            if((row['ProcedureEndDate(YYYY-MM-DD) (Mandatory)'] === undefined || row['ProcedureEndDate(YYYY-MM-DD) (Mandatory)'].trim() === '' )  && !skip){
                isEmptyData = true; isTaskEndDateBlank= true;
            }
            // set the data row here. If the pattern changes backend code need not change
            row_data['reference_id']= row['UniqueJobId (Mandatory)']; row_data['name']= row['JobName (Mandatory)']; row_data['description']= row['JobDescription (Mandatory)']; 
            row_data['start_date']= row['JobStartDate(YYYY-MM-DD) (Mandatory)']; row_data['end_date']= row['JobEndDate(YYYY-MM-DD) (Mandatory)'];
            row_data['task_instruction']= row['ProcedureInstruction (Mandatory)']; row_data['expected_result']= row['ProcedureExpectedResult (Mandatory)']; row_data['action_id'] = this.getSubTaskActionId({actionName: row['EvidenceType (Optional)']});
            row_data['task_startdate']= row['ProcedureStartDate(YYYY-MM-DD) (Mandatory)']; row_data['task_enddate']= row['ProcedureEndDate(YYYY-MM-DD) (Mandatory)'];
    
            return [isEmptyData, skip, row_data, isJobIdBlank, isJobNameBlank, isDescriptionBlank, isjobStartDateBlank, isjobEndDateBlank,
                isTaskInstructionBlank, isTaskResultBlank, isTaskEvidenceBlank, isTaskStartDateBlank, isTaskEndDateBlank];
        }catch(error){
            printConsole(`the parsing error is ${error}`)
            return [false, skip, row_data, isJobIdBlank, isJobNameBlank, isDescriptionBlank, isjobStartDateBlank, isjobEndDateBlank,
                isTaskInstructionBlank, isTaskResultBlank, isTaskEvidenceBlank, isTaskStartDateBlank, isTaskEndDateBlank];
        }
    }

    getSubTaskActionId = ({actionName}) =>{
        try{
            let action;
            if(actionName){
                action = this.state.subTaskActions.find(s => s.name === actionName);
            }
            if (action){
                return action.id;
            }else{
                return action ;
            }
        }catch(error){
            printConsole(`getSubTaskActionId error is ${error}`)
        }
    }

    mapJobAndTask = ({row_data}) => {
        try{
            let {jobs, subTasks, previous_reference_id, sequence} = this.state;
            let reference_id = row_data['reference_id'];
            //calculate sequence id
            if(previous_reference_id === reference_id){
                sequence += 1;
            }else{
                sequence = 1;
            }

            if(!jobs.hasOwnProperty(reference_id)){
                jobs[reference_id] = {reference_id: row_data['reference_id'], name: row_data['name'], description: row_data['description'],
                start_date: row_data['start_date'], end_date: row_data['end_date']}
            }
    
            if(subTasks[reference_id]){
                subTasks[reference_id].push({task_instruction: row_data['task_instruction'], expected_result: row_data['expected_result'], action_id: row_data['action_id'],
                task_startdate: row_data['task_startdate'], task_enddate: row_data['task_enddate'], sequence})
            }else{
                subTasks[reference_id] = [{task_instruction: row_data['task_instruction'], expected_result: row_data['expected_result'], action_id: row_data['action_id'],
                task_startdate: row_data['task_startdate'], task_enddate: row_data['task_enddate'], sequence}]
            }
    
            this.setState({jobs, subTasks, previous_reference_id: reference_id, sequence});
        }catch(error){
            printConsole(`mapJobAndTask error is ${error}`)
        }
    }

    //TP-2329
    uploadJobs = () => {
        try{
            const {jobs, subTasks} = this.state;
            let formattedJobs = [];
            for (const key in jobs) {
                if (jobs.hasOwnProperty(key)) {
                    formattedJobs.push({...jobs[key], subTasks: subTasks[key]})
                }
            }
            this.authService.fetch('jobs/addDraftJobs', {
                method: "POST",
                body: JSON.stringify({
                    jobs: formattedJobs,
                    accountId: this.props.accountId
                })
            })
            .then(response => {
                this.setState({retCode: response.status});
                if (response.status === 200) {
                    return response.json()
                }
                throw response;
            })
            .then(() => {
                this.setState(() => ({
                    isLoading: false,
                    mode: 'message',
                    errStrReturned: i18nMark('Jobs Uploaded')
                }));
            })
            .catch(error => {
                printConsole(`error in uploadJobs ${error}`)
                this.setState({
                    isLoading: false,
                    mode: 'message',
                    errStrReturned: i18nMark('Jobs upload failed.')
                });
            })
        }catch(error){
            printConsole(`error in uploadJobs ${error}`)
        }
    }

    renderSwitch({mode}) {

        switch(mode) {
            case 'message':
                let colorText = '#485890';
                if(this.state.retCode !== 200) colorText = 'red';
                return <div className='modale opened'>
					<div className='__modal-dialog'>
						<form>
							<div className="__modal-header">
                                <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned} 
                                    values={{uniqueJobIdBlankRowMsg: this.state.uniqueJobIdBlankRowMsg,
                                        jobNameBlankRowMsg: this.state.jobNameBlankRowMsg,
                                        jobDescriptionBlankRowMsg: this.state.jobDescriptionBlankRowMsg,
                                        jobStartDateBlankRowMsg: this.state.jobStartDateBlankRowMsg,
                                        jobEndDateBlankRowMsg: this.state.jobEndDateBlankRowMsg,
                                        taskInstructionBlankRowMsg: this.state.taskInstructionBlankRowMsg,
                                        taskResultBlankRowMsg: this.state.taskResultBlankRowMsg,
                                        taskStartDateBlankRowMsg: this.state.taskStartDateBlankRowMsg,
                                        taskEndDateBlankRowMsg: this.state.taskEndDateBlankRowMsg,
                                        allBlankRowMsg: this.state.allBlankRowMsg
                                    }}/>
                                </h4>
							</div>
							<div className="__modal-footer flex-center">
								<button className="btn-green" onClick={(e) => {this.props.closeUploadJobDialog(e);
                                    this.props.fetchJobs(e)}}><Trans id='OK'/></button>
							</div>
						</form>
					</div>
                </div>
            case 'upload_workflow_template':
                return <div className='modale opened'>
                    <div className='__modal-dialog'>
                        <form>
                            <div className="__modal-header flex-center">
                                <h4><Trans>Upload</Trans> <Trans>Evidence</Trans></h4>
                                <span className="btn-close"  onClick={() =>this.props.closeUploadJobDialog()}>&times;</span>
                            </div>
                            <div className="__modal-body">
                                <Button className="align-center margin-b-10" onClick={this.onDownloadJobCsvTemplate}><Trans>Download</Trans> <Trans>evidence</Trans> <Trans>csv</Trans></Button>
                                <Button className="align-center margin-b-10" showSpinner={this.state.isLoading} onClick={this.onUploadJobs}><Trans>Upload</Trans> <Trans>evidence</Trans> <Trans>(Csv only)</Trans></Button>
                            </div>
                        </form>
                    </div>
                </div>
            default:
                return '';
        }
    }

    render(){
        const {mode} = this.state
        return (
            this.renderSwitch({mode})
        )
    }
}

export default WorkFlowTemplate;