import React from "react";
import { Component } from "react";
import { Trans } from '@lingui/macro';
import { printConsole } from '../helpers';
import { UncontrolledTooltip } from 'reactstrap';

class GridActions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gZoomClick: false,
            gTorchClick: props.torchActive,
            gMuteMikeClick: props.disableMike,
            gMuteSpeakerClick: props.disableAudio,
        };    
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.participant.torchActive !== this.props.participant.torchActive) {
            this.handleTorchClick();
        }
        if(prevProps.participant.zoomLevel !== this.props.participant.zoomLevel) {
            this.handleZoomClick();
        }
        if(prevProps.participant.disableMike !== this.props.participant.disableMike) {
            this.handleMikeClick();
        }
        if(prevProps.participant.disableAudio !== this.props.participant.disableAudio) {
            this.handleSpeakerClick();
        }
    }

    handleTorchClick = () => {
        this.setState({ gTorchClick: this.props.participant.torchActive });
    }

    handleZoomClick = () => {
        this.setState({ gZoomClick: (this.props.participant.zoomLevel > 1) ? true : false });
    }

    handleMikeClick = () => {
        this.setState({ gMuteMikeClick: this.props.participant.disableMike });
    }

    handleSpeakerClick = () => {
        this.setState({ gMuteSpeakerClick: this.props.participant.disableAudio });
    }

    onClickToggleTorch = ({userId}) => {
        
            this.props.onClickToggleTorch({userId});
    }

    onClickRotateZoom = ({userId}) => {
        this.props.onClickRotateZoom({userId});
    }

    onClickMuteMikeofOne = ({userId}) => {
        this.props.onClickMuteMikeofOne({userId});
    }

    onClickMuteSpeakerofOne = ({userId}) => {
        this.props.onClickMuteSpeakerofOne({userId});
    }

    onClickTurnOffVideoofOne = ({userId}) => {
        this.props.onClickTurnOffVideoofOne({userId});
    }

    render() {
        const { participant, isPrimary, index, canTurnOffUserVideo, isSmallFormFactorDevice } = this.props;
        let torchStyle = { color: 'black'};
        if (this.state.gTorchClick) torchStyle = { color: 'red' };
        let mikeStyle = { color: 'black'};
        if (this.state.gMuteMikeClick) mikeStyle = { color: 'red' };
        let speakerStyle = { color: 'black'};
        if (this.state.gMuteSpeakerClick) speakerStyle = { color: 'red' };

        //TP-3937 -- Alignment of the Grid controls div
        let leftMargin = 200;
        if (document.getElementById("gridAction_"+participant.userId) && document.getElementById("gridAction_"+participant.userId) !== null) {
            leftMargin = Math.floor((this.props.totalWidth - document.getElementById("gridAction_"+participant.userId).offsetWidth)/2);
        } 

        if (isPrimary) {
            /**TP-3573 */
            return (
                <div id={"gridAction_"+participant.userId} className="position-absolute web-grid-view-controls-parent" style={{marginLeft: leftMargin+"px"}}>
                    { participant.torch_enabled ?
                        <span className={(window.innerWidth > 1299) ? "fa-stack maxdivcontrol-spacing grid-view-controls fa-lg" : "fa-stack maxdivcontrol-spacing grid-view-controls fa-sm"}>                                
                            <a id={"toggleTorch_"+index} onClick={() => this.onClickToggleTorch({userId: participant.userId})}>
                                <i className="fas fa-circle fa-stack-2x red-shadow grid-circle"></i>
                                <i className="fas fa-bolt fa-stack-1x grid-icon" style={{ color: participant.torchActive ? 'red' : 'black' }}></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"toggleTorch_"+index}>                                            
                                        <Trans>session.flashLight</Trans>                                            
                                    </UncontrolledTooltip>
                                    :
                                    ''
                                }
                            </a>                                    
                        </span>
                        :
                        ''
                    }
                    { participant.zoom_enabled ?
                        <span className={(window.innerWidth > 1299) ? "fa-stack maxdivcontrol-spacing grid-view-controls fa-lg" : "fa-stack maxdivcontrol-spacing grid-view-controls fa-sm"}>                                
                            <a id={"rotateZoom_"+index} onClick={() => this.onClickRotateZoom({userId: participant.userId})}>
                                <i className="fas fa-circle fa-stack-2x red-shadow grid-circle"></i>
                                <i className="fas fa-binoculars fa-stack-1x grid-icon" style={{ color: participant.zoomLevel > 1 ? 'red' : 'black' }}></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"rotateZoom_"+index}>                                            
                                        <Trans>session.zoom</Trans>                                            
                                    </UncontrolledTooltip>
                                    :
                                    ''
                                }
                            </a>                                    
                        </span>
                        :
                        ''
                    }
                    <span className={(window.innerWidth > 1299) ? "fa-stack maxdivcontrol-spacing grid-view-controls fa-lg" : "fa-stack maxdivcontrol-spacing grid-view-controls fa-sm"}>                                
                        <a id={"muteMikeEach_"+index} onClick={() => this.onClickMuteMikeofOne({userId: participant.userId})}>
                            <i className="fas fa-circle fa-stack-2x red-shadow grid-circle"></i>
                            <i className="fas fa-microphone fa-stack-1x grid-icon" style={{ color: participant.disableMike ? 'red' : 'black' }}></i>
                            { isSmallFormFactorDevice === false ?
                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"muteMikeEach_"+index}>                                            
                                    <Trans>session.muteMike</Trans>                                            
                                </UncontrolledTooltip>
                                :
                                ''
                            }
                        </a>                                    
                    </span>
                    {/* T32-413 -- Disabled Speaker of Remote user implementation */}    
                    <span className={(window.innerWidth > 1299) ? "fa-stack maxdivcontrol-spacing grid-view-controls fa-lg" : "fa-stack maxdivcontrol-spacing grid-view-controls fa-sm"}>                                
                        { participant.disableRemoteAudio === false ? 
                            <a id={"toggleAudio_"+index} onClick={() => this.onClickMuteSpeakerofOne({userId: participant.userId})}>
                                <i className="fas fa-circle fa-stack-2x red-shadow grid-circle"></i>
                                <i className="fas fa-volume-up fa-stack-1x grid-icon" style={{ color: participant.disableAudio ? participant.isSpeaking ? '#1338BE' : 'red' : participant.isSpeaking ? '#1338BE' : 'black' }}></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"toggleAudio_"+index}>                                            
                                        <Trans>session.toggleAudio</Trans>                                            
                                    </UncontrolledTooltip>  
                                    :
                                    ''
                                }
                            </a>
                            :
                            <a id={"toggleAudio_"+index}>
                                <i className="fas fa-circle font-awesome-grey fa-stack-2x red-shadow grid-circle"></i>
                                <i className="fas fa-volume-mute fa-stack-1x grid-icon" style={{ color: 'red' }}></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"toggleAudio_"+index}>                                            
                                        <Trans>session.toggleAudio</Trans>                                            
                                    </UncontrolledTooltip>  
                                    :
                                    ''
                                }
                            </a> 
                        }
                                                    
                    </span>
                    { /**TP-3573*/ canTurnOffUserVideo ?
                        <span className={(window.innerWidth > 1299) ? "fa-stack maxdivcontrol-spacing grid-view-controls fa-lg" : "fa-stack maxdivcontrol-spacing grid-view-controls fa-sm"}>                                
                            { participant.grayedVideo === true || /**TP-4538*/ participant.isPaused === true ?
                                <a id={"toggleVideo_"+index} >
                                    <i className="fas fa-circle fa-stack-2x font-awesome-grey red-shadow grid-circle"></i>
                                    <i className="fas fa-video fa-stack-1x grid-icon" ></i>
                                    { isSmallFormFactorDevice === false ?
                                        <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"toggleVideo_"+index}>                                            
                                            {/**TP-4538*/ participant.isPaused ? 
                                                participant.pausedReason === "FILESHARE_ONGOING" ?
                                                    <Trans>session.pausedVideo</Trans>
                                                    :
                                                    <Trans>session.pausedVideoDefault</Trans>
                                                :
                                                <Trans>session.disableVideo</Trans>                                            
                                            }
                                        </UncontrolledTooltip>  
                                        :
                                        ''
                                    }   
                                </a>
                                :
                                <a id={"toggleVideo_"+index} onClick={() => this.onClickTurnOffVideoofOne({userId: participant.userId})}>
                                    <i className="fas fa-circle fa-stack-2x red-shadow grid-circle"></i>
                                    <i className="fas fa-video fa-stack-1x grid-icon" style={{ color: participant.disableVideo ? 'red' : 'green' }}></i>
                                    { isSmallFormFactorDevice === false ?
                                        <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"toggleVideo_"+index}>                                            
                                            <Trans>session.toggleVideo</Trans>                                            
                                        </UncontrolledTooltip>  
                                        :
                                        ''
                                    }   
                                </a>                                    
                            }
                        </span>
                        :
                        ''
                    }                                             
                </div> 
            )
        } else {
            /**TP-3573 */ 
            return (
                <div id={"gridAction_"+participant.userId} className="position-absolute web-grid-view-controls-parent" style={{marginLeft: leftMargin+"px"}}>
                    { participant.torch_enabled ?
                        <span className={(window.innerWidth > 1299) ? "fa-stack maxdivcontrol-spacing grid-view-controls fa-lg" : "fa-stack maxdivcontrol-spacing grid-view-controls fa-sm"}>                                
                            <a id={"toggleTorch_"+index}>
                                <i className="fas fa-circle font-awesome-grey fa-stack-2x red-shadow grid-circle"></i>
                                <i className="fas fa-bolt fa-stack-1x grid-icon" style={{ color: participant.torchActive ? 'red' : 'black' }}></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"toggleTorch_"+index}>                                            
                                        <Trans>session.flashLight</Trans>                                            
                                    </UncontrolledTooltip>
                                    :
                                    ''
                                }
                            </a>                                    
                        </span>
                        :
                        ''
                    }
                    { participant.zoom_enabled ?
                        <span className={(window.innerWidth > 1299) ? "fa-stack maxdivcontrol-spacing grid-view-controls fa-lg" : "fa-stack maxdivcontrol-spacing grid-view-controls fa-sm"}>                                
                            <a id={"rotateZoom_"+index}>
                                <i className="fas fa-circle font-awesome-grey fa-stack-2x red-shadow grid-circle"></i>
                                <i className="fas fa-binoculars fa-stack-1x grid-icon" style={{ color: participant.zoomLevel > 1 ? 'red' : 'black' }}></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"rotateZoom_"+index}>                                            
                                        <Trans>session.zoom</Trans>                                            
                                    </UncontrolledTooltip>
                                    :
                                    ''
                                }
                            </a>                                    
                        </span>
                        :
                        ''
                    }
                    <span className={(window.innerWidth > 1299) ? "fa-stack maxdivcontrol-spacing grid-view-controls fa-lg" : "fa-stack maxdivcontrol-spacing grid-view-controls fa-sm"}>                                
                        <a id={"muteMikeEach_"+index} onClick={() => this.onClickMuteMikeofOne({userId: participant.userId})}>
                            <i className="fas fa-circle fa-stack-2x red-shadow grid-circle"></i>
                            <i className="fas fa-microphone fa-stack-1x grid-icon" style={{ color: participant.disableMike ? 'red' : 'black' }}></i>
                            { isSmallFormFactorDevice === false ?
                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"muteMikeEach_"+index}>                                            
                                    <Trans>session.muteMike</Trans>                                            
                                </UncontrolledTooltip>
                                :
                                ''
                            }
                        </a>                                    
                    </span>
                    {/* T32-413 -- Disabled Speaker of Remote user implementation */}    
                    <span className={(window.innerWidth > 1299) ? "fa-stack maxdivcontrol-spacing grid-view-controls fa-lg" : "fa-stack maxdivcontrol-spacing grid-view-controls fa-sm"}>                                
                        { participant.disableRemoteAudio === false ? 
                            <a id={"toggleAudio_"+index} onClick={() => this.onClickMuteSpeakerofOne({userId: participant.userId})}>
                                <i className="fas fa-circle fa-stack-2x red-shadow grid-circle"></i>
                                <i className="fas fa-volume-up fa-stack-1x grid-icon" style={{ color: participant.disableAudio ? participant.isSpeaking ? '#1338BE' : 'red' : participant.isSpeaking ? '#1338BE' : 'black' }}></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"toggleAudio_"+index}>                                            
                                        <Trans>session.toggleAudio</Trans>                                            
                                    </UncontrolledTooltip>  
                                    :
                                    ''
                                }
                            </a>
                            :
                            <a id={"toggleAudio_"+index}>
                                <i className="fas fa-circle font-awesome-grey fa-stack-2x red-shadow grid-circle"></i>
                                <i className="fas fa-volume-mute fa-stack-1x grid-icon" style={{ color: 'red' }}></i>
                                { isSmallFormFactorDevice === false ? 
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"toggleAudio_"+index}>                                            
                                        <Trans>session.toggleAudio</Trans>                                            
                                    </UncontrolledTooltip>  
                                    :
                                    ''
                                }
                            </a> 
                        }                       
                    </span>                                                                                        
                </div>
            )
        }
    }
}

export default GridActions;