import promisifyLoader from './PromisifyLoader';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';

export const  FBXObjectLoader = async ({ objFilePath, textureFilePath, fnSetAnimationMixer }) => {
    let objectToReturn;
    try {
        const loader = promisifyLoader(new FBXLoader(), progressHandler);
        objectToReturn = await loader.load(objFilePath);
        const mixer = new THREE.AnimationMixer( objectToReturn );
        fnSetAnimationMixer(mixer);
        if (objectToReturn.animations[ 0 ]) {
            const action = mixer.clipAction( objectToReturn.animations[ 0 ] );
            action && action.play();
        }

        /** Load all texture files */
        textureFilePath.forEach(path => {
            const textureLoader = new THREE.TextureLoader();
            const texture = textureLoader.load(path, progressHandler);
            objectToReturn.traverse(function (child) {
                if (child instanceof THREE.Mesh) {
                    //here's how we add the texture, you may need to
                    //UV Unwrap your model first in 3Ds
                    child.material = new THREE.MeshLambertMaterial();
                    child.material.map = texture;
                }   
                if ( child.isMesh ) {
                    child.castShadow = true;
                    child.receiveShadow = true;
                }      
            });
        });
    } catch (error) {
        errorHandler(error);
    }

    function progressHandler(e) {
        console.info((e.loaded / e.total * 100 ) + '% loaded' );
    }

    function errorHandler(error) {
		console.info( 'An error happened', error);
        throw error;
    }

    return objectToReturn;
}

export default FBXObjectLoader;
