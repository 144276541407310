import React from 'react';
import { Trans, t } from '@lingui/macro';

function Loader ({text, isLoading, loaderValues}) {
    if (!isLoading) return '';
    else {
        return (
            <div className="loader">
                <span className="d-flex flex-column justify-content-center align-items-center">
                    <i className="fa fa-spin fa-circle-o-notch fa-2" aria-hidden="true"></i>                    
                    { loaderValues !== undefined && loaderValues !== "" ?
                        <Trans id={text} values={loaderValues} ></Trans>                        
                        :
                        <Trans id={text}></Trans>
                    }
                </span>
            </div>
        )
   	}
}

export default Loader;

