import React, {Fragment} from 'react';
import Actions from './Actions';
import { Trans } from '@lingui/macro';
import BubbleElement from "../BubbleElement/BubbleElement";
import OnlineIndicator from '../OnlineIndicator/OnlineIndicator';
import { checkDummyEmail } from '../helpers';

export const TableRow = ({row, index, onActionClick, columns=[], isAdmin, children, isExpert, hideActions, showOnlineIndicator, isSmallFormFactor, isPictureShow, openBubble, customerRoles}) => {
	//console.log(showOnlineIndicator);
	//console.log(row['myPicture']);
	//TP-4871 TP-3497 & TP-3506 -- handle picture = "" for delete picture flow
	return (
		<tr>
			<td key={index+1}>{index+1}</td>
			{ isPictureShow && isPictureShow === true ?
				<td className='center-align'>
					{ row['myPicture'] === undefined || row['myPicture'] === null || row['myPicture'] === "" || row['myPicture'].includes("undefined") === true ?
						<span id={"logo-pic-"+row.id} onClick={() => openBubble(row)}>
							<img className="session-profile-pic logo-profile-pic-list profile-cursor" src={"/profile.png"} alt='profile_logo' />
						</span>
						:
						<span id={"logo-pic-"+row.id} onClick={() => openBubble(row)}>
							<img className="session-profile-pic logo-profile-pic-list profile-cursor" alt="profile_logo" src={row['myPicture']} ></img>
						</span>						
					}					
					{ /*TP-3423*/row ?
						<BubbleElement index={row.id} idx={index} row={row} className={"d-none"} customerRoles={customerRoles}></BubbleElement>
						:
						''
					}
				</td>
				:
				''
			}
			{
				columns.map((column, i) => {
					if((row[column] === 0 || row[column]) && column !== 'myPicture' && column !== 'email') {
						return <td key={column + row[column]} className={ i===0 && showOnlineIndicator ? 'd-flex justify-content-start align-items-baseline': ''}> {
							i === 0 && showOnlineIndicator ? 
								<>
									<OnlineIndicator isSmallFormFactor index={index} circleTop="-11px" ringTop="-15px" />
									<span className="d-inline">{row[column]}</span>
								</> 
							: row[column]
						}
						</td>
					} else if (column === 'first_name' && row['first_name'] === '') {
						return <td ></td> /**TP-5993 */
					} else if (column === 'last_name' && row['last_name'] === '') {
						return <td ></td> /**TP-5993 */
					} else if(column === 'phonenumber'){
						return <td key={ column + Math.random() }></td>//T32-472
					} else if(column === 'email') {
						return <td key={ column + Math.random() }>{ !checkDummyEmail({verified: row.verified, email: row[column]}) ? row[column] : ""}</td>//TP-5234
					} else if(column !== 'myPicture') {
						return <td key={ column + Math.random() }>{column}</td>
					}
			})
            }
            {
                !hideActions &&
                <td key={"actions-" + index} className="actions">
                    { children ? children : <Actions isAdmin={isAdmin} isExpert={isExpert} index={index} row={row} onActionClick={onActionClick}/> }
                </td>
            }
		</tr>
	)
}

export const NoDataFoundRow = (props) => (
    <tr>
        <td colSpan={props.colspan} style={{ textAlign: 'center' }}><Trans id={props.message || 'No data found'}></Trans></td>
    </tr>
);