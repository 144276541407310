import React from 'react';
import { Row, Col, Card, CardBody, Collapse, UncontrolledTooltip } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { I18n } from "@lingui/react";
import '../Landing/Groups.scss';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import { printConsole } from '../helpers';
import AuthService from '../AuthService';

class CheckListTasks extends React.Component{

    constructor(props){
        super(props);
        this.cardColor = {
            created: 'bg-white',//TP-4103
            assigned: 'bg-white', //TP-3222
            in_progress: 'bg-white', //'bg-in-progress' TP-4870
            finished: 'bg-finished',
            skipped: 'bg-finished',
            undefined: 'bg-white' //TP-3222
        };
        this.authService = new AuthService();
    }
    
    procedureStatus = (status, i18n) => {
        switch (status) {
            case 'created':
                return i18n._(t`created`);
            case 'assigned':
                return i18n._(t`assigned`);
            case 'in_progress':
                return i18n._(t`in_progress`);
            case 'finished':
                return i18n._(t`finished`);
            case 'skipped':
                return i18n._(t`skipped`);
            default:
                break;
        } 
    }

    mapTheme = (themeId) => {
        const {themes} = this.props
        const selectedTheme = themes.find((a) => a.id === themeId);
        if (selectedTheme){
            return {
                value: selectedTheme.value,
                label: selectedTheme.name
            };
        }else{
            return ''
        }
    }

    render(){
        const props = this.props;
        const isJobFinished = (props.selectedJob && (props.selectedJob.status === 'finished'));
        const {action= "", customer={}} = props;//TP-5606
        //console.log(props.isSmallFormFactor);
        //console.log(props.checkListTasks[idx].status);
        const selectStyle = {
            option: (styles) => ({...styles, color: '#000'})     
        }
        return (
                <>
                    {props.checkListTasks.map((val, idx)=> {
                        let descriptionId = `description-${idx}`,
                        isEditable = ((props.checkListTasks[idx].status === 'created' || props.checkListTasks[idx].status === 'assigned' || props.checkListTasks[idx].status === undefined)
                         && (action !== "clone_scan_checklist_job" && action !== "edit_scan_checklist"))
                        return (
                            <I18n key={idx} >
                            {({ i18n }) => 
                            
                                <Card className={this.cardColor[props.checkListTasks[idx].status] + " mt-1 text-black"}>
                                <CardBody className="p-2">
                                    <Row className="pointer">
                                        <div className="col-md-3" onClick={() => { props.collapseSubTask(idx) }}>
                                            {customer.behaviour === "pndsafety" ? <Trans>Safety Check</Trans> : <Trans>Procedure</Trans>} {idx+1}:
                                        </div>
                                        <div className="col-md-7 groups-form-validation-msg" onClick={() => { props.collapseSubTask(idx) }}>
                                            {props.checkListTasks[idx].showInvalidError ? <Trans>One or more entries are invalid</Trans> : ''} 
                                        </div>

                                        <div className="col-md-1" onClick={() => { props.collapseSubTask(idx) }}>

                                            {
                                                props.checkListTasks[idx].collapse ?
                                                <i className="fas fa-angle-up float-right" aria-hidden="true"></i>:
                                                <i className="fas fa-angle-down float-right" aria-hidden="true"></i> 
                                            }

                                        </div>
                                        <div className="col-md-1">
                                            {   isEditable ? 
                                                    <>
                                                        {props.checkListTasks[idx].id ?
                                                            <I18n>
                                                                {({ i18n }) =>
                                                                    <span className="pointer" onClick={(e) => props.deleteCheckListTask({e,index: idx})}>
                                                                        <i className="far fa-trash-alt red-trash" id={'deleteTask-' + idx}></i>
                                                                        { props.isSmallFormFactor === false ? 
                                                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'deleteTask-' + idx}>
                                                                                <Trans>Delete Task</Trans>
                                                                            </UncontrolledTooltip>       
                                                                            :
                                                                            ''
                                                                        }
                                                                    </span>
                                                                }
                                                            </I18n>
                                                        : 
                                                            <I18n>
                                                                {({ i18n }) =>
                                                                    <span className="pointer" onClick={(e) => props.deleteNewCheckListTask({e,index: idx})}>
                                                                        <i className="far fa-trash-alt red-trash" id={'deleteTask-' + idx}></i>
                                                                        { props.isSmallFormFactor === false ? 
                                                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'deleteTask-' + idx}>
                                                                                <Trans>Delete Task</Trans>
                                                                            </UncontrolledTooltip> 
                                                                            :      
                                                                            ''
                                                                        }
                                                                    </span>
                                                                } 
                                                            </I18n>
                                                        }
                                                    </>
                                                : null
                                            }
                                        </div>
                                    </Row>
                                    <Collapse isOpen={!props.checkListTasks[idx].collapse}>
                                        <Row className="mt-1 mb2">
                                            <Col className="input-wrapper">
                                                <textarea
                                                    rows={4}
                                                    style={{resize:'none'}}
                                                    name={i18n._(t`task_instruction`)+ idx}
                                                    data-id={idx}
                                                    id={descriptionId}
                                                    placeholder={i18n._(t` Instructions`)}
                                                    className="form-control"
                                                    onChange={props.onChangeCheckListTask('task_instruction', idx)}
                                                    value={props.checkListTasks[idx].task_instruction}
                                                    disabled={!isEditable || isJobFinished}
                                                />
                                                { props.isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={descriptionId}>
                                                        <Trans>Instructions</Trans>
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                                { props.validator.message(i18n._(t`instruction`)+ idx, props.checkListTasks[idx].task_instruction, 'required') }
                                            </Col>
                                            <Col id="response_type" className="input-wrapper">
                                                <Select
                                                    value={props.themeNames(this.mapTheme(props.checkListTasks[idx].theme_id), i18n)}
                                                    onChange={props.handleThemeChange(idx)}
                                                    components={makeAnimated()}
                                                    placeholder={props.isSmallFormFactor === false ? i18n._(t` Select Response Type`) : i18n._(t` Select Response`)}
                                                    options={props.getChecklistThemeNameOptions(i18n)}
                                                    isSearchable={this.props.isSmallFormFactor ? false : true}
                                                    isDisabled={!isEditable || isJobFinished}
                                                    styles={selectStyle}
                                                    id={`response_type_`+ idx}
                                                />
                                                <input type="text" readOnly value={props.checkListTasks[idx].theme_id} name={i18n._(t`theme_name`)+ idx} className="hidden"/>
                                                { props.validator.message(i18n._(t`theme_name`)+ idx, props.checkListTasks[idx].theme_id, 'required') }
                                                { props.isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="response_type">
                                                        <Trans>Select Response Type</Trans>
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </CardBody>
                                </Card>
                            }
                        </I18n>
                        )
                    })
                    }
                </>
        )
                
    }
}


export default CheckListTasks;