import React from 'react';
import { Trans } from '@lingui/macro';

function WebrtcErrorMessage ({showWebrtcErrorMessage, text, onClickClose}) {
    if (!showWebrtcErrorMessage) return '';
    else {
        return (
            <div className='modale opened' style={{zIndex: '99997'}}>
                <div className='__modal-dialog'>
                    <form>
                        <div className="__modal-header">
                            <h4 style={{color: 'red'/* TP-1165 '#485890' */}}><Trans id={text}/></h4>
                        </div>
                        <div className="__modal-footer flex-center">
                            <button className="btn-green" onClick={() => onClickClose()}><Trans id='OK'/></button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default WebrtcErrorMessage;
