/** TP-6771
 * Created by Prerana Ray   
 * for the new Chat Feature
 * Group Chat
 */


import React from 'react';
import './Groups.scss';
import _ from 'lodash';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import NewChat from '../NewChat/NewChat';
import ChatSidePanel from '../NewChat/ChatSidePanel';
import { printConsole, encryptData, getTimeStamp } from '../helpers';
let participantList;


class ChatGroup extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			chatMsgInfoArr: [{ msgColor: '', messageID: '',
                firstName: '', lastName: '', message: '', timeStamp: '', msgType: 0 // 0 - indiv, 1 - group
            }],
            selectedUser: null
		}
        this.message = "chat";
    }

    componentDidMount() {
        /* this.testTimer = setTimeout(() => {
            this.subscribeToChatSignal({
                type: "Chat:Message", messageID: 656382, 
                data: "hello from the other side", 
                to: "prerana@telepresenz.com", 
                from: {
                    firstName: "PR",
                    lastName: "Tech",
                    email: "prerana.tech.care4d@gmail.com"
                } 
            })
            clearTimeout(this.testTimer);
        }, 3000); */    
        window.addEventListener("resize", this.redrawChatWindow);   
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.redrawChatWindow);
    }

    redrawChatWindow = () => {
        participantList = [];
            this.props.onlineAllUsers.forEach(user => {
                //if (this.props.onlineUsers.includes(user.email) && this.props.adminemailid !== user.email)
                const userJson = {
                    email: user.email, 
                    first_name: user.label.split(" ")[0], 
                    last_name: user.label.split(" ")[1],
                }
                participantList.push(user);
            })
    }

    onClickClose = (e) => {
        e.preventDefault();
		this.props.onClose();
    }

    sendSignalChat = (messageID, message, groupId, action, to) => {
        const username = this.props.adminname.split(" ");
        const from = {
           email : this.props.adminemailid,
           firstName : username[0],
           lastName : username[username.length - 1]
        };
        const json = (to !== undefined) ? {
            from,
            to,
            messageID, // MB2-95
            message,
            isGroupChat: true,
            groupId,
            action,
            //type:"Chat:Message"
        } : {
            from,
            messageID, // MB2-95
            message,
            isGroupChat: true,
            groupId,
            action,
            //type:"Chat:Message"
        }
        console.log("socket payload is: ",json);
        this.props.authService.socket.emit(this.message, json);
    }

    sendChatMsg = (msgToSend, elemId) => {
        const {adminemailid, adminname, recipientUser, selectedGroup} = this.props;
        let { myPicture } = this.props;
        const username = adminname.split(" ");
        let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
        //TP-3303 -- Handling of profile picture when no profile picture in Chat window
        if (myPicture && myPicture.includes("base64") === false && myPicture.includes("profile.png") === false)
            myPicture = 'data:image/jpg;base64,'+ myPicture;
        else if (myPicture && (myPicture.includes("profile.png") === true || myPicture === ""))
            myPicture = null;
        let timeStamp = getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: username[0], messageID: sixdigitsrandom,
            lastName: username[username.length - 1], message: msgToSend, timeStamp: timeStamp, msgType: 1,
            myPicture: myPicture
        };
        this.props.updateChatArr('sendChat', chatMsgInfo);
        //this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}))
        const message = {
            font_color: "#ffb3b3",
            font_size: "14px",
            font_type: "900",
            font_underline: false,
            font_overline: false,
            font_line_through: true,
            font_family: "Titillium Web",
            msg_data: msgToSend,
        }
        const action = {send_msg: true,
            delete_msg: false, edit_msg: false, 
            vanish_msg: false}
        console.log(participantList);
        const groupId = selectedGroup.groupid;
        participantList && participantList.length > 0 && participantList.forEach(parti => {
            const toObject = {
                email: parti.email,
                firstName: parti.first_name,
                lastName: parti.last_name
            };
            this.sendSignalChat(sixdigitsrandom, message, groupId, action, toObject);
        })
        //this.sendSignalChat(sixdigitsrandom, message, action)
    }

    replyChatMsg = (new_msg, elemId, replymessageID) => {
        console.log("Reply to chat msg: ", replymessageID, elemId, new_msg)
        const {adminemailid, selectedGroup, adminname} = this.props;
        let { myPicture } = this.props;
        const username = adminname.split(" ");
        let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
        //TP-3303 -- Handling of profile picture when no profile picture in Chat window
        if (myPicture && myPicture.includes("base64") === false && myPicture.includes("profile.png") === false)
            myPicture = 'data:image/jpg;base64,'+ myPicture;
        else if (myPicture && (myPicture.includes("profile.png") === true || myPicture === ""))
            myPicture = null;
        let timeStamp = getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: username[0], messageID: sixdigitsrandom,
            lastName: username[username.length - 1], message: new_msg, timeStamp: timeStamp, msgType: 1,
            myPicture: myPicture
        };
        const groupId = selectedGroup.groupid;
        this.props.updateChatArr('replyChat', chatMsgInfo, replymessageID);
        const message = {
            font_color: "#ffb3b3",
            font_size: "14px",
            font_type: "900",
            font_underline: false,
            font_overline: false,
            font_line_through: true,
            font_family: "Titillium Web",
            msg_data: new_msg,
            replymessageID
        }
        const action = {send_msg: false,
            delete_msg: false, edit_msg: false, 
            reply_msg: true, vanish_msg: false}
        participantList && participantList.length > 0 && participantList.forEach(parti => {
            const toObject = {
                email: parti.email,
                firstName: parti.first_name,
                lastName: parti.last_name
            };
            this.sendSignalChat(sixdigitsrandom, message, groupId, action, toObject);
        })
    }

    editChatMsg = (new_msg, elemId, messageID) => {
        console.log("Edit chat msg: ", messageID, elemId, new_msg)
        const {adminemailid, selectedGroup, adminname, recipientUser} = this.props;
        let { myPicture } = this.props;
        const username = adminname.split(" ");
        //TP-3303 -- Handling of profile picture when no profile picture in Chat window
        if (myPicture && myPicture.includes("base64") === false && myPicture.includes("profile.png") === false)
            myPicture = 'data:image/jpg;base64,'+ myPicture;
        else if (myPicture && (myPicture.includes("profile.png") === true || myPicture === ""))
            myPicture = null;
        let timeStamp = getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: username[0], messageID,
            lastName: username[username.length - 1], message: new_msg, timeStamp: timeStamp, msgType: 1,
            myPicture: myPicture
        };
        const groupId = selectedGroup.groupid;
        this.props.updateChatArr('editChat', chatMsgInfo, messageID);
        const message = {
            font_color: "#ffb3b3",
            font_size: "14px",
            font_type: "900",
            font_underline: false,
            font_overline: false,
            font_line_through: true,
            font_family: "Titillium Web",
            msg_data: new_msg,
        }
        const action = {send_msg: false,
            delete_msg: false, edit_msg: true, 
            vanish_msg: false}
        participantList && participantList.length > 0 && participantList.forEach(parti => {
            const toObject = {
                email: parti.email,
                firstName: parti.first_name,
                lastName: parti.last_name
            };
            this.sendSignalChat(messageID, message, groupId, action, toObject);
        })
    }

    deleteChatMsg = (messageID, elemId) => {
        console.log("Delete chat msg: ", messageID);
        const {selectedGroup} = this.props;
        const action = {send_msg: false,
            delete_msg: true, edit_msg: false, 
            vanish_msg: false}
        const groupId = selectedGroup.groupid;
        this.props.updateChatArr('deleteChat', {}, messageID);
        participantList && participantList.length > 0 && participantList.forEach(parti => {
            const toObject = {
                email: parti.email,
                firstName: parti.first_name,
                lastName: parti.last_name
            };
            this.sendSignalChat(messageID, "", groupId, action, toObject);
        })
    }

    filterChatUser = (user_email) => {
        this.props.onlineAllUsers.forEach(user => {
            if (user_email === user.email) this.setState({ selectedUser: user });
        })
    }

    render() {
        //const {recipientUser} = this.props;
        //TP-6222
        const chatHeight = Math.floor((window.innerHeight - (98+42+2)) * 86/100);
        const marginTopButton = chatHeight;
        participantList = [];
        //console.log(this.props.onlineAllUsers, this.state.selectedUser);
        let recipientUser = this.state.selectedUser === null ? this.props.onlineAllUsers[0]: this.state.selectedUser;
        this.props.onlineAllUsers && this.props.onlineAllUsers.length > 0 && this.props.onlineAllUsers.forEach(user => {
            if (/* this.props.onlineUsers.includes(user.email) && */ this.props.adminemailid !== user.email) {
                const userJson = {
                    email: user.email, 
                    first_name: user.label.split(" ")[0], 
                    last_name: user.label.split(" ")[1],
                }
                participantList.push(userJson);
            }
        })
        return (
            <div className=" flex-center">
                <ChatSidePanel 
                    chatHeight={chatHeight}
                    updateUser={this.filterChatUser}
                    selectedUser={recipientUser}
                    participantList={participantList}
                />                    
                <NewChat 
                    {...this.props}
                    onSendChatMsg={this.sendChatMsg}
                    bPrivate={this.state.selectedUser === null ? false : true}
                    bShowChat={true}
                    elemId={this.state.selectedUser === null ? null : this.state.selectedUser.email}
                    recipientUser={this.state.selectedUser === null ? null : recipientUser }
                    startTimer={false}
                    updateChatFlags={this.updateChatFlags}
                    chatHeight={chatHeight}
                    isGroupChat={true}
                    editChatMsg={this.editChatMsg}
                    deleteChatMsg={this.deleteChatMsg}
                    replyChatMsg={this.replyChatMsg}    
                    isSessionChat={false}                
                />

                <div className="" style={{marginTop: marginTopButton+'px'}}>
					<button className="btn-red"  onClick={(e) =>this.onClickClose(e)}><Trans>Close</Trans></button>                                
			    </div>
            </div>
        )

    }
}

export default ChatGroup;