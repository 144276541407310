import React, {Component} from 'react';
// import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { I18n } from "@lingui/react";
import './StreamActions.scss';
import { printConsole } from '../helpers';
import { SketchPicker } from 'react-color';
import SessionSignal from '../Session/SessionSignal';
import AuthService from '../AuthService';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import SessionIcon from '../Session/SessionIcon.jsx';

const zoomLevelArray = [
    { id: '1', name: '1X'},
    { id: '2', name: '2X'},
    { id: '3', name: '3X'},
    { id: '4', name: '4X'},
    { id: '5', name: '5X'}
];
const annotateShapesArray = [
    { class: "fas fa-signature", name: "session.freehand" },
    { class: "far fa-circle", name: "session.circle" },
    { class: "far fa-square", name: "session.square" },
    { class: "fas fa-mouse-pointer", name: "session.arrow" },
    { class: "fas fa-crosshairs", name: "session.pointer" },//TP-2491
    { class: "fas fa-eraser", name: "session.erasor" }, //TP-2474
    { class: "far fa-comment", name: "session.text" }, //TP-4695
    { class: "fas fa-undo", name: "session.undo"}, //TP-4696
    { class: "fas fa-signature", name: "session.freehand" }
]
class StreamActions extends Component {
    constructor (props) {
        super(props);
        this.state = {
            bRemoteMute: props.maxDivSpeakerMuted,
            maxDivSpeakerDisabled: props.maxDivSpeakerDisabled, // T32-413
            muteMikeForMaxDiv: props.maxDivMikeMuted,
            bShareAsset: props.isHeaderFileShare ? props.isHeaderFileShare : false, //TP-1394
            bAnnotate: props.user.isexpert && !props.showMaxDivControls ? props.bAnnotate: false,
            showRc: false,
            bRemoteCapture: false,
            bHideAnnotateShapes: true,
            bHideAnnotateColour: true,
            bHideAnnotationPointer: false, //TP-2528 -- set as false for now this will be changed later to true
            pencolor: props.user.isexpert && !props.showMaxDivControls ? props.pencolor: "#ff0000",
            bSelectColor: false,
            bSelectShape: false,
            isUndoActive: props.user.isexpert && !props.showMaxDivControls ? props.shapesSetting.isUndoActive: false, //TP-4696
            isErasorActive: props.user.isexpert && !props.showMaxDivControls ? props.shapesSetting.isErasorActive: false, //TP-2474
            isPointerActive: props.user.isexpert && !props.showMaxDivControls ? props.shapesSetting.isPointerActive: false, //TP-2491
            isRectActive: props.user.isexpert && !props.showMaxDivControls ? props.shapesSetting.isRectActive: false,
            isCircleActive: props.user.isexpert && !props.showMaxDivControls ? props.shapesSetting.isCircleActive: false,
            isArrowActive: props.user.isexpert && !props.showMaxDivControls ? props.shapesSetting.isArrowActive: false,
            isTextActive: props.user.isexpert && !props.showMaxDivControls ? props.shapesSetting.isTextActive: false, //TP-4695
            isFreeDrawing: props.user.isexpert && !props.showMaxDivControls ? props.shapesSetting.isFreeDrawing: true,
            screenCaptureClicked: false,
            bMaxChat: false,
            bHideExpertSSButton: this.props.bHideExpertSSButton,
            bHideTechnicianSSButton: this.props.bHideTechnicianSSButton,
            expertShareScreenMaxDiv: this.props.isSideBarSS,//MB2-586
            showHideMaxDivControl: this.props.showMaxDivControls, /* showHideDivIconAudioOnly: false, */ clickHandSignal: false, disableHand: false,
            showControlRequestDialog: false, expertFirstName: '', expertLastName: '', /**TP-2292*/expertEmail: '', requestDialogTimer: 10, requestedUser: null,
            bTorchClick: false, bZoomClick: false, zoom_level: '1', startRecording: false, hideButton: 'd-blk',
            handIconHover: "session.handIconDisabled", showPanel: false, /**TP-3213*/ hideHandIcon: props.hideHandIcon ? props.hideHandIcon : false, /**TP-4543*/
            /*TP-5851*/ isYieldingControl: false, /**TP-5852*/ isPrepareExpertSS: false
        };
        this.sessionSignal = new SessionSignal({
            userId: props.user.id, 
            firstName: props.user.firstname, 
            lastName: props.user.lastname, 
            email: props.user.email, 
            isexpert: props.user.isexpert
        },true, props.selectedGroupId);
        this.authService = new AuthService();
    }

    componentDidMount() {
        if (this.props.user.isexpert && this.props.maxView && this.props.maxView === true) {
            const hideButton = this.getDeviceClassname();
            this.setState({hideButton});
            this.setAnnotationButtons();//set the annotations color and shapes button            
        } else if (this.props.user.isexpert === false) {
            //For technicians on check if Small form factor device or not
            const hideButton = this.getDeviceClassname();
            this.setState({hideButton});
        }
        this.sessionSignal.listenerWebsocket(this.subscribeToGrpSignal);
    }

    componentWillUnmount = () => {
        this.sessionSignal.removeWebsocket(this.subscribeToGrpSignal);
    }

    componentWillReceiveProps = (nextProps) => {
        //TP-4543
        if(nextProps.hideHandIcon !== this.props.hideHandIcon && nextProps.hideHandIcon !== undefined){            
            this.setState({ hideHandIcon: nextProps.hideHandIcon, handIconHover: /*TP-5168 (this.state.hideHandIcon === true) ? */"session.handIconDisabled"/*  : "session.expertControlsDisabled"  */});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.maxDivMikeMuted !== prevProps.maxDivMikeMuted){
            this.handleMuteMike()
        }
        if(this.props.maxDivSpeakerMuted !== prevProps.maxDivSpeakerMuted){
            this.handleMuteSpeaker()
        }
        //T32-413
        if (this.props.maxDivSpeakerDisabled !== prevProps.maxDivSpeakerDisabled) {
            this.handleDisableSpeaker()
        }
        if(this.props.isHeaderFileShare !== prevProps.isHeaderFileShare && this.props.user.isexpert){
            this.handleFileUpload()
        }

        if(this.props.isOneToOneFileShare !== prevProps.isOneToOneFileShare && this.props.user.isexpert){
            this.handleOneToOneFileUpload()
        }

        if(this.props.showRc !== prevProps.showRc){
            this.showMaxdivLocalRc()
        }

        if(this.props.localRc !== prevProps.localRc){
            this.handleMaxdivLocalRc()
        }

        if(this.props.screenCaptureClicked !== prevProps.screenCaptureClicked){
            this.handleScreenCaptureClicked()
        }

        if(this.props.bMaxChat !== prevProps.bMaxChat){
            this.handleMaxChatClicked()
        }

        if(this.props.isMaxDivSS !== prevProps.isMaxDivSS){
            this.handleMaxSSClicked(this.props.isMaxDivSS)
            this.handlesilhoutteeIcon(this.props.isMaxDivSS)
        }

        if(this.props.isSideBarSS !== prevProps.isSideBarSS){
            this.handleMaxSSClicked(this.props.isSideBarSS)
        }

        if(this.props.showMaxDivControls !== prevProps.showMaxDivControls){
            this.handleMaxDivControl()
        }

        if(this.props.bHideExpertSSButton !== prevProps.bHideExpertSSButton){
            this.handleExpertSSButton()
        }

        if(this.props.bAnnotate !== prevProps.bAnnotate){
            printConsole("props bAnnotate was changed");
            this.handleMaxDivAnnotation();//Mb2-507 will change depending on the state change in webrtc session
        }

        if(this.props.pencolor !== prevProps.pencolor || this.props.shapesSetting !== prevProps.shapesSetting) {
            this.handleAnnotationColorOrShapes();
        }

        /**TP-2597 -- Handling of Primary expert's ShapePicker icons 
         * to set to default after RC is clicked while Annotate is turned on */
        if(this.props.isAnnotateOnWtRC !== prevProps.isAnnotateOnWtRC && this.props.isAnnotateOnWtRC === false && prevProps.isAnnotateOnWtRC === true) {
            this.handleAnnotateShapeWhenRCClicked();
        }
        
        if(this.props.resetMax !== undefined && this.props.resetMax !== prevProps.resetMax && this.props.resetMax === true) {
            this.resetMaxdiv();
        }

        if(this.props.maxDivParticipant && this.props.maxDivParticipant !== '' && prevProps.maxDivParticipant && prevProps.maxDivParticipant !== '' && (this.props.maxDivParticipant.email !== prevProps.maxDivParticipant.email)){
            //this.resetMaxdiv();
        }

        if(this.props.startRecording !== prevProps.startRecording){
            this.handleRecording()
        }

        if(this.props.bRemoteCapture !== prevProps.bRemoteCapture && this.props.user.isexpert) {
            printConsole("RC button state is changed");
            this.handleMaxdivRC();
            //this.handlesilhoutteeIcon(this.props.bRemoteCapture)
        }

        if (this.props.changeHandIcon !== prevProps.changeHandIcon && this.props.changeHandIcon && this.props.user.isexpert) {
            this.handleHandIconChange();
        }

        //TP-1313 
        if (this.props.clickHandSignal !== this.state.clickHandSignal /* TP-2370 && this.props.clickHandSignal === true */) {
            this.handleHandIconFromProps(this.props.clickHandSignal);
        }

        //TP-2292 -- condition to check if the user who left the session is the same expert
        // who has requested controls from the Primary expert or not
        if (this.props.expertParticipantLeft !== prevProps.expertParticipantLeft && this.props.expertParticipantLeft !== null ) {
            this.checkIfRequestDialogisOpen(); 
        }

        //TP-2377 -- to trigger the setting of the buffer to store the Pencolor & shape setting
        if (this.props.setBufferColorShape !== prevProps.setBufferColorShape && this.props.setBufferColorShape === true) {
            this.setBufferColorShapeAnnotate();
        }

        //TP-2377 -- to set the Pencolor & shape setting from the buffer to the button states
        if (this.props.setColorShape !== prevProps.setColorShape && this.props.setColorShape === true) {
            this.setColorShapeIncaseResized();
        }
        //TP-2861
        if (this.props.resetMaxUserTorch !== prevProps.resetMaxUserTorch && this.props.resetMaxUserTorch === true) {
            this.handleTorchReset();
        }
        //TP-2861
        if (this.props.resetMaxUserZoom !== prevProps.resetMaxUserZoom && this.props.resetMaxUserZoom === true) {
            this.handleZoomReset();
        }
        //TP-3213
        if (this.props.showPanel !== prevProps.showPanel) {
            this.handleShowPanelClicked();
        }

        //TP-2971
        if (this.props.user.isexpert && this.props.screenCastStarted !== prevProps.screenCastStarted && this.props.screenCastStarted === true) {
            this.handleTorChAndZoomScreenCast()
        }

        if (this.props.user.isexpert && this.props.triggerCloseRC !== prevProps.triggerCloseRC && this.props.triggerCloseRC === true) {
            this.onClickCamera(true, true);//TP-5489 & TP-4933
        }

        if (this.props.user.isexpert && this.props.triggerCloseAnnotate !== prevProps.triggerCloseAnnotate && this.props.triggerCloseAnnotate === true) {
            this.onClickAnnotate(true, true);//TP-5489 & TP-4933
        }
        //TP-6170
        if (this.props.closeRC !== prevProps.closeRC && this.props.closeRC === true) {
            printConsole("Trigger the RC process to close");
            this.setState({ 
                bRemoteCapture: !prevState.bRemoteCapture,
                bAnnotate: !prevState.bAnnotate,
                bSelectColor: false,
                bSelectShape: false,
                pencolor: "#ff0000",
                isFreeDrawing: false, /**TP-2586 */
                isTextActive: false, /**TP-4695 */
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false,
                isPointerActive: false, /*TP-2491 */
                isUndoActive: false
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isPointerActive: false,
                    isArrowActive: false,
                    isFreeDrawing: false,
                    isTextActive: false, /**TP-4695*/
                    isRectActive: false,
                    isCircleActive: false,
                    isErasorActive: false,
                    isUndoActive: false /**TP-4696*/
                }, bSelectShape: true, pencolor: this.state.pencolor })
            })
        }
    }

    //group signal subscriber
    subscribeToGrpSignal = ({ type, data, to, from, expertName }) => {
        // const updatedChanges = {};
        //printConsole("Signal received on webapp side========>",type,data, to)
        switch (type) {
            case 'START_LOCAL_RC':
                this.props.user.isexpert && /**TP-5851*/ this.state.showControlRequestDialog === false && this.onClickStartLocalRc({flag:data})
                break;
            case "REQUEST_MAXDIV_CONTROL":
                this.processSignalRequestMaxDivControl({data, from});//MB2-549
                break;
            case "YIELD_MAXDIV_CONTROL":
                this.props.user.isexpert && this.state.showHideMaxDivControl === false && this.processSignalYieldMaxDivControl({data, from});//MB2-549
                break;
            case "YIELD_DISABLE_HAND_ICON":
                this.processSignalYieldDisableHandIcon({data, expertName});//Mb2-638
                break;
            /**TP-5851 */
            case "EXPERT_YIELD_MAXVIEW_CONTROLS":
                !this.props.user.isexpert && this.processSignalExpertYieldMaxviewControls({data, expertName, from});
                break;
            /**TP-5851 */
            case "CURRENT_PRIMARY_EXPERT":
                !this.props.user.isexpert && this.processIsYieldingControl({from}); // TP-359
                break;
            case "LOCAL_TORCH":
                this.props.user.isexpert && this.state.showHideMaxDivControl === true && this.processSignalLocalTorch({from, data});
                break;
            case "LOCAL_ZOOM_LEVEL":
                this.props.user.isexpert && this.state.showHideMaxDivControl === true && this.processSignalLocalZoomLevel({from, data});
                break;
            /** T32-495 -- For Zoom & Torch sync between Multi-experts */
            case "SYNC_EXPERTS":
                this.processSignalSyncExperts({from, data});
                break;
            /**TP-5852*/
            case "PREPARE_EXPERT_SS":
                !this.props.user.isexpert && this.processSignalPrepareExpertSS({data, from});
                break;
            /**TP-5852*/
            case "PREPARE_EXPERT_SS_CANCEL":
                !this.props.user.isexpert && this.processSignalPrepareExpertSSCancel({from});
                break;
            /**TP-5852*/
            case 'START_EXPERT_SS':
                !this.props.user.isexpert && this.processSignalStartExpertSS({from, type});
                break;
            default:
                break;
        }
        // this.setState((prev) => ({...prev,...updatedChanges}));
    }

    //TP-2971
    handleTorChAndZoomScreenCast = () => {
        const {bZoomClick, bTorchClick, zoom_level} = this.state;
        //TP-2861 -- Handling closing Zoom & Torch features when Screen cast (Max view controls)
        // is triggered by Primary expert
        if (bZoomClick && parseInt(zoom_level) > 1) this.onClickZoomLevel('1');
        if (bTorchClick) this.onClickTorchIcon();
    }
    
    handleMuteMike = () =>{
        this.setState({muteMikeForMaxDiv: this.props.maxDivMikeMuted})
    }

    handleMuteSpeaker = () =>{
        this.setState({bRemoteMute: this.props.maxDivSpeakerMuted})
    }

    // T32-413
    handleDisableSpeaker = () =>{
        this.setState({maxDivSpeakerDisabled: this.props.maxDivSpeakerDisabled});
    }

    handleFileUpload = () => {
        const { bAnnotate, bRemoteCapture, bZoomClick, bTorchClick, startRecording, bMaxChat } = this.state;
        const isBusy= (bAnnotate || bRemoteCapture || /* bZoomClick || bTorchClick || startRecording || bMaxChat  || */ this.props.isMaxDivSS);//MB2-731
        this.setState({bShareAsset: this.props.isHeaderFileShare}, () => {
            !isBusy && this.props.maxDivActionGoingOn({flag:this.state.bShareAsset})
        })
    }

    handleOneToOneFileUpload = () => {
        printConsole(`CHange the file share icon ${this.props.isOneToOneFileShare}`);
        const { bAnnotate, bRemoteCapture, bZoomClick, bTorchClick, startRecording, bMaxChat } = this.state;
        // expertShareScreenMaxDiv is enabled for sid bar ss also so considering only isMaxDivSS
        const isBusy= (bAnnotate || bRemoteCapture || /* bZoomClick || bTorchClick || startRecording || */ bMaxChat || this.props.isMaxDivSS);//MB2-731
        this.setState({bShareAsset: this.props.isOneToOneFileShare}, ()=> {
            !isBusy && this.props.maxDivActionGoingOn({flag:this.state.bShareAsset})
        })
    }

    handleHandIconChange = () => {
        this.setState({ hideHandIcon: false, /**TP-5009*/ disableHand: false, clickHandSignal: false }, () => {
            this.props.setClickHandSignal(false); //TP-1313
        });
    }

    //TP-1313 -- Handling of the clickHandSignal flag from Expert's parent component
    handleHandIconFromProps = (value) => {
        this.setState({ clickHandSignal: value });
    }

    showMaxdivLocalRc = () => {
        this.setState({showRc: this.props.showRc})
    }

    handleMaxdivRC = () => {
        printConsole(`Turn the remote capture button to ${this.props.bRemoteCapture}`);
        const { bAnnotate, bShareAsset, bZoomClick, bTorchClick, bMaxChat, startRecording } = this.state;
        const isBusy = (/* bAnnotate || */ bShareAsset || /* bZoomClick || bTorchClick || */ bMaxChat /* || startRecording */ || this.props.isMaxDivSS);//T32-580
        this.setState({bRemoteCapture: this.props.bRemoteCapture}, () => {
            if (this.props.user.isexpert /* && this.state.bRemoteCapture === false */){
                !isBusy && this.props.maxDivActionGoingOn({flag: this.state.bRemoteCapture})// T32-580
            }
            if (this.props.user.isexpert && this.state.bRemoteCapture === false && this.state.bZoomClick) {
                //TP-1181 -- Added below condition to show the zoom selector when RC is stopped (cancel RC flow)
                const zoomSelector = document.getElementById('zoomlevelselector');
                if(parseInt(this.state.zoom_level) >1 && zoomSelector  && zoomSelector.style.display !=='block'){
                    zoomSelector.style.display = 'block';
                }else if (parseInt(this.state.zoom_level) === 1 && zoomSelector && this.state.bZoomClick){
                    this.setState({bZoomClick:false})
                }
            }
        });
    }

    handleMaxdivLocalRc = () => {
        this.setState({bRemoteCapture: this.props.localRc}, () => {
            //TP-1181 -- Added below condition to avoid showing the zoom selector when Local capture is enabled/disabled
            const zoomSelector = document.getElementById('zoomlevelselector');
            if(this.state.bZoomClick && this.state.bRemoteCapture){
                if(zoomSelector && zoomSelector.style.display !=='none')
                    zoomSelector.style.display = 'none' 
            }else{
                if(parseInt(this.state.zoom_level) >1 && zoomSelector  && zoomSelector.style.display !=='block'){
                    zoomSelector.style.display = 'block';
                }else if (parseInt(this.state.zoom_level) === 1 && zoomSelector && this.state.bZoomClick){
                    this.setState({bZoomClick:false})
                }
            }
        })
    }

    handleScreenCaptureClicked = () => {
        this.setState({screenCaptureClicked: this.props.screenCaptureClicked})
    }

    handleMaxChatClicked = () => {
        const { bZoomClick, bTorchClick, startRecording } = this.state;
        // expertShareScreenMaxDiv is enabled for sid bar ss also so considering only isMaxDivSS
        const isBusy= (/* bZoomClick || bTorchClick || startRecording || */ this.props.isMaxDivSS);//MB2-731
        this.setState({bMaxChat: this.props.bMaxChat})
        if(!this.state.bAnnotate && !this.state.bRemoteCapture && !isBusy){
            //this.props.user.isexpert && this.props.maxDivActionGoingOn({flag: this.props.bMaxChat})
        }
    }

    //TP-3213
    handleShowPanelClicked = () => {
        this.setState({showPanel: this.props.showPanel});
    }

    handleMaxSSClicked = (flag) => {
        this.setState({expertShareScreenMaxDiv: flag}, () => {
            //TP-1181 -- Added below condition to avoid showing the zoom selector when ExpertSS is enabled
            const zoomSelector = document.getElementById('zoomlevelselector');
            if(this.state.bZoomClick && this.state.expertShareScreenMaxDiv){
                if(zoomSelector && zoomSelector.style.display !=='none')
                    zoomSelector.style.display = 'none' 
            }else{
                if(parseInt(this.state.zoom_level) >1 && zoomSelector  && zoomSelector.style.display !=='block'){
                    zoomSelector.style.display = 'block';
                }else if (parseInt(this.state.zoom_level) === 1 && zoomSelector && this.state.bZoomClick){
                    this.setState({bZoomClick:false})
                }
            }
        })
        if (flag === false && this.state.bAnnotate) {
            this.onClickAnnotate();
        }
    }

    handlesilhoutteeIcon = (flag) => {
        const { bAnnotate, bRemoteCapture, bShareAsset, bZoomClick, bTorchClick, startRecording } = this.state;
        const isBusy= (/* bZoomClick || bTorchClick || startRecording || */ bAnnotate || bRemoteCapture || bShareAsset);//MB2-731
        !isBusy && this.props.maxDivActionGoingOn({flag})
    }

    handleMaxDivControl = () => {
        this.setState({showHideMaxDivControl: this.props.showMaxDivControls})
        //if (this.props.maxView && this.props.maxView === false) return; 
        printConsole('In handle max div control========================>',this.props.bAnnotate , this.props.bRemoteCapture, this.props.rcStart)
        if(this.props.bAnnotate && this.props.bRemoteCapture && this.props.showMaxDivControls){
            // when RC has started and annotation has started
            //this.props.onClickAction({action: 'remoteCapture'})//MB2-489
            printConsole("Both RC and Annotation is on");
            this.props.maxDivActionGoingOn({flag: true});
            annotateShapesArray[8] = annotateShapesArray[0]; //TP-2491
            if (this.props.maxView && this.props.maxView === true) {
                this.setAnnotationButtons();//set the annotations color and shapes button
            }
        }else if((this.props.bAnnotate || this.props.bRemoteCapture) && this.props.showMaxDivControls){
            // when only annotation or when RC just started but no annotation has started
            //this.onClickAnnotate();//MB2-489
            printConsole("Only Annotation/RC is ongoing");
            this.props.maxDivActionGoingOn({flag: true});
            annotateShapesArray[8] = annotateShapesArray[0]; //TP-2491
            if (this.props.maxView && this.props.maxView === true) {
                this.setAnnotationButtons();//set the annotations color and shapes button
            }
        }else if (this.props.bAnnotate || this.props.bRemoteCapture && !this.props.showMaxDivControls) {
            // T32-495-- when only annotation or when RC just started but no annottaion has started
            // in the new Passive expert side
            printConsole("Only Annotation/RC is ongoing in Passive Expert side")
            if (this.props.maxView && this.props.maxView === true) {
                const sketchPicker = document.getElementById('sketchPicker');
                const shapeselector = document.getElementById('shapeselector');
                this.setState({
                    bSelectColor: false,
                    bSelectShape: false
                });
                if(shapeselector) shapeselector.style.display = 'none';
                if(sketchPicker) sketchPicker.style.display = 'none';                
            }
        } 
        printConsole(` bZoomClick: ${this.state.bZoomClick} && bRemoteCapture: ${this.state.bRemoteCapture} && showMaxControls: ${this.props.showMaxDivControls}`);
        /** For Zoom feature button sync between Multi-experts during an un-planned transfer of controls */
        if (this.state.bZoomClick && !this.state.bRemoteCapture && this.props.showMaxDivControls) {
            // T32-495 -- Showing the Zoom level selector div 
            printConsole("Zoom button is turned on when Max-div controls got changed..");
            const zoomSelector = document.getElementById('zoomlevelselector');
            zoomSelector.style.display = 'block';
        } else if (this.state.bZoomClick && this.state.bRemoteCapture && this.props.showMaxDivControls) {
            // TP-445 -- Hiding the Zoom level selector Div if Remote Capture is turned on
            printConsole("Zoom button is turned on when Max-div controls got changed..");
            printConsole("Since RC was also turned on so hiding the Zoom level selector");
            const zoomSelector = document.getElementById('zoomlevelselector');
            zoomSelector.style.display = 'none';
        }
        /* if(this.props.startRecording && !this.props.showMaxDivControls){
            // T32-495-- Closing the Screen Recording when the Primary Expert has changed
            this.props.onClickRecordScreen()
        } */
        if (this.props.showMaxDivControls) {
            this.handlesilhoutteeIcon(false);
        }
    }

    handleExpertSSButton = () => {
        this.setState({bHideExpertSSButton: this.props.bHideExpertSSButton})
    }

    handleMaxDivAnnotation = () => {
        const { bRemoteCapture, bShareAsset, bZoomClick, bTorchClick, bMaxChat, startRecording } = this.state;
        const isBusy = (bRemoteCapture || bShareAsset || /* bZoomClick || bTorchClick || */ bMaxChat /* || startRecording */ || this.props.isMaxDivSS);//T32-580
        printConsole(`current bAnnotate flag value: ${this.props.bAnnotate}`);
        this.setState({bAnnotate: this.props.bAnnotate},()=>{
            printConsole(`set bAnnotate flag as ${this.state.bAnnotate}`);
            if (this.props.user.isexpert){
                if (this.state.bAnnotate === false){
                    printConsole("bAnnotate flag is false");
                    annotateShapesArray[8] = annotateShapesArray[0]; //TP-2491
                    this.setState({
                        bSelectColor: false,
                        bSelectShape: false,
                        pencolor: "#ff0000",
                        isFreeDrawing: false, /**TP-2586 */
                        isTextActive: false,//TP-4695
                        isRectActive: false,
                        isCircleActive: false,
                        isArrowActive: false,
                        isPointerActive: false, //TP-2491
                        isErasorActive: false, //TP-2586
                        isUndoActive: false //TP-4696
                    })
                } else if (this.props.maxView && this.props.maxView === true) {
                    printConsole("bAnnotate flag is true");
                    this.setAnnotationButtons();
                    //TP-5065 -- Only reshow the Shape picker element if Share Asset is not ongoing
                    if (!this.state.bShareAsset) {
                        //TP-2491
                        const shapeselector = document.getElementById('shapeselector');
                        if (shapeselector && this.state.showHideMaxDivControl) {
                            shapeselector.style.display = 'block';
                        }
                    }
                }
                !isBusy && this.props.maxDivActionGoingOn({flag: this.state.bAnnotate})// T32-580
                this.state.bMaxChat && this.props.closeChat();// close chat when RC going on MB2-471
                //TP-2823
                if (!this.props.groupInfo.annotation_shape_enabled && this.state.bAnnotate && this.state.showHideMaxDivControl) {
                    //TP-2959 -- Only start showing the Annotation canvas loader 
                    //if the Expert's screen isn't being resized while RC/Annotation
                    if (this.props.onResizeWAnnotate === false) {
                        this.props.updateAnnotateLoader(true);//TP-2853
                        this.annTimer = setTimeout(() => {
                            this.onClickCrossHairsIcon(); 
                            clearTimeout(this.annTimer);
                        }, 2000);
                    } else {
                        this.props.updateResizeWAnnotate(false); //TP-2959
                    }
                }
            }
        });//MB2-507
    }

    // TP-377 -- Set the Pencolor & Shape Indicator for Passive experts
    handleAnnotationColorOrShapes = () => {
        if (this.props.user.isexpert){
            printConsole(`bAnnotate flag is ${this.state.bAnnotate}`);
            printConsole(`set the pencolor: ${this.props.pencolor}`);
            if (this.props.maxView && this.props.maxView === true) {
                this.setAnnotationButtons();
            }
            this.setState({ pencolor: this.props.pencolor }, () => {
                printConsole("Handle the Annotation Color & Shape .....");
                printConsole(this.props.shapesSetting);
                if (this.props.shapesSetting !== '') {
                    this.setState({
                        isFreeDrawing: this.props.shapesSetting.isFreeDrawing,
                        isTextActive: this.props.shapesSetting.isTextActive, //TP-4695
                        isRectActive: this.props.shapesSetting.isRectActive,
                        isCircleActive: this.props.shapesSetting.isCircleActive,
                        isArrowActive: this.props.shapesSetting.isArrowActive,
                        isPointerActive: this.props.shapesSetting.isPointerActive, /*TP-2491 */
                        isErasorActive: this.props.shapesSetting.isErasorActive, /**TP-2586 */
                        isUndoActive: this.props.shapesSetting.isUndoActive /**TP-4696 */
                    }, () => {
                        if (this.state.isFreeDrawing) annotateShapesArray[8] = annotateShapesArray[0];
                        else if (this.state.isTextActive) annotateShapesArray[8] = annotateShapesArray[6]; /**TP-4695*/
                        else if (this.state.isRectActive) annotateShapesArray[8] = annotateShapesArray[2];
                        else if (this.state.isCircleActive) annotateShapesArray[8] = annotateShapesArray[1];
                        else if (this.state.isArrowActive) annotateShapesArray[8] = annotateShapesArray[3];
                        else if (this.state.isPointerActive) annotateShapesArray[8] = annotateShapesArray[4]; /*TP-2491 */
                        else annotateShapesArray[8] = annotateShapesArray[0]; //TP-2586
                    })
                } else {
                    this.setState({
                        isFreeDrawing: false, /**TP-2667*/
                        isTextActive: false, /**TP-4695*/
                        isRectActive: false,
                        isCircleActive: false,
                        isArrowActive: false,
                        isPointerActive: false, /*TP-2491 */
                        isErasorActive: false, /**TP-2586 */
                        isUndoActive: false /**TP-4696*/
                    }, () => {
                        if (this.state.isFreeDrawing) annotateShapesArray[8] = annotateShapesArray[0];
                        else if (this.state.isTextActive) annotateShapesArray[8] = annotateShapesArray[6]; /**TP-4695*/
                        else if (this.state.isRectActive) annotateShapesArray[8] = annotateShapesArray[2];
                        else if (this.state.isCircleActive) annotateShapesArray[8] = annotateShapesArray[1];
                        else if (this.state.isArrowActive) annotateShapesArray[8] = annotateShapesArray[3];
                        else if (this.state.isPointerActive) annotateShapesArray[8] = annotateShapesArray[4]; /*TP-2491 */
                    })
                }
            })
        }
    }

    /**TP-2597 -- Handling of Primary expert's ShapePicker icons 
    * to set to default after RC is clicked while Annotate is turned on */
    handleAnnotateShapeWhenRCClicked = () => {
        if (this.props.user.isexpert){
            printConsole(`bAnnotate flag is ${this.state.bAnnotate}`);
            printConsole(`set the pencolor: ${this.props.pencolor}`);
            /* this.setState({ pencolor: this.props.pencolor }, () => { */
                printConsole("RC is turned on so handle the Annotation Color & Shape .....");
                printConsole(this.props.shapesSetting);
                if (this.props.shapesSetting === '') {                    
                    this.setState({
                        isFreeDrawing: false,
                        isTextActive: false, /**TP-4695*/
                        isRectActive: false,
                        isCircleActive: false,
                        isArrowActive: false,
                        isPointerActive: false, /*TP-2491 */
                        isErasorActive: false, /**TP-2586 */
                        isUndoActive: false /**TP-4696*/
                    }, () => {
                        printConsole(`is FreeDrawing ------------> ${this.state.isFreeDrawing}`);
                        if (this.state.isFreeDrawing) annotateShapesArray[8] = annotateShapesArray[0];
                        else if (this.state.isTextActive) annotateShapesArray[8] = annotateShapesArray[6]; /**TP-4695*/
                        else if (this.state.isRectActive) annotateShapesArray[8] = annotateShapesArray[2];
                        else if (this.state.isCircleActive) annotateShapesArray[8] = annotateShapesArray[1];
                        else if (this.state.isArrowActive) annotateShapesArray[8] = annotateShapesArray[3];
                        else if (this.state.isPointerActive) annotateShapesArray[8] = annotateShapesArray[4]; /*TP-2491 */
                    })
                }
            //})
        }
    }

    handleRecording = () => {
        const { bAnnotate, bRemoteCapture, bShareAsset, bZoomClick, bTorchClick, bMaxChat } = this.state;
        // expertShareScreenMaxDiv is enabled for sid bar ss also so considering only isMaxDivSS
        const isBusy = (bAnnotate || bRemoteCapture || bShareAsset || /* bZoomClick || bTorchClick || */ bMaxChat || this.props.isMaxDivSS);//MB2-731
        this.setState({startRecording: this.props.startRecording}, () => {
            //!isBusy && this.props.maxDivActionGoingOn({flag: this.state.startRecording});//to stop switching max div
        })
    }

    onClickMuteSpeaker = () => {
        this.setState((prevState) => ({bRemoteMute: !prevState.bRemoteMute}),() =>{
            this.props.onclickMuteSpeakerMaxDiv({flag: !this.state.bRemoteMute})
        })
    }

    onClickMuteMike = () => {
        this.setState((prevState) => ({muteMikeForMaxDiv: !prevState.muteMikeForMaxDiv}),() =>{
            this.props.onclickMuteMikeMaxDiv({flag: !this.state.muteMikeForMaxDiv })
        })
    }

    onClickUpload = () => {
        const { bZoomClick, bTorchClick, bAnnotate, bShareAsset, zoom_level, startRecording, bMaxChat, expertShareScreenMaxDiv } = this.state;
        const {maxDivParticipant, aspectRatio, participants} = this.props;
        // const isBusy = (bZoomClick || bTorchClick || startRecording || bMaxChat || expertShareScreenMaxDiv);//MB2-731
        //MB2-219
        // this.setState((prevState) => ({bShareAsset: !prevState.bShareAsset}),() =>{
            // !isBusy && this.props.maxDivActionGoingOn({flag:this.state.bShareAsset})
            // this.state.bShareAsset && this.props.openFileUpload(this.props.maxDivParticipant.stream_id)
            if (bShareAsset) return;
            else {
                const allExperts = participants.filter(p => p.isexpert); 
                allExperts && allExperts.forEach((e) => {
                    this.sessionSignal.sendSignalSyncExperts({
                        show_grid: false,
                        max_view_technician: maxDivParticipant.email, //TP-3893
                        ratio: aspectRatio ? aspectRatio : "16:9",
                        annotation_active: bAnnotate,//TP-2443    
                        torch_button_active: bTorchClick,
                        zoom_button_active: (parseInt(zoom_level) > 1) ? true : false,
                        zoom_level: parseInt(zoom_level),
                        hand_icon_enabled: true,            
                        header_fileShare_active: false, //TP-2378   
                        reason: "show_action"},  
                    e.email);                        
                });
                //TP-3551 & TP-3114 -- New signal to sync up all the Technician's Views with the Expert's when CM is enabled
                /*TP-4684 TP-4683 if (this.props.sessionType === true) {
                    //TP-3119
                    let technicians = participants.filter(p => !p.isexpert);
                    technicians && technicians.forEach(t => {
                        if (t.email === maxDivParticipant.email) {
                            printConsole(`onClickUpload sending sendSignalCurrentMaxViewUser===========> ${maxDivParticipant.email} to ${t.email}`);
                            this.sessionSignal.sendSignalCurrentMaxViewUser({
                                is_grid: false,
                                max_view_user: (!maxDivParticipant.isexpert) ? maxDivParticipant.email : ''},
                            t.email)
                        }
                    })
                } */
                this.props.openFileUpload(this.props.maxDivParticipant.stream_id)
            }
        // })
    }

    setAnnotationButtons = () => {
        const { annotation_color_enabled, annotation_shape_enabled, annotation_pointer_enabled } = this.props.groupInfo;
        if (annotation_color_enabled) this.setState({ bHideAnnotateColour: false });
        if (annotation_shape_enabled) this.setState({ bHideAnnotateShapes: false });
        if (annotation_pointer_enabled === false) this.setState({ bHideAnnotationPointer: true });//TP-2528
        else this.setState({bHideAnnotationPointer: false}); //TP-2528
    }

    onClickAnnotate = (isResetMaxDiv = false, isResetPointerMaxDiv = false /**TP-5009*/) => {
        printConsole("Clicked Annotate");
        const {isPointerActive} = this.state;
        if (this.state.bRemoteCapture && this.state.bAnnotate === true){
            // TP-905 --  Handling of Zoom level panel when RC is turned off by clicking on Annotate button
            const zoomSelector = document.getElementById('zoomlevelselector');
            if (parseInt(this.state.zoom_level) > 1 && zoomSelector) {
                zoomSelector.style.display = 'block';
            } else if (parseInt(this.state.zoom_level) === 1 && zoomSelector && this.state.bZoomClick) {
                this.setState({bZoomClick: false}) // Make the Zoom button inactive
            }
        }
        const {bZoomClick, bTorchClick, startRecording, bShareAsset } = this.state;
        const {maxDivParticipant, participants, isMaxDivSS, aspectRatio, b} = this.props;
        // expertShareScreenMaxDiv is enabled for side bar ss also so considering only isMaxDivSS
        // TP-2100 -- check if Asset share is ongoing when changing the Grid View Icon state while Annotate is clicked
        const isBusy = (/* bZoomClick || bTorchClick || startRecording || */ bShareAsset || isMaxDivSS);//MB2-731
        const allExperts = participants.filter(p => p.isexpert); 
        this.setState((prevState) => ({
            bRemoteCapture: false,
            bSelectColor: false,
            bSelectShape: false,
            pencolor: "#ff0000",
            isFreeDrawing: false, /**TP-2586 */
            isTextActive: false, /**TP-4695*/
            isRectActive: false,
            isCircleActive: false,
            isArrowActive: false,
            isPointerActive: false, /*TP-2491 */
            isUndoActive: false /**TP-4696 */
        }),() => {
            annotateShapesArray[8] = annotateShapesArray[0]; /*TP-2491 */
            if (this.state.bAnnotate === false) {
                //TP-3551 & TP-3114 -- New signal to sync up all the Technician's Views with the Expert's when CM is enabled
                /*TP-4684 TP-4683 if (this.props.sessionType === true) {
                    //TP-3119
                    let technicians = participants.filter(p => !p.isexpert);
                    technicians && technicians.forEach(t => {
                        if (t.email === maxDivParticipant.email) {
                            printConsole(`onClickAnnotate sending sendSignalCurrentMaxViewUser===========> ${maxDivParticipant.email} to ${t.email}`);
                            this.sessionSignal.sendSignalCurrentMaxViewUser({
                                is_grid: false,
                                max_view_user: (!maxDivParticipant.isexpert) ? maxDivParticipant.email : ''},
                            t.email)
                        }
                    })
                } */
            }
            if (this.props.bAnnotate && this.props.expertShareScreenMaxDiv) {
                //When annotation is ongoing while in Screencast Mode, if Passive becomes Primary
                //then clear out the Annotation feature related works
                this.props.clearAnnotationButtons(); //TP-4954
            } else if (this.state.bAnnotate && isPointerActive){
                //TP-5009
                if (isResetPointerMaxDiv === true) {
                    this.props.onClickAction({action: 'telestrate'});
                    // TP-3845 -- we are sending the bAnnotate because state has been already changed by now
                    !isBusy && this.props.maxDivActionGoingOn({flag:this.state.bAnnotate})//Mb2-507,bAnnotate will be set to true/false in handleMaxDivAnnotation
                    // doing this in handleMaxDivAnnotation because the state change is happening after handleMaxChatClicked
                    // this.state.bMaxChat && this.props.closeChat();// close chat when annotation going on MB2-471
                    // T32-592 -- Syncing of the Annotation Button active/inactive state of the Primary expert
                    // with all other Passive experts in that session
                    const {zoom_level, bShareAsset} = this.state;
                    //console.log("Triggering Sync_expert from within onClickAnnotate", this.state.bAnnotate);
                    allExperts && allExperts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: maxDivParticipant.email, //TP-3893
                            ratio: aspectRatio ? aspectRatio : "16:9",
                            annotation_active: (this.props.enable_user_video === "my_camera_disabled") ? false : this.state.bAnnotate, /**TP-5501*/
                            torch_button_active: this.state.bTorchClick,
                            zoom_button_active: (parseInt(zoom_level) > 1) ? true : false,
                            zoom_level: parseInt(zoom_level),
                            hand_icon_enabled: true,               
                            header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"},  
                        e.email);                        
                    });
                } else {
                    this.props.onClickSetShapes({shapesSetting: {
                        isPointerActive: false,
                        isArrowActive: false,
                        isFreeDrawing: false,
                        isTextActive: false, /**TP-4695 */
                        isRectActive: false,
                        isCircleActive: false,
                        isErasorActive: false,
                        isUndoActive: false /**TP-4696 */
                    }, bSelectShape: true, pencolor: this.state.pencolor }) 
                    //TP-4497
                    if (isResetMaxDiv === false)            
                        this.clearAnnotateTimer = setTimeout(() => {
                            this.props.onClickAction({action: 'telestrate'});                    
                            clearTimeout(this.clearAnnotateTimer);
                        }, 200);
                    this.expertSyncTimer = setTimeout(() => {
                        // TP-3845 -- we are sending the bAnnotate because state has been already changed by now
                        !isBusy && this.props.maxDivActionGoingOn({flag:this.state.bAnnotate})//Mb2-507,bAnnotate will be set to true/false in handleMaxDivAnnotation
                        // doing this in handleMaxDivAnnotation because the state change is happening after handleMaxChatClicked
                        // this.state.bMaxChat && this.props.closeChat();// close chat when annotation going on MB2-471
                        // T32-592 -- Syncing of the Annotation Button active/inactive state of the Primary expert
                        // with all other Passive experts in that session
                        const {zoom_level, bShareAsset} = this.state;
                        //console.log("ttt Sync_expert from within onClickAnnotate", this.state.bAnnotate);
                        allExperts && allExperts.forEach((e) => {
                            this.sessionSignal.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: maxDivParticipant.email, //TP-3893
                                ratio: aspectRatio ? aspectRatio : "16:9",
                                annotation_active: this.state.bAnnotate,     
                                torch_button_active: this.state.bTorchClick,
                                zoom_button_active: (parseInt(zoom_level) > 1) ? true : false,
                                zoom_level: parseInt(zoom_level),
                                hand_icon_enabled: true,               
                                header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                reason: "show_action"},  
                            e.email);                        
                        });
                        clearTimeout(this.expertSyncTimer);
                    }, 500);
                }
            } else {
                this.props.onClickAction({action: 'telestrate'});
                // we are sending the !bAnnotate because state has not changed yet
                !isBusy && this.props.maxDivActionGoingOn({flag:!this.state.bAnnotate})//Mb2-507,bAnnotate will be set to true/false in handleMaxDivAnnotation
                // doing this in handleMaxDivAnnotation because the state change is happening after handleMaxChatClicked
                // this.state.bMaxChat && this.props.closeChat();// close chat when annotation going on MB2-471
                // T32-592 -- Syncing of the Annotation Button active/inactive state of the Primary expert
                // with all other Passive experts in that session
                const {zoom_level, bShareAsset} = this.state;
                //console.log("sent Sync_expert from within onClickAnnotate", this.state.bAnnotate);
                allExperts && allExperts.forEach((e) => {
                    this.sessionSignal.sendSignalSyncExperts({
                        show_grid: false,
                        max_view_technician: maxDivParticipant.email, //TP-3893
                        ratio: aspectRatio ? aspectRatio : "16:9",
                        annotation_active: !this.state.bAnnotate,     
                        torch_button_active: this.state.bTorchClick,
                        zoom_button_active: (parseInt(zoom_level) > 1) ? true : false,
                        zoom_level: parseInt(zoom_level),
                        hand_icon_enabled: true,               
                        header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                        reason: "show_action"},  
                    e.email);                        
                });
            }
            
            //TP-2823
            if (!this.props.groupInfo.annotation_shape_enabled && !this.state.bAnnotate) {
                this.annTimer = setTimeout(() => {
                    this.props.updateAnnotateLoader(true); //TP-2853
                    this.onClickCrossHairsIcon(); 
                    clearTimeout(this.annTimer);
                }, 2000);
            }
        })
    }

    onClickCamera = (isResetMaxDiv = false, isResetPointerMaxDiv = false /**TP-5489*/) => {
        printConsole(`On click camera button`)//TP-4542
        const {bZoomClick, bTorchClick, startRecording, isPointerActive } = this.state;
        const {maxDivParticipant, participants} = this.props;
        // expertShareScreenMaxDiv is enabled for sid bar ss also so considering only isMaxDivSS
        const isBusy = (/* bZoomClick || bTorchClick || startRecording || */ this.props.isMaxDivSS);//MB2-731
        this.setState((prevState) => ({
            bRemoteCapture: !prevState.bRemoteCapture,
            bSelectColor: false,
            bSelectShape: false,
            pencolor: "#ff0000",
            isFreeDrawing: false, /**TP-2586 */
            isTextActive: false, /**TP-4695 */
            isRectActive: false,
            isCircleActive: false,
            isArrowActive: false,
            isPointerActive: false, /*TP-2491 */
            isUndoActive: false /**TP-4696 */
        }),() => {
            if (this.state.bRemoteCapture) {
                const zoomSelector = document.getElementById('zoomlevelselector');
                if (this.state.bZoomClick && zoomSelector) {
                    zoomSelector.style.display = 'none';
                }
                //TP-3551 & TP-3114 -- New signal to sync up all the Technician's Views with the Expert's when CM is enabled
                if (this.props.sessionType === true) {
                    //TP-3119
                    /*TP-4684 TP-4683 let technicians = participants.filter(p => !p.isexpert);
                    technicians && technicians.forEach(t => {
                        if (t.email === maxDivParticipant.email) {
                            printConsole(`onClickCamera sending sendSignalCurrentMaxViewUser===========> ${maxDivParticipant.email} to ${t.email}`);
                            this.sessionSignal.sendSignalCurrentMaxViewUser({
                                is_grid: false,
                                max_view_user: (!maxDivParticipant.isexpert) ? maxDivParticipant.email : ''},
                            t.email)
                        }
                    }) */
                }
            } else {
                const zoomSelector = document.getElementById('zoomlevelselector');
                if (parseInt(this.state.zoom_level) > 1 && zoomSelector) {
                    zoomSelector.style.display = 'block';
                } else if (parseInt(this.state.zoom_level) === 1 && zoomSelector && this.state.bZoomClick) {
                    this.setState({bZoomClick: false}) // Make the Zoom button inactive
                }
            }
            annotateShapesArray[8] = annotateShapesArray[0]; //TP-2491
            if (!this.state.bRemoteCapture && isPointerActive){
                //TP-5489 & TP-5501
                if (isResetPointerMaxDiv === true) {
                    this.props.onClickAction({action: 'remoteCapture'});
                    // TP-3845 -- we are sending the bAnnotate because state has been already changed by now
                    !isBusy && this.props.maxDivActionGoingOn({flag:this.state.bAnnotate})//Mb2-507,bAnnotate will be set to true/false in handleMaxDivAnnotation                    
                } else {
                    this.props.onClickSetShapes({shapesSetting: {
                        isPointerActive: false,
                        isArrowActive: false,
                        isFreeDrawing: false,
                        isTextActive: false, /**TP-4695*/
                        isRectActive: false,
                        isCircleActive: false,
                        isErasorActive: false,
                        isUndoActive: false /**TP-4696*/
                    }, bSelectShape: true, pencolor: this.state.pencolor }) 
                    //TP-4542
                    if (isResetMaxDiv === false)            
                        this.clearAnnotateTimer = setTimeout(() => {
                            this.props.onClickAction({action: 'remoteCapture'});                  
                            clearTimeout(this.clearAnnotateTimer);
                        }, 200);
                }
            } else
                this.props.onClickAction({action: 'remoteCapture'});
            !isBusy && this.props.maxDivActionGoingOn({flag:this.state.bRemoteCapture})
            // doing this in handleMaxDivAnnotation because the state change is happening after handleMaxChatClicked
            // this.state.bMaxChat && this.props.closeChat();// close chat when RC going on MB2-471
            //TP-2823
            if (!this.props.groupInfo.annotation_shape_enabled && this.state.bRemoteCapture) {
                this.annTimer = setTimeout(() => {
                    this.props.updateAnnotateLoader(true);
                    this.onClickCrossHairsIcon(); 
                    clearTimeout(this.annTimer);
                }, 4000);
            }
        })
    }

    onClickStartLocalRc = ({flag}) => {
        printConsole(`on click local capture button`);//TP-4542
        const {bZoomClick, bTorchClick, startRecording, showHideMaxDivControl } = this.state;
        if (showHideMaxDivControl === true) {
            // expertShareScreenMaxDiv is enabled for sid bar ss also so considering only isMaxDivSS
            const isBusy = (/* bZoomClick || bTorchClick || startRecording || */ this.props.isMaxDivSS);//MB2-731
            this.setState((prevState) => ({
                bRemoteCapture: flag,
                bSelectColor: false,
                bSelectShape: false,
                pencolor: "#ff0000",
                isFreeDrawing: false, /**TP-2586 */
                isTextActive: false, /**TP-4695 */
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false,
                isPointerActive: false, //TP-2491
                isUndoActive: false /**TP-4696 */
            }),() => {
                this.props.onClickAction({action: 'remoteCapture'})
                !isBusy && this.props.maxDivActionGoingOn({flag})
                this.state.bMaxChat && this.props.closeChat();// close chat when LC going on MB2-471
                //TP-1181:Rs Added below condition to avoid show of zoom selector div when Rc is enable
                const zoomSelector = document.getElementById('zoomlevelselector');
                if(this.state.bZoomClick && this.state.bRemoteCapture){
                    if(zoomSelector && zoomSelector.style.display !=='none')
                        zoomSelector.style.display = 'none' 
                }else{
                    if(parseInt(this.state.zoom_level) >1 && zoomSelector  && zoomSelector.style.display !=='block'){
                        zoomSelector.style.display = 'block';
                    }else if (parseInt(this.state.zoom_level) === 1 && zoomSelector && this.state.bZoomClick){
                        this.setState({bZoomClick:false})
                    }
                }
                //TP-2823
                if (!this.props.groupInfo.annotation_shape_enabled && this.state.bRemoteCapture) {
                    this.annTimer = setTimeout(() => {
                        this.onClickCrossHairsIcon(); 
                        clearTimeout(this.annTimer);
                    }, 4000);
                }
            })
        }
    }

    onClickexpertRcSignal = () => {
        this.setState((prevState) => ({
            bRemoteCapture: !prevState.bRemoteCapture,
        }),() => {
            this.props.onClickAction({action: 'localCapture'});            
        })
    }

    // NS2-258
    onClickRectIcon = () => {
        const { isRectActive, isPointerActive, isErasorActive, isUndoActive /**TP-4696 */ } = this.state;
        if (isRectActive === false) {
            annotateShapesArray[8] = annotateShapesArray[2];
            this.setState({
                isFreeDrawing: false,
                isTextActive: false, //TP-4695
                isRectActive: true,
                isCircleActive: false,
                isArrowActive: false,
                isPointerActive: false, /**TP-2586 & TP-2491*/
                isUndoActive: false /**TP-4696 */
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isFreeDrawing: false,
                    isTextActive: false, //TP-4695
                    isRectActive: true,
                    isCircleActive: false,
                    isArrowActive: false,
                    isPointerActive: false, /**TP-2586 & TP-2491 */
                    isErasorActive,
                    isUndoActive /**TP-4696 */
                }, bSelectShape: true, pencolor: this.state.pencolor })
            });
        }
    }

    onClickCircleIcon = () => {
        const { isCircleActive, isPointerActive, isErasorActive, isUndoActive /**TP-4696*/ } = this.state;
        if (isCircleActive === false) {
            annotateShapesArray[8] = annotateShapesArray[1]; //TP-2491
            this.setState({
                isFreeDrawing: false,
                isTextActive: false, //TP-4695
                isRectActive: false,
                isCircleActive: true,
                isArrowActive: false,
                isPointerActive: false, /**TP-2586 & TP-2491 */
                isUndoActive: false /**TP-4696*/
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isFreeDrawing: false,
                    isTextActive: false, /**TP-4695 */
                    isRectActive: false,
                    isCircleActive: true,
                    isArrowActive: false,
                    isPointerActive: false, /**TP-2586 & TP-2491 */
                    isErasorActive,
                    isUndoActive /**TP-4696 */
                }, bSelectShape: true, pencolor: this.state.pencolor })
            });
        }
    }
    
    onClickCrossHairsIcon = () => {
        const { isFreeDrawing, isPointerActive, isErasorActive, isUndoActive, /**TP-4696*/ bAnnotate } = this.state;
        if (isFreeDrawing === false && bAnnotate === true) {
            annotateShapesArray[8] = annotateShapesArray[0]; //TP-2491
            this.setState({
                isFreeDrawing: true,
                isTextActive: false, //TP-4695
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false,
                isPointerActive: false, /**TP-2586 & TP-2491 */
                isUndoActive: false /**TP-4696 */
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isFreeDrawing: true,
                    isTextActive: false, //TP-4695
                    isRectActive: false,
                    isCircleActive: false,
                    isArrowActive: false,
                    isPointerActive: false,  /**TP-2586 & TP-2491 */
                    isErasorActive,
                    isUndoActive /**TP-4696 */
                }, bSelectShape: true, pencolor: this.state.pencolor })
            });
        }
    }

    onClickArrowIcon = () => {
        const { isArrowActive, isPointerActive, isErasorActive, isUndoActive /**TP-4696 */ } = this.state;
        if (isArrowActive === false) {
            annotateShapesArray[8] = annotateShapesArray[3];//TP-2491
            this.setState({
                isArrowActive: true,
                isTextActive: false, //TP-4695
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: false,
                isPointerActive: false, /**TP-2586 & TP-2491 */
                isUndoActive: false /**TP-4696 */
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isArrowActive: true,
                    isTextActive: false, //TP-4695
                    isFreeDrawing: false,
                    isRectActive: false,
                    isCircleActive: false,
                    isPointerActive: false, /**TP-2586 & TP-2491 */
                    isErasorActive,
                    isUndoActive /**TP-4696*/
                }, bSelectShape: true, pencolor: this.state.pencolor })
            });
        }
    }

    //TP-2491 -- Onclick method for the Pointer Annotation type Icon
    onClickPointerIcon = () => {
        const { isPointerActive, isArrowActive, isFreeDrawing, isRectActive, isCircleActive, isErasorActive, isUndoActive } = this.state;
        if (isPointerActive === false) {
            //annotateShapesArray[8] = annotateShapesArray[4];
            /**TP-2586 -- The new Pointer Annotation feature */
            this.setState({
                isPointerActive: true,
                isTextActive: false, //TP-4695
                isArrowActive: false,
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: false                
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isPointerActive: true,
                    isTextActive: false, //TP-4695
                    isArrowActive: false,
                    isFreeDrawing: false,
                    isRectActive: false,
                    isCircleActive: false,
                    isErasorActive: false,
                    isUndoActive: false, /**TP-4696 */
                }, bSelectShape: true, pencolor: this.state.pencolor })
            });
        } /* else {
            printConsole("Turning off the Pointer Annotation")
            this.setState({
                isPointerActive: false                
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isPointerActive: false,
                    isArrowActive,
                    isFreeDrawing,
                    isRectActive,
                    isCircleActive,
                    isErasorActive,
                    isUndoActive, //TP-4695
                }, bSelectShape: true, pencolor: this.state.pencolor })
            });
        } */
    }

    //TP-2474
    onClickErasorIcon = () => {
        const { isErasorActive, isPointerActive, isArrowActive, isFreeDrawing, isRectActive, isCircleActive } = this.state;
        if (isErasorActive === false) {
            annotateShapesArray[8] = annotateShapesArray[5];
            this.setState({
                isErasorActive: true                
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isTextActive: false, //TP-4695
                    isPointerActive: false,
                    isArrowActive: false,
                    isFreeDrawing: false, /**TP-2586 */
                    isRectActive: false,
                    isCircleActive: false,
                    isUndoActive: false, /**TP-4696 */
                    isErasorActive: true
                }, bSelectShape: true, pencolor: this.state.pencolor })
                this.setState({ isErasorActive: false }, () => {
                    this.props.onClickSetShapes({shapesSetting: {
                        isTextActive: false, //TP-4695
                        isPointerActive: false,
                        isArrowActive: false,
                        isFreeDrawing: false, /**TP-2586 */
                        isRectActive: false,
                        isCircleActive: false,
                        isUndoActive: false, /**TP-4696 */
                        isErasorActive: false
                    }, bSelectShape: true, pencolor: this.state.pencolor })
                })
            });
        }
    }

    //TP-4696
    onClickUndoIcon = () => {
        const { isPointerActive, isArrowActive, isFreeDrawing, isRectActive, isCircleActive, isErasorActive, isUndoActive, isTextActive } = this.state;
        if (isUndoActive === false) {
            annotateShapesArray[8] = annotateShapesArray[7];
            this.setState({
                isUndoActive: true                
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isTextActive,
                    isPointerActive,
                    isArrowActive,
                    isFreeDrawing, /**TP-2586 */
                    isRectActive,
                    isCircleActive,
                    isErasorActive: false,
                    isUndoActive: true
                }, bSelectShape: true, pencolor: this.state.pencolor })
                this.setState({ isUndoActive: false }, () => {
                    this.props.onClickSetShapes({shapesSetting: {
                        isTextActive,
                        isPointerActive,
                        isArrowActive,
                        isFreeDrawing, /**TP-2586 */
                        isRectActive,
                        isCircleActive,
                        isErasorActive: false,
                        isUndoActive: false
                    }, bSelectShape: true, pencolor: this.state.pencolor })
                })
            });
        }
    }

    onClickTextIcon = () => {
        const { isPointerActive, isArrowActive, isFreeDrawing, isRectActive, isCircleActive, isErasorActive, isUndoActive, isTextActive } = this.state;
        if (isTextActive === false) {
            annotateShapesArray[8] = annotateShapesArray[6];
            /**TP-2586 -- The new Pointer Annotation feature */
            this.setState({
                isTextActive: true,
                isPointerActive: false,
                isArrowActive: false,
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: false                
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isTextActive: true,
                    isPointerActive: false,
                    isArrowActive: false,
                    isFreeDrawing: false,
                    isRectActive: false,
                    isCircleActive: false,
                    isErasorActive: false,
                    isUndoActive: false,
                }, bSelectShape: true, pencolor: this.state.pencolor })
            });
        } else {
            //To make this Text annotation button a selectable even when in active state
            this.props.onClickSetShapes({shapesSetting: {
                isTextActive: false,
                isPointerActive: false,
                isArrowActive: false,
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: false,
                isErasorActive: false,
                isUndoActive: false,
            }, bSelectShape: true, pencolor: this.state.pencolor })
            this.props.onClickSetShapes({shapesSetting: {
                isTextActive: true,
                isPointerActive: false,
                isArrowActive: false,
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: false,
                isErasorActive: false,
                isUndoActive: false,
            }, bSelectShape: true, pencolor: this.state.pencolor })
        }
    }

    onClickShape = () => {
        const { bSelectShape } = this.state;
        const shapeselector = document.getElementById('shapeselector');
        const sketchPicker = document.getElementById('sketchPicker');
        if (bSelectShape === true) {
            this.setState({ bSelectShape: false });
            if(shapeselector) shapeselector.style.display = 'none';
        } else {
            this.setState({
                bSelectColor: false,
                bSelectShape: true
            });
            if(sketchPicker) sketchPicker.style.display = 'none';
            if(shapeselector) shapeselector.style.display = 'block';
        }
    }

    //NS2-217
    onClickPenIcon = () => {
        const { bSelectColor } = this.state;
        const {maxDivParticipant, maxView} = this.props;
        const sketchPicker = document.getElementById('sketchPicker');
        const shapeselector = document.getElementById('shapeselector');
        if (bSelectColor === true) {
            this.setState({ bSelectColor: false });
            if(sketchPicker) sketchPicker.style.display = 'none';
        } else {
            this.setState({
                bSelectColor: true,
                //bSelectShape: false //TP-2491
            }, () => {
                //TP-4816 -- Adjusting the position of the colorPicker element when the screen is 4:3 instead of 16:9
                const [skPicker] = document.getElementsByClassName("sketch-picker");
                if (skPicker && skPicker !== null && maxView) {
                    if (maxDivParticipant && maxDivParticipant.aspect_ratio === "4:3") {
                        skPicker.style.right = (window.innerWidth > 1300) ? '58%' : '50%';
                    }
                }
            });
            // TP-2491 -- Commented out this code for changes done for this Jira ticket
            //if(shapeselector) shapeselector.style.display = 'none';
            if(sketchPicker) sketchPicker.style.display = 'block';
        }
    }

    processTooltip = (status, i18n, options = null) => {
        switch (status) {
            case "session.freehand":
                return i18n._(t`session.freehand`);
            case "session.circle":
                return i18n._(t`session.circle`);
            case "session.square":
                return i18n._(t`session.square`);
            case "session.arrow":
                return i18n._(t`session.arrow`);
            case "session.text":
                return i18n._(t`session.text`); /**TP-4695 */
            case "session.handIconDisabled":
                return i18n._({id: `session.handIconDisabled`, values: {role: options.expert}});
            case "session.expertControlsDisabled":
                return i18n._({id: `session.expertControlsDisabled`, values: {role: options.expert}});            
            default:
                break;
        }
    }

    processZoomLevels = (level, i18n) => {
        switch (level) {
            case "1X":
                return i18n._(t`1X`);
            case "2X":
                return i18n._(t`2X`);
            case "3X":
                return i18n._(t`3X`);
            case "4X":
                return i18n._(t`4X`);
            case "5X":
                return i18n._(t`5X`);
            default:
                break;
        }
    }

    // TP-2377 -- Store the Pencolor and Shape setting of the Primary expert when the Window is resized
    setBufferColorShapeAnnotate = () => {
        const { pencolor, isArrowActive, isFreeDrawing, /**TP-2491 */ isPointerActive, isRectActive, isCircleActive, isTextActive } = this.state;
        this.setState({ 
            oldPenColor: pencolor,
            oldArrowActive: isArrowActive,
            oldFreeDrawing: isFreeDrawing,
            oldTextActive: isTextActive, /**TP-4695 */
            oldRectActive: isRectActive,
            oldCircleActive: isCircleActive,
            oldPointerActive: isPointerActive //TP-2491
        })
    }

    // TP-2377 -- Set the Pencolor and Shape setting of the Primary expert when the canvas along with the
    // window has finished resizing
    setColorShapeIncaseResized = () => {
        const { oldPenColor, oldArrowActive, oldFreeDrawing, oldRectActive, oldCircleActive, oldTextActive, /*TP-2491*/oldPointerActive } = this.state;
        this.setState({ 
            pencolor: oldPenColor, 
            isArrowActive: oldArrowActive, 
            isFreeDrawing: oldFreeDrawing, 
            isTextActive: oldTextActive, //TP-4695
            isRectActive: oldRectActive, 
            isCircleActive: oldCircleActive,
            isPointerActive: oldPointerActive, /**TP-2491 */
            isErasorActive: false, //TP-2600
            isUndoActive: false //TP-4696
        }, () => {
            this.props.onClickSetShapes({shapesSetting: {
                isArrowActive: oldArrowActive,
                isFreeDrawing: oldFreeDrawing,
                isTextActive: oldTextActive, //TP-4695
                isRectActive: oldRectActive,
                isCircleActive: oldCircleActive,
                isPointerActive: oldPointerActive,
                isErasorActive: false, //TP-2600
                isUndoActive: false //TP-4695
            }, bSelectShape: true, pencolor: oldPenColor })
            if (oldArrowActive === true) 
                annotateShapesArray[8] = annotateShapesArray[3];
            else if (oldFreeDrawing === true) 
                annotateShapesArray[8] = annotateShapesArray[0];
            else if (oldRectActive === true) 
                annotateShapesArray[8] = annotateShapesArray[1];
            else if (oldCircleActive === true) 
                annotateShapesArray[8] = annotateShapesArray[2];    
            else if (oldPointerActive === true)
                annotateShapesArray[8] = annotateShapesArray[4]; //TP-2491
            else if (oldTextActive === true)
                annotateShapesArray[8] = annotateShapesArray[6];//TP-4695
            this.setState({ oldPenColor: '', oldArrowActive: null, oldFreeDrawing: null, oldRectActive: null, oldCircleActive: null, oldPointerActive: null, oldTextActive: null /**TP-4695 */ });
        })
    }

    handleChangeComplete = (color) => {
        this.setState({ pencolor: color.hex }, () => {
            this.props.onClickSetShapes({shapesSetting: {
                isArrowActive: this.state.isArrowActive,
                isFreeDrawing: this.state.isFreeDrawing,
                isTextActive: this.state.isTextActive, /**TP-4695 */
                isRectActive: this.state.isRectActive,
                isCircleActive: this.state.isCircleActive,
                isPointerActive: this.state.isPointerActive, /**TP-2491 */
                isErasorActive: this.state.isErasorActive, /**TP-2474 */
                isUndoActive: this.state.isUndoActive /**TP-4696 */
            }, bSelectShape: true, pencolor: this.state.pencolor })
        });
    };

    onClickMaxChat = () => {
        this.props.onClickMaxChat(); //MB2-471
    }

    expertScreenShareClick = () => {
        //TP-2971  
        this.props.initMaxScreenShare(false);
    }

    onClickHandIcon = () => {
        const { clickHandSignal } = this.state;
        const { user, currentPrimaryExpert } = this.props;
        if(!clickHandSignal) {
            this.setState({ clickHandSignal: true }, () => {
                this.props.setClickHandSignal(true); //TP-1313
                const data = { 'firstName': user.firstname, 'lastName': user.lastname };
                currentPrimaryExpert && currentPrimaryExpert.email && this.sessionSignal.sendSignalRequestMaxDivControl(data, currentPrimaryExpert.email);
            })
        }
    }

    // handler for REQUEST_MAXDIV_CONTROL signal will be trigerred for primary expert
    processSignalRequestMaxDivControl = ({data, from}) => {
        const { bAnnotate, bRemoteCapture, bShareAsset, bZoomClick, bTorchClick, techShareScreen, startRecording } = this.state;
        // expertShareScreenMaxDiv is enabled for sid bar ss also so considering only isMaxDivSS
        // Allowing the Max-view controls transfer when some of the features are ongoing
        const isBusy = (/* bAnnotate || bRemoteCapture || bShareAsset || bZoomClick || bTorchClick ||*/ techShareScreen /*|| startRecording  || this.props.isMaxDivSS  */); // T32-495        
        // check whether guy has the max div control/is the primary expert
        if (this.state.showHideMaxDivControl){
            // if max div actions going on then ignore the request
            if (!isBusy) {
                this.setState({ showControlRequestDialog: true, expertFirstName: data.firstName,
                    expertLastName: data.lastName, /*TP-2292*/expertEmail: from.email, requestDialogTimer: 45, requestedUser: from
                },() => {
                    this.props.maxDivActionGoingOn({flag:true});
                    this.props.setRequestMaxViewControl(true);
                    /**TP-6037 & TP-5851*/
                    let allTechnicians = this.props.participants.filter(p => !p.isexpert); 
                    allTechnicians && allTechnicians.forEach(tech => {
                        tech.email && this.sessionSignal.sendSignalExpertYieldMaxviewControls(true, data.firstName+" "+data.lastName, tech.email);
                    })
                    from.email && this.sendSignalYieldDisableHandIcon({data: true, senderName:`${data.firstName} ${data.lastName}`, senderEmail: from.email});//Mb2-638
                    this.streamTimer = setInterval(this.decrementCounter.bind(this), 1000);
                });
            }else{
                from && from.email && this.sessionSignal.sendSignalYieldMaxDivControls(false,from.email);  // Deny the request for maxdiv controls                      
            }
        }
    }

    //TP-2292 -- Control the hide of Request Dialog box if the expert who has requested controls
    // goes out of session
    checkIfRequestDialogisOpen = () => {
        const {expertParticipantLeft} = this.props;
        if (this.state.expertEmail === expertParticipantLeft && this.state.showControlRequestDialog) {
            printConsole("Check whether the expert that left the call is the one that has asked for controls??");
            const { expertFirstName, expertLastName } = this.state;
            this.sendSignalYieldDisableHandIcon({data: false, senderName:`${expertFirstName} ${expertLastName}`, senderEmail: null});
            this.hideRequestDialog();
            //TP-1305
            const connData = expertFirstName+" "+expertLastName;
            this.props.triggerMessage(connData, "yieldpositive");
            this.props.setExpertParticipantLeft("");
        }
    }

    // request dialog for max div control request coming from passive expert
    requestMaxDivControlDialog = () => {
        const isMobileDevice = window.innerHeight <= 420 ? true : false; //TP-2966
        /**TP-2966 */
        if (isMobileDevice === true) {
            return (
                <>
                    <Modal id="request-dialog" size="sm" /**TP-2966 */ contentClassName={isMobileDevice === true ? "custom-modal-style": ""} isOpen={this.state.showControlRequestDialog} toggle={this.onClickDenyMaxDivControl}>
                        <ModalHeader toggle={this.onClickDenyMaxDivControl} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Control Request</Trans></ModalHeader>
                        <ModalBody className="d-flex justify-content-start align-items-center row">
                            <div className="col-sm-12">
                                {this.props.user.isexpert ?
                                    <Trans id="Maxdiv Control" values={{ 'firstName': `${this.state.expertFirstName}`, 'lastName': `${this.state.expertLastName}`, 'role': `${this.props.customerRoles.expert}` }}></Trans>
                                    : ''
                                }
                            </div>
                            <div className='row' style={{ width: '100%'}}>
                                <div className="col-sm-6 align-self-center">
                                    <span style={{ fontSize: "30px", fontWeight: "bold", paddingLeft: "20px" }}>
                                        {this.state.requestDialogTimer}
                                    </span>
                                </div>
                                <div className="col-sm-6">
                                    <div className='row'>
                                        <span className='col-sm-7'><Button size="sm" className="btn-r" style={{marginLeft: "86%"}} onClick={this.onClickDenyMaxDivControl}><Trans>No</Trans></Button></span>
                                        <span className='col-sm-4'><Button size="sm" className=" btn-g" onClick={this.onClickAcceptMaxDivControl}><Trans>Yes</Trans></Button></span>
                                    </div>
                                </div>
                            </div>
                            <audio autoPlay="true" loop="true" src="/beep.mp3" />
                        </ModalBody>
                    </Modal>
                </>
            );
        } else {
            return (
                <>
                    <Modal id="request-dialog" size="sm" /**TP-2966 */ contentClassName={isMobileDevice === true ? "custom-modal-style": ""} isOpen={this.state.showControlRequestDialog} toggle={this.onClickDenyMaxDivControl}>
                        <ModalHeader toggle={this.onClickDenyMaxDivControl} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Control Request</Trans></ModalHeader>
                        <ModalBody className="d-flex justify-content-start align-items-center row">
                            <div className="col-sm-12">
                                {this.props.user.isexpert ?
                                    <Trans id="Maxdiv Control" values={{ 'firstName': `${this.state.expertFirstName}`, 'lastName': `${this.state.expertLastName}` }}></Trans>
                                    : ''
                                }
                            </div>
                            <div className="col-md-6 align-self-center" style={{ fontSize: "40px", fontWeight: "bold" }}>
                                {this.state.requestDialogTimer}
                            </div>
    
                            <audio autoPlay="true" loop="true" src="/beep.mp3" />
                        </ModalBody>
                        <ModalFooter>
                            <div className="d-flex justify-content-end">
                                <Button size="sm" className="mr-1 btn-r" onClick={this.onClickDenyMaxDivControl}><Trans>No</Trans></Button>
                                <Button size="sm" className=" btn-g" onClick={this.onClickAcceptMaxDivControl}><Trans>Yes</Trans></Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                </>
            );
        }
    }

    //TP-2586
    closePointerWhileControlTransfer = () => {
        const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, isTextActive, /**TP-4695*/ isErasorActive, isUndoActive /**TP-4696*/} = this.state;
        printConsole("Turning off the Pointer Annotation")
            this.setState({
                isPointerActive: false,               
            }, () => {
                this.props.onClickSetShapes({shapesSetting: {
                    isPointerActive: false,
                    isArrowActive,
                    isFreeDrawing,
                    isRectActive,
                    isCircleActive,
                    isTextActive, /**TP-4695*/
                    isErasorActive,
                    isUndoActive /**TP-4696 */
                }, bSelectShape: true, pencolor: this.state.pencolor })
            });
    }

    onClickAcceptMaxDivControl = () => {
        const { bZoomClick, bTorchClick, zoom_level, expertFirstName, expertLastName, requestedUser, bShareAsset, bAnnotate, bRemoteCapture, bMaxChat } = this.state;
        const isBusy = (bAnnotate || bRemoteCapture || bShareAsset /* || startRecording */ || bMaxChat || this.props.isMaxDivSS); //TP-2966
        let passiveExpert = this.props.participants.filter(p => p.email === requestedUser.email), allExperts = this.props.participants.filter(p => p.isexpert), flag;
        let allTechnicians = this.props.participants.filter(p => !p.isexpert); //TP-5851
        const isScreenShare = (this.props.isSideBarSS || this.props.isMaxDivSS );
        const isFileShare = bShareAsset;
        // T32-495 -- for Seamless transfer of ongoing features when Planned transfer of controls happens
        let json = {};
        if(this.props.maxView && this.props.maxDivParticipant && this.props.maxDivParticipant !== ''){
            json = {
                show_grid: false,
                max_view_technician: this.props.maxDivParticipant.email, //TP-3893
                ratio: this.props.aspectRatio ? this.props.aspectRatio : "16:9",   
                hand_icon_enabled: true,    
                header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378         
                reason: "show_action"
            };
        }
        if (passiveExpert && passiveExpert.length > 0){
            //TP-6055
            let drawElem = document.getElementById('canvas');
            if(drawElem && drawElem.fabric) {
                let canvas = drawElem.fabric;
                canvas.off("mouse:up");
                canvas.off("mouse:down");
                canvas.off("text:changed");
                canvas.off("mouse:over");
                canvas.off("mouse:out");
                canvas.off("mouse:move");
            }
            //TP-6169
            this.setHandIconTimer = setTimeout(() => {
                let passiveExpert = this.props.participants.filter(p => p.email === requestedUser.email)
                if (!passiveExpert || passiveExpert.length === 0) {
                    if(this.state.showHideMaxDivControl === false) {
                        this.setState({ showHideMaxDivControl: true });
                        this.sendSignalYieldDisableHandIcon({data: false, senderName:`${expertFirstName} ${expertLastName}`, senderEmail: null});//Mb2-638
                    } 
                }
                clearTimeout(this.setHandIconTimer);
            }, 4000)
            if (requestedUser) {
                this.setState({hideHandIcon: true});//TP-5009 TP-4543
                // T32-495 -- Clearing the Shared screen current Primary expert
                if (isScreenShare) {
                    // T32-553
                    if (this.props.isSideBarSS) this.props.initSideBarScreenShare(false, true);
                    else if (this.props.isMaxDivSS) this.props.initMaxScreenShare(true);
                    /* if (this.state.expertShareScreenMaxDiv) this.setState({expertShareScreenMaxDiv : false});
                    this.props.handleScreenShareIcons(false);
                    this.sessionSignal.sendSignalStopExpertSS();
                    if(this.props.maxView && this.props.maxDivParticipant && this.props.maxDivParticipant !== ''){
                        allExperts && allExperts.forEach((e) => {
                            this.sessionSignal.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: this.props.maxDivParticipant.email, //TP-3893
                                ratio: this.props.aspectRatio ? this.props.aspectRatio : "16:9",
                                header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                reason: "show_action"},  
                            e.email);                        
                        });
                    }else{
                        allExperts && allExperts.forEach((e) => {
                            this.sessionSignal.sendSignalSyncExperts({
                                show_grid: true,
                                header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                reason: "show_action"}, 
                            e.email);                        
                        });
                    } */
                } else if (isFileShare) {
                    // Clearing shared file of current expert
                    /* this.sessionSignal.sendSignalRemoveArtifacts(true); //T32-539
                    allExperts && allExperts.forEach(p => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: this.props.maxDivParticipant !== '' ? false : true,
                            max_view_technician: this.props.maxDivParticipant ? this.props.maxDivParticipant.email : '', //TP-3893
                            ratio: this.props.aspectRatio,
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"}, 
                        p.email);
                    }) */
                } if (bZoomClick && parseInt(zoom_level) > 1) {
                    // Syncing all the passive expert's zoom level
                    json.zoom_button_active = bZoomClick;
                    json.zoom_level = parseInt(zoom_level);
                } else if (bZoomClick && parseInt(zoom_level) == 1) {
                    // Syncing all the passive expert's zoom level to "1"
                    json.zoom_button_active = false;
                    json.zoom_level = 1;
                    this.setState({ bZoomClick: false });
                } if (bTorchClick) {
                    // Syncing all the passive expert's torch state
                    json.torch_button_active = bTorchClick;                    
                }
                // Sending the SYNC_EXPERTS reason="show_action" with zoom & torch payloads
                json && (json.zoom_button_active !== undefined || json.torch_button_active !== undefined) && allExperts && allExperts.forEach((e) => {
                    this.sessionSignal.sendSignalSyncExperts(json, e.email);                        
                });
                // TP-740-- Pausing the Screen recording when the View Controls
                // are transferred
                printConsole(`Start recording property: ${this.props.startRecording}`);
                if (this.props.startRecording === true) {
                    printConsole("If recording is ongoing when Primary expert gives up control....Pause the recording");
                    this.props.stopRecordingCallback("pause");                    
                } 
                if (isScreenShare || this.props.startRecording === true) {
                    if ((bAnnotate || bRemoteCapture) && this.state.isPointerActive) this.closePointerWhileControlTransfer(); //TP-2474
                    // TP-1269 -- Added a time lag before the "YIELD_MAX_DIV_CONTROLS" Signal sent to the Passive expert
                    // so as to enable the Primary expert to close the screen share & sync its current view with that of 
                    // the Passive experts
                    this.screenShareClearTimer = setTimeout(() => {
                        requestedUser.email && this.sessionSignal.sendSignalYieldMaxDivControls(true, requestedUser.email);
                        /**TP-5851*/
                        allTechnicians && allTechnicians.forEach(tech => {
                            tech.email && this.sessionSignal.sendSignalExpertYieldMaxviewControls(true, expertFirstName+" "+expertLastName, tech.email);
                        })
                        clearTimeout(this.screenShareClearTimer);
                    }, 2000); // 2 seconds lag provided
                } /**TP-2474 */ else if (bAnnotate || bRemoteCapture) {
                    if (this.state.isPointerActive) this.closePointerWhileControlTransfer();
                    this.annotateTimer = setTimeout(() => {
                        requestedUser.email && this.sessionSignal.sendSignalYieldMaxDivControls(true, requestedUser.email);
                        /**TP-5851*/
                        allTechnicians && allTechnicians.forEach(tech => {
                            tech.email && this.sessionSignal.sendSignalExpertYieldMaxviewControls(true, expertFirstName+" "+expertLastName, tech.email);
                        })
                        clearTimeout(this.annotateTimer);
                    }, 2000); // 2 seconds lag provided    
                } else {
                    requestedUser.email && this.sessionSignal.sendSignalYieldMaxDivControls(true, requestedUser.email);
                    /**TP-5851*/
                    allTechnicians && allTechnicians.forEach(tech => {
                        tech.email && this.sessionSignal.sendSignalExpertYieldMaxviewControls(true, expertFirstName+" "+expertLastName, tech.email);
                    })
                }
                this.props.startMultiExpertWaitTimer() // Start the 30 second timer to wait for the SYNC_EXPERTs signal
                //this.props.setPrimaryExpertIDApi(parseInt(requestedUser.userId));//NS2-490
                /* allExperts && allExperts.length> 0 && allExperts.forEach((participant) => {
                    participant.email && this.sessionSignal.sendSignalSyncExperts({
                        isPrimary: false,
                        expertId: requestedUser.userId,
                        email: requestedUser.email,
                        firstname: requestedUser.firstName,
                        lastname: requestedUser.lastName,
                        joinTimeStamp: passiveExpert[0].joinTimeStamp,
                        reason: "primary_sync"},
                    participant.email); // send to each expert NS2-486                    
                }) */
                flag = true;
            }
            if(flag){
                this.setState({ showHideMaxDivControl: false, disableHand: false  });
                requestedUser.email && this.sendSignalYieldDisableHandIcon({data: false, senderName:`${expertFirstName} ${expertLastName}`, senderEmail: requestedUser.email});//Mb2-638
                this.hideRequestDialog();
                //TP-2966
                if (isBusy) this.props.maxDivActionGoingOn({flag:true});
                else this.props.maxDivActionGoingOn({flag:false});
            }else{
                this.sendSignalYieldDisableHandIcon({data: false, senderName:`${expertFirstName} ${expertLastName}`, senderEmail: null});//Mb2-638
                this.hideRequestDialog();
                //TP-2966
                if (isBusy) this.props.maxDivActionGoingOn({flag:true});
                else this.props.maxDivActionGoingOn({flag:false});
            }
        }else{
            this.sendSignalYieldDisableHandIcon({data: false, senderName:`${expertFirstName} ${expertLastName}`, senderEmail: null});//Mb2-638
            this.hideRequestDialog();
            //TP-1305
            const connData = expertFirstName+" "+expertLastName;
            this.props.triggerMessage(connData, "yieldpositive", "40%", "50%"); 
            printConsole(`The expert ${connData} can't be granted Max-div controls since he has left this call!!`)
        }
    }

    onClickDenyMaxDivControl = () => {
        const { expertFirstName, expertLastName, requestedUser } = this.state;      
        const { bAnnotate, bRemoteCapture, bShareAsset, bMaxChat, startRecording } = this.state;  
        let passiveExpert = this.props.participants.filter(p => p.email === requestedUser.email);
        const isBusy = (bAnnotate || bRemoteCapture || bShareAsset /* || startRecording */ || bMaxChat || this.props.isMaxDivSS); //TP-1286
        const connData = expertFirstName+" "+expertLastName; //TP-1305
        if (passiveExpert && passiveExpert.length > 0) {
            if (isBusy) this.props.maxDivActionGoingOn({flag:true});
            else this.props.maxDivActionGoingOn({flag:false});
            requestedUser && requestedUser.email && this.sessionSignal.sendSignalYieldMaxDivControls(false, requestedUser.email);
            this.sendSignalYieldDisableHandIcon({data: false, senderName:`${expertFirstName} ${expertLastName}`, senderEmail: null});//Mb2-638
            this.hideRequestDialog();
        }else {
            if (isBusy) this.props.maxDivActionGoingOn({flag:true});
            else this.props.maxDivActionGoingOn({flag:false});
            this.sendSignalYieldDisableHandIcon({data: false, senderName:`${expertFirstName} ${expertLastName}`, senderEmail: null});//Mb2-638
            this.hideRequestDialog();
            //TP-1305
            this.props.triggerMessage(connData, "yieldnegative", "40%", "50%");
            printConsole(`The expert ${connData} has left this call!!`)
        }
        /**TP-6037 & TP-5851*/
        let allTechnicians = this.props.participants.filter(p => !p.isexpert); 
        allTechnicians && allTechnicians.forEach(tech => {
            tech.email && this.sessionSignal.sendSignalExpertYieldMaxviewControls(false, expertFirstName+" "+expertLastName, tech.email);
        })
    }

    hideRequestDialog = () => {
        this.setState({
            showControlRequestDialog: false,
            requestDialogTimer: 0,
            requestedUser: null
        }, () => {
            this.props.setRequestMaxViewControl(false);
            clearInterval(this.streamTimer);
        });
    }

    decrementCounter = () => {
        this.setState((prevState) => ({
            requestDialogTimer: prevState.requestDialogTimer - 1
        }), () => {
            if (this.state.requestDialogTimer === 0) {
                this.onClickAcceptMaxDivControl();
            }
        });
    }

    // handler for YIELD_MAXDIV_CONTROL signal coming from primary expert
    processSignalYieldMaxDivControl = ({data, from}) => {
        this.setState({ showHideMaxDivControl: data, clickHandSignal: false }, () => {
            this.props.setClickHandSignal(false); //TP-1313
        });
        // for NS2-137
        if (data) {
            if (this.props.maxView && this.props.maxView === true) {
                this.setAnnotationButtons();//set the annotations color and shapes button
            }
            this.props.makeMeExpert({experts:[]});//NS2-490
            //Only when expert not a Guest user
            if (!this.props.isGuest)
                this.props.convertMeToPrimary(); //	TP-5089
            // T32-495 -- Show or hide the Zoom level selector for New Primary expert
            if (this.state.bZoomClick) {
                const zoomSelector = document.getElementById('zoomlevelselector');
                //TP-5730
                if (zoomSelector)
                    zoomSelector.style.display = 'block';
            } else {
                const zoomSelector = document.getElementById('zoomlevelselector');
                //TP-5730
                if (zoomSelector)
                    zoomSelector.style.display = 'none';
            }
            if (this.state.bRemoteCapture) {
                //printConsole("Triggering the RC signals again from New Primary Expert");
                //this.props.startRCAnnotation({email: from.email});  
                const zoomSelector = document.getElementById('zoomlevelselector');
                zoomSelector.style.display = 'none';              
            }

            if(this.state.bMaxChat) {
                this.props.onClickMaxChat();
                this.props.onClickMaxChat();
            }

            if(this.props.bAllChat === true) {
                this.props.onClickChatAll();
                this.props.onClickChatAll();
            }

        } else {
            const { bAnnotate, bRemoteCapture, bShareAsset, bZoomClick, bTorchClick, techShareScreen, startRecording } = this.state;
            // expertShareScreenMaxDiv is enabled for sid bar ss also so considering only isMaxDivSS
            const isBusy = (bAnnotate || bRemoteCapture || bShareAsset || /* bZoomClick || bTorchClick ||  */techShareScreen || this.props.isMaxDivSS || this.props.expertShareScreenMaxDiv /* || startRecording */); // NS2-375
            if (isBusy === false && from.email) {
                this.sessionSignal.sendSignalSyncExperts({reason: "request_expert_sync"}, from.email);
                //this.sessionSignal.sendSignalQueryExpertMaxdivSwitch(from.email);
            }
        }        
    }

    //Mb2-638 disable hand icon for other experts
    sendSignalYieldDisableHandIcon = ({data, senderName, senderEmail, toExpert=null}) => { //TP-1313
        try {
            let passiveExperts = this.props.participants.filter(p => p.isexpert && p.email !== senderEmail && p.email !== this.props.user.email);
            //const signal = "YIELD_DISABLE_HAND_ICON";
            //TP-1313
            if (toExpert !== null) {
                this.sessionSignal.sendSignalYieldDisableHandIcon( data, senderName, toExpert);
            } else if (!senderEmail) {
                passiveExperts.forEach(expertInfo => {
                    expertInfo.email && this.sessionSignal.sendSignalYieldDisableHandIcon( data, senderName, expertInfo.email);
                });
            }else{
                passiveExperts.forEach(expertInfo => {
                    expertInfo.email && this.sessionSignal.sendSignalYieldDisableHandIcon( data, senderName, expertInfo.email);
                })
            }
        } catch (exception){
            printConsole(exception);
            printConsole('exception occured in send Yield disable Hand Icon function');
        }
    }

    //Mb2-638
    processSignalYieldDisableHandIcon = ({data, expertName}) => {
        this.setState({ disableHand: data, clickHandSignal: false, handIconHover: "session.expertControlsDisabled" }, () => {
            this.props.setClickHandSignal(false);
        });
        if (data){
            this.props.triggerMessage(expertName, "disableHand", "35%", "50%");
        }
    }
    //TP-5851
    processSignalExpertYieldMaxviewControls = ({data, expertName, from}) => {
        //console.log(data, expertName, from);
        this.setState({ isYieldingControl: data });
    }
    //TP-5851
    processIsYieldingControl = () => {
        this.setState({ isYieldingControl: false });
    }
    //TP-5852
    processSignalPrepareExpertSS = ({data, from}) => {
        this.setState({ isPrepareExpertSS: data });
    }
    //TP-5852
    processSignalPrepareExpertSSCancel = ({from}) => {
        this.setState({ isPrepareExpertSS: false });
    }
    //TP-5852
    processSignalStartExpertSS = ({from, type}) => {
        if (type === 'START_EXPERT_SS') this.setState({ isPrepareExpertSS: false });
    } 

    // MB2-603
    onClickTorchIcon = () => {
        try{
            const { bAnnotate, bRemoteCapture, bZoomClick, bTorchClick, startRecording, bMaxChat, zoom_level } = this.state;
            // expertShareScreenMaxDiv is enabled for sid bar ss also so considering only isMaxDivSS
            const isBusy = (bAnnotate || bRemoteCapture || /* bZoomClick || startRecording || */ bMaxChat || this.props.isMaxDivSS); //MB2-731
            let maxDivParticipant = this.props.maxDivParticipant;
            const allExperts = this.props.participants.filter(p => p.isexpert);
            if (bTorchClick && maxDivParticipant && maxDivParticipant !== '' && !maxDivParticipant.isexpert) {
                this.setState({ bTorchClick: false }, () => {
                    //!isBusy && this.props.maxDivActionGoingOn({flag:this.state.bTorchClick})
                    this.props.onClickTorchIcon({flag: this.state.bTorchClick});
                    maxDivParticipant.email && this.sessionSignal.sendSignalRemoteTorch(false,maxDivParticipant.email);
                    // T32-495 -- Syncing all the Passive expert's torch state
                    allExperts && allExperts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: maxDivParticipant.email, //TP-3893
                            ratio: this.props.aspectRatio ? this.props.aspectRatio : "16:9",
                            torch_button_active: this.state.bTorchClick,
                            zoom_button_active: (maxDivParticipant.zoomLevel > 1) ? true : false, //TP-2861
                            zoom_level: maxDivParticipant.zoomLevel, //TP-2861
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"},  
                        e.email);                        
                    });
                })
            } else if (maxDivParticipant && maxDivParticipant !== '' && !maxDivParticipant.isexpert) {
                this.setState({ bTorchClick: true }, () => {
                    //!isBusy && this.props.maxDivActionGoingOn({flag:this.state.bTorchClick})
                    this.props.onClickTorchIcon({flag: this.state.bTorchClick});
                    maxDivParticipant.email && this.sessionSignal.sendSignalRemoteTorch(true, maxDivParticipant.email);
                    // T32-495 -- Syncing all the Passive expert's torch state
                    allExperts && allExperts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: maxDivParticipant.email, //TP-3893
                            ratio: this.props.aspectRatio ? this.props.aspectRatio : "16:9",
                            torch_button_active: this.state.bTorchClick,
                            zoom_button_active: (parseInt(zoom_level) > 1) ? true : false,
                            zoom_level: parseInt(zoom_level),
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"},  
                        e.email);                        
                    });
                })
            } else {
                printConsole("Exception occured: The torch icon was clicked for video stream that isn't on the Maxdiv")
            }
        }catch(error){
            printConsole(error)
        }
    }

    //TP-2933 -- New method to call the zoom level rotate method for Max-view technician
    onClickZoomRotate = () =>{
        const {maxDivParticipant} = this.props;
        this.props.rotateUserZoom(maxDivParticipant.userId);        
    }

    // MB2-603
    onClickZoomIcon = () => {
        try{
            if (this.state.bZoomClick === false) {
                const { bAnnotate, bRemoteCapture, bZoomClick, bTorchClick, startRecording, bMaxChat } = this.state;
                // expertShareScreenMaxDiv is enabled for sid bar ss also so considering only isMaxDivSS
                const isBusy = (bAnnotate || bRemoteCapture || /* bTorchClick || startRecording || */ bMaxChat || this.props.isMaxDivSS); //Mb2-731
                const zoomSelector = document.getElementById('zoomlevelselector');
                this.setState({ bZoomClick: !bZoomClick }, () => {
                    if (this.state.bZoomClick) {
                        //!isBusy && this.props.maxDivActionGoingOn({flag:this.state.bZoomClick})
                        zoomSelector.style.display = 'block';
                    } else {
                        this.onClickZoomLevel('1');// zoom level 1 to be treated as closing the zoom on the technician side
                        //!isBusy && this.props.maxDivActionGoingOn({flag:this.state.bZoomClick})
                        zoomSelector.style.display = 'none';
                    }
                })
            } else return;
        }catch(error){
            printConsole(error)
        }
    }

    // MB2-603
    onClickZoomLevel = (val) => {
        try{
            this.setState({ zoom_level: val }, () => {
                const maxDivParticipant = this.props.maxDivParticipant;
                const { bZoomClick } = this.state;
                const allExperts = this.props.participants.filter(p => p.isexpert);
                if(maxDivParticipant && maxDivParticipant !== '' && !maxDivParticipant.isexpert && maxDivParticipant.email){
                    if(parseInt(this.state.zoom_level) === 1) {
                        const zoomSelector = document.getElementById('zoomlevelselector');
                        if (zoomSelector !== undefined && zoomSelector !== null) {
                            this.setState({bZoomClick : false});
                            zoomSelector.style.display = 'none';
                        }
                    }
                    this.sessionSignal.sendSignalRemoteZoom(parseInt(this.state.zoom_level), maxDivParticipant.email);
                    this.props.onClickZoomIcon({zoom_level: this.state.zoom_level});
                    // T32-495 -- Syncing all the Passive expert's zoom level value
                    allExperts && allExperts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: maxDivParticipant.email, //TP-3893
                            ratio: this.props.aspectRatio ? this.props.aspectRatio : "16:9",
                            torch_button_active: this.state.bTorchClick,
                            zoom_button_active: (parseInt(val) > 1) ? true : false,
                            zoom_level: parseInt(val),
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                            reason: "show_action"},  
                        e.email);                        
                    });
                }
            });
        }catch(error){
            printConsole(error)
        }
    }

    // NS2-216,603
    processSignalLocalTorch = ({from, data}) => {
        try{
            const { bAnnotate, bRemoteCapture, bZoomClick, startRecording, bMaxChat, zoom_level } = this.state;
            const {maxDivParticipant, aspectRatio, participants, maxView} =  this.props;
            if (maxDivParticipant && maxDivParticipant.email === from.email && maxView === true) {
                const allExperts = participants.filter(p => p.isexpert);
                if ((data && !this.state.bTorchClick) || (!data && this.state.bTorchClick)) {
                    this.setState(prevState => ({ bTorchClick: !prevState.bTorchClick }),()=>{
                        const isBusy = (bAnnotate || bRemoteCapture /* || bZoomClick  || startRecording */ || bMaxChat || this.props.isMaxDivSS); //Mb2-731
                        //!isBusy && this.props.maxDivActionGoingOn({flag:this.state.bTorchClick})
                        // T32-495 -- Syncing all the Passive expert's torch state
                        allExperts && allExperts.forEach((e) => {
                            this.sessionSignal.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: maxDivParticipant.email, //TP-3893
                                ratio: this.props.aspectRatio ? aspectRatio : "16:9",
                                torch_button_active: this.state.bTorchClick,
                                zoom_button_active: (parseInt(zoom_level) > 1) ? true : false,
                                zoom_level: parseInt(zoom_level),
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                reason: "show_action"},  
                            e.email);                        
                        });
                    });
                }
            } else return;
        }catch(error){
            printConsole(error)
        }
    }
    
    // NS2-216,603
    processSignalLocalZoomLevel = ({from, data}) => {
        let zoom_level = data.toString();
        try{
            const {maxDivParticipant, aspectRatio, participants, maxView} =  this.props;
            if (maxDivParticipant && maxDivParticipant.email === from.email && maxView === true && this.state.zoom_level !== zoom_level) {
                const allExperts = participants.filter(p => p.isexpert);
                this.setState({ zoom_level }, () => {
                    this.setState({ bZoomClick: (data > 1) ? true : false }, () => {
                        const zoomSelector = document.getElementById('zoomlevelselector');
                        if (this.state.bZoomClick) {
                            zoomSelector.style.display = 'block';
                            this.props.onClickZoomIcon({zoom_level: this.state.zoom_level});//TP-2933
                        } else {
                            this.onClickZoomLevel('1');// zoom level 1 to be treated as closing the zoom on the technician side
                            //zoomSelector.style.display = 'none';
                        }
                        // T32-495 -- Syncing all the Passive expert's zoom level value
                        allExperts && allExperts.forEach((e) => {
                            this.sessionSignal.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: maxDivParticipant.email, //TP-3893
                                ratio: aspectRatio ? aspectRatio : "16:9",
                                torch_button_active: this.state.bTorchClick,
                                zoom_button_active: this.state.bZoomClick,
                                zoom_level: data,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                reason: "show_action"},  
                            e.email);                        
                        });
                    })
                }) 
            }       
        }catch(error){
            printConsole(error)
        }
    }

    // T32-495 Processing of the Zoom & Torch payloads for SYNC_EXPERTS Signal
    processSignalSyncExperts = ({from, data}) => {
        switch (data.reason) {
            case "request_expert_sync":
                this.triggerExpertQueryMaxDiv({from});// Mb2-500
                break;
            case "show_action":
                this.triggerExpertSyncFeatures({from, data})
                break;
            case "update_action":
                this.triggerExpertGridViewSyncFeatures({from,data})
                break;
            /**	TP-5009*/  
            case "primary_sync":
                this.triggerSetHandIconDisabled({from, data});
                break;
            default:                
        }
    }

    // TP-1199
    triggerExpertQueryMaxDiv = ({from}) => {
        // This is to take care of scenarios where a Passive expert newly joins a session
        // while another Passive expert has asked for taking over the Max-div controls from the Primary expert
        // hence the Hand Icon for this new Expert needs to be disabled while the Request Dialog box is open
        // on the Primary expert's screen
        // TP-1313
        const {expertEmail} = this.state;
        if (this.state.showControlRequestDialog == true && expertEmail !== '') {
            this.sendSignalYieldDisableHandIcon({data: true, senderName:`${this.state.expertFirstName} ${this.state.expertLastName}`, senderEmail: expertEmail});//TP-1199
            //this.sendSignalYieldDisableHandIcon(true, `${this.state.expertFirstName} ${this.state.expertLastName}`, json.from.email); 
        }
    }
    // T32-495
    triggerExpertSyncFeatures = ({from, data}) => {
        const {zoom_button_active, zoom_level, torch_button_active, hand_icon_enabled} = data;
        if (hand_icon_enabled === true) {
            this.setState({ disableHand: false });
        } else {
            this.setState({ disableHand: true, handIconHover: "session.handIconDisabled" });
        }      
        if (zoom_button_active === undefined && zoom_level === undefined && torch_button_active === undefined) return printConsole("Undefined data");
        else {
            try {
                if (zoom_level !== undefined && zoom_level > 1) this.setState({ bZoomClick: true, zoom_level: zoom_level.toString() }, () => {
                    //if (this.state.bZoomClick) this.props.maxDivActionGoingOn({flag:this.state.bZoomClick})
                });
                else this.setState({bZoomClick: false, zoom_level: 1});
                
                if (torch_button_active !== undefined)
                    this.setState({ bTorchClick: torch_button_active }, () => {
                        //if (torch_button_active) this.props.maxDivActionGoingOn({flag:torch_button_active})
                    });
                else
                    this.setState({ bTorchClick: false });
                
            } catch (error) {
                printConsole ("Exception occured in the triggerExpertSyncFeatures method");
                printConsole(error);
            }
        }
    }

    // TP-207
    triggerExpertGridViewSyncFeatures = ({from, data}) => {
        const {hand_icon_enabled} = data;
        if (hand_icon_enabled === true) {
            this.setState({ disableHand: false });
        } else {
            this.setState({ disableHand: true, handIconHover: "session.handIconDisabled" });
        }
    }
    //TP-5009
    triggerSetHandIconDisabled = ({from, data}) => {
        this.setState({ hideHandIcon: false });
    }
    // MB2-246
    resetMaxdiv = () => {
        printConsole("reset Maxdiv Funtion");
        const {showMaxDivControls,maxDivParticipant, maxView} = this.props;
        const {showHideMaxDivControl} = this.state;
        if(showHideMaxDivControl && this.state.bAnnotate && !this.state.bRemoteCapture){
            //TP-5009
            /*TP-5501 if (this.props.isMaxDivSS || this.props.isSideBarSS)
                this.onClickAnnotate(true);//TP-4497
            else */ 
                this.onClickAnnotate(false, true);
        }

        if(showHideMaxDivControl && this.state.bRemoteCapture){
            this.onClickCamera(true, true);//TP-5501 Use case - 5 & TP-4542
        }

        if(showHideMaxDivControl && this.props.isOneToOneFileShare){
            // find whether 3d file is being shared. If yes then immitate a click event on the cross icon.
            const closeButton = document.getElementById('closeIcon');
            if(closeButton){
                closeButton.click();//3D
            }else{
                // other files image, pdf, xls files
                this.props.onClose3dCanvas();
            }
        }

        if (maxView === true && maxDivParticipant !== "") {
            this.setState({ bTorchClick: maxDivParticipant.torchActive });
            this.setState({ zoom_level: maxDivParticipant.zoomLevel ? maxDivParticipant.zoomLevel.toString(): "1", bZoomClick: (maxDivParticipant.zoomLevel && maxDivParticipant.zoomLevel > 1) ? true : false }, () => {
                const zoomSelector = document.getElementById('zoomlevelselector');
                //console.log(this.state.zoom_level);
                if (this.state.bZoomClick && zoomSelector && !this.state.bRemoteCapture) {
                    zoomSelector.style.display = 'block';
                } else if (zoomSelector) {
                    //this.onClickZoomLevel('1');// zoom level 1 to be treated as closing the zoom on the technician side
                    zoomSelector.style.display = 'none';
                }
            });
        }
        /* if(this.state.bTorchClick){
            this.onClickTorchIcon();
        } */
        
        /* if(this.state.bZoomClick){
            this.onClickZoomIcon();
        } */

        if(showHideMaxDivControl && this.state.expertShareScreenMaxDiv){
            this.expertScreenShareClick();
        }

        if(showHideMaxDivControl && this.state.bMaxChat){
            this.onClickMaxChat();
        }

        if(this.props.startRecording){
            //this.props.onClickRecordScreen()
        }
    }

    //TP-2861
    handleZoomReset = () => {
        printConsole("handle Zoom Reset!");
        const {maxDivParticipant} = this.props;
        this.setState({ zoom_level: maxDivParticipant.zoomLevel ? maxDivParticipant.zoomLevel.toString(): "1", bZoomClick: (maxDivParticipant.zoomLevel && maxDivParticipant.zoomLevel > 1) ? true : false }, () => {
            printConsole(this.state.zoom_level, this.state.bZoomClick);
            const zoomSelector = document.getElementById('zoomlevelselector');
            if (this.state.bZoomClick && zoomSelector && !this.state.bRemoteCapture) {
                zoomSelector.style.display = 'block';
            } else if (zoomSelector) {
                //this.onClickZoomLevel('1');// zoom level 1 to be treated as closing the zoom on the technician side
                zoomSelector.style.display = 'none';
            }
        });
    }

    //TP-2861
    handleTorchReset = () => {
        const {maxDivParticipant} = this.props;
        this.setState({ bTorchClick: maxDivParticipant.torchActive });
    }

    //TP-2539 -- swap the View Mode of the Technician
    onSwapView = () => {
        //e.preventDefault();
        this.props.cycleViewMode();
    }

    onClickTechGridView = () => {
        this.props.onClickMultiGridView();
    }

    getDeviceClassname = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        var isAndroid = /android/.test(ua);
        printConsole(`is an iPad device ${isiPad}`);
        let hideButton = "d-blk";
        if (isiPad || isAndroid) {
            hideButton = "d-none";
        } 
        return hideButton;
    }

    checkIsMobileDevice = () => {
        //console.log(window.innerWidth, window.innerHeight);
        return (window.innerHeight <= 420) ? true : false;
    }

    render(){
        const {hideButton} = this.state;      
        const {enable_remote_capture, maxDivParticipant, isSmallFormFactorDevice, setMaxView, expertVideoArr, behaviour} = this.props; /**TP-6373*/
        const {screen_share_mode, torch_enabled, zoom_enabled} = this.props.groupInfo;  
        const isMobileDevice = this.checkIsMobileDevice();
        //console.log(maxDivParticipant);
        //console.log(`screen_share_mode ${screen_share_mode}, torch_enabled ${torch_enabled}, zoom_enabled ${zoom_enabled}`)
        let annotateStyle = this.state.bAnnotate? {color: '#ff0000'} : {color: 'black'};
		let shareStyle = this.state.bShareAsset? {color: '#ff0000'} : {color: 'black'};
		let cameraStyle = this.state.bRemoteCapture? {color: '#ff0000'} : {color: 'black'};
		let chatStyle = this.state.bMaxChat? {color: '#ff0000'} : {color: 'black'};
        let sidePanelStyle = this.state.showPanel ? {color: '#ff0000'} : {color: 'black'}; //TP-3213
		let styleDivMaxBtnContainer = {position: 'absolute', left: '25%', top: '90%', backgroundColor: 'transparent'};
        // if(g_subInfoArr.length === 1) styleDivMaxBtnContainer = {position: 'absolute', left: '25%', top: '90%', backgroundColor: 'transparent'};
        let maxDivMuteMicColor = this.state.muteMikeForMaxDiv ? '#ff0000' : 'black';
        let techShareStyle = this.state.techShareScreen? {color: '#ff0000'} : {color : 'black'};
        let expertShareStyle = this.state.expertShareScreenMaxDiv ? {color: '#ff0000'} : {color : 'black'};
        let passiveShareStyle = this.props.expertShareScreenMaxDiv ? {color: '#ff0000'} : {color : 'black'};
        let getExpertControlStyle = this.state.clickHandSignal? {color : '#ff0000'} : {color : 'black'};
        let zoomStyle = this.state.bZoomClick? { color: '#ff0000' } : { color: 'black' };
        let torchStyle = this.state.bTorchClick? { color: '#ff0000' } : { color: 'black' };
        let penStyle = { color: this.state.pencolor }; //'#ff0000'
        let shapeStyle = { color: this.state.pencolor };
        let crossStyle = this.state.isFreeDrawing? { color: this.state.pencolor } : { color: 'black' }
        let circleStyle = this.state.isCircleActive? { color: this.state.pencolor } : { color: 'black' };   
        let rectStyle = this.state.isRectActive? { color: this.state.pencolor } : { color: 'black' };   
        let arrowStyle = this.state.isArrowActive? { color: this.state.pencolor } : { color: 'black' };
        let pointerStyle = this.state.isPointerActive? { color: this.state.pencolor } : { color: 'black' };//TP-2491
        let erasorStyle = this.state.isErasorActive ? { color: this.state.pencolor } : { color: 'black' } //TP-2474
        let undoStyle = this.props.isUndoDisabled === true ? { color : 'grey' } : this.state.isUndoActive ? { color: this.state.pencolor } : { color: 'black' }; //TP-4752 TP-4696
        let textStyle = this.state.isTextActive ? { color: this.state.pencolor } : { color: 'black' }; //TP-4695
        // NS2-274
        let maxDivRecordScreenColor = this.props.startRecording ? '#ff0000' : 'black';
        let maxDivRecordScreenIcon = this.props.startRecording ? 'fa-stop-circle' : 'fa-dot-circle';
        // NS2-367
        let captureScreenClass = this.state.screenCaptureClicked ? 'no-pointer-events disable-image' : '';
        let buttonSize = (window.innerWidth > 1299) ? "fa-lg" : "fa-sm";        
        let handSize = (window.innerWidth > 1299) ? "fa-2x" : "fa-lg";
        if (this.props.user.isexpert){
            //printConsole(g_subInfoArr);float-right pr-2 pt-1
            let actionReqControlStyle = 'hideDiv';
            let maxdivBtnControlStyle = 'hideDiv';
            let gridTileBtnCntStyle = 'hideDiv';
            if (this.props.maxView === true) {
                if (this.props.showHideDivIconAudioOnly === false) { //T32-552
                    if (this.state.showHideMaxDivControl) {
                        actionReqControlStyle = 'hideDiv';
                        maxdivBtnControlStyle = 'showDiv';
                        gridTileBtnCntStyle = 'hideDiv';
                    } else {
                        actionReqControlStyle = 'showDiv';
                        maxdivBtnControlStyle = 'hideDiv';
                        gridTileBtnCntStyle = 'showIcon';
                    }
                }
            } else {
                maxdivBtnControlStyle = 'hideDiv';
                gridTileBtnCntStyle = 'hideDiv';
                if (this.state.showHideMaxDivControl) {
                    actionReqControlStyle = 'hideDiv';
                } else {
                    //TP-3904
                    if (this.props.gridHandIconHide === true) 
                        actionReqControlStyle = 'hideDiv';
                    else
                        actionReqControlStyle = 'showDiv';
                }
            }
            //TP-4900 NS2-507
            let handIconStyle = this.props.maxView === true ? /**TP-5685*/ maxDivParticipant.isPaused && !this.state.showHideMaxDivControl && (!maxDivParticipant.poster || maxDivParticipant.poster === '') ? { bottom: '-65%', zIndex: '1060', backgroundColor: 'transparent' } : { bottom: '6%', zIndex: '1060', backgroundColor: 'transparent' } :
            { bottom: '12%', zIndex: '1060', backgroundColor: 'transparent' } ;
            // if (g_subInfoArr.length > 1) handIconStyle = { left: '35%', zIndex: '10', backgroundColor: 'transparent'};
            //console.log(this.state.disableHand);
            //TP-4574 & TP-4548 -- Hide the Max-view controls when Max-view technician has file viewer open
            let showControls = "";
            if (this.props.maxView === true && this.state.showHideMaxDivControl && maxDivParticipant && maxDivParticipant.isPaused && maxDivParticipant.isPaused === true) {
                showControls = "d-none";
            } else if (this.props.maxView === true && maxDivParticipant && (!maxDivParticipant.isPaused || maxDivParticipant.isPaused === false)) {
                showControls = "";
            }
            return(
                <div className={'flex-container '+showControls} id='flex-container' style={{backgroundColor: '#1c2238'}}>
                    {/* NS2-302 */}
                    { /**TP-2810 & TP-2491 & TP-2508 -- New UI of the shapeselector div */ this.state.bHideAnnotateShapes === false ?
                        <div id='shapeselector' className={maxdivBtnControlStyle+" shapeselector"} style={( this.state.bAnnotate && !this.state.bShareAsset)? { display: 'block' } : { display: 'none'}}>
                            <div className="shape-selector shape-selector-ow-left">
                                        {/*TP-2474*/annotateShapesArray[5].class  ?
                                            <span className={"fa-stack shapeselector-spacing "+buttonSize}>
                                                <a id="erasor" onClick={() => this.onClickErasorIcon()}>
                                                    <i className="fas fa-circle fa-stack-2x"></i>
                                                    <i className={annotateShapesArray[5].class + " fa-stack-1x rotate90"} style={erasorStyle}></i>
                                                    { isSmallFormFactorDevice === false ?
                                                        <UncontrolledTooltip placement="right" innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="erasor">                                            
                                                            <Trans>session.erasor</Trans>                                            
                                                        </UncontrolledTooltip>
                                                        :
                                                        ''
                                                    }
                                                </a>
                                            </span>
                                            :
                                            ''
                                        }
                                        {/*TP-4696*/annotateShapesArray[7].class  ?
                                            <span className={"fa-stack shapeselector-spacing "+buttonSize}>
                                                <a id="undo" onClick={/*TP-4752*/ this.props.isUndoDisabled === true ? () => {return null;} : () => this.onClickUndoIcon()}>
                                                    <i className="fas fa-circle fa-stack-2x"></i>
                                                    <i className={annotateShapesArray[7].class + " fa-stack-1x rotate90"} style={undoStyle}></i>
                                                    { isSmallFormFactorDevice === false ?
                                                        <UncontrolledTooltip placement="right" innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="undo">                                            
                                                            <Trans>session.undo</Trans>                                            
                                                        </UncontrolledTooltip>
                                                        :
                                                        ''
                                                    }
                                                </a>
                                            </span>
                                            :
                                            ''
                                        }
                                        {/*TP-4695*/annotateShapesArray[6].class  ?
                                            <span className={"fa-stack shapeselector-spacing "+buttonSize}>
                                                <a id="text" onClick={() => this.onClickTextIcon()}>
                                                    <i className="fas fa-circle fa-stack-2x"></i>
                                                    <i className={annotateShapesArray[6].class + " fa-stack-1x rotate90"} style={textStyle}></i>
                                                    { isSmallFormFactorDevice === false ?
                                                        <UncontrolledTooltip placement="right" innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="text">                                            
                                                            <Trans>session.text</Trans>                                            
                                                        </UncontrolledTooltip>
                                                        :
                                                        ''
                                                    }
                                                </a>
                                            </span>
                                            :
                                            ''
                                        }
                                        {/*TP-2474 & TP-2528 */ !this.state.bHideAnnotationPointer && annotateShapesArray[4].class  ?
                                            <span className={"fa-stack shapeselector-spacing "+buttonSize}>
                                                <a id="pointer" onClick={() => this.onClickPointerIcon()}>
                                                    <i className="fas fa-circle fa-stack-2x"></i>
                                                    <i className={annotateShapesArray[4].class + " fa-stack-1x rotate90"} style={pointerStyle}></i>
                                                    { isSmallFormFactorDevice === false ?
                                                        <UncontrolledTooltip placement="right" innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="pointer">                                            
                                                            <Trans>session.pointer</Trans>                                            
                                                        </UncontrolledTooltip>
                                                        :
                                                        ''
                                                    }
                                                </a>
                                            </span>
                                            :
                                            ''
                                        }
                                        {/*TP-2474*/annotateShapesArray[0].class  ?
                                            <span className={"fa-stack shapeselector-spacing "+buttonSize}>                                        
                                                <a id="freehand" onClick={() => this.onClickCrossHairsIcon()} >
                                                    <i className="fas fa-circle fa-stack-2x"></i>
                                                    <i className={annotateShapesArray[0].class+" fa-stack-1x rotate90"} style={crossStyle}></i>
                                                    { isSmallFormFactorDevice === false ?
                                                        <UncontrolledTooltip placement="right" innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="freehand">                                            
                                                            <Trans>session.freehand</Trans>                                            
                                                        </UncontrolledTooltip>
                                                        :
                                                        ''
                                                    }
                                                </a>                                            
                                            </span>
                                            :
                                            ''
                                        }
                                        {/*TP-2474*/annotateShapesArray[3].class  ?
                                            <span className={"fa-stack shapeselector-spacing "+buttonSize}>
                                                <a id="arrow" onClick={() => this.onClickArrowIcon()}>
                                                    <i className="fas fa-circle fa-stack-2x"></i>
                                                    <i className={annotateShapesArray[3].class + " fa-stack-1x rotate90"} style={arrowStyle}></i>
                                                    { isSmallFormFactorDevice === false ?
                                                        <UncontrolledTooltip placement="right" innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="arrow">                                            
                                                            <Trans>session.arrow</Trans>                                            
                                                        </UncontrolledTooltip>
                                                        :
                                                        ''
                                                    }
                                                </a>
                                            </span>
                                            :
                                            ''
                                        }
                                        {/*TP-2474*/annotateShapesArray[1].class  ?
                                            <span className={"fa-stack shapeselector-spacing "+buttonSize}>                                        
                                                <a id="circle" onClick={() => this.onClickCircleIcon()}>
                                                    <i className="fas fa-circle fa-stack-2x"></i>
                                                    <i className={annotateShapesArray[1].class + " fa-stack-1x rotate90"} style={circleStyle}></i>
                                                    { isSmallFormFactorDevice === false ?
                                                        <UncontrolledTooltip placement="right" innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="circle">                                            
                                                            <Trans>session.circle</Trans>                                            
                                                        </UncontrolledTooltip>
                                                        :
                                                        ''
                                                    }
                                                </a>                                            
                                            </span>
                                            :
                                            ''
                                        }
                                        {/*TP-2474*/annotateShapesArray[2].class  ?
                                            <span className={"fa-stack shapeselector-spacing "+buttonSize}>                                        
                                                <a id="square" onClick={() => this.onClickRectIcon()}>
                                                    <i className="fas fa-circle fa-stack-2x"></i>
                                                    <i className={annotateShapesArray[2].class + " fa-stack-1x rotate90"} style={rectStyle}></i>
                                                    { isSmallFormFactorDevice === false ?
                                                        <UncontrolledTooltip placement="right" innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="square">                                            
                                                            <Trans>session.square</Trans>                                            
                                                        </UncontrolledTooltip>
                                                        :
                                                        ''
                                                    }
                                                </a>                                            
                                            </span>
                                            :
                                            ''
                                        }
                            </div>
                        </div>
                        :
                        null
                    }
                    <div id="actionRequestControls" style={handIconStyle} className={actionReqControlStyle+" w-100"}>
                        <div id="actioncontrols">
                            {this.state.bHideAnnotateColour === false && this.state.bAnnotate ? 
                                <span className={"fa-stack "+buttonSize+" "+hideButton+" "+gridTileBtnCntStyle} >
                                    <a id="colourpicker" className="disabled">
                                        <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                        <i className="fas fa-palette fa-stack-1x" style={penStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="colourpicker">                                            
                                                <Trans>session.colourpicker</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                </span>
                                :
                            ''
                            }
                            {this.state.bHideAnnotateShapes === false && this.state.bAnnotate ?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+hideButton+" "+gridTileBtnCntStyle} >
                                    <I18n>
                                        {({ i18n }) =>
                                            <a id="shapepicker" className="disabled">
                                                <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                                <i className={/*TP-2491*/annotateShapesArray[8].class + " fa-stack-1x"} style={shapeStyle}></i>
                                                { isSmallFormFactorDevice === false ?
                                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="shapepicker">                                            
                                                        {/*TP-2491*/this.processTooltip(annotateShapesArray[8].name, i18n)}                                           
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </a>
                                        }
                                    </I18n>
                                </span>
                                :
                                ''
                            }
                            { behaviour !== "pndsafety" /**TP-6373 */ ?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>                                                            
                                    <a id="telestrate" className="disabled">
                                        <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                        <i className="fas fa-edit fa-stack-1x" style={annotateStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="telestrate">                                            
                                                <Trans>session.telestrate</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                </span>                            
                                :
                                ''
                            }
                            {((enable_remote_capture === undefined || enable_remote_capture === true) && this.props.isSideBarSS !== true && this.state.expertShareScreenMaxDiv !== true && this.state.techShareScreen !== true) ?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}> 
                                    <a id="remoteCapture" className="disabled">
                                        <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                        <i className="fas fa-camera fa-stack-1x" style={cameraStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="remoteCapture">                                            
                                                <Trans>session.remoteCapture</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>                                                                        
                                </span>
                                :
                                ''
                            }
                            {( /**TP-2933 */ maxDivParticipant && (maxDivParticipant.torch_enabled || this.props.expertShareScreenMaxDiv) && torch_enabled ) ?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>                                    
                                    <a id="flashLight" className="disabled">
                                        <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                        <i className="fas fa-bolt fa-stack-1x" style={torchStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="flashLight">                                            
                                                <Trans>session.flashLight</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>                                                                             
                                </span>
                                :
                                ''
                            }
                            {( /*TP-2933*/maxDivParticipant && (maxDivParticipant.zoom_enabled || this.props.expertShareScreenMaxDiv) && zoom_enabled ) ?
                                <span className={"fa-stack maxdivcontrol-spacing rel "+buttonSize+" "+gridTileBtnCntStyle}>
                                    <a id="zoom" className="disabled">
                                        <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                        <i className="fas fa-binoculars fa-stack-1x" style={zoomStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="zoom">                                            
                                                <Trans>session.zoom</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>                                
                                </span>
                                :
                                ''
                            }
                            { maxDivParticipant && (maxDivParticipant.screenshare_enabled || this.props.expertShareScreenMaxDiv) && (screen_share_mode === "screen_share_enabled" || screen_share_mode === "expert_screen_share_enabled") && (
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+hideButton+" "+gridTileBtnCntStyle}>
                                    <a id="expertSS" className="disabled">
                                        <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                        <i className="fab fa-chromecast fa-stack-1x" style={passiveShareStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="expertSS">                                            
                                                <Trans>session.expertSS</Trans>                                            
                                            </UncontrolledTooltip> 
                                            :
                                            ''
                                        }
                                    </a>                                        
                                </span>)}
                            { maxDivParticipant && maxDivParticipant.techSS_enabled && (screen_share_mode === "screen_share_enabled" || screen_share_mode === "tech_screen_share_enabled") && (
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+hideButton+" "+gridTileBtnCntStyle}>
                                    <a id="techSS" className="disabled">
                                        <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                        <i className="fas fa-tablet-alt fa-stack-1x" style={techShareStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="techSS">                                            
                                                <Trans values={{role: this.props.customerRoles.technician}}>session.techSS</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }                                            
                                    </a>
                                </span>)}                        
                            <span className={"fa-stack "+handSize}>
                                {!this.state.disableHand && this.state.hideHandIcon !== true /**TP-4543*/ ? 
                                    //this.props.showHideDivIconAudioOnly === false ?                              
                                        <a id="getExpertControl" onClick={this.onClickHandIcon}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="far fa-hand-paper fa-stack-1x" style={getExpertControlStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="getExpertControl">                                            
                                                    <Trans values={{role: this.props.customerRoles.expert}}>session.getExpertControl</Trans>                                            
                                                </UncontrolledTooltip> 
                                                :
                                                ''
                                            }
                                        </a>                              
                                    : 
                                    <I18n>
                                        {({ i18n }) =>                               
                                            <a id="getExpertControl" >
                                                <i className="fas fa-circle fa-stack-2x"></i>
                                                <i className="far fa-hand-paper font-awesome-grey fa-stack-1x"></i>
                                                { isSmallFormFactorDevice === false ?
                                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="getExpertControl">                                            
                                                        {this.processTooltip(this.state.handIconHover, i18n, this.props.customerRoles)}                                            
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </a> 
                                        }
                                    </I18n>                                   
                                }
                            </span>
                            { behaviour !== "pndsafety" /**TP-6373*/ ?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>
                                    <a id="shareAsset" className="disabled">
                                        <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                        <i className="fas fa-upload fa-stack-1x" style={shareStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="shareAsset">                                            
                                                <Trans>session.shareAsset</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                </span>
                                :
                                ''
                            }
                            <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+hideButton+" "+gridTileBtnCntStyle}>
                                <a id="chat" className="disabled">
                                    <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                    <i className="far fa-comments fa-stack-1x" style={chatStyle}></i>
                                    { isSmallFormFactorDevice === false ?
                                        <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chat">                                            
                                            <Trans>session.chat</Trans>                                            
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>                                        
                            </span>
                            {/* T32-413 -- Disabled Speaker of Remote user implementation */}
                            <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}> 
                                { this.state.maxDivSpeakerDisabled === false ?
                                    <a id="toggleAudio" onClick={() => this.onClickMuteSpeaker()}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-volume-up fa-stack-1x" style={{ color: this.state.bRemoteMute ? this.props.maxDivParticipant.isSpeaking ? '#1338BE' : '#ff0000' : this.props.maxDivParticipant.isSpeaking ? '#1338BE' : 'black' }}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="toggleAudio">                                            
                                                <Trans>session.toggleAudio</Trans>                                            
                                            </UncontrolledTooltip>  
                                            :
                                            ''
                                        }
                                    </a>  
                                        :
                                    <a id="toggleAudio">
                                        <i className="fas font-awesome-grey fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-volume-mute fa-stack-1x" style={{ color: '#ff0000' }}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="toggleAudio">                                            
                                                <Trans>session.toggleAudio</Trans>                                            
                                            </UncontrolledTooltip>  
                                            :
                                            ''
                                        }
                                    </a> 
                                }                               
                            </span>
                            <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>                                
                                <a id="muteMikeEach" onClick={() => this.onClickMuteMike()}>
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fas fa-microphone fa-stack-1x" style={{ color: maxDivMuteMicColor }}></i>
                                    { isSmallFormFactorDevice === false ?
                                        <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteMikeEach">                                            
                                            <Trans>session.muteMike</Trans>                                            
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>                                    
                            </span>
                        </div>
                        {/* <div id="recording_inactive">
                            {(!(/* this.props.isiOS && this.props.isSafari) && !this.props.isFirefox && this.props.screen_recording_enabled && !this.state.showHideMaxDivControl)  ?
                                <span style={(window.innerWidth > 1299) ? (this.props.maxView === true) ? {top: "8%", right: "20%"} : {top: "8%", right: "10%"} : {top: "14%", right: "15%"}} className={!(this.props.isiOS && this.props.isSafari)? "fa-stack position-absolute rec-button "+buttonSize+" "+hideButton : "fa-stack position-absolute rec-button "+buttonSize}>                                        
                                    <SessionIcon id="recordScreen" circleClass={"fas fa-circle font-awesome-grey fa-stack-2x"}
                                        iconClass={'fas ' + maxDivRecordScreenIcon + ' fa-stack-1x'} iconStyle={{ color: maxDivRecordScreenColor }} tooltipPlament="top"
                                        innerClass="tip-max no-pointer-events" tooltip={this.state.startRecording ? "session.stopRecordScreen" : "session.recordScreen"} tooltipStyle="Trans"
                                        onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>
                                </span>
                                :
                                ''
                            }
                        </div> */}
                    </div>
                    <div id='controlpanel' className={maxdivBtnControlStyle}>
                        { (this.state.bHideAnnotateColour === false && this.state.bAnnotate && !this.state.bShareAsset) ?
                            <div id='sketchPicker' style={{display: 'none'}}>
                                <SketchPicker color={this.state.pencolor} onChangeComplete={this.handleChangeComplete} />
                            </div>
                            :
                            null
                        }
                        {!this.state.bHideZoomButton ?
                            <div id='zoomlevelselector' className={/*TP-2933*/hideButton === "d-none" && window.innerHeight <= 420 ? "zoomSelector "+hideButton : "zoomSelector d-blk"} style={{ display: 'none', right: '40%' }} >
                                {
                                    zoomLevelArray.map(g => {
                                        return (
                                            <span key={g.id + '-level'} className={"fa-stack maxdivcontrol-spacing "+buttonSize}>
                                                <I18n>
                                                    {({ i18n }) =>
                                                        <a onClick={(!this.state.bShareAsset) ? () => this.onClickZoomLevel(g.id) : () => {}}>
                                                            <i className={(this.state.bShareAsset) ? "fas fa-circle font-awesome-grey fa-stack-2x" : "fas fa-circle fa-stack-2x"}></i>
                                                            <span className="fa-stack-1x fa-zoom-text" value={g.id} 
                                                                style={ (this.state.zoom_level === g.id) ? { fontWeight: '600', color: '#ff0000' } 
                                                                        : { fontWeight: '600', color: 'black' }}>
                                                                {this.processZoomLevels(g.name, i18n)}
                                                            </span>
                                                        </a>
                                                    }
                                                </I18n>
                                            </span>
                                        )
                                    })
                                }
                                {/* <div className="arrow-down"></div> */}
                            </div>
                            :
                            null
                        }
                        <div id='maxdivcontrols'>
                            {this.state.bHideAnnotateColour === false && this.state.bAnnotate  ? 
                                <span className={"fa-stack "+buttonSize+" "+hideButton} >
                                    { this.state.bShareAsset  ?  /* TP-1178 */
                                        <a id="colourpicker">
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-palette fa-stack-1x" style={penStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="colourpicker">                                            
                                                    <Trans>session.colourpicker</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                        :
                                        <a id="colourpicker" onClick={() => this.onClickPenIcon()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-palette fa-stack-1x" style={penStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="colourpicker">                                            
                                                    <Trans>session.colourpicker</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                    }
                                </span>
                                :
                                ''
                            }
                            { behaviour !== "pndsafety" /**TP-6373*/ ?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize}>   
                                    { this.state.bShareAsset ?
                                        <a id="telestrate">
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-edit fa-stack-1x" style={annotateStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="telestrate">                                            
                                                    <Trans>session.telestrate</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                        :
                                        <a id="telestrate" onClick={() => this.onClickAnnotate()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-edit fa-stack-1x" style={annotateStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="telestrate">                                            
                                                    <Trans>session.telestrate</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                    }
                                                            
                                </span>                            
                                :
                                ''
                            }
                            {((enable_remote_capture === undefined || enable_remote_capture === true) && this.props.isSideBarSS !== true && this.state.expertShareScreenMaxDiv !== true && this.state.techShareScreen !== true) ?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize}> 
                                    { this.state.bShareAsset ?
                                        <a id="remoteCapture">
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-camera fa-stack-1x" style={cameraStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="remoteCapture">                                            
                                                    <Trans>session.remoteCapture</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                        :
                                        <a id="remoteCapture" onClick={() => this.onClickCamera()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-camera fa-stack-1x" style={cameraStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="remoteCapture">                                            
                                                    <Trans>session.remoteCapture</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>                                                                        
                                    }
                                </span>
                                :
                                ''
                            }
                            {(!this.props.bHideTorchButton && !this.props.isSideBarSS && !this.state.expertShareScreenMaxDiv && !this.state.techShareScreen)?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize}>                                    
                                    { this.props.bRemoteCapture || this.state.bShareAsset ?
                                        <a id="flashLight">
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-bolt fa-stack-1x" style={torchStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="flashLight">                                            
                                                    <Trans>session.flashLight</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                        :
                                        <a id="flashLight" onClick={() => this.onClickTorchIcon()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-bolt fa-stack-1x" style={torchStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="flashLight">                                            
                                                    <Trans>session.flashLight</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>                                                                             
                                    }
                                </span>
                                :
                                ''
                            }
                            {(/*TP-2933*/!this.props.bHideZoomButton && !this.props.isSideBarSS && !this.state.expertShareScreenMaxDiv && !this.state.techShareScreen) ?
                                <span className={"fa-stack maxdivcontrol-spacing rel "+buttonSize}>
                                    { this.props.bRemoteCapture || this.state.bShareAsset ?
                                        <a id="zoom">
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-binoculars fa-stack-1x" style={zoomStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="zoom">                                            
                                                    <Trans>session.zoom</Trans>                                            
                                                </UncontrolledTooltip>   
                                                :                                              
                                                ''
                                            }
                                        </a> 
                                        :
                                        <a id="zoom" onClick={/*TP-2933*/hideButton === 'd-none' && window.innerHeight <= 420 ? () => this.onClickZoomRotate() : () => this.onClickZoomIcon()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-binoculars fa-stack-1x" style={zoomStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="zoom">                                            
                                                    <Trans>session.zoom</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                    }
                                </span>
                                :
                                ''
                            }
                            {!(this.props.isiOS && this.props.isSafari) && this.state.bHideExpertSSButton === false && (
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+hideButton}>
                                {this.props.bRemoteCapture || (this.state.bShareAsset && !this.props.isMaxDivSS && !this.props.isSideBarSS) /**TP-1426 */ /*TP-1255*/?                                     
                                    <a id="expertSS" >
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fab fa-chromecast font-awesome-grey fa-stack-1x"></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="expertSS">                                            
                                                <Trans>session.expertSS</Trans>                                            
                                            </UncontrolledTooltip> 
                                            :
                                            ''
                                        }
                                    </a>                                        
                                    :                                    
                                    <a id="expertSS" onClick={/*TP-5397*/ this.props.isSSOngoing ? () => {return false;}  : () => this.expertScreenShareClick()}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fab fa-chromecast fa-stack-1x" style={expertShareStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="expertSS">                                            
                                                <Trans>session.expertSS</Trans>                                            
                                            </UncontrolledTooltip>
                                            : 
                                            ''
                                        }
                                    </a>                                        
                                }
                                </span>)}
                            {this.state.bHideTechnicianSSButton === false && (
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+hideButton}>
                                    {this.props.bRemoteCapture ? 
                                        <a id="techSS">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-tablet-alt font-awesome-grey fa-stack-1x"></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="techSS">                                            
                                                    <Trans>session.techSS</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a> 
                                        :
                                        <a id="techSS" onClick={() => this.onTechScreenShareClick()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-tablet-alt fa-stack-1x" style={techShareStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="techSS">                                            
                                                    <Trans>session.techSS</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                    }                                    
                                </span>)}
                            { behaviour !== "pndsafety" /**TP-6373*/ ?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize}>
                                    { this.state.bRemoteCapture /* || this.props.isMaxDivSS || this.props.isSideBarSS TP-1426*/ /*TP-1255*/ ?                                    
                                        <a id="shareAsset" className="disabled">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-upload font-awesome-grey fa-stack-1x" ></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="shareAsset">                                            
                                                    <Trans>session.shareAsset</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>                                        
                                        :                                    
                                        <a id="shareAsset" onClick={() => this.onClickUpload()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-upload fa-stack-1x" style={shareStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="shareAsset">                                            
                                                    <Trans>session.shareAsset</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>                                        
                                    }
                                </span>
                                :
                                ''
                            }
                            { behaviour !== "pndsafety" ?
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+hideButton}>
                                    { this.state.bRemoteCapture || this.state.bAnnotate ?                                    
                                        <a id="chat" className="disabled">
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-comments font-awesome-grey fa-stack-1x" ></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chat">                                            
                                                    <Trans>session.chat</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>                                        
                                        :                                    
                                        <a id="chat" onClick={() => this.onClickMaxChat()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="far fa-comments fa-stack-1x" style={chatStyle}></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chat">                                            
                                                    <Trans>session.chat</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>                                        
                                    }
                                </span>
                                :
                                ''
                            }
                            {/* T32-413 -- Disabled Speaker of Remote user implementation */}
                            <span className={"fa-stack maxdivcontrol-spacing "+buttonSize}> 
                                { this.state.maxDivSpeakerDisabled === false ?
                                    <a id="toggleAudio" onClick={() => this.onClickMuteSpeaker()}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-volume-up fa-stack-1x" style={{ color: this.state.bRemoteMute ? this.props.maxDivParticipant.isSpeaking ? '#1338BE' : '#ff0000' : this.props.maxDivParticipant.isSpeaking ? '#1338BE' : 'black' }}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="toggleAudio">                                            
                                                <Trans>session.toggleAudio</Trans>                                            
                                            </UncontrolledTooltip>  
                                            :
                                            ''
                                        }
                                    </a>  
                                    :
                                    <a id="toggleAudio">
                                        <i className="fas font-awesome-grey fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-volume-mute fa-stack-1x" style={{ color: '#ff0000' }}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="toggleAudio">                                            
                                                <Trans>session.toggleAudio</Trans>                                            
                                            </UncontrolledTooltip>  
                                            :
                                            ''
                                        }
                                    </a> 
                                }                               
                            </span>
                            <span className={"fa-stack maxdivcontrol-spacing "+buttonSize}>                                
                                <a id="muteMikeEach" onClick={() => this.onClickMuteMike()}>
                                    <i className="fas fa-circle fa-stack-2x"></i>
                                    <i className="fas fa-microphone fa-stack-1x" style={{ color: maxDivMuteMicColor }}></i>
                                    { isSmallFormFactorDevice === false ?
                                        <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteMikeEach">                                            
                                            <Trans>session.muteMike</Trans>                                            
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>                                    
                            </span>
                            { (!this.props.isSafari && this.state.bRemoteCapture /* && this.props.screen_recording_enabledTP-5512 */) ? 
                                <span className={"fa-stack maxdivcontrol-spacing "+buttonSize}>
                                        <a id="captureScreen" onClick={() => this.props.takeScreenShot()} className={captureScreenClass}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className='far fa-save fa-stack-1x'></i>
                                            { isSmallFormFactorDevice === false ?
                                                <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="captureScreen">                                            
                                                    <Trans>session.captureScreen</Trans>                                            
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>                                                                              
                                </span>
                                :
                                ''
                            }
                        </div>
                        {/*(!(/* this.props.isiOS &&  this.props.isSafari) && !this.props.isFirefox && this.props.screen_recording_enabled && this.state.showHideMaxDivControl) ?
                                <div id='recording_button' className=''>
                                    <span style={(window.innerWidth > 1299) ? (this.props.maxView === true) ? {top: "8%"} : {bottom: "25px"} : (this.props.maxView === true) ? {top: "14%"} : {bottom: "10px"}} className={!(this.props.isiOS && this.props.isSafari)? "fa-stack position-absolute rec-button "+buttonSize+" "+hideButton : "fa-stack position-absolute rec-button "+buttonSize}>                                        
                                        <SessionIcon id="recordScreen" circleClass={ "fas fa-circle fa-stack-2x"}
                                            iconClass={'fas ' + maxDivRecordScreenIcon + ' fa-stack-1x'} iconStyle={{ color: maxDivRecordScreenColor }} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip={this.state.startRecording ? "session.stopRecordScreen" : "session.recordScreen"} tooltipStyle="Trans"
                                            onClickMethod = {() => this.props.onClickRecordScreen()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>
                                        
                                    </span>
                                </div>
                                :
                                ''
                        */}
                    </div>
                    { this.requestMaxDivControlDialog() }
                </div>
            );

        }else{
            //console.log(this.state.showRc, this.props.maxView, this.state.isYieldingControl, this.props.disableMaxDivControls);
            styleDivMaxBtnContainer = {position: 'absolute', left: (this.state.showRc /**TP-3341*/) ?  '47.5%' : '49%', top: '90%', backgroundColor: 'transparent', zIndex:1060};
            return(
                <div className='flex-container' id='flex-container' style={{backgroundColor: '#1c2238'}}>
                    <div id='maxdivcontrols' style={styleDivMaxBtnContainer}>
                        <span className={"fa-stack "+buttonSize+" "+hideButton}>                            
                            <a id="chat" onClick={() => this.onClickMaxChat()}>
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="far fa-comments fa-stack-1x" style={chatStyle}></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chat">                                            
                                        <Trans>session.chat</Trans>                                            
                                    </UncontrolledTooltip> 
                                    :
                                    ''
                                }
                            </a>                                
                        </span>
                        { (enable_remote_capture === undefined || enable_remote_capture === true) &&
                            this.state.showRc &&
                            <span className={"fa-stack maxdivcontrol-spacing "+buttonSize}>  
                                { this.props.disableMaxDivControls === false && this.state.isYieldingControl === false /**TP-5851*/ && this.state.isPrepareExpertSS === false /**TP-5852*/ ?
                                    <a id="localCapture" onClick={() => this.onClickexpertRcSignal()}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-camera fa-stack-1x" style={cameraStyle}></i>
                                        { isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="localCapture">                                            
                                                <Trans>session.localCapture</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>  
                                    :
                                    <a id="localCapture" >
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-camera fa-stack-1x font-awesome-grey"></i>
                                        { isSmallFormFactorDevice === false ? 
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="localCapture">                                            
                                                <Trans>session.localCapture</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>  
                                }                              
                            </span>
                        }
                        {/**TP-4319 & TP-3551 & TP-2539 & TP-3074*/}
                        {this.props.showToggleIcon === true && isMobileDevice /**TP-4277*/ /**TP-3698*/  ?
                            <span className={"fa-stack maxdivcontrol-spacing "+buttonSize} style={{"verticalAlign": "top"}}>
                                { 1==1 /*TP-4683(this.props.is_grid === true || (this.props.is_grid === false && this.props.max_view_user !== this.props.user.email)) && this.props.isHeaderFileShare === false /**TP-4422*/ ?
                                    <a id="swapView" onClick={() => this.onSwapView()}
                                    /* onClick={isMobileDevice ? () => this.onSwapView(): setMaxView === true ? () => this.onClickTechGridView() : () => {return null}}*/  >
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        {/* <i className="fas fa-desktop fa-stack-1x "></i> */}
                                        <i className="fas fa-chevron-right fa-stack-1x "></i>
                                        {/* isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="swapView">                                            
                                                <Trans>{isMobileDevice ? "session.swapView" : setMaxView === true ? "Click to view all participants": "All participants"}</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        */}    
                                    </a>
                                    :
                                    <a id="swapView" >
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-chevron-right fa-stack-1x font-awesome-grey"></i>
                                        {/* isSmallFormFactorDevice === false ?
                                            <UncontrolledTooltip innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="swapView">                                            
                                                <Trans>{isMobileDevice ? "session.swapView" : setMaxView === true ? "Click to view all participants": "All participants"}</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        */}    
                                    </a>
                                }
                            </span>
                            :
                            ''
                        }
                        { /**TP-4319 & TP-4264 & TP-3213*/ this.props.showToggleIcon === true && !isMobileDevice /**TP-4277*/ ?
                            <span className={"fa-stack maxdivcontrol-spacing "+buttonSize} style={{"verticalAlign": "top"}}>
                                { 1==1 /*TP-4683(this.props.is_grid === true || (this.props.is_grid === false && this.props.max_view_user !== this.props.user.email /**TP-4264)) && this.props.isHeaderFileShare === false /**TP-4422*/ ?
                                    <a id="showMore" onClick={() => this.props.toggleShowPanel()}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className={this.state.showPanel ? "fas fa-chevron-down fa-stack-1x" : "fas fa-chevron-up fa-stack-1x"} style={sidePanelStyle}></i>
                                        {/* <i className="fas fa-ellipsis-v fa-stack-1x" style={sidePanelStyle}></i> */}
                                        {/* <i className="fas fa-bars fa-stack-1x "style={sidePanelStyle}></i> */}
                                        {/* <i className="fas fa-desktop fa-stack-1x " style={sidePanelStyle}></i> */}
                                        { isSmallFormFactorDevice === false ? 
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="showMore">                                            
                                                {this.state.showPanel ? expertVideoArr.length > 2 ? <Trans>session.hideUsers</Trans> : <Trans>session.hideUser</Trans> : expertVideoArr.length > 2 ? <Trans>session.showUsers</Trans> : <Trans>session.showUser</Trans>}                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                    :
                                    <a id="showMore">
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-chevron-up fa-stack-1x font-awesome-grey"></i>
                                        { isSmallFormFactorDevice === false ? 
                                            <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="showMore">                                            
                                                {this.state.showPanel ? expertVideoArr.length > 2 ? <Trans>session.hideUsers</Trans> : <Trans>session.hideUser</Trans> : expertVideoArr.length > 2 ? <Trans>session.showUsers</Trans> : <Trans>session.showUser</Trans>}                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                }
                            </span>
                            :
                            ''
                        }
                    </div>
                </div>
            )
        }
    }
}

export default StreamActions;