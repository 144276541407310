import React from 'react';
import { Form, Modal, ModalHeader, ModalBody, Row, UncontrolledTooltip, Col } from 'reactstrap';
import Select from 'react-select';
import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import '../Landing/Groups.scss';
import AuthService from '../AuthService';
import Loader from '../Loader/Loader';
import SimpleReactValidator from 'simple-react-validator';
import makeAnimated from 'react-select/lib/animated';
import { printConsole, showOriginalFileName, isFileAllowed, getHostAbbreviationForSocket } from '../helpers';
import DatePicker from "react-datepicker";
import {uploadFile} from 'react-s3';
const fileDialog = require('file-dialog');
const AWS = require('aws-sdk');
AWS.config.update({
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
});
const s3 = new AWS.S3();//instantiate s3

class AssetForm extends React.Component{
    constructor(props){
        super(props);
        this.authService = new AuthService();
        this.state = {
            isLoading: false,
            retCode: 400,
            errStrReturned: '',
			bSubmitted: false,
            bResponded: false,
            selectedAsset: {account_id: "", asset_class_id: '', asset_description: "", geo_location: "", asset_installed_at: "", 
                references: [], remove_reference_materials: [], model_references: [], remove_model_references: [], comments: ""},
            allAssetClassData: [], selectedAssetClass: '',
            isAssetLoading: false, fileToLarge: false,
            action: '',
            loadingText: "Loading",
            invalidFile: false,
            fileNameArr: [],
            selectedField: ''
        }
        this.config = {
            bucketName: process.env.REACT_APP_S3_BUCKET,
            dirName: 'jobs', /* optional */
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        };
		// initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({
            locale: props.language,
            autoForceUpdate: this,
            element: message => <div className="job-form-validation-msg">{message.replace(/field|The|[0-9]/g, '').capitalize()}</div>
		});        
    }

    componentDidMount = () => {
        let {selectedAsset, name, userid} = this.props
        if (selectedAsset){
            this.setState((prevState) => ({
                    selectedAsset: {
                    ...prevState.selectedAsset, ...selectedAsset,//TP-4417
                    asset_installed_on: new Date(selectedAsset.asset_installed_on || new Date()),
                    asset_installation_end_date: new Date(selectedAsset.asset_installation_end_date || new Date())
                }
            }),() => {
                if(this.props.action === 'edit') this.fetchIndividualAsset();
            })
        }else{
            this.setState((prevState) => (
                {selectedAsset: {...prevState.selectedAsset, owner_id: userid, author_name: name, asset_installed_on: new Date(), asset_installation_end_date: new Date((new Date()).setDate((new Date()).getDate() +1))}}
            ))
        }
        this.fetchAssetClass();
    }

    oncloseAssetForm = async () => {
        try{
            this.props.changeAssetView({showAssetForm: false});
        }catch(error){
            printConsole(`${error}`)
            this.props.changeAssetView({showAssetForm: false});
        }
    }

    getAssetClassOptions = () => {
        return this.state.allAssetClassData.map(p => {
            return {
                value: p.id,
                label: p.name
            }
        });
    }

    handleAssetClassChange = (selectedAssetClass) => {
        this.setState({
            selectedAsset: {...this.state.selectedAsset, asset_class_id: selectedAssetClass.value},
            selectedAssetClass
        });
    }

    onChangeAsset = (fieldName) => ({ target }) => {
		this.setState({
            selectedAsset: {...this.state.selectedAsset, [fieldName]: target.value.trimStart()}//TP-5417
        });
    }

    getSelectedAssetClass = () =>{
        const {allAssetClassData, selectedAsset} = this.state
        if(selectedAsset.asset_class_id && allAssetClassData && allAssetClassData.length > 0){
            const assetClass = allAssetClassData.find((assetClass) => assetClass.id === selectedAsset.asset_class_id);
            if(assetClass){
                this.setState(() => ({selectedAssetClass: {value: assetClass.id, label: assetClass.name}}))
            }
        }
    }

    onDateChange = (date, e, fieldName) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        this.setState(prevState => ({
            selectedAsset: {...prevState.selectedAsset, [fieldName]: date},
        }));
    }

    openFileUpload = ({fieldName})=> (e) => {
        e.preventDefault();
        const constraints = fieldName === 'model_references' ? { accept: '.jpg, .png, .jpeg' } : { accept: '.mp4, .jpg, .pdf, .png, .jpeg' };
        const allowed_file_type = fieldName === 'model_references' ? ['image/png', 'image/jpeg'] : ['application/pdf', 'image/png', 'image/jpeg', 'video/mp4'];
        fileDialog(constraints)
        .then( async (files) => {
            const localAssets = Object.values(files);
            if(!isFileAllowed({localAssets, allowed_file_type})){
                this.setState({invalidFile: true, selectedField: fieldName})
                return false;
            }
            let modifiedFiles = [];
            localAssets.map((f) => {
                let newFileName = f.name.replace(/\+/g, "__").replace(/\ /g, "");//TP-3535
                let file = new File([f], (Date.now() + '_' + newFileName), { type: f.type });
                modifiedFiles.push(file);
            })
            this.setState({ isAssetLoading: true });
            const reference_material = await this.uploadAssetFile(modifiedFiles);
            this.setState({isAssetLoading: false, fileNameArr: []},() => {
                this.attachFile({fieldName, fileNameArr: reference_material})
            })
        })
        .catch(err => {
            this.setState({isAssetLoading: false, fileNameArr: []})
            if(err === "File too large"){
                this.setState({fileToLarge: true})
            }
            printConsole(`error in openFileUpload ${JSON.stringify(err)}`);
        })
    }

    uploadAssetFile = async (localAssets) => {
        const storage_config = this.props.customer && this.props.customer.storage_config ?  this.props.customer.storage_config : null;
        let retdata;
        for (const file of localAssets) {
            //TP-3063
            if(storage_config){
               retdata = await this.sendFileToBackend(file); //TP-5081
            }else{
                retdata = await this.s3FileUpload(file); //TP-5081
            }
            this.setState(prevState => ({
                fileNameArr: [...prevState.fileNameArr,{key: retdata.key, location: retdata.location, file_name: file.name}]//used for s3 and others
            }));
        }
        return this.state.fileNameArr;
    }

    sendFileToBackend = (file) => {
        const formdata = new FormData(), hostAbbr = getHostAbbreviationForSocket();//TP-1945
        if(hostAbbr !== ""){
            formdata.append('file', file); formdata.append('folder_name', `jobs/${hostAbbr}`);
        }else{
            formdata.append('file', file); formdata.append('folder_name', 'jobs');
        }
        return new Promise((resolve, reject) => {
            // push selected file to server
            this.authService.fetch('upload/files', {
                "method": "POST",
                body: formdata
            }, {
                //This is the header part for override the header of the form submission
            })
            .then(response => {
                if(response && response.status === 200){
                    return response.json()
                }else{
                    reject("File too large");//TP-4942
                }
            })
            .then(retdata => {
                resolve({...retdata});
            })
            .catch(err => {
                printConsole(`upload file error ${err}`)
            })
        })
    }

    // upload files to s3
    s3FileUpload = (file) => {
        //TP-5318
        const hostAbbr = getHostAbbreviationForSocket();//TP-1945
        if(hostAbbr !== ""){
            this.config.dirName = `jobs/${hostAbbr}`;
        }
        return new Promise((resolve, reject) => {
            uploadFile(file, this.config)
            .then(retdata => {
                resolve({...retdata });
            })
            .catch(err => {
                this.setState({isAssetLoading: false})
                printConsole(`error in s3FileUpload ${JSON.stringify(err)}`);
            })
        });
    }

    attachFile = ({fieldName, fileNameArr}) => {
        this.setState((prevState) => ({
            selectedAsset: {...prevState.selectedAsset, [fieldName]: [...prevState.selectedAsset[fieldName],...fileNameArr]}
        }))
    }

    invalidFileDialog = () => {
        return (
            <div>
                <Modal isOpen={this.state.invalidFile} toggle={() => this.setState({ invalidFile: false })}>
                    <ModalHeader toggle={() => this.setState({ invalidFile: false })} cssModule={{ 'modal-title': 'w-100 text-center text-danger' }}><Trans>File Alert</Trans></ModalHeader>
                    <ModalBody>
                        <div className="text-center text-danger">
                            {this.state.selectedField === 'model_references' ? <Trans>only image file</Trans> : <Trans>invalid file</Trans>}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    //TP-4942
    fileToLargeDialog = () => {
        return (
            <div>
                <Modal isOpen={this.state.fileToLarge} toggle={() => this.setState({ fileToLarge: false })}>
                    <ModalHeader toggle={() => this.setState({ fileToLarge: false })} cssModule={{ 'modal-title': 'w-100 text-center text-danger' }}><Trans>File Alert</Trans></ModalHeader>
                    <ModalBody>
                        <div className="text-center text-danger">
                            <Trans>big_file</Trans>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
    
    detachFiles = ({e, fieldName, fieldValue}) => {
        try{
            e.preventDefault();
            
            let {selectedAsset} = this.state;
            let {references, model_references} = selectedAsset;
            const storage_config = this.props.customer && this.props.customer.storage_config ?  this.props.customer.storage_config : null;
            if(fieldName === 'remove_reference_materials'){
                references = selectedAsset.references.filter((r) => r.file_name !== fieldValue.file_name);//remove from references
            }
            if(fieldName === 'remove_model_references'){
                model_references = selectedAsset.model_references.filter((r) => r.file_name !== fieldValue.file_name);//remove from model references
            }
            if(fieldValue.id){
                const fieldNameValue = selectedAsset[fieldName] ? [...selectedAsset[fieldName], fieldValue] : [fieldValue];//TP-2936
                this.setState((prevState) => ({
                    selectedAsset: {...prevState.selectedAsset, [fieldName]: fieldNameValue, references, model_references}
                }))
            }else{
                const keyArr = [{Key: fieldValue.key}];
                const filesToDelete = [{Key: fieldValue.key, location: fieldValue.location}];
                // if(storage_config){
                    this.sendFilesTodelete({filesToDelete});
                // }else{
                //     this.deleteS3Files({filesToDelete: keyArr});
                // }
                this.setState((prevState) => ({
                    selectedAsset: {...prevState.selectedAsset, references, model_references}
                }))
            }
        }catch(error){
            printConsole(`error in detachFiles ${error}`)
        }
    }

    deleteS3Files = async ({filesToDelete}) => {
        try{
            s3.deleteObjects({
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Delete: {Objects: filesToDelete, Quiet: false }
                }, (err, data) => {
                    if(err){
                        printConsole(`error in deleteS3Files ${JSON.stringify(err)}`);
                    }else{
                        printConsole(`file deleted ${JSON.stringify(data)}`); // successful response
                    }
                }
            );        
        }catch(error){
            // printConsole(`error in deleteS3Files ${JSON.stringify(error)}`);
        }
    }
    
    //TP-3057
    sendFilesTodelete = async ({filesToDelete}) => {
        this.authService.fetch(`deleteFiles`, {
            method: 'delete',
            body: JSON.stringify({
                files_to_delete: filesToDelete,
            })
        })
        .then(response => {
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .catch((error) => {
            printConsole("sendFilesTodelete error ------>", error);
        });
    }

    fetchAssetClass = () => {
        this.setState({ 
            isLoading: true
        });

        let fetchString = `jobs/getAssetClass/${true}`;
        this.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                let allAssetClassData = data;
                this.setState({ allAssetClassData, isLoading: false,
                },() => this.getSelectedAssetClass());
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            printConsole(`error in fetchAssetClass ===========> ${JSON.stringify(err)}`)
        })
    }

    fetchIndividualAsset = () => {
        this.setState({ isLoading: true});

        let fetchString = `jobs/getAssetDetails`;
        this.authService.fetch(fetchString, {
            method: 'post',
            body: JSON.stringify({id: this.state.selectedAsset.id, account_id: this.props.accountId})
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                this.setState((prevState) => ({ selectedAsset: {...prevState.selectedAsset, references: data.references,
                    model_references: data.lookup_images//TP-2629
                }, isLoading: false
                }));
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            printConsole(`error in fetchAssetClass ===========> ${JSON.stringify(err)}`)
        })
    }

    onAddAsset = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
			this.validator.showMessages();
			return;
		}

        const { selectedAsset } = this.state;
		this.setState({ bSubmitted: true, errStrReturned: i18nMark('Submitting new asset details')});
        this.authService.fetch('jobs/addAsset', {
            method: 'post',
            body: JSON.stringify({...selectedAsset, account_id: this.props.accountId})
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(() => {
            this.setState({ bSubmitted: false}, () => {
                this.props.fetchAsset();//reload the asset
                this.props.changeAssetView({showAssetForm: false});
            });
        })
        .catch((error) => {
            this.setState({
                bSubmitted: false, bResponded: true, retCode: error.status,
                errStrReturned: i18nMark('Failed to add the new asset. Please review the entries and resubmit.')
            });
            printConsole(`error in onAddAsset ===========> ${JSON.stringify(error)}`)
        });
    }

    onEditAsset = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
			return;
		}
        const { selectedAsset } = this.state;
		this.setState({
            bSubmitted: true, errStrReturned: i18nMark('Submitting asset details')
        });
        this.authService.fetch('jobs/editAsset', {
            method: 'put',
            body: JSON.stringify({...selectedAsset, account_id: this.props.accountId})
        })
        .then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
				return response.json();
			} else {
				throw new Error(response.body);
            }
        })
        .then(() => {
            this.setState({
                bSubmitted: false, bResponded: true, errStrReturned: i18nMark('Asset successfully updated!')
            },() => this.props.fetchAsset())
        })
        .catch((error) => {
            this.setState({
                retCode: error.status, errStrReturned: i18nMark('Failed to edit the asset. Please review the entries and resubmit.')
            });            
            printConsole(`error in onEditAsset ===========> ${JSON.stringify(error)}`)
        });
    }
    //TP-2784
    setLanguage = (value, i18n) => {
        switch(value) {
            case "Loading":
                return i18n._(t`Loading`);
            default:
                return i18n._(t`Loading`);
        }
    }

    
    render(){
        let {selectedAsset, selectedAssetClass} = this.state
        let {action, isSmallFormFactor} = this.props;
		if(this.state.bSubmitted || this.state.bResponded){
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
                <>
                    <div className='modale opened'>
                        <div className='__modal-dialog'>
                            <form>
                                <div className="__modal-header">
                                    <h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
                                </div>
                                <div className="__modal-footer flex-center">
                                    <button className="btn-green" onClick={() =>this.props.changeAssetView({showAssetForm: false})}><Trans id='OK'/></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
			);
		} else {
            //TP-3260 -- Embedded Form UI
			return(
                <>
                    <div className='formmodule center'>
					    <div className='dialog form-color width-min'>
                            <div className="formheader flex-center row">
                                <h2>
                                    <Trans id={(action === 'edit' && selectedAsset) ? 'Edit Asset': 'Add Asset'}></Trans>
                                </h2>
                            </div>
                            <div className="formbody form-text-color ">
                                <Form>
                                    <div className="jobs-form-body pb-0">
                                        <Row className="margin-top-15 mb-1">
                                            <Col id="asset_name" className="input-wrapper">
                                                <I18n>
                                                    {({ i18n }) =>
                                                        <>
                                                            <input className='signup-input-field' name={i18n._(t`name`)} type="text" placeholder={i18n._(t` Enter the asset name`)} value={selectedAsset.asset_name} onChange={this.onChangeAsset('asset_name')} autoComplete="off"/>
                                                            { this.validator.message(i18n._(t`name`), selectedAsset.asset_name, 'required') }
                                                        </>									
                                                    }
                                                </I18n>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="asset_name">
                                                        <Trans>Asset Name</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    ''
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3 mb-1">
                                            <Col id="desc" className="input-wrapper">
                                                <I18n>
                                                    {({ i18n }) =>
                                                        <input className='signup-input-field' name={i18n._(t`description`)} type="text" placeholder={i18n._(t` Enter the asset Description`)} value={selectedAsset.asset_description} onChange={this.onChangeAsset('asset_description')} autoComplete="off"/>
                                                    }
                                                </I18n>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="desc">
                                                        <><Trans>Asset</Trans> <Trans>Description</Trans></>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    ''
                                                }
                                            </Col>
                                        </Row>
                                        <I18n>
                                            {({ i18n }) =>
                                                <> 
                                                    <Row className="mt-3 mb-2">
                                                        <Col id="geo_location" className="input-wrapper">
                                                            <input className='signup-input-field' type="text" value={selectedAsset.geo_location} name={i18n._(t`geo_location`)} placeholder={i18n._(t` Enter the asset Geo Location`)} onChange={this.onChangeAsset('geo_location')} autoComplete="off"/>
                                                            { isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="geo_location">
                                                                    <Trans>Geo Location</Trans>
                                                                </UncontrolledTooltip>       
                                                                :
                                                                ''
                                                            }
                                                        </Col>
                                                        <Col id="asset_instal" className="input-wrapper">
                                                            <input className='signup-input-field' type="text" value={selectedAsset.asset_installed_at} name={i18n._(t`asset_installed_at`)} placeholder={i18n._(t` Enter the asset Installed Location`)} onChange={this.onChangeAsset('asset_installed_at')} autoComplete="off"/>
                                                            { isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="asset_instal">
                                                                    <><Trans>Installed Location</Trans></>
                                                                </UncontrolledTooltip>       
                                                                :
                                                                ''
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </I18n>
                                        <I18n>
                                            {({ i18n }) =>
                                                <> 
                                                    <Row className="mt-3 mb-1">
                                                        <Col id="asset_cls" className="input-wrapper">
                                                            <Select
                                                                value={selectedAssetClass}
                                                                onChange={this.handleAssetClassChange}
                                                                placeholder={i18n._(t` Select An Asset Class`)}
                                                                components={makeAnimated()}
                                                                options={this.getAssetClassOptions()}
                                                                isSearchable={isSmallFormFactor === false ? true : false} /**TP-3031 */
                                                                className="text-black"
                                                            />                                                        
                                                            <input type="text" readOnly value={selectedAsset.asset_class_id} name={i18n._(t`asset_class_id`)} className="hidden"/>
                                                            { isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="asset_cls">
                                                                    <Trans>Asset Class</Trans>
                                                                </UncontrolledTooltip>       
                                                                :
                                                                ''
                                                            }
                                                            { this.validator.message(i18n._(t`asset_class`), selectedAsset.asset_class_id, 'required') }
                                                        </Col>
                                                        <Col id="instal_on" className="input-wrapper">
                                                            <DatePicker selected={selectedAsset.asset_installed_on} className="custom-date" /**TP-3031 */ onFocus={isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} popperPlacement="top-start" onChange={(date, e) => this.onDateChange(date, e, 'asset_installed_on')} />
                                                            <input type="date" readOnly value={selectedAsset.asset_installed_on} name={i18n._(t`installed_on`)} className="hidden"/>
                                                            { isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="instal_on">
                                                                    <Trans>Installed On</Trans>
                                                                </UncontrolledTooltip>       
                                                                :
                                                                ''
                                                            }
                                                        </Col>
                                                        <Col id="end_date" className="input-wrapper">
                                                            <DatePicker selected={selectedAsset.asset_installation_end_date} className="custom-date" /**TP-3031 */ onFocus={isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} popperPlacement="top-start" onChange={(date, e) => this.onDateChange(date, e, 'asset_installation_end_date')} />
                                                            <input type="date" readOnly value={selectedAsset.asset_installation_end_date} name={i18n._(t`installation_end_date`)} className="hidden"/>
                                                            { isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="end_date">
                                                                    <Trans>Installation End Date</Trans>
                                                                </UncontrolledTooltip>       
                                                                :
                                                                ''
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </I18n>
                                        <Row className="mt-3 mb-1">
                                            <Col id="comment" className="input-wrapper">
                                                <I18n>
                                                    {({ i18n }) =>
                                                        <input className='signup-input-field' name={i18n._(t`comments`)} type="text" placeholder={i18n._(t` Enter the asset Comments`)} value={selectedAsset.comments} onChange={this.onChangeAsset('comments')} autoComplete="off"/>
                                                    }
                                                </I18n>
                                                { isSmallFormFactor === false ? 
                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="comment">
                                                        <Trans>Comments</Trans>
                                                    </UncontrolledTooltip>       
                                                    :
                                                    ''
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col className="input-wrapper">
                                                    <button type="button" className="btn btn-info"
                                                        // disabled={Array.isArray(selectedAsset.references) &&  !selectedAsset.references.length ? false : true} 
                                                        onClick={this.openFileUpload({fieldName:'references'})}
                                                    >
                                                        <Trans>Attach reference material</Trans>
                                                    </button>
                                            </Col>
                                        </Row>
                                        {
                                            selectedAsset.references && selectedAsset.references.map((reference, index) => 
                                                <Row className="mt-2" key={index}>
                                                    <Col className="mt-1 input-wrapper">{reference.file_name ? showOriginalFileName({fileName: reference.file_name}) : ''}</Col>
                                                    <Col className="mt-1 input-wrapper">
                                                        {reference && reference.file_name ? 
                                                            <I18n>
                                                                {({ i18n }) =>
                                                                    <span className="pointer" onClick={(e) => this.detachFiles({e, fieldName:'remove_reference_materials', fieldValue: reference})}>
                                                                        <i className="far fa-trash-alt red-trash" id={`delete_reference_material-`+ index}></i>
                                                                        { isSmallFormFactor === false ?
                                                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={`delete_reference_material-`+index}>
                                                                                <Trans>Delete reference material</Trans>
                                                                            </UncontrolledTooltip>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </span>
                                                                }
                                                            </I18n>
                                                            : null
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        <Row className="mt-2">
                                            <Col className="mt-2 input-wrapper">
                                                <button type="button" className={Array.isArray(selectedAsset.model_references) &&  !selectedAsset.model_references.length ? "btn btn-info": "btn btn-secondary"} 
                                                    disabled={Array.isArray(selectedAsset.model_references) &&  !selectedAsset.model_references.length ? false : true} 
                                                    onClick={this.openFileUpload({fieldName:'model_references'})}
                                                >
                                                    <Trans>Attach lookup image</Trans>
                                                </button>
                                            </Col>
                                        </Row>
                                        {
                                            selectedAsset.model_references && selectedAsset.model_references.map((reference, index) => 
                                                <Row className="mt-2" key={index}>
                                                    <Col className="mt-1">{reference.file_name ? showOriginalFileName({fileName: reference.file_name}) : ''}</Col>
                                                    <Col className="mt-1">
                                                        {reference && reference.file_name ? 
                                                            <I18n>
                                                                {({ i18n }) =>
                                                                    <span className="pointer" onClick={(e) => this.detachFiles({e, fieldName:'remove_model_references', fieldValue: reference})}>
                                                                        <i className="far fa-trash-alt red-trash" id={`delete_model_image-`+ index}></i>
                                                                        { isSmallFormFactor === false ?
                                                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={`delete_model_image-`+index}>
                                                                                <Trans>Delete model image</Trans>
                                                                            </UncontrolledTooltip>
                                                                            :
                                                                            ''
                                                                        }
                                                                    </span>
                                                                }
                                                            </I18n>
                                                            : null
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        }                                    
                                    </div>
                                    <div className="mt-2">
                                        {(action === 'edit' && selectedAsset) ?
                                        <Row>
                                            <Col className="d-flex justify-content-end">
                                                <button className="btn-red" onClick={(e) =>this.oncloseAssetForm(e)}><Trans>Close</Trans></button>
                                            </Col>
                                            <Col className="d-flex justify-content-left">
                                                <button className="btn-green" onClick={(e) =>this.onEditAsset(e)}><Trans>Update</Trans></button>
                                            </Col>
                                        </Row>
                                        :
                                        <Row>
                                            <Col className="d-flex justify-content-end">
                                                <button className="btn-red" onClick={(e) =>this.oncloseAssetForm(e)}><Trans>Close</Trans></button>
                                            </Col>
                                            <Col className="d-flex justify-content-left">
                                                <button className="btn-green" onClick={(e) =>this.onAddAsset(e)}><Trans>Create</Trans></button>
                                            </Col>
                                        </Row>
                                        }
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    {this.invalidFileDialog()}
                    {this.fileToLargeDialog()}
                    {/**TP-2753 */}
                    <I18n>
                        {({ i18n }) =>
                            <Loader text={/*TP-2784*/this.setLanguage(this.state.loadingText, i18n)} isLoading={this.state.isAssetLoading} />
                        }
                    </I18n>
                </>
            );
		}
    }
}

export default AssetForm;
