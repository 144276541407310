import React, { Fragment } from 'react';
import './AccountManager.scss';
import Groups from './Groups';
import Users from './Users';
import {AccountConsumer} from"../AccountContext";
import UsageDetails from './UsageDetails';
// import Gps from '../GeoFence/gps';
import Jobs from '../GeoFence/jobs';
import AccountDetails from '../AccountDetails/AccountDetails';
import AuthService from '../AuthService';
import Loader from 'react-loader-spinner';
import { Trans } from '@lingui/macro';
import { i18nMark } from "@lingui/react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button as BButton } from 'reactstrap';
import Notification from 'react-web-notification';
import {diagonastics, diagnostics} from "../DiagonasticHelper";
import { printConsole, encryptData, getTimeStamp } from '../helpers'; /**TP-6222*/
import FlashMessage from '../FlashMessage/FlashMessage';
import Recordings from './Recordings';
import AssetClass from '../GeoFence/assetClass';
import Asset from '../GeoFence/asset';
import MyProfile from './MyProfile'; //TP-3672
import ChangePassword from '../ResetPassword/ChangePassword'; //TP-5504

const hiddenTabs = ["jobs", "asset_class", "asset"]; //TP-3756
class AccountManager extends React.Component { //= ({ onRouteChange, isSignedIn, route, email }) => {
	constructor(){
        super();
        this.authService = new AuthService()
	    this.state = {
            purchases: [],
            retCode: 400,
            plans: [],
            customer: {},
            paymentSources: [],
            defaultSource: '',
            errStrReturned: '',
            isLoading: false,
            isChanged: false, // TODO: Should be removed once we implement way to sync users updation.
            pageName: 'Groups',//eg: if users updted with isexpert its should be updated in groups.
            showGpsTab: false,
            showCallNotification: false,
            ignore: true,
            title: '',
            callerConnectData: {
                groupName: '',
                groupId: '',
                from: '',
                name: ''
            },
            isIOS: false,
            permitted_payment_failure_count: 2, //NS2-167
            groupInfo: [{
                groupid: '',
                isadmin: '',
                deleted_on: '',
                group_name: '',
                description: '',
                isongoingnow: 'false',
                streamin_mode: 'routed',
                users: []               
            }],
            groupsData: [], //TP-1735
            isMonthlyMinutesConsumed: false,//T32-462
            flashMsgText: "", //TP-1462
            showFlashMessage: false, //TP-1462
            flashValues: "", //TP-1462
            flashLeft: "", //TP-1462
            flashTop: "", //TP-1462
            callUser: false, //TP-1462
            inSessionArr: [], //TP-1534
            recordings: null, //TP-1661
            user: {},//TP-2721
            maxPerPage: 10, //TP-2883
            isMobileDevice: false, //TP-2892
            isVerySmallDevice: false, //TP-3459
            isSmallFormFactor: false, //TP-2947
            allUsersofAccount: null, //TP-4352
            isPartOfAGroup: false, //TP-4814
            refreshUser: false,//TP-6431            
            chatMsgInfoArr: [{ msgColor: '', messageID: '',
                firstName: '', lastName: '', message: '', timeStamp: '', msgType: 0 // 0 - indiv, 1 - group
            }], //TP-6222
            selectedUserEmail: '', //TP-6222
            openUserChat: false, //TP-6222
            selectedGroup: null, //TP-6222
            openGroupChat: false, //TP-6222
            showMissedCalls: false, //TZ-487
            userlist: [{
				id: '',
				email: '',
				first_name: '',
				last_name: '',
				salutation: '',
				password: '',
				created_on: '',
				last_accessed_on: '',
				isadmin: '',
				birth_month: '',
                birth_year: '',
                is_inactive: false,
				isexpert: '',
				isonline: '',
				geolocation: '',
				last_location_report_on: '',
				phonenumber: '',
				accountid: ''
            }], //TZ-487      
            refetchMissedcalls: false /**TZ-475*/
        }
        this.isMount = false;
        this.message = "chat"; //TP-6222
    }
	openPage(pageName, elemId, color) {
        sessionStorage.setItem("pageName", pageName);//TP-1579
        if (pageName === 'Groups' && this.state && this.state.isChanged) { /**TP-3871*/
            this.setRecordingsArray({recordings: null});
            this.setState({ isChanged: false, pageName });
            this.showHeaderAndFooter(true);//TP-4028
        } else if (pageName !== 'Recordings') {
            this.setRecordingsArray({recordings: null});
            this.setState({ pageName });
            //TP-4028
            if (pageName === "MyProfile")
                this.showHeaderAndFooter(false);
            else 
                this.showHeaderAndFooter(true);
        } else {
            this.setState({ pageName });
            //TP-4028
            if (pageName === "MyProfile")
                this.showHeaderAndFooter(false);
            else 
                this.showHeaderAndFooter(true);
        }
		// Hide all elements with class="tabcontent" by default */
		let i, tabcontent, tablinks;
		tabcontent = document.getElementsByClassName("tabcontent");
		for (i = 0; i < tabcontent.length; i++) {
			tabcontent[i].style.display = "none";
		}

		// Remove the background color of all tablinks/buttons
		tablinks = document.getElementsByClassName("tablink");
		for (i = 0; i < tablinks.length; i++) {
			tablinks[i].style.backgroundColor = "";
		}

		// Show the specific tab content
        if (document.getElementById(pageName)) {
            document.getElementById(pageName).style.display = "block";
        }

		// Add the specific color to the button used to open the tab content
        if (document.getElementById(elemId)) {
            document.getElementById(elemId).style.backgroundColor = color;
        }

        //TP-4067
        if (pageName === "MyProfile") {
            //TP-4491 -- Timer is commented out for this ticket
            //this.sizingTimer = setTimeout(() => {
                this.setState({triggerResizeMyProfile: true}, () => {
                    this.setState({triggerResizeMyProfile: false});
                })
                //clearTimeout(this.sizingTimer);
            //}, 2000);
            this.setState({refreshUser: false})//TP-6431
        }
        if (pageName !== "UsageDetails"){
            //TZ-487
            this.setState({ showMissedCalls: false });
        }
        /* diagonastics(this.authService,{
            action: `${pageName} tab Clicked`,
            next_step: '',
            data: 'none',
            error: 'none'
        }) */
	}

    //TP-4028
    showHeaderAndFooter = (val) => {
        const headerEl = document.getElementById("header_bar");
        const footerEl = document.getElementById("footer_bar");
        // const tabEl = document.getElementsByClassName("tab-container")[0];//TP-4753
        if (val === true) {            
            if (headerEl)
                headerEl.style.display = "block";
                
            // if (tabEl) 
            //     tabEl.style.display = "block";//TP-4753
            
            if (footerEl)
                footerEl.style.display = "block";
        } else {
            if (headerEl)
                headerEl.style.display = "none";
            
            // if (tabEl) 
            //     tabEl.style.display = "none";//TP-4753
            
            if (footerEl)
                footerEl.style.display = "none";
        }
    }

    //TP-5504
    openChangePassword = () => {
        //this.openPage('ChangePassword', 'ChangePasswordTab', '#2d3858');
        this.props.openChangePasswordPage(true);
    }
    //TP-5504
    updateOpenMyProfile = () => {
        //this.openPage('MyProfile', 'MyProfileTab', '#2d3858');
        this.props.openMyProfile(true);
    }
    
    updateOpenGroup = (val) => {
        if (val = true){
            //TP-6051
            if(this.state.customer.behaviour === "pndsafety"){
                if(this.props.isadmin){
                    this.openPage('Users', 'UsersTab', '#2d3858');
                }else if(this.props.isexpert){
                    this.openPage('jobs', 'jobsTab', '#2d3858');
                }else{
                    this.openPage('Groups', 'GroupsTab', '#2d3858');
                }
            }else{
                this.openPage('Groups', 'GroupsTab', '#2d3858');
            }
            this.setState({refreshUser: true})//TP-6431
        }
    }

    getAccountDetails = () => {
        this.setState({
            isLoading: true
        })
        this.authService.fetch(`getAccoutDetails/${this.props.accountid}`, {
            method: 'get'
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(({purchases, plans, customer, paymentSources = { data: []}, defaultSource}) => {
            if (purchases && plans && customer) {
                this.setState({
                    purchases,
                    plans,
                    customer,
                    paymentSources: paymentSources.data,
                    defaultSource,
                    isLoading: false
                },() => {
                    this.props.updateCustomerFlag(customer);
                    this.props.updatedAvailableCredits(purchases);
                     //NS2-167 payment failure greater than permissible limit,FQ3-246
                    if((this.state.customer.payment_failure_count > this.state.permitted_payment_failure_count) || (this.state.customer.is_lan_enterprise && !this.state.customer.is_lan_license_activated)){
                        this.openPage('AccountDetails', 'AccountDetailsTab', '#2d3858');
                    }

                    // TP-1579 -- Jobs page is set when the user lands onto the Homepage with the value in session storage
                    // set as 'jobs'
                    if (sessionStorage.getItem('pageName') === 'jobs' && this.props.isSmartAdmin === false && this.state.customer.is_gps_enabled && this.state.isSmallFormFactor === false /**TP-3756*/) {
                        //TP-3616 -- do not open Jobs tab for Mobile devices
                        this.openPage('jobs', 'jobsTab', '#2d3858');
                    }
                    if (sessionStorage.getItem('pageName') === 'asset_class' && this.props.isSmartAdmin === false && this.props.isadmin && this.state.customer.is_gps_enabled 
                        && this.state.isSmallFormFactor === false /**TP-3756*/ && this.state.customer.enable_asset_class !== "disabled") {
                        this.openPage('asset_class', 'assetClassTab', '#2d3858');
                    }

                    if (sessionStorage.getItem('pageName') === 'asset' && this.props.isSmartAdmin === false && this.state.customer.is_gps_enabled 
                        && this.state.isSmallFormFactor === false /**TP-3756*/ && this.state.customer.enable_asset_class !== "disabled") {
                        this.openPage('asset', 'assetTab', '#2d3858');
                    }
                    //TP-5815 only for tp users, by default componentDidMount set page name to groups so after api call check page name value and change it
                    if(this.state.customer.behaviour === "pndsafety" && sessionStorage.getItem('pageName') === "Groups"){
                        if(this.props.isadmin){
                            this.openPage('Users', 'UsersTab', '#2d3858');
                        }else if(this.props.isexpert){
                            this.openPage('jobs', 'jobsTab', '#2d3858');
                        }
                    }
                });
            }
        })
        .catch((error) => {
            this.setState({
                errStrReturned: error.message,
                isLoading: false
            });
        })
    }

    diagonastics = (data) => {
        let fetchString = 'logDiagnostics/';
        this.authService.fetch(fetchString, {
            method: 'post',
            body: JSON.stringify({
                data
            })
        })
        .then(response => response.json())
        .then(data => {
            // console.info(data)
            return data;
        })
        .catch(err => console.error(err.message))
        //return;
    }
    
	// set the default tab
	componentDidMount = () => {
        this.isMount = true;
        /**TP-2883 */
        const maxPerPage = this.getMaxRowsPerPage();
        this.setState({ maxPerPage });
        /**TP-2892 */
        const isMobileDevice = this.checkIsMobileDevice();
        this.setState({ isMobileDevice });
        /**TP-3459 */
        const isVerySmallDevice = this.checkIsVerySmallDevice();
        this.setState({ isVerySmallDevice });
        /**TP-2947 */
        const isSmallFormFactor = this.checkIsSmallFormFactorDevice();
        this.setState({ isSmallFormFactor });
        window.addEventListener("resize", this.updateDimensions);
        if (sessionStorage.getItem('refresh-page') !== undefined && sessionStorage.getItem('refresh-page') === "true") {
            sessionStorage.removeItem('refresh-page');
            window.location.reload();
        }
        this.getAccountDetails();
        //TP-3672 -- Update my own profile picture for Header
        //TP-3735
        //TP-566
        if(!this.props.autoCall || !this.props.userIdForAutocall || this.props.userIdForAutocall === ''){
            if (sessionStorage.getItem('gotoMissed') !== undefined && sessionStorage.getItem('gotoMissed') === "true") {
                //TZ-487
                sessionStorage.setItem("gotoMissed", "false");
                this.openPage('UsageDetails', 'UsageDetailsTab', '#2d3858');
                // TZ-540
                this.callTimerMiss = setTimeout(() => {
                    this.setState({ showMissedCalls: false }, () => {
                        this.setState({ showMissedCalls: true });
                    });
                    clearTimeout(this.callTimerMiss);
                }, 3000);
            } else {
                // TP-1579 -- Set the default selected tab based on the value stored in session storage
                const pageName = sessionStorage.getItem('pageName');
                if (pageName !== undefined && pageName !== null && pageName !== 'Recordings') {
                    //TP-3756 -- Diverting the view to Groups tab whenever any of the hidden tabs are clicked in small screen devices
                    if (hiddenTabs.indexOf(pageName) >= 0 && isSmallFormFactor === true){
                        this.openPage('Groups', 'GroupsTab', '#2d3858');
                    } else {
                        const tabName = pageName+'Tab';
                        this.openPage(pageName, tabName, '#2d3858');
                    }
                } else if (pageName === 'Recordings') {
                    this.openPage('UsageDetails', 'UsageDetailsTab', '#2d3858');
                } else {
                    //TP-5927
                    if(this.props.behaviour === "pndsafety"){
                        if(this.props.isadmin){
                            this.openPage('Users', 'UsersTab', '#2d3858');
                        }else if(this.props.isexpert){
                            this.openPage('jobs', 'jobsTab', '#2d3858');
                        }
                    }else{
                        this.openPage('Groups', 'GroupsTab', '#2d3858');
                    }
                }
            }
        }else{
            this.openPage('Users', 'UsersTab', '#2d3858');
        }
        // emitting the log-me-out signal
        // when forced login happens
        if (this.props.forcedLogin === true) {
            /* this.diagonastics({
                action: `Forced login has happened for current user`,
                sequence: 1,
                next_step: '',
                data: `forcedLogin flag: ${this.props.forcedLogin}`,
                error: 'none'
            }) */
            const json = {
                userIsDeleted: false,
                email: this.props.email,
                loginTimeStamp: this.props.last_accessed_on
            }
            printConsole(json);
            this.authService.socket.emit('log-me-out', json);
            /* this.diagonastics({
                action: `log-me-out signal is emitted `,
                sequence: 1,
                next_step: '',
                data: `payload: { ${json.userIsDeleted}, ${json.email}, ${json.loginTimeStamp} }`,
                error: 'none'
            }) */
        }
        this.authService.socket.on(this.message, this.subscribeToChatSignal); //TP-6222
        this.authService.socket.on('connect-the-call', this.subscribeToCallConnection);
        this.authService.socket.on('group-user-change', this.subscribeToGroupUserChange); // FQ3-712
        this.authService.socket.on('log-me-out', this.subscribeToLogMeOut);        // FQ3-712
        this.authService.socket.on('user-is-in-session', this.subscribeToUserInSession) //TP-1534
        this.authService.socket.on('user-details-updated', this.subscribeToUserDetailsUpdate) //TP-1534
        this.authService.socket.on('fetch-missed-call-notification', this.subscribeToMissedCallNotificationAlert)//TZ-487
        this.checkPlatform();
        this.checkMonthlyMinutes();//T32-462
    }

    componentDidUpdate = (prevProps, prevState) => {
        //TP-3019
        if (prevProps.enable_streaming !== this.props.enable_streaming) {
            const pageName = sessionStorage.getItem('pageName');
            if (pageName === 'UsageDetails' && this.props.enable_streaming === true) {
                this.openPage('UsageDetails', 'UsageDetailsTab', '#2d3858');
            }
        }
        if(prevProps.isOpenMyProfile !== this.props.isOpenMyProfile && this.props.isOpenMyProfile === true) {
            this.openPage('MyProfile', 'MyProfileTab', '#2d3858');
            this.props.openMyProfile(false);
        }
        //TP-5504
        if(prevProps.isOpenChangePassword !== this.props.isOpenChangePassword && this.props.isOpenChangePassword === true) {
            this.openPage('ChangePassword', 'ChangePasswordTab', '#2d3858');
            this.props.openChangePasswordPage(false);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        // refresh the groups and users tab to not show the removed guest users NS2-116
        if(nextProps.isChanged!==prevState.isChanged){
            return { isChanged: nextProps.isChanged};
        }
        else return null;
    }

    //TP-2892
    checkIsMobileDevice = () => {
        //console.log(window.innerWidth, window.innerHeight);
        return (window.innerHeight <= 420) ? true : false;
    }

    checkIsVerySmallDevice = () => {
        return (window.innerWidth <= 752) ? true : false;
    }

    //TP-2947
    checkIsSmallFormFactorDevice = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let isSmallFormFactor = false;
        if (isiPad || isAndroid) {
            isSmallFormFactor = true;
        } 
        return isSmallFormFactor;
    }

    //TP-2883
    getMaxRowsPerPage = () => {
        let maxHeight;
        if (document.getElementById("header_bar") && document.getElementById("header_bar") !== null) {
            maxHeight = window.innerHeight - (document.getElementById("header_bar").offsetHeight + 138 + 20);
        } else {
            maxHeight = window.innerHeight - (98 + 138 + 20);
        }
        let heightOfEachCell = 31;
        let tempNoRows = Math.ceil(maxHeight / heightOfEachCell);
        return (tempNoRows >= 8) ? tempNoRows - 5 : tempNoRows;
    }

    //TP-2883
    updateDimensions = () => {
        const maxPerPage = this.getMaxRowsPerPage();
        this.setState({ maxPerPage });
        /**TP-2892 */
        const isMobileDevice = this.checkIsMobileDevice();
        this.setState({ isMobileDevice });
        /**TP-3459 */
        const isVerySmallDevice = this.checkIsVerySmallDevice();
        this.setState({ isVerySmallDevice });
        /**TP-2947 */
        const isSmallFormFactor = this.checkIsSmallFormFactorDevice();
        this.setState({ isSmallFormFactor });
    }

    //TP-4352
    updateAllUsers = (data) => {
        this.setState({allUsersofAccount: data});
    }
    //TP-4814
    changeIspartOfaGroup = (value) => {
        if (value !== this.state.isPartOfAGroup)
            this.setState({ isPartOfAGroup: value });
    }

    //TP-2273 -- Commented for now since this isn't working
    /* setPushNotification = (from) => {
        let notification = new Notification('Notification title', {
            body: `${from} is calling you in Telepresenz. Click here to go to telepresenz`
        });
        notification.onclick = function () {
            window.focus();
        } 
        setTimeout(notification.close.bind(notification), 28000);
    } */
    //TP-6828 & TP-6222
    updateChatArr = (action, chatMsgInfo, messageID=null) => {
        let chatMsgInfoArrNew = [...this.state.chatMsgInfoArr];
        switch(action) {
            case 'sendChat':
                this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}), () => {
                    this.props.updateChatMsgArr(this.state.chatMsgInfoArr);
                })
                break;
            case 'deleteChat':                
                let [chatMsgInfoDeleted] = this.state.chatMsgInfoArr.filter(info => info.messageID === messageID);
                const index = this.state.chatMsgInfoArr.findIndex(info => info.messageID === messageID);
                chatMsgInfoDeleted.isDeleted = true;
                chatMsgInfoArrNew[index] = chatMsgInfoDeleted;
                this.setState({ chatMsgInfoArr: chatMsgInfoArrNew }, () => {
                    this.props.updateChatMsgArr(this.state.chatMsgInfoArr);
                });
                break;
            case 'editChat': 
                const ind = this.state.chatMsgInfoArr.findIndex(info => info.messageID == messageID);
                let [chatMsgInfoEdited] = this.state.chatMsgInfoArr.filter(info => info.messageID == messageID);
                chatMsgInfoEdited.isEdited = true;
                chatMsgInfoEdited.message = chatMsgInfo.message;
                chatMsgInfoEdited.timeStamp = chatMsgInfo.timeStamp;
                chatMsgInfoArrNew[ind] = chatMsgInfoEdited;
                this.setState({ chatMsgInfoArr: chatMsgInfoArrNew }, () => {
                    this.props.updateChatMsgArr(this.state.chatMsgInfoArr);
                });
                break;
            case 'replyChat':
                let [chatMsgInfoReply] = this.state.chatMsgInfoArr.filter(info => info.messageID === messageID);
                chatMsgInfo.isReplied = true;
                chatMsgInfo.replyMessage = {
                    replyMessage: chatMsgInfoReply.message,
                    firstName: chatMsgInfoReply.firstName,
                    lastName: chatMsgInfoReply.lastName
                }
                this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}), () => {
                    this.props.updateChatMsgArr(this.state.chatMsgInfoArr);
                });
                break;
        }
    }
    //TP-6222 -- new Chat feature socket signal listener
    subscribeToChatSignal = ({action, messageID, message, isGroupChat, to, from, groupId }) => {
        if (to !== undefined && to.email === this.props.email) {
            console.log("Chat signal received with payload: ", action, messageID, isGroupChat, groupId, message, to, from);
        } else if (to === undefined) {
            console.log("Chat signal received with payload: ", action, messageID, isGroupChat, groupId, message, from);
        } 
        let strNewMsg = message.msg_data, bIsDuplicate = false, senderInfo = from, timeStamp = getTimeStamp();
        const {email, name}= this.props;
        let chatMsgInfoArrNew = [...this.state.chatMsgInfoArr];
        //TP-6828
        if (action.send_msg === true) {
            let { myPicture } = this.props;
    
            this.state.chatMsgInfoArr.forEach(msgInfo => {
                if (messageID === msgInfo.messageID) //MB2-95
                    bIsDuplicate = true;
            })
            if(bIsDuplicate === false) {
                
                let chatMsgInfo = {
                    msgId: from.email, messageID: messageID, firstName: senderInfo.firstName,
                    lastName: senderInfo.lastName, message: strNewMsg, timeStamp: timeStamp, msgType: 0
                    /* myPicture: fromParticipant.myPicture */
                };
                this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}), () => {
                    this.props.updateChatMsgArr(this.state.chatMsgInfoArr);
                    //Handling Group chat feature
                    if (isGroupChat === true && groupId !== undefined){
                        this.openPage('Groups', 'GroupsTab', '#2d3858');
                        this.setState({ openGroupChat: true, selectedGroup: groupId }, () => {
                            this.setState({ openGroupChat: false, selectedGroup: null });
                        })
                    } else {
                        this.openPage('Users', 'UsersTab', '#2d3858');
                        this.setState({ openUserChat: true, selectedUserEmail: from.email}, () => {
                            this.setState({ openUserChat: false, selectedUserEmail: '' });
                        });
                    }
                })
            }
        } else if (action.delete_msg === true && this.state.chatMsgInfoArr.length > 1) {            
            let [chatMsgInfoDeleted] = this.state.chatMsgInfoArr.filter(info => info.messageID === messageID);
            const index = this.state.chatMsgInfoArr.findIndex(info => info.messageID === messageID);
            chatMsgInfoDeleted.isDeleted = true;
            chatMsgInfoArrNew[index] = chatMsgInfoDeleted;
            this.setState({ chatMsgInfoArr: chatMsgInfoArrNew }, () => {
                this.props.updateChatMsgArr(this.state.chatMsgInfoArr);
                //Handling Group chat feature
                if (isGroupChat === true && groupId !== undefined){
                    this.openPage('Groups', 'GroupsTab', '#2d3858');
                    this.setState({ openGroupChat: true, selectedGroup: groupId }, () => {
                        this.setState({ openGroupChat: false, selectedGroup: null });
                    })
                } else {
                    this.openPage('Users', 'UsersTab', '#2d3858');
                    this.setState({ openUserChat: true, selectedUserEmail: from.email}, () => {
                        this.setState({ openUserChat: false, selectedUserEmail: '' });
                    });
                }
            });
        } else if (action.edit_msg === true && this.state.chatMsgInfoArr.length > 1) {
            const ind = this.state.chatMsgInfoArr.findIndex(info => info.messageID == messageID);
            let [chatMsgInfo] = this.state.chatMsgInfoArr.filter(info => info.messageID == messageID);
            console.log(chatMsgInfo);
            chatMsgInfo.isEdited = true;
            chatMsgInfo.message = strNewMsg;
            chatMsgInfo.timeStamp = timeStamp;
            chatMsgInfoArrNew[ind] = chatMsgInfo;
            this.setState({ chatMsgInfoArr: chatMsgInfoArrNew }, () => {
                this.props.updateChatMsgArr(this.state.chatMsgInfoArr);
                //Handling Group chat feature
                if (isGroupChat === true && groupId !== undefined){
                    this.openPage('Groups', 'GroupsTab', '#2d3858');
                    this.setState({ openGroupChat: true, selectedGroup: groupId }, () => {
                        this.setState({ openGroupChat: false, selectedGroup: null });
                    })
                } else {
                    this.openPage('Users', 'UsersTab', '#2d3858');
                    this.setState({ openUserChat: true, selectedUserEmail: from.email}, () => {
                        this.setState({ openUserChat: false, selectedUserEmail: '' });
                    });
                }
            });
        } else if (action.reply_msg && action.reply_msg === true) {
    
            this.state.chatMsgInfoArr.forEach(msgInfo => {
                if (messageID === msgInfo.messageID) //MB2-95
                    bIsDuplicate = true;
            })
            if(bIsDuplicate === false) {
                let [chatMsgInfoReply] = this.state.chatMsgInfoArr.filter(info => info.messageID == message.replymessageID);
                let chatMsgInfo = {
                    msgId: from.email, messageID, firstName: senderInfo.firstName,
                    lastName: senderInfo.lastName, message: strNewMsg, timeStamp: timeStamp, msgType: 0,
                    isReplied: true, replyMessage : {
                        replyMessage: chatMsgInfoReply.message,
                        firstName: chatMsgInfoReply.firstName,
                        lastName: chatMsgInfoReply.lastName
                    }
                };
                this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}), () => {
                    this.props.updateChatMsgArr(this.state.chatMsgInfoArr);
                    //Handling Group chat feature
                    if (isGroupChat === true && groupId !== undefined){
                        this.openPage('Groups', 'GroupsTab', '#2d3858');
                        this.setState({ openGroupChat: true, selectedGroup: groupId }, () => {
                            this.setState({ openGroupChat: false, selectedGroup: null });
                        })
                    } else {
                        this.openPage('Users', 'UsersTab', '#2d3858');
                        this.setState({ openUserChat: true, selectedUserEmail: from.email}, () => {
                            this.setState({ openUserChat: false, selectedUserEmail: '' });
                        });
                    }
                })
            }
                
        }    
    }

    subscribeToCallConnection = (data) => {
        printConsole("connect-the-call socket signal received");
        //TP-2790
        if (this.props.enable_streaming === false) {
            printConsole("streaming is turned off"); 
            return;
        }
        printConsole(`Auto connect feature setings enterprise setting? ${this.state.customer.enable_auto_connect} & user preference ${this.props.allow_auto_connect}`);
        //TP-2492 & TP-3378
        if ((data.isCancelled === undefined || data.isCancelled === false ) && this.state.customer.enable_auto_connect === true && this.props.allow_auto_connect === true) {
            //TP-6185
            if(!this.state.callUser) {
                //Auto connect into the session by accepting the call and don't show the calling Dialog box
                this.setState({ 
                    showCallNotification: false,
                    callerConnectData: data
                }, () => {
                    this.acceptCall();
                });  
            }
        }
        //console.log(this.state.callerConnectData);
        /* if (document.hasFocus() === false) {
            console.log('Tab isnot active');
            this.setPushNotification(data.from);
        } */
        
        // TP-1462 -- Expert/Technician should auto decline any new incoming calls 
        // while processing the current incoming call 
        if (this.state.showCallNotification === true && data.from !== this.state.callerConnectData.from) {
            if(data.isCancelled == undefined || data.isCancelled === false) {
                printConsole("Another user has called while the incoming call dialog box is open");
                //TP-6077
                this.authService.socket.emit('respond-to-the-call', { action: 'auto-decline', recipient: data.from });
                this.setState({ 
                    flashLeft: "", /**TP-6067*/
                    flashTop: "", /**TP-6067*/
                    flashMsgText: `Calling {name} is declined`,
                    flashValues: {name: data.name},
                    showFlashMessage: true
                })
            }
        } else if(this.state.callUser) {
            if(data.isCancelled == undefined || data.isCancelled === false) {
                printConsole("Another user has called while the outgoing calling dialog box is open");
                //TP-6077
                this.authService.socket.emit('respond-to-the-call', { action: 'auto-decline', recipient: data.from });
                this.setState({ 
                    flashLeft: "", /**TP-6067*/
                    flashTop: "", /**TP-6067*/
                    flashMsgText: `Calling {name} is declined`,
                    flashValues: {name: data.name},
                    showFlashMessage: true
                })
            }
        }else if (data.call_auto_connect === undefined || data.call_auto_connect === false) {  //TP-2519 
            if (this.props.log_level >= 3) {
                diagonastics(this.authService,{
                    action: `connect the call socket event`,
                    sequence: 2,
                    next_step: '',
                    data,
                    error: 'none'
                })
            }
            if(!this.isMount) return;
            // Don't activate timer when caller cancelling call.Enable it only when there is incoming call request as fail safe for caller unable to send any cancel signal. 
            if(data.isCancelled === undefined || data.isCancelled === false){
                this.timer = setTimeout(() => this.closeRingerForReciever(), window._env_.REACT_APP_API_CALL_TIME_OUT);
            }
            //TP-3378
            this.setState({
                showCallNotification: (data.isCancelled !== undefined)? (this.state.customer.enable_auto_connect === true && this.props.allow_auto_connect === true) ? false : !data.isCancelled : (this.state.customer.enable_auto_connect === true && this.props.allow_auto_connect === true) ? false : true,
                callerConnectData: data
            }, () => {
                if (!this.props.tabVisible && !data.isCancelled) {
                    !this.props.tabVisible && this.handleButtonClick();
                }
            })
        }
    }
    
    closeRingerForReciever = () => {
        if (this.timer) clearTimeout(this.timer);
        this.setState({showCallNotification: false}, () => {
            //TP-2191 -- Handling of turning back the phone icon to non-busy 
            // for a user who times out its incoming call
            const {from} = this.state.callerConnectData;
            //TP-5903 this.authService.socket.emit('respond-to-the-call', { action: 'timeout', recipient: from });
        })
    }

    onUpdatePurchases = (purchases, updatedCustomer) => {
        let customer = {...this.state.customer };
        if (this.state.customer.subscription_status ==='trialing' && updatedCustomer) {
            customer = {
                ...customer,
                ...updatedCustomer
            }
        }
        this.setState({
            customer,
            purchases,
            isChanged: true
        })
    }

    onChangeUser = (userDetails) => {
        /** TODO: This is a hack, and should be removed once we figureout synching data with multiple components */
        //this.setState({ isChanged: true });
        this.getAccountDetails();
        if (userDetails && userDetails.id === this.props.id) {
            this.props.onUpdateAdminUser(userDetails);
        } else {
            this.props.onChangeUser(true);
            this.props.onChangeUser(false);
        }
    }

    loadSessionInfo = (data) => {
        this.props.loadSessionInfo(data);
    }

    loadSelectedGroup = (groupid, groupname, from) => {
        this.props.loadSelectedGroup(groupid, groupname, from);
    }

    loadGroupUserList = (data) => {
        this.props.loadGroupUserList(data);
    }

    onRouteChange = (route) => {
        this.props.onRouteChange(route);
    }

    // for FQ3-712
    checkRefreshXception = () => {
        const {pageName} = this.state;
        if (pageName === "jobs") return true;
        return false;
    }

    subscribeToGroupUserChange = ({data}) => {
        const {groupInfo} = this.state;
        if (this.checkRefreshXception()) return;
        printConsole("group-user-change signal received with payload");
        printConsole(data);
        data.forEach(d => {
            if (d.userId === this.props.id) {
                printConsole("refreshing the page...");
                window.location = '/';
                return;
            }
        });
        printConsole("checking if the user is part of any groups of current user");
        data.forEach(d => {
            groupInfo.map(g => {
                g.users.forEach( u => {
                    if(u.value === d.userId) {
                        printConsole(`${d.userId} user is part of a group`);
                        printConsole("refreshing the page...");
                        window.location = '/';
                        return;
                    }
                })
            })            
        });
    }

    subscribeToLogMeOut = ({ userIsDeleted, email, loginTimeStamp }) => {
        printConsole("log-me-out signal received with payload");
        printConsole({ userIsDeleted, email, loginTimeStamp });
        /* this.diagonastics({
            action: `log-me-out signal is received`,
            sequence: 1,
            next_step: '',
            data: `payload: { ${userIsDeleted}, ${email}, ${loginTimeStamp} } for user_info: ${this.props.user_info}`,
            error: 'none'
        }) */
        const {groupInfo} = this.state;
        if (userIsDeleted === true && this.props.email === email) {
            sessionStorage.removeItem('id_token');
            sessionStorage.removeItem('pageName');//TP-1579
            window.location = '/';
            return;
        } else if (userIsDeleted === true && this.props.email !== email){
            if (this.checkRefreshXception()) return;
            printConsole("checking if the user is part of any groups of current user");
            groupInfo.map(g => {
                g.users.forEach( u => {
                    if(u.email === email) {
                        printConsole(`${email} user is part of a group`);
                        printConsole("refreshing the page...");
                        window.location = '/';
                        return;
                    }
                })
            })   
        } else if (userIsDeleted === false && this.props.email === email) {
            const {forcedLogin} = this.props;            
            if (forcedLogin === true && new Date(loginTimeStamp) === new Date(this.props.last_accessed_on)) {
                /* this.diagonastics({
                    action: `Not refreshing the page`,
                    sequence: 1,
                    next_step: '',
                    data: `forcedLogin flag: ${forcedLogin}, last accessed on time: ${this.props.last_accessed_on}, user_info: ${this.props.user_info}`,
                    error: 'none'
                }) */
                this.props.loadLogMeIn({ logMeIn: false }); 
                return;
            } else if (new Date(loginTimeStamp) > new Date(this.props.last_accessed_on)) {
                /* this.diagonastics({
                    action: `Refreshing the page`,
                    sequence: 1,
                    next_step: '',
                    data: `forcedLogin flag: ${forcedLogin}, last accessed on time: ${this.props.last_accessed_on}, user_info: ${this.props.user_info}`,
                    error: 'none'
                }) */
                /* sessionStorage.removeItem('id_token');
                sessionStorage.removeItem('pageName');//TP-1579
                window.location = '/'; */ //TP-2099
                this.onSubmitSignout(); //TP-2028
                return;
            }
        }
    }
    //TP-3369
    updateMyProfilePicture = (val) => {
        this.props.updateMyProfilePicture(val);
    }
    //TP-3328
    updateProfilePic = (data) => {
        this.props.updateProfilePic(data);
    }

    // TP-2028 -- To log out the user who has received the log-me-out 
    // signal from the current device that it is logged into
    onSubmitSignout = () => {
        //console.log('signout called');
        const myLocation = "Bangalore North++East"; //getGeoLocation();
        const encryptedEmail = encryptData(this.props.email);//FQ3-417
        this.authService.fetch('signout', { //TP-2028
          method: 'post',
          /* TP-2028 headers: {'Content-Type': 'application/json'}, */
          body: JSON.stringify({
            email: encryptedEmail,
            isencrypted: true,
            id: parseInt(this.props.id, 10),
            groupid: parseInt(0, 10),
            geoLocation: myLocation
          })
        })
        .then(response => response.json())
        .then(data => {
            // adding this on success UQ3-208. Instead of the call adding it on success
            this.authService.logout();
            this.props.onRouteChange('signout');
            
        })
        .catch(err => console.log(err.message))
    }

    //TP-2381 -- To set the callee list for Group calls
    setCalleeList = (value) => {
        this.props.setCalleeList(value);
    }

    //TP-2946 -- To remove users from callee list for users who declined/timeout the Group call
    removeFromCalleeList = (val) => {
        this.props.removeFromCalleeList(val);
    }

    // TP-1534 - To track the users who are already in a session
    subscribeToUserInSession = ({ isInSession, userName, caller, groupId }) => {
        printConsole("in Landing page user-is-in-session received payload");
        printConsole(`{isInSession: ${isInSession}, userName: ${userName}, caller ${caller}, groupId ${groupId}}`);
        let {inSessionArr, showCallNotification} = this.state;
        let { usersInSession } = this.props;
        if (isInSession) {
            if (userName) {
                const inSessionObj = {userName, groupId};
                inSessionArr.push(inSessionObj);
                usersInSession.push(userName);
            }
            if (caller) usersInSession.push(caller);
        } else {
            if (userName) {
                inSessionArr.splice(inSessionArr.findIndex(e => e.userName === userName), 1);
                usersInSession = usersInSession.filter(e => ![userName].includes(e));
                //inSessionArr = inSessionArr.filter(e => e.userName !== userName);
            } 
            //console.log(this.state.callerConnectData);
            //console.log(showCallNotification);
            if (caller && !showCallNotification) usersInSession = usersInSession.filter(e => ![caller].includes(e)); //TP-1605           
        }
        this.setState({ inSessionArr });
        this.props.setUsersInSession(usersInSession);
    }

    //TP-1661 -- To create the recordings tab content
    setRecordingsArray = ({recordings}) => {
        //console.log(recordings);
        this.setState({ recordings }, () => {
            if (this.state.recordings !== null)
                this.openPage('Recordings', 'RecordingsTab', '#2d3858');
        });
    }

    //TP-6431
    subscribeToUserDetailsUpdate = (data) => {
        const {userId} = data;
        if(userId === this.props.id){
            this.checkMonthlyMinutes()//reload user
            this.props.updateUserDetails();//TP-6431
        }
    }
    //TZ-487
    subscribeToMissedCallNotificationAlert = (data) => {
        //console.log("Missed call alert received ...", data);
        const strURL = window.location.href;
        if (sessionStorage.getItem("pageName") !== "UsageDetails") {
            //TZ-539
            if (strURL.toLowerCase().includes('omafra')) {
                this.openPage('UsageDetails','UsageDetailsTab', '#2d3858');
                this.setState({ showMissedCalls: true });
            }
        } else {
            /**TZ-475*/
            this.setState({ refetchMissedcalls: true }, () => {
                this.setState({ refetchMissedcalls: false });
            })
        }
        this.setState({ 
            flashLeft: "", /**TP-6067*/
            flashTop: "", /**TP-6067*/
            flashMsgText: "{name} is trying to call you.",
            flashValues: {name: data.callerName},
            showFlashMessage: true
        })
    }

    //TZ-540
    updateShowMissedCallFlag = (value) => {
        this.setState({ showMissedCalls: value });
    }

    showLoader = (isLoading) => {
        if (isLoading) {
            return (
                <div className="loader">
                    <Loader
                        type="ThreeDots"
                        color="#23c97d"
                        height="70"	
                        width="70"
                    />
                </div>
            )
        }
        return '';
    }

    getTechnicianRender = () => {
        return <Fragment>
                    {/*TP-4028*/ this.getTabContent("technician")}
                    <div id="Groups" className="tabcontent">
                        <AccountConsumer>
                            {(consumerProps) =>
                                <Groups 
                                    {...consumerProps}
                                    isSmartAdmin={this.props.isSmartAdmin}
                                    isPilot={this.props.isPilot}//TP-2539
                                    isadmin={this.props.isadmin}
                                    activePage={this.state.pageName}
                                    customer={this.state.customer} //TZ-1487
                                    email={this.props.email}
                                    adminname={this.props.name}
                                    userid={this.props.id}
                                    accountid={this.props.accountid}
                                    behaviour={this.props.behaviour}//TZ-1280
                                    myPicture={this.props.myPicture}
                                    isexpert={this.props.isexpert} 
                                    fetchLatest={this.state.isChanged}
                                    log_level={this.props.log_level}
                                    myCameraOn={this.props.myCameraOn} //TP-3532
                                    loadSelectedGroup={this.loadSelectedGroup}
                                    loadGroupUserList={this.loadGroupUserList}
                                    onRouteChange={this.onRouteChange}
                                    updateGroupInfo={this.updateGroupInfo}
                                    getMyGroups={this.getMyGroups}
                                    authService={this.authService}
                                    onUpdateGroup={this.props.onUpdateGroup}
                                    numberOfExperts={this.state.customer.number_of_experts}
                                    isCustomizable={this.state.customer.is_customizable}
                                    i18n={this.props.i18n}
                                    isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                    isSafari = {this.props.isSafari}
                                    updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462
                                    loadSessionInfo={this.loadSessionInfo}
                                    inSessionArr={this.state.inSessionArr} //TP-1534
                                    setCalleeList={this.setCalleeList} //TP-2381
                                    removeFromCalleeList={this.removeFromCalleeList} //TP-2946
                                    maxPerPage={this.state.maxPerPage} //TP-2883
                                    isMobileDevice={this.state.isMobileDevice} //TP-2892
                                    isSmallFormFactor={this.state.isSmallFormFactor} //TP-2892
                                    enable_streaming={this.props.enable_streaming} //TP-2790
                                    audio_mode={this.props.audio_mode} //TP-3156
                                    allow_expert_to_expert_call={this.props.allow_expert_to_expert_call} //TZ-989 
                                    session_type={this.props.session_type} //TP-3156
                                    isUserCall={this.props.isUserCall} //TP-5036
                                    setCameraResolution={this.props.setCameraResolution}//TP-5429
                                    changeIspartOfaGroup={this.changeIspartOfaGroup} //TP-4814
                                    updateChatArr={this.updateChatArr} //TP-6771
                                    chatMsgInfoArr={this.state.chatMsgInfoArr} //TP-6771
                                    customerRoles={this.props.customerRoles}
                                    openGroupChat={this.state.openGroupChat} //TP-6222
                                    selectedGroup={this.state.selectedGroup}
                                />
                            }
                        </AccountConsumer>
                    </div>
                    <div id="Users" className="tabcontent">
                        <AccountConsumer>
                            {(consumerProps) =>
                                <Users 
                                    {...consumerProps}
                                    isGuest={this.props.isGuest} /**TP-4352*/
                                    allUsersofAccount={this.state.allUsersofAccount} /**TP-4352*/
                                    defaultHeight={this.state.user.selectedHeight}//TP-5431
                                    defaultWidth={this.state.user.selectedWidth} /**TP-5431*/
                                    isSmartAdmin={this.props.isSmartAdmin}
                                    activePage={this.state.pageName}
                                    purchases={this.state.purchases}
                                    adminemailid={this.props.email}
                                    isadmin={this.props.isadmin}
                                    isexpert={this.props.isexpert}
                                    adminname={this.props.name}
                                    userid={this.props.id}
                                    accountid={this.props.accountid}
                                    behaviour={this.props.behaviour}
                                    last_accessed_on={this.props.last_accessed_on}
                                    log_level={this.props.log_level}
                                    myPicture={this.props.myPicture}//TP-3328
                                    myCameraOn={this.props.myCameraOn}//TP-3532
                                    onUpdatePurchases={this.onUpdatePurchases}
                                    onStateChange={this.onChangeUser}
                                    authService={this.authService}
                                    customer={this.state.customer}
                                    loadSelectedGroup={this.loadSelectedGroup}
                                    setGroupUsersList={this.setGroupUsersList}
                                    onRouteChange={this.onRouteChange}
                                    isChanged={this.state.isChanged}
                                    canResetUserPassword={this.props.canResetUserPassword}
                                    isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                    updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462
                                    loadSessionInfo={this.loadSessionInfo}
                                    inSessionArr={this.state.inSessionArr} //TP-1534
                                    usersInSession={this.props.usersInSession} //TP-1599
                                    setUsersInSession={this.props.setUsersInSession} //TP-1599
                                    setCalleeList={this.setCalleeList} //TP-2381
                                    removeFromCalleeList={this.removeFromCalleeList} //TP-2946
                                    groupsData={this.state.groupsData} //TP-2493
                                    maxPerPage={this.state.maxPerPage} //TP-2883
                                    isMobileDevice={this.state.isMobileDevice} //TP-2892
                                    isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                    enable_streaming={this.props.enable_streaming} //TP-2790
                                    audio_mode={this.props.audio_mode} //TP-3156
                                    allow_expert_to_expert_call={this.props.allow_expert_to_expert_call} //TZ-989
                                    session_type={this.props.session_type} //TP-3156
                                    updateProfilePic={this.props.updateProfilePic}//TP-3328
                                    updatePrfilePicInArray={this.props.updatePrfilePicInArray} //TP-3385
                                    updateMyProfilePicture={this.updateMyProfilePicture} //TP-3672 & TP-3369
                                    loadUserProfile={this.props.loadUserProfile} //TP-3746 & TP-3328
                                    setCurrentStreamingRes={this.props.setCurrentStreamingRes} //TP-3821
                                    isUserCall={this.props.isUserCall} //TP-5036
                                    setCameraResolution={this.props.setCameraResolution} //TP-5429
                                    isPartOfAGroup={this.state.isPartOfAGroup} //TP-4814
                                    updateChatArr={this.updateChatArr} //TP-6222
                                    chatMsgInfoArr={this.state.chatMsgInfoArr} //TP-6222
                                    openUserChat={this.state.openUserChat} //TP-6222
                                    selectedUserEmail={this.state.selectedUserEmail} //TP-6222
                                    customerRoles={this.props.customerRoles}
                                    updateuserdata={this.updateuserdata} //TZ-487
                                />
                        
                            }
                        </AccountConsumer>
                    </div>
                    { this.props.enable_streaming === true ?
                        <div id="UsageDetails" className="tabcontent">
                            <AccountConsumer>
                                {({noInternet, isConnected, onlineUsers}) =>
                                    <UsageDetails 
                                        isadmin={this.props.isadmin}
                                        isSmartAdmin={this.props.isSmartAdmin}
                                        updateAllUsers={this.updateAllUsers} //TP-4352
                                        isexpert={this.props.isexpert}
                                        userid={this.props.id}
                                        activePage={this.state.pageName}
                                        accountid={this.props.accountid}
                                        adminname={this.props.name} //TZ-487
                                        adminemailid={this.props.email}
                                        authService={this.authService}
                                        downloadUsage={this.state.customer.download_usage}
                                        customer={this.state.customer}
                                        maxPerPage={this.state.maxPerPage} //TP-2883
                                        isMobileDevice={this.state.isMobileDevice} //TP-2892
                                        isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                        updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462
                                        showMissedCalls={this.state.showMissedCalls}
                                        loadSessionInfo={this.loadSessionInfo}
                                        setUsersInSession={this.props.setUsersInSession} //TP-1599,5766
                                        isUserCall={this.props.isUserCall} //TP-5036,5766
                                        setCalleeList={this.setCalleeList} //TP-2381,5766
                                        usersInSession={this.props.usersInSession} //TP-1599,5766
                                        isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                        noInternet={noInternet}
                                        isConnected={isConnected}
                                        onlineUsers={onlineUsers}
                                        enable_streaming={this.state.customer.enable_streaming}
                                        setGroupUsersList={this.setGroupUsersList}
                                        onRouteChange={this.onRouteChange}
                                        isPartOfAGroup={this.state.isPartOfAGroup}
                                        groupsData={this.state.groupsData} //TP-2493
                                        myCameraOn={this.props.myCameraOn}//TP-3532
                                        setCameraResolution={this.props.setCameraResolution} //TP-5429
                                        inSessionArr={this.state.inSessionArr} //TP-1534
                                        userlist={this.state.userlist}
                                        loadSelectedGroup={this.loadSelectedGroup}
                                        refetchMissedcalls={this.state.refetchMissedcalls} //TZ-475
                                        updateShowMissedCallFlag={this.updateShowMissedCallFlag}//TZ-540
                                    />
                                }
                            </AccountConsumer>
                        </div> 
                        :
                        ''
                    }
                    {/**TP-3949 & TP-3328 */
                    <div id="MyProfile" className="tabcontent min-height-profile">
                        <AccountConsumer>
                            {(consumerProps) =>
                                <MyProfile
                                    {...consumerProps}
                                    id={this.props.id}
                                    authService={this.authService}
                                    first_name={this.state.user.first_name}
                                    last_name={this.state.user.last_name}
                                    phonenumber={this.state.user.phonenumber}//TP-6082
                                    defaultHeight={this.state.user.selectedHeight}//TP-5431
                                    defaultWidth={this.state.user.selectedWidth} /**TP-5431*/
                                    email={this.state.user.email}
                                    isexpert={this.props.isexpert}
                                    isadmin={this.props.isadmin} //TP-3950
                                    myPicture={this.props.myPicture}//TP-3328
                                    streaming_quality={this.props.streaming_quality} /**TP-3794*/
                                    image_quality={this.props.image_quality} /**TP-3794*/
                                    camera_on={this.props.camera_on} /**TP-3794*/
                                    auto_connect={this.props.allow_auto_connect} /**TP-3794*/
                                    disable_notifications={this.props.disable_notifications} /**TP-3794*/
                                    enable_auto_connect={this.state.customer.enable_auto_connect}
                                    enable_user_video={this.state.customer.enable_user_video}
                                    enable_streaming={this.state.customer.enable_streaming}
                                    sms_support={this.state.customer.sms_support}//TP-6082
                                    isMobileDevice={this.state.isMobileDevice} //TP-3260
                                    isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                    onRouteChange={this.onRouteChange}
                                    openPage={this.openPage}
                                    updateMyProfilePicture={this.updateMyProfilePicture} //TP-3369
                                    updateChangePassword={this.props.updateChangePassword} //TP-3213
                                    loadUserProfile={this.props.loadUserProfile} //TP-3328
                                    updateMyProfilePic={this.props.updateMyProfilePic}
                                    isVerySmallDevice={this.props.isVerySmallDevice} //TP-3746
                                    updateOpenGroup={this.updateOpenGroup}//TP-4028
                                    triggerResizeMyProfile={this.state.triggerResizeMyProfile} //TP-4067
                                    setCameraResolution={this.props.setCameraResolution} //TP-3821
                                    setStreamingOptions={this.props.setStreamingOptions} //TP-3821
                                    verified={this.state.user.verified}//TP-5234
                                    openChangePassword={this.openChangePassword} /**TP-5504*/
                                    checkMonthlyMinutes={this.checkMonthlyMinutes}//TP-6213
                                />
                            }
                        </AccountConsumer>
                    </div>
                    }  
                    {/**TP-5504*/}
                    <div id="ChangePassword" className="tabcontent min-height-profile">
                        <AccountConsumer>
                            {(consumerProps) =>
                                <ChangePassword {...consumerProps}
                                    routeChange={this.onRouteChange} 
                                    onCloseModal={this.props.closeAdminPopup}
                                    behaviour={this.props.behaviour}
                                    accountid={this.props.accountid}
                                    id={this.props.id}
                                    oldPassword={this.props.oldPassword}
                                    email={this.state.user.email}
                                    isChangePassword={true} //TP-3213
                                    last_accessed_on={this.props.last_accessed_on} //TP-5418
                                    updateOpenMyProfile={this.updateOpenMyProfile}
                                />
                            }
                        </AccountConsumer>
                    </div>
                </Fragment>
    }

    onAddNewCard = (newCard) => {
        this.setState((prev) => ({
            paymentSources: [
                ...prev.paymentSources,
                newCard
            ]
        }))
    }

    onDeleteCard = (cardId) => {
        const updatedPaymentSources = this.state.paymentSources.filter(e => e.id !== cardId);
        this.setState((prev) => ({
            paymentSources: updatedPaymentSources
        }))
    }

    onMakeDefaultCard = (cardId) => {
        this.setState({
            defaultSource: cardId
        });
    }

    updateGroupInfo = ({ groupInfo }) => {
        this.setState({ groupInfo });
    }
    //TZ-487
    updateuserdata = (users) => {
        this.setState({ userlist: users });
    }

    //TP-1735 -- To get the online group users for User tab call
    getMyGroups = ({ groupsData }) => {
        this.setState({ groupsData });
    }

    //TP-1735 -- To set the group users list for the selected call group Id from Users Tab
    setGroupUsersList = (groupId) => {
        printConsole(`group id in setGroupUsersList ${groupId}`);
        const {groupsData} = this.state;
        let grpdata = [];
        if (groupsData !== undefined) {
            [grpdata] = groupsData.filter(g => 
                g.groupid === parseInt(groupId)
            )
        }
        if(grpdata && grpdata.users){
            printConsole(`group data users in setGroupUsersList are ${grpdata.users}`);
            this.props.loadGroupUserList(grpdata.users);
        }
    }

    //TP-1462 
    updateCallDialogStatus = (val) => {
        this.setState({ callUser: val }, () => {
            //TP-5547
            if (this.state.callUser === true && this.state.showCallNotification === true /* && data.from !== this.state.callerConnectData.from */) {
                printConsole("Another user has called while the outgoing calling dialog box is open");
                //TP-6077
                this.authService.socket.emit('respond-to-the-call', { action: 'auto-decline', recipient: this.state.callerConnectData.from });
                this.setState({ 
                    flashLeft: "40%", 
                    flashTop: "50%",
                    flashMsgText: `Calling {name} is declined`,
                    flashValues: {name: this.state.callerConnectData.from},
                    showFlashMessage: true
                })
            }
            this.setState({ showCallNotification: false });
        });
    }

    /**TP-2883 */
    getTabContent = (role) => {
        const pageName = sessionStorage.getItem('pageName'); //TP-4028      
        const {customer : {enable_asset_class}, user: {enable_asset}} =  this.state;
        const {behaviour} = this.props;//TP-5927, fetch props is faster
        if(pageName === "MyProfile" || pageName === "ChangePassword" /**TP-5504*/){
            return(null);//TP-4753
        }else if (role === "expert") {
            return (
                <div className="tab-container row">
                        {/* TP-5815 */}
                        {   behaviour !== "pndsafety"?
                                <button id="GroupsTab" className="tablink col" onClick={() => this.openPage('Groups', 'GroupsTab', '#2d3858')}><Trans>Groups</Trans></button>
                            :
                                null
                        }
                        {   behaviour !== "pndsafety"?
                                <button id="UsersTab" className="tablink col" onClick={() => this.openPage('Users', 'UsersTab', '#2d3858')}><Trans>Users</Trans></button>
                            :
                                null
                        }
                        { this.props.enable_streaming === true && behaviour !== "pndsafety" ?
                            <button id="UsageDetailsTab" className="tablink col" onClick={() => this.openPage('UsageDetails', 'UsageDetailsTab', '#2d3858')}>{/*TP-3619 TP-2892*/<Trans>Usage</Trans>}</button>
                            :
                            ''
                        }
                        { this.props.isSmartAdmin === false && this.state.recordings !== null ?
                            <button id="RecordingsTab" className="tablink col" onClick={() => this.openPage('Recordings', 'RecordingsTab', '#2d3858')}><Trans>Recordings</Trans></button>
                            :
                            ''
                        }
                        { this.props.isSmartAdmin === false && this.state.customer.is_gps_enabled?
                            <>    
                                { /**TP-3616 & TP-3756*/ this.state.isSmallFormFactor === false ? 
                                    <button id="jobsTab" className="tablink col" onClick={() => this.openPage('jobs', 'jobsTab', '#2d3858')}>{this.state.customer.behaviour === "pndsafety"? <Trans>Work Order</Trans> : <Trans>Workflow</Trans>}</button>
                                    :
                                    ''
                                }                    
                                { /*TP-3756*/ this.state.isSmallFormFactor === false && (enable_asset_class === "enabled_for_all" || (enable_asset_class === "enabled_on_demand" && enable_asset)) ?
                                    <button id="assetTab" className="tablink col" onClick={() => this.openPage('asset', 'assetTab', '#2d3858')}><Trans>Asset</Trans></button>
                                    : ''
                                }
                            </>
                            : ''
                        }
                        {/* <button id="MyProfileTab" className="tablink" onClick={() => this.openPage('MyProfile', 'MyProfileTab', '#2d3858')}>{/*TP-2892this.state.isMobileDevice ? <Trans>Profile</Trans> : <Trans>My Profile</Trans>}</button> */}
                    </div>
            )
        } else if (role === "admin") {
            return (
                <div className="tab-container row">
                    {/*  NS2-167 FQ3-246*/}
                    {((this.state.customer.payment_failure_count > this.state.permitted_payment_failure_count) || (this.state.customer.is_lan_enterprise && !this.state.customer.is_lan_license_activated))?
                            <button id="AccountDetailsTab" className="tablink col" onClick={() => this.openPage('AccountDetails', 'AccountDetailsTab', '#2d3858')}>{/**TP-3619*/}<Trans>Account</Trans></button>
                        :
                        <>
                            {/* TP-5815 */}
                            {   behaviour !== "pndsafety"?
                                    <button id="GroupsTab" className="tablink col" onClick={() => this.openPage('Groups', 'GroupsTab', '#2d3858')}><Trans>Groups</Trans></button>
                                :
                                    null
                            }
                            <button id="UsersTab" className="tablink col" onClick={() => this.openPage('Users', 'UsersTab', '#2d3858')}><Trans>Users</Trans></button>
                            <button id="AccountDetailsTab" className="tablink col" onClick={() => this.openPage('AccountDetails', 'AccountDetailsTab', '#2d3858')}>{/*TP-3619 TP-2892*/this.state.isMobileDevice && window.innerWidth <= 738 ? <Trans>A/C</Trans> : <Trans>Account</Trans>}</button>
                            { this.props.enable_streaming === true && behaviour !== "pndsafety"?
                                <button id="UsageDetailsTab" className="tablink col" onClick={() => this.openPage('UsageDetails', 'UsageDetailsTab', '#2d3858')}>{/*TP-3619 TP-2892*/<Trans>Usage</Trans>}</button>
                                :
                                ''
                            }
                            { this.props.isSmartAdmin === false && this.state.recordings !== null ?
                                <button id="RecordingsTab" className="tablink col" onClick={() => this.openPage('Recordings', 'RecordingsTab', '#2d3858')}><Trans>Recordings</Trans></button>
                                :
                                ''
                            }
                            { this.props.isSmartAdmin === false && this.state.customer.is_gps_enabled?
                                <>
                                    { /**TP-3616 & TP-3756*/ this.state.isSmallFormFactor === false ?
                                        <button id="jobsTab" className="tablink col" onClick={() => this.openPage('jobs', 'jobsTab', '#2d3858')}>{this.state.customer.behaviour === "pndsafety"? <Trans>Work Order</Trans> : <Trans>Workflow</Trans>}</button>
                                        :
                                        ''
                                    }
                                    { /*TP-3756*/ this.state.isSmallFormFactor === false && enable_asset_class !== "disabled" ?
                                        <>
                                            <button id="assetTab" className="tablink col" onClick={() => this.openPage('asset', 'assetTab', '#2d3858')}><Trans>Asset</Trans></button>
                                            <button id="assetClassTab" className="tablink col" onClick={() => this.openPage('asset_class', 'assetClassTab', '#2d3858')}>{/*TP-2892*/this.state.isMobileDevice ? /**TP-3459 */ this.state.isVerySmallDevice ? <Trans>A Cls</Trans> : <Trans>A Class</Trans> : <Trans>Asset Class</Trans>}</button>
                                        </>
                                        :
                                        ''
                                    }
                                </>
                                : ''
                            }
                            {/* <button id="MyProfileTab" className="tablink" onClick={() => this.openPage('MyProfile', 'MyProfileTab', '#2d3858')}>{/*TP-2892this.state.isMobileDevice ? <Trans>Profile</Trans> : <Trans>My Profile</Trans>}</button> */}
                        </>
                    }
                </div>
            )
        } else {
            return (
                <div className="tab-container row">
                    <button id="GroupsTab" className="tablink col" onClick={() => this.openPage('Groups', 'GroupsTab', '#2d3858')}><Trans>Groups</Trans></button>
                    <button id="UsersTab" className="tablink col" onClick={() => this.openPage('Users', 'UsersTab', '#2d3858')}><Trans>Users</Trans></button>                        
                    { this.props.enable_streaming === true ?
                        <button id="UsageDetailsTab" className="tablink col" onClick={() => this.openPage('UsageDetails', 'UsageDetailsTab', '#2d3858')}>{ /*TP-3619 TP-2892*/<Trans>Usage</Trans>}</button>
                        :
                        ''
                    }
                    {/* <button id="MyProfileTab" className="tablink" onClick={() => this.openPage('MyProfile', 'MyProfileTab', '#2d3858')}>{/*TP-2892this.state.isMobileDevice ? <Trans>Profile</Trans> : <Trans>My Profile</Trans>}</button> */}
                </div>
            )
        }
    }

    getAdminRender = () => {    
        return <Fragment>
                    {/*TP-4028*/ this.getTabContent("admin")}
                    {/*  NS2-167 FQ3-246*/}
                    {((this.state.customer.payment_failure_count > this.state.permitted_payment_failure_count) || (this.state.customer.is_lan_enterprise && !this.state.customer.is_lan_license_activated))?

                    			<div id="AccountDetails" className="tabcontent">
                                    { this.showLoader(this.state.isLoading) }
                                    <AccountConsumer>
                                        {(consumerProps) =>
                                            <AccountDetails 
                                                {...consumerProps}
                                                purchases={this.state.purchases}
                                                accountId={this.props.accountid}
                                                email={this.props.email}
                                                plans={this.state.plans}
                                                adminUserId={this.props.id}
                                                onUpdatePurchases={this.onUpdatePurchases}
                                                authService={this.authService}
                                                customer={this.state.customer}
                                                paymentSources={this.state.paymentSources}
                                                defaultSource={this.state.defaultSource}
                                                onAddNewCard={this.onAddNewCard}
                                                onMakeDefaultCard={this.onMakeDefaultCard}
                                                onDeleteCard={this.onDeleteCard}
                                                isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                            />
                                        }
                                    </AccountConsumer>
                                </div> 
                        :
                            <>
                                <div id="Groups" className="tabcontent">
                                    <AccountConsumer>
                                        {(consumerProps) =>
                                            <Groups 
                                                {...consumerProps}
                                                isSmartAdmin={this.props.isSmartAdmin}
                                                isPilot={this.props.isPilot}//TP-2539
                                                isadmin={this.props.isadmin}
                                                email={this.props.email}
                                                activePage={this.state.pageName}
                                                customer={this.state.customer} //TZ-1487
                                                adminname={this.props.name}
                                                userid={this.props.id}
                                                accountid={this.props.accountid}
                                                myPicture={this.props.myPicture}
                                                isexpert={this.props.isexpert} 
                                                fetchLatest={this.state.isChanged}
                                                log_level={this.props.log_level}
                                                myCameraOn={this.props.myCameraOn}//TP-3532
                                                loadSelectedGroup={this.loadSelectedGroup}
                                                loadGroupUserList={this.loadGroupUserList}
                                                onRouteChange={this.onRouteChange}
                                                updateGroupInfo={this.updateGroupInfo}
                                                getMyGroups={this.getMyGroups}
                                                authService={this.authService}
                                                onUpdateGroup={this.props.onUpdateGroup}
                                                numberOfExperts={this.state.customer.number_of_experts}
                                                isCustomizable={this.state.customer.is_customizable}
                                                streaming_medium={this.state.customer.streaming_medium}
                                                subscription_status={this.state.customer.subscription_status}
                                                i18n={this.props.i18n}
                                                isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                                isSafari = {this.props.isSafari}
                                                updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462
                                                loadSessionInfo={this.loadSessionInfo}
                                                inSessionArr={this.state.inSessionArr} //TP-1534
                                                setCalleeList={this.setCalleeList} //TP-2381
                                                removeFromCalleeList={this.removeFromCalleeList} //TP-2946
                                                maxPerPage={this.state.maxPerPage} //TP-2883
                                                isMobileDevice={this.state.isMobileDevice} //TP-2892
                                                isSmallFormFactor={this.state.isSmallFormFactor} //TP-2892
                                                enable_streaming={this.props.enable_streaming} //TP-2790
                                                audio_mode={this.props.audio_mode} //TP-3156
                                                allow_expert_to_expert_call={this.props.allow_expert_to_expert_call} //TZ-989
                                                session_type={this.props.session_type} //TP-3156
                                                isUserCall={this.props.isUserCall} //TP-5036
                                                setCameraResolution={this.props.setCameraResolution} //TP-5429
                                                changeIspartOfaGroup={this.changeIspartOfaGroup} //TP-4814
                                                updateChatArr={this.updateChatArr} //TP-6771
                                                chatMsgInfoArr={this.state.chatMsgInfoArr} //TP-6771
                                                customerRoles={this.props.customerRoles}
                                                openGroupChat={this.state.openGroupChat} //TP-6222
                                                selectedGroup={this.state.selectedGroup}
                                            />
                                        }
                                    </AccountConsumer>
                                </div>

                                <div id="Users" className="tabcontent">
                                    <AccountConsumer>
                                        {(consumerProps) =>
                                            <Users 
                                                {...consumerProps}
                                                isGuest={this.props.isGuest} /**TP-4352*/
                                                allUsersofAccount={this.state.allUsersofAccount} /**TP-4352*/
                                                defaultHeight={this.state.user.selectedHeight}//TP-5431
                                                defaultWidth={this.state.user.selectedWidth} /**TP-5431*/
                                                isSmartAdmin={this.props.isSmartAdmin}
                                                activePage={this.state.pageName}
                                                purchases={this.state.purchases}
                                                adminemailid={this.props.email}
                                                isadmin={this.props.isadmin}
                                                isexpert={this.props.isexpert}
                                                adminname={this.props.name}
                                                userid={this.props.id}
                                                accountid={this.props.accountid}
                                                behaviour={this.props.behaviour}
                                                last_accessed_on={this.props.last_accessed_on}
                                                log_level={this.props.log_level}
                                                myPicture={this.props.myPicture}//TP-3328
                                                myCameraOn={this.props.myCameraOn} //TP-3532
                                                onUpdatePurchases={this.onUpdatePurchases}
                                                onStateChange={this.onChangeUser}
                                                authService={this.authService}
                                                customer={this.state.customer}
                                                loadSelectedGroup={this.loadSelectedGroup}
                                                setGroupUsersList={this.setGroupUsersList}
                                                onRouteChange={this.onRouteChange}
                                                isChanged={this.state.isChanged}
                                                canResetUserPassword={this.props.canResetUserPassword}
                                                isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                                userIdForAutocall={this.props.userIdForAutocall}//TP-566
                                                autoCall={this.props.autoCall}//TP-566
                                                updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462
                                                loadSessionInfo={this.loadSessionInfo}
                                                inSessionArr={this.state.inSessionArr} //TP-1534
                                                usersInSession={this.props.usersInSession} //TP-1599
                                                setUsersInSession={this.props.setUsersInSession} //TP-1599
                                                setCalleeList={this.setCalleeList} //TP-2381
                                                removeFromCalleeList={this.removeFromCalleeList} //TP-2946
                                                groupsData={this.state.groupsData} //TP-2493
                                                maxPerPage={this.state.maxPerPage} //TP-2883
                                                isMobileDevice={this.state.isMobileDevice} //TP-2892
                                                isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                                enable_streaming={this.props.enable_streaming} //TP-2790
                                                audio_mode={this.props.audio_mode} //TP-3156
                                                allow_expert_to_expert_call={this.props.allow_expert_to_expert_call} //TZ-989
                                                session_type={this.props.session_type} //TP-3156
                                                updateProfilePic={this.props.updateProfilePic} //TP-3328
                                                updatePrfilePicInArray={this.props.updatePrfilePicInArray} //TP-3385
                                                updateMyProfilePicture={this.updateMyProfilePicture} //TP-3672 & TP-3369
                                                loadUserProfile={this.props.loadUserProfile} //TP-3746 & TP-3328
                                                setCurrentStreamingRes={this.props.setCurrentStreamingRes} //TP-3821
                                                isUserCall={this.props.isUserCall} //TP-5036
                                                setCameraResolution={this.props.setCameraResolution} //TP-5429
                                                isPartOfAGroup={this.state.isPartOfAGroup} //TP-4814
                                                refreshUser={this.state.refreshUser} //TP-6431
                                                updateChatArr={this.updateChatArr} //TP-6222
                                                chatMsgInfoArr={this.state.chatMsgInfoArr} //TP-6222
                                                openUserChat={this.state.openUserChat} //TP-6222
                                                selectedUserEmail={this.state.selectedUserEmail} //TP-6222
                                                customerRoles={this.props.customerRoles}
                                                updateuserdata={this.updateuserdata} //TZ-487
                                            />
                                    
                                        }
                                    </AccountConsumer>
                                </div>

                                <div id="AccountDetails" className="tabcontent">
                                    { this.showLoader(this.state.isLoading) }
                                    <AccountConsumer>
                                        {(consumerProps) =>
                                            <AccountDetails 
                                                {...consumerProps}
                                                purchases={this.state.purchases}
                                                accountId={this.props.accountid}
                                                email={this.props.email}
                                                plans={this.state.plans}
                                                adminUserId={this.props.id}
                                                onUpdatePurchases={this.onUpdatePurchases}
                                                authService={this.authService}
                                                customer={this.state.customer}
                                                paymentSources={this.state.paymentSources}
                                                defaultSource={this.state.defaultSource}
                                                onAddNewCard={this.onAddNewCard}
                                                onMakeDefaultCard={this.onMakeDefaultCard}
                                                onDeleteCard={this.onDeleteCard}
                                                isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                            />
                                        }
                                    </AccountConsumer>
                                </div> 
                                { this.props.enable_streaming === true ?
                                    <div id="UsageDetails" className="tabcontent">
                                        <AccountConsumer>
                                            {({noInternet, isConnected, onlineUsers}) =>
                                                <UsageDetails 
                                                    isadmin={this.props.isadmin}
                                                    isSmartAdmin={this.props.isSmartAdmin}
                                                    updateAllUsers={this.updateAllUsers} //TP-4352
                                                    isexpert={this.props.isexpert}
                                                    userid={this.props.id}
                                                    activePage={this.state.pageName}
                                                    accountid={this.props.accountid}
                                                    adminname={this.props.name} //TZ-487
                                                    adminemailid={this.props.email}
                                                    accountId={this.props.accountid}
                                                    authService={this.authService}
                                                    downloadUsage={this.state.customer.download_usage}
                                                    customer={this.state.customer}
                                                    setRecordingsArray={this.setRecordingsArray}
                                                    maxPerPage={this.state.maxPerPage} //TP-2883
                                                    isMobileDevice={this.state.isMobileDevice} //TP-2892
                                                    isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                                    updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462
                                                    showMissedCalls={this.state.showMissedCalls}
                                                    loadSessionInfo={this.loadSessionInfo}
                                                    setUsersInSession={this.props.setUsersInSession} //TP-1599,5766
                                                    isUserCall={this.props.isUserCall} //TP-5036,5766
                                                    setCalleeList={this.setCalleeList} //TP-2381,5766
                                                    usersInSession={this.props.usersInSession} //TP-1599,5766
                                                    isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                                    noInternet={noInternet}
                                                    isConnected={isConnected}
                                                    onlineUsers={onlineUsers}
                                                    enable_streaming={this.state.customer.enable_streaming}
                                                    setGroupUsersList={this.setGroupUsersList}
                                                    onRouteChange={this.onRouteChange}
                                                    isPartOfAGroup={this.state.isPartOfAGroup}
                                                    groupsData={this.state.groupsData} //TP-2493
                                                    myCameraOn={this.props.myCameraOn}//TP-3532
                                                    setCameraResolution={this.props.setCameraResolution} //TP-5429
                                                    inSessionArr={this.state.inSessionArr} //TP-1534
                                                    userlist={this.state.userlist}
                                                    loadSelectedGroup={this.loadSelectedGroup}
                                                    refetchMissedcalls={this.state.refetchMissedcalls} //TZ-475
                                                    updateShowMissedCallFlag={this.updateShowMissedCallFlag}//TZ-540
                                                />
                                            }
                                        </AccountConsumer>
                                    </div>
                                    :
                                    ''
                                }
                                {
                                    this.props.isSmartAdmin === false && this.state.recordings !== null ? 
                                        <Recordings
                                            recordings ={this.state.recordings}
                                            setRecordingsArray={this.setRecordingsArray}
                                            isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                        />
                                    :
                                    ''
                                } 
                                { this.props.isSmartAdmin === false && this.state.customer.is_gps_enabled ?
                                    <>
                                        {/* <div id="gps" className="tabcontent">
                                            <Gps 
                                                isadmin={this.props.isadmin}
                                                isexpert={this.props.isexpert}
                                                userid={this.props.id}
                                                accountId={this.props.accountid}
                                                authService={this.authService}
                                            />
                                        </div>  */}

                                        { /*TP-3756*/ this.state.isSmallFormFactor === false ? /**TP-3616*/
                                            <div id="jobs" className="tabcontent justify-content-center align-items-center">
                                                <AccountConsumer>
                                                    {({language, noInternet, isConnected, onlineUsers}) =>
                                                        <Jobs 
                                                            isadmin={this.props.isadmin}
                                                            isexpert={this.props.isexpert}
                                                            email={this.props.email}
                                                            userid={this.props.id}
                                                            accountId={this.props.accountid}
                                                            authService={this.authService}
                                                            customer={this.state.customer}//T32-10
                                                            enable_asset={this.state.user.enable_asset}//TP-2721
                                                            maxPerPage={this.state.maxPerPage} //TP-2883
                                                            isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                                            enable_job_template={this.state.user.enable_job_template}//TP-3161
                                                            user_enable_job_scheduler={this.state.user.user_enable_job_scheduler}//TP-4891
                                                            language={language}
                                                            isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                                            noInternet={noInternet}
                                                            isConnected={isConnected}
                                                            name={this.props.name}
                                                            log_level={this.props.log_level}
                                                            loadSessionInfo={this.loadSessionInfo}
                                                            updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462,5766
                                                            setGroupUsersList = {this.setGroupUsersList}
                                                            setUsersInSession={this.props.setUsersInSession} //TP-1599,5766
                                                            isUserCall={this.props.isUserCall} //TP-5036,5766
                                                            setCalleeList={this.setCalleeList} //TP-2381,5766
                                                            usersInSession={this.props.usersInSession} //TP-1599,5766
                                                            activePage={this.state.pageName}
                                                            loadSelectedGroup={this.loadSelectedGroup}
                                                            onRouteChange={this.onRouteChange}
                                                            onlineUsers = {onlineUsers}
                                                            customerRoles={this.props.customerRoles}
                                                        />
                                                    }
                                                </AccountConsumer>
                                            </div> 
                                            :
                                            ''
                                        }

                                        {/**TP-3756 */ this.state.isSmallFormFactor === false ? /**TP-2883 */
                                            <div id="asset" className="tabcontent justify-content-center align-items-center">
                                                <Asset
                                                    isadmin={this.props.isadmin}
                                                    isexpert={this.props.isexpert}
                                                    name={this.props.name}
                                                    email={this.props.email}
                                                    userid={this.props.id}
                                                    accountId={this.props.accountid}
                                                    customer={this.state.customer}//T32-10
                                                    maxPerPage={this.state.maxPerPage} //TP-2883
                                                    isMobileDevice={this.state.isMobileDevice} //TP-3260
                                                    isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                                />
                                            </div>
                                            :
                                            ''
                                        }
                                        { /*TP-3756*/ this.state.isSmallFormFactor === false ? /**TP-2883 */
                                            <div id="asset_class" className="tabcontent justify-content-center align-items-center">
                                                <AssetClass 
                                                    isadmin={this.props.isadmin}
                                                    isexpert={this.props.isexpert}
                                                    name={this.props.name}
                                                    email={this.props.email}
                                                    userid={this.props.id}
                                                    accountId={this.props.accountid}
                                                    customer={this.state.customer}//T32-10
                                                    maxPerPage={this.state.maxPerPage} //TP-2883
                                                    isMobileDevice={this.state.isMobileDevice} //TP-3259
                                                    isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                                />
                                            </div>
                                            :
                                            ''
                                        }
                                    </>
                                    : ''
                                }
                                {/**TP-3949 & TP-3328*/  
                                <div id="MyProfile" className="tabcontent min-height-profile">
                                    <AccountConsumer>
                                        {(consumerProps) =>
                                            <MyProfile
                                                {...consumerProps}
                                                id={this.props.id}
                                                authService={this.authService}
                                                first_name={this.state.user.first_name}
                                                last_name={this.state.user.last_name}
                                                phonenumber={this.state.user.phonenumber}//TP-6082
                                                email={this.state.user.email}
                                                isexpert={this.props.isexpert}
                                                isadmin={this.props.isadmin} //TP-3950
                                                myPicture={this.props.myPicture}
                                                streaming_quality={this.props.streaming_quality} /**TP-3794*/
                                                image_quality={this.props.image_quality} /**TP-3794*/
                                                camera_on={this.props.camera_on} /**TP-3794*/
                                                auto_connect={this.props.allow_auto_connect} /**TP-3794*/
                                                disable_notifications={this.props.disable_notifications} /**TP-3794*/
                                                enable_auto_connect={this.state.customer.enable_auto_connect}
                                                enable_user_video={this.state.customer.enable_user_video}
                                                sms_support={this.state.customer.sms_support}//TP-6082
                                                enable_streaming={this.state.customer.enable_streaming}
                                                isMobileDevice={this.state.isMobileDevice} //TP-3260
                                                isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                                onRouteChange={this.onRouteChange}
                                                openPage={this.openPage}
                                                updateMyProfilePicture={this.updateMyProfilePicture}//TP-3369
                                                updateChangePassword={this.props.updateChangePassword} //TP-3213
                                                loadUserProfile={this.props.loadUserProfile}
                                                updateMyProfilePic={this.props.updateMyProfilePic}
                                                isVerySmallDevice={this.props.isVerySmallDevice} //TP-3746
                                                updateOpenGroup={this.updateOpenGroup} //TP-4028
                                                triggerResizeMyProfile={this.state.triggerResizeMyProfile} //TP-4067
                                                setCameraResolution={this.props.setCameraResolution} //TP-3821
                                                setStreamingOptions={this.props.setStreamingOptions} //TP-3821
                                                verified={this.state.user.verified}//TP-5234
                                                openChangePassword={this.openChangePassword} /**TP-5504*/
                                                checkMonthlyMinutes={this.checkMonthlyMinutes}//TP-6213
                                            />
                                        }
                                    </AccountConsumer>
                                </div>
                                }   
                                {/**TP-5504*/}
                                <div id="ChangePassword" className="tabcontent min-height-profile">
                                    <AccountConsumer>
                                        {(consumerProps) =>
                                            <ChangePassword {...consumerProps}
                                                routeChange={this.onRouteChange} 
                                                onCloseModal={this.props.closeAdminPopup}
                                                behaviour={this.props.behaviour}
                                                accountid={this.props.accountid}
                                                id={this.props.id}
                                                oldPassword={this.props.oldPassword}
                                                email={this.state.user.email}
                                                isChangePassword={true} //TP-3213
                                                last_accessed_on={this.props.last_accessed_on} //TP-5418
                                                updateOpenMyProfile={this.updateOpenMyProfile}
                                            />
                                        }
                                    </AccountConsumer>  
                                </div>
                            </>
                    }

			</Fragment>
    }

    getExpertRender = () => {     
        return <Fragment>
                {/*TP-4028*/ this.getTabContent("expert")}
                <div id="Groups" className="tabcontent">
                    <AccountConsumer>
                        {(consumerProps) =>
                            <Groups 
                                {...consumerProps}
                                isSmartAdmin={this.props.isSmartAdmin}
                                isPilot={this.props.isPilot} //TP-2539
                                isadmin={this.props.isadmin}
                                email={this.props.email}
                                activePage={this.state.pageName}
                                customer={this.state.customer} //TZ-1487
                                adminname={this.props.name}
                                userid={this.props.id}
                                accountid={this.props.accountid}
                                myPicture={this.props.myPicture}
                                isexpert={this.props.isexpert} 
                                fetchLatest={this.state.isChanged}
                                log_level={this.props.log_level}
                                myCameraOn={this.props.myCameraOn}//TP-3532
                                loadSelectedGroup={this.loadSelectedGroup}
                                loadGroupUserList={this.loadGroupUserList}
                                onRouteChange={this.onRouteChange}
                                updateGroupInfo={this.updateGroupInfo}
                                getMyGroups={this.getMyGroups}
                                authService={this.authService}
                                numberOfExperts={this.state.customer.number_of_experts}
                                isCustomizable={this.state.customer.is_customizable}
                                i18n={this.props.i18n}
                                isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                isSafari = {this.props.isSafari}
                                updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462
                                loadSessionInfo={this.loadSessionInfo}
                                inSessionArr={this.state.inSessionArr} //TP-1534
                                setCalleeList={this.setCalleeList} //TP-2381
                                removeFromCalleeList={this.removeFromCalleeList} //TP-2946
                                maxPerPage={this.state.maxPerPage} //TP-2883
                                isMobileDevice={this.state.isMobileDevice} //TP-2892
                                isSmallFormFactor={this.state.isSmallFormFactor} //TP-2892
                                enable_streaming={this.props.enable_streaming} //TP-2790
                                audio_mode={this.props.audio_mode} //TP-3156
                                allow_expert_to_expert_call={this.props.allow_expert_to_expert_call} //TZ-989
                                session_type={this.props.session_type} //TP-3156
                                isUserCall={this.props.isUserCall} //TP-5036
                                setCameraResolution={this.props.setCameraResolution} //TP-5429
                                changeIspartOfaGroup={this.changeIspartOfaGroup} //TP-4814
                                updateChatArr={this.updateChatArr} //TP-6771
                                chatMsgInfoArr={this.state.chatMsgInfoArr} //TP-6771
                                customerRoles={this.props.customerRoles}
                                openGroupChat={this.state.openGroupChat} //TP-6222
                                selectedGroup={this.state.selectedGroup}
                            />
                        }
                    </AccountConsumer>
				</div>
				<div id="Users" className="tabcontent">
                    <AccountConsumer>
                        {(consumerProps) =>
                            <Users 
                                {...consumerProps}
                                isGuest={this.props.isGuest} /**TP-4352*/
                                allUsersofAccount={this.state.allUsersofAccount} /**TP-4352*/
                                defaultHeight={this.state.user.selectedHeight}//TP-5431
                                defaultWidth={this.state.user.selectedWidth} /**TP-5431*/
                                isSmartAdmin={this.props.isSmartAdmin}
                                activePage={this.state.pageName}
                                purchases={this.state.purchases}
                                adminemailid={this.props.email}
                                isadmin={this.props.isadmin}
                                isexpert={this.props.isexpert}
                                adminname={this.props.name}
                                userid={this.props.id}
                                accountid={this.props.accountid}
                                behaviour={this.props.behaviour}
                                last_accessed_on={this.props.last_accessed_on}
                                log_level={this.props.log_level}
                                myPicture={this.props.myPicture}//TP-3328
                                myCameraOn={this.props.myCameraOn}//TP-3532
                                onUpdatePurchases={this.onUpdatePurchases}
                                onStateChange={this.onChangeUser}
                                authService={this.authService}
                                customer={this.state.customer}
                                loadSelectedGroup={this.loadSelectedGroup}
                                setGroupUsersList={this.setGroupUsersList}
                                onRouteChange={this.onRouteChange}
                                isChanged={this.state.isChanged}
                                canResetUserPassword={this.props.canResetUserPassword}
                                isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                userIdForAutocall={this.props.userIdForAutocall}//TP-566
                                autoCall={this.props.autoCall}//TP-566
                                updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462
                                loadSessionInfo={this.loadSessionInfo}
                                inSessionArr={this.state.inSessionArr} //TP-1534
                                usersInSession={this.props.usersInSession} //TP-1599
                                setUsersInSession={this.props.setUsersInSession} //TP-1599
                                setCalleeList={this.setCalleeList} //TP-2381
                                removeFromCalleeList={this.removeFromCalleeList} //TP-2946
                                groupsData={this.state.groupsData} //TP-2493
                                maxPerPage={this.state.maxPerPage} //TP-2883
                                isMobileDevice={this.state.isMobileDevice} //TP-2892
                                isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                enable_streaming={this.props.enable_streaming} //TP-2790
                                audio_mode={this.props.audio_mode} //TP-3156
                                allow_expert_to_expert_call={this.props.allow_expert_to_expert_call} //TZ-989
                                session_type={this.props.session_type} //TP-3156
                                updateProfilePic={this.props.updateProfilePic}//TP-3328
                                updatePrfilePicInArray={this.props.updatePrfilePicInArray} //TP-3385
                                updateMyProfilePicture={this.updateMyProfilePicture} //TP-3672 & TP-3369
                                loadUserProfile={this.props.loadUserProfile} //TP-3746 & TP-3328
                                setCurrentStreamingRes={this.props.setCurrentStreamingRes} //TP-3821
                                isUserCall={this.props.isUserCall} //TP-5036
                                setCameraResolution={this.props.setCameraResolution} //TP-5429
                                isPartOfAGroup={this.state.isPartOfAGroup} //TP-4814
                                refreshUser={this.state.refreshUser} //TP-6431
                                updateChatArr={this.updateChatArr} //TP-6222
                                chatMsgInfoArr={this.state.chatMsgInfoArr} //TP-6222
                                openUserChat={this.state.openUserChat} //TP-6222
                                selectedUserEmail={this.state.selectedUserEmail} //TP-6222
                                customerRoles={this.props.customerRoles}
                                updateuserdata={this.updateuserdata} //TZ-487
                            />
                        }
                    </AccountConsumer>
				</div>
                { this.props.enable_streaming === true ?
                    <div id="UsageDetails" className="tabcontent">
                        <AccountConsumer>
                            {({noInternet, isConnected, onlineUsers}) =>
                                <UsageDetails 
                                    isadmin={this.props.isadmin}
                                    isSmartAdmin={this.props.isSmartAdmin}
                                    updateAllUsers={this.updateAllUsers} //TP-4352
                                    isexpert={this.props.isexpert}
                                    userid={this.props.id}
                                    activePage={this.state.pageName}
                                    accountid={this.props.accountid}
                                    adminname={this.props.name} //TZ-487
                                    adminemailid={this.props.email}
                                    authService={this.authService}
                                    downloadUsage={this.state.customer.download_usage}
                                    customer={this.state.customer}
                                    accountId={this.props.accountid}//TP-1719
                                    setRecordingsArray={this.setRecordingsArray}
                                    maxPerPage={this.state.maxPerPage} //TP-2883
                                    isMobileDevice={this.state.isMobileDevice} //TP-2892
                                    isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                    updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462
                                    showMissedCalls={this.state.showMissedCalls}
                                    loadSessionInfo={this.loadSessionInfo}
                                    setUsersInSession={this.props.setUsersInSession} //TP-1599,5766
                                    isUserCall={this.props.isUserCall} //TP-5036,5766
                                    setCalleeList={this.setCalleeList} //TP-2381,5766
                                    usersInSession={this.props.usersInSession} //TP-1599,5766
                                    isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                    noInternet={noInternet}
                                    isConnected={isConnected}
                                    onlineUsers={onlineUsers}
                                    enable_streaming={this.state.customer.enable_streaming}
                                    setGroupUsersList={this.setGroupUsersList}
                                    onRouteChange={this.onRouteChange}
                                    isPartOfAGroup={this.state.isPartOfAGroup}
                                    groupsData={this.state.groupsData} //TP-2493
                                    myCameraOn={this.props.myCameraOn}//TP-3532
                                    setCameraResolution={this.props.setCameraResolution} //TP-5429
                                    inSessionArr={this.state.inSessionArr} //TP-1534  
                                    userlist={this.state.userlist} 
                                    loadSelectedGroup={this.loadSelectedGroup}     
                                    refetchMissedcalls={this.state.refetchMissedcalls}//TZ-475
                                    updateShowMissedCallFlag={this.updateShowMissedCallFlag}//TZ-540
                                />
                            }
                        </AccountConsumer>
                    </div> 
                    :
                    ''
                }
                {
                    this.props.isSmartAdmin === false && this.state.recordings !== null ? 
                        <Recordings
                            recordings ={this.state.recordings}
                            setRecordingsArray={this.setRecordingsArray}
                            isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                        />
                    :
                    ''
                }
                { this.props.isSmartAdmin === false && this.state.customer.is_gps_enabled ?
                    <>                        
                        {/* <div id="gps" className="tabcontent">
                            <Gps 
                                isadmin={this.props.isadmin}
                                isexpert={this.props.isexpert}
                                userid={this.props.id}
                                accountId={this.props.accountid}
                                authService={this.authService}
                            />
                        </div>  */}
                        { /*TP-3756*/ this.state.isSmallFormFactor === false ? /**TP-3616*/ 
                            <div id="jobs" className="tabcontent justify-content-center align-items-center">
                            <AccountConsumer>
                                {({language, noInternet, isConnected, onlineUsers}) =>
                                    <Jobs 
                                        isadmin={this.props.isadmin}
                                        isexpert={this.props.isexpert}
                                        email={this.props.email}
                                        userid={this.props.id}
                                        accountId={this.props.accountid}
                                        authService={this.authService}
                                        customer={this.state.customer}//T32-10,T32-241
                                        enable_asset={this.state.user.enable_asset}//TP-2721
                                        maxPerPage={this.state.maxPerPage} //TP-2883
                                        isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                        enable_job_template={this.state.user.enable_job_template}//TP-3161
                                        user_enable_job_scheduler={this.state.user.user_enable_job_scheduler}//TP-4891
                                        language={language}
                                        isMonthlyMinutesConsumed={this.state.isMonthlyMinutesConsumed}
                                        noInternet={noInternet}
                                        isConnected={isConnected}
                                        name={this.props.name}
                                        log_level={this.props.log_level}
                                        loadSessionInfo={this.loadSessionInfo}
                                        updateCallDialogStatus = {this.updateCallDialogStatus} //TP-1462,5766
                                        setGroupUsersList = {this.setGroupUsersList}
                                        setUsersInSession={this.props.setUsersInSession} //TP-1599,5766
                                        isUserCall={this.props.isUserCall} //TP-5036,5766
                                        setCalleeList={this.setCalleeList} //TP-2381,5766
                                        usersInSession={this.props.usersInSession} //TP-1599,5766
                                        activePage={this.state.pageName}
                                        loadSelectedGroup={this.loadSelectedGroup}
                                        onRouteChange={this.onRouteChange}
                                        onlineUsers = {onlineUsers}
                                        customerRoles={this.props.customerRoles}
                                    />
                                }
                            </AccountConsumer>

                            </div> 
                            :
                            ''
                        }
                        { /*TP-3756*/ this.state.isSmallFormFactor === false ? 
                            <div id="asset" className="tabcontent justify-content-center align-items-center">
                                <Asset
                                    isadmin={this.props.isadmin}
                                    isexpert={this.props.isexpert}
                                    name={this.props.name}
                                    email={this.props.email}
                                    userid={this.props.id}
                                    accountId={this.props.accountid}
                                    customer={this.state.customer}//T32-10,T32-241
                                    maxPerPage={this.state.maxPerPage} //TP-2883
                                    isMobileDevice={this.state.isMobileDevice} //TP-3260
                                    isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                />
                            </div> 
                            :
                            ''
                        }
                    </>
                    : ''
                }
                {/**TP-3949 & TP-3328*/
                <div id="MyProfile" className="tabcontent min-height-profile">
                    <AccountConsumer>
                        {(consumerProps) =>
                            <MyProfile
                                {...consumerProps}
                                id={this.props.id}
                                authService={this.authService}
                                first_name={this.state.user.first_name}
                                last_name={this.state.user.last_name}
                                phonenumber={this.state.user.phonenumber}//TP-6082
                                email={this.state.user.email}
                                isexpert={this.props.isexpert}
                                isadmin={this.props.isadmin} //TP-3950
                                myPicture={this.props.myPicture}
                                streaming_quality={this.props.streaming_quality} /**TP-3794*/
                                image_quality={this.props.image_quality} /**TP-3794*/
                                camera_on={this.props.camera_on} /**TP-3794*/
                                auto_connect={this.props.allow_auto_connect} /**TP-3794*/
                                disable_notifications={this.props.disable_notifications} /**TP-3794*/
                                enable_auto_connect={this.state.customer.enable_auto_connect}
                                enable_user_video={this.state.customer.enable_user_video}
                                enable_streaming={this.state.customer.enable_streaming}
                                sms_support={this.state.customer.sms_support}//TP-6082
                                isMobileDevice={this.state.isMobileDevice} //TP-3260
                                isSmallFormFactor={this.state.isSmallFormFactor} //TP-2947
                                onRouteChange={this.onRouteChange}
                                openPage={this.openPage}
                                updateMyProfilePicture={this.updateMyProfilePicture} //TP-3369
                                updateChangePassword={this.props.updateChangePassword} //TP-3213
                                loadUserProfile={this.props.loadUserProfile}
                                updateMyProfilePic={this.props.updateMyProfilePic}
                                isVerySmallDevice={this.props.isVerySmallDevice} //TP-3746
                                updateOpenGroup={this.updateOpenGroup} //TP-4028
                                triggerResizeMyProfile={this.state.triggerResizeMyProfile} //TP-4067
                                setCameraResolution={this.props.setCameraResolution} //TP-3821
                                setStreamingOptions={this.props.setStreamingOptions} //TP-3821
                                verified={this.state.user.verified}//TP-5234
                                openChangePassword={this.openChangePassword} /**TP-5504*/
                                checkMonthlyMinutes={this.checkMonthlyMinutes}//TP-6213
                            />
                        }
                    </AccountConsumer>
                </div>  
                }
                {/**TP-5504*/}
                <div id="ChangePassword" className="tabcontent min-height-profile">
                    <AccountConsumer>
                        {(consumerProps) =>
                            <ChangePassword {...consumerProps}
                                routeChange={this.onRouteChange} 
                                onCloseModal={this.props.closeAdminPopup}
                                behaviour={this.props.behaviour}
                                accountid={this.props.accountid}
                                id={this.props.id}
                                oldPassword={this.props.oldPassword}
                                email={this.state.user.email}
                                isChangePassword={true} //TP-3213
                                last_accessed_on={this.props.last_accessed_on} //TP-5418
                                updateOpenMyProfile={this.updateOpenMyProfile}
                            />
                        }
                    </AccountConsumer>
                </div>
        </Fragment>
    }

    getUsersForGroupId = () => {
        const { from, groupName, groupId } = this.state.callerConnectData;
        let fetchString = 'getusersbygroupid/'+ groupId;
        //console.log(fetchString); 
        this.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
        .then(data => {
            if(data){
                printConsole(`selected groups Info ${JSON.stringify(data)}`);
                //this.loadSelectedGroup(groupId, groupName, from); //For TP-2192
                this.loadGroupUserList(data);
                /* this.onRouteChange('session'); For TP-2192 this code was commented out
                this.setState({ showCallNotification: false }); */
                if (this.timer) clearTimeout(this.timer);
                if (this.props.log_level >= 3) {
                    diagonastics(this.authService,{
                        action: `acceptCall`,
                        sequence: 4,
                        next_step: '',
                        data: `recipient: ${from}, groupId: ${groupId}, groupName: ${groupName}`,
                        error: 'none'
                    })
                }
            }
        })
        .catch(err => {
            printConsole('cannot user data for groupid')
        })
    }

    getSessionTokenForGroupId = (groupId, action) => {
        const { from, groupName } = this.state.callerConnectData;
        const {isexpert, audio_mode /**TP-4268*/} = this.props;
        let fetchString = "", apiname;
        //TP-4268 && TP-4213 -- New API integration to support Tech2Tech call feature from Users Tab
        if (!isexpert && audio_mode === "tech2Tech") {
            fetchString = 'user_session/callPeer?groupID=';
            apiname = 'callPeer';
        } else {
            fetchString = 'sessiondataH364?groupID=';
            apiname = 'sessionData';
        }
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.id;
        printConsole(`${apiname} is called on accepting the call with payload ---> { groupID: ${groupId}, loggedInUserId: ${this.props.id} }`)
        this.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            if(response.status === 200) 
                return response.json();
            else
				throw response;
        })
        .then((data) => {
            printConsole(`${apiname} call is successful with payload ---> ${data.isongoingnow}, ${data.primary_expert_id}`);
            printConsole(`current user userid: ${this.props.id}`);
            printConsole(data);
            if (this.props.log_level >= 3) {
                diagnostics(this.authService,{
                    action: apiname, /**TP-4268 */
                    sequence: 3,
                    next_step: '',
                    data: data,
                    error: 'none'
                })                 
            }
            this.loadSessionInfo(data);  
            //if (!isexpert) { // This condition was commented out for #TP-2192
                this.loadSelectedGroup(groupId, groupName, from);
                this.onRouteChange('session');
                this.setState({ showCallNotification: false });     
            //}
        })
        .catch((error) => {
            // output error in sessiondata
            //this.setState({bResponded: true});
            if(error.status === 444) {
                this.setState({errStrReturned: i18nMark('Establishing the live call failed due to a server error (444). Please contact Telepresenz support if this problem persists.')});
            } else {
                this.setState({errStrReturned: i18nMark('The call could not proceed due to some issues.')});
            }
            printConsole(error)
            if (this.props.log_level >= 3) {
                diagonastics(this.authService,{
                    action: `sessiondataH364 has returned error`,
                    sequence: 1,
                    next_step: '',
                    data: `groupID: ${groupId}, loggedInUserId: ${this.props.userid}`,
                    error: `error code: ${error.status}`
                })
            }
        })
    }

    acceptCall = async () => {
        printConsole("Accepting the incoming call to Webapp user");
        const { from, groupName, groupId } = this.state.callerConnectData;
        //const {groupInfo} = this.state;
        //console.log("All groups Info", groupInfo);
        const {isexpert} = this.props;
        // UQ3-186 only for tech respond to call will be send but for expert in session js after hitting sessiondatah364
        if(!isexpert){
            this.authService.socket.emit('respond-to-the-call', { action: 'accepted', recipient: from, groupId, groupName });
            this.getSessionTokenForGroupId(groupId, '');            
            if (this.timer) clearTimeout(this.timer);
            if (this.props.log_level >= 3) {
                diagonastics(this.authService,{
                    action: `acceptCall`,
                    sequence: 4,
                    next_step: '',
                    data: `recipient: ${from}, groupId: ${groupId}, groupName: ${groupName}`,
                    error: 'none'
                })
            }
        } else {
            await this.getUsersForGroupId(); //TP-2192 -- To await the response from this API added an async-await 
            this.props.setRespondToCall(true);//TP-2462
            this.getSessionTokenForGroupId(groupId, '');
        }
    }

    declineCall = () => {
        const { from, groupName, groupId } = this.state.callerConnectData;
        this.authService.socket.emit('respond-to-the-call', { action: 'declined', recipient: from });
        this.setState({ showCallNotification: false });
        if (this.timer) clearTimeout(this.timer);
        if (this.props.log_level >= 3) {
            diagonastics(this.authService,{
                action: `declined`,
                sequence: 4,
                next_step: '',
                data: `recipient: ${from}, groupId: ${groupId}, groupName: ${groupName}`,
                error: 'none'
            })
        }
    }

    handlePermissionGranted = () => {
        this.setState({
            ignore: false
        });
    }
    handlePermissionDenied = () => {
        this.setState({
            ignore: true
        });
    }
    handleNotSupported = () => {
        this.setState({
            ignore: true
        });
    }

    handleNotificationOnClick = (e, tag) =>{
        window.focus(); //TZ-1451
    }

    handleNotificationOnError = (e, tag) => {
    }

    handleNotificationOnClose = (e, tag) => {
    }

    handleNotificationOnShow = (e, tag) => {
        this.playSound();
    }

    playSound = ()=>{
        document.getElementById('sound').pause();
    }

    stopSound = ()=>{
        document.getElementById('sound').pause();
    }

    handleButtonClick= ()=> {

        if(this.state.ignore) {
            return;
        }
        const now = Date.now();
        const title = `${this.state.callerConnectData.name} is calling you in Telepresenz.`;
        const body = `Click here to go to Telepresenz`;
        const tag = now;
        const icon = 'http://mobilusoss.github.io/react-web-notification/example/Notifications_button_24.png';
        const options = {
            tag: tag,
            body: body,
            icon: icon,
            lang: 'en',
            dir: 'ltr',
            sound: '/notification_rington.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
        }
        this.setState({
            title: title,
            options: options
        });
    }

    componentWillUnmount = () => {
        this.isMount = false;
        window.removeEventListener("resize", this.updateDimensions); //TP-2883
        this.authService.socket.removeListener(this.message, this.subscribeToChatSignal); //TP-6222
        this.authService.socket.removeListener('connect-the-call', this.subscribeToCallConnection);
        this.authService.socket.removeListener('group-user-change', this.subscribeToGroupUserChange);
        this.authService.socket.removeListener('log-me-out', this.subscribeToLogMeOut);
        this.authService.socket.removeListener('user-is-in-session', this.subscribeToUserInSession); //TP-1534
        this.authService.socket.removeListener('user-details-updated', this.subscribeToUserDetailsUpdate); //TP-1534
        this.authService.socket.removeListener('fetch-missed-call-notification', this.subscribeToMissedCallNotificationAlert)//TZ-487
    }

    checkPlatform = () => {
        let ua = navigator.userAgent.toLowerCase(); 
        let chromeAgent = ua.indexOf("chrome") > -1; 
        let safariAgent = ua.indexOf("safari") > -1; 
        if ((chromeAgent) && (safariAgent)) safariAgent = false; 

        let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (safariAgent)
        this.setState({isIOS})
    };

    //T32-462
    checkMonthlyMinutes = () => {
        this.authService.getProfile()
        .then(response => {
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(({user}) => {
            this.setState({isMonthlyMinutesConsumed: user.is_monthly_minutes_consumed, user: user})
            printConsole(user);
        })
        .catch((error) => {
            console.log(error)
        });
    }

    //Flash message specific methods
    updateShowMessage = (val) => {
        if (val === false)
            this.setState({ flashMsgText: "", flashValues: "", showFlashMessage: val, flashLeft: "", flashTop: "" });
    }

    render = () => {
		return(
            <>
			<Fragment>
                <Notification
                    ignore={this.state.ignore && this.state.title !== ''}
                    notSupported={this.handleNotSupported}
                    onPermissionGranted={this.handlePermissionGranted}
                    onPermissionDenied={this.handlePermissionDenied}
                    // onShow={this.handleNotificationOnShow}
                    onClick={this.handleNotificationOnClick}
                    onClose={this.handleNotificationOnClose}
                    onError={this.handleNotificationOnError}
                    timeout={5000}
                    title={this.state.title}
                    options={this.state.options}
                />
                {/** Commenting the notification sound because of Edge compactibility issue */}
                {/* <audio id='sound' preload='auto'>
                    <source src='/notification_rington.mp3' type='audio/mpeg' />
                    <source src='/notification_rington.ogg' type='audio/ogg' />
                    <embed hidden={true} autostart='false' loop={false} src='/notification_rington.mp3' />
                    </audio> 
                    */}
                {this.state.showCallNotification ? <CallNotification caller={this.state.callerConnectData.name} 
                                                                    acceptCall={this.acceptCall} 
                                                                    declineCall={this.declineCall} 
                                                                    showCallNotification={this.state.showCallNotification}
                                                                    isIOS={this.state.isIOS }
                                                                    /> : ''}
                {this.props.isadmin ? this.getAdminRender() : ''}
                {this.props.isexpert && !this.props.isadmin ? this.getExpertRender() : ''}
                {!this.props.isadmin && !this.props.isexpert ? this.getTechnicianRender() : '' }
			</Fragment>
            <FlashMessage 
                flashValues={this.state.flashValues}
                flashMsgText={this.state.flashMsgText} 
                flashLeft={this.state.flashLeft}
                flashTop={this.state.flashTop}
                showMessage={this.state.showFlashMessage} 
                updateShowMessage={this.updateShowMessage}
                isTimerEnabled={true}/**TP-5986 */
            ></FlashMessage>
            </>
		);
	}
}

const CallNotification = (props) => (
    <Modal size="sm" isOpen={true} className="call-notification-toast bounce">
        <ModalHeader className="text-center">{props.caller} <Trans>is calling you...</Trans></ModalHeader>
        <ModalBody>
            {props.caller} <Trans> is calling you. Do you want to accept the call?</Trans>
            {!props.isIOS ? 
                <audio autoPlay={props.showCallNotification} loop={props.showCallNotification} src="/incoming_call.mp3"/>
                : ''
            }
        </ModalBody>
        <ModalFooter>
            <div className="d-flex justify-content-end">
                <BButton size="sm" className="mr-1 btn-r" onClick={props.declineCall}><Trans>Decline</Trans></BButton>
                <BButton size="sm" className="btn-g" onClick={props.acceptCall}><Trans>Accept</Trans></BButton>
            </div>
        </ModalFooter>
    </Modal>
)


export default AccountManager;
