import React, {PureComponent} from 'react';
import './session.css';

class SessionTimer extends PureComponent {
    constructor (props) {
      super(props);
      this.state = {
          count: 0,
          timerInHMS: '00:00',
          startTimer: this.props.startTimer,
          credit_limits: this.props.credit_limits,
          timerColor: 'white',
          hideClass: ''
        }
    }

    addLeadingZeros = (value) => {
        value = String(value);
        while (value.length < 2) {
            value = '0' + value;
        }
        return value;
    }
    
    convertToHrMins = () => {
        let timeCount = this.state.count;
        let seconds = timeCount%60;
        let minutes = Math.floor((timeCount % (60 * 60)) / (60));
        minutes = minutes >= 60 ? 0:minutes;
        let hours = Math.floor((timeCount % (60 * 60 * 24)) / (60 * 60));   
        if (hours > 0) {
            return (this.addLeadingZeros(hours) + ':' + this.addLeadingZeros(minutes) + ':' + 
            this.addLeadingZeros(seconds));    
        } else if (hours === 0) {
            // Removing the hours from the timer
            return (this.addLeadingZeros(minutes) + ':' + 
            this.addLeadingZeros(seconds));
        }
    }

    checkCreditLimit = (timeCount) => {
        let credit_limits = this.state.credit_limits;
        let {first_threshold,available_monthly_minutes} = credit_limits;
        if(available_monthly_minutes === 0 || timeCount > (available_monthly_minutes*60)){
            this.setState({timerColor: "red"});
        }else if(first_threshold > available_monthly_minutes || 
            ( ( (first_threshold*60) + timeCount) > (available_monthly_minutes * 60) ) ){
                this.setState({timerColor: "yellow"});
            }
    }
    
    tick = () => {
        this.setState({count: (this.state.count + 1)},() => {
            this.setState({timerInHMS: this.convertToHrMins()});
            this.checkCreditLimit(this.state.count);
        });
    }
    
    start = () => {
        this.setState({hideClass: ''},() => {
            this.timer = setInterval(this.tick.bind(this), 1000);
        });
    }
    
    stop = () => {
        this.setState({
            hideClass: 'd-none',
            count: 0,
            timerInHMS: '00:00'
        },() => {
            clearInterval(this.timer);
        });
    }
    
    componentWillUnmount = () => {
        clearInterval(this.timer)
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.startTimer!==this.props.startTimer){
            
            if(this.state.startTimer){
                
                this.start();
                
            }else{
                
                this.stop();
            }
            
        }
    }
    
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.startTimer!==prevState.startTimer){
          return { 
                startTimer: nextProps.startTimer,
                credit_limits: nextProps.credit_limits
            };
        }
       else return null;
    }

    render(){
        return(
            <span style={{color: `${this.state.timerColor}`}} className={`float-right pr-2 ${this.state.hideClass}`}>
                {this.state.timerInHMS}
            </span>
        );
    }
}

export default SessionTimer;