import React from "react";
import { I18n } from "@lingui/react";
import { Trans } from '@lingui/macro';
import SidePanelVideoTile from './SidePanelVideoTile.jsx';
import {UncontrolledTooltip} from 'reactstrap';
import { printConsole } from '../helpers';

class SidePanel extends React.Component {
    constructor(props) {
        super(props)
        this.testRef = React.createRef(); //TP-3213
        this.state = {
            newVdoheight: 0,
            newVdowidth: 0,
            participantWithVideos: [],
            changedParticipant: props.changedParticipant ? props.changedParticipant : null,
            showPanel: false
        }
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.changedParticipant !== this.props.changedParticipant) {
            this.setState({changedParticipant: nextProps.changedParticipant});
        }
    }

    componentDidMount () {
        this.updateVideoStream();
        window.addEventListener("resize", 
            this.updateVideoStream()
        );
    }

    componentDidUpdate (prevProps, prevState){
        //TP-3213
        if(this.props.participants && this.props.participants.length !== prevProps.participants.length){
            console.log("participant tiles count changed.", this.props.participants.length);
            this.updateVideoStream(true) //TP-3213
        }
        if(this.props.triggeruiRedraw !== prevProps.triggeruiRedraw && this.props.triggeruiRedraw === true){
            printConsole("redraw the UI again");
            this.updateVideoStream(true); //TP-3213
        }
        if(this.props.showPanel !== prevProps.showPanel && this.props.showPanel === true) {
            printConsole("recalibrate the UI of the panel again");
            this.updateVideoStream(true); //TP-3213
        }
    }

    //TP-3213
    updateOneParticipant = (pp) => {
        const {participants, user, isAccountLevelExpertVideoDisabled} = this.props;
        if (!user.isexpert && isAccountLevelExpertVideoDisabled === false) {
            console.log("updateVideoStream for particular user....", pp.email);
            printConsole(participants.length);
            let participantWithVideos = participants;
            let updatedStateVar = {};
            
            //console.log("partcipants with User Video on ", participantWithVideos);
            if(participantWithVideos && participantWithVideos.length > 0){
                printConsole(`participants with Video turned on: ${participantWithVideos.length}`)
                printConsole(participantWithVideos);

                participantWithVideos.forEach(participant => {
                    if (participant.email === pp.email) {
                        this.streamTimer = setTimeout(() => {
                            if (participant.streams && participant.streams.length>0) {
                                
                            }
                            clearTimeout(this.streamTimer);
                        }, 1000);
                    }
                })
                const [ newVdoheight, newVdowidth, numCells ] = this.getGridViewDim(participantWithVideos.length);// dimension of the grid
                console.log(newVdoheight, newVdowidth, numCells);
                updatedStateVar.newVdoheight = newVdoheight;
                updatedStateVar.newVdowidth = newVdowidth;
                updatedStateVar.participantWithVideos = participantWithVideos;
                this.setState((prev) => ({
                    ...prev,
                    ...updatedStateVar
                }));
            }
        }
    }

    updateVideoStream = (flag=false) => {
        const {participants, user, sessionType, isAccountLevelExpertVideoDisabled, /**TP-3637*/ setMaxView} = this.props;
        if (!user.isexpert && isAccountLevelExpertVideoDisabled === false) {
            console.log("updateVideoStream for technician....");
            printConsole(participants.length);
            let participantWithVideos = participants;
            let updatedStateVar = {};
            //TP-3213
            if (flag === true) {
                printConsole("resetting the video width & height....");
                updatedStateVar.newVdoheight = 0;
                updatedStateVar.newVdowidth = 0;
                updatedStateVar.participantWithVideos = participantWithVideos;
                this.setState((prev) => ({
                    ...prev,
                    ...updatedStateVar
                }), () => {
                    //TP-3213
                    //console.log("partcipants with User Video on ", participantWithVideos);
                    if(participantWithVideos && participantWithVideos.length > 0){
                        printConsole(`participants with Video turned on: ${participantWithVideos.length}`)
                        printConsole(participantWithVideos);
                        const [ newVdoheight, newVdowidth, numCells ] = this.getGridViewDim(participantWithVideos.length);// dimension of the grid
                        console.log(newVdoheight, newVdowidth, numCells);
                        updatedStateVar.newVdoheight = newVdoheight;
                        updatedStateVar.newVdowidth = newVdowidth;
                        updatedStateVar.participantWithVideos = participantWithVideos;
                        this.setState((prev) => ({
                            ...prev,
                            ...updatedStateVar
                        }));
                    }
                });
            } else {
                //console.log("partcipants with User Video on ", participantWithVideos);
                if(participantWithVideos && participantWithVideos.length > 0){
                    printConsole(`participants with Video turned on: ${participantWithVideos.length}`)
                    printConsole(participantWithVideos);
                    const [ newVdoheight, newVdowidth, numCells ] = this.getGridViewDim(participantWithVideos.length);// dimension of the grid
                    console.log(newVdoheight, newVdowidth, numCells);
                    updatedStateVar.newVdoheight = newVdoheight;
                    updatedStateVar.newVdowidth = newVdowidth;
                    updatedStateVar.participantWithVideos = participantWithVideos;
                    this.setState((prev) => ({
                        ...prev,
                        ...updatedStateVar
                    }));
                }
            }
            
        }
    }

    //TP-3213
    getGridViewDim = (userCount) => {
        let numCells, newVdoheight, newVdowidth;
        if(userCount && userCount > 1){
            numCells = userCount;
            newVdoheight = this.getRowHeight();
            newVdowidth = this.getRowWidth(numCells, newVdoheight);
            
        }else{
            numCells = userCount;
            newVdoheight= this.getRowHeight();
            newVdowidth= this.getRowWidth(numCells, newVdoheight);
        }
        return [newVdoheight, newVdowidth, numCells];//TP-2973
    }

    //TP-3213
    getRowHeight = () => {
        const rect = document.getElementById("sidePanel").getBoundingClientRect();
        const panelHeight = rect.height;
        const yGap = 6;
        const yMargin = 10;        
        let newVdoheight = Math.floor(panelHeight - (yGap + yMargin));

        return newVdoheight;
    }

    //TP-3213
    getRowWidth = (numCells, newVdoheight) => {
        let newVdowidth;
        newVdowidth = Math.floor(newVdoheight * (16/9));

        return newVdowidth;
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateVideoStream); // T32-575
    }

    //TP-3213 -- Addition of Navigation buttons for Side panel UI
    checkToShowNavButtons = () => {
        if(document.getElementById("sidePanel") && document.getElementById("sidePanel") !== null) {
            const {newVdowidth, participantWithVideos} = this.state;
            const tileCount = participantWithVideos.length - 1; //TP-4272
            const sideBarWidth = document.getElementById("sideBar").offsetWidth;
            //console.log(newVdowidth, tileCount, sideBarWidth, window.innerWidth);
            //if(tileCount > 4)
            if ((window.innerWidth - sideBarWidth) < (newVdowidth * tileCount))
                return true;
            else
                return false;
        } else 
            return false;
    }

    scrollToElement = (offset) => this.testRef.current.scrollTo(offset,0);
    
    render() {
        const {participantWithVideos, newVdoheight, newVdowidth, changedParticipant} = this.state;
        const {user, sessionType, connections, setTechMaxStreamid, ssFrom, isSmallFormFactorDevice} = this.props;
        let buttonSize = (window.innerWidth > 1299) ? "fa-lg" : "fa-sm"; 
        const leftStyle = {left: '1.5%', visibility: this.checkToShowNavButtons() === true ? "visible" : "hidden"};
        const rightStyle = {right: '1.5%', visibility: this.checkToShowNavButtons() === true ? "visible" : "hidden"};
        //TP-4330
        let containerClass = " justify-content-center align-items-center";
        if (this.checkToShowNavButtons() === true) {
            containerClass = "";
        }
        return (
            <div className={"side-panel-container"+containerClass} ref={this.testRef}> 
                <div className="nav-buttons-side-panel" style={leftStyle}>
                    {/*TP-3213 Left Button*/}
                        <span className={"fa-stack "+buttonSize+"prev"}>                            
                            <a id="slideleft" onClick={() => this.scrollToElement(-newVdowidth)}>
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fas fa-angle-left fa-stack-1x"></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="slideleft">                                            
                                        <Trans>session.slideleft</Trans>                                            
                                    </UncontrolledTooltip> 
                                    :
                                    ''
                                }
                            </a>                                
                        </span>                    
                </div> 
                <I18n>
                        {({i18n}) =>
                            participantWithVideos.map((participant, index, participantWithVideos) => {
                                //const [videoConnection] = connections.filter(conn => participant.email !== user.email && conn.email === participant.email);
                                if(participant.stream && participant.stream.id !== setTechMaxStreamid){
                                    return (
                                        <SidePanelVideoTile
                                            key={index}
                                            //videoConnection={videoConnection}
                                            /* participants= {participantWithVideos} */
                                            participant={participant}
                                            changedParticipant={changedParticipant}
                                            user = {user}
                                            index= {index}
                                            vdoheight= {newVdoheight}
                                            vdowidth= {newVdowidth}
                                            ssFrom={ssFrom}
                                            onClickMaximize={this.props.onClickMaximize}
                                            /* setTechMaxStreamid={setTechMaxStreamid} */
                                            sessionType={sessionType}
                                            isSmallFormFactorDevice={isSmallFormFactorDevice}//TP-4572
                                            triggerDeviceErrorAndEndSession={this.props.triggerDeviceErrorAndEndSession} //TP-5442, TP-5441
                                        />
                                    )
                                }else{
                                    return null;
                                }
                            })
                        }
                </I18n> 
                <div className="nav-buttons-side-panel" style={rightStyle}>
                    {/*TP-3213 Right Button*/}
                        <span className={"fa-stack "+buttonSize+"next"}>                            
                            <a id="slideright" onClick={() => this.scrollToElement(+(newVdowidth + 100))}>
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fas fa-angle-right fa-stack-1x"></i>
                                { isSmallFormFactorDevice === false ?
                                    <UncontrolledTooltip   innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="slideright">                                            
                                        <Trans>session.slideright</Trans>                                            
                                    </UncontrolledTooltip> 
                                    :
                                    ''
                                }
                            </a>                                
                        </span>
                </div>
            </div>                
           
        )
    }
}

export default SidePanel;
