import React, {Fragment} from 'react';
import { Row, Col, Badge, Form, ModalBody, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import Button from '../Button/Button';
import SimpleReactValidator from 'simple-react-validator';
import MessageModal from '../MessageModal/MessageModal';
import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import './RegisterTrialAccount.scss';

export default class RegisterTrialAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            contact_email: '',
            contact_name: '',
            company_details: '',
            contact_phone: '',
            serial_numbers: [],
            serial_number: '',
            vendor_name: '',
            isLoading: false,
            message: '',
            isError: false,
            language:''
        }
        // initialize validator
        this.vaidationRules(props.language);
    }

    onChangeForm = name => event => {
        event.persist();
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		this.setState({
            [name]: value
        });
    }
    
    addSerialNo = (e) => {
        e.preventDefault();
        if (!this.state.serial_number) return;
        this.setState(prev => ({
            serial_numbers: [
                ...prev.serial_numbers,
                this.state.serial_number.trim()
            ],
            serial_number: ''
        }))
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
        }
        if (!this.state.serial_numbers.length) {
            this.setState({
                isError: true,
                message: i18nMark('Minimum one device serial number required.')
            })
            return; 
        } 
        this.setState({ isLoading: true });
        const { isLoading, serial_number, message, ...optionsToSave } = this.state;
		fetch(window._env_.REACT_APP_API_URL + '/registerTrialAccount', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
                ...optionsToSave,
                contact_email: optionsToSave.contact_email.trim().toLocaleLowerCase(),
                serial_numbers: [
                    ...new Set(optionsToSave.serial_numbers)
                ]
            })
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
		.then(res => {
            if (res) {
                this.setState({
                    message: i18nMark('Your free trial registration has been received. You will receive an email shortly with a confirmation and login credentials.'),
                    isLoading: false
                })
            }
		})
		.catch((error) => {
            let message = i18nMark('Something went wrong. Please try again later.');
            if (error.status === 409) {
                message = i18nMark('The email provided is already signed up for paid purchases. You can log into the paid account and edit the relevant user details to attach the device serial number to one of the registered users on the platform. You may also use a different email-id to request the new device purchase trial.');
            }
            if (error.status === 410) {
                message = i18nMark('The serial numbers provided are already registered. Please enter unique serial numbers.');
            }
			this.setState({ 
                message,
                isError: true,
                isLoading: false
            })
		})
    }

    onClicRemoveSerialNo = index => event => {
        event.preventDefault();
        const serialNos = this.state.serial_numbers.filter((e, i) => i !== index);
        this.setState({
            serial_numbers: [...serialNos]
        });
    }

    handleKeyPress = e =>  {
        if(e.key === 'Enter'){
            let registerForm = e.target.form;
            let index = Array.prototype.indexOf.call(registerForm, e.target);
            registerForm.elements[index + 1].focus();
            e.preventDefault();
        }
    }

    onDismiss = (e) => {
        e.preventDefault();
        this.setState({ 
            contact_email: '',
            contact_name: '',
            company_details: '',
            contact_phone: '',
            serial_numbers: [],
            serial_number: '',
            isLoading: false,
            message: '',
            vendor_name: '',
            isError: false
        });
    }

    showMessage = () => {
        return (
            <MessageModal onClickClose={this.onDismiss}>
                <div className="text-center" style={{ color: this.state.isError ? 'red' : '#485890'}}>
                    <div>
                        {
                            !this.state.isError ? 
                            <i className="fas fa-check-circle fa-3x" aria-hidden="true"></i> :
                            <i className="fas fa-exclamation-triangle fa-3x" aria-hidden="true"></i>
                        }
                    </div>
                    <Trans id={this.state.message} />
                </div>
            </MessageModal>
        )
    }

    onClickClose = (e) => {
		e.preventDefault();
		this.props.closeAdminPopup();
	}

    componentDidUpdate = (prevProps, prevState) =>{
        if (prevState.language !== this.state.language) {
            this.vaidationRules(this.state.language);
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.language!==prevState.language){
           
          return {language : nextProps.language};
        }
        else return null;
    }
    
    vaidationRules = (language) => {
        SimpleReactValidator.addLocale(language, require(`../../locales/${language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: language,
			element: message => <div style={{ marginTop: 0 }} className="register-trial-validation-msg">{message.replace(/field|The/, '').capitalize()}</div>,
			validators: {
                emailValidate: {  // name the rule
					message: 'Please enter a valid email address',
					rule: (val, params, validator) => {
						const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return validator.helpers.testRegex(
							String(val).toLowerCase(),
							regex
						);
					},
					required: true  // optional
                },
                phone: {
                    message: 'Enter a valid phone number',
                    rule: (val,params,validator) => {
                        let telRegex= /^\d+$/;
                        return (val.length >= 10 && val.length <=13 && validator.helpers.testRegex(
                            val,telRegex)
                        );
                    }
                }
			}
		});
    }
    render = () => {
        return (
            <Row>
                <Col xs="12" sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
                    {
                        this.state.message ?
                        this.showMessage() :
                        <Modal isOpen={true} className="modal-trial-register">
                            <ModalHeader className="text-center" toggle={this.onClickClose}><Trans>Register Trial</Trans></ModalHeader>
                            <ModalBody>
                                <Fragment>
                                    <Form>
                                        <div key="contact_name" className="input-wrapper margin-bottom-10">
                                            <I18n>
                                            { ({ i18n }) =>
                                            <>
                                                <input
                                                    className='signup-input-field'
                                                    id="contact_name"
                                                    name={i18n._(t`contact_name`)}
                                                    type="text"
                                                    value={this.state.contact_name}
                                                    onChange={this.onChangeForm('contact_name')}
                                                    onKeyPress={this.handleKeyPress}
                                                    placeholder={i18n._(t`Contact Name`) + '*'} />
                                                {this.validator.message(i18n._(t`contact_name`), this.state.contact_name, 'required')}		
                                            </>
                                            }
                                            </I18n>
                                        </div>
                                        <div key="contact_email" className="input-wrapper margin-bottom-10">
                                            <I18n>
                                            { ({ i18n }) =>
                                                <>
                                                    <input
                                                        className='signup-input-field'
                                                        id="contact_email"
                                                        name={i18n._(t`contact_email`)}
                                                        type="text"
                                                        value={this.state.contact_email}
                                                        onChange={this.onChangeForm('contact_email')}
                                                        onKeyPress={this.handleKeyPress}
                                                        placeholder={i18n._(t`Contact Email`) + '*'} /> 
                                                    {this.validator.message(i18n._(t`contact_email`), this.state.contact_email, 'required|emailValidate')}
                                                </>
                                            }
                                            </I18n>
                                        </div>
                                        <div key="contact_phone" className="input-wrapper margin-bottom-10">
                                            <I18n>
                                                { ({ i18n }) =>
                                                <>
                                                    <input
                                                        className='signup-input-field'
                                                        id="contact_phone"
                                                        name={i18n._(t`contact_phone`)}
                                                        type="text"
                                                        value={this.state.contact_phone}
                                                        onChange={this.onChangeForm('contact_phone')}
                                                        onKeyPress={this.handleKeyPress}
                                                        placeholder={i18n._(t`Contact Phone`) + '*'} />
                                                    {this.validator.message(i18n._(t`contact_phone`), this.state.contact_phone, 'required|phone')}
                                                </>	
                                            }
                                            </I18n>
                                        </div>
                                        <div key="vendor_name" className="input-wrapper margin-bottom-10">
                                            <I18n>
                                            { ({ i18n }) =>
                                                <>
                                                    <input className='signup-input-field'
                                                        id="vendor_name"
                                                        name={i18n._(t`vendor_name`)}
                                                        type="text"
                                                        value={this.state.vendor_name}
                                                        onChange={this.onChangeForm('vendor_name')}
                                                        onKeyPress={this.handleKeyPress}
                                                        placeholder={i18n._(t`Vendor Name`) + '*'} />
                                                    {this.validator.message(i18n._(t`vendor_name`), this.state.vendor_name, 'required')}		
                                                </>
                                            }
                                            </I18n>
                                        </div>
                                        <div key="company_details" className="input-wrapper margin-bottom-10">
                                            <I18n>
                                                { ({ i18n }) =>
                                                <textarea 
                                                    style={{'height':'60px', resize: 'none'}}
                                                    className='signup-input-field'
                                                    id="company_details"
                                                    name="company_details"
                                                    placeholder={i18n._(t`Company Details`)}
                                                    type="text" value={this.state.company_name}
                                                    onKeyPress={this.handleKeyPress}
                                                    onChange={this.onChangeForm('company_details')}>
                                                </textarea>
                                                }
                                            </I18n>
                                        </div>
                                        <div className="input-wrapper margin-bottom-10 d-flex align-items-center">
                                            <I18n>
                                                { ({ i18n }) =>
                                                <input
                                                    style={{ marginRight: 5 }}
                                                    className='signup-input-field'
                                                    id="serial_number"
                                                    name="serial_number"
                                                    type="text"
                                                    value={this.state.serial_number}
                                                    onChange={this.onChangeForm('serial_number')}
                                                    onKeyPress={this.handleKeyPress}
                                                    placeholder={i18n._(t`Enter Serial Number`)} />
                                            }</I18n>
                                                <button disabled={!this.state.serial_number} className="btn-plus hvr-grow" onClick={(e) => this.addSerialNo(e)}>
                                                    <i className="fas fa-plus" aria-hidden="true"></i>
                                                </button>
                                        </div>
                                        <div key="serial_number" className="display-serial-no-container">
                                            {   this.state.serial_numbers.map((serialNo, index) => {
                                                    return <Badge key={index} color="dark" className="serial-badge tm-regular" pill>
                                                        {serialNo} 
                                                        <i onClick={this.onClicRemoveSerialNo(index)} className="fas fa-times-circle pointer close-icon hvr-grow" aria-hidden="true"></i>
                                                    </Badge>
                                                })
                                            }
                                        </div>
                                    </Form>
                                </Fragment>
                            </ModalBody>
                            <ModalFooter>
                                <div className="d-flex justify-content-center btn-trial-register">
                                    <Button 
                                        showSpinner={this.state.isLoading} 
                                        onClick={this.handleSubmit}>
                                        <Trans>Purchase Now</Trans>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </Modal>
                    }
                </Col>
            </Row>
        )
    }
}