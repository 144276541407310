/**TP-6222
 * Created by Prerana Ray
 * for the new Chat feature 
 * one-to-one between two users
 */


import React from 'react';
import './Groups.scss';
import _ from 'lodash';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import NewChat from '../NewChat/NewChat';
import ChatSidePanel from '../NewChat/ChatSidePanel';
import { printConsole, encryptData, getTimeStamp } from '../helpers';
let participantList;

class ChatUser extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			chatMsgInfoArr: [{ msgColor: '', messageID: '',
                firstName: '', lastName: '', message: '', timeStamp: '', msgType: 0 // 0 - indiv, 1 - group
            }]
		}
        this.message = "chat";
    }

    componentDidMount() {
        /* this.testTimer = setTimeout(() => {
            this.subscribeToChatSignal({
                type: "Chat:Message", messageID: 656382, 
                data: "hello from the other side", 
                to: "prerana@telepresenz.com", 
                from: {
                    firstName: "PR",
                    lastName: "Tech",
                    email: "prerana.tech.care4d@gmail.com"
                } 
            })
            clearTimeout(this.testTimer);
        }, 3000); */    
        window.addEventListener("resize", this.redrawChatWindow);   
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.redrawChatWindow);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.userdata.length !== prevProps.userdata.length) {
            participantList = [];
            this.props.userdata.forEach(user => {
                if (this.props.onlineUsers.includes(user.email) && this.props.adminemailid !== user.email)
                    participantList.push(user);
            })
        } else if (this.props.onlineUsers !== prevProps.onlineUsers) {
            participantList = [];
            this.props.userdata.forEach(user => {
                if (this.props.onlineUsers.includes(user.email) && this.props.adminemailid !== user.email)
                    participantList.push(user);
            })
        }
    }

    redrawChatWindow = () => {
        participantList = [];
            this.props.userdata.forEach(user => {
                if (this.props.onlineUsers.includes(user.email) && this.props.adminemailid !== user.email)
                    participantList.push(user);
            })
    }

    updateChatFlags = (val) => {
        this.setState({ 
            bMaxChat: val, 
            bAllChat: val, 
            startChatTimer: val, 
            chatUserEmails: [],
            chatUserNames: [] 
        });
    }

    sendSignalChat = (messageID, message, action, to) => {
        const username = this.props.adminname.split(" ");
        const from = {
           email : this.props.adminemailid,
           firstName : username[0],
           lastName : username[username.length - 1]
        };
        const json = (to !== undefined) ? {
            from,
            to,
            messageID, // MB2-95
            message,
            isGroupChat: false,
            action,
            //type:"Chat:Message"
        } : {
            from,
            messageID, // MB2-95
            message,
            isGroupChat: false,
            action,
            //type:"Chat:Message"
        }
        console.log("socket payload is: ",json);
        this.props.authService.socket.emit(this.message, json);
    }

    
    sendChatMsg = (msgToSend, elemId) => {
        const {adminemailid, adminname, recipientUser} = this.props;
        let { myPicture } = this.props;
        const username = adminname.split(" ");
        let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
        //TP-3303 -- Handling of profile picture when no profile picture in Chat window
        if (myPicture && myPicture.includes("base64") === false && myPicture.includes("profile.png") === false)
            myPicture = 'data:image/jpg;base64,'+ myPicture;
        else if (myPicture && (myPicture.includes("profile.png") === true || myPicture === ""))
            myPicture = null;
        let timeStamp = getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: username[0], messageID: sixdigitsrandom,
            lastName: username[username.length - 1], message: msgToSend, timeStamp: timeStamp, msgType: 1,
            myPicture: myPicture
        };
        this.props.updateChatArr('sendChat', chatMsgInfo);
        //this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}))
        const message = {
            font_color: "#ffb3b3",
            font_size: "14px",
            font_type: "900",
            font_underline: false,
            font_overline: false,
            font_line_through: true,
            font_family: "Titillium Web",
            msg_data: msgToSend,
        }
        const action = {send_msg: true,
            delete_msg: false, edit_msg: false, 
            vanish_msg: false}
        const toObject = {
            email: recipientUser.email,
            firstName: recipientUser.first_name,
            lastName: recipientUser.last_name
        };
        this.sendSignalChat(sixdigitsrandom, message, action, toObject);
        //this.sendSignalChat(sixdigitsrandom, message, action)
    }

    //TP-6222
    onClickClose = (e) => {
        e.preventDefault();
		this.props.onClose();
    }

    replyChatMsg = (new_msg, elemId, replymessageID) => {
        console.log("Reply to chat msg: ", replymessageID, elemId, new_msg)
        const {adminemailid, adminname, recipientUser} = this.props;
        let { myPicture } = this.props;
        const username = adminname.split(" ");
        let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
        //TP-3303 -- Handling of profile picture when no profile picture in Chat window
        if (myPicture && myPicture.includes("base64") === false && myPicture.includes("profile.png") === false)
            myPicture = 'data:image/jpg;base64,'+ myPicture;
        else if (myPicture && (myPicture.includes("profile.png") === true || myPicture === ""))
            myPicture = null;
        let timeStamp = getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: username[0], messageID: sixdigitsrandom,
            lastName: username[username.length - 1], message: new_msg, timeStamp: timeStamp, msgType: 1,
            myPicture: myPicture
        };
        this.props.updateChatArr('replyChat', chatMsgInfo, replymessageID);
        const message = {
            font_color: "#ffb3b3",
            font_size: "14px",
            font_type: "900",
            font_underline: false,
            font_overline: false,
            font_line_through: true,
            font_family: "Titillium Web",
            msg_data: new_msg,
            replymessageID
        }
        const action = {send_msg: false,
            delete_msg: false, edit_msg: false, 
            reply_msg: true, vanish_msg: false}
        const toObject = {
            email: recipientUser.email,
            firstName: recipientUser.first_name,
            lastName: recipientUser.last_name
        };
        this.sendSignalChat(sixdigitsrandom, message, action, toObject);
    }

    editChatMsg = (new_msg, elemId, messageID) => {
        console.log("Edit chat msg: ", messageID, elemId, new_msg)
        const {adminemailid, adminname, recipientUser} = this.props;
        let { myPicture } = this.props;
        const username = adminname.split(" ");
        //TP-3303 -- Handling of profile picture when no profile picture in Chat window
        if (myPicture && myPicture.includes("base64") === false && myPicture.includes("profile.png") === false)
            myPicture = 'data:image/jpg;base64,'+ myPicture;
        else if (myPicture && (myPicture.includes("profile.png") === true || myPicture === ""))
            myPicture = null;
        let timeStamp = getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: username[0], messageID,
            lastName: username[username.length - 1], message: new_msg, timeStamp: timeStamp, msgType: 1,
            myPicture: myPicture
        };
        this.props.updateChatArr('editChat', chatMsgInfo, messageID);
        const message = {
            font_color: "#ffb3b3",
            font_size: "14px",
            font_type: "900",
            font_underline: false,
            font_overline: false,
            font_line_through: true,
            font_family: "Titillium Web",
            msg_data: new_msg,
        }
        const action = {send_msg: false,
            delete_msg: false, edit_msg: true, 
            vanish_msg: false}
        const toObject = {
            email: recipientUser.email,
            firstName: recipientUser.first_name,
            lastName: recipientUser.last_name
        };
        this.sendSignalChat(messageID, message, action, toObject);
    }

    deleteChatMsg = (messageID, elemId) => {
        console.log("Delete chat msg: ", messageID);
        const {recipientUser} = this.props;
        const action = {send_msg: false,
            delete_msg: true, edit_msg: false, 
            vanish_msg: false}
        this.props.updateChatArr('deleteChat', {}, messageID);
        const toObject = {
            email: recipientUser.email,
            firstName: recipientUser.first_name,
            lastName: recipientUser.last_name
        };
        this.sendSignalChat(messageID, "", action, toObject);        
    }

    render() {
        const {recipientUser} = this.props;
        //TP-6222
        const chatHeight = Math.floor((window.innerHeight - (98+42+2)) * 86/100);
        const marginTopButton = chatHeight;
        let participantList = [];
        this.props.userdata.forEach(user => {
            if (this.props.onlineUsers.includes(user.email) && this.props.adminemailid !== user.email)
                participantList.push(user);
        })
        return (
            <div className=" flex-center">
                <div className='chat-parent-container'>
                    <ChatSidePanel 
                        chatHeight={chatHeight}
                        updateUser={this.props.updateChatUser}
                        selectedUser={recipientUser}
                        participantList={participantList}
                    />                    
                    <NewChat 
                        {...this.props}
                        onSendChatMsg={this.sendChatMsg}
                        bPrivate={true}
                        bShowChat={true}
                        elemId={recipientUser.email}
                        recipientname={recipientUser.first_name+" "+recipientUser.last_name}
                        recipientImage={recipientUser.myPicture}
                        startTimer={false}
                        updateChatFlags={this.updateChatFlags}
                        chatHeight={chatHeight}
                        isGroupChat={false}
                        editChatMsg={this.editChatMsg}
                        deleteChatMsg={this.deleteChatMsg}
                        replyChatMsg={this.replyChatMsg}
                        isSessionChat={false}
                    />

                    <div className="" style={{marginTop: marginTopButton+'px'}}>
                        <button className="btn-red"  onClick={(e) =>this.onClickClose(e)}><Trans>Close</Trans></button>                                
                    </div>
                </div>
            </div>
        )

    }
}

export default ChatUser;