import React from 'react';
import { fabric } from 'fabric';
import { base64StringToBlob } from 'blob-util';
import PropTypes from 'prop-types';
import { printConsole } from '../helpers';

let g_bCleanSlate = false;
let strCanvasJSON = null;
let pointerX = "-1"; //TP-2474
let pointerY = "-1"; //TP-2474

function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

//TP-5151 & TP-4786
function excapeChar(str, type) {
    let newStr;
    switch (type) {
        case "backward-slash":
            newStr = str.replaceAll(String.fromCharCode(92),String.fromCharCode(92,92))
            break;
        case "single-quotes":
            newStr = str.replace(/'/g,'\'')    
            break;
        case "double-quotes":
            newStr = str.replace(/"/g,'\\"')    
            break;
        case "newline":
            newStr = str.replace(/\n/g,'\\n')    
            break;    
        default:
            newStr = str.replace(/\n/g,'\\n')
            break;
    }
    return newStr;
}

//TP-5151 & TP-4786
function unescapeChar(str, type) {
    let newStr;
    switch (type) {
        case "backward-slash":
            newStr = str.replaceAll(String.fromCharCode(92,92),String.fromCharCode(92))    
            break;
        case "single-quotes":
            newStr = str.replace(/\'/g,"'")    
            break;
        case "double-quotes":
            newStr = str.replace(/\\"/g,'"')    
            break;
        case "newline":
            newStr = str.replace(/\\n/g, '\n');
            break;    
        default:
            newStr = str.replace(/\\n/g, '\n');
            break;
    }
    return newStr;
}
//TP-2830
const clearAnnotateObj = "{\"version\":\"3.6.2\",\"objects\":[{\"originX\":\"left\",\"globalCompositeOperation\":\"source-over\",\"width\":0.01,\"type\":\"path\",\"strokeLineJoin\":\"round\",\"version\":\"3.6.2\",\"backgroundColor\":\"\",\"stroke\":\"grey\",\"transformMatrix\":null,\"scaleY\":1,\"height\":0.01,\"top\":471.49700000000001,\"originY\":\"top\",\"path\":[[\"M\",420.99700000000001,472.99700000000001],[\"L\",421.00299999999999,473.00299999999999]],\"left\":419.49700000000001,\"fillRule\":\"nonzero\",\"fill\":null,\"strokeWidth\":1,\"skewY\":0,\"strokeDashArray\":null,\"strokeLineCap\":\"round\",\"scaleX\":1,\"strokeMiterLimit\":10,\"angle\":0,\"visible\":true,\"flipX\":false,\"flipY\":false,\"opacity\":1,\"shadow\":null,\"clipTo\":null,\"paintFirst\":\"fill\",\"skewX\":0}]}";
//const clearAnnotateObj = "{\"version\":\"2.3.6\",\"objects\":[{\"originX\":\"left\",\"globalCompositeOperation\":\"source-over\",\"width\":0.01,\"type\":\"path\",\"strokeLineJoin\":\"round\",\"version\":\"2.3.6\",\"backgroundColor\":\"\",\"stroke\":\"grey\",\"transformMatrix\":null,\"scaleY\":1,\"height\":0.01,\"top\":471.49700000000001,\"originY\":\"top\",\"path\":[[\"M\",420.99700000000001,472.99700000000001],[\"L\",421.00299999999999,473.00299999999999]],\"left\":419.49700000000001,\"fillRule\":\"nonzero\",\"fill\":null,\"strokeWidth\":1,\"skewY\":0,\"strokeDashArray\":null,\"strokeLineCap\":\"round\",\"scaleX\":1,\"strokeMiterLimit\":10,\"angle\":0,\"visible\":true,\"flipX\":false,\"flipY\":false,\"opacity\":1,\"shadow\":null,\"clipTo\":null,\"paintFirst\":\"fill\",\"skewX\":0}]}";
let annotateObj = null; //TP-2720
class Annotation extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
            annotation: {},
            bAnnotate: false,
            bRemoteCapture: false,
            expertDimX: 0,
            expertDimY: 0,
            rcImgObj: '',
            shapesSetting: '',
            bSelectShape:false,
            pencolor: "red",
            isStateChange: false //TP-6459
        }
        this.canvas = null;
        this.aTimer = null; //TP-2474
        annotateObj = this; //TP-2720
    }

    componentDidMount = () => {
        const { g_maxDivId, bRemoteCapture, urlObj, bAnnotate} = this.props;
        if (bAnnotate && this.props.hasMaxDivControls === true){
            //console.log("Mounting Annotate class all over again");
            this.onClickDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj);
            //this.onClickPointerTrack(document.getElementById(g_maxDivId)); //TP-2474
        }
    }

    static getDerivedStateFromProps = (props, state) => {
        let updatedStateVar = {};

        if (props.annotation !== state.annotation) {
            updatedStateVar.annotation= props.annotation
        }
        if (props.bAnnotate !== state.bAnnotate) {
            updatedStateVar.bAnnotate= props.bAnnotate
        }
        if (props.bRemoteCapture !== state.bRemoteCapture) {
            updatedStateVar.bRemoteCapture= props.bRemoteCapture
        }

        if (props.pencolor !== state.pencolor) {
            updatedStateVar.pencolor= props.pencolor
        }

        if (props.shapesSetting !== state.shapesSetting) {
            updatedStateVar.shapesSetting= props.shapesSetting
        }    
        
        if (props.bSelectShape !== state.bSelectShape) {
            updatedStateVar.bSelectShape= props.bSelectShape
        }
        
        return Object.keys(updatedStateVar).length ? updatedStateVar : null;
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        let { user, g_maxDivId, urlObj, rcStart, rcImgObj, hasMaxDivControls, format, isAnnotateOnWtRC, isAnnotateOnWtExpertSS, rcAnnotateStart, bShareAsset, onResizeWPointer /**TP-2560*/, stopPointerWhenControlTransfer, /**TP-2824 */ reRenderCanvas, /**TP-4585*/ redrawPassiveCanvas, /**TP-4772*/ redrawTechCanvas, /**TP-4769*/ onResizeWText, /**TP-5073*/ redrawPassiveWhenResized /**TP-4930*/} = this.props;
        const { annotation, bAnnotate, bRemoteCapture, bSelectShape, shapesSetting, pencolor } = this.state;
        if (annotation !== undefined && annotation !== prevState.annotation) {
            // When the annotation state is changed,
            // This gets called when annotation starts or stops
            // Mostly for the Technician side handling.
            const { data, recipient } = annotation;
            //console.log(data);
            if ((data.dataObj == null && data.dataPointer == "" /**TP-2523 */) || (data.dataString == "" && data.dataPointer == "" /**TP-2523 */) || data.dataString === clearAnnotateObj /*TP-2474*/|| data.dataObj === clearAnnotateObj) {
                // If the annotation is stopped/cleared 
                if(user.isexpert) {
                    // Only this code is for Expert side
                    strCanvasJSON = "";                    
                    if (data.dataString === clearAnnotateObj || data.dataObj === clearAnnotateObj) {
                        //TP-2474 -- to take care of the Erase annotation feature on Passive expert end   
                        let PublisherElemList = document.getElementById(g_maxDivId);
                        /**TP-2797 */
                        if (rcImgObj === '') {
                            this.displayAnnotation(PublisherElemList, rcImgObj);
                        } else {
                            this.displayRCAnnotate(PublisherElemList, rcImgObj, format);
                        }
                    } else {
                        //TP-2474 -- To take care of use case when Annotation is stopped
                        this.clearAnnotations();
                    }
                }else{
                    strCanvasJSON = "";
                    if (data.dataString === clearAnnotateObj || data.dataObj === clearAnnotateObj) {
                        //TP-2474 -- to take care of the Erase annotation feature on Technician end 
                        let PublisherElemList = document.getElementById(g_maxDivId);
                        /**TP-2797 */
                        if (rcImgObj === '') {
                            this.displayAnnotation(PublisherElemList, rcImgObj);
                        } else {
                            this.displayRCAnnotate(PublisherElemList, rcImgObj, format);
                        }
                    } else {
                        let PublisherElemList = document.getElementById(g_maxDivId);
                        //this.displayAnnotation(PublisherElemList, rcImgObj);
                        /**TP-4555*/
                        if (rcImgObj === '') {
                            this.displayAnnotation(PublisherElemList, rcImgObj);
                        } else {
                            this.displayRCAnnotate(PublisherElemList, rcImgObj, format);
                        }
                    }
                }
            } else {
                // If the annotation is opened
                //console.log("Process the data string...")
                if (annotation.data.dataObj !== prevState.annotation.data.dataObj && annotation.data.dataObj !== null)
                    this.processString(data.dataString, recipient);
                /*TP-2474*/else if (annotation.data.dataPointer !== prevState.annotation.data.dataPointer && annotation.data.dataPointer !== '')
                    this.processPointerString(data.dataPointer, recipient);
            }            
        }
        if (user.isexpert && isAnnotateOnWtRC !== prevProps.isAnnotateOnWtRC && isAnnotateOnWtRC === true) {
            //TP-2474
            clearInterval(this.aTimer);
            this.aTimer = null;
            this.clearOldAnnotationObject();
            this.canvas = null;
        } else if (user.isexpert && isAnnotateOnWtExpertSS !== prevProps.isAnnotateOnWtExpertSS && isAnnotateOnWtExpertSS === true) {
            this.sendSignaltoClearAnnotation(); //TP-1365
        }
        // When the bAnnotate state is changed,
        // This gets called when annotation starts or stops
        // Only for the Expert side handling.
        // We are passing the bRemoteCapture state..
        // ..which says if RC is on or not
        // "urlObj" is the RC image that has to be set on the canvas
        if (user.isexpert && bAnnotate !== prevState.bAnnotate && this.props.hasMaxDivControls === true) {
            printConsole("Started Annotation/RC");
            this.onClickDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, true, format);           
        } else if (user.isexpert && reRenderCanvas !== prevProps.reRenderCanvas && reRenderCanvas === true && this.props.hasMaxDivControls === true) {
            //TP-4585
            printConsole("Re-start Annotation right after fileshare is stopped")
            this.onClickDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, true, format);
        }
        if (user.isexpert && hasMaxDivControls !== prevProps.hasMaxDivControls && hasMaxDivControls === true && bAnnotate === true && bRemoteCapture === true && urlObj) {
            printConsole("RC & Annotation started");
            this.triggerSendAnnotation(); //TP-2474
            //TP-6459
            this.setState({ isStateChange: true }, () => {
                this.onClickDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, true, format, true);
                //TP-5450, TP-5226
                if (this.canvas) {
                    printConsole("Discard all canvas active objects")
                    this.canvas.discardActiveObject();
                    this.canvas.renderAll();
                    this.canvas.forEachObject(function(object){ 
                        object.set({selectable:false});
                    });
                }
            })
        } else if (user.isexpert && hasMaxDivControls !== prevProps.hasMaxDivControls && hasMaxDivControls === true && bAnnotate === true) {
            printConsole("Annotation started!!");
            this.triggerSendAnnotation(); //TP-2474
            this.onClickDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, true, format, true);
            //TP-5450, TP-5226
            if (this.canvas) {
                printConsole("Discard all canvas active objects")
                this.canvas.discardActiveObject();
                this.canvas.renderAll();
                this.canvas.forEachObject(function(object){ 
                    object.set({selectable:false});
                });
            }
        } else if (user.isexpert && hasMaxDivControls !== prevProps.hasMaxDivControls && hasMaxDivControls === true && bRemoteCapture === true && urlObj) {
            printConsole("Only RC started");
            this.triggerSendAnnotation(); //TP-2474
            //TP-6459
            this.setState({ isStateChange: true }, () => {
                this.onClickDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, true, format, true);
                //TP-5450, TP-5226
                if (this.canvas) {
                    printConsole("Discard all canvas active objects")
                    this.canvas.discardActiveObject();
                    this.canvas.renderAll();
                    this.canvas.forEachObject(function(object){ 
                        object.set({selectable:false});
                    });
                }
            });
        }

        //TP-2824
        if (user.isexpert && hasMaxDivControls === true && stopPointerWhenControlTransfer !== prevProps.stopPointerWhenControlTransfer && stopPointerWhenControlTransfer === true) {
            this.sendStopPointerAnnotation();
        }

        //If File share is stopped and Annotations are ongoing re-draw and send the annotations again
        if (user.isexpert && bShareAsset !== prevProps.bShareAsset && bShareAsset === false && hasMaxDivControls === true && bAnnotate === true && bRemoteCapture === false) {
            printConsole("re-Draw annotations all over again reason == 'share assets stopped'");
            //this.onClickDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, true, format);           

        }

        //If Screen share is stopped and Annotations are ongoing re-draw and send the annotations again
        /* if (user.isexpert && bExpertSS !== prevProps.bExpertSS && bExpertSS === false && hasMaxDivControls === true && bAnnotate === true && bRemoteCapture === false) {
            printConsole("re-Draw annotations all over again reason == 'screen share stopped'");
            this.onClickDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, true, format);           
        } */
        
        // If Remote Capture image has been set 
        // OR the Stop/Start Remote capture image socket Signal has been received
        // Only for Technician side/passive expert MB2-556
        printConsole(`rcStart ${rcStart}, prev rcStart ${prevProps.rcStart}`)
        printConsole(`prev rcAnnotateStart ---> ${prevProps.rcAnnotateStart} at ${performance.now()}`); //TP-4275
        if (rcImgObj !== prevProps.rcImgObj || rcStart !== prevProps.rcStart || rcAnnotateStart !== prevProps.rcAnnotateStart){
            printConsole(`Display the RC annotation at ${performance.now()}`); //TP-4275
            printConsole(`rcAnnotateStart   ============> ${rcAnnotateStart}`);
            // if(!user.isexpert) {
                if (rcImgObj === '' || (rcAnnotateStart !== prevProps.rcAnnotateStart && rcAnnotateStart === true)) strCanvasJSON = "";
                let PublisherElemList = document.getElementById(g_maxDivId);
                this.displayRCAnnotate(PublisherElemList, rcImgObj, format);
            // }
        }
        if (user.isexpert && hasMaxDivControls !== prevProps.hasMaxDivControls && hasMaxDivControls === false && bRemoteCapture === true && urlObj) {
            printConsole("New Passive expert RC is on");
            //TP-6459
            this.setState({ isStateChange: true }, () => {
                //TP-4744
                if (this.canvas) {
                    this.canvas.off('mouse:down');
                    this.canvas.off('mouse:move');
                    this.canvas.off('mouse:up');
                    printConsole("Discard all canvas active objects")
                    this.canvas.discardActiveObject(); //TP-5450
                }
                //this.clearPointerAnnotation();
                //strCanvasJSON = "";
                let PublisherElemList = document.getElementById(g_maxDivId);
                this.displayRCAnnotate(PublisherElemList, urlObj, format)
            })
        } else if (user.isexpert && hasMaxDivControls !== prevProps.hasMaxDivControls && hasMaxDivControls === false && bAnnotate === true && bRemoteCapture === false) {
            printConsole("New Passive expert Annotation without RC is on");
            //TP-4744
            if (this.canvas) {
                this.canvas.off('mouse:down');
                this.canvas.off('mouse:move');
                this.canvas.off('mouse:up');
                printConsole("Discard all canvas active objects")
                this.canvas.discardActiveObject(); //TP-5450
            }
            //this.clearPointerAnnotation();
            //strCanvasJSON = "";
            let PublisherElemList = document.getElementById(g_maxDivId);
            this.displayAnnotation(PublisherElemList, urlObj);
        }
        else if (user.isexpert && hasMaxDivControls === false && bAnnotate !== prevState.bAnnotate && bAnnotate == true && bRemoteCapture === false) {
            printConsole("Passive expert to start the Annotation View mode right when the Primary expert activates the annotation");
            strCanvasJSON = "";
            let PublisherElemList = document.getElementById(g_maxDivId);
            this.displayAnnotation(PublisherElemList, urlObj);
        } 
        else if (user.isexpert && hasMaxDivControls === false && redrawPassiveWhenResized !== prevProps.redrawPassiveWhenResized && redrawPassiveWhenResized === true) {
            //TP-4930
            if (bRemoteCapture === true) {
                printConsole("Re-draw the Passive expert's RC canvas after resizing");
                let PublisherElemList = document.getElementById(g_maxDivId);
                this.displayRCAnnotateResizedPassiveExpert(PublisherElemList, urlObj, format)
            } else {
                printConsole("Re-draw the Passive expert's canvas after resizing");
                let PublisherElemList = document.getElementById(g_maxDivId);
                this.displayAnnotation(PublisherElemList, urlObj, format)
            }
        }
        else if (user.isexpert && hasMaxDivControls === false && redrawPassiveCanvas !== prevProps.redrawPassiveCanvas && redrawPassiveCanvas === true) {
            //TP-4772
            if (bRemoteCapture === true) {
                printConsole("Re-draw the Passive expert's RC canvas after ratio changes");
                let PublisherElemList = document.getElementById(g_maxDivId);
                this.displayRCAnnotate(PublisherElemList, urlObj, format)
            } else {
                printConsole("Re-draw the Passive expert's canvas after ratio changes");
                let PublisherElemList = document.getElementById(g_maxDivId);
                this.displayAnnotation(PublisherElemList, urlObj, format)
            }
        }
        else if (!user.isexpert && redrawTechCanvas !== prevProps.redrawTechCanvas && redrawTechCanvas === true) {
            //TP-4769 & TP-4941
            if (rcImgObj === '') {
                printConsole("Re-draw the Technician's canvas after View changes");
                let PublisherElemList = document.getElementById(g_maxDivId);
                this.displayAnnotation(PublisherElemList, urlObj, format)
            } else {
                //TP-4941
                printConsole("Re-draw the Technician's RC canvas after View changes");
                let PublisherElemList = document.getElementById(g_maxDivId);
                this.displayRCAnnotate(PublisherElemList, urlObj, format)
            } 
        }
        
        //TP-2474
        if ((bSelectShape !== prevState.bSelectShape || shapesSetting !== prevState.shapesSetting  || pencolor !== prevState.pencolor) && bAnnotate == true && user.isexpert && onResizeWText === false /**TP-5073*/ && hasMaxDivControls === true && shapesSetting !== ''){
            printConsole("the color/shape is changed");
            //TP-2739
            if (!(pencolor !== prevState.pencolor && shapesSetting.isPointerActive === true) && !(shapesSetting.isUndoActive !== prevState.shapesSetting.isUndoActive) /**TP-4766*/) {
                printConsole("Canvas events are to be removed only if Pointer is not turned on!!");
                this.removeCanvasEvents();
            }
            if (shapesSetting.isErasorActive !== prevState.shapesSetting.isErasorActive && shapesSetting.isErasorActive === true) {
                this.clearAnnotations();
                this.sendAnnotation(clearAnnotateObj);
            }
            if (pencolor !== prevState.pencolor) {
                //TP-4695 & TP-2739
                if (!shapesSetting.isPointerActive && !shapesSetting.isTextActive)
                    this.onClickChangeDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, false, true); //TP-4696
                //TP-2474 -- Added the condition to resolve the multiple instance created for the annotation Timer (Pointer) issue
                if (shapesSetting.isPointerActive !== prevState.shapesSetting.isPointerActive && shapesSetting.isPointerActive === true) 
                    this.onClickPointerTrack(document.getElementById(g_maxDivId));
                else if /**TP-2961 */ (shapesSetting.isPointerActive !== prevState.shapesSetting.isPointerActive && shapesSetting.isPointerActive === false) 
                    this.clearPointerAnnotation();
                else if (shapesSetting.isFreeDrawing || shapesSetting.isArrowActive || shapesSetting.isCircleActive || shapesSetting.isRectActive)
                    this.onClickChangeDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, false, true);  //TP-4696 TP-2586
                //TP-4695
                if (pencolor === "#ff0000" & prevState.pencolor === "red" && shapesSetting.isTextActive) 
                    this.onClickChangeDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, false, true); //TP-4695 TP-4696
            }
            else if (shapesSetting.isPointerActive !== prevState.shapesSetting.isPointerActive /* && prevState.shapesSetting !== '' */) {
                //TP-2474 -- Added the condition to resolve the multiple instance created for the annotation Timer (Pointer) issue
                /* TP-2586 if (prevState.shapesSetting === '')
                    this.onClickChangeDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, false, true);  */ //TP-4696
                if (!(prevState.shapesSetting === '' && shapesSetting.isPointerActive === false)) this.onClickPointerTrack(document.getElementById(g_maxDivId));
                if (shapesSetting.isFreeDrawing || shapesSetting.isArrowActive || shapesSetting.isCircleActive || shapesSetting.isRectActive || shapesSetting.isTextActive /**TP-4695*/)
                    this.onClickChangeDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, false, true); //TP-4696
            } else if (shapesSetting.isUndoActive !== prevState.shapesSetting.isUndoActive && shapesSetting.isUndoActive === true) {
                this.undoLastAnnotation(); //TP-4696
            } else {
                if (shapesSetting.isUndoActive !== prevState.shapesSetting.isUndoActive && shapesSetting.isUndoActive === false)
                    this.onClickChangeDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, true, true); 
                else
                    this.onClickChangeDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, false, true); //TP-4696
            }
        }
        //TP-2560 -- Handling the restarting the Canvas "move" events for Pointer Annotation to continue 
        // Whenever Primary expert's screen is resized
        if (user.isexpert && onResizeWPointer !== prevProps.onResizeWPointer && onResizeWPointer === true ) {
            this.restartPointerCanvasEvents(document.getElementById(g_maxDivId));
        } 
    }

    // Function to create/clear the canvas element
    // Also initialize the mouse:up, mouse:move, mouse:down event listeners for the canvas fabric
    onClickDraw = (paramElem, bCapture, urlObj, chatFlg, format, reDraw=false) => {
        const { g_maxDivId, canvasParent } = this.props;
        if(paramElem === null) {
            printConsole('invalid element passed to onClickDraw');
            return;
        }

        let drawElem = document.getElementById('canvas');
        //printConsole('this.canvas = ', this.canvas);
        let nameDiv = document.getElementsByClassName("text-center nameDiv");
        // && drawElem.fabric !== undefined
        if (this.canvas !== null /* && this.canvas !== undefined */) { //TP-2586
            //TP-4821 -- Hanlded the "Something went wrong" issues during Control transfers
            if (this.canvas !== undefined)
                this.canvas.clear();
            this.canvas = null;
        }
        if( drawElem !== null && bCapture === false && chatFlg === false){
            // This block is called when annotation needs to be cleared
            if(drawElem.style.display === 'block'){
                g_bCleanSlate = true;

                this.canvas = drawElem;
                this.canvas.fabric = drawElem.fabric;
                //TP-1626
                if (this.canvas.getObjects !== undefined) {
                    let canvasObjects = this.canvas.getObjects('path');
                    let idx = 0;
                    for(idx = 0; idx < canvasObjects.length; idx++)
                        this.canvas.remove(canvasObjects[idx]);
                    canvasObjects = this.canvas.getObjects('path');
                }
                this.sendAnnotation('', null);
                //TP-2436 -- resolved the "Something went wrong issue"
                if (this.canvas !== undefined && this.canvas.fabric !== undefined) {
                    this.canvas.fabric.isDrawingMode = false;
                    this.canvas.fabric.backgroundImage = false;
                    this.canvas.style.display = "none";
                }
                const parentel = document.getElementById(canvasParent);
                if (parentel !== null && parentel !== undefined) {
                   let first = parentel.firstElementChild; 
                    while (first) { 
                        first.remove(); 
                        first = parentel.firstElementChild; 
                    } 
                }
                strCanvasJSON = null;
                this.setState({
                    expertDimX: 0,
                    expertDimY: 0,
                });
            }else{
     
                if(bCapture === true){
                  fabric.Image.fromURL(urlObj, function(img) {
                     // add background image
                     printConsole("1Canvas image:", img);
                     printConsole(this.canvas);
                     //TP-2667
                     if (this.canvas !== undefined && this.canvas !== null) {
                        this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
                            scaleX: this.canvas.width / img.width,
                            scaleY: this.canvas.height / img.height
                        });
                     }
                  });
                }

                drawElem.fabric.isDrawingMode = true;
                drawElem.style.display = 'block';
            }
            return;
        }

        // create a canvas element
        let newCanvas = document.createElement('canvas');
        newCanvas.id = 'canvas';
        let context = newCanvas.getContext("2d");
        let paramElemVdoChild = paramElem;
        const rect = paramElemVdoChild.getBoundingClientRect();
        const sideBar = document.getElementById(this.props.sideBar);
        const videoContainer = document.getElementById(this.props.parentId);
        const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')
        //printConsole(rect);
        newCanvas.width = bCapture ? paramElemVdoChild.offsetWidth : Math.ceil(rect.width);
        newCanvas.height = bCapture ? paramElemVdoChild.offsetHeight : Math.ceil(rect.height);
        printConsole(`Canvas resolution width: ${newCanvas.width}px height: ${newCanvas.height}px `);
        newCanvas.style.width = rect.width;
        newCanvas.style.height = rect.height;
        newCanvas.style.borderRadius = "10px";
        newCanvas.style.display= 'block';   
        
        //TP-4118 --Show the normal crosshair pointer during annotation
        newCanvas.classList.add("cursor-cross-hair");
        //add cross-hair cursor for the "upper-canvas" element as well
        if(newCanvas.nextSibling)
            newCanvas.nextSibling.classList.add("cursor-cross-hair");

        // insert canvas inside canvas Parent element
        let parentel = document.getElementById(canvasParent);
        if(parentel === null) {
            printConsole('canvasParent isnt created yet!!');
            return;
        }
        parentel.style.position = 'absolute';
        parentel.style.left = (rect.x - sideBar.offsetWidth - parseInt(videoContainerPadding) ) + 'px'; // document.getElementById('meet').className == "col-sm-12 text-center" ? : '0px'
        parentel.style.top =  '0'+ 'px'; //rect.y
        //parentel.className = paramElemVdoChild.parentNode.className;
        if(parentel.children.length < 1) {
            parentel.appendChild(newCanvas);
        } else {
            parentel.removeChild(parentel.lastChild);
            parentel.appendChild(newCanvas);
            //parentel.insertBefore(newCanvas, parentel.children[0]); //.nextSibling
        }

        //TP-2810
        let shapeselector = document.getElementById('shapeselector');
        if (shapeselector && shapeselector !== null) {
            shapeselector.style.left = (rect.x - sideBar.offsetWidth - parseInt(videoContainerPadding) + 10 ) + 'px';
        }

        // add a fabric canvas element
        this.canvas = new fabric.Canvas(newCanvas);
        let {isFreeDrawing, isPointerActive} = this.state.shapesSetting; //TP-2474
        const {isSmallFormFactorDevice} = this.props;
        if (isPointerActive === false) {
            this.canvas.off("mouse:over");
            this.canvas.off("mouse:out");
            this.stopAnnotationTimer();
            this.canvas.off("mouse:move");
        }
        let drawingObj = document.getElementById('canvas');
        //drawingObj.style.left = '170px';
        drawingObj.fabric = this.canvas;

        if(this.canvas === null){
            printConsole('Fabric canvas could not be created!!!');
            return;
        }
        //TP-6013
        if(this.canvas === undefined) {
            printConsole("the canvas fabric is undefined");
        }

        printConsole("set the canvas drawing mode, brush color, brush width");
        //TP-2586
        if (isFreeDrawing) {
            this.canvas.isDrawingMode= true;
            this.canvas.freeDrawingBrush.color = "red";
            this.canvas.freeDrawingBrush.width = (isSmallFormFactorDevice === true) ? 2 : 4; //TP-2720
        } else {
            this.canvas.isDrawingMode= false;
        }
        //TP-2474 --  Handling of when Passive becomes Primary expert
        printConsole(` expert dimX = ${this.state.expertDimX}, expert dimY = ${this.state.expertDimY}`)
        let dimX = this.state.expertDimX;
        let dimY = this.state.expertDimY;
        if(dimX === 0 && reDraw === true || reDraw === true){ 
            printConsole('dimensions of expert canvas did not come in');
            dimX = this.canvas.getWidth();
            dimY = this.canvas.getHeight();
            //return;
        }
        
        printConsole(`dimX ${dimX}, dimY ${dimY}`);
        // TP-2543 -- When the Object Json is set
        printConsole(strCanvasJSON);
        printConsole(this.state.isPointerActive);
        if(strCanvasJSON !== null && strCanvasJSON !== "" && dimX !== 0 && (this.state.isPointerActive === false || this.state.isPointerActive === undefined)){ //TP-2475
            if (strCanvasJSON.objects) {
                //TP-4788 TP-4786
                for(let i=0; i<strCanvasJSON.objects.length; i++) {                    
                    if(strCanvasJSON.objects[i].type === "i-text") strCanvasJSON.objects[i].text = unescapeChar(strCanvasJSON.objects[i].text, "double-quotes"); //TP-5151
                    if(strCanvasJSON.objects[i].type === "i-text") strCanvasJSON.objects[i].text = unescapeChar(strCanvasJSON.objects[i].text, "newline"); //TP-5151
                    if(strCanvasJSON.objects[i].type === "i-text") strCanvasJSON.objects[i].text = unescapeChar(strCanvasJSON.objects[i].text, "backward-slash"); //TP-5151
                }
                //TP-5119
                //this.releaseCanvas();
                this.canvas.loadFromJSON(strCanvasJSON, () => {
                    let objList = this.canvas.getObjects();
                    let index = 0;
                    //printConsole(`canvas Width: ${this.canvas.getWidth()}, canvas Height: ${this.canvas.getHeight()}`)
                    
                    // Calculate the Ratio of Technician screen/Expert screen
                    let multiX = this.canvas.getWidth()/dimX;
                    let multiY = this.canvas.getHeight()/dimY;
                    //printConsole(`multiX = ${multiX}, multiY = ${multiY}`)
                    for(index = 0; index < objList.length; index++){
                        //printConsole(`scaleX = ${objList[index].scaleX}, scaleY = ${objList[index].scaleY}, left = ${objList[index].left}, top = ${objList[index].top}`)
                        objList[index].scaleX = objList[index].scaleX * multiX;
                        objList[index].scaleY = objList[index].scaleY * multiY;
                        objList[index].left = objList[index].left * multiX; // 
                        objList[index].top = objList[index].top * multiY;
                        objList[index].selectable = false;
                        //printConsole(`scaleX = ${objList[index].scaleX}, scaleY = ${objList[index].scaleY}, left = ${objList[index].left}, top = ${objList[index].top}`)
                        objList[index].setCoords();
                    }
                });
            }
            //TP-4752
            if (strCanvasJSON.objects && strCanvasJSON.objects.length > 0)
                this.props.enableButtons(true);
            else
                this.props.enableButtons(false);
            //this.clearAnnotations();
            //this.canvas.backgroundImage = false;
		    //this.canvas.renderAll();
        } else {
            this.props.enableButtons(false); //TP-4752
        }
        
        // to set the captured image as the canvas when "bCapture" is true     
        if(bCapture === true && urlObj !== null){
            //printConsole(`loadImagebackground hit with: ${urlObj}`);
            if (format === "" || format === undefined) {
                fabric.Image.fromURL(urlObj, (img) => {
                    // add background image
                    //TP-2667
                    if (this.canvas !==undefined && this.canvas !== null) {
                        this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
                        scaleX: this.canvas.width / img.width,
                        scaleY: this.canvas.height / img.height
                        });
                    }
                });
            } else {
                const contentType = 'image/'+format;
                urlObj = urlObj.replace('data:'+contentType+';base64,', '').trim();
                // Primary expert urlObj is processed
                const blob = new base64StringToBlob(urlObj, contentType);// MB2-556
                printConsole(blob);
                let imgObj = URL.createObjectURL(blob);
                printConsole(imgObj);

                    fabric.Image.fromURL(imgObj, (img) => {
                    // add background image
                    //TP-2667
                    if (this.canvas !== undefined && this.canvas !== null) {
                        this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
                            scaleX: this.canvas.width / img.width,
                            scaleY: this.canvas.height / img.height
                        });
                    }
                });          

            }

            if (drawElem != null && drawElem.fabric !== undefined && this.state.bAnnotate == true) {
                drawElem.fabric.isDrawingMode = true;
                drawElem.style.display = 'block';
            } 
        }
        else {
        // else to render the canvas as it is    
            this.canvas.renderAll();
        }

        //TP-2586
        if (isFreeDrawing) {
            // Canvas Mouse down, clear annotation event listener
            this.canvas.on('mouse:down', (option) => {
    
                if (option.target !== null) {
                    return;
                } else {
    
                    // this.clearAnnotations();// multipart annotation
                    this.canvas.on('mouse:move', (option) => {
                        // remove the old freehand drawing objects if they exist
                        if(g_bCleanSlate)
                        {
                            g_bCleanSlate = false;
                        }
                    });
    
                }
            });
    
            // Canvas Mouse up, move, send the String/Object to emitter event listener
            this.canvas.on('mouse:up', () => {
                this.canvas.off('mouse:move');
                const drawElem = document.getElementById('canvas');
                if(drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block'){
                    this.canvas.enableRetinaScaling = true;
                    let obj = this.canvas.getObjects();
                    if (obj.length > 0) this.props.enableButtons(true); /**TP-4752*/
                    //TP-2600 -- Use case 2
                    for(let index = 0; index < obj.length; index++){
                        if (obj[index].type === "path") obj[index].selectable = false;                    
                    }
                    if(g_maxDivId !== null){
                        printConsole("sending the annotations from onClickDraw...");
                        // Setting the expert X & Y (Height & Width) values into the state
                        this.setState({
                            expertDimX: this.canvas.width,
                            expertDimY: this.canvas.height
                        });
                        let strDim = this.canvas.getWidth().toString() + "px"; //document.getElementById(g_maxDivId).offsetWidth.toString() + "px";
                        strDim += this.canvas.getHeight().toString() + "px"; //document.getElementById(g_maxDivId).offsetHeight.toString() + "px";
                        printConsole(`ANNOTATE signal dimensions ${strDim}`);
                        this.sendAnnotation(strDim, paramElem);
                    }
                    
                }
                let json = this.canvas.toJSON();
                if (json.backgroundImage !== undefined) delete json.backgroundImage;
                json.objects = json.objects.filter(oo => !(oo.type === "i-text" && (oo.text === "Type your text" || oo.text.includes("Type your text") || oo.text.includes("Type your") || oo.text.includes("your text") || oo.text.includes("Type")))) //TP-5169 TP-4802
                this.sendAnnotation(json, paramElem);
                //TP-2474
                printConsole(`isPointerActive??? ${isPointerActive}`);
    
                if (isPointerActive) {
                    //To handle when default behaviour is isPointerActive
                } else {
                    pointerX = "-1";
                    pointerY = "-1";
                    const string = this.canvas.width + ":::" + this.canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + this.state.pencolor;
                    //this.sendAnnotation(string, paramElem);
                }
            });

            this.props.updateAnnotateLoader(false); //TP-2853
        }
    }

    FabricCalcArrowAngle = (x1, y1, x2, y2) => {
        var angle = 0, x, y;
        x = (x2 - x1);
        y = (y2 - y1);
        if (x === 0) {
            angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
        } else if (y === 0) {
            angle = (x > 0) ? 0 : Math.PI;
        } else {
            angle = (x < 0) ? Math.atan(y / x) + Math.PI :
                (y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
        }
        return (angle * 180 / Math.PI + 90);
    }

    onClickChangeDraw = (paramElem, bCapture, urlObj, isundo = false, flag = false) => {
        printConsole("Started the change color & shapes");
        const { canvasParent } = this.props; //TP-2586
        if (paramElem === null) {
            printConsole('invalid element passed to onClickChangeDraw');
            return;
        }
        
        let canvas;
        const {g_maxDivId} = this.props;
        let drawElem = document.getElementById('canvas');
        //TP-2586
        if (drawElem === undefined || drawElem === '' || drawElem === null) {
            printConsole("canvas to draw shapes doesn't exists!!!");
            return;
        }
        //TP-2586
        /* if (drawElem === undefined || drawElem === '' || drawElem === null){
            // create a canvas element
            let newCanvas = document.createElement('canvas');
            newCanvas.id = 'canvas';
            let context = newCanvas.getContext("2d");
            let paramElemVdoChild = paramElem;
            const rect = paramElemVdoChild.getBoundingClientRect();
            const sideBar = document.getElementById(this.props.sideBar);
            const videoContainer = document.getElementById(this.props.parentId);
            const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')
            //printConsole(rect);
            newCanvas.width = bCapture ? paramElemVdoChild.offsetWidth : Math.ceil(rect.width);
            newCanvas.height = bCapture ? paramElemVdoChild.offsetHeight : Math.ceil(rect.height);
            printConsole(`Canvas resolution width: ${newCanvas.width}px height: ${newCanvas.height}px `);
            newCanvas.style.width = rect.width;
            newCanvas.style.height = rect.height;
            newCanvas.style.borderRadius = "10px";
            newCanvas.style.display= 'block';        

            // insert canvas inside canvas Parent element
            let parentel = document.getElementById(canvasParent);
            if(parentel === null) {
                printConsole('canvasParent isnt created yet!!');
                return;
            }
            parentel.style.position = 'absolute';
            parentel.style.left = (rect.x - sideBar.offsetWidth - parseInt(videoContainerPadding) ) + 'px'; // document.getElementById('meet').className == "col-sm-12 text-center" ? : '0px'
            parentel.style.top =  '0'+ 'px'; //rect.y
            //parentel.className = paramElemVdoChild.parentNode.className;
            if(parentel.children.length < 1) {
                parentel.appendChild(newCanvas);
            } else {
                parentel.removeChild(parentel.lastChild);
                parentel.appendChild(newCanvas);
                //parentel.insertBefore(newCanvas, parentel.children[0]); //.nextSibling
            }

            // add a fabric canvas element
            this.canvas = new fabric.Canvas(newCanvas);
            if (this.state.shapesSetting.isPointerActive === false) {
                this.canvas.off("mouse:over");
                this.canvas.off("mouse:out");
                this.stopAnnotationTimer();
                this.canvas.off("mouse:move");
            }
            drawElem = document.getElementById('canvas');
            drawElem.fabric = this.canvas;

            if(this.canvas === null || this.canvas === undefined){
                printConsole('Fabric canvas could not be created!!!');
                return;
            }
            canvas = drawElem.fabric; //TP-2586
        } else {
            //TP-2586
            drawElem.fabric = this.canvas;
        } */
        canvas = drawElem.fabric;
        //TP-4821 -- Hanlded the "Something went wrong" issues during Control transfers
        if (canvas === undefined) {
            printConsole("the canvas fabric is undefined");
            return;
        }
        let context = canvas.getContext("2d");
        canvas.selection = false;
        let rect, circle, line, triangle, origX, origY, activeObj, textArr; //TP-4695 TP-2475
        let {isRectActive, isCircleActive, isArrowActive, isFreeDrawing, isPointerActive, isTextActive} =  this.state.shapesSetting; //TP-2475
        const {isSmallFormFactorDevice} = this.props;
        let {pencolor} = this.state;
        const FabricCalcArrowAngle = this.FabricCalcArrowAngle;
        const sendAnnotation = this.sendAnnotation;
        const enableButtons = this.props.enableButtons; /**TP-4752*/
        const that = this;

        //TP-4118 -- Show the normal crosshair pointer during shapes annotation
        if (isPointerActive === false && drawElem !== undefined && drawElem.matches('.cursor-cross-hair') === false) {
            drawElem.classList.remove("cursor-none");
            //remove cursor-none for the "upper-canvas" element as well
            drawElem.nextSibling.classList.remove("cursor-none");
            drawElem.classList.add("cursor-cross-hair");
            //add cross-hair cursor for the "upper-canvas" element as well
            drawElem.nextSibling.classList.add("cursor-cross-hair");
        }

        if (canvas === null) {
            printConsole('Fabric canvas could not be created!!!');
            return;
        }
        canvas.isDrawingMode = 1;
        //TP-2586
        if (isFreeDrawing /* || isPointerActive */) { //TP-2475
            canvas.freeDrawingBrush.color = pencolor;
            (isSmallFormFactorDevice === false) ? canvas.freeDrawingBrush.width = 4 : canvas.freeDrawingBrush.width = 2; //TP-2720 & TP-2475
        } else if (isRectActive || isCircleActive || isArrowActive || isTextActive /**TP-4695*/) {
            printConsole("draw transparent brush for shapes annotations");
            canvas.freeDrawingBrush.color = "transparent";
            canvas.freeDrawingBrush.width = 1;
            //TP-4695
            if(isTextActive)
                canvas.selection = true;
            else {
                //TP-5242
                canvas.selection = false;
                canvas.forEachObject(function(object){ 
                    object.selectable = false; 
                });
            }                
        } else {
            canvas.isDrawingMode = 0;
        }
    
        if (isFreeDrawing || isCircleActive || isArrowActive || isRectActive || isTextActive) {
            if (!isFreeDrawing) {
                //To handle the duplicate annotation objects being created
                this.canvas.off("mouse:down");
                this.canvas.off("mouse:up");
                this.canvas.off("mouse:move");
            }
            printConsole("Discard all canvas active objects")
            this.canvas.discardActiveObject(); //TP-5450
            
            //TP-4695
            if (isTextActive && isundo === false) { //TP-4696
                if (circle !== null && circle !== undefined) {
                    circle = null;
                }
                if (rect !== null && rect !== undefined) {
                    rect = null;
                }
                if (line !== null && line !== undefined) {
                    line = null;
                }
                if (triangle !== null && triangle !== undefined) {
                    triangle = null;
                }
                if (textArr !== null && textArr !== undefined) {
                    textArr = null;
                }
                canvas.discardActiveObject();
                textArr = new fabric.IText("Type your text", {
                    fontFamily: 'Titillium Web',
                    left: 200,
                    top: 200,
                    fontSize: 40,
                    fill: pencolor,
                    stroke: pencolor,
                    selectable: true
                });                
                canvas.add(textArr);
                canvas.setActiveObject(textArr);
                /**TP-4695 */
                textArr.selectAll(); 
                textArr.enterEditing();
                textArr.hiddenTextarea.focus();
                activeObj = textArr;
                //TP-4752
                canvas.on('mouse:down', function(option) {
                    //activeObj = option.target;
                    //TP-4695
                    if (textArr !== null && textArr !== undefined) {
                        //TP-4695 console.log(option.target);
                        //TP-4820 -- To select and edit only one text at a time
                        canvas.setActiveObject(textArr);
                        if (textArr.text) {
                            textArr.selectAll();
                            textArr.enterEditing();
                            textArr.hiddenTextarea.focus();
                        }
                        activeObj = textArr;
                    }
                    //console.log(activeObj);
                    /* const obj = canvas.getActiveObject();
                    if (activeObj.text === "Type your text") {
                        activeObj.set({text : " "});
                    } */
                    canvas.on('text:editing:exited', function (option) {
                        console.log('Text edited!')   
                    });
                    
                    //textArr.set({ text: " "});
                })
                //TP-5083
                canvas.on('text:changed', function (option) {   
                    //TP-4821 -- To handle removing the default text when user starts typing
                    if (textArr.text && (textArr.text.includes("Type your text") || textArr.text.includes("Type your") || textArr.text.includes("your text") || textArr.text.includes("Type"))) {
                        textArr.selectAll();
                        textArr.enterEditing();
                        textArr.hiddenTextarea.focus();
                        return;
                    }
                    //TP-5119
                    textArr.text && console.log(textArr.text.length);
                    if (textArr.text && textArr.text.length <= 100) {
                        //activeObj = option.target;   
                        let drawElem = document.getElementById('canvas');
                        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
                            canvas.enableRetinaScaling = true;
                            let obj = canvas.getObjects();
                            //TP-2600 -- Use case 2
                            for(let index = 0; index < obj.length; index++){
                                if (obj[index].type === "path") obj[index].selectable = false;  
                                //TP-5546
                                if (obj[index].type === "line" && obj[index].id === "pointer_line_h_primary") canvas.remove(obj[index]);
                                if (obj[index].type === "line" && obj[index].id === "pointer_line_y_primary") canvas.remove(obj[index]);
                                if (obj[index].type === "circle" && obj[index].id === "pointer_circle_primary") canvas.remove(obj[index]);                 
                            }
    
                            if (g_maxDivId !== null) {
                                printConsole("sending the annotations from change shape & color");
                                // Setting the expert X & Y (Height & Width) values into the state
                                that.setState({
                                    expertDimX: canvas.width,
                                    expertDimY: canvas.height
                                });
                                let strDim = canvas.getWidth().toString() + "px"; //document.getElementById(g_maxDivId).offsetWidth.toString() + "px";
                                strDim += canvas.getHeight().toString() + "px"; //document.getElementById(g_maxDivId).offsetHeight.toString() + "px";
                                // sendAnnotationToExpert(strDim);//NS2-345
                                sendAnnotation(strDim, paramElem);
                                printConsole(`ANNOTATE signal dimensions ${strDim}`);
                            }
                            // sendAnnotationToExpert(canvas.toJSON()); //NS2-345
                            let json = canvas.toJSON();
                            if (json.backgroundImage !== undefined) delete json.backgroundImage;
                            //TP-5169 & TP-4802
                            /* let rtnFlag = false;
                            let iin;
                            for (iin = 0; iin < json.objects.length; iin++) {
                                if (json.objects[iin].type === "i-text" && (json.objects[iin].text === "Type your text" || json.objects[iin].text.includes("Type your text") || json.objects[iin].text.includes("Type your") || json.objects[iin].text.includes("your text") || json.objects[iin].text.includes("Type"))){
                                    rtnFlag = true; 
                                    break; 
                                }
                            } if (rtnFlag === true){
                                printConsole("Remove default i-text object"); 
                                json.objects.splice(iin, 1)
                            } */
                            json.objects = json.objects.filter(oo => !(oo.type === "i-text" && (oo.text === "Type your text" || oo.text.includes("Type your text") || oo.text.includes("Type your") || oo.text.includes("your text") || oo.text.includes("Type"))))
                            //TP-4786
                            for(let ii = 0; ii <json.objects.length; ii++) {
                                if(json.objects[ii].type === "i-text") json.objects[ii].text = excapeChar(json.objects[ii].text, "backward-slash");
                                if(json.objects[ii].type === "i-text") json.objects[ii].text = excapeChar(json.objects[ii].text, "single-quotes");
                                if(json.objects[ii].type === "i-text") json.objects[ii].text = excapeChar(json.objects[ii].text, "double-quotes");
                                if(json.objects[ii].type === "i-text") json.objects[ii].text = excapeChar(json.objects[ii].text, "newline");
                            }
                            sendAnnotation(json, paramElem);
                        }  
                    } else if (textArr && textArr.text.length > 100) {
                        let result = textArr.text.slice(0, 100);
                        textArr.text = result;
                    }
                }); 

                                                 
            }
            canvas.on('mouse:down', function (option) {
                //printConsole(option);
                // NS2-509
                /* if (option.target !== null) {
                    return;
                } else { */
                let pointer = canvas.getPointer(option.e);
                origX = pointer.x;
                origY = pointer.y;
                if (isRectActive) {
                    if (circle !== null && circle !== undefined) {
                        circle = null;
                    }
                    if (line !== null && line !== undefined) {
                        line = null;
                    }
                    if (triangle !== null && triangle !== undefined) {
                        triangle = null;
                    }
                    if (textArr !== null && textArr !== undefined) {
                        textArr = null;//TP-4695
                    }
        
                    rect = new fabric.Rect({
                        left: origX,
                        top: origY,
                        width: pointer.x - origX,
                        height: pointer.y - origY,
                        fill: '',
                        stroke: pencolor,
                        type: 'rect',
                        strokeWidth: isSmallFormFactorDevice ? 2 : 4, //TP-2720
                    });
                    canvas.add(rect);
                    activeObj = rect;
                }
                else if (isCircleActive) {
                    if (line !== null && line !== undefined) {
                        line = null;
                    }
                    if (triangle !== null && triangle !== undefined) {
                        triangle = null;
                    }
                    if (rect !== null && rect !== undefined) {
                        rect = null;
                    }
                    if (textArr !== null && textArr !== undefined) {
                        textArr = null;//TP-4695
                    }
                    circle = new fabric.Circle({
                        left: origX,
                        top: origY,
                        originX: 'left',
                        originY: 'top',
                        radius: pointer.x - origX,
                        /* rx: pointer.x - origX,
                        ry: pointer.y - origY, */
                        angle: 0,
                        fill: '',
                        stroke: pencolor,
                        strokeWidth: isSmallFormFactorDevice ? 2 : 4, //TP-2720
                        type: 'circle'
                    });
                    canvas.add(circle);
                    activeObj = circle;
                }
                else if (isArrowActive) {
                    if (circle !== null && circle !== undefined) {
                        circle = null;
                    }
                    if (rect !== null && rect !== undefined) {
                        rect = null;
                    }
                    if (textArr !== null && textArr !== undefined) {
                        textArr = null;//TP-4695
                    }
                    let points = [pointer.x, pointer.y, pointer.x, pointer.y];
                    line = new fabric.Line(points, {
                        strokeWidth: isSmallFormFactorDevice ? 1.5 : 3, //TP-2720
                        fill: pencolor,
                        stroke: pencolor,
                        originX: 'center',
                        originY: 'center',
                        id: 'arrow_line',
                        type: 'line'
                    });
                    let centerX = (line.x1 + line.x2) / 2;
                    let centerY = (line.y1 + line.y2) / 2;
                    var deltaX = line.left - centerX;
                    var deltaY = line.top - centerY;
        
                    triangle = new fabric.Triangle({
                        left: line.get('x1') + deltaX,
                        top: line.get('y1') + deltaY,
                        originX: 'center',
                        originY: 'center',
                        selectable: false,
                        pointType: 'arrow_start',
                        angle: -45,
                        width: 15,
                        height: 25,
                        fill: pencolor,
                        id: 'arrow_triangle',
                        type: 'triangle'
                    });
                    canvas.add(line, triangle);
                    activeObj = line;
                } else if (isTextActive) {
                    //TP-4695                    
                    //if (option.target === activeObj) {
                        
                    //}
                }
                /* TP-2474 -- Commented this out for these changes
                 else if (isPointerActive) {
                    // TP-2475 -- Remove the rest of the canvas drawings before pointer is drawn
                    let obj = canvas.getObjects();
                    for(let index = 0; index < obj.length; index++){
                        canvas.remove(obj[index]);
                    }
                    var context=canvas.getContext("2d");
                    context.clearRect(0,0,canvas.width, canvas.height);
        
                    this.pointerTimer = setInterval(()=>{
                        context.font='500 40px FontAwesome';
                        context.fillStyle = pencolor;
                        context.textAlign = 'center';
                        context.fillText('\uf05b',origX,origY+10);
                        clearInterval(this.pointerTimer);
                    },1000)
                    
                    //canvas.add(text).setActiveObject(text);
                    //activeObj = text;
                } */
                canvas.on('mouse:move', function (option) {
        
                    let pointer = canvas.getPointer(option.e);
                    if (isRectActive) {
        
                        if (origX > pointer.x) {
                            rect.set({ left: pointer.x });
                        }
                        if (origY > pointer.y) {
                            rect.set({ top: pointer.y });
                        }
                        rect.set({ width: Math.abs(origX - pointer.x) });
                        rect.set({ height: Math.abs(origY - pointer.y) });
                        canvas.renderAll();
                    } else if (isCircleActive) {
                        if (circle === null) {
                            return;
                        }
                        let rx = Math.abs(origX - pointer.x) / 2;
                        let ry = Math.abs(origY - pointer.y) / 2;
                        if (rx > circle.strokeWidth) {
                            rx -= circle.strokeWidth / 2;
                        }
                        if (ry > circle.strokeWidth) {
                            ry -= circle.strokeWidth / 2;
                        }
                        circle.set({ radius: rx/*  rx: rx, ry: ry */ });
        
                        if (origX > pointer.x) {
                            circle.set({ originX: 'right' });
                        } else {
                            circle.set({ originX: 'left' });
                        }
                        if (origY > pointer.y) {
                            circle.set({ originY: 'bottom' });
                        } else {
                            circle.set({ originY: 'top' });
                        }
                        canvas.renderAll();
                    } else if (isArrowActive) {
                        line.set({
                            x2: pointer.x,
                            y2: pointer.y
                        });
                        triangle.set({
                            'left': pointer.x + deltaX,
                            'top': pointer.y + deltaY,
                            'angle': FabricCalcArrowAngle(line.x1,
                                line.y1,
                                line.x2,
                                line.y2)
                        });
                        canvas.renderAll();
                    } else if (isTextActive) {
                        //TP-4696 TP-4695 
                        if (textArr !== null && textArr !== undefined) {
                            if (origX !== pointer.x) {
                                textArr.set({ left: pointer.x });
                            }
                            if (origY !== pointer.y) {
                                textArr.set({ top: pointer.y });
                            }
                            //TP-4821 -- Make the textbox by default selected when being moved
                            // only if the content is the default one
                            if (textArr.text && textArr.text === "Type your text") {
                                textArr.selectAll();
                                textArr.enterEditing();
                                textArr.hiddenTextarea.focus();
                            }
                            //textArr.set({ width: Math.abs(origX - pointer.x) });
                            //textArr.set({ height: Math.abs(origY - pointer.y) });
                            canvas.renderAll();
                            activeObj = textArr; //TP-4820
                        }                       
                    }
                });
        
                //}
            });
        
            canvas.on('mouse:up', function (option) {
                canvas.off('mouse:move');
                var pointer = canvas.getPointer(option.e); //TP-2474
                let drawElem = document.getElementById('canvas');
                if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
                    canvas.enableRetinaScaling = true;
                    let obj = canvas.getObjects();
                    if (obj.length > 0) enableButtons(true); //TP-4752
                    //TP-2600 -- Use case 2
                    for(let index = 0; index < obj.length; index++){
                        if (obj[index].type === "path") obj[index].selectable = false;   
                        //TP-5546
                        if (obj[index].type === "line" && obj[index].id === "pointer_line_h_primary") canvas.remove(obj[index]);
                        if (obj[index].type === "line" && obj[index].id === "pointer_line_y_primary") canvas.remove(obj[index]);
                        if (obj[index].type === "circle" && obj[index].id === "pointer_circle_primary") canvas.remove(obj[index]);                                  
                    }
                    
                    // NS2-493
                    // if (lengthInUtf8Bytes(JSON.stringify(canvas.toJSON())) > 8192) {
                    //     //printConsole('annotation data size exceeds limit');
                    //     if (g_maxDivId !== null) {
                    //         let strDim = document.getElementById(g_maxDivId).style.width;
                    //         strDim += document.getElementById(g_maxDivId).style.height;
                    //         sendMultipartAnnotationToExpert(strDim);//NS2-493
                    //         //sendAnnotationToExpert(strDim);
                    //         sendAnnotationMultipart(strDim, paramElem);// NS2-455
                    //         //printConsole(strDim);
                    //     }
                    //     let objSize = lengthInUtf8Bytes(JSON.stringify(canvas.toJSON()));
                    //     //sendAnnotationToExpert(canvas.toJSON()); //NS2-345
                    //     processMultiPartAnnotation(JSON.stringify(canvas.toJSON()), objSize, paramElem);// NS2-455
                    // } else {
                        if (g_maxDivId !== null) {
                            printConsole("sending the annotations from change shape & color");
                            // Setting the expert X & Y (Height & Width) values into the state
                            that.setState({
                                expertDimX: canvas.width,
                                expertDimY: canvas.height
                            });
                            let strDim = canvas.getWidth().toString() + "px"; //document.getElementById(g_maxDivId).offsetWidth.toString() + "px";
                            strDim += canvas.getHeight().toString() + "px"; //document.getElementById(g_maxDivId).offsetHeight.toString() + "px";
                            // sendAnnotationToExpert(strDim);//NS2-345
                            sendAnnotation(strDim, paramElem);
                            printConsole(`ANNOTATE signal dimensions ${strDim}`);
                        }
                        // sendAnnotationToExpert(canvas.toJSON()); //NS2-345
                        let json = canvas.toJSON();
                        if (json.backgroundImage !== undefined) delete json.backgroundImage;
                        //TP-5169 TP-4802
                        /* let rtnFlag = false;
                        let iin;
                        for (iin = 0; iin < json.objects.length; iin++) {
                            if (json.objects[iin].type === "i-text" && (json.objects[iin].text === "Type your text" || json.objects[iin].text.includes("Type your text") || json.objects[iin].text.includes("Type your") || json.objects[iin].text.includes("your text") || json.objects[iin].text.includes("Type")) /**TP-5083){
                                rtnFlag = true; 
                                break; 
                            }
                        } if (rtnFlag === true){
                            printConsole("Remove default i-text object"); 
                            json.objects.splice(iin, 1)
                        } */
                        json.objects = json.objects.filter(oo => !(oo.type === "i-text" && (oo.text === "Type your text" || oo.text.includes("Type your text") || oo.text.includes("Type your") || oo.text.includes("your text") || oo.text.includes("Type"))))
                        //TP-4786
                        for(let ii = 0; ii <json.objects.length; ii++) {
                            if(json.objects[ii].type === "i-text") json.objects[ii].text = excapeChar(json.objects[ii].text, "backward-slash");
                            if(json.objects[ii].type === "i-text") json.objects[ii].text = excapeChar(json.objects[ii].text, "single-quotes");
                            if(json.objects[ii].type === "i-text") json.objects[ii].text = excapeChar(json.objects[ii].text, "double-quotes");
                            if(json.objects[ii].type === "i-text") json.objects[ii].text = excapeChar(json.objects[ii].text, "newline");
                        }
                        sendAnnotation(json, paramElem);
                        //TP-2474
                        printConsole(`isPointerActive??? ${isPointerActive}`);
                        if (isPointerActive) {
                            pointerX = Math.round(pointer.x); //TP-2474
                            pointerY = Math.round(pointer.y); //TP-2474
                            //const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + pencolor;
                            //sendAnnotation(string, paramElem);
                        } else {
                            pointerX = "-1";
                            pointerY = "-1";
                            const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + pencolor;
                            //sendAnnotation(string, paramElem);
                        }
                    // }
                }
            });
            this.props.updateAnnotateLoader(false); //TP-2853
        }
    
    }

    //TP-2474 -- Main on click method to start/stop Annotation Pointer feature
    onClickPointerTrack = (paramElem) => {
        printConsole("on Pointer tracking start/stop")
        if (paramElem === null) {
            printConsole('invalid element passed to onClickPointerTrack');
            return;
        }
        
        let drawElem = document.getElementById('canvas');        
        if (drawElem === null) {
            printConsole("Canvas element is null.")
            return;
        }
    
        const {g_maxDivId} = this.props;
        const sendAnnotation = this.sendAnnotation;
        let canvas = drawElem.fabric;
        if (canvas === null) {
            printConsole('Fabric canvas could not be created!!!');
            return;
        }
        //TP-6013
        if (canvas === undefined) {
            printConsole("the canvas fabric is undefined");
            return;
        }
        canvas.selection = false;
        const {isPointerActive} = this.state.shapesSetting;
        let { pencolor } = this.state;
        canvas.isDrawingMode = 1;
        if (isPointerActive) { //TP-2475
            canvas.isDrawingMode = 0; //TP-2586 -- Turn off the Drawing Mode of Primary expert canvas when Pointer is selected
            canvas.discardActiveObject(); //TP-5484 -- Discard all the Active objects when Pointer is turned on
            //canvas.freeDrawingBrush.color = otExpObj.state.pencolor;
            //canvas.freeDrawingBrush.width = 3;
        }
    
        const {isSmallFormFactorDevice} = this.props; //TP-4118
        if (isPointerActive) {
            //TP-4118
            drawElem.classList.remove("cursor-cross-hair");     
            //remove the default crosshair cursor for the "upper-canvas" element as well       
            drawElem.nextSibling.classList.remove("cursor-cross-hair");            
            drawElem.classList.add("cursor-none");
            //add cursor-none for the "upper-canvas" element as well
            drawElem.nextSibling.classList.add("cursor-none");
            this.triggerAnnotationTimer(); //TP-2474
            let lastevent = false;
            let r = 0;
            
            function animate() {
                //let ctx = canvas.getContext("2d");
                //TP-2720 Remove older pointer objects in the canvas
                let obj = canvas.getObjects();
                //TP-4118 & TP-2569 -- Crosshair sign drawing for the Pointer's location insted of the Dot
                for(let index = 0; index < obj.length; index++){
                    if (obj[index].type === "line" && obj[index].id === "pointer_line_h_primary") canvas.remove(obj[index]);
                    if (obj[index].type === "line" && obj[index].id === "pointer_line_y_primary") canvas.remove(obj[index]);
                    if (obj[index].type === "circle" && obj[index].id === "pointer_circle_primary") canvas.remove(obj[index]);
                }
                let lineH, lineV, circleP;
                //ctx.clearRect(0, 0, canvas.width, canvas.height);
                if (lastevent) {
                    //ctx.strokeStyle = otExpObj.state.pencolor;
                    var pointer = canvas.getPointer(lastevent);
                    let xPos = pointer.x;
                    let yPos = pointer.y;

                    let deltaPos = 24; //TP-4118
                    // Initiate a line instance
                    let xPos1 = (xPos - deltaPos);
                    let xPos2 = (xPos + deltaPos);
                    lineH = new fabric.Line([xPos1, yPos-1, xPos2, yPos-1], {
                        strokeWidth: isSmallFormFactorDevice ? 2.5 : 2,
                        stroke: annotateObj.state.pencolor,
                        id: 'pointer_line_h_primary',
                    });
                    let yPos1 = (yPos - deltaPos);
                    let yPos2 = (yPos + deltaPos);
                    lineV = new fabric.Line([xPos-0.75, yPos1, xPos-0.75, yPos2], {
                        strokeWidth: isSmallFormFactorDevice ? 2.5 : 2,
                        stroke: annotateObj.state.pencolor,
                        id: 'pointer_line_y_primary',
                    });
                    circleP = new fabric.Circle({
                        left: xPos,
                        top: yPos,
                        originX: 'center',
                        originY: 'center',
                        radius: 16, //TP-4118
                        fill: '',
                        stroke: annotateObj.state.pencolor, //"red", 
                        strokeWidth: 4, //TP-4118
                        id : 'pointer_circle_primary'
                    });
                    //console.log("xPos=" + xPos + " yPos=" + yPos + " xPos1=" + xPos1 + " xPos2=" + xPos2 + " yPos1=" + yPos1 + " yPos2=" + yPos2);
                    canvas.add(lineH);
                    canvas.add(lineV); 
                    canvas.add(circleP);
                    r += 8;
                    if (r > 255) r = 0;
                    //ctx.beginPath();
                    //ctx.arc(pointer.x, pointer.y, 7, 0, 2 * Math.PI);
                    //ctx.stroke();
    
                    let drawElem = document.getElementById('canvas');
                    if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
                        canvas.enableRetinaScaling = true;
                        
                            if (g_maxDivId !== null) {
                                // Setting the expert X & Y (Height & Width) values into the state
                                /* this.setState({
                                    expertDimX: canvas.width,
                                    expertDimY: canvas.height
                                }); */
                                let strDim = canvas.width+"px";
                                strDim += canvas.height+"px";
                                //console.log(strDim);
                                //console.log(typeof(strDim));
                                //sendAnnotation(strDim, paramElem);
                                //console.log(strDim);
                            }
                            pointerX = Math.round(pointer.x); //TP-2474
                            pointerY = Math.round(pointer.y); //TP-2474
                            /* const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + pencolor;
                            sendAnnotation(string, paramElem); */
    
                            //strCanvasJSON = canvas.toJSON();
                            let json = canvas.toJSON();
                            if (json.backgroundImage !== undefined) delete json.backgroundImage;
                            //sendAnnotation(json, paramElem);
                    
                    }
                    requestAnimationFrame(animate);
                }
            }
            canvas.on("mouse:move", function(option) {
                lastevent = option.e;
            });
            canvas.on("mouse:over", function(option) {
                lastevent = option.e;
                animate();
            });
            canvas.on("mouse:out", function(option) {
                lastevent = false;
            });
        } else {
            printConsole("Else block for POinter");
            canvas.off("mouse:over");
            canvas.off("mouse:out");
            //TP-4118
            if (drawElem !== undefined) {
                drawElem.classList.remove("cursor-none");
                //remove cursor-none for the "upper-canvas" element as well
                drawElem.nextSibling.classList.remove("cursor-none");
                drawElem.classList.add("cursor-cross-hair");
                //add cross-hair cursor for the "upper-canvas" element as well
                drawElem.nextSibling.classList.add("cursor-cross-hair");
            }
            this.stopAnnotationTimer(); //TP-2474

            //TP-4118 & TP-2720 -- Remove older pointer objects in the canvas
            let obj = canvas.getObjects();
            //TP-2569 -- Plus sign drawing for the Pointer's location insted of the Dot
            for(let index = 0; index < obj.length; index++){
                if (obj[index].type === "line" && obj[index].id === "pointer_line_h_primary") canvas.remove(obj[index]);
                if (obj[index].type === "line" && obj[index].id === "pointer_line_y_primary") canvas.remove(obj[index]);
                if (obj[index].type === "circle" && obj[index].id === "pointer_circle_primary") canvas.remove(obj[index]);
            }
    
            let drawElem = document.getElementById('canvas');
            if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
                canvas.enableRetinaScaling = true;
                        
                if (g_maxDivId !== null) {
                    // Setting the expert X & Y (Height & Width) values into the state
                    /* this.setState({
                        expertDimX: canvas.width,
                        expertDimY: canvas.height
                    }); */
                    let strDim = canvas.width+"px";
                    strDim += canvas.height+"px";
                    //console.log(strDim);
                    //console.log(typeof(strDim));
                    //sendAnnotation(strDim, paramElem);
                    //console.log(strDim);
                }
                pointerX = "-1";
                pointerY = "-1";
                const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + pencolor;
                sendAnnotation(string, paramElem);
    
                //strCanvasJSON = canvas.toJSON();
                let json = canvas.toJSON();
                if (json.backgroundImage !== undefined) delete json.backgroundImage;
                //sendAnnotation(json, paramElem);
                canvas.off("mouse:move");
            }    
        }
    }

    //TP-2560 -- Restart of Pointer Canvas events
    restartPointerCanvasEvents = (paramElem) => {
        printConsole("restarting the Pointer Canvas Events again");
        if (paramElem === null) {
            printConsole('invalid element passed to onClickPointerTrack');
            return;
        }
        
        let drawElem = document.getElementById('canvas');
        if (drawElem === null) {
            printConsole("Canvas element is null.")
            return;
        }
    
        const {g_maxDivId, isSmallFormFactorDevice} = this.props;
        let canvas = drawElem.fabric;
        if (canvas === null) {
            printConsole('Fabric canvas could not be created!!!');
            return;
        }
        //TP-6013
        if (canvas === undefined) {
            printConsole("the canvas fabric is undefined");
            return;
        }
        canvas.selection = false;
        canvas.isDrawingMode = 0; //TP-2660
        let lastevent = false;
        let r = 0;
        //TP-4118
        drawElem.classList.remove("cursor-cross-hair");     
        //remove the default crosshair cursor for the "upper-canvas" element as well       
        drawElem.nextSibling.classList.remove("cursor-cross-hair");            
        drawElem.classList.add("cursor-none");
        //add cursor-none for the "upper-canvas" element as well
        drawElem.nextSibling.classList.add("cursor-none");
            
        function animate() {
            //let ctx = canvas.getContext("2d");
            //ctx.clearRect(0, 0, canvas.width, canvas.height);
            let obj = canvas.getObjects();
            //TP-4118 & TP-2569 -- Crosshair sign drawing for the Pointer's location insted of the Dot
            for(let index = 0; index < obj.length; index++){
                if (obj[index].type === "line" && obj[index].id === "pointer_line_h_primary") canvas.remove(obj[index]);
                if (obj[index].type === "line" && obj[index].id === "pointer_line_y_primary") canvas.remove(obj[index]);
                if (obj[index].type === "circle" && obj[index].id === "pointer_circle_primary") canvas.remove(obj[index]);
            }
            let lineH, lineV, circleP;
            if (lastevent) {
                //ctx.strokeStyle = otExpObj.state.pencolor;
                var pointer = canvas.getPointer(lastevent);
                let xPos = pointer.x;
                let yPos = pointer.y;

                let deltaPos = 24; //TP-4118
                // Initiate a line instance
                let xPos1 = (xPos - deltaPos);
                let xPos2 = (xPos + deltaPos);
                lineH = new fabric.Line([xPos1, yPos-1, xPos2, yPos-1], {
                    strokeWidth: isSmallFormFactorDevice ? 2.5 : 2,
                    stroke: annotateObj.state.pencolor,
                    id: 'pointer_line_h_primary',
                });
                let yPos1 = (yPos - deltaPos);
                let yPos2 = (yPos + deltaPos);
                lineV = new fabric.Line([xPos-0.75, yPos1, xPos-0.75, yPos2], {
                    strokeWidth: isSmallFormFactorDevice ? 2.5 : 2,
                    stroke: annotateObj.state.pencolor,
                    id: 'pointer_line_y_primary',
                });
                circleP = new fabric.Circle({
                    left: xPos,
                    top: yPos,
                    originX: 'center',
                    originY: 'center',
                    radius: 16, //TP-4118
                    fill: '',
                    stroke: annotateObj.state.pencolor, //"red", 
                    strokeWidth: 4, //TP-4118
                    id : 'pointer_circle_primary'
                });
                //console.log("xPos=" + xPos + " yPos=" + yPos + " xPos1=" + xPos1 + " xPos2=" + xPos2 + " yPos1=" + yPos1 + " yPos2=" + yPos2);
                canvas.add(lineH);
                canvas.add(lineV); 
                canvas.add(circleP);

                r += 8;
                if (r > 255) r = 0;
                //ctx.beginPath();
                //ctx.arc(pointer.x, pointer.y, 7, 0, 2 * Math.PI);
                //ctx.stroke();
    
                let drawElem = document.getElementById('canvas');
                if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
                    canvas.enableRetinaScaling = true;                        
                    if (g_maxDivId !== null) {
                        let strDim = canvas.width+"px";
                        strDim += canvas.height+"px";                                
                    }
                    pointerX = Math.round(pointer.x); //TP-2474
                    pointerY = Math.round(pointer.y); //TP-2474                           
                    
                }
                requestAnimationFrame(animate);
            }
        }
        canvas.on("mouse:move", function(option) {
            lastevent = option.e;
        });
        canvas.on("mouse:over", function(option) {
            lastevent = option.e;
            animate();
        });
        canvas.on("mouse:out", function(option) {
            lastevent = false;
        });
    }

    // Function to create the Annotation object with recipient email, to send to the emitter function
    sendAnnotation =(annotationObj, elemId) => {        
        strCanvasJSON = (typeof annotationObj !== "object" && annotationObj.includes(":::") !== false) ? strCanvasJSON : annotationObj;
        // const { user, userList, g_maxDivId } = this.props;
        // const { imgUrl } = this.state;
        // const blob = new Blob([JSON.stringify(annotationObj)]);
        
        /* Setting Limit size for Json Data for canvas Object*/
        /*if(blob.size > 8192){
            printConsole('ERROR: annotation data size exceeds limit');
            return;
        }*/

        // send this message out
        // let annotationChanges = {};

        // annotationChanges = {
        //     imgUrl: imgUrl,
        //     data: annotationObj
        // };

        this.props.onSendAnnotation(annotationObj);

    }

    //TP-2824
    sendStopPointerAnnotation = () => {
        printConsole("send stop pointer annotation to all users");
        const {pencolor} = this.state;
        const {g_maxDivId} = this.props;
        const paramElem = document.getElementById(g_maxDivId);
        if (paramElem !== null && paramElem !== undefined) {
            const string = paramElem.offsetWidth + ":::" + paramElem.offsetHeight + ":::-1:::-1:::" + pencolor;
            //console.log(string);
            this.sendAnnotation(string, paramElem);
        }
    }

    //TP-3857 & TP-2474 - Function to trigger sending annotation string/JSON again to all users
    // When Passive becomes Primary expert
    triggerSendAnnotation = () => {
        const {g_maxDivId} = this.props;
        if (this.canvas !== '' && this.canvas !== null) {
            let strDim = this.canvas.width+"px";
            strDim += this.canvas.height+"px";
            printConsole(strDim);
            printConsole(typeof(strDim));
            //this.props.onSendAnnotationOnlyExpert(strDim);
            this.props.onSendAnnotation(strDim);//TP-3857

            let obj = this.canvas.getObjects();
            //TP-2809 -- Remove the POinter object from the canvas Json before sending it to all other experts
            for(let index = 0; index < obj.length; index++){
                if (obj[index].type === "line" && obj[index].id === "pointer_line_h") this.canvas.remove(obj[index]);
                if (obj[index].type === "line" && obj[index].id === "pointer_line_y") this.canvas.remove(obj[index]);
                if (obj[index].type === "circle" && obj[index].id === "pointer_circle") this.canvas.remove(obj[index]); //TP-2828
                if (obj[index].type === "i-text") obj[index].text = excapeChar(obj[index].text, "backward-slash"); //TP-4831 TP-4786  
                if (obj[index].type === "i-text") obj[index].text = excapeChar(obj[index].text, "double-quotes"); //TP-4831 TP-4786
                if (obj[index].type === "i-text") obj[index].text = excapeChar(obj[index].text, "single-quotes"); //TP-4831 TP-4786    
                if (obj[index].type === "i-text") obj[index].text = excapeChar(obj[index].text, "newline"); //TP-4831 TP-4786  
            }
            let json = this.canvas.toJSON(); 
            //TP-2794
            let finalJson = {
                objects: json.objects, //TP-2809
                version: json.version
            }               
            //this.props.onSendAnnotationOnlyExpert(finalJson);
            this.props.onSendAnnotation(finalJson);//TP-3857
        }
    }

    // Function to clear the annotation object on the Expert side.
    clearAnnotations = () => {
        let drawElem = document.getElementById('canvas');
        if( drawElem !== null && drawElem.fabric !== undefined && drawElem.style.display === 'block'){
            g_bCleanSlate = true;

            this.canvas = drawElem.fabric;
            //TP-5226
            this.canvas.discardActiveObject();
            drawElem.style.pointerEvents = "none";
            // let canvasObjects = this.canvas.getObjects('path');
            let canvasObjects = this.canvas.getObjects();
            for(let idx = 0; idx < canvasObjects.length; idx++){
                if (canvasObjects[idx].type === "i-text") canvasObjects[idx].hasControls = false; //TP-5226
                this.canvas.remove(canvasObjects[idx]);
            }
            this.props.enableButtons(false); //TP-4752
            // canvasObjects = this.canvas.getObjects('path');
            return true;
        }
        return false;
    }
    //TP-5119
    releaseCanvas() {
        let drawElem = document.getElementById('canvas');
        if( drawElem !== null && drawElem.fabric !== undefined && drawElem.style.display === 'block'){
            g_bCleanSlate = true;

            this.canvas = drawElem.fabric;
            this.canvas.width = 1;
            this.canvas.height = 1;
            const ctx = this.canvas.getContext('2d');
            ctx && ctx.clearRect(0, 0, 1, 1);
        }            
    }

    //TP-2474 -- Function to clear out the Pointer Annotations when Primary gives up control and becomes Passive expert
    clearPointerAnnotation = () => {
        //if (isPointerActive === false) {
            const {g_maxDivId} = this.props;
            const paramElem = document.getElementById(g_maxDivId);
            if (this.canvas && this.canvas !== null) {
                //TP-4548
                this.canvas.off("mouse:over");
                this.canvas.off("mouse:out");
                this.canvas.off("mouse:move");
                this.stopAnnotationTimer();
                pointerX = "-1";
                pointerY = "-1";
                const string = this.canvas.width + ":::" + this.canvas.height + ":::" + pointerX + ":::" + pointerY + ":::red";
                this.sendAnnotation(string, paramElem);
            }
        //}
    }	

    //TP-4696
    undoLastAnnotation = () => {
        printConsole("Undo the last annotation object from Primary expert's canvas");
        let drawElem = document.getElementById('canvas');
        if( drawElem !== null && drawElem.fabric !== undefined && drawElem.style.display === 'block'){
            this.canvas = drawElem.fabric;
            //TP-4824 -- Store the already loaded RC image
            let storedBackground = null;
            if (this.canvas.backgroundImage !== null) {
                storedBackground = this.canvas.backgroundImage;
            }
            if (this.canvas.getObjects().length > 0) {
                const {g_maxDivId} = this.props;
                let canvasObjects = this.canvas.toJSON();
                let newCanvasObject = [];
                //remove all path type objects which are transparent
                for(let idx = 0; idx < canvasObjects.objects.length; idx++) {
                    if (canvasObjects.objects[idx].stroke !== "transparent"/*  && canvasObjects.objects[idx].type === "path" */) {
                        newCanvasObject.push(canvasObjects.objects[idx]);
                        //this.canvas.remove(canvasObjects[idx]);
                        //this.canvas.renderAll();                    
                    } 
                }
                //console.log(newCanvasObject);
                //TP-4696
                if (newCanvasObject.length > 0) {
                    if (newCanvasObject[newCanvasObject.length-1].type === "triangle"/*  || newCanvasObject[newCanvasObject.length-1].id === "arrow_line" */){
                        newCanvasObject.splice(newCanvasObject.length-1, 1);
                        newCanvasObject.splice(newCanvasObject.length-1, 1);
                    } else {
                        newCanvasObject.splice(newCanvasObject.length-1, 1);
                    }
                }                
                const loadJson = {
                    version: "3.0.0",
                    objects: newCanvasObject
                }
                //TP-4786
                if (newCanvasObject.length > 0) {
                    for(let i=0; i<loadJson.objects.length; i++) {
                        if(loadJson.objects[i].type === "i-text") loadJson.objects[i].text = unescapeChar(loadJson.objects[i].text, "double-quotes");
                        if(loadJson.objects[i].type === "i-text") loadJson.objects[i].text = unescapeChar(loadJson.objects[i].text, "newline");
                        if(loadJson.objects[i].type === "i-text") loadJson.objects[i].text = unescapeChar(loadJson.objects[i].text, "backward-slash");
                    }
                }
                //console.log(loadJson);
                //this.releaseCanvas(); //TP-5119
                this.canvas.loadFromJSON(loadJson, () => {
                    let objList = this.canvas.getObjects();
                    let index = 0;
                    
                    // Set the Ratio of Expert screen
                    let multiX = 1;
                    let multiY = 1;
                    //printConsole(`multiX = ${multiX}, multiY = ${multiY}`)
                    for(index = 0; index < objList.length; index++){
                        //printConsole(`scaleX = ${objList[index].scaleX}, scaleY = ${objList[index].scaleY}, left = ${objList[index].left}, top = ${objList[index].top}`)
                        objList[index].scaleX = objList[index].scaleX * multiX;
                        objList[index].scaleY = objList[index].scaleY * multiY;
                        objList[index].left = objList[index].left * multiX; // 
                        objList[index].top = objList[index].top * multiY;
                        objList[index].selectable = false;
                        //printConsole(`scaleX = ${objList[index].scaleX}, scaleY = ${objList[index].scaleY}, left = ${objList[index].left}, top = ${objList[index].top}`)
                        objList[index].setCoords();
                    }
                });
                let {urlObj, format} = this.props;
                const {bRemoteCapture} = this.state;
                // TP-4770 -- use case 1, to set the captured image as the canvas when "bRemoteCapture" is true     
                if (bRemoteCapture === true && storedBackground !== null) {
                    //TP-4824 -- don't reload the RC image onto expert's screen if image was already loaded
                    // to resolve the flickering screen issue
                    this.canvas.backgroundImage = storedBackground;
                    this.canvas.renderAll();
                }
                else if(bRemoteCapture === true && urlObj !== null){
                    //printConsole(`loadImagebackground hit with: ${urlObj}`);
                    if (format === "" || format === undefined) {
                        fabric.Image.fromURL(urlObj, (img) => {
                            // add background image
                            //TP-2667
                            if (this.canvas !==undefined && this.canvas !== null) {
                                this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
                                scaleX: this.canvas.width / img.width,
                                scaleY: this.canvas.height / img.height
                                });
                            }
                        });
                    } else {
                        const contentType = 'image/'+format;
                        urlObj = urlObj.replace('data:'+contentType+';base64,', '').trim();
                        // Primary expert urlObj is processed
                        const blob = new base64StringToBlob(urlObj, contentType);// MB2-556
                        printConsole(blob);
                        let imgObj = URL.createObjectURL(blob);
                        printConsole(imgObj);

                            fabric.Image.fromURL(imgObj, (img) => {
                            // add background image
                            //TP-2667
                            if (this.canvas !== undefined && this.canvas !== null) {
                                this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
                                    scaleX: this.canvas.width / img.width,
                                    scaleY: this.canvas.height / img.height
                                });
                            }
                        });          

                    } 
                }
                if (g_maxDivId !== null) {
                    const paramElem = document.getElementById(g_maxDivId);
                    let strDim = this.canvas.width+"px";
                    strDim += this.canvas.height+"px";
                    //console.log(strDim);
                    this.sendAnnotation(strDim, paramElem);

                    let json = this.canvas.toJSON();
                    if (json.backgroundImage !== undefined) delete json.backgroundImage;
                    json.objects = json.objects.filter(oo => !(oo.type === "i-text" && (oo.text === "Type your text" || oo.text.includes("Type your text") || oo.text.includes("Type your") || oo.text.includes("your text") || oo.text.includes("Type")))) //TP-5169 TP-4802
                    //TP-4737
                    if (newCanvasObject.length > 0) {
                        this.sendAnnotation(json, paramElem);
                    } else {
                        this.props.enableButtons(false); //TP-4752
                        this.props.sendAnnotationToMaxViewUser(clearAnnotateObj); 
                        this.props.onSendAnnotationOnlyExpert(json); //send the actual JSON to the Passive experts
                    }

                }                
            } else {
                this.props.enableButtons(false); //TP-4752
                this.props.sendAnnotationToMaxViewUser(clearAnnotateObj); //TP-4737 Send Annotate signal only to Max-view technician
            }
        }
    }

    // Method to clear out the old annotations on the Primary expert canvas
    // When RC is triggered without closing Annotate
    clearOldAnnotationObject = () => {
        printConsole("Clear out the old annotation object")
        // This block is called when annotation needs to be cleared
        this.clearAnnotations();
        //this.sendAnnotation({})
        this.props.sendAnnotationExpertSS(clearAnnotateObj); // Send Annotate signal even if ExpertSS is ongoing
        //this.sendAnnotation(clearAnnotateObj, null);
        strCanvasJSON = null;
        this.setState({
            expertDimX: 0,
            expertDimY: 0,
        });
    }

    // TP-1365 -- Method to send the signal for clearing the Annotation drawings
    // on Technicians & Passive Experts
    sendSignaltoClearAnnotation = () => {
        printConsole("Sending signals to clear out the annotations on all remote users");
        this.props.sendAnnotationExpertSS(clearAnnotateObj); // Send Annotate signal even if ExpertSS is ongoing
    }

    removeCanvasEvents = () => {
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
            this.canvas = drawElem.fabric;
            if (this.canvas === undefined) return; //TP-2586
            this.canvas.off('mouse:down');
            this.canvas.off('mouse:move');
            this.canvas.off('mouse:up');// not removing the up event, because after this signal doesn't go to technician
        }
    }

    //TP-2474 -- Annotation timer start/stop methods
    triggerAnnotationTimer = () => {
        this.aTimer = setInterval(() => {
            const {pencolor} = this.state;
            const {g_maxDivId} = this.props;
            if (this.canvas !== null) {
                const paramElem = document.getElementById(g_maxDivId);
                const string = this.canvas.width + ":::" + this.canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + pencolor;
                this.sendAnnotation(string, paramElem);
            } else {
                clearInterval(this.aTimer);
            }
        }, 2000); //2 seconds time interval
    }

    stopAnnotationTimer = () => {
        printConsole("Stop annotation Pointer timer!!!");
        clearInterval(this.aTimer);
    }
    
    componentWillUnmount = () => {
        printConsole("Unmounting Annotate class");
        const { g_maxDivId, user, canvasParent, format } = this.props;        
        this.clearAnnotations();
        //TP-2559
        //if (user.isexpert /* && this.props.hasMaxDivControls === false */) {
            strCanvasJSON = "";
        //}
        if (user.isexpert && this.props.hasMaxDivControls === true){ //TP-1626 
            //TP-2474 & TP-2540
            if (this.canvas) {
                this.canvas.off('mouse:down');
                this.canvas.off('mouse:move');
                this.canvas.off('mouse:up');
            }
            this.onClickDraw(document.getElementById(g_maxDivId), false, '', false, format ); 
            //TP-2474                   
            this.stopAnnotationTimer();
            this.canvas = null;
            /* if (this.canvas !== null) {
                this.canvas.clear();
                this.canvas = null;
            } */
        }else {
            const parentel = document.getElementById(canvasParent);
            if (parentel !== null) {
                let first = parentel.firstElementChild; 
                while (first) { 
                    first.remove(); 
                    first = parentel.firstElementChild; 
                }
            }
        }
    }

    //TP-2523 -- Process the "String" data received by the Technician
    processString = (dimData, recipient) => {
        const { user } = this.props;
        const { data } = this.state.annotation;
        //printConsole(recipient);
        if (user.email === recipient) {
            const expertDims = typeof dimData === "string" && dimData.includes(":::") === false ? dimData.split('px') : "";
            // Setting the expert X & Y (Height & Width) values into the state
            this.setState((prevState) =>({
                expertDimX: (expertDims.length > 2 ? parseInt(expertDims[0]) : prevState.expertDimX),
                expertDimY: (expertDims.length > 2 ? parseInt(expertDims[1]) : prevState.expertDimY)
            }), ()=> {
                // Send the "Object" data for processing
                this.processObj(data.dataObj, recipient);
            });
        }
    }

    // TP-2523 -- Process the "Object" data received by the Technician
    processObj = (dataObj, recipient) => {
        const { user, rcImgObj, g_maxDivId, format } = this.props;       
        const { data } = this.state.annotation; 
        if (user.email === recipient) {
            // If Remote Capture Image is there
            if (rcImgObj) {
                this.setState({
                    rcImgObj : rcImgObj
                }, ()=> {
                        //TP-4786
                        for(let i=0; i<dataObj.objects.length; i++) {
                            if(dataObj.objects[i].type === "i-text") dataObj.objects[i].text = unescapeChar(dataObj.objects[i].text, "double-quotes"); //TP-5151
                            if(dataObj.objects[i].type === "i-text") dataObj.objects[i].text = unescapeChar(dataObj.objects[i].text, "newline"); //TP-5151
                            if(dataObj.objects[i].type === "i-text") dataObj.objects[i].text = unescapeChar(dataObj.objects[i].text, "backward-slash"); //TP-5151
                        }
                        strCanvasJSON = dataObj; // Set the object data into the global variable
                        let PublisherElemList = document.getElementById(g_maxDivId);
                        // Function to create the new canvas, with image and draw the object
                        this.displayRCAnnotate(PublisherElemList, rcImgObj, format);
                        //TP-2600 -- Handled the use case 3, where the data.dataPointer = undefined
                        if (data.dataPointer && data.dataPointer !== '')
                            this.processPointerString(data.dataPointer, recipient);
                }) 
            } else {
                // If Remote Capture Image is not there (Plain Annotation)
                this.setState({
                    rcImgObj : ''
                }, ()=> {
                        //TP-4786
                        for(let i=0; i<dataObj.objects.length; i++) {
                            if(dataObj.objects[i].type === "i-text") dataObj.objects[i].text = unescapeChar(dataObj.objects[i].text, "double-quotes"); //TP-5151
                            if(dataObj.objects[i].type === "i-text") dataObj.objects[i].text = unescapeChar(dataObj.objects[i].text, "newline"); //TP-5151
                            if(dataObj.objects[i].type === "i-text") dataObj.objects[i].text = unescapeChar(dataObj.objects[i].text, "backward-slash"); //TP-5151
                        }
                        strCanvasJSON = dataObj; // Set the object data into the global variable
                        let PublisherElemList = document.getElementById(g_maxDivId);
                        // Function to create the new canvas, without image and draw the object
                        this.displayAnnotation(PublisherElemList, rcImgObj);
                        //TP-2600 -- Handled the use case 3, where the data.dataPointer = undefined
                        if (data.dataPointer && data.dataPointer !== '')
                            this.processPointerString(data.dataPointer, recipient);
                }) 
            }
        }
    }

    //TP-2523 -- Process the "Pointer" string received by Technician
    processPointerString = (dimData, recipient) => {
        printConsole("Process the Pointer String payload"); //TP-2474
        const { user, rcImgObj, g_maxDivId, format } = this.props;
        if (user.email === recipient) {
            const pointerDims = typeof dimData === "string" && dimData.includes(":::") === true ? dimData.split(":::") : ""; //TP-2523
            if (this.state.pointerDimX === parseInt(pointerDims[2]) && this.state.pointerDimY === parseInt(pointerDims[3]) ) return; //TP-2474
            this.setState({ 
                expertDimX: parseInt(pointerDims[0]),
                expertDimY: parseInt(pointerDims[1]),
                pointerDimX: parseInt(pointerDims[2]),
                pointerDimY: parseInt(pointerDims[3])
            }, () => {
                let PublisherElemList = document.getElementById(g_maxDivId);
                this.displayPointerTrack(PublisherElemList, pointerDims[4], rcImgObj, format); //TP-2474
            });
        }
    }

    // Function to create the new canvas, display the annotation object
    // For Technician side (Normal Annotation) 
    displayAnnotation = (paramElem, imgData) => {
        printConsole("Display only Annotations on Passive expert/Technician");
        const { canvasParent, isSafari, isiOS } = this.props;
        let parentel = document.getElementById(canvasParent);
        if(!parentel){
            return false;//MB2-612, MB2-575
        }
        let drawElem = document.getElementById('canvas');
        if (isSafari === true /* TP-5119 && isiOS === true */ && drawElem && drawElem !== null) { //TP-3029
            printConsole("release canvas during Annotate!!")
            drawElem.height = 1;
            drawElem.width = 1;
            //TP-5119
            const ctx = drawElem.getContext('2d');
            ctx && ctx !== null && ctx.clearRect(0, 0, 1, 1);
            if(parentel.children.length >= 1) {
                for(let ii=0; ii<parentel.children.length; ii++) {
                    parentel.removeChild(parentel.children[ii]);
                }
            }
        }
        const canvasContainer = document.getElementsByClassName('canvas-container');
        printConsole(canvasContainer.length);
        if (canvasContainer.length > 0) {
            //canvasContainer.parentNode.removeChild(canvasContainer);
            canvasContainer[0].remove();
        }
        if(drawElem !== null) {
            drawElem.parentNode.removeChild(drawElem);
        }
        //const {isPointerActive} = this.state.shapesSetting; //TP-2475
        // create a canvas element
        let newCanvas = document.createElement('canvas');
        newCanvas.id = 'canvas';
        let context = newCanvas.getContext("2d");
        if (paramElem === null) return; //TP-5092
        let paramElemVdoChild = paramElem;
        const rect = paramElemVdoChild.getBoundingClientRect();
        const sideBar = document.getElementById(this.props.sideBar);
        const videoContainer = document.getElementById(this.props.parentId);
        const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')

        newCanvas.width = paramElemVdoChild.offsetWidth;
        newCanvas.height = paramElemVdoChild.offsetHeight;
        newCanvas.style.borderRadius = "10px";
        newCanvas.style.display= 'block';
        // insert canvas inside canvas Parent element
        parentel.style.position = 'absolute';
        parentel.style.left = (rect.x- sideBar.offsetWidth - parseInt(videoContainerPadding)) + 'px'; //document.getElementById('meet').className == "col-sm-12 text-center" ? : '0px'
        parentel.style.top =  '0'+ 'px'; //rect.y 
        //parentel.className = paramElemVdoChild.parentNode.className;
        if(parentel.children.length < 1) {
            parentel.appendChild(newCanvas);
        } else {
            parentel.removeChild(parentel.lastChild);
            parentel.appendChild(newCanvas);
            //parentel.insertBefore(newCanvas, parentel.children[0]); //.nextSibling
        }

        // add a fabric canvas element
        this.canvas = new fabric.Canvas(newCanvas);

        // set the Expert X & Y (Width & Height)
        printConsole(` expert dimX = ${this.state.expertDimX}, expert dimY = ${this.state.expertDimY}`)
        let dimX = this.state.expertDimX;
        let dimY = this.state.expertDimY;
        if(dimX === 0){ 
            printConsole('dimensions of expert canvas did not come in');
            dimX = this.canvas.getWidth();
            dimY = this.canvas.getHeight();
            //return;
        }

        printConsole(`dimX = ${dimX}, dimY = ${dimY}`)
        //console.log(strCanvasJSON, typeof(strCanvasJSON));
        // When the Object Json is set
        if(strCanvasJSON !== null && typeof(strCanvasJSON) == "object"){
            //TP-4696
            printConsole("Load the json on the Passive expert")
            // TP-2475
            /*TP-2523 -- if (isPointerActive === true || this.checkForPointerTypeJSON() === true) {
                this.canvas.loadFromJSON(strCanvasJSON, () => {
                    let objList = this.canvas.getObjects();
                    let index = 0;
                    //printConsole(`canvas Width: ${this.canvas.getWidth()}, canvas Height: ${this.canvas.getHeight()}`)
                    
                    // Calculate the Ratio of Technician screen/Expert screen
                    let multiX = this.canvas.getWidth()/dimX;
                    let multiY = this.canvas.getHeight()/dimY;
                    //printConsole(`multiX = ${multiX}, multiY = ${multiY}`)
                    for(index = 0; index < objList.length; index++){
                        var context=this.canvas.getContext("2d");
                        context.clearRect(0,0,this.canvas.width, this.canvas.height);
                        let marginLeft = objList[index].left * multiX;
                        let marginTop = (objList[index].top * multiY)+10;
                        let strokeColor = objList[index].stroke;
            
                        setInterval(()=>{
                            context.font='500 40px FontAwesome';
                            context.fillStyle = strokeColor;
                            context.textAlign = 'center';
                            context.fillText('\uf05b',marginLeft, marginTop);
                        },1000)
                    }
                });
                this.clearAnnotations();
                this.canvas.backgroundImage = false;
                this.canvas.renderAll();
            } else { */
                this.releaseCanvas(); //TP-5119
                this.canvas.loadFromJSON(strCanvasJSON, () => {
                    let objList = this.canvas.getObjects();
                    let index = 0;
                    //printConsole(`canvas Width: ${this.canvas.getWidth()}, canvas Height: ${this.canvas.getHeight()}`)
                    
                    // Calculate the Ratio of Technician screen/Expert screen
                    let multiX = this.canvas.getWidth()/dimX;
                    let multiY = this.canvas.getHeight()/dimY;
                    //printConsole(`multiX = ${multiX}, multiY = ${multiY}`)
                    for(index = 0; index < objList.length; index++){
                        //printConsole(`scaleX = ${objList[index].scaleX}, scaleY = ${objList[index].scaleY}, left = ${objList[index].left}, top = ${objList[index].top}`)
                        objList[index].scaleX = objList[index].scaleX * multiX;
                        objList[index].scaleY = objList[index].scaleY * multiY;
                        objList[index].left = objList[index].left * multiX; // 
                        objList[index].top = objList[index].top * multiY;
                        objList[index].selectable = false;
                        //printConsole(`scaleX = ${objList[index].scaleX}, scaleY = ${objList[index].scaleY}, left = ${objList[index].left}, top = ${objList[index].top}`)
                        objList[index].setCoords();
                    }
                });
                this.clearAnnotations();
                this.canvas.backgroundImage = false;
                this.canvas.renderAll();
            //}
            
        } else {
            this.clearAnnotations();
            let first = parentel.firstElementChild; 
            while (first) { 
                first.remove(); 
                first = parentel.firstElementChild; 
            }
        }
    }

    // Function to create the new canvas, display the annotation object
    // For Technician side (Annotation with RC Image)
    displayRCAnnotate = (paramElem, imgData, format) =>{
        printConsole(`Display RC Annotations on Passive expert at ${performance.now()}`); //TP-4275
        const { canvasParent, isSafari, isiOS } = this.props;                         
        let parentel = document.getElementById(canvasParent);
        if(!parentel){
            return false;//MB2-612, MB2-575
        }
        let oldCanvas = document.getElementById("canvas");
        /* if (isSafari === true && isiOS === true && oldCanvas) { //
            printConsole("release canvas during RC!!")
            oldCanvas.height = 1;
            oldCanvas.width = 1;
            if(parentel.children.length >= 1) {
                for(let ii=0; ii<parentel.children.length; ii++) {
                    parentel.removeChild(parentel.children[ii]);
                }
            }
        } */
        //TP-2724 -- If old canvas exists do not re-create the canvas again only except when Erase All needs to be done
        if (oldCanvas && strCanvasJSON !== "") {
            //TP-2724 -- Canvas already exists
            //TP-4118 -- Show the normal crosshair pointer during shapes annotation
            if (oldCanvas.matches('.cursor-none') === true) {
                oldCanvas.classList.remove("cursor-none");
                oldCanvas.style.cursor = "pointer";
                //remove cursor-none for the "upper-canvas" element as well
                oldCanvas.nextSibling.classList.remove("cursor-none");
                oldCanvas.nextSibling.style.cursor = "pointer";
            }
        } else {
            // create a canvas element
            let newCanvas = document.createElement('canvas');
            newCanvas.id = 'canvas';
            let context = newCanvas.getContext("2d");
            let paramElemVdoChild = paramElem;
            const rect = paramElemVdoChild.getBoundingClientRect();
            const sideBar = document.getElementById(this.props.sideBar);
            const videoContainer = document.getElementById(this.props.parentId);
            const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')

            newCanvas.width = paramElemVdoChild.offsetWidth;
            newCanvas.height = paramElemVdoChild.offsetHeight;
            newCanvas.style.borderRadius = "10px";
            newCanvas.style.display= 'block';
            
            // insert canvas inside canvas Parent element
            parentel.style.position = 'absolute';
            parentel.style.left = (rect.x - sideBar.offsetWidth - parseInt(videoContainerPadding)) + 'px'; 
            parentel.style.top =  '0'+ 'px'; //rect.y 
            if(parentel.children.length < 1) {
                parentel.appendChild(newCanvas);
            } else {
                
                //parentel.removeChild(parentel.firstChild);
                //parentel.appendChild(newCanvas);
                /**TP-2724 */
                parentel.insertBefore(newCanvas, parentel.children[0]); //.nextSibling
                parentel.removeChild(parentel.children[1]);
            }

            // add a fabric canvas element
            this.canvas = new fabric.Canvas(newCanvas);
        }        

        //TP-2474 --const {isPointerActive} = this.state.shapesSetting; //TP-2475
        printConsole(` expert dimX = ${this.state.expertDimX}, expert dimY = ${this.state.expertDimY} at ${performance.now()}`) //TP-4275
        // set the Expert X & Y (Width & Height)
        let dimX = this.state.expertDimX;
        let dimY = this.state.expertDimY;
        if(dimX === 0){ 
            printConsole('dimensions of expert canvas did not come in');
            dimX = this.canvas.getWidth();
            dimY = this.canvas.getHeight();
            //return;
        }

        printConsole(`dimX = ${dimX}, dimY = ${dimY} at ${performance.now()}`) //TP-4275
        let storedBackground = null; //TP-2724
        //console.log(strCanvasJSON);
        // When the Object Json is set
        if(strCanvasJSON !== null){
            printConsole(`Load the json on the Passive expert at ${performance.now()}`) //TP-4275
            //TP-2475
            /*if (isPointerActive === true || this.checkForPointerTypeJSON()) {
                this.canvas.loadFromJSON(strCanvasJSON, () => {
                    let objList = this.canvas.getObjects();
                    let index = 0;
                    
                    // Calculate the Ratio of Technician screen/Expert screen
                    let multiX = this.canvas.getWidth()/dimX;
                    let multiY = this.canvas.getHeight()/dimY;
                    //printConsole(`multiX = ${multiX}, multiY = ${multiY}`)
                    for(index = 0; index < objList.length; index++){
                        var context=this.canvas.getContext("2d");
                        context.clearRect(0,0,this.canvas.width, this.canvas.height);
                        let marginLeft = objList[index].left * multiX;
                        let marginTop = (objList[index].top * multiY)+10;
                        let strokeColor = objList[index].stroke;
            
                        setInterval(()=>{
                            context.font='500 40px FontAwesome';
                            context.fillStyle = strokeColor;
                            context.textAlign = 'center';
                            context.fillText('\uf05b',marginLeft, marginTop);
                        },1000)
                    }
                });
                
            } else {*/
                //TP-2724
                if (this.canvas.backgroundImage !== null) {
                    storedBackground = this.canvas.backgroundImage;
                }
                //TP-5615
                if (typeof(strCanvasJSON) === "string" && strCanvasJSON.includes("px"))
                    printConsole("JSON is empty");
                else {
                    //TP-6459
                    if (this.state.isStateChange === false)
                        this.releaseCanvas(); //TP-5119
                    this.canvas.loadFromJSON(strCanvasJSON, () => {
                        //fabric.log(o, object);
                        let objList = this.canvas.getObjects();
                        let index = 0;
                        // Calculate the Ratio of Technician screen/Expert screen
                        let multiX = this.canvas.getWidth()/dimX;
                        let multiY = this.canvas.getHeight()/dimY;
                        for(index = 0; index < objList.length; index++)
                        {
                            objList[index].scaleX = objList[index].scaleX * multiX;
                            objList[index].scaleY = objList[index].scaleY * multiY;
                            objList[index].left = objList[index].left * multiX; // 
                            objList[index].top = objList[index].top * multiY;
                            objList[index].selectable = false;
                            objList[index].setCoords();
                        }
                    });
                }
            //}
        }

        //TP-2724
        if(storedBackground !== null) {
            this.canvas.backgroundImage = storedBackground;
        }

        // When the RC Image is set
        if(imgData /**TP-5030*/ && imgData !== '' && this.canvas.backgroundImage === null /**TP-2724*/){
            let contentType = 'image/png';
            if (this.props.user.isexpert && format !== "" && format !== undefined) {
                contentType = 'image/'+format;
                imgData = imgData.replace('data:'+contentType+';base64,', '').trim();
            } 
            // technician/passive expert imgData is different for technician and passive expert
            const blob = this.props.user.isexpert && (format === "" || format === undefined )? imgData : new base64StringToBlob(imgData, contentType);// MB2-556
            //const blob = new base64StringToBlob(imgData, contentType);// FQ3-250
            let urlObj = URL.createObjectURL(blob);
            printConsole(`Load the RC image onto the Canvas at ${performance.now()}`);//TP-4275

                fabric.Image.fromURL(urlObj, (img) => {
                 // add background image
                 //printConsole("3Canvas image:", img);
                 //printConsole(this.canvas);
                //TP-2436 -- resolved the "Something went wrong issue"
                 if (this.canvas.renderAll !== undefined) {
                    this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
                       scaleX: this.canvas.width / img.width,
                       scaleY: this.canvas.height / img.height
                   });
                }
            });
        } else if (this.canvas.backgroundImage !== null && imgData !== '' && strCanvasJSON !== "") {
            //TP-2724
            this.canvas.renderAll();
        } else{
            printConsole(`Clear out the RC image & Annotation at ${performance.now()}`); //TP-4275
            // Else Clear the RC Image with annotation

            //document.getElementById('canvas').style.display = "none";
            this.clearAnnotations();
            let first = parentel.firstElementChild; 
            while (first) { 
                first.remove(); 
                first = parentel.firstElementChild; 
            } 

            this.canvas.backgroundImage = false;

            this.canvas.renderAll();
            storedBackground = null;//TP-2724
            //this.canvas.clear();
        }
    }

    //TP-4930 -- Method to redraw the Passive expert's RC Canvas, when screen is resized
    displayRCAnnotateResizedPassiveExpert = (paramElem, imgData, format) => {
        printConsole(`Display RC Annotations on Passive expert after resizing`); //TP-4275
        const { canvasParent, isSafari, isiOS } = this.props;                         
        let parentel = document.getElementById(canvasParent);
        if(!parentel){
            return false;//MB2-612, MB2-575
        }
        let oldCanvas = document.getElementById("canvas");
        if (oldCanvas && strCanvasJSON !== "") {
            //TP-2724 -- Canvas already exists
            //TP-4118 -- Show the normal crosshair pointer during shapes annotation
            if (oldCanvas.matches('.cursor-none') === true) {
                oldCanvas.classList.remove("cursor-none");
                oldCanvas.style.cursor = "pointer";
                //remove cursor-none for the "upper-canvas" element as well
                oldCanvas.nextSibling.classList.remove("cursor-none");
                oldCanvas.nextSibling.style.cursor = "pointer";
            }

            let paramElemVdoChild = paramElem;
            const rect = paramElemVdoChild.getBoundingClientRect();
            const sideBar = document.getElementById(this.props.sideBar);
            const videoContainer = document.getElementById(this.props.parentId);
            const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')

            oldCanvas.width = paramElemVdoChild.offsetWidth;
            oldCanvas.height = paramElemVdoChild.offsetHeight;
            oldCanvas.style.borderRadius = "10px";
            oldCanvas.style.display= 'block';
            
            // insert canvas inside canvas Parent element
            parentel.style.position = 'absolute';
            parentel.style.left = (rect.x - sideBar.offsetWidth - parseInt(videoContainerPadding)) + 'px'; 
            parentel.style.top =  '0'+ 'px'; //rect.y 
            if(parentel.children.length < 1) {
                parentel.appendChild(oldCanvas);
            } else {
                
                //parentel.removeChild(parentel.firstChild);
                //parentel.appendChild(newCanvas);
                /**TP-2724 */
                parentel.insertBefore(oldCanvas, parentel.children[0]); //.nextSibling
                parentel.removeChild(parentel.children[1]);
            }
            this.canvas = new fabric.Canvas(oldCanvas);
            //Also resize the dimensions of the oldCanvas
            printConsole(` expert dimX = ${this.state.expertDimX}, expert dimY = ${this.state.expertDimY} at ${performance.now()}`) //TP-4275
            // set the Expert X & Y (Width & Height)
            let dimX = this.state.expertDimX;
            let dimY = this.state.expertDimY;
            if(dimX === 0){ 
                printConsole('dimensions of expert canvas did not come in');
                dimX = this.canvas.getWidth();
                dimY = this.canvas.getHeight();
                //return;
            }

            printConsole(`dimX = ${dimX}, dimY = ${dimY}`) //TP-4275

            let storedBackground = null; //TP-2724
            //console.log(strCanvasJSON);
            // When the Object Json is set
            if(strCanvasJSON !== null){
                printConsole(`Load the json on the Passive expert at ${performance.now()}`) //TP-4275                
                //TP-2724
                if (this.canvas.backgroundImage !== null) {
                        storedBackground = this.canvas.backgroundImage;
                }
                this.releaseCanvas(); //TP-5119
                this.canvas.loadFromJSON(strCanvasJSON, () => {
                    //fabric.log(o, object);
                    let objList = this.canvas.getObjects();
                    let index = 0;
                    // Calculate the Ratio of Technician screen/Expert screen
                    let multiX = this.canvas.getWidth()/dimX;
                    let multiY = this.canvas.getHeight()/dimY;
                    for(index = 0; index < objList.length; index++)
                    {
                        objList[index].scaleX = objList[index].scaleX * multiX;
                        objList[index].scaleY = objList[index].scaleY * multiY;
                        objList[index].left = objList[index].left * multiX; // 
                        objList[index].top = objList[index].top * multiY;
                        objList[index].selectable = false;
                        objList[index].setCoords();
                    }
                });
                
            }

            //TP-2724
            if(storedBackground !== null) {
                this.canvas.backgroundImage = storedBackground;
            }

            // When the RC Image is set
            if(imgData /**TP-5030*/ && imgData !== '' && this.canvas.backgroundImage === null /**TP-2724*/){
                let contentType = 'image/png';
                if (this.props.user.isexpert && format !== "" && format !== undefined) {
                    contentType = 'image/'+format;
                    imgData = imgData.replace('data:'+contentType+';base64,', '').trim();
                } 
                // technician/passive expert imgData is different for technician and passive expert
                const blob = this.props.user.isexpert && (format === "" || format === undefined )? imgData : new base64StringToBlob(imgData, contentType);// MB2-556
                //const blob = new base64StringToBlob(imgData, contentType);// FQ3-250
                let urlObj = URL.createObjectURL(blob);
                printConsole(`Load the RC image onto the Canvas at ${performance.now()}`);//TP-4275

                    fabric.Image.fromURL(urlObj, (img) => {
                    // add background image
                    //printConsole("3Canvas image:", img);
                    //printConsole(this.canvas);
                    //TP-2436 -- resolved the "Something went wrong issue"
                    if (this.canvas.renderAll !== undefined) {
                        this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
                        scaleX: this.canvas.width / img.width,
                        scaleY: this.canvas.height / img.height
                    });
                    }
                });
            } else if (this.canvas.backgroundImage !== null && imgData !== '' && strCanvasJSON !== "") {
                //TP-2724
                this.canvas.renderAll();
            } else{
                //printConsole(`Clear out the RC image & Annotation at ${performance.now()}`); //TP-4275
                // Else Clear the RC Image with annotation

                //document.getElementById('canvas').style.display = "none";
                /*TP-5197 this.clearAnnotations();
                let first = parentel.firstElementChild; 
                while (first) { 
                    first.remove(); 
                    first = parentel.firstElementChild; 
                } 

                this.canvas.backgroundImage = false; */
                //TP-5197
                printConsole(`Just render out the RC image with the Annotations ${performance.now()}`);
                this.canvas.renderAll();
                storedBackground = null;//TP-2724
                //this.canvas.clear();
            }
        }
    }

    //TP-2523 -- Method to render/remove the tracker for the Pointer movement of the Primary expert
    // on Technician's screen (with or without RC Image)
    displayPointerTrack = (paramElem, pencolor, imgData, format) => {
        const { canvasParent, isSafari, isiOS } = this.props;
        let drawElem = document.getElementById('canvas');
        let parentel = document.getElementById(canvasParent);
        /* TP-2724 if (isSafari === true && isiOS === true && drawElem) { //TP-2602
            printConsole("release canvas during pointer Annotation")
            drawElem.height = 1;
            drawElem.width = 1;
            if(parentel.children.length >= 1) {
                for(let ii=0; ii<parentel.children.length; ii++) {
                    parentel.removeChild(parentel.children[ii]);
                }
            }
        } */

        //TP-2474 -- To handle the creation of the canvas when only the Pointer
        // Payload comes to the technician without there being any other annotation drawn
        if (drawElem === null || drawElem === undefined) {
            printConsole("canvas element not there so creating new canvas element");
            //return;

            const { canvasParent } = this.props;                         
            let parentel = document.getElementById(canvasParent);
            if(!parentel){
                return false;//MB2-612, MB2-575
            }
            // create a canvas element
            let newCanvas = document.createElement('canvas');
            newCanvas.id = 'canvas';
            let context = newCanvas.getContext("2d");
            let paramElemVdoChild = paramElem;
            const rect = paramElemVdoChild.getBoundingClientRect();
            const sideBar = document.getElementById(this.props.sideBar);
            const videoContainer = document.getElementById(this.props.parentId);
            const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')

            newCanvas.width = paramElemVdoChild.offsetWidth;
            newCanvas.height = paramElemVdoChild.offsetHeight;
            newCanvas.style.borderRadius = "10px";
            newCanvas.style.display= 'block';
            
            // insert canvas inside canvas Parent element
            parentel.style.position = 'absolute';
            parentel.style.left = (rect.x - sideBar.offsetWidth - parseInt(videoContainerPadding)) + 'px'; 
            parentel.style.top =  '0'+ 'px'; //rect.y 
            if(parentel.children.length < 1) {
                parentel.appendChild(newCanvas);
            } else {
                
                /* parentel.removeChild(parentel.firstChild);
                parentel.appendChild(newCanvas); */
                /**TP-2724 */
                parentel.insertBefore(newCanvas, parentel.children[0]); //.nextSibling
                parentel.removeChild(parentel.children[1]);
            }

            // add a fabric canvas element
            this.canvas = new fabric.Canvas(newCanvas);
        } else {
            // add a fabric canvas element
            console.log(this.canvas.fabric);
            //this.canvas = drawElem.fabric;
            //this.canvas = new fabric.Canvas(drawElem);

        }    
        let canvas = this.canvas;

        let dimX = this.state.expertDimX;
        let dimY = this.state.expertDimY;
        if (dimX === 0) {
            printConsole('dimensions of expert canvas did not come in');
            dimX = this.canvas.getWidth();
            dimY = this.canvas.getHeight();
            //return;
        }
        printConsole(`dimX = ${dimX}, dimY = ${dimY}`);
        let lineH, lineV, circleP; //TP-2720 & TP-2569
        
        let pointerDimX = this.state.pointerDimX; //TP-2474
        let pointerDimY = this.state.pointerDimY; //TP-2474
        const {isSmallFormFactorDevice} = this.props;
        printConsole(`pointerDimX = ${pointerDimX}, pointerDimY = ${pointerDimY}`);
        let storedBackground = null; //TP-2724
        printConsole(strCanvasJSON);
        if (strCanvasJSON && strCanvasJSON !== null && strCanvasJSON !== "" && pointerDimX !== -1 && pointerDimY !== -1) {
            //TP-4788 TP-2724
            if (this.canvas.backgroundImage !== null) {
                storedBackground = this.canvas.backgroundImage;
            }
            if (strCanvasJSON.objects) {
                //TP-4788 TP-4786
                for(let i=0; i<strCanvasJSON.objects.length; i++) {
                    if(strCanvasJSON.objects[i].type === "i-text") strCanvasJSON.objects[i].text = unescapeChar(strCanvasJSON.objects[i].text, "double-quotes"); //TP-5151
                    if(strCanvasJSON.objects[i].type === "i-text") strCanvasJSON.objects[i].text = unescapeChar(strCanvasJSON.objects[i].text, "newline"); //TP-5151
                    if(strCanvasJSON.objects[i].type === "i-text") strCanvasJSON.objects[i].text = unescapeChar(strCanvasJSON.objects[i].text, "backward-slash"); //TP-5151
                }
                this.releaseCanvas(); //TP-5119
                this.canvas.loadFromJSON(strCanvasJSON, function () {
                    //fabric.log(o, object);
                    let objList = canvas.getObjects();
                    let index = 0;
                    let multiX = canvas.getWidth() / dimX;
                    let multiY = canvas.getHeight() / dimY;
                    for (index = 0; index < objList.length; index++) {
                        objList[index].scaleX = objList[index].scaleX * multiX;
                        objList[index].scaleY = objList[index].scaleY * multiY;
                        objList[index].left = objList[index].left * multiX;
                        objList[index].top = objList[index].top * multiY;
                        objList[index].selectable = false;
                        objList[index].setCoords();
                    }
                });
            }
        }
    
        if (pointerDimX !== -1 && pointerDimY !== -1) {
            //Remove older pointer objects in the canvas
            let obj = this.canvas.getObjects();
            //TP-2569 -- Plus sign drawing for the Pointer's location insted of the Dot
            for(let index = 0; index < obj.length; index++){
                if (obj[index].type === "line" && obj[index].id === "pointer_line_h") this.canvas.remove(obj[index]);
                if (obj[index].type === "line" && obj[index].id === "pointer_line_y") this.canvas.remove(obj[index]);
                if (obj[index].type === "circle" && obj[index].id === "pointer_circle") this.canvas.remove(obj[index]); //TP-2828 & TP-2720
            }

            let multiX = this.canvas.getWidth() / dimX;
            let multiY = this.canvas.getHeight() / dimY;

            let xPos = Math.round(pointerDimX * multiX);
            let yPos = Math.round(pointerDimY * multiY);

            let deltaPos = 24; //TP-4112 & TP-2720
            // Initiate a line instance
            let xPos1 = (xPos - deltaPos);
            let xPos2 = (xPos + deltaPos);
            /**TP-2720 -- New UI for the Pointer Crosshair 
             * Icon with changes to size and strokeWidth etc
             */
            lineH = new fabric.Line([xPos1, yPos-1, xPos2, yPos-1], {
                strokeWidth: isSmallFormFactorDevice ? 2.5 : 2, //TP-4112
                stroke: pencolor,
                selectable: false, //TP-2600 -- Use case 2
                id: 'pointer_line_h',
            });
            let yPos1 = (yPos - deltaPos);
            let yPos2 = (yPos + deltaPos);
            lineV = new fabric.Line([xPos-0.75, yPos1, xPos-0.75, yPos2], {
                strokeWidth: isSmallFormFactorDevice ? 2.5 : 2, //TP-4112
                stroke: pencolor,
                selectable: false, //TP-2600 -- Use case 2
                id: 'pointer_line_y',
            });
            circleP = new fabric.Circle({
                left: xPos,
                top: yPos,
                originX: 'center',
                originY: 'center',
                radius: 16, //TP-4112
                fill: '',
                stroke: pencolor, //"red", 
                selectable: false, //TP-2600 -- Use case 2 
                strokeWidth: /* isSmallFormFactorDevice ? 1 : */ 4, //TP-4112
                id: "pointer_circle" //TP-2828
            });
            //console.log("xPos=" + xPos + " yPos=" + yPos + " xPos1=" + xPos1 + " xPos2=" + xPos2 + " yPos1=" + yPos1 + " yPos2=" + yPos2);
            this.canvas.add(lineH);
            this.canvas.add(lineV);            
            this.canvas.add(circleP);//TP-2720
            
            //TP-2474
            /* pointer = new fabric.Circle({
                left: pointerDimX * multiX,
                top: pointerDimY * multiY,
                originX: 'center',
                originY: 'center',
                radius: 4,
                fill: pencolor,
                stroke: pencolor,
                strokeWidth: 2
            });
    
            this.canvas.add(pointer); */
        } else {
            if (this.canvas === undefined) return;
            //Remove older pointer objects in the canvas
            let obj = this.canvas.getObjects();
            for(let index = 0; index < obj.length; index++){
                //TP-2569
                if (obj[index].type === "line" && obj[index].id === "pointer_line_h") this.canvas.remove(obj[index]);
                if (obj[index].type === "line" && obj[index].id === "pointer_line_y") this.canvas.remove(obj[index]);
                if (obj[index].type === "circle" && obj[index].id === "pointer_circle") this.canvas.remove(obj[index]); //TP-2828 & TP-2720
            }
            //let multiX = canvas.getWidth() / dimX;
            //let multiY = canvas.getHeight() / dimY;
        }

        //TP-2724 -- Set the background image of the canvas instead of loading the image again
        if(storedBackground !== null) {
            this.canvas.backgroundImage = storedBackground;
        }

        //TP-2474 -- Commented out so as not to reload the image again
        //TP-2523-- Handling of Technician/Passive expert side RC image loading when pointer annotaion payload is received
        // When the RC Image is set
        /* if(imgData !== ''){
            let contentType = 'image/png';
            if (this.props.user.isexpert && format !== "" && format !== undefined) {
                contentType = 'image/'+format;
                imgData = imgData.replace('data:'+contentType+';base64,', '').trim();
            } 
            // technician/passive expert imgData is different for technician and passive expert
            const blob = this.props.user.isexpert && (format === "" || format === undefined )? imgData : new base64StringToBlob(imgData, contentType);// MB2-556
            //const blob = new base64StringToBlob(imgData, contentType);// FQ3-250
            let urlObj = URL.createObjectURL(blob);

            fabric.Image.fromURL(urlObj, (img) => {
                 // add background image
                 //printConsole("3Canvas image:", img);
                 //printConsole(this.canvas);
                //TP-2436 -- resolved the "Something went wrong issue"
                 if (this.canvas.renderAll !== undefined) {
                    this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
                       scaleX: this.canvas.width / img.width,
                       scaleY: this.canvas.height / img.height
                   });
                }
            });
        } */
        this.canvas.renderAll();//TP-2724

    }

    //TP-2475 -- Checking method for when Expert's json payload is a pointer
    // compared to other types of Annotations
    checkForPointerTypeJSON = () => {
        if (strCanvasJSON.objects !== undefined && strCanvasJSON.objects.length === 1 && strCanvasJSON.objects[0].type === "path") {
            const xDistance = strCanvasJSON.objects[0].path[1][1] - strCanvasJSON.objects[0].path[0][1];
            const yDistance = strCanvasJSON.objects[0].path[1][2] - strCanvasJSON.objects[0].path[0][2];
            //console.log("xDistance ", xDistance, " yDistance ", yDistance);
            if (xDistance < 1 && yDistance < 1)
                return true;
            else return false;
        } else {
            printConsole("Other types of annotation is received");
            return false;
        }
    }

    render = ()=> '';
}

Annotation.propTypes = {
    canvasParent: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    g_maxDivId: PropTypes.string.isRequired,
    annotation: PropTypes.object.isRequired,
    //imgUrl: PropTypes.string,
    bAnnotate: PropTypes.bool.isRequired,
    bRemoteCapture: PropTypes.bool,
    bMaxChat: PropTypes.bool,
    bAllChat: PropTypes.bool,
    urlObj: PropTypes.string,
    rcStart: PropTypes.bool,
    rcImgObj: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
      
};


export default Annotation;