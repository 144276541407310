import React from 'react';
import { Trans } from '@lingui/macro';

const MessageModal = (props) => (
    <div className="modale opened">
        <div className="__modal-dialog">
            <form>
                {
                    props.message ? <div className='__modal-header'>
                        <h4 style={{color: props.color}}><Trans id={props.message} /></h4>
                    </div> : ''
                }
                { 
                    props.children ? 
                    <div className="__modal-body">
                        {props.children}
                    </div>: 
                    '' 
                }
                <div className="__modal-footer flex-center">
                    <button className="btn-green" onClick={props.onClickClose}><Trans>Close</Trans></button>
                </div>
            </form>
        </div>
    </div>
)

export default MessageModal;