import React from 'react';
import ReactPaginate from 'react-paginate';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import moment from 'moment';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import DatePicker, { registerLocale, setDefaultLocale, getDefaultLocale } from "react-datepicker";
import en from 'date-fns/locale/en-US';
import { ModalFooter, Button as BButton, Spinner, UncontrolledTooltip, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './Groups.scss';
import ReactHintFactory from 'react-hint';
import { printConsole } from '../helpers';
import {diagonastics, diagnostics} from "../DiagonasticHelper";
import SortIcons from './SortIcons';
const DetectRTC = require('detectrtc');

registerLocale('en', en);
setDefaultLocale('en');
const ReactHint = ReactHintFactory(React);

//TZ-487
const swapArrayElements = function(arr, indexA, indexB) {
    let temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
    return arr;
};

function parseMilliseconds(milliseconds){
    //Get hours from milliseconds
    var hours = milliseconds / (1000*60*60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;
  
    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' +  absoluteMinutes;
  
    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;
  
  
    return h + ':' + m + ':' + s;
}  

// Converting the jSON to CSV format
function convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }

        str += line + '\r\n';
    }

    return str;
}

// Exporting jSON data to csv format
function exportCSVFile(headers, items, fileTitle) {
    if (headers) {
        items.unshift(headers);
    }

    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = convertToCSV(jsonObject);

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}


class UsageDetails extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            usageData: [],
            missedCallData: [],
            usersInSession: props.usersInSession, //TP-1599
            toggleCallDialog: false,
            groups: [],
            selectedGroup: '',
            isLoading: false,
            pageCount: 0,
            pagination: {
                currentPage: 1,
                perPage: props.maxPerPage ? props.maxPerPage : 10, //TP-2883
            },
            callpageCount: 0, //TZ-487
            callpagination: {
                currentPage: 1,
                perPage: props.maxPerPage ? props.maxPerPage : 10, 
            }, //TZ-487
            recordingUrlExists: false,
            groups:[],
            groupUsers:[],
            selectedOption: null,
            disableGroupSelect: false,
            selectedUser: null,
            disableUserSelect: false,
            fetchGroupUsage: false,
            toDate: '',
            fromDate: '',
            unFormatedToDate: '',
            unFormatedFromDate: '',
            isMobileDevice: props.isMobileDevice ? props.isMobileDevice : false, //TP-2892
            showMissedCalls: props.showMissedCalls ? props.showMissedCalls : false,
            showRecording: false,
            recording_url: null,
            webcamAvailable: false,
            micAvailable: false,
            isPartOfAGroup: props.isPartOfAGroup ? props.isPartOfAGroup : false, /**TP-4814*/
            selectedGroupId: '',
            sortColumn: [], //TZ-487
            missedCallCurrentPageData: []
        }
        this.timer = null;
        this.isMount = false;
    }

    componentDidMount = () => {
        let {isadmin, isexpert} = this.props;
        this.isMount = true;
        this.setState({
            unFormatedFromDate: new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1),
            unFormatedToDate: new Date(),
            fromDate: this.convertDate(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1)),
            toDate: this.convertDate(new Date())
        },() => {
            //if (this.state.showMissedCalls === false) {
                this.fetchUsageDetails();
                if(isadmin || isexpert){
                    this.fetchGroupNames();
                    this.fetchAllUsers();
                }
            //} else {
                if (isexpert === false) {
                    DetectRTC.load(() => {
                        this.setState({webcamAvailable: DetectRTC.hasWebcam && DetectRTC.hasMicrophone});
                    });
                } else {
                    DetectRTC.load(() => {
                        this.setState({micAvailable: DetectRTC.hasMicrophone});
                    });
                }
                this.fetchMissedCallDetails();
                this.props.authService.socket.on('respond-to-the-call', this.subscribeToRespondToTheCall);
            //}
        });
    }

    componentDidUpdate = (prevProps, prevState) => {
        /**TP-2883*/
        if (prevState.pagination.perPage !== this.state.pagination.perPage) {
            //if (this.state.showMissedCalls === false)
                this.fetchUsageDetails();	            
        } else if (prevProps.showMissedCalls !== this.props.showMissedCalls && this.props.showMissedCalls === true) {
            //this.toggleMissedCalls();
            this.setState({ showMissedCalls: true });
        } else if (this.props.usersInSession !== prevProps.usersInSession) {
            this.setState({ usersInSession: this.props.usersInSession }); //TP-1599
        } else if (this.props.isPartOfAGroup !== prevProps.isPartOfAGroup) {
            this.setState({ isPartOfAGroup: this.props.isPartOfAGroup }); //TP-1599
        } else if (prevProps.refetchMissedcalls !== this.props.refetchMissedcalls && this.props.refetchMissedcalls === true) {
            this.fetchMissedCallDetails();
        }
    }

    componentWillReceiveProps(nextProps){
        /* if (nextProps.isPartOfAGroup !== this.props.isPartOfAGroup) {
            this.setState({ isPartOfAGroup: nextProps.isPartOfAGroup }, () => {
                printConsole(this.state.isPartOfAGroup);
            });
        } */
        if(nextProps.maxPerPage !== this.props.maxPerPage) {
            //TZ-487
            this.setState({ callpagination: { currentPage: 1/* this.state.pagination.currentPage */, perPage: nextProps.maxPerPage }}, () => {
                const {perPage} = this.state.callpagination;
                if (this.state.missedCallData && this.state.missedCallData.length > 1) {
                    const total = this.state.missedCallData.length
                    //console.log(total, perPage);
                    this.setState({
                        callpageCount: Math.ceil(total / perPage)
                    }, () => {
                        this.getMissedCallsCurrentPage(this.state.missedCallData);
                        this.handleCallPaginationClick({selected: 0})
                    });
                } else {
                    this.fetchMissedCallDetails();
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        //TP-2892
        if(nextProps.isMobileDevice !== prevState.isMobileDevice) {
            return {isMobileDevice : nextProps.isMobileDevice};
        } else if(nextProps.maxPerPage !== prevState.maxPerPage) {
            /**TP-2883 */
            return { pagination: { 
                currentPage: prevState.pagination.currentPage, //1
                perPage: nextProps.maxPerPage }
            };
        } else if(nextProps.isPartOfAGroup !== prevState.isPartOfAGroup) {
            return { isPartOfAGroup: nextProps.isPartOfAGroup };
        } else if(nextProps.showMissedCalls !== prevState.showMissedCalls && nextProps.showMissedCalls === true) {
            return { showMissedCalls: nextProps.showMissedCalls }; //TZ-487
        }
        else return null;
    }

    componentWillUnmount() {
        this.props.authService.socket.removeListener('respond-to-the-call', this.subscribeToRespondToTheCall);
    }

    isAnotherExpertInSession = (groupId) => {
        const {inSessionArr, isexpert} = this.props;
        printConsole("in session array");
        printConsole(inSessionArr);
        const expertUsers = this.props.userlist.filter(u => u.isexpert);
        printConsole(expertUsers);
        let flag = false;
        for (let ii = 0; ii<inSessionArr.length; ii++) {
            if (expertUsers.some(ex => ex.email === inSessionArr[ii].userName) === true && groupId === inSessionArr[ii].groupId) {
                flag = true;
                break;
            }
            else
                flag = false;
        }
        printConsole(`is Passive expert ${flag}`);
        return flag;
    }

    subscribeToRespondToTheCall = ({ action, groupName, groupId, recipient }) => {
        if (!this.isMount || this.props.activePage !== "UsageDetails") return;
        printConsole("Usage tab Missed call notification View listener for respond-to-the-call");
        let msg;
        const {isexpert, isadmin} = this.props;
        const {selectedGroupId} = this.state;
        // Clear the timer if there is already a timeout.
        if (this.timer && action !== "timeout") clearTimeout(this.timer);
        if (action === 'accepted') {
            if (isexpert) {
                // Expert Handling
                const isPassive = this.isAnotherExpertInSession(groupId) //TP-1534
                if (isPassive) 
                    this.getSessionTokenForTechnician(groupId, groupName); 
                else {
                    this.props.updateCallDialogStatus(false);
                    this.props.loadSelectedGroup(groupId, groupName);
                    this.props.onRouteChange('session');
                    sessionStorage.setItem("gotoMissed", "true"); //TZ-487
                }
            } else
                this.getSessionTokenForTechnician(groupId, groupName);            
            return;
        } else if(action === 'declined') {
            this.props.updateCallDialogStatus(false);
            msg = i18nMark('User has declined your call. Please try later.');
            if (isexpert && selectedGroupId !== ''){
                this.clearSessionToken(selectedGroupId);
            }
            //TP-5903 -- Do not send the missed call notification during the declined call flow
            this.cancelCall({ mode: 'declinedUser', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) });
        } else if(action === 'auto-decline') {
            //TP-6077
            this.props.updateCallDialogStatus(false);
            msg = i18nMark('User has declined your call. Please try later.');
            if (isexpert && selectedGroupId !== ''){
                this.clearSessionToken(selectedGroupId);
            }
            //Send the missed call notification during the auto-declined call flow
            this.cancelCall({ mode: 'callUser', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) });
        } else if (action === 'in_session') {
            //TP-4228 -- Join the ongoing call if by same groupid else drop the call attempt only for Technician
            if (!isexpert && !isadmin && selectedGroupId && groupId && groupId === selectedGroupId) {
                this.getSessionTokenForTechnician(groupId, groupName);    
            } else {
                this.props.updateCallDialogStatus(false);
                msg = i18nMark('User is already on a call with another user, please try again later.');
            }
        } else if (action === 'no_group_found') {
            this.props.updateCallDialogStatus(false);
            msg = i18nMark('You are not currently setup to call {recipient}');
        } /* TP-5903 else if (action === 'timeout') {
            this.props.updateCallDialogStatus(false);
            msg = i18nMark(`{recipient} did not pick up the call, please try again later.`);            
        } */
        //TP-2420
        if (msg !== undefined) {
            this.setState({
                userCallResponse: msg
            });
        }
    }

    getSessionTokenForTechnician = (groupId, groupName) => {
        const {isexpert, audio_mode} = this.props;
        let fetchString = "", apiname;
        //TP-4213 -- New API integration to support Tech2Tech call feature from Users Tab
        if (!isexpert && audio_mode === "tech2Tech") {
            fetchString = 'user_session/callPeer?groupID=';
            apiname = 'callPeer';
        } else {
            fetchString = 'sessiondataH364?groupID=';
            apiname = 'sessionData';
        }
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.userid;
        printConsole(`${apiname} is called on respond-to-the-call with payload ---> { groupID: ${groupId}, loggedInUserId: ${this.props.userid} }`)
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            return response.json();
        })
        .then((data) => {
            /* if(!data.isongoingnow){
                const selectedGroup = { groupid: newVal.value };
                this.setPrimaryExpertIDApi(selectedGroup);
            } */
            printConsole(data);
            this.props.loadSessionInfo(data);
            if (!isexpert) {
                // Technician Handling
                this.props.updateCallDialogStatus(false);
                this.props.loadSelectedGroup(groupId, groupName);
                this.props.onRouteChange('session');
                sessionStorage.setItem("gotoMissed", "true"); //TZ-487
            } else {
                // Expert Handling
                this.props.updateCallDialogStatus(false);
                this.props.loadSelectedGroup(groupId, groupName);
                this.props.onRouteChange('session');
                sessionStorage.setItem("gotoMissed", "true"); //TZ-487
            }
        })
        .catch((error) => {
            printConsole(error)
        })
    }

    toggleMissedCalls = () => {
        this.setState((prevState) => ({
            showMissedCalls:
                !prevState.showMissedCalls
        }), () => {
            this.props.updateShowMissedCallFlag(this.state.showMissedCalls);//TZ-540
            //if (this.state.showMissedCalls === true)
                //this.fetchMissedCallDetails();
        })
    }

    fetchMissedCallDetails = () => {
        let { perPage, } = this.state.callpagination; //TZ-487
        let {fromDate, toDate} = this.state;
        

        this.setState({ isLoading: true });

        let fetchString = 'user_session/getMissedCallAlert';
        let json = {}
        
        if(fromDate !== '' && toDate !== ''){
            json = {
                "start_date": fromDate,
                "end_date": toDate
            }
        }
        

        this.props.authService.fetch(fetchString, {
            method: 'POST',
            body: JSON.stringify(json)
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                //console.log(data);
                /* let recordingUrlExists = false;
                let rec = data.records;
                for (let ii = 0; ii< rec.length; ii++) {
                    if(rec[ii].recording_url && rec[ii].recording_url !== null && rec[ii].recording_url !== ""){
                        recordingUrlExists = true;
                        break;
                    }
                } */
                this.setState({
                    missedCallData: data,
                    isLoading: false,
                    total: data.length,
                    callpageCount: Math.ceil(data.length / perPage),
                }, () => {
                    if (data.length > 0)
                        this.sortMissedCalls("created_on");//TZ-487    
                    else 
                        this.setState({ missedCallCurrentPageData: [] });//TZ-519       
                });
            }
        })
        .catch(err => {
           this.setState({ isLoading: false });
           printConsole(err.message)
        })
    }

    fetchUsageDetails = () => {
        let { perPage, currentPage} = this.state.pagination;
        let {selectedUser, selectedOption, fetchGroupUsage, fromDate, toDate} = this.state;
        let {userid,isadmin} = this.props;
        let groupId = selectedOption && selectedOption.length > 0 ? selectedOption.map(e => e.value) : null;//TP-1315
        let groupUserId = selectedUser && selectedUser.length > 0 ? selectedUser.map(e => e.value) : null;//TP-1315

        this.setState({ isLoading: true });

        let fetchString = 'sessionDetails/';
        fetchString += userid; //by user id
        fetchString += `/${isadmin}`;
        if(fromDate !== '' && toDate !== ''){
            fetchString += `/${fromDate}`;
            fetchString += `/${toDate}`;
        }
        if(fetchGroupUsage){
            fetchString += `/${groupId}`; //by group id
            if(groupUserId !== null){
                fetchString += `/${groupUserId}`; //by group user id
            }
        }
        fetchString += `?perpage=${perPage}&currentpage=${currentPage}`;

        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){
                let recordingUrlExists = false;
                let rec = data.records;
                for (let ii = 0; ii< rec.length; ii++) {
                    if(rec[ii].recording_url && rec[ii].recording_url !== null && rec[ii].recording_url !== ""){
                        recordingUrlExists = true;
                        break;
                    }
                }
                this.setState({
                   usageData: data.records,
                   isLoading: false,
                   total: data.total,
                   pageCount: Math.ceil(data.total / perPage),
                   recordingUrlExists
                });
            }
        })
        .catch(err => {
           this.setState({ isLoading: false });
           printConsole(err.message)
        })
    }

    fetchGroupNames = () => {
        try{
            let accountId = this.props.accountId;
            let userId = this.props.userid;
    
            let fetchString = 'getGroupsByAccountId/';
            fetchString += accountId;
            fetchString += '/';
            fetchString += userId;
    
            this.props.authService.fetch(fetchString, {
                method: 'get'
            })
            .then(response => response.json())
            .then(data => {
                if(data !== undefined && data !== null){
                    data.sort((a, b) => a.group_name.localeCompare(b.group_name))//TP-1576
                    this.setState({ groups: data });
                }
            })
            .catch(err => {
                this.setState({ isLoading: false });
                printConsole(err.message)
            })
        }catch(error){
            printConsole(error)
        }
    }

    fetchGroupUsers = () => {
        try{
            let {selectedOption} = this.state;
            let groupId = selectedOption && selectedOption.length > 0 ? this.state.selectedOption.map(e => e.value) : null;//TP-1315

            let fetchString = 'getusersbygroupid/';
            fetchString += groupId; //by group id

            this.props.authService.fetch(fetchString, {
                method: 'get'
            })
            .then(response => response.json())
            .then(data => {
                //only for expert
                if(!this.props.isadmin){
                    data = data.filter((d) => !d.isexpert || d.user_id === this.props.userid)//TP-1719 all tech and expert himself
                }
                if(data !== undefined && data !== null){
                    data.sort((a, b) => a.first_name.localeCompare(b.first_name))//TP-1576
                    this.setState({ groupUsers: data });
                }
            })
            .catch(err => {
                this.setState({ isLoading: false });
                printConsole(err.message)
            })
        }catch(error){
            printConsole(error)
        }
    }

    fetchAllUsers = () => {
        try{
            let { accountId:account_id, isadmin} = this.props;
    
            let fetchString = isadmin ? 'getusers/' : 'getGroupUsersForExpert';//TP-1719
            if(isadmin){
                fetchString += account_id; //by account id
            }
    
            this.props.authService.fetch(fetchString, {
                method: 'get'
            })
            .then(response => response.json())
            .then(data => {
                if(data !== undefined && data !== null){
                    this.props.updateAllUsers(data); //TP-4352
                    //only for expert
                    if(!isadmin){
                        data = data.filter((d) => !d.isexpert || d.user_id === this.props.userid)//TP-1719 all tech and expert himself
                    }
                    data.sort((a, b) => a.first_name.localeCompare(b.first_name))//TP-1576
                    this.setState({ groupUsers: data });
                }
           })
           .catch(err => {
               this.setState({ isLoading: false });
               printConsole(err.message)
           })
        }catch(error){
            printConsole(error)
        }
    }
    
    handlePaginationClick = data => {
        let selected = data.selected;
        const { pagination } = this.state;
        this.setState({ 
            pagination: {
                ...pagination,
                currentPage: selected + 1
            }
        }, () => {            
                this.fetchUsageDetails();            
        });
    };

    getDateTimeStringforMissedCallTable = (_date,seperator) => {
        let date = _date ? moment(_date) : '';
        if (date !== ''){
            return date.format("DD MMMM YYYY"+seperator+" HH:mm");
        }else{
            return ''
        }
    }

    getDateTimeString = (_date,isStartTime,duration, seperator) => {
        let date = _date ? moment(_date) : '';
        if (date !== ''){
            date = this.roundOffSessionTime(date,isStartTime,duration);
            return date.format("DD MMMM YYYY"+seperator+" HH:mm");
        }else{
            return ''
        }
    }

    getSessionDuration = (startTime,endTime,duration) => {

        if (startTime !== '' && endTime !== ''){
            let minutes, seconds;
            if(duration !== ''){
                seconds = Math.floor(duration/1000);
                minutes = Math.floor(seconds/60);
                // hours = Math.floor(minutes/60);
                
                // minutes = minutes-(hours*60);
                // seconds = seconds-(hours*60*60)-(minutes*60);
                // if(seconds > 0){
                //     minutes = minutes + 1;
                // }
            }else{
                let st = moment(startTime);
                let et = moment(endTime);
                st = this.roundOffSessionTime(st,true);
                et = this.roundOffSessionTime(et,false);
                let timeDiff = moment.duration(et.diff(st));
                // hours = parseInt(timeDiff.asHours());
                // minutes = parseInt(timeDiff.asMinutes())%60;
                minutes = parseInt(timeDiff.asMinutes());
            }
            return (minutes);
        }else{
            return '--';
        }

    }

    roundOffSessionTime = (d,isStartTime,duration) => {
        if(isStartTime){
                d.set({second:0,millisecond:0});
        }else{
            if(d.seconds() > 0){
                if(duration === ''){
                    d.add('minutes', 1);
                }
                d.set({second:0,millisecond:0});
            }
        }
        return d;
    }

    showDataLoader = () => {
        const { isLoading } = this.state;
        if (isLoading) {
            return (
                <div className="loader">
                    <Loader
                        type="ThreeDots"
                        color="#23c97d"
                        height="70"	
                        width="70"
                    />
                </div>
            )
        }
        return '';
    }

    getGroupNameOptions = () => {
        let groups = this.state.groups;
        return groups.map(p => {
            return {
                value: p.groupid,
                label: `${p.group_name}`
            }
        });

    }

    getUserNameOptions = () => {
        let users = this.state.groupUsers;
        return users.map(p => {
            return {
                value: p.user_id || p.id,
                label: `${p.first_name} ${p.last_name}`
            }
        });

    }

    //TP-696   
    handleChange = (selectedOption) => {
        const { pagination } = this.state;
        this.setState({ 
                selectedOption: selectedOption,
                // selectedUser: null,//TP-1325,1326
                fetchGroupUsage: true,
                pagination: {
                    ...pagination,
                    currentPage: 1
                }
            },() => {
            this.fetchUsageDetails();
            if (this.state.selectedOption && this.state.selectedOption.length > 0){
                this.fetchGroupUsers();
            }else{
                this.fetchAllUsers();
            }
        });
    }
    //TP-696
    handleUserChange = (selectedUser) => {
        const { pagination } = this.state;
        this.setState({ 
            selectedUser: selectedUser,
            fetchGroupUsage: true,
            pagination: {
                ...pagination,
                currentPage: 1
            }
        },() => {
        this.fetchUsageDetails();
        });
    }

    convertDate = (str) => {
        let date = str,
            mnth = ("0" + (date.getMonth()+1)).slice(-2),
            day  = ("0" + date.getDate()).slice(-2);
        return [ date.getFullYear(), mnth, day ].join("-");
    }
    
    onfromDateChange = (date) => {
        let fromDate = this.convertDate(date);
        if (this.state.showMissedCalls === false) {
            this.setState(prevState =>({
                fromDate: fromDate,
                unFormatedFromDate: date,
                pagination: {
                    ...prevState.pagination,//TP-2526
                    currentPage: 1
                }
            }),() => {
                if(this.state.fromDate !== '' && this.state.toDate !== ''){                
                    this.fetchUsageDetails();               
                }
            });
        } else {
            //TZ-487
            this.setState(prevState =>({
                fromDate: fromDate,
                unFormatedFromDate: date,
                callpagination: {
                    ...prevState.callpagination,//TP-2526
                    currentPage: 1
                }
            }),() => {
                if(this.state.fromDate !== '' && this.state.toDate !== ''){                
                    this.fetchMissedCallDetails();               
                }
            });
        }
    }

    ontoDateChange = (date) => {
        let toDate = this.convertDate(date);
        if (this.state.showMissedCalls === false) {
            this.setState(prevState => ({
                toDate: toDate,
                unFormatedToDate: date,
                pagination: {
                    ...prevState.pagination,//TP-2526
                    currentPage: 1
                }
            }),() => {
                if(this.state.fromDate !== '' && this.state.toDate !== ''){
                    this.fetchUsageDetails();               
                }
            });
        } else {
            //TZ-487
            this.setState(prevState => ({
                toDate: toDate,
                unFormatedToDate: date,
                callpagination: {
                    ...prevState.callpagination,//TP-2526
                    currentPage: 1
                }
            }),() => {
                if(this.state.fromDate !== '' && this.state.toDate !== ''){
                    this.fetchMissedCallDetails();               
                }
            });
        }
    }

    // onClick of Download as CSV Icon
    downloadAsCsv = async () => {
        var headers = {
            group_name: 'Group',
            first_name: "User First Name",
            last_name: "User Last Name",
            start_time: "Start Time", // (UTC)
            stop_time: "End Time", // (UTC)
            duration: "Duration (hh:mm:ss)",
            credit_id: "License No.",
            is_deleted: "Deleted Group",
            is_user_deleted: "Deleted User"
        };

        let { perPage} = this.state.pagination;
        let {selectedUser, selectedOption, fetchGroupUsage, fromDate, toDate, pageCount} = this.state;
        let {userid,isadmin} = this.props;
        let groupId = selectedOption && selectedOption.length > 0 ? selectedOption.map(e => e.value) : null;//TP-1315
        let groupUserId = selectedUser && selectedUser.length > 0 ? selectedUser.map(e => e.value) : null;//TP-1315

        if (pageCount === 0) {
            printConsole("No data found")
            return;
        }        
        this.setState({ isLoading: true });
        var response = [];
        for(let i=1; i<=pageCount; i++) {
            let fetchString = 'sessionDetails/';
            fetchString += userid; //by user id
            fetchString += `/${isadmin}`;
            if(fromDate !== '' && toDate !== ''){
                fetchString += `/${fromDate}`;
                fetchString += `/${toDate}`;
            }
            if(fetchGroupUsage){
                fetchString += `/${groupId}`; //by group id
                if(groupUserId !== null){
                    fetchString += `/${groupUserId}`; //by group user id
                }
            }
            fetchString += `?perpage=${perPage}&currentpage=${i}`;
    
            this.props.authService.fetch(fetchString, {
                method: 'get'
            })
            .then(response => response.json())
            .then(data => {
                if(data !== undefined && data !== null){
                    if (parseInt(data.total) === 0) {
                        this.setState({ isLoading: false });
                        printConsole("No data found")
                        response = [];
                        return;
                    }
                    response = response.concat(data.records) ;
                    printConsole(response); 
                    if (response.length === parseInt(data.total)) {
                        var itemsFormatted = [];
                        // format the data
                        response.forEach((item) => {
                            const splittedUserName = item.user_name ? item.user_name.split(' ') : null //TP-2141
                            itemsFormatted.push({
                                group_name: item.group_name === null || item.group_name === ''? "Removed": item.group_name,
                                first_name: splittedUserName ? (splittedUserName[0] ? splittedUserName[0] : '') : (item.first_name === null || item.first_name === ''? "Removed": item.first_name), //TP-2141
                                last_name: splittedUserName ? (splittedUserName[1] ? splittedUserName[1] : '') : (item.last_name === null || item.last_name === ''? "Removed": item.last_name), //TP-2141
                                start_time: this.getDateTimeString(item.start_time,true,item.duration," "),
                                stop_time: this.getDateTimeString(item.stop_time,false,item.duration," "),
                                duration: parseMilliseconds(parseInt(item.duration)),
                                credit_id: item.credit_id,
                                is_deleted: item.is_deleted? 'Yes' : 'No',
                                is_user_deleted: item.first_name && item.first_name ? "No" : "Yes"
                            });
                        });
                        const timestamp = new Date().toISOString();

                        var fileTitle = `Usage Details ${timestamp}`; // or 'my-unique-title'

                        exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON
                        this.setState({ isLoading: false });
                    }
                }
           })
           .catch(err => {
               this.setState({ isLoading: false });
               printConsole(err.message)
               response = [];
           })
        }
    }

    //TP-2128
    userName = (row) => {
        if(row.user_name !== null){
            if(row.first_name && row.last_name){
                return row.user_name
            }else{
                return <s className='times tm-regular'>{row.user_name}</s>//TP-2141
            }
        }else if(row.first_name !== null && row.last_name!== null){
            return row.first_name +' '+ row.last_name
        }else{
            return 'Removed'
        }
    }

    //TP-871
    downloadRecordings = ({recording_url}) => {
        try{
            let isJsonData = true, jsonData;
            try {
                jsonData = JSON.parse(recording_url);
            } catch(e) {
                printConsole(`recording_url is not json data`)
                isJsonData = false;
            }
            if(isJsonData){
                printConsole(`recording_url,${jsonData.length}, ${JSON.stringify(jsonData)}`)
                this.props.setRecordingsArray({recordings: jsonData});
                /* for(let i=0; i< jsonData.length; i++){
                    setTimeout(() => {
                        const link = document.createElement('a');
                        link.href = jsonData[i].location;
                        link.click(); link.remove();
                    }, i * 2000);
                } */
            }else{
                const splittedUrl = recording_url.split(',');
                printConsole(`recording_url,${splittedUrl.length}, ${JSON.stringify(splittedUrl)}`)
                this.props.setRecordingsArray({recordings: splittedUrl});
                /* for(let i=0; i< splittedUrl.length; i++){
                    setTimeout(() => {
                        const link = document.createElement('a');
                        link.href = splittedUrl[i];
                        link.click(); link.remove();
                    }, i * 2000);
                } */
            }
        }catch(error){
            printConsole(`error in session recording download ${JSON.stringify(error)}`)
        }
    }

    usageDetails = () => {
        let { isadmin, isexpert, isSmallFormFactor /**TP-2953 */ } = this.props;
        let { selectedOption, disableGroupSelect, selectedUser, disableUserSelect, recordingUrlExists } = this.state;
        let { currentPage, perPage } = this.state.pagination;
        let bodyHeight = window.innerHeight - (25 * (window.innerHeight/100)); //TP-3498
        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected ? 'white' : 'blue'
            })
        }
        return(<>
            <ReactHint autoPosition events />
            <div style={{height: bodyHeight + "px"}} /**TP-3498 */>
                <div style={{marginTop: 20, marginBottom: 15}}>
                    <div className='row'>
                        { isadmin || isexpert ?
                            <>
                                <div className='col-sm-3' style={{color: "black"}}>
                                    <I18n>
                                        {({ i18n }) => 
                                            <Select
                                                isMulti
                                                value={selectedOption}
                                                onChange={this.handleChange}
                                                components={makeAnimated()}
                                                placeholder={this.state.isMobileDevice === true ? i18n._(t`Select Group`) : i18n._(t`Select a Group`)} /**TP-2953 */
                                                options={this.getGroupNameOptions()}
                                                isDisabled={disableGroupSelect}
                                                isClearable={!disableGroupSelect}
                                                styles={customStyles}
                                                isSearchable={isSmallFormFactor === true ? false : !disableGroupSelect} /**TP-2953 */
                                            />
                                        }
                                    </I18n>
                                </div> 
                                <div className='col-sm-3' style={{color: "black"}}>
                                    <I18n>
                                        {({ i18n }) => 
                                            <Select
                                                value={selectedUser}
                                                isMulti
                                                onChange={this.handleUserChange}
                                                components={makeAnimated()}
                                                placeholder={this.state.isMobileDevice === true ? i18n._(t`Select User`) : i18n._(t`Select a User`)} /**TP-2953 */
                                                options={this.getUserNameOptions()}
                                                isDisabled={disableUserSelect}
                                                isClearable={!disableUserSelect}
                                                styles={customStyles}
                                                isSearchable={isSmallFormFactor === true ? false : !disableUserSelect} /**TP-2953*/
                                            />
                                        }
                                    </I18n>
                                </div>                                
                            </>
                    :<span></span>}
                    <div className='col-sm-2'>
                        <DatePicker selected={this.state.unFormatedFromDate} className="custom-date" /**TP-2953 */ onFocus={isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} popperPlacement="top-start" onChange={this.onfromDateChange} locale={getDefaultLocale()} />
                    </div>
                    <div className='col-sm-2'>
                        <DatePicker selected={this.state.unFormatedToDate} className="custom-date" /**TP-2953 */ onFocus={isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} popperPlacement="top-start" onChange={this.ontoDateChange} locale={getDefaultLocale()} />
                    </div>                                       
                    { isadmin && this.props.downloadUsage === "as_csv" ?
                        <a id="Download" className='fa-stack fa-lg pointer' onClick={() => this.downloadAsCsv()}>
                            <i className="fas fa-file-csv fa-lg" ></i>
                            <i className="fas fa-long-arrow-alt-down"></i>
                            { isSmallFormFactor === false ?
                                <UncontrolledTooltip placement="right" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={"Download"}>
                                    {
                                        <Trans>Download as csv</Trans>
                                    }
                                </UncontrolledTooltip>
                                :
                                ''
                            }
                        </a>
                        :
                        ""
                    }
                    { /**TZ-517 TZ-1101*/
                        <div className=''>
                            <button type="button" className="btn-green-medium float-right" onClick={(e) => this.toggleMissedCalls(e)}>
                                <Trans>Show Missed Calls</Trans>
                            </button>
                        </div>                         
                    }
                    </div>
                </div>

                <div className="table-responsive table-container">
                    <table id="usageTable">
                        <thead>
                            <tr className="no-sort">
                                <th>#</th>
                                <th><Trans>Group</Trans></th>
                                {isadmin || isexpert? (<th> <Trans>User</Trans> </th>): null}
                                <th><Trans>Start Time</Trans></th>
                                <th><Trans>End Time</Trans></th>
                                <th><Trans>Duration (mins)</Trans></th>
                                {isadmin? (<th> <Trans>License No.</Trans> </th>): null}
                                {(isexpert) && recordingUrlExists && this.props.customer.screen_recording_enabled && this.props.customer.behaviour !== 'smartvision' ? (<th> <Trans>Recording</Trans> </th>): 
                                    (isadmin) && recordingUrlExists && (this.props.customer.screen_recording_enabled || this.props.customer.enable_silent_recording) && this.props.customer.behaviour !== 'smartvision' ? (<th> <Trans>Recording</Trans> </th>):
                                null}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (this.state.usageData.length > 0) ? 
                                    this.state.usageData.map((row, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{((currentPage-1)*perPage)+(index + 1)}</td>
                                                <td>{row.group_name === null ? <Trans>Removed</Trans> : row.is_deleted ? <s className='times tm-regular'>{row.group_name}</s> : row.group_name}</td>
                                                {isadmin || isexpert? (<td>{this.userName(row)}</td>) : null}
                                                <td>{this.getDateTimeString(row.start_time,true,row.duration,",")}</td>
                                                <td>{this.getDateTimeString(row.stop_time,false,row.duration,",")}</td>
                                                <td>{this.getSessionDuration(row.start_time,row.stop_time,row.duration)}</td>
                                                {isadmin? (<td>{row.credit_id}</td>) : null}
                                                {(isexpert) && recordingUrlExists && this.props.customer.screen_recording_enabled && row.recording_url && row.recording_url !=='' && this.props.customer.behaviour !== 'smartvision'? 
                                                    (<td> 
                                                        <I18n>
                                                            {({ i18n }) =>
                                                                <i className="fas fa-cloud-download-alt recording-url" data-rh={i18n._(t`Download Session Recording`)} onClick={() => this.downloadRecordings({recording_url: row.recording_url})}></i>/**TP-245 */
                                                            }
                                                        </I18n>
                                                    </td>) :
                                                    (isadmin) && recordingUrlExists && (this.props.customer.screen_recording_enabled || this.props.customer.enable_silent_recording) && row.recording_url && row.recording_url !=='' && this.props.customer.behaviour !== 'smartvision' ?
                                                    (<td> 
                                                        <I18n>
                                                            {({ i18n }) =>
                                                                <i className="fas fa-cloud-download-alt recording-url" data-rh={i18n._(t`Download Session Recording`)} onClick={() => this.downloadRecordings({recording_url: row.recording_url})}></i>/**TP-245 */
                                                            }
                                                        </I18n>
                                                    </td>) : (isexpert) && recordingUrlExists && this.props.customer.screen_recording_enabled && this.props.customer.behaviour !== 'smartvision' ? 
                                                        <td></td> : 
                                                        (isadmin) && recordingUrlExists && (this.props.customer.screen_recording_enabled || this.props.customer.enable_silent_recording) && this.props.customer.behaviour !== 'smartvision' ?
                                                        <td></td> : null
                                                }
                                            </tr>
                                        );
                                    })
                                :(
                                    <tr>
                                        <td colSpan={this.props.customer.screen_recording_enabled && this.props.customer.behaviour !== 'smartvision' ?
                                                isadmin? 8:6
                                                :
                                                isadmin? 7:6 //TP-2461
                                            } 
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Trans id={'No data found'}/>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="pagination-wrapper d-flex flex-row justify-content-end">
                    {(isexpert) && recordingUrlExists && this.props.customer.screen_recording_enabled && this.props.customer.behaviour !== 'smartvision' ? (<div className="p-2"><Trans>Remove recording link</Trans></div>): 
                            (isadmin) && recordingUrlExists && (this.props.customer.screen_recording_enabled || this.props.customer.enable_silent_recording) && this.props.customer.behaviour !== 'smartvision' ? (<div className="p-2"><Trans>Remove recording link</Trans></div>):
                        null}
                    <div>
                        <I18n>
                            {({ i18n }) => 
                                <ReactPaginate
                                    previousLabel={i18n._(t`previous`)}
                                    nextLabel={i18n._(t`next`)}
                                    breakLabel={'...'}
                                    breakClassName={'break-me'}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={10}
                                    onPageChange={this.handlePaginationClick}
                                    containerClassName={'pagination'}
                                    pageClassName={'page-item'}
                                    subContainerClassName={'pages pagination'}
                                    pageLinkClassName={'page-link'}
                                    disabledClassName={'disabled'}
                                    activeClassName={'active'}
                                    previousClassName={'page-item'}
                                    nextClassName={'page-item'}
                                    previousLinkClassName={'page-link'}
                                    nextLinkClassName={'page-link'}
                                    breakLinkClassName={'page-link'}
                                    forcePage={this.state.pagination.currentPage -1}
                                />
                            }
                        </I18n>
                    </div>
                </div>

                {this.showDataLoader()}

            </div>
            </>
        );
    }

    openRecordingModal = ({recording_url}) => {
        this.setState((prevState) => ( 
            {showRecording: !prevState.showRecording}
        ), () => {
            if (recording_url !== undefined)
                this.setState({ recording_url });
            else this.setState({ recording_url: null });
        });
    }

    recordingModal = (recording_url) => {
        if (recording_url === null) {
            return null;
        } else {
            /**TZ-521 */
            return <Modal size="md" isOpen={this.state.showRecording} className="modal-edit-card">
                        <ModalHeader className="text-center" toggle={this.openRecordingModal}>
                            <Trans>Voice Note</Trans>
                        </ModalHeader>
                        <ModalBody>
                            <audio autoPlay={true} controls>
                                <source src={recording_url.url} type="audio/ogg" />
                                Your browser does not support the audio tag.
                            </audio>
                        </ModalBody>
                        <ModalFooter></ModalFooter>
                    </Modal>
        }
    }

    //TZ-487
    getCallerName = (row) => {
        if (row.caller_first_name === null || row.caller_last_name === null)
            return "Removed";
        else return row.caller_first_name + " " + row.caller_last_name;
    }
    //TZ-1101
    getRecipientName = (row) => {
        if (row.recipient_first_name === null || row.recipient_last_name === null)
            return "Removed";
        else return row.recipient_first_name + " " + row.recipient_last_name;
    }
    //TZ-487
    sortMissedCalls = (column) => {
        let x,y,shouldSwitch, i, misedCalls;
        const { missedCallData } = this.state;
        if (missedCallData.length > 0) {
            misedCalls = missedCallData.slice();
        }
        
        let switching = true;
        let switchcount = 0;
        ///Set the sorting direction to ascending:
        let dir = 'asc';
        while (switching) {
            switching = false;
            for (i = 0; i < (misedCalls.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                switch (column) {
                    /* case 'date': 
                        x = this.getExpertsCount(misedCalls[i]).toString();
                        y = this.getExpertsCount(misedCalls[i+1]).toString();
                    break;*/
                    case 'callername':
                        x = this.getCallerName(misedCalls[i]);
                        y = this.getCallerName(misedCalls[i+1]);
                    break;
                    case 'recipientname':
                        x = this.getRecipientName(misedCalls[i]);
                        y = this.getRecipientName(misedCalls[i+1]);//TZ-1101
                    break;
                    default:
                        x = misedCalls[i][column];
                        y = misedCalls[i + 1][column];
                }
                
                /*check if the two rows should switch place,
                based on the direction, asc or desc:*/
                  if (dir === 'asc') {
                    if (x.toLowerCase() > y.toLowerCase()) {
                      //if so, mark as a switch and break the loop:
                      shouldSwitch= true;
                      break;
                    }
                  } else if (dir === 'desc') {
                    if (x.toLowerCase() < y.toLowerCase()) {
                      //if so, mark as a switch and break the loop:
                      shouldSwitch = true;
                      break;
                    }
                  }
            }

            if (shouldSwitch) {
              /*If a switch has been marked, make the switch
              and mark that a switch has been done:*/
              misedCalls = swapArrayElements(misedCalls, i + 1, i);
              switching = true;
              //Each time a switch is done, increase this count by 1:
              switchcount ++;      
            } else {
              /*If no switching has been done AND the direction is "asc",
              set the direction to "desc" and run the while loop again.*/
              if (switchcount === 0 && dir === 'asc') {
                dir = "desc";
                switching = true;
              }
            }
        }

        const sortColumn = {
            [column]: dir
        }

        this.setState({
            missedCallData: misedCalls,
            sortColumn: sortColumn
        }, () => {
            let missedCallCurrentPageData = this.getMissedCallsCurrentPage(this.state.missedCallData);
            this.setState({ missedCallCurrentPageData });
        })
        
    }

    onActionClick = async ({ action, row }) => {
        //console.log("Action: ", action);
        printConsole(row); //return;
        const {isexpert} = this.props;
        
        if (action === 'callUser') {
            this.getUserGroup({ action, row, callerName: this.props.adminname});
        }
    }

    //TZ-487
    getMissedCallsCurrentPage = (missedcalldata) => {
        const { perPage, currentPage } = this.state.callpagination;
        let startIndex = Math.ceil(perPage*(currentPage-1));
        let endIndex = Math.ceil(perPage*(currentPage-1)) + perPage;
        let mcallsCurrent = missedcalldata.slice(startIndex, endIndex);
        return mcallsCurrent;
    }
    //TZ-487
    handleCallPaginationClick = (data) => {
        let selected = data.selected;
        const { callpagination } = this.state;
        this.setState({ 
            callpagination: {
                ...callpagination,
                currentPage: selected + 1
            }
        }, () => {
            let missedCallCurrentPageData = this.getMissedCallsCurrentPage(this.state.missedCallData);
            this.setState({ missedCallCurrentPageData });
        });
    };

    missedCallDetails = () => {
        let { isadmin, isexpert, onlineUsers, isSmallFormFactor, isSmartAdmin /**TP-2953 */ } = this.props;
        let { recordingUrlExists, usersInSession, missedCallCurrentPageData /**TZ-487*/ } = this.state;
        let { currentPage, perPage } = this.state.callpagination;
        let bodyHeight = window.innerHeight - (25 * (window.innerHeight/100)); //TP-3498
        const customStyles = {
            option: (provided, state) => ({
              ...provided,
              color: state.isSelected ? 'white' : 'blue'
            })
        }        
        //console.log("usersInSession missed call page array contains ----",usersInSession);
        //console.log(missedCallCurrentPageData);
        return(<>
            <ReactHint autoPosition events />
            <div style={{height: bodyHeight + "px"}} /**TP-3498 */>
                <div style={{marginTop: 20, marginBottom: 15}}>
                    <div className='row'>
                        <span></span>
                    <div className='col-sm-2'>
                        <DatePicker selected={this.state.unFormatedFromDate} className="custom-date" /**TP-2953 */ onFocus={isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} popperPlacement="top-start" onChange={this.onfromDateChange} locale={getDefaultLocale()} />
                    </div>
                    <div className='col-sm-2'>
                        <DatePicker selected={this.state.unFormatedToDate} className="custom-date" /**TP-2953 */ onFocus={isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} popperPlacement="top-start" onChange={this.ontoDateChange} locale={getDefaultLocale()} />
                    </div>                                       
                    <div className='col-sm-3'></div>
                    <div className='col-sm-3'></div>
                    <div className=''>
                        <button type="button" className="btn-green-medium float-right" onClick={(e) => this.toggleMissedCalls(e)}>
                            <Trans>Show Usage Details</Trans>
                        </button>
                    </div> 
                    </div>
                </div>

                <div className="table-responsive table-container">
                    <table id="missedCallTable">
                        <thead>
                            <tr className="no-sort">
                                <th>#</th>
                                <th onClick={() => this.sortMissedCalls('callername')}>
                                    <Trans>Caller Name</Trans>
                                    { this.state.sortColumn.callername !== undefined? <SortIcons direction={this.state.sortColumn.callername} /> : ''} 
                                </th>
                                { /**TZ-1101*/ isadmin ?
                                    <th onClick={() => this.sortMissedCalls('recipientname')}>
                                        <Trans>Recipient Name</Trans>
                                        { this.state.sortColumn.recipientname !== undefined? <SortIcons direction={this.state.sortColumn.recipientname} /> : ''} 
                                    </th>
                                    :
                                    null
                                }
                                <th onClick={() => this.sortMissedCalls('created_on')}>
                                    <Trans>Notified On</Trans>
                                    { this.state.sortColumn.created_on !== undefined? <SortIcons direction={this.state.sortColumn.created_on} /> : ''} 
                                </th>
                                {/*TZ-1101*/this.props.customer.behaviour !== 'smartvision' ? <th><Trans>Voice Note</Trans></th> : null}
                                {/*TZ-1101*/(isexpert || (!isexpert && !isadmin)) && this.props.enable_streaming === true ? <th><Trans>Call user</Trans></th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (missedCallCurrentPageData.length > 0) ? 
                                    missedCallCurrentPageData.map((row, index) => {
                                        const isBusy = usersInSession.includes(row.caller_email);
                                        const isPartOfAGroup = this.state.isPartOfAGroup; //TP-4814
                                        const isOnline = onlineUsers.includes(row.caller_email) && !this.props.noInternet && this.props.isConnected;//MB2-577
                                        const deviceAvailable = this.state.micAvailable || this.state.webcamAvailable;
                                        //console.log(isPartOfAGroup, isOnline, row.caller_email, this.props.adminemailid);
                                        return (
                                            <tr className={((currentPage-1)*perPage)+(index + 1)===1? "first-child-missed-call": ""} key={index}>
                                                <td>{((currentPage-1)*perPage)+(index + 1)}</td>
                                                <td>{row.caller_first_name === null || row.caller_last_name === null ? <Trans>Removed</Trans> : row.caller_first_name + " " + row.caller_last_name}</td>
                                                { /**TZ-1101*/ isadmin ?
                                                    <td>{row.recipient_first_name === null || row.recipient_last_name === null ? <Trans>Removed</Trans> : row.recipient_first_name + " " + row.recipient_last_name}</td>
                                                    :
                                                    null
                                                }
                                                <td>{this.getDateTimeStringforMissedCallTable(row.created_on,",")}</td>
                                                {/*TZ-1101*/ row.recording_url && row.recording_url !=='' && row.recording_url !== null && this.props.customer.behaviour !== 'smartvision'? 
                                                    (<td> 
                                                        <I18n>
                                                            {({ i18n }) =>
                                                                <i className="fas fa-play recording-url" data-rh={i18n._(t`Open Voice note`)} onClick={() => this.openRecordingModal({recording_url: row.recording_url})}></i>/**TP-245 */
                                                            }
                                                        </I18n>
                                                    </td>) 
                                                    : (<td>
                                                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                    </td>)
                                                }
                                                { /*TP-2790 TZ-1101*/ (isexpert || (!isexpert && !isadmin)) && this.props.enable_streaming === true ?
                                                    <td> 
                                                        <I18n>
                                                                {({ i18n }) =>
                                                                   <IconCallUser selectedUser={row.caller_email} /**TP-5277*/ isSmartAdmin={isSmartAdmin} index={index} isBusy={isBusy} isVisible={/*TP-4814*/ isPartOfAGroup && isOnline && (row.caller_email !== this.props.adminemailid)} row={row} onActionClick={this.onActionClick} noInternet={this.props.noInternet} isConnected={this.props.isConnected} deviceAvailable={deviceAvailable} isMonthlyMinutesConsumed={this.props.isMonthlyMinutesConsumed} isExpert={this.props.isexpert } isAdmin={this.props.isadmin} isSmallFormFactor={isSmallFormFactor} /**TP-4327*/ />
                                                                }
                                                        </I18n>
                                                    </td>
                                                    :
                                                    null
                                                }
                                            </tr>
                                        );
                                    })
                                :(
                                    <tr>
                                        <td colSpan={this.props.customer.behaviour !== 'smartvision' ?
                                                isadmin? 8:6
                                                :
                                                isadmin? 7:6 //TP-2461
                                            } 
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Trans id={'No data found'}/>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
                <div className="pagination-wrapper d-flex flex-row justify-content-end">
                        {(isexpert) && recordingUrlExists && this.props.customer.screen_recording_enabled && this.props.customer.behaviour !== 'smartvision' ? (<div className="p-2"><Trans>Remove recording link</Trans></div>): 
                                (isadmin) && recordingUrlExists && (this.props.customer.screen_recording_enabled || this.props.customer.enable_silent_recording) && this.props.customer.behaviour !== 'smartvision' ? (<div className="p-2"><Trans>Remove recording link</Trans></div>):
                            null}
                        <div>
                            <I18n>
                                {({ i18n }) => 
                                    <ReactPaginate
                                        previousLabel={i18n._(t`previous`)}
                                        nextLabel={i18n._(t`next`)}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.callpageCount} //TZ-487
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={10}
                                        onPageChange={this.handleCallPaginationClick} //TZ-487
                                        containerClassName={'pagination'}
                                        pageClassName={'page-item'}
                                        subContainerClassName={'pages pagination'}
                                        pageLinkClassName={'page-link'}
                                        disabledClassName={'disabled'}
                                        activeClassName={'active'}
                                        previousClassName={'page-item'}
                                        nextClassName={'page-item'}
                                        previousLinkClassName={'page-link'}
                                        nextLinkClassName={'page-link'}
                                        breakLinkClassName={'page-link'}
                                        forcePage={this.state.callpagination.currentPage -1} //TZ-487
                                    />
                                }
                            </I18n>
                        </div>
                </div>

                {this.showDataLoader()}

            </div>
            </>
        );
    }

    getSessionTokenForSingleGroup = (groupData, row, action) => {
        const {isexpert} = this.props;
        let groupId;
        groupId = groupData[0].groupid;
        let fetchString = 'sessiondataH364?groupID=';
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.userid;
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            return response.json();
        })
        .then((data) => {            
            if (this.props.log_level >= 3) {
                diagnostics(this.props.authService,{
                    action: `sessionData`,
                    sequence: 3,
                    next_step: '',
                    data: data,
                    error: 'none'
                }) 
            }
            printConsole(data);
            this.props.loadSessionInfo(data);
            if (isexpert) {
                this.props.updateCallDialogStatus(true); //TP-5547
                this.setState({ selectedUser:row }); /**TP-5277	*/
                this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), groupId }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                this.props.setGroupUsersList(groupId);//TP-1735
                // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
                let {usersInSession} = this.state;
                usersInSession.push(row.caller_email);
                this.setState({usersInSession});
                this.props.setUsersInSession(usersInSession);//TP-1599
                this.props.authService.socket.emit('connect-the-call', { recipientEmail: row.caller_email, callerName: this.props.adminname, isCancelled: false, source: "user" });
                this.props.isUserCall(true); //TP-5036
                this.props.setCalleeList(null);//TP-2381                
                this.setState({ mode: action, selectedGroupId:groupId, userCallResponse: '' }) //TP-5277
            } else {
                // Technician handling                 
            }

        })
        .catch((error) => {
            printConsole(error)
            if (this.props.log_level >= 3) {
                diagonastics(this.props.authService,{
                    action: `sessiondataH364 has returned error`,
                    sequence: 1,
                    next_step: '',
                    data: `groupID: ${groupId}, loggedInUserId: ${this.props.userid}`,
                    error: `error code: ${error.status}`
                }) 
            }
            // output error in sessiondata
            if(error.status === 444) {
                this.setState({mode: 'message', errStrReturned: i18nMark('Establishing the live call failed due to a server error (444). Please contact Telepresenz support if this problem persists.')});
            } else {
                this.setState({mode: 'message', errStrReturned: i18nMark('The call could not proceed due to some issues.')});
            }
        })
    }

    getSessionTokenForMultiGroup = (newVal) => {
        let groupId;
        const {selectedUser, selectedAction} = this.state;
        const {isexpert} = this.props;
        groupId = newVal.value;
        let fetchString = 'sessiondataH364?groupID=';
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.userid;
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            return response.json();
        })
        .then((data) => {
            /* if(!data.isongoingnow){
                const selectedGroup = { groupid: newVal.value };
                this.setPrimaryExpertIDApi(selectedGroup);
            } */
            printConsole(data);
            this.props.loadSessionInfo(data);
            if (isexpert) {
                this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), groupId }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                this.props.setGroupUsersList(groupId);//TP-1735
                // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
                let {usersInSession} = this.state;
                usersInSession.push(selectedUser.caller_email);
                this.setState({usersInSession});
                this.props.setUsersInSession(usersInSession);//TP-1599
                this.props.updateCallDialogStatus(true);
                this.props.authService.socket.emit('connect-the-call', { 
                    recipientEmail: selectedUser.caller_email, 
                    callerName: this.props.adminname, 
                    isCancelled: false,
                    group_id: newVal.value,
                    source: "user"
                });
                this.props.isUserCall(true); //TP-5036
                this.props.setCalleeList(null);//TP-2381
                this.props.updateCallDialogStatus(true);
            }
            else {
                // Technician Handling                
            }
        })
        .catch((error) => {
            printConsole(error)
        })
    }

    checkAudioAvailable = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({
                video: false,
                audio: true
            })
            .then((stream) => {
                stream.getTracks().forEach(track => track.stop());
                resolve(true);
            })
            .catch(err => {
                console.log('cannot access microphone');
                console.log(`Device detection Error :: ${err}`);
                resolve(false)
            });
        });
    }

    // MB2- 459
    checkVideoAvialble = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            })
            .then((stream) => {
                stream.getTracks().forEach(track => track.stop());
                resolve(true);
            })
            .catch(err => {
                console.log('cannot access camera');
                console.log(`Device detection Error :: ${err}`);
                resolve(false)
            });
        });
    };

    getFilteredGroupsForLoggedinUser = (data) => {
        const {groupsData, isexpert, isadmin, userid} = this.props;
        let groupsList = groupsData;
        //console.log(groupsList);
        if(isadmin && isexpert) {
            groupsList = groupsData.map(grp => {
                let [ggg] = grp.users.filter(u => u.value === userid)
                if (ggg !== undefined) 
                    return grp;
            })
            groupsList = groupsList.filter(grp => grp !== undefined)
        }
        //console.log(groupsList);
        let updatedData = [];
        groupsList.forEach(grp => {
            let [tempGroup] = data.filter(d => d.groupid === grp.groupid);
            if (tempGroup !== undefined)
                updatedData.push(tempGroup); 
        })
        //console.log("updtaed group list ::",updatedData);
        return updatedData;
    }

    getUserGroup = ({action, row}) => {
        const {isexpert, session_type} = this.props; //TP-2539
        if(row && row.caller_email){
            this.props.authService.fetch(`getgroups/${row.caller_email}`, {
                method: 'get'
            })
            .then(response => {
                return response.json();
            })
            .then(async (data) => {
                if (this.props.log_level >= 3) {
                    diagonastics(this.props.authService,{
                        action: `clicked on call user`,
                        sequence: 1,
                        next_step: '',
                        data: ``,
                        error: 'none'
                    })
                }
                // don't show pop up when there is one matching group
                if (data.length === 1){
                    // if expert then first get the session token and then send the signal-UQ3-186
                    if(!isexpert){
                        this.setState({selectedGroupId: data[0].groupid});//TP-4460
                        const cameraAvailable = await this.checkVideoAvialble();
                        if (!cameraAvailable){
                            this.setState({ mode: 'message', errStrReturned: i18nMark('DevicesNotFoundError')});//MB2-459
                            return false;
                        }
                        this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                        // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
                        let {usersInSession} = this.state;
                        usersInSession.push(row.caller_email);
                        this.setState({usersInSession});
                        this.props.setUsersInSession(usersInSession); //TP-1599
                        this.props.authService.socket.emit('connect-the-call', { recipientEmail: row.caller_email, callerName: this.props.adminname, isCancelled: false });
                        this.props.isUserCall(true); //TP-5036
                        this.props.setCalleeList(null);//TP-2381
                        this.props.updateCallDialogStatus(true);
                        this.setState({ mode: action, selectedUser: row, groups: data, userCallResponse: ''})
                    }/**TP-3419 & TP-3119 & TP-2539 & TP-3156 */ else if (isexpert && (session_type === "CM" || (session_type === "RM" && this.props.myCameraOn() === true))) {
                        const cameraAvailable = await this.checkVideoAvialble();
                        if (!cameraAvailable){
                            this.props.setCameraResolution(0, 0); //TP-5429
                            //this.setState({bResponded: true, errStrReturned: i18nMark('DevicesNotFoundError')})// MB2-459
                            //return false;
                        }
                        //TP-5429 -- Only check if mic is connected or not
                        const micAvailable = await this.checkAudioAvailable();
                        if (!micAvailable) {
                            this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                            return false;
                        }
                        this.getSessionTokenForSingleGroup(data, row, action)
                    } else {
                        //TP-3156 & TP-614
                        const micAvailable = await this.checkAudioAvailable();
                        if (!micAvailable) {
                            this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                            return false;
                        }
                        this.getSessionTokenForSingleGroup(data, row, action)
                    }
                }else{
                    let updatedData = this.getFilteredGroupsForLoggedinUser(data);//TP-2493
                    //TP-4751 -- don't show pop up when there is one updated group
                    if (updatedData.length === 1){
                        // if expert then first get the session token and then send the signal-UQ3-186
                        if(!isexpert){
                            this.setState({selectedGroupId: updatedData[0].groupid});//TP-4460
                            const cameraAvailable = await this.checkVideoAvialble();
                            if (!cameraAvailable){
                                this.setState({ mode: 'message', errStrReturned: i18nMark('DevicesNotFoundError')});//MB2-459
                                return false;
                            }
                            this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                            // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
                            let {usersInSession} = this.state;
                            usersInSession.push(row.caller_email);
                            this.setState({usersInSession});
                            this.props.setUsersInSession(usersInSession); //TP-1599
                            this.props.authService.socket.emit('connect-the-call', { recipientEmail: row.caller_email, callerName: this.props.adminname, isCancelled: false });
                            this.props.isUserCall(true); //TP-5036
                            this.props.setCalleeList(null);//TP-2381
                            this.props.updateCallDialogStatus(true);
                            this.setState({ mode: action, selectedUser: row, groups: updatedData, userCallResponse: ''})
                        }/**TP-3419 & TP-3119 & TP-2539 & TP-3156 */ else if (isexpert && (session_type === "CM" || (session_type === "RM" && this.props.myCameraOn() === true))) {
                            const cameraAvailable = await this.checkVideoAvialble();
                            if (!cameraAvailable){
                                this.props.setCameraResolution(0, 0);//TP-5429
                                //this.setState({bResponded: true, errStrReturned: i18nMark('DevicesNotFoundError')})// MB2-459
                                //return false;
                            }
                            //TP-5429 -- Only check if mic is connected or not
                            const micAvailable = await this.checkAudioAvailable();
                            if (!micAvailable) {
                                this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                                return false;
                            }
                            this.getSessionTokenForSingleGroup(updatedData, row, action)
                        } else {
                            //TP-3156 & TP-614
                            const micAvailable = await this.checkAudioAvailable();
                            if (!micAvailable) {
                                this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                                return false;
                            }
                            this.getSessionTokenForSingleGroup(updatedData, row, action)
                        }
                    } else {
                        this.setState({
                            groups: updatedData,/**TP-2493 */
                            toggleCallDialog: true,
                            selectedUser: row,
                            selectedAction: action
                        });
                    }
                }
            })
            .catch((error) => {
                printConsole(error)
            })
        }else{
            printConsole('No email of user')
        }
    }

    handleOnChangecalleGroup = async (newVal) => {
        const {selectedUser, selectedAction} = this.state;
        const {isexpert, session_type} = this.props;
        // if expert then first get the session token and then send the signal-UQ3-186
        if (/*TP-3419*/ isexpert && (session_type === "CM" || (session_type === "RM" && this.props.myCameraOn() === true))) {
            //TP-3156
            const cameraAvailable = await this.checkVideoAvialble();
            if (!cameraAvailable){
                this.props.setCameraResolution(0, 0); //TP-5429
                // Close the Group selection box and show the Camera detection error messages
                /* this.setState({
                    bResponded: true, 
                    errStrReturned: i18nMark('DevicesNotFoundError'),
                    selectedGroupId: newVal.value,
                    toggleCallDialog:false
                }) */// TP-580
                //return false;
            }
            //TP-5429 -- Only check if mic is connected or not
            const micAvailable = await this.checkAudioAvailable();
            if (!micAvailable) {
                this.setState({ 
                    bResponded: true, 
                    errStrReturned: i18nMark('MicrophoneNotFoundError'),
                    selectedGroupId: newVal.value,
                    toggleCallDialog:false
                })// TP-580
                return false;
            }
            this.getSessionTokenForMultiGroup(newVal);
        } else if(isexpert){
            /* if (newVal.is_onprem === false) {
                const cameraAvailable = await this.checkVideoAvialble();
                if (!cameraAvailable){
                    // Close the Group selection box and show the Camera detection error messages
                    this.setState({
                        bResponded: true, 
                        errStrReturned: i18nMark('DevicesNotFoundError'),
                        selectedGroupId: newVal.value,
                        toggleCallDialog:false
                    })// TP-580
                    return false;
                }
            } else { */
                //TP-614
                const micAvailable = await this.checkAudioAvailable();
                if (!micAvailable) {
                    this.setState({ 
                        bResponded: true, 
                        errStrReturned: i18nMark('MicrophoneNotFoundError'),
                        selectedGroupId: newVal.value,
                        toggleCallDialog:false
                    })// TP-580
                    return false;
                }
            //}
            this.getSessionTokenForMultiGroup(newVal);
        }else{
            const cameraAvailable = await this.checkVideoAvialble();
            if (!cameraAvailable){
                    this.setState({ mode: 'message', errStrReturned: i18nMark('DevicesNotFoundError')});//MB2-459
                    this.toggleCallUser();
                    return false;
            }
            this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`) }), window._env_.REACT_APP_API_CALL_TIME_OUT);
            // TP-1572 -- Setting the isBusy flag for the recipient user to whom the new connect-the-call signal is triggered
            let {usersInSession} = this.state;
            usersInSession.push(selectedUser.caller_email);
            this.setState({usersInSession});
            this.props.setUsersInSession(usersInSession);//TP-1599
            this.props.authService.socket.emit('connect-the-call', { 
                recipientEmail: selectedUser.caller_email, 
                callerName: this.props.adminname, 
                isCancelled: false,
                group_id: newVal.value
            });
            this.props.isUserCall(true); //TP-5036
            this.props.setCalleeList(null);//TP-2381
        }
        this.props.updateCallDialogStatus(true);
        this.setState({ 
            selectedGroupId: newVal.value,
            mode: selectedAction,
            toggleCallDialog:false,
            userCallResponse: ''
        });
    }

    callUserDialog = (props) => {
        
            return(
                <Modal isOpen={this.state.toggleCallDialog} toggle={this.toggleCallUser}>
                    <ModalHeader toggle={this.toggleCallUser}><Trans>Select group</Trans></ModalHeader>
                    <ModalBody>
                        <I18n>
                            {({ i18n }) =>
                                <Select
                                    value={this.state.selectedGroup}
                                    components={makeAnimated()}
                                    onChange={this.handleOnChangecalleGroup}
                                    placeholder={i18n._(t`Select a group to call`)}
                                    options={this.calleGroupOptions()}
                                    isSearchable={props.isSmallFormFactor === true? false : true} /**TP-2953 */
                                />                 
                            }
                        </I18n>  
                    </ModalBody>
                </Modal>
            )
        
    }

    toggleCallUser = () => {
        this.setState((prev) => (
            { toggleCallDialog : !prev.toggleCallDialog}
        ))
    }

    calleGroupOptions = () => {
        const {groups} = this.state;
        return groups.map(group => {
            return {
               value: group.groupid,
               label: group.group_name,
               is_onprem: group.is_onprem
            }
        }) 
    }

    clearSessionToken = (groupId) => {
        this.props.authService.fetch('updateGroupToken', {
            method: 'post',
            body: JSON.stringify({
                groupId
            })
        })
        .then(response => response.json())
        .then(data => {
            console.info('token cleared from users tab')
        })
        .catch(err => console.error(err.message))
    }

    cancelCall = ({ mode = 'view', msg = '', groupId= '' }) => {
        const {isexpert} = this.props;
        if (this.props.log_level >= 3) {
            diagonastics(this.props.authService,{
                action: `cancelling the call`,
                sequence: 3,
                next_step: '',
                data: msg,
                error: 'none'
            })
        }
        if (this.timer) clearTimeout(this.timer);
        this.setState({
            mode: mode === "declinedUser" ? "callUser": mode, /**TP-6103*/
            isLoading: false,
            userCallResponse: msg
        }, () => {
            this.props.updateCallDialogStatus(false);
        })
        //TP-1572 -- Resetting the Call Icon for the user whose call has timed out/cancelled
        let {usersInSession} = this.state;
        usersInSession = usersInSession.filter(e => e !== this.state.selectedUser.caller_email);
        this.setState({usersInSession});
        this.props.setUsersInSession(usersInSession);
        this.props.authService.socket.emit('connect-the-call', { recipientEmail: this.state.selectedUser.caller_email, isCancelled: true, callerName: this.props.adminname, source: "user" });
        this.props.isUserCall(true); //TP-5036
        this.props.setCalleeList(null);//TP-2381
        const isPassive = this.isAnotherExpertInSession(groupId) //TP-1550
        if (!isPassive && isexpert && groupId !== '') {
            this.clearSessionToken(groupId)
        }
        // TP-1129 -- Sending Missed Call Notification to all online users 
        // of a Group call if the call is timeout and no one accepts/declines the call
        if (mode === "callUser" || mode === "timeoutCall" /**TP-5891*/) {
            this.props.authService.socket.emit('send-missed-call-notification', { 
                emails: [this.state.selectedUser.caller_email]
            });
        }
    }

    closeTimeoutDialog = () => {
        this.setState({
            mode: 'view',
            isLoading: false,
            userCallResponse: ''            
        }, () => {
            this.props.updateCallDialogStatus(false); 
        })
    }

    renderSwitch = (mode) =>{
        switch (mode) {
            case 'callUser':
                //TP-4228 -- Close out the dialog box automatically after 30 seconds, when response has come
                if (this.state.userCallResponse) {
                    this.dialogTimer = setTimeout(() => {
                        this.cancelCall({ mode : 'view', msg : '', groupId: '' });
                        clearTimeout(this.dialogTimer);
                    }, 30000);
                }
                return <Modal size="sm" isOpen={true} className="call-notification-toast">
                    <ModalHeader className="text-center">
                        { this.state.userCallResponse ? <Trans>Call failed</Trans> : <Trans id="Calling {name}" values={{'name': `${this.state.selectedUser.caller_first_name} ${this.state.selectedUser.caller_last_name}`}}></Trans> }
                    </ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center">
                    {this.state.userCallResponse ? '' : <Spinner className="align-self-center mr-2" type="grow" size="sm" />}
                    {
                        this.state.userCallResponse ?
                        <Trans id={this.state.userCallResponse} values={{recipient: `${this.state.selectedUser.caller_first_name} ${this.state.selectedUser.caller_last_name}`}}></Trans>:
                        <>
                            <Trans id="Calling {name}" values={{'name': `${this.state.selectedUser.caller_first_name} ${this.state.selectedUser.caller_last_name}`}}></Trans>
                            {
                                !this.state.isIOS ?
                                    <audio autoPlay="true" loop="true" src="/incoming_call.mp3"/>
                                : ''
                            }
                        </>
                    }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <BButton size="sm" onClick={this.cancelCall}><Trans>Cancel</Trans></BButton>
                        </div>
                    </ModalFooter>
                </Modal>
            case 'timeoutCall': /**TP-1550 */
                return <Modal size="sm" isOpen={true} className="call-notification-toast">
                    <ModalHeader className="text-center">
                        { this.state.userCallResponse ? <Trans>Call failed</Trans> : '' }
                    </ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center">                    
                    {
                        this.state.userCallResponse ?
                            <Trans id={this.state.userCallResponse} values={{recipient: `${this.state.selectedUser.caller_first_name} ${this.state.selectedUser.caller_last_name}`}}></Trans>
                        :
                        ''
                    }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <BButton size="sm" onClick={this.closeTimeoutDialog}><Trans>Close</Trans></BButton>
                        </div>
                    </ModalFooter>
                </Modal>
        
            default:
                break;
        }
    }

    render(){
        const { showMissedCalls, recording_url, mode } = this.state;
        
        if (showMissedCalls === false) {
            return this.usageDetails();
        } else {
            return (
                <>
                {this.missedCallDetails()}
                {this.recordingModal(recording_url)}   
                {this.callUserDialog(this.props)}    
                {this.renderSwitch(mode)}          
                </>
            ) 
                
            
        }
    }
    
}

const IconCallUser = (props) => (
    // props.isVisible already is taking care of props.noInternet so changing the below condition for props.isMonthlyMinutesConsumed//T32-462
    <>
        {props.isVisible && props.isSmartAdmin === false ? 
            <>
                {   (!props.isMonthlyMinutesConsumed && props.deviceAvailable)?                        
                        /*TP-4228*/props.isExpert || props.isAdmin ? 
                            <>
                                    <span id={"icon-phone-" + props.index} className={" pointer " + (props.isBusy ? "text-danger disabled" : "")} /**TZ-518*/ onClick={() => !props.isBusy && props.onActionClick({ action: 'callUser', row: props.row })}>
                                        <i className={props.isBusy ? "fas fa-phone-slash" : "fas fa-phone"}></i>
                                    </span>
                                    { props.isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="top"  target={"icon-phone-" + props.index}>
                                            {
                                                !props.isBusy ? <Trans>Call</Trans> : /*TP-5277, TZ-534 */ 
                                                <Trans>User is already on a call with another user, please try again later.</Trans>
                                            }
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                            </>
                                    :
                            <>
                                {/**TP-4228*/}
                                    <span id={"icon-phone-" + props.index} className=" pointer" /**TZ-518*/ onClick={() => props.onActionClick({ action: 'callUser', row: props.row })}>
                                        <i className="fas fa-phone"></i>
                                    </span>
                                    { props.isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="top"  target={"icon-phone-" + props.index}>
                                            {
                                                <Trans>Call</Trans>
                                            }
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                            </>
                        
                        :
                            <>
                                <span id={"icon-phone-" + props.index} className="" /**TZ-518*/>
                                    <i className="fas fa-phone fa-video-disabled"></i>
                                </span>
                                { props.isSmallFormFactor === false ?
                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'icon-phone-' + props.index}>
                                        {
                                            props.deviceAvailable ? <Trans>Monthly minutes over</Trans> :
                                            (props.isExpert) ? 
                                                <Trans>MicrophoneNotFoundError</Trans>
                                                :
                                                <Trans>DevicesNotFoundError</Trans>
                                        }
                                    </UncontrolledTooltip>
                                    :
                                    ''
                                }
                            </>
                }
            </>
        :
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
        }
    </>
);

export default UsageDetails;