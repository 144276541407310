import React, { Fragment } from 'react';
import './Register.scss';
import SimpleReactValidator from 'simple-react-validator';
import Loader from 'react-loader-spinner';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";

class Register extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			bSubmitted: false,
			errStrReturned: '',
			registerFirstName: '',
			registerLastName: '',
			//registerRole: '',
			registerEmail: '',
			registerPassword: '',
			repeatPassword:'',
            isError: false,
            isLoading: false
		}
		// initialize validator
        this.validationRules(props.language);
	}

    componentDidUpdate = (prevProps, prevState) =>{
        if (prevState.language !== this.state.language) {
            this.validationRules(this.state.language)
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.language!==prevState.language){
           
          return {language : nextProps.language};
        }
        else return null;
    }

    validationRules = (language) => {
        SimpleReactValidator.addLocale(language, require(`../../locales/${language}/messages.json`));
		//TP-6467
		this.validator = new SimpleReactValidator({locale: language,
			element: message => <div className="register-form-validation-msg">{message.replace(/The|field/g, '').replace(/repeatpassword/g, 'repeat password').capitalize()}</div>,
			validators: {
				confirmPassword: {  // name the rule
					message: 'Password and Confirm Password do not match.',
					rule: (val, params, validator) => {
						return val === this.state.registerPassword ? true : false; 
					},
					required: true  // optional
                },
                emailValidate: {  // name the rule
					message: 'Please enter a valid email address',
					rule: (val, params, validator) => {
						const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return validator.helpers.testRegex(
							String(val).toLowerCase(),
							regex
						);
					},
					required: true  // optional
				}
			}
		});
    }

	getInviteCode = () =>{
		let strCode = this.props.inviteeURL;
		let index = strCode.search('selfregister');
		index += 13;
		let strInviteCode = strCode.slice(index)
		let strURI = encodeURIComponent(strInviteCode);
		return strURI;
	}

	componentDidMount = () => {
		if(this.props.inviteeURL.length > 0)
		{
			let fetchString = window._env_.REACT_APP_API_URL + '/selfregisterinvitee/';
			fetchString += this.getInviteCode();
			if (!this.getInviteCode().length) {
				this.setState({
					isError: true,
					errStrReturned: i18nMark('Invite Code Not found!')
				});
				return;
			}
			this.setState({isError: false, isLoading: true});
			fetch(fetchString, {
				method: 'get',
				headers: {'Content-Type': 'application/json'},
			})
			.then(response => {
                if (!response.ok) throw Error();
                return response.json();
            })
			.then(invitees => {
				if(!invitees.length){
					return;
				}
				//TP-6040
				if (invitees[0].isclicked && invitees[0].verified !== "mobile") {
					this.setState({
                        isError: true,
                        isLoading: false,
						errStrReturned: i18nMark('You have already registered!')
					})
					return;
				}
				this.setState({registerFirstName: invitees[0].first_name, isLoading: false});
				this.setState({registerLastName: invitees[0].last_name});
				this.setState({registerEmail: invitees[0].email});
				//document.getElementById("register_form").reset();

				let element = document.getElementById("firstname");
				if(element !== null){
					element.value = this.state.registerFirstName;
					element.disabled = true;
				}
				element = document.getElementById("lastname");
				if(element !== null){
					element.value = this.state.registerLastName;
					element.disabled = true;
				}
				element = document.getElementById("email-address");
				if(element !== null){
					element.value = this.state.registerEmail;
					element.disabled = true;
				}

			})
			.catch(err => {
                this.setState({
                    isError: true,
                    isLoading: false,
                    errStrReturned: i18nMark('The passed invite code does not match with any outstanding invitations!')
                })
            })			
		}
    }
    
    showLoader = () => {
        if (!this.state.isLoading) return '';
        return (
            <div className="loader">
                <Loader
                    type="ThreeDots"
                    color="#23c97d"
                    height="70"	
                    width="70"
                />
            </div>
        )
    }

	onSubmitRegister = (e) => {
		e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
		this.setState({bSubmitted: true});
		fetch(window._env_.REACT_APP_API_URL + '/register', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				firstname: this.state.registerFirstName,
				lastname: this.state.registerLastName,
				email: this.state.registerEmail,
				password: this.state.registerPassword
			})
		})
		.then(user => {
			this.setState({ retCode: 200 });
			this.setState({errStrReturned: i18nMark('You have successfully registered on Telepresenz. You will receive an email shortly providing you with login details.')})
			if(user && user.email){
				this.props.onRegistrationComplete();
				//this.props.loadUser(user);
				this.props.onRouteChange('signin');
			}
		})
		.catch((error) => {
			// output error in login
			if(this.state.retCode === 409){
				this.setState({errStrReturned: i18nMark('There is already a user registered with these details. Please resubmit with a different name.')});
			} else if(this.state.retCode === 404){
				this.setState({errStrReturned: i18nMark('New user registration failed since this feature is not supported.')});
			} else{
				this.setState({errStrReturned: i18nMark('Failed to add the new user. Please review the entries and resubmit again.')});
			}
		})
	}

	onClickClose(e) {
		e.preventDefault();
		this.props.onRegistrationComplete();
		window.history.pushState(null, null, '/');
		this.props.onRouteChange('signout');
		// TODO: Sould be removed after implementing 'React Router'
		// window.location='/';
	}

    focusNextField = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13) {
            let registerForm = e.target.form;
            let index = Array.prototype.indexOf.call(registerForm, e.target);
            registerForm.elements[index + 1].focus();
            e.preventDefault();
        }
	}
	
	focusOnRegister = (e) => {
		var code = e.keyCode || e.which;
        if(code === 13) {
			this.onSubmitRegister(e);
		}
	}

	onChangeForm = (name) => (event)=> {
		if(name === 'firstname')
			this.setState({registerFirstNameregisterLastNameregisterEmail: event.target.value})
		if(name === 'lastname')
			this.setState({registerLastNameregisterEmail: event.target.value})
		if(name === 'email-address')
			this.setState({registerEmail: event.target.value})
		if(name === 'password')
			this.setState({registerPassword: event.target.value})
		if(name === 'repeatpassword')
			this.setState({repeatPassword: event.target.value})
	
		// if(this.state.registerPassword && this.state.registerPassword !== '' &&
		// 	this.state.repeatpassword && this.state.repeatpassword !== '')
		// 	document.getElementById('btnRegister').style.display = 'none';
		// else
		// 	document.getElementById('btnRegister').style.display = 'block';		
	}

	render(){
		if(this.state.bSubmitted || this.state.isError) {
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
				<div className='modale opened'>
					<div className='__modal-dialog'>
						<form>
							<div className="__modal-header">
								<h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}></Trans></h4>
							</div>
							<div className="__modal-footer flex-center">
								<button className="btn-green" onClick={(e) =>this.onClickClose(e)}><Trans id='OK'/></button>
							</div>
						</form>
					</div>
				</div>
			);
		} else {
			return(
                <Fragment>
                { this.showLoader() }
				<div className='modale opened'>
					<div className='__modal-dialog'>
						<form>
							<div className="__modal-header default-blue">
								<h2><Trans id='Self-Registration'/></h2>
							</div>
							<div className="__modal-body">
								{/* <label>First Name:
									<div className="input-wrapper">
										<input className='signup-input-field' id="firstname" name="firstname" type="text" value={this.state.registerFirstName} onChange={this.onChangeForm.bind(this)} required/>
										{this.validator.message('firstname', this.state.registerFirstName, 'required')}		
									</div>
								</label>
								<label>Last Name:
									<div className="input-wrapper">
										<input className='signup-input-field' id="lastname" name="lastname" type="text" value={this.state.registerLastName} onChange={this.onChangeForm.bind(this)} required/>
										{this.validator.message('lastname', this.state.registerLastName, 'required')}		
									</div>
								</label> */}
								<label><Trans id='Registered Email' />:
									<div className="input-wrapper">
										<input className='signup-input-field' id="email-address" name="email-address" type="text" value={this.state.registerEmail} onChange={this.onChangeForm('email-address')} required/>
										{this.validator.message('email-address', this.state.registerEmail, 'required|emailValidate')}		
									</div>
								</label>
								<label><Trans id='New Password' />:
									<div className="input-wrapper">
                                        <I18n>
                                            {({ i18n }) =>
                                            <>
                                                <input className='signup-input-field' name={i18n._(t`password`)} type="password" placeholder={i18n._(t`Enter your new password here`)} autoFocus={true} value={this.state.registerPassword} onChange={this.onChangeForm('password')} onKeyPress={this.focusNextField} required/>
										        {this.validator.message(i18n._(t`password`), this.state.registerPassword, 'required')}	
                                            </>	
                                            }
                                        </I18n>
									</div>
								</label>
								<label><Trans id='Re-enter Password' />:
									<div className="input-wrapper">
                                        <I18n>
                                            {({ i18n }) =>
                                            <>
                                                <input className='signup-input-field margin-bottom-0' name={i18n._(t`repeatpassword`)} type="password" placeholder={i18n._(t`Re-enter the new password here`)} value={this.state.repeatPassword} onChange={this.onChangeForm('repeatpassword')}  onKeyPress={this.focusNextField} required/>
                                                {this.validator.message(i18n._(t`repeatpassword`), this.state.repeatPassword, 'required|confirmPassword')}										
                                            </>
                                            }
                                        </I18n>
									</div>
								</label>
							</div>	
							<div className="__modal-footer flex-center">
								<button id='btnRegister' className="btn-register" onClick={(e) =>this.onSubmitRegister(e)}  onKeyPress={this.focusOnRegister}><Trans id='Register' /></button>
							</div>
						</form>
					</div>
				</div>
                </Fragment>
			);
		}
	}

}

export default Register;