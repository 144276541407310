import React, { Fragment } from 'react';
import { Row, Col, Label, CustomInput, ModalBody, Modal, ModalHeader, ModalFooter, Input, FormGroup } from 'reactstrap';
import Button from '../Button/Button';
import SimpleReactValidator from 'simple-react-validator';
import MessageModal from '../MessageModal/MessageModal';
import {EnterprisePurchaseDetails} from './EnterpriseRequestForm';
import { Trans } from '@lingui/macro';
import { i18nMark } from "@lingui/react";
import {uploadFile} from 'react-s3';
const fileDialog = require('file-dialog');
const AWS = require('aws-sdk');
AWS.config.update({
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
});
const s3 = new AWS.S3();//instantiate s3
export class ModifyEnterprisRequestForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            company_name: '',
            amount: 0,
            quantity: 0,
            monthly_minutes: 0,
            overage_cost: 0,
            audio_mode: {
                value: 'expert2Tech',
                label: 'Expert to Tech'
            },
            audioModes: [{
                value: 'expert2Tech',
                label: 'Expert to Tech'
            },{
                value: 'tech2Tech',
                label: 'Tech to Tech'
            }],
            number_of_experts: 1,
            isLoading: false,
            message: '',
            isError: false,
            isEmailAlreadyRegistered: false,
            start_date: this.convertDate(new Date()),
            end_date: '',
            isActive: false,
            company_code: '',
            min_licences: 0,
            language: '',
            is_customizable: false,
            is_gps_enabled: false,
            is_offline_jobs_enabled: false,
            assign_jobs_to_all_users: false,
            asset_scan: false,
            streaming_medium: {value: 'open_tok', label: 'Open Tok'},
            streamimgMediums: [{ value: 'open_tok',label: 'Open Tok' },{value: 'onprem', label: 'Onprem'},
            { value: 'both',label: 'Both' }],
            is_solo_program: false,
            guest_user_enabled: false,
            expert_ss_enabled: false,
            technician_ss_enabled: false,
            check_password_expiry: false,
            password_expiry_duration: 45,
            screen_recording_enabled: false,
            zoom_enabled: false,
            torch_enabled: false,
            annotation_color_enabled: false,
            annotation_shape_enabled: false,
            license_key: '',
            install_by_date: null,
            swap_camera_enabled: false,
            behaviour: '',
            expert_can_disconnect_user: false,
            can_turn_off_user_video: false,
            reset_user_password: { value: 'none', label: 'No password reset'},
            reset_password_options: [{value: 'reset_password_without_qr', label: 'Reset Password Without QR'}, {value: 'reset_password_with_qr', label: 'Reset Password With QR'},
            {value: 'none', label: 'No password reset'}],
            download_usage: { value: '', label: 'No usage download'},
            download_usage_options: [{value: 'as_csv', label: 'As CSV'}, {value: '', label: 'No usage download'}],
            invite_online_user: false,
            allow_custom_logo: false,//FQ3-846
            jobs_logo_url: '',
            fileNameArr:[],
            invalidFile: false
        }
        this.config = {
            bucketName: process.env.REACT_APP_S3_BUCKET,
            dirName: 'companyLogo', /* optional */
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        };  
        this.validationRules(props.language);
    }

    validationRules = (language) =>{
        SimpleReactValidator.addLocale(language, require(`../../locales/${language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: language,
			element: message => <div style={{ display: 'block' }} className="invalid-feedback">{message.replace(/field|The/, '').capitalize()}</div>,
			validators: {
                emailValidate: {  // name the rule
					message: 'Please enter a valid email address',
					rule: (val, params, validator) => {
						const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return validator.helpers.testRegex(
							String(val).toLowerCase(),
							regex
						);
					},
					required: true  // optional
                },
                phone: {
                    message: 'Enter a valid phone number',
                    rule: (val,params,validator) => {
                        let telRegex= /^\d+$/;
                        return (val.length >= 10 && val.length <=13 && validator.helpers.testRegex(
                            val,telRegex)
                        );
                    }
                }
			}
		});
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.language!==prevState.language){
           
          return {language : nextProps.language};
        }
        else return null;
    }

    componentDidUpdate = (prevProps, prevState) =>{
        if (prevState.language !== this.state.language) {
            this.validationRules(this.state.language);
        }
    }

    componentDidMount = () => {
        if (!this.props.accountId) {
            this.setState({
                message: i18nMark('Account id not found'),
                isLoading: false,
                isError: true
            })
            return;
        }
        this.setState({ isLoading: true });
        fetch(window._env_.REACT_APP_API_URL + `/getEnterpriseCustomerDetailsByAccountId/${this.props.accountId}`, {
			method: 'get',
			headers: {'Content-Type': 'application/json'}
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
		.then(details => {
            if (details) {
                const [audio_mode] = this.state.audioModes.filter(e => e.value === details.existing_audio_mode)
                const [streaming_medium] = this.state.streamimgMediums.filter(e => e.value === details.streaming_medium)
                const [reset_user_password] = this.state.reset_password_options.filter(e => e.value === details.reset_user_password)
                const [download_usage] = this.state.download_usage_options.filter(e => e.value === details.download_usage)
                this.setState(prev => ({
                    ...details,
                    audio_mode, streaming_medium, reset_user_password, download_usage,
                    amount: details.price * details.total_licences,
                    quantity: details.total_licences,
                    start_date: this.convertDate(new Date(details.start_date)),
                    end_date: new Date(details.end_date),
                    isLoading: false,
                    min_licences: details.total_licences - details.available_credits,
                    isActive: !details.isInactive,
                    is_customizable: details.is_customizable,
                    is_gps_enabled: details.is_gps_enabled,
                    is_offline_jobs_enabled: details.is_offline_jobs_enabled,
                    assign_jobs_to_all_users: details.assign_jobs_to_all_users,
                    asset_scan: details.asset_scan,
                    is_solo_program: details.is_solo_program,
                    guest_user_enabled: details.guest_user_enabled,
                    expert_ss_enabled: (details.screen_share_mode == 'screen_share_enabled' || details.screen_share_mode == 'expert_screen_share_enabled')? true : false,
                    technician_ss_enabled: (details.screen_share_mode == 'screen_share_enabled' || details.screen_share_mode == 'tech_screen_share_enabled')  ? true : false,
                    check_password_expiry: details.password_expiry_duration > 0 ? true : false,
                    password_expiry_duration: details.password_expiry_duration > 0 ? details.password_expiry_duration : this.state.password_expiry_duration,
                    screen_recording_enabled: details.screen_recording_enabled,
                    zoom_enabled: details.zoom_enabled,
                    torch_enabled: details.torch_enabled,
                    annotation_color_enabled: details.annotation_color_enabled,
                    annotation_shape_enabled: details.annotation_shape_enabled,
                    license_key: details.license_key,
                    install_by_date: details.install_by_date? new Date(details.install_by_date) : null,
                    swap_camera_enabled: details.swap_camera_enabled,
                    behaviour: details.behaviour,
                    expert_can_disconnect_user: details.expert_can_disconnect_user,
                    can_turn_off_user_video: details.can_turn_off_user_video,
                    invite_online_user: details.invite_online_user,
                    allow_custom_logo: details.allow_custom_logo,
                    jobs_logo_url: details.jobs_logo_url,
                    id: details.id
                }))
            }
		})
		.catch((error) => {
            let message = 'Something went wrong. Please try again later.';
			this.setState({ 
                message,
                isError: true,
                isLoading: false
            })
		})
    }

    onChangeForm = name => event => {
        event.persist();
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        if (parseInt(value) !== 'NaN' && parseInt(value) < 0)  {
            value = 0;
        }
        if (event.target.name === 'number_of_experts' && parseInt(value) === 0) {
            value = 1;
        }
        if (event.target.name === 'allow_custom_logo' && !value) {
            this.detachLogoFiles({e:event, fieldName:'jobs_logo_url', shouldEventpersists: true})
        }
        if (event.target.name === 'email') this.delayedCallback(event.target.value);
        this.setState({
            [name]: value
        });
    }
    
    openLogoFileUpload = ({fieldName})=> (e) => {
        e.preventDefault();
        // MB2-844
        const constraints = { accept: '.jpg, .png, .jpeg' }
        fileDialog(constraints)
        .then( async (files) => {
            const localAssets = Object.values(files);
            if(fieldName === 'jobs_logo_url' && !this.isFileAllowed({localAssets})){
                this.setState({invalidFile: true})
                return false;
            }
            let modifiedFiles = [];
            localAssets.map((f) => {
                console.log('type', f.type)
                const filetype = f.type.split('/')
                let file = new File([f], (this.state.company_name + '_' + this.props.accountId + '_' + Date.now() + '.' + filetype[1]), { type: f.type });
                modifiedFiles.push(file);
            })
            this.setState({ isLoading: true });//to indicate file share started
            const jobs_logo_url = await this.uploadAssetFile(modifiedFiles);
            this.setState({isLoading: false, fileNameArr: []},() => {
                this.setState({[fieldName]: JSON.stringify(jobs_logo_url[0])})
            })
        })
        .catch(err => {
            this.setState({isLoading: false, fileNameArr: []})
            console.error(err);
        })

    }

    isFileAllowed = ({localAssets}) =>{
        let flag = false, allowed_file_type=['image/png', 'image/jpeg'];
        for (const file of localAssets) {
            if(allowed_file_type.includes(file.type)){
                flag = true;
            }
        }
        return flag;
    }

    uploadAssetFile = async (localAssets) => {
        let retdata;
        for (const file of localAssets) {
            retdata = await this.s3FileUpload(file);
            this.setState(prevState => ({
                fileNameArr: [...prevState.fileNameArr,{key: retdata.key, location: retdata.location, name: file.name}]//used for s3
            }));
        }
        return this.state.fileNameArr;
    }

    // upload files to s3
    s3FileUpload = (file) => {
        return new Promise((resolve, reject) => {
            uploadFile(file, this.config)
            .then(retdata => {
                resolve({...retdata });
            })
            .catch(err => {
                this.setState({isLoading: false})
                console.error(err);
            })
        });
    }
    
    invalidFileDialog = () => {
        return (
            <div>
                <Modal isOpen={this.state.invalidFile} toggle={() => this.setState({ invalidFile: false })}>
                    <ModalHeader toggle={() => this.setState({ invalidFile: false })} cssModule={{ 'modal-title': 'w-100 text-center text-danger' }}><Trans>File Alert</Trans></ModalHeader>
                    <ModalBody>
                        <div className="text-center text-danger">
                            <Trans>invalid logo file</Trans>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    detachLogoFiles = async ({e, fieldName, shouldEventpersists}) => {
        try{
            !shouldEventpersists && e && e.preventDefault();
            let fileObj = this.state[fieldName] ? JSON.parse(this.state[fieldName]) : null
            if(fileObj){
                let filesToDelete = [{Key: (fileObj.key)}];
                await this.deleteS3Files({filesToDelete})
            }
            this.setState({ [fieldName]: '' });
        }catch(error){
            console.error(error);
        }
    }

    //MB2-775
    deleteS3Files = async ({filesToDelete}) => {
        try{
            s3.deleteObjects({
                Bucket: process.env.REACT_APP_S3_BUCKET,
                Delete: {Objects: filesToDelete, Quiet: false }
                }, (err, data) => {
                    if(err){
                        console.error(err, err.stack); // an error occurred
                    }else{
                        // console.info(`file deleted ${JSON.stringify(data)}`); // successful response
                    }
                }
            );        
        }catch(error){
            console.error(error);
        }
    }


    handleSelect = name => newValue => {
        this.setState({ [name]: newValue, purchase_id: newValue.value });
    }

    getOtherFlagsOptions = () => {
        let screenShareMode = '';
        if (this.state.expert_ss_enabled === true && this.state.technician_ss_enabled === true)
        {
            screenShareMode = 'screen_share_enabled';
        } else if (this.state.expert_ss_enabled === true) 
        {
            screenShareMode = 'expert_screen_share_enabled';
        } else if (this.state.technician_ss_enabled === true)
        {
            screenShareMode = 'tech_screen_share_enabled';
        } else 
        {
            screenShareMode = 'screen_share_disabled';
        }
        return screenShareMode;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
        }
        if (this.state.quantity < this.state.min_licences) {
            this.setState({ 
                message: i18nMark(`Number of licenses always greater than or equal to number of assigned credits({credits})`),
                isError: true,
                isLoading: false
            })
            return;
        }
        const screenShareMode = this.getOtherFlagsOptions();
        const options = {
            account_id: this.props.accountId,
            total_price: parseInt(this.state.amount),
            quantity: parseInt(this.state.quantity),
            monthly_minutes: parseInt(this.state.monthly_minutes),
            overage_cost: this.state.overage_cost,
            audio_mode: this.state.audio_mode.value,
            number_of_experts: parseInt(this.state.number_of_experts),
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            is_active: this.state.isActive,
            is_customizable: this.state.is_customizable,
            is_gps_enabled: this.state.is_gps_enabled,
            is_offline_jobs_enabled: this.state.is_offline_jobs_enabled,
            assign_jobs_to_all_users: this.state.assign_jobs_to_all_users,
            asset_scan: this.state.asset_scan,
            streaming_medium: this.state.streaming_medium.value,
            is_solo_program: this.state.is_solo_program,
            guest_user_enabled: this.state.guest_user_enabled,
            screen_share_mode: screenShareMode,
            check_password_expiry: this.state.check_password_expiry,
            password_expiry_duration: this.state.password_expiry_duration,
            screen_recording_enabled: this.state.screen_recording_enabled,
            zoom_enabled: this.state.zoom_enabled,
            torch_enabled: this.state.torch_enabled,
            annotation_color_enabled: this.state.annotation_color_enabled,
            annotation_shape_enabled: this.state.annotation_shape_enabled,
            license_key: this.state.license_key,
            install_by_date: this.state.install_by_date,
            swap_camera_enabled: this.state.swap_camera_enabled,
            behaviour: this.state.behaviour,
            expert_can_disconnect_user: this.state.expert_can_disconnect_user,
            can_turn_off_user_video: this.state.can_turn_off_user_video,
            reset_user_password: this.state.reset_user_password.value,//FQ3-832
            download_usage: this.state.download_usage.value,//FQ3-832
            invite_online_user: this.state.invite_online_user, //FQ3-832
            allow_custom_logo: this.state.allow_custom_logo,
            jobs_logo_url: this.state.jobs_logo_url
        }
        this.setState({ isLoading: true });
		fetch(window._env_.REACT_APP_API_URL + '/submitEnterpriseModificationRequest', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(options)
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
		.then(res => {
            if (res) {
                this.setState({
                    message: i18nMark('The request has been sent for an approval.'),
                    isLoading: false
                })
            }
		})
		.catch((error) => {
            let message = i18nMark('Something went wrong. Please try again later.');
			this.setState({ 
                message,
                isError: true,
                isLoading: false
            })
		})
    }

    handleKeyPress = e =>  {
        if(e.key === 'Enter'){
            let registerForm = e.target.form;
            let index = Array.prototype.indexOf.call(registerForm, e.target);
            registerForm.elements[index + 1].focus();
            e.preventDefault();
        }
    }

    onDismiss = (e) => {
        e.preventDefault();
        if (!this.state.isError || this.state.message === 'Account id not found'){
            this.props.closeAdminPopup();
            return;
        }
        this.setState({ 
            isLoading: false,
            message: '',
            isError: false
        });
    }

    showMessage = () => {
        return (
            <MessageModal onClickClose={this.onDismiss}>
                <div className="text-center" style={{ color: this.state.isError ? 'red' : '#485890'}}>
                    <div>
                        {
                            !this.state.isError ? 
                            <i className="fas fa-check-circle fa-3x" aria-hidden="true"></i> :
                            <i className="fas fa-exclamation-triangle fa-3x" aria-hidden="true"></i>
                        }
                    </div>
                    <Trans values={{ credits: this.state.min_licences }} id={this.state.message}></Trans>
                </div>
            </MessageModal>
        )
    }

    onClickClose = (e) => {
		e.preventDefault();
		this.props.closeAdminPopup();
    }
    
    onHandleDateChange = name => date => {
        this.setState({
          [name]: date
        });
    }
    
    convertDate = (str) => {
        let date = str,
            mnth = ("0" + (date.getMonth()+1)).slice(-2),
            day  = ("0" + date.getDate()).slice(-2);
        return [ date.getFullYear(), mnth, day ].join("-");
    }

    render() {
        return (
            <>
            {this.invalidFileDialog()}
            <Row>
                <Col xs="12" sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
                    {
                        this.state.message ?
                        this.showMessage() :
                        <Modal isOpen={true} className="modal-trial-register">
                            <ModalHeader className="text-center" toggle={this.onClickClose}><Trans>Modify Enterprise</Trans></ModalHeader>
                            <ModalBody>
                                <Fragment>
                                    <form>
                                        <section className="p-2 border rounded mb-3">
                                            <div className="input-wrapper margin-bottom-10">
                                                <Trans>Company Name</Trans>: {this.state.company_name}
                                            </div>
                                            <div className="input-wrapper margin-bottom-10">
                                                <Trans>Company Email</Trans>: {this.state.email}
                                            </div>
                                        </section>
                                        <EnterprisePurchaseDetails 
                                            validator={this.validator}
                                            values={this.state}
                                            handleKeyPress={this.handleKeyPress}
                                            onChangeForm={this.onChangeForm}
                                            onHandleDateChange={this.onHandleDateChange}
                                            handleSelect={this.handleSelect}
                                            startDateEnabled={true}
                                            openLogoFileUpload={this.openLogoFileUpload}
                                            detachLogoFiles={this.detachLogoFiles}
                                        />
                                    </form>
                                </Fragment>
                                <FormGroup>
                                    <Label for="active" className="mr-3"><Trans>Account Status</Trans></Label>
                                    <CustomInput type="checkbox" id="active" label={this.state.isActive ? 'Active' : 'Inactive'} name="isActive" checked={this.state.isActive} value={this.state.isActive} onChange={this.onChangeForm('isActive')} inline />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <div className="d-flex justify-content-center btn-trial-register">
                                    <Button 
                                        showSpinner={this.state.isLoading} 
                                        onClick={this.handleSubmit}>
                                        <Trans>Submit</Trans>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </Modal>
                    }
                </Col>
            </Row>
            </>
        );
    }
}

export default ModifyEnterprisRequestForm;