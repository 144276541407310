import React, { Fragment } from 'react';
import './Groups.scss';
import CheckBox from '../CheckBox/CheckBox';
import SimpleReactValidator from 'simple-react-validator';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import { Label, Input, FormGroup } from 'reactstrap';

class AddGroup extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			group_name: '',
            description: '',
            is_onprem: props.streaming_medium === 'open_tok' ? false : true, //TP-3198
			bSubmitted: false,
			bResponded: false,
			errStrReturned: i18nMark('Add a new Group'),
			retCode: 400,
			newgroupid: 0,
			bNeedPull: true,
			streaming_mode: 'routed'
		}

		// initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: props.language,
			element: message => <div className="groups-form-validation-msg">{message.replace(/field|The/g, '').capitalize()}</div>,
		});
	}

	onChangeForm = name => (event) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		this.setState({
			[name]: value
		});
	}

	onClickClose = () => {
		this.props.onClose();
	}

	onAddGroup = (e) => {
		e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
		this.setState({bSubmitted: true});
        this.setState({errStrReturned: i18nMark('Submitting new Group details')});
        const groupDetails = {
            group_name: this.state.group_name,
            description: this.state.description,
            is_onprem: this.state.is_onprem,
            adminuserid: this.props.userid,
			account_id: this.props.accountid,
			streaming_mode: this.state.streaming_mode
        };
		this.props.authService.fetch('addgroup', {
			method: 'post',
			body: JSON.stringify(groupDetails)
		})
		.then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
				this.props.onFetchGroups();
				return response.json();
			} else {
				throw new Error(response.body);
			}
		})
		.then(id => {
			if(id){
				this.setState({
                    newgroupid: id,
                    bSubmitted: false,
                    bResponded: true,
                    errStrReturned: i18nMark('New Group successfully added!')
                });
                this.props.addedGroup({
                    ...groupDetails,
                    groupid: id,
                    users: []
                    // users: [{
                    //     value: this.props.userid,
                    //     label: this.props.adminname
                    // }]
                })
			}
		})
		.catch((error) => {
			// output error in login
			this.setState({retCode: error.status}, ()=>{
				if(this.state.retCode === 409){
					this.setState({errStrReturned: i18nMark('You already have a group by this name. Please resubmit with a different name.')});
				} else if(this.state.retCode === 404) {
					this.setState({errStrReturned: i18nMark('New Group creation failed since this feature is not supported.')});
	            } else {
					this.setState({errStrReturned: i18nMark('Failed to add the new group. Please review the entries and resubmit.')});
				}
			});
		})
	}

	render() {
		if(this.state.bSubmitted || this.state.bResponded){
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
				<div className='modale opened'>
					<div className='__modal-dialog'>
						<form>
							<div className="__modal-header">
								<h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
							</div>
							<div className="__modal-footer flex-center">
								<button className="btn-green" onClick={() =>this.onClickClose()}><Trans id='OK'/></button>
							</div>
						</form>
					</div>
				</div>
			);
		} else {
			return(
				<div className='formmodule center'>
					<div className='dialog form-color width-min'>
						<form>
							<div className="formheader flex-center row">
								<h2><Trans>Add Group</Trans></h2>								
							</div>
							<div className="formbody form-text-color">
								<label><Trans>Group Name</Trans>:
									<div className="input-wrapper">
                                        <I18n>
                                            {({ i18n }) =>
                                                <Fragment>
                                                <input className='signup-input-field' name={i18n._(t`group_name`)} type="text" placeholder={i18n._(t` Enter the new Group name`)} value={this.state.group_name} onChange={this.onChangeForm('group_name')} />
                                                { this.validator.message(i18n._(t`group_name`), this.state.group_name, 'required|alpha_num_space|max:40') }
                                                </Fragment>									
                                            }
                                        </I18n>
									</div>
								</label>
								<label><Trans>Description</Trans>:
									<div className="input-wrapper mb-3">
                                        <I18n>
                                            {({ i18n }) =>
                                            <Fragment>
										        <input className='signup-input-field' name={i18n._(t`description`)} type="text" placeholder={i18n._(t` Enter the Group Description`)} value={this.state.description} onChange={this.onChangeForm('description')} />
                                                {this.validator.message(i18n._(t`description`), this.state.description, 'required|alpha_num_dash_space')}		
                                            </Fragment>
                                            }
                                        </I18n>
									</div>
								</label>
								{
									this.props.isCustomizable && this.props.streaming_medium !== "onprem"  && !this.state.is_onprem &&
									<label><Trans>Streaming Mode</Trans>:
										<FormGroup className="mt-2">
											<I18n>
												{({ i18n }) =>
													<Fragment>
														<Label className="ml-4">
															<input type="radio" checked={this.state.streaming_mode === 'routed'} value='routed' name="streaming_mode" onChange={this.onChangeForm('streaming_mode')}/>{' '}
															<Trans>Routed</Trans>
														</Label>
														<Label className="ml-5">
															<input type="radio" value='relayed' checked={this.state.streaming_mode === 'relayed'} name="streaming_mode" onChange={this.onChangeForm('streaming_mode')} />{' '}
															<Trans>Relayed</Trans>
														</Label>
													</Fragment>
												}
											</I18n>
											<div className="stream-mode-desc mt-2 ml-3">
                                                { this.state.streaming_mode === 'routed' ?
                                                    <Trans id="groups.routedTooltip" />
                                                    :
                                                    <Trans id="groups.relayedTooltip" />
                                                }
                                            </div>
										</FormGroup>
									</label>
								}
								{/* RS: commented out code to remove On prem checkbox. Jira #T32-170
									this.props.streaming_medium === "both" ?
										<div className="ml-2">
											<label htmlFor="is_onprem">
												<input className="checkbox" type="checkbox" name="is_onprem" checked={this.state.is_onprem} onChange={this.onChangeForm('is_onprem')}></input>
												<Trans>Onprem</Trans>
											</label>
										</div>
                                    : null
                                */}
							</div>			
							<div className="flex-center" style={{marginTop: 100}}>
								<button className="btn-red"  onClick={() =>this.onClickClose()}><Trans>Close</Trans></button>
								<button id='btnAddGroup' className="margin10 btn-green" onClick={(e) =>this.onAddGroup(e)}><Trans>Add Group</Trans></button>
							</div>
						</form>
					</div>
	            </div>
	   		);
		}
	}
}

export default AddGroup;