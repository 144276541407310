import AuthService from '../AuthService';
import { printConsole } from '../helpers';
import diagonastics from "../DiagonasticHelper";

export default class SessionSignal  {
    constructor(from, is_onprem, groupId){
        
        this.from = from;
        this.is_onprem = is_onprem;
        this.groupId = groupId;
        //printConsole(this);
        SessionSignal.instance = this;;
        this.authService = new AuthService();
        this.emitSignal = this.emitSignal.bind(this);
        this.listener = this.listener.bind(this);
        this.listenerWebsocket = this.listenerWebsocket.bind(this);
        this.listenerIsOfflineNotification = this.listenerIsOfflineNotification.bind(this);
        this.removeListener = this.removeListener.bind(this);
        this.removeWebsocket = this.removeWebsocket.bind(this);
        this.sendSignalMuteExpertMike = this.sendSignalMuteExpertMike.bind(this);
        this.sendSignalMuteUserAudio = this.sendSignalMuteUserAudio.bind(this);
        this.sendSignalQueryRCSupport = this.sendSignalQueryRCSupport.bind(this);
        this.sendSignalShowLocalRC = this.sendSignalShowLocalRC.bind(this);
        this.sendSignalMaxDivShowButtons = this.sendSignalMaxDivShowButtons.bind(this);
        this.sendSignalChat = this.sendSignalChat.bind(this);
        this.sendSignalChatNew = this.sendSignalChatNew.bind(this);
        this.sendSignalAnnotate = this.sendSignalAnnotate.bind(this);        
        this.sendSignalAnnotateMultipart = this.sendSignalAnnotateMultipart.bind(this);        
        this.sendSignalRemoteCaptureClick = this.sendSignalRemoteCaptureClick.bind(this);        
        this.sendSignalRCUploaded = this.sendSignalRCUploaded.bind(this);  
        this.sendSignalRCAnnotation = this.sendSignalRCAnnotation.bind(this);        
        this.sendSignalLocalRC = this.sendSignalLocalRC.bind(this);  
        this.sendSignalRemoteZoom = this.sendSignalRemoteZoom.bind(this);        
        this.sendSignalRemoteTorch = this.sendSignalRemoteTorch.bind(this);        
        this.sendSignalArtifacts = this.sendSignalArtifacts.bind(this);  
        this.sendSignalShareArtifacts = this.sendSignalShareArtifacts.bind(this);      
        this.sendSignalRemoveArtifacts = this.sendSignalRemoveArtifacts.bind(this);        
        this.sendSignal3DRotation = this.sendSignal3DRotation.bind(this);        
        this.sendSignalOpacity = this.sendSignalOpacity.bind(this);
        this.sendSignalPrepareExpertSS = this.sendSignalPrepareExpertSS.bind(this);      
        this.sendSignalPrepareExpertSSCancel = this.sendSignalPrepareExpertSSCancel.bind(this);
        this.sendSignalStartExpertSS = this.sendSignalStartExpertSS.bind(this);        
        this.sendSignalStopExpertSS = this.sendSignalStopExpertSS.bind(this);        
        this.sendSignalStartTechnicianSS = this.sendSignalStartTechnicianSS.bind(this);        
        this.sendSignalStopTechnicianSS = this.sendSignalStopTechnicianSS.bind(this);        
        this.sendSignalCancelTechnicianSS = this.sendSignalCancelTechnicianSS.bind(this); 
        this.sendSignalSwapCamera = this.sendSignalSwapCamera.bind(this);
        this.sendSignalSwapCameraReceived = this.sendSignalSwapCameraReceived.bind(this); 
        this.sendSignalConfirmSwapCamera = this.sendSignalConfirmSwapCamera.bind(this);
        this.sendSignalStopSessionUser = this.sendSignalStopSessionUser.bind(this); 
        this.sendSignalTurnOnUserVideo = this.sendSignalTurnOnUserVideo.bind(this);
        this.sendSignalVideoPaused = this.sendSignalVideoPaused.bind(this);//TP-4483
        this.sendSignalExpertYieldMaxviewControls = this.sendSignalExpertYieldMaxviewControls.bind(this);
        /** Multiple Experts Signal emit Signal methods */     
        this.sendSignalSyncExperts = this.sendSignalSyncExperts.bind(this) ;
        this.sendSignalQueryExpertMaxdivSwitch = this.sendSignalQueryExpertMaxdivSwitch.bind(this);
        this.sendSignalExpertSwitchMaxdiv = this.sendSignalExpertSwitchMaxdiv.bind(this);
        this.sendSignalRequestMaxDivControl = this.sendSignalRequestMaxDivControl.bind(this);
        this.sendSignalYieldMaxDivControls = this.sendSignalYieldMaxDivControls.bind(this);
        this.sendSignalCurrentPrimaryExpert = this.sendSignalCurrentPrimaryExpert.bind(this);
        this.sendSignalExpertRCClicked = this.sendSignalExpertRCClicked.bind(this);        
        this.sendSignalExpertRCUploaded = this.sendSignalExpertRCUploaded.bind(this); 
        this.sendSignalYieldDisableHandIcon = this.sendSignalYieldDisableHandIcon.bind(this);
        this.sendSignalTurnOnMaxViewUserVideo = this.sendSignalTurnOnMaxViewUserVideo.bind(this);
        this.sendSignalExpertVideoFeedEnabled = this.sendSignalExpertVideoFeedEnabled.bind(this); //TP-3532
        this.sendSignalMissedCallNotification = this.sendSignalMissedCallNotification.bind(this);//TP-2462
        this.sendSignalRecordingData = this.sendSignalRecordingData.bind(this);
        this.sendSignalScreenShotImage = this.sendSignalScreenShotImage.bind(this); 
        this.sendSignalAudioData = this.sendSignalAudioData.bind(this);   
        /** New socket signals for recording feature */      
        this.sendSignalRecordingStart = this.sendSignalRecordingStart.bind(this);
        this.sendSignalRecordingUploadImages = this.sendSignalRecordingUploadImages.bind(this);
        this.sendSignalRecordingUploadAudio = this.sendSignalRecordingUploadAudio.bind(this);
        this.sendSignalRecordingStop = this.sendSignalRecordingStop.bind(this);
        this.sendSignalRecordingPause = this.sendSignalRecordingPause.bind(this);
        this.sendSignalRecordingContinue = this.sendSignalRecordingContinue.bind(this);
        /** New socket signals for Collaboration Mode */
        this.sendSignalCurrentMaxViewUser = this.sendSignalCurrentMaxViewUser.bind(this);
        this.sendSignalUserSpeakerOn = this.sendSignalUserSpeakerOn.bind(this);
    }

    
    
    /** This method is for emitting the websocket signal
     * *@param {`OT-group-signal`} message format: string
     * @param {type, data, groupId, to, from} json format: JSON Object
     */
    emitSignal = (message, json) => {
        //printConsole(this.is_onprem);
        if ((this.authService.socket.connected === true && !this.is_onprem) || this.is_onprem) {
            
                //TP-5733
                printConsole("****************Sending the below signal********************");
                printConsole(json);
            
            /* if (json.type === "REMOTECLICKDATA" || json.type === "SHARE_ARTIFACTS" || json.type === "EXPERT_RC_IMAGE_DATA") {
                diagonastics(this.authService, {
                    action: `websocket signal sent`,
                    next_step: 'the signal will be received in backend',
                    sequence: 26,
                    error: 'none'
                })
            } else {
                diagonastics(this.authService, {
                    action: `websocket signal sent`,
                    next_step: 'the signal will be received in backend',
                    sequence: 26,
                    data: json,
                    error: 'none'
                })
            } */
            this.authService.socket.emit(message, json);
        } else {
            printConsole("Socket emit won't work since connection state isn't 'connected'");
        }
    }

    emitSignalAcknowledged = (message, json, callback=null) => {
        if (this.authService.socket.connected === true) {
            printConsole("****************Sending the below signal********************");
            printConsole(json);
            if (callback !== null)
                this.authService.socket.emit(message, json, callback);
            else
                this.authService.socket.emit(message, json);
        } else {
            printConsole("Socket acknowledgement emit won't work since connection state isn't 'connected'");
        }
    }

    sendSignalMuteExpertMike = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                from,
                to,
                groupId,
                data,
                type:"MUTE_EXPERT_AUDIO"
            } : {
                from,
                groupId,
                data,
                type:"MUTE_EXPERT_AUDIO"
            }
        this.emitSignal(
            message,
            json
        ) 
    }

    /** T32-413
     * Signal to be sent by Remote users to experts when they Mute their own mikes
     * @param {*} data = true/false (When Mike muted true, when unmuted false) 
     * @param {*} to  = "prerana@care4d.com" (email id of recipient)
     */
    sendSignalMuteUserAudio = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                from,
                to,
                groupId,
                data,
                type:"MUTE_USER_AUDIO"
            } : {
                from,
                groupId,
                data,
                type:"MUTE_USER_AUDIO"
            }
        this.emitSignal(
            message,
            json
        ) 
    }

    sendSignalQueryRCSupport = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                groupId,
                data,
                type:"CONFIRMRCSUPPORT"
            }
        )
    }
    sendSignalShowLocalRC = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type:"MAX_VIEW"
            }            
        );
    }
    sendSignalMaxDivShowButtons = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type:"MAX_DIV_SHOW_BUTTONS"
            }            
        );
    }
    /** Trigger send Signal for Chat Feature
     * 
     * @param {"this is chat message"} data format: string
     * @param {} to format: object   
     */
    sendSignalChat = (messageID,data, isCapabilities, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                from,
                to,
                isCapabilities, //TP-4884
                messageID, // MB2-95
                data,
                groupId,
                type:"IM"
            } : {
                from,
                isCapabilities, //TP-4884
                messageID, // MB2-95
                data,
                groupId,
                type:"IM"
            }
        this.emitSignal(
            message,
            json
        );
    }

    sendSignalChatNew = (messageID, message, isGroupChat, action, to) => {
        const {groupId, from} = this;
        const signalmessage = `chat`;
        const json = (to !== undefined) ? {
            from,
            to,
            messageID, // MB2-95
            message,
            isGroupChat,
            groupId,
            action,
            //type:"Chat:Message"
        } : {
            from,
            messageID, // MB2-95
            message,
            isGroupChat,
            groupId,
            action,
            //type:"Chat:Message"
        }
        this.emitSignal(
            signalmessage,
            json
        );
    }

    sendSignalAnnotate = (data, to, id) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                unique_id: id, //TP-2549
                groupId,
                type:"ANNOTATE"
            }            
        );
    }
    sendSignalAnnotateMultipart = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type:"ANNOTATE_MULTI_PART"
            }            
        );
    }

    /**Remote capture button click signal
     * 
     * @param {*} data 
     * @param {} to 
     */
    sendSignalRemoteCaptureClick = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type:"REMOTECLICK"
            }
        );
    }
    sendSignalRCUploaded = (data, to, format) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        //if (is_onprem === true) {
            const assetData = {
                data,
                format
            }
            this.emitSignal(
                message,
                {
                    from,
                    to,
                    assetData,
                    groupId,
                    type: "REMOTECLICKDATA"
                }
            );
        /* } else {
            this.emitSignal(
                message,
                {
                    from,
                    to,
                    data,                    
                    groupId,
                    type: "REMOTECLICKUPLOADED"
                }
            );
        } */
    }    
    sendSignalRCAnnotation = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type:"REMOTECAPTUREANNOTATE"
            }
        );
    }
    sendSignalLocalRC = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "START_LOCAL_RC"
            }
        );
    }   
    

    /** Zoom Feature signals
     * 
     * @param {*} data 
     * @param {*} to format: object 
     */
    sendSignalRemoteZoom = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "REMOTE_ZOOM_LEVEL"
            }
        );
    }
    /** Torch Feature signals
     * 
     * @param {*} data 
     * @param {*} to format: object 
     */
    sendSignalRemoteTorch = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "TECHNICIAN_TORCH_ON"
            }
        );
    }

    /** Share assets signals
     * 
     * @param {*} data : string
     * @param {*} to format : Object 
     */
    sendSignalArtifacts = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                from,
                to,
                data,
                groupId,
                type: "ARTIFACTS"
            } : {
                from,
                data,
                groupId,
                type: "ARTIFACTS"
            }
        this.emitSignal(
            message,
            json
        );
    }

    // Share assets new signal
    // @param {*} data : object
    // @param {*} to : email
    //
    sendSignalShareArtifacts = (assetData, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        let json = {};
        json = (to !== undefined) ? {
            from,
            to,
            assetData,
            groupId,
            type: "SHARE_ARTIFACTS"
        } : {
            from,
            assetData,
            groupId,
            type: "SHARE_ARTIFACTS"
        }
        this.emitSignal(
            message,
            json
        );
    }
    sendSignalRemoveArtifacts = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        let json = {};
        json = (to !== undefined) ? {
            from,
            to,
            data,
            groupId,
            type: "REMOVE_ARTIFACTS"
        } : {
            from,
            data,
            groupId,
            type: "REMOVE_ARTIFACTS"
        }
        this.emitSignal(
            message,
            json
        );
    }      
    sendSignal3DRotation = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "ROTATION"
            } 
        );
    }

    sendSignalOpacity = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "OPACITY"
            } 
        );
    }

    /** Method to send the signal for preparing to
     *  start an Expert screen share to a technician
     * @param {true} data format : Boolean
     * @param {*} to  format : Object
     */
    sendSignalPrepareExpertSS = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                from,
                to,
                data,
                groupId,
                type: "PREPARE_EXPERT_SS"
            } : {
                from,
                data,
                groupId,
                type: "PREPARE_EXPERT_SS"
            } 
        this.emitSignal(
            message,
            json
        );
    }
    //TP-5852, TP-5783
    sendSignalPrepareExpertSSCancel = (to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                from,
                to,
                groupId,
                type: "PREPARE_EXPERT_SS_CANCEL"
            } : {
                from,
                groupId,
                type: "PREPARE_EXPERT_SS_CANCEL"
            } 
        this.emitSignal(
            message,
            json
        );
    }
    /** Method to trigger the expert Screen share signal
     * 
     * @param {*} to format: Object 
     */
    sendSignalStartExpertSS = (to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                from,
                to,
                groupId,
                type: "START_EXPERT_SS"
            } : {
                from,
                groupId,
                type: "START_EXPERT_SS"
            } 
        this.emitSignal(
            message,
            json
        );
    }    
    sendSignalStopExpertSS = (to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                from,
                to,
                groupId,
                type: "STOP_EXPERT_SS"
            } : {
                from,
                groupId,
                type: "STOP_EXPERT_SS"
            } 
        this.emitSignal(
            message,
            json
        );
    }
    /** Method to trigger the technician Screen share signals
     * 
     * @param {*} to format : Object
     */
    sendSignalStartTechnicianSS = (to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        
        this.emitSignal(
            message,
            {
                from,
                to,
                groupId,
                type: "START_TECHNICIAN_SS"
            }
        );
    }

    sendSignalStopTechnicianSS = (to) => {        
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        
        this.emitSignal(
            message,
            {
                from,
                to,
                groupId,
                type: "STOP_TECHNICIAN_SS"
            }
        );    
    }

    sendSignalCancelTechnicianSS = (to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        
        this.emitSignal(
            message,
            {
                from,
                to,
                groupId,
                type: "CANCEL_TECHNICIAN_SS"
            }
        ); 
    }

    sendSignalSwapCamera = (data, eventType, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        
        this.emitSignal(
            message,
            {
                from,
                data,
                eventType,
                to,
                groupId,
                type: "SWAP_CAMERA"
            }
        );
    }

    sendSignalSwapCameraReceived = (data, eventType, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        
        this.emitSignal(
            message,
            {
                from,
                data,
                eventType,
                to,
                groupId,
                type: "SWAP_CAMERA_RECEIVED"
            }
        );
    }

    sendSignalConfirmSwapCamera = (data, eventType, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        
        this.emitSignal(
            message,
            {
                from,
                data,
                eventType,
                to,
                groupId,
                type: "CONFIRM_SWAP_CAMERA"
            }
        );
    }

    sendSignalStopSessionUser = (to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        
        this.emitSignal(
            message,
            {
                from,
                to,
                groupId,
                type: "STOP_SESSION_USER"
            }
        );
    }

    sendSignalTurnOnUserVideo = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        
        this.emitSignal(
            message,
            {
                from,
                data,
                to,
                groupId,
                type: "TURN_ON_USER_VIDEO"
            }
        );
    }

    //TP-4483 -- New signal sent by technician when Camera was paused due to any reason
    sendSignalVideoPaused = (isPaused, data, to, format, reason) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const sendingData = {
            isPaused,
            format,
            reason,
            imageframe: data
        }
        this.emitSignal(
            message,
            {
                from,
                to,
                data: sendingData,
                groupId,
                type: "LIVE_VIDEO_PAUSED"
            }
        );        
    }
    //TP-5851 & TP-5533
    sendSignalExpertYieldMaxviewControls = (data, expertName, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                expertName,
                groupId,
                type: "EXPERT_YIELD_MAXVIEW_CONTROLS"
            }
        );
    }

    /** Multiple Experts Signal Methods */
    sendSignalSyncExperts = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                    from,
                    to,
                    data,
                    groupId,
                    type: "SYNC_EXPERTS"
                } : {
                    from,
                    data,
                    groupId,
                    type: "SYNC_EXPERTS"
                }
              
        this.emitSignal(
            message,
            json
        );
    }
     
    sendSignalQueryExpertMaxdivSwitch = (to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                groupId,
                type: "EXPERT_QUERY_MAXDIV"
            }
        );
    }

    sendSignalExpertSwitchMaxdiv = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                from,
                to,
                data,
                groupId,
                type: "EXPERT_CHANGE_MAX_DIV"
            } : {
                from,
                data,
                groupId,
                type: "EXPERT_CHANGE_MAX_DIV"
            } 
        this.emitSignal(
            message,
            json
        );
    }

    sendSignalRequestMaxDivControl = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "REQUEST_MAXDIV_CONTROL"
            }
        );
    }

    sendSignalYieldMaxDivControls = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "YIELD_MAXDIV_CONTROL"
            }
        );
    }

    sendSignalCurrentPrimaryExpert = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "CURRENT_PRIMARY_EXPERT"
            }
        );
    }

    sendSignalExpertRCClicked = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "EXPERT_RC_CLICKED"
            }
        );
    }    

    sendSignalExpertRCUploaded = (data, to, format) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        //if (is_onprem === true) {
            const assetData = {
                data,
                format
            }
            this.emitSignal(
                message,
                {
                    from,
                    to,
                    assetData,
                    groupId,
                    type: "EXPERT_RC_IMAGE_DATA"
                }
            );
        /* } else {
            this.emitSignal(
                message,
                {
                    from,
                    to,
                    data,
                    groupId,
                    type: "EXPERT_RC_IMAGE_UPLOADED"
                }
            );
        } */
    }
    
    sendSignalYieldDisableHandIcon = (data, expertName, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                expertName,
                groupId,
                type: "YIELD_DISABLE_HAND_ICON"
            }
        );
    }

    sendSignalTurnOnMaxViewUserVideo = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                groupId,
                type: "TURN_ON_MAX_VIEW_USER_VIDEO"
            }
        );
    }

    //TP-3532 -- session signal for sending the expert's Camera enabled/disabled state
    sendSignalExpertVideoFeedEnabled = (data, my_camera_enabled, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.emitSignal(
            message,
            {
                from,
                to,
                data,
                my_camera_enabled, //TP-3683
                groupId,
                type: "EXPERT_VIDEO_FEED_ENABLED"
            }
        );
    }

    //session signal for sending the recording data to Server
    sendSignalRecordingData = (data) => {
        const message = `stream-video`;
        this.emitSignal(message, data);
    }

    sendSignalScreenShotImage = (data) => {
        const message = `stream-snapshot`;
        this.emitSignal(message, data);
    }

    sendSignalAudioData = (data) => {
        const message = `stream-audio`;
        this.emitSignal(message, data);
    }

    //New session signals for recording feature
    sendSignalRecordingStart = (data, callback) => {
        const message = `recording-expert-start`;
        this.emitSignalAcknowledged(message, data, callback);
    }

    sendSignalRecordingUploadImages = (data, callback) => {
        const message = `recording-expert-upload-images`;
        this.emitSignalAcknowledged(message, data, callback);
    }

    sendSignalRecordingUploadAudio = (data, callback) => {
        const message = `recording-expert-upload-audios`;
        this.emitSignalAcknowledged(message, data, callback);
    }

    sendSignalRecordingStop = (data) => {
        const message = `recording-expert-stop`;
        this.emitSignalAcknowledged(message, data);
    }

    sendSignalRecordingPause = (data) => {
        const message = `recording-expert-pause`;
        this.emitSignalAcknowledged(message, data);
    }

    sendSignalRecordingContinue = (data) => {
        const message = `recording-expert-continue`;
        this.emitSignalAcknowledged(message, data);
    }

    //TP-3114 -- New signals for Collaboration Mode 
    sendSignalCurrentMaxViewUser = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                    from,
                    to,
                    data,
                    groupId,
                    type: "CURRENT_MAX_VIEW_USER"
                } : {
                    from,
                    data,
                    groupId,
                    type: "CURRENT_MAX_VIEW_USER"
                }
              
        this.emitSignal(
            message,
            json
        );
    }

    sendSignalUserSpeakerOn = (data, to) => {
        const {is_onprem, groupId, from} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        const json = (to !== undefined) ? {
                    from,
                    to,
                    data,
                    groupId,
                    type: "USER_SPEAKER_ON"
                } : {
                    from,
                    data,
                    groupId,
                    type: "USER_SPEAKER_ON"
                }
              
        this.emitSignal(
            message,
            json
        );
    }

    //TP-2462
    sendSignalMissedCallNotification = (data) => {
        const message = `send-missed-call-notification`;
        this.emitSignal(message, data);
    }

    /*data={recipientEmail: email, callerName: first_name + last_name, isCancelled: false/true, group_id: selectedGroupId} */
    sendConnectTheCall = ({data}) => {
        const {is_onprem, groupId} = this;
        const signal = is_onprem ? `connect-the-call`: `OT-group-signal`;
        this.emitSignal(signal,{...data});
    }

    /*data={action: 'accepted', recipient: fromSocketUser, groupId: selectedGroupId, groupName: selectedGroupName} */
    sendRespondToCall = ({data}) => {
        const {is_onprem, groupId} = this;
        const signal = is_onprem ? `respond-to-the-call`: `OT-group-signal`;
        this.emitSignal(signal,{...data});
    }

    /*data={groupId} */
    sendUserInSession = ({data}) => {
        const {is_onprem, groupId} = this;
        const signal = is_onprem ? `user-is-in-session`: `OT-group-signal`;
        this.emitSignal(signal,{...data});
    }

    /*data={groupId} */
    sendRoomJoined = () => {
        const {is_onprem, groupId} = this;
        const signal = is_onprem ? `joined`: `OT-joined`;
        this.emitSignal(signal,{groupId});
    }

    /*data={groupId} */
    sendWebrtcEndCall = () => {
        const {is_onprem, groupId} = this;
        const signal = is_onprem ? `end-call`: `OT-end-call`;
        this.emitSignal(signal,{groupId});
    }

    /*data={offer, to: this.to, groupId: this.groupId, stream_id: this.localStream.id} */
    sendWebrtcConnectionOffer = ({data}) => {
        const {is_onprem, groupId} = this;
        const signal = is_onprem ? `call-user`: `OT-group-signal`;
        this.emitSignal(signal,{...data});
    }

    /*data={answer:answer, to: this.to, groupId: this.groupId, stream_id: this.localStream.id} */
    sendWebrtcConnectionAnswer = ({data}) => {
        const {is_onprem, groupId} = this;
        const signal = is_onprem ? `answer-user`: `OT-group-signal`;
        this.emitSignal(signal,{...data});
    }

    /*data={ candidate: event.candidate,to: this.to, groupId: this.groupId} */
    sendWebrtcIceCandidate = ({data}) => {
        const {is_onprem, groupId} = this;
        const signal = is_onprem ? `new-ice-candidate`: `OT-group-signal`;
        //TP-5733
        this.newIceTimer = setTimeout(() => {
            this.emitSignal(signal,{...data});
            clearTimeout(this.newIceTimer);
        }, 50);
    }
    
    /** This method is for listening on the
     * received web-socket signals
     * @param {`OT-group-signal`} message 
     * @param {function reference} callback 
     */
    listener = (message, callback) => {     
        //printConsole(`Listener socket messages "${message}"`);
        //printConsole(this.authService.socket);
        this.authService.socket.on(message, callback);
    }

    /**This function triggers the callback functions
     * based on the signal.type parameter
     * @param {type, data, groupId, to, from} json format: JSON Object
     */
    listenerWebsocket = (callback) => {
        const {is_onprem, groupId} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        this.listener(message,callback);         
    }

    /**This function triggers the callback function
     * for the user-is-in-session signal
     * @param {isInSession, userName, caller} json format: JSON Object
     */
    listenerUserinsession = (callback) => {
        const message = `user-is-in-session`;
        this.listener(message, callback);
    }

    listenerIsOfflineNotification = (callback) => {
        const message1 = `connect_failed`;
        this.listener(message1, callback);
        const message2 = `disconnect`;
        this.listener(message2, callback);

        /* this.listener('connect_failed', function() {
            alert("Sorry, there seems to be an issue with the connection!");
        });
        this.listener('disconnect', function() {
            alert("Sorry, the websocket connection has disconnected!");
        }); */
         
    }

    /** This method is for removing the listener function
     *  after the session gets unmounted
     * @param {`OT-group-signal`} message 
     * @param {function reference} callback 
     */
    removeListener = (message, callback) => {        
        this.authService.socket.removeListener(message, callback);
    }

    removeWebsocket = (callback) =>{ 
        const {is_onprem} = this;
        const message = is_onprem === false ? `OT-group-signal` : `group-signal`;
        /** Unsubscribe / remove the event listeners while component unmounts */
        this.removeListener(message, callback);        
    }

    removeUserinsession = (callback) => {
        const message = `user-is-in-session`;
        this.removeListener(message, callback);
    }

    removeIsOfflineNotification = (callback) => {
        const message1 = `connect_failed`;
        /** Unsubscribe / remove the event listeners while component unmounts */
        this.removeListener(message1, callback);
        const message2 = `disconnect`;
        /** Unsubscribe / remove the event listeners while component unmounts */
        this.removeListener(message2, callback);

        /* this.removeListener('connect_failed', function() {
            alert("Sorry, there seems to be an issue with the connection!");
        })
        this.removeListener('disconnect', function() {
            alert("Sorry, the websocket connection has disconnected!");
        }) */
    }
    
}




