import React, { Fragment } from 'react';
import './Header.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
import Button from '../Button/Button';
import CheckBox from '../CheckBox/CheckBox';
import MyEditor from '../PhotoEditor/Editor.js';

import {Collapse,NavbarToggler,UncontrolledTooltip,Modal,ModalHeader,
    Form, Row, Col,
    ModalBody} from 'reactstrap';

import startButton from './Start_button.png';
import stopButton from './Stop_button.png';
import AuthService from '../AuthService';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import {diagonastics} from "../DiagonasticHelper";
import { version } from "../../../package.json"; 
import { encryptData, printConsole } from '../helpers'; //TP-3506

function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const fileDialog = require('file-dialog');

class Header extends React.Component { //= ({ onRouteChange, isSignedIn, route, email }) => {
    constructor(props){
        super(props);
        this.authService = new AuthService();
        this.state = {
            toggleMuteMike: false,
            toggleMuteAll: false,
            toggleStart: true,
            toggleChat: false,
            toggleShare: false,
            toggleShareScreen: false,
            headerCommand: '',
            isOpen: false,
            chatUserCount: 0,
            chatUserNameArr: [],
            shareAssetAll: false,
            group_id: '',
            sessionJoinees: [],
            showStartStop: true,
            disableStartStopClass: 'no-cursor-pointer',
            disableImgClass: 'faded-image',
            guestUser: {isexpert: false},
            showGuestForm: false,
            isEmailAlreadyRegistered: false,
            disableGuestSubmission:true,
            isLoading: false,
            bResponded: false,
            language: '',
            creditsAvailable: props.creditsAvailable,
            showEditor: false, //TP-3199
            saveImage: false, //TP-3199
            disableSavePhotoButton: true, //TP-3199
            isPhotoSubmitLoading: false, //TP-3199
            imageUneditted: (props.myPicture !== undefined && props.myPicture !== null) ? props.myPicture : "/profile.png", //TP-3199
            imageScaleValue: 1.2
        }
        this.validationRules(props.language);
    }

validationRules = (language) => {
    SimpleReactValidator.addLocale(language, require(`../../locales/${language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: language,
            element: message => <div className='add-users-validation-msg'>{message.replace(/field|The/g, '').capitalize()}</div>,
            validators: {
                emailValidate: {  // name the rule
                    message: 'Please enter a valid email address',
                    rule: (val, params, validator) => {
                        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return validator.helpers.testRegex(
                            String(val).toLowerCase(),
                            regex
                        );
                    },
                    required: true  // optional
                }
            }
    });
}

componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.joineescount > 0 && this.props.joineescount === 0 && this.props.RCStatus === false && this.props.isexpert === true) {
        this.onSubmitStopSession();
    }
    if (prevProps.techStopSession !== this.props.techStopSession && this.props.isexpert == false && this.props.techStopSession === true) {
            this.onSubmitStopSession();
    }
    if (prevState.language !== this.state.language) {
        this.validationRules(this.state.language);
    } 
    if (prevProps.myPicture !== this.props.myPicture && this.props.myPicture !== undefined) {
        this.updateImageUrl(this.props.myPicture);
    }
}

componentDidMount = () => {
    this.delayedCallback = _.debounce(this.checkEmailExists, 500);
}

toggle = () => {
    this.setState({
        isOpen: !this.state.isOpen
    });
}

onSubmitSignout = () => {
	//console.log('signout called');
	const myLocation = "Bangalore North++East"; //getGeoLocation();
    const encryptedEmail = encryptData(this.props.email);//FQ3-417
	this.authService.fetch('signout', { //TP-2028
	  method: 'post',
	  /* TP-2028 headers: {'Content-Type': 'application/json'}, */
	  body: JSON.stringify({
	    email: encryptedEmail,
        isencrypted: true,
	    id: parseInt(this.props.id, 10),
	    groupid: parseInt(this.props.groupId, 10),
	    geoLocation: myLocation
	  })
	})
	.then(response => response.json())
	.then(data => {
        // adding this on success UQ3-208. Instead of the call adding it on success
        this.authService.logout();
        this.props.onRouteChange('signout');
        if(this.state.shareAssetAll){
            this.props.updateFileShare(false);
        }
        this.setState({
                        toggleStart: false, 
                        toggleMuteMike: false, 
                        toggleMuteAll: false, 
                        toggleChat: false, 
                        toggleShare: false, 
                        toggleShareScreen: false,
                        chatUserCount: 0, 
                        chatUserNameArr: [],
                        shareAssetAll: false,
                        headerCommand:'',
                        sessionJoinees: []
                    });
	})
	.catch(err => console.log(err.message))
}

onSubmitStartSession = () => {
    /* diagonastics(this.authService,{
        action: `click of header start session`,
        next_step: '',
        data: ``,
        error: 'none'
    }) */
	this.setState({
                    toggleStart: true, 
                    toggleMuteMike: false, 
                    toggleMuteAll: false, 
                    toggleChat: false, 
                    toggleShare: false,
                    toggleShareScreen: false,
                    chatUserCount: 0, 
                    chatUserNameArr: [],
                    shareAssetAll: false,
                    sessionJoinees:[],
                    disableStartStopClass: 'no-cursor-pointer',
                    disableImgClass: 'faded-image'
                });
	this.props.onSessionCmdChange('session_started');
	this.setState({headerCommand: 'session_started'});
}

onSubmitStopSession = () => {
    if(this.state.shareAssetAll){
        this.props.updateFileShare(false);
    }
    /* diagonastics(this.authService,{
        action: `click of header stop session`,
        next_step: '',
        data: ``,
        error: 'none'
    }) */
    this.setState({
                    toggleStart: false, 
                    toggleMuteMike: false, 
                    toggleMuteAll: false, 
                    toggleChat: false, 
                    toggleShare: false, 
                    toggleShareScreen: false,
                    chatUserCount: 0, 
                    chatUserNameArr: [],
                    shareAssetAll: false,
                    sessionJoinees:[]
                    //disableStartStopClass: 'no-cursor-pointer',
                    //disableImgClass: 'faded-image'
                });
	this.props.onSessionCmdChange('session_stopped');
	this.setState({headerCommand: 'session_stopped'});
    //this.onGoBack();
}

onSubmitMuteMike = () => {
	if(this.props.isSignedIn && this.props.route === 'session'){
		if(this.state.toggleMuteMike){ 
            // this.state.toggleMuteMike = false;
			this.setState({toggleMuteMike: false});
			this.props.onSessionCmdChange('session_unmutemike');
            this.setState({headerCommand: 'session_unmutemike'});
		}
		else{
            // this.state.toggleMuteMike = true;
			this.setState({toggleMuteMike: true});
			this.props.onSessionCmdChange('session_mutemike');
			this.setState({headerCommand: 'session_mutemike'});
		}
	}
}

onSubmitMuteAll = () => {
	if(this.props.joineescount > 0){
		if(this.state.toggleMuteAll){ 
            // this.state.toggleMuteAll = false;
            this.setState({toggleMuteAll: false});
			this.props.onSessionCmdChange('session_unmuteall');
			this.setState({headerCommand: 'session_unmuteall'});
		}
		else{
            // this.state.toggleMuteAll = true;
			this.setState({toggleMuteAll: true});
			this.props.onSessionCmdChange('session_muteall');
			this.setState({headerCommand: 'session_muteall'});
		}
	}
}

onClickChat = () => {
    /* diagonastics(this.authService,{
        action: `click of header Chat`,
        next_step: '',
        data: `toggleChat:${this.state.toggleChat}`,
        error: 'none'
    }) */
    if(this.props.joineescount > 0){
		if(this.state.toggleChat){ 
            this.setState({toggleChat: false});
			this.props.onSessionCmdChange('session_chat_off');
            this.setState({headerCommand: 'session_chat_off'});
            this.onToolTipClick();
		}
		else{
			this.setState({toggleChat: true});
			this.props.onSessionCmdChange('session_chat');
			this.setState({headerCommand: 'session_chat'});
            this.onToolTipClick();
			// if(this.state.toggleShare) this.onClickShare();
		}
	}
	// console.log('inside Header::onClickChat --- ', this.state.toggleChat, this.state.headerCommand);
}

onClickShare = () => {
    /* diagonastics(this.authService,{
        action: `click of header file share`,
        next_step: '',
        data: `toggleShare:${this.state.toggleShare}`,
        error: 'none'
    }) */
	if(this.props.joineescount > 0){
		if(this.state.toggleShare){
			this.setState({toggleShare: false});
			this.props.onSessionCmdChange('session_share_off');
            this.setState({headerCommand: 'session_share_off'});
		}
		else{
			this.setState({toggleShare: true});
            this.props.onSessionCmdChange('session_share');
			this.setState({headerCommand: 'session_share'});
			// if(this.state.toggleChat) this.onClickChat();
		}
	}
}

onClickScreenShare = () => {
    if(this.state.toggleShareScreen) {
        this.setState({toggleShareScreen: false});
        this.props.onSessionCmdChange('session_share_screen_off');
        this.setState({headerCommand: 'session_share_screen_off'});
    } else {
        this.setState({toggleShareScreen: true});
        this.props.onSessionCmdChange('session_share_screen');
        this.setState({headerCommand: 'session_share_screen'});
    }
}

onGoBack(){
    /* diagonastics(this.authService,{
        action: `click of go back button`,
        next_step: '',
        data: ``,
        error: 'none'
    }) */
    this.setState({
        headerCommand: '',
        toggleStart: true,
        showGuestForm: false,// to ensure popup is not left in open state NS2-115
        disableGuestSubmission: true, //NS2-131
        //disableStartStopClass: 'no-cursor-pointer',
        //disableImgClass: 'faded-image'
    });
	this.props.onGoBack();
}

onClickHamburger() {
    var x = document.getElementById('myTopnav');
   if (x.className === 'topnav') {
        x.className += ' responsive';
    }
    else 
    	x.className = 'topnav';
}

handleGroupNameChange = (e) => {
    this.setState({group_id: e.target.value});
    this.props.onChangeGroup(e.target.value);
}

componentWillReceiveProps(nextProps){
    if(nextProps.chatUserCount!==this.props.chatUserCount){
        this.setState({
            chatUserCount: this.state.chatUserCount + 1,
            chatUserNameArr: [...nextProps.chatNameArr]
        });
    }
    if(nextProps.headerFileShare!==this.props.headerFileShare){
        this.setState({
            shareAssetAll: nextProps.headerFileShare
        });
    }
    if(nextProps.sessionJoinees!==this.props.sessionJoinees){
        this.setState({
            sessionJoinees: nextProps.sessionJoinees
        });
    }

    if(nextProps.chatAll!==this.props.chatAll){
        this.setState({
            toggleChat: nextProps.chatAll
        });
    }
    if (nextProps.screenShareAll !== this.props.screenShareAll) {
        this.setState({
            toggleShareScreen: nextProps.screenShareAll
        });
    }

    if(nextProps.shouldStartStopChange!== this.props.shouldStartStopChange){
        /* changes done to enable/disable the Header buttons based in the 
        * shouldStartStopChange flag that is set from session page */
        if (nextProps.shouldStartStopChange === false) {
            // for disabling the Header Icons
            this.setState({
                disableStartStopClass: 'no-cursor-pointer',
                disableImgClass: 'faded-image'
            })
            this.onGoBack();
        } else if (nextProps.shouldStartStopChange === true) {
            // for enabling the Header Icons
            this.setState({
                disableStartStopClass: '',
                disableImgClass: ''
            });
        }
        
    }

    if(nextProps.groupId !== this.props.groupId){
        this.setState({
            group_id: nextProps.groupId
        })
    }

    if(nextProps.language!==this.props.language){
        this.setState({
            language: nextProps.language
        })
    }

    if(nextProps.creditsAvailable!==this.props.creditsAvailable){
        this.setState({
            creditsAvailable: nextProps.creditsAvailable
        })
    }

}

onToolTipClick = () => {
    this.setState({
        chatUserCount: 0,
        chatUserNameArr: []
    },() => {
    });
}

hasQueryParams = (str) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const strPresent = urlParams.has(str);
    return strPresent;
}

checkEmailExists = (email,emailField) => {
    if (!this.validator.fieldValid(emailField))  {
        this.setState({ isEmailAlreadyRegistered: false });
        return
    };
    this.setState({
        disableGuestSubmission: true
    });
    fetch(window._env_.REACT_APP_API_URL+'/checkEmailExists/' + email, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
    })
    .then(response => {
        if(response.status === 200) 
            return response.json();
        else
            throw new Error(response.statusText);
    })
    .then(isExists => {
        this.setState({
            isEmailAlreadyRegistered: isExists,
            disableGuestSubmission: isExists
        });
    })
    .catch((error) => {
        console.log(error);
    })
}

onChangeForm = name => event => {
    event.persist();
    if (name === 'email') {
        this.delayedCallback(event.target.value,event.target.name)
    }

    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    this.setState((prevState) => (
        {guestUser: {...prevState.guestUser, [name]: value}
    }));
}

showHideGuestForm = () => {
    this.setState((prevState) => ({
        showGuestForm: !prevState.showGuestForm,
        guestUser: {isexpert: false},
        isEmailAlreadyRegistered:false,
        disableGuestSubmission:true
    }),() => {
        // clear the previous messages if any
        this.validator.hideMessages();
    });
}

guestForm = () =>{
    return (
        <>
            <Modal isOpen={this.state.showGuestForm} toggle={this.showHideGuestForm}>
            <ModalHeader toggle={this.showHideGuestForm} cssModule={{'modal-title': 'w-100 text-center'}}><Trans>Add User</Trans></ModalHeader>
            {/* <ModalBody> */}
                <Form>
                    <div className="__modal-body pb-0">
                        <div className="input-wrapper margin-bottom-15">
                            <I18n>
                                {({ i18n }) => 
                                    <>
                                        <input className='signup-input-field' name={i18n._(t`first_name`)} type="text" placeholder={i18n._(t`First Name`) + '*'} value={this.state.guestUser.first_name || ''} onChange={this.onChangeForm('first_name')} />
                                        {this.validator.message(i18n._(t`first_name`), this.state.guestUser.first_name, 'required|alpha_num_space')}
                                    </>
                                }
                            </I18n>
                        </div>
                        <div className="input-wrapper margin-bottom-15">
                            <I18n>
                                {({ i18n }) => 
                                <>
                                    <input className='signup-input-field' name={i18n._(t`last_name`)} type="text" placeholder={i18n._(t`Last Name`)+ '*'} value={this.state.guestUser.last_name || ''} onChange={this.onChangeForm('last_name')} />
                                    {this.validator.message(i18n._(t`last_name`), this.state.guestUser.last_name, 'required|alpha_num_space')}
                                </>
                                }
                            </I18n>
                        </div>
                        <div className="input-wrapper margin-bottom-15">
                            <I18n>
                                {({ i18n }) => 
                                <>
                                    <input className='signup-input-field' name={i18n._(t`user_email`)} type="text" placeholder={i18n._(t`Email`)+ '*'} value={this.state.guestUser.email || ''} onChange={this.onChangeForm('email')} />
                                    {this.validator.message(i18n._(t`user_email`), this.state.guestUser.email, 'required|emailValidate')}
                                    { this.state.isEmailAlreadyRegistered ? <div className="add-users-validation-msg"><Trans>This email is already registered.</Trans></div> : '' }	
                                </>											
                                }
                            </I18n>                                        
                        </div>
                        <label htmlFor="isexpert">
                            <input className="checkbox" type="checkbox" name="isexpert" checked={this.state.guestUser.isexpert} onChange={this.onChangeForm('isexpert')}></input>
                            <Trans>User is an expert</Trans>
                        </label>                            
                        <div className="__modal-footer flex-center">
                            <Button showSpinner={this.state.isLoading} disabled={this.state.disableGuestSubmission} onClick={this.addGuest}><Trans>Add</Trans></Button>
						</div>
                    </div>			
                </Form>
            {/* </ModalBody> */}
            </Modal>
        </>
    );
}

addGuest = (e) => {
    e.preventDefault();
    if (!this.validator.allValid() || this.state.isEmailAlreadyRegistered) {
        this.validator.showMessages();
        // rerender to show messages for the first time
        this.forceUpdate();
        return;
    }
    let {guestUser} = this.state;
    guestUser.groupid = this.props.groupId;
    this.setState({ isLoading: true });
    this.authService.fetch('addGuestUser', {
        method: 'post',
        body: JSON.stringify(
            guestUser
        )
    })
    .then(response => {
        this.setState({
            retCode: response.status,
            showGuestForm: false,
            disableGuestSubmission: true
        });
        if(response.status === 200){
            return response.json();
        } else {
            throw new Error(response.body);
        }
    })
    .then(data => {
        if(data !== null && data !== undefined){
            this.setState((prevState) => ({
                guestUser: {isexpert: false},
                bResponded: true,
                isLoading: false,
                responseText: i18nMark('New user added successfully!'),
                creditsAvailable: prevState.creditsAvailable - 1
            }));
        }
    })
    .catch((error) => {
        this.setState({
            guestUser: {isexpert: false},
            showGuestForm: false,
            disableGuestSubmission: true,
            retCode: error.status, 
            bResponded: true, 
            isLoading: false,
            responseText: i18nMark('Failed to add a new user. Please review the entries and resubmit again.')
        });
    })
}

formMsg = () => {
    let colorText = '#485890';
    if(this.state.retCode !== 200) colorText = 'red';
    return(
        <div className='modale opened'>
            <div className='__modal-dialog'>
                <form>
                    <div className="__modal-header">
                        <h4 style={{color: colorText}}><Trans id={this.state.responseText}/></h4>
                    </div>
                    <div className="__modal-footer flex-center">
                        <button className="btn-green" onClick={(e) =>this.onClickClose(e)}><Trans id='OK'/></button>
                    </div>
                </form>
            </div>
        </div>
    );
}

onClickClose = (e) => {
    e.preventDefault();
    this.setState({bResponded: false});
}

//TP-3199 -- set the image (in sessionStorage) as the profile picture
updateImageUrl = (url) => {
    try {
        let imageEle = document.getElementById("profile-pic");
        let fileUrl = url;
        //TP-3506
        if (fileUrl !== "" && fileUrl.includes("base64") === false && fileUrl.includes("profile.png") === false)            
            fileUrl = 'data:image/jpg;base64,'+ url;
        else
            fileUrl = "/profile.png";
        if (imageEle && imageEle !== null)
            imageEle.src = fileUrl;
        else {
            this.profileTimer = setTimeout(() => {
                let imageEle = document.getElementById("profile-pic");
                if (imageEle && imageEle !== null)
                    imageEle.src = fileUrl;
                clearTimeout(this.profileTimer);
            }, 2500);
        }

        //let stringLength = fileUrl.length - 'data:image/png;base64,'.length;

        //var sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
        //var sizeInKb=sizeInBytes/1000;
        //console.log("Size of the file??", sizeInKb);
        //sessionStorage.setItem('my_profile_pic', fileUrl)
    } catch (error) {
        printConsole(`error occured in Header updateImageUrl method ${error}`);
    }    
}

openMyProfile = (e) => {
    e.preventDefault();
    this.props.openMyProfile(true);
}

render() {
	let str = i18nMark('Welcome!');
	let divStyle = {
		  color: '#23c97d',
		  fontSize:'36px'
        };
    let {disableStartStopClass, disableImgClass} = this.state;
    const { isMobileDevice } = this.props;
	//console.log('render on Header called with:', this.props.isSignedIn, this.props.route, this.state.headerCommand, this.props.joineescount);

	if(this.props.route === 'session'){
        return null;// for webrtc and OT sessions no header bar
    }
    else if(this.props.isSignedIn && this.props.route === 'home'){
        // TP-3199 -- to be removed later when the API integration for fetch Profile picture is to be done
        if (this.props.myPicture !== undefined && this.props.myPicture !== null && this.props.myPicture !== "")
            this.updateImageUrl(this.props.myPicture);
            // if(this.props.firstname) str = 'Welcome ' + this.props.firstname + '!';	    
        // TP-3289 -- Implemented the Header Bar UI with Profile pic for mobiles
        const pageName = sessionStorage.getItem('pageName');
        //TP-4028
        return (
            <div id="header_bar" style={(pageName === "MyProfile") ? {display: "none"} : {display: "block"}}>
                <I18n>
                    {({ i18n }) =>
                        isMobileDevice || window.innerWidth <= 992 ?
                        <>
                            <>
                            {/* <a className="navbar-brand d-none d-lg-block no-cursor-pointer" href="javascript:void(0)">
                                <img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} alt='logo' style={{width:'142px'}}/>
                            </a> */}
                            {/* <hr className="hr-separator d-none d-lg-block"/> */}
                            {/* <NavbarToggler onClick={() => this.toggle()} /> */}
                            <nav className=" navbar-gradient fixed-top w-100" style={{zIndex:'3'}}>
                                <Row className=" w-100"  >
                                    <Col sm="6" className="signout-no-decoration margin-top-15 justify-content-left pb-0 pt-0 d-lg-block df-cursor">
                                        <a href="" onClick={this.openMyProfile} data-tool-tip={i18n._(t`View My Profile`)}><img id="profile-pic" className="profile-pic-sm"  src={"/profile.png"} alt='profile_logo' /></a>
                                        <span className="name-class"><Trans id="Welcome {name}" values={{name: this.props.firstname}}/></span>
                                    </Col>

                                    {/* <hr className="hr-separator d-none d-lg-block"/> */}

                                    <Col sm="6" className="d-lg-none justify-content-end navbar-right margin-top-15 pt-0">
                                        <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)}  style={{textDecoration: "none"}}>
                                            <span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                        </a>
                                    </Col>

                                </Row>
                            </nav>
                        </>
                        </>
                        : 
                        <> 
                            <nav className="navbar navbar-dark py-0 relativebg-primary navbar-expand-lg py-md-0 navbar-gradient fixed-top" style={{zIndex:'3'}}>
                                <a className="navbar-brand d-none d-lg-block no-cursor-pointer" href="javascript:void(0)">
                                    <img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} className='padding-logo' alt='logo' style={{width:'142px', paddingTop: '2px', paddingBottom: '2px'}}/>
                                </a>
                                <hr className="hr-separator d-none d-lg-block"/>
                                <NavbarToggler onClick={() => this.toggle()} />
                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <ul className="text-center navbar-nav nav-fill w-100 justify-content-end align-items-lg-center">
                                        <span className="navbar-text signout-no-decoration  d-none d-lg-block df-cursor">
                                            <a href="" onClick={this.openMyProfile} data-tool-tip={i18n._(t`View My Profile`)}><img id="profile-pic" className="profile-pic" src={"/profile.png"} alt='profile_logo' /></a>
                                            <span className="name-class"><Trans id="Welcome {name}" values={{name: this.props.firstname}}/></span>
                                        </span>

                                        <hr className="hr-separator d-none d-lg-block"/>

                                        <li className="navbar-left d-none d-lg-block" style={{paddingLeft:'15px'}}>
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)} style={{textDecoration: "none"}}>
                                                <i className="fas fa-sign-out-alt" ></i><span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>
                                        <li className="navbar-left d-lg-none pb-2 pt-2">
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)}  style={{textDecoration: "none"}}>
                                                <span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>

                                    </ul>


                                </Collapse>
                            </nav>
                        </>
                }
                </I18n>
            </div>
        );
	}
	else if(this.props.isSignedIn && this.props.route === 'session' && this.state.headerCommand ==='session_stopped'){
        let navContent;
        // d-none, d-lg-block to hide only in screens smaller than lg
        // d-lg-none to hide only in screens wider than lg
        if(this.props.isexpert){
            navContent = <I18n>
                {({ i18n }) => <nav className="navbar navbar-dark py-0 bg-primary navbar-expand-lg py-md-0 navbar-gradient fixed-top" style={{zIndex:'3'}}>
                                <a className="navbar-brand d-none d-lg-block no-cursor-pointer" href="javascript:void(0)">
                                    <img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} className='padding-logo' alt='logo' style={{width:'142px', paddingTop: '2px', paddingBottom: '2px'}}/>
                                </a>
                                <hr className="hr-separator d-none d-lg-block"/>
                                <NavbarToggler onClick={() => this.toggle()} />
                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <ul className="navbar-nav nav-fill w-100 align-items-lg-center">

                                        <li className="nav-item py-0 d-none d-lg-block"><a href="javascript:void(0)" className="nav-link"></a></li>
                                        {/*<li className="nav-item py-0 d-none d-lg-block">
                                                                                    <a href="javascript:void(0)" className="nav-link" onClick={() => this.onGoBack()}>
                                                                                        <i className="fas fa-arrow-left"  title={i18n._(t`Dashboard`)}></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li className="nav-item py-0 d-lg-none" onClick={() => this.onGoBack()}>
                                                                                    <a href="javascript:void(0)" className="nav-link">
                                                                                        <span className="signout-no-decoration"  title={i18n._(t`Stop session to go back to Dashboard`)}><Trans id="session.dashBoard" /></span>
                                                                                    </a>
                                                                                </li>*/}

                                        <li className="nav-item py-0">
                                            <select className="selectBox" defaultValue={this.props.groupId} value={this.state.group_id} onChange={this.handleGroupNameChange} disabled={this.state.toggleStart}>
                                                {/* <option value="">Select Group</option> */}
                                                {
                                                    this.props.assignedGroups.map(g => {
                                                        return <option value={g.id} key={g.id}>{g.group_name}</option>     
                                                    })
                                                }
                                            </select>
                                        </li>

                                        <li className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link relative no-cursor-pointer">
                                                <i className="far fa-fw fa-user" style={divStyle} title={i18n._(t`session.participantCount`)}></i>
                                                {this.props.joineescount > 0 ? 
                                                    <span className="participant-count badge badge-danger badge-pill"> {this.props.joineescount} </span>
                                                : null
                                                }
                                            </a>
                                        </li>

                                        {this.props.showHeaderActionButtons && this.props.expert_ss_enabled === true && !this.props.isSafari && this.props.hideExpertSSAll === false ?
                                            <li className="nav-item py-0 d-none d-lg-block">
                                                <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                    <i className="fab fa-chromecast font-awesome-blue" title={i18n._(t`session.expertSSAll`)}></i>
                                                </a>
                                            </li>
                                            : null
                                        }                                              
                                        
                                        <li className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                <i className="far fa-comments font-awesome-blue"  title={i18n._(t`session.chatAll`)}></i>
                                            </a>
                                        </li>
                                        { /**NS2-137 */ this.props.showHeaderActionButtons ? 
                                            <li className="nav-item py-0 d-none d-lg-block">
                                                <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                    <i className="fas fa-upload font-awesome-blue" title={i18n._(t`session.shareAssetToAll`)}
                                                    ></i>
                                                </a>
                                            </li>
                                            :
                                            null
                                        }
                                        <li className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                <i  className="fas fa-microphone font-awesome-blue" title={i18n._(t`session.muteMike`)}
                                                ></i>
                                            </a>
                                        </li>
                                        { /**NS2-137  this.props.showHeaderActionButtons ? */}
                                            <li className="nav-item py-0 d-none d-lg-block">
                                                <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                    <i className= "fas fa-volume-up font-awesome-blue" title={i18n._(t`session.muteAll`)}></i>
                                                </a>  
                                            </li>                                        
                                        
                                        {this.state.showStartStop ?
                                        <>
                                            <li className="nav-item py-0 d-none d-lg-block">
                                                {/* <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStartSession()}> */}
                                                    <img src={startButton} alt="Start" style={{width: '100px'}} onClick={() => this.onSubmitStartSession()}
                                                    title={i18n._(t`session.startStream`)} className={disableImgClass + ' cursor-pointer ' +disableStartStopClass}/>
                                                {/* </a> */}
                                            </li>
                                            <li className="nav-item py-0 d-lg-none">
                                                <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStartSession()}
                                                    title={i18n._(t`session.startStream`)}>
                                                    <span className="signout-no-decoration"><Trans id="session.start"></Trans></span>
                                                </a>
                                            </li>
                                        </> 
                                        : null}

                                        <hr className="hr-separator d-none d-lg-block"/>

                                        <li className="navbar-left d-none d-lg-block" style={{paddingLeft:'15px'}}>
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)} style={{textDecoration: "none"}}>
                                                <i className="fas fa-sign-out-alt" ></i><span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>
                                        <li className="navbar-left d-lg-none pb-2">
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)}  style={{textDecoration: "none"}}>
                                                <span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>
                                    </ul>

                                </Collapse>
                            </nav>
            }
            </I18n>

        } else {
            navContent =  <I18n>
            {({ i18n }) => <nav className="navbar navbar-dark py-0 bg-primary navbar-expand-lg py-md-0 navbar-gradient fixed-top" style={{zIndex:'3'}}>
                                <a className="navbar-brand d-none d-lg-block no-cursor-pointer" href="javascript:void(0)">
                                    <img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} className='padding-logo' alt='logo' style={{width:'142px', paddingTop: '2px', paddingBottom: '2px'}}/>
                                </a>
                                <hr className="hr-separator d-none d-lg-block"/>
                                <NavbarToggler onClick={() => this.toggle()} />
                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <ul className="navbar-nav nav-fill w-100 align-items-lg-center">

                                    <li className="nav-item py-0 d-none d-lg-block"><a href="javascript:void(0)" className="nav-link"></a></li>
                                    <li className="nav-item py-0 d-none d-lg-block"><a href="javascript:void(0)" className="nav-link"></a></li>
                                    <li className="nav-item py-0 d-none d-lg-block"><a href="javascript:void(0)" className="nav-link"></a></li>
                                    <li className="nav-item py-0 d-none d-lg-block"><a href="javascript:void(0)" className="nav-link"></a></li>
                                    <li className="nav-item py-0 d-none d-lg-block"><a href="javascript:void(0)" className="nav-link"></a></li>
                                    <li className="nav-item py-0 d-none d-lg-block"><a href="javascript:void(0)" className="nav-link"></a></li>
                                        {/*<li className="nav-item py-0 d-none d-lg-block">
                                                                                    <a href="javascript:void(0)" className="nav-link" onClick={() => this.onGoBack()}>
                                                                                        <i className="fas fa-arrow-left" title={i18n._(t`session.dashBoard`)}></i>
                                                                                    </a>
                                                                                </li>
                                                                                <li className="nav-item py-0 d-lg-none">
                                                                                    <a href="javascript:void(0)" className="nav-link" onClick={() => this.onGoBack()}>
                                                                                    <span className="signout-no-decoration"><Trans id="session.dashBoard" /></span>
                                                                                    </a>
                                                                                </li>*/}

                                        <li className="nav-item py-0">
                                            <select className="selectBox" defaultValue={this.props.groupId} value={this.state.group_id} onChange={this.handleGroupNameChange} disabled={this.state.toggleStart}>
                                                {/* <option value="">Select Group</option> */}
                                                {
                                                    this.props.assignedGroups.map(g => {
                                                        return <option value={g.id} key={g.id}>{g.group_name}</option>     
                                                    })
                                                }
                                            </select>
                                        </li>

                                        <li className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                <i  className="fas fa-microphone font-awesome-blue" title={i18n._(t`session.muteMike`)}
                                                ></i>
                                            </a>
                                        </li>

                                        {this.state.showStartStop ?
                                        <>
                                            <li className="nav-item py-0 d-none d-lg-block">
                                                {/* <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStartSession()}> */}
                                                    <img src={startButton} alt="Start" style={{width: '100px'}}  onClick={() => this.onSubmitStartSession()}
                                                    title={i18n._(t`session.startStream`)} className={disableImgClass + ' cursor-pointer ' +disableStartStopClass}/>
                                                {/* </a> */}
                                            </li>
                                            <li className="nav-item py-0 d-lg-none">
                                                <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStartSession()} 
                                                    title={i18n._(t`session.startStream`)}>
                                                    <span className="signout-no-decoration"><Trans id="session.start" /></span>
                                                </a>
                                            </li>
                                        </>
                                        : null}

                                        <hr className="hr-separator d-none d-lg-block"/>

                                        <li className="navbar-left d-none d-lg-block" style={{paddingLeft:'15px'}}>
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)} style={{textDecoration: "none"}}>
                                                <i className="fas fa-sign-out-alt" ></i><span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>
                                        <li className="navbar-left d-lg-none pb-2">
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)}  style={{textDecoration: "none"}}>
                                                <span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>
                                    </ul>
                                </Collapse>
                            </nav>
            }
            </I18n>        
        }
		return (
            <div  id="header_bar">
                {navContent}
            </div>

    	);
	}
	else if(this.props.isSignedIn && this.props.route === 'session' && this.props.joineescount > 0 && this.props.isexpert){
        const muteToggleIcon = this.state.toggleMuteMike ? "fa-microphone-slash font-awesome-red" : "fa-microphone"
        const muteToggleIconText = this.state.toggleMuteMike ? "Unmute" : "Mute"
        const muteToggleAllIcon = this.state.toggleMuteAll ? "fa-volume-off font-awesome-red" : "fa-volume-up"
        const muteToggleAllIconText = this.state.toggleMuteAll ? "Unmute All" : "Mute All"
        const chatToggleIcon = this.state.toggleChat ? "font-awesome-red" : ""
        const shareToggleIcon = this.state.shareAssetAll ? "font-awesome-red" : ""
        const shareScreenToggleIcon = this.state.toggleShareScreen ? "font-awesome-red" : ""
		return (
        // d-none, d-lg-block to hide only in screens smaller than lg
        // d-lg-none to hide only in screens wider than lg
        <div  id="header_bar">
            <I18n>
            {({ i18n }) =>
                <nav className="navbar navbar-dark py-0 bg-primary navbar-expand-lg py-md-0 navbar-gradient fixed-top" style={{zIndex:'3'}}>
                    <a className="navbar-brand d-none d-lg-block no-cursor-pointer" href="javascript:void(0)">
                        <img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} className='padding-logo' alt='logo' style={{width:'142px', paddingTop: '2px', paddingBottom: '2px'}}/>
                    </a>
                    <hr className="hr-separator d-none d-lg-block"/>
                    <NavbarToggler onClick={() => this.toggle()} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <ul className="navbar-nav nav-fill w-100 align-items-lg-center">

                            <li className="nav-item py-0  d-none d-lg-block"><a href="javascript:void(0)" className="nav-link"></a></li>

                            {/*<li className="nav-item py-0 d-none d-lg-block">
                                                            <a href="javascript:void(0)" className="nav-link disabled">
                                                                <i className="fas fa-arrow-left font-awesome-blue"  title={i18n._(t`Stop session to go back to Dashboard`)}></i>
                                                            </a>
                                                        </li>*/}

                            <li className="nav-item d-none d-lg-block py-0 pt-3">
                                <select className="selectBox" defaultValue={this.props.groupId} value={this.state.group_id} onChange={this.handleGroupNameChange} disabled={this.state.toggleStart}>
                                    {/* <option value="">Select Group</option> */}
                                    {
                                        this.props.assignedGroups.map(g => {
                                            return <option value={g.id} key={g.id}>{g.group_name}</option>     
                                        })
                                    }
                                </select>
                            </li>

                            <li className="nav-item d-lg-none py-0 pt-3">
                                <select className="selectBox" defaultValue={this.props.groupId} value={this.state.group_id} onChange={this.handleGroupNameChange} disabled={this.state.toggleStart}>
                                    {/* <option value="">Select Group</option> */}
                                    {
                                        this.props.assignedGroups.map(g => {
                                            return <option value={g.id} key={g.id}>{g.group_name}</option>     
                                        })
                                    }
                                </select>
                            </li>

                            <li className="nav-item py-0 d-none d-lg-block">
                                <a href="javascript:void(0)" className="nav-link relative">
                                    <i className="fas fa-fw fa-user" style={divStyle} title={i18n._(t`session.participantCount`)}></i>
                                    {this.props.joineescount > 0 ? 
                                        <Fragment>
                                            <UncontrolledTooltip placement="bottom" target="joineesTooltip">
                                                {this.state.sessionJoinees.map((e,i) => <Fragment key={i}><span>{e}</span><br /></Fragment>)}
                                            </UncontrolledTooltip>
                                            <span id="joineesTooltip" className="participant-count badge badge-danger badge-pill">
                                                {this.props.joineescount}
                                            </span>
                                        </Fragment>
                                    : null
                                    }
                                </a>
                            </li>
                            {(this.props.guest_user_enabled === true && this.state.creditsAvailable > 0 && !this.props.isGuest && this.props.showHeaderActionButtons )?
                                <> 
                                    <li className="nav-item py-0 d-none d-lg-block">
                                        <a href="javascript:void(0)" className="nav-link" onClick={() => this.showHideGuestForm()} >
                                            <i className= {"fas fa-user-plus"} title={i18n._(t`session.addGuest`)}></i>
                                        </a>
                                    </li>

                                    <li className="nav-item py-0 d-lg-none">
                                        <a href="javascript:void(0)" className="nav-link" onClick={() => this.showHideGuestForm()}>
                                            <span className="signout-no-decoration" title={i18n._(t`session.addGuest`)}><Trans id="session.addGuest" /></span>
                                        </a>
                                    </li>
                                </>
                            : ''}
                            {/** for NS2-282, NS2-117 && NS2-137*/}
                            {this.props.expert_ss_enabled === true && !this.props.isSafari && this.props.showHeaderActionButtons && this.props.hideExpertSSAll === false ?
                                this.props.screenShareMaxDiv ?
                                <li className="nav-item py-0 d-none d-lg-block">
                                    <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                        <i className="fab fa-chromecast font-awesome-blue" title={i18n._(t`session.expertSSAll`)}
                                        ></i>
                                    </a>
                                </li>
                                :
                                <li className="nav-item py-0 d-none d-lg-block">
                                    <a href="javascript:void(0)" className="nav-link" onClick={() => this.onClickScreenShare()} >
                                        <i className={"fab fa-chromecast " + shareScreenToggleIcon} title={i18n._(t`session.expertSSAll`)}
                                        ></i>
                                    </a>
                                </li>
                                : null
                            }

                            <li className="nav-item py-0 d-none d-lg-block">
                                <a href="javascript:void(0)" className="nav-link position-relative">
                                    <i className={"far fa-comments " + chatToggleIcon}  title={i18n._(t`session.chatAll`)}
                                    onClick={() => this.onClickChat()} ></i>
                                <span >
                                    {this.state.chatUserCount> 0 ?
                                        <Fragment>
                                            <UncontrolledTooltip placement="bottom" target="PopoverFocus" 
                                                id="tooltip-bottom">
                                                {this.state.chatUserNameArr.map((e,i) => <Fragment key={i}><span>{e}</span><br /></Fragment>)}
                                            </UncontrolledTooltip>
                                            <span id="PopoverFocus" className="chat-participant-count badge badge-danger badge-pill" >
                                                {this.state.chatUserCount} 
                                            </span>
                                        </Fragment>
                                        :null
                                    }
                                </span>                                    
                                </a>
                            </li>

                            <li className="nav-item py-0 d-lg-none">
                                <a href="javascript:void(0)" className="nav-link" onClick={() => this.onClickChat()}>
                                <span className="signout-no-decoration" title={i18n._(t`session.chatAll`)}><Trans id="session.chat" /></span>
                                </a>
                            </li>
                            {this.props.showHeaderActionButtons ?
                                this.props.fileShareMaxDiv ?
                                <li className="nav-item py-0 d-none d-lg-block">
                                    <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                        <i className="fas fa-upload font-awesome-blue" title={i18n._(t`session.shareAssetToAll`)}
                                        ></i>
                                    </a>
                                </li>
                                :
                                <li className="nav-item py-0 d-none d-lg-block">
                                    <a href="javascript:void(0)" className="nav-link" onClick={() => this.onClickShare()} style={this.state.shareAssetAll ? { pointerEvents: 'none' } : {}} >
                                        <i className={"fas fa-upload " + shareToggleIcon} title={i18n._(t`session.shareAssetToAll`)}
                                        ></i>
                                    </a>
                                </li>
                                :
                                null
                            }
                            {this.props.showHeaderActionButtons ?                        
                                <li className="nav-item py-0 d-lg-none">
                                    <a href="javascript:void(0)" className="nav-link" onClick={() => this.onClickShare()}>
                                        <span className="signout-no-decoration" title={i18n._(t`session.shareAssetToAll`)}><Trans id="session.shareAssetButton" /></span>
                                    </a>
                                </li>
                                :
                                null
                            }       

                            <li className="nav-item py-0 d-none d-lg-block">
                                <a href="javascript:void(0)" className={disableImgClass + ' nav-link ' +disableStartStopClass} onClick={() => this.onSubmitMuteMike()}>
                                    <i  className={"fas " + muteToggleIcon} title={i18n._(t`session.muteMike`)}
                                    ></i>
                                </a>
                            </li>
                            <li className="nav-item py-0 d-lg-none">
                                <a href="javascript:void(0)" className={disableImgClass + ' nav-link ' +disableStartStopClass} onClick={() => this.onSubmitMuteMike()} title={i18n._(t`session.muteMike`)} >
                                    <span className="signout-no-decoration"><Trans id={muteToggleIconText}/></span>
                                </a>
                            </li>
                            
                            {/*this.props.showHeaderActionButtons ?*/}
                                <li className="nav-item py-0 d-none d-lg-block">
                                    <a href="javascript:void(0)" className={disableImgClass + ' nav-link ' +disableStartStopClass} onClick={() => this.onSubmitMuteAll()} >
                                        <i className={"fas " + muteToggleAllIcon} title={i18n._(t`session.muteAll`)}></i>
                                    </a>  
                                </li>
                                
                            {/* this.props.showHeaderActionButtons ? */}
                                <li className="nav-item py-0 d-lg-none">
                                    <a href="javascript:void(0)" className={disableImgClass + ' nav-link ' +disableStartStopClass} onClick={() => this.onSubmitMuteAll()} >
                                        <span className="signout-no-decoration"><Trans id={muteToggleAllIconText}/></span>
                                    </a>  
                                </li>                                

                            {this.state.showStartStop ?
                            <>
                                <li className="nav-item py-0 d-none d-lg-block">
                                    {/* <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStopSession()}> */}
                                        <img src={stopButton} alt="Stop" style={{width: '100px'}} onClick={() => this.onSubmitStopSession()}
                                        title={i18n._(t`session.stopStream`)} className={disableImgClass + ' cursor-pointer ' +disableStartStopClass}/>
                                    {/* </a> */}
                                </li>
                                <li className="nav-item py-0 d-lg-none">
                                    <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStopSession()}
                                        title={i18n._(t`session.stopStream`)}>
                                        <span className="signout-no-decoration"><Trans id="session.stop"/></span>
                                    </a>
                                </li>
                            </>
                            : null}

                            { this.guestForm()}
                            {this.state.bResponded ? this.formMsg() : ''}
                            <hr className="hr-separator d-none d-lg-block"/>

                            <li className="navbar-left d-none d-lg-block" style={{paddingLeft:'15px'}}>
                                <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)} style={{textDecoration: "none"}}>
                                    <i className="fas fa-sign-out-alt" ></i><span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                </a>
                            </li>
                            <li className="navbar-left d-lg-none pb-2">
                                <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)}  style={{textDecoration: "none"}}>
                                    <span className="signout-no-decoration"><Trans id="Signout" /></span>
                                </a>
                            </li>
                        </ul>
                    </Collapse>
                </nav>
            }
            </I18n>
            </div>
    	);
	} 
	else if(this.props.isSignedIn && this.props.route === 'session'){
        const muteToggleIcon = this.state.toggleMuteMike ? "fa-microphone-slash font-awesome-red" : "fa-microphone"
        const muteToggleIconText = this.state.toggleMuteMike ? "Unmute" : "Mute"
        let navContent;
        // d-none, d-lg-block to hide only in screens smaller than lg
        // d-lg-none to hide only in screens wider than lg
        if(this.props.isexpert){
            navContent = <I18n>
                {({ i18n }) => <nav className="navbar navbar-dark py-0 bg-primary navbar-expand-lg py-md-0 navbar-gradient fixed-top" style={{zIndex:'3'}}>
                                <a className="navbar-brand d-none d-lg-block no-cursor-pointer" href="javascript:void(0)">
                                    <img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} className='padding-logo' alt='logo' style={{width:'142px', paddingTop: '2px', paddingBottom: '2px'}}/>
                                </a>
                                <hr className="hr-separator d-none d-lg-block"/>
                                <NavbarToggler onClick={() => this.toggle()} />
                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <ul className="navbar-nav nav-fill w-100 justify-content-end align-items-lg-center" >

                                        <li key='link_0' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link">
                                            </a>
                                        </li>
                                        
                                        {/*<li key='link_1' className="nav-item py-0 d-none d-lg-block">
                                                                                    <a href="javascript:void(0)" className="nav-link disabled">
                                                                                        <i className="fas fa-arrow-left font-awesome-blue"  title={i18n._(t`Stop session to go back to Dashboard`)}></i>
                                                                                    </a>
                                                                                </li>*/}

                                        <li key='link_1' className="nav-item  d-none d-lg-block py-0">
                                            <select className="selectBox" defaultValue={this.props.groupId} value={this.state.group_id} onChange={this.handleGroupNameChange} disabled={this.state.toggleStart}>
                                                {/* <option value="">Select Group</option> */}
                                                {
                                                    this.props.assignedGroups.map(g => {
                                                        return <option value={g.id} key={g.id}>{g.group_name}</option>     
                                                    })
                                                }
                                            </select>
                                        </li>

                                        <li key='link_2' className="nav-item d-lg-none py-0 pt-3">
                                            <select className="selectBox" defaultValue={this.props.groupId} value={this.state.group_id} onChange={this.handleGroupNameChange} disabled={this.state.toggleStart}>
                                                {/* <option value="">Select Group</option> */}
                                                {
                                                    this.props.assignedGroups.map(g => {
                                                        return <option value={g.id} key={g.id}>{g.group_name}</option>     
                                                    })
                                                }
                                            </select>
                                        </li>

                                        <li key='link_3' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link relative no-cursor-pointer">
                                                <i className="fas fa-fw fa-user" style={divStyle} title={i18n._(t`Participants count`)}>
                                                </i>
                                                {this.props.joineescount > 0 ? 
                                                    <span className="participant-count badge badge-danger badge-pill"> {this.props.joineescount} </span>
                                                : null
                                                }
                                            </a>
                                        </li>

                                        {this.props.showHeaderActionButtons && this.props.expert_ss_enabled === true && !this.props.isSafari && this.props.hideExpertSSAll === false ? 
                                            <li key='link_4' className="nav-item py-0 d-none d-lg-block">
                                                <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                    <i className="fab fa-chromecast font-awesome-blue" title={i18n._(t`session.expertSSAll`)}
                                                    ></i>
                                                </a>
                                            </li>
                                            : null
                                        }

                                        <li key='link_5' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                <i className="far fa-comments font-awesome-blue"  title={i18n._(t`session.chatAll`)}
                                                ></i>
                                            </a>
                                        </li>
                                        { /**NS2-137 */ this.props.showHeaderActionButtons ? 
                                            <li key='link_6' className="nav-item py-0 d-none d-lg-block">
                                                <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                    <i className="fas fa-upload font-awesome-blue" title={i18n._(t`session.shareAssetToAll`)}
                                                    ></i>
                                                </a>
                                            </li>
                                            :
                                            null
                                        }
                                        <li key='link_7' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className={disableImgClass + ' nav-link ' +disableStartStopClass} onClick={() => this.onSubmitMuteMike()}>
                                                <i  className={"fas " + muteToggleIcon} title={i18n._(t`session.muteMike`)} 
                                                ></i>
                                            </a>
                                        </li>
                                        <li key='link_8' className="nav-item py-0 d-lg-none">
                                            <a href="javascript:void(0)" className={disableImgClass + ' nav-link ' +disableStartStopClass} onClick={() => this.onSubmitMuteMike()} title={i18n._(t`session.muteMike`)}>
                                                <span className="signout-no-decoration"><Trans id={muteToggleIconText}/></span>
                                            </a>
                                        </li>

                                        { /**NS2-137  this.props.showHeaderActionButtons ?*/}
                                            <li key='link_9' className="nav-item py-0 d-none d-lg-block">
                                                <a href="javascript:void(0)" className="nav-link disabled" aria-disabled="true">
                                                    <i className= "fas fa-volume-up font-awesome-blue" title={i18n._(t`session.muteAll`)}></i>
                                                </a>  
                                            </li>
                                            
                                        {this.state.showStartStop ?
                                        <>
                                            <li key='link_10' className="nav-item py-0 d-none d-lg-block">
                                                {/* <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStopSession()}> */}
                                                    <img src={stopButton} alt="Stop" style={{width: '100px'}}  onClick={() => this.onSubmitStopSession()}
                                                    title={i18n._(t`session.stopStream`)} className={disableImgClass + ' cursor-pointer ' +disableStartStopClass}/>
                                                {/* </a> */}
                                            </li>
                                            <li key='link_11' className="nav-item py-0 d-lg-none">
                                                <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStopSession()} 
                                                    title={i18n._(t`session.stopStream`)}>
                                                    <span className="signout-no-decoration"><Trans id="session.stop"/></span>
                                                </a>
                                            </li>
                                        </>
                                        : null}

                                        <hr className="hr-separator d-none d-lg-block"/>

                                        <li key='link_12' className="navbar-left d-none d-lg-block" style={{paddingLeft:'15px'}}>
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)}  style={{textDecoration: "none"}}>
                                                <i className="fas fa-sign-out-alt" ></i><span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>
                                        <li key='link_13' className="navbar-left d-lg-none pb-2">
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)}  style={{textDecoration: "none"}}>
                                                <span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>
                                    </ul>
                                </Collapse>
                            </nav>
                }
                </I18n>
        }else{
            navContent= <I18n>
                {({ i18n }) => <nav className="navbar navbar-dark py-0 bg-primary navbar-expand-lg py-md-0 navbar-gradient fixed-top" style={{zIndex:'3'}}>
                                <a className="navbar-brand d-none d-lg-block no-cursor-pointer" href="javascript:void(0)">
                                    <img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} className=' padding-logo' alt='logo' style={{width:'142px', paddingTop: '2px', paddingBottom: '2px'}}/>
                                </a>
                                <hr className="hr-separator d-none d-lg-block"/>
                                <NavbarToggler onClick={() => this.toggle()} />
                                <Collapse isOpen={this.state.isOpen} navbar>
                                    <ul className="navbar-nav nav-fill w-100 justify-content-end align-items-lg-center" >

                                        <li key='link_0' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link">
                                            </a>
                                        </li>
                                        <li key='link_1' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link">
                                            </a>
                                        </li>
                                        <li key='link_2' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link">
                                            </a>
                                        </li>
                                        <li key='link_3' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link">
                                            </a>
                                        </li>
                                        <li key='link_4' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link">
                                            </a>
                                        </li>
                                        <li key='link_5' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className="nav-link">
                                            </a>
                                        </li>
                                        
                                        {/*<li key='link_6' className="nav-item py-0 d-none d-lg-block">
                                                                                    <a href="javascript:void(0)" className="nav-link disabled">
                                                                                        <i className="fas fa-arrow-left font-awesome-blue"  title={i18n._(t`Stop session to go back to Dashboard`)}></i>
                                                                                    </a>
                                                                                </li>*/}

                                        <li key='link_7' className="nav-item py-0 d-none d-lg-block">
                                            <select className="selectBox" defaultValue={this.props.groupId} value={this.state.group_id} onChange={this.handleGroupNameChange} disabled={this.state.toggleStart}>
                                                {/* <option value="">Select Group</option> */}
                                                {
                                                    this.props.assignedGroups.map(g => {
                                                        return <option value={g.id} key={g.id}>{g.group_name}</option>     
                                                    })
                                                }
                                            </select>
                                        </li>

                                        <li key='link_8' className="nav-item  d-lg-none py-0 pt-3">
                                            <select className="selectBox" defaultValue={this.props.groupId} value={this.state.group_id} onChange={this.handleGroupNameChange} disabled={this.state.toggleStart}>
                                                {/* <option value="">Select Group</option> */}
                                                {
                                                    this.props.assignedGroups.map(g => {
                                                        return <option value={g.id} key={g.id}>{g.group_name}</option>     
                                                    })
                                                }
                                            </select>
                                        </li>

                                        <li key='link_9' className="nav-item py-0 d-none d-lg-block">
                                            <a href="javascript:void(0)" className={disableImgClass + ' nav-link ' +disableStartStopClass} onClick={() => this.onSubmitMuteMike()}>
                                                <i  className={"fas " + muteToggleIcon} title={i18n._(t`session.muteMike`)} 
                                                ></i>
                                            </a>
                                        </li>
                                        <li key='link_10' className="nav-item py-0 d-lg-none">
                                            <a href="javascript:void(0)" className={disableImgClass + ' nav-link ' +disableStartStopClass} onClick={() => this.onSubmitMuteMike()} title={i18n._(t`session.muteMike`)}>
                                                <span className="signout-no-decoration"><Trans id={muteToggleIconText}/></span>
                                            </a>
                                        </li>

                                        {this.state.showStartStop ?
                                        <>
                                            <li key='link_11' className="nav-item py-0 d-none d-lg-block">
                                                {/* <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStopSession()}> */}
                                                    <img src={stopButton} alt="Stop" style={{width: '100px'}} onClick={() => this.onSubmitStopSession()}
                                                    title={i18n._(t`session.stopStream`)} className={disableImgClass + ' cursor-pointer ' +disableStartStopClass}/>
                                                {/* </a> */}
                                            </li>
                                            <li key='link_12' className="nav-item py-0 d-lg-none">
                                                <a href="javascript:void(0)" className={"nav-link " + disableStartStopClass} onClick={() => this.onSubmitStopSession()} 
                                                title={i18n._(t`session.stopStream`)}>
                                                    <span className="signout-no-decoration"><Trans id="session.stop"/></span>
                                                </a>
                                            </li>
                                        </>
                                        : null}

                                        <hr className="hr-separator d-none d-lg-block"/>

                                        <li key='link_13' className="navbar-left d-none d-lg-block" style={{paddingLeft:'15px'}}>
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)}  style={{textDecoration: "none"}}>
                                                <i className="fas fa-sign-out-alt" ></i><span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>
                                        <li key='link_14' className="navbar-left d-lg-none pb-2">
                                            <a href="javascript:void(0)" className="small-nav-link" onClick={() => this.onSubmitSignout()} title={i18n._(t`Click to log out of the platform`)}  style={{textDecoration: "none"}}>
                                                <span className="signout-no-decoration"><Trans>Signout</Trans></span>
                                            </a>
                                        </li>
                                    </ul>
                                </Collapse>
                            </nav>
                }
                </I18n>
        }

            return (
            <div  id="header_bar">
                {navContent}
            </div>

    	);
	} 
	else if(this.props.route === 'signup'){
		str = i18nMark('Welcome to account Sign up!');
		return (
				<div className="topnav" id="myTopnav">
					<div className="topnav-centered">
						<a className="topnav centered"><Trans id={str}></Trans></a>
					</div>
					<div className='topnav-right'>
						<a onClick={() => this.props.onRouteChange('signin')}>
						<i className="fas fa-fw fa-user"></i><Trans>Sign in</Trans></a>
					</div>
				</div>
	    );
	}
	else if(this.props.route !== 'signin' && this.props.route !== 'signout'){
		/* return (
				<div className="topnav" id="myTopnav">
					<div className="topnav-centered">
						<a className="topnav centered">8349238409<Trans id={str}></Trans></a>
					</div>
					<div className='topnav-right'>
						<a onClick={() => this.props.onRouteChange('signin')}>
						<i className="fas fa-fw fa-user"></i><Trans>Sign in</Trans></a>
					</div>
				</div>
	    ); */
        return null;
	}
	else if(this.props.route === 'signout' && this.hasQueryParams('plan')){
		str = i18nMark('Welcome to account Sign up!');
		return (
				<div className="topnav" id="myTopnav">
					<div className="topnav-centered">
						<a className="topnav centered"><Trans id={str}></Trans></a>
					</div>
					<div className='topnav-right'>
						<a onClick={() => this.props.onRouteChange('signin')}>
						<i className="fas fa-fw fa-user"></i><Trans>Sign in</Trans></a>
					</div>
				</div>
	    );
    }else if(window._env_.REACT_APP_ENV === "demo" || window._env_.REACT_APP_ENV === "anz" || window._env_.REACT_APP_ENV === "sgp" || window._env_.REACT_APP_ENV === "production"){
        //T32-469,TP-505
        return null;
    } else { /* if(this.props.isiOS === false) */
		return null;	 
	} 
  }
}

Header.defaultProps = {
    assignedGroups: []
};

export default Header;
