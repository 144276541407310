import React from 'react';
import { t, Trans } from '@lingui/macro';
import { I18n } from "@lingui/react";
import GridActions from './GridActions';
import { printConsole } from '../helpers';
import SessionIcon from './SessionIcon.jsx';
import BubbleElement from '../BubbleElement/BubbleElement'; //TP-3423
let isVideoStreamstarted = false; //TP-3645

class WebrtcVideoTile extends React.Component {
    constructor(props) {
        super(props)
        this.videoRef = React.createRef();
        this.state = {
            columnWidth: 12,
            newVdoheight: props.vdoheight,
            vdowidth: props.vdowidth,
            newVdowidthLastRow: 0, //TP-2973
            lastRowNumCells: 0, //TP-2973
            participantWithVideos: [],
            muteMikeArray: [
                {
                    userId: 0,
                    toggleMike: false
                }
            ],
            muteAudioArray: [
                {
                    toggleAudio: false
                }
            ],
            checkFlag: props.checkFlag ? props.checkFlag : false, /**TP-3645 */           
        }
    }
      
    componentDidMount() {
        this.updateVideoStream();
        // T32-575 -- To listen for resizing of the Expert screen 
        // in Grid View Mode
        //TP-4936window.addEventListener("resize", this.updateVideoStream);
        const video = document.querySelector('video');        
        video && video.addEventListener('loadeddata', (event) => {
            //console.log('Yay! The readyState just increased to', event);
            //this.updateVideoStream();          
        });
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {videoConnection} = this.props;
        if(this.props.participants.length !== prevProps.participants.length){
            this.updateVideoStream()
        }

        if(this.props.userVideoCount !== prevProps.userVideoCount) {
            printConsole("video stream count has changed...");
            //TP-3681 -- Handling the Grid View rendering differently for new RM and old Expert camera disabled states
            if (this.props.changedParticipant !== null)
                this.updateVideoStreamIndividual(this.props.changedParticipant);//TP-3677
            else if(this.props.isAccountLevelExpertVideoDisabled === true) {
                this.updateVideoStream()
            }
        }

        if(this.props.redrawGrid !== prevProps.redrawGrid && this.props.redrawGrid === true){
            printConsole("New Stream added to a participant")
            let hasVideoTracks = false;
            this.props.participant.streams && this.props.participant.streams.forEach(s => {
                if (s.getVideoTracks().length > 0) 
                    hasVideoTracks = true;
            })
            if (hasVideoTracks === true) 
                this.updateVideoStream();
        }

        /* if (this.state.muteMikeArray !== prevState.muteMikeArray) {
            this.render();
        } */
        if (this.props.participant.disableVideo !== prevProps.participant.disableVideo) {
            // TP-3677 -- Commented out these changes to remove flickering of Video tiles
            //printConsole("Changed the participant's Video disabled flag ",this.props.participant.disableVideo)
            //this.updateVideoStream()
        }
        //TP-3611 -- When Expert turns off/on its own Preview video then trigger a re-draw of the Grid View
        //console.log(this.props.triggerGridView, prevProps.triggerGridView, this.props.changedParticipant);
        if (this.props.triggerGridView !== prevProps.triggerGridView && this.props.triggerGridView === true && this.props.changedParticipant !== null) {
            printConsole("triggered redrawing the Grid View of expert");
            //this.updateVideoStream();
            this.updateVideoStreamIndividual(this.props.changedParticipant); //TP-3677
        }
        else if (this.props.changedParticipant !== prevProps.changedParticipant && this.props.changedParticipant !== null){
            //TP-3736
            printConsole("draw the Grid again when one participant is changed");
            this.updateVideoStreamIndividual(this.props.changedParticipant); //TP-3677
        }
        if (this.props.participant !== prevProps.participant) {
            // TP-3677 -- Commented out these changes to remove flickering of Video tiles
            printConsole("participant has changed.....");
            this.updateVideoStream()
        }
        if (videoConnection && videoConnection.peerConnection && prevProps.videoConnection && prevProps.videoConnection.peerConnection
            && videoConnection.peerConnection.peerConnection && prevProps.videoConnection.peerConnection.peerConnection
            && videoConnection.peerConnection.peerConnection.iceConnectionState !== prevProps.videoConnection.peerConnection.peerConnection.iceConnectionState 
            /* && videoConnection.peerConnection.peerConnection.iceConnectionState === "connected" */)
        {
            printConsole("Video connection state is changed....");
            this.updateVideoStream()
        }
        if (prevProps.setMaxView !== this.props.setMaxView && !this.props.user.isexpert){
            this.updateVideoStream()
        }
        if (prevState.participantWithVideos.length !== this.state.participantWithVideos.length && this.state.participantWithVideos.length > 0){
            printConsole(`Participant count with video turned on changed`);
            this.resizeVideoStream();//TP-4508
        }
    }

    componentWillUnmount() {
        //TP-4936window.removeEventListener("resize", this.updateVideoStream); // T32-575
        //isVideoStreamstarted = false;
    }
    // TP-3677 -- To resolve the Video flickering issue. Only the changed participant Tile will be affected
    updateVideoStreamIndividual(pp) {
        const {participants, participant, user, sessionType, isAccountLevelExpertVideoDisabled, /**TP-3637*/ setMaxView} = this.props;
        if (user.isexpert && isAccountLevelExpertVideoDisabled === false) 
        {
            printConsole(participants.length);
            let participantWithVideos = [];
            /* if (sessionType === true) {
                participantWithVideos = participants.filter(p => p.streams && p.disableVideo === false);
            } else 
                participantWithVideos = participants.filter(p => !p.isexpert && !p.isadmin && p.streams && p.disableVideo === false); */
            //TP-3637 -- In cases where Expert Video is disabled on Account level then do not show the users whose Videos are turned off or disabled
            if (isAccountLevelExpertVideoDisabled === true)
                participantWithVideos = participants.filter(p => !p.isexpert && !p.isadmin && p.streams && p.disableVideo === false);
            else
                participantWithVideos = participants;
            let updatedStateVar = {};
            if (participant.email === pp.email) {
                this.streamTimer = setTimeout(() => {
                    if (participant.streams && participant.streams.length>0) {
                        participant.streams.forEach((stream) => {
                            if(this.videoRef.current){
                                this.videoRef.current.id = stream.id //TP-4861
                                this.videoRef.current.srcObject = stream
                            }
                        })
                    }
                    clearTimeout(this.streamTimer);
                }, 2000); //TP-4861
            }
            //printConsole("partcipants with User Video on ", participantWithVideos);
            if(participantWithVideos && participantWithVideos.length > 1){
                printConsole(`participants with Video turned on: ${participantWithVideos.length}`)
                printConsole(participantWithVideos);
                const [ newVdoheight, newVdowidth, numRows, numCells ] = this.getGridViewDim(participantWithVideos.length);// dimension of the grid
                const [ newVdowidthLastRow, lastRowNumCells ] = this.getLastRowViewDim(participantWithVideos.length, numRows, numCells); // TP-2973 -- dimension of the last row of the grid
                updatedStateVar.newVdoheight = newVdoheight;
                updatedStateVar.newVdowidth = newVdowidth;
                updatedStateVar.newVdowidthLastRow = newVdowidthLastRow; //TP-2973
                updatedStateVar.lastRowNumCells = lastRowNumCells; //TP-2973
                updatedStateVar.participantWithVideos = participantWithVideos;
                /* let muteMikeArray = [];
                for (let i=0; i< participants.length; i++){
                    muteMikeArray.push({
                        userId: participants[i].userId,
                        toggleMike: participants[i].disableVideo
                    })
                }
                updatedStateVar.muteMikeArray = muteMikeArray; */
                this.setState((prev) => ({
                    ...prev,
                    ...updatedStateVar
                }));
            } 
        } else if (user.isexpert && isAccountLevelExpertVideoDisabled === true) {
            this.updateVideoStream();
        }
    }
      
    updateVideoStream() {
        const {participant, participants, user, sessionType, isAccountLevelExpertVideoDisabled, /**TP-3637*/ setMaxView} = this.props;
        /* if (!user.isexpert && setMaxView === true && sessionType === true) {
            //console.log(participant.stream);
            if (participant.stream) {
                //console.log(this.videoRef.current);
                    if(this.videoRef.current){
                        //console.log("set video Reference..");
                        this.videoRef.current.srcObject = participant.stream
                    } else {
                        this.videoRef.current = {
                            srcObject: participant.stream
                        }
                    }             
            }
        }
        //TP-3052
        else */ if (!user.isexpert && sessionType === true) {
            console.log("updateVideoStream for technician....");
            printConsole(participants.length);
            let participantWithVideos = participants;
            let updatedStateVar = {};
            //console.log(participant.stream);
            if (participant.stream) {
                //console.log(this.videoRef.current);
                if(this.videoRef.current){
                    //console.log("set video Reference..");
                    this.videoRef.current.srcObject = participant.stream
                    if (setMaxView === true && participant.email !== user.email)  this.videoRef.current.id = participant.stream.id
                } /* else {
                    this.videoRef = React.createRef();
                    this.videoRef.current.srcObject = participant.stream
                    /* this.videoRef.current = {
                        srcObject: participant.stream
                    } 
                }  */                
            }
            //console.log("partcipants with User Video on ", participantWithVideos);
            if(participantWithVideos && participantWithVideos.length > 1){
                printConsole(`participants with Video turned on: ${participantWithVideos.length}`)
                printConsole(participantWithVideos);
                const [ newVdoheight, newVdowidth, numRows, numCells ] = this.getGridViewDim(participantWithVideos.length);// dimension of the grid
                //console.log(newVdoheight, newVdowidth, numRows, numCells);
                const [ newVdowidthLastRow, lastRowNumCells ] = this.getLastRowViewDim(participantWithVideos.length, numRows, numCells); // TP-2973 -- dimension of the last row of the grid
                updatedStateVar.newVdoheight = newVdoheight;
                updatedStateVar.newVdowidth = newVdowidth;
                updatedStateVar.newVdowidthLastRow = newVdowidthLastRow; //TP-2973
                updatedStateVar.lastRowNumCells = lastRowNumCells; //TP-2973
                updatedStateVar.participantWithVideos = participantWithVideos;
                /* let muteMikeArray = [];
                for (let i=0; i< participants.length; i++){
                    muteMikeArray.push({
                        userId: participants[i].userId,
                        toggleMike: participants[i].disableVideo
                    })
                }
                updatedStateVar.muteMikeArray = muteMikeArray; */
                this.setState((prev) => ({
                    ...prev,
                    ...updatedStateVar
                }));
            }
        } else {
            printConsole(participants.length);
            let participantWithVideos = [];
            /* if (sessionType === true) {
                participantWithVideos = participants.filter(p => p.streams && p.disableVideo === false);
            } else 
                participantWithVideos = participants.filter(p => !p.isexpert && !p.isadmin && p.streams && p.disableVideo === false); */
            //TP-3637 -- In cases where Expert Video is disabled on Account level then do not show the users whose Videos are turned off or disabled
            if (isAccountLevelExpertVideoDisabled === true)
                participantWithVideos = participants.filter(p => !p.isexpert && !p.isadmin && p.streams && p.disableVideo === false);
            else
                participantWithVideos = participants;
            let updatedStateVar = {};
            if (participant.streams && participant.streams.length>0) {
                participant.streams.forEach((stream) => {
                    if(this.videoRef.current){
                        this.videoRef.current.srcObject = stream
                    }
                })
            }
            //printConsole("partcipants with User Video on ", participantWithVideos);
            if(participantWithVideos && participantWithVideos.length > 1){
                printConsole(`participants with Video turned on: ${participantWithVideos.length}`)
                printConsole(participantWithVideos);
                const [ newVdoheight, newVdowidth, numRows, numCells ] = this.getGridViewDim(participantWithVideos.length);// dimension of the grid
                const [ newVdowidthLastRow, lastRowNumCells ] = this.getLastRowViewDim(participantWithVideos.length, numRows, numCells); // TP-2973 -- dimension of the last row of the grid
                updatedStateVar.newVdoheight = newVdoheight;
                updatedStateVar.newVdowidth = newVdowidth;
                updatedStateVar.newVdowidthLastRow = newVdowidthLastRow; //TP-2973
                updatedStateVar.lastRowNumCells = lastRowNumCells; //TP-2973
                updatedStateVar.participantWithVideos = participantWithVideos;
                /* let muteMikeArray = [];
                for (let i=0; i< participants.length; i++){
                    muteMikeArray.push({
                        userId: participants[i].userId,
                        toggleMike: participants[i].disableVideo
                    })
                }
                updatedStateVar.muteMikeArray = muteMikeArray; */
                this.setState((prev) => ({
                    ...prev,
                    ...updatedStateVar
                }));
            } /* else if (participants.length === 1) {
                printConsole("Only one participant is there on the call")
                const newVdoheight = this.getRowDim(1);
                const newVdowidth = this.getCellDim(1);
                printConsole(newVdoheight, newVdowidth);
                updatedStateVar.newVdoheight = newVdoheight;
                updatedStateVar.newVdowidth = newVdowidth;
                updatedStateVar.participantWithVideos = participants;
    
                this.setState((prev) => ({
                    ...prev,
                    ...updatedStateVar
                }));
            } else if (this.props.currentPrimaryExpert && this.props.currentPrimaryExpert.id === this.props.user.id && participants && participants.length > 1) {
                printConsole(`total participants: ${participants.length}`)
                let techparticipant = participants.filter(p => !p.isexpert && p.disableVideo === false);
                printConsole(`Video participants: ${techparticipant.length}`)
                const [ newVdoheight, newVdowidth ] = this.getGridViewDim(participants.length);// dimension of the grid
                printConsole(newVdoheight, newVdowidth);
                updatedStateVar.newVdoheight = newVdoheight;
                updatedStateVar.newVdowidth = newVdowidth;
                updatedStateVar.participantWithVideos = participants;
                
                this.setState((prev) => ({
                    ...prev,
                    ...updatedStateVar
                }));
            } */
        }    
    }

    //TP-4508
    resizeVideoStream = () => {
        const {participant, participants, user, sessionType, isAccountLevelExpertVideoDisabled, /**TP-3637*/ setMaxView} = this.props;
        if (user.isexpert) {
            printConsole(`resize the paricipant video tiles`)
            printConsole(participants.length);
            let participantWithVideos = [];            
            //TP-3637 -- In cases where Expert Video is disabled on Account level then do not show the users whose Videos are turned off or disabled
            if (isAccountLevelExpertVideoDisabled === true)
                participantWithVideos = participants.filter(p => !p.isexpert && !p.isadmin && p.streams && p.disableVideo === false);
            else
                participantWithVideos = participants;
            let updatedStateVar = {};
            if(participantWithVideos && participantWithVideos.length > 1){
                printConsole(`participants with Video turned on: ${participantWithVideos.length}`)
                printConsole(participantWithVideos);
                const [ newVdoheight, newVdowidth, numRows, numCells ] = this.getGridViewDim(participantWithVideos.length);// dimension of the grid
                const [ newVdowidthLastRow, lastRowNumCells ] = this.getLastRowViewDim(participantWithVideos.length, numRows, numCells); // TP-2973 -- dimension of the last row of the grid
                updatedStateVar.newVdoheight = newVdoheight;
                updatedStateVar.newVdowidth = newVdowidth;
                updatedStateVar.newVdowidthLastRow = newVdowidthLastRow; //TP-2973
                updatedStateVar.lastRowNumCells = lastRowNumCells; //TP-2973
                updatedStateVar.participantWithVideos = participantWithVideos;
                this.setState((prev) => ({
                    ...prev,
                    ...updatedStateVar
                }));
            } 
        }
    }

    getGridViewDim = (userCount) => {
        const Height = this.props.vdoheight;  
        const Width = this.props.vdowidth;

        let numRows, numCells, newVdoheight, newVdowidth;
        if(userCount && userCount > 1){
            if (userCount == 2) {
                numRows = 1;
                numCells = 2;                
            }else if(userCount <= 6 && userCount > 2){
                if (userCount <= 4){
                    numRows = 2;
                    numCells = 2;
                }else{
                    numRows = 2;
                    numCells = 3;                    
                }
            }else if(userCount <= 12 && userCount > 6){
                if (userCount <= 9){
                    numRows = 3;
                    numCells = 3; 
                }else{
                    numRows = 3;
                    numCells = 4;
                }
            }else if(userCount > 12){
                numRows = 4;
                numCells = Math.ceil((userCount)/4);                
            }
            newVdoheight = this.getRowDim(numRows);
            newVdowidth = this.getCellDim(numCells);
        }else{
            numRows = 0;
            newVdoheight= Height;
            newVdowidth= Width;
        }
        return [newVdoheight, newVdowidth, numRows, numCells];//TP-2973
    }

    getRowDim = (numRows) => {
        const Height = window.innerHeight;        
        const yGap = 10;
        const numPad = numRows + 1;

        let newVdoheight;
        newVdoheight = Math.floor((Height - (yGap * numPad))/numRows);

        return newVdoheight;
    }

    getCellDim = (numCells) => {
        const sideBarWidth = 80;
        const Width = window.innerWidth;
        const maxWidth = Math.floor(Width - sideBarWidth);
        const xGap = 6;
        const xMargin = 10;
        const numPad = numCells + 1;

        let newVdowidth = Math.floor((maxWidth - ((xGap + xMargin) * numPad))/numCells);

        return newVdowidth;
    }

    /** TP-2973 -- to calculate the width 
    * of Video tiles in the last row of Grid
    * only when more than one row is there */
    getLastRowViewDim = (userCount, numRows, numCellsMax) => {
        const lastRowNumCells = userCount % numCellsMax;
        //console.log(`total user count ${userCount}, Max no of cells ${numCellsMax}, number of rows ${numRows}`);
        if (numRows > 1 && lastRowNumCells > 0 && lastRowNumCells < numCellsMax) {
            const sideBarWidth = 80;
            const Width = window.innerWidth;
            const maxWidth = Math.floor(Width - sideBarWidth);
            const xGap = 6;
            const xMargin = 10;
            const numPad = lastRowNumCells + 1;

            let newVdowidthLastRow = Math.floor((maxWidth - ((xGap + xMargin) * numPad))/lastRowNumCells);

            //console.log(`video width of last row ${newVdowidthLastRow} & number of tiles in the last row ${lastRowNumCells}`);

            return [newVdowidthLastRow, lastRowNumCells];
        } else {
            return [0, lastRowNumCells];
        }
    }

    processZoomLevels = (level, i18n) => {
        switch (level) {
            case "1X":
                return i18n._(t`1X`);
            case "2X":
                return i18n._(t`2X`);
            case "3X":
                return i18n._(t`3X`);
            case "4X":
                return i18n._(t`4X`);
            case "5X":
                return i18n._(t`5X`);
            default:
                break;
        }
    }

    // onclick methods for Grid View Icons
    onClickMuteMikeofOne = ({userId}) => {
        this.props.toggleUserMike(userId);
    }  
    
    // onclick method for Speaker in Grid View
    onClickMuteSpeakerofOne = ({userId}) => {
        this.props.toggleUserSpeaker(userId);
    }

    // onclick method for Video in Grid View
    onClickTurnOffVideoofOne = ({userId}) => {
        this.props.toggleUserVideo(userId);
    }

    // onlick method for toggle Torch in Grid View
    onClickToggleTorch = ({userId}) => {
        this.props.toggleUserTorch(userId);
    }

    // onlick method for rotating Zoom level in Grid View
    onClickRotateZoom = ({userId}) => {
        this.props.rotateUserZoom(userId);
    }

    //onclick method for technician Grid View Tiles
    onClickTechMaximise = ({email, type}) => {
        this.props.onClickMaximize(email, type);
    }

    //TP-3423 -- onClick method for opening the user info bubble
    openBubble = (row) => {
        this.props.openBubble(row);
    }

    expertGrid = () => {
        const {participant, videoConnection, expertCanDisconnectUser, canTurnOffUserVideo, index, user, participants, sessionType, isAccountLevelExpertVideoDisabled /**TP-3637*/} = this.props;
        let {participantWithVideos} = this.state;
        let hasVideoTracks = false;
        participant.streams && participant.streams.forEach(s => {
            if (s.getVideoTracks().length > 0) 
                hasVideoTracks = true;
        })
        const experts = participants.filter(p=> p.isexpert === true);
        //TP-3645 -- let isVideoStreamstarted = false;
        if (this.state.checkFlag === true && participantWithVideos[0] && participantWithVideos[0].isThisMe !== true && participantWithVideos[0].streams) {
            participantWithVideos[0].streams.forEach(s => {
                if (s.getVideoTracks().length > 0) 
                    isVideoStreamstarted = true;
            })
        }
        if (participant.isThisMe === true) {
            participant.streams && participant.streams.forEach(stream => {
                //TP-5442
                if (stream.getVideoTracks().length > 0) {
                    stream.getVideoTracks().forEach(track => {
                        track.addEventListener(
                            "ended",
                            (event) => {
                              console.log("My video is ended");
                              this.props.triggerDeviceErrorAndEndSession("camera");
                            },
                            false,
                        );
                    })
                }
                //TP-5441
                if (stream.getAudioTracks().length > 0) {
                    stream.getAudioTracks().forEach(track => {
                        track.addEventListener(
                            "ended",
                            (event) => {
                              console.log("My audio is ended");
                              this.props.triggerDeviceErrorAndEndSession("microphone");
                            },
                            false,
                        );
                    })
                }
            })
        }
        //console.log("checkFlag ??",this.state.checkFlag," isVideoStreamstarted ====>",isVideoStreamstarted, " is this my preview?? ",participant.isThisMe);
        /* if (isVideoStreamstarted === true && participant.isThisMe === true) {  
            participant.streams && participant.streams.forEach(stream => {
                if(this.videoRef.current){
                    this.videoRef.current.srcObject = stream
                }
            })          
        }  */       
        //printConsole(`${participant.email} Has Video Tracks in its stream ${hasVideoTracks}`);
        /**TP-3205 */
        //let {muteMikeArray} = this.state;
        //console.log(participantWithVideos);
        //console.log("This user?? ",participant);
        /* let participantWithVideos;
        if (sessionType === true) {
            participantWithVideos = participants.filter(p => p.streams && p.disableVideo === false);
        } else 
            participantWithVideos = participants.filter(p => !p.isexpert && !p.isadmin && p.streams && p.disableVideo === false); */
        //const [ newVdoheight, newVdowidth, numRows, numCells ] = this.getGridViewDim(participantWithVideos.length);// dimension of the grid
        //const [ newVdowidthLastRow, lastRowNumCells ] = this.getLastRowViewDim(participantWithVideos.length, numRows, numCells); // TP-2973 -- dimension of the last row of the grid
        const { newVdoheight, newVdowidth, newVdowidthLastRow, lastRowNumCells} = this.state;//TP-4508
        //console.log(newVdoheight, newVdowidth, numRows, numCells, newVdowidthLastRow, lastRowNumCells);
        let flag = false;
        /**	TP-5071, TP-3014 && TP-2973*/
        if (newVdowidthLastRow !== 0 && lastRowNumCells > 0 && ((isAccountLevelExpertVideoDisabled === true && hasVideoTracks === true) || (isAccountLevelExpertVideoDisabled === false)) ) {
            let newIndex = index;
            //TP-4508 & TP-3636
            if (isAccountLevelExpertVideoDisabled === true /* && experts.length > 0 */) {
                newIndex = participantWithVideos.findIndex(p => p.userId === participant.userId);
            }
            //console.log(newIndex);
            let totalNum = participantWithVideos.length;
            //console.log("total count....?",totalNum);
            if(newIndex >= totalNum - lastRowNumCells)
                flag = true;
            //console.log("flag for resizing the tile??",flag);
        }      
        let gridClassName = "grid-card";
        let tileClassName = "fit-cover ";
        //TP-5672 TP-4861
        if (!participant.streams || !(participant.streams[0].getVideoTracks().length > 0) && participant.disableVideo === false) {
            gridClassName = "grid-card-logo";
            tileClassName = "fit-contain ";
            //TP-4861
            /* if (this.videoRef.current !== null) {
                let videoEle = document.getElementById(this.videoRef.current.id);
                if (videoEle) {
                    //videoEle.parentElement.parentElement.setAttribute("class", gridClassName);
                    videoEle.classList.remove("fit-cover");
                    videoEle.classList.add("fit-contain");
                }
            } */
        } else {
            gridClassName = "grid-card";
            tileClassName = "fit-cover ";
        }
        /**TP-3637 */
        let cardClass = "";
        if (participant.disableVideo === true) cardClass = "d-none";
        let zoomClass = "oneX";
        switch(participant.zoomLevel) {
            case 2:
                zoomClass = "twoX";
                break;
            case 3:
                zoomClass = "threeX";
                break;
            case 4:
                zoomClass = "fourX";
                break;
            case 5:
                zoomClass = "fiveX";
                break;
            default:
                zoomClass = "oneX";                
        }
        //TP-3423 -- participant info for bubble element
        const userdetails = {
            id: participant.userId,
            myPicture: participant.myPicture,
            first_name: participant.first_name,
            last_name: participant.last_name,
            email: participant.email,
            showOnlineIndicator: true,
            isexpert: participant.isexpert, /**TP-3657 */
            isadmin: participant.isadmin /**TP-3657 */
        }
        /**TP-3573 & TP-3572*/
        //TP-3679 -- New dimension & position of Profile picture in disabled state on Grid View tiles
        let profileHeight = Math.floor(newVdoheight - ((newVdoheight * 50)/100)) + "px"; let profileWidth = profileHeight;   
        let profileLeft = flag === false ? Math.floor((newVdowidth - Math.floor(newVdoheight - ((newVdoheight * 50)/100)))/2) + "px" : Math.floor((newVdowidthLastRow - Math.floor(newVdoheight - ((newVdoheight * 50)/100)))/2) + "px";
        let profileTop = Math.floor((newVdoheight - Math.floor(newVdoheight - ((newVdoheight * 30)/100)))/2) + "px";
        let paisedTextLeft = flag === false ? this.props.isSmallFormFactor === true ? Math.floor(newVdowidth/4) : Math.floor(newVdowidth/3) : this.props.isSmallFormFactor === true ? Math.floor(newVdowidthLastRow/4) : Math.floor(newVdowidthLastRow/2.25); //TP-4572
        let paisedTextTop = this.props.isSmallFormFactor === true ? Math.floor(newVdoheight/2.5) : Math.floor(newVdoheight/2);//TP-4589
        let pausedNoImageLeft = flag === false ? this.props.isSmallFormFactor === true ? Math.floor(newVdowidth/3.75) : Math.floor(newVdowidth/2.5) : this.props.isSmallFormFactor === true ? Math.floor(newVdowidthLastRow/4) : Math.floor(newVdowidthLastRow/2.25); //TP-5509 TP-4572
        let pausedNoImageTop = this.props.isSmallFormFactor === true ? Math.floor(newVdoheight/1.25) : Math.floor(newVdoheight/1.5);//TP-5509 TP-4589
        let mutedIsSpeakingLeft = flag === false ? this.props.isSmallFormFactor === true ? participant.disableVideo === false ? Math.floor(newVdowidth/6) : -Math.floor(newVdowidth/6) : participant.disableVideo === false ? Math.floor(newVdowidth/3) : -Math.floor(newVdowidth/3) : this.props.isSmallFormFactor === true ? participant.disableVideo === false ? Math.floor(newVdowidthLastRow/3) : -Math.floor(newVdowidthLastRow/6.5) : participant.disableVideo === false ? Math.floor(newVdowidthLastRow/2.5) : -Math.floor(newVdowidthLastRow/6.5); //TP-4572
        //console.log(participant.isThisMe);
        //TP-3877 -- removing the pointer cursor for the Profile pictures
        return(
            <>
                { /**TP-3637 */ isAccountLevelExpertVideoDisabled === false ?                
                    (participant.streams ) ?
                        /*(TP-3645participant.isThisMe !== true || (participant.isThisMe === true && isVideoStreamstarted === true))?*/
                            <div className={gridClassName} style={{ position: "relative", height: newVdoheight, width: flag === false ? newVdowidth : newVdowidthLastRow, marginLeft: "10px"}}>
                                {/**TP-3201 */}
                                <div className="position-absolute w-100 text-center nameDiv" style={{ color: 'white', zIndex:1, top: '10px' }}>
                                    {  participant.disableVideo === false ?
                                            /*TP-5509*/ participant.isPaused === true && participant.poster === "" ?
                                                null
                                            :
                                                /*TP-3497*/(participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                                <span className='position-relative'>
                                                    <img id={"logo-pic-"+userdetails.id} /* onClick={() => this.openBubble(userdetails)} */  className="session-profile-pic logo-profile-pic  " src={participant.myPicture} alt='profile_logo' />
                                                    {/* TP-3704 <BubbleElement index={userdetails.id} row={userdetails} className={"high-zIndex d-none"} /**TP-3480 ></BubbleElement>   */}                              
                                                </span>
                                                :
                                                <span>
                                                    <img id={"logo-pic-"+userdetails.id} /* onClick={() => this.openBubble(userdetails)} */  className="session-profile-pic logo-profile-pic  " src={"/profile.png"} alt='profile_logo' />
                                                    {/* TP-3704 <BubbleElement index={userdetails.id} row={userdetails} className={"high-zIndex d-none"} /**TP-3480 ></BubbleElement> */}                                
                                                </span>
                                        :
                                        null
                                    }
                                    { participant.isThisMe ?
                                        <>
                                            <i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;
                                            <Trans id={participant.first_name}></Trans>
                                        </>
                                        :
                                        participant.isexpert ?                                        
                                            <>
                                                <i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;
                                                {participant.first_name +' ' +participant.last_name}
                                            </>
                                        :
                                        participant.first_name +' ' +participant.last_name
                                    }
                                </div>
                                    <>
                                        { this.props.currentPrimaryExpert && this.props.currentPrimaryExpert.id === this.props.user.id ?
                                            <div className="paddingVdo" style={participant.disableVideo === false && participant.isThisMe !== true /**TP-3935*/ ? {cursor: 'pointer', height: '100%', width: '100%'} : {cursor: '', height: '100%', width: '100%'}}>
                                                { participant.disableVideo === false /* && hasVideoTracks === true */ ? 
                                                    /*TP-4483*/ participant.isPaused && participant.poster !== "" ? 
                                                        <a onClick={(participant.isThisMe === true) ? () => {return null;} : () => this.props.onClickMaximise({participant, isSame: false})} >
                                                            { /**TP-4572*/ this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                ""
                                                                :
                                                                <span className='paused-text paused-text-grid' style={{marginLeft: paisedTextLeft+"px", marginTop: paisedTextTop+"px" /**TP-4589*/}}>
                                                                    <Trans id="Video Paused"></Trans><br></br>
                                                                    { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                        <Trans id="Reason: Share Asset"></Trans>
                                                                        :
                                                                        ''
                                                                    }
                                                                </span>
                                                            }
                                                            { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                :
                                                                ''
                                                            }
                                                            <img className="video-stream hover-grid" width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} src={participant.poster} alt="Max view Stream frame" />
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                className={expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                        </a>
                                                        : /**TP-5509*/ participant.isPaused && participant.poster === "" ?
                                                            (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                                                <>
                                                                    { /**TP-4572*/ this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                        ""
                                                                        :
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: pausedNoImageLeft+"px", marginTop: pausedNoImageTop+"px" /**TP-4589*/}}>
                                                                            <Trans id="Video Paused"></Trans><br></br>
                                                                            { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                                <Trans id="Reason: Share Asset"></Trans>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                    { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                        :
                                                                        ''
                                                                    }
                                                                    {/**TP-5467 */}
                                                                    <img id={participant.stream_id} className={/**TP-3848*/expertCanDisconnectUser === true ? tileClassName+" session-profile-pic session-profile-pic-position video-stream hover-grid" : tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={participant.myPicture} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                                    <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                        className={expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                                </>
                                                            : 
                                                                <>
                                                                    { /**TP-4572*/ this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                        ""
                                                                        :
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: pausedNoImageLeft+"px", marginTop: pausedNoImageTop+"px" /**TP-4589*/}}>
                                                                            <Trans id="Video Paused"></Trans><br></br>
                                                                            { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                                <Trans id="Reason: Share Asset"></Trans>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                    { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                        :
                                                                        ''
                                                                    }
                                                                    <img id={participant.stream_id} className={/**TP-3848*/expertCanDisconnectUser === true ? tileClassName+" session-profile-pic session-profile-pic-position video-stream hover-grid" : tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={"/profile.png"} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                                    <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                        className={expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                                </>
                                                            :
                                                            <>
                                                                { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                    <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                    :
                                                                    ''
                                                                }
                                                                <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                    onClick={(participant.isThisMe === true) ? () => {return null;} : () => this.props.onClickMaximise({participant, isSame: false})} className={expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid" : tileClassName+"video-stream"} muted={participant.isThisMe} />
                                                            </>
                                                    :
                                                    (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                                        <>{/**TP-5467 */}
                                                            <img id={participant.stream_id} className={/**TP-3848*/expertCanDisconnectUser === true ? tileClassName+" session-profile-pic session-profile-pic-position video-stream hover-grid" : tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={participant.myPicture} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                            { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                :
                                                                ''
                                                            }
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                className={expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                        </>
                                                        : 
                                                        <>
                                                            { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                :
                                                                ''
                                                            }
                                                            <img id={participant.stream_id} className={/**TP-3848*/expertCanDisconnectUser === true ? tileClassName+" session-profile-pic session-profile-pic-position video-stream hover-grid" : tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={"/profile.png"} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                className={expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                        </>
                                                }
                                                { expertCanDisconnectUser === true && participant.streams && participant.streams.length > 0 && participant.isThisMe !== true /**TP-3615*/ ?
                                                    /* && videoConnection && videoConnection.peerConnection && videoConnection.peerConnection.peerConnection && videoConnection.peerConnection.peerConnection.iceConnectionState === "connected" */ 
                                                    <div className="position-absolute web-grid-cross-parent">
                                                        <span className={(window.innerWidth > 1299) ? "fa-stack fa-lg grid-cross-btn": "fa-stack fa-sm grid-cross-btn"}>
                                                        <SessionIcon id={"stopSessionUser_"+participant.userId} circleClass="fas fa-circle fa-stack-2x"
                                                            iconClass="fas fa-times fa-stack-1x grid-cross-icon" tooltipPlament="left"
                                                            innerClass="tip-max no-pointer-events" tooltip="Disconnect {firstName} {lastName}" tooltipValues={{ 'firstName': `${participant.first_name}`, 'lastName': `${participant.last_name}` }} tooltipStyle="Trans"
                                                            onClickMethod = {() => this.props.onClickStopSessionUser({participant})} isSmallFormFactorDevice={this.props.isSmallFormFactor} />
                                                            {/* <a id={"stopSessionUser_"+participant.userId} onClick={() => this.props.onClickStopSessionUser({participant}) }>
                                                                <i className="fas fa-circle fa-stack-2x"></i>
                                                                <i className="fas fa-times fa-stack-1x grid-cross-icon" ></i>
                                                                <UncontrolledTooltip innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "window"}}} target={"stopSessionUser_"+participant.userId}>                                            
                                                                    <Trans id="Disconnect {firstName} {lastName}" values={{ 'firstName': `${participant.first_name}`, 'lastName': `${participant.last_name}` }}></Trans>                                            
                                                                </UncontrolledTooltip>
                                                            </a> */}
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                { participant.streams && participant.streams.length > 0 ?
                                                /* videoConnection && videoConnection.peerConnection && videoConnection.peerConnection.peerConnection && videoConnection.peerConnection.peerConnection.iceConnectionState === "connected"? */
                                                    <GridActions totalWidth={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} participant={participant} index={index} canTurnOffUserVideo={canTurnOffUserVideo}
                                                        isPrimary={(this.props.currentPrimaryExpert && this.props.currentPrimaryExpert.id === this.props.user.id) ? true : false}
                                                        onClickToggleTorch={this.onClickToggleTorch} onClickRotateZoom={this.onClickRotateZoom}
                                                        onClickMuteMikeofOne={this.onClickMuteMikeofOne} onClickMuteSpeakerofOne={this.onClickMuteSpeakerofOne}
                                                        onClickTurnOffVideoofOne={this.onClickTurnOffVideoofOne} isSmallFormFactorDevice={this.props.isSmallFormFactor}>                                            
                                                    </GridActions>                                        
                                                    :
                                                    <div className="position-absolute w-100 text-center web-grid-loader-parent">
                                                        <span className="fa-3x">
                                                            <i className="fas fa-circle-notch fa-spin "></i>
                                                        </span><br></br>
                                                        <Trans id="Loading the Remote User's control Icons"></Trans>
                                                    </div>
                                                }
                                            </div>
                                        :
                                            <div className="paddingVdo" style={/*TP-3935 participant.disableVideo === false && participant.isThisMe !== true ? {cursor: 'pointer', height: '100%', width: '100%'} : */ {cursor: '', height: '100%', width: '100%'}}>
                                                { participant.disableVideo === false ? 
                                                    /*TP-4483*/ participant.isPaused && participant.poster !== "" ? 
                                                        <> 
                                                            {/**TP-4572*/ this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                ""
                                                                :
                                                                <span className='paused-text paused-text-grid' style={{marginLeft: paisedTextLeft+"px", marginTop: paisedTextTop+"px" /**TP-4589*/}}>
                                                                    <Trans id="Video Paused"></Trans><br></br>
                                                                    { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                        <Trans id="Reason: Share Asset"></Trans>
                                                                        :
                                                                        ''
                                                                    }
                                                                </span>
                                                            }
                                                            { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                :
                                                                ''
                                                            }
                                                            <img className="video-stream" width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} src={participant.poster} alt="Max view Stream frame" />
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                className={tileClassName+"video-stream d-none"} muted={participant.isThisMe} /**TP-5462*/ />
                                                        </>
                                                        : /**TP-5509*/ participant.isPaused && participant.poster === "" ? 
                                                            (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                                                <>
                                                                    {/**TP-4572*/ this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                        ""
                                                                        :
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: pausedNoImageLeft+"px", marginTop: pausedNoImageTop+"px" /**TP-4589*/}}>
                                                                            <Trans id="Video Paused"></Trans><br></br>
                                                                            { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                                <Trans id="Reason: Share Asset"></Trans>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                    { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                        :
                                                                        ''
                                                                    }
                                                                    {/**TP-5467*/}
                                                                    <img id={participant.stream_id} className={tileClassName+" session-profile-pic session-profile-pic-position video-stream "} alt="User profile" src={participant.myPicture} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                                    <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                        className={tileClassName+"video-stream d-none"} muted={participant.isThisMe} /**TP-5462*/ />
                                                                </>                                                    
                                                                :
                                                                <>
                                                                    {/**TP-4572*/ this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                        ""
                                                                        :
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: pausedNoImageLeft+"px", marginTop: pausedNoImageTop+"px" /**TP-4589*/}}>
                                                                            <Trans id="Video Paused"></Trans><br></br>
                                                                            { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                                <Trans id="Reason: Share Asset"></Trans>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                    { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                        :
                                                                        ''
                                                                    }
                                                                    <img id={participant.stream_id} className={tileClassName+" session-profile-pic session-profile-pic-position video-stream "} alt="User profile" src={"/profile.png"} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>                                                
                                                                    <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                            className={tileClassName+"video-stream d-none"} muted={participant.isThisMe} /**TP-5462*/ />
                                                                </> 
                                                            :
                                                            <>
                                                                { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                    <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                    :
                                                                    ''
                                                                }
                                                                <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                className={tileClassName+"video-stream "} muted={participant.isThisMe} /**TP-5462*/ />
                                                            </>
                                                    :
                                                    (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                                        <>
                                                            { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                                <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                                :
                                                                ''
                                                            }
                                                            {/**TP-5467*/}
                                                            <img id={participant.stream_id} className={tileClassName+" session-profile-pic session-profile-pic-position video-stream "} alt="User profile" src={participant.myPicture} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                className={tileClassName+"video-stream d-none"} muted={participant.isThisMe} /**TP-5462*/ />
                                                        </>                                                    
                                                    :
                                                    <>
                                                        { this.props.showIsMutedbutSpeaking === true && participant.isThisMe === true ?
                                                            <span className='paused-text paused-text-grid' style={{marginLeft: mutedIsSpeakingLeft+"px", marginTop: paisedTextTop+"px"}}><Trans>session.mutedbuttalking</Trans></span>
                                                            :
                                                            ''
                                                        }
                                                        <img id={participant.stream_id} className={tileClassName+" session-profile-pic session-profile-pic-position video-stream "} alt="User profile" src={"/profile.png"} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>                                                
                                                        <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                className={tileClassName+"video-stream d-none"} muted={participant.isThisMe} /**TP-5462*/ />
                                                    </>                                                    
                                                }
                                                { participant.streams && participant.streams.length > 0 ?
                                                /* videoConnection && videoConnection.peerConnection && videoConnection.peerConnection.peerConnection && videoConnection.peerConnection.peerConnection.iceConnectionState === "connected" ? */
                                                    <GridActions totalWidth={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} participant={participant} index={index} canTurnOffUserVideo={canTurnOffUserVideo}
                                                        isPrimary={(this.props.currentPrimaryExpert && this.props.currentPrimaryExpert.id === this.props.user.id) ? true : false}
                                                        onClickToggleTorch={this.onClickToggleTorch} onClickRotateZoom={this.onClickRotateZoom}
                                                        onClickMuteMikeofOne={this.onClickMuteMikeofOne} onClickMuteSpeakerofOne={this.onClickMuteSpeakerofOne} isSmallFormFactorDevice={this.props.isSmallFormFactor} /**TP-4759*/
                                                        onClickTurnOffVideoofOne={this.onClickTurnOffVideoofOne}>
                                                    </GridActions>
                                                    :
                                                    <div className="position-absolute w-100 text-center web-grid-loader-parent">
                                                        <span className="fa-3x">
                                                            <i className="fas fa-circle-notch fa-spin "></i>
                                                        </span><br></br>
                                                        <Trans id="Loading the Remote User's control Icons"></Trans>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>
                                { participant.zoomLevel > 1 ?
                                    <I18n>
                                        {({ i18n }) =>
                                            <div className={"position-absolute text-center web-grid-view-controls-parent nameDiv "+zoomClass} 
                                                /*TP-2967*/style={{ color: 'white', zIndex:1, right: '1%' }}>
                                                {this.processZoomLevels(participant.zoomLevel+"X", i18n)}
                                            </div>
                                        }
                                    </I18n>
                                    :
                                    ''
                                }
                            </div>
                        /* :
                        '' */
                    :
                    ''
                    :
                    (participant.streams) ?
                        (participant.disableVideo === false && !participant.isexpert && !participant.isadmin) ?
                            <div className={gridClassName} style={{ position: "relative", height: newVdoheight, width: flag === false ? newVdowidth : newVdowidthLastRow, marginLeft: "10px"}}>
                                {/**TP-3201 */}
                                <div className="position-absolute w-100 text-center nameDiv" style={{ color: 'white', zIndex:1, top: '10px' }}>
                                    {  participant.disableVideo === false ?
                                        /*TP-5509*/ participant.isPaused === true && participant.poster === "" ?
                                                null
                                            :
                                            /*TP-3497*/(participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                            <span className='position-relative'>
                                                <img id={"logo-pic-"+userdetails.id} /* onClick={() => this.openBubble(userdetails)} */  className="session-profile-pic logo-profile-pic  " src={participant.myPicture} alt='profile_logo' />
                                                {/*  TP-3704 <BubbleElement index={userdetails.id} row={userdetails} className={"high-zIndex d-none"} /**TP-3480 ></BubbleElement>  */}                               
                                            </span>
                                            :
                                            <span>
                                                <img id={"logo-pic-"+userdetails.id} /* onClick={() => this.openBubble(userdetails)} */  className="session-profile-pic logo-profile-pic  " src={"/profile.png"} alt='profile_logo' />
                                                {/* TP-3704 <BubbleElement index={userdetails.id} row={userdetails} className={"high-zIndex d-none"} /**TP-3480 ></BubbleElement> */}
                                            </span>
                                        :
                                        null
                                    }
                                    { participant.isThisMe ?
                                        <>
                                            <i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;
                                            <Trans id={participant.first_name}></Trans>
                                        </>
                                        :
                                        participant.isexpert ?                                        
                                            <>
                                                <i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;
                                                {participant.first_name +' ' +participant.last_name}
                                            </>
                                        :
                                        participant.first_name +' ' +participant.last_name
                                    }
                                </div>
                                    <>
                                        { this.props.currentPrimaryExpert && this.props.currentPrimaryExpert.id === this.props.user.id ?
                                            <div className="paddingVdo" style={participant.disableVideo === false && participant.isThisMe !== true /**TP-3935*/ ? {cursor: 'pointer', height: '100%', width: '100%'} : {cursor: '', height: '100%', width: '100%'}}>
                                                { participant.disableVideo === false /* && hasVideoTracks === true */ ? 
                                                       /**TP-4483 */ participant.isPaused && participant.poster !== "" ?
                                                        <a onClick={(participant.isThisMe === true) ? () => {return null;} : () => this.props.onClickMaximise({participant, isSame: false})} >
                                                            {/**TP-4572*/  this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                ""
                                                                :
                                                                <span className='paused-text paused-text-grid' style={{marginLeft: paisedTextLeft+"px", marginTop: paisedTextTop+"px" /**TP-4589*/}}>
                                                                    <Trans id="Video Paused"></Trans><br></br>
                                                                    { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                        <Trans id="Reason: Share Asset"></Trans>
                                                                        :
                                                                        ''
                                                                    }
                                                                </span>
                                                            }
                                                            <img className="video-stream hover-grid" width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} src={participant.poster} alt="Max view Stream frame" />
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                className={/*TP-4537*/ expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                        </a>
                                                        : /*TP-5509*/ participant.isPaused && participant.poster === "" ?
                                                            (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                                                <>
                                                                    {/**TP-4572*/  this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                        ""
                                                                        :
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: pausedNoImageLeft+"px", marginTop: pausedNoImageTop+"px" /**TP-4589*/}}>
                                                                            <Trans id="Video Paused"></Trans><br></br>
                                                                            { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                                <Trans id="Reason: Share Asset"></Trans>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                    {/**TP-5467 */}
                                                                    <img id={participant.stream_id} className={/**TP-3848*/expertCanDisconnectUser === true ? tileClassName+" session-profile-pic session-profile-pic-position video-stream hover-grid" : tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={participant.myPicture} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                                    <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                        className={/*TP-4537*/ expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                                </>                                                        
                                                            :
                                                                <>
                                                                    {/**TP-4572*/  this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                        ""
                                                                        :
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: pausedNoImageLeft+"px", marginTop: pausedNoImageTop+"px" /**TP-4589*/}}>
                                                                            <Trans id="Video Paused"></Trans><br></br>
                                                                            { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                                <Trans id="Reason: Share Asset"></Trans>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                    <img id={participant.stream_id} className={/**TP-3848*/expertCanDisconnectUser === true ? tileClassName+" session-profile-pic session-profile-pic-position video-stream hover-grid" : tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={"/profile.png"} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                                    <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                        className={/*TP-4537*/ expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                                </>
                                                        :
                                                        <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                onClick={(participant.isThisMe === true) ? () => {return null;} : () => this.props.onClickMaximise({participant, isSame: false})} className={expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid" : tileClassName+"video-stream"} muted={participant.isThisMe} />
                                                    :
                                                    (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                                        <>{/**TP-5467 */}
                                                            <img id={participant.stream_id} className={/**TP-3848*/expertCanDisconnectUser === true ? tileClassName+" session-profile-pic session-profile-pic-position video-stream hover-grid" : tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={participant.myPicture} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                className={/*TP-4537*/ expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                        </>                                                        
                                                        :
                                                        <>
                                                            <img id={participant.stream_id} className={/**TP-3848*/expertCanDisconnectUser === true ? tileClassName+" session-profile-pic session-profile-pic-position video-stream hover-grid" : tileClassName+" session-profile-pic session-profile-pic-position video-stream"} alt="User profile" src={"/profile.png"} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"} /**TP-3609 */
                                                                className={/*TP-4537*/ expertCanDisconnectUser === true ? tileClassName+"video-stream hover-grid d-none" : tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                        </>
                                                }
                                                { expertCanDisconnectUser === true && participant.streams && participant.streams.length > 0 && participant.isThisMe !== true /**TP-3615*/ ?
                                                    /* && videoConnection && videoConnection.peerConnection && videoConnection.peerConnection.peerConnection && videoConnection.peerConnection.peerConnection.iceConnectionState === "connected" */ 
                                                    <div className="position-absolute web-grid-cross-parent">
                                                        <span className={(window.innerWidth > 1299) ? "fa-stack fa-lg grid-cross-btn": "fa-stack fa-sm grid-cross-btn"}>
                                                        <SessionIcon id={"stopSessionUser_"+participant.userId} circleClass="fas fa-circle fa-stack-2x"
                                                            iconClass="fas fa-times fa-stack-1x grid-cross-icon" tooltipPlament="left"
                                                            innerClass="tip-max no-pointer-events" tooltip="Disconnect {firstName} {lastName}" tooltipValues={{ 'firstName': `${participant.first_name}`, 'lastName': `${participant.last_name}` }} tooltipStyle="Trans"
                                                            onClickMethod = {() => this.props.onClickStopSessionUser({participant})} isSmallFormFactorDevice={this.props.isSmallFormFactor} />
                                                            {/* <a id={"stopSessionUser_"+participant.userId} onClick={() => this.props.onClickStopSessionUser({participant}) }>
                                                                <i className="fas fa-circle fa-stack-2x"></i>
                                                                <i className="fas fa-times fa-stack-1x grid-cross-icon" ></i>
                                                                <UncontrolledTooltip innerClassName="tip-max no-pointer-events" modifiers={{preventOverflow: {boundariesElement: "window"}}} target={"stopSessionUser_"+participant.userId}>                                            
                                                                    <Trans id="Disconnect {firstName} {lastName}" values={{ 'firstName': `${participant.first_name}`, 'lastName': `${participant.last_name}` }}></Trans>                                            
                                                                </UncontrolledTooltip>
                                                            </a> */}
                                                        </span>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                { participant.streams && participant.streams.length > 0 ?
                                                /* videoConnection && videoConnection.peerConnection && videoConnection.peerConnection.peerConnection && videoConnection.peerConnection.peerConnection.iceConnectionState === "connected"? */
                                                    <GridActions totalWidth={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} participant={participant} index={index} canTurnOffUserVideo={canTurnOffUserVideo}
                                                        isPrimary={(this.props.currentPrimaryExpert && this.props.currentPrimaryExpert.id === this.props.user.id) ? true : false}
                                                        onClickToggleTorch={this.onClickToggleTorch} onClickRotateZoom={this.onClickRotateZoom}
                                                        onClickMuteMikeofOne={this.onClickMuteMikeofOne} onClickMuteSpeakerofOne={this.onClickMuteSpeakerofOne}
                                                        onClickTurnOffVideoofOne={this.onClickTurnOffVideoofOne} isSmallFormFactorDevice={this.props.isSmallFormFactor}>                                            
                                                    </GridActions>                                        
                                                    :
                                                    <div className="position-absolute w-100 text-center web-grid-loader-parent">
                                                        <span className="fa-3x">
                                                            <i className="fas fa-circle-notch fa-spin "></i>
                                                        </span><br></br>
                                                        <Trans id="Loading the Remote User's control Icons"></Trans>
                                                    </div>
                                                }
                                            </div>
                                        :
                                            <div className="paddingVdo" style={/*TP-3935 participant.disableVideo === false && participant.isThisMe !== true ? {cursor: 'pointer', height: '100%', width: '100%'} : */ {cursor: '', height: '100%', width: '100%'}}>
                                                { participant.disableVideo === false ? 
                                                       /**TP-4483 */ participant.isPaused && participant.poster !== "" ?
                                                        <>
                                                            {/**TP-4572*/  this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                ""
                                                                :
                                                                <span className='paused-text paused-text-grid' style={{marginLeft: paisedTextLeft+"px", marginTop: paisedTextTop+"px" /**TP-4589*/}}>
                                                                    <Trans id="Video Paused"></Trans><br></br>
                                                                    { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                        <Trans id="Reason: Share Asset"></Trans>
                                                                        :
                                                                        ''
                                                                    }
                                                                </span>
                                                            }
                                                            <img className="video-stream" width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} src={participant.poster} alt="Max view Stream frame" />
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                className={/*TP-4537*/ tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                        </>
                                                        : participant.isPaused && participant.poster === "" ?
                                                            (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                                                <>
                                                                    {/**TP-4572*/  this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                        ""
                                                                        :
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: pausedNoImageLeft+"px", marginTop: pausedNoImageTop+"px" /**TP-4589*/}}>
                                                                            <Trans id="Video Paused"></Trans><br></br>
                                                                            { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                                <Trans id="Reason: Share Asset"></Trans>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                    {/**TP-5467 */}
                                                                    <img id={participant.stream_id} className={tileClassName+" session-profile-pic session-profile-pic-position video-stream "} alt="User profile" src={participant.myPicture} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                                    <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                        className={/*TP-4537*/ tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                                </>                                                        
                                                            :
                                                                <>
                                                                    {/**TP-4572*/  this.props.isSmallFormFactor === true && participantWithVideos.length > 2 ? 
                                                                        ""
                                                                        :
                                                                        <span className='paused-text paused-text-grid' style={{marginLeft: pausedNoImageLeft+"px", marginTop: pausedNoImageTop+"px" /**TP-4589*/}}>
                                                                            <Trans id="Video Paused"></Trans><br></br>
                                                                            { /**TP-4888*/ participant.pausedReason === "FILESHARE_ONGOING" ?
                                                                                <Trans id="Reason: Share Asset"></Trans>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                    <img id={participant.stream_id} className={tileClassName+" session-profile-pic session-profile-pic-position video-stream "} alt="User profile" src={"/profile.png"} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>                                                
                                                                    <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                        className={/*TP-4537*/ tileClassName+"video-stream d-none"} muted={participant.isThisMe} />
                                                                </>
                                                        :
                                                        <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                        className={tileClassName+"video-stream "} muted={participant.isThisMe} /*TP-5531*/ />
                                                    :
                                                    (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                                        <>{/**TP-5467 */}
                                                            <img id={participant.stream_id} className={tileClassName+" session-profile-pic session-profile-pic-position video-stream "} alt="User profile" src={participant.myPicture} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                className={/*TP-4537*/ tileClassName+"video-stream d-none"} muted={participant.isThisMe} /**TP-5531*/ />
                                                        </>                                                        
                                                        :
                                                        <>
                                                            <img id={participant.stream_id} className={tileClassName+" session-profile-pic session-profile-pic-position video-stream "} alt="User profile" src={"/profile.png"} style={{height: profileHeight, width: profileWidth, marginLeft: profileLeft, marginTop: profileTop}}></img>                                                
                                                            <video width={/*TP-2973*/flag === false ? newVdowidth: newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={window._env_.REACT_APP_ASSET_FOLDER+"/session_bg_logo.png"}  /**TP-3609*/
                                                                className={/*TP-4537*/ tileClassName+"video-stream d-none"} muted={participant.isThisMe} /**TP-5531*/ />
                                                        </>
                                                }
                                                { participant.streams && participant.streams.length > 0 ?
                                                /* videoConnection && videoConnection.peerConnection && videoConnection.peerConnection.peerConnection && videoConnection.peerConnection.peerConnection.iceConnectionState === "connected" ? */
                                                    <GridActions totalWidth={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} participant={participant} index={index} canTurnOffUserVideo={canTurnOffUserVideo}
                                                        isPrimary={(this.props.currentPrimaryExpert && this.props.currentPrimaryExpert.id === this.props.user.id) ? true : false}
                                                        onClickToggleTorch={this.onClickToggleTorch} onClickRotateZoom={this.onClickRotateZoom}
                                                        onClickMuteMikeofOne={this.onClickMuteMikeofOne} onClickMuteSpeakerofOne={this.onClickMuteSpeakerofOne} isSmallFormFactorDevice={this.props.isSmallFormFactor} /**TP-4759*/
                                                        onClickTurnOffVideoofOne={this.onClickTurnOffVideoofOne}>
                                                    </GridActions>
                                                    :
                                                    <div className="position-absolute w-100 text-center web-grid-loader-parent">
                                                        <span className="fa-3x">
                                                            <i className="fas fa-circle-notch fa-spin "></i>
                                                        </span><br></br>
                                                        <Trans id="Loading the Remote User's control Icons"></Trans>
                                                    </div>
                                                }
                                            </div>
                                        }
                                    </>
                                { participant.zoomLevel > 1 ?
                                    <I18n>
                                        {({ i18n }) =>
                                            <div className={"position-absolute text-center web-grid-view-controls-parent nameDiv "+zoomClass} 
                                                /*TP-2967*/style={{ color: 'white', zIndex:1, right: '1%' }}>
                                                {this.processZoomLevels(participant.zoomLevel+"X", i18n)}
                                            </div>
                                        }
                                    </I18n>
                                    :
                                    ''
                                }
                            </div>
                        :
                        <div>
                            <video ref={this.videoRef} autoPlay id={participant.stream_id} poster={'/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png'} className="video-stream d-none" />
                        </div>
                    :
                    ''
                }

                {/* {(participant.isexpert && sessionType === false) || (participant.streams && participant.disableVideo === true) ? 
                    <div>
                        <video ref={this.videoRef} autoPlay id={participant.stream_id} poster={'/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png'} className="video-stream d-none" />
                    </div>
                    : 
                } */}
                {/* <div className={'col-'+ columnWidth}>
                    <div className="embed-responsive embed-responsive-16by9">
                    <video autoPlay> 
                    <source src='http://techslides.com/demos/sample-videos/small.mp4' height={newVdoheight} type='video/mp4'/>
                    </video>
                    </div>
                </div>                  */}
                {/* {((participants.length <= 4 && (index +1)%2 === 0) || (index+1)%4 === 0) ? <div className="w-100"></div>: null} */}
            </>
        )
    }

    //TP-3052
    technicianGrid = () => {
        console.log("Draw the technician Grid...");
        const {participant, videoConnection, index, participants, setMaxView, setTechMaxStreamid, user} = this.props;
        let hasVideoTracks = false;
        /* if (participant.stream) {
            console.log(this.videoRef.current);
            if(this.videoRef.current){
                console.log("set video Reference..");
                this.videoRef.current.srcObject = participant.stream
            }                
        } */
        console.log("user details: ", participant.email," total Tracks ",participant.stream.getTracks());
        if (participant.stream && participant.stream.getVideoTracks().length > 0) {
                hasVideoTracks = true;
        }
        printConsole(`${participant.email} Has Video Tracks in its stream ${hasVideoTracks}`);
        const [ newVdoheight, newVdowidth, numRows, numCells ] = this.getGridViewDim(participants.length);// dimension of the grid
        const [ newVdowidthLastRow, lastRowNumCells ] = this.getLastRowViewDim(participants.length, numRows, numCells); // TP-2973 -- dimension of the last row of the grid
        //let {newVdoheight, newVdowidth, newVdowidthLastRow, lastRowNumCells} = this.state;
        //console.log(newVdoheight, newVdowidth, newVdowidthLastRow, lastRowNumCells);
        let flag = false;
        //console.log(lastRowNumCells)
        /**TP-2973*/
        if (newVdowidthLastRow !== 0 && lastRowNumCells > 0) {
            //console.log(index);
            let totalNum = participants.length;
            //console.log("total count....?",totalNum);
            if(index >= totalNum - lastRowNumCells)
                flag = true;
        } 
        let gridClassName = "grid-card";
        let tileClassName = "fit-cover ";
        if (!participant.stream) {
            gridClassName = "grid-card-logo";
            tileClassName = "fit-contain ";
        }
        console.log("is in Maxview? ",setMaxView);
        console.log("participant stream id: ",participant.stream.id," setTechMaxStreamid: ",setTechMaxStreamid);
        console.log(this.videoRef);
        //TP-3877 -- removing the pointer cursor for the Profile pictures
        return(
            <>
                {(participant.stream /* && hasVideoTracks === true */) ?
                    setMaxView === false ?
                        <div className={gridClassName} style={{ position: "relative", height: newVdoheight, width: flag === false ? newVdowidth : newVdowidthLastRow, marginLeft: "10px"}}>
                            <div className="position-absolute w-100 text-center nameDiv" style={{ color: 'white', zIndex:1, top: '10px' }}>
                                { /*TP-3497*/ (participant.myPicture !== undefined && participant.myPicture !== null && participant.myPicture !== "") ?
                                    <img className="session-profile-pic logo-profile-pic  " src={participant.myPicture} alt='profile_logo' />
                                    :
                                    <img className="session-profile-pic logo-profile-pic  " src={"/profile.png"} alt='profile_logo' />
                                }
                                { participant.isexpert ?                                        
                                    <>
                                        <i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;
                                        {participant.fullName}
                                    </>
                                    :
                                    participant.fullName
                                }
                            </div>
                                <>                                    
                                    <div className="paddingVdo" style={{cursor: 'pointer', height: '100%', width: '100%'}}>
                                        <video width={/*TP-2973*/flag === false ? newVdowidth : newVdowidthLastRow} height={newVdoheight} ref={this.videoRef} autoPlay id={participant.stream_id} poster={'/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png'}
                                            onClick={() => this.onClickTechMaximise({email: participant.email, type: participant.type}) } className={tileClassName+"video-stream"} muted={participant.isThisMe} />
                                            
                                    </div>                                    
                                </>
                            
                        </div>
                        :
                        (participant.stream.id !== setTechMaxStreamid && participant.email !== user.email) ? 
                            <video ref={this.videoRef} autoPlay style={{height: this.props.vdoheight}} className={"d-none"} muted={participant.isThisMe}></video>
                            :
                            ''
                    :
                    ''
                }
            </>
        )
    }

    render(){
        const {user, participant, ssFrom, sessionType, videoConnection} = this.props;
        if(user.isexpert){
            return(
                this.expertGrid()
            )
        } else if (user.isexpert === false && sessionType === true) {
            return (
                this.technicianGrid()
            )
        }
        else{
            return <video ref={this.videoRef} id={participant.stream_id} /**TP-3342*/ autoPlay style={{height: this.props.vdoheight}} className={participant.email === ssFrom? "" : "d-none"}></video>
        }
    }

}

export default WebrtcVideoTile;