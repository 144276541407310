import React, { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import AuthService from '../AuthService';
import {diagonastics} from "../DiagonasticHelper"
import SimpleReactValidator from 'simple-react-validator';
import Button from '../Button/Button';
import ToolTip from "./ToolTip";
import { UncontrolledTooltip, UncontrolledPopover, PopoverHeader, PopoverBody, Modal, ModalHeader, Form } from 'reactstrap';
import _ from 'lodash';
import { printConsole } from '../helpers';

const iconsPath = "/Icons";

class WebrtcSideBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            participantNames: [],
            muteAll: false,
            muteMike: false,
            muteMikeIcon: "fa-microphone",
            /* muteMikeIcon: iconsPath+"/Microphone/active", */
            muteSpeakerIcon: 'fa-volume-up',
            /* muteSpeakerIcon: iconsPath+"/Speaker/active", */
            guestUser: { isexpert: false, groupid: props.selectedGroupId },
            showGuestForm: false,
            isEmailAlreadyRegistered: false,
            disableGuestSubmission: true,
            retCode: '',
            bResponded: false,
            values: "",
            isLoading: false,
            responseText: '',
            creditsAvailable: props.creditsAvailable,
            videoArray: [
                {
                    userId: 0,
                    toggleVideo: false,
                    toggleVideoIcon: "fa-video font-awesome-green"

                }
            ],
            audioArray: [
                {
                    userId: 0,
                    toggleAudio: false,
                    toggleAudioIcon: "fa-volume-up"

                }
            ],
            mikeArray : [
                {
                    userId: 0,
                    toggleMike: false,
                    toggleMikeIcon: "fa-microphone"
                }
            ],
            callArray: [
                {
                    user_id: 0,
                    toggleCall: false,
                    toggleCallIcon: "fa-phone font-awesome-green"
                }
            ],
            toggleShareScreen: false,
            screenShareIcon: '',
            /* screenShareIcon: iconsPath+"/Screen_share/active", */
            toggleFileShare: false,
            fileShareIcon: '',
            /* fileShareIcon: iconsPath+"/Upload/active", */
            chatUserCount: 0,
            chatUserNames: [],
            toggleChat: false,
            toggleChatIcon: '',
            /* toggleChatIcon: iconsPath+'/Chat/active', */
            toggleChangeCamera: true,
            changeCameraIcon: iconsPath+"/Multi_camera/active",
            guestUserIcon: iconsPath+"/Invite_guest/active",
            toggleStopSession: false,
            stopSessionIcon: iconsPath+"/Stop_live/active",
            cmd: '',
            disableGridView: true,
            shouldStartStopChange: (props.isOnprem) ? true : false,
            userVideoCount: 0 //TP-2029 & TP-1547
        };
        this.authService = new AuthService();
        this.validationRules(props.language);
        this.setupRefs();
    }

    validationRules = (language) => {
        SimpleReactValidator.addLocale(language, require(`../../locales/${language}/messages.json`));
        this.validator = new SimpleReactValidator({
            locale: language,
            element: message => <div className='add-users-validation-msg'>{message.replace(/field|The/g, '').capitalize()}</div>,
            validators: {
                emailValidate: {  // name the rule
                    message: 'Please enter a valid email address',
                    rule: (val, params, validator) => {
                        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return validator.helpers.testRegex(
                            String(val).toLowerCase(),
                            regex
                        );
                    },
                    required: true  // optional
                }
            }
        });
    }

    componentDidMount = () => {
        this.delayedCallback = _.debounce(this.checkEmailExists, 500);
        const hideButton = this.getDeviceClassname();
        this.setState({hideButton});
    }

    componentDidUpdate(prevProps) {
        if (this.props.participantNames !== prevProps.participantNames) {
            this.updateParticipant();
        } else if (this.props.isChanged !== prevProps.isChanged && this.props.isChanged === true) {
            this.updateParticipant();
        } /* else if (this.props.participants !== prevProps.participants && this.props.participants !== undefined && this.props.isOnprem === false) {
            this.updateParticipant();
        } */
        if (this.props.onlineUsers !== prevProps.onlineUsers) {
            this.updateOnlineUsers();
        }
        if (this.props.shouldStartStopChange !== prevProps.shouldStartStopChange){
            this.setState({ shouldStartStopChange: this.props.shouldStartStopChange });            
        }
        if (this.props.triggerStopSession !== prevProps.triggerStopSession) {
            if (this.props.triggerStopSession === true)
                this.onclickStopSession();
        }
        if (this.props.chatUserCount !== prevProps.chatUserCount) {
            this.updateChatParticipant();
        }
        if (this.props.isHeaderFileShare !== prevProps.isHeaderFileShare) {
            this.updateFileShare();
        }
        if (this.props.screenShareAll !== prevProps.screenShareAll) {
            this.updateScreenShare();
        }
        if (this.props.changeScreenShareIcon !== prevProps.changeScreenShareIcon) {
            this.updateScreenShare(this.props.changeScreenShareIcon); //TP-1042
        }
        if (this.props.chatAll !== prevProps.chatAll) {
            this.updateChatAll();
        }
        if (this.props.changeCamera !== prevProps.changeCamera) {
            this.updateChangeCamera();
        }
        if (this.props.disableGridView !== prevProps.disableGridView) {
            this.updateDisableGridView();
        }
        // T32-413
        if (this.props.disabledMutedAll !== prevProps.disabledMutedAll) {
            this.updateSpeaker();
        }
        if (prevProps.participantNames.length > 0 && this.props.participantNames.length === 0 && this.props.RCStatus === false && this.props.user.isexpert === true) {
            this.onclickStopSession();
        }
        if (this.props.participantNames.length === 0 && prevProps.CameraSwapStatus !== this.props.CameraSwapStatus && this.props.CameraSwapStatus === false && this.props.user.isexpert === true) {
            this.onclickStopSession();
        }
        if ((this.props.bAnnotate || this.props.bRemoteCapture) && 
            (this.props.bAnnotate !== prevProps.bAnnotate || this.props.bRemoteCapture !== prevProps.bRemoteCapture) &&
            this.state.toggleChat) {
            this.onClickChat();// MB2-471
        }
        // TP-1162 -- To handle the closing of Add Guest Form when Primary become Passive expert
        if (this.props.showHeaderActionButtons !== prevProps.showHeaderActionButtons && this.props.showHeaderActionButtons === false) {
            if (this.state.showGuestForm) 
                this.showHideGuestForm();
        }
        //	TP-2349	
        if(this.props.guestUser !== prevProps.guestUser && this.props.guestUser !== '') {
            this.removeGuest();
        }
        //TP-3573
        if(this.props.triggerMyMike !== prevProps.triggerMyMike && this.props.triggerMyMike === true) {
            this.updateMyMikefromGrid();
        }
        //TP-3573
        if(this.props.triggerMySpeaker !== prevProps.triggerMySpeaker && this.props.triggerMySpeaker === true) {
            this.updateMySpeakerfromGrid();
        }
    }

    // Update participant count 
    updateParticipant = () => {
        let updatedStateVar = {};
        const { participantNames, participants, isOnprem, sessionType } = this.props;
        let list = [];
        //console.log(sessionType);
        if (sessionType === true) { /*TP-3532*/
            list = participantNames.filter(p => p.disableVideo === false);
        } else {
            //TP -- 2029 & TP-1547
            list = participantNames.filter(p => p.disableVideo === false && p.name.includes("*") === false);
        }
        let length = 0;
        //printConsole(`list --------------------------- ${JSON.stringify(list)}`);
        if (list !== undefined) {
            printConsole(`Side bar -- Count of Users with Video turned on -- ${list.length}`)
            length = list.length;
        }
        this.setState({ userVideoCount: length });
        //if (isOnprem === true) {
            /* let videoArray = [];
            let audioArray = [];
            let mikeArray = [];
            let len = participants.length;
            for (let ii=0; ii<len; ii++)
            {
                console.log("Video: ", participants[ii].disableVideo)
                console.log("Audio: ", participants[ii].disableAudio)
                console.log("Mike: ", participants[ii].disableMike)
                let videoObj = {
                    userId: participants[ii].g_divId,
                    toggleVideo: participants[ii].disableVideo,
                    toggleVideoIcon: participants[ii].disableVideo === false ? "fa-video font-awesome-green" : "fa-video-slash font-awesome-red"
                }
                videoArray.push(videoObj);
                let audioObj = {
                    userId: participants[ii].g_divId,
                    toggleAudio: participants[ii].disableAudio,
                    toggleAudioIcon: participants[ii].disableAudio === false ? "fa-volume-up" : "fa-volume-up font-awesome-red"
                }
                audioArray.push(audioObj);
                let mikeObj = {
                    userId: participants[ii].g_divId,
                    toggleMike: participants[ii].disableMike,
                    toggleMikeIcon: participants[ii].disableMike === false ? "fa-microphone" : "fa-microphone-slash font-awesome-red"
                }
                mikeArray.push(mikeObj);
            } 
            console.log(videoArray);           
            updatedStateVar.videoArray = videoArray;
            updatedStateVar.audioArray = audioArray;
            updatedStateVar.mikeArray = mikeArray;  */           
        //}
        updatedStateVar.participantNames = participantNames;
        this.setState((prev) => ({
            ...prev,
            ...updatedStateVar
        }));
    }

    // Update online users
    updateOnlineUsers = () => {
        let updatedStateVar = {};
        const { onlineUsers, isOnprem } = this.props;
        printConsole(onlineUsers);
        if (isOnprem === true && onlineUsers.length > 0 && onlineUsers[0] !== undefined && onlineUsers[0].isBusy !== undefined) {
            let callArray = [];
            for (let i=0; i< onlineUsers.length; i++){ 
                if (onlineUsers[i].user_id !== undefined) {
                    callArray[i] = {
                        user_id: onlineUsers[i].user_id,
                        toggleCall: onlineUsers[i].isBusy,
                        toggleCallIcon: !onlineUsers[i].isBusy ? "fa-phone font-awesome-green" : "fa-phone-slash font-awesome-red"
                    }
                    
                }              
                
            }
            updatedStateVar.callArray = callArray;
        }
        this.setState((prev) => ({
            ...prev,
            ...updatedStateVar
        }));
    }

    // Update the file share state and icon class
    updateFileShare = () => {
        const fileSharePath = '/Upload';
        this.setState({
            toggleFileShare: this.props.isHeaderFileShare,
            fileShareIcon: this.props.isHeaderFileShare ? 'font-awesome-red' : ''
            /* fileShareIcon: this.props.isHeaderFileShare ? iconsPath+fileSharePath+'/click' : iconsPath+fileSharePath+'/active' */
        })
    }

    // Update the screen share state and icon class
    updateScreenShare = (value=null) => {
        const screenSharePath = '/Screen_share';
        if (value === null) {
            this.setState({
                toggleShareScreen: this.props.screenShareAll,
                screenShareIcon: this.props.screenShareAll ? 'font-awesome-red' : ''
                /* screenShareIcon: this.props.screenShareAll ? iconsPath+screenSharePath+'/click' : iconsPath+screenSharePath+'/active' */
            })
        } else { /* TP-1042 */
            this.setState({
                toggleShareScreen: value,
                screenShareIcon: value ? 'font-awesome-red' : ''
                /* screenShareIcon: this.props.screenShareAll ? iconsPath+screenSharePath+'/click' : iconsPath+screenSharePath+'/active' */
            })
        }
    }

    // Update the Chat All state and icon class
    updateChatAll = () => {
        const chatPath = '/Chat';
        this.setState({
            toggleChat: this.props.chatAll,
            toggleChatIcon: this.props.chatAll ? 'font-awesome-red' : ''
            /* toggleChatIcon: this.props.chatAll ? iconsPath+chatPath+'/click' : iconsPath+chatPath+'/active', */
        })
    }

    updateChatParticipant = () => {
        this.setState({
            chatUserCount: this.props.chatUserCount,
            chatUserNames: this.props.chatNameArr
        })
    }

    updateDisableGridView = () => {
        this.setState({
            disableGridView: this.props.disableGridView
        })
    }

    //	TP-3573
    updateMyMikefromGrid = () => {
        const mikePath = "/Microphone";
        this.setState((prevState) => ({
            muteMike: !prevState.muteMike,
            muteMikeIcon: prevState.muteMike ? "fa-microphone" : "fa-microphone-slash font-awesome-red"
            /* muteMikeIcon: prevState.muteMike ? iconsPath+mikePath+'/active' : iconsPath+mikePath+'/click', */
        }))
        this.handleCloseSliders();
    }

    //	TP-3573
    updateMySpeakerfromGrid = () => {
        const speakerPath = '/Speaker';
        this.setState((prevState) => ({
            muteAll: !prevState.muteAll,
            muteSpeakerIcon: prevState.muteAll ? "fa-volume-up" : "fa-volume-mute font-awesome-red"
            /* muteSpeakerIcon: prevState.muteAll ? iconsPath+speakerPath+'/active' : iconsPath+speakerPath+'/click', */
        }))
        this.handleCloseSliders();
    }

    // T32-413
    updateSpeaker = () => {
        this.setState({
            /* muteAll: this.props.disabledMutedAll, */
            muteSpeakerIcon: !this.props.disabledMutedAll ? !this.state.muteAll ? "fa-volume-up" : "fa-volume-mute font-awesome-red" : "fa-volume-mute"
        })
    }

    updateChangeCamera = () => {
        const cameraPath = "/Multi_camera";
        this.setState({
            toggleChangeCamera: this.props.changeCamera,
            changeCameraIcon: this.props.changeCamera ? iconsPath+cameraPath+"/active" : iconsPath+cameraPath+"/click"
        })
    }

    setupRefs() {
        this.toolTip = React.createRef();
        this.slider = React.createRef();
    }

    // mute own mike
    muteMyMike = (e) => {
        e.preventDefault();
        const mikePath = "/Microphone";
        this.setState((prevState) => ({
            muteMike: !prevState.muteMike,
            muteMikeIcon: prevState.muteMike ? "fa-microphone" : "fa-microphone-slash font-awesome-red"
            /* muteMikeIcon: prevState.muteMike ? iconsPath+mikePath+'/active' : iconsPath+mikePath+'/click', */
        }), () => this.props.muteHeaderMikeForAll(!this.state.muteMike))
        this.handleCloseSliders();
    }

    // mute everyone
    muteMySpeaker = (e) => {
        e.preventDefault();
        const speakerPath = '/Speaker';
        this.setState((prevState) => ({
            muteAll: !prevState.muteAll,
            muteSpeakerIcon: prevState.muteAll ? "fa-volume-up" : "fa-volume-mute font-awesome-red"
            /* muteSpeakerIcon: prevState.muteAll ? iconsPath+speakerPath+'/active' : iconsPath+speakerPath+'/click', */
        }), () => this.props.muteHeaderSpeakerOfAll(!this.state.muteAll))
        this.handleCloseSliders();
    }

    // sign out
    // onclickSignout = (e) => {
    //     e.preventDefault();
    //     this.props.onSessionCmdChange('session_stopped');// the webrtcSessionEndpoint api has to be called first so that usage is updated and token is cleared
    //     const {user, selectedGroupId, myLocation = "Bangalore North++East" } = this.props;
    //     this.authService.fetch('signout', {
    //         method: 'post',
    //         body: JSON.stringify({
    //             email: user.email, id: user.id, groupid: selectedGroupId, geoLocation: myLocation
    //         })
    //     })
    //     .then(response => response.json())
    //     .then(data => {
    //         // this.props.onSessionCmdChange('session_stopped');
    //     })
    //     .catch(err => printConsole(err.message))
    // }

    // stop session
    onclickStopSession = (e) => {
        if (e !== undefined)
            e.preventDefault();
        const stopSessionPath = '/Stop_live';
        if (this.props.log_level >= 3) {
            diagonastics(this.authService, {
                action: `click of header stop session`,
                next_step: '',
                data: ``,
                error: 'none'
            })
        }
        this.setState((prevState) => ({
            toggleStopSession: !prevState.toggleStopSession,
            stopSessionIcon: prevState.toggleStopSession ? iconsPath+stopSessionPath+'/active' : iconsPath+stopSessionPath+'/click'            
        }), () => {       
            this.props.onSessionCmdChange('session_stopped');
        });
    }

    // form fields focus
    onClickField = event => {
        event.target.focus();
    }

    // form state update
    onChangeForm = name => event => {
        event.persist();
        if (name === 'email') {
            this.delayedCallback(event.target.value, event.target.name)
        }

        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        this.setState((prevState) => (
            {
                guestUser: { ...prevState.guestUser, [name]: value }
            }), () => {
                //TP-3182
                if(!this.validator.allValid() || this.state.isEmailAlreadyRegistered) {
                    this.validator.showMessages();
                    this.setState({ disableGuestSubmission: true })
                } else {
                    this.validator.hideMessages();
                    this.setState({ disableGuestSubmission: false });
                }
            });
    }

    // check emails exists
    checkEmailExists = (email, emailField) => {
        if (!this.validator.fieldValid(emailField)) {
            this.setState({ isEmailAlreadyRegistered: false });
            return
        };
        this.setState({ disableGuestSubmission: true });
        this.authService.fetch('checkEmailExists/' + email, {
            method: 'GET'
        })
            .then(response => {
                if (response.status === 200)
                    return response.json();
                else
                    throw new Error(response.statusText);
            })
            .then(isExists => {
                this.setState({
                    isEmailAlreadyRegistered: isExists,
                    disableGuestSubmission: isExists
                });
            })
            .catch((error) => {
                printConsole(error);
            })
    }

    // show/hide guest form
    showHideGuestForm = (e) => {
        if (e) e.preventDefault();
        const guestUserPath = "/Invite_guest";
        this.setState((prevState) => ({
            showGuestForm: !prevState.showGuestForm,
            guestUser: { isexpert: false, groupid: this.props.selectedGroupId },
            isEmailAlreadyRegistered: false,
            disableGuestSubmission: true,
            guestUserIcon: !prevState.showGuestForm ? iconsPath+guestUserPath+'/click' : iconsPath+guestUserPath+'/active',
        }), () => {
            // clear the previous messages if any
            this.validator.hideMessages();
        });
        this.handleCloseSliders();
    }

    // guest form
    guestForm = () => {
        return (
            <>
                <Modal isOpen={this.state.showGuestForm} toggle={this.showHideGuestForm}>
                    <ModalHeader toggle={this.showHideGuestForm} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Add User</Trans></ModalHeader>
                    <Form>
                        <div className="__modal-body pb-0">
                            <div className="input-wrapper margin-bottom-15">
                                <I18n>
                                    {({ i18n }) =>
                                        <>
                                            <input className='signup-input-field' name={i18n._(t`first_name`)} type="text" placeholder={i18n._(t`First Name`) + '*'} defaultValue={''} value={this.state.guestUser.first_name || ''} onChange={this.onChangeForm('first_name')} onClick={(e) => this.onClickField(e)} />
                                            {this.validator.message(i18n._(t`first_name`), this.state.guestUser.first_name, 'required|alpha_num_space')}
                                        </>
                                    }
                                </I18n> 
                            </div>
                            <div className="input-wrapper margin-bottom-15">
                                <I18n>
                                    {({ i18n }) =>
                                        <>
                                            <input className='signup-input-field' name={i18n._(t`last_name`)} type="text" placeholder={i18n._(t`Last Name`) + '*'} defaultValue={''} value={this.state.guestUser.last_name || ''} onChange={this.onChangeForm('last_name')} onClick={(e) => this.onClickField(e)} />
                                            {this.validator.message(i18n._(t`last_name`), this.state.guestUser.last_name, 'required|alpha_num_space')}
                                        </>
                                    }
                                </I18n>
                            </div>
                            <div className="input-wrapper margin-bottom-15">
                                <I18n>
                                    {({ i18n }) =>
                                        <>
                                            <input className='signup-input-field' name={i18n._(t`user_email`)} type="text" placeholder={i18n._(t`Email`) + '*'} defaultValue={''} value={this.state.guestUser.email || ''} onChange={this.onChangeForm('email')} onClick={(e) => this.onClickField(e)}/>
                                            {this.validator.message(i18n._(t`user_email`), this.state.guestUser.email, 'required|emailValidate')}
                                            {this.state.isEmailAlreadyRegistered ? <div className="add-users-validation-msg"><Trans>This email is already registered.</Trans></div> : ''}
                                        </>
                                    }
                                </I18n>
                            </div>
                            <label htmlFor="isexpert">
                                <input className="checkbox" type="checkbox" name="isexpert" checked={this.state.guestUser.isexpert} onChange={this.onChangeForm('isexpert')}></input>
                                <Trans values={{role: this.props.customerRoles.expert}}>User is an expert</Trans>
                            </label>
                            <div className="__modal-footer flex-center" style={this.props.isSmallFormFactor == true ? { marginBottom: "75px"} : {}}>
                                <Button showSpinner={this.state.isLoading} className={this.state.disableGuestSubmission ? "btn-grey" : "btn-green"}  disabled={this.state.disableGuestSubmission} onClick={this.addGuest}><Trans>Add</Trans></Button>
                            </div>
                        </div>
                    </Form>
                </Modal>
            </>
        );
    }

    // submit guest form
    addGuest = (e) => {
        e.preventDefault();
        if (!this.validator.allValid() || this.state.isEmailAlreadyRegistered) {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.forceUpdate();
            this.setState({disableGuestSubmission: true}); //TP-3182
            return;
        }
        let { guestUser } = this.state;
        //printConsole(guestUser); return;
        this.setState({ isLoading: true });
        this.authService.fetch('addGuestUser', {
            method: 'post',
            body: JSON.stringify(
                guestUser
            )
        })
            .then(response => {
                this.setState({ retCode: response.status, showGuestForm: false, disableGuestSubmission: true });
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.body);
                }
            })
            .then(data => {
                if (data !== null && data !== undefined) {
                    const nameObj = {name: guestUser.first_name + " " + guestUser.last_name};
                    this.setState((prevState) => ({
                        guestUser: { isexpert: false }, bResponded: true, isLoading: false,
                        responseText: '{name} has been added successfully', values: nameObj,
                        creditsAvailable: prevState.creditsAvailable - 1
                    }));
                    this.props.addGuestUserList(guestUser.email); //TP-2349	
                }
            })
            .catch((error) => {
                let responseText = i18nMark('Failed to add a new user. Please review the entries and resubmit again.')
                //TP-3225
                if (error.status === 417) {
                    responseText = i18nMark('No available credits to add guest user. Please contact your platform administrator.')
                    this.setState({ creditsAvailable: 0 });
                }
                this.setState({
                    guestUser: { isexpert: false },
                    showGuestForm: false,
                    disableGuestSubmission: true,
                    retCode: error.status,
                    bResponded: true,
                    isLoading: false,
                    responseText
                });
            })
    }

    //TP-2349 -- Remove Guest user API call implemented to flush out guest users from database when 
    // they get disconnected from the ongoing session
    removeGuest = () => {
        let { guestUser } = this.props;
        //printConsole(guestUser); return;
        this.setState({ isLoading: true });
        let fetchString = 'deleteGuestUser/'+guestUser;
        this.authService.fetch(fetchString, {
            method: 'delete',
        })
            .then(response => {
                this.setState({ retCode: response.status, showGuestForm: false, disableGuestSubmission: true });
                if (response.status === 200) {
                    return response.json();
                } else {
                    throw new Error(response.body);
                }
            })
            .then(data => {
                if (data !== null && data !== undefined && data.deleted === true) {
                    this.setState((prevState) => ({
                        bResponded: false, isLoading: false,
                        creditsAvailable: prevState.creditsAvailable + 1
                    }));
                    this.props.removeGuestUserList(guestUser);
                } 
                else {
                    printConsole("Delete guest user API returned false");
                }
            })
            .catch((error) => {
                // output error in delete guest
                const retCode = error.status;
                let responseText = i18nMark('Exception error');
                if(retCode === 400) {                    
                    responseText = i18nMark('User not found');
                    /* this.setState((prevState) => ({
                        creditsAvailable: prevState.creditsAvailable + 1 
                    })) */
                    this.props.removeGuestUserList(guestUser);
                } else if(retCode === 422) {                    
                    responseText = i18nMark('Invalid Data');
                } else if(retCode === 403) {                    
                    responseText = i18nMark('Unauthorised access');
                } else {
                    responseText = i18nMark('Exception error');
                }

                this.setState({
                    guestUser: { isexpert: false },
                    showGuestForm: false,
                    disableGuestSubmission: true,
                    retCode,
                    bResponded: true,
                    isLoading: false,
                    responseText
                });
            })
        

    }

    // show success/error messages after submission of guest form
    formMsg = () => {
        let colorText = '#485890';
        if (this.state.retCode !== 200) colorText = 'red';
        return (
            <Modal isOpen={this.state.bResponded} toggle={() => this.setState((prev) => ({ bResponded: !prev.bResponded }))}>
                <Form>
                    <div className="__modal-header">
                        <h4 style={{ color: colorText }}>
                            { this.state.values === "" ?
                                <Trans id={this.state.responseText} />
                                :
                                <Trans id={this.state.responseText} values={this.state.values} />
                            }
                        </h4>
                    </div>
                    <div className="__modal-footer flex-center">
                        <button className="btn-green" onClick={(e) => this.setState((prev) => ({ bResponded: !prev.bResponded }))}><Trans id='OK' /></button>
                    </div>
                </Form>
            </Modal>
        );
    }

    // turn off/on the user video
    onClickUserVideo = (e) => {
        e.preventDefault();
        const key = e.target.id.replace("-link", '');
        if (key === "") return;
        let {videoArray} = this.state;
        let {participantNames} = this.props;
        const participant = participantNames.filter(p => p.userId === parseInt(key));
        
            /* if(this.props.isOnprem) */
                this.props.toggleUserVideo(key, !participant.disableVideo);
            /* else
                this.props.toggleUserVideo(videoArray[key].userId, !videoArray[key].toggleVideo); */
        
    }

    // turn on/off the remote user speaker
    onClickUserSpeaker = (e) => {
        e.preventDefault();
        const key = e.target.id.replace("-link", '');
        if (key === "") return;
        let {audioArray} = this.state;
        let {participantNames} = this.props;
        const participant = participantNames.filter(p => p.userId === parseInt(key));
        
            //if(this.props.isOnprem)
                this.props.toggleUserSpeaker(key, participant.disableAudio);
            /* else    
                this.props.toggleUserSpeaker(audioArray[key].userId, !audioArray[key].toggleAudio); */
        
    }

    // turn on/off the expert mike for user
    onClickUserMike = (e) => {
        e.preventDefault();
        const key = e.target.id.replace("-link", '');
        if (key === "") return;
        let {mikeArray} = this.state;
        let {participantNames} = this.props;
        const participant = participantNames.filter(p => p.userId === parseInt(key));
        
            //if(this.props.isOnprem)
                this.props.toggleUserMike(key, participant.disableMike)
            /* else
                this.props.toggleUserMike(mikeArray[key].userId, !mikeArray[key].toggleMike) */
        
        
    }

    // call and invite online users 
    onClickUserCall = (e) => {
        e.preventDefault();
        const user_id = e.target.id.replace("-link", '');
        if (user_id === "") return;
        const {onlineUsers} = this.props;
        printConsole(parseInt(user_id));
        if (onlineUsers[parseInt(user_id)] && onlineUsers[parseInt(user_id)].isBusy === false) {
            this.props.callAndInviteUser(false, user_id);
        } else {
            printConsole("User is busy");
        }       
        
    }

    // start screen share
    onClickScreenShare = (e) => {
        e.preventDefault();
        const screenSharePath = '/Screen_share';        
        this.setState((prevState) => ({
            toggleShareScreen: !prevState.toggleShareScreen,
            screenShareIcon: prevState.toggleShareScreen ? '' : 'font-awesome-red',
            /* screenShareIcon: prevState.toggleShareScreen ? iconsPath+screenSharePath+'/active' : iconsPath+screenSharePath+'/click', */
            cmd: prevState.toggleShareScreen ? 'session_share_screen_off' : 'session_share_screen'
        }), () => {
            //this.props.onSessionCmdChange(this.state.cmd)
            this.props.openExpertScreenShare(this.state.toggleShareScreen);
        });
        this.handleCloseSliders();
    }

    // start/stop file share
    onclickFileShare = (e) => {
        e.preventDefault();
        if(this.state.toggleFileShare) return; 
        else this.props.openFileUpload()
        /* diagonastics(this.authService, {
            action: `click of header file share`, next_step: '',
            data: `toggleFileShare:${this.state.toggleFileShare}`,
            error: 'none'
        }) */
        this.handleCloseSliders()
    }

    //chat
    onClickChat = (e) => {
        e && e.preventDefault();
        const chatPath = "/Chat";
        /* diagonastics(this.authService, {
            action: `click of header Chat`,
            next_step: '',
            data: `toggleChat:${this.state.toggleChat}`,
            error: 'none'
        }) */
        this.setState((prevState) => ({
            toggleChat: !prevState.toggleChat,
            chatUserCount: 0,
            chatUserNames: [],
            /* toggleChatIcon: prevState.toggleChat ? iconsPath+chatPath+'/click' : iconsPath+chatPath+'/active', */
            toggleChatIcon: prevState.chatAll ? 'font-awesome-red' : '',
            cmd: prevState.toggleChat ? 'session_chat_off' : 'session_chat'
        }), () => {
            //this.props.onSessionCmdChange(this.state.cmd);
            this.props.onSetChatJoinees(this.state.chatUserNames);// Reset the Chat User count when Chat Button is clicked
            this.props.updateChatAll(this.state.toggleChat);
            this.props.openExpertChatWindow(this.state.toggleChat);
        });
        this.handleCloseSliders();
    }

    //go to grid view from sidebar
    onClickGrid = (e) => {
        e.preventDefault(); 
        //TP-3690
        //console.log(this.props.isAccountLevelExpertVideoDisabled, " no of participants ", this.state.participantNames.length, " no of Video tiles ", this.state.userVideoCount);
        if (this.props.isAccountLevelExpertVideoDisabled === true && (this.state.participantNames.length < 2 || this.state.userVideoCount < 2)) return;
        //TP-3573
        else if (this.props.sessionType === false && this.state.participantNames.length < 2) return;
        this.props.onClickMultiGridView()
        this.handleCloseSliders();
    }

    //onClick Method to Open the Slider
    handleOnButtonClick(evt) {
        evt.preventDefault();
        this.handleCloseSliders();
        let el = evt.currentTarget;
    
        if (el != null) {
            let rect = el.getBoundingClientRect();
    
            this.toolTip.current.show(rect);
        }
    }

    handleOnInviteUsersClick(evt) {
        evt.preventDefault();
        this.handleCloseSliders();
        let el = evt.currentTarget;
    
        if (el != null) {
            let rect = el.getBoundingClientRect();
    
            this.slider.current.show(rect);
        }
    }

    handleCloseSliders = () => {
        if (document.getElementById("joineesTooltip") === null || document.getElementById("joineesTooltip") === undefined) return;
        if (document.getElementById("invite_users") === null || document.getElementById("invite_users") === undefined) return;
        let el1 = document.getElementById("joineesTooltip").parentElement;
        printConsole(el1);
        if (el1 != null && document.getElementById("tooltip") !== null && document.getElementById("tooltip") !== undefined && document.getElementById("tooltip").classList.contains("on")) {
            let rect1 = el1.getBoundingClientRect();
    
            this.toolTip.current.show(rect1);
        }

        let el2 = document.getElementById("invite_users").parentElement;
        printConsole(el2);        
        if (el2 != null && document.getElementById("invite_slider") !== null && document.getElementById("invite_slider") !== undefined && document.getElementById("invite_slider").classList.contains("on")) {
            let rect2 = el2.getBoundingClientRect();
    
            this.slider.current.show(rect2);
        }

    }

    onClickChangeCamera = (event) => {
        event.preventDefault();
        const cameraPath = "/Multi_camera";
        printConsole(event.type);
        this.setState((prevState) => ({
            toggleChangeCamera: !prevState.toggleChangeCamera,
            changeCameraIcon: prevState.toggleChangeCamera ? iconsPath+cameraPath+'/active' : iconsPath+cameraPath+'/click',
            cmd: prevState.toggleChangeCamera ? 'session_change_camera_on' : 'session_change_camera_off'
        }), () => {
            this.props.openChangeCameraDialog();
        });
    }

    handleClick = (e) =>{
        e.preventDefault();
        return false;
    }

    getDeviceClassname = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let hideButton = "d-blk";
        if (isiPad || isAndroid) {
            hideButton = "d-none";
        } 
        return hideButton;
    }

    // expert content
    expertContent = () => {
        //printConsole(window.innerWidth);
        const { participantNames, guestUserIcon, muteMikeIcon, videoArray, audioArray, mikeArray, 
                muteSpeakerIcon, screenShareIcon, fileShareIcon, chatUserNames, chatUserCount, 
                toggleChatIcon, stopSessionIcon, callArray, userVideoCount /**TP-1547 */ } = this.state;
        const { isOngoingMaxDivFeatures, disableGridView, maxView, /**TP-3629*/ showMaxDivControls, isOnprem, 
                canTurnOffUserVideo, onlineUsers, inviteOnlineUser, disabledMutedAll, 
                isSmallFormFactor, sessionType, isAccountLevelExpertVideoDisabled, /**TP-3629*/ myPicture } = this.props;
        const {hideButton} = this.state;
        let buttonSize = (window.innerWidth > 1349) ? "sm-nav-link" : (window.innerWidth > 1299) ? "md-nav-link" : "xs-nav-link";  
        //buttonSize = (hideButton === 'd-none') ? "sm-nav-link" : buttonSize;
        const iconStyle = (window.innerWidth > 1349) ? {
            width: "45px",
            height: "45px"
        } : {
            width: "30px",
            height: "30px"
        }
        //printConsole(this.props.expert_ss_enabled);
        //printConsole(this.props.isSafari);
        //printConsole(`showHeaderActionButtons : ${this.props.showHeaderActionButtons}`);
        //printConsole(`technicianCount: ${this.props.technicianCount}`);
        //printConsole(`disableGridView: ${disableGridView}`);
        //console.log("isMaxView", maxView, " is primaru expert ? ",showMaxDivControls);//TP-3629
        //printConsole(participantNames.length);
        //printConsole(`isOngoingMaxDivFeatures: ${isOngoingMaxDivFeatures}`);
        if (participantNames.length > 0) {
            return (
            <I18n>
                {({ i18n }) =>    
                <div className="row no-gutters rounded">
                    <div className="col-6 d-flex min-vh-100">
                            <ul className="nav flex-column sidebar-gradient zindex-1062" style={(window.innerWidth > 1349) ? { minWidth: "75px"}: {minWidth: "50px"}}>
                                <li className="nav-item flex-fill"></li>
                                <li className="nav-item flex-fill">
                                    { /* T32-445 */ hideButton === 'd-blk' ?
                                        <a className="nav-brand" >
                                            <img id="logoToolTip" src={window._env_.REACT_APP_ASSET_FOLDER+"/session_sidebar_logo.png"} alt='logo' style={iconStyle} className=" left-Icons" onClick={(e) => this.handleClick(e)}/>
                                            {/* <UncontrolledTooltip placement="bottom" innerClassName="tip" style={{ backgroundColor: '#FFFFFF' }}  modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="logoToolTip">                                            
                                                <img src="/green_logo_transparent_background.png" />
                                            </UncontrolledTooltip> */}
                                                    
                                        </a>
                                        :
                                        <a className="nav-brand" >
                                            <img id="logoToolTip" src={window._env_.REACT_APP_ASSET_FOLDER+"/session_sidebar_logo.png"} alt='logo' style={iconStyle} className="left-Icons" onClick={(e) => this.handleClick(e)}/>
                                            { isSmallFormFactor === false ?
                                                <UncontrolledTooltip placement="bottom" innerClassName="tip-logo" /* style={{ backgroundColor: '#FFFFFF' }} */  modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="logoToolTip">                                            
                                                    <Fragment><span><Trans>session.logo</Trans></span></Fragment>
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }                                                    
                                        </a>
                                    }
                                </li>                                
                                <li className="nav-item flex-fill">
                                    { disableGridView === false ?
                                       /* isOnprem === false && */ showMaxDivControls === true ?
                                        <>
                                            {participantNames.length > 0 ? 
                                                isOngoingMaxDivFeatures === false ?
                                                    <>
                                                        {/* <UncontrolledPopover trigger="legacy" placement="bottom" className="tip-badge react-tooltip-clickable-link" target="joineesTooltip">
                                                            <PopoverHeader className="popover-header"><b><u>{this.props.selectedGroupName}</u></b></PopoverHeader>
                                                            <PopoverBody>
                                                                {participantNames.map((e,i) => 
                                                                    <Fragment key={i}>
                                                                        <span>{e.name}</span>
                                                                        
                                                                            
                                                                        { e.name.includes("*") === false ?
                                                                            <>
                                                                            &nbsp;&nbsp;&nbsp;<a onClick={(e) => this.onClickUserSpeaker(e)}><i id={e.userId+"-link"} value={e} className={e.disableAudio ? "fas fa-volume-up font-awesome-red": "fas fa-volume-up"}></i></a>
                                                                            &nbsp;&nbsp;<a onClick={(e) => this.onClickUserMike(e)}><i id={e.userId+"-link"} value={e} className={e.disableMike ? "fas fa-microphone-slash font-awesome-red": "fas fa-microphone"}></i></a>
                                                                                { canTurnOffUserVideo === true ?
                                                                                    <>&nbsp;&nbsp;<a onClick={(e) => this.onClickUserVideo(e)}><i id={e.userId+"-link"} value={e} className={e.disableVideo ? "fas fa-video-slash font-awesome-red": "fas fa-video font-awesome-green"}></i></a></>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </>
                                                                            :
                                                                            ''
                                                                        }
                                                                        <br />
                                                                    </Fragment>)}
                                                                <Fragment><span>* <Trans>You</Trans></span><br /></Fragment>
                                                            </PopoverBody>
                                                        </UncontrolledPopover> */}                                                        
                                                        <a onClick={(e) => this.handleOnButtonClick(e)}>
                                                            <span id="joineesTooltip" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute cursor-pointer member-count badge badge-danger badge-pill">
                                                                {participantNames.length + 1}
                                                            </span>
                                                        </a>
                                                        <ToolTip ref={this.toolTip} inputRef={this.toolTip} type="partcipant_list" headerText={this.props.selectedGroupName} 
                                                            data={participantNames} canTurnOffUserVideo={this.props.canTurnOffUserVideo} 
                                                            onClickUserSpeaker={this.onClickUserSpeaker} onClickUserMike={this.onClickUserMike}
                                                            onClickUserVideo={this.onClickUserVideo} isAccountLevelExpertVideoDisabled={isAccountLevelExpertVideoDisabled} /**TP-5453*/ sessionType={sessionType} myPicture={myPicture}
                                                        />
                                                    </>
                                                :
                                                    <span id="joineesTooltip" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute cursor-pointer member-count badge badge-danger badge-pill">
                                                        {participantNames.length + 1}
                                                    </span>
                                                : null
                                            }
                                            { isSmallFormFactor === false ? 
                                                <a data-tool-tip={/*TP-3854 TP-3629*/(isAccountLevelExpertVideoDisabled === false) ? (participantNames.length >= 1 && isOngoingMaxDivFeatures === false && maxView === true ? i18n._(t`session.gridViewText`): i18n._(t`session.participantCount`)) : (participantNames.length > 1 && this.props.technicianCount > 1 && isOngoingMaxDivFeatures === false && maxView === false ? i18n._(t`session.gridViewText`): i18n._(t`session.participantCount`))} className={buttonSize+" cursor-pointer"} onClick={(e) => this.onClickGrid(e)}>
                                                    {/* <span class="sideBar-icons" > */}
                                                    { /**TP-3629 TP-3419 & TP-3532*/ isAccountLevelExpertVideoDisabled === false ?
                                                        <i id="participantCount" className={participantNames.length >= 1 && maxView === true /**TP-3854*/ ? "fas fa-th padding-left-5 sideBar-icons" : "fas fa-user padding-left-7 sideBar-icons"} style= {{color: participantNames.length >= 1 && isOngoingMaxDivFeatures === false ? '#23c97d' : '#A9A9A9'}}></i>       
                                                        :
                                                        <i id="participantCount" className={participantNames.length > 1 && this.props.technicianCount > 1 && /**& TP-1547 */ userVideoCount > 1 && maxView === true /*TP-3629*/? "fas fa-th padding-left-5 sideBar-icons" : "fas fa-user padding-left-7 sideBar-icons"} style= {{color: /**TP-5970*/ participantNames.length >= 1 && isOngoingMaxDivFeatures === false ? '#23c97d' : '#A9A9A9'}}></i>                                        
                                                    }
                                                    {/* </span> */}
                                                    {/* <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="participantCount">                                            
                                                        { participantNames.length > 1 && this.props.technicianCount > 1 && isOngoingMaxDivFeatures === false ? 
                                                            <Trans>session.gridViewText</Trans>
                                                            : 
                                                            <Trans>session.participantCount</Trans>
                                                        }
                                                    </UncontrolledTooltip> */}
                                                </a>  
                                                :                                          
                                                <a className={buttonSize+" cursor-pointer"} onClick={(e) => this.onClickGrid(e)}>
                                                    {/* <span class="sideBar-icons" > */}
                                                    { /**TP-3629 TP-3419 & TP-3532*/ isAccountLevelExpertVideoDisabled === false ?
                                                        <i id="participantCount" className={participantNames.length >= 1 && maxView === true /**TP-3854*/ ? "fas fa-th sideBar-icons" : "fas fa-user sideBar-icons"} style= {{color: participantNames.length > 1 && isOngoingMaxDivFeatures === false ? '#23c97d' : '#A9A9A9'}}></i> 
                                                        :
                                                        <i id="participantCount" className={participantNames.length > 1 && this.props.technicianCount > 1 && /**& TP-1547 */ userVideoCount > 1 && maxView === true /**TP-3629*/ ? "fas fa-th sideBar-icons" : "fas fa-user sideBar-icons"} style= {{color: participantNames.length > 1 && isOngoingMaxDivFeatures === false ? '#23c97d' : '#A9A9A9'}}></i>                                     
                                                    }
                                                    {/* </span> */}
                                                    {/* <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="participantCount">                                            
                                                        { participantNames.length > 1 && this.props.technicianCount > 1 && isOngoingMaxDivFeatures === false ? 
                                                            <Trans>session.gridViewText</Trans>
                                                            : 
                                                            <Trans>session.participantCount</Trans>
                                                        }
                                                    </UncontrolledTooltip> */}
                                                </a> 
                                            }
                                        </>
                                        :
                                        <>
                                        { /*TP-3629 TP-3419 & TP-3532*/ isSmallFormFactor === false ? 
                                            <a data-tool-tip={i18n._(t`session.participantCount`)} className={buttonSize+" cursor-pointer"} onClick={(e) => this.handleClick(e)}>
                                                {participantNames.length > 0 ? 
                                                    <>
                                                        <UncontrolledTooltip placement="bottom" innerClassName="tip-badge" target="joineesTooltip">
                                                            <><h3><b><u>{this.props.selectedGroupName}</u></b></h3></>
                                                            {participantNames.map((e,i) => <Fragment key={i}><span>{e.name}</span><br /></Fragment>)}
                                                            <Fragment><span>* <Trans>You</Trans></span><br /></Fragment>
                                                        </UncontrolledTooltip>
                                                        <span id="joineesTooltip" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute member-count badge badge-danger badge-pill">
                                                            {participantNames.length + 1}
                                                        </span>
                                                    </>
                                                : null
                                                }
                                                {/* <span class="sideBar-icons" > */}
                                                {/**TP-3629 TP-3419 & TP-3532*/ isAccountLevelExpertVideoDisabled === false ?
                                                    <i id="participantCount" className="fas fa-user padding-left-7 sideBar-icons" style= {{color: participantNames.length > 1 && isOngoingMaxDivFeatures === false ? '#23c97d' : '#A9A9A9'}}></i>                                        
                                                    :
                                                    <i id="participantCount" className="fas fa-user padding-left-7 sideBar-icons" style= {{color: participantNames.length > 1 && isOngoingMaxDivFeatures === false ? '#23c97d' : '#A9A9A9'}}></i>
                                                }
                                                {/* </span> */}
                                                {/* <UncontrolledTooltip placement="bottom" autohide={false} innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="participantCount">                                            
                                                    { participantNames.length > 1 && this.props.technicianCount > 1 && isOngoingMaxDivFeatures === false ? 
                                                        <Trans>session.gridViewText</Trans>
                                                        : 
                                                        <Trans>session.participantCount</Trans>
                                                    }
                                                </UncontrolledTooltip> */}
                                            </a>
                                            :
                                            <a className={buttonSize+" cursor-pointer"} onClick={(e) => this.handleClick(e)}>
                                                {participantNames.length > 0 ? 
                                                    <>
                                                        <UncontrolledTooltip placement="bottom" innerClassName="tip-badge" target="joineesTooltip">
                                                            <><h3><b><u>{this.props.selectedGroupName}</u></b></h3></>
                                                            {participantNames.map((e,i) => <Fragment key={i}><span>{e.name}</span><br /></Fragment>)}
                                                            <Fragment><span>* <Trans>You</Trans></span><br /></Fragment>
                                                        </UncontrolledTooltip>
                                                        <span id="joineesTooltip" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute member-count badge badge-danger badge-pill">
                                                            {participantNames.length + 1}
                                                        </span>
                                                    </>
                                                : null
                                                }
                                                {/* <span class="sideBar-icons" > */}
                                                {/**TP-3629 TP-3419 & TP-3532*/  isAccountLevelExpertVideoDisabled === false ?
                                                    <i id="participantCount" className="fas fa-user sideBar-icons" style= {{color: participantNames.length > 1 && isOngoingMaxDivFeatures === false ? '#23c97d' : '#A9A9A9'}}></i>                                        
                                                    :
                                                    <i id="participantCount" className="fas fa-user sideBar-icons" style= {{color: participantNames.length > 1 && isOngoingMaxDivFeatures === false ? '#23c97d' : '#A9A9A9'}}></i>                                        
                                                }
                                                {/* </span> */}
                                                {/* <UncontrolledTooltip placement="bottom" autohide={false} innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="participantCount">                                            
                                                    { participantNames.length > 1 && this.props.technicianCount > 1 && isOngoingMaxDivFeatures === false ? 
                                                        <Trans>session.gridViewText</Trans>
                                                        : 
                                                        <Trans>session.participantCount</Trans>
                                                    }
                                                </UncontrolledTooltip> */}
                                            </a>
                                        }
                                        </>
                                    :
                                    /* isOnprem === false && */ showMaxDivControls === true ?
                                        <>
                                            {participantNames.length > 0 ? 
                                                isOngoingMaxDivFeatures === false ?
                                                    <>
                                                        {/* <UncontrolledPopover trigger="legacy" placement="bottom" className="tip-badge react-tooltip-clickable-link" target="joineesTooltip">
                                                            <PopoverHeader className="popover-header"><b><u>{this.props.selectedGroupName}</u></b></PopoverHeader>
                                                            <PopoverBody>
                                                                {participantNames.map((e,i) => 
                                                                    <Fragment key={i}>
                                                                        <span>{e.name}</span>
                                                                        
                                                                            
                                                                        { e.name.includes("*") === false ?
                                                                            <>
                                                                            &nbsp;&nbsp;&nbsp;<a onClick={(e) => this.onClickUserSpeaker(e)}><i id={e.userId+"-link"} value={e} className={e.disableAudio ? "fas fa-volume-up font-awesome-red": "fas fa-volume-up"}></i></a>
                                                                            &nbsp;&nbsp;<a onClick={(e) => this.onClickUserMike(e)}><i id={e.userId+"-link"} value={e} className={e.disableMike ? "fas fa-microphone-slash font-awesome-red": "fas fa-microphone"}></i></a>
                                                                                { canTurnOffUserVideo === true ?
                                                                                    <>&nbsp;&nbsp;<a onClick={(e) => this.onClickUserVideo(e)}><i id={e.userId+"-link"} value={e} className={e.disableVideo ? "fas fa-video-slash font-awesome-red": "fas fa-video font-awesome-green"}></i></a></>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </>
                                                                            :
                                                                            ''
                                                                        }
                                                                        <br />
                                                                    </Fragment>)}
                                                                <Fragment><span>* <Trans>You</Trans></span><br /></Fragment>
                                                            </PopoverBody>
                                                        </UncontrolledPopover> */}
                                                        <a onClick={(e) => this.handleOnButtonClick(e)}>
                                                            <span id="joineesTooltip" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute cursor-pointer member-count badge badge-danger badge-pill">
                                                                {participantNames.length + 1}
                                                            </span>
                                                        </a>
                                                        <ToolTip ref={this.toolTip} inputRef={this.toolTip} type="partcipant_list" headerText={this.props.selectedGroupName} 
                                                            data={participantNames} canTurnOffUserVideo={this.props.canTurnOffUserVideo} 
                                                            onClickUserSpeaker={this.onClickUserSpeaker} onClickUserMike={this.onClickUserMike}
                                                            onClickUserVideo={this.onClickUserVideo} isAccountLevelExpertVideoDisabled={isAccountLevelExpertVideoDisabled} /**TP-5453*/ sessionType={sessionType} myPicture={myPicture}
                                                        />
                                                    </>
                                                :
                                                    <span id="joineesTooltip" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute cursor-pointer member-count badge badge-danger badge-pill">
                                                        {participantNames.length + 1}
                                                    </span>
                                                : null
                                            }
                                            { isSmallFormFactor === false ? 
                                                <a data-tool-tip={i18n._(t`session.participantCount`)} className={buttonSize+" cursor-pointer"} onClick={(e) => this.handleClick(e)}>
                                                    {/* <span class="sideBar-icons"> */}
                                                        <i id="participantCount" className={"fas fa-user padding-left-7 sideBar-icons "} style= {{color: '#23c97d'}}></i>
                                                    {/* </span> */}
                                                    {/* <UncontrolledTooltip placement="bottom" autohide={false} innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="participantCount">
                                                        <Trans>session.participantCount</Trans>
                                                    </UncontrolledTooltip> */} 
                                                </a>                                            
                                                :
                                                <a className={buttonSize+" cursor-pointer"} onClick={(e) => this.handleClick(e)}>
                                                    {/* <span class="sideBar-icons"> */}
                                                        <i id="participantCount" className={"fas fa-user sideBar-icons "} style= {{color: '#23c97d'}}></i>
                                                    {/* </span> */}
                                                    {/* <UncontrolledTooltip placement="bottom" autohide={false} innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="participantCount">
                                                        <Trans>session.participantCount</Trans>
                                                    </UncontrolledTooltip> */} 
                                                </a> 
                                            }
                                        </>
                                        :
                                        <>
                                            { isSmallFormFactor === false ?
                                                <a data-tool-tip={i18n._(t`session.participantCount`)} className={buttonSize+" cursor-pointer"} onClick={(e) => this.handleClick(e)}>
                                                    {participantNames.length > 0 ? 
                                                        <>
                                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-badge" target="joineesTooltip">
                                                                <><h3><b><u>{this.props.selectedGroupName}</u></b></h3></>
                                                                {participantNames.map((e,i) => <Fragment key={i}><span>{e.name}</span><br /></Fragment>)}
                                                                <Fragment><span>* <Trans>You</Trans></span><br /></Fragment>
                                                            </UncontrolledTooltip>
                                                            <span id="joineesTooltip" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute member-count badge badge-danger badge-pill">
                                                                {participantNames.length + 1}
                                                            </span>
                                                        </>
                                                    : null
                                                    }
                                                    {/* <span class="sideBar-icons"> */}
                                                        <i id="participantCount" className={"fas fa-user padding-left-7 sideBar-icons "} style= {{color: '#23c97d'}}></i>
                                                    {/* </span> */}
                                                    {/* <UncontrolledTooltip placement="bottom" autohide={false} innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="participantCount">
                                                        <Trans>session.participantCount</Trans>
                                                    </UncontrolledTooltip> */}                                     
                                                </a>
                                                :
                                                <a className={buttonSize+" cursor-pointer"} onClick={(e) => this.handleClick(e)}>
                                                    {participantNames.length > 0 ? 
                                                        <>
                                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-badge" target="joineesTooltip">
                                                                <><h3><b><u>{this.props.selectedGroupName}</u></b></h3></>
                                                                {participantNames.map((e,i) => <Fragment key={i}><span>{e.name}</span><br /></Fragment>)}
                                                                <Fragment><span>* <Trans>You</Trans></span><br /></Fragment>
                                                            </UncontrolledTooltip>
                                                            <span id="joineesTooltip" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute member-count badge badge-danger badge-pill">
                                                                {participantNames.length + 1}
                                                            </span>
                                                        </>
                                                    : null
                                                    }
                                                    {/* <span class="sideBar-icons"> */}
                                                        <i id="participantCount" className={"fas fa-user sideBar-icons "} style= {{color: '#23c97d'}}></i>
                                                    {/* </span> */}
                                                    {/* <UncontrolledTooltip placement="bottom" autohide={false} innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="participantCount">
                                                        <Trans>session.participantCount</Trans>
                                                    </UncontrolledTooltip> */}                                     
                                                </a>
                                            }
                                        </>
                                    }   
                                </li> 
                                { ( /* isOnprem === false &&  */inviteOnlineUser === true && onlineUsers !== undefined && onlineUsers.length > 0 && !this.props.isGuest && this.props.showHeaderActionButtons) ?
                                    <li className="nav-item flex-fill">
                                        {/* <UncontrolledPopover trigger="legacy"  placement="bottom" className="tip-badge react-tooltip-clickable-link" target="invite_users">
                                            <PopoverHeader className="popover-header"><b><u>{this.props.selectedGroupName}</u></b></PopoverHeader>
                                            <PopoverBody>
                                                {onlineUsers.map((c,i) => 
                                                    <Fragment key={i}>
                                                        { onlineUsers[i].label !== undefined ?
                                                            <span>{onlineUsers[i].label}</span>
                                                            :
                                                            <span>{onlineUsers[i].first_name+ " " +onlineUsers[i].last_name}</span>
                                                        }
                                                        { 
                                                            <>&nbsp;&nbsp;&nbsp;<a className={" cursor-pointer"} onClick={(e) => this.onClickUserCall(e)}><i id={i+"-link"} value={c} className={ onlineUsers[i].isBusy === false ? "fas fa-phone font-awesome-green": "fas fa-phone-slash font-awesome-red"}></i></a></>                                                            
                                                        }
                                                        <br />
                                                    </Fragment>)}
                                                    { (this.props.guest_user_enabled === true && this.state.creditsAvailable > 0 && !this.props.isGuest && this.props.showHeaderActionButtons) ?
                                                        <Fragment>
                                                            <span>
                                                                <a className={" cursor-pointer"} onClick={(e) => this.showHideGuestForm(e)}>
                                                                    Invite Guest User
                                                                </a>
                                                            </span><br />
                                                        </Fragment>
                                                        :
                                                        null
                                                    }
                                            </PopoverBody>
                                        </UncontrolledPopover> */}
                                        <ToolTip ref={this.slider} inputRef={this.slider} type="invite_users" headerText={this.props.selectedGroupName} 
                                            data={this.props.onlineUsers} inviteOnlineUser={inviteOnlineUser} guest_user_enabled={this.props.guest_user_enabled} 
                                            creditsAvailable={this.state.creditsAvailable} isGuest={this.props.isGuest}
                                            showHeaderActionButtons={this.props.showHeaderActionButtons} onClickUserCall={this.onClickUserCall}
                                            showHideGuestForm={this.showHideGuestForm} sessionType={sessionType} myPicture={myPicture}
                                        />
                                        <a className={buttonSize+" cursor-pointer"} onClick={(e) => this.handleOnInviteUsersClick(e)}>
                                            {/* <span class="sideBar-icons"> */}
                                                <i id="invite_users" className="fas fa-user-plus sideBar-icons"></i>
                                            {/* </span>  */}
                                            { isSmallFormFactor === false ?
                                                <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="invite_users">                                            
                                                    <Trans>session.inviteOnlineUsers</Trans> 
                                                </UncontrolledTooltip> 
                                                :
                                                ''
                                            }
                                        </a>
                                    </li>
                                    :
                                    (this.props.guest_user_enabled === true && this.state.creditsAvailable > 0 && !this.props.isGuest && this.props.showHeaderActionButtons) ?
                                        <li className="nav-item flex-fill">
                                            <a className={buttonSize+" cursor-pointer"} onClick={(e) => this.showHideGuestForm(e)} /* data-tool-tip={i18n._(t`session.addGuest`)} */ >
                                                {/* <span class="sideBar-icons"> */}
                                                    <i id="addGuest" className="fas fa-user-plus sideBar-icons"></i>
                                                {/* </span>  */}
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="addGuest">                                            
                                                        <Trans>session.addGuest</Trans> 
                                                    </UncontrolledTooltip>    
                                                    :                                         
                                                    ''
                                                }
                                            </a>
                                        </li>
                                    : null                                    
                                }                                   
                                {this.props.expert_ss_enabled === true && !(this.props.isiOS && this.props.isSafari) && this.props.showHeaderActionButtons && this.props.hideExpertSSAll === false ?
                                    (((this.props.fileShareMaxDiv || this.props.isHeaderFileShare) && !this.props.screenShareAll) || this.props.screenShareMaxDiv || this.props.bRemoteCapture) /* TP-1426 */ /*TP-1255*/?
                                        <li className={!(this.props.isiOS && this.props.isSafari)? "nav-item flex-fill "+hideButton : "nav-item flex-fill"}>
                                            <a className={buttonSize+" disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.expertSSAll`)} */>
                                                {/* <span class="sideBar-icons"> */}
                                                    <i id="expertSSAll" className="fab fa-chromecast sideBar-icons  font-awesome-blue" ></i>                                                
                                                {/* </span> */}
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="expertSSAll">                                            
                                                        <Trans>session.expertSSAll</Trans>
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </a>
                                        </li>
                                        :
                                        <li className={"nav-item flex-fill "+hideButton}>
                                            <a className={buttonSize+" cursor-pointer"} onClick={/*TP-5397*/ this.props.isSSOngoing ? () => {return false;} : (e) => this.onClickScreenShare(e)} /* data-tool-tip={i18n._(t`session.expertSSAll`)} */>                                                
                                                {/* <span class="sideBar-icons"> */}
                                                    <i id="expertSSAll" className={"fab fa-chromecast sideBar-icons "+screenShareIcon}></i>  
                                                {/* </span> */}
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="expertSSAll">                                            
                                                        <Trans>session.expertSSAll</Trans>
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </a>
                                        </li>
                                    : this.props.expert_ss_enabled === true && !(this.props.isiOS && this.props.isSafari) && this.props.showHeaderActionButtons && this.props.hideExpertSSAll === true ?
                                        <li className={!(this.props.isiOS && this.props.isSafari)? "nav-item flex-fill "+hideButton : "nav-item flex-fill"}>
                                            <a className={buttonSize+" disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.expertSSAll`)} */>
                                                {/* <span class="sideBar-icons"> */}
                                                    <i id="expertSSAll" className="fab fa-chromecast sideBar-icons  font-awesome-blue" ></i>                                                
                                                {/* </span> */}
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="expertSSAll">                                            
                                                        <Trans>session.expertSSAll</Trans>
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </a>
                                        </li>
                                    : null
                                }
                                { this.props.behaviour !== "pndsafety" /**TP-6373*/ ?
                                    <li className={"nav-item flex-fill "+hideButton}>
                                        {(this.props.bAnnotate || this.props.bRemoteCapture) ?
                                            <a className={buttonSize+" disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.chatAll`)} */>
                                                {/* <span class="sideBar-icons"> */}
                                                    <i id="chatAll" className="far fa-comments sideBar-icons  font-awesome-blue"></i>
                                                {/* </span> */}
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chatAll">                                            
                                                        <Trans>session.chatAll</Trans>
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </a>
                                            :
                                                <>
                                                    {
                                                        this.props.isSafari ?
                                                            <a className="nav-link" href="javascript:void(0)" onClick={(e) => this.onClickChat(e)} /* data-tool-tip={i18n._(t`session.chatAll`)} */>
                                                                {/* <span class="sideBar-icons"> */}
                                                                    <i id="chatAll" className={"far fa-comments rtcIcon sideBar-icons " + toggleChatIcon} ></i> 
                                                                {/* </span> */}
                                                                { isSmallFormFactor === false ?
                                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chatAll">                                            
                                                                        <Trans>session.chatAll</Trans>
                                                                    </UncontrolledTooltip>
                                                                    :
                                                                    ''
                                                                }
                                                                <span >
                                                                    {chatUserCount > 0 ?
                                                                        <>
                                                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-badge" target="PopoverFocus" id="tooltip-bottom">
                                                                                {chatUserNames.map((e,i) => <Fragment key={i}><span>{e}</span><br /></Fragment>)}
                                                                            </UncontrolledTooltip>
                                                                            <span id="PopoverFocus" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute member-count badge badge-danger badge-pill" >
                                                                                {chatUserCount} 
                                                                            </span>
                                                                        </>
                                                                        : null
                                                                    }
                                                                </span>
                                                            </a>
                                                        :
                                                            <a className={buttonSize+" cursor-pointer"} onClick={(e) => this.onClickChat(e)} /* data-tool-tip={i18n._(t`session.chatAll`)} */>  
                                                                {/* <span class="sideBar-icons"> */}
                                                                    <i id="chatAll" className={"far fa-comments sideBar-icons  "+toggleChatIcon} ></i> 
                                                                {/* </span> */}          
                                                                { isSmallFormFactor === false ?
                                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chatAll">                                            
                                                                        <Trans>session.chatAll</Trans>
                                                                    </UncontrolledTooltip>
                                                                    :
                                                                    ''
                                                                }                         
                                                                <span >
                                                                    {chatUserCount > 0 ?
                                                                        <>
                                                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-badge" target="PopoverFocus" id="tooltip-bottom">
                                                                                {chatUserNames.map((e, i) => <Fragment key={i}><span>{e}</span><br /></Fragment>)}
                                                                            </UncontrolledTooltip>
                                                                            <span id="PopoverFocus" style={(window.innerWidth > 1299) ? {fontSize: '75%'} : (window.innerWidth <= 1298 && window.innerWidth > 1030) ? {fontSize: '50%'} : {fontSize: '40%'}} className="position-absolute chat-count badge badge-danger badge-pill" >
                                                                                {chatUserCount}
                                                                            </span>
                                                                        </>
                                                                        : null
                                                                    }
                                                                </span>
                                                            </a>
                                                    }
                                                </>
                                        }
                                    </li>
                                    :
                                    ''
                                }
                                {this.props.showHeaderActionButtons && this.props.hideExpertSSAll === false && this.props.behaviour !== "pndsafety" /*TP-6373*/ ?
                                    (/* this.props.screenShareMaxDiv || this.props.screenShareAll || TP-1426 */ this.props.bRemoteCapture) /*TP-1255*/ ?
                                        <li className="nav-item flex-fill">
                                            <a className={buttonSize+" text-center disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.shareAssetToAll`)} */>
                                                {/* <span class="sideBar-icons"> */}
                                                    <i id="shareAssetToAll" className="fas fa-upload sideBar-icons font-awesome-blue"></i>
                                                {/* </span> */}
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="shareAssetToAll">                                            
                                                        <Trans>session.shareAssetToAll</Trans>
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </a>
                                        </li>
                                        :
                                        <li className="nav-item flex-fill">
                                            <a className={buttonSize+" text-center cursor-pointer"} onClick={(e) => this.onclickFileShare(e)} /* data-tool-tip={i18n._(t`session.shareAssetToAll`)} */>
                                                {/* <span class="sideBar-icons"> */}
                                                    <i id="shareAssetToAll" className={"fas fa-upload  sideBar-icons " + fileShareIcon}></i>
                                                {/* </span> */}
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="shareAssetToAll">                                            
                                                        <Trans>session.shareAssetToAll</Trans>
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </a>
                                        </li>
                                    : this.props.showHeaderActionButtons && this.props.hideExpertSSAll === true ?
                                        <li className="nav-item flex-fill">
                                            <a className={buttonSize+" text-center disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.shareAssetToAll`)} */>
                                                {/* <span class="sideBar-icons"> */}
                                                    <i id="shareAssetToAll" className="fas fa-upload sideBar-icons font-awesome-blue"></i>
                                                {/* </span> */}
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="shareAssetToAll">                                            
                                                        <Trans>session.shareAssetToAll</Trans>
                                                    </UncontrolledTooltip>
                                                    :
                                                    ''
                                                }
                                            </a>
                                        </li>
                                    :
                                    null
                                }
                                <li className="nav-item flex-fill">
                                    <a className={buttonSize+" text-center cursor-pointer"} onClick={(e) => this.muteMyMike(e)} /* data-tool-tip={i18n._(t`session.muteMike`)} */>
                                        {/* <span class="sideBar-icons"> */}
                                            <i id="muteMike" className={"fas " + muteMikeIcon + ' sideBar-icons '}></i>
                                        {/* </span> */}
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteMike">                                            
                                                <Trans>session.muteMike</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                </li>
                                <li className="nav-item flex-fill">
                                    {/* T32-413 -- Disabled Speaker of Remote user implementation */}
                                    { disabledMutedAll === false ?
                                        <a className={buttonSize+" text-center cursor-pointer"} onClick={(e) => this.muteMySpeaker(e)} /* data-tool-tip={i18n._(t`session.muteAll`)} */>
                                            {/* <span class="sideBar-icons"> */}
                                                <i id="muteAll" className={"fas " + muteSpeakerIcon + ' sideBar-icons '} ></i>
                                            {/* </span> */}
                                            { isSmallFormFactor === false ?
                                                <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteAll">                                            
                                                    <Trans>session.muteAll</Trans>
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                        :
                                        <a className={buttonSize+" text-center cursor-pointer"} /* data-tool-tip={i18n._(t`session.muteAll`)} */>
                                            {/* <span class="sideBar-icons"> */}
                                                <i id="muteAll" className={"fas " + muteSpeakerIcon + ' icon-disabled sideBar-icons '} ></i>
                                            {/* </span> */}
                                            { isSmallFormFactor === false ?
                                                <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteAll">                                            
                                                    <Trans>session.muteAll</Trans>
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                    }
                                </li>
                                <li className="nav-item flex-fill">
                                    <a className={buttonSize+" cursor-pointer data-top"} onClick={(e) => this.onclickStopSession(e)} /* data-tool-tip={i18n._(t`session.stopStream`)} */>
                                        {/* <span class="sideBar-icons"> */}
                                            <i id="stopStream" className="fas fa-stop-circle sideBar-icons "></i>
                                        {/* </span> */}
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="stopStream">                                            
                                                <Trans>session.stopStream</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                </li>
                            </ul>
                        </div>
                </div>
                }
            </I18n>   
            )
        } else {            
            return (
            <I18n>
                {({ i18n }) =>     
                <div className="row no-gutters rounded">
                    <div className="col-6 d-flex min-vh-100">
                        <ul className="nav flex-column sidebar-gradient zindex-1062" style={(window.innerWidth > 1349) ? { minWidth: "75px"}: {minWidth: "50px"}}>
                            <li className="nav-item flex-fill"></li>
                            <li className="nav-item flex-fill">
                                { /* T32-445 */ hideButton === "d-blk" ? 
                                    <a className="nav-brand" onClick={(e) => this.handleClick(e)}>
                                        <img id="logoToolTip" src={window._env_.REACT_APP_ASSET_FOLDER+"/session_sidebar_logo.png"} alt='logo' style={iconStyle} className=" left-Icons" onClick={(e) => this.handleClick(e)}/>
                                        {/* <UncontrolledTooltip placement="bottom" innerClassName="tip" style={{ backgroundColor: '#FFFFFF' }} modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="logoToolTip">                                            
                                            <img src="/green_logo_transparent_background.png" />
                                        </UncontrolledTooltip> */}
                                    </a>
                                    :
                                    <a className="nav-brand" onClick={(e) => this.handleClick(e)}>
                                        <img id="logoToolTip" src={window._env_.REACT_APP_ASSET_FOLDER+"/session_sidebar_logo.png"} alt='logo' style={iconStyle} className=" left-Icons" onClick={(e) => this.handleClick(e)}/>
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-logo" /* style={{ backgroundColor: '#FFFFFF' }}  */ modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="logoToolTip">                                            
                                                <Trans>session.logo</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                            
                                    </a>

                                }
                                
                            </li>
                            <li className="nav-item flex-fill">
                                <a id="participantCount" className={buttonSize+" cursor-pointer"} onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.participantCount`)} */ >
                                    <i id="participantCount" className="fas fa-user padding-left-7 relative no-cursor-pointer" style= {{color: '#A9A9A9'}}></i>
                                    { isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="participantCount">                                            
                                            <Trans>session.participantCount</Trans>                                            
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>
                            </li>  

                            {/* {(this.props.guest_user_enabled === true && this.state.creditsAvailable > 0 && !this.props.isGuest && this.props.showHeaderActionButtons) ? */}
                                <li className="nav-item flex-fill">
                                        <a className={buttonSize+" cursor-pointer"} /* data-tool-tip={i18n._(t`session.addGuest`)} */ >
                                            <i id="addGuest" className="fab fa-user-plus   font-awesome-blue"></i>
                                            { isSmallFormFactor === false ?
                                                <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="addGuest">                                            
                                                    <Trans>session.addGuest</Trans>
                                                </UncontrolledTooltip>
                                                :
                                                ''
                                            }
                                        </a>
                                </li>                             
                            {/* : null} */}
                            
                            {/* {this.props.expert_ss_enabled === true && !this.props.isSafari && this.props.showHeaderActionButtons && this.props.hideExpertSSAll === false ? */}
                                <li className={!(this.props.isiOS && this.props.isSafari)? "nav-item flex-fill "+hideButton : "nav-item flex-fill"}>
                                    <a className={buttonSize+" disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.expertSSAll`)} */ >
                                        <i id="expertSSAll" className="fab fa-chromecast   font-awesome-blue" ></i>
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="expertSSAll">                                            
                                                <Trans>session.expertSSAll</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                </li>
                                {/* :
                                null
                            } */}
                            { this.props.behaviour !== "pndsafety" /**TP-6373*/ ?
                                <li className={"nav-item flex-fill "+hideButton}>
                                    <a className={buttonSize+" disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.chatAll`)} */>
                                        <i id="chatAll" className="far fa-comments   font-awesome-blue"></i>
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chatAll">                                            
                                                <Trans>session.chatAll</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                </li>
                                :
                                ''
                            }
                            {this.props.behaviour !== "pndsafety" /**TP-6373 */ ?
                                <li className="nav-item flex-fill">
                                    <a className={buttonSize+" disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.shareAssetToAll`)} */>
                                        <i id="shareAssetToAll" className="fas fa-upload   font-awesome-blue"></i>
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="shareAssetToAll">                                            
                                                <Trans>session.shareAssetToAll</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                </li>
                                :
                                null
                            }
                            <li className="nav-item flex-fill">
                                <a className={buttonSize+" text-center disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.muteMike`)} */>
                                    <i id="muteMike" className={"fas " + muteMikeIcon + '   font-awesome-blue'} ></i>
                                    { isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteMike">                                            
                                            <Trans>session.muteMike</Trans>
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>
                            </li>
                            <li className="nav-item flex-fill">
                                <a className={buttonSize+" text-center disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.muteAll`)} */>
                                    <i id="muteAll" className={"fas " + muteSpeakerIcon + '   font-awesome-blue'} ></i>
                                    { isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteAll">                                            
                                            <Trans>session.muteAll</Trans>
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>
                            </li>
                            <li className="nav-item flex-fill">
                                <a className={buttonSize+" disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.stopStream`)} */>
                                    <i id="stopStream" className="fas fa-stop-circle   font-awesome-blue"></i>
                                    { isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="stopStream">                                            
                                            <Trans>session.stopStream</Trans>
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                }
            </I18n>    
            )
        }
    }

    technicianContent = () => {
        const { muteMikeIcon, changeCameraIcon, shouldStartStopChange, hideButton, stopSessionIcon } = this.state;
        const { isSmallFormFactor } = this.props;
        let buttonSize = (window.innerWidth > 1349) ? "sm-nav-link" : (window.innerWidth > 1299) ? "md-nav-link" : "xs-nav-link";
        //buttonSize = (hideButton === 'd-none') ? "sm-nav-link" : buttonSize;
        const iconStyle = (window.innerWidth > 1349) ? {
            width: "45px",
            height: "45px"
        } : {
            width: "30px",
            height: "30px"
        }
        if (shouldStartStopChange === false) {
            return (
            <I18n>
                {({ i18n }) =>     
                <div className="row no-gutters rounded">
                    <div className="col-8 d-flex min-vh-100">
                        <ul className="nav flex-column sidebar-gradient zindex-1062" style={(window.innerWidth > 1349) ? { minWidth: "75px"}: {minWidth: "50px"}}>
                            <li className="nav-item flex-fill"></li>
                            <li className="nav-item flex-fill">
                                { /* T32-445 */ hideButton === "d-blk" ? 
                                    <a className="nav-brand cursor-pointer" onClick={(e) => this.handleClick(e)}>
                                        <img id="logoToolTip" src={window._env_.REACT_APP_ASSET_FOLDER+"/session_sidebar_logo.png"} alt='logo' style={iconStyle} className=" left-Icons" />
                                        {/* <UncontrolledTooltip placement="bottom" innerClassName="tip" style={{ backgroundColor: '#FFFFFF' }}  modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="logoToolTip">                                            
                                            <img src="/green_logo_transparent_background.png" />
                                        </UncontrolledTooltip> */}
                                    </a>
                                    :
                                    <a className="nav-brand cursor-pointer" onClick={(e) => this.handleClick(e)}>
                                        <img id="logoToolTip" src={window._env_.REACT_APP_ASSET_FOLDER+"/session_sidebar_logo.png"} alt='logo' style={iconStyle} className="left-Icons" />
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-logo" /* style={{ backgroundColor: '#FFFFFF' }} */  modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="logoToolTip">                                            
                                                <Trans>session.logo</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                }
                            </li>
                            
                            {this.props.swap_camera_enabled ?
                                <li className="nav-item flex-fill">
                                    <a className="nav-brand cursor-pointer" aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.changeCameraDisabled`)} */>
                                        <img id="changeCameraDisabled" src={iconsPath+"/Multi_camera/disable.png"} style={iconStyle} className="sideBar-icons left-Icons"  />
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="changeCameraDisabled">                                            
                                                <Trans>session.changeCameraDisabled</Trans>                                            
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                </li>
                            : ''}
                            <li className="nav-item flex-fill">
                                <a className={buttonSize+" disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.muteMike`)} */>
                                    <i id="muteMike" className={"fas " + muteMikeIcon + ' font-awesome-blue'}></i>
                                    { isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteMike">                                            
                                            <Trans>session.muteMike</Trans>                                            
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>
                            </li>
                            <li className="nav-item flex-fill">
                                <a className={buttonSize+" disabled cursor-pointer"} aria-disabled="true" onClick={(e) => this.handleClick(e)} /* data-tool-tip={i18n._(t`session.stopStream`)} */>
                                    <i id="stopStream" className="fas fa-stop-circle font-awesome-blue" ></i>
                                    { isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="stopStream">                                            
                                            <Trans>session.stopStream</Trans>                                            
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                }
            </I18n>    
            )
        }  else {
            return (
            <I18n>
                {({ i18n }) =>     
                <div className="row no-gutters rounded">
                    <div className="col-8 d-flex min-vh-100">
                        <ul className="nav flex-column sidebar-gradient zindex-1062" style={(window.innerWidth > 1349) ? { minWidth: "75px"}: {minWidth: "50px"}}>
                            <li className="nav-item flex-fill"></li>
                            <li className="nav-item flex-fill">
                                { /* T32-445 */ hideButton === "d-blk" ? 
                                    <a className="nav-brand" onClick={(e) => this.handleClick(e)}>
                                        <img id="logoToolTip" src={window._env_.REACT_APP_ASSET_FOLDER+"/session_sidebar_logo.png"} alt='logo' style={iconStyle} className="left-Icons" />
                                        {/* <UncontrolledTooltip placement="bottom" innerClassName="tip" style={{ backgroundColor: '#FFFFFF' }} modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="logoToolTip">                                            
                                            <img src="/green_logo_transparent_background.png" />
                                        </UncontrolledTooltip> */}
                                    </a>
                                    :
                                    <a className="nav-brand" onClick={(e) => this.handleClick(e)}>
                                        <img id="logoToolTip" src={window._env_.REACT_APP_ASSET_FOLDER+"/session_sidebar_logo.png"} alt='logo' style={iconStyle} className="left-Icons" />
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-logo" /* style={{ backgroundColor: '#FFFFFF' }} */ modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="logoToolTip">                                            
                                                <Trans>session.logo</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </a>
                                }
                            </li>
                            {this.props.swap_camera_enabled ?
                                <>
                                    {this.props.cameraCount > 1 && ((this.props.maxView && !this.props.isOnprem) || this.props.isOnprem) && this.props.publishVideo === true ?
                                        <li className="nav-item flex-fill">
                                            <a className="nav-brand cursor-pointer" onClick={(e) => this.onClickChangeCamera(e)} /* data-tool-tip={i18n._(t`session.changeCamera`)} */>
                                                <img id="changeCamera" src={changeCameraIcon + ".png"} style={iconStyle} className="sideBar-icons left-Icons" />
                                                { isSmallFormFactor === false ?
                                                    <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="changeCamera">                                            
                                                        <Trans>session.changeCamera</Trans>                                            
                                                    </UncontrolledTooltip>    
                                                    :                                                    
                                                    ''
                                                }
                                            </a>
                                        </li>
                                        
                                        : null
                                    }
                                </>
                            : ''}
                            <li className="nav-item flex-fill">
                                <a className={buttonSize+" text-center cursor-pointer"} onClick={(e) => this.muteMyMike(e)} /* data-tool-tip={i18n._(t`session.muteMike`)} */>
                                    {/* <span class="sideBar-icons"> */}
                                        <i id="muteMike" className={"fas " + muteMikeIcon + " sideBar-icons"}></i>
                                    {/* </span> */}
                                    { isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteMike">                                            
                                            <Trans>session.muteMike</Trans>                                            
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>
                            </li>
                            <li className="nav-item flex-fill">
                                <a className={buttonSize+" cursor-pointer"} onClick={(e) => this.onclickStopSession(e)} /* data-tool-tip={i18n._(t`session.stopStream`)} */>
                                    {/* <span class="sideBar-icons"> */}
                                        <i id="stopStream" className="fas fa-stop-circle sideBar-icons"></i>
                                    {/* </span> */}
                                    { isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="stopStream">                                            
                                            <Trans>session.stopStream</Trans>                                            
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                }
            </I18n>    
            )

        }
    }

    render() {
        const { user } = this.props
        if (user.isexpert) {
            return (
                <>
                    {this.expertContent()}
                    {this.guestForm()}
                    {this.state.bResponded ? this.formMsg() : ''}
                </>

            )
        } else {
            return (
                <>
                    {this.technicianContent()}
                </>

            )
        }
    }

}

export default WebrtcSideBar;