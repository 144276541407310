import React from 'react';
import ReactDOM from 'react-dom';
import 'url-search-params-polyfill';
import './index.scss';
import App from './App';
import 'tachyons';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import '@fortawesome/fontawesome-free/css/all.min.css';

import WebFont from 'webfontloader';
// import './custom.scss';

// WebFont.load({
//   google: {
//     families: ['Titillium+Web:300,400,600,700', 'sans-serif']
//   }
// });

ReactDOM.render(<App />, document.getElementById('root'));

const onUpdate = () => {
}

String.prototype.capitalize = function() {
  return this.trim().charAt(0).toUpperCase() + this.trim().slice(1);
}

// registerServiceWorker(onUpdate);
unregister();