import React, { Fragment } from 'react';
import { fabric } from 'fabric';
import { uploadFile, deleteFile } from 'react-s3';
import { printConsole } from '../helpers';
import SessionChat from './SessionChat';
import SessionSignal from './SessionSignal';
import './session.css';
import threeEntryPoint from "../Three/threeEntryPoint";
import * as THREE from 'three';
import FileViewer from 'react-file-viewer';
import MessageModal from '../MessageModal/MessageModal';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import map from 'lodash/map';
import { base64StringToBlob } from 'blob-util';
import SessionTimer from './SessionTimer';
import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import QrReader from 'react-qr-reader';
import AuthService from '../AuthService';
import {diagonastics, diagnostics} from "../DiagonasticHelper";
import { SketchPicker } from 'react-color';
import RecordRTC from 'recordrtc';
import {calculateDownloadSpeed, calculateUploadSpeed} from './Latency';

import OTTechnician from './OTTechnician';
import OTExpert from './OTExpert';


let AWS = require('aws-sdk');

const headerSize = 104;
const footerSize = 0; // if needed we will factor the footer in
const borderYsize = 2; //23;

let strCanvasJSON = null;
let dataList = [];

let propsSetJoineeCount = null;

const OT = {}; //TP-3988
const fileDialog = require('file-dialog');

let g_bCleanSlate = false;
let g_subInfoArr = [];
let g_ss_subInfo = null;
let g_pubConnsArr = [];
// Array which should hold the list of Expert connection data for Technician Users
let g_expertConnsArr = [];
// Variable that holds the total no of experts in a Session
let g_expertsCounter = 0;
let CHAT_DATA = [];
let g_subscriber = null;
let g_publisher = null;
let g_camera_publisher = null;
let g_screen_publisher = null;
let g_stream = null;
let g_session = null;
let g_thisObj = null;
let g_maxDivId = null;
let sessionObj = null;
let from = null;

// for NS2-148
const zoomLevelArray = [
    { id: '1', name: '1X' },
    { id: '2', name: '2X' },
    { id: '3', name: '3X' },
    { id: '4', name: '4X' },
    { id: '5', name: '5X' }
];
let annotateShapesArray = [
    { class: "fas fa-signature", name: "session.freehand" },
    { class: "far fa-circle", name: "session.circle" },
    { class: "far fa-square", name: "session.square" },
    { class: "fas fa-mouse-pointer", name: "session.arrow" },
    { class: "fas fa-signature", name: "session.freehand" }
]

const subscriberContainerDivId = 'flex-container';
const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
};

// NS2-502
function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};


function displayAnnotation(paramElem, imgData) {
    //printConsole('inside displayAnnotation!');
    let drawElem = document.getElementById('canvas');

    if (drawElem !== null)
        drawElem.parentNode.removeChild(drawElem);

    // create a canvas element
    let newCanvas = document.createElement('canvas');
    newCanvas.id = 'canvas';
    let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
    newCanvas.width = paramElemVdoChild[0].offsetWidth;
    newCanvas.height = paramElemVdoChild[0].offsetHeight;

    // insert canvas after video element
    paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);

    // add a fabric canvas element
    let canvas = new fabric.Canvas(newCanvas);

    let dimX = sessionObj.state.expertDimX;
    let dimY = sessionObj.state.expertDimY;
    if (dimX === 0) {
        printConsole('dimensions of expert canvas did not come in');
        dimX = canvas.getWidth();
        dimY = canvas.getHeight();
        //return;
    }

    if (strCanvasJSON !== null)
        canvas.loadFromJSON(strCanvasJSON, function () {
            //fabric.log(o, object);
            let objList = canvas.getObjects();
            let index = 0;
            let multiX = canvas.getWidth() / dimX;
            let multiY = canvas.getHeight() / dimY;
            for (index = 0; index < objList.length; index++) {
                objList[index].scaleX = objList[index].scaleX * multiX;
                objList[index].scaleY = objList[index].scaleY * multiY;
                objList[index].left = objList[index].left * multiX;
                objList[index].top = objList[index].top * multiY;
                objList[index].setCoords();
            }
        });

    if (imgData !== '') {
        const contentType = 'image/png';
        const blob = new base64StringToBlob(imgData, contentType);
        let urlObj = URL.createObjectURL(blob);

        fabric.Image.fromURL(urlObj, function (img) {
            // add background image
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
        });
    }
    else {
        canvas.backgroundImage = false;
        canvas.renderAll();
    }
}

function sendChatOut(msgToSend, elemId) {
    //printConsole('sendChatOut called with: ', msgToSend, elemId, g_subInfoArr, g_session, g_pubConnsArr);
    let sixdigitsrandom = 0;//MB2-95
    // send this message out
    if (g_session !== null && elemId !== null && g_subInfoArr.length > 0) {
        sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);//MB2-95
        g_subInfoArr.forEach(subInfo => {
            if (subInfo.g_divId === elemId) {
                const toArray = subInfo.g_stream.connection.data.split(":::");
                const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                sessionObj.sessionSignal.sendSignalChat(

                    //from: g_session.connection.data,
                    sixdigitsrandom, //MB2-95
                    msgToSend,
                    toObject
                    //type:"IM"

                );
                //printConsole("signal sent.");
                return;
            }
        })
    }
    else if (g_session !== null && elemId !== null && g_pubConnsArr.length > 0) {
        // send to all subscribers ---- 
        sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);//MB2-95
        g_pubConnsArr.forEach(connectionObj => {
            if (g_session.connection !== connectionObj) {
                const toArray = connectionObj.data.split(":::");
                const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                sessionObj.sessionSignal.sendSignalChat(

                    //from: g_session.connection.data,
                    sixdigitsrandom, //MB2-95
                    msgToSend,
                    toObject
                    //type:"IM"

                );
                //printConsole("signal sent.");
                return;
            }
        })
    }
    else if (g_session !== null && elemId === null) {
        sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); // MB2-95
        sessionObj.sessionSignal.sendSignalChat(

            //from: g_session.connection.data,
            sixdigitsrandom,    //MB2-95
            msgToSend
            //type:"IM"

        );
        //printConsole("signal sent.");
    }
    return;
}

function FabricCalcArrowAngle(x1, y1, x2, y2) {
    var angle = 0, x, y;
    x = (x2 - x1);
    y = (y2 - y1);
    if (x === 0) {
        angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
    } else if (y === 0) {
        angle = (x > 0) ? 0 : Math.PI;
    } else {
        angle = (x < 0) ? Math.atan(y / x) + Math.PI :
            (y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
    }
    return (angle * 180 / Math.PI + 90);
}

function onClickDraw(paramElem, bCapture, urlObj, flag = false) {
    if (paramElem === null) {
        printConsole('invalid element passed to onClickDraw');
        //		alert('There is no window handle to draw annotations on. Please contact support.');
        return;
    }

    let drawElem = document.getElementById('canvas');
    //printConsole('drawElem = ', drawElem);

    if (drawElem !== null && drawElem !== undefined && bCapture === false && sessionObj.state.pencolor === "red" && sessionObj.state.isFreeDrawing === true && flag == false) {
        if (drawElem.style.display === 'block') {
            g_bCleanSlate = true;

            let canvas = drawElem.fabric;
            let canvasObjects = canvas.getObjects();
            let idx = 0;
            for (idx = 0; idx < canvasObjects.length; idx++)
                canvas.remove(canvasObjects[idx]);
            //canvasObjects = canvas.getObjects('path');
            canvasObjects = canvas.getObjects();
            sendAnnotationToExpert('');//NS2-345
            sendAnnotation('', null);
            drawElem.fabric.freeDrawingBrush.color = sessionObj.state.pencolor;
            drawElem.fabric.isDrawingMode = false;
            drawElem.fabric.backgroundImage = false;
            drawElem.style.display = 'none'; // hide the window
        }
        else {

            if (bCapture === true) {
                fabric.Image.fromURL(urlObj, function (img) {
                    // add background image
                    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                        scaleX: canvas.width / img.width,
                        scaleY: canvas.height / img.height
                    });
                });
            }
            drawElem.fabric.freeDrawingBrush.color = sessionObj.state.pencolor;
            drawElem.fabric.isDrawingMode = true;
            drawElem.style.display = 'block';
        }
        return;
    }

    // create a canvas element
    let newCanvas = document.createElement('canvas');
    newCanvas.id = 'canvas';
    let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
    newCanvas.width = bCapture ? paramElem.offsetWidth : paramElemVdoChild[0].offsetWidth;
    newCanvas.height = bCapture ? paramElem.offsetHeight : paramElemVdoChild[0].offsetHeight;
    newCanvas.style.display = 'block';

    // insert canvas after video element
    if (paramElemVdoChild.length < 1)
        paramElem.appendChild(newCanvas);
    else
        paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);

    // add a fabric canvas element
    let canvas = new fabric.Canvas(newCanvas);
    canvas.selection = false;
    var rect, ellipse, line, triangle, origX, origY, activeObj;
    var isRectActive = sessionObj.state.isRectActive, isCircleActive = sessionObj.state.isCircleActive, isArrowActive = sessionObj.state.isArrowActive, isFreeDrawing = sessionObj.state.isFreeDrawing;
    let drawingObj = document.getElementById('canvas');
    //printConsole(drawingObj);
    drawingObj.fabric = canvas;

    if (canvas === null) {
        printConsole('Fabric canvas could not be created!!!');
        return;
    }
    canvas.isDrawingMode = 1;
    if (isFreeDrawing) {
        canvas.freeDrawingBrush.color = sessionObj.state.pencolor; //"red" 
        canvas.freeDrawingBrush.width = 3;
    } else {
        canvas.freeDrawingBrush.color = "transparent";
        canvas.freeDrawingBrush.width = 1;
    }

    if (bCapture === true) {
        printConsole('loadImagebackground hit with: ', urlObj);
        fabric.Image.fromURL(urlObj, function (img) {
            // add background image
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
        });
    }
    else
        canvas.renderAll();

    canvas.on('mouse:down', function (option) {
        //printConsole(option);
        // NS2-509
        /* if (option.target !== null) {
            return;
        } else {             
                    
            canvas.on('mouse:move', function (option) {
                                
            });

        } */
    });

    canvas.on('mouse:up', function () {
        canvas.off('mouse:move');
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
            canvas.enableRetinaScaling = true;
            //NS2-493
            if (lengthInUtf8Bytes(JSON.stringify(canvas.toJSON())) > 8192) {
                //printConsole('annotation data size exceeds limit');
                if (g_maxDivId !== null) {
                    let strDim = document.getElementById(g_maxDivId).style.width;
                    strDim += document.getElementById(g_maxDivId).style.height;
                    sendMultipartAnnotationToExpert(strDim);//NS2-493 
                    //sendAnnotationToExpert(strDim);
                    sendAnnotationMultipart(strDim, paramElem);// NS2-455
                    //printConsole(strDim);
                }
                let objSize = lengthInUtf8Bytes(JSON.stringify(canvas.toJSON()));
                //sendAnnotationToExpert(canvas.toJSON()); //NS2-345
                processMultiPartAnnotation(JSON.stringify(canvas.toJSON()), objSize, paramElem);// NS2-455
            } else {
                if (g_maxDivId !== null) {
                    let strDim = document.getElementById(g_maxDivId).style.width;
                    strDim += document.getElementById(g_maxDivId).style.height;
                    sendAnnotationToExpert(strDim);//NS2-345
                    sendAnnotation(strDim, paramElem);
                    //printConsole(strDim);
                }
                sendAnnotationToExpert(canvas.toJSON()); //NS2-345
                sendAnnotation(canvas.toJSON(), paramElem);
            }
        }
    });
}

// Multipart Annotation Implementation
// NS2-455
function onClickChangeDraw(paramElem, bCapture, urlObj, flag = false) {
    if (paramElem === null) {
        printConsole('invalid element passed to onClickChangeDraw');
        return;
    }

    let drawElem = document.getElementById('canvas');
    let canvas = drawElem.fabric;
    canvas.selection = false;
    var rect, ellipse, line, triangle, origX, origY, activeObj;
    var isRectActive = sessionObj.state.isRectActive, isCircleActive = sessionObj.state.isCircleActive, isArrowActive = sessionObj.state.isArrowActive, isFreeDrawing = sessionObj.state.isFreeDrawing;

    if (canvas === null) {
        printConsole('Fabric canvas could not be created!!!');
        return;
    }
    canvas.isDrawingMode = 1;
    if (isFreeDrawing) {
        canvas.freeDrawingBrush.color = sessionObj.state.pencolor;
        canvas.freeDrawingBrush.width = 3;
    } else {
        canvas.freeDrawingBrush.color = "transparent";
        canvas.freeDrawingBrush.width = 1;
    }

    canvas.on('mouse:down', function (option) {
        //printConsole(option);
        // NS2-509
        /* if (option.target !== null) {
            return;
        } else { */
        var pointer = canvas.getPointer(option.e);
        origX = pointer.x;
        origY = pointer.y;
        if (isRectActive) {
            if (ellipse !== null && ellipse !== undefined) {
                ellipse = null;
            }
            if (line !== null && line !== undefined) {
                line = null;
            }
            if (triangle !== null && triangle !== undefined) {
                triangle = null;
            }

            rect = new fabric.Rect({
                left: origX,
                top: origY,
                width: pointer.x - origX,
                height: pointer.y - origY,
                fill: '',
                stroke: sessionObj.state.pencolor,
                type: 'rect',
                strokeWidth: 3,
            });
            canvas.add(rect);
            activeObj = rect;
        }
        else if (isCircleActive) {
            if (line !== null && line !== undefined) {
                line = null;
            }
            if (triangle !== null && triangle !== undefined) {
                triangle = null;
            }
            if (rect !== null && rect !== undefined) {
                rect = null;
            }
            ellipse = new fabric.Ellipse({
                left: origX,
                top: origY,
                originX: 'left',
                originY: 'top',
                rx: pointer.x - origX,
                ry: pointer.y - origY,
                angle: 0,
                fill: '',
                stroke: sessionObj.state.pencolor,
                strokeWidth: 3,
                type: 'ellipse'
            });
            canvas.add(ellipse);
            activeObj = ellipse;
        }
        else if (isArrowActive) {
            if (ellipse !== null && ellipse !== undefined) {
                ellipse = null;
            }
            if (rect !== null && rect !== undefined) {
                rect = null;
            }
            var points = [pointer.x, pointer.y, pointer.x, pointer.y];
            line = new fabric.Line(points, {
                strokeWidth: 2,
                fill: sessionObj.state.pencolor,
                stroke: sessionObj.state.pencolor,
                originX: 'center',
                originY: 'center',
                id: 'arrow_line',
                type: 'line'
            });
            var centerX = (line.x1 + line.x2) / 2;
            var centerY = (line.y1 + line.y2) / 2;
            var deltaX = line.left - centerX;
            var deltaY = line.top - centerY;

            triangle = new fabric.Triangle({
                left: line.get('x1') + deltaX,
                top: line.get('y1') + deltaY,
                originX: 'center',
                originY: 'center',
                selectable: false,
                pointType: 'arrow_start',
                angle: -45,
                width: 15,
                height: 25,
                fill: sessionObj.state.pencolor,
                id: 'arrow_triangle',
                type: 'triangle'
            });
            canvas.add(line, triangle);
            activeObj = line;
        }
        canvas.on('mouse:move', function (option) {

            var pointer = canvas.getPointer(option.e);
            if (isRectActive) {

                if (origX > pointer.x) {
                    rect.set({ left: Math.abs(pointer.x) });
                }
                if (origY > pointer.y) {
                    rect.set({ top: Math.abs(pointer.y) });
                }
                rect.set({ width: Math.abs(origX - pointer.x) });
                rect.set({ height: Math.abs(origY - pointer.y) });
                canvas.renderAll();
            } else if (isCircleActive) {
                if (ellipse === null) {
                    return;
                }
                var rx = Math.abs(origX - pointer.x) / 2;
                var ry = Math.abs(origY - pointer.y) / 2;
                if (rx > ellipse.strokeWidth) {
                    rx -= ellipse.strokeWidth / 2;
                }
                if (ry > ellipse.strokeWidth) {
                    ry -= ellipse.strokeWidth / 2;
                }
                ellipse.set({ rx: rx, ry: ry });

                if (origX > pointer.x) {
                    ellipse.set({ originX: 'right' });
                } else {
                    ellipse.set({ originX: 'left' });
                }
                if (origY > pointer.y) {
                    ellipse.set({ originY: 'bottom' });
                } else {
                    ellipse.set({ originY: 'top' });
                }
                canvas.renderAll();
            } else if (isArrowActive) {
                line.set({
                    x2: pointer.x,
                    y2: pointer.y
                });
                triangle.set({
                    'left': pointer.x + deltaX,
                    'top': pointer.y + deltaY,
                    'angle': FabricCalcArrowAngle(line.x1,
                        line.y1,
                        line.x2,
                        line.y2)
                });
                canvas.renderAll();
            }
        });

        //}
    });

    canvas.on('mouse:up', function () {
        canvas.off('mouse:move');
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
            canvas.enableRetinaScaling = true;
            // NS2-493
            if (lengthInUtf8Bytes(JSON.stringify(canvas.toJSON())) > 8192) {
                //printConsole('annotation data size exceeds limit');
                if (g_maxDivId !== null) {
                    let strDim = document.getElementById(g_maxDivId).style.width;
                    strDim += document.getElementById(g_maxDivId).style.height;
                    sendMultipartAnnotationToExpert(strDim);//NS2-493
                    //sendAnnotationToExpert(strDim);
                    sendAnnotationMultipart(strDim, paramElem);// NS2-455
                    //printConsole(strDim);
                }
                let objSize = lengthInUtf8Bytes(JSON.stringify(canvas.toJSON()));
                //sendAnnotationToExpert(canvas.toJSON()); //NS2-345
                processMultiPartAnnotation(JSON.stringify(canvas.toJSON()), objSize, paramElem);// NS2-455
            } else {
                if (g_maxDivId !== null) {
                    let strDim = document.getElementById(g_maxDivId).style.width;
                    strDim += document.getElementById(g_maxDivId).style.height;
                    sendAnnotationToExpert(strDim);//NS2-345
                    sendAnnotation(strDim, paramElem);
                    //printConsole(strDim);
                }
                sendAnnotationToExpert(canvas.toJSON()); //NS2-345
                sendAnnotation(canvas.toJSON(), paramElem);
            }
        }
    });

}
// for NS2-345
function onDrawPassiveExperts(paramElem, bCapture, imgData, imgType) {
    if (paramElem === null) {
        printConsole('invalid element passed to onClickDraw');
        //		alert('There is no window handle to draw annotations on. Please contact support.');
        return;
    }

    let drawElem = document.getElementById('canvas');

    if (drawElem !== null) {
        //NS2-511
        if (sessionObj.props.isSafari) {
            drawElem.width = 0;
            drawElem.height = 0;
        }
        drawElem.parentNode.removeChild(drawElem);
    }

    // create a canvas element
    let newCanvas = document.createElement('canvas');
    newCanvas.id = 'canvas';
    let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
    newCanvas.width = paramElemVdoChild[0].offsetWidth;
    newCanvas.height = paramElemVdoChild[0].offsetHeight;

    // insert canvas after video element
    paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);
    // add a fabric canvas element
    let canvas = new fabric.Canvas(newCanvas);

    if (canvas === null) {
        printConsole('Fabric canvas could not be created!!!');
        return;
    }
    let dimX = sessionObj.state.expertDimX;
    let dimY = sessionObj.state.expertDimY;
    if (dimX === 0) {
        printConsole('dimensions of expert canvas did not come in');
        dimX = canvas.getWidth();
        dimY = canvas.getHeight();
        //return;
    }

    if (strCanvasJSON !== null)
        canvas.loadFromJSON(strCanvasJSON, function () {
            //fabric.log(o, object);
            let objList = canvas.getObjects();
            let index = 0;
            let multiX = canvas.getWidth() / dimX;
            let multiY = canvas.getHeight() / dimY;
            for (index = 0; index < objList.length; index++) {
                objList[index].scaleX = objList[index].scaleX * multiX;
                objList[index].scaleY = objList[index].scaleY * multiY;
                objList[index].left = objList[index].left * multiX;
                objList[index].top = objList[index].top * multiY;
                objList[index].setCoords();
            }
        });

    if (bCapture === true) {
        printConsole('loadImagebackground hit with: ', imgData);
        const blob = new Blob(imgData, { type: imgType });
        let urlObj = URL.createObjectURL(blob);
        fabric.Image.fromURL(urlObj, function (img) {
            // add background image
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
        });
    }
    else {
        canvas.backgroundImage = false;
        canvas.renderAll();
    }

}

function clearAnnotations() {
    let drawElem = document.getElementById('canvas');
    if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
        g_bCleanSlate = true;

        let canvas = drawElem.fabric;
        //let canvasObjects = canvas.getObjects('path');
        let canvasObjects = canvas.getObjects();
        //printConsole('path objects = ', canvasObjects.length);
        //printConsole(canvasObjects);
        let idx = 0;
        for (idx = 0; idx < canvasObjects.length; idx++)
            canvas.remove(canvasObjects[idx]);
        //canvasObjects = canvas.getObjects('path');
        return true;
    }
    return false;
}

// For implementation of the Multipart Annotation object sending feature
// NS2-455
function removeCanvasEvents() {
    let drawElem = document.getElementById('canvas');
    if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
        let canvas = drawElem.fabric;
        canvas.off('mouse:down');
        canvas.off('mouse:move');
        canvas.off('mouse:up');
    }
}

function insertAt(array, index, str) {
    array.splice(index, 0, str);
    return array;
}

function lengthInUtf8Bytes(str) {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    var m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str.length + (m ? m.length : 0);
} 

function chunkString(str, length) {
    return str.match(new RegExp('.{1,' + length + '}', 'g'));
}

function processMultiPartAnnotation(annotateString, objSize, elemId) {

    let dataList = chunkString(annotateString, 7000);
    /* printConsole(dataList);
    printConsole(dataList.length);
    printConsole(objSize); */

    let param = "";
    for (let i = 0; i < dataList.length; i++) {
        param = (i + 1) + ":::" + (dataList.length) + ":::" + dataList[i];
        //printConsole(param)
        sendAnnotationMultipart(param, elemId);
        sendMultipartAnnotationToExpert(param); //NS2-493
    }
}

function sendAnnotationMultipart(annotationObj, elemId) {
    if (g_session !== null && g_subInfoArr.length > 0) {
        g_subInfoArr.forEach(subInfo => {
            if (elemId === null || subInfo.g_divId === elemId.id) {
                const toArray = subInfo.g_stream.connection.data.split(":::");
                const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                sessionObj.sessionSignal.sendSignalAnnotateMultipart(

                    //from: g_session.connection.data,
                    annotationObj,
                    toObject
                    //type: "ANNOTATE_MULTI_PART"

                );
                //printConsole("annotation multipart signal sent...");
                return;
            }
        })
    }
}

// NS2-493
function sendMultipartAnnotationToExpert(annotationObj) {
    //printConsole(annotationObj);
    // send this message out
    if (sessionObj.state.showHideMaxDivControl === true && g_session !== null && g_expertConnsArr.length > 0) {
        g_expertConnsArr.forEach(expertInfo => {
            const toArray = expertInfo.data.split(":::");
            const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
            sessionObj.sessionSignal.sendSignalAnnotateMultipart(

                //from: g_session.connection.data,
                annotationObj,
                toObject
                //type: "ANNOTATE_MULTI_PART"

            );
            //printConsole("expert annotate multipart signal sent...");
            return;
        })
    }
}
/*  End of the Multipart Annotation Implementation **/

// "#&artify&#5537331063634575687238455everyone5537331063634575687238455CarCheck1.jpg"
function sendAnnotation(annotationObj, elemId) {
    //printConsole(JSON.stringify(annotationObj));       
    //printConsole(lengthInUtf8Bytes(JSON.stringify(annotationObj)));
    // send this message out 
    if (lengthInUtf8Bytes(JSON.stringify(annotationObj)) > 8192) {
        printConsole('annotation data size exceeds limit');
        /* let objSize = lengthInUtf8Bytes(JSON.stringify(annotationObj));
        processMultiPartAnnotation(JSON.stringify(annotationObj), objSize, elemId, annotateDim); */
        return;
    }
    if (g_session !== null && g_subInfoArr.length > 0) {
        g_subInfoArr.forEach(subInfo => {
            if (elemId === null || subInfo.g_divId === elemId.id) {
                //printConsole('divId matched up');
                const toArray = subInfo.g_stream.connection.data.split(":::");
                const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                sessionObj.sessionSignal.sendSignalAnnotate(

                    //from: g_session.connection.data,
                    annotationObj,
                    toObject
                    //type:"ANNOTATE"

                    //printConsole("annotation signal sent...");                            
                );
                return;
            }
        })
    }
}

// for NS2-345
function sendAnnotationToExpert(annotationObj) {
    //printConsole(annotationObj);
    // send this message out
    if (lengthInUtf8Bytes(JSON.stringify(annotationObj)) > 8192) {
        printConsole('annotation data size exceeds limit');
        return;
    }
    if (sessionObj.state.showHideMaxDivControl === true && g_session !== null && g_expertConnsArr.length > 0) {
        g_expertConnsArr.forEach(expertInfo => {
            const toArray = expertInfo.data.split(":::");
            const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
            sessionObj.sessionSignal.sendSignalAnnotate(

                //from: g_session.connection.data,
                annotationObj,
                toObject
                //type: "ANNOTATE"

            );
            //printConsole("expert annotate signal sent...");
            return;
        })
    }
}

function isDivExpert(divIsExpert) {
    let elemIsExpert = document.getElementById(divIsExpert);
    if (elemIsExpert !== null && elemIsExpert !== undefined) {
        let elemVdoPoster = elemIsExpert.getElementsByClassName('OT_video-poster');
        if (elemVdoPoster && elemVdoPoster.length > 0) {
            if (elemVdoPoster[0].style.display === 'block')
                return true;
        }
    }
    return false;
}


function drawSmallSubscriberUI(hVal, wVal, maxElem, flag) {
    let Height = hVal; //window.innerHeight;
    let Width = wVal; //window.innerWidth;Height -= 40;
    Height -= 40;

    // Set the size of the container
    let subscriberBox = document.getElementById(subscriberContainerDivId); //'flex-container'
    if (subscriberBox === null) {
        printConsole('no div layout for videos yet!');
        return;
    }
    subscriberBox.style.position = "relative";
    subscriberBox.style.width = Width + "px";
    subscriberBox.style.height = Height + "px";
    subscriberBox.style.backgroundColor = '#1c2238';

    // 	printConsole(`inside drawSmallSubscriberUI, Box.width = ${subscriberBox.style.width}, 
    // 		Box.height = ${subscriberBox.style.height})`)

    // NS2-441
    // The small divs on side panel
    let divContainerSmall = document.getElementById('small-divs-container');
    if (divContainerSmall === null) {
        // create a container div to hold the smaller videos
        divContainerSmall = document.createElement('div');
        divContainerSmall.setAttribute('className', 'small-container');
        divContainerSmall.setAttribute('id', 'small-divs-container');
    }
    divContainerSmall.style.display = 'none';
    subscriberBox.appendChild(divContainerSmall);
    let actionReqControlDiv = document.getElementById('actionRequestControls');
    if (actionReqControlDiv !== null) {
        actionReqControlDiv.style.left = '45%';
        // NS2-476
        if (sessionObj.props.isSafari)
            actionReqControlDiv.style.bottom = '80px';
    }



    // for iPad the factor should be more
    // NS2-476 & NS2-512
    let factor = 10;
    if (sessionObj.props.isSafari) {
        factor = 80;
    }
    // the big div
    let maxHeight = Height - factor; // NS2-476 & NS2-512
    let maxWidth = Width - factor;  // NS2-476 & NS2-512
    let parent = null;
    let ii = 0;

    /// Big div and small divs to be added to the respective video elements
    // NS2-441
    let count = g_subInfoArr.length;
    for (ii = 0; ii < count; ii++) {
        parent = document.getElementById(g_subInfoArr[ii].g_divId);
        if (parent === null) return;

        // if max divid draw the max window
        if (g_subInfoArr.length === 1 || g_subInfoArr[ii].g_divId === g_maxDivId) {
            let xx = (maxWidth - (maxHeight * 16 / 9)) / 2;
            let yy = 2;
            parent.style.position = 'absolute';
            parent.style.left = xx + 'px';
            parent.style.top = yy + 'px';
            parent.style.width = (maxHeight * 16 / 9) + 'px';
            parent.style.height = (maxHeight) + 'px';
            if (g_maxDivId !== null) {
                let maxDivParent = document.getElementById(g_maxDivId);
                if (maxDivParent !== null) {
                    //maxDivParent.appendChild(document.getElementById('maxdivcontrols')); 
                    maxDivParent.appendChild(document.getElementById('controlpanel'));
                }
            }
        }
        else // now draw the other sub videos if they exist
        {
            // All streams placed in absolute position relative to the layout container
            parent.style.position = 'absolute';
        }
    }
}



//NS2-482	
function sortConnArr(connArr) {
    let len = connArr.length;
    if (connArr.length > 1) {
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < len; j++) {
                if (connArr[j + 1] !== undefined) {
                    if (connArr[j].data.split(':::')[6] > connArr[j + 1].data.split(':::')[6]) {
                        let tmp = connArr[j];
                        connArr[j] = connArr[j + 1];
                        connArr[j + 1] = tmp;
                    }
                }
            }
        }
    }
    return connArr;
}

class Session extends React.Component {
    constructor(props) {
        super(props);
        this.fileViewer = null;
        this.active3dModel = null;
        this.state = {
            bShareAll: false,
            bMuteAllAudio: false,
            bMuteMyMike: false,
            muteMikeForMaxDiv: false,
            bSessionStarted: false,
            chatLeft: '0px',
            chatTop: '0px',
            chatWidth: '0px',
            chatHeight: '0px',
            expertDimX: 0,
            expertDimY: 0,
            rcImgObj: '',
            rcImgType: '',
            chatMsgInfoArr: [{
                msgColor: '',
                firstName: '',
                lastName: '',
                message: '',
                timeStamp: '',
                msgType: 0 // 0 - indiv, 1 - group
            }],
            bAllChat: false,
            nAllChatNewMsgCounter: 0,
            bMaxChat: false,
            bRemoteMute: false,
            bHideExpertSSButton: true,
            bHideTechnicianSSButton: true,
            bHideZoomButton: true,
            bHideTorchButton: true,
            bHideAnnotateColour: true,
            bHideAnnotateShapes: true,
            bAnnotate: false,
            bShareAsset: false,
            bRemoteCapture: false,
            route: '',
            session: null,
            status: '',
            error: null,
            closeString: 'You are in a video session',
            initOT: false,
            bRefresh: true,
            group_name: '',
            signInEmail: '',
            inFocusID: '',
            sessionInfo: {
                audio_mode: 'expert2Tech',
                sessionID: '',
                token: '',
                APIKey: '',
                role: '',
                firstname: '',
                lastname: '',
                credit_limits: {},
                screen_share_mode: ''
            },
            isLoading: false,
            showFileViewer: false,
            fileType: 'none',
            filePath: '',
            fileName: '',
            URLs: [],
            chatUserEmails: [],
            chatUserNames: [],
            startTimer: false,
            fileNameArr: [],
            shareOnDiv: '',
            sessionJoinees: [],
            isOpenQRScanner: false,
            openMikeDialog: false,
            maxDivTechName: '',
            openStreamDestroyedDialog: false,
            imageCaptureTimer: 10,
            sRCTechDetails: '',
            bRCAnnotateOn: false,
            maxView: false,
            maxViewExpert: "",
            showRc: false,
            techShareScreen: false,
            expertShareScreenMaxDiv: false,
            expertSSAll: false,
            showControlRequestDialog: false,
            expertFirstName: '',
            expertLastName: '',
            clickHandSignal: false,
            showHideMaxDivControl: false,
            showHideDivIconAudioOnly: false,
            msgID: "",
            showAlertDialog: false,
            bZoomClick: false,
            bTorchClick: false,
            zoom_level: "1",
            pencolor: "red",
            bSelectColor: false,
            urlObj: null,
            bSelectShape: false,
            isRectActive: false,
            isCircleActive: false,
            isArrowActive: false,
            isFreeDrawing: true,
            startRecording: false, //NS2-274
            showRecordingBeacon: false, //state to control recording beacon NS2-360
            screenCaptureClicked: false, //NS2-367
            requestDialogTimer: 10,
            loggedinUserTimeStamp: "", //NS2-482
            currentPrimaryExpert: 0, // NS2-486
            muteExpertMikeArr: [], //N2-436
            disconnectExpertEmail: "", //MB2-522
            triggerSwapCamera: false,
            downLoadSpeed: null, 
            uploadSpeed: null,
            cameraCount: 1,
            cameraSwapEventType: "",
            btnstate: true,
            newConnObj: null,
            sendSyncExperts: false
        }
        g_subInfoArr = [];
        g_pubConnsArr = [];
        g_expertConnsArr = [];
        g_expertsCounter = 0;
        CHAT_DATA = [];
        this.config = {
            bucketName: process.env.REACT_APP_S3_BUCKET,
            dirName: process.env.REACT_APP_S3_DIR, /* optional */
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        };
        //printConsole('constructor in session called');
        sessionObj = this;
        this.closeSubscriber3dObject = null;
        this.authService = new AuthService();
        this.decodedUser = this.authService.getDecodedUser();
        //this.sessionSignal = new SessionSignal("");
    }

    updatePublishers = (pub, session)=> {
        g_publisher = pub;
        g_camera_publisher = pub;
        g_session = session;
    }

    updatePublishVideo = (flag) => {
        if (g_publisher !== null)
            g_publisher.publishVideo(flag);
        else if (g_camera_publisher !== null)
            g_camera_publisher.publishVideo(flag);
        
    }

    updateCameraCount = (val) => {
        this.setState({ cameraCount: val });
    }

    updateCameraSwapEventType = (val) => {
        this.setState({ cameraSwapEventType: val})
    }

    updateTriggerSwapCamera = (val) => {
        this.setState({ triggerSwapCamera: val });
    }

    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // Setting the Camera/Microphone deviceId in Cookie
    setDeviceCookie = (name, val) => {
        // "username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        let cookieText = name+"="+val+"; path=/";
        //console.log(cookieText);
        document.cookie = cookieText;
    }

    deleteCookie = (cname) => {
        var name = cname + "=";
        document.cookie = name+"; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    setScreenShareMode = () => {
        const { screen_share_mode } = this.state.sessionInfo;
        if (screen_share_mode === 'screen_share_enabled') {
            this.setState({ bHideExpertSSButton: false });
            this.setState({ bHideTechnicianSSButton: false });
        } else if (screen_share_mode === 'expert_screen_share_enabled') {
            this.setState({ bHideExpertSSButton: false });
        } else if (screen_share_mode === 'tech_screen_share_enabled') {
            this.setState({ bHideTechnicianSSButton: false });
        }
    }

    // NS2-405
    setAnnotationButtons = () => {
        const { annotation_color_enabled, annotation_shape_enabled } = this.state.sessionInfo;
        if (annotation_color_enabled) this.setState({ bHideAnnotateColour: false });
        if (annotation_shape_enabled) this.setState({ bHideAnnotateShapes: false });
    }

    // NS2-217
    handleChangeComplete = (color) => {
        this.setState({ pencolor: color.hex }, () => {
            //printConsole(this.state.bRemoteCapture, this.state.urlObj);
            removeCanvasEvents();
            onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
            /* const sketchPicker = document.getElementById('sketchPicker');
            this.setState({ bSelectColor: false });
            sketchPicker.style.display = 'none'; */
        });
    };

    remove3dObject = () => {
        this.setState({
            showFileViewer: false,
            fileType: 'none',
            filePath: '',
            fileName: ''
        });

        this.state.URLs.forEach(url => {
            if (url) window.URL.revokeObjectURL(url);
        });

        const [closeIcon, canvas] = document.getElementsByClassName('asset-3d');
        window.removeEventListener('mousemove', function () { });
        window.removeEventListener('mouseup', function () { });
        window.removeEventListener('mousedown', function () { });
        /** remove both element from dom */
        if (!canvas) return;
        closeIcon && closeIcon.parentNode && closeIcon.parentNode.removeChild(closeIcon);
        canvas.parentNode.removeChild(canvas);
    };

    initializeOpentok() {
        this.decodedUser && this.decodedUser.showuploadspeed && this.initcheckNetworkSpeed();//Mb2-588
        propsSetJoineeCount = this.props.onSetJoinees;
        const { user, selectedGroupId, selectedGroupName, fromSocketUser, sessionInfo } = this.props;
        if (this.state.bRefresh === true) {
            diagonastics(this.authService, {
                action: `initializing open tok`,
                next_step: 'initializeOTSession',
                data: '',
                error: 'none'
            })
            this.setState({ bRefresh: false });
            this.setState({ bShareAll: false });
            this.setState({ bMuteAllAudio: false });
            this.setState({ bMuteMyMike: false });
            this.setState({ bAllChat: false });
            this.setState({ nAllChatNewMsgCounter: 0 });
            this.setState({ bMaxChat: false });
            this.setState({ bRemoteMute: false });
            this.setState({ bAnnotate: false });
            this.setState({ bShareAsset: false });
            this.setState({ bRemoteCapture: false });
            this.setState({ sRCTechDetails: '' });
            if (this.chatMsgInfoArr !== undefined && this.chatMsgInfoArr !== null && this.chatMsgInfoArr.length > 0)
                this.chatMsgInfoArr.splice(0, this.chatMsgInfoArr.length);
            g_subInfoArr.splice(0, g_subInfoArr.length);
            if (sessionInfo !== null) {
                printConsole(`Here --- SessiondataH364 returned ${JSON.stringify(sessionInfo)}`);
                if (sessionInfo.sessionID !== null) {
                    if (user.log_level >= 3) {
                        diagnostics(this.authService,{
                            action: `sessionData`,
                            sequence: 3,
                            next_step: '',
                            data: sessionInfo,
                            error: 'none'
                        })
                    }
                    // UQ3-186 only for expert respond to call will be send after hitting sessiondatah364
                    if (user.isexpert && fromSocketUser !== null) {
                        printConsole(`respond-to-the-call socket signal sent with payload {action: 'accepted', recipient: ${fromSocketUser}, groupId: ${selectedGroupId}, groupName: ${selectedGroupName}}`)
                        this.authService.socket.emit('respond-to-the-call', { action: 'accepted', recipient: fromSocketUser, groupId: selectedGroupId, groupName: selectedGroupName });
                    }
                    this.authService.socket.emit('user-is-in-session', { groupId: selectedGroupId, isInSession: true });                        
                    this.setState({ sessionInfo });
                    this.setState({ bRefresh: false });
                    this.setState({ initOT: true }, () => {
                        if (this.state.initOT === true && this.state.sessionInfo !== undefined) {
                            this.setState({ initOT: false });
                            this.setState({ bSessionStarted: true });
                            if (!user.isexpert){
                                let session = this.initializeOTSession(this.state.sessionInfo.role);
                                this.setState({ session: session });
                                g_session = session;
                            } else {
                                ///sessionObj.startSessionTimer();
                            }
                            g_thisObj = this;
                        }
                        sessionObj.startSessionTimer();
                    });
                    // NS2-405
                    //sessionObj.setAnnotationButtons();
                    
                }
            } else {
                printConsole(`sessiondataH364 API is called which returned ${JSON.stringify(sessionInfo)}`);
                let fetchString = 'sessiondataH364?groupID=';
                fetchString += this.props.selectedGroupId;
                fetchString += '&loggedInUserId=';
                fetchString += this.props.loggedInUserId;
                this.authService.fetch(fetchString, {
                    method: 'get'
                })
                    .then(response => {
                        if (response.status >= 200 && response.status < 300) {
                            return response.json();
                        } else {
                            throw response;
                        }
                    })
                    .then(data => {
                        printConsole(`SessiondataH364 returned ${JSON.stringify(data)}`);
                        if (data !== undefined && data.sessionID !== null) {
                            if (user.log_level >= 3) {
                                diagnostics(this.authService,{
                                    action: `sessionData`,
                                    sequence: 3,
                                    next_step: '',
                                    data: data,
                                    error: 'none'
                                })
                            }
                            // UQ3-186 only for expert respond to call will be send after hitting sessiondatah364
                            if (user.isexpert && fromSocketUser !== null) {
                                printConsole(`respond-to-the-call socket signal sent with payload {action: 'accepted', recipient: ${fromSocketUser}, groupId: ${selectedGroupId}, groupName: ${selectedGroupName}}`)
                                this.authService.socket.emit('respond-to-the-call', { action: 'accepted', recipient: fromSocketUser, groupId: selectedGroupId, groupName: selectedGroupName });
                            }
                            this.authService.socket.emit('user-is-in-session', { groupId: selectedGroupId, isInSession: true });                        
                            this.setState({ sessionInfo: data });
                            this.setState({ bRefresh: false });
                            this.setState({ initOT: true });
                            // NS2-405
                            //sessionObj.setAnnotationButtons();
                            if (this.state.initOT === true && this.state.sessionInfo !== undefined) {
                                this.setState({ initOT: false });
                                this.setState({ bSessionStarted: true });
                                if (!user.isexpert){
                                    let session = this.initializeOTSession(this.state.sessionInfo.role);
                                    this.setState({ session: session });
                                    g_session = session;
                                } else {
                                    ///sessionObj.startSessionTimer();
                                }
                                g_thisObj = this;
                            }
                            sessionObj.startSessionTimer();
                        }
                    })
                    .catch(err => {
                        diagonastics(this.authService, {
                            action: `initializing open tok failed`,
                            next_step: '',
                            data: '',
                            error: err
                        })
                        if (err.status === 409) {
                            this.props.onBackToDashboard();
                        }
                    })
            }
        }
    }   

    handleError(error) {
        if (error) {
            //printConsole(error.code);
            alert(error.message);
            printConsole(error.message);
        }
    }

    onStreamCreated(event) {
        printConsole('streamCreated callback triggered. Event: ', event.stream)
    }

    onStreamDestroyed(event) {
        printConsole('onStreamDestroyed called');
    }

    sessionConnected(events) {
        printConsole('event streams from sessionConnected: ', events);
    }

    sessionDisconnectedCB(event) {
        printConsole('session disconnected callback called: ', event);
    }

    accessDeniedHandler = (event) => {
        //user denied access to camera and mike
        this.setState({ status: 'User denied access to camera and microphone. Outgoing streaming will fail.' });
    }

    // MB2-529
    // Network & Latency related methods
    initcheckNetworkSpeed = () => {
        this.downloadTimer = setInterval(this.checkDownloadSpeed.bind(this), 60000);
        this.uploadTimer = setInterval(this.checkUploadSpeed.bind(this), 60000);
    }

    stopcheckNetworkSpeed = () => {
        clearInterval(this.downloadTimer);
        clearInterval(this.uploadTimer);
    }

    checkDownloadSpeed = async () => {
        try{
            const speedInMBPS = await calculateDownloadSpeed();
            this.setState({downLoadSpeed: speedInMBPS})
        }catch(error){
            printConsole(error)
        }
    }

    checkUploadSpeed = async () => {
        try {
            const speedInMBPS = await calculateUploadSpeed();
            this.setState({uploadSpeed: speedInMBPS})
        }catch(e){
            printConsole(e);
        }
    }

    selectNewDiv(divId) {
        // 1. Ignore click handling if click is on max div or on another expert div
        if (divId === g_maxDivId || isDivExpert(divId) === true) return;
        // for NS2-117
        if (this.state.techShareScreen) { // NS2-303
            printConsole("Screen share is ongoing. Please close before selecting a new technician");
            return;
        }
        if (this.state.showHideMaxDivControl === false) return; // NS2-372
        // for NS2-303
        if (this.state.expertShareScreenMaxDiv && !this.state.expertSSAll) {
            this.expertScreenShareClick();
        }


        // 2. reset all active max action buttons
        if (this.state.bMaxChat) this.setState({ bMaxChat: false });
        if (this.state.bAnnotate && !this.state.bRemoteCapture) {// NS2-525	& NS2-202
            this.setState({ bAnnotate: false });
            const controlpanel = document.getElementById('controlpanel');
            /* const penPicker = document.getElementById('penpicker');
            const shapespicker = document.getElementById('shapespicker'); */
            const sketchPicker = document.getElementById('sketchPicker');
            const shapeselector = document.getElementById('shapeselector');
            /*  penPicker.style.display = 'none';
             shapespicker.style.display = 'none'; */
            sketchPicker.style.display = 'none';
            shapeselector.style.display = 'none';
            //controlpanel.style.left = '57%';
            this.setState({
                bSelectColor: false,
                bSelectShape: false,
                pencolor: "red",
                isFreeDrawing: true,
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false
            });
        }
        if (this.state.bShareAsset) this.setState({ bShareAsset: false });
        if (this.state.bRemoteCapture && this.state.sRCTechDetails === '') {
            // this.setState({bRemoteCapture: false});// onClickCamera will take care
            //this.onClickCamera(); // NS2-525	
            return;
        }
        if (this.state.bZoomClick) this.onClickZoomIcon();

        let maxDivSub = g_subInfoArr.find(function (element) {
            return element.g_divId === divId;
        });
        let maxDivSubVolume = maxDivSub.g_subscriber.getAudioVolume();
        //((this.state.bMuteAllAudio && maxDivSubVolume === 0) || (this.state.bMuteAllAudio === false && maxDivSubVolume === 0)){
        if (this.state.sRCTechDetails === '' && maxDivSubVolume === 0) { //NS2-524
            // Set true if all is muted / sub has been muted earlier
            this.setState({ bRemoteMute: true });
        } else if (this.state.sRCTechDetails === '') { //NS2-524
            this.setState({ bRemoteMute: false });
        }

        // 3. If annotations is on - close and remove from old max
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined) {
            sendAnnotationToExpert('');//NS2-345
            sendAnnotation('', null);
            clearAnnotations();
            if (drawElem.fabric) drawElem.fabric.isDrawingMode = false;
            drawElem.parentNode.removeChild(drawElem);
        }

        // 4. Make the switch ^-^
        if (g_maxDivId !== null && document.getElementById('small-divs-container') !== null) {
            // first mute/unmute the outgoing/old maxDiv tech mike as per the bMuteMyMike 
            this.muteExpertMikeInOutMaxDiv(!this.state.bMuteMyMike);
            // first remove the rc button from outgoing/previous div
            this.showHideRcForTech(false);
            document.getElementById('small-divs-container').appendChild(document.getElementById(g_maxDivId));
            document.getElementById(subscriberContainerDivId).appendChild(document.getElementById(divId));
            if (sessionObj.state.showHideDivIconAudioOnly === true)
                sessionObj.setState({ showHideDivIconAudioOnly: false })
            g_maxDivId = divId;
            // for NS2-342
            if (this.state.showHideMaxDivControl) {
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.g_divId === g_maxDivId) {
                        this.sendSignalExpertSwitchMaxdiv(subInfo.g_stream.connection.data, null);
                        //break;
                    }
                })
            }

            // mute/unmute the incoming/new maxDiv tech mike as per the muteMikeForMaxDiv
            this.muteExpertMikeInOutMaxDiv(!this.state.muteMikeForMaxDiv);
            this.props.showScreenShareHeaderButton(true); // for NS2-282
            this.setState({
                bHideExpertSSButton: true,
                bHideTechnicianSSButton: true,
                bHideZoomButton: true,
                bHideTorchButton: true
                /* bHideAnnotateColour: true,
                bHideAnnotateShapes: true */
            })
            // show the rc button for incoming/new maxdiv 
            this.showHideRcForTech(true);
            if (this.state.muteMikeForMaxDiv !== this.state.bMuteMyMike) {
                this.setState({ openMikeDialog: true });
            }
            // remove timer element from the max vdo div so that it can be added again while 
            // switching between screens
            let timerElement = document.getElementById("timer-span");
            if (timerElement) {
                timerElement.remove();
            }

            this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
        }
    }

    // NS2-372
    selectNewDivPassiveExperts(divId) {
        // 1. Ignore click handling if click is on max div or on another expert div
        if (divId === g_maxDivId || isDivExpert(divId) === true) return;
        // for NS2-117
        if (this.state.techShareScreen) { // NS2-303
            printConsole("Screen share is ongoing. Please close before selecting a new technician");
            return;
        }
        if (this.state.showHideMaxDivControl === true) return;
        // for NS2-303
        if (this.state.expertShareScreenMaxDiv && !this.state.expertSSAll) {
            this.expertScreenShareClick();
        }


        // 2. reset all active max action buttons
        if (this.state.bMaxChat) this.setState({ bMaxChat: false });
        if (this.state.bAnnotate) {//NS2-202
            this.setState({ bAnnotate: false });
            const controlpanel = document.getElementById('controlpanel');
            /* const penPicker = document.getElementById('penpicker');
            const shapespicker = document.getElementById('shapespicker'); */
            const sketchPicker = document.getElementById('sketchPicker');
            const shapeselector = document.getElementById('shapeselector');
            /*  penPicker.style.display = 'none';
             shapespicker.style.display = 'none'; */
            sketchPicker.style.display = 'none';
            shapeselector.style.display = 'none';
            //controlpanel.style.left = '57%';
            this.setState({
                bSelectColor: false,
                bSelectShape: false,
                pencolor: "red",
                isFreeDrawing: true,
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false
            });
        }
        if (this.state.bShareAsset) this.setState({ bShareAsset: false });
        if (this.state.bRemoteCapture && this.state.sRCTechDetails === '') {
            // this.setState({bRemoteCapture: false});// onClickCamera will take care
            this.onClickCamera();
        }
        if (this.state.bZoomClick) this.onClickZoomIcon();

        let maxDivSub = g_subInfoArr.find(function (element) {
            return element.g_divId === divId;
        });
        let maxDivSubVolume = maxDivSub.g_subscriber.getAudioVolume();
        //((this.state.bMuteAllAudio && maxDivSubVolume === 0) || (this.state.bMuteAllAudio === false && maxDivSubVolume === 0)){
        if (maxDivSubVolume === 0) {
            // Set true if all is muted / sub has been muted earlier
            this.setState({ bRemoteMute: true });
        } else {
            this.setState({ bRemoteMute: false });
        }

        // 3. If annotations is on - close and remove from old max
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined) {
            sendAnnotationToExpert('');//NS2-345
            sendAnnotation('', null);
            clearAnnotations();
            if (drawElem.fabric) drawElem.fabric.isDrawingMode = false;
            drawElem.parentNode.removeChild(drawElem);
        }

        // 4. Make the switch ^-^
        if (g_maxDivId !== null && document.getElementById('small-divs-container') !== null) {
            // first mute/unmute the outgoing/old maxDiv tech mike as per the bMuteMyMike 
            this.muteExpertMikeInOutMaxDiv(!this.state.bMuteMyMike);
            // first remove the rc button from outgoing/previous div
            //this.showHideRcForTech(false);
            document.getElementById('small-divs-container').appendChild(document.getElementById(g_maxDivId));
            document.getElementById(subscriberContainerDivId).appendChild(document.getElementById(divId));
            if (sessionObj.state.showHideDivIconAudioOnly === true)
                sessionObj.setState({ showHideDivIconAudioOnly: false })
            g_maxDivId = divId;

            // mute/unmute the incoming/new maxDiv tech mike as per the muteMikeForMaxDiv
            this.muteExpertMikeInOutMaxDiv(!this.state.muteMikeForMaxDiv);
            this.props.showScreenShareHeaderButton(true); // for NS2-282
            this.setState({
                bHideExpertSSButton: true,
                bHideTechnicianSSButton: true,
                bHideZoomButton: true,
                bHideTorchButton: true
                /* bHideAnnotateColour: true,
                bHideAnnotateShapes: true */
            })
            // show the rc button for incoming/new maxdiv 
            //this.showHideRcForTech(true);
            if (this.state.muteMikeForMaxDiv !== this.state.bMuteMyMike) {
                this.setState({ openMikeDialog: true });
            }
            // remove timer element from the max vdo div so that it can be added again while 
            // switching between screens
            let timerElement = document.getElementById("timer-span");
            if (timerElement) {
                timerElement.remove();
            }

            this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
        }
    }

    // NS2-490
    //API to reset the primary_expert_id
    setPrimaryExpertIDApi = (userID, flag=false) => {
        //printConsole(userID);
        let fetchString = 'setPrimaryExpertID';
        let primary_expert_id = this.props.loggedInUserId;
        if (userID !== null && userID !== undefined) 
            primary_expert_id = userID;

        this.authService.fetch(fetchString, {
            method: 'put',
            body: JSON.stringify({
                groupID: this.props.selectedGroupId,
                primary_expert_id
            })
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    //return response.json();
                    if(flag === true) {
                        this.setState({ sendSyncExperts: true }, () => {
                            this.setState({ sendSyncExperts: false });
                        })
                    }
                } else {
                    throw response;
                }
            })
            .catch(err => {
                throw err;
            })
    }


    //NS2-486
    // Signal to reset the Primary Expert ID for 
    // the Secondary Experts currently in the session
    sendSignalCurrentPrimaryExpert = (userID) => {
        try {
            //const signal = "CURRENT_PRIMARY_EXPERT";
            g_expertConnsArr.forEach(expertInfo => {
                const toArray = expertInfo.data.split(":::");
                const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                this.sessionSignal.sendSignalCurrentPrimaryExpert(

                    //from: g_session.connection.data,
                    userID,
                    toObject
                    //type: signal

                );
                //printConsole(`Current Primary Expert userID ${userID} sent to ${expertInfo}`);
            });
        } catch (exception) {
            printConsole('exception occured in sendSignalExpertSwitchMaxdiv');
            printConsole(exception);
        }
    }

    // for NS2-342
    sendSignalExpertSwitchMaxdiv = (data, connData) => {
        try {
            //const signal = "EXPERT_CHANGE_MAX_DIV";
            let dataArr = data.split(":::");
            if (connData === null) {
                g_expertConnsArr.forEach(expertInfo => {
                    const toArray = expertInfo.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    this.sessionSignal.sendSignalExpertSwitchMaxdiv(

                        //from: g_session.connection.data,
                        dataArr[3],
                        toObject
                        //type: signal

                    );
                    //printConsole(`Expert to switch maxdiv Technician sent to ${expertInfo.data}`);                        
                });
            } else {
                this.sessionSignal.sendSignalExpertSwitchMaxdiv(

                    //from: g_session.connection.data,
                    dataArr[3],
                    connData
                    //type: signal

                );
                //printConsole(`Expert to switch maxdiv Technician sent to ${connData}`);                        
            }
        } catch (exception) {
            printConsole('exception occured in sendSignalExpertSwitchMaxdiv');
            printConsole(exception);
        }
    }

    // for NS2-342
    sendSignalQueryExpertSwitchMaxdiv = () => {
        try {
            //const signal = "EXPERT_QUERY_MAXDIV";
            g_expertConnsArr.forEach(expertInfo => {
                const toArray = expertInfo.data.split(":::");
                const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                this.sessionSignal.sendSignalQueryExpertMaxdivSwitch(

                    //from: g_session.connection.data,
                    toObject
                    //data: dataArr[3],
                    //type: signal                    
                );
                //printConsole(`Passive Expert asks main expert to switch maxdiv Technician sent to ${expertInfo.data}`);                        
            })
        } catch (exception) {
            printConsole('exception occured in sendSignalQueryExpertSwitchMaxdiv');
            printConsole(exception);
        }
    }

    isScreenShareSupported = () => {
        // check whether this browser will support screen sharing otherwise hide the button
        OT.checkScreenSharingCapability(function (response) {
            if (!response.supported || response.extensionRegistered === false) {
                // This browser does not support screen sharing
                // 1. Fading popup informing user that the browser does not support screen share
                // 2. hide the corresponding screenshare button
                sessionObj.setState({ bHideExpertSSButton: true });
            }
        });
    }

    // called from initializeOTSession::streamCreated event of the session object
    // called in the expert flavor of webapp only
    switchExpertLive2Screen(connectionId, subInfo) {
        let ii = 0;
        // find the corresponding live streaming div if it exists when created stream is with the screen share
        if (g_session !== null && g_subInfoArr.length > 0) {
            for (ii = 0; ii < g_subInfoArr.length; ii++) {
                if (g_subInfoArr[ii].g_stream.connection.connectionId === connectionId) {
                    g_ss_subInfo = g_subInfoArr[ii];
                    g_subInfoArr.splice(ii, 1, subInfo);
                    return;
                }
            }
        }
    }

    // called from expert session::streamDestroyed event - add back the live streaming subInfo to the array
    // called in the expert flavor of webapp only
    switchExpertScreen2Live() {
        let subscriberInfo = {
            g_subscriber: g_ss_subInfo.g_subscriber,
            g_stream: g_ss_subInfo.g_stream,
            g_divId: g_ss_subInfo.g_divId,
            canRC: true
        };
        document.getElementById(subscriberInfo.g_divId).style.display = 'block';
        // for NS2-94
        g_subInfoArr.splice(0, 0, subscriberInfo);
        //g_subInfoArr.push(subscriberInfo);
        // add the joinees name
        // This line is commented out for NS2-136
        //sessionObj.addSessionJoinees(g_stream);
    }

    getDeviceType = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        
        return isAndroid;
    }

    initializeOTSession(role) {
        //printConsole('initializeOTSession called with sessionInfo:', this.state.sessionInfo);
        var bSubscribe = true;
        if (role === 'publisher') bSubscribe = false;
        let session = OT.initSession(this.state.sessionInfo.APIKey, this.state.sessionInfo.sessionID, { ipWhitelist: true });
        let loggedInUserId = this.props.loggedInUserId;
        let sessionId = this.state.sessionInfo.sessionID;
        //printConsole("Logged in User Id: ", this.props.loggedInUserId);
        //printConsole("Primary expert id: ", this.state.sessionInfo.primary_expert_id);
        let primary_expert_id = this.state.sessionInfo.primary_expert_id;
        if (loggedInUserId === primary_expert_id) {
            sessionObj.setState({ showHideMaxDivControl: true });
            sessionObj.props.updateShowHideHeaderActionButtons(true);
        } else if (bSubscribe === true && loggedInUserId !== primary_expert_id) {
            sessionObj.sendSignalQueryExpertSwitchMaxdiv();
        }

        if (session !== null && bSubscribe === false) {
            session.on("connectionCreated", function (event) {
                g_pubConnsArr.push(event.connection);
                //printConsole('connectionCreated: ', event.connection);
                diagonastics(sessionObj.authService, {
                    action: `open tok connectionCreated`,
                    next_step: 'initializeOTSession',
                    data: event.connection.data,
                    error: 'none'
                })
            });
        } 

        //printConsole('session: ', session);
        
        //technician block
        
            //printConsole('Inside technician loop - trying to start publishing');
            const streamName = this.state.sessionInfo.firstname + ' ' + this.state.sessionInfo.lastname;

            let div = document.createElement('div');
            div.setAttribute('id', 'publisherContainer');
            let subscriberInfo = '';
            let subscriberBox = document.getElementById(subscriberContainerDivId);

            //set the name div of the camera feed element
            let nameDiv = document.createElement('div');
            nameDiv.classList.add('position-absolute', 'w-100', 'nameDiv');
            nameDiv.style.color = 'white'; 
            //nameDiv.style.zIndex = '10';
            nameDiv.style.top = "2%";
            nameDiv.style.left = "0";
            nameDiv.innerHTML = streamName;
            div.className = "text-center";
            div.appendChild(nameDiv);
            let retNode = subscriberBox.appendChild(div);
            let pubOptions = {};
            //printConsole(this.getCookie("camera"));
            if (this.getCookie("camera") === "") {
                pubOptions = {
                    insertMode: 'append',
                    name: streamName,
                    facingMode: 'environment',
                    resolution: '1280x720',
                    frameRate: 15,
                    width: '100%',
                    height: '100%',
                    mirror: false,
                    fitMode: 'contain', // 'cover' = cropped by default
                    style: {
                        nameDisplayMode: 'off',
                        buttonDisplayMode: 'off'
                    }
                }
            } else {
                let camera = this.getCookie("camera");
                let mic = this.getCookie("microphone");
                pubOptions = {
                    insertMode: 'append',
                    name: streamName,
                    facingMode: 'environment',
                    resolution: '1280x720',
                    frameRate: 15,
                    width: '100%',
                    height: '100%',
                    videoSource: camera,
                    audioSource: mic,
                    mirror: false,
                    fitMode: 'contain', // 'cover' = cropped by default
                    style: {
                        nameDisplayMode: 'off',
                        buttonDisplayMode: 'off'
                    }
                }
            }

            // Create a camera publisher
            let publisher = OT.initPublisher('publisherContainer', pubOptions, function (error) {
                if (error) {
                    // Refactoring of the Camera Swap Feature to make use of the OT get Devices API
                    OT.getDevices(devices => {
                        if (devices !== undefined && devices !== null) {
                            let audioInputDevices = devices.filter(e => e.kind == "audioinput");
                            let videoInputDevices = devices.filter(e => e.kind == "videoinput");
                            if (videoInputDevices.length === 1) {
                                for (let i = 0; i < videoInputDevices.length; i++) {
                                    //console.log("video input device: ", videoInputDevices[i].deviceId);
                                }
                                sessionObj.setDeviceCookie("camera", videoInputDevices[0].deviceId)
                                sessionObj.setDeviceCookie("microphone", audioInputDevices[0].deviceId)
                            }
                        }
                    })
                    publisher.setVideoSource(sessionObj.getCookie("camera")) //T32-487
                            .then(() => printConsole('video source set'))
                            .catch((error) => printConsole(error.name));                    
                }
            });

            if (publisher === null)
                printConsole('publisher is a null!!!');
            else {
                //publisher.setStyle({ buttonDisplayMode: 'off' });
                // Connect to the session
                session.connect(this.state.sessionInfo.token, function (error) {
                    // If the connection is successful, publish to the session
                    if (error) {
                        this.handleError(error);
                        printConsole('error in calling publisher -> session.connect, error: ', error);
                    } else {
                        session.publish(publisher, function (error) {
                            if (error) {
                                this.handleError(error);
                                printConsole('Publish call gave an error: ', error);
                            } else {
                                sessionObj.props.changeStartStop();
                            }
                        });
                    }
                });
                //this.setState ( {publisher: publisher} );
                publisher.on({
                    'accessDenied': function () {
                        printConsole('Please allow access to the Camera and Microphone and try publishing again.');
                        alert('Please allow access to the Camera and Microphone and try streaming again.');
                    }
                    ,
                    'streamCreated': function (event) {
                    }
                    ,
                    'streamDestroyed': function (event) {
                        //printConsole("Publisher stream got destroyed due to reason");
                        //printConsole(event.reason);
                        //printConsole("Camera Count: ",sessionObj.state.cameraCount);
                        //printConsole("change the camera state: ",sessionObj.state.changeCamera);
                        //printConsole("camera swap event type: ",sessionObj.state.cameraSwapEventType);
                        if ((sessionObj.state.cameraCount > 1 && sessionObj.state.cameraSwapEventType === "") || event.reason === "mediaStopped") {
                            //printConsole("Auto Swap camera triggered")
                            sessionObj.setState({ triggerSwapCamera: true });
                        }
                        sessionObj.setState({ cameraSwapEventType: "" });
                        if (!sessionObj.state.expertShareScreenMaxDiv)
                            return;
                            
                        let subscriberBox = document.getElementById(subscriberContainerDivId);
                        subscriberBox.appendChild(document.getElementById('maxdivcontrols'));
                        //subscriberBox.appendChild(document.getElementById('controlpanel'));
                        let pubElem = document.getElementById('publisherContainer');
                        if (pubElem !== null && pubElem !== undefined) {
                            subscriberBox.removeChild(document.getElementById('publisherContainer'));
                        }
                        let screenElem = document.getElementById('expertscreenshare');
                        if (screenElem !== null && screenElem !== undefined) {
                            subscriberBox.removeChild(document.getElementById('expertscreenshare'));
                        }
                        // hide the rc button when stopping the session for tech
                        sessionObj.setState({ showRc: false })   
                    }
                });

                g_publisher = publisher;
                g_camera_publisher = publisher;

                session.on("streamDestroyed", function (event) {
                    //printConsole(event.stream);
                    printConsole(event.stream.streamId)
                    printConsole(event.stream.videoType)
                    // data format is 1:::fname:::lname:::email:::true:::opentok webhook url:::logged in user timestamp
                    let data = event.stream.connection.data;
                    printConsole(data);
                    let dataSplit = data.split(':::');

                    if (dataSplit[4] === 'true') {
                        // this for loop and if condition is added for NS2-122
                        // g_expertConnsArr is an array of experts connection data
                        // which is set inside the streamCreated of the technician
                        // and here it is getting unset
                        for (let ii = 0; ii < g_expertConnsArr.length; ii++) {
                            if (event.stream.connection.data === g_expertConnsArr[ii] && event.stream.videoType !== 'screen') {
                                g_expertConnsArr.splice(ii, 1);
                            }
                        }

                        //When the expertScreenShare is turned on and the expert stops the session
                        // But there are other Experts in the session then the technician live feed
                        // should get turned on
                        // NS2-121
                        //printConsole(sessionObj.state.expertShareScreenMaxDiv);
                        if (event.stream.videoType === 'screen' && sessionObj.state.expertShareScreenMaxDiv === true) {
                            sessionObj.setState({ expertShareScreenMaxDiv: false });
                            // hide the incoming expert screen share Div to the container
                             document.getElementById('expertscreenshare').style.display = "none";
                            // show the self-published Div from the container
                            if (document.getElementById('publisherContainer') !== null)
                                document.getElementById('publisherContainer').style.display = "block";
                            // for NS2-90
                            for (let i = 0; i < g_subInfoArr.length; i++) {
                                if (event.stream.connection.data === g_subInfoArr[i].g_stream.connection.data && g_subInfoArr[i].g_stream.videoType === "screen") {
                                    g_subInfoArr.splice(i, 1);
                                }
                            }
                            // for NS2-98
                            sessionObj.drawPublisherUI(window.innerHeight, window.innerWidth, null, false);
                        } else if (event.stream.videoType === 'camera') {
                            for (let i = 0; i < g_subInfoArr.length; i++) {
                                if (event.stream.connection.data === g_subInfoArr[i].g_stream.connection.data && g_subInfoArr[i].g_stream.videoType === "camera") {
                                    g_subInfoArr.splice(i, 1);
                                }
                            }
                        }
                        //printConsole(g_subInfoArr.length);
                        // Only when the no. of Experts in the session becomes zero,
                        // should the technicians be taken out of the session.
                        //printConsole(sessionObj.state.expertShareScreenMaxDiv);
                        if (g_expertConnsArr.length === 0) {
                            if (!sessionObj.state.expertShareScreenMaxDiv && document.getElementById('expertscreenshare') === null) {
                                sessionObj.props.onCloseTechnician(true);
                            } else if ((event.stream.videoType !== 'screen' && sessionObj.state.expertShareScreenMaxDiv) || (event.stream.videoType !== 'screen' && sessionObj.state.techShareScreen)) {
                                // for NS2-100 -- Handling on the technician side
                                sessionObj.props.onCloseTechnician(true);
                            }
                        }
                        // this condition is added for NS2-96
                        if (event.stream.videoType !== 'screen') {
                            sessionObj.setState({ showRc: false });
                            sessionObj.setState({ disconnectExpertEmail: dataSplit[3]});
                        }
                    }
                    diagonastics(sessionObj.authService, {
                        action: `in streamDestroyed for technician`,
                        next_step: '',
                        data,
                        error: 'none'
                    })
                });

                session.on("streamCreated", function (event) {
                    //let subProps = {insertDefaultUI: false};
                    printConsole(event.stream.streamId)
                    printConsole(event.stream.videoType)
                    let senderInfo = event.stream.connection.data;
                    printConsole(senderInfo);
                    const senderInfoArr = typeof senderInfo === "string" ? senderInfo.split(':::') : "";
                    if (senderInfoArr[4] === 'true' || sessionObj.state.sessionInfo.audio_mode === 'tech2Tech') {
                        // for NS2-122                      
                        if (senderInfoArr[4] === 'true' && event.stream.videoType !== 'screen') {
                            g_expertConnsArr.push(event.stream.connection.data);
                            sessionObj.setState({ newConnObj: event.stream.connection }, () => {
                                sessionObj.setState({newConnObj: null});
                            });
                            //printConsole('Expert streamCreated: ', event.stream.connection.data);
                        }
                        if (senderInfoArr[4] === 'true' && event.stream.videoType === 'screen') {
                            // subscribe to the expert screen share stream when its available **
                            let subscriberBox = document.getElementById(subscriberContainerDivId);
                            let divElem = document.createElement('div');
                            divElem.setAttribute('id', 'expertscreenshare');
                            subscriberBox.appendChild(divElem);
                            let subProps = { insertMode: 'append', width: '100%', height: '100%', fitMode: 'contain'/**TP-2265*/}; //insertMode: 'append', };
                            g_subscriber = session.subscribe(event.stream, "expertscreenshare", subProps);
                            document.getElementById('expertscreenshare').style.display = 'none';
                        }

                        // ** else subscribe to expert audio only
                        g_subscriber = session.subscribe(event.stream, "", { insertDefaultUI: false });
                        g_stream = event.stream;
                        subscriberInfo = {
                            g_stream: g_stream,
                            g_subscriber: g_subscriber
                        }
                        g_subInfoArr.push(subscriberInfo);
                    }

                    let pubContainerElem = document.getElementById('publisherContainer');
                    if (document.getElementById('maxdivcontrols') !== null && pubContainerElem !== null && pubContainerElem !== document.getElementById('maxdivcontrols').parentElement) {
                        pubContainerElem.appendChild(document.getElementById('maxdivcontrols'));
                    }
                    diagonastics(sessionObj.authService, {
                        action: `open tok streamCreated`,
                        next_step: 'stream will go on until stopped',
                        data: event.stream.connection.data,
                        error: 'none'
                    })
                });

                session.on("sessionDisconnected", (event) => {
                    //invoke this before other code
                    if(typeof(Storage) !== "undefined"){
                        ['groupId', 'isonprem'].forEach(key =>  sessionStorage.removeItem(key));//FQ3-295, TP-991
                    }else{
                        printConsole(`Sorry, your browser does not support Web Storage...`)
                    }
                    printConsole("session got disconnected");
                    printConsole(event);
                    session.off();
                    sessionObj.props.changeStartStop();
                    this.authService.socket.emit('OT-end-call', { groupId: this.props.selectedGroupId });
                    this.authService.socket.emit('user-is-in-session', { groupId: this.props.selectedGroupId, isInSession: false });
                });               
                this.drawPublisherUI(document.documentElement.clientHeight, document.documentElement.clientWidth, null);



            }
        
        return session;
    }

    // update the local state for Screen share
    updateScreenShareState = (val) => {
        this.setState({ expertShareScreenMaxDiv : val });
    }

    
    // The Technician Screen Share is turned on/off on the expert's maxdiv 
    // called on expert webapp ONLY
    onTechScreenShareClick = () => {
        // these below conditions are both for NS2-117
        if (this.state.expertSSAll) {
            printConsole(" Expert Screen share all is ongoing. Please close that before trying to do expert screen share on Maxdiv. ");
            return;
        } else if (this.state.expertShareScreenMaxDiv) {
            printConsole(" Expert screen share is going on. Please close that before trying to do technician screen share. ")
            return;
        } else if (this.state.bAnnotate && !this.state.bRemoteCapture) {
            if (this.state.techShareScreen) {
                this.onClickAnnotate();
            }
        }

        let nLen = g_subInfoArr.length;
        let ii = 0;
        for (ii = 0; ii < nLen; ii++) {
            if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                let maxDivConObj = g_subInfoArr[ii].g_stream.connection;
                this.setState({ techShareScreen: !this.state.techShareScreen }, () => {
                    // for NS2-117
                    this.props.updateScreenShareMaxdiv(this.state.techShareScreen);
                    // for NS2-126
                    this.props.updateRCStatus(true);
                    this.sendSignalTechnicianScreenShare(this.state.techShareScreen, maxDivConObj);
                });
            }
        }
    }

    // To be called when the Technician Screen share Start/Stop signals are sent from expert to technician
    // called on Expert Webapp only
    sendSignalTechnicianScreenShare = (techShareScreen, connObj) => {
        try {
            //let signal = "STOP_TECHNICIAN_SS";
            const toArray = connObj.data.split(":::");
            const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
            if (techShareScreen === true) {
                //signal = "START_TECHNICIAN_SS";
                this.sessionSignal.sendSignalStartTechnicianSS(

                    //from: g_session.connection.data,
                    toObject
                    //data: techShareScreen,
                    //type: signal
                );
                // printConsole("Technician Screen Share command sent...",techShareScreen);
            } else if (techShareScreen === false) {
                this.sessionSignal.sendSignalStopTechnicianSS(

                    //from: g_session.connection.data,
                    toObject
                    //data: techShareScreen,
                    //type: signal

                );
                // printConsole("Technician Screen Share command sent...",techShareScreen);
            }
        }
        catch (exception) {
            printConsole('exception occured in techShareScreen');
            printConsole(exception);
        }
    }

    //To be called when the Technician clicks on "cancel" button of Screen Share pop-up
    //called from Technician Webapp only
    sendSignalCancelTechScreenShare = (connData) => {
        try {

            this.sessionSignal.sendSignalCancelTechnicianSS(

                //from: g_session.connection.data,
                connData
                //type: "CANCEL_TECHNICIAN_SS"
            );
            //printConsole("Technician Screen Share cancelled sent...");
        }
        catch (exception) {
            printConsole('exception occured in sendSignalCancelTechScreenShare');
            printConsole(exception);
        }
    }


    // publish the right kinda stream based on the flag (true=screen, false=camera)
    // called from Expert Webapp only
    rePublishExpertStream = (bScreen, maxDivConObj) => {
        try {
            let subscriberBox = document.getElementById(subscriberContainerDivId);
            if (bScreen) {
                // create the screen publisher
                let div = document.createElement('div');
                div.setAttribute('id', 'myTechScreenShareDiv');
                let retNode = subscriberBox.appendChild(div);
                // NS2-113
                this.sendSignalPrepareExpertSS(true, maxDivConObj);
                g_screen_publisher = OT.initPublisher('myTechScreenShareDiv', {
                    /* facingMode: 'environment', Removed for TP-2235 */
                    resolution: '1280x720',
                    frameRate: 15,
                    width: '100%',
                    height: '100%',
                    publishAudio: false,
                    videoSource: 'screen',
                    mirror: false,
                    fitMode: 'contain'
                }, function (error) {
                    if (error) {
                        printConsole("The user has cancelled the Screen share. Hence closing...")
                        if (maxDivConObj === null) {
                            sessionObj.props.onSessionCmdChange("session_screen_share_off")
                            sessionObj.setState({ expertSSAll: false });
                            sessionObj.setState({ expertShareScreenMaxDiv: false });
                            sessionObj.props.updateScreenShareAll(false);
                        } else {
                            sessionObj.setState({ expertShareScreenMaxDiv: false });
                        }
                        // for NS2-113
                        sessionObj.sendSignalPrepareExpertSS(false, maxDivConObj);
                        // for NS2-95
                        sessionObj.props.updateRCStatus(false);
                        // for NS2-117
                        sessionObj.props.updateScreenShareMaxdiv(false);
                        return;
                    } else {
                        // publish the screen
                        g_session.publish(g_screen_publisher, function (error) {
                            if (error) {
                                sessionObj.handleError(error);
                                alert('Screen Publish call gave an error: ', error);
                            } else {
                                // Sending the session signal for the Expert screen share on/off to all the technicians
                                if (maxDivConObj === null) {
                                    sessionObj.sendSignalExpertSS(sessionObj.state.expertSSAll, maxDivConObj);
                                } else {
                                    // Sending the session signal for the Expert screen share on/off to maxdiv technician
                                    sessionObj.sendSignalExpertSS(sessionObj.state.expertShareScreenMaxDiv, maxDivConObj);
                                }
                            }
                        });
                        //for NS2-95
                        // To Make the Stop Session enabled for the case where the Expert side
                        // screen share publish fails/succeeds hence moved the RC status update call 
                        // after the publish function                       
                        sessionObj.props.updateRCStatus(false);
                    }
                }); // this.handleError

                // hide the technician screen share Div - we continue to show the live stream on publisher side
                document.getElementById('myTechScreenShareDiv').style.display = "none";
            }
            else {
                // Sending the session signal for the Expert screen share on/off to the technicians
                if (maxDivConObj === null) {
                    sessionObj.sendSignalExpertSS(sessionObj.state.expertSSAll, maxDivConObj);
                } else {
                    // Sending the session signal for the Expert screen share on/off to maxdiv technician
                    sessionObj.sendSignalExpertSS(sessionObj.state.expertShareScreenMaxDiv, maxDivConObj);
                }
                // screen share has been turned off - do the needful
                subscriberBox.removeChild(document.getElementById('myTechScreenShareDiv'));
                g_session.unpublish(g_screen_publisher);
                // to enable the expert to be bummed out after the screen share stops
                // for NS2-95
                sessionObj.props.updateRCStatus(false);
                g_screen_publisher.destroy();
                g_screen_publisher = null;
            }
        } catch (exception) {
            alert(`${exception}`);
        }
    }

    

    // The Expert screen share is turned on/off for MaxDiv
    // called on Expert Webapp only
    expertScreenShareClick = () => {
        // NS2-117
        if (this.state.expertSSAll) {
            printConsole(" Expert Screen share all is ongoing. Please close that before trying to do expert screen share on Maxdiv. ");
            return;
        } else if (this.state.techShareScreen) {
            printConsole(" Technician screen share is going on. Please close that before trying to do expert screen share. ")
            return;
        }
        let nLen = g_subInfoArr.length;
        let ii = 0;
        for (ii = 0; ii < nLen; ii++) {
            if (g_subInfoArr[ii].g_divId === g_maxDivId && g_publisher !== null) {
                let maxDivConObj = g_subInfoArr[ii].g_stream.connection;
                this.setState({ expertShareScreenMaxDiv: !this.state.expertShareScreenMaxDiv }, () => {
                    // Turning on/off the expert video publishing 
                    if (this.state.expertShareScreenMaxDiv) {
                        // NS2-117
                        this.props.updateScreenShareMaxdiv(true);
                        this.props.updateRCStatus(true);
                        // change the video source to be screen
                        this.rePublishExpertStream(true, maxDivConObj);
                    } else {
                        this.props.updateRCStatus(true);
                        // change the video source to be screen
                        this.rePublishExpertStream(false, maxDivConObj);
                        // NS2 - 117
                        this.props.updateScreenShareMaxdiv(false);
                    }
                });
            }
        }
    }

    // To be called when the Expert Screen share Start/Stop signals are sent
    // called on Expert Webapp only
    sendSignalExpertSS = (expertShareScreenMaxDiv, connObj) => {
        try {

            //let signalName = "START_EXPERT_SS";
            //let data = "moderator";

            if (expertShareScreenMaxDiv === false) {
                //signalName = "STOP_EXPERT_SS";
                //	data = "publisher";        		

                if (connObj === null) {
                    this.sessionSignal.sendSignalStopExpertSS();
                }
                else if (connObj !== null) {
                    const toArray = connObj.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    this.sessionSignal.sendSignalStopExpertSS(

                        //from: g_session.connection.data,
                        toObject
                        //    data,
                        //type: signalName
                    );
                    //printConsole("sent Expert Screen Stop share signal to :", connObj.data);
                }
            } else if (expertShareScreenMaxDiv === true) {
                if (connObj === null) {
                    this.sessionSignal.sendSignalStartExpertSS();
                }
                else if (connObj !== null) {
                    const toArray = connObj.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    this.sessionSignal.sendSignalStartExpertSS(

                        //from: g_session.connection.data,
                        toObject
                        //    data,
                        //type: signalName
                    );
                    //printConsole("sent Expert Screen Start share signal to :", connObj.data)
                }
            }

        }
        catch (exception) {
            printConsole('exception occured in sendSignalExpertSS');
            printConsole(exception);
        }
    }

    // Send signal function for the Prepare Expert SS 
    // For NS2-113
    // Handling the Expert Screen share on APK side
    sendSignalPrepareExpertSS = (bflag, connObj) => {
        try {

            if (connObj === null) {
                this.sessionSignal.sendSignalPrepareExpertSS(

                    //from: g_session.connection.data,
                    bflag
                    //type: "PREPARE_EXPERT_SS"

                    //printConsole(" sendSignal PrepareExpert SS sent to all the participating technicians ");
                );
            }
            else if (connObj !== null) {
                const toArray = connObj.data.split(":::");
                const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                this.sessionSignal.sendSignalPrepareExpertSS(

                    //from: g_session.connection.data,
                    bflag,
                    toObject
                    //type: "PREPARE_EXPERT_SS"

                    //printConsole("sent Prepare Expert Screen share signal to :", connObj)
                );
            }
        }
        catch (exception) {
            printConsole('exception occured in sendSignalPrepareExpertSS');
            printConsole(exception);
        }
    }

    // on Click Function for Hand Icon beside the Timer component
    onClickHandIcon = () => {
        const { clickHandSignal } = this.state;
        const { user } = this.props;
        //printConsole(g_subInfoArr);
        if (clickHandSignal === false) {
            this.setState({ clickHandSignal: true }, () => {
                const nLen = g_subInfoArr.length;
                for (let ii = 0; ii < nLen; ii++) {
                    if (g_subInfoArr[ii].g_stream.videoType !== "screen" && g_subInfoArr[ii].g_stream.hasVideo === false) {
                        const expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
                        const data = { 'firstName': user.firstname, 'lastName': user.lastname };
                        this.sendSignalRequestMaxDivControl(data, expertDivConnObj);
                    }
                }
            });
        }
    }

    // send the Signal for requesting to make Max Div Controls tab visible 
    // for second expert in the session
    sendSignalRequestMaxDivControl = (data, connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
            this.sessionSignal.sendSignalRequestMaxDivControl(

                //from: g_session.connection.data,
                data,
                toObject
                //type: "REQUEST_MAXDIV_CONTROL"

                //printConsole(`Other Expert request for maxdiv control command sent.. to ${connObj.data}.`);
            );
        }
        catch (exception) {
            printConsole(exception);
            alert('exception occured in send Request for MaxDiv Control function');
        }
    }

    // for NS2-375
    isOngoingPrimaryExpertOperations = () => {
        const { bAnnotate, bRemoteCapture, bShareAsset, bZoomClick, bTorchClick, techShareScreen, expertShareScreenMaxDiv, startRecording } = this.state;
        let flag = false;
        if (bAnnotate) flag = true;
        if (bRemoteCapture) flag = true;
        if (bShareAsset) flag = true;//NS2-457
        if (bZoomClick) flag = true;
        if (bTorchClick) flag = true;
        if (techShareScreen) flag = true;
        if (expertShareScreenMaxDiv) flag = true;//NS2-457
        if (startRecording) flag = true;
        return flag;
    }

    onClickAcceptMaxDivControl = () => {
        const { expertFirstName, expertLastName } = this.state;
        let expertDivConnObj;
        const nLen = g_subInfoArr.length;
        for (let ii = 0; ii < nLen; ii++) {
            expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
            let connData = expertDivConnObj.data;
            let connArray = connData.split(':::');
            if (g_subInfoArr[ii].g_stream.videoType !== "screen" && connArray[1] === expertFirstName && connArray[2] === expertLastName) {
                const data = true;
                this.sendSignalYieldMaxDivControls(data, expertDivConnObj);
            }
        }
        this.setState({ showHideMaxDivControl: false });
        //NS2-372
        sessionObj.sendSignalQueryExpertSwitchMaxdiv();
        // for NS2-137
        this.props.updateShowHideHeaderActionButtons(false);
        this.hideRequestDialog();
    }

    onClickDenyMaxDivControl = () => {
        const { expertFirstName, expertLastName } = this.state;
        let expertDivConnObj;
        const nLen = g_subInfoArr.length;
        for (let ii = 0; ii < nLen; ii++) {
            expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
            let connData = expertDivConnObj.data;
            let connArray = connData.split(':::');
            if (g_subInfoArr[ii].g_stream.videoType !== "screen" && connArray[1] === expertFirstName && connArray[2] === expertLastName) {
                const data = false;
                this.sendSignalYieldMaxDivControls(data, expertDivConnObj);
            }
        }
        this.hideRequestDialog();
    }

    // send the Yield Max Div Control Request to the second expert
    sendSignalYieldMaxDivControls = (data, connObj) => {
        try {
            //printConsole(connObj.data);
            let toObject = {};
            if (connObj.data === undefined) {
                toObject = connObj;
            } else {
                const toArray = connObj.data.split(":::");
                toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
            }
            this.sessionSignal.sendSignalYieldMaxDivControls(
                //from: g_session.connection.data,
                data,
                toObject
                //type: "YIELD_MAXDIV_CONTROL"                
            );
            //printConsole("Main Expert response Yield for maxdiv control command sent... with data ", data);
        }
        catch (exception) {
            printConsole(exception);
            printConsole('exception occured in send Yield for MaxDiv Control function');
        }
    }

    onClickZoomIcon = () => {
        const { bZoomClick } = this.state;
        const zoomSelector = document.getElementById('zoomlevelselector');
        this.setState({ bZoomClick: !bZoomClick }, () => {
            if (sessionObj.state.bZoomClick) {
                zoomSelector.style.display = 'block';
            } else {
                zoomSelector.style.display = 'none';
            }
        })
    }

    onClickZoomLevel = (val) => {
        this.setState({ zoom_level: val }, () => {
            const nLen = g_subInfoArr.length;
            for (let ii = 0; ii < nLen; ii++) {
                if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                    const expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
                    this.sendSignalRemoteZoomLevel(this.state.zoom_level, expertDivConnObj);
                }
            }
        });
    }

    sendSignalRemoteZoomLevel = (data, connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
            this.sessionSignal.sendSignalRemoteZoom(

                //from: g_session.connection.data,
                data,
                toObject
                //type: "REMOTE_ZOOM_LEVEL"

                //printConsole(" Remote Zoom Level command sent with data:", data);
            );
        }
        catch (exception) {
            printConsole(exception);
            printConsole('exception occured in send remote zoom level function');
        }
    }

    // function triggered when Torch Icon is clicked
    // Only on the Expert side
    onClickTorchIcon = () => {
        const { bTorchClick } = this.state;
        const nLen = g_subInfoArr.length;
        let expertDivConnObj = '';
        let data = "";
        for (let ii = 0; ii < nLen; ii++) {
            if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
            }
        }
        if (bTorchClick === true && expertDivConnObj !== '') {
            this.setState({ bTorchClick: false }, () => {
                data = false;
                this.sendSignalTechnicianTorch(data, expertDivConnObj);
            })
        } else if (expertDivConnObj !== '') {
            this.setState({ bTorchClick: true }, () => {
                data = true;
                this.sendSignalTechnicianTorch(data, expertDivConnObj);
            })
        } else {
            printConsole("Exception occured: The torch icon was clicked for video stream that isn't on the Maxdiv")
        }
    }

    // function to send the Technician Torch on/off signal
    // Only on the Expert side
    sendSignalTechnicianTorch = (data, connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
            this.sessionSignal.sendSignalRemoteTorch(

                //from: g_session.connection.data,
                data,
                toObject
                //type: "TECHNICIAN_TORCH_ON"                
            );
            //printConsole(" Technician torch command sent with data:", data);
        }
        catch (exception) {
            printConsole(exception);
            printConsole('exception occured in send technician torch on/off function');
        }
    }

    // send the individual mute/unmute expert mike signal
    muteExpertMikeForMaxDiv = (muteMikeFortech, connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
            this.sessionSignal.sendSignalMuteExpertMike(

                //from: g_session.connection.data,
                muteMikeFortech,
                toObject
                //type:"MUTE_EXPERT_AUDIO"

            );
            //printConsole("Expert mute command sent...");
        }
        catch (exception) {
            printConsole(exception);
            alert('exception occured in ExpertMute');
        }
    }

    // Find the the maxDiv tech to send the mute/unmute expert mike signal
    sendExpertMuteCmd = () => {
        let nLen = g_subInfoArr.length;
        let ii = 0;
        for (ii = 0; ii < nLen; ii++) {
            if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                let maxDivConObj = g_subInfoArr[ii].g_stream.connection;
                this.setState({ muteMikeForMaxDiv: !this.state.muteMikeForMaxDiv }, () => {
                    this.muteExpertMikeForMaxDiv(!this.state.muteMikeForMaxDiv, maxDivConObj);
                });
            }
        }
    }

    // To be called from the header bar "Mute Mike" for all
    sendExpertMuteAllCmd = (muteMikeFortech) => {
        try {
            this.sessionSignal.sendSignalMuteExpertMike(

                //from: g_session.connection.data,
                muteMikeFortech,
                //type:"MUTE_EXPERT_AUDIO"

            );
            // printConsole("Expert mute command sent...",muteMikeFortech);
        }
        catch (exception) {
            printConsole('exception occured in ExpertMuteAll');
            printConsole(exception);
        }
    }

    // mute/unmute expert mike for single tech
    muteExpertMikeInOutMaxDiv = (muteMicFlag) => {
        let nLen = g_subInfoArr.length;
        let ii = 0;
        for (ii = 0; ii < nLen; ii++) {
            if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                let maxDivStream = g_subInfoArr[ii].g_stream
                let maxDivConObj = maxDivStream.connection;
                this.setState({ maxDivTechName: maxDivStream.name });
                this.muteExpertMikeForMaxDiv(muteMicFlag, maxDivConObj);
            }
        }
    }

    

    // send signal to initiate rc from expert 
    onClickexpertRcSignal = () => {
        let { bRemoteCapture } = this.state;
        diagonastics(sessionObj.authService, {
            action: `Annotate button clicked by technician`,
            next_step: '',
            data: `bRemoteCapture-${bRemoteCapture}`,
            error: 'none'
        })
        this.setState((prevState) => ({ bRemoteCapture: !prevState.bRemoteCapture }), () => {
            g_pubConnsArr.forEach(connectionObj => {
                if (g_session.connection !== connectionObj) {
                    const toArray = connectionObj.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    this.sessionSignal.sendSignalLocalRC(
                        //from: g_session.connection.data,
                        sessionObj.state.bRemoteCapture,
                        toObject
                        //type:"START_LOCAL_RC"

                    );
                    //printConsole(`signal sent - for = START_LOCAL_RC with value ${sessionObj.state.bRemoteCapture.toString()}`);
                }
            });
        })
    }

    // mute/unmute mike modal
    muteMikeForTechDialog = (openMikeDialog) => {
        return (
            <div>
                <Modal isOpen={this.state.openMikeDialog} toggle={this.closeMikeDialog}>
                    <ModalHeader toggle={this.closeMikeDialog} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Audio Alert</Trans></ModalHeader>
                    <ModalBody>
                        <div>
                            {this.state.maxDivTechName} {this.state.muteMikeForMaxDiv ? <Trans>cannot hear you. Do you want to unmute your mike?</Trans> : <Trans>can hear you. Do you want to mute your mike?</Trans>}
                        </div>
                        <div>
                            <Button color="primary" onClick={this.muteUnmuteMaxDiv} className="pull-right">
                                {this.state.muteMikeForMaxDiv ? <Trans>Unmute Mike</Trans> : <Trans>Mute Mike</Trans>}
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    // dialog for stream destroyed during screeen capture
    streamDestroyedDialog = () => {
        const senderInfoArr = sessionObj.state.sRCTechDetails.split(':::');
        return (
            <div>
                <Modal centered={true} backdrop={false} isOpen={this.state.openStreamDestroyedDialog} toggle={this.closeStreamDestroyedDialog}>
                    <ModalHeader className="p-2" toggle={this.closeStreamDestroyedDialog} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Image Capture Mode</Trans></ModalHeader>
                    <ModalBody>
                        <div>
                            <h3>
                                <Trans>RC Countdown Msg1</Trans>
                                {senderInfoArr[1]}
                                <Trans>RC Countdown Msg2</Trans>
                            </h3>
                        </div>
                        <div className="text-center" style={{ fontSize: "40px", fontWeight: "bold" }}>
                            {this.state.imageCaptureTimer}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    queryRCSupport = (connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
            this.sessionSignal.sendSignalQueryRCSupport(

                //from: g_session.connection.data,
                "",
                toObject
                //type:"CONFIRMRCSUPPORT"

            );
            //printConsole("remote click capability query sent...");
        }
        catch (exception) {
            printConsole(exception);
            alert('exception occured');
        }
    }

    incrementAllChatBadge = () => {
        sessionObj.setState({ nAllChatNewMsgCounter: (sessionObj.state.nAllChatNewMsgCounter + 1) })
    }

    processRemoteClickImage = (paramElem, fileName) => {
        // download the s3 file
        AWS.config.update(
            {
                bucketName: 'telepresenz-20-p2pfiles',
                dirName: 'boo', /* optional */
                region: 'us-east-2',
                accessKeyId: 'dummy-aws-access-key',//TP-6973
                secretAccessKey: 'dummy-aws-secret-access-key',//TP-6973
            }
        );

        //let elemRemoteCaptureImg = getRemoteCaptureElement(paramElem);
        let s3 = new AWS.S3();
        //printConsole('s3 = ', s3);
        s3.getObject(
            { Bucket: "telepresenz-20-p2pfiles/boo", Key: fileName },
            function (error, data) {
                if (error != null) {
                    printConsole("Failed to retrieve a remote image object: " + error);
                } else {
                    if (sessionObj.state.bRemoteCapture === true) {
                        let blob = new Blob([data.Body], { type: data.ContentType });
                        let urlObj = URL.createObjectURL(blob);
                        if (sessionObj.state.showHideMaxDivControl === true) {
                            //NS2-217
                            sessionObj.setState({ urlObj: urlObj });
                            sessionObj.startRemoteCaptureAnnotation(paramElem);
                            sessionObj.turnOnAnnotate(true, urlObj);

                        } else {
                            // get the frame image from the publisher stream
                            // NS2-345
                            sessionObj.setState({ rcImgObj: [data.Body], rcImgType: data.ContentType }, () => { //urlObj
                                // turn on annotation on passive Experts
                                strCanvasJSON = "";
                                let expertMaxdivElement = document.getElementById(g_maxDivId);
                                onDrawPassiveExperts(expertMaxdivElement, sessionObj.state.bRemoteCapture, sessionObj.state.rcImgObj);
                                //displayAnnotation(expertMaxdivElement, sessionObj.state.rcImgObj); 
                            });
                        }
                    }
                }
                sessionObj.setState({ isLoading: false });
                sessionObj.setState({ sRCTechDetails: '' });
            }
        );
    }

    stopRemoteCaptureAnnotation = (elemId) => {
        if (g_session !== null && g_subInfoArr.length > 0) {
            g_subInfoArr.forEach(subInfo => {
                if (elemId === null || subInfo.g_divId === elemId.id) {
                    //printConsole('divId matched up');
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    this.sessionSignal.sendSignalStopRCAnnotation(

                        //from: g_session.connection.data,
                        "",
                        toObject
                        //type:"STOPREMOTECAPTUREANNOTATE"                        
                    );
                    //printConsole("STOPREMOTECAPTUREANNOTATE signal sent to technician...");
                    return;
                }
            })
        }
    }

    // for NS2-345
    stopExpertRCAnnotate = () => {
        if (g_session !== null && g_expertConnsArr.length > 0) {
            g_expertConnsArr.forEach(subInfo => {
                if (this.state.showHideMaxDivControl === true) {
                    const toArray = subInfo.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    this.sessionSignal.sendSignalExpertStopRC(
                        //from: g_session.connection.data,
                        "",
                        toObject
                        //type: "EXPERT_STOP_RC"
                    );
                    //printConsole("EXPERT_STOP_RC signal sent to other experts...");
                    return;
                }
            })
        }
    }

    startRemoteCaptureAnnotation = (elemId) => {
        if (g_session !== null && g_subInfoArr.length > 0) {
            g_subInfoArr.forEach(subInfo => {
                if (elemId === null || subInfo.g_divId === elemId.id) {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };

                    //printConsole('divId matched up');
                    this.sessionSignal.sendSignalStartRCAnnotation(

                        //from: g_session.connection.data,
                        "",
                        toObject
                        //type:"STARTREMOTECAPTUREANNOTATE"

                        //printConsole("STARTREMOTECAPTUREANNOTATE signal sent to technician...");
                    );
                    return;
                }
            })
        }
    }

    getMonthString = (mo) => {
        switch (mo) {
            case 0: return 'Jan';
            case 1: return 'Feb';
            case 2: return 'Mar';
            case 3: return 'Apr';
            case 4: return 'May';
            case 5: return 'Jun';
            case 6: return 'Jul';
            case 7: return 'Aug';
            case 8: return 'Sep';
            case 9: return 'Oct';
            case 10: return 'Nov';
            case 11: return 'Dec';
            default: return '';
        }
    }

    getTimeStamp = () => {
        let strTimeBlob = '';
        let today = new Date();

        strTimeBlob = this.getMonthString(today.getMonth());
        strTimeBlob += ' ';
        strTimeBlob += today.getDate();
        strTimeBlob += ' ';
        strTimeBlob += today.getHours();
        strTimeBlob += ':';
        strTimeBlob += today.getMinutes();

        return strTimeBlob;
    }

    recalcLayout = (heightDim, widthDim, bExtra, bFlag = false) => {
        if (this.state.sessionInfo.role === 'publisher')
            this.drawPublisherUI(heightDim, widthDim, bExtra, bFlag);
        
    }

    drawPublisherUI = (heightDim, widthDim, bExtra, flg = false) => {
        // Set the size of the container
        //printConsole(`Window Height ${heightDim} & Window Width ${widthDim}`);
        let Height = Math.floor(heightDim - heightDim*(borderYsize/100) - 6); 
        let Width = Math.floor(widthDim); 
        const sideBarWidth = 70;
        let containerBox = document.getElementById(subscriberContainerDivId); //flex-container
        if (containerBox === null) {
            printConsole('no div layout for videos yet!');
            return;
        }
        containerBox.style.position = "relative";//side bar related changes
        //containerBox.style.left = '0px';
        //containerBox.style.top = headerSize + 'px';
        //containerBox.style.width = Math.floor((heightDim * 16) / 9) + "px";
        //containerBox.style.height = Math.floor(heightDim - (borderYsize)) + "px";
        containerBox.style.width = Width + "px";
        containerBox.style.height =  Height + "px";
        containerBox.style.backgroundColor = '#1c2238';

        let pubContainerElem = null;
        // draw the shared screen if expert is publishing
        if (document.getElementById('publisherContainer') == null || window.getComputedStyle(document.getElementById('publisherContainer')).display === 'none') {
            pubContainerElem = document.getElementById('expertscreenshare');
        } else {
            pubContainerElem = document.getElementById('publisherContainer');
        }

        if (pubContainerElem !== null) {
            let yDim = Math.floor(Height - (Height*(borderYsize/100)));
            let xDim = Math.floor((yDim * 16) / 9);
            let xGap = Math.floor((Width - xDim - sideBarWidth) / 4);
            let yGap = 0;

            if (Width > 1300 && Width <= 1400)
                xGap = Math.floor((Width - xDim - sideBarWidth) / 6);
            else if (Width > 1400 && Width <= 1600)
                xGap = Math.floor((Width - xDim - sideBarWidth) / 8);

            else if (Width <= 1300) {
                yDim = Math.floor(Height - (Height*2*(borderYsize/100)));
                xDim = Math.floor((yDim * 16) / 9);
                //yGap = Math.floor((Height - yDim) / 3);
                xGap = Math.floor((Width - xDim - sideBarWidth) / 4) ;
                if (xDim > Width) {
                   xDim = Math.floor(Width - sideBarWidth);
                   yDim = Math.floor((xDim * 9) / 16);
                   yGap = Math.floor((Height - yDim) / 3);
                   xGap = 0; 
                }
            }
            // T32-473 Setting the x axis margin from the left for 
            // Video Element
            let xMargin = Math.floor((Width - xDim - sideBarWidth) / 2);
            if (xDim >= Math.floor(Width - sideBarWidth)) xMargin = 0;
            //printConsole(`yDim = ${yDim}, xDim = ${xDim}, yGap = ${yGap}, xGap = ${xGap}`);
            pubContainerElem.style.height = (yDim) + 'px';
            pubContainerElem.style.width = xDim + 'px';
            pubContainerElem.style.position = 'absolute';
            pubContainerElem.style.left = xMargin + 'px'; // T32-473
            pubContainerElem.style.top = yGap + 'px';
            sessionObj.setState({ chatLeft: '0px' });
            sessionObj.setState({ chatTop: '0px' });
            sessionObj.setState({ chatWidth: '0px' });
            sessionObj.setState({ chatHeight: '0px' });

            //			alert(`Elem.style.height = ${yDim}, width = ${xDim}, left = ${xGap}, top = ${yGap}`);


            if (sessionObj.state.bMaxChat === true || sessionObj.state.bAllChat === true) {
                pubContainerElem.style.left = '10' + 'px';
                sessionObj.setState({ chatLeft: (xDim + 20) + 'px' });
                sessionObj.setState({ chatTop: (yGap) + 'px' });
                sessionObj.setState({ chatWidth: ((xGap - 20) * 2 + 'px') });
                sessionObj.setState({ chatHeight: (yDim + 'px') });
            }

            // NS2-98
            //if (flg === true) pubContainerElem.style.display = 'none';

            if (document.getElementById('maxdivcontrols') !== null && flg === false) {
                //    	document.getElementById('maxdivcontrols').style.display = 'block';
                pubContainerElem.appendChild(document.getElementById('maxdivcontrols'));
                document.getElementById('maxdivcontrols').style.left = '46%'; // NS2-173
            } // NS2-98
            else if (document.getElementById('maxdivcontrols') !== null && flg === true) {
                document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('maxdivcontrols'));
            }
            // NS2-173
            // This if condition is to take care of the Max-div control buttons when screen share is ongoing
            if (document.getElementById('maxdivcontrols') !== null && sessionObj.state.expertShareScreenMaxDiv === true) {
                document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('maxdivcontrols'));
                document.getElementById('maxdivcontrols').style.left = '46%';
                if (sessionObj.state.bMaxChat === true || sessionObj.state.bAllChat === true) {
                    document.getElementById('maxdivcontrols').style.left = '35%';
                }
            }
        }
        // T32-473 setting the position of the session timer
        // relative to the Video Element
        let sessionTimer = document.getElementById('sessionTimer');
        if (sessionTimer !== null) {
            pubContainerElem.appendChild(sessionTimer); // to center the timestamp w.r.t the Video Element
        }
    }

    componentWillUnmount = () => {
        const { user } = this.props
        this.decodedUser && this.decodedUser.showuploadspeed && this.stopcheckNetworkSpeed();
        //clearInterval(this.interval);
        if (user.isexpert)
        {
            this.props.onCloseTechnician(false);
            this.state.URLs.forEach(url => {
                if (url) window.URL.revokeObjectURL(url);
            });            
        }
        /** Unsubscribe / remove the event listeners while component unmounts */
        // MB2-106
        if (user.isExpert) {
            //this.sessionSignal.removeListener(`group-${this.props.selectedGroupId}-signal`, this.subscribeToGroupSignalForExperts);
            //this.sessionSignal.removeWebsocket(this.subscribeToGroupSignalForExperts);
        } else {
            this.sessionSignal.removeUserinsession(this.subscribeToUserIsInSession);
        }

        this.onClose();
        
    }

    updateDimensions = () => {
        //printConsole('updateDimensions called with ', window.innerHeight, window.innerWidth);
        this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
    }

    componentWillMount = () => {
        //printConsole('OT initialized from componentWillMount');
        const { user, loggedInUserId } = this.props;        
        if (user.isexpert) {
            // MB2-90 & MB2-106
            const from = { userId: loggedInUserId, firstName: user.firstname, lastName: user.lastname, email: user.email, isexpert: user.isexpert };
            this.sessionSignal = new SessionSignal(from, false, this.props.selectedGroupId);
            //this.sessionSignal.listener(`group-${this.props.selectedGroupId}-signal`, this.subscribeToGroupSignalForExperts);
            //this.sessionSignal.listenerWebsocket(this.subscribeToGroupSignalForExperts);
        } else {
            const from = { userId: loggedInUserId, firstName: user.firstname, lastName: user.lastname, email: user.email, isexpert: user.isexpert };
            this.sessionSignal = new SessionSignal(from, false, this.props.selectedGroupId);
            this.sessionSignal.listenerUserinsession(this.subscribeToUserIsInSession);
        } 
    }

    subscribeToUserIsInSession = ({ isInSession, userName, caller }) => {
        //let { g_subInfoArr } = this.props;
        //printConsole(userName);
        
        if(isInSession) {
            /* if (g_subInfoArr.length === 0)
                this.setState({ isLoading: true }); */            
        } else { 
            //printConsole(g_expertConnsArr.length);           
            if (g_expertConnsArr.length === 0){
                //printConsole("Last Expert went out of the call");
                this.props.onCloseTechnician(true);
            }            
        }
    }

    onStart = () => {
        this.setState({ bSessionStarted: true });
        this.setState({ bRefresh: true });
        this.initializeOpentok();
    }

    setChatJoinees = (nameArr) => {
        this.props.onSetChatJoinees(nameArr);
    }

    // start timer
    startSessionTimer = () => {
        this.setState({ startTimer: true });
    }

    // stop timer
    stopSessionTimer = () => {
        this.setState({ startTimer: false });
    }

    //stop timer from expert component
    updateSessionTimer = (val) => {
        if (val === false)
            this.stopSessionTimer();
        else 
            this.startSessionTimer();
    }

    checkChatUser = (from, g_subInfo, g_maxDivId) => {
        let pubData = from;
        if (this.state.bAllChat !== true) {
            if (g_subInfo.length > 1) {
                g_subInfo.forEach(subInfo => {
                    const subInforArr = subInfo.g_stream.connection.data.split(":::");
                    if (subInforArr[3] === from.email && subInfo.g_divId !== g_maxDivId) {
                        let pubDataEmail = pubData.email;
                        let pubDataName = pubData.firstName + ' ' + pubData.lastName;
                        let chatUserEmailArr = this.state.chatUserEmails;
                        if ((chatUserEmailArr.indexOf(pubDataEmail)) < 0) {
                            this.setState(prev => ({
                                chatUserEmails: [
                                    ...prev.chatUserEmails,
                                    pubDataEmail
                                ]
                            }), () => {
                                if (this.state.chatUserEmails.length > 0) {
                                    this.setState(prev => ({
                                        chatUserNames: [
                                            ...prev.chatUserNames,
                                            pubDataName
                                        ]
                                    }), () => {
                                        this.setChatJoinees(this.state.chatUserNames);
                                    });
                                }
                            });
                        }
                    }
                })

            }
        }
    }

    addSessionJoinees = (gStream) => {
        let { data } = gStream.connection;
        let token_data = data.split(':::');
        let isExpert = token_data[4];
        let name = isExpert === 'true' ? ('* ' + token_data[1] + ' ' + token_data[2]) :
            (token_data[1] + ' ' + token_data[2]);
        this.setState(prev => ({
            sessionJoinees: [
                ...prev.sessionJoinees,
                name
            ]
        }), () => {
            sessionObj.props.onSetSessionJoinees(sessionObj.state.sessionJoinees);
        });

    }

    removeSessionJoinees = (gStream) => {
        let { data } = gStream.connection;
        let token_data = data.split(':::');
        let isExpert = token_data[4];
        let name = isExpert === 'true' ? ('* ' + token_data[1] + ' ' + token_data[2]) :
            (token_data[1] + ' ' + token_data[2]);
        let { sessionJoinees } = this.state;
        let valueToRemove = name;
        let names = sessionJoinees.filter(n => n !== valueToRemove);
        this.setState({ sessionJoinees: names }, () => {
            sessionObj.props.onSetSessionJoinees(this.state.sessionJoinees);
        });

    }
    componentDidUpdate = (prevProps) => {
        // printConsole('this.props.sessionCmd, prevProps.sessionCmd = ', this.props.sessionCmd, prevProps.sessionCmd);
        //        if(this.props.sessionCmd !== prevProps.sessionCmd )
        //        {
        if (this.props.sessionCmd === 'session_started' && sessionObj.state.bSessionStarted === false && g_session === null) {
            if (prevProps.sessionCmd !== 'session_started') this.onStart();
        }
        else if (this.props.sessionCmd === 'session_stopped' && sessionObj.state.bSessionStarted === true) {
            if (this.state.sessionInfo.role === 'publisher') {
                //this.props.onCloseTechnician(false);
            } else {
                //NS2-179
                this.props.updateShowHideHeaderActionButtons(false);
                this.props.showScreenShareHeaderButton(true); // for NS2-282
            }
            if (prevProps.sessionCmd !== 'session_stopped') this.onClose();
            if (this.state.sessionInfo.role !== 'publisher') propsSetJoineeCount(0);


            // re-initialize sub array
            g_subInfoArr.splice(0, g_subInfoArr.length);
            g_maxDivId = null;
        }
        else if ((this.props.sessionCmd === 'session_unmutemike' && this.state.bMuteMyMike === true) || (this.props.sessionCmd === 'session_mutemike' && this.state.bMuteMyMike === false)) {
            // mute mike
            if (g_publisher !== null && (this.props.sessionCmd === 'session_unmutemike')) {
                if (this.state.sessionInfo.role !== 'publisher') {
                    this.setState({ bMuteMyMike: false, muteMikeForMaxDiv: false }, () => {
                        this.sendExpertMuteAllCmd(!this.state.bMuteMyMike);
                    });
                    //g_camera_publisher.publishAudio(true);
                } else {
                    this.setState({ bMuteMyMike: false }, () => {
                        g_camera_publisher.publishAudio(true);
                    });
                }
            }
            else if (g_publisher !== null && (this.props.sessionCmd === 'session_mutemike')) {
                if (this.state.sessionInfo.role !== 'publisher') {
                    this.setState({ bMuteMyMike: true, muteMikeForMaxDiv: true }, () => {
                        this.sendExpertMuteAllCmd(!this.state.bMuteMyMike);
                    });
                    //g_camera_publisher.publishAudio(false);
                } else {
                    this.setState({ bMuteMyMike: true }, () => {
                        g_camera_publisher.publishAudio(false);
                    });
                }
            }
        }
        else if (((this.props.sessionCmd === 'session_muteall' && this.state.bMuteAllAudio === false) || (this.props.sessionCmd === 'session_unmuteall' && this.state.bMuteAllAudio === true)) && g_subInfoArr.length > 0) {
            // mute all users
            let nLen = g_subInfoArr.length;
            let ii = 0;
            for (ii = 0; ii < nLen; ii++) {
                if (this.props.sessionCmd === 'session_muteall') {
                    g_subInfoArr[ii].g_subscriber.subscribeToAudio(false); //.setAudioVolume(0); - //audio off
                }
                else {
                    g_subInfoArr[ii].g_subscriber.subscribeToAudio(true); //.setAudioVolume(100); // audio on
                }
            }
            if (this.props.sessionCmd === 'session_muteall') {
                this.setState({ bMuteAllAudio: true });
                this.setState({ bRemoteMute: true });
            }
            else {
                this.setState({ bMuteAllAudio: false });
                this.setState({ bRemoteMute: false });
            }
        }
        /* else if (((this.props.sessionCmd === 'session_chat' && this.state.bAllChat === false) || (this.props.sessionCmd === 'session_chat_off' && this.state.bAllChat === true)) && g_subInfoArr.length > 0) {
            if (this.props.sessionCmd === 'session_chat') {
                this.setState({ bAllChat: true }, () => {

                    this.props.updateChatAll(true);

                    if (this.state.bMaxChat === true && this.state.bAllChat === true) {
                        this.onClickMaxChat();
                    } else {
                        this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
                    }
                });
            }
            if (this.props.sessionCmd === 'session_chat_off') {
                this.setState({
                    bAllChat: false,
                    chatUserEmails: [],
                    chatUserNames: []
                }, () => {
                    this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
                });
            }

        } */
        else if (((this.props.sessionCmd === 'session_share' && this.state.bShareAll === false) || (this.props.sessionCmd === 'session_share_off' && this.state.bShareAll === true))) {
            //NS2-484            
            this.setState({ bShareAll: !this.state.bShareAll }, () => {
                // let shareIndex = 0;
                // for(shareIndex = 0; shareIndex < g_subInfoArr.length; shareIndex++)
                // {
                // if(this.state.sessionInfo.role !== 'publisher') // publisher
                // {
                //this.onClickUploadFile(null);
                // }
                // }
            });
        }
        else if ((this.props.sessionCmd === 'session_share_screen' && this.state.expertSSAll === false) || (this.props.sessionCmd === 'session_share_screen_off' && this.state.expertSSAll === true) && g_subInfoArr.length > 0) {
            if (this.props.sessionCmd === 'session_share_screen') {
                this.props.updateScreenShareAll(true);
            }
            this.setState({ expertSSAll: !this.state.expertSSAll }, () => {
                //this.expertScreenShareAll();
            })
        }
        //        }
    }

    componentDidMount = () => {
        this.setState({ chatUserEmails: [], chatUserNames: [] });
    }

    // NS2-366
    trackClickOn3dCanvas = () => {
        document.addEventListener('click', (event) => {
            this.canvasListner(event)
        });
    }
    // NS2-366
    canvasListner = (event) => {
        const canvas = document.getElementById('canavas-3d-container');
        if (canvas) {
            const isClickInsideElement = canvas.contains(event.target);
            if (!isClickInsideElement) {
                const closeButton = document.getElementById('closeIcon');
                if (closeButton) {
                    closeButton.click();
                }
            }
        }
    }
    // NS2-366
    remove3dCanvasListner = () => {
        document.removeEventListener('click', (event) => {
            this.canvasListner(event)
        });
    }

    onClose = () => {
        this.setState({ bSessionStarted: false });
        if (this.state !== undefined && this.state.session !== undefined && this.state.sessionInfo !== undefined) {
            if (this.state.sessionInfo.role !== 'publisher' && g_subInfoArr !== null && this.state.session !== null) {
                let index = 0;
                if (g_subInfoArr.length > 0) {
                    document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('controlpanel'));
                    for (index = 0; index < g_subInfoArr.length; index++) {
                        if (g_subInfoArr[index].g_stream.publisher !== null) g_subInfoArr[index].g_subscriber.subscribeToVideo(false);
                        this.state.session.unsubscribe(g_subInfoArr[index].g_subscriber);
                        document.getElementById(g_subInfoArr[index].g_divId).parentElement.removeChild(document.getElementById(g_subInfoArr[index].g_divId));
                        g_subInfoArr.splice(index, 1);
                    }
                    let elemRemnantList = document.getElementsByClassName('vdoDiv');
                    for (index = 0; index < elemRemnantList.length; index++)
                        elemRemnantList[index].parentElement.removeChild(elemRemnantList[index]);
                    //let elemSmallDivContainer = document.getElementById('small-divs-container');
                    //elemSmallDivContainer.parentElement.removeChild(elemSmallDivContainer);
                }
            }
            // NS2-100
            if (g_screen_publisher !== null && this.state.session !== null) {
                if (g_screen_publisher !== null && g_screen_publisher !== undefined) {
                    this.state.session.unpublish(g_screen_publisher);
                    g_screen_publisher.destroy();
                    g_screen_publisher = null;
                }
            }
            if (g_publisher !== null && this.state.session !== null) {
                //   if(document.getElementById('maxdivcontrols') !== null)
                // document.getElementById('maxdivcontrols').style.display = 'none';
                //g_publisher.publishVideo(false);
                if (g_publisher !== null && g_publisher !== undefined) {
                    this.state.session.unpublish(g_publisher);
                    g_publisher.destroy();
                    g_publisher = null;
                }
                // NS2-117
                this.props.updateScreenShareMaxdiv(false);
            }
            // NS2-100
            if (g_camera_publisher !== null && this.state.session !== null) {
                if (g_camera_publisher !== null && g_camera_publisher !== undefined) {
                    this.state.session.unpublish(g_camera_publisher);
                    g_camera_publisher.destroy();
                    g_camera_publisher = null;
                }
            }
            if (this.state.session !== null && this.state.session.connection !== null) {
                this.state.session.off("streamCreated");
                this.state.session.disconnect();
                this.setState({ session: null });
            }

            // set mute all flag to false
            if (this.state.sessionInfo.role !== 'publisher') {
                if (this.state.bRemoteCapture === true)
                    this.onClickCamera();
                this.setState({
                    bMuteAllAudio: false,
                    bRemoteMute: false,
                    bMuteMyMike: false,
                    muteMikeForMaxDiv: false,
                    sessionJoinees: []
                });
            }
        }
        g_maxDivId = null;
        g_session = null;
        from = null;
        this.stopSessionTimer();
    }

    // NS2-258
    onClickRectIcon = () => {
        const { isRectActive, isCircleActive, isFreeDrawing } = this.state;
        if (isRectActive === false) {
            annotateShapesArray[4] = annotateShapesArray[2];
            this.setState({
                isFreeDrawing: false,
                isRectActive: true,
                isCircleActive: false,
                isArrowActive: false
            }, () => {
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
            });
        }
    }
    onClickCircleIcon = () => {
        const { isRectActive, isCircleActive, isFreeDrawing } = this.state;
        if (isCircleActive === false) {
            annotateShapesArray[4] = annotateShapesArray[1];
            this.setState({
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: true,
                isArrowActive: false
            }, () => {
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
            });
        }
    }
    onClickCrossHairsIcon = () => {
        const { isRectActive, isCircleActive, isFreeDrawing } = this.state;
        if (isFreeDrawing === false) {
            annotateShapesArray[4] = annotateShapesArray[0];
            this.setState({
                isFreeDrawing: true,
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false
            }, () => {
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
            });
        }
    }

    onClickArrowIcon = () => {
        const { isRectActive, isCircleActive, isFreeDrawing, isArrowActive } = this.state;
        if (isArrowActive === false) {
            annotateShapesArray[4] = annotateShapesArray[3];
            this.setState({
                isArrowActive: true,
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: false
            }, () => {
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
            });
        }
    }

    onClickShape = () => {
        const { bSelectShape } = this.state;
        const shapeselector = document.getElementById('shapeselector');
        const sketchPicker = document.getElementById('sketchPicker');
        if (bSelectShape === true) {
            this.setState({ bSelectShape: false });
            shapeselector.style.display = 'none';
        } else {
            this.setState({
                bSelectColor: false,
                bSelectShape: true
            });
            sketchPicker.style.display = 'none';
            shapeselector.style.display = 'block';
        }
    }

    //NS2-217
    onClickPenIcon = () => {
        const { bSelectColor } = this.state;
        const sketchPicker = document.getElementById('sketchPicker');
        const shapeselector = document.getElementById('shapeselector');
        if (bSelectColor === true) {
            this.setState({ bSelectColor: false });
            sketchPicker.style.display = 'none';
        } else {
            this.setState({
                bSelectColor: true,
                bSelectShape: false
            });
            shapeselector.style.display = 'none';
            sketchPicker.style.display = 'block';
        }
    }

    turnOnAnnotate = (bRemoteCap, urlObj) => {
        //const controlpanel = document.getElementById('controlpanel');
        let sketchPicker, shapeselector;
        if (this.state.bHideAnnotateColour === false) {
            sketchPicker = document.getElementById('sketchPicker');
        }
        if (this.state.bHideAnnotateShapes === false) {
            shapeselector = document.getElementById('shapeselector');
        }
        if (urlObj !== null && this.state.bAnnotate === true)
            return; // avoid dupes
        if (this.state.bAnnotate === true)
            this.setState({ bAnnotate: false }, () => {
                if (document.getElementById('canvas') === null) {
                    printConsole('There is no window to handle annotation!!');
                    return;
                } else {
                    // NS2-217
                    if (this.state.bHideAnnotateColour === false)
                        sketchPicker.style.display = 'none';
                    if (this.state.bHideAnnotateShapes === false)
                        shapeselector.style.display = 'none';
                    this.setState({
                        bSelectColor: false,
                        bSelectShape: false,
                        pencolor: "red",
                        isFreeDrawing: true,
                        isRectActive: false,
                        isCircleActive: false,
                        isArrowActive: false
                    }, () => {
                        onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj);
                    });
                }
            });
        else {  //if(this.state.bAnnotate === false)
            annotateShapesArray[4] = annotateShapesArray[0];
            this.setState({ bAnnotate: true }, () => {
                // NS2-217                
                this.setState({
                    bSelectColor: false,
                    bSelectShape: false,
                    pencolor: "red",
                    isFreeDrawing: true,
                    isRectActive: false,
                    isCircleActive: false,
                    isArrowActive: false
                }, () => {
                    onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj, true);
                });
            });
        }

        //onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj);
    }

    onClickAnnotate = () => {
        let { bRemoteCapture, bAnnotate } = this.state;
        diagonastics(sessionObj.authService, {
            action: `Annotate button clicked`,
            next_step: '',
            data: `bRemoteCapture:${bRemoteCapture}, bAnnotate:${bAnnotate}`,
            error: 'none'
        })
        if (bRemoteCapture === true) {
            this.stopExpertRCAnnotate(); // NS2-345
            this.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
            this.setState({ bRemoteCapture: false });
            this.props.updateScreenShareMaxdiv(false); // NS2-376
            this.props.updateFileShareMaxdiv(false); // NS2-376
            this.setState({ sRCTechDetails: '' });
            if (this.state.bAnnotate === true)
                this.turnOnAnnotate(false, null);
            else
                this.setState({ bAnnotate: false });
        }
        else {
            this.turnOnAnnotate(false, null);
        }
    }

    onClickCamera = () => {
        let { bRemoteCapture, bAnnotate } = this.state;
        diagonastics(sessionObj.authService, {
            action: `RC button clicked`,
            next_step: '',
            data: `bRemoteCapture:${bRemoteCapture}, bAnnotate:${bAnnotate}`,
            error: 'none'
        })
        if (bRemoteCapture === true) {
            this.stopExpertRCAnnotate();// NS2-345
            this.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
            this.setState({ bRemoteCapture: false });
            this.setState({ bRCAnnotateOn: false });
            this.setState({ sRCTechDetails: '' });
            if (this.state.bAnnotate === true)
                this.turnOnAnnotate(false, null);
            else
                this.setState({ bAnnotate: false });
            this.props.updateRCStatus(false);
            this.props.updateScreenShareMaxdiv(false);// NS2-376    
            this.props.updateFileShareMaxdiv(false); // NS2-376
        }
        else {
            this.props.updateScreenShareMaxdiv(true);
            this.props.updateFileShareMaxdiv(true);
            this.props.updateRCStatus(true);
            g_subInfoArr.forEach(subInfo => {
                if (subInfo.g_divId === g_maxDivId) {
                    if (subInfo.canRC === true) {
                        this.setState({ bRemoteCapture: true });
                        this.setState({ bRCAnnotateOn: true });
                        this.sendSignalExpertRemoteClick('clickremoteandsend', null);// for NS2-345
                        this.remoteClick('clickremoteandsend', document.getElementById(g_maxDivId));
                    }
                }
            })
        }
    }

    remoteClick = (msgToSend, elemId) => {
        // send this message out
        if (g_session !== null && g_subInfoArr.length > 0) {
            g_subInfoArr.forEach(subInfo => {
                if (elemId === null || subInfo.g_divId === elemId.id) {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    sessionObj.setState({ isLoading: true });
                    //sessionObj.showLoader('Capturing...');

                    //printConsole('divId matched up');
                    this.sessionSignal.sendSignalRemoteCaptureClick(

                        //from: g_session.connection.data,
                        msgToSend,
                        toObject
                        //type:"REMOTECLICK"                        
                    );
                    //printConsole("remote click signal sent...");                    
                    return;
                }
            })
        }

    }

    // for NS2-345
    sendSignalExpertRemoteClick = (msgToSend, connData) => {
        //send this message out to other experts
        if (g_session !== null && g_expertConnsArr.length > 0) {
            if (connData === null) {
                g_expertConnsArr.forEach(expertInfo => {
                    if (this.state.showHideMaxDivControl) {
                        const toArray = expertInfo.data.split(":::");
                        const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                        this.sessionSignal.sendSignalExpertRCClicked(

                            //from: g_session.connection.data,
                            msgToSend,
                            toObject
                            //type: "EXPERT_RC_CLICKED"                            
                        );
                        //printConsole("expert RC button click signal sent...");
                        return;
                    }
                })
            } else {
                if (this.state.showHideMaxDivControl) {
                    this.sessionSignal.sendSignalExpertRCClicked(

                        //from: g_session.connection.data,
                        msgToSend,
                        connData
                        //type: "EXPERT_RC_CLICKED"

                    );
                    //printConsole("expert RC button click signal sent...");
                    return;
                }
            }
        }
    }

    onClickUpload = () => {
        this.setState({ bShareAsset: (this.state.bShareAsset === true) ? false : true });
        diagonastics(sessionObj.authService, {
            action: `on click of max upload`,
            next_step: '',
            data: `bShareAsset:${this.state.bShareAsset}, bAnnotate:${this.state.bAnnotate}, bRemoteCapture:${this.state.bRemoteCapture},`,
            error: 'none'
        })
        if (this.state.bShareAsset) {
            this.setState({ bAnnotate: false });
            this.setState({ bRemoteCapture: false });
            this.setState({ sRCTechDetails: '' });
            this.props.updateScreenShareMaxdiv(false); // NS2-376
            this.props.updateFileShareMaxdiv(false); // NS2-376
        }
        this.onClickUploadFile(document.getElementById(g_maxDivId));
    }

    onClickMaxChat = () => {
        // if(this.state.bMaxChat === true) this.state.bMaxChat = false; //this.setState({bMaxChat: false});
        // else if(this.state.bMaxChat === false) this.state.bMaxChat = true; //this.setState({bMaxChat: true});
        diagonastics(sessionObj.authService, {
            action: `on click of maxchat`,
            next_step: '',
            data: `bMaxChat:${this.state.bMaxChat}`,
            error: 'none'
        })
        this.setState((prevState) => ({
            bMaxChat: !prevState.bMaxChat
        }), () => {
            let flg = false;
            if (this.state.bMaxChat) {
                if (this.state.bRemoteCapture === true) this.onClickCamera();
                else if (this.state.bAnnotate === true) this.onClickAnnotate();// NS2-333
                if (this.state.bAllChat === true) {
                    // while resetting the chat all flag we need to reset the header
                    // bar togglechat also and change the state of chatuseremails,
                    // chatusernames
                    this.setState({
                        bAllChat: false,
                        chatUserEmails: [],
                        chatUserNames: []
                    });
                    this.props.onSessionCmdChange('session_chat_off');
                    this.props.updateChatAll(false);
                }
            }
            this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null, flg);
        });
    }

    onClickMaxMute = () => {
        this.setState({ bRemoteMute: (this.state.bRemoteMute === true) ? false : true }, () => {

            // mute max user
            let nLen = g_subInfoArr.length;
            let ii = 0;
            for (ii = 0; ii < nLen; ii++) {
                if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                    if (this.state.bRemoteMute)
                        g_subInfoArr[ii].g_subscriber.subscribeToAudio(false); //audio off
                    else
                        g_subInfoArr[ii].g_subscriber.subscribeToAudio(true); // audio on
                }
            }
        });

    }

    sendChatMsg = (msgToSend, elemId) => {
        // save message in list
        let timeStamp = sessionObj.getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: sessionObj.state.sessionInfo.firstname,
            lastName: sessionObj.state.sessionInfo.lastname, message: msgToSend, timeStamp: timeStamp, msgType: 1
        };

        sessionObj.state.chatMsgInfoArr.push(chatMsgInfo);
        this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
        let sixdigitsrandom = 0; //MB2-95

        // send this message out
        // NS2-347
        if (this.state.sessionInfo.role !== 'publisher' && g_session !== null && elemId !== null && g_subInfoArr.length > 0) {
            sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
            g_subInfoArr.forEach(subInfo => {
                if (subInfo.g_divId === elemId) {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    this.sessionSignal.sendSignalChat(

                        //from: g_session.connection.data,
                        sixdigitsrandom, //MB2-95
                        msgToSend,
                        toObject
                        //type:"IM"

                    );
                    //printConsole("signal sent.");
                    return;
                }
            })
        }
        else if (g_session !== null && elemId !== null && g_pubConnsArr.length > 0) {
            // send to all subscribers ---- 
            sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); // MB2-95
            g_pubConnsArr.forEach(connectionObj => {
                if (g_session.connection !== connectionObj) {
                    const toArray = connectionObj.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    this.sessionSignal.sendSignalChat(

                        //from: g_session.connection.data,
                        sixdigitsrandom, //MB2-95
                        msgToSend,
                        toObject
                        //type:"IM"

                        //printConsole('signal sent - error = ', error);
                    );
                    return;
                }
            })
        }
        else if (g_session !== null && elemId === null) {
            sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); // MB2-95
            this.sessionSignal.sendSignalChat(

                //from: g_session.connection.data,
                sixdigitsrandom,  // MB2-95
                msgToSend
                //type:"IM"

            );
            //printConsole("signal sent.");
        }
        return;
    }


    showLoader = (text) => {
        if (!this.state.isLoading) return '';
        return (
            <div className="loader">
                <span className="d-flex flex-column justify-content-center align-items-center">
                    <i className="fas fa-spin fa-circle-notch fa-2" aria-hidden="true"></i>
                    <Trans id={text}></Trans>
                </span>
            </div>
        )
    }

    send3dRotation = (rotationsObj) => {
        if (g_session !== null && g_subInfoArr.length > 0) {
            g_subInfoArr.forEach(subInfo => {
                // if (subInfo.g_divId === elemId) {
                const toArray = subInfo.g_stream.connection.data.split(":::");
                const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                this.sessionSignal.sendSignal3DRotation(
                    //from: g_session.connection.data,
                    rotationsObj,
                    toObject,
                    //type: "ROTATION"

                );
                //printConsole("signal sent.");
                return;
                // }
            })
        }
    }

    getSceneManager = () => {
        const closeIconText = this.props.i18n._('Click here to remove the 3d object');
        const paramElem = document.getElementById(g_maxDivId);

        if (!paramElem) return;

        let paramElemVdoChild;
        if (isDivExpert(g_maxDivId)) {
            [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-poster');
        } else {
            [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-element');
        }
        if (!paramElemVdoChild) return;

        const { sceneManager } = threeEntryPoint({
            videoElement: paramElemVdoChild,
            id: 'canavas-3d-container',
            fnSend3dRotation: this.send3dRotation,
            fnClose3dCanvas: this.onCloseFileViewer,
            closeIconText
        });

        return sceneManager;
    }

    async render3dModelPublisher(filesArr, s3Files) {
        try {
            const [mtlFile] = filesArr.filter(e => e.name.endsWith('.mtl'));
            const [objFile] = filesArr.filter(e => e.name.endsWith('.obj') || e.name.endsWith('.3ds') || e.name.endsWith('.3DS') || e.name.endsWith('.FBX') || e.name.endsWith('.fbx'));
            diagonastics(sessionObj.authService, {
                action: `rendering 3d model for publisher`,
                next_step: '',
                data: `filesArr:${JSON.stringify(filesArr)}, s3Files:${JSON.stringify(s3Files)}`,
                error: 'none'
            })
            if (!objFile) {
                const [file] = filesArr;
                const splitedArray = file.name.split('.');
                const fileUrl = URL.createObjectURL(file);
                this.setState({
                    showFileViewer: true,
                    fileType: splitedArray[splitedArray.length - 1],
                    filePath: fileUrl,
                    fileName: file.name,
                    expertLoggedInId: this.props.loggedInUserId
                    // Using this we can identify which expert was uploaded the file
                });
                this.setState(prev => ({
                    URLs: [
                        ...prev.URLs,
                        fileUrl
                    ]
                }));
                return;
            }
            const textureFiles = s3Files.filter(e => e.key.endsWith('.jpg') || e.key.endsWith('.png') || e.key.endsWith('.BMP') || e.key.endsWith('.bmp') | e.key.endsWith('.exr') || e.key.endsWith('.tga'));
            const sceneManager = this.getSceneManager();
            if (!sceneManager) return

            // create object URL from the file object
            const objectURL = URL.createObjectURL(objFile);
            const mtlURL = mtlFile ? URL.createObjectURL(mtlFile) : undefined;
            const textureURLs = map(textureFiles, 'location'); //textureFiles ? textureFiles.map(e => URL.createObjectURL(e)) : [];
            this.setState(prev => ({
                isLoading: true,
                URLs: [
                    ...prev.URLs,
                    objectURL,
                    mtlURL,
                ],
                expertLoggedInId: this.props.loggedInUserId
            }));
            const fileType = objFile.name.split('.').pop();
            await sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlURL, objFilePath: objectURL, textureFilePath: textureURLs })
            this.setState({ isLoading: false });
            // NS2-366 Initialise the event listner to close the canvas when clicked outside
            this.trackClickOn3dCanvas();
        } catch (error) {
            let msg = i18nMark('Something went wrong. Please try again later.');
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.props.updateFileShare(false);
            this.setState({ bShareAsset: false });// NS2-457
            this.setState({ isLoading: false, error: msg });
        }
    }

    renderFileFromQrScanner = async (fileList) => {
        try {
            const [mtlFile] = fileList.filter(e => e.endsWith('.mtl'));
            const [objFile] = fileList.filter(e => e.endsWith('.obj') || e.endsWith('.3ds') || e.endsWith('.3DS') || e.endsWith('.FBX') || e.endsWith('.fbx'));
            const textureFiles = fileList.filter(e => e.endsWith('.jpg') || e.endsWith('.png') || e.endsWith('.BMP') || e.endsWith('.bmp') | e.endsWith('.exr') || e.endsWith('.tga'));
            if (!objFile) {
                const [fileUrl] = fileList;
                const fileType = fileUrl.split('.').pop();
                const fileName = fileUrl.split('/').pop();
                this.setState({
                    showFileViewer: true,
                    fileType: fileType,
                    filePath: fileUrl,
                    fileName: fileName,
                    expertLoggedInId: this.props.loggedInUserId
                    // Using this we can identify which expert was uploaded the file
                });
                this.setState(prev => ({
                    URLs: [
                        ...prev.URLs,
                        fileUrl
                    ]
                }));
                return;
            }
            const sceneManager = this.getSceneManager();
            if (!sceneManager) return;

            // create object URL from the file object
            this.setState(prev => ({
                isLoading: true,
                URLs: [
                    ...prev.URLs,
                    objFile,
                    mtlFile,
                ],
                expertLoggedInId: this.props.loggedInUserId
            }));
            const fileType = objFile.split('.').pop();
            await sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlFile, objFilePath: objFile, textureFilePath: textureFiles })
            this.setState({ isLoading: false });
        } catch (error) {
            let msg = i18nMark('Something went wrong. Please try again later.');
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false, error: msg });
        }
    }

    onClickUploadFile = (paramElem) => {
        fileDialog({ multiple: true, accept: '.xlsx, .mp3, .mp4, .csv, .mov, .jpg, .pdf, .png, .obj, .mtl, .docx, .3ds, .bmp, .tga, .exr, .fbx' })
            .then(files => {
                this.setState({ shareOnDiv: paramElem });
                if (paramElem === null) {
                    this.props.updateFileShare(true);
                    this.setState({ bShareAsset: true });//NS2-457
                }
                const fileArr = Object.values(files);
                // const fileSizeExceed = fileArr.filter(e => (e.size / 1048576) > 40 );
                // if (fileSizeExceed.length) {
                //     this.setState({ error: 'File size should be below 40 MB' });
                //     this.props.updateFileShare(false);
                //     return;
                // }
                this.setState({ isLoading: true });
                const uploadedFiles = [];
                for (const file of fileArr) {
                    const data = new FormData();
                    data.append('file', file);
                    this.setState(prevState => ({
                        fileNameArr: [...prevState.fileNameArr, file.name]
                    }));
                    // push selected file to server
                    uploadFile(file, this.config)
                        .then(retdata => {
                            uploadedFiles.push({
                                ...retdata,
                                loggedInUserId: this.props.loggedInUserId
                            });
                            // let expert send the selected file out to remote users
                            if (uploadedFiles.length === fileArr.length) {
                                this.setState({ isLoading: false });
                                this.render3dModelPublisher(fileArr, uploadedFiles);
                                this.sendAttachment(JSON.stringify(uploadedFiles), paramElem);
                            }
                        })
                        .catch(err => {
                            this.props.updateFileShare(false);
                            this.setState({ bShareAsset: false });//NS2-457
                            this.setState({ isLoading: false });
                            console.error(err);
                        })
                    //if(this.state.bShareAsset === true) this.setState({bShareAsset: false});//NS2-457
                }
            })
    }

    sendAttachment = (msgToSend, elemId) => {
        //printConsole('sendAttachment called with: ', msgToSend, elemId, g_subInfoArr, g_session, g_pubConnsArr);
        // send this message out
        if (g_session !== null && g_subInfoArr.length > 0) {
            g_subInfoArr.forEach(subInfo => {
                if (!elemId || subInfo.g_divId === elemId.id) {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                    this.sessionSignal.sendSignalArtifacts(
                        //from: g_session.connection.data,
                        msgToSend,
                        toObject
                        //type: "ARTIFACTS"

                    );
                    //printConsole("artifacts signal sent...");
                    return;
                }
            })
        }
    }

    handleArtifactsOnSubscribers = ({ data, paramElem, isExpertDiv }) => {
        const closeIconText = this.props.i18n._('Click here to remove the 3d object')
        try {
            const [objFile] = data.filter(e => e.key.endsWith('.obj') || e.key.endsWith('.3ds') || e.key.endsWith('.3DS') || e.key.endsWith('.FBX') || e.key.endsWith('.fbx'));
            const [mtlFile = {}] = data.filter(e => e.key.endsWith('.mtl'));
            const textureFiles = data.filter(e => e.key.endsWith('.jpg') || e.key.endsWith('.png') || e.key.endsWith('.BMP') || e.key.endsWith('.bmp') | e.key.endsWith('.exr') || e.key.endsWith('.tga'));

            if (objFile) {
                this.setState({ isLoading: true });
                if (!paramElem) return;
                let paramElemVdoChild;
                if (isExpertDiv) {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-poster');
                } else {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-element');
                }

                if (!paramElemVdoChild) return;

                const { sceneManager } = threeEntryPoint({
                    videoElement: paramElemVdoChild,
                    id: 'canavas-3d-container',
                    fnSend3dRotation: () => { },
                    fnClose3dCanvas: () => { },
                    closeIconText
                });
                const fileType = objFile.key.split('.').pop();
                sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlFile.location, objFilePath: objFile.location, textureFilePath: map(textureFiles, 'location') })
                    .then(e => {
                        this.setState({ isLoading: false });
                        this.active3dModel = e;
                    })
                    .catch(err => {
                        this.setState({ isLoading: false });
                        console.warn(err)
                    });
                this.closeSubscriber3dObject = sceneManager.removeCanvas;
            } else {
                const [file] = data;
                this.closeSubscriber3dObject = null;
                const splitedArray = file.key.split('.');
                this.setState({
                    showFileViewer: true,
                    fileType: splitedArray[splitedArray.length - 1],
                    filePath: file.location,
                    fileName: file.key.replace('boo/', ''),
                    expertLoggedInId: file.loggedInUserId
                });
            }
        } catch (error) {
            let msg = 'Something went wrong while uploading file.';
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false, error: msg });
        }
    }

    onCloseFileViewer = (e) => {
        if (e) e.preventDefault();
        let { role } = this.state.sessionInfo
        let files = this.state.fileNameArr;
        this.state.URLs.forEach(url => {
            if (url) window.URL.revokeObjectURL(url);
        });
        this.setState(prev => ({
            showFileViewer: false,
            fileType: 'none',
            filePath: '',
            fileName: '',
            // expertLoggedInId: null
        }), () => {
            if (role != "publisher") {
                if (this.state.bShareAsset === true) {
                    this.setState({ bShareAsset: false })
                }
                if (this.state.shareOnDiv === null) {
                    this.props.updateFileShare(false);
                    this.setState({ bShareAsset: false });//NS2-457
                }
                this.deleteS3Files(files);
                // remove the event listner of 3d canvas which closes it when clicked outside //Ns2-366
                this.remove3dCanvasListner();
            }
        });
        if (g_session !== null && g_subInfoArr.length > 0 && this.props.loggedInUserId === this.state.expertLoggedInId) {

            g_subInfoArr.forEach(subInfo => {
                const toArray = subInfo.g_stream.connection.data.split(":::");
                const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                this.sessionSignal.sendSignalRemoveArtifacts(

                    //from: g_session.connection.data,
                    true,
                    toObject
                    //type: "REMOVE_ARTIFACTS"

                );
                //printConsole("artifacts signal sent...");
                return;
            });
        }
    }

    deleteS3Files = (files) => {
        deleteFile(files, this.config)
            .then(response => {
                // printConsole(response);
                this.setState({
                    fileNameArr: []
                });
            })
            .catch(err => {
                // console.error(err)
            })

    }

    onClickCloseErrorModal = (e) => {
        e.preventDefault();
        this.setState({ error: false });
    }

    onClickScanQRScanner = () => {
        this.setState((prev) => ({
            isOpenQRScanner: !prev.isOpenQRScanner
        }))
    }

    sendAttachmentBesedOnQRCode = ({ qrObj, type, isOneToOne, renderExpert }) => {
        const fileList = qrObj[type].constructor === Array ? qrObj[type] : [qrObj[type]];
        if (renderExpert) this.renderFileFromQrScanner(fileList);
        const artifacts = fileList.map(e => {
            return { key: e.split('/').pop(), location: e }
        });
        let paramElem;
        if (isOneToOne) {
            paramElem = document.getElementById(g_maxDivId);
        }
        this.sendAttachment(JSON.stringify(artifacts), paramElem);
    }

    handleQrScan = (e) => {
        if (e) {
            try {
                const qrObj = typeof e === 'object' ? e : JSON.parse(e);
                if (!qrObj['render3D'] && !qrObj['open2D']) {
                    this.setState({ isLoading: false, error: 'Not supported!' });
                    this.onClickScanQRScanner();
                    this.onCloseFileViewer();
                    return;
                }
                /** open2D for 2d assets
                  *  render3D for 3d assets
                   */
                if (qrObj['open2D']) {
                    this.sendAttachmentBesedOnQRCode({ qrObj, type: 'open2D', isOneToOne: true, renderExpert: true });
                } else if (qrObj['render3D']) {
                    this.sendAttachmentBesedOnQRCode({ qrObj, type: 'render3D', isOneToOne: true, renderExpert: true });
                }
                /*else if (qrObj['render3Dall']) {
                        this.sendAttachmentBesedOnQRCode({ qrObj, type: 'render3Dall', isOneToOne: false, renderExpert: true });
                    } else if (qrObj['render3Dexpert']) {
                        const fileList = qrObj['render3Dexpert'].constructor === Array ? qrObj['render3Dexpert']: [qrObj['render3Dexpert']];
                        this.renderFileFromQrScanner(fileList);
                    } else if(qrObj['render3Dtechnician']) {
                        this.sendAttachmentBesedOnQRCode({ qrObj, type: 'render3Dtechnician', isOneToOne: true, renderExpert: false });
                    } else if (qrObj['render3D'] || qrObj['render3Dboth']) {
                        const renderType = qrObj['render3D'] ? 'render3D' : 'render3Dboth';
                        this.sendAttachmentBesedOnQRCode({ qrObj, type: renderType, isOneToOne: true, renderExpert: true });
                    }*/
            } catch {
                this.setState({ isLoading: false, error: 'Not supported!' });
                this.onCloseFileViewer();
            }
            this.onClickScanQRScanner();
        }
    }


    handleQRScannerError = (e) => {
        if (!e) return;
        console.warn(e)
    }

    renderQrScannerModal = () => {
        return <Modal size="md" isOpen={true} toggle={this.onClickScanQRScanner}>
            <ModalHeader toggle={this.onClickScanQRScanner}><Trans>Scan a QR code</Trans></ModalHeader>
            <ModalBody>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <QrReader
                        delay={500}
                        onError={this.handleQRScannerError}
                        onScan={this.handleQrScan}
                        style={{ width: '300px' }}
                    />
                </div>
            </ModalBody>
        </Modal>
    }

    closeMikeDialog = () => {
        this.setState({ openMikeDialog: false });
    }

    closeStreamDestroyedDialog = () => {
        this.setState({
            openStreamDestroyedDialog: false,
            imageCaptureTimer: 0
        }, () => {
            clearInterval(this.streamTimer);
        });
    }

    decrementCounter = () => {
        this.setState((prevState) => ({
            imageCaptureTimer: prevState.imageCaptureTimer - 1
        }), () => {
            if (this.state.imageCaptureTimer === 0) {
                this.closeStreamDestroyedDialog();
            }
        });
    }

    muteUnmuteMaxDiv = () => {
        this.setState(prevState => ({
            openMikeDialog: false,
            muteMikeForMaxDiv: !prevState.muteMikeForMaxDiv
        }), () => {
            this.muteExpertMikeInOutMaxDiv(!this.state.muteMikeForMaxDiv);
        });
    }

    hideRequestDialog = () => {
        this.setState({
            showControlRequestDialog: false,
            requestDialogTimer: 0
        }, () => {
            clearInterval(this.streamTimer);
        });
    }

    decrementCountr = () => {
        this.setState((prevState) => ({
            requestDialogTimer: prevState.requestDialogTimer - 1
        }), () => {
            if (this.state.requestDialogTimer === 0) {
                this.onClickAcceptMaxDivControl();
            }
        });
    }

    showHideAlertDialog = () => {
        this.setState({ showAlertDialog: !this.state.showAlertDialog })
    }

    alertDialogBox = () => {
        return (
            <>
                <Modal size="sm" isOpen={this.state.showAlertDialog} toggle={this.showHideAlertDialog}>
                    <ModalHeader toggle={this.showHideAlertDialog} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Process Error</Trans></ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center">
                        {this.state.msgID ?
                            <Trans id={this.state.msgID}></Trans>
                            : ''
                        }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <Button size="sm" className="mr-1 btn-g" onClick={this.showHideAlertDialog}><Trans>Ok</Trans></Button>
                        </div>
                    </ModalFooter>
                </Modal>

            </>
        )
    }

    requestMaxDivControlDialog = () => {
        // NS2-128 
        // changed the toggle function from this.hideRequestDialog to this.onClickDenyMaxDivControl
        // This is to ensure that even when clicked outside the Dialog box, the deny signal is sent
        // to the Expert who requested for Maxdiv Controls
        return (
            <>
                <Modal size="sm" isOpen={this.state.showControlRequestDialog} toggle={this.onClickDenyMaxDivControl}>
                    <ModalHeader toggle={this.onClickDenyMaxDivControl} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Control Request</Trans></ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center row">
                        <div className="col-sm-12">
                            {this.props.user.isexpert ?
                                <Trans id="Maxdiv Control" values={{ 'firstName': `${this.state.expertFirstName}`, 'lastName': `${this.state.expertLastName}` }}></Trans>
                                : ''
                            }
                        </div>
                        <div className="col-md-6 align-self-center" style={{ fontSize: "40px", fontWeight: "bold" }}>
                            {this.state.requestDialogTimer}
                        </div>

                        <audio autoPlay="true" loop="true" src="/beep.mp3" />
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <Button size="sm" className="mr-1 btn-r" onClick={this.onClickDenyMaxDivControl}><Trans>No</Trans></Button>
                            <Button size="sm" className=" btn-g" onClick={this.onClickAcceptMaxDivControl}><Trans>Yes</Trans></Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    // start recording NS2-274
    recordScreen = () => {
        this.setState((prevState) => ({
            startRecording: !prevState.startRecording
        }), async () => {
            if (this.state.startRecording) {
                await this.startRecording();
            } else {
                this.stopRecordingCallback();
            }
        })
    }

    startRecording = async () => {
        try {
            // calling the screen capture
            this.captureScreen((screen) => {
                this.screen = screen
                // calling the camera feed containing only the audio
                this.captureCamera((camera) => {
                    this.camera = camera
                    this.recorder = RecordRTC([screen, camera], {
                        type: 'video',
                        mimeType: 'video/webm',
                        video: { width: 1280, height: 720 }
                    });

                    this.recorder.startRecording();
                    this.setState({ showRecordingBeacon: true }) // show the beacon
                });
            });
        } catch (error) {
            console.error(error);
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording
            }))
        }
    }

    invokeGetDisplayMedia(success, error) {
        let displaymediastreamconstraints = {
            video: true
        };

        if (navigator.mediaDevices.getDisplayMedia) {
            navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
        } else {
            navigator.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
        }
    }

    captureCamera = (cb) => {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(cb);
    }

    captureScreen = (callback) => {
        this.invokeGetDisplayMedia((screen) => {
            // when screen capture is directly stopped by clicking on the popup then this execute  
            this.addStreamStopListener(screen, this.stopRecordingCallback);
            callback(screen);
        }, (error) => {
            console.error(error);
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording
            }))
        });
    }

    // recorder is stopped
    stopRecordingCallback = async () => {
        try {
            if (this.recorder) {
                this.recorder.stopRecording(() => {
                    let fileName = `Telepresenz_${this.getDateString()}`;
                    this.recorder.save(`${fileName}.webm`);

                    this.recorder.destroy();
                    this.recorder = null;

                    [this.screen, this.camera].forEach(function (stream) {
                        stream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                    });
                    // if instead of clicking on the icon user clicks the popup the state will
                    // change from here
                    if (this.state.startRecording) {
                        this.setState({ startRecording: false })
                    }
                    this.setState({ showRecordingBeacon: false })// hide the beacon
                });
            }
        } catch (error) {
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording
            }))
        }
    }

    addStreamStopListener = (stream, callback) => {
        stream.addEventListener('ended', function () {
            callback();
            callback = function () { };
        }, false);
        stream.addEventListener('inactive', function () {
            callback();
            callback = function () { };
        }, false);
        stream.getTracks().forEach(function (track) {
            track.addEventListener('ended', function () {
                callback();
                callback = function () { };
            }, false);
            track.addEventListener('inactive', function () {
                callback();
                callback = function () { };
            }, false);
        });
    }

    getDateString = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${year}${month}${day}_${hour}${minutes}${seconds}`
    }

    // Take screen shot of the canvas of remote capture NS2-367
    takeScreenShot = () => {
        this.setState({ screenCaptureClicked: true }, () => {
            const canvas = document.getElementById('canvas');
            if (canvas) {
                const dataURL = canvas.toDataURL('image/jpeg', 1.0);
                const fileName = `Telepresenz_Rc_${this.getDateString()}`;
                const element = document.createElement('a');
                element.setAttribute('href', dataURL);
                element.setAttribute('download', fileName);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);

                this.setState({ screenCaptureClicked: false })
            }
        })
    }

    processTooltip = (status, i18n) => {
        switch (status) {
            case "session.freehand":
                return i18n._(t`session.freehand`);
            case "session.circle":
                return i18n._(t`session.circle`);
            case "session.square":
                return i18n._(t`session.square`);
            case "session.arrow":
                return i18n._(t`session.arrow`);
            default:
                break;
        }
    }

    // NS2-425 to ensure session id is cleared
    clearSessionToken = () => {
        try {
            if (this.authService !== undefined) {
                this.authService.fetch('clearSessionToken', {
                    method: 'post',
                    body: JSON.stringify({
                        groupId: this.props.selectedGroupId
                    })
                })
                    .then(response => {
                        response.json()
                    })
                    .then(data => {
                        printConsole('session cleared when last expert is leaving')
                        diagnostics(this.props.authService,{
                            action: `clearSessionToken`,
                            sequence: 3,
                            next_step: '',
                            data: `{ groupId: ${this.props.selectedGroupId} }`,
                            error: 'none'
                        }) 
                    })
                    .catch(err => console.log(err.message))
            }
            
        } catch (error) {
            console.log("Error in clearSessionToken call ", error);
        }
    }

    updateAudioPublisher = (val) => {
        g_camera_publisher && g_camera_publisher.publishAudio(val);
    }
    

    render() {
        if (this.state.bRefresh)
            this.initializeOpentok();

        if (this.state.sessionInfo === undefined || this.state.sessionInfo === null)
            return (<div>Loading AV cards...</div>);
        let currWidth = window.innerWidth;
        let currHeight = window.innerHeight - headerSize;
        let annotateStyle = { color: 'black' };
        if (this.state.bAnnotate) annotateStyle = { color: 'red' };
        let shareStyle = { color: 'black' };
        if (this.state.bShareAsset) shareStyle = { color: 'red' };
        let cameraStyle = { color: 'black' };
        if (this.state.bRemoteCapture) cameraStyle = { color: 'red' };
        let chatStyle = { color: 'black' };
        if (this.state.bMaxChat) chatStyle = { color: 'red' };
        let qrScanStyle = { color: 'black' };
        if (this.state.isOpenQRScanner) qrScanStyle = { color: 'red' };
        let muteStyle = { color: 'black' };
        if (this.state.bRemoteMute) muteStyle = { color: 'red' };
        let styleDivMaxBtnContainer = { position: 'absolute', left: '25%', top: '90%', backgroundColor: 'transparent' };
        if (g_subInfoArr.length === 1) styleDivMaxBtnContainer = { position: 'absolute', left: '25%', top: '90%', backgroundColor: 'transparent' };
        let maxDivMuteMicColor = this.state.muteMikeForMaxDiv ? 'red' : 'black';
        let techShareStyle = { color: 'black' };
        if (this.state.techShareScreen) techShareStyle = { color: 'red' };
        let expertShareStyle = { color: 'black' };
        if (this.state.expertShareScreenMaxDiv) expertShareStyle = { color: 'red' };
        let getExpertControlStyle = { color: 'black' };
        if (this.state.clickHandSignal === true) getExpertControlStyle = { color: 'red' };
        else if (this.state.clickHandSignal === false) getExpertControlStyle = { color: 'black' };
        let zoomStyle = { color: 'black' };
        if (this.state.bZoomClick) zoomStyle = { color: 'red' };
        let torchStyle = { color: 'black' };
        if (this.state.bTorchClick) {
            torchStyle = { color: 'red' };
        }
        let penStyle = { color: this.state.pencolor }; //'red'
        //let shapeStyle = { color: 'black' };
        let shapeStyle = { color: this.state.pencolor };
        let crossStyle = { color: 'black' };
        if (this.state.isFreeDrawing) crossStyle = { color: this.state.pencolor };
        let circleStyle = { color: 'black' };
        if (this.state.isCircleActive) circleStyle = { color: this.state.pencolor };
        let rectStyle = { color: 'black' };
        if (this.state.isRectActive) rectStyle = { color: this.state.pencolor };
        let arrowStyle = { color: 'black' };
        if (this.state.isArrowActive) arrowStyle = { color: this.state.pencolor };
        // NS2-274
        let maxDivRecordScreenColor = this.state.startRecording ? 'red' : 'black';
        let maxDivRecordScreenIcon = this.state.startRecording ? 'fa-stop-circle' : 'fa-dot-circle';
        // NS2-367
        let captureScreenClass = this.state.screenCaptureClicked ? 'no-pointer-events disable-image' : '';
        if (this.props.user.isexpert !== true && this.state.sessionInfo.APIKey !== "") {            
            return (
                <>
                    <OTTechnician 
                        {...this.props}
                        {...this.state}
                        sessionInfo={this.state.sessionInfo}
                        g_subInfoArr={g_subInfoArr}
                        g_publisher={g_publisher}
                        g_camera_publisher={g_camera_publisher}
                        g_session={g_session}
                        g_pubConnsArr={g_pubConnsArr}
                        g_expertConnsArr={g_expertConnsArr}
                        getTimeStamp={this.getTimeStamp}
                        updateAudioPublisher={this.updateAudioPublisher}
                        updateScreenShareState={this.updateScreenShareState}
                        updatePublishers={this.updatePublishers}
                        updatePublishVideo={this.updatePublishVideo}
                        updateTriggerSwapCamera={this.updateTriggerSwapCamera}
                        updateCameraCount={this.updateCameraCount}
                        updateCameraSwapEventType={this.updateCameraSwapEventType}
                    />
                </>
            );
        }
        else if(this.props.user.isexpert === true && this.state.sessionInfo.APIKey !== "")  {
            return (
                <>
                    <OTExpert 
                        {...this.props}
                        sessionInfo={this.state.sessionInfo}
                        bSessionStarted={this.state.bSessionStarted}
                        startTimer={this.state.startTimer}
                        downLoadSpeed={this.state.downLoadSpeed}
                        uploadSpeed={this.state.uploadSpeed}
                        bShareAll={this.state.bShareAll}
                        sendSyncExperts={this.state.sendSyncExperts}
                        getTimeStamp={this.getTimeStamp}                         
                        clearSessionToken={this.clearSessionToken}
                        closeStreamDestroyedDialog={this.closeStreamDestroyedDialog}
                        setPrimaryExpertIDApi={this.setPrimaryExpertIDApi}                      
                        updateSessionTimer={this.updateSessionTimer}
                    />
                </>
            );
        }
        else
            return (
                <div className='flex-container' id='flex-container' style={{ backgroundColor: '#1c2238' }}>
                    <div id="sessionTimer" className="position-absolute nameDiv">
                        <SessionTimer
                            credit_limits={this.state.sessionInfo.credit_limits}
                            startTimer={this.state.startTimer}
                        />
                    </div>
                    <span style={{ color: `white` }} className={`float-left pr-2 pt-1 ${this.state.showRecordingBeacon ? '' : 'd-none'}`}>
                        REC&nbsp;&nbsp;<span style={{ fontSize: `1em`, color: `red` }}><i className="fas fa-circle Blink"></i></span>
                    </span>
                    <div id='maxdivcontrols' style={styleDivMaxBtnContainer}>
                    </div>
                    {this.streamDestroyedDialog()}
                </div>
            );
    }
}

export default Session;