import React from 'react';
import { printConsole } from '../helpers';
import ReactPaginate from 'react-paginate';
import { Modal, ModalBody } from 'reactstrap';
import SessionIcon from '../Session/SessionIcon.jsx';
import './Recording.css';

class Recordings extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            recordings: [],
            showRecording: false,
            selected_url: ""
        }
    }

    componentWillMount = () => {
        //TP-2285 -- To resize the 
        window.addEventListener("resize", this.updateDimensions);        
    }

    componentDidUpdate = (prevProps, prevStatate) => {
        if (this.props.recordings !== this.state.recordings) {
            this.setState({ recordings: this.props.recordings});
        }
    }

    componentWillUnmount = () => {
        this.setState({ recordings: [] }, () => {
            this.props.setRecordingsArray({recordings: null});
        });
        window.removeEventListener("resize", this.updateDimensions); //TP-2285
    }

    // TP-2285 -- To resize the the Video file viewer whenever
    // the window gets resized
    updateDimensions = () => {
        if (this.state.showRecording) {
            const {selected_url} = this.state;
            this.closePlayer();
            this.openPlayer(selected_url);
        }
    }

    getGridViewDim = (divCount) => {
        const headerBar = document.getElementById("header_bar");
        if (headerBar === null) return [0, 0]; //TP-1992
        const tabNameElem = document.getElementById("UsageDetailsTab");
        const sideBarWidth = 80;
        const Height = Math.floor(window.innerHeight - headerBar.style.height - tabNameElem.offsetHeight - 300);  
        const Width = window.innerWidth;

        let numRows, numCells, newVdoheight, newVdowidth;
        if(divCount && divCount > 1){
            if (divCount == 2) {
                numRows = 1;
                numCells = 2;                
            }else if(divCount <= 6 && divCount > 2){
                if (divCount <= 4){
                    numRows = 2;
                    numCells = 2;
                }else{
                    numRows = 2;
                    numCells = 3;                    
                }
            }else if(divCount <= 12 && divCount > 6){
                if (divCount <= 9){
                    numRows = 3;
                    numCells = 3; 
                }else{
                    numRows = 3;
                    numCells = 4;
                }
            }else if(divCount > 12){
                numRows = 4;
                numCells = Math.ceil((divCount)/4);                
            }
            if (divCount === 2) {
                newVdowidth = this.getCellDim(numCells);
                newVdoheight = newVdowidth * (9/16);//this.getRowDim(numRows);
            } else {
                newVdoheight = this.getRowDim(numRows);
                newVdowidth = newVdoheight * (16/9);//this.getCellDim(numCells);
            }
        }else{
            numRows = 0;
            newVdoheight= Height;
            newVdowidth= newVdoheight * (16/9); /* Math.floor(Width - sideBarWidth); */
        }
        return [newVdoheight, newVdowidth];
    }

    getRowDim = (numRows) => {
        const headerBar = document.getElementById("header_bar");
        const tabNameElem = document.getElementById("UsageDetailsTab");
        //console.log(tabNameElem.offsetHeight);
        const Height = window.innerHeight - headerBar.style.height - tabNameElem.offsetHeight - 120;        
        const yGap = 10;
        const numPad = numRows + 1;

        let newVdoheight;
        newVdoheight = Math.floor((Height - (yGap * numPad))/numRows);

        return newVdoheight;
    }

    getCellDim = (numCells) => {
        const sideBarWidth = 80;
        const Width = window.innerWidth;
        const maxWidth = Math.floor(Width - sideBarWidth);
        const xGap = 6;
        const xMargin = 40;
        const numPad = numCells + 1;

        let newVdowidth = Math.floor((maxWidth - ((xGap + xMargin) * numPad))/numCells);

        return newVdowidth;
    }

    //TP-2206 -- Implementation of pop-up for Recording Video player
    openPlayer = (url) => {
        this.setState({ showRecording: true, selected_url: url }, () => {
            const modal = document.getElementById("recording-viewer");
            //this.autoplayVideo(modal);
        });
    }

    closePlayer = () => {
        this.setState({ showRecording: false, selected_url: "" }, () => {
            const modal = document.getElementById("recording-viewer");
            //this.stopVideo(modal);
        });
    }

    render = () => {
        const {recordings} = this.props;
        const { selected_url } = this.state;
        //printConsole(`Total no of recordings: ${recordings.length}`);
        if (recordings !== null && recordings !== undefined && recordings.length > 0) {            
            const [ newVdoheight, newVdowidth ] = this.getGridViewDim(recordings.length);// dimension of the grid
            //TP-2206 -- UI handling for new mp4 Video player window
            let bodyHeight = window.innerHeight - (10 * (window.innerHeight/100));
            let bodyWidth = window.innerWidth - (10 * (window.innerWidth/100));
            const bodyStyle = {height: bodyHeight + 'px', maxWidth: bodyWidth + 'px'};
            let videoHeight = bodyHeight - 70;
            let videoWidth = (videoHeight*16)/9;
            let marginTop = Math.floor((bodyHeight - videoHeight)/4) //TP-2285
            let marginLeft = Math.floor((bodyWidth - videoWidth)/2);//TP-2285
            //min-vh-100 no-gutters
            return (
                <div className='w-100 p-2 row justify-content-center align-items-center' height={recordings.length === 1 ? newVdoheight+"px" : ""} style={recordings.length === 1 ? { position: "fixed", marginTop: "5%"}: recordings.length === 2 ? {position: "fixed", marginTop: "8%"}: {}}>
                {
                    recordings.map((record, index) => {
                        return (
                            <div key={index} className="recording-container"> 
                                <a href="javascript:void(0)" onClick={() => this.openPlayer(record.location)}> 
                                    <video className="video-tile" width={newVdowidth} height={newVdoheight} controls style={recordings.length === 1 ? { position: "relative", marginLeft: "40px" } : { position: "relative", marginLeft: "80px"/* , objectFit: "cover" */}} controls="controls" preload="metadata">
                                        <source src={record.location+"#t=0.1"} type="video/mp4"/>
                                    </video>
                                    <div className="play-button hide-button">
                                        <span className="fa-stack fa-2x">
                                            <i className="fas fa-circle fa-stack-2x" style={{ color: "rgba(179, 169, 174, 0.44)" }}></i>                
                                            <i className="fas fa-play fa-stack-1x" style={{color: "black"}}></i>
                                        </span>
                                    </div>
                                </a>
                                {/* <div className="play-button"> */}
                                {/* </div> */}
                                { recordings.length > 1 ?
                                    <div className="recording-label">Recording {index+1}</div>
                                    :
                                    ""
                                }
                            </div>
                        )
                    })
                }
                <Modal id="recording-viewer" isOpen={this.state.showRecording} style={bodyStyle}>
                    <ModalBody className="" style={bodyStyle}>
                        <div className="position-absolute" style={{ zIndex: '1060', right: "5%", /*left: closeLeft, */ top: "5%" }}>
                            <span className={(window.innerWidth > 1299) ? "fa-stack fa-lg close-modal ": "fa-stack fa-sm close-modal "}
                                >
                                <SessionIcon id="closePlayer" circleClass="fas fa-circle fa-stack-2x"
                                    iconClass="fas fa-times fa-stack-1x close-file-icon" tooltipPlament="top"
                                    innerClass="tip-max no-pointer-events" tooltip="Close Player" tooltipStyle="Trans"
                                    onClickMethod = {(e) => this.closePlayer(e)} isSmallFormFactorDevice={this.props.isSmallFormFactor} /*TP-2947*/ />
                                            
                            </span>
                        </div>
                        <video width={videoWidth} height={videoHeight} style={{ position: "relative", marginLeft: marginLeft, marginTop: marginTop /**TP-2285*/}} controls="controls" preload="metadata">
                            <source src={selected_url} type="video/mp4"/>
                        </video>
                    </ModalBody>
                </Modal>
                </div>
            )
        } else return '';
    }
}

export default Recordings;