import React from 'react';
import { Trans } from '@lingui/macro';
import { I18n } from "@lingui/react";
import { UncontrolledTooltip } from 'reactstrap';


function SessionIcon ({id, circleClass, iconClass, innerClass, tooltipStyle, iconStyle, tooltip, tooltipPlament, onClickMethod, isSmallFormFactorDevice, anchorName, tooltipValues}) {
    if (tooltipStyle === "Trans" && (tooltipValues === undefined || tooltipValues === null)) {
        return (
            <a href="javascript:void(0);" id={id} onClick={onClickMethod} className={anchorName !== '' && anchorName !== undefined ? anchorName : ''}>
                <i className={circleClass}></i>
                <i className={iconClass} style={iconStyle !== undefined? iconStyle : {}}></i> 
                { isSmallFormFactorDevice === false ?
                    <UncontrolledTooltip placement={tooltipPlament ? tooltipPlament : "bottom"} innerClassName={innerClass} modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={id}>                                            
                        <Trans id={tooltip} ></Trans>                                            
                    </UncontrolledTooltip>
                    :
                    ''
                }
            </a>
        )
    } else if (tooltipStyle === "Trans" && tooltipValues !== undefined && tooltipValues !== null){
        return (
            <a href="javascript:void(0);" id={id} onClick={onClickMethod} className={anchorName !== '' && anchorName !== undefined ? anchorName : ''}>
                <i className={circleClass}></i>
                <i className={iconClass} style={iconStyle !== undefined? iconStyle : {}}></i> 
                { isSmallFormFactorDevice === false ?
                    <UncontrolledTooltip placement={tooltipPlament ? tooltipPlament : "bottom"} innerClassName={innerClass} modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={id}>                                            
                        <Trans id={tooltip} values={tooltipValues}></Trans>                                            
                    </UncontrolledTooltip>
                    :
                    ''
                }
            </a>
        )
    } else {
        return (                                                      
            <a href="javascript:void(0);" id={id} onClick={onClickMethod}>
                <i className={circleClass}></i>
                <i className={iconClass} style={iconStyle !== undefined? iconStyle : {}}></i>
                { isSmallFormFactorDevice === false ?
                    <UncontrolledTooltip placement={tooltipPlament ? tooltipPlament : "bottom"} innerClassName={innerClass} modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={id}>                                            
                        {tooltip}                                           
                    </UncontrolledTooltip>
                    :
                    ''
                }
            </a>                   
        )
    }
}

export default SessionIcon;