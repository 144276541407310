import * as THREE from 'three';
import OrbitControls from 'three-orbitcontrols';
import ObjectLoader from './ObjectLoader';
import TDSObjectLoader from './TDSObjectLoader';
import FBXObjectLoader from './FBXObjectLoader';
import './closeButton.css';

export default ({ canvas, isSafari, fnSend3dRotation, fnSetAnimationMixer, videoElement, fnClose3d, closeIconText, showCloseIcon }) => {
    let objectLoaded;
    let previousMousePosition = {x : 0,  y : 0 };
    let isDragging = false;
    let isTouched = false;

    const screenDimensions = {
        width: canvas.width,
        height: canvas.height
    }

    const mousePosition = {
        x: 0,
        y: 0
    }

    const scene = buildScene();
    const renderer = buildRender(screenDimensions);
    const camera = buildCamera(screenDimensions);
    const controls = buildControls(camera, renderer);

    bindEventListeners();
    // changePos();
    function bindEventListeners() {
        renderer.domElement.onmousemove = mouseMove;
        renderer.domElement.ontouchmove = touchMove;

        renderer.domElement.onmousedown = mouseDown;
        renderer.domElement.ontouchstart = mouseDown;

        renderer.domElement.onmouseup = mouseUp;
        renderer.domElement.ontouchend = mouseUp;

        renderer.domElement.onmouseleave = mouseUp;
    }

    async function createObject({ type, ...otherOptions }) {
        let objectToAdd;
        //console.log(type);
        //console.log("==================>",otherOptions);
        try {  
            controls.enableZoom = false;
            if (type === 'obj') {
                objectToAdd = await addObjectToScene(otherOptions);
            } else if (type === '3ds') {
                objectToAdd = await add3dsToScene(otherOptions);
            } else if (type === 'fbx') {
                objectToAdd =  await addFBXToScene({...otherOptions, fnSetAnimationMixer});
                controls.enableZoom = true;
            }
            controls.update();
            /** Add close icon */
            if (showCloseIcon) attachCloseIcon(videoElement, closeIconText);
            return objectToAdd;
        } catch (error) {
            throw error;
        }
    }

    function attachCloseIcon(videoElement, closeIconText) {
        /** Close 3d model icon wrapper*/
        const existingCloseIcon = document.getElementById('closeIcon');
        /** remove the the 3d canvas if already exists and create new */
        if (existingCloseIcon) {
            existingCloseIcon.parentNode.removeChild(existingCloseIcon);
        }
        const closeIconWrapper = document.createElement("span");
        closeIconWrapper.setAttribute('id', 'closeIcon');
        closeIconWrapper.innerHTML = '<div class="close-square"><a class="close3d" data-toggle="tooltip" title="' + closeIconText + '"></a></div>';
        //closeIconWrapper.innerHTML= '<i class="fas fa-circle fa-stack-1x" style="color:white"></i><i class="far fa-times-circle fa-stack-1x" data-toggle="tooltip" title="' + closeIconText + '"></i>';
        closeIconWrapper.style.fontSize = '28px';
        closeIconWrapper.style.position = 'absolute';
        closeIconWrapper.style.top = '35px';
        closeIconWrapper.style.right = (videoElement.id === 'flex-container'|| videoElement.id === 'allVideos') ? '47%': '49%';
        // closeIconWrapper.style.backgroundColor= 'white';
        // closeIconWrapper.style.border= '3px';
        // closeIconWrapper.style.borderRadius= '50%';
        closeIconWrapper.style.zIndex = 4;
 
        closeIconWrapper.setAttribute('class', 'fa-stack asset-3d pointer');
        closeIconWrapper.addEventListener('click', removeCanvas, false);

        videoElement.parentNode.insertBefore(closeIconWrapper, canvas);
    }


    function removeCanvas() {
        const [closeIcon, canvas] = document.getElementsByClassName('asset-3d');
        /** cleanup all three object types*/
        if (scene) {
            clearScene(scene);
        }
        window.removeEventListener('mousemove', function(){});
        window.removeEventListener('touchmove', function(){});
        
        window.removeEventListener('mouseup', function(){});
        window.removeEventListener('touchend', function(){});

        window.removeEventListener('mousedown', function(){});
        window.removeEventListener('touchstart', function(){});

        /** remove both element from dom */
        closeIcon && closeIcon.parentNode && closeIcon.parentNode.removeChild(closeIcon);
        canvas && canvas.parentNode.removeChild(canvas);

        fnClose3d();
    }

    function clearScene( scene ) {
        scene.traverse(object => {
            if (!object.isMesh) return;
            object.geometry.dispose();
            if (object.material.isMaterial) {
                cleanMaterial(object.material)
            } else {
                // an array of materials
                for (const material of object.material) cleanMaterial(material)
            }
        })        
    }

    function cleanMaterial(material) {
        material.dispose();
        // dispose textures
        for (const key of Object.keys(material)) {
            const value = material[key];
            if (value && typeof value === 'object' && 'minFilter' in value) {
                value.dispose();
            }
        }
    }

    function mouseDown(e) {
        isDragging = true;
    }

    function mouseUp() {
        if (!objectLoaded || !isDragging) return;
        isDragging = false;
        isTouched = false;
        // publishing the rotation value with quaternion,
        const dataToSend = JSON.stringify({
            rotation: objectLoaded.rotation,
            quaternion: objectLoaded.quaternion,
            matrix: objectLoaded.matrixWorld
        })
        /** Send rotation values to the parent */
        fnSend3dRotation(dataToSend);
    }

    function mouseMove(e) {
        const deltaMove = { x : e.offsetX - previousMousePosition.x,y : e.offsetY - previousMousePosition.y };         
        if (isDragging && objectLoaded) {
            const deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(toRadians(deltaMove.y * 1), toRadians(deltaMove.x * 1), 0, 'XYZ'));
            if (e.which === 1) {
                objectLoaded.quaternion.multiplyQuaternions(deltaRotationQuaternion, objectLoaded.quaternion);
            }
        }
        previousMousePosition = {x : e.offsetX,  y : e.offsetY };
    }

    function touchMove(e) {
        const touch = e.touches[0] || e.changedTouches[0];
        const realTarget = document.elementFromPoint(touch.clientX, touch.clientY);
        e.offsetX = touch.clientX-realTarget.getBoundingClientRect().x;
        e.offsetY = touch.clientY-realTarget.getBoundingClientRect().y
        
        const deltaMove = { x : e.offsetX - previousMousePosition.x,y : e.offsetY - previousMousePosition.y };         
        if (isDragging && objectLoaded && !isTouched) {
            isTouched = true;
            const deltaRotationQuaternion = new THREE.Quaternion().setFromEuler(new THREE.Euler(toRadians(deltaMove.y * 1), toRadians(deltaMove.x * 1), 0, 'XYZ'));
            if (e.which === 0) {
                objectLoaded.quaternion.multiplyQuaternions(deltaRotationQuaternion, objectLoaded.quaternion);
            }
        }
        previousMousePosition = {x : e.offsetX,  y : e.offsetY };
    }

    function toRadians(angle) {
		return angle * (Math.PI / 180);
	}

    function buildScene() {
        const scene = new THREE.Scene();
        return scene;
    }

    function removeEntity(object) {
        const selectedObject = scene.getObjectByName(object.name);
        scene.remove( selectedObject );
    }    

    function buildControls(camera, renderer) {
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = false;
        controls.rotateSpeed = 0.2;
        controls.enableRotate = false;
        controls.dampingFactor = 0.25;
        controls.enablePan = false;
        controls.enableZoom = false;
        controls.maxPolarAngle = Math.PI * 0.5;
        controls.update();
        return controls;
    }

    function buildRender({ width, height }) {
        const renderer = new THREE.WebGLRenderer({ canvas: canvas, antialias: true, alpha: true }); 
        const DPR = window.devicePixelRatio ? window.devicePixelRatio : 1;
        renderer.setPixelRatio(DPR);
        renderer.setSize(width, height);

        renderer.gammaInput = true;
        renderer.gammaOutput = true; 

        return renderer;
    }

    function buildCamera({ width, height }) {
        const aspectRatio = width / height;
        const fieldOfView = 100; //55
        const nearPlane = 0.001;
        const farPlane = 20000; 
        const camera = new THREE.PerspectiveCamera( fieldOfView, aspectRatio, nearPlane, farPlane );

        // camera.position.z = 100;
        camera.position.set( -5, 5, 7 );
        camera.lookAt(scene.position);
        return camera;
    }

    async function addObjectToScene({mtlFilePath, objFilePath, textureFilePath}) {
        try {
            const object = await new ObjectLoader({ scene, mtlFilePath, objFilePath, textureFilePath })
            scene.add(object);
            mtlFilePath ? addLightsToObjectLoaderWithMaterial() : addLightsToObjectLoaderWithoutMaterial();
            centerCam(object);
            objectLoaded = object;
            return object;
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    async function add3dsToScene({mtlFilePath, objFilePath, textureFilePath}) {
        try {
            const object = await new TDSObjectLoader({ mtlFilePath, objFilePath, textureFilePath })
            scene.add(object);
            mtlFilePath ? addLightsToObjectLoaderWithMaterial() : addLightsToObjectLoaderWithoutMaterial();
            centerCam(object);
            objectLoaded = object;
            return object;
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    async function addFBXToScene({mtlFilePath, objFilePath, textureFilePath, fnSetAnimationMixer}) {
        try {
            const object = await new FBXObjectLoader({ mtlFilePath, objFilePath, textureFilePath, fnSetAnimationMixer })
            scene.add(object);
            mtlFilePath ? addLightsToObjectLoaderWithMaterial() : addLightsToObjectLoaderWithoutMaterial();
            centerCam(object);
            objectLoaded = object;
            return object;
        } catch (error) {
            throw {
                code: 'not_supported',
                msg: 'FBX file version not suported'
            };
            console.warn(error);
        }
    }

    async function getFile(file) {
        return new Promise(resolve => {
            // set your file encoding
            const encoding = 'ISO-8859-1'; 

            // create a file reader
            const reader = new FileReader();

            // set on load handler for reader
            reader.onload = function(e) {
                const result = reader.result;
                // parse using your corresponding loader
                resolve(result);
            }

            // read the file as text using the reader
            reader.readAsText(file, encoding);
        });
    }

    async function addJsonObjectToScene({objFile, mtlFile}) {
        const loader = new THREE.ObjectLoader();
        const objText = await getFile(objFile);
        const resObj = loader.parse( objText );
        resObj.name = 'objectName';
        removeEntity(resObj);
        if (mtlFile) {
            loader.parseMaterials(mtlFile, (resMtl) => {
                scene.add( resMtl );
                addLightsToObjectLoaderWithMaterial();
                centerCam(resMtl);
                return resMtl;
            });
        } else {
            scene.add( resObj );
            addLightsToObjectLoaderWithoutMaterial();
            centerCam(resObj);
            return resObj;
        }
    }

    function addLightsToObjectLoaderWithMaterial () {
        const lightDp = new THREE.DirectionalLight( 0xffffff );
        lightDp.position.set( 1, 1, 1 );
        scene.add( lightDp );
        
        const lightDn = new THREE.DirectionalLight( 0xffffff );
        lightDn.position.set( -1, -1, -1 );
        scene.add( lightDn );	
        
        const ambientLight = new THREE.AmbientLight( 0x404040 ); // soft white light
        scene.add( ambientLight );	
        
        const spotLightP = new THREE.SpotLight( 0xffffff );
        spotLightP.position.set( 100, 1000, 100 );
        scene.add( spotLightP );	
        
        const spotLightN = new THREE.SpotLight( 0xffffff );
        spotLightN.position.set( -100, -1000, 100 );
        scene.add( spotLightN );	
    }

    function addLightsToObjectLoaderWithoutMaterial() {
        const lightP = new THREE.DirectionalLight(0xffffff);
        lightP.position.set(1, 1, 1);
        scene.add(lightP);
        const lightN = new THREE.DirectionalLight(0xffffff);
        lightN.position.set(-1, -1, -1);
        scene.add(lightN);
    }

    //3d model scale according to screen
	function centerCam(aroundObject3D) {
		// calc cam pos from Bounding Box
        const BB = new THREE.Box3().setFromObject(aroundObject3D);
		const centerpoint = BB.center();
		const size = BB.size();
		const backup = (size.y / 2) / Math.sin((camera.fov / 2) * (Math.PI / 180));
		const camZpos = BB.max.z + backup + camera.near;
		// move cam
		camera.position.set(centerpoint.x, centerpoint.y, camZpos);
		camera.far = camera.near + 10 * size.z;
		camera.updateProjectionMatrix();
    }

    function update() {
        controls.update();
        renderer.render(scene, camera);
    }

    function onWindowResize() {
        const { width, height } = canvas;
        
        screenDimensions.width = width;
        screenDimensions.height = height;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        
        renderer.setSize(width, height);
    }

    function onMouseMove(x, y) {
        mousePosition.x = x;
        mousePosition.y = y;
    }

    return {
        update,
        onWindowResize,
        onMouseMove,
        addObjectToScene,
        addJsonObjectToScene,
        scene,
        createObject,
        removeCanvas
    }
}