import React from 'react';
import './session.css';
import { t } from '@lingui/macro';
import { I18n } from "@lingui/react";
const maxWordLimit = 25;//TP-4110

let chatObject;

function onKeyPress(e){
  if(e === null || e === undefined)
    return;

let code = (e.keyCode ? e.keyCode : e.which);
  if (code === 13) { //'Enter' keycode
    let sendElem = document.getElementsByClassName('textareaIM');
    if(sendElem && sendElem[0] && 
        (navigator.platform === 'iPad' || navigator.platform === 'iPhone' ||
        navigator.platform === 'Android' || navigator.platform === 'Linux armv7l')) 
      sendElem[0].blur();
    onSendChat();
  }
}

function onSendChat(){
    let elemInput = document.getElementsByClassName('textareaIM');
    if(elemInput[0] !== null && elemInput[0] !== undefined)
    {
      //console.log('calling session::onsendchatmsg', elemInput[0].value);
      if(elemInput[0].value !== '' && chatObject !== null && chatObject !== undefined){
        let strMsg = elemInput[0].value;
        strMsg = strMsg.trim();
        if(strMsg.length > 0)
          chatObject.props.onSendChatMsg(strMsg, chatObject.props.bPrivate?chatObject.props.elemId:null);
         
        elemInput[0].value = '';
      }
    }
    else
      console.log('calling session::onsendchatmsg with elemInput = ', elemInput);
}

class SessionChat extends React.Component { //= ({ onRouteChange, isSignedIn, route, email }) => {
  constructor(){
    super();
    this.state = {
      bSetupCallback: false,
      timerStart: false,
      timeOutPeriod: 20000,
      showMessage: false,
    }
    chatObject = this;

  }

  getMessagesHTML = () => {
    let index = 0;
    let strMsgDiv = '';
    let msgInfoArr = this.props.chatMsgInfoArr;
    let prevTimeStamp = '';

    if(msgInfoArr.length < 2)
      strMsgDiv += '<div class="chatdivblob"><span class="chatspangap"></span></div>';

    for(index = 1; index < msgInfoArr.length; index++){
      
      if(this.props.bPrivate && msgInfoArr[index].msgId !== this.props.elemId) // skip
        continue;
      if(index === (msgInfoArr.length - 1))
        strMsgDiv += '<div class="chatdivblob d-flex flex-column" id="lastmsgdiv">'; //last one
      else
        strMsgDiv += '<div class="chatdivblob d-flex flex-column">';

      if(prevTimeStamp !== msgInfoArr[index].timeStamp || 
        (msgInfoArr[index].firstName !== msgInfoArr[index-1].firstName && msgInfoArr[index].lastName !== msgInfoArr[index-1].lastName)){
        strMsgDiv += '<span class="chattimeblob">';
        strMsgDiv += msgInfoArr[index].firstName + ' ' + msgInfoArr[index].lastName + ' - ';
        strMsgDiv += msgInfoArr[index].timeStamp;
        prevTimeStamp = msgInfoArr[index].timeStamp;
      }
      else strMsgDiv += '<span class="chatspangap">';
      strMsgDiv += '</span>';

      if(msgInfoArr[index].msgType === 0){
        strMsgDiv += '<span class="d-flex">';
        /**TP-3609 & TP-3200 */
        if (msgInfoArr[index].myPicture !== undefined && msgInfoArr[index].myPicture !== null && msgInfoArr[index].myPicture !== "") {
          strMsgDiv += '<span class="chatidnewblob idnewleft">';
          strMsgDiv += '<img class="chat-pic" src="'+msgInfoArr[index].myPicture+'"}/>';
        } else {
          strMsgDiv += '<span class="chatidblob idleft">';
          strMsgDiv += msgInfoArr[index].firstName.slice(0,1).toUpperCase();
          strMsgDiv += msgInfoArr[index].lastName.slice(0,1).toUpperCase();
        }
        strMsgDiv += '</span>';
        strMsgDiv += '&nbsp;&nbsp';
        strMsgDiv += '<span class="chatmsgblob">';
        strMsgDiv += sanitizeHTML(msgInfoArr[index].message);
        strMsgDiv += '</span>';
        strMsgDiv += '</span>';
      }
      else{
        strMsgDiv += '<span class="d-flex">'; //idright
        strMsgDiv += '<span class="chatmsgblob">';
        strMsgDiv += sanitizeHTML(msgInfoArr[index].message);
        strMsgDiv += '</span>';
        strMsgDiv += '&nbsp;&nbsp';
        /**TP-3609 & TP-3200 */
        if (msgInfoArr[index].myPicture !== undefined && msgInfoArr[index].myPicture !== null && msgInfoArr[index].myPicture !== "") {
          strMsgDiv += '<span class="chatidnewblob idnewright">';
          strMsgDiv += '<img class="chat-pic" src="'+msgInfoArr[index].myPicture+'"}/>';
        } else {
          strMsgDiv += '<span class="chatidblob idright">';
          strMsgDiv += msgInfoArr[index].firstName.slice(0,1).toUpperCase();
          strMsgDiv += msgInfoArr[index].lastName.slice(0,1).toUpperCase();
        }
        strMsgDiv += '</span>';
        strMsgDiv += '</span>';
      }

      strMsgDiv += '</span>';
      strMsgDiv += '</div>';
    }
    return strMsgDiv;
  }

  addEditDiv = () => {
    let strEdit = '<div>';
    let commentText =   <I18n>
                            {({ i18n }) => <input 
                                    className="textareaIM" 
                                    placeholder={i18n._(t`session.chatComment`)}
                                    required
                                    autoFocus={true}
                                />
                            }
                        </I18n>


  	strEdit += commentText;
    strEdit += '<a id="sendChatBtn">'; //</span>';onClick={() => onSendChatMsg()}
    strEdit += '<i class="fas fa-paper-plane sendchatbtn"></i>'; //</span>';
    strEdit += '</a>';
    strEdit += '</div>';

  	return strEdit;
  }

  displayChatWindow = () => {
  	let chatElement = null, chatElementParent = null, chatInputElement = null;
  	let elemList = document.getElementsByClassName('chatreadonly');
  	if(elemList[0] !== null){
  		chatElement = elemList[0];
    }
    if(chatElement !== null && chatElement !== undefined){
        chatElement.style.position = 'relative';
        //   chatElement.style.width = this.props.chatWidth + 'px';
        chatElement.classList.add("col-12", "my-0", "mt-1", "mb-0", "pl-1", "pr-0", "pt-1", "pb-3");
        //   chatElement.style.height = this.props.chatHeight + 'px';
        chatElement.style.display = 'block';

        //   chatElement.style.left = this.props.chatLeft + 'px';
        //   chatElement.style.top = this.props.chatTop + 'px';

        elemList = document.getElementsByClassName('chatwindow');
        let inputElemList = document.getElementsByClassName('chatInputBar');
        if(elemList[0] !== null && inputElemList[0] !== null){
            chatElementParent = elemList[0];
            chatInputElement = inputElemList[0];

            chatElementParent.style.position = 'absolute';
            // chatElementParent.style.width = this.props.chatWidth;
            chatElementParent.classList.add("col-3", "m-0", "p-0");
            // chatElementParent.style.height = this.props.chatHeight;
            chatElementParent.style.height = "100%";
            // chatElementParent.style.left = this.props.chatLeft;
            chatElementParent.style.right = '0px';
            // chatElementParent.style.top = this.props.chatTop;
            chatElementParent.style.display = 'block';

            chatInputElement.style.position = 'absolute';
            // chatInputElement.style.bottom = '10px';
        }
        chatElement.style.height = (chatElementParent.offsetHeight - chatInputElement.offsetHeight - 10 - 22) + 'px';//10 for bottom of input bar and 22 is arbitrary to adjust the scrolling -- TP-87
    	if(!this.props.bShowChat){
    		chatElement.style.display = 'none';
            chatElementParent.style.display = 'none';
            chatInputElement.style.display = 'none';
    	}else{ // show the chat window
            // push the latest chat array and the edit control
            let chatContents = '';
            chatContents += this.getMessagesHTML();
  	        //chatContents += this.addEditDiv();

            chatElement.innerHTML = chatContents;
            // show the chat window
            chatElement.style.display = 'block';
            chatElementParent.style.display = 'block';
            chatInputElement.style.display = 'block';
          if(document.getElementById('lastmsgdiv') !== null)
            document.getElementById('lastmsgdiv').scrollIntoView(false);
          //TP-5200
            chatElement.scrollTo({
              top: chatElement.scrollHeight,
              behavior: 'smooth'
          });
    	}
    }
    let elemInput = document.getElementsByClassName('textareaIM');//MB2-482
    if(elemInput[0] !== null && elemInput[0] !== undefined){
        elemInput[0].focus();
    }
  }

  componentDidMount = () =>{
    this.displayChatWindow();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.bShowChat !== prevProps.bShowChat || this.props.chatMsgInfoArr !== prevProps.chatMsgInfoArr || this.props.bPrivate !== prevProps.bPrivate){
        this.displayChatWindow();
    }
    if(this.props.startTimer !== prevProps.startTimer && this.props.startTimer === true) {
      this.startTimer();
    }
  }

  startTimer = () => {
      this.setState({ timerStart: true });
      this.timeOut = setTimeout(() => {
          this.props.updateChatFlags(false);
          this.stopTimer();
      }, this.state.timeOutPeriod);
  }

  stopTimer = () => {
      this.setState({ timerStart: false });
      //reset timeout
      clearTimeout(this.timeOut);
      this.timeOut = null;
  }

  render(){
    //console.log('inside SessionChat render method - chatWidth, this.props.bShowChat = ', this.props.chatWidth, this.props.bShowChat);
    // if(this.props.chatWidth !== 0)
    //   this.displayChatWindow();
  	return(
  		<div className='chatwindow'>
            <div className='chatreadonly'></div>
            <div className='chatInputBar'>
                <I18n>
                    {({ i18n }) => <input 
                            className="textareaIM" 
                            placeholder={i18n._(t`session.chatComment`)}
                            autoFocus={true}
                            onKeyPress={(event) => onKeyPress(event)}
                        />
                    }
                </I18n>          
                <span>
                    <I18n>
                        {({ i18n }) =>
                            <a title={i18n._(t`session.chatSend`)} onClick={() => onSendChat()}>
                                <i className="fas fa-paper-plane sendchatbtn"></i>
                            </a>
                        }
                    </I18n>          
                </span>
            </div>
  		</div>
  	);
  }

}

const sanitizeHTML = function (str) {
  //console.log(str)
  let words = str.split(" ");
  words = handleOverflow(words); //TP-4110
  let newStr = words.join(" ");
	var temp = document.createElement('div');
	temp.textContent = newStr;
  //console.log(temp.innerHTML);
	return temp.innerHTML;
};

//TP-4110
const handleOverflow = function (words) {
  let new_words = words.map(word => {
    if (word.length > maxWordLimit) {
      let num = parseInt(word.length / maxWordLimit);
      //console.log(num);
      let new_word = ""
      for (let i=0; i<=num; i++){
        new_word += word.slice(i*maxWordLimit, (i+1)*maxWordLimit);
        new_word += " ";
      }
      //console.log(new_word)
      word = new_word;
    }
    return word;
  });
  return new_words;
}

export default SessionChat;