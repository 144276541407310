//TP-3199 -- New module to show the the Profile Photo editor is created
import React from 'react';
import { base64StringToBlob } from 'blob-util';
import AvatarEditor from 'react-avatar-editor'

class MyEditor extends React.Component {
    constructor(props){
        super(props);
    }
    componentDidUpdate = (prevProps) => {
        if(this.props.saveImage !== prevProps.saveImage && this.props.saveImage === true){
            this.onClickSave();
        }
        /* if(this.props.changedImage !== prevProps.changedImage && this.props.changedImage === true){
            this.onClickSave();
        } */
    }
    onClickSave = () => {
        //console.log(this.editor);
        if (this.editor) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            //const canvas = this.editor.getImage()
            //console.log("height of image?? ", canvas.height," width of image?? ", canvas.width);
    
            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            const canvasScaled = this.editor.getImageScaledToCanvas()            
            this.props.updateImageUrl(canvasScaled.toDataURL());
        }
    }

    handleDefaultChange = (e) =>{
        return false;
    }
    
    setEditorRef = (editor) => this.editor = editor
    render () {
        let {image} = this.props;
        //TP-3820 -- handled for image as ""
        if (image.includes("profile.png") === false && image.includes("base64") === false && image.includes("blob:") === false && image !== '')
            image = 'data:image/jpg;base64,'+ image;
        //console.log(image);
        return (
        <AvatarEditor
            ref={this.setEditorRef}
            image={image}
            width={150} //TP-3328 -- image size has been made smaller for upload
            height={150} //TP-3328 -- image size has been made smaller for upload
            border={100}
            borderRadius={100} //TP-3672 -- the image crop rectagle to show as circle
            color={[255, 255, 255, 0.6]} // RGBA
            scale={this.props.imageScaleValue}
            rotate={0}
            onImageChange={(this.props.image === "telepresenz/profile.png" || image === "" /**TP-3820*/) ? (e) => this.handleDefaultChange(e) : () => this.props.updateSaveImageButton()}
            onPositionChange={(this.props.image === "telepresenz/profile.png" || image === "" /**TP-3820*/) ? (e) => this.handleDefaultChange(e) : () => this.props.updateSaveImageButton()}
        />
        )
    }
}

export default MyEditor