import React from 'react';


const SortIcons = (props) => (
    <span style={{ position: 'absolute', right: 15 }}>
        { props.direction === 'asc' ? 
            <i className={'fas fa-angle-up'} aria-hidden="true"></i> :
            <i className={'fas fa-angle-down'} aria-hidden="true"></i>
        }
    </span>
)

export default SortIcons;