import React from 'react';
import Annotation from '../Annotation/Annotation';
import AssetsLoader from '../AssetsLoader/AssetsLoader';

function ActionRenderer({ action, ...props }) {
    //console.log('action', action)
    return (
        <>
            {{
                ['telestrate']: <Annotation canvasParent={props.canvasParent} 
                        chatAnnotateFlg={props.chatAnnotateFlg} 
                        user={props.user}
                        g_maxDivId={props.g_maxDivId} 
                        rcStart={props.rcStart} 
                        rcImgObj={props.rcImgObj} 
                        annotation={props.annotation } 
                        urlObj={props.urlObj} 
                        bAnnotate={props.bAnnotate} 
                        bRemoteCapture={props.bRemoteCapture} 
                        onSendAnnotation={props.onSendAnnotation} 
                        sendAnnotationExpertSS={props.sendAnnotationExpertSS}
                        sideBar={props.sideBar}
                        parentId={props.parentId}
                        shapesSetting = {props.shapesSetting}
                        bSelectShape={props.bSelectShape}
                        pencolor={props.pencolor}
                        hasMaxDivControls={props.hasMaxDivControls}
                        format={props.format}
                        reSizeWindow={props.reSizeWindow}
                        isAnnotateOnWtRC={props.isAnnotateOnWtRC}
                        rcAnnotateStart={props.rcAnnotateStart}
                        bShareAsset={props.bShareAsset}
                        isAnnotateOnWtExpertSS={props.isAnnotateOnWtExpertSS}
                        onSendAnnotationOnlyExpert={props.onSendAnnotationOnlyExpert}//TP-2474
                        onResizeWPointer={props.onResizeWPointer} //TP-2560
                        updateAnnotateLoader={props.updateAnnotateLoader}
                        stopPointerWhenControlTransfer={props.stopPointerWhenControlTransfer} //TP-2824
                        isSmallFormFactorDevice={props.isSmallFormFactorDevice} //TP-2975
                        isSafari={props.isSafari}
                        isiOS={props.isiOS}
                        reRenderCanvas={props.reRenderCanvas} //TP-4585
                        sendAnnotationToMaxViewUser={props.sendAnnotationToMaxViewUser} //TP-4737
                        enableButtons={props.enableButtons} //TP-4752
                        redrawPassiveCanvas={props.redrawPassiveCanvas} //TP-4772
                        redrawTechCanvas={props.redrawTechCanvas} //TP-4769
                        onResizeWText={props.onResizeWText} //TP-5073
                        redrawPassiveWhenResized={props.redrawPassiveWhenResized} //TP-4930
                    />,
                ['remoteCapture']: <Annotation canvasParent={props.canvasParent} 
                        chatAnnotateFlg={props.chatAnnotateFlg} 
                        user={props.user} 
                        g_maxDivId={props.g_maxDivId} 
                        rcStart={props.rcStart} 
                        rcImgObj={props.rcImgObj} 
                        annotation={props.annotation } 
                        imgUrl={props.imgUrl} 
                        data={props.data} 
                        urlObj={props.urlObj} 
                        bAnnotate={props.bAnnotate} 
                        bRemoteCapture={props.bRemoteCapture} 
                        onSendAnnotation={props.onSendAnnotation} 
                        sendAnnotationExpertSS={props.sendAnnotationExpertSS}
                        sideBar={props.sideBar}
                        parentId={props.parentId}
                        shapesSetting = {props.shapesSetting}
                        bSelectShape={props.bSelectShape}
                        pencolor={props.pencolor}
                        hasMaxDivControls={props.hasMaxDivControls}
                        format={props.format}
                        reSizeWindow={props.reSizeWindow}
                        isAnnotateOnWtRC={props.isAnnotateOnWtRC}
                        rcAnnotateStart={props.rcAnnotateStart}
                        bShareAsset={props.bShareAsset}
                        isAnnotateOnWtExpertSS={props.isAnnotateOnWtExpertSS}
                        onSendAnnotationOnlyExpert={props.onSendAnnotationOnlyExpert}//TP-2474
                        onResizeWPointer={props.onResizeWPointer} //TP-2560
                        updateAnnotateLoader={props.updateAnnotateLoader}
                        stopPointerWhenControlTransfer={props.stopPointerWhenControlTransfer} //TP-2824
                        isSmallFormFactorDevice={props.isSmallFormFactorDevice} //TP-2975
                        isSafari={props.isSafari}
                        isiOS={props.isiOS}
                        reRenderCanvas={props.reRenderCanvas}//TP-4585
                        sendAnnotationToMaxViewUser={props.sendAnnotationToMaxViewUser} //TP-4737
                        enableButtons={props.enableButtons} //TP-4752
                        redrawPassiveCanvas={props.redrawPassiveCanvas} //TP-4772
                        redrawTechCanvas={props.redrawTechCanvas} //TP-4769
                        onResizeWText={props.onResizeWText} //TP-5073
                        redrawPassiveWhenResized={props.redrawPassiveWhenResized} //TP-4930
                    />,
                ['localCapture']: <Annotation canvasParent={props.canvasParent} 
                        chatAnnotateFlg={props.chatAnnotateFlg} 
                        user={props.user} 
                        g_maxDivId={props.g_maxDivId} 
                        rcStart={props.rcStart} 
                        rcImgObj={props.rcImgObj} 
                        annotation={props.annotation } 
                        imgUrl={props.imgUrl} 
                        data={props.data} 
                        urlObj={props.urlObj} 
                        bAnnotate={props.bAnnotate} 
                        bRemoteCapture={props.bRemoteCapture} 
                        onSendAnnotation={props.onSendAnnotation} 
                        sendAnnotationExpertSS={props.sendAnnotationExpertSS}
                        sideBar={props.sideBar}
                        parentId={props.parentId}
                        isAnnotateOnWtRC={props.isAnnotateOnWtRC}
                        rcAnnotateStart={props.rcAnnotateStart}
                        bShareAsset={props.bShareAsset}
                        isAnnotateOnWtExpertSS={props.isAnnotateOnWtExpertSS}
                        onSendAnnotationOnlyExpert={props.onSendAnnotationOnlyExpert} //TP-2474
                        onResizeWPointer={props.onResizeWPointer} //TP-2560
                        updateAnnotateLoader={props.updateAnnotateLoader}
                        stopPointerWhenControlTransfer={props.stopPointerWhenControlTransfer}//TP-2824
                        isSmallFormFactorDevice={props.isSmallFormFactorDevice} //TP-2975
                        isSafari={props.isSafari}
                        isiOS={props.isiOS}
                        reRenderCanvas={props.reRenderCanvas} //TP-4585
                        sendAnnotationToMaxViewUser={props.sendAnnotationToMaxViewUser} //TP-4737
                        enableButtons={props.enableButtons} //TP-4752
                        redrawPassiveCanvas={props.redrawPassiveCanvas} //TP-4772
                        redrawTechCanvas={props.redrawTechCanvas} //TP-4769
                        onResizeWText={props.onResizeWText} //TP-5073
                        redrawPassiveWhenResized={props.redrawPassiveWhenResized} //TP-4930
                    />,
                ['shareAsset']:  <AssetsLoader 
                        assetRecipient={props.assetRec} 
                        localAssets={props.localAssets} externalAssets={props.externalAssets}
                        i18n={props.i18n} rotationChanges={props.rotationChanges} opacityChanges={props.opacityChanges}
                        fnSendFileOpacity={props.fnSendFileOpacity}
                        fnSend3dRotation={props.fnSend3dRotation} 
                        fnClose3dCanvas={props.fnClose3dCanvas} 
                        user={props.user} 
                        removeArtifacts={props.removeArtifacts} 
                        g_maxDivId={props.g_maxDivId}
                        hasMaxDivControls={props.hasMaxDivControls}
                        bCancelShare={props.bCancelShare} updateCancelShare={props.updateCancelShare}
                        isSafari={props.isSafari}
                        isiOS={props.isiOS}
                        isAndroid={props.isAndroid}
                        redraw={props.redraw}
                        rotationArray={props.rotationArray} //TP-2085
                        isSmallFormFactorDevice={props.isSmallFormFactorDevice} //TP-2975
                    />,
                ['shareAssetAll']:  <AssetsLoader
                        assetRecipient={props.assetRec} 
                        localAssets={props.localAssets} 
                        externalAssets={props.externalAssets} 
                        i18n={props.i18n} rotationChanges={props.rotationChanges} opacityChanges={props.opacityChanges}
                        fnSendFileOpacity={props.fnSendFileOpacity}
                        fnSend3dRotation={props.fnSend3dRotation} 
                        fnClose3dCanvas={props.fnClose3dCanvas} 
                        user={props.user} 
                        removeArtifacts={props.removeArtifacts} 
                        g_maxDivId={props.g_maxDivId} 
                        hasMaxDivControls={props.hasMaxDivControls}
                        bCancelShare={props.bCancelShare} updateCancelShare={props.updateCancelShare}
                        isSafari={props.isSafari}
                        isiOS={props.isiOS}
                        isAndroid={props.isAndroid}
                        redraw={props.redraw}
                        rotationArray={props.rotationArray} //TP-2085
                        isSmallFormFactorDevice={props.isSmallFormFactorDevice} //TP-2975
                    />,
            }[action]}
        </>
    );
}

export default ActionRenderer;