import React from 'react';
import { Row, Col, Card, CardBody, Collapse, Modal, ModalHeader, ModalBody, UncontrolledTooltip } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { I18n } from "@lingui/react";
import '../Landing/Groups.scss';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import DatePicker from "react-datepicker";
import ActionData from './action_data';
import Loader from '../Loader/Loader';
import {uploadFile} from 'react-s3';
import { printConsole, getHostAbbreviationForSocket } from '../helpers';
import AuthService from '../AuthService';
const fileDialog = require('file-dialog');

class SubTask extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            fileLoading: false, fileToLarge: false,
            fileNameArr: [],
            invalidFile: false
        };
        this.cardColor = {
            created: 'bg-white',//TP-4103
            assigned: 'bg-white', //TP-3220
            in_progress: 'bg-white', //'bg-in-progress' TP-4870
            finished: 'bg-finished',
            skipped: 'bg-finished',
            undefined: 'bg-white' //TP-3220
        };
        this.config = {
            bucketName: process.env.REACT_APP_S3_BUCKET,
            dirName: 'jobs', /* optional */
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        };  
        this.authService = new AuthService();
    }
    
    procedureStatus = (status, i18n) => {
        switch (status) {
            case 'created':
                return i18n._(t`created`);
            case 'assigned':
                return i18n._(t`assigned`);
            case 'in_progress':
                return i18n._(t`in_progress`);
            case 'finished':
                return i18n._(t`finished`);
            case 'skipped':
                return i18n._(t`skipped`);
            default:
                break;
        } 
    }

    openFileUpload = ({idx, fieldName})=> (e) => {
        e.preventDefault();
        // MB2-844
        const constraints = fieldName === 'external_evidence' ? {} : { accept: '.mp4, .jpg, .pdf, .png, .jpeg' };//TP-742
        fileDialog(constraints)
        .then( async (files) => {
            const localAssets = Object.values(files);
            //Both reference material and external evidence will have same restriction
            if(fieldName === 'reference_materials' && !this.isFileAllowed({localAssets})){
                this.setState({invalidFile: true})
                return false;
            }
            let modifiedFiles = [];
            localAssets.map((f) => {
                let newFileName = f.name.replace(/\+/g, "__").replace(/\ /g, "");//TP-3535
                let file = new File([f], (Date.now() + '_' + newFileName), { type: f.type });
                modifiedFiles.push(file);
            })
            this.setState({ fileLoading: true });//to indicate file share started
            const reference_material = await this.uploadAssetFile(modifiedFiles);
            this.setState({fileLoading: false, fileNameArr: []},() => {
                this.props.attachFile({fieldName, id: idx, fileNameArr : reference_material})
            })
        })
        .catch(err => {
            this.setState({fileLoading: false, fileNameArr: []})
            if(err === "File too large"){
                this.setState({fileToLarge: true})
            }
            printConsole(`error in openFileUpload ${JSON.stringify(err)}`);
        })

    }

    isFileAllowed = ({localAssets}) =>{
        let flag = false, allowed_file_type=['application/pdf', 'image/png', 'image/jpeg', 'video/mp4'];
        for (const file of localAssets) {
            if(allowed_file_type.includes(file.type)){
                flag = true;
            }
        }
        return flag;
    }

    uploadAssetFile = async (localAssets) => {
        const storage_config = this.props.customer && this.props.customer.storage_config ?  this.props.customer.storage_config : null;
        let retdata;
        for (const file of localAssets) {
            if(storage_config){
               retdata = await this.sendFileToBackend(file);//TP-5081 TP-2983
            }else{
                retdata = await this.s3FileUpload(file);//TP-5081 TP-1484
            }
            this.setState(prevState => ({
                fileNameArr: [...prevState.fileNameArr,{key: retdata.key, location: retdata.location, name: file.name}]//used for s3
            }));
        }
        return this.state.fileNameArr;
    }

    // upload files to s3
    s3FileUpload = (file) => {
        //TP-5318
        const hostAbbr = getHostAbbreviationForSocket();//TP-1945
        if(hostAbbr !== ""){
            this.config.dirName = `jobs/${hostAbbr}`;
        }
        return new Promise((resolve, reject) => {
            uploadFile(file, this.config)
            .then(retdata => {
                resolve({...retdata });
            })
            .catch(err => {
                this.setState({fileLoading: false})
                console.error(err);
            })
        });
    }
    
    sendFileToBackend = (file) => {
        const formdata = new FormData(), hostAbbr = getHostAbbreviationForSocket();//TP-1945
        if(hostAbbr !== ""){
            formdata.append('file', file); formdata.append('folder_name', `jobs/${hostAbbr}`);
        }else{
            formdata.append('file', file); formdata.append('folder_name', 'jobs');
        }
        return new Promise((resolve, reject) => {
            // push selected file to server
            this.authService.fetch('upload/files', {
                "method": "POST",
                body: formdata
            }, {
                //This is the header part for override the header of the form submission
            })
            .then(response => {
                if(response && response.status === 200){
                    return response.json()
                }else{
                    reject("File too large");//TP-4942
                }
            })
            .then(retdata => {
                resolve({...retdata});
            })
            .catch(err => {
                this.setState({fileLoading: false})
                console.error(err);
            })
        })
    }

    invalidFileDialog = () => {
        return (
            <div>
                <Modal isOpen={this.state.invalidFile} toggle={() => this.setState({ invalidFile: false })}>
                    <ModalHeader toggle={() => this.setState({ invalidFile: false })} cssModule={{ 'modal-title': 'w-100 text-center text-danger' }}><Trans>File Alert</Trans></ModalHeader>
                    <ModalBody>
                        <div className="text-center text-danger">
                            <Trans>invalid file</Trans>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    //TP-4942
    fileToLargeDialog = () => {
        return (
            <div>
                <Modal isOpen={this.state.fileToLarge} toggle={() => this.setState({ fileToLarge: false })}>
                    <ModalHeader toggle={() => this.setState({ fileToLarge: false })} cssModule={{ 'modal-title': 'w-100 text-center text-danger' }}><Trans>File Alert</Trans></ModalHeader>
                    <ModalBody>
                        <div className="text-center text-danger">
                            <Trans>big_file</Trans>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    //T32-292
    showOriginalFileName = ({fileName}) => {
        try{
            let originalFileName = '';
            if (fileName){
                let splitedFileName = fileName.split(/_(.+)/);//TP-1058
                // splitedFileName.shift();originalFileName = splitedFileName.join('-');
                originalFileName = splitedFileName[1];
            }
            return originalFileName;
        }catch(error){
            printConsole('showOriginalFileName error', error)
            return ''
        }
    }

    render(){
        const props = this.props;
        const isSequenceReadOnly = ((props.selectedJob && props.selectedJob.status === 'created' ) || (props.selectedJob.status === undefined)) ? false : true
        const isJobFinished = (props.selectedJob && (props.selectedJob.status === 'finished'));
        const selectStyle = {
            option: (styles) => ({...styles, color: '#000'})     
        }
        // const showDateField = (props.action === 'add_evidence_job' || props.action === 'edit_evidence_job' || props.action === 'clone_evidence_job' )//TP-3490
        const showDateField = false//TP-4866
        //TP-3220
        // const {assetDetails} = props;
        // const isModelAttached = assetDetails && assetDetails.model
        // console.log(props);
        //console.log(props.subTasks[idx].status);
        return(
            <>
                {this.invalidFileDialog()}
                {this.fileToLargeDialog()}
                <Loader text="Uploading...." isLoading={this.state.fileLoading} />
                {
                    props.subTasks.map((val, idx)=> {
                        let descriptionId = `description-${idx}`,
                        stDate = new Date(props.subTasks[idx].task_startdate),// MB2-175
                        edDate = new Date(props.subTasks[idx].task_enddate),// MB2-175
                        isSubTaskReadOnly = (props.subTasks[idx].status === 'created' || props.subTasks[idx].status === 'assigned' || props.subTasks[idx].status === undefined),
                        reference_materials = (props.subTasks[idx].reference_materials ? props.subTasks[idx].reference_materials : null),
                        external_evidence = (props.subTasks[idx].external_evidence ? JSON.parse(props.subTasks[idx].external_evidence) : null)
                        return (
                            <I18n key={idx} >
                                {({ i18n }) => 
                                
                                    <Card className={this.cardColor[props.subTasks[idx].status] + " mt-1 text-black"}>
                                        <CardBody className="p-2">
                                            <Row  className="pointer">
                                                <div className="col-md-3" onClick={() => { props.collapseSubTask(idx) }}>
                                                    <Trans>Procedure</Trans> {idx+1}:
                                                </div>
                                                <div className="col-md-7 groups-form-validation-msg" onClick={() => { props.collapseSubTask(idx) }}>
                                                    {props.subTasks[idx].showInvalidError ? <Trans>One or more entries are invalid</Trans> : ''} 
                                                </div>

                                                <div className="col-md-1" onClick={() => { props.collapseSubTask(idx) }}>

                                                    {
                                                        props.subTasks[idx].collapse ?
                                                        <i className="fas fa-angle-up float-right" aria-hidden="true"></i>
                                                        :
                                                        <i className="fas fa-angle-down float-right" aria-hidden="true"></i> 
                                                    }

                                                </div>
                                                <div className="col-md-1">
                                                    {   isSubTaskReadOnly ? 
                                                            <>
                                                                {props.subTasks[idx].id ?
                                                                    <I18n>
                                                                        {({ i18n }) =>
                                                                            <span className="pointer" onClick={(e) => props.deleteSubTask({e,index: idx})}>
                                                                                <i className="far fa-trash-alt red-trash" id={'deleteTask-' + idx}></i>
                                                                                { props.isSmallFormFactor === false ? 
                                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'deleteTask-' + idx}>
                                                                                        <Trans>Delete Task</Trans>
                                                                                    </UncontrolledTooltip>       
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </I18n>
                                                                : 
                                                                    <I18n>
                                                                        {({ i18n }) =>
                                                                            <span className="pointer" onClick={(e) => props.deleteNewSubTask({e,index: idx})}>
                                                                                <i className="far fa-trash-alt red-trash" id={'deleteTask-' + idx}></i>
                                                                                { props.isSmallFormFactor === false ? 
                                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'deleteTask-' + idx}>
                                                                                        <Trans>Delete Task</Trans>
                                                                                    </UncontrolledTooltip>       
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </span>
                                                                        } 
                                                                    </I18n>
                                                                }
                                                            </>
                                                        : null
                                                    }
                                                </div>
                                            </Row>
                                            <Collapse isOpen={!props.subTasks[idx].collapse}>
                                                {   props.customer.behaviour !== "hindalco" ?
                                                    <>
                                                        <Row>
                                                            <Col className="input-wrapper">
                                                                <textarea
                                                                rows={4}
                                                                style={{resize:'none'}}
                                                                name={i18n._(t`task_instruction`)+ idx}
                                                                data-id={idx}
                                                                id={descriptionId}
                                                                placeholder={i18n._(t` Instructions`)}
                                                                className="signup-input-field form-control"
                                                                onChange={props.onChangeJobForm('task_instruction')}
                                                                value={props.subTasks[idx].task_instruction}
                                                                disabled={!isSubTaskReadOnly || isJobFinished}
                                                                />
                                                                { props.isSmallFormFactor === false ? 
                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={descriptionId}>
                                                                        <Trans>Instructions</Trans>
                                                                    </UncontrolledTooltip>
                                                                    :
                                                                    ''
                                                                }
                                                                { props.validator.message(i18n._(t`instruction`)+ idx, props.subTasks[idx].task_instruction, 'required') }
                                                            </Col>
                                                            <Col className="input-wrapper">
                                                            {   props.subTasks[idx].selectedAction && props.subTasks[idx].selectedAction.label === 'Pass Fail' ?
                                                                    <>
                                                                        <textarea
                                                                            rows={4}
                                                                            style={{resize:'none'}}
                                                                            placeholder={i18n._(t` Expected Results`)}
                                                                            className="signup-input-field form-control"
                                                                            value='' 
                                                                            disabled={true}
                                                                        />
                                                                    </>
                                                                :
                                                                    <>
                                                                        <textarea
                                                                            rows={4}
                                                                            style={{resize:'none'}}
                                                                            name={i18n._(t`expected_result`)+ idx}
                                                                            data-id={idx}
                                                                            id={`expected_result-`+ idx}
                                                                            placeholder={i18n._(t` Expected Results`)}
                                                                            className="signup-input-field form-control"
                                                                            onChange={props.onChangeJobForm('expected_result')}
                                                                            value={props.subTasks[idx].expected_result} 
                                                                            disabled={!isSubTaskReadOnly || isJobFinished}
                                                                        />
                                                                        { props.isSmallFormFactor === false ? 
                                                                            <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={`expected_result-`+idx}>
                                                                                <Trans>Expected Results</Trans>
                                                                            </UncontrolledTooltip>
                                                                            :
                                                                            ''
                                                                        }
                                                                        {/* { props.validator.message(i18n._(t`expected_result`)+ idx, props.subTasks[idx].expected_result, 'required') } */}
                                                                    </>
                                                            }
                                                            </Col>

                                                        </Row>
                                                        <Row className="mt-3">
                                                            <Col className="input-wrapper">
                                                                <Select
                                                                    value={props.actionNames(props.subTasks[idx].selectedAction, i18n)}
                                                                    onChange={props.handleActionChange(idx)}
                                                                    components={makeAnimated()}
                                                                    placeholder={i18n._(t` Select Evidence Type`)}
                                                                    options={props.getSubTaskActionNameOptions(i18n)}
                                                                    isSearchable={props.isSmallFormFactor ? false : true}
                                                                    isDisabled={!isSubTaskReadOnly || isJobFinished}
                                                                    styles={selectStyle}
                                                                    id={`evidence_list_`+ idx}
                                                                />
                                                                <input type="text" readOnly value={props.subTasks[idx].action_id} name={i18n._(t`action_name`)+ idx} className="hidden"/>
                                                                { props.validator.message(i18n._(t`action_name`)+ idx, props.subTasks[idx].action_id, 'required') }
                                                            </Col>
                                                            <Col className="input-wrapper ">
                                                                <input
                                                                    type="number"
                                                                    min={1}
                                                                    name={i18n._(t`sequence`)+ idx}
                                                                    data-id={idx}
                                                                    placeholder={i18n._(t` Sequence`)}
                                                                    className="signup-input-field mt-0"
                                                                    onChange={props.onChangeJobForm('sequence')}
                                                                    value={props.subTasks[idx].sequence} 
                                                                    disabled={!isSubTaskReadOnly || isJobFinished}//TP-3951,4103
                                                                />
                                                                { props.validator.message(i18n._(t`sequence`)+ idx, props.subTasks[idx].sequence, 'required|numeric|checkSequence') }
                                                            </Col>
                                                            {/* TP-4866 */}
                                                            <Col className="input-wrapper">
                                                                <input type="text" readOnly value={this.procedureStatus(props.subTasks[idx].status,i18n)} className='signup-input-field mt-0'/>
                                                            </Col>
                                                        </Row>

                                                        <ActionData
                                                            subTasks={props.subTasks}
                                                            validator={props.validator}
                                                            onChangeTaskForm={props.onChangeTaskForm}
                                                            addActionData={props.addActionData}
                                                            index={idx}
                                                            deleteActionData={props.deleteActionData}
                                                            isSubTaskReadOnly={!isSubTaskReadOnly || isJobFinished}
                                                            onChangeJobForm = {props.onChangeJobForm}
                                                        />
                                                    </>
                                                    : 
                                                    <Row>
                                                        <Col className="input-wrapper">
                                                            <textarea
                                                                rows={4}
                                                                style={{resize:'none'}}
                                                                name={i18n._(t`task_instruction`)+ idx}
                                                                data-id={idx}
                                                                id={descriptionId}
                                                                placeholder={i18n._(t` Instructions`)}
                                                                className="signup-input-field form-control"
                                                                onChange={props.onChangeSubtask('task_instruction')}
                                                                value={props.subTasks[idx].task_instruction}
                                                                disabled={!isSubTaskReadOnly || isJobFinished}
                                                            />
                                                            { props.isSmallFormFactor === false ? 
                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={descriptionId}>
                                                                    <Trans>Instructions</Trans>
                                                                </UncontrolledTooltip>
                                                                :
                                                                ''
                                                            }
                                                            { props.validator.message(i18n._(t`instruction`)+ idx, props.subTasks[idx].task_instruction, 'required') }
                                                        </Col>
                                                        <Col className="input-wrapper mt-1">
                                                            <input
                                                                type="number"
                                                                min={1}
                                                                name={i18n._(t`sequence`)+ idx}
                                                                data-id={idx}
                                                                placeholder={i18n._(t` Sequence`)}
                                                                className="signup-input-field mt-0"
                                                                onChange={props.onChangeJobForm('sequence')}
                                                                value={props.subTasks[idx].sequence} 
                                                                disabled={!isSubTaskReadOnly || isJobFinished}//TP-3951,4103
                                                            />
                                                            { props.validator.message(i18n._(t`sequence`)+ idx, props.subTasks[idx].sequence, 'required|numeric|checkSequence') }
                                                        </Col>
                                                    </Row>
                                                }

                                                <Row className="mt-3">
                                                    {/* <Col className="input-wrapper">
                                                        <Select
                                                            value={props.subTasks[idx].selectedUser || ''}
                                                            onChange={props.handleTechChange(idx)}
                                                            components={makeAnimated()}
                                                            placeholder={i18n._(t`Select A Technician`)}
                                                            options={props.getTechNameOptions()}
                                                            isSearchable={true}
                                                        />
                                                        <input type="text" readOnly value={props.subTasks[idx].user_id} name={i18n._(t`technician`)} className="hidden"/>
                                                        { props.validator.message(i18n._(t`technician`)+ idx, props.subTasks[idx].user_id, 'required') }
                                                        <input type="text" readOnly value={this.procedureStatus(props.subTasks[idx].status,i18n)} className='signup-input-field mt-0'/>
                                                    </Col> */}
                                                    {
                                                        showDateField?
                                                        <>
                                                            <Col className="input-wrapper">
                                                                <DatePicker 
                                                                    selected={stDate}
                                                                    className="custom-date" 
                                                                    onChange={props.onSubTaskStartDateChange(idx)}
                                                                    name={i18n._(t`task_startdate`)}
                                                                    disabled={!isSubTaskReadOnly || isJobFinished}
                                                                    /**TP-3031 */ onFocus={props.isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} 
                                                                    popperPlacement="top-start" 
                                                                    />
                                                                <input type="date" readOnly value={props.subTasks[idx].task_startdate} name={i18n._(t`task_startdate`)+ idx} className="hidden"/>
                                                                { props.validator.message(i18n._(t`task_startdate`)+ idx, props.subTasks[idx].task_startdate, `checkDateRange:${idx}`) }
                                                            </Col>
                                                            <Col className="input-wrapper">
                                                                <DatePicker 
                                                                    selected={edDate}
                                                                    className="custom-date" 
                                                                    onChange={props.onSubTaskEndDateChange(idx)} 
                                                                    name={i18n._(t`task_enddate`)}
                                                                    disabled={!isSubTaskReadOnly || isJobFinished}
                                                                    /**TP-3031 */ onFocus={props.isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} 
                                                                    popperPlacement="top-start" 
                                                                    />
                                                                <input type="date" readOnly value={props.subTasks[idx].task_enddate} name={i18n._(t`task_enddate`)+ idx} className="hidden"/>
                                                                { props.validator.message(i18n._(t`task_enddate`)+ idx, props.subTasks[idx].task_enddate, `afterTaskStartDate:${idx}|checkDateRange:${idx}`) }
                                                            </Col>
                                                        </>
                                                        : null
                                                    }

                                                </Row>

                                                <Row className="mt-3">
                                                    <Col md="4" className="input-wrapper"><button type="button" className={isSubTaskReadOnly && !isJobFinished ? "btn btn-info": "btn btn-secondary"} disabled={isSubTaskReadOnly && !isJobFinished? false : true} onClick={this.openFileUpload({idx, fieldName:'reference_materials'})}><Trans>Attach reference material</Trans></button></Col>
                                                </Row>
                                                {
                                                    reference_materials && reference_materials.map((reference_material, index) => 
                                                        <Row className="mt-2" key={index}>
                                                            <Col md="7" className="input-wrapper mt-1">{reference_material.file_info ? this.showOriginalFileName({fileName: JSON.parse(reference_material.file_info).name}) : ''}</Col>
                                                            <Col md="1" className="input-wrapper mt-1">
                                                                {reference_material && reference_material.file_info && isSubTaskReadOnly && !isJobFinished ? 
                                                                    <I18n>
                                                                        {({ i18n }) =>
                                                                            <span className="pointer" onClick={(e) => props.detachFiles({e, idx, fieldName:'reference_materials', fieldValue: reference_material})}>
                                                                                <i className="far fa-trash-alt red-trash" id={`delete_reference_material-`+idx}></i>
                                                                                { props.isSmallFormFactor === false ? 
                                                                                    <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={`delete_reference_material-`+idx}>
                                                                                        <Trans>Delete reference material</Trans>
                                                                                    </UncontrolledTooltip>
                                                                                    :
                                                                                    ''
                                                                                }
                                                                            </span>
                                                                        }
                                                                    </I18n>
                                                                    : null
                                                                }
                                                            </Col>
                                                        </Row>
                                                    )
                                                }

                                                {isJobFinished? 
                                                    <Row className="mt-2">
                                                        <Col md="4" className="input-wrapper mt-1"><button type="button" className={external_evidence ? "btn btn-secondary": "btn btn-info"} disabled={external_evidence} onClick={this.openFileUpload({idx, fieldName:'external_evidence'})}><Trans>Attach external evidence</Trans></button></Col>
                                                        <Col md="6" className="mt-1">{external_evidence ? this.showOriginalFileName({fileName: external_evidence[0]['name'] }): ''}</Col>
                                                        <Col md="1" className="mt-1">
                                                            {external_evidence ? 
                                                                <I18n>
                                                                    {({ i18n }) =>
                                                                        <span className="pointer" onClick={(e) => props.detachFiles({e, idx, fieldName:'external_evidence'})}>
                                                                            <i className="far fa-trash-alt red-trash" id={`delete_external_evidence-`+idx}></i>
                                                                            { props.isSmallFormFactor === false ? 
                                                                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={`delete_external_evidence-`+idx}>
                                                                                    <Trans>Delete external evidence</Trans>
                                                                                </UncontrolledTooltip>
                                                                                :
                                                                                ''
                                                                            }
                                                                        </span>
                                                                    }
                                                                </I18n>
                                                                : null
                                                            }
                                                        </Col>
                                                    </Row>
                                                    :null
                                                }
                                            </Collapse>
                                        </CardBody>
                                    </Card>
                                }
                            </I18n>
                        )   
                    })
                }
            </>
        )
    }
}


export default SubTask;