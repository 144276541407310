import React from 'react';
import './NewChat.css';
import { Trans, t } from '@lingui/macro';
import { I18n } from "@lingui/react";
import { UncontrolledTooltip } from 'reactstrap';
const maxWordLimit = 25;//TP-4110

let chatObject;

function onKeyPress(e){
  if(e === null || e === undefined)
    return;

let code = (e.keyCode ? e.keyCode : e.which);
  if (code === 13) { //'Enter' keycode
    let sendElem = document.getElementsByClassName('textareaIM');
    if(sendElem && sendElem[0] && 
        (navigator.platform === 'iPad' || navigator.platform === 'iPhone' ||
        navigator.platform === 'Android' || navigator.platform === 'Linux armv7l')) 
      sendElem[0].blur();
      if (chatObject.state.mode === 'edit')
        onEditChat(chatObject.state.editMessageId);
      else if (chatObject.state.mode === 'reply')
        onSendReplyChat(chatObject.state.replyMessageInfo.messageID);
      else
        onSendChat();
  }
}

function onSendChat(){
    let elemInput = document.getElementsByClassName('textareaIM');
    if(elemInput[0] !== null && elemInput[0] !== undefined)
    {
      //console.log('calling session::onsendchatmsg', elemInput[0].value);
      if(elemInput[0].value !== '' && chatObject !== null && chatObject !== undefined){
        let strMsg = elemInput[0].value;
        strMsg = strMsg.trim();
        if(strMsg.length > 0)
          chatObject.props.onSendChatMsg(strMsg, chatObject.props.bPrivate?chatObject.props.elemId:null);
         
        elemInput[0].value = '';
      }
    }
    else
      console.log('calling session::onsendchatmsg with elemInput = ', elemInput);
}

function onEditChat(messageID) {
  let elemInput = document.getElementsByClassName('textareaIM');
    if(elemInput[0] !== null && elemInput[0] !== undefined)
    {
      //console.log('calling session::onsendchatmsg', elemInput[0].value);
      if(elemInput[0].value !== '' && chatObject !== null && chatObject !== undefined){
        let strMsg = elemInput[0].value;
        strMsg = strMsg.trim();
        if(strMsg.length > 0)
          chatObject.props.editChatMsg(strMsg, chatObject.props.bPrivate?chatObject.props.elemId:null, messageID);
        chatObject.setState({ editMessageId: null, mode: 'send' });
        elemInput[0].value = '';
      }
    }
    else
      console.log('calling session::oneditchatmsg with elemInput = ', elemInput);
}

function onSendReplyChat(messageID) {
  let elemInput = document.getElementsByClassName('textareaIM');
    if(elemInput[0] !== null && elemInput[0] !== undefined)
    {
      //console.log('calling session::onsendchatmsg', elemInput[0].value);
      if(elemInput[0].value !== '' && chatObject !== null && chatObject !== undefined){
        let strMsg = elemInput[0].value;
        strMsg = strMsg.trim();
        if(strMsg.length > 0)
          chatObject.props.replyChatMsg(strMsg, chatObject.props.bPrivate?chatObject.props.elemId:null, messageID);
        chatObject.setState({ replyMessage: '', mode: 'send' });
        elemInput[0].value = '';
      }
    }
    else
      console.log('calling session::onsendreplychatmsg with elemInput = ', elemInput);
}

function onSendDeleteChat(messageID) {
  chatObject.props.deleteChatMsg(messageID, chatObject.props.bPrivate?chatObject.props.elemId:null);  
}

function onCloseReplyMode(e) {
  chatObject.setState({ replyMessage: '', mode: 'send' });
}

class NewChat extends React.Component { //= ({ onRouteChange, isSignedIn, route, email }) => {
  constructor(){
    super();
    this.state = {
      bSetupCallback: false,
      timerStart: false,
      timeOutPeriod: 20000,
      showMessage: false,
      editMessageId: null, 
      mode: 'send',
      replyMessageInfo: null
    }
    chatObject = this;

  }
  
  getMessagesHTML = () => {
    const {isGroupChat} = this.props;
    let index = 0;
    let strMsgDiv = '';
    let msgInfoArr = this.props.chatMsgInfoArr;
    //console.log(msgInfoArr);
    let prevTimeStamp = '';

    if(msgInfoArr.length < 2)
      strMsgDiv += '<div class="chatdivblob"><span class="chatspangap"></span></div>';

    for(index = 1; index < msgInfoArr.length; index++){
      
      if((this.props.bPrivate && msgInfoArr[index].msgId !== this.props.elemId && isGroupChat === false) || (this.props.bPrivate && msgInfoArr[index].msgId !== this.props.elemId && msgInfoArr[index].msgType == 0 && isGroupChat === true)) // skip
        continue;
      if(index === (msgInfoArr.length - 1))
        strMsgDiv += '<div class="chatdivblob d-flex flex-column" id="lastmsgdiv">'; //last one
      else
        strMsgDiv += '<div class="chatdivblob d-flex flex-column">';

      if(prevTimeStamp !== msgInfoArr[index].timeStamp || 
        (msgInfoArr[index].firstName !== msgInfoArr[index-1].firstName && msgInfoArr[index].lastName !== msgInfoArr[index-1].lastName)){
        if (msgInfoArr[index].msgType === 0)
          strMsgDiv += '<span class="chattimeblob chattimeleftblob">';
        else
          strMsgDiv += '<span class="chattimeblob chattimerightblob">';
        if (this.props.isGroupChat === true)
          strMsgDiv += msgInfoArr[index].firstName + ' ' + msgInfoArr[index].lastName + ' - ';
        strMsgDiv += msgInfoArr[index].timeStamp;
        prevTimeStamp = msgInfoArr[index].timeStamp;
      }
      else strMsgDiv += '<span class="chatspangap">';
      strMsgDiv += '</span>';
      //console.log(msgInfoArr[index].msgType);
      if(msgInfoArr[index].msgType === 0){
        strMsgDiv += '<span class="d-flex">';
        /**TP-3609 & TP-3200 */
        if (msgInfoArr[index].myPicture !== undefined && msgInfoArr[index].myPicture !== null && msgInfoArr[index].myPicture !== "") {
          strMsgDiv += '<span class="chatidnewblob idnewleft">';
          strMsgDiv += '<img class="chat-pic-new" src="'+msgInfoArr[index].myPicture+'"}/>';
        } else {
          strMsgDiv += '<span class="chatidblob idleft">';
          strMsgDiv += msgInfoArr[index].firstName.slice(0,1).toUpperCase();
          strMsgDiv += msgInfoArr[index].lastName.slice(0,1).toUpperCase();
        }
        strMsgDiv += '</span>';
        strMsgDiv += '&nbsp;&nbsp';
        if (msgInfoArr[index].isDeleted && msgInfoArr[index].isDeleted === true) {
          strMsgDiv += '<span class="chatmsgblob chatmsgblobright deletedblog pointer" id="incoming-bubble-'+msgInfoArr[index].messageID+'">';
          strMsgDiv += 'This message has been deleted';
        } else if (msgInfoArr[index].isEdited && msgInfoArr[index].isEdited === true && (msgInfoArr[index].isReplied === undefined || msgInfoArr[index].isReplied === false)) {
          strMsgDiv += '<span class="chatmsgblob chatmsgblobright pointer" id="incoming-bubble-'+msgInfoArr[index].messageID+'">';
          strMsgDiv += sanitizeHTML(msgInfoArr[index].message);
          strMsgDiv += getEditedHTML();
        } else if (msgInfoArr[index].isReplied && msgInfoArr[index].isReplied === true) {
          strMsgDiv += '<span class="chatmsgblob chatmsgblobright pointer" id="incoming-bubble-'+msgInfoArr[index].messageID+'">';
          strMsgDiv += getRepliedMsgHTML(msgInfoArr[index].replyMessage);
          strMsgDiv += '<hr class="divider-cls"/>'
          strMsgDiv += sanitizeHTML(msgInfoArr[index].message);
          if (msgInfoArr[index].isEdited && msgInfoArr[index].isEdited === true)
            strMsgDiv += getEditedHTML();
        } else {
          strMsgDiv += '<span class="chatmsgblob chatmsgblobright pointer" id="incoming-bubble-'+msgInfoArr[index].messageID+'">';
          strMsgDiv += sanitizeHTML(msgInfoArr[index].message);
        }
        strMsgDiv += '</span>';
        strMsgDiv += '&nbsp;&nbsp';
        strMsgDiv += '</span>';
      }
      else{
        strMsgDiv += '<span class="d-flex">'; //idright
        if (msgInfoArr[index].isDeleted && msgInfoArr[index].isDeleted === true) {
          strMsgDiv += '<span class="chatmsgblob chatmsgblobleft deletedblog" id="msg-bubble-'+msgInfoArr[index].messageID+'">';
          strMsgDiv += 'This message has been deleted';
        } else if (msgInfoArr[index].isEdited && msgInfoArr[index].isEdited === true && (msgInfoArr[index].isReplied === undefined || msgInfoArr[index].isReplied === false)) {
          strMsgDiv += '<span class="chatmsgblob chatmsgblobleft pointer" id="msg-bubble-'+msgInfoArr[index].messageID+'">';
          strMsgDiv += sanitizeHTML(msgInfoArr[index].message);
          strMsgDiv += getEditedHTML();
        } else if (msgInfoArr[index].isReplied && msgInfoArr[index].isReplied === true) {
          strMsgDiv += '<span class="chatmsgblob chatmsgblobleft pointer" id="msg-bubble-'+msgInfoArr[index].messageID+'">';
          strMsgDiv += getRepliedMsgHTML(msgInfoArr[index].replyMessage);
          strMsgDiv += '<hr class="divider-cls"/>'
          strMsgDiv += sanitizeHTML(msgInfoArr[index].message);
          if (msgInfoArr[index].isEdited && msgInfoArr[index].isEdited === true)
            strMsgDiv += getEditedHTML();
        } else {
          strMsgDiv += '<span class="chatmsgblob chatmsgblobleft pointer" id="msg-bubble-'+msgInfoArr[index].messageID+'">';
          strMsgDiv += sanitizeHTML(msgInfoArr[index].message);
        }
        strMsgDiv += '</span>';        
        strMsgDiv += '&nbsp;&nbsp';
        /**TP-3609 & TP-3200 */
        if (msgInfoArr[index].myPicture !== undefined && msgInfoArr[index].myPicture !== null && msgInfoArr[index].myPicture !== "") {
          strMsgDiv += '<span class="chatidnewblob idnewright">';
          strMsgDiv += '<img class="chat-pic-new" src="'+msgInfoArr[index].myPicture+'"}/>';
        } else {
          strMsgDiv += '<span class="chatidblob idright">';
          strMsgDiv += msgInfoArr[index].firstName.slice(0,1).toUpperCase();
          strMsgDiv += msgInfoArr[index].lastName.slice(0,1).toUpperCase();
        }
        strMsgDiv += '</span>';
        strMsgDiv += '&nbsp;&nbsp';        
        strMsgDiv += '</span>';
      }

      strMsgDiv += '</span>';
      strMsgDiv += '</div>';
    }
    return strMsgDiv;
  }

  addEditDiv = () => {
    let strEdit = '<div>';
    let commentText =   <I18n>
                            {({ i18n }) => <input 
                                    className="textareaIM" 
                                    placeholder={i18n._(t`session.chatComment`)}
                                    required
                                    autoFocus={true}
                                />
                            }
                        </I18n>


  	strEdit += commentText;
    strEdit += '<a id="sendChatBtn">'; //</span>';onClick={() => onSendChatMsg()}
    strEdit += '<i class="fas fa-paper-plane sendchatbtn"></i>'; //</span>';
    strEdit += '</a>';
    strEdit += '</div>';

  	return strEdit;
  }

  displayChatWindow = () => {
  	let chatElement = null, chatElementParent = null, chatInputElement = null;
  	let elemList = document.getElementsByClassName('chatreadonlynew');
    let topsection = document.getElementsByClassName('chattopsection');
  	if(elemList[0] !== null){
  		chatElement = elemList[0];
    }
    if(chatElement !== null && chatElement !== undefined){
        chatElement.style.position = 'relative';
        //   chatElement.style.width = this.props.chatWidth + 'px';
        chatElement.classList.add("col-12", "my-0", "mt-1", "mb-0", "pl-1", "pr-0", "pt-1", "pb-3");
        //   chatElement.style.height = this.props.chatHeight + 'px';
        chatElement.style.display = 'block';

        //   chatElement.style.left = this.props.chatLeft + 'px';
        //   chatElement.style.top = this.props.chatTop + 'px';

        elemList = document.getElementsByClassName('chatwindownew');
        let inputElemList = document.getElementsByClassName('chatInputBar');
        if(elemList[0] !== null && inputElemList[0] !== null){
            chatElementParent = elemList[0];
            chatInputElement = inputElemList[0];

            chatElementParent.style.position = 'absolute';
            // chatElementParent.style.width = this.props.chatWidth;
            chatElementParent.classList.add("col-3", "m-0", "p-0");
            // chatElementParent.style.height = this.props.chatHeight;
            chatElementParent.style.top = this.props.isSessionChat ? "0px" : "146px";
            chatElementParent.style.height = this.props.chatHeight+"px";
            // chatElementParent.style.left = this.props.chatLeft;
            //console.log(chatElement.offsetWidth);
            if (this.props.isSessionChat)
              chatElementParent.style.setProperty ("right", '0px', "important");
              //chatElementParent.style.right = '0px';
            else {
              const parentRight = Math.floor((window.innerWidth - chatElementParent.offsetWidth)/2)
              chatElementParent.style.right = parentRight+'px';
              chatElementParent.style.bottom = '120px';
            }
            // chatElementParent.style.top = this.props.chatTop;
            chatElementParent.style.display = 'block';

            chatInputElement.style.position = 'absolute';
            // chatInputElement.style.bottom = '10px';
        }
        if (topsection[0] !== null && topsection[0] !== undefined /* && this.props.bPrivate */)
          chatElement.style.height = (chatElementParent.offsetHeight - topsection[0].offsetHeight - chatInputElement.offsetHeight - 10 - 22) + 'px';//10 for bottom of input bar and 22 is arbitrary to adjust the scrolling -- TP-87
        else
          chatElement.style.height = (chatElementParent.offsetHeight - chatInputElement.offsetHeight - 10 - 22) + 'px';//10 for bottom of input bar and 22 is arbitrary to adjust the scrolling -- TP-87
    	if(!this.props.bShowChat){
    		chatElement.style.display = 'none';
            chatElementParent.style.display = 'none';
            chatInputElement.style.display = 'none';
    	}else{ // show the chat window
            // push the latest chat array and the edit control
            let chatContents = '';
            chatContents += this.getMessagesHTML();
  	        //chatContents += this.addEditDiv();

            chatElement.innerHTML = chatContents;
            // show the chat window
            chatElement.style.display = 'block';
            chatElementParent.style.display = 'block';
            chatInputElement.style.display = 'block';
          if(document.getElementById('lastmsgdiv') !== null)
            document.getElementById('lastmsgdiv').scrollIntoView(false);
          //TP-5200
            chatElement.scrollTo({
              top: chatElement.scrollHeight,
              behavior: 'smooth'
          });
    	}
    }
    this.addToolbarForAllMyChatElements();
    let elemInput = document.getElementsByClassName('textareaIM');//MB2-482
    if(elemInput[0] !== null && elemInput[0] !== undefined){
        elemInput[0].focus();
    }
    this.addClickEventBubble();
  }

  addToolbarForAllMyChatElements = () => {
    let msgInfoArr = this.props.chatMsgInfoArr;
    for(let index = 1; index < msgInfoArr.length; index++){
      if(msgInfoArr[index].msgType !== 0){
        let chatElement = document.getElementById("msg-bubble-"+msgInfoArr[index].messageID);
        if (chatElement && chatElement !== null) {
          chatElement.append(this.createToolSection(msgInfoArr[index]))
        }
      } else {
        let chatElement = document.getElementById("incoming-bubble-"+msgInfoArr[index].messageID);
        if (chatElement && chatElement !== null) {
          //chatElement.parentNode.insertBefore(this.createIncominToolSection(msgInfoArr[index]), chatElement.nextSibling)
          chatElement.append(this.createIncominToolSection(msgInfoArr[index]))
        }
      }
    }
  }
 
  addClickEventBubble = () => {
    const allBubbleEle = document.querySelectorAll('[id^="msg-bubble-"]');
    if (allBubbleEle && allBubbleEle !== null && allBubbleEle.length > 0) {
      for(let ii=0; ii<allBubbleEle.length; ii++) {
        const myId = allBubbleEle[ii].id;
        allBubbleEle[ii].addEventListener('click', function (e) {
          const myId = e.target.id.split("-")[2];
          const toolBarEle = document.getElementById("tool-bubble-"+myId);
          if (toolBarEle && toolBarEle !== null && toolBarEle.classList.contains("d-none") === true) {
            toolBarEle.classList.remove("d-none");
          } else if (toolBarEle && toolBarEle !== null && toolBarEle.classList.contains("d-none") === false) {
            toolBarEle.classList.add("d-none");
          }
        })
      }
    }

    const allInBubbleEle = document.querySelectorAll('[id^="incoming-bubble-"]');
    if (allInBubbleEle && allInBubbleEle !== null && allInBubbleEle.length > 0) {
      for(let ii=0; ii<allInBubbleEle.length; ii++) {
        const myId = allInBubbleEle[ii].id;
        allInBubbleEle[ii].addEventListener('click', function (e) {
          const myId = e.target.id.split("-")[2];
          const toolBarEle = document.getElementById("tool-bubble-"+myId);
          if (toolBarEle && toolBarEle !== null && toolBarEle.classList.contains("d-none") === true) {
            toolBarEle.classList.remove("d-none");
          } else if (toolBarEle && toolBarEle !== null && toolBarEle.classList.contains("d-none") === false) {
            toolBarEle.classList.add("d-none");
          }
        })
      }
    }
  }

  componentWillReceiveProps(nextProps){
    for(let i=0; i<= nextProps.chatMsgInfoArr.length; i++) {
      if(nextProps.chatMsgInfoArr[i] && nextProps.chatMsgInfoArr[i].isEdited && nextProps.chatMsgInfoArr[i].isEdited !== this.props.chatMsgInfoArr[i].isEdited && nextProps.chatMsgInfoArr[i].isEdited === true) {
        //console.log("One message bubble edited");
        this.displayChatWindow();
      }
    }
  }

  componentDidMount = () =>{
    this.displayChatWindow();
    window.addEventListener("resize", this.displayChatWindow);   
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.bShowChat !== prevProps.bShowChat || this.props.chatMsgInfoArr !== prevProps.chatMsgInfoArr || this.props.bPrivate !== prevProps.bPrivate){
        this.displayChatWindow();
    }
    if(this.props.elemId !== prevProps.elemId && this.props.bShowChat === true && this.props.bPrivate === true) {
      this.displayChatWindow();
    }
    if(this.props.startTimer !== prevProps.startTimer && this.props.startTimer === true) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.displayChatWindow);
  }

  startTimer = () => {
      this.setState({ timerStart: true });
      this.timeOut = setTimeout(() => {
          this.props.updateChatFlags(false);
          this.stopTimer();
      }, this.state.timeOutPeriod);
  }

  stopTimer = () => {
      this.setState({ timerStart: false });
      //reset timeout
      clearTimeout(this.timeOut);
      this.timeOut = null;
  }

  onClickReply = (msgInfo) => {
    this.setState({ replyMessageInfo: msgInfo, mode: 'reply' }, () => {
      let elemInput = document.getElementsByClassName('textareaIM');//MB2-482
      if(elemInput[0] !== null && elemInput[0] !== undefined){
          elemInput[0].value = '';
          elemInput[0].focus();
      }
    })
  }

  cloneChatMsg = (copied_msg) => {
    this.setState({ editMessageId: null, mode: 'send' }, () => {
      let elemInput = document.getElementsByClassName('textareaIM');//MB2-482
      if(elemInput[0] !== null && elemInput[0] !== undefined){
          elemInput[0].value = copied_msg;
          elemInput[0].focus();
      }
    })
  }

  onClickEditMsg = (editMessageId, old_message) => {
    this.setState({ editMessageId, mode: 'edit' }, () => {
      let elemInput = document.getElementsByClassName('textareaIM');//MB2-482
      if(elemInput[0] !== null && elemInput[0] !== undefined){
          elemInput[0].value = old_message;
          elemInput[0].focus();
      }
    })
  }

  createIncominToolSection = (msgInfo) => {
    //console.log("Create incoming toolbar section");
    let rtnDiv = document.createElement('div');
    rtnDiv.id = 'tool-bubble-'+msgInfo.messageID;
    rtnDiv.classList.add('action', 'bubble', 'bubble-left', 'd-none');
    let rtnSpan = document.createElement('span');
    rtnSpan.classList.add('icon-wrapper', 'pointer');
    let rtnAnchor = document.createElement('a');
    rtnAnchor.onclick = function (e) {
      chatObject.cloneChatMsg(msgInfo.message);
    }
    let rtnIcon = document.createElement('i');
    rtnIcon.classList.add('far', 'fa-clone');
    rtnAnchor.appendChild(rtnIcon);
    rtnSpan.appendChild(rtnAnchor);
    rtnDiv.appendChild(rtnSpan);
    rtnSpan = document.createElement('span');
    rtnSpan.classList.add('icon-wrapper', 'pointer');
    rtnAnchor = document.createElement('a');
    rtnAnchor.onclick = function (e) {
      chatObject.onClickReply(msgInfo);
    }
    rtnIcon = document.createElement('i');
    rtnIcon.classList.add('fas', 'fa-reply');
    rtnAnchor.appendChild(rtnIcon);
    rtnSpan.appendChild(rtnAnchor);
    rtnDiv.appendChild(rtnSpan);
    return rtnDiv;
  }

  createToolSection = (msgInfo) => {
    //console.log(msgInfo);
    let rtnDiv = document.createElement('div');
    rtnDiv.id = 'tool-bubble-'+msgInfo.messageID;
    rtnDiv.classList.add('action', 'bubble', 'd-none');
    let rtnSpan = document.createElement('span');
    rtnSpan.classList.add('icon-wrapper', 'pointer');
    let rtnAnchor = document.createElement('a');
    rtnAnchor.onclick = function (e) {
      chatObject.onClickEditMsg(msgInfo.messageID, msgInfo.message);
    }
    let rtnIcon = document.createElement('i');
    rtnIcon.classList.add('fas', 'fa-edit');
    rtnAnchor.appendChild(rtnIcon);
    rtnSpan.appendChild(rtnAnchor);
    rtnDiv.appendChild(rtnSpan);
    rtnSpan = document.createElement('span');
    rtnSpan.classList.add('icon-wrapper', 'pointer');
    rtnAnchor = document.createElement('a');
    rtnAnchor.onclick = function (e) {
      chatObject.cloneChatMsg(msgInfo.message);
    }
    rtnIcon = document.createElement('i');
    rtnIcon.classList.add('far', 'fa-clone');
    rtnAnchor.appendChild(rtnIcon);
    rtnSpan.appendChild(rtnAnchor);
    rtnDiv.appendChild(rtnSpan);    
    rtnSpan = document.createElement('span');
    rtnSpan.classList.add('icon-wrapper', 'pointer');
    rtnAnchor = document.createElement('a');
    rtnAnchor.onclick = function (e) {
      chatObject.onClickReply(msgInfo);
    }
    rtnIcon = document.createElement('i');
    rtnIcon.classList.add('fas', 'fa-reply');
    rtnAnchor.appendChild(rtnIcon);
    rtnSpan.appendChild(rtnAnchor);
    rtnDiv.appendChild(rtnSpan);
    rtnSpan = document.createElement('span');
    rtnSpan.classList.add('icon-wrapper', 'pointer');
    rtnAnchor = document.createElement('a');
    rtnAnchor.onclick = function (e) {
      //chatObject.props.deleteChatMsg(msgInfo.messageID);
      chatObject.onSendDeleteChat(msgInfo.messageID);
    }
    rtnIcon = document.createElement('i');
    rtnIcon.classList.add('far', 'fa-trash-alt');
    rtnAnchor.appendChild(rtnIcon);
    rtnSpan.appendChild(rtnAnchor);
    rtnDiv.appendChild(rtnSpan);
    return rtnDiv;
    
  }

  render(){
    //console.log('inside NewChat render method - chatWidth, this.props.bShowChat = ', this.props.chatWidth, this.props.bShowChat);
    // if(this.props.chatWidth !== 0)
    //   this.displayChatWindow();
    const { mode, editMessageId, replyMessageInfo } = this.state;
    const { enable_streaming, recipientUser, isSmartAdmin, isBusy, 
        isPartOfAGroup, isOnline, adminemailid, isVisible, noInternet,
        isConnected, isMonthlyMinutesConsumed, isExpert, isAdmin, 
        deviceAvailable, isSmallFormFactor, selectedGroup, userid,
        audio_mode, webcamAvailable, micAvailable, onlineUsers} = this.props;
  	return(
  		<div className='chatwindownew'>
            { !this.props.isSessionChat || (this.props.isSessionChat && this.props.isGroupChat) ?
                this.props.bPrivate ?
                <div className='chattopsection'>
                  <div className='topsectionleft row no-gutters'>
                    <span className="col-2"> 
                    { this.props.recipientImage !== undefined && this.props.recipientImage !== null ?
                      <img className="session-profile-pic profile-image profile-pic profile-cursor" alt="profile_logo" src={this.props.recipientImage} ></img>
                      :
                      <img className="session-profile-pic profile-image profile-pic profile-cursor" src={"/profile.png"} alt='profile_logo' />
                    }
                    </span>
                    <span className="col-7 topname">
                      {/*  this.props.recipientUser !== null && this.props.recipientUser.isexpert ?
                          <><i className="fas fa-asterisk fa-xs"></i>&nbsp;&nbsp;</>
                          :
                          ''
                      */}
                      {this.props.recipientname ? this.props.recipientname : this.props.recipientUser.label}
                      { this.props.isGroupChat === false && this.props.isSessionChat === false && this.props.recipientUser !== null && this.props.recipientUser.isexpert !== undefined?
                        <span className='badge-type'>
                          {this.props.getUserType(this.props.recipientUser)}
                        </span>
                        :
                        ''
                      }
                    </span>
                    <span className="col-2">
                        { /*TP-2790*/ enable_streaming === true ?
                            <IconCallUser selectedUser={recipientUser.email} /**TP-5277*/ isSmartAdmin={isSmartAdmin} row={recipientUser} isBusy={isBusy} isVisible={/*TP-4814*/ isPartOfAGroup && isOnline && (recipientUser.email !== adminemailid) && isVisible /* && isSmartvision */} onClickUserCall={this.props.onClickUserCall} noInternet={noInternet} isConnected={isConnected} deviceAvailable={deviceAvailable} isMonthlyMinutesConsumed={isMonthlyMinutesConsumed} isExpert={isExpert } isAdmin={isAdmin} isSmallFormFactor={isSmallFormFactor} /**TP-4327*/ />
                            :
                            ''
                        }
                    </span>
                  </div>
                  <hr className='chattop-seperator'></hr>
                </div>
                :
                <div className='chattopsection'>
                  <div className='topsectionleft row no-gutters'>
                    {/* <span className="col-2"> 
                    { this.props.recipientImage !== undefined && this.props.recipientImage !== null ?
                      <img className="session-profile-pic profile-pic profile-cursor" alt="profile_logo" src={this.props.recipientImage} ></img>
                      :
                      <img className="session-profile-pic profile-pic profile-cursor" src={"/profile.png"} alt='profile_logo' />
                    }
                    </span> */}
                    <span className="col-8 topname" style={this.props.isGroupChat ? {marginLeft: "10px"} : {}}>
                      {this.props.isGroupChat ? selectedGroup.group_name : this.props.recipientname }
                    </span>
                    <span className="col-2">
                      { isSmartAdmin === false && enable_streaming === true && isExpert /*TP-2790*/ ?
                          <RenderVideoIcon row={selectedGroup} isexpert={isExpert} isadmin={isAdmin}
                              onlineUsers={onlineUsers} isSmallFormFactor={isSmallFormFactor} 
                              email={adminemailid} audio_mode={audio_mode} noInternet={noInternet} 
                              isConnected={isConnected} isMonthlyMinutesConsumed={isMonthlyMinutesConsumed}
                              webcamAvailable={webcamAvailable} micAvailable={micAvailable} 
                              getExperts={this.props.getExperts} getUsers={this.props.getUsers} 
                              userid={userid} onClickGroupCall={this.props.onClickGroupCall}
                          />
                          :
                          ''
                      }
                      {/* <span className="icon-wrapper chattopicon pointer"><i class="fas fa-video"></i></span> */}
                    </span>
                  </div>
                  <hr className='chattop-seperator'></hr>
                </div>
              :
              ''
            }
            <div className='chatreadonlynew'></div>
            <div className='chatInputBar' style={mode === 'reply' ? {height: "fit-content"} : {height: "40px"}}>
                { mode === 'reply' && replyMessageInfo !== null ?
                  <div className='chatReplyBubble'>
                    <span className='chatreplyspanname'>
                      { replyMessageInfo.firstName+" "+replyMessageInfo.lastName }
                    </span>
                    <span className='replyclosebtn' onClick={(e) => onCloseReplyMode(e)}>
                      <i class="fas fa-times"></i>
                    </span>
                    <br/>
                    <span className='chatreplyspanmessage'>
                      { replyMessageInfo.message }
                    </span>
                  </div>
                  :
                  ''
                }
                <I18n>
                    {({ i18n }) => <input 
                            className="textareaIM" 
                            placeholder={i18n._(t`session.chatComment`)}
                            autoFocus={true}
                            onKeyPress={(event) => onKeyPress(event)}
                        />
                    }
                </I18n>          
                <ChatSendInputBox mode={mode} editMessageId={editMessageId} replyMessageId={replyMessageInfo !== null ? replyMessageInfo.messageID : null} />
            </div>
  		</div>
  	);
  }

}

const ChatSendInputBox = ({mode, editMessageId, replyMessageId}) => {
  //console.log("mode is: ", mode);
  if (mode === 'edit') {
    return (
      <span>
          <I18n>
              {({ i18n }) =>
                  <a title={i18n._(t`session.chatSend`)} onClick={() => onEditChat(editMessageId)}>
                      <i className="fas fa-paper-plane sendchatbtn"></i>
                  </a>
              }
          </I18n>
      </span>
    )
  } else if (mode === 'reply') {
    return (
      <span>
          <I18n>
              {({ i18n }) =>
                  <a title={i18n._(t`session.chatSend`)} onClick={() => onSendReplyChat(replyMessageId)}>
                      <i className="fas fa-paper-plane sendchatbtn"></i>
                  </a>
              }
          </I18n>
      </span>
    )
  } else {
    return (
      <span>
          <I18n>
              {({ i18n }) =>
                  <a title={i18n._(t`session.chatSend`)} onClick={() => onSendChat()}>
                      <i className="fas fa-paper-plane sendchatbtn"></i>
                  </a>
              }
          </I18n>          
                      
      </span>
    )
  }
}

const RenderVideoIcon = ({ row, isexpert, isadmin, onlineUsers, isSmallFormFactor, 
    email, audio_mode, noInternet, isConnected, isMonthlyMinutesConsumed, 
    webcamAvailable, micAvailable, getExperts, getUsers, userid, onClickGroupCall}) => {
  const isViewable = row.users.find(e => e.value === userid);
  let isOnlineUser = false;
  if (isexpert) {
      isOnlineUser = getUsers(row).find(e => onlineUsers.includes(e.email));
  } else {
      //TP-3303 -- Implementation of the tech2tech call feature
      if (audio_mode === "tech2Tech")
          isOnlineUser = row.users.filter(u => u.email !== email).find(e => onlineUsers.includes(e.email));
      else
          isOnlineUser = getExperts(row).find(e => onlineUsers.includes(e.email));
  }
  //console.log (isOnlineUser);
  if (isViewable && ((webcamAvailable && !isexpert ) || (micAvailable && isexpert )) && isOnlineUser && !noInternet && isConnected && !isMonthlyMinutesConsumed) {
      return (
          <I18n>
              {({ i18n }) =>
                  <>
                      { isSmallFormFactor === false ?
                          <span className="icon-wrapper chattopicon pointer" data-rh={i18n._(t`Start the session`)} onClick={() => onClickGroupCall({action: 'play', selectedGroup: row})}>
                              <i className="fas fa-video "></i>
                          </span>
                          :
                          <span className="icon-wrapper chattopicon pointer" onClick={() => onClickGroupCall({action: 'play', selectedGroup: row})}>
                              <i className="fas fa-video "></i>
                          </span>
                      }
                  </>
              }
          </I18n>
      );
  }else if (isViewable && !isOnlineUser) {
      return (
          <I18n>
              {({ i18n }) =>
                  <>
                      { isSmallFormFactor === false ?
                          <span className="icon-wrapper chattopicon pointer" data-rh={i18n._(t`No user is online`)}>
                              <i className="fas fa-video fa-video-disabled "></i>
                          </span>
                          :
                          <span className="icon-wrapper chattopicon pointer" >
                              <i className="fas fa-video fa-video-disabled "></i>
                          </span>
                      }
                  </>
              }
          </I18n>
      );
  }else if (isViewable /*TP-1165*/ && !webcamAvailable && !(isadmin && !isexpert)) {
      return (
          <I18n>
              {({ i18n }) =>
                  <span className="icon-wrapper chattopicon pointer" >
                      { isSmallFormFactor === false ?
                          <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'chat-video'}>
                              { isexpert ?
                                  <Trans>MicrophoneNotFoundError</Trans>
                                  :
                                  <Trans>DevicesNotFoundError</Trans>
                              }
                          </UncontrolledTooltip>                            
                          :
                          ''
                      }
                      <i className="fas fa-video fa-video-disabled " id='chat-video'></i>
                  </span>
              }
          </I18n>
      );
  }else if (isViewable /*TP-1165*/ && noInternet || !isConnected){
      return (
          <I18n>
              {({ i18n }) =>
                  <>
                      { isSmallFormFactor === false ?
                          <span className="icon-wrapper chattopicon pointer" data-rh={i18n._(t`No user is online`)}>
                              <i className="fas fa-video fa-video-disabled "></i>
                          </span>
                          :
                          <span className="icon-wrapper chattopicon pointer" >
                              <i className="fas fa-video fa-video-disabled "></i>
                          </span>
                      }
                  </>
              }
          </I18n>
      );
  }else if (isViewable /*TP-1165*/ && isMonthlyMinutesConsumed){
      return (
          <I18n>
              {({ i18n }) =>
                  <span className="icon-wrapper chattopicon pointer">
                      { isSmallFormFactor === false ?
                          <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target='chat-video'>
                              {
                                  <Trans>Monthly minutes over</Trans>                                     
                              }
                          </UncontrolledTooltip>
                          :
                          ''
                      }
                      <i className="fas fa-video fa-video-disabled " id='chat-video'></i>
                  </span>
              }
          </I18n>
      );
  }else if (!isViewable /*TP-1165*/ && !(isadmin && !isexpert) /**TP-4814 -- use case 2*/) {
      return (
          <I18n>
              {({ i18n }) =>
                  <span className="icon-wrapper chattopicon pointer">
                      { isSmallFormFactor === false ?
                          <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target='chat-video'>
                              {
                                  <Trans>No common group found</Trans>                                     
                              }
                          </UncontrolledTooltip>
                          :
                          ''
                      }
                      <i className="fas fa-video fa-video-disabled " id='chat-video'></i>
                  </span>
              }
          </I18n>
      );
  }else{
      return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
  }
}

const IconCallUser = (props) => (
  // props.isVisible already is taking care of props.noInternet so changing the below condition for props.isMonthlyMinutesConsumed//T32-462
  <>
      {props.isVisible && props.isSmartAdmin === false ? 
          <>
              {   (!props.isMonthlyMinutesConsumed && props.deviceAvailable)?                        
                      /*TP-4228*/props.isExpert || props.isAdmin ? 
                          <>
                                  <span id={"icon-phone"} className={"icon-wrapper chattopicon pointer " + (props.isBusy ? "text-danger disabled" : "")} onClick={() => !props.isBusy && props.onClickUserCall({ action: 'callUser', row: props.row })}>
                                      <i className={props.isBusy ? "fas fa-phone-slash" : "fas fa-phone"}></i>
                                  </span>
                                  { props.isSmallFormFactor === false ?
                                      <UncontrolledTooltip placement="top"  target={"icon-phone"}>
                                          {
                                              !props.isBusy ? <Trans>Call</Trans> : /*TP-5277*/ props.selectedUser !== props.row.email ?
                                              <Trans>User is already on a call with another user, please try again later.</Trans> : /**TP-5277 */
                                              <Trans>User is attempting to make a call, please try later.</Trans>
                                          }
                                      </UncontrolledTooltip>
                                      :
                                      ''
                                  }
                          </>
                                  :
                          <>
                              {/**TP-4228*/}
                                  <span id="icon-phone" className="icon-wrapper chattopicon pointer" onClick={() => props.onClickUserCall({ action: 'callUser', row: props.row })}>
                                      <i className="fas fa-phone"></i>
                                  </span>
                                  { props.isSmallFormFactor === false ?
                                      <UncontrolledTooltip placement="top"  target="icon-phone">
                                          {
                                              <Trans>Call</Trans>
                                          }
                                      </UncontrolledTooltip>
                                      :
                                      ''
                                  }
                          </>
                      
                      :
                          <>
                              <span id="icon-phone" className="icon-wrapper chattopicon">
                                  <i className="fas fa-phone fa-video-disabled"></i>
                              </span>
                              { props.isSmallFormFactor === false ?
                                  <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target='icon-phone'>
                                      {
                                          props.deviceAvailable ? <Trans>Monthly minutes over</Trans> :
                                          (props.isExpert) ? 
                                              <Trans>MicrophoneNotFoundError</Trans>
                                              :
                                              <Trans>DevicesNotFoundError</Trans>
                                      }
                                  </UncontrolledTooltip>
                                  :
                                  ''
                              }
                          </>
              }
          </>
      :
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      }
  </>
);

const sanitizeHTML = function (str) {
  //console.log(str)
  let words = str.split(" ");
  words = handleOverflow(words); //TP-4110
  let newStr = words.join(" ");
	var temp = document.createElement('div');
	temp.textContent = newStr;
  //console.log(temp.innerHTML);
	return temp.innerHTML;
};

const getRepliedMsgHTML = function (replyMessageInfo) {
  let strDiv = '<div class="chatreplyblob"><span class="chatreplyspanname">'
  strDiv += replyMessageInfo.firstName+' '+replyMessageInfo.lastName+'</span><br/>';
  strDiv += '<span class="chatreplyblobmessage">'+replyMessageInfo.replyMessage+'</span></div>';
  return strDiv;
}

const getEditedHTML = function () {
  let strDiv = '<br><span class="edited-section">Message has been edited</span>';
  return strDiv;
}

//TP-4110
const handleOverflow = function (words) {
  let new_words = words.map(word => {
    if (word.length > maxWordLimit) {
      let num = parseInt(word.length / maxWordLimit);
      //console.log(num);
      let new_word = ""
      for (let i=0; i<=num; i++){
        new_word += word.slice(i*maxWordLimit, (i+1)*maxWordLimit);
        new_word += " ";
      }
      //console.log(new_word)
      word = new_word;
    }
    return word;
  });
  return new_words;
}

export default NewChat;