
import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import Button from '../Button/Button';
import './PurchaseUserLimit.scss';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";

class PurchaseUserLimit extends React.Component {
    constructor() {
        super();
        this.state = {
            plans: [],
            selectedPlan: {},
            limits: 1
        }
    }

    onChangeQuntity = (value, event) => {
        event.preventDefault();
        if (value === -1 && this.state.limits === 1) return;
        this.setState(prev => ({ limits: prev.limits + value }));
    }

    componentWillMount = () => {
        this.setState({
            plans: this.props.plans
        });
    }

    onPurchase= (e) => {
        e.preventDefault();
        this.props.onPurchase({
            plan: this.state.selectedPlan,
            licenses: this.state.limits
        });
    }

    handleChange = (newValue) => {
        this.setState({ selectedPlan: newValue });
    }

    render = () => {
        const { headerText, onClose, startDate, endDate } = this.props;
        const dateDiff = Math.floor((new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000)
        const currDiff = Math.floor((new Date(endDate).getTime() - Math.floor(new Date().getTime())) / 1000);
        const perOneMinute = (this.state.selectedPlan.price || 0) / dateDiff;
        // const amountToBeCharged =  (this.state.limits * (perOneMinute * currDiff)).toFixed(2);
        // No need to show prorated price NS2-206
        const amountToBeCharged =  (this.state.selectedPlan.price * this.state.limits);
        const amount = '$ ' + (amountToBeCharged || 0);
        const numberOfLicenses = this.state.limits;
        return (
            <div className='modale opened user-units-container'>
                <div className='__modal-dialog'>
                    <div className="__modal-header">
                        <h4>{headerText}</h4>
                        <span className="btn-close"  onClick={(e) => onClose(e)}>&times;</span>
                    </div>
                    <div className="__modal-body">
                        <div style={{marginTop: 5}}>
                            <I18n>
                                {({ i18n }) =>
                                    <Select
                                        components={makeAnimated()}
                                        onChange={this.handleChange}
                                        placeholder={i18n._(t`Select a plan`)}
                                        options={this.state.plans.filter(e => e.code !== 'plan_trial').map(p => { return { value: p.id, label: p.name + '- '  + '$' + p.price, price: p.price, name: p.name }})}
                                    />
                                }
                            </I18n>
                        </div> 
                        <div className="item">
                            <h4><Trans>User Count</Trans></h4>
                            <h4><Trans>Amount</Trans></h4>
                        </div>
                        <div className="item">
                            <div className="quantity">
                                <button className="plus-btn" type="button" name="button-dic" onClick={(e) => this.onChangeQuntity(-1, e)}>
                                    <i className="fas fa-minus" aria-hidden="true"></i>
                                </button>
                                <input type="text" name="name" readOnly={true} value={this.state.limits} />
                                <button className="minus-btn" type="button" name="button-inc" onClick={(e) => this.onChangeQuntity(+1, e)}>
                                    <i className="fas fa-plus" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="total-price">
                                ${ amountToBeCharged || 0 }
                            </div>
                        </div>
                        <h5 className="h5-text">
                            <Trans id="You will be charged {amount} for {numberOfLicenses} license(s) from your default card." values={{amount: amount, numberOfLicenses: numberOfLicenses}}/>
                        </h5>
                    </div>
                    <div className="__modal-footer flex-center">
                        <Button showSpinner={this.props.isLoading} disabled={!this.state.selectedPlan.price} onClick={(e) => this.onPurchase(e)}><Trans>Purchase</Trans></Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default PurchaseUserLimit
