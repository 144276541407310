import React from 'react';
import AuthService from './AuthService';
import { tabVisibility, printConsole } from './helpers';

const AccountContext = React.createContext();
export const AccountConsumer = AccountContext.Consumer;

class AccountProvider extends React.Component {
    authService = new AuthService();
    state = {
        language: ['en', 'zh-cn'].includes(navigator.language.toLowerCase()) ? navigator.language : 'en',
        setLanguage: language => this.setLanguage(language),
        updateDetails: updatedDetails => this.updateDetails(updatedDetails),
        updateUserIdUploadImage: (val) => this.updateUserIdUploadImage(val),//TP-3483
        subscribeToNewImageUploadNotification: () => this.subscribeToNewImageUploadNotification(),
        /** Method to subscribe the online status of users under the account */
        subscribeToOnlineNotification: () => this.subscribeToOnlineNotification(),
        upload_image_user_list: [],
        onlineUsers: [],
        tabVisible: true,
        noInternet: false,
        isConnected: true,
    }

    updateDetails = (updatedDetails) => {
        this.setState((prev) => ({
            ...prev,
            ...updatedDetails
        }))
    }

    checkLanguageSupport = (localStorageLang) => {
        // let supportedLanguage = ['fr', 'en-us', 'en', 'zh-cn', 'de', 'it', 'ja', 'ko', 'pl', 
        //                         'pt', 'ru', 'es', 'ja-jp'];
        let defaultLanguage = navigator.language;
        const langFlag = this.tryRequire(defaultLanguage)
        if ( localStorageLang != null || localStorageLang != undefined){
            return localStorageLang;
        }else if (langFlag !== null) {
            return defaultLanguage;
        } else {
            return 'en';
        }
    }

    tryRequire = (language) => {
        try {
            return require(`../locales/${language}/messages.js`);
        } catch (err) {
            return null;
        }
    };

    //TP-3483
    updateUserIdUploadImage = (val) => {
        console.log("call the upload user list updated API....", val);//TP-3672
        this.setState({upload_image_user_list: val}, () => {
            this.setState({upload_image_user_list: []});
        });
    }

    subscribeToNewImageUploadNotification = () => {
        this.authService.socket.on('new-image-upload', (data) => {
            console.log("new-image-upload socket signal received ", data);
            this.setState({upload_image_user_list: [data.userId]}, () => {
                this.setState({upload_image_user_list: []});
            });
        })
    }

    subscribeToOnlineNotification = () => {
        this.authService.socket.on('isOnline',  (userNames) => {
            this.setState((prev) => ({
                onlineUsers: userNames
            }))
        });

        this.authService.socket.on('isOffline',  (userName) => {
            this.setState((prev) => ({
                onlineUsers: prev.onlineUsers.filter(e => e !== userName)
            }))
        })
    }

    subscribeToConnectNotification = () => {
        this.authService.socket.on('connect', () => {
            console.log("check 1.2.3", this.authService.socket.connected);
            if (this.authService.socket.connected === true) {
                // TP-1186 -- checking after the user's socket gets connected again
                // whether the previous session ended abruptly or not
                // if yes, then send the end-call & user-is-in-session socket signals again.
                if (typeof(Storage) !== "undefined") {
                    const selectedGroupId = sessionStorage.getItem("groupId"), isonprem = sessionStorage.getItem("isonprem");//FQ3-318,TP-991
                    if (typeof(selectedGroupId) !== "undefined" && selectedGroupId !== null && selectedGroupId !== undefined){
                        printConsole("Sending the user-is-in-session & end-call Socket signals after this user comes online");
                        this.authService.socket.emit('user-is-in-session', { groupId: selectedGroupId, isInSession: false });
                        if(isonprem === 'true'){
                            this.authService.socket.emit('end-call', { groupId: selectedGroupId });
                            this.updatedUsage({session_started: false, selectedGroupId}); //TP-4511
                        }else{
                            this.authService.socket.emit('OT-end-call', { groupId: selectedGroupId });
                        }
                        ['groupId', 'isonprem'].forEach(key =>  sessionStorage.removeItem(key));
                    }
                } else {
                    printConsole("Sorry, your browser does not support Web Storage...");
                }
                /* this.onlineTimer = setTimeout(() => {
                    this.setState((prev) => ({
                        isConnected: true
                    }));
                    clearTimeout(this.onlineTimer);
                }, 7000) */
            }
        })
    }
    
    componentDidMount = () => {
        /** Initialize socket connection */
        this.authService.socketInit();

        /** Method to subscribe to the "connect" event of the socket connection */
        this.subscribeToConnectNotification();
        /** Method to subscribe the online status of users under the account */
        this.subscribeToOnlineNotification();
        /**TP-3385 -- Method to subscribe to user id of those users who updated their profile pictures */
        this.subscribeToNewImageUploadNotification();
        
        this.setState({
            language: this.checkLanguageSupport(localStorage.getItem("language"))
        })

        tabVisibility(() => {
            this.setState({ tabVisible: tabVisibility() ? true : false })
        })
        this.addTrackOnlineListner();    // MB2-577
        this.setState({noInternet: !navigator.onLine})//MB2-577
    }

    componentWillUnmount = () => {
        this.removeTrackOnlineListner();    // MB2-577
    }

    setLanguage = (language) => {
        this.setState({
            language
        },() =>{
            localStorage.setItem("language", language);
        })
    }

    // MB2-577
    addTrackOnlineListner = () => {
        try{
            window.addEventListener('online',  this.updateIndicator);
            window.addEventListener('offline', this.updateIndicator);
        }catch(error){
            printConsole(error);
        }
    }

    // MB2-577
    removeTrackOnlineListner = () => {
        try{
            window.removeEventListener('online',  this.updateIndicator);
            window.removeEventListener('offline', this.updateIndicator);
        }catch(error){
            printConsole(error);
        }
    }

    //TP-4511 -- inform back end session has started/ended
    updatedUsage = ({session_started, selectedGroupId}) => {
        let fetchString = 'webrtcSessionEndpoint';  
        //TP-4511  
        const record_id = sessionStorage.getItem("recordId");
        let payload = {
            groupId: selectedGroupId,
            session_started
        }   
        if (typeof(record_id) !== "undefined" && record_id !== null && record_id !== undefined) {
            payload = {
                groupId: selectedGroupId,
                session_started,
                record_id
            }
        }
        printConsole(`Calling the webrtcSessionEndpoint API...after session`)
        this.authService.fetch(fetchString, {
            method: 'post',
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                //return response.json();
            } else {
                throw response;
            }
        })            
        .catch(err => {
            throw err; 
        })
    }

    // MB2-577
    updateIndicator = (event) => {
        try{
            const noInternet = event.type === 'offline' ? true:false;
            this.setState({noInternet: noInternet})
            if (noInternet === true) this.setState({ isConnected: false });
        }catch(error){
            printConsole(error);
        }
    }
    
    render = () => {
        return <AccountContext.Provider value={this.state}>
            { this.props.children }
        </AccountContext.Provider>
    }
}

export default AccountProvider;
