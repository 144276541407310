import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './FlashMessage.scss';
import { Trans, t } from '@lingui/macro';
import { printConsole } from '../helpers';

class FlashMessage extends Component {
    constructor(props){
        super(props);
        this.state ={
            timerStart: false,
            timerStop: false,
            timeOutPeriod: 10000,
            showMessage: false,
        }
        this.isVisible = false;
        this.timeOut = null;
        //this.showFlashMessage = this.showFlashMessage.bind(this);
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.showMessage !== this.state.showMessage && this.isVisible === false) {
            this.showFlashMessage();
        } else if (this.props.showMessage !== this.state.showMessage && this.props.showMessage === false && this.isVisible === true) {
            //TP-5681 console.log("Trigger close the toast message")
            this.hideFlashMessages();
        } else if (this.props.isTimerEnabled !== prevProps.isTimerEnabled && this.props.isTimerEnabled === true && this.isVisible === true ) {
            this.hideFlashMessages();//TP-6081
        }
    }

    showFlashMessage = () => {
        //hide other flash messages
        this.hideFlashMessages();
        //const flashElem = document.getElementById("flashMessage");
        //flashElem.style.left = this.props.flashLeft;
        //flashElem.style.top = this.props.flashTop;
        //console.log(`flash left margin: ${this.props.flashLeft} & top margin: ${this.props.flashTop}`);
        this.setState({ showMessage: true });
        this.isVisible = true;
        //TP-5681
        if (this.props.isTimerEnabled === true)
            this.startTimer();
    }

    hideFlashMessages = () => {
        if (this.isVisible === true) {
            const flashElem = document.getElementById("flashMessage");
            flashElem.style.left = "";
            flashElem.style.top = "";
            this.hideFlashMessage();
        }
    }

    hideFlashMessage = () => {
        
        this.setState({ showMessage: false });
        this.isVisible = false;
        //TP-5681
        if (this.props.isTimerEnabled === true)
            this.stopTimer();
    }

    startTimer = () => {
        this.setState({ timerStart: true });
        this.timeOut = setTimeout(() => {
            this.props.updateShowMessage(false);
            this.hideFlashMessage();
        }, this.state.timeOutPeriod);
    }

    stopTimer = () => {
        this.setState({ timerStop: false });
        //reset timeout
        clearTimeout(this.timeOut);
        this.timeOut = null;
    }

    render = () => {
        const {flashMsgText, flashValues} = this.props;
        //const divStyle = {left: flashLeft, top: flashTop};
        //console.log(divStyle);
        let showClass = "flash-message js-flash-message ";
        if (this.isVisible)
        //TP-1518 -- Centering the Auto decline messages only
        showClass = "flash-message js-flash-message flash-message--is-visible";
        //TP-5179 & TP-1271
        let style = {};
        if (this.props.flashLeft === "") {
            showClass += " centered"            
            style = {};       
        } else {
            style = { left: this.props.flashLeft, top: this.props.flashTop};
        }
        return (
            <>
                <div className={showClass} id="flashMessage" style={/*TP-1518 & TP-1271*/style}>
                    { flashValues !== undefined && flashValues !== "" ?
                        <Trans id={flashMsgText} values={flashValues} ></Trans>                        
                        :
                        <Trans id={flashMsgText}></Trans>
                    }
                    {/* {flashMsgText} */}
                </div>
            </>
        );
    }
}

FlashMessage.propTypes = {
    showMessage: PropTypes.bool.isRequired,
    flashMsgText: PropTypes.string.isRequired,
    flashLeft: PropTypes.string.isRequired,
    flashTop: PropTypes.string.isRequired,
}

export default FlashMessage;