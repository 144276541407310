import React from 'react';
import AuthService from '../AuthService';
import Loader from 'react-loader-spinner';
import { Trans } from '@lingui/macro';
import { i18nMark } from "@lingui/react";
import {Modal,ModalBody,ModalFooter,Button} from 'reactstrap';
import './GuestLogin.scss'; //TP-3157
const crypto = require('crypto');

class GuestLogin extends React.Component {
	constructor(props){
        super(props);
		this.authService = new AuthService(true);
		this.state = {
            signInEmail: this.decryptData(this.props.email),
			signInGeoLocation: '',
			signInPassword: 'd@$hboard',
			isLoading: false,
            isSignedIn: false, //TP-3157
            isErrorMsg: false, //TP-3157
            isSmallFormFactor: false, //TP-3157
            dialogMsg: '' //TP-3157
        }
    }
    
    componentDidMount = () => {
		this.setState({isLoading: true});
        //TP-3157
        const isSmall = this.checkUserAgent(); 
        this.setState({ isSmallFormFactor: isSmall });
        this.signInGuest();
    }

    //TP-3157
    checkUserAgent = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        this.setState({ isiOS: isiPad });
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let isSmallFormFactor = false;
        if (isiPad || isAndroid) {
            isSmallFormFactor = true;
        } 
        return isSmallFormFactor;
    }

	showLoader = () => {
        if (!this.state.isLoading) return '';
        return(
            <div className="loader">
                <Loader
                    type="ThreeDots"
                    color="#23c97d"
                    height="70"	
                    width="70"
                />
            </div>
        );
    }

	signInGuest = () => {
        console.log('in guest login');
        let groupid = this.decryptData(this.props.groupid);
		fetch(window._env_.REACT_APP_API_URL+'/signin', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				email: this.state.signInEmail,// here sign in email need to be converted to lowercase NS2-317
				geoLocation: this.state.signInGeoLocation,
				password: this.state.signInPassword
			})
		})
		.then(response => {
			this.setState({ status: response.status });
			if(response.status === 200){
				return response.json();
            }else{
				throw response;
            }
		})
		.then(({ user, token }) => {
			if(user && token){
                this.setState({isLoading: false})
                this.authService.setToken(token);
                this.authService.socketInit();
                this.props.subscribeToOnlineNotification();
                this.props.subscribeToNewImageUploadNotification();
                this.props.loadUser(user);
                this.props.changeStateforGuest();
                this.props.loadSelectedGroup(groupid, '');
                window.history.replaceState(null, null, '/'); //TP-4155               
			}
		})
		.catch((error) => {
            this.setState({isLoading: false})
            //TP-3157
            this.props.changeonlyGuestState();
            if(error.status === 409){
                this.showHideLoggedInModal();
                return;
            }else {
                this.showHideErrorModal();
                return;
            }
		})
    }

    //TP-3157
    logMeOut = () => {
        window.history.replaceState(null, null, '/');
        this.props.onRouteChange('signout');
    }

    showHideLoggedInModal = () => {        
        let errorMsg;
        this.setState((prevState) => ({
            isSignedIn: !prevState.isSignedIn,
        }),() => {
            errorMsg = i18nMark("Guest user already joined in another device")
            this.setState({dialogMsg: errorMsg})
        });            
    }

    showHideErrorModal = () => {
        let errorMsg;
        this.setState((prevState) => ({
            isErrorMsg: !prevState.isErrorMsg,
        }),() => {
            errorMsg = i18nMark("Guest user error message")
            this.setState({dialogMsg: errorMsg})
        });
    }

    decryptData = (text) => {
        try{
            let decipher = crypto.createDecipheriv('aes-128-cbc','619bf494133645e2', 'c5d7d8f5d59fd3e4')//MB2-712
            let dec = decipher.update(text,'hex','utf8')
            dec += decipher.final('utf8');
            return dec;
        }catch(error){
            console.error('error decrypted data')
        }
    }

    loggedInModal = () => {
        return (
            <>
                <Modal size="md" className="modal-centered" isOpen={this.state.isSignedIn} toggle={this.showHideLoggedInModal}>
                    <ModalBody>
                        <Trans id={this.state.dialogMsg}></Trans>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="lg" className="btn-g" onClick={this.logMeOut}><Trans>OK</Trans></Button>{' '}                        
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    errorMsgModal = () => {
        return (
            <>
                <Modal size="md" className="modal-centered" isOpen={this.state.isErrorMsg} toggle={this.showHideErrorModal}>
                    <ModalBody>
                        <Trans id={this.state.dialogMsg}></Trans>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="lg" className="btn-g" onClick={this.logMeOut}><Trans>OK</Trans></Button>{' '}                        
                    </ModalFooter>
                </Modal>
            </>
        );
    }
      
	render() {
        const { isSignedIn, isSmallFormFactor, isErrorMsg } = this.state;
        let bgImgCls = "w-60 center db";
        if (isSmallFormFactor === false && window.innerWidth > 1559) {
            bgImgCls = "w-90 center db";
        } else if(isSmallFormFactor === false && window.innerWidth > 1450 && window.innerWidth < 1559){
            bgImgCls = "w-70 center db";
        } else {
            bgImgCls = "w-60 center db";
        }
        if (isSignedIn === true || isErrorMsg === true) {
            //TP-3157
            return (
                <>
                <div className='center'>
                    <div className='log-me-center-dialog log-me-color log-me-min-width'>
                        <div className="bg-transparent center mw5">
                            <a className="link dim mt-4 lh-title" href="https://www.telepresenz.com/" target="_blank" style={{outline:'none'}}>
                                <img alt="Log into telepresenz!" className={bgImgCls} src={window._env_.REACT_APP_ASSET_FOLDER+"/signin_page_logo.png"} onError={"this.onerror=null;this.src='Whitelabeling/signin_page_logo.png';"} />
                            </a>
                        </div>
                        <div className="margin10 form-text-color" style={isSmallFormFactor === false && window.innerWidth < 1250 ? {marginTop: '6%', fontSize: '20px'} : {marginTop: '10%', fontSize: '20px'}}>
                            <Trans id={this.state.dialogMsg}></Trans>
                        </div>
                        <div className="flex-center" style={isSmallFormFactor === false && window.innerWidth < 1250 ? {marginTop: '6%'} : {marginTop: '10%'}}>
                            <button className="btn-green" onClick={this.logMeOut}><Trans>Close</Trans></button>{' '}
                        </div>
                    </div>
                </div>
                </>
            );
        } else {
            return(
                this.state.isLoading ? this.showLoader() : ''

            );

        }
	}
}

export default GuestLogin;