import React from 'react';
import WebrtcPeerConnection from './WebrtcPeerConnection';
import SessionSignal from './SessionSignal';
import {diagnostics} from "../DiagonasticHelper";
import AuthService from '../AuthService';
import { i18nMark } from "@lingui/react";
import { printConsole } from '../helpers';

let hasSentRequestExpertSync = false; //TP-5621

const fileTransferTimeout = 1;//TP-5560
let audioVolumeTickCount = 0; //TP-5733
//TP-3821
let camera_check_success_attempts = 0;
let default_camera_res = {width: {exact: 1280}, height: {exact: 720}};

const ResolutionsToCheck = [
    {width: {exact: 120}, height: {exact: 160}},
    {width: {exact: 320}, height: {exact: 240}},
    {width: {exact: 640}, height: {exact: 480}},
    {width: {exact: 1280}, height: {exact: 720}},
    {width: {exact: 1920}, height: {exact: 1080}},
    {width: {exact: 3840}, height: {exact: 2160}},
    {width: {exact: 4096}, height: {exact: 2160}},
    {width: {exact: 7680}, height: {exact: 4320}}
];

var mid = ResolutionsToCheck.length-1;
let displayTrackId = 0;
let cameraTrackId = 0;

//TP-4837
const roundOff = function (num, decimal) {
    let res = (Number(Math.round(num + "e" +decimal) + "e-" +decimal));
    if (res === 1.78) return 1.77;
    else return res;
}

//TP-5320, TP-5358
const matchTrack = function (track_id, mediaStream) {
    let return_flag = false;
    mediaStream && mediaStream.getVideoTracks().length > 0 && mediaStream.getVideoTracks().forEach(track => {
        if (track.id && track.id === track_id) return_flag = true;
    })
    return return_flag;
}

const getDisplayTrack = function (track_id, mediaStream) {
    let return_track = null;
    if (mediaStream && mediaStream.getVideoTracks().length > 0 ) {
        for (let i=0; i< mediaStream.getVideoTracks().length; i++) {
            if (mediaStream.getVideoTracks()[i].id && mediaStream.getVideoTracks()[i].id === track_id) {
                return_track = mediaStream.getVideoTracks()[i];
                break;
            }
        }
    }
    return return_track;
}

const getCameraTrack = function (track_id, mediaStream) {
    let return_track = null;
    if (mediaStream && mediaStream.getVideoTracks().length > 0 ) {
        for (let i=0; i< mediaStream.getVideoTracks().length; i++) {
            if (mediaStream.getVideoTracks()[i].id && mediaStream.getVideoTracks()[i].id !== track_id) {
                return_track = mediaStream.getVideoTracks()[i];
                break;
            }
        }
    }
    return return_track;
}

class WebrtcSessionUser extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            localStream: '',
            previewStream: '',
            connections: [],
            user: {...props.user},
            vdowidth: 0,
            vdoheight: 0,
            participantNames: [],
            technicianCount: 0,
            expertsCount: 0,
            userVideoCount: 0,
            userAudioEnabledCount: 0, //T32-413
            screenCaptureClicked: false,
            socketId:'',
            groupInfo: '',
            showWebrtcErrorMessage: false,
            webrtcErrorType: '',
            bMaxChat: false, bAllChat: false, chatMsgInfoArr:  [{ msgColor: '', messageID: '',
            firstName: '', lastName: '', message: '', timeStamp: '', msgType: 0 // 0 - indiv, 1 - group
            }], chatUserEmails: [], chatUserNames:[], startChatTimer: false,
            isMaxDivSS: false, isSideBarSS: false, screenShareStream: null, hideLocalVdoClass: '', ssFrom: '', ssStreamId: '', /**TP-3342 */
            currentPrimaryExpert: null, exited: false, flashMsgText: '', showFlashMessage:false,flashLeft: "4%",
            flashTop: "3%", flashTimer: true, /** TP-5681*/ startTimer: false, selectedWidth: props.user.selectedWidth ? props.user.selectedWidth : {exact: 1280}, /**TP-3821*/
            selectedHeight: props.user.selectedHeight ? props.user.selectedHeight : {exact: 720}, /**TP-3821*/
            showHideDivIconAudioOnly: false, mode: 'view', userCallResponse: '', onlineList: [], selectedUser: null,
            isParticipantChanged: false, userInfoArray: [], expertWaitTimer: 20, prevMaxDivPartcicipantEmail: '',
            isOngoingPeer: false, isOngoingPeerEmail: '', peerWaitTimer: 20, expertParticipantLeft: "", //TP-2292
            calleeList: null, expertVideoArr: [], /**TP-2539 */ newVideoArr: [], /**TP-3114 */ resetMaxUserZoom: false, resetMaxUserTorch: false, //TP-2861
            setMaxView: true, /*TP-3074 */ triggerGridView: false, /**TP-3611*/ changedParticipant: null, /**TP-3677*/ updatedParticipants: [], /*TP-3573*/
            isScreencasting: false, /**TP-3955*/ isAtMaxWidth: true, /**TP-4956*/ isSSOngoing: false, /**TP-5320*/ cameraNotDetected: false, /**TP-5429*/ 
            showIsMutedbutSpeaking: false, currentDecibel: 0, removedIsInSession: [], /**TP-2946*/ expertShareScreenMaxDiv: false, /**TP-5621*/ caleeDecList: [], /**TP-5903*/
            donotSendSyncExpert: false /**TP-6163*/
        };
        this.sessionSignal = new SessionSignal({
            userId: props.user.id, 
            firstName: props.user.firstname, 
            lastName: props.user.lastname, 
            email: props.user.email, 
            isexpert: props.user.isexpert
        },true, props.selectedGroupId);
        this.authService = new AuthService();
        this.decodedUser = this.authService.getDecodedUser();
    }

    //TP-5442 & TP-5441
    triggerDeviceErrorAndEndSession = (type) => {
        let errorType = 'PermissionDeniedError';
        let isEndSession = false;
        if ((type === "camera" || type === "microphone") && !this.props.user.isexpert) {
            errorType = 'PermissionDeniedError';
            isEndSession = true;
        } else if (type === "camera" && this.props.user.isexpert) {
            errorType = 'DevicesNotFoundError';
        } else if (type === "microphone" && this.props.user.isexpert) {
            errorType = 'MicPermissionDeniedError';
            isEndSession = true;
        }
        if (this.state.showWebrtcErrorMessage === false && isEndSession === true){
            this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType});
        } else if (this.state.showWebrtcErrorMessage === false && isEndSession === false) {
            this.triggerMessage(errorType, errorType);
        }
    }

    setOnlineUsers = (usernames = []) => {
        //console.log("Here...")
        const {participants} = this.state;
        let {calleeList} = this.state;
        let {groupUsers, onlineUsers, user} = this.props;
        let flag = false;
        if (usernames.length > 0) {
            onlineUsers = usernames;
            flag = true;
        }
        printConsole(onlineUsers);
        let len = groupUsers.length;
        let {onlineList} = this.state;
        for (let ii = 0; ii<len; ii++) {
            if (user.email !== groupUsers[ii].email && 
                onlineUsers.includes(groupUsers[ii].email) === true && 
                !(groupUsers[ii].isadmin === true && !groupUsers[ii].isexpert) && 
                onlineList.some(on => on.email === groupUsers[ii].email) === false)
            {
                onlineList.push(groupUsers[ii]);
            }
        }
        
        let online = onlineList;
        // Exclude from online users the participants list        
        participants && participants.forEach(p => {
            let [tmp] = onlineList.filter (o => o.email === p.email);
            if (tmp !== undefined) {
                let index = onlineList.indexOf(tmp);
                onlineList.splice(index, 1);
            }
        });
        
        //TP-3488 -- Adding the profile pictures for online users list:
        this.props.userPictures && online.forEach(on => {
            this.props.userPictures.forEach(usr => {
                if(usr.user_id === on.value || usr.user_id === on.user_id /**TP-3468*/) {
                    printConsole(`Online user list User id ${usr.user_id} found in the profile picture array`);
                    /*TP-3497*/
                    if (usr.my_picture && usr.my_picture !== null && usr.my_picture !== "" && usr.my_picture.includes("profile.png") === false /**TP-3572*/)
                        on.myPicture = usr.my_picture;
                    else 
                        on.myPicture = "/profile.png";
                }
            })
        })
        this.setState({ onlineList: online }, () => {
            //TP-2381 -- Handling of showing the invite users phone icons as busy
            // when the session has started due to a Group call instead if a user call
            //console.log(calleeList);
            if (calleeList === null) {
                online.forEach(o => {
                    if(o.isBusy === undefined)
                        o.isBusy = false;
                    else if (flag === true)
                        o.isBusy = false;
                })
                this.setState({ onlineList: online }); //TP-2946
            } else {
                //console.log(" removed from usersInSession: ", this.state.removedIsInSession);
                for (let jj = 0; jj< online.length; jj++){
                    if(calleeList.indexOf(online[jj].email) > -1 && this.state.removedIsInSession.indexOf(online[jj].email) === -1) {
                        //console.log("isBusy..........",online[jj].email)
                        online[jj].isBusy = true;
                        this.setState({ onlineList: online });
                        //calleeList = calleeList.splice(calleeList.indexOf(online[jj].email), 1);
                    } else if (calleeList.indexOf(online[jj].email) > -1 && this.state.removedIsInSession.indexOf(online[jj].email) > -1) {
                        //TP-2946 -- Added the condition to set the isBusy flag as false if that user has declined/went out of call
                        //console.log("this user has declined/went out of session....", online[jj].email)
                        online[jj].isBusy = false;
                        let removedIsInSession = this.state.removedIsInSession.filter(e => e.email !== online[jj].email);
                        this.setState({ onlineList: online, removedIsInSession });
                    }
                    else {
                        online[jj].isBusy = false;
                        this.setState({ onlineList: online });
                    }
                }
            }
            printConsole("Online users: ");
            printConsole(online);
            //console.log("changed online user list: ",online);
            //console.log(calleeList);
            this.setState({calleeList: null});
            this.props.updateIsImageUploaded(false); //TP-3483 & TP-3468
        })
    }

    getCookie = (cname) => {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    // FQ3-333
    // Dynamic allocation of exact Video Resolution constraints 
    // for different types of cameras
    initGetMedia = () => {
        let deviceId = "";
        var constraints = {
            "video": ResolutionsToCheck[mid]
        }
        if (this.getCookie("camera") !== "") {
            deviceId = this.getCookie("camera");
            constraints = {
                "video": {
                    width : ResolutionsToCheck[mid].width,
                    height : ResolutionsToCheck[mid].height,
                    deviceId: {exact: deviceId}
                }
                    
            }
        }
        printConsole("Constraints ------>", constraints);
        this.getMedia(constraints); 
    } 
    
    getMedia = (constraints)=> {
        navigator.mediaDevices.getUserMedia(constraints)
            .then(this.checkSuccess)
            .catch(this.checkError);
    }
    
    checkSuccess = (stream) => {
        //TP-3821 -- Handling of setting the resolution of Technician camera based on the Streaming Quality set in Profile page
        camera_check_success_attempts ++;
        //console.log(this.props.user.selectedWidth);
        printConsole(`Success for -->  ${mid} ${JSON.stringify(ResolutionsToCheck[mid])} & success attempt count ${camera_check_success_attempts}`);
        if (this.props.user.selectedWidth && ResolutionsToCheck[mid].width.exact === this.props.user.selectedWidth.exact) {
            camera_check_success_attempts = 0;
            this.setState({
                selectedWidth: ResolutionsToCheck[mid].width,
                selectedHeight: ResolutionsToCheck[mid].height
            }, () => {
                printConsole(this.state.selectedWidth);
                printConsole(this.state.selectedHeight);
                mid = ResolutionsToCheck.length-1;
    
                for (let track of stream.getTracks()) {
                    track.stop()
                }
    
                this.initMedia();
            });
        } else {
            printConsole(`Ignoring this resolution for ---> ${mid} ${JSON.stringify(ResolutionsToCheck[mid])}`);
            if (camera_check_success_attempts === 1) default_camera_res = ResolutionsToCheck[mid];
            mid = mid - 1;

            for (let track of stream.getTracks()) {
                track.stop()
            }

            if (mid > 0) this.initGetMedia();
            else {
                printConsole("The selected resolution isn't supported for this device. So setting the default resolution for this device");
                if (default_camera_res.height.exact <= 480) default_camera_res = ResolutionsToCheck[3];//TP-4663
                printConsole(`Success for --> ${JSON.stringify(default_camera_res)}`);
                this.setState({
                    selectedWidth: default_camera_res.width,
                    selectedHeight: default_camera_res.height
                }, () => {
                    printConsole(this.state.selectedWidth);
                    printConsole(this.state.selectedHeight);
                    mid = ResolutionsToCheck.length-1;
        
                    for (let track of stream.getTracks()) {
                        track.stop()
                    }
        
                    this.initMedia();
                });
            }
        }

    }

    checkError = (error) => {
        printConsole("Failed for --> " + mid, " ", ResolutionsToCheck[mid], " ", error);
        mid = mid - 1;
        if (mid > 0) this.initGetMedia();
        else {
            //TP-4663
            printConsole(error);
            this.initMedia();
        }
    }

    deleteCookie = (cname) => {
        var name = cname + "=";
        document.cookie = name+"; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    // invoked for all. This asks the permission for getting the camera and microphone permission
    initMedia = () => {
        const {user} = this.props;
        let videoConfig = {};
        let audioConfig;
        //TP-5447 -- Added the condition for when Technician side camera "cookie" isn't there
        if (!user.isexpert && this.getCookie("camera") === "") {
            videoConfig = { width: this.state.selectedWidth, height: this.state.selectedHeight }; // /* {width: {min: 1280}, height: {min: 720}} 
            //audioConfig = true;
            audioConfig = { 
                echoCancellation: true, 
                noiseSuppression: true,
                googNoiseSupression: true
            }
        } else if ((user.isexpert && this.state.selectedWidth.exact !== 0 && this.state.selectedHeight.exact !== 0)) {
            this.props.setCameraNotDetected(false); //TP-5429
            videoConfig = { width: this.state.selectedWidth, height: this.state.selectedHeight }; // /* {width: {min: 1280}, height: {min: 720}} 
            //audioConfig = true;
            audioConfig = { 
                echoCancellation: true, 
                noiseSuppression: true,
                googNoiseSupression: true
            }
        } else if (user.isexpert && this.state.selectedWidth.exact === 0 && this.state.selectedHeight.exact === 0) {
            //TP-5429 -- Camera is not detected so video Constraint is set as false
            this.setState({ cameraNotDetected: true });
            this.props.setCameraNotDetected(true);
            videoConfig = false;
            audioConfig = {
                echoCancellation: true, 
                noiseSuppression: true,
                googNoiseSupression: true
            }
        } else {
            videoConfig = {
                width: this.state.selectedWidth , height: this.state.selectedHeight ,
                deviceId: {exact: this.getCookie("camera")}
            }
            audioConfig = {
                echoCancellation: true, 
                noiseSuppression: true,
                googNoiseSupression: true,
                deviceId: {exact: this.getCookie("microphone")}
            }
        }
        /* if (this.getCookie("camera") === "") {
            videoConfig = {width: { ideal: 1920 }, height: { ideal: 1080 }}; /* {width: {min: 1280}, height: {min: 720}} 
            audioConfig = true;
        } else {
            videoConfig = {
                width: { ideal: 1920 }, height: { ideal: 1080 },
                deviceId: this.getCookie("camera")
            }
            audioConfig = {
                deviceId: this.getCookie("microphone")
            }
        } */
            /* .then(this.getUsersForGroupId) */
        this.getGroupDetails();//APR-1 Ordering changed to load group data before peer connection is instantiated
        
            if (user.isexpert) {
                //videoConfig = {displaySurface: "window"};
                printConsole(`SessionType --- ${this.props.session_type}`)
                videoConfig = /**TP-3156 & TP-3119*/ (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? videoConfig : false; //TP-3572 & TP-2539
                //audioConfig = true;
                printConsole("video config ==============>");
                printConsole(videoConfig);
                printConsole("Audio config ==============>");
                printConsole(audioConfig);
                navigator.mediaDevices.getUserMedia({
                    audio: audioConfig,
                    video: videoConfig
                })
                .then(this.gotStream)            
                .catch(err => {
                    // MB2-431
                    let errorType;
                    console.log(`Device detection Error :: ${err}`);
                    if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
                        //required track is missing 
                        errorType = (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? 'DevicesNotFoundError' : 'MicrophoneNotFoundError';
                        this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                            this.stopprimaryExpertCheck(); //TP-5098
                        })
                    } else if (err.name === "NotReadableError" || err.name === "AbortError" || err.name === "TrackStartError") {
                        //webcam or mic are already in use 
                        errorType = (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? 'TrackStartError' : 'MicrophoneTrackStartError';
                        this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                            this.stopprimaryExpertCheck(); //TP-5098
                        })
                    } else if (err.name === "OverconstrainedError" || err.name === "ConstraintNotSatisfiedError") {
                        if (this.getCookie("microphone") !== "") {
                            this.deleteCookie("microphone");
                            audioConfig = {
                                echoCancellation: true, 
                                noiseSuppression: true,
                                googNoiseSupression: true
                            }
                            navigator.mediaDevices.getUserMedia({
                                audio: audioConfig,
                                video: false
                            })
                            .then(this.gotStream)            
                            .catch(err => {
                                // MB2-431
                                let errorType;
                                console.log(`Device detection Error :: ${err}`);
                                if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
                                    //required track is missing 
                                    errorType = (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? 'DevicesNotFoundError' : 'MicrophoneNotFoundError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                                        this.stopprimaryExpertCheck(); //TP-5098
                                    })
                                } else if (err.name === "NotReadableError" || err.name === "AbortError" || err.name === "TrackStartError") {
                                    //webcam or mic are already in use 
                                    errorType = (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? 'TrackStartError' : 'MicrophoneTrackStartError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                                        this.stopprimaryExpertCheck(); //TP-5098
                                    })
                                } else if (err.name === "OverconstrainedError" || err.name === "ConstraintNotSatisfiedError") {
                                    //constraints can not be satisfied by avb. devices 
                                    errorType = (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? 'ConstraintNotSatisfiedError' : 'MicContraintNotSatisfiedError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                                        this.stopprimaryExpertCheck(); //TP-5098
                                    })
                                } else if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
                                    //permission denied in browser 
                                    errorType = (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? 'PermissionDeniedError' : 'MicPermissionDeniedError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                                        this.stopprimaryExpertCheck(); //TP-5098
                                    })
                                } else {
                                    //other errors 
                                    errorType = 'OtherWebrtcError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                                        this.stopprimaryExpertCheck(); //TP-5098
                                    })
                                }                                
                            })
                        } else {
                            //constraints can not be satisfied by avb. devices 
                            errorType = (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? 'ConstraintNotSatisfiedError' : 'MicContraintNotSatisfiedError';
                            this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                                this.stopprimaryExpertCheck(); //TP-5098
                            })
                        }
                    } else if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
                        //permission denied in browser 
                        errorType = (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? 'PermissionDeniedError' : 'MicPermissionDeniedError';
                        this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                            this.stopprimaryExpertCheck(); //TP-5098
                        })
                    } else {
                        //other errors 
                        errorType = 'OtherWebrtcError';
                        this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}, () => {
                            this.stopprimaryExpertCheck(); //TP-5098
                        })
                    }                    
                });
            }
            else {    
                printConsole("video config ==============>");
                printConsole(videoConfig);        
                navigator.mediaDevices.getUserMedia({
                    video: videoConfig,
                    audio: audioConfig
                })
                .then(this.gotStream)
                .catch(err => {
                    // MB2-431
                    let errorType;
                    console.log(`Device detection Error :: ${err}`);
                    if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
                        //required track is missing 
                        errorType = 'DevicesNotFoundError';
                        this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}) //TP-4903
                    } else if (err.name === "NotReadableError" || err.name === "AbortError" || err.name === "TrackStartError") {
                        //webcam or mic are already in use 
                        errorType = 'TrackStartError';
                        this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}) //TP-4903
                    } else if (err.name === "OverconstrainedError" || err.name === "ConstraintNotSatisfiedError") {
                        //constraints can not be satisfied by avb. devices 
                        //TP-4903errorType = 'ConstraintNotSatisfiedError';
                        // TP-1425 -- 
                        if (this.getCookie("microphone") !== "" || this.getCookie("camera") !== "") {
                            this.deleteCookie("microphone");
                            this.deleteCookie("camera");
                            audioConfig = {
                                echoCancellation: true, 
                                noiseSuppression: true,
                                googNoiseSupression: true
                            }
                            //TP-4903
                            videoConfig = {
                                width: this.state.selectedWidth, 
                                height: this.state.selectedHeight
                            }
                            printConsole("updated video config ==============>");
                            printConsole(videoConfig); 
                            navigator.mediaDevices.getUserMedia({
                                audio: audioConfig,
                                video: videoConfig
                            })
                            .then(this.gotStream)            
                            .catch(err => {
                                // MB2-431
                                let errorType;
                                console.log(`Device detection Error again :: ${err}`); //TP-4903
                                if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
                                    //required track is missing 
                                    errorType = 'DevicesNotFoundError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}) //TP-4903
                                } else if (err.name === "NotReadableError" || err.name === "AbortError" || err.name === "TrackStartError") {
                                    //webcam or mic are already in use 
                                    errorType = 'TrackStartError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}) //TP-4903
                                } else if (err.name === "OverconstrainedError" || err.name === "ConstraintNotSatisfiedError") {
                                    //constraints can not be satisfied by avb. devices 
                                    errorType = 'ConstraintNotSatisfiedError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}) //TP-4903
                                } else if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
                                    //permission denied in browser 
                                    errorType = 'PermissionDeniedError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}) //TP-4903
                                } else {
                                    //other errors 
                                    errorType = 'OtherWebrtcError';
                                    this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType})//TP-4903
                                }                                
                            })
                        } else {
                            //constraints can not be satisfied by avb. devices 
                            errorType = 'ConstraintNotSatisfiedError';
                            this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}) //TP-4903
                        }
                    } else if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
                        //permission denied in browser 
                        errorType = 'PermissionDeniedError';
                        this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}) //TP-4903
                    } else {
                        //other errors 
                        errorType = 'OtherWebrtcError';
                        this.setState({showWebrtcErrorMessage: true, webrtcErrorType: errorType}) //TP-4903
                    }                     
                });
            }
    };

    // get group settings
    getGroupDetails = () => {
        const {user, selectedGroupId, isGuest, audio_mode} = this.props; 
        if (this.props.sessionInfo !== null) {
            printConsole(`Here --- SessiondataH364 returned ${JSON.stringify(this.props.sessionInfo)}`);
                printConsole(`current user userid: ${user.id}`);
                //printConsole(this.props.sessionInfo);
                if (user.isexpert && this.props.sessionInfo.primary_expert_id === user.id) {
                    this.startExpertTimer();
                }
                else if(user.isexpert && user.id !== this.props.sessionInfo.primary_expert_id && this.props.sessionInfo.isongoingnow === false){
                    const message = "Primary Expert not same as user";
                    const flashValues = {role: this.props.customerRoles.expert}
                    this.setState({ flashMsgText: message, showFlashMessage: true, flashLeft: "", flashTop: "", flashValues: flashValues, flashTimer: true }); //TP-5681
                }
                this.setState({ groupInfo: this.props.sessionInfo , startTimer: true},() => {
                    if (this.state.user.log_level >= 3) {
                        diagnostics(this.authService,{
                            action: `sessionData`,
                            sequence: 3,
                            next_step: '',
                            data: this.props.sessionInfo,
                            error: 'none'
                        })
                    }
                    this.props.setGroupInfo({groupInfo: this.props.sessionInfo})// set sessiondata response for that user
                    if(this.state.user.isexpert){
                        //console.log(this.state.user.id, this.state.groupInfo.primary_expert_id);
                        if (this.state.user.id === this.state.groupInfo.primary_expert_id && this.props.isRespondToCall === false /**TP-2462*/ && this.props.isUserCallDone === false /**TP-5036*/) {
                            this.startMissedCallNotifyTimer(); // TP-1129
                        }
                        if (isGuest === true) {
                            this.waitTimer = setTimeout(()=> {
                                this.checkPrimaryExpert();
                                this.sendRequestExpertSync();
                                clearTimeout(this.waitTimer);
                            }, 5000)
                        } else {
                            this.checkPrimaryExpert();
                            this.waitTimer = setTimeout (() => {
                                this.sendRequestExpertSync();
                                clearTimeout(this.waitTimer);
                            }, 5000) //TP-1547
                        }
                        // TP-105
                        if(this.state.localStream !== ''){
                            this.callUser();// We need to invoke call user from here so that the previous calls which were not invoked from updateUserList and gotStream method can be done
                        }
                    } else {
                        // TP-3052 & TP-3156
                        if(this.state.localStream !== '' && this.props.audio_mode === "tech2Tech" ){
                            this.callUserForTech();// We need to invoke call user from here so that the previous calls which were not invoked from updateUserList and gotStream method can be done
                        }
                    }
                });
        } else {
            let fetchString = "", apiname;
            //TP-4268 & TP-4213 -- New API integration to support Tech2Tech call feature from Users Tab
            if (!user.isexpert && audio_mode === "tech2Tech") {
                fetchString = 'user_session/callPeer?groupID=';
                apiname = 'callPeer';
            } else {
                fetchString = 'sessiondataH364?groupID=';
                apiname = 'sessionData';
            }
            printConsole(`${apiname} is called with payload ---> { groupID: ${selectedGroupId}, loggedInUserId: ${user.id} }`)     
            fetchString += selectedGroupId + '&loggedInUserId=' + user.id;
            this.authService.fetch(fetchString, {
                method: 'get'
            })
            .then(response => {
                if(response.status >= 200 && response.status < 300){
                    return response.json();
                } else {
                    throw response;
                }
            })
            .then(data => {
                if(data){
                    printConsole(`${apiname} returned ${JSON.stringify(data)}`);
                    printConsole(`current user userid: ${user.id}`);
                    if (user.isexpert && data.primary_expert_id === user.id) { //TP-2082
                        this.startExpertTimer();
                    }
                    else if(user.isexpert && user.id !== data.primary_expert_id && data.isongoingnow === false){
                        const message = "Primary Expert not same as user";
                        this.setState({ flashMsgText: message, showFlashMessage: true, flashLeft: "", flashTop: "", flashTimer: true }); //TP-5681
                    }
                    this.setState({ groupInfo: data , startTimer: true},() => {
                        if (this.state.user.log_level >= 3) {
                            diagnostics(this.authService,{
                                action: apiname, //TP-4268
                                sequence: 3,
                                next_step: '',
                                data: data,
                                error: 'none'
                            })
                        }
                        this.props.setGroupInfo({groupInfo: data})// set sessiondata response for that user
                        if(this.state.user.isexpert){
                            //console.log(this.state.user.id, this.state.groupInfo.primary_expert_id);
                            if (this.state.user.id === this.state.groupInfo.primary_expert_id && this.props.isRespondToCall === false /**TP-2462*/ && this.props.isUserCallDone === false /**TP-5036*/) {
                                this.startMissedCallNotifyTimer(); // TP-1129
                            }
                            if (isGuest === true) {
                                this.waitTimer = setTimeout(()=> {
                                    this.checkPrimaryExpert();
                                    this.sendRequestExpertSync();
                                    clearTimeout(this.waitTimer);
                                }, 5000)
                            } else {
                                this.checkPrimaryExpert();
                                this.waitTimer = setTimeout (() => {
                                    this.sendRequestExpertSync();
                                    clearTimeout(this.waitTimer);
                                }, 1000)
                            }
                            // TP-105
                            if(this.state.localStream !== ''){
                                this.callUser();// We need to invoke call user from here so that the previous calls which were not invoked from updateUserList and gotStream method can be done
                            }
                        } else {
                            // TP-3052 & TP-3156
                            if(this.state.localStream !== '' && this.props.audio_mode === "tech2Tech" ){
                                this.callUserForTech();// We need to invoke call user from here so that the previous calls which were not invoked from updateUserList and gotStream method can be done
                            }
                        }
                    });
                }
            })
            .catch(err => {
                printConsole(`cannot get ${apiname} data`) //TP-4268
                //TP-6029 -- Disconnect the Guest user if the sessionData API returns any error code
                if (isGuest === true) {
                    printConsole("Since the sessionData call returned error code so ending this user from session");
                    this.hangUp();
                }
            })

        }
    }
    
    sendRequestExpertSync = () => {
        const experts = this.state.participants.filter(participant => participant.isexpert === true);
        if (experts && experts.length>0) {
            experts.forEach(e => {
                printConsole(e);
                printConsole(this.state.groupInfo.primary_expert_id);
                if (this.state.groupInfo.primary_expert_id === e.userId) {
                    printConsole("Passive expert is to send the `request_expert_sync` signal to Primary expert");
                    this.sessionSignal.sendSignalSyncExperts({reason: "request_expert_sync"}, e.email) // send to only primary expert
                    hasSentRequestExpertSync = true; //TP-5621
                }
            })
        }
    }

    // invoked when any user joins the group
    notifyGroup = () => {
        const {user, selectedGroupId, selectedGroupName, fromSocketUser} = this.props;

        if(user.isexpert && fromSocketUser !== null){
            this.sessionSignal.sendRespondToCall({data:{ action: 'accepted', recipient: fromSocketUser, groupId: selectedGroupId, groupName: selectedGroupName }});
        }
        this.sessionSignal.sendUserInSession({data:{ groupId: selectedGroupId, isInSession: true }});

        this.sessionSignal.sendRoomJoined();
        this.updatedUsage({session_started: true})
        this.setState({socketId: this.authService.socket.id})
    }

    //TP-2209 -- invoked when an expert ends the session
    notifyEndCallGroup = () => {
        this.sessionSignal.sendWebrtcEndCall();
        this.sessionSignal.sendUserInSession({data:{ groupId: this.props.selectedGroupId, isInSession: false }});
        printConsole("Calling the end call APIs & socket signals");
        this.updatedUsage({session_started: false})
        if (typeof(Storage) !== "undefined") {
            // if (this.state.showMaxDivControls && this.state.currentPrimaryExpert === this.state.user)//FQ3-295
            ['groupId', 'isonprem'].forEach(key =>  sessionStorage.removeItem(key));//FQ3-295, TP-991
        } else {
            printConsole(`Sorry, your browser does not support Web Storage...`)
        }
        this.props.onClickBack();
    }

    subscribeToUserIsInSession = ({ isInSession, userName, caller }) => {
        let { participants, onlineList } = this.state;
        let { usersInSession } = this.props; //TP-1599
        printConsole(`user-is-in-session signal for user: ${userName}, isInSession: ${isInSession}`);
        
        if(isInSession) {
            if (userName) usersInSession.push(userName);   
            this.props.setUsersInSession(usersInSession); //TP-1599        
            if (this.props.user.isexpert && onlineList.length > 0) { //TP-2832
                let [tmp] = onlineList.filter (o => o.email === userName);
                if (tmp !== undefined) {
                    let index = onlineList.indexOf(tmp);
                    printConsole("Seems user is in another call");
                    onlineList[index].isBusy = true;
                }
            }           
        } else { 
            printConsole("User went out of the call");
            if (userName) usersInSession = usersInSession.filter(e => ![userName].includes(e));
            this.props.setUsersInSession(usersInSession);//TP-1599
            /* if (participants.length > 0) {
                for (let i=0; i<g_subInfoArr.length; i++) {
                    let dataSplit = g_subInfoArr[i].g_stream.connection.data.split(":::");
                    if (dataSplit[3] === userName && g_subInfoArr[i].g_stream.videoType !== "screen") 
                        this.streamDisconnected(i, "user is in session False");
                }
            } */
            if(this.props.user.isexpert) {
                let [parti] = participants.filter(p => p.email === userName);
                if (parti) {
                    this.resetLayout({participantLeft: parti});
                }
            }
            //console.log("Is there any online user's list?", onlineList.length > 0);
            if (this.props.user.isexpert && onlineList.length > 0) { //TP-2832
                let [tmp] = onlineList.filter (o => o.email === userName);
                if (tmp !== undefined) {
                    let index = onlineList.indexOf(tmp);
                    //TP-2946 -- store the user who has gone out of call in a seperate array
                    let removedIsInSession = [...this.state.removedIsInSession];
                    removedIsInSession.push(userName);
                    this.setState({ removedIsInSession })
                    if (onlineList[index].isBusy === true) {
                        printConsole("Seems busy user has comeback..");
                        onlineList[index].isBusy = false;
                        /* let onlineEmails = [];
                        onlineEmails.push(onlineList[index].email); 
                        this.authService.socket.emit('send-missed-call-notification', { 
                            emails: onlineEmails
                        }); */
                    }
                }
            }
        }
        if (this.props.user.isexpert) {
            this.setState({onlineList});
        }
    }

    subscribeToOnlineNotification = (userNames) => {     
        this.setOnlineUsers(userNames); 
    }    

    // update the user list when other participants are joining
    updateUserList = ({allParticipants}) => {
        const {isGuest, session_type} = this.props;
        printConsole('Update User List----------------');
        printConsole("all participants in the room are")
        printConsole(allParticipants)
        let technicianArr = allParticipants.filter((participant) => {
            return participant.isexpert === false && participant.isadmin === false;
        });
        printConsole("all technicians in the room are");
        printConsole(technicianArr);
        
        this.setState({ technicianCount: technicianArr.length/* , userVideoCount: technicianArr.length */ });
        const {user, localStream, participants, groupInfo} = this.state;
        const partcipantEmails = participants.map((p)=> p.email)
        allParticipants.forEach(p => {
            if (!p.isexpert) {
                if (partcipantEmails.includes(p.email) === true) {
                    const [parti] = participants.filter(pt => pt.email === p.email);
                    //console.log("Partipipant ---", parti);
                    // T32-413 Handling for the new flag of the Remote User's Mute Mike
                    if (parti) {
                        p.disableVideo = parti.disableVideo;
                        p.disableRemoteAudio = parti.disableRemoteAudio;
                        // Set isRegistered flag = false unless the SYNC_EXPERTS signal 
                        // with reason="registered_sync" is received by the Expert user
                        p.isRegistered = parti.isRegistered;
                        p.isPaused = parti.isPaused; //TP-4483
                    } 
                    else {
                        p.disableVideo = false;
                        p.disableRemoteAudio = false;
                        // Set isRegistered flag = false unless the SYNC_EXPERTS signal 
                        // with reason="registered_sync" is received by the Expert user
                        p.isRegistered = true;
                        p.isPaused = false; //TP-4483
                    }   
                }
                else {
                    p.disableVideo = false;
                    p.disableRemoteAudio = false;
                    // Set isRegistered flag = false unless the SYNC_EXPERTS signal 
                    // with reason="registered_sync" is received by the Expert user
                    p.isRegistered = true;
                    p.isPaused = false; //TP-4483
                }
            }                
            else {
                printConsole(`Session type: ${session_type}`); //TP-4483
                p.disableVideo = /** TP-3551 & TP-3532 */ (session_type === "CM" || (session_type === "RM" && this.isExpertVideo() === true)) ? false : true;   
                if (partcipantEmails.includes(p.email)) {
                    const [parti] = participants.filter(pt => pt.email === p.email);
                    //("Partipipant ---", parti);
                    if (parti) {
                        p.disableRemoteAudio = parti.disableRemoteAudio;
                        p.isPaused = parti.isPaused; //TP-4483
                    }
                    else {
                        p.disableRemoteAudio = false;
                        p.isPaused = false; //TP-4483
                    }
                } else {
                    p.disableRemoteAudio = false;
                    p.isPaused = false; //TP-4483
                }
            }
            if (this.state.mutedAll === false)
                p.disableAudio = false;         
            else
                p.disableAudio = true;
            if (this.state.headerMuteMike === false)
                p.disableMike = false;
            else
                p.disableMike = true; 
            //p.disableRemoteAudio = false;  // T32-413    
            if (partcipantEmails.includes(p.email)) {
                const [parti] = this.state.participants.filter(pt => pt.email === p.email); //TP-4808
                p.zoom_enabled = (parti) ? p.zoom_enabled : false; //TP-207
                p.torch_enabled = (parti) ? p.torch_enabled : false; // TP-207
                p.screenshare_enabled = (parti) ? p.screenshare_enabled : false; //TP-207
                p.techSS_enabled = (parti) ? p.techSS_enabled : false; // TP-207
                p.zoomLevel = (parti) ? p.zoomLevel : 1;  // TP-207
                p.torchActive = (parti) ? p.torchActive : false; //TP-207 
                // Set isRegistered flag = false unless the SYNC_EXPERTS signal 
                // with reason="registered_sync" is received by the Expert user
                p.isRegistered = (parti) ? p.isRegistered : true;
                p.grayedVideo = (parti) ? p.grayedVideo : (!p.isexpert && p.streams === undefined) ? true : false;
            } else {
                p.zoom_enabled = false; //TP-207
                p.torch_enabled = false; // TP-207
                p.screenshare_enabled = false; //TP-207
                p.techSS_enabled =  false; // TP-207
                p.zoomLevel = 1;  // TP-207
                p.torchActive = false; //TP-207  
                // Set isRegistered flag = false unless the SYNC_EXPERTS signal 
                // with reason="registered_sync" is received by the Expert user
                p.isRegistered = true;
                p.grayedVideo = (!p.isexpert && p.streams === undefined) ? true : false;
            }
        })
        //console.log(allParticipants);
        // Calculate the user video count:
        let userVideoArr = [];
        //TP-3551 & TP-3156 & TP-3119
        if (session_type === "CM" || (session_type === "RM" && this.isExpertVideo() === true)) {
            userVideoArr = allParticipants.filter((pa) => {
                return pa.disableVideo === false;
            });
        } else {
            userVideoArr = allParticipants.filter((pa) => {
                return pa.isexpert === false && pa.disableVideo === false;
            });
        }
        printConsole(`users with Video turned on?? ${JSON.stringify(userVideoArr)}`);
        // T32-413
        let userRemoteArr = allParticipants.filter(pa => {
            return pa.disableRemoteAudio === false && pa.email !== user.email;
        })
        //console.log(userRemoteArr);
        printConsole(`User Speakers enabled count ${userRemoteArr.length}`)
        //if (userVideoArr.length < technicianArr.length)
        this.setState({ 
            userVideoCount: userVideoArr.length, 
            userAudioEnabledCount: userRemoteArr.length 
        }, () => {
            if (user.isexpert && this.state.userAudioEnabledCount > 0 && this.state.disabledMutedAll === true) {                
                this.setState({ disabledMutedAll: false/*,  mutedAll: false */ });
            } else if (user.isexpert && this.state.userAudioEnabledCount === 0) {
                this.setState({ disabledMutedAll: true/* , mutedAll: true */ });
            }
        });
        // new particpants who are not there in participants state variable and there email is not same as the current user email
        let new_experts = allParticipants.filter((participant) => (participant.email !== user.email && participant.isexpert === true && !partcipantEmails.includes(participant.email)));
        let new_participants = allParticipants.filter((participant) => (participant.email !== user.email && !partcipantEmails.includes(participant.email)))
        if (new_participants && new_participants.length > 0 && this.state.userInfoArray.length > 0) {
            //TP-263 -- Process the user Info "IM" Signal payload for newly joining technician
            new_participants.forEach(np => {
                this.state.userInfoArray.forEach(obj => {
                    if (obj.id === np.userId /*TP-4837 && np.isexpert === false */) {
                        printConsole("New User joined session");
                        printConsole(obj.id);
                        np.zoom_enabled = (obj.data.zoom_enabled && this.state.groupInfo.zoom_enabled) ? obj.data.zoom_enabled : false;
                        np.torch_enabled = (obj.data.torch_enabled && this.state.groupInfo.torch_enabled) ? obj.data.torch_enabled : false;
                        np.screenshare_enabled = (obj.data.screenshare_enabled) ? obj.data.screenshare_enabled : true;
                        np.techSS_enabled = (obj.data.techSS_enabled) ? obj.data.techSS_enabled : false; 
                        np.aspect_ratio = obj.data.aspectratio ? (obj.data.aspectratio === "1.33" || obj.data.aspectratio === "1.34") ? "4:3" : obj.data.aspectratio === "1.77" ? "16:9" : "16:9" : "16:9";//TP-4837
                    }
                })
            });
        }
        if (new_participants && new_participants.length > 0 && this.props.userPictures && this.props.userPictures.length > 0) {
            //TP-3488 -- Only if the profile pictures array exists then do this
            //TP-3369 -- Process the Profile pictures (if any) for the newly joining users in that session
            new_participants.forEach(np => {
                this.props.userPictures.forEach(usr => {
                    if(usr.user_id === np.userId) {
                        printConsole(`User id ${usr.user_id} found in the profile picture array`);
                        /*TP-3497*/
                        if (usr.my_picture && usr.my_picture !== null && usr.my_picture !== "" && usr.my_picture.includes("profile.png") === false /**TP-3572*/)
                            np.myPicture = usr.my_picture;
                        else 
                            np.myPicture = "/profile.png";
                    }
                })
            })
        }
        if(!this.state.user.joinTimeStamp){
            const [self] = allParticipants.filter(p => p.email === user.email);
            this.setState((prevState) => ({user : {...prevState.user, joinTimeStamp: self.joinTimeStamp}}));
        }
        this.setState((prevState) => ({
            participants: [...prevState.participants, ...new_participants]
        }), ()=> {
            printConsole(`participants list:}`);
            printConsole(this.state.participants);
            if(this.state.participants.length> 0){
                this.updateJoinees({other_participants: this.state.participants})
                // TP-2381 
                this.setState({ calleeList: this.props.calleeList }, () => {
                    this.setOnlineUsers();   
                });
                if(this.state.user.isexpert){
                    //TP-105
                    let experts = this.state.participants.filter(p => p.isexpert)
                    printConsole(`All experts count in session: ${experts.length}`);
                    printConsole(`New experts count in session: ${new_experts.length}`);
                    if (new_experts && new_experts.length > 0) {
                        this.setState({ expertsCount: experts.length })
                        if (/* experts.length === 1 && */ this.state.currentPrimaryExpert !== null && this.state.user.id === this.state.currentPrimaryExpert.id && this.state.user.id === this.state.groupInfo.primary_expert_id) {
                            //TP-4902 -- Sending this signal to all newly joined experts right after they join
                            // by the current Primary expert
                            new_experts.forEach((ex) => {
                                this.sessionSignal.sendSignalSyncExperts({
                                    isPrimary: true,
                                    expertId: this.state.user.id,
                                    email: this.state.user.email,
                                    firstname: this.state.user.firstname,
                                    lastname: this.state.user.lastname,
                                    joinTimeStamp: this.state.user.joinTimeStamp,
                                    reason: "primary_sync"
                                    },
                                ex.email);
                            });
                        }
                        new_experts.forEach((participant) => {
                            //this.sessionSignal.sendSignalQueryExpertMaxdivSwitch(participant.email); // send to each expert
                            // TP-552
                            // Send the signal SYNC_EXPERTS with reason="registered_sync"
                            // to announce to all other experts that this expert is a registered user
                            isGuest && this.sessionSignal.sendSignalSyncExperts({
                                isRegistered: false,
                                reason: "registered_sync"},
                            participant.email);
                            // TP-552
                            // Send the signal SYNC_EXPERTS with reason="registered_sync"
                            // to announce to all other experts that this expert is a registered user
                            !isGuest && this.sessionSignal.sendSignalSyncExperts({
                                isRegistered: true,
                                reason: "registered_sync"},
                            participant.email)
                        })
                        //TP-4837 -- Only send this signal if the Expert Video setting (Account level) is not disabled
                        /*TP-5310 if (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") {
                            //Send this to only other expert users (technicians trigger is at a different place)
                            new_experts.forEach(np => {
                                this.sendUserInfoToUser({participant: np});
                            });
                        } */
                    }
                    // TP-1383 -- CURRENT_PRIMARY_EXPERT signal is sent to new joining technician user
                    printConsole(`New participants in the call: ${new_participants.length}`)
                    if(new_participants && new_participants.length > 0) {
                        if (this.state.currentPrimaryExpert === this.state.user || this.state.showMaxDivControls === true) {
                            printConsole("isPrimary expert: true");
                            new_participants.forEach(np => {
                                if (np.isexpert === false) {
                                    printConsole(`sending the Current Primary expert's identity to technician : ${np.email}`);
                                    this.sessionSignal.sendSignalCurrentPrimaryExpert(0, np.email);
                                    //TP-3114 -- New signal to sync up all the Technician's Views with the Expert's when CM is enabled
                                    //TP-3119
                                    /*TP-4684 TP-4683 if (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) { /**TP-3572 
                                        if (this.state.maxView && this.state.maxDivParticipant) {
                                            printConsole(`sending the sendSignalCurrentMaxViewUser ========> ${this.state.maxDivParticipant.email} to ${np.email}`);                                        
                                            this.sessionSignal.sendSignalCurrentMaxViewUser({
                                                is_grid: false,
                                                max_view_user: this.state.maxDivParticipant.email},
                                            np.email)
                                        } else {
                                            printConsole(`sending the sendSignalCurrentMaxViewUser ========> with is_grid = true to ${np.email}`);                                        
                                            this.sessionSignal.sendSignalCurrentMaxViewUser({
                                                is_grid: true},
                                            np.email)
                                        }
                                    } */
                                    
                                }
                            })
                        }                        
                    }

                    //this.checkPrimaryExpert(); //should be called after getGroupDetails
                } else {
                    //TP-4849 -- If tech2Tech enabled  
                    if (this.props.audio_mode === "tech2Tech") {
                        if(new_participants && new_participants.length > 0) {
                            printConsole(`Count of new participants in the call: ${new_participants.length}`)
                            new_participants.forEach(np => {
                                printConsole(` user to join ${np.email}`);
                                //then send this signal to all users including other technicians
                                //TP-5310 -- For now send this signal only to experts joining this session
                                if (np.isexpert === true)
                                    this.sendUserInfoToUser({participant: np}) // TP-226
                                this.sendTechnicianMuteAllCmd({participant: np, flag: this.state.headerMuteMike}); //TP-5353, T32-413
                            })
                        }
                    } else {
                        if (new_experts && new_experts.length > 0) {
                            printConsole(`Count of new experts in the call: ${new_experts.length}`);
                            new_experts.forEach(np => {                            
                                printConsole(`Expert Participant to join, ${np.email}`);
                                //this.joiningTimer = setTimeout(()=> {
                                    this.sendTechnicianMuteAllCmd({participant: np, flag: this.state.headerMuteMike}); //T32-413
                                    //else send this signal to only experts of that session
                                    this.sendUserInfoToUser({participant: np}) // TP-226
                                    //clearTimeout(this.joiningTimer);
                                ///}, 12000);    
                            })
                        }
                    }
                }            
            }
            // TP-105 if local stream added and group info available then only call user for expert
            if(localStream !== '' && this.props.user.isexpert && this.state.groupInfo !== ''){
                this.callUser();
            } else if (localStream !== '' && !this.props.user.isexpert && this.props.audio_mode === "tech2Tech" ) { /**TP-3052 */
                this.callUserForTech();
            }
            //TP-5387
            //console.log(this.props.isGuest, new_participants);
            if (this.props.isGuest && new_participants.length > 0){
                let userArr = [];
                new_participants.forEach(nn => {
                    userArr.push(nn.userId);
                })
                if (userArr.length > 0) {
                    this.props.updateUserIdUploadImage(userArr); //TP-3483
                    this.getUserProfilePictures(userArr, true);
                }
            }
        })
    }
    //TP-5387 -- Update the profile picture for GUest users who joined the session
    updateParticipantsImages = () => {
        let participants = [...this.state.participants];
        participants && participants.forEach(part => {
            this.props.userPictures.forEach(usr => {
                if(usr.user_id === part.userId) {
                    printConsole(`User id ${usr.user_id} found in the profile picture array`);
                    /*TP-3497*/
                    if (usr.my_picture && usr.my_picture !== null && usr.my_picture !== "" && usr.my_picture.includes("profile.png") === false /**TP-3572*/)
                        part.myPicture = usr.my_picture;
                    else 
                        part.myPicture = "/profile.png";
                }
            })
        })
        //Handled for the use cases where Expert video is enabled/enabled with preference
        let updatedParticipants = [...this.state.updatedParticipants];
        updatedParticipants && updatedParticipants.length > 0 && updatedParticipants.forEach(up => {
            this.props.userPictures.forEach(usr => {
                if(usr.user_id === up.userId) {
                    printConsole(`User id ${usr.user_id} found in the profile picture array`);
                    /*TP-3497*/
                    if (usr.my_picture && usr.my_picture !== null && usr.my_picture !== "" && usr.my_picture.includes("profile.png") === false /**TP-3572*/)
                        up.myPicture = usr.my_picture;
                    else 
                        up.myPicture = "/profile.png";
                }
            })
        })
        this.setState({ participants, updatedParticipants }, () => {
            this.props.setParticipant({participantList: participants});
            //Specially for Guest technician
            if (!this.state.user.isexpert && this.state.expertVideoArr && this.state.expertVideoArr.length > 0){
                let expertVideoArr = [...this.state.expertVideoArr];
                expertVideoArr.forEach(ex => {
                    this.props.userPictures.forEach(usr => {
                        if(usr.user_id === ex.id) {
                            printConsole(`User id ${usr.user_id} found in the profile picture array`);
                            console.log(usr);
                            /*TP-3497*/
                            if (usr.my_picture && usr.my_picture !== null && usr.my_picture !== "" && usr.my_picture.includes("profile.png") === false /**TP-3572*/)
                                ex.myPicture = usr.my_picture;
                            else 
                                ex.myPicture = "/profile.png";
                        }
                    })
                })
                this.setState({ expertVideoArr, newVideoArr: expertVideoArr }, () => {
                    this.setState({triggeruiRedraw: true}, () => {
                        this.setState({ triggeruiRedraw: false });
                    })
                });
            }
        });
    }

    //TP-4837 Method to fetch the User's default/selected camera resolution
    getCameraResolution = () => {
        const { selectedWidth, selectedHeight} = this.state;
        //let stringCamera = selectedWidth.exact+"px"+selectedHeight.exact+"px";
        let aspectRatio = (selectedWidth.exact/selectedHeight.exact);
        aspectRatio = roundOff(aspectRatio, 2);
        printConsole(`camera aspect ratio ${aspectRatio}`);
        return aspectRatio;
    }

    //TP-4837 To find out the user Info about this user and to send to all experts
    sendUserInfoToUser = ({participant}) => {
        const {user_info} = this.props.user;
        let info = JSON.parse(user_info);
        printConsole("user Info");
        printConsole(info);
        // If the technician is in the Max view of the Expert
        // and is on a safari browser/Android device then remove tech_ss
        // into the signal payload else dont
        // for the signal is True  
        const ua = navigator.userAgent.toLowerCase();
        printConsole(ua);
        const cameraRes = this.getCameraResolution();//TP-4704
        let data = {};

        if (!this.props.user.isexpert) {
            data = {
                zoom_enabled: false,
                torch_enabled: false,
                screenshare_enabled: true,
                techSS_enabled: true,
                techtype: "WEB",
                browser_name: info.browser_name.toLowerCase(),
                browser_version: info.browser_version,
                os_name: info.os_name.toLowerCase(),
                os_version: info.os_version,
                deviceip: info.ip,
                coordinates: info.coordinates,
                aspectratio: cameraRes.toString()//TP-4704
            }
            
            if (/chrome/.test(ua)) {
                if (/android/.test(ua)) {
                    data.techSS_enabled = false; 
                }
            } else if (/safari/.test(ua)) {
                data.techSS_enabled = false; 
            }
        } else {
            data = {
                screenshare_enabled: !(this.props.isiOS && this.props.isSafari),                
                type: "WEB",
                browser_name: info.browser_name.toLowerCase(),
                browser_version: info.browser_version,
                os_name: info.os_name.toLowerCase(),
                os_version: info.os_version,
                deviceip: info.ip,
                coordinates: info.coordinates,
                aspectratio: cameraRes.toString()//TP-4704
            }
        }

        this.sessionSignal.sendSignalChat(0, data, true, participant.email); // TP-4884 Send the Technicians user info to all experts
    }

    // incoming call from expert/other technician
    incomingCall = (data) => {
        //console.log("Here-------------->", data);
        let {localStream, socketId} = this.state;
        // update the stream id that has been sent in data
        if(data.recipientSocketId && socketId !== data.recipientSocketId){
            return;
        }
        this.updateParticipantStreamId({data})
        this.updateMediaStreamMetaData({data}); //TP-2539
        ///console.log("local stream-->", this.state.localStream);
        //console.log(this.state.localStream.getTracks());
        const connection = this.createPeerConnection({to: data.from, localStream})
        //console.log("Connection array---------->", this.state.connections);
        connection.peerConnection.connectionOffer(data);
    }

    // response to the call by users while session is ongoing
    subscribeToRespondToTheCall = ({ action, groupName, groupId, recipient, userName }) => {
        //if (!this.isMount) return;
        let {onlineList, selectedUser} = this.state;
        // turn off the call button for selected User
        for (let i=0; i<onlineList.length; i++) {
            if((userName && onlineList[i].email === userName) || onlineList[i] === selectedUser) {
                onlineList[i].isBusy = false;
                // Clear the timer if there is already a timeout.
                if (this.timer) clearTimeout(this.timer);
                if (action === 'accepted') {
                    // When call is accepted
                } else if(action === 'declined') {
                    //TP-5903 -- Keep track of the users who declined the call
                    let {caleeDecList} = this.state;
                    caleeDecList.push(userName);
                    this.setState({ caleeDecList });                  
                } else if (action === 'in_session') {
                    // When call is already ongoing
                } else if (action === 'no_group_found') {
                    // When the call (group_id) is not found
                }
                break;
            } 
        }
        this.setState({
            onlineList,
            selectedUser: {}
        });
    }

    // TP-1129 -- Missed Call notification Implementation on the Primary expert side
    startMissedCallNotifyTimer = () => {
        printConsole("Start the missed call notify timer");
        this.missedCallTimer = setTimeout(() => {
            if (this.state.onlineList.length > 0) {
                // get the emails of users who didn't join the call yet
                let onlineEmails = this.state.onlineList.map(o => {
                    return o.email;
                });
                //TP-5903 -- Only send the online users who have not accepted or declined the call, the missed call notification email
                //console.log(onlineEmails, this.props.calleeList);
                if (this.props.calleeList && this.props.calleeList.length > 0) {
                    const temponlineEmails = onlineEmails.filter(o => {
                        return this.props.calleeList.some(dec => {
                            return o === dec
                        })
                    });
                    //console.log(temponlineEmails);
                    const {caleeDecList} = this.state;
                    //console.log(temponlineEmails, caleeDecList);
                    if (caleeDecList.length > 0) {
                        caleeDecList.forEach(dec => {
                            onlineEmails = temponlineEmails.filter(o => o !== dec);                
                        });
                    } else onlineEmails = temponlineEmails;
                    //console.log(onlineEmails); 
                    if (onlineEmails.length > 0){
                        this.sessionSignal.sendSignalMissedCallNotification({ //TP-2462
                            emails: onlineEmails
                        });
                    }
                }
                this.setState({ caleeDecList: [] });
            }
            printConsole("Stop the missed call notify timer...");
            clearTimeout(this.missedCallTimer);
        }, 31000) // After 31 seconds of the call starting up missed call to be sent 
    }

    checkSmallScreenDevice = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let flag = false;
        if (isiPad || isAndroid) {
            flag = true;
        }
        return flag;
    }

    // invoked for all
    calVdoHeightWidth = (asRatio = "16:9", isResize = false) => {
        printConsole(`calculate width of video with aspect ratio ${asRatio}`);//TP-4666
        const {aspectRatio, maxDivParticipant, participants} = this.state;
        let vdowidth, vdoheight;
        //if (this.props.isiOS && this.props.isSafari) {
        //TP-4956if (this.checkSmallScreenDevice() === true) {
            //console.log("drawing the Max-view tile for small screen device");
            let winWidth = window.innerWidth;
            let winHeight = window.innerHeight;
            const sideBar = document.getElementById('sideBar');
            let sideBarWidth = sideBar ? sideBar.offsetWidth : 0;
            vdowidth = Math.floor(winWidth - sideBarWidth - 20); // 20 is the margin
            vdoheight = (asRatio === "4:3") ? Math.ceil((vdowidth * 3)/4) : Math.floor((vdowidth * 9)/16);
            //TP-2949 -- Use case 3
            //printConsole(`inner Height: ${winHeight}, inner Width: ${winWidth}, sideBarWidth: ${sideBarWidth}, vdoheight: ${vdoheight}, vdowidth: ${vdowidth}`);
            if (vdoheight > winHeight) {
                const headerBar = document.getElementById('header_bar');
                let headerHeight = headerBar? headerBar.offsetHeight : 0;
                vdoheight = Math.floor(winHeight - headerHeight - 15);// 20 is the margin
                vdowidth =  (asRatio === "4:3") ? Math.ceil((vdoheight * 4)/3) : Math.floor((vdoheight * 16)/9);
                //printConsole(`changed -- inner Height: ${winHeight}, headerHeight: ${headerHeight}, vdoheight: ${vdoheight}, vdowidth: ${vdowidth}`);
            } 
        /*TP-4956} else {            
            let winHeight = window.innerHeight;
            const headerBar = document.getElementById('header_bar');
            let headerHeight = headerBar? headerBar.offsetHeight : 0;
            vdoheight = Math.floor(winHeight - headerHeight - 25);// 20 is the margin
            vdowidth =  (asRatio === "4:3") ? Math.ceil((vdoheight * 4)/3) : Math.floor((vdoheight * 16)/9);
            printConsole(`inner height: ${winHeight}, headerHeight: ${headerHeight}, vdoheight: ${vdoheight}, vdowidth: ${vdowidth}`);            
        }  */  
        //TP-5060
        var browserZoomLevel = Math.round(window.devicePixelRatio * 100);
        //console.log(window.window.screen.availWidth, window.innerWidth, Math.floor((70*(window.innerWidth))/100), browserZoomLevel);
        //const isAtMaxWidth = (window.window.screen.availWidth - window.innerWidth === 0) ? true : false;   
        const isAtMaxWidth = window.innerWidth > Math.floor((70*(window.window.screen.availWidth))/100) ? true : false; //TP-5060
        let isMaximizedAndDefaultZoom = isAtMaxWidth;
        //TP-5060 -- Detecting the zoom setting for Safari Browser seperately
        /*TP-5060 if (this.props.isSafari)
            isMaximizedAndDefaultZoom = browserZoomLevel <= 70 && browserZoomLevel >= 130 ? isAtMaxWidth : false; */ //TP-5060
        this.setState({vdowidth, vdoheight, aspectRatio: asRatio, isAtMaxWidth: isMaximizedAndDefaultZoom});
        if (this.props.user.isexpert) {
            this.props.setTechAspectRatio({aspectRatio: asRatio});
            //TP-4837
            if (this.state.showMaxDivControls && this.state.currentPrimaryExpert.email === this.state.user.email) {
                const experts = participants.filter(p => p.isexpert);
                //if(aspectRatio !== asRatio){
                    if(maxDivParticipant && maxDivParticipant !== '' && experts && experts.length > 0 && this.state.donotSendSyncExpert === false /**TP-6163*/){
                        //console.log("Trigger Sync_expert from calVdoHeightWidth", this.state.bAnnotate);
                        experts && experts.forEach((e) => {
                            this.sessionSignal.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: maxDivParticipant.email, //TP-3893
                                ratio: asRatio,
                                annotation_active: this.props.bAnnotate,
                                torch_button_active: maxDivParticipant ? maxDivParticipant.torchActive: false,
                                zoom_button_active: maxDivParticipant && parseInt(maxDivParticipant.zoomLevel) > 1 ? true : false,
                                zoom_level: maxDivParticipant ? parseInt(maxDivParticipant.zoomLevel) : 1,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                reason: "show_action"}, 
                            e.email);                            
                        });
                    }
                //}
            }
        }
        
        if (this.props.user.isexpert && this.state.showMaxDivControls && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email){
            // redrawing the Annotations/RC canvas along with the Video Element
            let drawElem = document.getElementById('canvas');
            if (drawElem !== null && drawElem !== undefined) {
                //this.props.setAnnotate({bAnnotate: false}, () => {
                    let nameDiv = document.getElementsByClassName("text-center nameDiv");
                    if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                        nameDiv[0].style.maxWidth = vdowidth + "px";                
                    }
                    //this.props.setAnnotate({bAnnotate: true});
                //});
            }
        } else if (!this.props.user.isexpert && isResize === true) {
            //TP-4770
            const [meParticipant] = this.state.expertVideoArr.filter (e => e.email === this.props.user.email && e.type === "camera");
            if (meParticipant && this.state.setTechMaxStreamid === meParticipant.stream.id) {
                // resizing the Annotations/RC canvas along with the Video Element
                let drawElem = document.getElementById('canvas');
                if (drawElem !== null && drawElem !== undefined) {
                    if (this.props.rcStart === true) {
                        this.props.setRCStart({rcStart: false});
                        this.props.setRCStart({rcStart: true});
                    } else {
                        drawElem.width = vdowidth;
                        drawElem.height = vdoheight;
                        drawElem.style.width = vdowidth;
                        drawElem.style.height = vdoheight;
                        let nameDiv = document.getElementsByClassName("text-center nameDiv");
                        if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                            nameDiv[0].style.maxWidth = vdowidth + "px";                
                        }
                    }
                }
            }
        } else if (this.props.user.isexpert && !this.state.showMaxDivControls) {
            // resizing the Annotations/RC canvas along with the Video Element
            let drawElem = document.getElementById('canvas');
            if (drawElem !== null && drawElem !== undefined) {
                if (this.props.rcStart === true) {
                    this.props.setRCStart({rcStart: false});
                    this.props.setRCStart({rcStart: true});
                } else if (this.props.bRemoteCapture === true) {
                    //TP-4930
                    this.props.setRCStart({rcStart: true});
                    //this.props.setRCStart({rcStart: false});
                    /* drawElem.width = vdowidth;
                    drawElem.height = vdoheight;
                    drawElem.style.width = vdowidth;
                    drawElem.style.height = vdoheight; */
                } else {
                    drawElem.width = vdowidth;
                    drawElem.height = vdoheight;
                    drawElem.style.width = vdowidth;
                    drawElem.style.height = vdoheight;
                    let nameDiv = document.getElementsByClassName("text-center nameDiv");
                    if (nameDiv !== null && nameDiv !== undefined && nameDiv.length > 0){
                        nameDiv[0].style.maxWidth = vdowidth + "px";                
                    }
                }
            }
        }
    };

    stopVideoOnly = stream => {
        stream.getTracks().forEach(function(track) {
            if (track.readyState === 'live' && track.kind === 'video') {
                track.stop();
            }
        });

        return stream;
    }

    //TP-2618 -- Disabling method for Video Track
    disableVideoTrack = stream => {
        stream.getTracks().forEach(function(track) {
            if (track.readyState === 'live' && track.kind === 'video') {
                track.enabled = false;
            }
        });

        return stream;
    }

    //TP-2618 -- Enabling method for Video Track
    enableVideoTrack = stream => {
        stream.getTracks().forEach(function(track) {
            if (track.readyState === 'live' && track.kind === 'video') {
                track.enabled = true;
            }
        });

        return stream;
    }

    //TP-5475
    enableAudioTrack = stream => {
        stream.getTracks().forEach(function(track) {
            if (track.readyState === 'live' && track.kind === 'audio') {
                track.enabled = true;
            }
        });
        return stream;
    }

    // invoked when local stream is available. This method is invoked one time
    // once local stream is available add them to the state variable and send out the notification
    gotStream = stream => {
        
        const {participants, groupInfo} = this.state;        
        const {user} = this.props;
        //TP-4155 && TP-3119 & TP-3540
        if (!user.isexpert /* && (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) || groupInfo.audio_mode === "tech2Tech") */) { /**TP-3572 */
            let expertVideoArr = [];
            //TP-3506 -- handle picture = "" for delete picture flow
            if (user.myPicture && user.myPicture !== null && user.myPicture !== "" && user.myPicture.includes("base64") === false && user.myPicture.includes("profile.png") === false)
                user.myPicture = 'data:image/jpg;base64,'+ user.myPicture;
            else 
                user.myPicture = "/profile.png";
            let videoObject = {
                id: user.id, //TP-3423
                stream,
                fullName: user.firstname + " " + user.lastname,
                email: user.email,
                type: "camera",
                isexpert: user.isexpert,
                myPicture: user.myPicture, //TP-3423
                showOnlineIndicator: true, //TP-3423
                isexpert: user.isexpert, //TP-3657
                isadmin: user.isadmin, //TP-3657
                isThisMe: true, //TP-3657
                isPaused: false, //TP-4483
                aspect_ratio: this.getCameraResolution() === "1.33" || this.getCameraResolution() === "1.34" ? "4:3" : "16:9",//TP-4849
                poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
            }
            expertVideoArr.push(videoObject); 
            this.setState({ expertVideoArr, newVideoArr: expertVideoArr }, () => {
                this.setState({ maxViewPicture: user.myPicture, hasMaxViewVideoTracks: true /**TP-3701*/ });
            });//TP-3114
        } //else {
            if (this.localVideoRef.current !== null) this.localVideoRef.current.srcObject = stream;
            if (this.localVideoRef.current !== null) this.localVideoRef.current.id = stream.id;

        //}
        //console.log(this.props.session_type, this.props.enable_user_video, this.props.user.my_camera_on, this.checkIfExpertCameraEnabled());
        //TP-4155 && TP-2539 & TP-3119
        if (user.isexpert && (this.props.session_type === "CM" || (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true))) { /**TP-3419*/
            //TP-3156
            printConsole("video tracks on expert user with PeertoPeer behaviour ===========>");
            printConsole(stream.getVideoTracks());
        } else if (this.props.user.isexpert) {
            stream = this.stopVideoOnly(stream);
            printConsole("video tracks on expert user===========>");
            printConsole(stream.getVideoTracks());//TP-4155
            //stream.getVideoTracks()[0].enabled = false

        } else {
            printConsole("video tracks on technician user===========>",stream.getVideoTracks());
        }
        
        this.setState({
            localStream: stream
        },() => {
            //console.log("Set the local stream to my camera stream ===========>", this.state.localStream);
            this.initializeAudioDetection(this.state.localStream);
            //TP-5678
            /* if(this.volumeCallback !== null && (this.volumeInterval === null && this.volumeInterval2 === null) && this.state.connections.length <= 2) {
                console.log("Start the Audio detection, from beginning");
                const func = () => {
                    this.volumeCallback();
                    this.volumeInterval2 = setTimeout(func, 5000);
                }
                this.volumeInterval = setTimeout(func, 5000);
            } else */ if (this.state.connections.length > 2) {
                //TP-5733
                console.log("Stop the Audio detection, from beginning");
                if(this.volumeInterval !== null) {
                    clearTimeout(this.volumeInterval);//TP-5678
                    this.volumeInterval = null;
                } 
                //TP-5678
                if(this.volumeInterval2 !== null) {
                    clearTimeout(this.volumeInterval2);
                    this.volumeInterval2 = null;
                }
            }
            this.notifyGroup();
            if (!this.props.user.isexpert){   
                this.setState({setTechMaxStreamid: stream.id}); //TP-2539
                this.props.setMaxDivId({g_maxDivId: stream.id, maxDivParticipant: ''})// set maxdiv id to be used for 3d file rendering for tech
                if (this.decodedUser && this.decodedUser.showuploadspeed){
                    this.props.checkDownloadSpeed();//MB2-588
                    this.props.checkUploadSpeed();//MB2-588
                }
            }            
            // sometimes expert will recieve update user list event even before local stream is available
            // like expert is in firefox and tech is in chrome then we will call user after we local stream is set
            //TP-105 group info has to be present
            if(participants.length > 0 && this.props.user.isexpert && this.state.groupInfo !== ''){
                this.callUser();
            } /**TP-3156 */ else if (participants.length > 0 && !this.props.user.isexpert && this.props.audio_mode === "tech2Tech" ) { /**TP-3052 */
                this.callUserForTech();
            }

        });
    };

    initializeAudioDetection = async (stream) => {
        this.volumeCallback = null;
        this.volumeInterval = null;
        this.volumeInterval2 = null;//TP-5678
        const {user} = this.props;
        let audioContext;
        if (this.props.isSafari)
            audioContext = new (window.AudioContext || window.webkitAudioContext)();
        else
            audioContext = new AudioContext();
        const audioSource = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 32; //TP-5681
        //change the fftSize based on the browser using a sample app
        analyser.minDecibels = -127;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.8;
        audioSource.connect(analyser);
        const volumes = new Uint8Array(analyser.frequencyBinCount);
        this.volumeCallback = () => {
            ++audioVolumeTickCount; //TP-5733
            //console.log(volumes)
            analyser.getByteFrequencyData(volumes);
            //console.log(volumes)
            let volumeSum = 0;
            for(const volume of volumes)
                volumeSum += volume;
            const averageVolume = volumeSum / volumes.length;
            //const maxVolume = volumes.reduce((a, b) => Math.max(a, b), -Infinity);
            //const minVolume = volumes.reduce((a, b) => Math.min(a, b));
            // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
            //TP-5733 console.log(audioVolumeTickCount);
            if (audioVolumeTickCount%10 === 0) printConsole(`Current volume in decibels (avg): ${averageVolume}`)
            //TP-5699
            let isExpertAudioUnMutedCounter = 0;
            this.state.participants.forEach(p => {
                if (!p.disableMike) isExpertAudioUnMutedCounter += 1;
            })
            //TP-5681 & TP-6149
            //if (averageVolume > 0 && averageVolume > 5) {
            if (averageVolume > 0 && averageVolume > 5 && averageVolume > this.state.currentDecibel) {
                //TP-5733
                if (audioVolumeTickCount%10 === 0) printConsole("user Audio is detected");
                //TP-5681 TP-5699
                if ((user.isexpert && isExpertAudioUnMutedCounter === 0) || (!user.isexpert && this.state.headerMuteMike === true)) {
                    const leftFlash = window.innerWidth >= 1450 ? user.isexpert ? "41%" : "43%" : window.innerWidth >= 1100 ? "39%" : "32%";
                    const topFlash = window.innerWidth >= 1450 ? "79%" : window.innerWidth >= 1100 ? "75%" : "70%";
                    this.setState({showIsMutedbutSpeaking: true }, () => {
                        if ((user.isexpert && this.state.maxView) || !user.isexpert ) {
                            //TP-5179
                            this.setState({ flashMsgText: "", showFlashMessage: false, flashLeft: "", flashTop: "", flashTimer: true}, () => {
                                this.triggerMessage("you-are-muted", "you-are-muted", leftFlash, topFlash);
                            });
                        }
                    });
                }
                else
                    this.setState({ showIsMutedbutSpeaking: false }, () => {
                        //TP-5352
                        if (((user.isexpert && this.state.maxView) || !user.isexpert) && this.state.flashMsgText === "session.mutedbuttalking") {
                            this.setState({ flashMsgText: "", showFlashMessage: false, flashLeft: "", flashTop: "", flashTimer: true});
                        } else if (((user.isexpert && this.state.maxView) || !user.isexpert) && this.state.flashMsgText !== "session.mutedbuttalking") {
                            //TP-5179 & TP-6081
                            /* this.setState({flashTimer: false}, () => {
                                this.setState({flashTimer: true});
                            }); */
                        }
                    });
                this.state.participants.length > 0 && this.state.participants.forEach(p => {
                    //TP-5699, TP-5678 -- Only send this signal to experts and not to technicians
                    if (isExpertAudioUnMutedCounter > 0 && p.isexpert)
                        this.sessionSignal.sendSignalUserSpeakerOn(true, p.email);                
                });
            }
            else {
                //TP-5733
                if (audioVolumeTickCount%10 === 0) printConsole("user no Audio detected");
                //TP-5681
                this.setState({ showIsMutedbutSpeaking: false }, () => {
                    //TP-5352
                    if (((user.isexpert && this.state.maxView) || !user.isexpert) && this.state.flashMsgText === "session.mutedbuttalking") {
                        this.setState({ flashMsgText: "", showFlashMessage: false, flashLeft: "", flashTop: "", flashTimer: true });
                    }
                });
                this.state.participants.length > 0 && this.state.participants.forEach(p => {
                    //TP-5699, TP-5678 -- Only send this signal to experts and not technicians
                    if (isExpertAudioUnMutedCounter > 0 && p.isexpert/* this.state.headerMuteMike === false */)
                        this.sessionSignal.sendSignalUserSpeakerOn(false, p.email);
                })

                
            }
            this.setState({ currentDecibel: averageVolume});
        };
    }

    componentWillUnmount () {
        if(this.volumeInterval !== null) {
            clearTimeout(this.volumeInterval);//TP-5678
            this.volumeInterval = null;
        } 
        //TP-5678
        if(this.volumeInterval2 !== null) {
            clearTimeout(this.volumeInterval2);
            this.volumeInterval2 = null;
        }
    }

    // this being invoked for technician. Expert has its own version
    gotRemoteTrack = ({track, streams: [stream]}) => {
        printConsole(`got remote stream, the track is `); 
        printConsole(track);
        printConsole(`and the stream is`);
        printConsole(stream);
        if (stream === undefined) return [];
        this.updateParticipantStream({track, stream})
    };

    // update the audio/video stream of participant
    updateParticipantStream = async ({track, stream}) => {
        try{            
            const {user} = this.props; /**TP-2539 */            
            printConsole(`The participant list, ${JSON.stringify(this.state.participants)}`);
            const elementsIndex = this.state.participants.findIndex(element => element.stream_id === stream.id );
            printConsole(elementsIndex);
            const otherTechnicianIndex = this.state.participants.findIndex(element => element.stream_id !== stream.id && !element.isexpert);
            printConsole(otherTechnicianIndex);
            let participants = [...this.state.participants];
            //TP-3611 -- Handle the flickering of the Video streaming whenever any button is clicked
            let updatedParticipants, ii;
            if (user.isexpert) {
                updatedParticipants = [...this.state.updatedParticipants];
                ii = this.state.updatedParticipants.findIndex(element => element.stream_id === stream.id );
            }
            let p = participants[elementsIndex];
            printConsole(`Matched participant ==============> ${JSON.stringify(p)}`);
            if (p === undefined) {
                const message = "Unknown stream with id " + stream.id + " from user is encountered. Hence ignoring";
                printConsole(message);
                this.triggerMessage(stream.id, "unknown-stream", '10%', '50%');
                return [p,otherTechnicianIndex];
            }
            if(p && p.isexpert && track.kind === 'video') {
                //stream.getVideoTracks()[0].enabled = false
                if (p.disableVideo === true) {
                    //console.log("Turning on the Expert's Video");
                    stream.getVideoTracks()[0].enabled = true;
                    p.disableVideo = false;
                    //TP-4077 & TP-3611
                    if (user.isexpert && this.props.session_type === "RM" && updatedParticipants[ii] !== undefined && this.checkIfExpertCameraEnabled() === true)
                        updatedParticipants[ii].disableVideo = false;
                }
                // T32-463 
                /* if (this.state.showMaxDivControls && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email){
                    this.sessionSignal.sendSignalSyncExperts({
                        isPrimary: true,
                        expertId: this.state.user.id,
                        email: this.state.user.email,
                        firstname: this.state.user.firstname,
                        lastname: this.state.user.lastname,
                        joinTimeStamp: this.state.user.joinTimeStamp,
                        reason: "primary_sync"},
                    p.email);
                } */
            } else if (p && !p.isexpert && track.kind === 'video') {
                if (p.disableVideo === true) {
                    //console.log("Turning on the Technician's Video");
                    stream.getVideoTracks()[0].enabled = true;
                    p.disableVideo = false;
                    //TP-3611
                    if (user.isexpert && this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true)
                        updatedParticipants[ii].disableVideo = false;
                }
            }
            if (p && p.streams){
                p.streams.push(stream);
                if (/* p.email === data.from && */ p.streams && p.streams.length > 0) {
                    const strms = p.streams.filter(st => st.id === stream.id)
                    p.streams = strms;
                }
                p.disableRemoteAudio && this.muteSpeakerOfOne({participant: p, flag: false});
            }else if (p){
                p.streams = [stream]
                // send mute/unmute speaker/mike for the newly joined participant. calling it here to avoid call being send twice
                this.state.mutedAll /* && !this.state.disabledMutedAll */ && this.muteSpeakerOfOne({participant: p, flag: !this.state.mutedAll})// mute/unmute the speaker for the incoming participant
                this.state.headerMuteMike && this.sendMuteMikeSignal({participant: p, flag: !this.state.headerMuteMike})// mute/unmute the mike for the incoming participant
                printConsole(`disable Audio flag ${p.disableRemoteAudio}`);
                p.disableRemoteAudio && this.muteSpeakerOfOne({participant: p, flag: false});
                //TP-5179
                //TP-5179
                if (track.kind === "audio")
                    this.setState({ flashMsgText: "", showFlashMessage: false, flashLeft: "", flashTop: "", flashTimer: true }, () => {
                        const flashleft = window.innerWidth >= 1770 ? "4%" : window.innerWidth >= 1200 ? "5.5%" : "6%";
                        this.state.user.isexpert && this.state.maxView && this.triggerMessage(p, "connect", flashleft, "3%");// show flash message MB2-473
                    });
            } if (p && p.isexpert && p.streams) {
                p.streams.forEach(str => {
                    //console.log("Length of Video Tracks in the new stream....", str.getVideoTracks().length)
                    if(str.getVideoTracks().length === 0) {
                        p.disableVideo = true;
                        //TP-4077 & TP-3611
                        if (user.isexpert && this.props.session_type === "RM" && updatedParticipants[ii] !== undefined && this.checkIfExpertCameraEnabled() === true)
                            updatedParticipants[ii].disableVideo = true;
                    }
                })
            } if (this.state.user.isexpert && p && !p.isexpert && p.streams && track.kind === 'video' && p.grayedVideo === true) {
                p.grayedVideo = false;
                //TP-4077 & TP-3611
                if (user.isexpert && this.props.session_type === "RM" && updatedParticipants[ii] !== undefined && this.checkIfExpertCameraEnabled() === true)
                    updatedParticipants[ii].grayedVideo = false;
            }
            let isScreencasting = false;//TP-3955
            /**TP-4077 & TP-3551 & TP-3052 & TP-2539 & TP-3156 & TP-3119 */
            if (!user.isexpert && (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.state.groupInfo.audio_mode === "tech2Tech")) {
                if (p /* && p.isexpert */ && track.kind === "video") {
                    //console.log("track ------------->",track);
                    //console.log(p.metaData);
                    let videoObject = {};
                    let expertVideoArr = this.state.expertVideoArr;
                    if (JSON.stringify(p.metaData) === '{}') {
                        expertVideoArr = expertVideoArr.filter(e => e.email !== p.email);
                        //console.log(expertVideoArr);
                        p.streams = [];
                        p.streams = [stream];
                        videoObject = {
                            id: p.userId,
                            stream,
                            fullName: p.first_name + " " + p.last_name,
                            email: p.email,
                            type: "camera",
                            isexpert: p.isexpert,
                            myPicture: p.myPicture,
                            showOnlineIndicator: true,
                            isexpert: p.isexpert, //TP-3657
                            isadmin: p.isadmin, //TP-3657
                            isPaused: false, //TP-4483
                            aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9",
                            poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                        }
                        expertVideoArr.push(videoObject); 
                    } else if (p.metaData !== "") {
                        /**TP-3343 -- Handled Screencast view on Technician side when only Tech2tech is enabled */
                        //console.log(this.props.enable_user_video, " is expert's video disabled ?", p.grayedVideo);
                        //TP-5231 & TP-3343
                        if (p.metaData && p.metaData.type === "screen" && (this.props.enable_user_video === "my_camera_disabled" || 
                            (this.props.enable_user_video === "my_camera_enabled_with_prefs" && p.grayedVideo === true) || 
                            (this.props.enable_user_video === "my_camera_enabled" && p.grayedVideo === true))) {
                            //console.log("Screen cast track received and Expert's Video is disabled..");
                            displayTrackId = track.id;
                            expertVideoArr = expertVideoArr.filter(e => e.stream.active === true); //TP-2618
                            expertVideoArr = expertVideoArr.filter(e => e.email !== p.email);
                            //console.log(expertVideoArr);
                            p.streams = [];
                            p.streams = [stream];
                            //console.log(stream.getVideoTracks());
                            let new_stream = new MediaStream();
                            new_stream.addTrack(track);
                            //console.log(new_stream);
                            //stream.removeTrack(track);
                            //p.streams = [stream];
                            //p.streams.push(new_stream);
                            videoObject = {
                                id: p.userId,
                                stream: new_stream,//TP-5231 TP-4077
                                fullName: p.first_name + " " + p.last_name,
                                email: p.email,
                                type: "screen",
                                isexpert: p.isexpert,
                                myPicture: p.myPicture,
                                showOnlineIndicator: true,
                                isexpert: p.isexpert,
                                isadmin: p.isadmin,
                                isPaused: false, //TP-4483
                                aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", //TP-4849
                                poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                            }
                            expertVideoArr.push(videoObject);
                        } else if (p.metaData && p.metaData.type === "screen" && this.state.isSSOngoing === false) {
                            /**TP-5320 -- Feature change of pushing the 1st Video track as Screen type
                             * and 2nd Video track as camera track so no matching of Track.id anymore
                             */
                            //console.log(stream.getVideoTracks());
                            if (stream.getVideoTracks().length === 1) {
                                /** TP-5358 If only One Video Track is incoming                                 * 
                                 */
                                this.setState({isSSOngoing: true}, () => {
                                    expertVideoArr = expertVideoArr.filter(e => e.stream.active === true);
                                    let displayStream = new MediaStream(); 
                                    displayStream.addTrack(stream.getVideoTracks()[0]); // add the only video Track
                                    displayStream.addTrack(stream.getAudioTracks()[0]); //add the Audio track
                                    videoObject = {
                                        id: p.userId,
                                        stream: displayStream ? displayStream : stream,//TP-4077
                                        fullName: p.first_name + " " + p.last_name,
                                        email: p.email,
                                        type: "screen",
                                        isexpert: p.isexpert,
                                        myPicture: p.myPicture,
                                        showOnlineIndicator: true,
                                        isexpert: p.isexpert,
                                        isadmin: p.isadmin,
                                        isPaused: false, //TP-4483
                                        aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", //TP-4849
                                        poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                                    }
                                    expertVideoArr.push(videoObject);   
                                })
                            } else if (stream.getVideoTracks().length > 1 && matchTrack(p.metaData.id, stream) === true) {
                                /** TP-5358 if the Track id is matching for the Technician's Browser                                 * 
                                 */
                                printConsole("Track ID is matching with the metadata on Tech")
                                this.setState({isSSOngoing: true}, () => {
                                    expertVideoArr = expertVideoArr.filter(e => e.stream.active === true);
                                    let displayStream = new MediaStream();
                                    displayStream.addTrack(getDisplayTrack(p.metaData.id, stream));
                                    let cameraStream = new MediaStream();
                                    cameraStream.addTrack(getCameraTrack(p.metaData.id, stream)); //add the 2nd Video track as camera type
                                    cameraStream.addTrack(stream.getAudioTracks()[0]);//add the Audio track to the camera stream
                                    videoObject = {
                                        id: p.userId,
                                        stream: displayStream && displayStream !== null ? displayStream : stream,//TP-4077
                                        fullName: p.first_name + " " + p.last_name,
                                        email: p.email,
                                        type: "screen",
                                        isexpert: p.isexpert,
                                        myPicture: p.myPicture,
                                        showOnlineIndicator: true,
                                        isexpert: p.isexpert,
                                        isadmin: p.isadmin,
                                        isPaused: false, //TP-4483
                                        aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", //TP-4849
                                        poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                                    }
                                    expertVideoArr.push(videoObject);
                                    videoObject = {
                                        id: p.userId,
                                        stream: cameraStream && cameraStream !== null ? cameraStream : stream,//TP-4077
                                        fullName: p.first_name + " " + p.last_name,
                                        email: p.email,
                                        type: "camera",
                                        isexpert: p.isexpert,
                                        myPicture: p.myPicture,
                                        showOnlineIndicator: true,
                                        isexpert: p.isexpert,
                                        isadmin: p.isadmin,
                                        isPaused: false, //TP-4483
                                        aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", //TP-4849
                                        poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                                    }
                                    expertVideoArr.push(videoObject);
                                });
                            } else if (stream.getVideoTracks().length > 1) {
                                /** Feature change of pushing the 1st Video track as Screen type
                                 * and 2nd Video track as camera track so no matching of Track.id anymore
                                 */
                                printConsole("Track ID is not matching so fallback");
                                this.setState({isSSOngoing: true}, () => {
                                    expertVideoArr = expertVideoArr.filter(e => e.stream.active === true);
                                    let displayStream = new MediaStream();
                                    displayStream.addTrack(stream.getVideoTracks()[0]); //add the first Video track as screen type
                                    let cameraStream = new MediaStream();
                                    cameraStream.addTrack(stream.getVideoTracks()[1]); //add the 2nd Video track as camera type
                                    cameraStream.addTrack(stream.getAudioTracks()[0]);//add the Audio track to the camera stream
                                    videoObject = {
                                        id: p.userId,
                                        stream: displayStream ? displayStream : stream,//TP-4077
                                        fullName: p.first_name + " " + p.last_name,
                                        email: p.email,
                                        type: "screen",
                                        isexpert: p.isexpert,
                                        myPicture: p.myPicture,
                                        showOnlineIndicator: true,
                                        isexpert: p.isexpert,
                                        isadmin: p.isadmin,
                                        isPaused: false, //TP-4483
                                        aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", //TP-4849
                                        poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                                    }
                                    expertVideoArr.push(videoObject);
                                    videoObject = {
                                        id: p.userId,
                                        stream: cameraStream ? cameraStream : stream,//TP-4077
                                        fullName: p.first_name + " " + p.last_name,
                                        email: p.email,
                                        type: "camera",
                                        isexpert: p.isexpert,
                                        myPicture: p.myPicture,
                                        showOnlineIndicator: true,
                                        isexpert: p.isexpert,
                                        isadmin: p.isadmin,
                                        isPaused: false, //TP-4483
                                        aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", //TP-4849
                                        poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                                    }
                                    expertVideoArr.push(videoObject);
                                })
                            }                            
                        }
                    } else {
                        //TP-2618
                        videoObject = {
                            id: p.userId,
                            stream: stream,
                            fullName: p.first_name + " " + p.last_name,
                            email: p.email,
                            type: "camera",
                            isexpert: p.isexpert,
                            myPicture: p.myPicture,
                            showOnlineIndicator: true,
                            isexpert: p.isexpert, //TP-3657
                            isadmin: p.isadmin, //TP-3657
                            isPaused: false, //TP-4483,
                            aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", //TP-4849
                            poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                        }
                        expertVideoArr.push(videoObject);
                        
                    }
                    //console.log("show the video array......",expertVideoArr); //TP-3657
                    this.setState({ expertVideoArr, newVideoArr: expertVideoArr }, () => {
                        //TP-3213
                        this.setState({triggeruiRedraw: true}, () => {
                            this.setState({ triggeruiRedraw: false });
                        })
                    }) //TP-3114
                    //console.log(p.streams);
                } else if (p && track.kind === "audio" && JSON.stringify(p.metaData) === '{}') {
                    //console.log("audio track ------------->",track);
                    //console.log(p.metaData);
                    let videoObject = {};
                    let expertVideoArr = this.state.expertVideoArr;
                    //console.log(this.props.enable_user_video, p.grayedVideo);
                    //TP-5231
                    if ((this.props.enable_user_video === "my_camera_enabled_with_prefs" || this.props.enable_user_video === "my_camera_enabled") && p.grayedVideo === true) {
                        printConsole("Re-drawing the Expert's Audio Tile again on Technician, if Expert video is disabled from user setting");
                        expertVideoArr = expertVideoArr.filter(e => e.email !== p.email);
                        //console.log(expertVideoArr);
                        p.streams = [];
                        p.streams = [stream];
                        videoObject = {
                            id: p.userId,
                            stream,
                            fullName: p.first_name + " " + p.last_name,
                            email: p.email,
                            type: "camera",
                            isexpert: p.isexpert,
                            myPicture: p.myPicture,
                            showOnlineIndicator: true,
                            isexpert: p.isexpert, //TP-3657
                            isadmin: p.isadmin, //TP-3657
                            isPaused: false, //TP-4483
                            aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9",
                            poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                        }
                        expertVideoArr.push(videoObject); 
                        //console.log("show the video array......",expertVideoArr); //TP-3657
                        this.setState({ expertVideoArr, newVideoArr: expertVideoArr }, () => {
                            //TP-3213
                            this.setState({triggeruiRedraw: true}, () => {
                                this.setState({ triggeruiRedraw: false });
                            })
                        }) //TP-3114
                        //console.log(p.streams);
                    }
                } else if (p && track.kind === "audio" && JSON.stringify(p.metaData) !== "" && JSON.stringify(p.metaData) !== '{}') {
                    //TP-5231
                    if (p.metaData && p.metaData.type === "screen" && (this.props.enable_user_video === "my_camera_disabled" || 
                        (this.props.enable_user_video === "my_camera_enabled_with_prefs" && p.grayedVideo === true) || 
                        (this.props.enable_user_video === "my_camera_enabled" && p.grayedVideo === true))) {
                            let videoObject = {};
                            let expertVideoArr = this.state.expertVideoArr;
                            let audio_stream = new MediaStream();
                            audio_stream.addTrack(stream.getAudioTracks()[0]);
                            videoObject = {
                                id: p.userId,
                                stream: audio_stream,//TP-4077
                                fullName: p.first_name + " " + p.last_name,
                                email: p.email,
                                type: "camera",
                                isexpert: p.isexpert,
                                myPicture: p.myPicture,
                                showOnlineIndicator: true,
                                isexpert: p.isexpert,
                                isadmin: p.isadmin,
                                isPaused: false, //TP-4483
                                aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", //TP-4849
                                poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                            }
                            expertVideoArr.push(videoObject);
                    }
                }
            } else if (user.isexpert && (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled" /**TP-4257*/))) {
                //TP-3551 & TP-2618 & TP-3156 & TP-3119
                if (p && p.isexpert && track.kind === "video") {
                    //console.log("passive expert track ------------->",track);
                    //console.log(p.metaData);
                    let videoObject = {};
                    let expertVideoArr = this.state.expertVideoArr;
                    if (JSON.stringify(p.metaData) === '{}') {
                        p.streams = [];
                        //TP-3921
                        stream.getVideoTracks().forEach(track => {
                            track.enabled = true;
                        })
                        p.streams = [stream];   
                        isScreencasting = false; 
                        this.setState({isScreencasting});//TP-3955 
                    } else if (p.metaData !== "") {
                        if (p.metaData && p.metaData.type === "screen" && this.state.isSSOngoing === false) {
                            /**TP-5320
                             */
                            //console.log(stream.getVideoTracks());
                            if (stream.getVideoTracks().length === 1) {
                                /**TP-5358 If only One Video Track is incoming                                 * 
                                 */
                                this.setState({isSSOngoing: true}, () => {
                                    let displayStream = new MediaStream(); 
                                    displayStream.addTrack(stream.getVideoTracks()[0]); // add the only video Track
                                    displayStream.addTrack(stream.getAudioTracks()[0]); //add the Audio track
                                    p.streams = [];
                                    p.streams = [displayStream];
                                    //TP-3955
                                    isScreencasting = true;
                                    this.setState({isScreencasting});   
                                })
                            } else if (stream.getVideoTracks().length > 1 && matchTrack(p.metaData.id, stream) === true) {
                                /** if the Track id is matching for the Technician's Browser                                 * 
                                 */
                                printConsole("Track ID is matching with the metadata on Tech")
                                this.setState({isSSOngoing: true}, () => {
                                    let displayStream = new MediaStream();
                                    displayStream.addTrack(getDisplayTrack(p.metaData.id, stream));
                                    let cameraStream = new MediaStream();
                                    cameraStream.addTrack(getCameraTrack(p.metaData.id, stream)); //add the 2nd Video track as camera type
                                    displayStream.addTrack(stream.getAudioTracks()[0]);//add the Audio track to the camera stream
                                    p.streams = [];
                                    p.streams = [displayStream];
                                    //TP-3955
                                    isScreencasting = true;
                                    this.setState({isScreencasting});                                    
                                });
                            } else if (stream.getVideoTracks().length > 1) {
                                /** Feature change of pushing the 1st Video track as Screen type
                                 * and 2nd Video track as camera track so no matching of Track.id anymore
                                 */
                                printConsole("Track ID is not matching so fallback");
                                this.setState({isSSOngoing: true}, () => {
                                    let displayStream = new MediaStream();
                                    let cameraStream = new MediaStream();
                                    /*TP-4861 if(this.props.isSafari) {
                                        displayStream.addTrack(stream.getVideoTracks()[1]); //add the first Video track as screen type
                                        cameraStream.addTrack(stream.getVideoTracks()[0]); //add the 2nd Video track as camera type
                                    } else { */
                                        displayStream.addTrack(stream.getVideoTracks()[0]); //add the first Video track as screen type
                                        cameraStream.addTrack(stream.getVideoTracks()[1]); //add the 2nd Video track as camera type
                                    //}

                                    displayStream.addTrack(stream.getAudioTracks()[0]);//add the Audio track to the camera stream
                                    p.streams = [];
                                    p.streams = [displayStream];   
                                    isScreencasting = true; 
                                    this.setState({isScreencasting}); 
                                })
                            }                            
                        }
                    } else {
                        //TP-2618
                        videoObject = {
                            id: p.userId,
                            stream: stream,
                            fullName: p.first_name + " " + p.last_name,
                            email: p.email,
                            type: "camera",
                            isexpert: p.isexpert,
                            myPicture: p.myPicture,
                            showOnlineIndicator: true,
                            isexpert: p.isexpert, //TP-3657
                            isadmin: p.isadmin, //TP-3657
                            isPaused: false, //TP-4483,
                            aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", //TP-4849
                            poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                        }
                        expertVideoArr.push(videoObject);
                        
                    }
                    
                    //console.log(p.streams);
                } else if (p && p.isexpert && track.kind === "audio") {
                    //TP-4257
                    if (JSON.stringify(p.metaData) !== '{}' && p.metaData !== "") {
                        const displayTracks = stream.getVideoTracks();
                        //console.log(displayTracks);
                        const [displayTrack] = displayTracks.filter(t => (t.id === p.metaData.id)); //TP-2618
                        if (displayTrack) {
                            const audioTrack = stream.getAudioTracks()[0];
                            //console.log(audioTrack);
                            let new_stream = new MediaStream();
                            new_stream.addTrack(displayTrack);
                            new_stream.addTrack(audioTrack);
                            p.streams = [];
                            p.streams = [new_stream]; 
                        }
                    }
                }
            } /* else if (user.isexpert && this.state.groupInfo.session_type === "CM" && this.state.participants.length > 1) {
                let {localStream} = this.state;
                //localStream = this.disableVideoTrack(localStream); //TP-2618
                printConsole("video tracks on expert user after more users joined===========>");
                printConsole(localStream.getVideoTracks());     
                this.setState({ localStream });
            } */
            //TP-4575
            if (!p.isexpert) 
                participants[elementsIndex] = {...participants[elementsIndex], grayedVideo: p.grayedVideo, streams: p.streams};
            else
                participants[elementsIndex] = {...participants[elementsIndex], streams: p.streams};
            //TP-3611 & TP-3573
            if (user.isexpert && this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                //TP-4575
                if (!p.isexpert)
                    updatedParticipants[ii] = {...updatedParticipants[ii], grayedVideo: p.grayedVideo, streams: p.streams};
                else
                    updatedParticipants[ii] = {...updatedParticipants[ii], streams: p.streams};
            }
            if (this.state.user.isexpert && participants[elementsIndex].streams.length > 0)
                this.stopExpertCounter();
            /**TP-3611 */
            if (this.state.user.isexpert) {
                this.setState({participants, updatedParticipants}, () => {
                    printConsole(`audio/video with stream id ${stream.id} for Track ${track.id} updated,`);//TP-4257
                    printConsole(participants);
                    if (isScreencasting === true) {
                        //TP-3955
                        if (!this.state.maxView && participants[elementsIndex].isexpert === true) {    
                            printConsole("Show the participants tile in Max-view while the Screen cast Socket signals come");
                            this.setState({ prevMaxDivPartcicipantEmail: ''})
                            this.setState({maxDivParticipantEmail: participants[elementsIndex].email})//MB2-608
                            this.triggerMessage(participants[elementsIndex].first_name+participants[elementsIndex].last_name , "screencast-started")
                            //Instead of showing the participant tile in max-view show a blank screen while the Screencast Socket signals arrive
                            this.onMaximizeSilhouetteView({participant: participants[elementsIndex]}); //TP-4908
                            //this.onClickMaximise({participant: participants[elementsIndex], isSame: false});
                            //this.calVdoHeightWidth("16:9");
                            //this.props.checkAnnotation();
                        } else if (this.state.maxView && participants[elementsIndex].isexpert === true) {
                            this.triggerMessage(participants[elementsIndex].first_name+participants[elementsIndex].last_name , "screencast-started") //TP-4908
                        }
                    }
                });
            } else {
                this.setState({participants}, () => {
                    printConsole(`audio/video with stream id ${stream.id} updated,`);
                    printConsole(participants);
                });
            }
            return [p,otherTechnicianIndex];
        }catch(error){
           printConsole(error) 
        }
    }

    // update the stream id of participant
    updateParticipantStreamId = ({data}) => {
        let {participants} = this.state
        // update the stream of the person whose has answered the call
        // {answer/offer: data.answer/data.offer, from: socket.nickname, stream_id: data.stream_id }
        participants = participants.map((p) => {
            if(p.email === data.from) p.stream_id = data.stream_id;            
            return p
        });
        this.setState({ participants },console.log(`${data.from} stream id updated`, participants))
    }

    //TP-2539 -- update the mediaStream meta data either undefined or a jSON
    updateMediaStreamMetaData = ({data}) => {
        let {participants} = this.state;
        if (data.media_stream_meta_data !== undefined) {
            printConsole(Array.isArray(data.media_stream_meta_data));
            participants = participants.map((p) => {
                if(p.email === data.from) p.metaData = /**TP-4178*/ (Array.isArray(data.media_stream_meta_data) === true) ? {} : data.media_stream_meta_data;            
                return p
            });
        } else {
            participants = participants.map((p) => {
                if(p.email === data.from) p.metaData = "";            
                return p
            });
        }
    }

    // create the peer connection.Invoke for all
    createPeerConnection = ({to}) => {
        console.log("Create peer-connection, ",to);
        const {connections, localStream, user, groupInfo} = this.state;
        const {session_type, audio_mode} = this.props;
        let connection = connections.find((connection) => connection.email === to)
        //console.log(connection);
        if (connection) return connection;
        //printConsole(user.isexpert);
        //console.log("localStream ===============>",localStream);   
        //printConsole(connections);
        /* if (this.state.user.isexpert && connections.length < 1 &&this.state.groupInfo.primary_expert_id === this.state.user.id)
            this.startExpertTimer();  */       
        const peerConnection = new WebrtcPeerConnection({
            gotRemoteTrack: this.gotRemoteTrack,
            onClose: this.closeVideoCall,
            onConnectionStateChange: this.onConnectionStateChange,
            user: user,
            to: to,
            localStream: localStream,
            groupId: this.props.selectedGroupId,
            sessionSignal: this.sessionSignal,
            turn_username: groupInfo.turn_username,
            turn_password: groupInfo.turn_password,
            sessionType: session_type === "CM" || (session_type === "RM" && this.checkIfExpertCameraEnabled() === true) || audio_mode === "tech2Tech" ? true : false /**TP-3119*/
        });
        connection = {email: to, peerConnection} // data format
        this.setState((prevState) => ({
            connections: [...prevState.connections, connection]
        }), () => {
            //TP-5733 Handle either creating or removing the Audio Detection Timer depending on the number of peer-connections present
            printConsole(this.state.connections);
            if (this.state.connections.length <= 2) {
                if(this.volumeCallback !== null && (this.volumeInterval === null && this.volumeInterval2 === null)) {
                    console.log("start the Audio Detectoin timer, when creating peerconnection");
                    audioVolumeTickCount = 0;
                    const func = () => {
                        this.volumeCallback();
                        this.volumeInterval2 = setTimeout(func, 5000);
                    }
                    this.volumeInterval = setTimeout(func, 5000);
                }
            } else {
                if(this.volumeInterval !== null) {
                    console.log("stop the Audio Detectoin timer, when creating peerconnection");
                    clearTimeout(this.volumeInterval);//TP-5678
                    this.volumeInterval = null;
                } 
                //TP-5678
                if(this.volumeInterval2 !== null) {
                    clearTimeout(this.volumeInterval2);
                    this.volumeInterval2 = null;
                }
            }
            
            /* if (this.state.user.isexpert && this.state.connections.length === 1 && this.state.connections[0].peerConnection && this.state.connections[0].peerConnection.peerConnection && this.state.connections[0].peerConnection.peerConnection.iceConnectionState === "connected"){
                this.stopExpertCounter();
            } else if (this.state.user.isexpert && this.state.connections.length > 1) {
                this.stopExpertCounter();
            } else */ if (this.state.user.isexpert && this.state.connections.length === 1 && this.state.connections[0].peerConnection && this.state.connections[0].peerConnection.peerConnection && this.state.connections[0].peerConnection.peerConnection.iceConnectionState === "failed") {
                this.closeExpertWindow();
            }
        })
        // once the session has started call this for once
        // if(connections.length === 0){
        //     this.onStartStopOfSession();
        // }
        return connection;
    };
    
    // when new ice candidate is recieved from participant. Invoked for all
    onRecieveIceCandidate = (data) => {
        //TP-5733
        this.iceTimer = setTimeout(() => {
            try {
                const {connections, socketId} = this.state;
                if(data.recipientSocketId && socketId !== data.recipientSocketId){
                    return;
                }
                const connection = connections.find((connection) => connection.email === data.from);
                /* if (connection.peerConnection !== undefined) */
                    connection.peerConnection.newICECandidate(data)
            } catch (e) {
                console.error('Error adding received ice candidate', e, data);
            }
            clearTimeout(this.iceTimer);
        }, 500);
    }

    //TP-3551 -- Method to determine whether the toggle view is supposed to be 
    isExpertVideo = () => {
        //TP-3609
        const {enable_user_video} = this.props;
        if (enable_user_video === "my_camera_disabled") return false;
        let {participants} = this.state;
        const [participant] = participants.filter(p => p.isexpert === true && p.disableVideo === false);
        if (participant) return true;
        else return false;
    }

    //TP-3698
    isOtherTechVideo = () => {
        let {expertVideoArr, user} = this.state; //	TP-4379
        const [participant] = expertVideoArr.filter(p => p.isexpert === false && p.email !== user.email);
        if (participant) return true;
        else return false;
    }

    // Update the joinees count. Invoke for all
    updateJoinees = ({other_participants}) => {
        //console.log("update Joinees ----> ", other_participants);
        const names = other_participants.map(participant => {
            // MB2-636
            let name = "";
            if(participant.isexpert){
                name = '* ' + participant.first_name + ' ' + participant.last_name
            }else{
                name =  participant.first_name + ' ' + participant.last_name
            }
            const userId = participant.userId;
            let joineeObj = {
                userId,
                disableVideo: participant.disableVideo,
                disableAudio: participant.disableAudio,
                disableMike: participant.disableMike,
                disableRemoteAudio: participant.disableRemoteAudio, //T32-413
                grayedVideo: participant.grayedVideo, //TP-1790,
                isPaused: participant.isPaused, //TP-4538
                name,
                myPicture: participant.myPicture
            }

            return joineeObj;
        })
        this.props.setParticipant({participantList:other_participants})
        this.setState({
            participantNames: names
        })
    }

    // update each of the session Joinee elements in the array
    updateEachSessionJoinee = ({participant}) => {
        //console.log("update each joinee ---> ",participant);
        let name = "";
        if(participant.isexpert){
            name = '* ' + participant.first_name + ' ' + participant.last_name
        }else{
            name =  participant.first_name + ' ' + participant.last_name
        }
        const userId = participant.userId;
        const joineeObj = {
                userId,
                disableVideo: participant.disableVideo,
                disableAudio: participant.disableAudio,
                disableMike: participant.disableMike,
                disableRemoteAudio: participant.disableRemoteAudio, //T32-413
                grayedVideo: participant.grayedVideo, //TP-1790
                isPaused: participant.isPaused, //TP-4538
                name,
                myPicture: participant.myPicture
            }
        const key = this.state.participantNames.findIndex(e => e.userId === userId); //TP-5333
        let { participantNames } = this.state;
        participantNames[key] = joineeObj;
        
        // Set the new Joinees array
        this.setState({
            participantNames
        });
    }
    //TZ-487
    subscribeToMissedCallNotificationAlert = (data) => {
        console.log("Missed call alert received while in session...", data);
        if (data.callerName) {
            const message = "{name} is trying to call you."; /**TZ-523*/
            this.setState({ flashMsgText: message, flashValues: {name: data.callerName}, showFlashMessage: true, flashLeft: "", flashTop: "", flashTimer: true }); //TP-5681
        }
        if (sessionStorage.getItem("pageName") !== "UsageDetails") {  
            //TZ-539
            if (window.location.href.toLowerCase().includes('omafra')) { 
                sessionStorage.setItem("gotoMissed", "true"); 
            }           
        }
    }

    //TP-2131 -- Handling of the end-group-call socket listener
    subscribeToGlobalEndCall = ({ group_id }) => {
        printConsole(`end-group-call signal received with payload {group_id: ${group_id}}`);
        let { selectedGroupId } = this.props;
        if (group_id === parseInt(selectedGroupId)) {
            this.props.onSessionCmdChange('session_stopped');
            printConsole(" The user is being automatically made to end its session ");
        }
    }

    //group signal subscriber
    subscribeToGrpSignal = ({ type, messageID, eventType, data, my_camera_enabled, to, from, recipientSocketId }) => {
        if(recipientSocketId && (this.state.socketId !== recipientSocketId)){
            return;
        }
        //TP-5733
        if (type !== "USER_SPEAKER_ON") {
            printConsole("*user....************Receiving Signal on side **********");
            if (type === "SYNC_EXPERTS"/*  || type === "CURRENT_MAX_VIEW_USER" */) {
                printConsole({type, data, from, to})
            }/**TP-3733*/ else if (type === "EXPERT_VIDEO_FEED_ENABLED") {
                printConsole({type, data, my_camera_enabled, from, to});
            }else
            printConsole({ type, messageID, eventType, data, to, from, recipientSocketId });
        }
        const updatedChanges = {};
        const { expertShareScreenMaxDiv } = this.state; //TP-4683
        //printConsole(type,messageID, data)
        switch (type) {
            case 'MUTE_EXPERT_AUDIO': 
                this.muteAudioOfRemote({data, from});
                break;
            /**T32-413 */
            case 'MUTE_USER_AUDIO': 
                this.state.user.isexpert && this.processSignalMuteRemoteUserAudio({data, from});
                break;
            case 'MAX_VIEW': 
                !this.state.user.isexpert && this.processSignalMaxView({data, from})
                break;
            case "MAX_DIV_SHOW_BUTTONS":
                this.processSignalMaxDivShowButtons({data, from}); //TP-4772
                break;
            case "LOCAL_TORCH":
                this.state.user.isexpert && this.state.showMaxDivControls === true && /**TP-5851*/ this.state.showControlRequestDialog === false && this.processSignalLocalTorch({from, data});
                break;
            case "LOCAL_ZOOM_LEVEL":
                this.state.user.isexpert && this.state.showMaxDivControls === true && /**TP-5851*/ this.state.showControlRequestDialog === false && this.processSignalLocalZoomLevel({from, data});
                break;
            case 'IM':
                //TP-5560 -- Spawning a new thread for processing this signal
                if (typeof data === 'string')
                    this.timer = setTimeout(()=> {
                        this.processSignalIM({messageID, data, from});
                        clearTimeout(this.timer);
                    }, fileTransferTimeout);
                else
                    this.timer = setTimeout(()=> {
                        /* this.state.user.isexpert && */ this.processSignalUserInfo({data, from});//TP-4849
                        clearTimeout(this.timer);
                    }, fileTransferTimeout);                    
                break;
            case 'START_EXPERT_SS':
                //TP-4532
                if(this.props.isHeaderFileShare === true) {
                    this.props.closeFileViewer();
                }
            case 'STOP_EXPERT_SS':
                /**TP-4077 & TP-3551 & TP-2539 */
                if (!this.state.user.isexpert && (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled") || this.state.groupInfo.audio_mode === "tech2Tech")) /**TP-3119*/
                    this.processSignalSSPilot({from, type});
                else
                    this.processSignalSS({from, type}); //MB2-550
                break;
            case 'SHARE_ARTIFACTS':
            case 'REMOVE_ARTIFACTS':
                !this.state.user.isexpert && this.processSignalShareAssets({from, type}) // TP-825
                break;
            case "SYNC_EXPERTS":
                this.state.user.isexpert && this.processSignalSyncExperts({from, data});
                break;
            /* case "EXPERT_CHANGE_MAX_DIV":
                this.processSignalExpertChangeMaxDiv({data, from});
                break;*/ 
            case "CURRENT_PRIMARY_EXPERT":
                //this.setPrimaryExpert({data, from})
                !this.state.user.isexpert && this.setPrimaryExpertForTech({from}); // TP-359
                break;
            case "TURN_ON_MAX_VIEW_USER_VIDEO":
                this.processSignalTurnOnMaxViewUserVideo({data, from});
                break;
            case "LIVE_VIDEO_PAUSED":
                //TP-5629
                this.videoPausedTimer = setTimeout(()=> {
                    this.processSignalLiveVideoPaused({data, from}); //TP-4483
                    clearTimeout(this.videoPausedTimer);
                }, fileTransferTimeout);
                break;
            case "SWAP_CAMERA_RECEIVED":
                !this.state.user.isexpert && this.processSignalSwapCameraReceived({data, eventType});                
                break;
            case "SWAP_CAMERA":
                this.processSignalSwapCamera({data, eventType, from});
                break;
            case "CONFIRM_SWAP_CAMERA":
                this.processSignalConfirmSwapCamera({data, eventType, from});
                break;
            case "TURN_ON_USER_VIDEO":
                !this.state.user.isexpert && this.processSignalTurnOnUserVideo({data, to});
                this.state.user.isexpert && (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled")) && this.processSignalTurnOnUserVideo({data, to, from}); //TP-4019 & TP-3679 & TP-3551 & TP-3205 & TP-3119
                break;
            case "STOP_SESSION_USER":
                !this.state.user.isexpert && this.processSignalStopSessionUser({ to });        
                this.state.user.isexpert && (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled")) && this.processSignalStopSessionUser({ to }); /**TP-3858, TP-3551 & TP-3119*/
                break;
            case "REQUEST_MAXDIV_CONTROL":
                this.state.user.isexpert && this.processSignalRequestMaxDivControl({data, from});//TP-740
                break;
            case "YIELD_MAXDIV_CONTROL":
                this.state.user.isexpert && this.processSignalYieldMaxDivControl({data, from}); //TP-740
                break;            
            case "REMOTECLICK":
                !this.state.user.isexpert && this.processAutoPreviewMode({data, from}); //TP-4683
                break;            
            case "ANNOTATE":
                if (expertShareScreenMaxDiv || this.state.user.isexpert || this.props.bRemoteCapture) break; 
                !this.state.user.isexpert && this.processAutoPreviewMode({data, from}); //TP-4683
                break;
            /**TP-4683 TP-3114 */
            /* case "CURRENT_MAX_VIEW_USER":
                !this.state.user.isexpert && this.processSignalCurrentMaxViewUser({data, from}); //TP-3114
                break; */
            /**TP-3532 */
            case "EXPERT_VIDEO_FEED_ENABLED":
            //TP-3419
            if (this.props.enable_user_video !== "my_camera_disabled")
                this.processSignalExpertVideoFeedEnabled({data, my_camera_enabled, from});//TP-3733
            else if (this.props.enable_user_video === "my_camera_disabled" && this.state.user.isexpert)
                this.processSignalDisabledExpertVideoFeed({data, from});
                break;
            case "USER_SPEAKER_ON":
                this.audioTimer = setTimeout(()=> {
                    this.state.user.isexpert && this.processSignalUserSpeakerOn({data, from});
                    clearTimeout(this.audioTimer)
                }, 15);
                break;
            default:
                break;
        }
        this.setState((prev) => ({...prev,...updatedChanges}));
    }
    
    /* subscribeToEndCall = ({groupId, willBeReconnecting}) => {
        if (willBeReconnecting === true) 
            this.props.triggerReconnecting(true);
        else
            this.userLeft({groupId, willBeReconnecting});
    } */
    
    sendMaxViewSignal = ({flag}) => {
        const {maxDivParticipant} = this.state
        const toObject = maxDivParticipant.email;
        maxDivParticipant && !maxDivParticipant.isexpert && this.sessionSignal.sendSignalShowLocalRC(flag, toObject);
    }

    startMultiExpertWaitTimer = () => {
        this.setState({
            multiExpertTimer : 30
        },
        () => {
            this.multiExpertTimer = setInterval(this.decrementMultiExpertCounter.bind(this), 1000)
        });
    }

    decrementMultiExpertCounter = () => {        
        const experts = this.state.participants.filter(p => p.isexpert);
        if (this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === this.state.user.email){
            this.setState((prevState) =>({
                multiExpertTimer: prevState.multiExpertTimer - 1
            }),() => {
                if(this.state.multiExpertTimer === 0){
                    // If within 30 seconds after the transfer of Max-view controls,
                    // the "primary_sync" signal doesn't come to the
                    // current Primary Expert then make that expert as Primary expert again
                    this.makeMeExpert({experts});
                    this.setState({ changeHandIcon: true}, () => {
                        this.setState({ changeHandIcon: false })
                    })
                }
            });
        } else if (this.state.currentPrimaryExpert.email !== this.state.user.email) {
            this.stopMultiExpertWaitTimer();
        }
    }

    stopMultiExpertWaitTimer = () => {
        this.setState({
            multiExpertTimer: 0
        },() => {
            clearInterval(this.multiExpertTimer);
        });
    }

    processSignalSyncExperts = ({from, data}) => {
        switch (data.reason) {
            case "request_expert_sync":
                this.triggerExpertQueryMaxDiv({from});// TP-1934
                break;
            case "show_action":
                this.triggerExpertViewControlsSync({from, data})
                break;
            case "update_action":
                this.triggerExpertGridViewSync({from, data})
                break;
            case "primary_sync":
                this.setPrimaryExpert({data: data.expertId});
                break;
            case "registered_sync":
                this.setRegisteredExpert({from, data});
                break;
            case "recording_sync":
                this.setRecordingStatus({from, data});
                break;
            // TP-2349 -- guest user list syncing signal
            case "guest_user_sync":
                this.setGuestUserList({ guestUserList: data.guestUserList });
                break;
            //TP-2474 -- handling of UI when Erase Annotate is clicked
            case "canvas_erased":
                this.triggerCanvasErase({from});//TP-2474
                break;
            default:                
        }
    }

    // handler for SYNC_EXPERTS reason="primary_sync" signal
    setPrimaryExpert = ({data}) => {
        const [primaryExpert] = this.state.participants.filter(e => e.userId === data);
        if(primaryExpert){
            this.setState({ currentPrimaryExpert: primaryExpert, showMaxDivControls: false, disableGridView: true,
                showHeaderActionButtons: false
            }, () => {
                //If joining the session as a Passive expert
                // And the missed call alert timer is on for user
                // then reset the timer
                if (this.missedCallTimer) {
                    clearTimeout(this.missedCallTimer);
                }
            });
            this.props.setPrimaryExpert({primaryExpert});// set primary expert in webrtc session    
            this.props.setHasMaxDivControls({flag: false});// set max div control in webrtc session            
        }
    }

    // call users while in session 
    callAndInviteUser = (flag, key) => {
        printConsole(flag);
        try {
            if (this.state.showMaxDivControls && flag === false) {
                key = parseInt(key); // TP-2168 
                printConsole(`element index ${key}`);
                let {onlineList} = this.state;                
                const [to] = onlineList.filter((e, i) => key === i);
                printConsole(to);
                to.isBusy = !flag;
                onlineList[key] = to;
                this.setState({onlineList, selectedUser: to}, () => {
                    this.timer = setTimeout(() => this.cancelCall({ mode: 'callUser', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), key }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                    this.sessionSignal.sendConnectTheCall({data:{ recipientEmail: to.email, callerName: this.props.user.firstname + this.props.user.lastname, isCancelled: false, group_id: this.props.selectedGroupId }});                    
                })
            }
        } catch (exception) {
            printConsole("exception in callAndInviteUser Method ");
            printConsole(exception);
        }
    }

    // cancel call to users online
    cancelCall = ({ mode = 'view', msg = '', key = null }) => {
        printConsole("Call timed out");
        const {first_name, last_name} = this.props.user;
        const {selectedGroupId} = this.props;
        let {onlineList} = this.state;
        if (key!== null) {
            printConsole(onlineList[key]);
            let onlineEmails = [];
            if (onlineList[key])
                onlineEmails.push(onlineList[key].email); 
            this.authService.socket.emit('send-missed-call-notification', { 
                emails: onlineEmails
            });
        }        
        onlineList.forEach((o, k) => {            
            if (o.isBusy === true) {
                o.isBusy = false;
                let to = o;
                this.sessionSignal.sendConnectTheCall({
                    data:{ 
                        recipientEmail: to.email, 
                        isCancelled: true, 
                        callerName: first_name + last_name, 
                        group_id: selectedGroupId
                    }
                });                
            }
        })
        this.setState({
            onlineList
        })
    }

    //TP-3573
    toggleMySpeaker = () => {
        this.setState({ triggerMySpeaker: true }, () => {
            this.muteHeaderSpeakerOfAll(this.state.mutedAll);//TP-3774
            this.setState({ triggerMySpeaker: false })
        })
    }

    toggleMyMike = () => {
        this.setState({ triggerMyMike: true }, () => {
            this.muteHeaderMikeForAll(this.state.headerMuteMike);//TP-3774
            this.setState({ triggerMyMike: false });
        })
    }

    // turn on/off the remote user's audio
    toggleUserSpeaker = (key, flag=null) => {
        printConsole(flag);
        try {
            //if (this.state.showMaxDivControls) {   
                key = parseInt(key); // TP-2168            
                printConsole(`element userId ${key}`);
                let participants = [...this.state.participants];
                //TP-3573
                if (this.props.user.id === key){
                    this.toggleMySpeaker();
                    return;
                }                
                const [to] = this.state.participants.filter(e => e.userId === key);
                const index = this.state.participants.findIndex(e => e.userId === key);
                printConsole(to);
                let isParticipantChanged = false;
                if (flag === null){
                   isParticipantChanged = true;// to change the Side Bar button state
                   flag = to.disableAudio; 
                } 
                // Making the audio track for that participant enabled/disabled
                to.disableAudio = !flag;
                //set the changed session Joinee in the array
                this.updateEachSessionJoinee({participant: to});

                participants[index] = to;

                //TP-3611 & TP-3573
                let updatedParticipants = [...this.state.updatedParticipants];
                if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                    //this.getUpdatedParticipantswithPreview();
                    const ii = this.state.updatedParticipants.findIndex(e => e.userId === key);
                    updatedParticipants[ii].disableAudio = !flag;
                }
                this.setState({participants, updatedParticipants}, () => {                    
                    this.props.setParticipant({participantList: participants});
                    //console.log("Changed participant list ---->", this.state.participants);
                    if (this.state.maxView && to.stream_id === this.state.maxDivParticipant.stream_id) {
                        printConsole(`Maxview user's Speaker was turned ${flag}`);
                        this.setState({ maxDivSpeakerMuted: !flag }, () => {
                            this.muteSpeakerOfOne({participant: to, flag})
                        });                            
                    } else {
                        printConsole(`User's Speaker was turned ${flag}`);
                        this.muteSpeakerOfOne({participant: to, flag});
                    }
                    isParticipantChanged = false;
                    this.setState({ isParticipantChanged });
                })
                
            //}
            
        } catch (error) {
            printConsole(error);
        }
    }

    //TP-2861
    resetUserZoom = (key, value) => {
        try {
            if (this.state.showMaxDivControls) {   
                printConsole(`element userId ${key}`);
                let participants = [...this.state.participants];
                let {maxDivParticipant} = this.state;
                const experts = this.state.participants.filter(p => p.isexpert)                
                const [to] = this.state.participants.filter(e => e.userId === key);
                const index = this.state.participants.findIndex(e => e.userId === key);
                printConsole(to);
                
                // Resetting the zoom level for that participant to the sent value
                to.zoomLevel = parseInt(value);

                participants[index] = to;
                //TP-3611 & TP-3573
                let updatedParticipants = [...this.state.updatedParticipants];
                if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                    const ii = this.state.updatedParticipants.findIndex(e => e.userId === key);
                    updatedParticipants[ii].zoomLevel = parseInt(value);
                }
                
                //resetting the max-view user's zoom level as well
                if(this.state.maxView && to.stream_id === maxDivParticipant.stream_id) {
                    //console.log(maxDivParticipant.zoomLevel);
                    this.setState({resetMaxUserZoom: true}, () => {
                        this.setState({ resetMaxUserZoom: false });//TP-2935
                    })
                }

                // Send the signal to other experts to keep the zoomLevel flag of the 
                // other experts in sync with the Primary expert
                if (experts.length > 0) {
                    experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: true,
                            email: to.email,                                    
                            torch_button_active: to.torchActive,
                            zoom_level: to.zoomLevel,
                            hand_icon_enabled: true,
                            reason: "update_action"
                        }, e.email);
                    });
                }

                
                this.setState({participants, updatedParticipants}, () => {
                    this.props.setParticipant({participantList: participants});// to set the changes in the participant list
                    //to Send the torch on/off signal to the Remote user whose torch button has been clicked
                    to.email && this.sessionSignal.sendSignalRemoteZoom(to.zoomLevel, to.email);
                });
            }

        } catch (error) {
            printConsole(error);
        }
    }

    // rotate the zoom_level of the remote user video
    rotateUserZoom = (key) => {
        try {
            if (this.state.showMaxDivControls) {   
                printConsole(`element userId ${key}`);
                let participants = [...this.state.participants];
                let {maxDivParticipant} = this.state;
                const experts = this.state.participants.filter(p => p.isexpert)                
                const [to] = this.state.participants.filter(e => e.userId === key);
                const index = this.state.participants.findIndex(e => e.userId === key);
                printConsole(to);
                
                // Rotating the zoom level for that participant to a higher/default value
                to.zoomLevel = (to.zoomLevel > 0 && to.zoomLevel < 5) ? to.zoomLevel + 1 : 1;
                
                // TP-2933 --resetting the max-view user's zoom level as well
                if(this.state.maxView && to.stream_id === maxDivParticipant.stream_id) {
                    //console.log(maxDivParticipant.zoomLevel);
                    this.setState({maxDivParticipant: to}, () => {
                        this.setZoomClass();
                        this.setState({resetMaxUserZoom: true}, () => {
                            this.setState({ resetMaxUserZoom: false });
                        })
                    })
                }
                participants[index] = to;

                //TP-3611 & TP-3573
                let updatedParticipants = [...this.state.updatedParticipants];
                if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                    const ii = this.state.updatedParticipants.findIndex(e => e.userId === key);
                    updatedParticipants[ii].zoomLevel = to.zoomLevel;
                }

                // Send the signal to other experts to keep the zoomLevel flag of the 
                // other experts in sync with the Primary expert
                if (experts.length > 0) {
                    experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: true,
                            email: to.email,                                    
                            torch_button_active: to.torchActive,
                            zoom_level: to.zoomLevel,
                            hand_icon_enabled: true,
                            reason: "update_action"
                        }, e.email);
                    });
                }

                
                this.setState({participants, updatedParticipants}, () => {
                    this.props.setParticipant({participantList: participants});// to set the changes in the participant list
                    //to Send the torch on/off signal to the Remote user whose torch button has been clicked
                    to.email && this.sessionSignal.sendSignalRemoteZoom(to.zoomLevel, to.email);
                });
            }

        } catch (error) {
            printConsole(error);
        }
    }

    // turn on/off the remote user's Torch 
    toggleUserTorch = (key) => {
        try {
            if (this.state.showMaxDivControls) {   
                printConsole(`element userId ${key}`);
                let participants = [...this.state.participants];
                let {maxDivParticipant} = this.state;
                const experts = this.state.participants.filter(p => p.isexpert)                
                const [to] = this.state.participants.filter(e => e.userId === key);
                const index = this.state.participants.findIndex(e => e.userId === key);
                printConsole(to);
                let flag = to.torchActive; 

                // Making the torch flag for that participant enabled/disabled
                to.torchActive = !flag;

                participants[index] = to;

                //TP-3611 & TP-3573
                let updatedParticipants = [...this.state.updatedParticipants];
                if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                    //this.getUpdatedParticipantswithPreview();
                    const ii = this.state.updatedParticipants.findIndex(e => e.userId === key);
                    updatedParticipants[ii].torchActive = !flag;
                }

                //TP-2861 -- resetting the max-view user's zoom level as well
                if(this.state.maxView && to.stream_id === maxDivParticipant.stream_id) {
                    //console.log(maxDivParticipant.torchActive);
                    this.setState({resetMaxUserTorch: true}, () => {
                        this.setState({ resetMaxUserTorch: false }); //TP-2935
                    })                    
                }

                // Send the signal to other experts to keep the torchActive flag of the 
                // other experts in sync with the Primary expert
                if (experts.length > 0) {
                    experts.forEach((e) => {
                        this.sessionSignal.sendSignalSyncExperts({
                            show_grid: true,
                            email: to.email,                                    
                            torch_button_active: !flag,
                            zoom_level: to.zoomLevel,
                            hand_icon_enabled: true,
                            reason: "update_action"
                        }, e.email);
                    });
                }

                
                this.setState({participants, updatedParticipants}, () => {
                    this.props.setParticipant({participantList: participants});// to set the changes in the participant list
                    //to Send the torch on/off signal to the Remote user whose torch button has been clicked
                    to.email && this.sessionSignal.sendSignalRemoteTorch(!flag,to.email);
                });
            }            
        } catch (error) {
            printConsole(error);
        }
    }

    // turn on/off the remote user's audio
    toggleUserMike = (key, flag=null) => {
        printConsole(flag);
        try {
            //if (this.state.showMaxDivControls) {  
                key = parseInt(key); // TP-2168           
                printConsole(`element userId ${key}`);
                let participants = [...this.state.participants];
                //	TP-3573
                if (this.props.user.id === key){
                    this.toggleMyMike();
                    return;
                }
                //TP-1918 -- To get the previous unMuted Expert Mike count
                let isExpertAudioUnMutedCounterPrev = 0;
                participants.forEach(p => {
                    if (!p.disableMike) isExpertAudioUnMutedCounterPrev += 1;
                })                
                const [to] = this.state.participants.filter(e => e.userId === key);
                const index = this.state.participants.findIndex(e => e.userId === key);
                printConsole(to);
                let isParticipantChanged = false;
                if (flag === null){
                   isParticipantChanged = true;// to change the Side Bar button state
                   flag = to.disableMike; 
                } 
                // Making the audio track for that participant enabled/disabled
                to.disableMike = !flag;
                
                //set the changed session Joinee in the array
                this.updateEachSessionJoinee({participant: to});
                participants[index] = to;
                // TP-1918 -- To get the latest unMuted Expert Mike count
                let isExpertAudioUnMutedCounterLatest = 0;
                participants.forEach(p => {
                    if (!p.disableMike) isExpertAudioUnMutedCounterLatest += 1;
                })
                let recordingFlag = false;
                printConsole(`Prev unmuted Mike counter: ${isExpertAudioUnMutedCounterPrev} current unMuted mike counter: ${isExpertAudioUnMutedCounterLatest}`);
                if (this.state.startRecording === true && isExpertAudioUnMutedCounterPrev !== isExpertAudioUnMutedCounterLatest && ((isExpertAudioUnMutedCounterPrev === 0 && isExpertAudioUnMutedCounterLatest > 0) || (isExpertAudioUnMutedCounterPrev > 0 && isExpertAudioUnMutedCounterLatest === 0))) {
                    printConsole("The Expert's individual Mikes count changed while recording is ongoing")
                    recordingFlag = true;
                }

                //TP-3611 & TP-3573
                let updatedParticipants = [...this.state.updatedParticipants];
                if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                    //this.getUpdatedParticipantswithPreview();
                    const ii = this.state.updatedParticipants.findIndex(e => e.userId === key);
                    updatedParticipants[ii].disableMike = !flag;
                }
                
                this.setState({participants, isParticipantChanged, updatedParticipants}, () => {
                    this.props.setParticipant({participantList: participants});
                    // TP-1918
                    if (recordingFlag === true) {
                        printConsole("Stop and re-start a new recording due to Mute/un-Mute Slider/Grid View Mic icon");
                        this.stopAndContinueRecording(); //TP-1881 stop the old recording and start a new recording when Side Bar Mute Mike is clicked
                    }
                    //console.log("Changed participant list ---->", this.state.participants);
                    if (this.state.maxView && to.stream_id === this.state.maxDivParticipant.stream_id) {
                        printConsole(`Maxview user's Mike was turned ${flag}`);
                        this.setState({ maxDivMikeMuted: !flag }, () => {
                            this.sendMuteMikeSignal({participant: to, flag: flag})// mute/unmute the mike for the participant
                        });                            
                    } else {
                        printConsole(`User's Mike was turned ${flag}`);
                        this.sendMuteMikeSignal({participant: to, flag: flag})// mute/unmute the mike for the participant
                    }
                    isParticipantChanged = false;
                    this.setState({ isParticipantChanged });
                })
                
            //}
            
        } catch (error) {
            printConsole(error);
        }
    }
    //TP-3573
    toggleMyVideo = () => {
        let {participants, localStream} = this.state;
        const {user} = this.props; let flag = true;
        let updatedParticipants = this.state.updatedParticipants;
        let changedParticipant = null; //TP-3677
        updatedParticipants && updatedParticipants.forEach(u => {
            if (u.email === user.email){
                u.disableVideo = (this.checkIfExpertCameraEnabled() === true) ? !u.disableVideo : false;
                flag = u.disableVideo;
                this.props.setMyVideoState(!flag);//TP-3737
                u.streams && u.streams.forEach(stream=> {
                    stream.getVideoTracks()[0].enabled = !u.disableVideo;
                })
                localStream.getVideoTracks().forEach(track => track.enabled === !u.disableVideo) //TP-3611
                changedParticipant = u; //TP-3677
            }
        })
        this.setState({ updatedParticipants, localStream, changedParticipant /**TP-3677*/ }, () => {
            this.setState({ isParticipantChanged: true }, () => {
                this.setState({ isParticipantChanged: false });
            });
          
            /**TP-3611 */
            this.setState({ triggerGridView: true }, () => {
                this.setState({ triggerGridView: false }, () => {
                    this.setState({changedParticipant: null})
                });
            })
        });
        participants && participants.forEach(p => {
            //TP-3532 -- New Socket signal to let the other Remote users to know this expert user's Video feed is turned on or not
            this.sessionSignal.sendSignalExpertVideoFeedEnabled(!flag, this.checkIfExpertCameraEnabled(), p.email);//TP-3683
        })
    }
    
    // turn on/off the user's remote video
    toggleUserVideo = (key, flag=null) => {
        printConsole(flag);
        try {
            if (this.state.showMaxDivControls) {  
                key = parseInt(key); // TP-2168            
                printConsole(`element userId ${key}`);
                let participants = [...this.state.participants];
                //TP-3573
                if (this.props.user.id === key){
                    this.toggleMyVideo();
                    return;
                }
                const {enable_user_video} = this.props;//TP-3737
                const experts = this.state.participants.filter(p => p.isexpert)
                const [to] = this.state.participants.filter(e => e.userId === key);
                const index = this.state.participants.findIndex(e => e.userId === key);
                printConsole(to);
                const {session_type, audio_mode} = this.props;
                let isParticipantChanged = false;
                //if (flag === null){
                   isParticipantChanged = true;// to change the Side Bar button state
                   flag = to.disableVideo; 
                //}
                // Making the video track for that participant enabled/disabled
                to.disableVideo = !flag;
                //TP-3736
                to.streams && to.streams.forEach(stream => {
                    if (stream.getVideoTracks().length > 0)
                        stream.getVideoTracks()[0].enabled = flag;
                })

                //set the changed session Joinee in the array
                this.updateEachSessionJoinee({participant: to});                
                participants[index] = to;

                //TP-3611 & TP-3573
                let changedParticipant = null; //TP-3677
                let updatedParticipants = [...this.state.updatedParticipants];
                if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                    const ii = this.state.updatedParticipants.findIndex(e => e.userId === key);
                    updatedParticipants[ii].disableVideo = !flag;
                    /**TP-3736*/
                    updatedParticipants[ii].streams && updatedParticipants[ii].streams.forEach(stream => {
                        if (stream.getVideoTracks().length > 0)
                            stream.getVideoTracks()[0].enabled = flag;
                    })
                    changedParticipant = updatedParticipants[ii];//TP-3677
                } else if (this.props.session_type === "RM" && enable_user_video !== "my_camera_disabled") {
                    changedParticipant = to; //	TP-3737
                }

                printConsole(`Turn on/off video signal being sent to user ----> ${to.email}`);
                this.sessionSignal.sendSignalTurnOnUserVideo(flag, to.email);

                let payload = [];
                participants.forEach(p => {
                    if (p!== undefined) {
                        let obj = {}
                        //TP-3551 -- if (p.isexpert === false) {
                            obj = {
                                flag: p.disableVideo, 
                                userId: p.userId,
                                email: p.email
                            }
                        /*} TP-3551 else {
                            obj = {
                                flag: (session_type === "CM" || (session_type === "RM" && this.checkIfExpertCameraEnabled() === true)) ? p.disableVideo : true, /**TP-3205 & TP-3119 
                                userId: p.userId,
                                email: p.email
                            }
                        } */
                        payload.push(obj);
                    }
                })

                // Send the signal to other experts to keep the disableVideo flag of the 
                // participants in sync with the Primary expert
                //TP-3119
                /*if (experts.length > 0 && session_type === "RM" && this.checkIfExpertCameraEnabled() === false) {
                    experts.forEach((p) => {
                        this.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, p.email);
                    });
                } else if (participants.length > 0 && (session_type === "CM" || (session_type === "RM" && this.checkIfExpertCameraEnabled() === true) || audio_mode === "tech2Tech")) {*/
                    //TP-3551, TP-3119 & TP-3205 -- Send the Video disabled/enabled flag values for current user to all users except that user
                    participants.forEach(p => {
                        if (p.email !== to.email)
                            this.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, p.email);
                    })
                //}

                let tmp = participants.filter(p => p !== undefined);
                participants = tmp;

                this.setState({participants, isParticipantChanged, updatedParticipants, changedParticipant /**TP-3677*/}, () => {
                    this.props.setParticipant({participantList: participants});
                    this.setState({triggerGridView: true}, () => {
                        this.setState({triggerGridView: false})
                    })
                    const list = this.state.participants.filter(p => p.disableVideo === false);
                    let length = 0;
                    //console.log("list ---------------------------", list)
                    if (list !== undefined) {
                        printConsole(`Length of Users with Video on -- ${list.length}`)
                        length = list.length;
                    }
                    this.setState({ userVideoCount: length }, () => {
                        this.setState({changedParticipant: null}); //TP-3677
                        /* if (length > 1) this.setState({ disableGridView : false })
                        else if (length <= 1) this.setState({ disableGridView: true }); */
                        //console.log("Changed participant list ---->", this.state.participants);
                        //TP-5995
                        if (this.state.maxView && to.stream_id === this.state.maxDivParticipant.stream_id && this.state.technicianCount === 1 && !to.isexpert) {
                            printConsole(`Only one video user's video was turned ${flag}`);
                            const newMaxView = participants.find((p) => p.stream_id === this.state.maxDivParticipant.stream_id );
                            this.setState({ maxDivParticipant: newMaxView, showHideDivIconAudioOnly: !flag/* , hideExpertSSAll: !flag */ }); //TP-4436
                            
                        } else if (this.state.maxView && to.stream_id === this.state.maxDivParticipant.stream_id && (this.state.technicianCount > 1 || (to.isexpert && this.state.technicianCount > 0)) /**TP-5995*/) {
                            const newParticipant = participants.find((p) => 
                                (p.isexpert === false && p.stream_id !== to.stream_id && p.disableVideo === false)
                            )
                            if (newParticipant) {
                                printConsole(`Showing the new Maxview Expert ----------->`);
                                printConsole(newParticipant);
                                this.sendMaxViewSignal({flag: false});// disable max view for outgoing
                                this.onClickMaximise({participant: newParticipant, isSame: false})
                                if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false/* , hideExpertSSAll: false */ }) //TP-4436
                            } else {
                                printConsole(`No video user's video was turned ${flag}`);
                                const newMaxView = participants.find((p) => p.stream_id === this.state.maxDivParticipant.stream_id );
                                this.setState({ maxDivParticipant: newMaxView, showHideDivIconAudioOnly: !flag/* , hideExpertSSAll: !flag */ }); //TP-4436
                                this.sendMaxViewSignal({flag});// enable/disable max view for incoming (depending on value of flag)
                            }
                        } else if (this.state.maxView && to.stream_id !== this.state.maxDivParticipant.stream_id) {
                            printConsole(`Behind user's video was turned ${flag}`);
                            printConsole(this.state.userVideoCount);
                            if (flag === true && this.state.userVideoCount === 1) {
                                printConsole(`Find new technician with enabled video and place it on Max-view`);
                                const newParticipant = participants.find((p) => p.isexpert === false && p.disableVideo === false ) // Find the first user whose Video is turned on
                                if (newParticipant) {
                                    printConsole(`Showing the new Maxview Expert ----------->`);
                                    printConsole(newParticipant);
                                    this.sendMaxViewSignal({flag: false});// disable max view for outgoing
                                    this.onClickMaximise({participant: newParticipant, isSame: false})
                                    if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false/* , hideExpertSSAll: false */ }) //TP-4436
                                } else 
                                this.onClickMaximise({participant: this.state.maxDivParticipant, isSame: false});
                            } else if (flag === false && this.state.userVideoCount === 0) {
                                printConsole(`Place the user whose video is getting turned on now on Max-view`);
                                const newParticipant = participants.find((p) => p.userId === key) // Find the user with the same userId thats shared 
                                if (newParticipant) {
                                    printConsole(`Showing the new Maxview user ----------->`);
                                    printConsole(newParticipant);
                                    this.sendMaxViewSignal({flag: false});// disable max view for outgoing
                                    this.onClickMaximise({participant: newParticipant, isSame: false})
                                    if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false/* , hideExpertSSAll: false */ }) //TP-4436
                                }
                            }                            
                            else {
                                if (flag === false) {
                                    printConsole(`check if the last Max-view user still has Video turned on or not`)
                                    //if (this.state.userVideoCount === 1) this.hideGridViewIcon(false);
                                    const maxParticipant = participants.find(p => 
                                        (p.stream_id === this.state.maxDivParticipant.stream_id && p.isexpert === false && p.disableVideo === false)
                                    )
                                    if (maxParticipant) {
                                        printConsole(`Showing the old Maxview user ----------->`);
                                        printConsole(maxParticipant);
                                        //TP-5980this.sendMaxViewSignal({flag: false});// disable max view for outgoing
                                        this.onClickMaximise({participant: maxParticipant, isSame: true}) //TP-5452
                                        if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false/* , hideExpertSSAll: false */ }) //TP-4436
                                        //if (this.state.userVideoCount >= 1) this.hideGridViewIcon(false);
                                        
                                    } else {
                                        printConsole(`No the old Max-view user has video turned off ----------->`);                                        
                                        const newParticipant = participants.find(p => 
                                            (p.stream_id !== this.state.maxDivParticipant.stream_id && p.isexpert === false && p.disableVideo === false)
                                        )
                                        if (newParticipant) {
                                            printConsole(`Showing the new Maxview user ----------->`);
                                            printConsole(newParticipant);
                                            this.sendMaxViewSignal({flag: false});// disable max view for outgoing
                                            this.onClickMaximise({participant: newParticipant, isSame: false})
                                            if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false/* , hideExpertSSAll: false */ }) //TP-4436
                                        }
                                    }
                                } else {
                                    //TP-4808 -- Handled showing the same user as current Max-view user again on the Expert's Max-view
                                    const [maxParticipant] = this.state.participants.filter(p => p.email === this.state.maxDivParticipant.email);
                                    printConsole("Max-view Participant ----> ");
                                    printConsole(maxParticipant)
                                    if (maxParticipant)
                                        this.onClickMaximise({participant: maxParticipant, isSame: true}); //TP-5452
                                    else    
                                        this.onClickMaximise({participant: this.state.maxDivParticipant, isSame: true}); //TP-5452
                                }
                            }
                        } else if (!this.state.maxView && (this.props.session_type === "RM" && this.props.enable_user_video === "my_camera_disabled" /**TP-3688*/)) { /**TP-3419 */
                            const newParticipants = participants.filter((p) => 
                                (/* p.isexpert === false && */ p.stream_id !== to.stream_id && p.disableVideo === false)
                            )
                            printConsole(newParticipants);
                            if (newParticipants.length > 1) {
                                this.onClickMultiGridView();
                            } else {
                                printConsole(`Showing the new Maxview Expert ----------->`);
                                printConsole(newParticipants[0]);
                                this.sendMaxViewSignal({flag: false});// disable max view for outgoing
                                this.onClickMaximise({participant: newParticipants[0], isSame: false})
                                //if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false })
                            }
                            //Handling of the re-drawing of the Passive expert screens, when Video is turned back on
                            //Sending the SYNC_EXPERTS reason="show_action" to all the passive experts in that session
                            if (experts.length > 0) {
                                experts && experts.forEach((e) => {
                                    this.sessionSignal.sendSignalSyncExperts({
                                        show_grid: true,
                                        hand_icon_enabled: true,
                                        header_fileShare_active: (this.props.isHeaderFileShare && !this.props.isOneToOneFileShare) ? true : false, //TP-2378
                                        reason: "show_action"
                                    }, e.email);                            
                                });
                            }
                        } else if (this.state.maxView && to.stream_id === this.state.maxDivParticipant.stream_id && this.state.technicianCount === 0 && (this.props.session_type === "RM" && this.props.enable_user_video !== "my_camera_disabled")) {
                            //TP-5551
                            printConsole(`Only experts are in session and Max-view user's video was turned ${flag}`);
                            const newMaxView = participants.find((p) => p.stream_id === this.state.maxDivParticipant.stream_id );
                            this.onClickMaximise({participant: newMaxView, isSame: false});
                        }
                        isParticipantChanged = false;
                        this.setState({ isParticipantChanged });
                    });
                })
                
            }
            
        } catch (error) {
            printConsole(error);
        }
    }

    // mute own mike for all. can be invoked by all
    muteHeaderMikeForAll = (flag) => {
        try{
            const { user } = this.props;
            if(this.props.user.isexpert){
                printConsole(flag);
                let {participants, participantNames, updatedParticipants} = this.state;
                participants && participants.forEach(p => p.disableMike = !flag);
                //TP-3611 & TP-3573
                if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true){
                    updatedParticipants && updatedParticipants.forEach(p => p.disableMike = !flag);
                }
                participantNames && participantNames.forEach(pa => pa.disableMike = !flag);
                this.setState({headerMuteMike: !flag, participants, updatedParticipants /**TP-3611 */, participantNames, maxDivMikeMuted: !flag},() => {
                    //TP-5681
                    if (this.state.headerMuteMike === false && this.state.showIsMutedbutSpeaking === true) 
                        this.setState({ showIsMutedbutSpeaking: false }, () => {
                            this.setState({ showFlashMessage: false });
                        });
                    this.sessionSignal.sendSignalMuteExpertMike(flag,''); //type:"MUTE_EXPERT_AUDIO"                    
                    this.updateJoinees({other_participants: participants});//TP-3774
                });
                if (this.state.startRecording === true) {
                    this.stopAndContinueRecording(); //TP-1881 stop the old recording and start a new recording when Side Bar Mute Mike is clicked
                }
            }else{
                const {participants, connections, groupInfo} = this.state;
                //TP-3113
                let usersToSend = [];
                if (groupInfo.audio_mode === "tech2Tech") {
                    usersToSend = participants;
                } else {
                    usersToSend = participants.filter(p => p.isexpert === true);
                }
                connections && connections.forEach((connection) => {
                    connection.peerConnection.muteMike({flag})
                })
                this.setState({headerMuteMike: !flag});
                //T32-413 - Emitting the MUTE_USER_AUDIO Signal to all experts in that session
                usersToSend && usersToSend.length > 0 && usersToSend.forEach(ex => this.sendTechnicianMuteAllCmd({participant: ex, flag: !flag}))
            }
        }catch(error){
            printConsole(error)
        }
    }

    // send the signal for muting the mike
    sendMuteMikeSignal = ({participant, flag}) => {
        const toObject = participant.email;
        this.sessionSignal.sendSignalMuteExpertMike(flag,toObject);//type:"MUTE_EXPERT_AUDIO"
    }

    // T32-413 To be called from the Side bar "Mute Mike" of Remote Users
    sendTechnicianMuteAllCmd = ({participant, flag}) => {
        try {
            const toObject = participant.email;
            this.sessionSignal.sendSignalMuteUserAudio(flag, toObject);
            printConsole(`User mute command sent... ${flag} to ${toObject}`);
        }
        catch (exception) {
            printConsole('exception occured in technicianMuteAll');
            printConsole(exception);
        }
    }

    // mute own video for all. can be invoked by all
    muteHeaderVideoForAll = (flag) => {
        const {localStream, connections} = this.state;
        connections && connections.forEach((connection) => {
            connection.peerConnection.muteVideo({localStream ,flag})
        })
    }

    // mute/unmute all incoming audio for all. can be invoked by all
    muteHeaderSpeakerOfAll = (flag) => {
        printConsole("User Header Mute Speaker button clicked");
        printConsole(flag);
        const {participants, participantNames, updatedParticipants} = this.state
        participants && participants.length > 0 && participants.forEach((participant) => {
            participant.disableAudio = !flag;            
            participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
                stream.getAudioTracks().forEach(track => track.enabled = flag);
            })
        })
        participantNames && participantNames.forEach(pa => pa.disableAudio = !flag);
        //TP-5880
        if(this.state.maxView && this.state.maxView === true && this.state.maxDivParticipant && this.state.maxDivParticipant !== ''){
            const participant = this.state.maxDivParticipant;
            participant.disableAudio = !flag;            
            participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
                stream.getAudioTracks().forEach(track => track.enabled = flag);
            })
            this.setState({ maxDivParticipant: participant });
        }
        //TP-3611 & TP-3573
        if (this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
            updatedParticipants && updatedParticipants.length > 0 && updatedParticipants.forEach((participant) => {
                participant.disableAudio = !flag;  
                if (participant.email !== this.state.user.email) {
                    //TP-5344
                    participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
                        stream.getAudioTracks().forEach(track => track.enabled = flag);
                    })
                }           
            })
        }
        const isParticipantChanged = true;// to change the Side Bar button state
        this.setState({mutedAll: !flag, maxDivSpeakerMuted: !flag, participantNames, participants, updatedParticipants /**TP-3611*/, isParticipantChanged}, () => {
            //console.log("After the Spekaer button clicked the participants list??", this.state.participants);
            this.setState({isParticipantChanged: false});
            
        })
    }

    // mute Speaker for one
    muteSpeakerOfOne = ({participant, flag}) => {
        printConsole(`enable speaker audio --- ${flag}`)
        participant && participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
            stream.getAudioTracks().forEach(track => track.enabled = flag);
        })
    }

    // mute mike for one
    // Expert to expert Mute Mike signal implementation
    muteAudioOfRemote = ({data, from}) => {
        printConsole(`turn the expert's audio ${data} `)
        const {user} = this.state;
        let participants = [...this.state.participants];
        let updatedParticipants = [...this.state.updatedParticipants];//TP-3774
        let participant = this.state.participants.find((p) => p.email === from.email)
        const index = this.state.participants.findIndex(e => e.email === from.email);
        if(!user.isexpert) this.muteSpeakerOfOne({participant, flag: data});
        else if (!(this.state.mutedAll === true && data === true) && /**TP-3893*/ participant && !(participant.disableAudio === true && data === true)) 
            this.muteSpeakerOfOne({participant, flag: data});
        let isParticipantChanged = true;

        //TP-3893 & T32-463 -- Making the Speaker button for that expert participant not-clickable/clickable again
        if (participant)
            participant.disableRemoteAudio = !data;
        //TP-3774 -- set the Incoming User's Remote Audio state when in RM with Video enabled/enabled with preference states
        if (user.isexpert && updatedParticipants.length > 0 && this.props.enable_user_video !== "my_camera_disabled"){
            const [pp] = updatedParticipants.filter(up => up.email === from.email);
            const ii = updatedParticipants.findIndex(up => up.email === from.email);
            //this.muteSpeakerOfOne({pp, flag: data});
            //TP-6148
            pp && pp.streams && pp.streams.length > 0 && pp.streams.forEach((stream) => {
                stream.getAudioTracks().forEach(track => track.enabled = data);
            })
            pp.disableRemoteAudio = !data;
            updatedParticipants[ii] = pp;
            //console.log(updatedParticipants);
        }
        //set the changed session Joinee in the array
        if (participant)
            this.updateEachSessionJoinee({participant});

        //TP-3893
        if (participant)
            participants[index] = participant;
        printConsole(participants);
        //TP-6148
        this.setState({participants, updatedParticipants}, () => {
            this.props.setParticipant({participantList: participants});
            const list = this.state.participants.filter(p => p.disableRemoteAudio === false);
            let length = 0;
            //console.log("list ---------------------------", list)
            if (list !== undefined) {
                printConsole(`Length of Users with Speaker button enabled & clickable -- ${list.length}`)
                length = list.length;
            }
            //TP-4253 -- Handling of Expert's Preview tile Speaker button state when Expert's Video is enabled/enabled with preference
            if (user.isexpert && updatedParticipants.length > 0 && this.props.enable_user_video !== "my_camera_disabled"){
                let [meParticipant] = updatedParticipants.filter(p => p.email === user.email);
                const iii = this.state.updatedParticipants.findIndex(up => up.email === user.email);
                if (meParticipant) {
                    meParticipant.disableRemoteAudio = length === 0 ? true : false
                    updatedParticipants[iii] = meParticipant;
                    this.setState({updatedParticipants}) /**TP-3774*/
                }
            }
            this.setState({ userAudioEnabledCount: length }, () => {
                if (this.state.userAudioEnabledCount === 0) {
                    this.setState({disabledMutedAll: !data, isParticipantChanged}, () => {
                        this.setState({isParticipantChanged: false});
                    })
                } else if (this.state.userAudioEnabledCount > 0) {
                    this.setState({disabledMutedAll: false, isParticipantChanged}, () => {
                        this.setState({isParticipantChanged: false});
                    })
                }
            });
        })
    }

    // mute all incoming video for all. can be invoked by all
    muteHeaderVideoOfAll = (flag) => {
        const {participants} = this.state
        participants && participants.length > 0 && participants.forEach((participant) => {
            participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
                stream.getVideoTracks().forEach(track => track.enabled = flag);
            })
        })
    }

    // process screen share signal  MB2-550, 614
    processSignalSS = ({from, type}) => {
        printConsole("Start/Stop Expert SS")
        const {expertShareScreenMaxDiv, user, ssFrom} = this.state
        const [participant] = this.state.participants.filter(p => p.email === from.email)
        printConsole(participant);
        if (participant){
            //TP-5621
            if(user.isexpert /* && hasSentRequestExpertSync === true */){
                if(!expertShareScreenMaxDiv && type === 'START_EXPERT_SS'){
                    this.setState({ expertShareScreenMaxDiv: true, ssFrom: from.email }, () => {
                        this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                        if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false/* , hideExpertSSAll: false */ }) //TP-4436
                        // T32-463 -- The Passive expert side handling of the UI switch
                        // for START_EXPERT_SS Signal
                        if (this.state.maxView)
                            this.setState({ prevMaxDivPartcicipantEmail: this.state.maxDivParticipant.email});
                        else
                            this.setState({ prevMaxDivPartcicipantEmail: ''})
                        this.setState({maxDivParticipantEmail: participant.email})//MB2-608
                        //TP-6499
                        if (this.props.enable_user_video === "my_camera_disabled" && participant.disableAudio === true) {
                            participant.streams && participant.streams.forEach(parti => {
                                parti.getAudioTracks().forEach(track => {
                                    track.enabled = !participant.disableAudio
                                })
                            })
                        }
                        this.onClickMaximise({participant, isSame: false});
                        this.calVdoHeightWidth("16:9");
                    });
                    this.props.checkAnnotation();//check annotation on/off in webrtc session
                }else if(expertShareScreenMaxDiv && type === 'STOP_EXPERT_SS'){
                    //TP-5621
                    printConsole("stop expert ss");
                    this.setState({ expertShareScreenMaxDiv: false, ssFrom: '' }, () => {
                        this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                        if (this.state.isScreencasting === true) this.setState({isScreencasting: false}); //TP-3955
                        if (this.state.isAnnotateWExpertSS === true) this.setState({ isAnnotateWExpertSS: false }); //TP-4669
                    });
                    if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false/* , hideExpertSSAll: false */ }) //TP-4436
                    if (this.props.bAnnotate === true) {
                        this.props.setMaxAnnotate(false);
                    }
                    const technicians = this.state.participants.filter(p => !p.isexpert);
                    printConsole(`previous Max-view participant ${this.state.prevMaxDivPartcicipantEmail}`);
                    // TP-1605 -- use case #4 -- 
                    if (this.state.prevMaxDivPartcicipantEmail !== '') {
                        const maxDivParticipant = this.state.participants.filter(p => p.email === this.state.prevMaxDivPartcicipantEmail)
                        if (maxDivParticipant) {
                            this.setState({maxDivParticipantEmail: this.state.prevMaxDivPartcicipantEmail})//MB2-608
                            this.onClickMaximise({participant: maxDivParticipant, isSame: false});
                            this.calVdoHeightWidth("16:9");
                        } else if(technicians.length > 1) {
                            this.onClickMultiGridView();
                        } else if(technicians.length === 1) {
                            const maxDivParticipant = this.state.participants.filter(p => p.isexpert === false) // Only technician in that session
                            //this.setState({maxDivParticipantEmail: this.state.prevMaxDivPartcicipantEmail})//MB2-608
                            this.onClickMaximise({participant: maxDivParticipant, isSame: false});
                            this.calVdoHeightWidth("16:9");
                        } else {
                            const [maxDivParticipant] = this.state.participants.filter(p => p.isexpert === true) // Only experts in that session
                            this.onClickMaximizeExpert({participant: maxDivParticipant});
                        }
                    } else if (this.state.prevMaxDivPartcicipantEmail === '' && technicians.length > 1) {
                        this.onClickMultiGridView();
                    }
                    this.setState({ isSSOngoing: false }); //TP-5320, TP-5358
                }
                if (participant.streams) {
                    participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
                        stream.getVideoTracks().forEach(track => {
                            if(!expertShareScreenMaxDiv && type === 'START_EXPERT_SS'){
                                track.enabled = true;
                            }else{
                                //TP-3844
                                if (this.props.enable_user_video === "my_camera_disabled")
                                    track.enabled = false;
                            }
                        });
                    })
                } else {
                    this.waittimer = setTimeout(() => {
                        participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
                            stream.getVideoTracks().forEach(track => {
                                if(!expertShareScreenMaxDiv && type === 'START_EXPERT_SS'){
                                    track.enabled = true;
                                }else{
                                    //TP-3844
                                    if (this.props.enable_user_video === "my_camera_disabled")
                                        track.enabled = false;
                                }
                            });
                        })
                        clearTimeout(this.waittimer);
                    }, 10000)
                }
                //TP-6080
                if(this.state.isSSOngoing === true && type === "STOP_EXPERT_SS") 
                    this.setState({ isSSOngoing: false });
            }else if (!user.isexpert){
                //TP-5621 -- To not enter this condition for Passive experts
                if(!expertShareScreenMaxDiv && type === 'START_EXPERT_SS'){
                    this.setState({ expertShareScreenMaxDiv: true, ssFrom: from.email, ssStreamId: participant.stream_id, /**TP-3342*/ hideLocalVdoClass: 'd-none', showRc: false }, () => {
                        this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                    });
                    this.props.checkAnnotation();//check annotation on/off in webrtc session
                }else{
                    this.setState({ expertShareScreenMaxDiv: false, ssFrom: '', ssStreamId: '', /**TP-3342*/ hideLocalVdoClass: '', showRc: (this.state.maxView === true) ? true : false}, ()=> {
                        this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                    });
                }
                participant.streams && participant.streams.length > 0 && participant.streams.forEach((stream) => {
                    // ss stop signal also comes when max div tech leaves while ss is ongoing and new tech comes to max div 
                    stream.getVideoTracks().forEach(track => {
                        if(!expertShareScreenMaxDiv && type === 'START_EXPERT_SS'){
                            track.enabled = true;
                        }else{
                            track.enabled = false;
                        }
                    });
                });
            }
        } else {
            //TP-4160
            if (!user.isexpert && expertShareScreenMaxDiv && type === 'STOP_EXPERT_SS') {
                this.setState({ expertShareScreenMaxDiv: false, ssFrom: '', ssStreamId: '', /**TP-3342*/ hideLocalVdoClass: '', showRc: (this.state.maxView === true) ? true : false}, ()=> {
                    this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                });
            }
        }
    }

    processSignalShareAssets = ({from, type}) => {  
        const {user, expertShareScreenMaxDiv} = this.state;      
        if (user.isexpert === false) {
            if (type === "SHARE_ARTIFACTS") {
                this.setState({ showRc: false });
                if (expertShareScreenMaxDiv) return; 
                this.processAutoPreviewMode() //TP-4683
            } /* TP-4261  -- commented out for this implementation
            else if (type === "REMOVE_ARTIFACTS") {
                this.setState({ showRc: (maxView === true && !expertShareScreenMaxDiv) ? true : false})
            } */
        }        
    }

    //TP-4261 -- Trigger showing the LC icon right after technician closes his own Artifact
    startShowingLConTech = () => {
        const {expertShareScreenMaxDiv, maxView, user} = this.state;
        if (user.isexpert === false) 
            this.setState({ showRc: (maxView === true && !expertShareScreenMaxDiv) ? true : false})
    }

    //TP-3551 & TP-3532
    processSignalExpertVideoFeedEnabled = ({data, my_camera_enabled, from}) => {     
        //console.log(data,my_camera_enabled,from); //TP-3733 TP-3679
        const {expertShareScreenMaxDiv, ssFrom} = this.state; //TP-4019
        let changedParticipant = null; //TP-3677
        const {enable_user_video} = this.props;
        if (this.state.expertVideoArr.length === 0) {
            let expertVideoArr = [];
            let participants = [...this.state.participants];//TP-3737
            //TP-3611 & TP-3573
            let updatedParticipants = this.state.updatedParticipants.length > 0 ? [...this.state.updatedParticipants] : this.state.updatedParticipants;
            const [p] = this.state.participants.filter(p=> p.email === from.email);
            if(p !== undefined) {
                const index = this.state.participants.findIndex(e => e.email === from.email);
                participants[index].disableVideo = !data; //TP-3551
                //TP-3774
                participants[index].grayedVideo = !my_camera_enabled;//TP-3954 & TP-3804
                //TP-4019
                if (this.state.showMaxDivControls === false && my_camera_enabled && data === false && expertShareScreenMaxDiv && from.email === ssFrom){
                   printConsole("Don't disable the incoming Video track of Primary expert when screenshare mode is ongoing") 
                } else {
                    participants[index].streams && participants[index].streams.forEach( stream => {
                        if (stream.getVideoTracks()[0])
                            stream.getVideoTracks()[0].enabled = data;
                    })
                }
                //TP-3611 & TP-3573
                if (this.state.user.isexpert && this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                    const ii = this.state.updatedParticipants.findIndex(e => e.email === from.email);
                    if (ii >= 0) {
                        const beforeVideoState = updatedParticipants[ii].disableVideo;//TP-3677
                        updatedParticipants[ii].disableVideo = !data; //TP-3551
                        updatedParticipants[ii].grayedVideo = !my_camera_enabled; //TP-3733
                        //TP-4019
                        if (this.state.showMaxDivControls === false && my_camera_enabled && data === false && expertShareScreenMaxDiv && from.email === ssFrom){
                            printConsole("Don't disable the incoming Video track of Primary expert when screenshare mode is ongoing") 
                        } else {
                            updatedParticipants[ii].streams && updatedParticipants[ii].streams.forEach( stream => {
                                if (stream.getVideoTracks()[0])
                                    stream.getVideoTracks()[0].enabled = data;
                            })
                        }
                        //TP-3677
                        if (updatedParticipants[ii] /**TP-3954*/ || updatedParticipants[ii].grayedVideo === true /**TP-3774*/){
                            changedParticipant = updatedParticipants[ii];
                        }
                    } else {
                        changedParticipant = participants[index];
                    }
                } else if (/* this.state.user.isexpert && TP-3213 */ this.props.session_type === "RM" && enable_user_video !== "my_camera_disabled") {
                    //TP-3677
                    changedParticipant = participants[index];
                }
                //console.log(participants[index], changedParticipant);
                
                let videoObject = {};
                videoObject = {
                    id: p.userId,
                    stream: participants[index] && participants[index].streams ? participants[index].streams[0] : {}, //TP-3701
                    fullName: p.first_name + " " + p.last_name,
                    email: p.email,
                    type: "camera",
                    isexpert: p.isexpert,
                    myPicture: p.myPicture,
                    showOnlineIndicator: true,
                    isexpert: p.isexpert, //TP-3657
                    isadmin: p.isadmin, //TP-3657
                    isPaused: false, //TP-4483
                    aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", /**TP-4849*/
                    poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                }
                expertVideoArr.push(videoObject);
                this.setState({expertVideoArr, participants, updatedParticipants, changedParticipant /**TP-3611 */}, () => {
                    //TP-3213
                    if (!this.state.user.isexpert && data === false && videoObject.stream.id === this.state.setTechMaxStreamid) {
                        this.setState({ disableMaxDivControls: true }, () => {
                            this.positionFreezeIcon();
                        });
                    } else if (data === true && videoObject.stream.id === this.state.setTechMaxStreamid) {
                        this.setState({ disableMaxDivControls: false })
                    }
                    //console.log(changedParticipant);
                    this.setState({changedParticipant: null}); //TP-3774 & TP-3679 & TP-3677
                    //TP-3774 -- set the session Joinee array again
                    if (this.state.user.isexpert && participants.length > 0){
                        this.updateJoinees({other_participants: participants});
                    }
                });
            } else {
                printConsole(`User ${from.email} does not exist`)
            }
        } else {
            let expertVideoArr = [...this.state.expertVideoArr];
            let participants = [...this.state.participants]; //TP-3737
            //TP-3611 & TP-3573
            let updatedParticipants = this.state.updatedParticipants.length > 0 ? [...this.state.updatedParticipants] : this.state.updatedParticipants;
            const [p] = this.state.participants.filter(p=> p.email === from.email);
            if (p !== undefined) {
                const index = this.state.participants.findIndex(e => e.email === from.email);
                participants[index].disableVideo = !data; 
                participants[index].grayedVideo = !my_camera_enabled;//TP-3733
                //TP-4019
                //console.log("is expert ss ongoing??",expertShareScreenMaxDiv, " from expert email??", ssFrom)
                if (this.state.showMaxDivControls === false && my_camera_enabled && data === false && expertShareScreenMaxDiv && from.email === ssFrom){
                    printConsole("Don't disable the incoming Video track of Primary expert when screenshare mode is ongoing") 
                } else if (!this.state.user.isexpert) {
                    //TP-4077
                    const [videoobjscreencast] = this.state.expertVideoArr.filter(e => e.email === from.email && e.type === "screen")
                    if (videoobjscreencast) {
                        printConsole("Dont disable incoming Screencast Video track of Primary expert.....in technician");
                    } else {
                        participants[index].streams && participants[index].streams.forEach( stream => {
                            if (stream.getVideoTracks()[0])
                                stream.getVideoTracks()[0].enabled = data;
                        })
                    }

                } else {
                    participants[index].streams && participants[index].streams.forEach( stream => {
                        if (stream.getVideoTracks()[0])
                            stream.getVideoTracks()[0].enabled = data;
                    })
                }
                //TP-3611 & TP-3573
                if (this.state.user.isexpert && this.props.session_type === "RM" && this.checkIfExpertCameraEnabled() === true) {
                    const ii = this.state.updatedParticipants.findIndex(e => e.email === from.email);
                    if (ii >= 0) {
                        const beforeVideoState = updatedParticipants[ii].disableVideo; //TP-3677
                        updatedParticipants[ii] = participants[index]//TP-5416 reset the updatedParticipants array with that of participants array
                        updatedParticipants[ii].disableVideo = !data; //TP-3551
                        updatedParticipants[ii].grayedVideo = !my_camera_enabled; //TP-3733
                        //TP-4019
                        if (this.state.showMaxDivControls === false && my_camera_enabled && data === false && expertShareScreenMaxDiv && from.email === ssFrom){
                            printConsole("Don't disable the incoming Video track of Primary expert when screenshare mode is ongoing") 
                        } else {
                            updatedParticipants[ii].streams && updatedParticipants[ii].streams.forEach( stream => {
                                if (stream.getVideoTracks()[0])
                                    stream.getVideoTracks()[0].enabled = data;
                            })
                        }
                        //TP-3917 & TP-3677
                        if (updatedParticipants[ii] || updatedParticipants[ii].grayedVideo === true /**TP-3774*/){
                            changedParticipant = updatedParticipants[ii];
                        }
                    } else {
                        changedParticipant = participants[index];
                    }
                } else if (/* this.state.user.isexpert &&  TP-3213*/this.props.session_type === "RM" && enable_user_video !== "my_camera_disabled") {
                    //TP-3677
                    changedParticipant = participants[index];
                }
                //console.log(participants[index], changedParticipant);
                //TP-3551
                const [expertVideoObj] = expertVideoArr.filter(e => e.email === from.email);
                let videoObject = {};//TP-3213
                if (expertVideoObj) {   
                    //const ii = expertVideoArr.findIndex(e => e.email === from.email);
                    videoObject = expertVideoObj;//TP-3213
                } else {
                    videoObject = {
                        id: p.userId,
                        stream: participants[index] && participants[index].streams ? participants[index].streams[0] : {}, //TP-3701
                        fullName: p.first_name + " " + p.last_name,
                        email: p.email,
                        type: "camera",
                        isexpert: p.isexpert,
                        myPicture: p.myPicture,
                        showOnlineIndicator: true,
                        isexpert: p.isexpert, //TP-3657
                        isadmin: p.isadmin, //TP-3657
                        isPaused: false, //TP-4483
                        aspect_ratio: p.aspect_ratio ? p.aspect_ratio : "16:9", /**TP-4849*/
                        poster: '/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' //TP-4483
                    }
                    expertVideoArr.push(videoObject);
                }
                this.setState({expertVideoArr, participants, updatedParticipants, changedParticipant /**TP-3611*/}, () => {
                    //TP-3213
                    if (!this.state.user.isexpert && data === false && videoObject.stream.id === this.state.setTechMaxStreamid) {
                        this.setState({ disableMaxDivControls: true }, () => {
                            this.positionFreezeIcon();
                        });
                    } else if (data === true && videoObject.stream.id === this.state.setTechMaxStreamid) {
                        this.setState({ disableMaxDivControls: false })
                    }
                    
                    //console.log(changedParticipant);
                    this.setState({changedParticipant: null}); //TP-3774 & TP-3679 & TP-3677
                    //TP-3774 -- set the session Joinee array again
                    if (this.state.user.isexpert && participants.length > 0) {
                        this.updateJoinees({other_participants: participants});
                    }
                });
            } else {
                printConsole(`User ${from.email} does not exist`)
            }
        }        
    }

    //TP-3419 -- Processing the Video turn off/on button for the incoming Expert's tile in when Account Video is disabled for account
    // Processed only on expert's Grid View screen
    processSignalDisabledExpertVideoFeed = ({data, from}) => {
        printConsole("process the expert video feed disabled use case");
        let participants = this.state.participants;
        const [p] = this.state.participants.filter(p=> p.email === from.email);
        if(p !== undefined) {
            const index = this.state.participants.findIndex(e => e.email === from.email);
            participants[index].disableVideo = !data; //TP-3551
            if (data === false) participants[index].grayedVideo = true;
            this.setState({participants});
        } else {
            printConsole(`User ${from.email} does not exist`)
        }
    }
    //MB2-681,MB2-661
    checkUserOffline = (userName) => {
        printConsole(`This user has gone offline =================================================>${userName}`)
        const connection = this.state.connections.find((connection) => connection.email === userName);
        const participant = this.state.participants.find(p => p.email === userName); //TP-4905
        if (connection) {
            //TP-5179   
            this.setState({ flashMsgText: "", showFlashMessage: false, flashLeft: "", flashTop: "", flashTimer: true}, () => {
                const flashleft = window.innerWidth >= 1770 ? "4%" : window.innerWidth >= 1200 ? "5.5%" : "6%"; 
                this.triggerMessage(`${userName}`, "offline", flashleft, "3%");
            });         
            this.userLeft({from: userName});
            if(!this.state.user.isexpert){
                // check whether ss was from this expert
                if(this.state.expertShareScreenMaxDiv && this.state.ssFrom === userName){
                    this.setState({ expertShareScreenMaxDiv: false, ssFrom: '', hideLocalVdoClass: '' }, () => {
                        this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                    });
                }
            }
        } else if (participant) {
            //TP-5179 & TP-4905
            this.setState({ flashMsgText: "", showFlashMessage: false, flashLeft: "", flashTop: "", flashTimer: true}, () => {
                const flashleft = window.innerWidth >= 1770 ? "4%" : window.innerWidth >= 1200 ? "5.5%" : "6%";
                this.triggerMessage(`${userName}`, "offline", flashleft, "3%");
            });
            if (this.state.user.isexpert) {
                //If expert user then redraw the expert's screen
                this.closeVideoCall({remoteUserEmail: userName});
                //this.resetLayout({participantLeft: participant});
            } else {
                // check whether ss was from this expert
                if(this.state.expertShareScreenMaxDiv && this.state.ssFrom === userName){
                    this.setState({ expertShareScreenMaxDiv: false, ssFrom: '', hideLocalVdoClass: '' }, () => {
                        this.props.setMaxDivSS({ expertShareScreenMaxDiv: this.state.expertShareScreenMaxDiv });
                    });
                }
            }
        }
        // Handling of online users list when a user goes offline            
        const { onlineList } = this.state;
        if (onlineList !== undefined) {
            this.setState((prev) => ({
                onlineList: prev.onlineList.filter(e => e.email !== userName)
            }))
        }
    }

    //TP-3213
    positionFreezeIcon = () => {
        let paramElemVdoChild = document.getElementById(this.state.setTechMaxStreamid)
        //console.log(paramElemVdoChild)
        const rect = paramElemVdoChild.getBoundingClientRect();
        //console.log(rect);
        const sideBar = document.getElementById("sideBar");
                        
        const videoContainer = document.getElementById("allVideos");
        const videoContainerPadding = window.getComputedStyle(videoContainer).getPropertyValue('padding-left')
        //console.log(sideBar.offsetWidth, videoContainerPadding);
        let videoFreeze = document.getElementById("video-freeze");
        videoFreeze.style.position = 'absolute';
        videoFreeze.style.left = (rect.x- sideBar.offsetWidth - parseInt(videoContainerPadding)) + 'px';
        videoFreeze.style.width = Math.floor(rect.width) + 'px'; //TP-3342
    }

    //TP-3483 & TP-3468
    subscribeToImageUpload = (data) => {
        printConsole(`subscribe to "new-image-upload" socket signal with payload: ${data.userId}`);
        let userArr = [];
        userArr.push(data.userId);
        this.props.updateUserIdUploadImage(userArr); //TP-3483
        this.getUserProfilePictures(userArr);
    }

    //TP-3483 & TP-3468
    getUserProfilePictures = (userArr=null, isSpecificUsers=null) => {
        //console.log("Calling the getProfilePicture API from withing sessions");
        const {userdata} = this.state;
        let isAllUsersfetch = false;
        if (userArr === null) {
            isAllUsersfetch = true;
            userArr = [];
            userdata.forEach(u => {
                userArr.push(u.id)
            })
        } 
        if (userArr.length > 0) {
            let fetchString = 'user/getProfilePicture/';
            const params = {
                user_ids: userArr
            }
            
			this.authService.fetch(fetchString, {
                method: 'post',
                body: JSON.stringify(params)
			})
			.then(response => response.json())
			.then(data => {
			 	if(data !== undefined && data !== null){
                    if (isAllUsersfetch === true || isSpecificUsers !== null /**TP-5387*/) {
                        this.props.updateProfilePic(data);
                    }
                    else {
                        this.props.updatePrfilePicInArray(data[0]);
                        this.props.updateIsImageUploaded(true);
                    }
			 	}
			})
			.catch(err => {
                this.setState({ isLoading: false });
                printConsole(err.message)
            })
        }
    }
 
    //MB2-441
    subscribeToOffline = () => {
        printConsole("Your websocket connection has been interrupted!"); 
        const message = "Connection Interrupted";
        this.triggerMessage(message, "self-offline", '40%', '50%');
        this.timeOut = setTimeout(this.hangUp, 10000)
    }

    startExpertTimer = () => {
        printConsole("Start 30 seconds timer on expert side");
        this.setState({
            expertWaitTimer : 30
        },
        () => {
            this.expertTimer = setInterval(this.decrementExpertCounter.bind(this), 1000)
        });
    }

    decrementExpertCounter = () => {
        this.setState((prevState) =>({
            expertWaitTimer: prevState.expertWaitTimer - 1
        }),() => {
            //console.log("Timer -- :", this.state.expertWaitTimer, " seconds left");
            if(this.state.expertWaitTimer === 0){
                this.closeExpertWindow();
            }
        });        
    } 

    stopExpertCounter = () => {
        if (this.state.expertWaitTimer === 0) return;
        printConsole("Stop the 30 second timer if the first user's feed comes in");
        this.setState({
            expertWaitTimer: 0
        },() => {
            clearInterval(this.expertTimer);
        });
    }

    // Method to close the Expert's session screen
    closeExpertWindow = () => {
        const {selectedGroupId} = this.props;
        const message = "Ending the call due to failure to establish a connection with the callee(s). Please try calling again.";   
        console.log(message);
        this.triggerMessage(message, "expert-timeout", "", ""); //TP-2502
        this.timeot = setTimeout(() => {
            this.hangUp();
            this.setState({
                expertWaitTimer: 0
            },() => {
                clearInterval(this.expertTimer);
                this.props.cleanSessionToken(selectedGroupId);//T32-420 clear the session token  
            });
        }, 4000)
    }

    //TP-1922 -- Implementation of the 30 seconds timeout of an Peer-connection offer-answer pair
    startPeerConnectionTimer = () => {
        printConsole("Start 30 seconds timer for each peer-connection on expert side");
        this.setState({
            peerWaitTimer : 30
        },
        () => {
            this.peerConnectionTimer = setInterval(this.decrementPeerConnectionCounter.bind(this), 1000)
        });
    }

    decrementPeerConnectionCounter = () => {
        const {isOngoingPeerEmail} = this.state;        
        this.setState((prevState) =>({
            peerWaitTimer: prevState.peerWaitTimer - 1
        }),() => {
            //console.log("Timer -- :", this.state.peerWaitTimer, " seconds left");
            if(this.state.peerWaitTimer === 0 && isOngoingPeerEmail !== ''){
                printConsole(" Bumping out the user whose peer-connection wasn't established properly within 30 secs")//TP-1922
                this.closeVideoCall({remoteUserEmail: isOngoingPeerEmail});
            }
        });        
    } 

    stopPeerConnectionCounter = () => {
        if (this.state.peerWaitTimer === 0) return;
        printConsole(`Stop the 30 second timer if user's ${this.state.isOngoingPeerEmail} feed comes in`);
        this.setState({
            peerWaitTimer: 0
        },() => {
            clearInterval(this.peerConnectionTimer);
        });
    }
    
    // stop session. Can be invoked by all
    hangUp = () => {
        printConsole("Hang up the call by severing the peerconnections");
        const {connections} = this.state;
        if (connections.length > 0){
            connections.forEach((connection) => {
                connection.peerConnection.close();
            })
        }else{
            // some times expert goes into call directly without dialer then he has to come out from the indeterminate state
            this.closeVideoCall({remoteUserEmail: null})
        }
    };

    // other user left. Invoked for all
    userLeft = (data) => {
        printConsole(`other user has left ${JSON.stringify(data)}`)
        let {connections, user, participants, expertVideoArr, newVideoArr} = this.state;
        // MB2-431 if the other only participant is leaving without giving access to his camera/mic
        if(connections.length === 0){
            this.closeVideoCall({remoteUserEmail: null})
        }
        // to be executed if peer connection is there 
        const connection = connections.find((connection) => connection.email === data.from);
        if(connection){
            connections = connections.filter((connection) => connection.email !== data.from);
            this.setState({connections},() => {
                connection.peerConnection.close();
                //TP-5733
                if (this.state.connections.length <= 2) {
                    if(this.volumeCallback !== null && (this.volumeInterval === null && this.volumeInterval2 === null)) {
                        console.log("start the Audio detection timer, from userLeft");
                        audioVolumeTickCount = 0;
                        const func = () => {
                            this.volumeCallback();
                            this.volumeInterval2 = setTimeout(func, 5000);
                        }
                        this.volumeInterval = setTimeout(func, 5000);
                    }
                } else {
                    if(this.volumeInterval !== null) {
                        console.log("stop the Audio detection timer, from userLeft");
                        clearTimeout(this.volumeInterval);//TP-5678
                        this.volumeInterval = null;
                    } 
                    //TP-5678
                    if(this.volumeInterval2 !== null) {
                        clearTimeout(this.volumeInterval2);
                        this.volumeInterval2 = null;
                    }
                }
            })
        }
        //TP-2539
        if (!user.isexpert) {
            const other_participants = participants.filter((participant) => participant.email !== data.from);
            expertVideoArr = expertVideoArr.filter(e => e.email !== data.from)
            newVideoArr = newVideoArr.filter(n => n.email !== data.from); //TP-3114
            this.setState({participants: other_participants, expertVideoArr, newVideoArr},() => {
                printConsole(`${data.from} participant removed`);
                printConsole("Remaining participants in this session ------");
                printConsole(other_participants);
                if(other_participants.length > 0){
                    this.updateJoinees({other_participants});
                }
                //TP-3303 -- Handling of enabling the toggle view button, whenever there are 
                //no experts remaining in an tech-to-tech session
                if (this.props.audio_mode === "tech2Tech") {
                    const experts = this.state.participants.filter(p => p.isexpert);
                    if(experts === undefined || experts.length === 0) {
                        printConsole("No experts remaining in tech2tech enabled session");
                        this.setState({is_grid: true, max_view_user: '', showRc: false}); //TP-4423
                        //TP-4319
                        this.props.resetTechnicianFeature();
                        this.resetTechVideoEnabledState();
                    } 
                    if (this.state.max_view_user === data.from) {
                        printConsole("If the user that left is the Max-view user, then change the view to own preview");
                        //TP-4507 -- If the user that left is the Max-view user, then change the view to own preview
                        this.onClickMaximize(user.email, "camera");
                    }
                }
            });
        }
    }

    //TP-4319
    resetTechVideoEnabledState = () => {
        let {participants, expertVideoArr} = this.state;        
        let userVideoCount = 0;
        participants && participants.forEach((p,i) => {
            participants[i].disableVideo = false; 
            participants[i].streams && participants[i].streams[0].getVideoTracks().forEach(track => {
                track.enabled = true
            })               
        });
        if (expertVideoArr && expertVideoArr.length > 0) {
            expertVideoArr.forEach((e,i) => {
                printConsole(`for user ...${e.email} was updated to video enabled ${true}`);
                this.setState({ disableMaxDivControls: false })
                expertVideoArr[i].stream.getVideoTracks().forEach(track => {
                    track.enabled = true;
                })
            })
        }
        printConsole("changed subscriber list: ");
        printConsole(participants);
        const list = participants.filter(p => p.disableVideo === false);
        printConsole("list ---------------------------");
        printConsole(list);
        if (list !== undefined) {
            printConsole(`Length of Users with Video on -- ${list.length}`)
            userVideoCount = list.length;
        }
        
        this.setState({expertVideoArr, participants, userVideoCount}, () => {
            //TP-3213
            if (expertVideoArr.length > 0) {
                this.setState({triggeruiRedraw: true}, () => {
                    this.setState({ triggeruiRedraw: false });
                })
            }
            if (this.state.setMaxView === false && userVideoCount > 1)
                this.onClickMultiGridView();
            else {
                if (userVideoCount === 0) this.setState({ showHideDivIconAudioOnly: true });
                else this.setState({ showHideDivIconAudioOnly: false });
            }
        })
    }

    // remove the participants leaving the call
    removeParticipant = async({remoteUserEmail}) => {
        try{
            const {enable_user_video} = this.props;
            const {participants, user, groupInfo} = this.state;
            // stop the remote tracks for partcipant leaving
            const participant = participants.find((participant) => participant.email === remoteUserEmail);
            participant && participant.streams && participant.streams.length > 0 && participant.streams.forEach(stream => {
                stream.getTracks().forEach(track => track.stop());
            })
            // if the participant leaving is also the primary expert and he left without giving the max div control to other expert
            if(user.isexpert && this.state.currentPrimaryExpert && this.state.currentPrimaryExpert.email === remoteUserEmail){
                this.setState({currentPrimaryExpert: null})
            }
            // update the list of participants 
            const other_participants = participants.filter((participant) => participant.email !== remoteUserEmail);
            let technicianArr = other_participants.filter((participant) => {
                return participant.isexpert === false;
            });
            printConsole(`all technicians left in the room are ${technicianArr.length}`);
            this.setState({ technicianCount: technicianArr.length });

            let experts = other_participants.filter(p => p.isexpert);
            printConsole(`All experts count in session: ${experts.length}`);
            if (experts.length !== this.state.expertsCount && user.isexpert === true) {
                this.setState({ expertsCount: experts.length })
            }

            //TP-4270 -- update the video list for technician (in Tech2tech mode or Expert video enabled/with preference modes)
            let other_video_list = [...this.state.expertVideoArr];
            let expertParticipant;
            if (!user.isexpert) {
                [expertParticipant] = this.state.expertVideoArr.filter(e => e.email === remoteUserEmail);
            }
            if (!user.isexpert && this.state.expertVideoArr.length > 1 && ((groupInfo.session_type === "RM" && enable_user_video !== "my_camera_disabled") || groupInfo.audio_mode === "tech2Tech")) {
                other_video_list = this.state.expertVideoArr.filter(e => e.email !== remoteUserEmail);
            }
            this.setState({participants: other_participants, expertVideoArr: other_video_list},() => {
                printConsole(`${remoteUserEmail} participant removed, ${JSON.stringify(other_participants)}`)
                if (!user.isexpert && participant /**TP-4906*/ && participant.isexpert && ((groupInfo.session_type === "RM" && enable_user_video !== "my_camera_disabled") || groupInfo.audio_mode === "tech2Tech")) {
                    //TP-4270 -- For a technician if the user that left is an expert and that expert was in its Max-view then
                    // put your own camera preview on its max-view
                    if (expertParticipant && expertParticipant.stream.id === this.state.setTechMaxStreamid) {
                        this.onClickMaximize(user.email, "camera"); //put my preview on my Max-view
                        this.setState({showPanel: false}) //TP-4379
                    } else {
                        //TP-4391 -- When the last expert leaves a session then make this Technician put its own user on its max-view
                        const allExperts = this.state.expertVideoArr.filter(e => e.isexpert);
                        if (allExperts && allExperts.length === 0){
                            this.onClickMaximize(user.email, "camera", true); //TP-5052 put my preview on my Max-view
                            this.setState({showPanel: false}) //TP-4379
                        }
                    }
                }
                // T32-413 Re-calculate the Count of Remote Users who have their own Mikes turned on
                let userRemoteArr = other_participants.filter(pa => {
                    return pa.disableRemoteAudio === false && pa.email !== user.email;
                })
                printConsole(`User Speakers enabled count ${userRemoteArr.length}`)
                this.setState({ 
                    /* userVideoCount: userVideoArr.length,  */
                    userAudioEnabledCount: userRemoteArr.length 
                }, () => {
                    //disable the Side Bar Mute Speaaker Icon if count is 0
                    // else enable the same Side Bar Mute Speaaker Icon if count > 0
                    if (this.state.userAudioEnabledCount === 0) 
                        this.setState({ disabledMutedAll: true, isParticipantChanged: true}, () => {
                            this.setState({isParticipantChanged: false});
                        });
                    else
                        this.setState({ disabledMutedAll: false, isParticipantChanged: true}, () => {
                            this.setState({isParticipantChanged: false});
                        });
                });
                //TP-5179
                this.setState({ flashMsgText: "", showFlashMessage: false, flashLeft: "", flashTop: "", flashTimer: true }, () => {
                    const flashleft = window.innerWidth >= 1770 ? "4%" : window.innerWidth >= 1200 ? "5.5%" : "6%";
                    this.state.user.isexpert && this.state.maxView && this.triggerMessage(participant, "disconnect", flashleft, "3%");// show flash message MB2-473
                });
                if(other_participants.length > 0){
                    this.updateJoinees({other_participants});
                    this.setOnlineUsers();
                }
            });

            // when user leaving session without clicking stop button like pressing f5, closing browser. MB2-575
            const connections = [...this.state.connections];
            const connectionIndex = connections.findIndex(c => c.email === remoteUserEmail);
            if (connectionIndex !== -1){
                connections.splice(connectionIndex, 1);
                //TP-5733
                this.setState({connections}, () => {
                    if (this.state.connections.length <= 2) {
                        if(this.volumeCallback !== null && (this.volumeInterval === null && this.volumeInterval2 === null)) {
                            console.log("start the Audio detect timer, in removeParticipant");
                            audioVolumeTickCount = 0;
                            const func = () => {
                                this.volumeCallback();
                                this.volumeInterval2 = setTimeout(func, 5000);
                            }
                            this.volumeInterval = setTimeout(func, 5000);
                        }
                    } else {
                        if(this.volumeInterval !== null) {
                            console.log("stop the Audio detect timer, in removeParticipant");
                            clearTimeout(this.volumeInterval);//TP-5678
                            this.volumeInterval = null;
                        } 
                        //TP-5678
                        if(this.volumeInterval2 !== null) {
                            clearTimeout(this.volumeInterval2);
                            this.volumeInterval2 = null;
                        }
                    }
                });
            }

            return participant; // to be used to check if participant leaving is the max div participant
        }catch(error){
            printConsole(error)
        }
    }

    //TP-2292 -- Set the expertParticipantLeft parameter from the child components
    setExpertParticipantLeft = (val) => {
        this.setState({ expertParticipantLeft: val });
    }

    // to be invoked by tech/expert when peer connection is closed
    closeVideoCall = async({remoteUserEmail, isReconnecting}) => {
        try{
            let participantLeft;
            if (remoteUserEmail)  participantLeft = await this.removeParticipant({remoteUserEmail})
            /*TP-2292*/if (/*TP-2502*/participantLeft && participantLeft.isexpert === true && sessionCmd !== 'session_stopped') this.setState({ expertParticipantLeft: participantLeft.email })

            /* if (this.props.user.isexpert && isReconnecting === true) {
                this.sessionSignal.sendWebrtcEndCall({willBeReconnecting: isReconnecting});
            } else if (!this.props.user.isexpert && isReconnecting === true) {
                // Show the Reconnecting UI on the technician side
                this.props.triggerReconnecting(true);
                return;
            } */

            const {localStream, previewStream, participants, connections, sessionCmd, screenShareStream, showMaxDivControls} = this.state;
            /*1.when anyone is refreshing the page/closing the browser then peer disconnection will get trigered but user left signal will not be triggered
            so connection will stay back in the state back although participant will be cleared. 2.For multi tech the peer connection for tech will be less than partcipant count
            so peer conection will be 0 but participant count will not be 0 so automatic session close will not happen.
            3. For multi expert scenario this block will get hit for each peer connection so put a check with exited flag or else signals will trigger multiple times */
            if((participants.length === 0 || connections.length === 0 || sessionCmd === 'session_stopped') && !this.state.exited){
                //TP-3075 & TP-2349 -- Handling the removal of the Guest user when it gets disconnected
                if (this.state.showMaxDivControls && this.state.guestUserList.length > 0 && this.state.guestUserList.indexOf(participantLeft.email) > -1) {
                    printConsole("remove guest user from Primary expert's Guest user list");
                    this.setState({ guestUser: participantLeft.email });
                }
                if (this.state.user.isexpert && showMaxDivControls && screenShareStream){
                    this.state.isMaxDivSS && await this.initMaxScreenShare(false);// if call is ending without stopping max div ss
                    this.state.isSideBarSS && await this.initSideBarScreenShare(false, false);// if call is ending without stopping side bar ss
                }
                this.setState({exited :true},() => {
                    if (localStream){
                        localStream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    }
                    if (previewStream) {
                        previewStream.getTracks().forEach((track) => {
                            track.stop();
                        });
                    }
                    if (screenShareStream) {
                        this.removeScreenShareStopListener(screenShareStream, this.initMaxScreenShare);// first remove the listner
                        screenShareStream.getTracks().forEach((track) => {
                            track.stop();
                        })
                    }
                    if (this.localVideoRef.current) this.localVideoRef.current.src = null;
                    const experts = this.state.participants.filter((p) => p.isexpert === true)
                    if (this.props.user.isexpert && experts.length === 0) {
                        //printConsole("Clear session token called");
                        //this.props.clearSessionToken(this.props.selectedGroupId);//NS2-425 clear the session token
                    }
                    //TP-5678 -- Clear out the Audio detection timers when session is closed on this user's side
                    if(this.volumeInterval !== null) {
                        clearTimeout(this.volumeInterval);//TP-5678
                        this.volumeInterval = null;
                    } 
                    if(this.volumeInterval2 !== null) {
                        clearTimeout(this.volumeInterval2);
                        this.volumeInterval2 = null;
                    }
                    //TP-2209 -- handling of stop and clear session resording feature
                    // before the session is closed on the Primary expert side
                    printConsole(`is startRecording -----> ${this.state.startRecording}`);
                    printConsole(`is Silent recording ----> ${this.state.isSilentRecording}`);
                    printConsole(this.state.showMaxDivControls && this.state.currentPrimaryExpert === this.state.user);
                    //console.log("total participants?? ", participants.length, "total peerconnections?? ", connections.length);
                    if (this.state.startRecording && (this.state.isSilentRecording === false || this.state.isSilentRecording === undefined) && this.state.showMaxDivControls && this.state.currentPrimaryExpert === this.state.user /* && (participants.length === 0 || connections.length === 0) */) {
                        printConsole("Stop Recording when expert ends the session abruptly...");
                        //this.stopScreenshotRecordingCb();
                        this.stopRecordingCallback("end");
                    } else {
                        // this.onStartStopOfSession();
                        /* !isReconnecting &&  */this.sessionSignal.sendWebrtcEndCall();
                        this.sessionSignal.sendUserInSession({data:{ groupId: this.props.selectedGroupId, isInSession: false }});
                        printConsole("Close button clicked");
                        this.updatedUsage({session_started: false})
                        if (typeof(Storage) !== "undefined") {
                            // if (this.state.showMaxDivControls && this.state.currentPrimaryExpert === this.state.user)//FQ3-295
                            //TP-3075 - -Commented this out to resolve this issue
                            //['groupId', 'isonprem'].forEach(key =>  sessionStorage.removeItem(key));//FQ3-295, TP-991
                        } else {
                            printConsole(`Sorry, your browser does not support Web Storage...`)
                        }
                        this.props.onClickBack();
                    }
                })
            }else{
                if (this.props.user.isexpert && !this.isGuest && this.state.showMaxDivControls && sessionCmd === 'session_stopped' && connections.length > 0) {
                    const registeredExperts = participants.filter(p => p.isexpert && p.isRegistered === true);
                    if (registeredExperts && registeredExperts.length === 0 && this.state.guestUserList.length > 0) {
                        //TP-4352 -- When the only expert (Primary) of the session leaves, while having Guest users in that session
                        // and there aren't anymore registered experts in that session
                        printConsole("delete the guest users after session ends");
                        if (typeof(Storage) !== "undefined") {
                            printConsole(`setting isGuestUsersTobeDeleted to true`);
                            sessionStorage.setItem("isGuestUsersTobeDeleted", "true");
                        } else {
                            printConsole(`Sorry, your browser does not support Web Storage...`)
                        }
                    }
                }
                // MB2-653 reset layout should'nt be triggered when expert himself stopping the call
                if(this.props.user.isexpert && sessionCmd !== 'session_stopped'){
                    //TP-2618 & TP-3119
                    if (this.props.user.isexpert && (this.state.groupInfo.session_type === "CM" || (this.state.groupInfo.session_type === "RM" && this.checkIfExpertCameraEnabled() === true /**TP-3419	*/)) && participants.length === 1) {
                        let {localStream} = this.state;
                        localStream = this.enableAudioTrack(localStream); //TP-5475
                        localStream = this.enableVideoTrack(localStream);
                        printConsole("video tracks on expert user after users left===========>");
                        printConsole(localStream.getVideoTracks());     
                        this.setState({ localStream });
                    } else if (!this.props.user.isexpert && this.state.groupInfo.session_type === "RM" && this.props.audio_mode === "tech2Tech" && participants.length >= 1) {
                        const experts = participants.filter(p => p.isexpert);
                        if (experts === undefined){
                            //TP-4319
                            printConsole("No experts remaining in tech2tech enabled session");
                            this.props.resetTechnicianFeature();
                            this.resetTechVideoEnabledState();
                        }
                    }
                    this.resetLayout({participantLeft})
                }
            }
        }catch(error){
            printConsole(error)
        }
    };

    onConnectionStateChange = ({to, connectionState}) => {
        printConsole(`peerconnection state change detected for user: ${to}, state changed to ${connectionState}`);
        /* if (this.state.participants.length === 1 && connectionState === "connecting") {
            //this.setState({ isOngoingPeer: true, isOngoingPeerEmail: to });
        } else */ if (this.state.isOngoingPeerEmail !== '' && this.state.isOngoingPeerEmail === to && connectionState === "connected") {
            this.setState({ isOngoingPeer: false, isOngoingPeerEmail: ''})
        }
    }


    // inform back end session has started/ended
    updatedUsage = ({session_started}) => {
        let fetchString = 'webrtcSessionEndpoint'; 
        printConsole("Calling the webrtcSessionEndpoint API");
        this.authService.fetch(fetchString, {
            method: 'post',
            body: JSON.stringify({
                groupId: this.props.selectedGroupId,
                session_started,
                record_id: this.state.record_id //TP-1650
            })
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                throw response;
            }
        })
        .then(data => {
            printConsole("WebRTC Session End Point call reponse is received");
            printConsole(data);
            if (session_started === true) {
                this.setState({record_id: data.record_id})//TP-1650
                //TP-4511
                if (typeof(Storage) !== "undefined") {
                    printConsole(`setting the record_id in session storage =======================================>${this.props.selectedGroupId}`)
                    sessionStorage.setItem("recordId", data.record_id);
                } else {
                    printConsole(`Sorry, your browser does not support Web Storage...`)
                }
            }
        })
        .catch(err => {
            throw err; 
        })
    }

    // Enable the start/stop header button. invoked for all
    onStartStopOfSession = () => {
        this.props.changeStartStop();
    }

    getDateString = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${year}${month}${day}_${hour}${minutes}${seconds}`
    }

    getDateStringForWatermark = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`
    }

    takeScreenShot = () => {
        this.setState({ screenCaptureClicked: true }, () => {
            const canvas = document.getElementById('canvas');
            if (canvas) {
                const dataURL = canvas.toDataURL('image/jpeg', 1.0);
                const fileName = `Telepresenz_RC_${this.getDateString()}`;
                const element = document.createElement('a');
                element.setAttribute('href', dataURL);
                element.setAttribute('download', fileName);

                element.style.display = 'none';
                document.body.appendChild(element);

                element.click();

                document.body.removeChild(element);

                this.setState({ screenCaptureClicked: false })
            }
        })
    }

    // close the webrtc error message MB2-431
    onCloseWebrtcMessage = () => {
        const {fromSocketUser} = this.props;
        const {localStream, previewStream} = this.state;
        this.setState({showWebrtcErrorMessage: false, webrtcErrorType: ''})
        //TP-6208
        if (localStream){
            localStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        //TP-6208
        if (previewStream) {
            previewStream.getTracks().forEach((track) => {
                track.stop();
            });
        }
        if(fromSocketUser){
            this.sessionSignal.sendRespondToCall({data:{ action: 'declined', recipient: fromSocketUser }});
        }else{
            this.sessionSignal.sendWebrtcEndCall();
        }
        this.props.onClickBack();
    }

    //onClick function for Toggle of Header Chat button
    onClickChatAll = (flag) => {
        let {bAllChat, bMaxChat } = this.state;
        this.setState({
            bAllChat : !bAllChat
        }, ()=>{                
            if (this.state.bAllChat && bMaxChat === true) {
                this.setState({bMaxChat : !bMaxChat }) // if max chat on close it 
            }
            if (!this.state.bAllChat) {
                this.setState({chatUserNames : [], chatUserEmails: [] })// reset the user names and emails so that again next it can be initialized if it text comes from same user
            }
        })
    }
    
    //onClick function for Toggle of maxDiv Chat button
    onClickChat = () => {
        let {bAllChat, bMaxChat} = this.state;
        this.setState({
            bMaxChat : !bMaxChat
        }, ()=> {
            if(this.state.bMaxChat && bAllChat){
                this.setState({bAllChat : !bAllChat, chatUserNames : [], chatUserEmails: [] })
            }
        });
    }

    getMonthString = (mo) => {
        switch (mo) {
            case 0: return 'Jan';
            case 1: return 'Feb';
            case 2: return 'Mar';
            case 3: return 'Apr';
            case 4: return 'May';
            case 5: return 'Jun';
            case 6: return 'Jul';
            case 7: return 'Aug';
            case 8: return 'Sep';
            case 9: return 'Oct';
            case 10: return 'Nov';
            case 11: return 'Dec';
            default: return '';
        }
    }

    getTimeStamp = () => {
        let strTimeBlob = '';
        let today = new Date();

        strTimeBlob = this.getMonthString(today.getMonth());
        strTimeBlob += ' ';
        strTimeBlob += today.getDate();
        strTimeBlob += ' ';
        strTimeBlob += today.getHours();
        strTimeBlob += ':';
        strTimeBlob += today.getMinutes();

        return strTimeBlob;
    }

    /*TZ-56 componentWillReceiveProps(nextProps){
        let flag = false;
        for(let i=0; i<= nextProps.chatMsgInfoArr.length; i++) {
            if(nextProps.chatMsgInfoArr[i] && nextProps.chatMsgInfoArr[i].isEdited && nextProps.chatMsgInfoArr[i].isEdited !== this.state.chatMsgInfoArr[i].isEdited && nextProps.chatMsgInfoArr[i].isEdited === true) {
                //console.log("One message bubble edited");
                flag = true;
            }
        }
        if (flag === true) {
            this.setState({ chatMsgInfoArr: nextProps.chatMsgInfoArr });
        }
    } */

    updateChatArr = (action, chatMsgInfo, messageID=null) => {
        let chatMsgInfoArrNew = [...this.state.chatMsgInfoArr];
        switch(action) {
            case 'sendChat':
                chatMsgInfoArrNew.push(chatMsgInfo);
                //this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}), () => {
                    this.props.updateChatMsgArr(chatMsgInfoArrNew);
                //})
                break;
            case 'deleteChat':                
                let [chatMsgInfoDeleted] = this.state.chatMsgInfoArr.filter(info => info.messageID === messageID);
                const index = this.state.chatMsgInfoArr.findIndex(info => info.messageID === messageID);
                chatMsgInfoDeleted.isDeleted = true;
                chatMsgInfoArrNew[index] = chatMsgInfoDeleted;
                //this.setState({ chatMsgInfoArr: chatMsgInfoArrNew }, () => {
                    this.props.updateChatMsgArr(chatMsgInfoArrNew);
                //});
                break;
            case 'editChat': 
                const ind = this.state.chatMsgInfoArr.findIndex(info => info.messageID == messageID);
                let [chatMsgInfoEdited] = this.state.chatMsgInfoArr.filter(info => info.messageID == messageID);
                chatMsgInfoEdited.isEdited = true;
                chatMsgInfoEdited.message = chatMsgInfo.message;
                chatMsgInfoEdited.timeStamp = chatMsgInfo.timeStamp;
                chatMsgInfoArrNew[ind] = chatMsgInfoEdited;
                //this.setState({ chatMsgInfoArr: chatMsgInfoArrNew }, () => {
                    this.props.updateChatMsgArr(chatMsgInfoArrNew);
                //});
                break;
            case 'replyChat':
                let [chatMsgInfoReply] = this.state.chatMsgInfoArr.filter(info => info.messageID === messageID);
                chatMsgInfo.isReplied = true;
                chatMsgInfo.replyMessage = {
                    replyMessage: chatMsgInfoReply.message,
                    firstName: chatMsgInfoReply.firstName,
                    lastName: chatMsgInfoReply.lastName
                }
                chatMsgInfoArrNew.push(chatMsgInfo);
                //this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}), () => {
                    this.props.updateChatMsgArr(chatMsgInfoArrNew);
                //});
                break;
        }
    }
    //TZ-56 -- reverted the send Chat message logic to the production behaviour
    sendChatMsg = (msgToSend, elemId) => {
        const {maxDivParticipant, user} = this.state; 
        let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
        //TP-3303 -- Handling of profile picture when no profile picture in Chat window
        if (user.myPicture && user.myPicture.includes("base64") === false && user.myPicture.includes("profile.png") === false)
            user.myPicture = 'data:image/jpg;base64,'+ user.myPicture;
        else if (user.myPicture && (user.myPicture.includes("profile.png") === true || user.myPicture === ""))
            user.myPicture = null;
        let timeStamp = this.getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: this.props.user.firstname,
            lastName: this.props.user.lastname, message: msgToSend, timeStamp: timeStamp, msgType: 1,
            myPicture: user.myPicture
        };
        //this.updateChatArr('sendChat', chatMsgInfo);
        this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}))
        /* const message = {
            font_color: "#ffb3b3",
            font_size: "14px",
            font_type: "900",
            font_underline: false,
            font_overline: false,
            font_line_through: true,
            font_family: "Titillium Web",
            msg_data: msgToSend,
        }
        const action = {send_msg: true,
            delete_msg: false, edit_msg: false, 
            vanish_msg: false
        }
        this.handlerSendChatSocketSignalLogic(sixdigitsrandom, elemId, message, action); */
        //console.log(participants);
        //const groupId = selectedGroup.groupid;
        if(user.isexpert){
            if (elemId !== null) {
                this.sessionSignal.sendSignalChat(sixdigitsrandom, msgToSend, false, maxDivParticipant.email);
                //TP-5102 -- Sending the one-to-one chats to all other experts in that session
                const experts = this.state.participants.filter(p => p.isexpert === true);
                if (experts) {
                    experts.forEach(ee => {
                        this.sessionSignal.sendSignalChat(sixdigitsrandom, msgToSend, false, ee.email);
                    });
                }
            }else{
                //Sending the Group chat to all users part of this group (in a one-to-one signal)
                this.state.participants.forEach((participant) => {
                    this.sessionSignal.sendSignalChat(sixdigitsrandom, msgToSend, false, participant.email);
                })
            }
        } else {
            //TP-4203 -- New chat one-to-one feature implementation on Webapp Technician during
            //either tech2tech or Expert's Camera is enabled sessions
            if (this.props.session_type === "RM" && (this.props.audio_mode === "tech2Tech" || this.props.enable_user_video !== "my_camera_disabled")) {
                const [maxViewParticipant] = this.state.expertVideoArr.filter(e => e.stream.id === this.state.setTechMaxStreamid);
                if (maxViewParticipant && maxViewParticipant.email !== user.email) {
                    this.sessionSignal.sendSignalChat(sixdigitsrandom, msgToSend, false, maxViewParticipant.email);
                } else if (maxViewParticipant && maxViewParticipant.email === user.email && this.props.enable_user_video !== "my_camera_disabled" && this.props.audio_mode !== "tech2Tech") { 
                    //When tech2Tech is disabled but Expert Camera is enabled 
                    this.state.participants.forEach((participant) => {
                        if(participant.isexpert){
                            this.sessionSignal.sendSignalChat(sixdigitsrandom, msgToSend, false, participant.email);
                        }
                    })
                }else {
                    this.state.participants.forEach((participant) => {
                        this.sessionSignal.sendSignalChat(sixdigitsrandom, msgToSend, false, participant.email);
                    })
                }
            } else {
                this.state.participants.forEach((participant) => {
                    if(participant.isexpert){
                        this.sessionSignal.sendSignalChat(sixdigitsrandom, msgToSend, false, participant.email);
                    }
                })
            }
        }        
    }

    replyChatMsg = (new_msg, elemId, replymessageID) => {
        console.log("Reply to chat msg: ", replymessageID, elemId, new_msg)
        const {user} = this.state;
        let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
        //TP-3303 -- Handling of profile picture when no profile picture in Chat window
        if (user.myPicture && user.myPicture.includes("base64") === false && user.myPicture.includes("profile.png") === false)
            user.myPicture = 'data:image/jpg;base64,'+ user.myPicture;
        else if (user.myPicture && (user.myPicture.includes("profile.png") === true || user.myPicture === ""))
            user.myPicture = null;
        let timeStamp = this.getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: this.props.user.firstname,
            lastName: this.props.user.lastname, messageID: sixdigitsrandom,
            message: new_msg, timeStamp: timeStamp, msgType: 1,
            myPicture: user.myPicture
        };
        this.updateChatArr('replyChat', chatMsgInfo, replymessageID);
        const message = {
            font_color: "#ffb3b3",
            font_size: "14px",
            font_type: "900",
            font_underline: false,
            font_overline: false,
            font_line_through: true,
            font_family: "Titillium Web",
            msg_data: new_msg,
            replymessageID
        }
        const action = {send_msg: false,
            delete_msg: false, edit_msg: false, 
            reply_msg: true, vanish_msg: false}
        this.handlerSendChatSocketSignalLogic(sixdigitsrandom, elemId, message, action);
        /* participantList && participantList.length > 0 && participantList.forEach(parti => {
            const toObject = {
                email: parti.email,
                firstName: parti.first_name,
                lastName: parti.last_name
            };
            this.sendSignalChat(sixdigitsrandom, message, groupId, action, toObject);
        }) */
    }

    editChatMsg = (new_msg, elemId, messageID) => {
        console.log("Edit chat msg: ", messageID, elemId, new_msg)
        const {user} = this.state;
        //TP-3303 -- Handling of profile picture when no profile picture in Chat window
        if (user.myPicture && user.myPicture.includes("base64") === false && user.myPicture.includes("profile.png") === false)
            user.myPicture = 'data:image/jpg;base64,'+ user.myPicture;
        else if (user.myPicture && (user.myPicture.includes("profile.png") === true || user.myPicture === ""))
            user.myPicture = null;
        let timeStamp = this.getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: this.props.user.firstname,
            lastName: this.props.user.lastname, messageID,
            message: new_msg, timeStamp: timeStamp, msgType: 1,
            myPicture: user.myPicture
        };
        this.updateChatArr('editChat', chatMsgInfo, messageID);
        const message = {
            font_color: "#ffb3b3",
            font_size: "14px",
            font_type: "900",
            font_underline: false,
            font_overline: false,
            font_line_through: true,
            font_family: "Titillium Web",
            msg_data: new_msg,
        }
        const action = {send_msg: false,
            delete_msg: false, edit_msg: true, 
            vanish_msg: false}
        this.handlerSendChatSocketSignalLogic(messageID, elemId, message, action);
        /* participantList && participantList.length > 0 && participantList.forEach(parti => {
            const toObject = {
                email: parti.email,
                firstName: parti.first_name,
                lastName: parti.last_name
            };
            this.sendSignalChat(messageID, message, groupId, action, toObject);
        }) */
    }

    deleteChatMsg = (messageID, elemId) => {
        console.log("Delete chat msg: ", messageID);
        const {user} = this.state;
        const action = {send_msg: false,
            delete_msg: true, edit_msg: false, 
            vanish_msg: false}
        this.updateChatArr('deleteChat', {}, messageID);
        this.handlerSendChatSocketSignalLogic(messageID, elemId, '', action);
        /* participantList && participantList.length > 0 && participantList.forEach(parti => {
            const toObject = {
                email: parti.email,
                firstName: parti.first_name,
                lastName: parti.last_name
            };
            this.sendSignalChat(messageID, "", groupId, action, toObject);
        }) */
    }


    handlerSendChatSocketSignalLogic = (sixdigitsrandom, elemId, message, action) => {
        const {maxDivParticipant, user} = this.state;
        if(user.isexpert){
            if (elemId !== null) {
                const maxToObject = {
                    email: maxDivParticipant.email,
                    firstName: maxDivParticipant.first_name,
                    lastName: maxDivParticipant.last_name
                };
                this.sessionSignal.sendSignalChatNew(sixdigitsrandom, message, false, action, maxToObject); //TP-4884
                //TP-5102 -- Sending the one-to-one chats to all other experts in that session
                const experts = this.state.participants.filter(p => p.isexpert === true);
                if (experts) {
                    experts.forEach(ee => {
                        const toObject = {
                            email: ee.email,
                            firstName: ee.first_name,
                            lastName: ee.last_name
                        };
                        this.sessionSignal.sendSignalChatNew(sixdigitsrandom, message, false, action, toObject);                        
                    });
                }
            }else{
                //Sending the Group chat to all users part of this group (in a one-to-one signal)
                this.state.participants.forEach((participant) => {
                    const toObject = {
                        email: participant.email,
                        firstName: participant.first_name,
                        lastName: participant.last_name
                    };
                    this.sessionSignal.sendSignalChatNew(sixdigitsrandom, message, true, action, toObject); //TP-4884
                })
            }
        }else{
            //TP-4203 -- New chat one-to-one feature implementation on Webapp Technician during
            //either tech2tech or Expert's Camera is enabled sessions
            if (this.props.session_type === "RM" && (this.props.audio_mode === "tech2Tech" || this.props.enable_user_video !== "my_camera_disabled")) {
                const [maxViewParticipant] = this.state.expertVideoArr.filter(e => e.stream.id === this.state.setTechMaxStreamid);
                if (maxViewParticipant && maxViewParticipant.email !== user.email) {
                    const maxToObject = {
                        email: maxDivParticipant.email,
                        firstName: maxDivParticipant.first_name,
                        lastName: maxDivParticipant.last_name
                    };
                    this.sessionSignal.sendSignalChatNew(sixdigitsrandom, message, false, action, maxToObject); //TP-4884 send to only Max-vew user                        
                } else if (maxViewParticipant && maxViewParticipant.email === user.email && this.props.enable_user_video !== "my_camera_disabled" && this.props.audio_mode !== "tech2Tech") { 
                    //When tech2Tech is disabled but Expert Camera is enabled 
                    this.state.participants.forEach((participant) => {
                        if(participant.isexpert){
                            const toObject = {
                                email: participant.email,
                                firstName: participant.first_name,
                                lastName: participant.last_name
                            };
                            this.sessionSignal.sendSignalChatNew(sixdigitsrandom, message, false, action, toObject); //TP-4884 send to all experts only
                        }
                    })
                }else {
                    this.state.participants.forEach((participant) => {
                        const toObject = {
                            email: participant.email,
                            firstName: participant.first_name,
                            lastName: participant.last_name
                        };
                        this.sessionSignal.sendSignalChatNew(sixdigitsrandom, message, false, action, toObject); //TP-4884 send to all users (both experts & technicians)
                    })
                }
            } else {
                this.state.participants.forEach((participant) => {
                    if(participant.isexpert){
                        const toObject = {
                            email: participant.email,
                            firstName: participant.first_name,
                            lastName: participant.last_name
                        };
                        this.sessionSignal.sendSignalChatNew(sixdigitsrandom, message, false, action, toObject); //TP-4884 send to all expert
                    }
                })
            }
        }
    }


    processSignalIM = ({messageID, data, from}) => {
        let strNewMsg = data, bIsDuplicate = false, senderInfo = from, timeStamp = this.getTimeStamp();
        const {user, maxDivParticipant}= this.state;

        this.state.chatMsgInfoArr.forEach(msgInfo => {
            /* if (strNewMsg ===   msgInfo.message)
                bIsDuplicate = true;
            else */ if (messageID === msgInfo.messageID) //MB2-95
                bIsDuplicate = true;
        })
        if(user.isexpert && bIsDuplicate === false) {
            //TP-2222 -- Handled this use case only for Android/iOS devices
            if (this.checkSmallScreenDevice() === true) {
                let chatMsg = "{name} says: {text}";
                let values = {name: from.firstName + " " + from.lastName, text: strNewMsg};
                this.setState({ flashMsgText: chatMsg, flashValues: values, showFlashMessage: true, flashLeft: '43%', flashTop: '50%', flashTimer: true }); //TP-5681

            } else {
                if(this.state.maxView && !this.state.expertShareScreenMaxDiv){
                    // Only When expert is in Max-view Mode and not in Screen cast mode
                    this.checkChatUser({from}); //TP-933
                }
                const [fromParticipant] = this.state.participants.filter(p => p.email === from.email);
                //TP-3303 -- Handling of profile picture when no profile picture in Chat window
                if (fromParticipant.myPicture && fromParticipant.myPicture.includes("base64") === false && fromParticipant.myPicture.includes("profile.png") === false)
                    fromParticipant.myPicture = 'data:image/jpg;base64,'+ fromParticipant.myPicture;
                else if (fromParticipant.myPicture && (fromParticipant.myPicture.includes("profile.png") === true || fromParticipant.myPicture === ""))
                    fromParticipant.myPicture = null;
                let chatMsgInfo = {
                    msgId: from.email, messageID: messageID, firstName: senderInfo.firstName,
                    lastName: senderInfo.lastName, message: strNewMsg, timeStamp: timeStamp, msgType: 0,
                    myPicture: fromParticipant.myPicture
                };
                this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}))
                if (!this.state.bAllChat){
                    if(!this.state.maxView){
                        this.setState({bAllChat: true});// max view is not on so activate global chat
                    }else if(this.state.maxView && !this.state.bMaxChat && from.email === maxDivParticipant.email){
                        this.setState({ bMaxChat: true });// max view is on and chat came from max view tech so activate max chat
                        if(this.props.bAnnotate || this.props.bRemoteCapture){
                            this.setState({startChatTimer: true});
                        }
                    }else if(this.state.maxView && from.email !== maxDivParticipant.email){
                        // max view is on and chat not came from max view tech and annotate or rc going on so activate global chat, MB2-489
                        if(this.props.bAnnotate || this.props.bRemoteCapture){
                            this.setState({bMaxChat: false, bAllChat: true , startChatTimer: true});//Mb2-489
                        } else if (this.state.expertShareScreenMaxDiv) { //T32-240
                            this.setState({bMaxChat: false, bAllChat: true});
                        } else if (from.isexpert === true) {  //T32-240
                            this.setState({bMaxChat: false, bAllChat: true});
                        }
                    }
                }
            }
        }else if(!user.isexpert && bIsDuplicate === false && ((senderInfo.isexpert === true && this.props.audio_mode !== "tech2Tech") || (this.props.audio_mode === "tech2Tech" /**TP-5203, TP-4269 && TP-4203*/))){
            if (this.checkSmallScreenDevice() === true) {
                let chatMsg = "{name} says: {text}";
                let values = {name: from.firstName + " " + from.lastName, text: strNewMsg};
                this.setState({ flashMsgText: chatMsg, flashValues: values, showFlashMessage: true, flashLeft: '43%', flashTop: '50%', flashTimer: true }); //TP-5681

            } else {
                const [fromParticipant] = this.state.participants.filter(p => p.email === from.email);
                //TP-3303 -- Handling of profile picture when no profile picture in Chat window
                if (fromParticipant.myPicture && fromParticipant.myPicture.includes("base64") === false && fromParticipant.myPicture.includes("profile.png") === false)
                    fromParticipant.myPicture = 'data:image/jpg;base64,'+ fromParticipant.myPicture;
                else if (fromParticipant.myPicture && (fromParticipant.myPicture.includes("profile.png") === true || fromParticipant.myPicture === ""))
                    fromParticipant.myPicture = null;
                let chatMsgInfo = {
                    msgId: user.email, messageID: messageID, firstName: senderInfo.firstName,
                    lastName: senderInfo.lastName, message: strNewMsg, timeStamp: timeStamp, msgType: 0,
                    myPicture: fromParticipant.myPicture
                };
                this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}))
                this.setState({ bMaxChat: true });
            }
        }
        return;
    }

    // MB2-489
    updateChatFlags = (val) => {
        this.setState({ 
            bMaxChat: val, 
            bAllChat: val, 
            startChatTimer: val, 
            chatUserEmails: [],
            chatUserNames: [] 
        });
    }

    checkChatUser = ({from}) => {
        // T32-240 -- Chat Feature Handling for expert to expert chat messages
        if (!this.state.bAllChat && (from.isexpert === false || from.isExpert === false) &&  this.state.maxDivParticipant && this.state.maxDivParticipant.email !== from.email && !this.props.bAnnotate && !this.props.bRemoteCapture) {
            if ((this.state.chatUserEmails.indexOf(from.email)) < 0) {
                this.setState(prev => ({
                    chatUserEmails: [...prev.chatUserEmails, from.email]
                }), () => {
                    if (this.state.chatUserEmails.length > 0) {
                        this.setState(prev => ({ chatUserNames: [...prev.chatUserNames, from.firstName + ' ' + from.lastName]}));
                    }
                });
            }
        }
    }

    /**TP-3419 */
    checkIfExpertCameraEnabled = () => {
        const {enable_user_video, user} = this.props;
        let flag = false;
        if (enable_user_video === "my_camera_enabled" && this.state.cameraNotDetected === false /*TP-5429*/) {
            flag = true;
        } else if (enable_user_video === "my_camera_enabled_with_prefs" && user.my_camera_on === true && this.state.cameraNotDetected === false /*TP-5429*/ ) {
            flag = true;
        }
        return flag;
    }

    // show flash message MB2-473
    triggerMessage = (connData, status, leftPosition, topPosition) => {
        let flashLeft = leftPosition ? leftPosition : ''; //TP-1305
        let flashTop = topPosition ? topPosition : ''; //TP-1305
        let flashTimer = true; //TP-5681
        let message = "";
        let values = {};
        if(connData){
            switch (status) {
                case "connect":
                    //message = connData.first_name+" "+connData.last_name+" has joined the call";
                    message = "{name} has joined the call";
                    values = {name: connData.first_name+" "+connData.last_name}
                    break;
                case "disconnect":
                    //message = connData.first_name+" "+connData.last_name+" has left the call";
                    message = "{name} has left the call";
                    values = {name: connData.first_name+" "+connData.last_name}
                    break;
                case "yieldpositive":
                    //message = connData+" has left the call. Control remains with the same expert.";
                    if (connData) {
                        message = "{name} has left the call so control remains with the same expert."; //TP-1305
                        values = {name: connData, role: this.props.customerRoles.expert}
                        break;
                    } else
                        break;
                case "yieldnegative":
                    //message = connData+" has left the call";
                    message = "{name} has left the call";
                    values = {name: connData}
                    break;
                case "disableHand":
                    //message = connData+" has requested control for Max-div Icons already.";
                    message = "{name} has requested control for Max-div Icons already."
                    values = {name: connData};
                    break;
                case "offline":
                    //message = connData+" has gone offline.";//MB2-681,MB2-661
                    message = "{name} has gone offline";
                    values = {name: connData};
                    break;
                case "self-offline":
                    message = connData;//MB2-441
                    values = "";
                    break;
                case "web-storage":
                    message = "Not Support Web Storage";
                    values = "";
                    break;
                case "unknown-stream":
                    message = "Unknown stream with id {id} from user is encountered. Hence ignoring";
                    values = {id: connData}
                    break;
                case "camera-connect":
                    message = "New external camera has been connected.";     
                    values = "";               
                    break;
                case "camera-disconnect":
                    message = "External camera has been disconnected.";   
                    values = "";                 
                    break;
                case "stopsession":
                    message = "Remote Disconnection";      
                    values = "";                  
                    break;
                case "switch-to-grid":
                    message = "Switching to grid view";
                    values = "";     
                    break;
                case "switch-to-max":
                    message = "Switching to full screen mode {name}";
                    values = {name: connData};
                    break;
                case "expert-timeout":
                    message = "Ending call";
                    values = "";  
                    break;
                case "errorRecording":
                    message = "recording error";
                    break;
                /**TP-4013*/
                case "failedRecording":
                    message = "recording failed";
                    break;
                //TP-2474 -- Message to be shown for when Primary expert erases the canvas of the Passive expert
                case "eraseCanvas":
                    message = "Erase Passive canvas";
                    values = {name: connData};
                    break;
                /**TP-3551 */
                case "switch-to-preview":
                    message = "Switching to my camera preview mode";
                    break;
                case "switch-to-other-max":
                    message = "Switching to full screen mode {name}";
                    values = {name: connData};
                    break;
                case "only-experts-remaining":
                    message = "Waiting for Technician users to join the session";//TP-3893
                    values = {role: this.props.customerRoles.expert} //TZ-989
                    flashTimer = false; //TP-5352
                    break;
                case "you-are-muted":
                    //TP-5681
                    message = "session.mutedbuttalking";
                    flashTimer = false
                    break;
                case "screencast-started":
                    //TP-4908
                    message = "{name} has started Screencast";
                    values = {name: connData};
                    break;
                //TP-5442
                case "DevicesNotFoundError":
                    message = "DevicesNotFoundError";
                    break;
                default:
                    break;
            }    
            this.setState({ flashValues: values, flashMsgText: message, showFlashMessage: true, flashLeft, flashTop, flashTimer }, () =>{
                console.log(this.state.flashLeft, this.state.flashTop, this.state.flashTimer);
            });
        }
    }

    updateShowMessage = (val) => {
        if (val === false)
            this.setState({ flashMsgText: "", flashValues: "", showFlashMessage: val });
    }

    //TP-3423 -- onclick method to open the bubble element
    openBubble = (row) => {
        const allBubbleEle = document.querySelectorAll('[id^="bubble-"]');
        for(let ii=0; ii<allBubbleEle.length; ii++) {
            allBubbleEle[ii].classList.add("d-none");
        }
		const bubbleEle = document.getElementById("bubble-"+row.id);
		bubbleEle.classList.remove("d-none");
	}

    render(){
            return (
                <>
                    {this.props.children}
                </>
            )
    }
}

export default WebrtcSessionUser;