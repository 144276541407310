import React, { Fragment } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import CheckBox from '../CheckBox/CheckBox';
import './Groups.scss';
import _ from 'lodash';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import { Label, Input, FormGroup, Alert } from 'reactstrap';
import { printConsole } from '../helpers';

class EditGroup extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			bNeedPull: true,
			bSubmitted: false,
			bResponded: false,
			selecteduserid: '',
			errStrReturned: i18nMark('Processing Group change.'),
			retCode: 400,
			usersmap: [],
			groupsmap: [],
            selectedGroup: {},
            isLoading: false,
            initialFetch: true,
            showExpertWarning: false,
            // streaming_mode: 'routed'
		}
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: props.language,
			element: message => <div className="groups-form-validation-msg">{message.replace(/The|field/g, '').capitalize()}</div>,
		});
	}

	getAccountUsers = (inputValue) => {
        let accountid = this.props.accountid;
        if (!inputValue && !this.state.initialFetch) return;
        this.setState({isLoading: true, initialFetch: false});
        let fetchString = 'getUsersByAccountIdForDropdown/';
        fetchString += accountid; //by account id
        if (inputValue) {
            fetchString += `?text=${inputValue}`;
        }
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(users => {
            if(users !== undefined && users !== null){
                this.setState({users});
                const userNames = users.filter(e => (e.isadmin && e.isexpert) || !e.isadmin)
                this.setState({ usersmap: userNames, isLoading: false });
            }
        })
        .catch(err => {
            console.log(err.message)
        })
    }

    //TP-2892
    handleSelectChangeMobile = () => {
        let select = document.getElementById('users');
        const {usersmap} = this.state;
        let [newVal] = usersmap.filter(u => u.value === parseInt(select.options[select.selectedIndex].value));
        //console.log(newVal);
        this.handleSelectChange(newVal);
    }

	handleSelectChange = (selectedOption) => {
        this.setState(prev => ({
            selectedGroup: {
                ...prev.selectedGroup,
                users: selectedOption
            }
        }))
	}

	componentWillMount = () => {
		this.setState(prevState => ({ 
            selectedGroup: { 
                ...prevState.selectedGroup, 
                ...this.props.group
            }
        }));
        //this.delayedCallback = _.debounce(this.getAccountUsers, 500); TP-2913
		this.getAccountUsers('', true);
	}

    getNotCommonUsers = (users1, users2) => {
        
        const usersRemoved = users1.filter((u,i) => 
            !users2.includes(u)
        );
        //console.log(usersRemoved);
        const usersAdded = users2.filter((u,i) => !users1.includes(u));
        //console.log(usersAdded);
        const res1 = usersRemoved && usersRemoved.map (u => {
            return {
                userId: u.value,
                userAdded: false
            }
        });

        const res2 = usersAdded && usersAdded.map (u => {
            return {
                userId: u.value,
                userAdded: true
            }
        });
        const result = res1 && res2 && res1.concat(res2)|| res1 && res1 || res2 && res2; 
        printConsole(result);
        return result;
    }

	onEditGroup = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
        }
        const experts = this.state.selectedGroup.users.filter(user => user.isexpert);
        let showExpertWarning;
        if (parseInt(this.props.numberOfExperts) < experts.length) {
			showExpertWarning = true;
			this.setState({ showExpertWarning });
			return;
		} else {
			showExpertWarning = false;
		}
        //add the selected user to the selected group
        this.setState({bSubmitted: true});
        const { selectedGroup } = this.state;
        this.props.authService.fetch('updateGroup', {
            method: 'put',
            body: JSON.stringify(selectedGroup)
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                this.props.updateSuccess(selectedGroup);
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(data => {
            this.setState({bResponded: true});
            this.setState({bNeedPull: true});
            printConsole(this.props.group);
            printConsole(selectedGroup);
            const userData = this.getNotCommonUsers(this.props.group.users, selectedGroup.users);        
            const json = {
                data: userData
            };
            printConsole(json);
            userData && this.props.authService.socket.emit('group-user-change', { data: userData }); 
            if(data !== null && data !== undefined){
                this.setState({errStrReturned: i18nMark('Group updated successfully!')});
            }
        })
        .catch((error) => {
            this.setState({bResponded: true});
            // output error in login
            if(this.state.retCode === 409)
            {
                this.setState({errStrReturned: i18nMark('The selected user is already in the group specified.')});
            }
            else if(this.state.retCode === 404)
            {
                this.setState({errStrReturned: i18nMark('User could not be added to specified Group as this feature is not supported.')});
            }
            else
            {
                this.setState({errStrReturned: i18nMark('Failed to add the selected user to the Group.')});
            }
        })
	}

	onClickClose = (e) => {
        e.preventDefault();
		this.props.closeEditGroup();
	}

	onChangeForm = name => event => {
        event.persist();
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		this.setState(prevState => ({
			selectedGroup: {
				...prevState.selectedGroup,
				[name]: value
			}
		}));
	}

    //TP-2913
    searchUsernames = (newTextValue) => {
        const {users} = this.state;
        let userNames = [];
        if (newTextValue !== "") {
            let usersSearch = users.filter((usr)=> {
                return usr.label.toUpperCase().indexOf(newTextValue.toUpperCase()) > -1;
            });
            //console.log(usersSearch);            
            if (usersSearch.length > 0) {
                userNames = usersSearch.filter(e => (e.isadmin && e.isexpert) || !e.isadmin)
            }
        } else {
            userNames = users.filter(e => (e.isadmin && e.isexpert) || !e.isadmin)
        }
        //console.log(userNames);
        this.setState({ usersmap: userNames, isLoading: false });
    }

    //TP-2913
    onInputChange (newTextValue) {
        if(newTextValue.length===3 && newTextValue.trim() !=="")
        {
            return this.searchUsernames(newTextValue);
        } else if (newTextValue.trim() === "") {
            return this.searchUsernames(newTextValue);
        }
        return newTextValue;
    }

	render() {
		const { selectedGroup, usersmap, bSubmitted, bResponded, retCode, errStrReturned, showExpertWarning } = this.state;
        const { numberOfExperts } = this.props;
		if(bSubmitted || bResponded) {
			let colorText = '#485890';
			if(retCode !== 200) colorText = 'red';
			return(
				<div className='modale opened'>
					<div className='__modal-dialog'>
						<form>
							<div className="__modal-header">
								<h4 style={{color: colorText}}><Trans id={errStrReturned}/></h4>
							</div>
							<div className="__modal-footer flex-center">
								<button className="btn-green" onClick={(e) =>this.onClickClose(e)}><Trans id='OK'/></button>
							</div>
						</form>
					</div>
				</div>
			);
		} else {
			return(
				<div className='formmodule center'>
					<div className='dialog form-color width-min'>
	            		<form>
							<div className="formheader tabsColor flex-center row">
								<h2><Trans>Edit Group</Trans></h2>								
							</div>
							<div id="selectfromusers" className="formbody form-text-color">
								<label><Trans>Group Name</Trans>:
									<div className="input-wrapper">
                                        <I18n>
                                            {({ i18n }) => 
                                            <>
        										<input className='signup-input-field' name={i18n._(t`group_name`)} type="text" placeholder={i18n._(t`Enter the group name here`)} value={selectedGroup.group_name} onChange={this.onChangeForm('group_name')} />
                                                {this.validator.message(i18n._(t`group_name`), selectedGroup.group_name, 'required|alpha_num_space|max:40')}											
										    </>  
                                            }
                                        </I18n>
									</div>
								</label>
								<label><Trans>Description</Trans>:
									<div className="input-wrapper">
                                        <I18n>
                                            {({ i18n }) => 
                                            <>
	        									<input className='signup-input-field' name={i18n._(t`description`)} type="text" placeholder={i18n._(t`Enter the description`)} value={selectedGroup.description} onChange={this.onChangeForm('description')} />
                                                {this.validator.message(i18n._(t`description`), selectedGroup.description, 'required|alpha_num_dash_space')}									
										    </>
                                            }
                                        </I18n>
									</div>
								</label>
                                {
                                    this.props.isCustomizable && this.props.streaming_medium !== "onprem"  && !this.state.selectedGroup.is_onprem && <label><Trans>Streaming Mode</Trans>:
                                        <FormGroup className="mt-2 mb-2">
                                            <I18n>
                                                {({ i18n }) =>
                                                    <Fragment>
                                                        <Label className="ml-4">
                                                            <input type="radio" checked={this.state.selectedGroup.streaming_mode === 'routed'} value='routed' name="streaming_mode" onChange={this.onChangeForm('streaming_mode')}/>{' '}
                                                            <span id="routed"><Trans>Routed</Trans></span>
                                                        </Label>
                                                        <Label className="ml-5">
                                                            <input type="radio" value='relayed' checked={this.state.selectedGroup.streaming_mode === 'relayed'} name="streaming_mode" onChange={this.onChangeForm('streaming_mode')} />{' '}
                                                            <span id="relayed"><Trans>Relayed</Trans></span>
                                                        </Label>
                                                    </Fragment>
                                                }
                                            </I18n>
                                            <div className="stream-mode-desc mt-1 ml-3">
                                                { this.state.selectedGroup.streaming_mode === 'routed' ?
                                                    <Trans id="groups.routedTooltip" />
                                                    :
                                                    <Trans id="groups.relayedTooltip" />
                                                }
                                            </div>
                                        </FormGroup>
                                    </label>
                                }
                                <label><Trans>Users</Trans>:
                                    <div className="ml-3" style={{marginTop: 5, color: "black"}}>
                                        <I18n>
                                            {({ i18n }) => 
                                                <>
                                                    <Select
                                                        placeholder={i18n._(t`Select`)}
                                                        isLoading={this.state.isLoading}
                                                        // styles={styles}
                                                        components={makeAnimated()}
                                                        // isClearable={selectedGroup.users.length > 1 ? true : false}
                                                        value={selectedGroup.users}
                                                        onCloseResetsInput={selectedGroup.users.length > 1 ? true : false}
                                                        onChange={this.handleSelectChange}
                                                        options={usersmap}
                                                        onInputChange={this.onInputChange.bind(this)} /**TP-2913 */
                                                        maxMenuHeight={220} /**TP-2892 */
                                                        isMulti="true"
                                                        isSearchable={this.props.isMobileDevice === true ? false : true} /**TP-2892 */
                                                    />
                                                </>
                                            }
                                        </I18n>    
                                    </div>
                                    {
                                        showExpertWarning && <div style={{ display: 'block' }} className="invalid-feedback">
										    <Trans values={{role: this.props.customerRoles.expert}} id='You cannot add more than {numberOfExperts} expert(s) to the group.' values={{numberOfExperts:numberOfExperts}}/>
                                        </div> 
                                    }
                                </label>
								{/* RS: commented out code to remove On prem checkbox. Jira #T32-170
									this.props.streaming_medium === "both" ?
                                        <div className="ml-3">
                                            <label>
                                                <br/>
                                                <input className="checkbox" type="checkbox" name="is_onprem" checked={selectedGroup.is_onprem} onChange={this.onChangeForm('is_onprem')}></input>
                                                <Trans>Onprem</Trans>
                                            </label>
                                        </div>
                                    : null
                                */}
							</div>	
							<div className="flex-center" style={{marginTop: '10%'}}>
                                <button className="btn-red"  onClick={(e) =>this.onClickClose(e)}><Trans>Close</Trans></button>
								<button id='btnCloseEditGroup' className="margin10 btn-green" onClick={(e) => this.onEditGroup(e)}><Trans>Save</Trans></button>
							</div>		
						</form>
	            	</div>
	            </div>
            );
        }
	}
}

export default EditGroup;