import React, { Fragment } from 'react';
import './Adminform.scss';
import SimpleReactValidator from 'simple-react-validator';
import _ from 'lodash';
// import StripeCheckoutForm from '../StripeCheckoutForm/StripeCheckoutForm';
import Loader from 'react-loader-spinner';
import { Row, Col, Form, ModalBody, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import Button from '../Button/Button';
import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import { setupI18n } from "@lingui/core"
import { printConsole, encryptData } from '../helpers';

const i18n = setupI18n()

class Adminform extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			adminFirstName: '',	adminLastName: '', adminEmail: '', adminPhone:'', adminGeoLocation: '',	companyName: '', companyAddress: '', companyURL: '',
			companyTelephone: '', street_address: '', additional_street_address: '', zipcode: '', city: '', country: '',
			bFormSubmitted: false,
			bFormSubmissionSuccessful: false,
			sFormSubmission: 'Form is yet to be submitted!',
			bCompanyInfoPart: false,
			agreeTerms: false,
			bMyInfoPart: true,
			errStrReturned: '',
			bPayment: false,
			isEmailAlreadyRegistered: false,
			isLoading: false,
			planSelected: {
				name: ''
			},
            description: '',
            language:'',
            user_list:	[
						{email: "", first_name: "", last_name: "", phone_number: "", isexpert: true},
						{email: "", first_name: "", last_name: "", phone_number: "", isexpert: false}
					],
			showContactForm: false,
			checkingFreeEmail: false
		}
		// initialize validator
        this.validationRules(props.language)
	}

    componentDidUpdate = (prevProps, prevState) =>{
        if (prevState.language !== this.state.language) {
            this.validationRules(this.state.language);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.language!==prevState.language){
           
          return {language : nextProps.language};
        }
        else return null;
    }
    
    validationRules = (language) => {
        SimpleReactValidator.addLocale(language, require(`../../locales/${language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: language,
			element: message => {
				if(message.includes("street address 1")){
					return <div className="admin-form-validation-msg">{message.replace(/admin|field|The/g, '').replace('u r l', 'url').capitalize()}</div>
				}else{
					return <div className="admin-form-validation-msg">{message.replace(/admin|field|The|[0-9]/g, '').replace('u r l', 'url').capitalize()}</div>
				}
			},
			validators: {
				emailValidate: {  // name the rule
					message: 'Please enter a valid email address',
					rule: (val, params, validator) => {
						const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return validator.helpers.testRegex(
							String(val).toLowerCase(),
							regex
						);
					},
					required: true  // optional
                },
				phone: {
						message: 'Enter a valid phone number',
						rule: (val,params,validator) => {
							let telRegex= /^[0-9\)\(+-\s]+$/g;
							return (validator.helpers.testRegex(val,telRegex));
					}
				},
				validAddress: {
						message: 'Enter a valid address',
						rule: (val,params,validator) => {
							let testRegex= /^[a-zA-Z0-9\s,'-]*$/;
							return validator.helpers.testRegex(
								String(val).toLowerCase(),
								testRegex
							);
					}
				},
				validStreetAddress: {
					rule: (val,params,validator) => {
						let testRegex= /^[a-zA-Z0-9\s,#-]*$/;
						return validator.helpers.testRegex(
							String(val).toLowerCase(),
							testRegex
						);
					}
				},				
				confirmEmail: {
					rule: (val, params, validator) => {
						return val === this.state.user_list[params[0]]['email'] ? true : false; 
					},
					required: true  // optional	
				}
			},
		});
    }
    
    onChangeForm = name => (event) => {
		// if(event.target.name === 'adminFirstName')
		// 	this.setState({adminFirstName: event.target.value})
		// if(event.target.name === 'adminLastName')
		// 	this.setState({adminLastName: event.target.value})
		// if(event.target.name === 'adminEmail') {
		// 	this.delayedCallback(event.target.value);
		// 	this.setState({adminEmail: event.target.value})
		// }
		// if(event.target.name === 'adminPhone')
		// 	this.setState({adminPhone: event.target.value})
		// if(event.target.name === 'companyName')
		// 	this.setState({companyName: event.target.value})
		// if(event.target.name === 'companyAddress')
		// 	this.setState({companyAddress: event.target.value})
		// if(event.target.name === 'companyURL')
		// 	this.setState({companyURL: event.target.value})
		// if(event.target.name === 'companyTelephone')
		// 	this.setState({companyTelephone: event.target.value})
		// if(event.target.name === 'agreeTerms')
		// 	this.setState({agreeTerms: event.target.checked})
		// if(event.target.name === 'description')
        // 	this.setState({description: event.target.value})
        
        if(name === 'adminFirstName')
        this.setState({adminFirstName: event.target.value})
        if(name === 'adminLastName')
            this.setState({adminLastName: event.target.value})
        if(name === 'adminEmail') {
            this.delayedCallback(event.target.value,event.target.name);
            this.setState({adminEmail: event.target.value})
        }
        if(name === 'adminPhone')
            this.setState({adminPhone: event.target.value})
        if(name === 'companyName')
            this.setState({companyName: event.target.value})
        if(name === 'companyAddress')
            this.setState({companyAddress: event.target.value})
        if(name === 'companyURL')
            this.setState({companyURL: event.target.value})
        if(name === 'companyTelephone')
            this.setState({companyTelephone: event.target.value})
        if(name === 'agreeTerms')
            this.setState({agreeTerms: event.target.checked})
        if(name === 'description')
            this.setState({description: event.target.value})
	}

	onSetGeoLocation = () =>{
		this.setState({signInGeoLocation: '12.9716° N : 77.5946° E'});
	}

	onBackToPersonalInfo = (e) => {
		e.preventDefault();
		this.setState({bCompanyInfoPart: false, bMyInfoPart: true});
    }
    
    onBackToCompanyInfo = (e) => {
		e.preventDefault();
		this.setState({bCompanyInfoPart: true, bPayment: false});
	}

	onBackToContactForm = (e) => {
		e.preventDefault();
		this.setState({bCompanyInfoPart: false, bMyInfoPart: false, showContactForm: true});
    }

	onBackToUserForm = (e) => {
		e.preventDefault();
		this.setState({bCompanyInfoPart: false, bMyInfoPart: true, showContactForm: false});
    }

	onClickNext = (validatorArray, e) => {
        e.preventDefault();
		const fieldsToValidate = [
            ...validatorArray,
			!this.state.isEmailAlreadyRegistered
		];
        const isValid = _.every(fieldsToValidate, Boolean);
		if (!isValid) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
		this.setState({bCompanyInfoPart: true, bMyInfoPart: false});
	}

	onGoToPayment(e) {
		e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
		this.setState({
			bCompanyInfoPart: false,
			bPayment: true
		});		
	}

	getSubscriptionid = () => {
		const today = Math.floor(Date.now() / 1000);
		const subid = this.state.companyName.split(' ')[0] + this.state.planSelected.name.replace(/' '/g, '') + today;
		return subid.toLocaleLowerCase();
	}
	
	onClickClose = (e) => {
		e.preventDefault();
		this.props.closeAdminPopup();
	}

	onSubmitRegistrationDetails = (pResponse) => {
		if (!pResponse || pResponse.error) {
			return;
		}
		let retCode = 400;
		this.getSubscriptionid()
		this.setState({bFormSubmitted: true});
		this.onSetGeoLocation();
		this.setState({errStrReturned: i18nMark('Processing. Please wait for confirmation.')});

		fetch(window._env_.REACT_APP_API_URL + '/registeraccount', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				firstname: this.state.adminFirstName,
				lastname: this.state.adminLastName, 
				email: this.state.adminEmail.toLowerCase(),
				phonenumber: this.state.adminPhone,
				geolocation: this.state.adminGeoLocation,
				companyname: this.state.companyName,
				companyaddress: this.state.companyAddress,
				companyurl: this.state.companyURL,
				companyphone: this.state.companyTelephone,
				paymentToken: pResponse.token.id,
                planId: this.state.planSelected.id,
                quantity: pResponse.licensesCount
			})
		})
		.then(response => {
			this.setState({ retCode: response.status });
			if(response.status === 200){
				return response.json();
			} else{
				this.setState({bFormSubmissionSuccessful: false});
				throw new Error(response.statusText);
			}
		})
		.then(data => {
			if(data !== null && data !== undefined){
				this.setState({bFormSubmissionSuccessful: true});
				this.setState({errStrReturned: i18nMark('Your account activation request was successfully processed. Please follow the instructions received in your email to complete registration.')});
			}
		})
		.catch((error) => {
			// output error in login
			if(retCode === 409) {
				//this.setState({bMyInfoPart: true});
				this.setState({errStrReturned: i18nMark('Duplicate Error: You are already registered on the Telepresenz Platform. Please sign in by navigating to the log in page.')});
			} else {
				//this.setState({bCompanyInfoPart: true});
				this.setState({errStrReturned: i18nMark('Registration Failed: Please double check your registration details.')});
			}
		});
	}
	
	componentDidMount = () => {
		this.delayedCallback = _.debounce(this.checkEmailExists, 500);
		this.delayedCallbackForRetailEmail = _.debounce(this.checkEmailExistsForFreePlan, 500);
		if (this.props.planSelected) {
			this.setState({planSelected: this.props.planSelected});
			return;
		}
		this.getPlanByCode();
	}

	getPlanByCode = () => {
		if (this.props.planCode === 'plan_enterprise' || this.props.planCode === 'plan_free') return;
		this.setState({ isLoading: true });
		const { planCode='plan_basic' } = this.props;
        fetch(window._env_.REACT_APP_API_URL+'/plans/' + planCode, {
			method: 'GET',
			headers: {'Content-Type': 'application/json'}
		})
		.then(response => {
			if(response.status === 200) {
				return response.json();
			} else {
				this.setState({
					retCode: response.status
				});
				throw new Error(response.statusText);
			}
		})
		.then(plan => {
			this.setState({ isLoading: false });
			if (plan) {
				this.setState({
					planSelected: plan
				});
			}
		})
		.catch((error) => {
			printConsole(error);
			this.setState({ isLoading: false, bFormSubmitted: true, errStrReturned: i18nMark('Plan not found, Please verify the plan code you entered.')});
		})
	}
    
    checkEmailExists = (email,emailField) => {
		if (!this.validator.fieldValid(emailField))  {
			this.setState({ isEmailAlreadyRegistered: false });
			return
		};
        fetch(window._env_.REACT_APP_API_URL+'/checkEmailExists/' + email + '?type=standard', {
			method: 'GET',
			headers: {'Content-Type': 'application/json'}
		})
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			this.setState({
				isEmailAlreadyRegistered: isExists
			});
		})
		.catch((error) => {
			printConsole(error);
		})
	}

	showLoader = () => {
        if (!this.state.isLoading) return '';
        return (
            <div className="loader">
                <Loader
                    type="ThreeDots"
                    color="#23c97d"
                    height="70"	
                    width="70"
                />
            </div>
        )
	}

	onSubmitEnterpriseContactForm = (e) => {
		e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
		this.setState({bFormSubmitted: true});
		this.setState({errStrReturned: i18nMark('Processing. Please wait for confirmation.')});
		fetch(window._env_.REACT_APP_API_URL + '/submitEnterpriseRequest', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				first_name: this.state.adminFirstName,
				last_name: this.state.adminLastName, 
				email: this.state.adminEmail.toLowerCase(),
				phone_number: this.state.adminPhone,
				company_name: this.state.companyName,
				website: this.state.companyURL,
                description: this.state.description
			})
		})
		.then(response => {
			this.setState({ retCode: response.status });
			if(response.status === 200){
				return response.json();
			} else{
				this.setState({bFormSubmissionSuccessful: false});
				throw new Error(response.statusText);
			}
		})
		.then(data => {
			if(data !== null && data !== undefined){
				this.setState({bFormSubmissionSuccessful: true});
				this.setState({errStrReturned: i18nMark('Your enterprise account request was successfully sent')});
			}
		})
		.catch((error) => {
			//this.setState({bCompanyInfoPart: true});
			this.setState({errStrReturned: i18nMark('Submission failed')});
		});
	}

	// T32-404
    checkEmailExistsForFreePlan = (email, emailField, index) => {
		const user_list = [...this.state.user_list]; 
		//cannot set the isEmailFree flag here or else below condition in isExists block will fail
		if(!this.validator.fieldValid(emailField)){
			user_list[index]['isEmailAlreadyRegistered'] = false;user_list[index]['isEmailFree'] = false;// any one of the message will show
			this.setState({ user_list });
			return;
		};
		// cannot be same for both the user
		if(user_list[0]['email'] === user_list[1]['email']){
			user_list[index]['isEmailAlreadyRegistered'] = true;user_list[index]['isEmailFree'] = false;// any one of the message will show
			this.setState({ user_list });
			return;
		}
		//TP-1257
		fetch(window._env_.REACT_APP_API_URL+'/checkEmailExists/' + email, {
			method: 'GET',
			headers: {'Content-Type': 'application/json'}
		})
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			if(!isExists && user_list[index]['isEmailFree']){
				user_list[index]['isEmailFree'] = true;// any one of the message will show TP-1541
			}
			user_list[index]['isEmailAlreadyRegistered'] = isExists;
			this.setState({ user_list });
		})
		.catch((error) => {
			printConsole(error);
		})
	}

	//TP-702
	checkFreeEmail =  (fieldName) => ({ target }) => {
		let email = encryptData(target.value), emailField = target.name, index = target.dataset.id;
		const user_list = [...this.state.user_list]; 
		if(!this.validator.fieldValid(emailField)){
			user_list[index]['isEmailFree'] = false;
			this.setState({ user_list });
			return;
		};
		this.setState({checkingFreeEmail: true});
		fetch(window._env_.REACT_APP_API_URL+'/checkValidEmail', {
			method: 'POST',	headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({email})
		})
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then((isNotValid) => {
			if(isNotValid){
				user_list[index]['isEmailFree'] = true;	user_list[index]['isEmailAlreadyRegistered'] = false;// any one of the message will show
				this.setState({ user_list });
			}
			this.setState({ checkingFreeEmail: false});
		})
		.catch((error) => {
			this.setState({checkingFreeEmail: false})
			printConsole(error);
		})
	}

	// T32-404
	onChangeFreePlanForm = (fieldName) => ({ target }) => {
		const user_list = [...this.state.user_list];

		if(fieldName === 'email') {
            this.delayedCallbackForRetailEmail(target.value, target.name, target.dataset.id);// check for uniqueness
        }

		user_list[target.dataset.id][fieldName] = target.value;

		this.setState({ user_list })
	}

    onChangeContactForm = name => ({target}) => {
		this.setState({[name]: target.value})
	}

	// T32-404
	onClickFreePlanNext = (e) => {
		e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
		}
		this.setState({bCompanyInfoPart: true, showContactForm: false, bMyInfoPart: false});
	}

	//TP-1456
	onClickFreePlanContactForm = (e, i18n) => {
		e.preventDefault();
		let user_flag = [];
		this.state.user_list.forEach((user, index) => {
			user_flag.push(_.every([this.validator.fieldValid(i18n._(t`user_email`) + index), this.validator.fieldValid(i18n._(t`confirm_email`) + index), this.validator.fieldValid(i18n._(t`first_name`) + index),
			this.validator.fieldValid(i18n._(t`last_name`) + index), this.validator.fieldValid(i18n._(t`phone number`) + index)], Boolean))
		})
		if (!user_flag[0] || !user_flag[1] || this.state.user_list[0].isEmailAlreadyRegistered || this.state.user_list[1].isEmailAlreadyRegistered
			|| this.state.user_list[0].isEmailFree || this.state.user_list[1].isEmailFree) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
		}
		this.setState({showContactForm: true, bMyInfoPart: false});
	}

	// T32-404
	onSubmitFreePlanUser = (e) => {
		e.preventDefault();
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
		}
		const user_list = [...this.state.user_list];
		if(user_list[0]['first_name'] === user_list[1]['first_name']){
			user_list[0]['first_name'] = user_list[0]['first_name'] + ' Expert';
			user_list[1]['first_name'] = user_list[1]['first_name'] + ' Technician';
		}
		this.setState({bFormSubmitted: true, errStrReturned: i18nMark('Processing. Please wait for confirmation.')});
		fetch(window._env_.REACT_APP_API_URL + '/retail/onboarding', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({user_list: user_list,	companyName: this.state.companyName, street_address: this.state.street_address,
				additional_street_address: this.state.additional_street_address, zipcode: this.state.zipcode,
				city: this.state.city,	country: this.state.country
			})
		})
		.then(response => {
			this.setState({ retCode: response.status });
			if(response.status === 200){
				return response.json();
			} else{
				this.setState({bFormSubmissionSuccessful: false});
				throw new Error(response.statusText);
			}
		})
		.then(data => {
			if(data !== null && data !== undefined){
				this.setState({bFormSubmissionSuccessful: true});
				this.setState({errStrReturned: i18nMark('Free account submission success message')});
			}
		})
		.catch((error) => {
			//this.setState({bCompanyInfoPart: true});
			printConsole(error)
			this.setState({errStrReturned: i18nMark('Submission failed')});
		});		
	}

	enterpriseContactFormRender = () => {
		return (
			<Row>
                <Col xs="12" sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
					<Modal isOpen={true} className="modal-trial-register" contentClassName="signup-modal-content">
						<ModalHeader className="text-center white signup-modal-header" toggle={this.onClickClose}><h1><Trans>Contact Us</Trans></h1></ModalHeader>
						<ModalBody>
							<Fragment>
								<Form>
									<div key="first_name" className="input-wrapper margin-bottom-10">
										<I18n>
                                        { ({ i18n }) =>
                                            <>
                                                <input className='signup-input-field' name={i18n._(t`adminFirstName`)} type="text" placeholder={i18n._(t`First Name`) + '*'} value={this.state.adminFirstName} onChange={this.onChangeForm('adminFirstName')} required/>
                                                {this.validator.message(i18n._(t`adminFirstName`), this.state.adminFirstName, 'required|alpha_num_space')}
                                            </>		
										}
										</I18n>
									</div>
									<div key="last_name" className="input-wrapper margin-bottom-10">
										<I18n>
                                        { ({ i18n }) =>
                                            <>
                                                <input className='signup-input-field' name={i18n._(t`adminLastName`)} type="text" placeholder={i18n._(t`Last Name`) + '*'} value={this.state.adminLastName} onChange={this.onChangeForm('adminLastName')} required/>
                                                {this.validator.message(i18n._(t`adminLastName`), this.state.adminLastName, 'required|alpha_num_space')}
                                            </>
										}
										</I18n>
									</div>
									<div key="email" className="input-wrapper margin-bottom-10">
										<I18n>
                                        { ({ i18n }) =>
                                        <>
											<input className='signup-input-field'
											name={i18n._(t`adminEmail`)} type="email"
											placeholder={i18n._(t`Email`) + '*'} value={this.state.adminEmail} onChange={this.onChangeForm('adminEmail')} required/>
                                            {this.validator.message(i18n._(t`adminEmail`), this.state.adminEmail, 'required|emailValidate')}	
                                        </>	
										}</I18n>
									</div>
									<div key="company_name" className="input-wrapper margin-bottom-10">
										<I18n>
                                        { ({ i18n }) =>
                                        <>
											<input className='signup-input-field' name={i18n._(t`companyName`)} type="text" placeholder={i18n._(t`Company Name`) + '*'} value={this.state.companyName} onChange={this.onChangeForm('companyName')} />
                                            {this.validator.message(i18n._(t`companyName`), this.state.companyName, 'required|alpha_num_dash_space')}
                                        </>
										}</I18n>
									</div>
									<div key="admin_phone" className="input-wrapper margin-bottom-10">
										<I18n>
                                        { ({ i18n }) =>
                                        <>
											<input className='signup-input-field margin-bottom-0' name={i18n._(t`adminPhone`)} type="text" placeholder={i18n._(t`Phone Number`) + '*'} value={this.state.adminPhone} onChange={this.onChangeForm('adminPhone')} />
									        {this.validator.message(i18n._(t`adminPhone`), this.state.adminPhone, 'required|phone')}		
                                        </>
										}</I18n>
									</div>
									<div key="website" className="input-wrapper margin-bottom-10">
										<I18n>
                                        { ({ i18n }) =>
                                        <>
											<input className='signup-input-field' name={i18n._(t`companyURL`)} type="text" placeholder={i18n._(t`Website`) + '*'} value={this.state.companyURL} onChange={this.onChangeForm('companyURL')}/>
                                            {this.validator.message(i18n._(t`companyURL`), this.state.companyURL, 'required')}
                                        </>
										}</I18n>
									</div>
									<div key="description" className="input-wrapper margin-bottom-10">
										<I18n>
										{ ({ i18n }) =>
											<>
												<textarea className='signup-input-field' style={{'height':'60px', resize: 'none'}} name={i18n._(t`description`)} type="text" placeholder={i18n._(t`Tell us more about your interest`)} value={this.state.description} onChange={this.onChangeForm('description')}/>
												{ this.validator.message(i18n._(t`description`), this.state.description, 'alpha_num_dash_space') }
											</>
										}
										</I18n>
									</div>
								</Form>
							</Fragment>
						</ModalBody>
						<ModalFooter className="signup-modal-header">
							<div className="d-flex justify-content-center btn-trial-register">
								<Button 
									className="medium-size-button"
									showSpinner={this.state.bFormSubmitted} 
									onClick={this.onSubmitEnterpriseContactForm}>
									<Trans>Submit</Trans>
								</Button>
							</div>
						</ModalFooter>
					</Modal>
				</Col>
			</Row>
		);
	}
	
	// T32-404
	freePlanForm = () => {
		return (
				<div className='formmodule'>
				<div className='signup-dialog' style={{height:"690px"}}>
					<form>
						<div className="formheader row no-gutters">
							<span className="col-2">
								<img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} alt='logo' />
							</span>
							<span className="col-9">
								<h1 className="text-center white"><Trans>Free Plan Sign Up</Trans></h1>
								<div className="row no-gutters" >
									<h3 className="col-12 text-center white"><Trans>free plan signup thanks note</Trans></h3>
									<h3 className="col-12 text-left white"><Trans>free plan signup note</Trans></h3>
								</div>
							</span>
							<span className="col-1"></span>
						</div>
						<br/>		
						<div className="formbody row">
							<div className="column">
								<h2 className="white"><label><Trans values={{value: 1}}>User Details</Trans> (<Trans>Expert</Trans>):</label></h2>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`first_name`) + 0} data-id={0} type="text" placeholder={i18n._(t`Enter User 1 first name`)} value={this.state.user_list[0].first_name} onChange={this.onChangeFreePlanForm('first_name')}/>
													{this.validator.message(i18n._(t`first_name`) + 0, this.state.user_list[0].first_name, 'required|alpha_num_space')}	
												</>	
											}
										</I18n>
									</div>	
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`last_name`) + 0} data-id={0} type="text" placeholder={i18n._(t`Enter User 1 last name`)} value={this.state.user_list[0].last_name} onChange={this.onChangeFreePlanForm('last_name')}/>
													{this.validator.message(i18n._(t`last_name`) + 0, this.state.user_list[0].last_name, 'required|alpha_num_space')}
												</>
											}
										</I18n>
									</div>
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
											<>
												<input className='signup-input-field'
												name={i18n._(t`user_email`) + 0} type="email"
												placeholder={i18n._(t`Enter a unique and valid email id`)} value={this.state.user_list[0].email} data-id={0} onChange={this.onChangeFreePlanForm('email')} onBlur={this.checkFreeEmail('email')}/>
												{this.validator.message(i18n._(t`user_email`) + 0, this.state.user_list[0].email, 'required|emailValidate')}		
												{ this.state.user_list[0].isEmailAlreadyRegistered ? <div className="admin-form-validation-msg"><Trans>This Email is already in use.</Trans></div> : ''  }
												{ this.state.user_list[0].isEmailFree ? <div className="admin-form-validation-msg"><Trans>free email</Trans></div> : ''  }
											</>
											}
										</I18n>
									</div>
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
											<>
												<input className='signup-input-field' name={i18n._(t`confirm_email`) + 0} type="email" placeholder={i18n._(t`confirm email`)} 
												value={this.state.user_list[0].confirm_email} data-id={0} onChange={this.onChangeFreePlanForm('confirm_email')}/>
												{this.validator.message(i18n._(t`confirm_email`) + 0, this.state.user_list[0].confirm_email, 'confirmEmail:0')}		
											</>
											}
										</I18n>
									</div>
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
											<>
												<input className='signup-input-field margin-bottom-0' name={i18n._(t`phone number`) + 0} data-id={0} type="text" placeholder={i18n._(t`Enter a valid phone number`)} value={this.state.user_list[0].phone_number} onChange={this.onChangeFreePlanForm('phone_number')}/>
												{this.validator.message(i18n._(t`phone number`) + 0, this.state.user_list[0].phone_number, 'required|phone')}	
											</>	
											}
										</I18n>
									</div>
								</label>
							</div>
							<div className="column">
								<h2 className="white"><label><Trans values={{value: 2}}>User Details</Trans> (<Trans>Field User</Trans>):</label></h2>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`first_name`) + 1} data-id={1} type="text" placeholder={i18n._(t`Enter User 2 first name`)} value={this.state.user_list[1].first_name} onChange={this.onChangeFreePlanForm('first_name')}/>
													{this.validator.message(i18n._(t`first_name`) + 1, this.state.user_list[1].first_name, 'required|alpha_num_space')}	
												</>	
											}
										</I18n>
									</div>	
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`last_name`) + 1} data-id={1} type="text" placeholder={i18n._(t`Enter User 2 last name`)} value={this.state.user_list[1].last_name} onChange={this.onChangeFreePlanForm('last_name')}/>
													{this.validator.message(i18n._(t`last_name`) + 1, this.state.user_list[1].last_name, 'required|alpha_num_space')}
												</>
											}
										</I18n>
									</div>
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
											<>
												<input className='signup-input-field'
												name={i18n._(t`user_email`) + 1} type="email"
												placeholder={i18n._(t`Enter a unique and valid email id`)} value={this.state.user_list[1].email} data-id={1} onChange={this.onChangeFreePlanForm('email')} onBlur={this.checkFreeEmail('email')}/>
												{this.validator.message(i18n._(t`user_email`) + 1, this.state.user_list[1].email, 'required|emailValidate')}		
												{ this.state.user_list[1].isEmailAlreadyRegistered ? <div className="admin-form-validation-msg"><Trans>This Email is already in use.</Trans></div> : '' }
												{ this.state.user_list[1].isEmailFree ? <div className="admin-form-validation-msg"><Trans>free email</Trans></div> : ''  }
											</>
											}
										</I18n>
									</div>
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
											<>
												<input className='signup-input-field' name={i18n._(t`confirm_email`) + 1} type="email" placeholder={i18n._(t`confirm email`)} 
												value={this.state.user_list[1].confirm_email} data-id={1} onChange={this.onChangeFreePlanForm('confirm_email')}/>
												{this.validator.message(i18n._(t`confirm_email`) + 1, this.state.user_list[1].confirm_email, 'confirmEmail:1')}		
											</>
											}
										</I18n>
									</div>
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
											<>
												<input className='signup-input-field margin-bottom-0' name={i18n._(t`phone number`) + 1} data-id={1} type="text" placeholder={i18n._(t`Enter a valid phone number`)} value={this.state.user_list[1].phone_number} onChange={this.onChangeFreePlanForm('phone_number')}/>
												{this.validator.message(i18n._(t`phone number`) + 1, this.state.user_list[1].phone_number, 'required|phone')}	
											</>	
											}
										</I18n>
									</div>
								</label>
							</div>
						</div>
						<div className="footer">
							<div className='row mt-3'>
								<div className='col-1'></div>
								<div className='col-10'>
									<I18n>
										{ ({ i18n }) =>
											<button type="button" className="btn-green center medium-size-button" onClick={(e) => this.onClickFreePlanContactForm(e, i18n)} disabled={this.state.checkingFreeEmail}>
												{this.state.checkingFreeEmail?  <><Trans>Validating Email</Trans>....</> : <Trans>Next</Trans>}
											</button>
										}
									</I18n>
								</div>
								<div className="col-1 text-center mt-2 white"><h3>1/3</h3></div>
							</div>
						</div>
					</form>
				</div>
			</div>
		)
	}

	// TP-1456
	freePlanContactForm = () => {
		return (
				<div className='formmodule'>
				<div className='signup-dialog' style={{height:"485px"}}>
					<form>
						<div className="formheader row no-gutters">
							<span className="col-2">
								<img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} alt='logo'/>
							</span>
							<span className="col-9">
								<h1 className="text-center white"><Trans>Free Plan Sign Up</Trans></h1>
								<div className="row no-gutters" >
									<h3 className="col-12 text-center white"><Trans>free plan signup thanks note</Trans></h3>
									<h3 className="col-12 text-center white"><Trans>Business Details</Trans></h3>
								</div>
							</span>
							<span className="col-1"></span>
						</div>
						<br/>		
						<div className="formbody row">
							<div className="column">
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`companyName`)} type="text" placeholder={i18n._(t`Enter Company name`)} value={this.state.companyName} onChange={this.onChangeContactForm('companyName')}/>
													{this.validator.message(i18n._(t`companyName`), this.state.companyName, 'required|alpha_num_dash_space')}		
												</>
											}
										</I18n>
									</div>
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`street_address`)} type="text" placeholder={i18n._(t`Enter Street Address 1`)} value={this.state.street_address} onChange={this.onChangeContactForm('street_address')}/>
													{this.validator.message(i18n._(t`street_address_1`), this.state.street_address, 'required|validStreetAddress')}	
												</>	
											}
										</I18n>
									</div>	
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`additional_street_address`)} type="text" placeholder={i18n._(t`Enter Street Address 2 (optional)`)} value={this.state.additional_street_address} onChange={this.onChangeContactForm('additional_street_address')}/>
													{this.validator.message(i18n._(t`street_address_2`), this.state.additional_street_address, 'validStreetAddress')}		
												</>
											}
										</I18n>
									</div>
								</label>
							</div>
							<div className="column">
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`city`)} type="text" placeholder={i18n._(t`Enter City`)} value={this.state.city} onChange={this.onChangeContactForm('city')}/>
													{this.validator.message(i18n._(t`city`), this.state.city, 'required|alpha_space')}		
												</>
											}
										</I18n>
									</div>
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`zipcode`)} type="text" placeholder={i18n._(t`Enter zip (pin) code`)} value={this.state.zipcode} onChange={this.onChangeContactForm('zipcode')}/>
													{this.validator.message(i18n._(t`zipcode`), this.state.zipcode, 'required|alpha_num_dash')}		
												</>
											}
										</I18n>
									</div>
								</label>
								<label>
									<div className="input-wrapper">
										<I18n>
											{ ({ i18n }) =>
												<>
													<input className='signup-input-field' name={i18n._(t`country`)} type="text" placeholder={i18n._(t`Enter Country`)} value={this.state.country} onChange={this.onChangeContactForm('country')}/>
													{this.validator.message(i18n._(t`country`), this.state.country, 'required|alpha_space')}		
												</>
											}
										</I18n>
									</div>
								</label>
							</div>
						</div>
						<div className="footer">
							<div className='row mt-3'>
								<div className='col-1'></div>
								<div className='col-10 flex-center'>
									<button type="button" className="btn-blue medium-size-button" onClick={(e) => this.onBackToPersonalInfo(e)}><Trans>Back</Trans></button> 
									<button type="button" className="btn-green margin10 medium-size-button" onClick={(e) => this.onClickFreePlanNext(e)}><Trans>Next</Trans></button>
								</div>
								<div className="col-1 text-center mt-2 white"><h3>2/3</h3></div>
							</div>
						</div>
					</form>
				</div>
			</div>
		)
	}

	render = () => {
		if (this.props.planCode === 'plan_enterprise' && !this.state.errStrReturned) {
			return this.enterpriseContactFormRender();
		}
		// T32-404
		if (this.props.planCode === 'plan_free' && !this.state.errStrReturned && !this.state.bCompanyInfoPart && this.state.bMyInfoPart ) {
			return this.freePlanForm();//load the free user form and the t&c form after form is submitted
		}
		// TP-1456
		if (this.props.planCode === 'plan_free' && !this.state.errStrReturned && this.state.showContactForm && !this.state.bMyInfoPart ) {
			return this.freePlanContactForm();//load the free user contact form
		}
		if (this.state.isLoading) {
			return (
				<div className="loader">
					<Loader
						type="ThreeDots"
						color="#23c97d"
						height="70"	
						width="70"
					/>
				</div>
			)
		}
		const sPlanText = <Trans id="signup.thanksForSignup" values={{name: this.state.planSelected.name}} />;
		// sPlanText += this.state.planSelected.name;
		// sPlanText += ' plan.';
		if(this.state.bFormSubmitted || this.state.bFormSubmissionSuccessful){
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
				<div className="modale opened">
					<div className="__modal-dialog">
						<form>
							<div className='__modal-header'>
								<h4 style={{color: colorText}}><Trans id={this.state.errStrReturned} /></h4>
							</div>
							<div className="__modal-footer flex-center">
								<button className="btn-green medium-size-button" onClick={this.onClickClose}><Trans>Close</Trans></button>
							</div>
						</form>
					</div>
				</div>
			);
		} else if(!this.state.bFormSubmitted && !this.state.bCompanyInfoPart && this.state.bMyInfoPart){
			return(
				<div className='formmodule'>
					<div className='signup-dialog'>
	            		<form>
							<div className="formheader row no-gutters">
								<span className="col-2"><img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} alt='logo'/></span>
								<span className="col-8">
									<h1 className="text-center white"><Trans>Sign Up</Trans></h1>
									<span className="row" >
										<h3 className="col-12 text-center white">{sPlanText}</h3>
									</span>
								</span>
								<span className="col-2"></span>
							</div>
							<div className='row'>
								<div className='col-1'>&nbsp;</div>
								<div className="admin-form-validation-msg text-center col-10">{ this.state.isEmailAlreadyRegistered ? <Trans>You are already registered based on the email you provided below.</Trans>: '' }</div> 
								<div className='col-1'>&nbsp;</div>
							</div>
							<div className="formbody row">
								<div className="column">
										<label className="white"><Trans>First Name</Trans>:
											<div className="input-wrapper">
												<I18n>
		                                        { ({ i18n }) =>
		                                        <>
													<input className='signup-input-field' name={i18n._(t`adminFirstName`)} type="text" placeholder={i18n._(t`Enter your first name`)} value={this.state.adminFirstName} onChange={this.onChangeForm('adminFirstName')} required/>
		                                            {this.validator.message(i18n._(t`adminFirstName`), this.state.adminFirstName, 'required|alpha_num_space')}	
		                                        </>	
												}
												</I18n>
											</div>	
										</label>
										<label className="white"><Trans>Last Name</Trans>:
											<div className="input-wrapper">
												<I18n>
		                                            { ({ i18n }) =>
		                                            <>
														<input className='signup-input-field' name={i18n._(t`adminLastName`)} type="text" placeholder={i18n._(t`Enter your last name`)} value={this.state.adminLastName} onChange={this.onChangeForm('adminLastName')} required/>
		                                                {this.validator.message(i18n._(t`adminLastName`), this.state.adminLastName, 'required|alpha_num_space')}
		                                            </>
													}
												</I18n>
											</div>
										</label>
										<label className="white"><Trans>Email</Trans>:
											<div className="input-wrapper">
												<I18n>
		                                            { ({ i18n }) =>
		                                            <>
														<input className='signup-input-field'
														name={i18n._(t`adminEmail`)} type="email"
														placeholder={i18n._(t`Work email address`)} value={this.state.adminEmail} onChange={this.onChangeForm('adminEmail')} required/>
												        {this.validator.message(i18n._(t`adminEmail`), this.state.adminEmail, 'required|emailValidate')}		
		                                            </>
													}
												</I18n>
											</div>
										</label>
										<label className="white"><Trans>Telephone</Trans>:
											<div className="input-wrapper">
												<I18n>
		                                            { ({ i18n }) =>
		                                            <>
														<input className='signup-input-field margin-bottom-0' name={i18n._(t`adminPhone`)} type="text" placeholder={i18n._(t`Enter your mobile phone number`)} value={this.state.adminPhone} onChange={this.onChangeForm('adminPhone')} required/>
												        {this.validator.message(i18n._(t`adminPhone`), this.state.adminPhone, 'required|phone')}	
		                                            </>	
													}
												</I18n>
											</div>
										</label>
										{/* <AgreeTerms value={this.state.agreeTerms} onChange={this.onChangeForm.bind(this)} /> */}
										<div id="ShowErrorRegisterAccount" style={{color:'red'}}><Trans id={this.state.errStrReturned} /></div>
								</div>
						   		<div className="column">
										<label className="white"><Trans>Name</Trans>:
											<div className="input-wrapper">
												<I18n>
		                                        { ({ i18n }) =>
		                                        <>
													<input className='signup-input-field' name={i18n._(t`companyName`)} type="text" placeholder={i18n._(t`Enter Company name`)} value={this.state.companyName} onChange={this.onChangeForm('companyName')} />
		                                            {this.validator.message(i18n._(t`companyName`), this.state.companyName, 'required|alpha_num_dash_space')}
		                                        </>
												}
												</I18n>
											</div>
										</label>										
										<label className="white"><Trans>Website</Trans>:
											<div className="input-wrapper">
												<I18n>
		                                        { ({ i18n }) =>
		                                        <>
													<input className='signup-input-field' name={i18n._(t`companyURL`)} type="text" placeholder={i18n._(t`Enter Company website`)} value={this.state.companyURL} onChange={this.onChangeForm('companyURL')}/>
		                                            {this.validator.message(i18n._(t`companyURL`), this.state.companyURL, 'required')}
		                                        </>
												}
												</I18n>
											</div>
										</label>
										<label className="white"><Trans>Telephone</Trans>:
											<div className="input-wrapper">
												<I18n>
		                                        { ({ i18n }) =>
		                                        <>
													<input className='signup-input-field' name={i18n._(t`companyTelephone`)} type="text" placeholder={i18n._(t`Enter Company phone number`)} value={this.state.companyTelephone} onChange={this.onChangeForm('companyTelephone')}/>
		                                            {this.validator.message(i18n._(t`companyTelephone`), this.state.companyTelephone, 'required|phone')}
		                                        </>
												}
												</I18n>
											</div>
										</label>
										<label className="white"><Trans>Address</Trans>:
											<div className="input-wrapper">
												<I18n>
		                                        { ({ i18n }) =>
		                                        <>
													<textarea className='signup-input-field' style={{'height':'60px', resize: 'none'}} name={i18n._(t`companyAddress`)} type="text" placeholder={i18n._(t`Enter Company address`)} value={this.state.companyAddress} onChange={this.onChangeForm('companyAddress')}/>
		                                            {this.validator.message(i18n._(t`companyAddress`), this.state.companyAddress, "required|validAddress")}
		                                        </>
												}
												</I18n>
											</div>
										</label>
										<div id="ShowErrorRegisterAccount" style={{color:'red'}}><Trans id={this.state.errStrReturned} /></div>
								</div>
							</div>
							<div className="footer">
								<div className='row mt-3'>
									<div className='col-1'></div>
									<div className='col-10 text-center'>
										<I18n>
											{ ({ i18n }) =>
												<button className="btn-green center medium-size-button" onClick={(e) => this.onClickNext([
													this.validator.fieldValid(i18n._(t`adminFirstName`)),
													this.validator.fieldValid(i18n._(t`adminLastName`)),
													this.validator.fieldValid(i18n._(t`adminEmail`)),
													this.validator.fieldValid(i18n._(t`adminPhone`)),
													this.validator.fieldValid(i18n._(t`companyName`)),
													this.validator.fieldValid(i18n._(t`companyAddress`)),
													this.validator.fieldValid(i18n._(t`companyURL`)),
													this.validator.fieldValid(i18n._(t`companyTelephone`)),
													], e)}><Trans>Next</Trans>
												</button>
											}
										</I18n>
									</div>
									<div className='col-1 text-center mt-2 white'><h3>1/2</h3></div>
								</div>
							</div>
						</form>
					</div>
	            </div>
	   		);
		} else if(!this.state.bFormSubmitted && !this.state.bMyInfoPart && this.state.bCompanyInfoPart){
			return (
				<div className='formmodule'>
					<div className='signup-dialog' style={{height:"500px"}}>
	            		<form>
							<div className="formheader row no-gutters">
								<span className="col-2"><img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} alt='logo'/></span>
								<span className="col-8">
									<h1 className="text-center white"><Trans>Terms & Conditions</Trans></h1>
								</span>
								<span className="col-2"></span>
							</div>
							<br/>		
							<div className="formbody">

								<div>
									{this.props.planCode === 'plan_free' ?
										<embed className="pg-viewer-wrapper" src={window._env_.REACT_APP_ASSET_FOLDER+"/terms&conditions.pdf#toolbar=0&navpanes=0&scrollbar=0"} />
										:
										<embed className="pg-viewer-wrapper" src={window._env_.REACT_APP_ASSET_FOLDER+"/terms&conditions.pdf#toolbar=0&navpanes=0&scrollbar=0"} />
									}
								</div>

								<div className="center white" style={{ marginTop: 10, marginBottom: 5 }}>
									<label htmlFor="agreeTerms">
										<input className="big-checkbox" type="checkbox" name="agreeTerms" checked={this.state.agreeTerms} onChange={this.onChangeForm('agreeTerms')}></input>
										<Trans>I have read and agree to the Terms and Conditions.</Trans>&nbsp;&nbsp;<a className='text-white' href={window._env_.REACT_APP_ASSET_FOLDER+"/terms&conditions.pdf"} download>(<Trans>Download Terms & condition</Trans>)</a>
									</label>		                            
		                        </div>

								<div className="footer">
									<div className='row mt-3'>
										<div className='col-1'></div>
										{this.props.planCode === 'plan_free' ? 
											<>
												<div className='col-10 flex-center'>
													<button className="btn-blue medium-size-button" onClick={(e) => this.onBackToContactForm(e)}><Trans>Back</Trans></button> 
													<button className="margin10 btn-green medium-size-button" disabled={!this.state.agreeTerms} onClick={(e) => this.onSubmitFreePlanUser(e)}><Trans>Submit to request your free account</Trans></button>
												</div >
												<div className='col-1 text-center mt-2 white'><h3>3/3</h3></div>
											</>
										:
											<>
												<div className='col-10 flex-center'>
													<button className="btn-blue medium-size-button" onClick={(e) => this.onBackToPersonalInfo(e)}><Trans>Back</Trans></button> 
													<button className="margin10 btn-green medium-size-button" disabled={!this.state.agreeTerms} onClick={(e) => this.onGoToPayment(e)}><Trans>Next</Trans></button>
												</div>
												<div className='col-1 text-center mt-2 white'><h3>2/2</h3></div>
											</>
										}
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			);
		} else if(this.state.bPayment) {
			return (
				<div className="formmodule payment">
					<div className="signup-dialog">
						<div className="formheader row no-gutters">
							<span className="col-2"><img src={window._env_.REACT_APP_ASSET_FOLDER+"/header_logo.png"} alt='logo'/></span>
							<span className="col-8">
								<h1 className="text-center white"><Trans>Payment</Trans></h1>
								<span className="row" >
									<h3 className="col-12 text-center white" style={{margin: '10px 15px'}}><Trans>Please enter your credit card details to continue</Trans></h3>
								</span>
							</span>
						</div>			
						<div className="formbody">
							{/* TP-2422 */}
                            {/* <StripeCheckoutForm
                                language={this.props.language} 
                                stripe={this.state.stripe}
                                onLoadToken={this.onSubmitRegistrationDetails}
                                onBack={(e) =>this.onBackToCompanyInfo(e)}
                                planAmount={this.state.planSelected.price}
                                userLicenses="5"
                                planCode= {this.state.planSelected.code}
                            /> */}
							<div id="ShowErrorRegisterAccount" style={{color:'red'}}><Trans id={this.state.errStrReturned} /></div>
						</div>
					</div>
				</div>
			);
		} else {
			return(
				<div style={{'fontSize': 21, 'textAlign':'left', 'width': '420px'}}>
						<br /><label>{this.state.sFormSubmission}</label>
					<div id="ShowErrorRegisterAccount" style={{color:'red'}}>
					</div>
				</div>
			);
		}
	}
}

export default Adminform;