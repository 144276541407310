import React from 'react';
import { Row, Label, FormGroup } from 'reactstrap';
import { Trans, t } from '@lingui/macro';
import { I18n } from "@lingui/react";
import '../Landing/Groups.scss';
import _ from 'lodash';
import DatePicker from "react-datepicker";

class Scheduler extends React.Component{

    constructor(props){
        super(props);
        this.days_of_week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    }

    //TP-5997
    translateDays = (day, i18n) => {
        switch (day) {
            case 'Sunday':
                return i18n._(t`Sunday`);
            case 'Monday':
                return i18n._(t`Monday`);
            case 'Tuesday':
                return i18n._(t`Tuesday`);
            case 'Wednesday':
                return i18n._(t`Wednesday`);
            case 'Thursday':
                return i18n._(t`Thursday`);
            case 'Friday':
                return i18n._(t`Friday`);
            case 'Saturday':
                return i18n._(t`Saturday`);
            default:
                break;
        } 
    }

    render(){
        const mode_of_recurrence = this.props.selectedJob && this.props.selectedJob.mode_of_recurrence ? JSON.parse(this.props.selectedJob.mode_of_recurrence) : {}
        return  (
                    <>
                        <div className="ml-3 mt-3">
                            <I18n>
                                {({ i18n }) =>
                                <>
                                    <Row>
                                        <label><Trans>Mode Of Recurrence</Trans>:
                                            <div className="col mt-2">
                                                <>
                                                    <Label className="ml-2">
                                                        <input type="radio"  value="daily" name="mode" checked={mode_of_recurrence.mode === "daily"} onChange={this.props.onChangeJobSchedule("mode")}/>{' '}
                                                        <Trans>Daily</Trans>
                                                    </Label>
                                                    <Label className="ml-5">
                                                        <input type="radio" value="weekly" name="mode" checked={mode_of_recurrence.mode === "weekly"} onChange={this.props.onChangeJobSchedule("mode")} />{' '}
                                                        <Trans>Weekly</Trans>
                                                    </Label>
                                                    <Label className="ml-4">
                                                        <input type="radio"  value="monthly" name="mode" checked={mode_of_recurrence.mode === "monthly"} onChange={this.props.onChangeJobSchedule("mode")}/>{' '}
                                                        <Trans>Monthly</Trans>
                                                    </Label>
                                                </>
                                            { this.props.validator.message(i18n._(t`mode_of_recurrence`), mode_of_recurrence.mode, 'required') }
                                            </div>
                                        </label>
                                    </Row>
                                </>
                                }
                            </I18n>
                            {mode_of_recurrence.mode === "daily" ?
                                <Row>
                                    <I18n>
                                        {({ i18n }) =>
                                            <>
                                                <div className="col mt-2">
                                                    <Label className="ml-4">
                                                        <input type="radio"  value={false} name="weekday" checked={mode_of_recurrence.weekday === "false"} onChange={this.props.onChangeJobSchedule("weekday")}/>{' '}
                                                        <Trans>Every</Trans>
                                                    </Label>
                                                </div>
                                                <div className="col">
                                                    <input
                                                        type="text"
                                                        name={i18n._(t`days`)}
                                                        className="signup-input-field"
                                                        value={mode_of_recurrence.interval || ""}
                                                        onChange={this.props.onChangeJobSchedule("interval")}
                                                        disabled={mode_of_recurrence.weekday === "true"}
                                                        style={{width: "50px"}}
                                                    />
                                                    <label style={{clear:"both"}}><Trans>Day(s)</Trans></label>
                                                    {mode_of_recurrence.weekday === "false" ? this.props.validator.message(i18n._(t`interval`), mode_of_recurrence.interval, "required|numeric|checkInterval") : null}
                                                </div>
                                                <div className="col mt-2">
                                                    <Label>
                                                        <input type="radio" value={true} name="weekday" checked={mode_of_recurrence.weekday === "true"} onChange={this.props.onChangeJobSchedule("weekday")} />{' '}
                                                        <Trans>Every weekday</Trans>
                                                    </Label>
                                                </div>
                                            </>
                                        }
                                    </I18n>
                                </Row>
                                : null
                            }
                            {mode_of_recurrence.mode === "weekly" ?
                                    <I18n>
                                        {({ i18n }) =>
                                            <>
                                                <Row className="ml-1">
                                                    <div className="col-4">
                                                        <label className="m-0"><Trans>Repeat every</Trans>:</label>{" "}
                                                        {/* <input
                                                            type="text"
                                                            name={i18n._(t`days`)}
                                                            className="signup-input-field"
                                                            value={mode_of_recurrence.weekly_interval}
                                                            onChange={this.props.onChangeJobSchedule("weekly_interval")}
                                                            disabled={mode_of_recurrence.mode !== "weekly"}
                                                            style={{width: "50px", marginLeft: "20px"}}
                                                        />
                                                        <label><Trans>week(s) on: </Trans></label>
                                                        { this.props.validator.message(i18n._(t`interval`), mode_of_recurrence.weekly_interval, 'required') } */}
                                                    </div>
                                                </Row>
                                                <Row className="ml-2 mt-3">
                                                {
                                                    this.days_of_week.map((day, j)=> {
                                                        
                                                        return(
                                                            <label htmlFor="days_of_week">
                                                                <input className="checkbox" type="checkbox" name="days_of_week" checked={mode_of_recurrence.days_of_week && mode_of_recurrence.days_of_week.includes(j)} 
                                                                    onChange={this.props.onChangeJobSchedule("days_of_week")} data-id={j}
                                                                    />
                                                                    {this.translateDays(day, i18n)}
                                                                { j===0 ?this.props.validator.message(i18n._(t`days_of_week`), mode_of_recurrence.days_of_week, 'minArrayLength'): null }
                                                            </label>
                                                        )
                                                    })
                                                }
                                                </Row>
                                            </>
                                        }
                                    </I18n>
                                : 
                                    null
                            }
                        </div>
                        <div className="ml-3 mt-3">
                            <Row>
                                <I18n>
                                    {({ i18n }) =>
                                        <>
                                            <div className="col-6">
                                                <Row>
                                                    <div className="col-7 pr-0 pl-4">
                                                        <label><Trans>Recurrence end date</Trans>:</label>{" "}
                                                    </div>
                                                    <div className="col-5 pl-0">
                                                        {
                                                            mode_of_recurrence.recurrence_end_date ?
                                                                <DatePicker selected={new Date(mode_of_recurrence.recurrence_end_date)} onFocus={this.props.isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} 
                                                                    popperPlacement="top-start" onChange={this.props.onChangeScheduleEndDate}
                                                                    onKeyDown={(e) => { e.preventDefault();}}
                                                                />
                                                            :                                                           
                                                                <DatePicker onFocus={this.props.isSmallFormFactor ? (e) => e.target.readOnly = true : () => {return null;}} 
                                                                    popperPlacement="top-start" onChange={this.props.onChangeScheduleEndDate}
                                                                    onKeyDown={(e) => { e.preventDefault();}}
                                                                />
                                                        }
                                                        { this.props.validator.message(i18n._(t`recurrence_end_date`), mode_of_recurrence.recurrence_end_date, 'required|afterStartDateForScheduler') }
                                                        <input type="date" readOnly value={mode_of_recurrence.recurrence_end_date} name={i18n._(t`recurrence_end_date`)} className="hidden"/>
                                                    </div>
                                                </Row>
                                            </div>
                                            <div className="col-6">
                                                <Row>
                                                    <div className="col-7 text-right">
                                                        <label><Trans>No of ocurrences</Trans>:</label>{" "}{mode_of_recurrence.no_of_occurences || 0}
                                                    </div>
                                                </Row>
                                            </div>
                                        </>
                                    }
                                </I18n>
                            </Row>
                        </div>
                    </>
                )
    }
}

export default Scheduler;