import React, { Fragment } from 'react';
import './PricingPlan.scss';
import Loader from 'react-loader-spinner';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";

class PricingPlan extends React.Component {
    constructor() {
        super();
        this.state = {
            plans: [],
            isLoading: false,
            errStrReturned: '',
            retCode: 400,
            enterprisePlan: {
                code: 'plan_enterprise',
                name: 'ENTERPRISE',
                price: 'Custom pricing'
            },
            freePlan: {
                code: 'plan_free',
                name: 'FREE',
                price: 'Free'
            }
        }
    }

    componentWillMount = () => {
        this.getPlans();
    }

    getPlans = () => {
        this.setState({
            isLoading: true
        })
        fetch(`${window._env_.REACT_APP_API_URL }/plans`, {
            method: 'get',
            headers: {'Content-Type': 'application/json'}
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(data => {
            if(data !== null && data !== undefined){
                this.setState({
                    plans: data,
                    isLoading: false
                })
                this.props.onGetAllPlans(data);
            }
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                errStrReturned: i18nMark('Something went wrong while changing plan!')
            })
        })
    }

    showLoader = () => {
        if (!this.state.isLoading) return '';
        return (
            <div className="loader">
                <Loader
                    type="ThreeDots"
                    color="#23c97d"
                    height="70"	
                    width="70"
                />
            </div>
        )
    }

    render = () => {
        const { buttonText, onPlanSelect } = this.props;
        return (
            <Fragment>
            <div className="flex-center-wrap" id="subscription-table">
                {
                    this.state.plans.map((plan, index) => {
                        return <Plan key={'plam' + index} plan={plan} onPlanSelect={onPlanSelect} buttonText={buttonText} />
                    })
                }
                <Plan plan={this.state.enterprisePlan} onPlanSelect={onPlanSelect} buttonText="Contact Us"/>
                <Plan plan={this.state.freePlan} onPlanSelect={onPlanSelect} buttonText={buttonText}/>
            </div>
            { this.showLoader() }
            </Fragment>
        )
    }
}

const Plan = ({plan, onPlanSelect, buttonText}) => (
    <div className="plan" key={plan.id}>
        <div className="header"><Trans id={plan.name}/></div>
        {
            plan.code === 'plan_enterprise' || plan.code === 'plan_free'? 
            <Fragment>
                <div className="price"><Trans id={plan.price}></Trans></div>
                <div className="monthly">
                    <Trans>PER USER YEARLY</Trans>
                </div>
                <ul>
                    <li><b>{plan.code === 'plan_free'? <Trans id="{minutes} minutes per month" values={{minutes: 60}} /> : <Trans>custom minutes per month</Trans>}</b></li>
                    <li><Trans>No overage will be calculated</Trans></li>
                    <li>{plan.code === 'plan_free'? <Trans values={{users: 2}}>Users per subscription</Trans> : <Trans>No Minimum Users restrication.</Trans>}</li>
                </ul>
            </Fragment> :
            <Fragment>
                <div className="price">$ {plan.price}</div>
                <div className="monthly">
                    <Trans>PER USER YEARLY</Trans>
                </div>
                <ul>
                    <li><Trans id="{minutes} minutes per month" values={{minutes: plan.monthly_minutes}} /></li>
                    <li><Trans id="$ {overage} per minute overage" values={{overage: plan.overage_cost}} /></li>
                    <li><Trans id="{users} Minimum Users" values={{users: 5}}/></li>			
                </ul>
            </Fragment>
        }
        <div className="flex-center">
            <button className="btn-green-signup" onClick={() =>onPlanSelect(plan)}><Trans id={buttonText}></Trans></button>
        </div>
    </div>
)

export default PricingPlan;