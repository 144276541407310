import React, { Component } from 'react';
import SessionSignal from './SessionSignal';
import { Trans } from '@lingui/macro';
import { printConsole } from '../helpers';



//let userObject = null;

class OTSessionUser extends Component {
    constructor (props) {
        super(props);
        this.state = {
            user: this.props.user,
            connArr: [],
            subArr: [],
            expertArr: [],
            json: {},
            isLoading: false,
            showControlRequestDialog: false,
            expertFirstName: '',
            expertLastName: '',
            expertEmail: '',
            clickHandSignal: false,
            showHideMaxDivControl: false,
            showHideDivIconAudioOnly: false, 
            showCameraErrorMessage: false,
            cameraErrorType: '',  
            triggerStopSession: false,         
        };
        //userObject = this;
        const from = { userId: this.props.loggedInUserId, firstName: this.props.user.firstname, lastName: this.props.user.lastname, email: this.props.user.email, isexpert: this.props.user.isexpert };
        let {selectedGroupId} = this.props;
        if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
        this.sessionSignal = new SessionSignal(from, false, selectedGroupId);
        this.sessionSignal.listenerIsOfflineNotification(this.subscribeToOffline);
     }

    notifyUser = () => {
        //const {user, selectedGroupId, selectedGroupName, fromSocketUser} = this.props;

        /* if(user.isexpert && fromSocketUser !== null){
            this.sessionSignal.emitSignal('respond-to-the-call', { action: 'accepted', recipient: fromSocketUser, groupId: selectedGroupId, groupName: selectedGroupName });
        }
        this.sessionSignal.emitSignal('user-is-in-session', { groupId: selectedGroupId, isInSession: true });         */
        this.sessionSignal.sendRoomJoined();
    }

    notifyGroupWhenDisconnected = () => {
        let {selectedGroupId} = this.props;
        if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
        this.sessionSignal.sendWebrtcEndCall();
        this.sessionSignal.emitSignal('user-is-in-session', { groupId: selectedGroupId, isInSession: false });        
    }

    triggerStopSession = () => {
        this.setState({ triggerStopSession: true });
    }

    subscribeToOffline = () => {
        const message = "Connection Interrupted";
        this.setState({ flashMsgText: message, showFlashMessage: true, flashLeft: "", flashTop: "" });
        printConsole("Your connection has been interrupted!"); 
        this.timeOut = setTimeout(() => {
            this.triggerStopSession();
        }, 10000)
    }

    getDateString = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${year}${month}${day}_${hour}${minutes}${seconds}`
    }

    getDateStringForWatermark = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = `${date.getMonth() + 1}`.padStart(2, '0');
        const day = `${date.getDate()}`.padStart(2, '0');
        const hour = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`
    }
        
    sessionConnectionCreated = (conn) => {
        let connArr = this.state.connArr.splice();
        connArr.push(conn);
        this.setState({ connArr: connArr });
    }

    sessionStreamCreated = (session, stream, divName, options) => {
        const subscriber = session.subscribe(stream, divName, options);
        const subscriberInfo = {
                            g_stream: stream,
                            g_subscriber: subscriber
                        }
        let subInfoArr = this.state.subArr.splice();
        subInfoArr.push(subscriberInfo);
        this.setState({subArr : subInfoArr});
        return session;
    }

    // invoked for all. This asks the permission for getting the camera and microphone permission
    initMedia = () => {
        const {user} = this.props;
        let videoConfig = {width: {min: 1280}, height: {min: 720}}
        if (user.isexpert) {
            videoConfig = false;
            navigator.mediaDevices.getUserMedia({
                audio: true,
                video: videoConfig
            })
            .then(stream => {
                stream.getTracks().forEach ( track => {
                    track.stop();
                })
            })
            .catch(err => {
                // MB2-431
                let errorType;
                console.log(`Device detection Error :: ${err}`);
                if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
                    //required track is missing 
                    errorType = 'MicrophoneNotFoundError';
                } else if (err.name === "NotReadableError" || err.name === "AbortError" || err.name === "TrackStartError") {
                    //webcam or mic are already in use 
                    errorType = 'MicrophoneTrackStartError';
                } else if (err.name === "OverconstrainedError" || err.name === "ConstraintNotSatisfiedError") {
                    //constraints can not be satisfied by avb. devices 
                    errorType = 'MicContraintNotSatisfiedError';
                } else if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
                    //permission denied in browser 
                    errorType = 'MicPermissionDeniedError';
                } else {
                    //other errors 
                    errorType = 'OtherWebrtcError';
                }
                this.setState({showCameraErrorMessage: true, cameraErrorType: errorType})
            });
        } else {
            navigator.mediaDevices.getUserMedia({
                video: videoConfig,
                audio: true
            })
            .then(stream => {
                stream.getTracks().forEach ( track => {
                    track.stop();
                })
            })
            .catch(err => {
                // MB2-431
                let errorType;
                console.log(`Device detection Error :: ${err}`);
                if (err.name === "NotFoundError" || err.name === "DevicesNotFoundError") {
                    //required track is missing 
                    errorType = 'DevicesNotFoundError';
                } else if (err.name === "NotReadableError" || err.name === "AbortError" || err.name === "TrackStartError") {
                    //webcam or mic are already in use 
                    errorType = 'TrackStartError';
                } else if (err.name === "OverconstrainedError" || err.name === "ConstraintNotSatisfiedError") {
                    //constraints can not be satisfied by avb. devices 
                    errorType = 'ConstraintNotSatisfiedError';
                } else if (err.name === "NotAllowedError" || err.name === "PermissionDeniedError") {
                    //permission denied in browser 
                    errorType = 'PermissionDeniedError';
                } else {
                    //other errors 
                    errorType = 'OtherWebrtcError';
                }
                this.setState({showCameraErrorMessage: true, cameraErrorType: errorType})
            });

        }
    };

    showLoader = (text) => {
        if (!this.state.isLoading) return '';
        let textClass = "";
        switch (text) {
            case "Uploading....":
            case "Downloading...":
                textClass = "left-loader-one-word";
                break;
            case "Downloading image":
                textClass = "left-loader-two-words";
                break;
            case "Swap to default camera":
                textClass = "left-loader-multi-word";
                break;
            /**TP-2853 */
            case "Annotation Canvas is starting up":
                textClass = "left-loader-multi-word";
                break;
            default:
                textClass = "left-loader-one-word";
        }
        return (
            <div className="loader">
                <span className="d-flex flex-column justify-content-center align-items-center">
                    <i className="fas fa-spin fa-circle-notch fa-2" aria-hidden="true"></i>
                </span>
                <div className={"loader-text " + textClass}><Trans id={text}></Trans></div>
            </div>
        )
    }

    //TP-2131 -- Handling of the end-group-call socket listener
    subscribeToGlobalEndCall = ({group_id}) => {
        printConsole(`end-group-call signal received with payload {group_id: ${group_id}}`);
        let {selectedGroupId} = this.props;
        if (group_id === parseInt(selectedGroupId)) {
            this.props.onSessionCmdChange('session_stopped');
            printConsole(" The user is being automatically made to end its session ");
        }
    }

    componentWillUnmount = () => {
        this.sessionSignal.removeIsOfflineNotification(this.subscribeToOffline);
        this.setState({showCameraErrorMessage: false, cameraErrorType: ''})
        clearTimeout(this.timeOut);
    }

    render(){
            return (
                <>
                    {/* ref={this.child} */}
                    {this.props.children}
                </>
            )
    }
}

export default OTSessionUser;