import React, { Fragment } from 'react';
// import Select from 'react-select';
import { Row, Col, Label, Form, ModalBody, CustomInput, Modal, ModalHeader, ModalFooter, Input, FormGroup } from 'reactstrap';
import Button from '../Button/Button';
import SimpleReactValidator from 'simple-react-validator';
import MessageModal from '../MessageModal/MessageModal';
// import makeAnimated from 'react-select/lib/animated';
import { debounce } from 'lodash';
import DatePicker, { registerLocale, setDefaultLocale, getDefaultLocale } from "react-datepicker";
import en from 'date-fns/locale/en-US';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
registerLocale('en', en);
setDefaultLocale('en');

class EnterpriseRequestForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            company_name: '',
            website: '',
            amount: 1250,
            quantity: 10,
            monthly_minutes: 1000,
            overage_cost: 0,
            audio_mode: {
                value: 'expert2Tech',
                label: 'Expert to Tech'
            },
            audioModes: [{
                value: 'expert2Tech',
                label: 'Expert to Tech'
            },{
                value: 'tech2Tech',
                label: 'Tech to Tech'
            }],
            number_of_experts: 1,
            isLoading: false,
            message: '',
            isError: false,
            isEmailAlreadyRegistered: false,
            start_date: this.convertDate(new Date()),
            end_date: '',
            language: '',
            is_customizable: false,
            is_gps_enabled: false,
            is_offline_jobs_enabled: false,
            assign_jobs_to_all_users: false,
            asset_scan: false,
            streaming_medium: { value: 'onprem', label: 'Onprem' },
            streamimgMediums: [{ value: 'open_tok',label: 'Open Tok' },{value: 'onprem', label: 'Onprem'},
            { value: 'both',label: 'Both' }],
            is_solo_program: false,
            guest_user_enabled: false,
            expert_ss_enabled: false,
            technician_ss_enabled: false,
            check_password_expiry: false,
            password_expiry_duration: 45,
            screen_recording_enabled: false, //NS2-293
            zoom_enabled: false, //NS2-413
            torch_enabled: false, //NS2-413
            annotation_color_enabled: false, //NS2-422
            annotation_shape_enabled: false, //NS2-422
            license_key: '', //MB2-302
            install_by_date: null, //MB2-302
            swap_camera_enabled: false, //NS2-660,
            behaviour: '', //FQ3-413
            expert_can_disconnect_user: "disabled",
            can_turn_off_user_video: "disabled",
            reset_user_password: { value: 'none', label: 'No password reset'},
            reset_password_options: [{value: 'reset_password_without_qr', label: 'Reset Password Without QR'}, {value: 'reset_password_with_qr', label: 'Reset Password With QR'},
            {value: 'none', label: 'No password reset'}],//FQ3-832
            download_usage: { value: '', label: 'No usage download'},//FQ3-832
            download_usage_options: [{value: 'as_csv', label: 'As CSV'}, {value: '', label: 'No usage download'}],//FQ3-832
            invite_online_user: false,//FQ3-832
            pass_code: ""//TZ-845
        }
        // initialize validator
        this.validationRules(props.language);
    }

    componentDidUpdate = (prevProps, prevState) =>{
        if (prevState.language !== this.state.language) {
            this.validationRules(this.state.language);
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.language!==prevState.language){
           
          return {language : nextProps.language};
        }
        else return null;
    }
    
    validationRules = (language) =>{
        SimpleReactValidator.addLocale(language, require(`../../locales/${language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: language,
			element: message => <div style={{ display: 'block' }} className="invalid-feedback">{message.replace(/field|The/, '').capitalize()}</div>,
			validators: {
                emailValidate: {  // name the rule
					message: 'Please enter a valid email address',
					rule: (val, params, validator) => {
						const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return validator.helpers.testRegex(
							String(val).toLowerCase(),
							regex
						);
					},
					required: true  // optional
                },
                phone: {
                    message: 'Enter a valid phone number',
                    rule: (val,params,validator) => {
                        let telRegex= /^\d+$/;
                        return (val.length >= 10 && val.length <=13 && validator.helpers.testRegex(
                            val,telRegex)
                        );
                    }
                }
			}
		});
    }
    onChangeForm = name => event => {
        event.persist();
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        if (parseInt(value) !== 'NaN' && parseInt(value) < 0)  {
            value = 0;
        }
        if (event.target.name === 'number_of_experts' && parseInt(value) === 0) {
            value = 1;
        }
        if (name === 'email') this.delayedCallback(event.target.value,event.target.name);
        this.setState({
            [name]: value
        });
    }


    handleSelect = name => newValue => {
        this.setState({ [name]: newValue, purchase_id: newValue.value });
    }

    componentDidMount = () => {
		this.delayedCallback = debounce(this.checkEmailExists, 500);
    }

    getOtherFlagsOptions = () => {
        let screenShareMode = '';
        if (this.state.expert_ss_enabled === true && this.state.technician_ss_enabled === true)
        {
            screenShareMode = 'screen_share_enabled';
        } else if (this.state.expert_ss_enabled === true) 
        {
            screenShareMode = 'expert_screen_share_enabled';
        } else if (this.state.technician_ss_enabled === true)
        {
            screenShareMode = 'tech_screen_share_enabled';
        } else 
        {
            screenShareMode = 'screen_share_disabled';
        }
        return screenShareMode;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
        }
        const screenShareMode = this.getOtherFlagsOptions();
        const options = {
            first_name: this.state.first_name.trim(),
            last_name: this.state.last_name.trim(),
            email: this.state.email.trim().toLowerCase(),
            phone: this.state.phone,
            company_name: this.state.company_name,
            website: this.state.website,
            amount: parseInt(this.state.amount),
            quantity: parseInt(this.state.quantity),
            monthly_minutes: parseInt(this.state.monthly_minutes),
            overage_cost: this.state.overage_cost,
            audio_mode: this.state.audio_mode.value,
            number_of_experts: parseInt(this.state.number_of_experts),
            start_date: this.state.start_date,
            end_date: this.state.end_date,
            is_customizable: this.state.is_customizable,
            is_gps_enabled: this.state.is_gps_enabled,
            is_offline_jobs_enabled: this.state.is_offline_jobs_enabled,
            assign_jobs_to_all_users: this.state.assign_jobs_to_all_users,
            asset_scan: this.state.asset_scan,
            streaming_medium: this.state.streaming_medium.value,
            is_solo_program: this.state.is_solo_program,
            guest_user_enabled: this.state.guest_user_enabled,
            screen_share_mode: screenShareMode || "screen_share_disabled",
            check_password_expiry: this.state.check_password_expiry,
            password_expiry_duration: this.state.password_expiry_duration,
            screen_recording_enabled: this.state.screen_recording_enabled,
            zoom_enabled: this.state.zoom_enabled,
            torch_enabled: this.state.torch_enabled,
            annotation_color_enabled: this.state.annotation_color_enabled,
            annotation_shape_enabled: this.state.annotation_shape_enabled,
            license_key: this.state.license_key,
            install_by_date: this.state.install_by_date,
            swap_camera_enabled: this.state.swap_camera_enabled,
            behaviour: this.state.behaviour,
            expert_can_disconnect_user: this.state.expert_can_disconnect_user,
            can_turn_off_user_video: this.state.can_turn_off_user_video,
            reset_user_password: this.state.reset_user_password.value,
            download_usage: this.state.download_usage.value,
            invite_online_user: this.state.invite_online_user,
            pass_code: this.state.pass_code
        }
        this.setState({ isLoading: true });
        const { isLoading, serial_number, message, ...optionsToSave } = this.state;
		fetch(window._env_.REACT_APP_API_URL + '/registerEnterpriseCustomer', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(options)
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
		.then(res => {
            if (res) {
                this.setState({
                    message: i18nMark('The request has been sent for an approval.'),
                    isLoading: false
                })
            }
		})
		.catch((error) => {
            let message = i18nMark('Something went wrong. Please try again later.');
            if (error.status === 409) {
                message = 'This Email is already registered'
            }
			this.setState({ 
                message,
                isError: true,
                isLoading: false
            })
		})
    }

    handleKeyPress = e =>  {
        if(e.key === 'Enter'){
            let registerForm = e.target.form;
            let index = Array.prototype.indexOf.call(registerForm, e.target);
            registerForm.elements[index + 1].focus();
            e.preventDefault();
        }
    }

    onDismiss = (e) => {
        e.preventDefault();
        this.setState({ 
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            company_name: '',
            website: '',
            amount: 1250,
            quantity: 10,
            monthly_minutes: 1000,
            overage_cost: 0,
            audio_mode: {
                value: 'expert2Tech',
                label: 'Expert to Tech'
            },
            number_of_experts: 1,
            isLoading: false,
            message: '',
            isError: false,
            start_date: this.convertDate(new Date()),
            end_date: '',
            guest_user_enabled: false,
            expert_ss_enabled: false,
            technician_ss_enabled: false,
            check_password_expiry: false,
            password_expiry_duration: 45,
            screen_recording_enabled: false,
            zoom_enabled: false,
            torch_enabled: false,
            annotation_color_enabled: false,
            annotation_shape_enabled: false,
            license_key: '',
            install_by_date: null,
            streaming_medium: { value: 'open_tok', label: 'Open Tok'},
            expert_can_disconnect_user: "disabled",
            swap_camera_enabled: false,
            behaviour: '',
            can_turn_off_user_video: "disabled",
            reset_user_password: { value: 'none', label: 'No password reset'},
            download_usage: {value: '', label: 'No usage download'},
            invite_online_user: false,
        });
    }

    showMessage = () => {
        return (
            <MessageModal onClickClose={this.onDismiss}>
                <div className="text-center" style={{ color: this.state.isError ? 'red' : '#485890'}}>
                    <div>
                        {
                            !this.state.isError ? 
                            <i className="fas fa-check-circle fa-3x" aria-hidden="true"></i> :
                            <i className="fas fa-exclamation-triangle fa-3x" aria-hidden="true"></i>
                        }
                    </div>
                    <Trans id={this.state.message}></Trans>
                </div>
            </MessageModal>
        )
    }

    onClickClose = (e) => {
		e.preventDefault();
		this.props.closeAdminPopup();
    }
    
    onHandleDateChange = name => date => {
        this.setState({
          [name]: date
        });
    }

    checkEmailExists = (email,emailField) => {
		if (!this.validator.fieldValid(emailField))  {
			this.setState({ isEmailAlreadyRegistered: false });
			return
		};
        fetch(window._env_.REACT_APP_API_URL+'/checkEmailExists/' + email + '?type=standard', {
			method: 'GET',
			headers: {'Content-Type': 'application/json'}
		})
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			this.setState({
				isEmailAlreadyRegistered: isExists
			});
		})
		.catch((error) => {
			console.log(error);
		})
    }
    
    convertDate = (str) => {
        let date = str,
            mnth = ("0" + (date.getMonth()+1)).slice(-2),
            day  = ("0" + date.getDate()).slice(-2);
        return [ date.getFullYear(), mnth, day ].join("-");
    }
    
    render = () => {
        return (
            <Row>
                <Col xs="12" sm={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }}>
                    {
                        this.state.message ?
                        this.showMessage() :
                        <Modal isOpen={true} className="modal-trial-register">
                            <ModalHeader className="text-center" toggle={this.onClickClose}><Trans>Register Enterprise</Trans></ModalHeader>
                            <ModalBody>
                                <I18n>
                                {({ i18n }) =>
                                <Fragment>
                                    <Form>
                                        <Row>
                                            <div key="first_name" className="input-wrapper margin-bottom-10 col-md-6">
                                                <input
                                                    className='signup-input-field'
                                                    id="first_name"
                                                    name={i18n._(t`first_name`)}
                                                    type="text"
                                                    value={this.state.first_name}
                                                    onChange={this.onChangeForm('first_name')}
                                                    onKeyPress={this.handleKeyPress}
                                                    placeholder={i18n._(t`First Name`).concat('*')}/>
                                                {this.validator.message(i18n._(t`first_name`), this.state.first_name, 'required')}		
                                            </div>
                                            <div key="last_name" className="input-wrapper margin-bottom-10 col-md-6">
                                                <input
                                                    className='signup-input-field'
                                                    id="last_name"
                                                    name={i18n._(t`last_name`)}
                                                    type="text"
                                                    value={this.state.last_name}
                                                    onChange={this.onChangeForm('last_name')}
                                                    onKeyPress={this.handleKeyPress}
                                                    placeholder={i18n._(t`Last Name`).concat('*')}/>
                                                {this.validator.message(i18n._(t`last_name`), this.state.last_name, 'required')}		
                                            </div>
                                        </Row>
                                        <div key="email" className="input-wrapper margin-bottom-10">
                                            <input
                                                className='signup-input-field'
                                                id="email"
                                                name={i18n._(t`contact_email`)}
                                                type="text"
                                                value={this.state.email}
                                                onChange={this.onChangeForm('email')}
                                                onKeyPress={this.handleKeyPress}
                                                placeholder={i18n._(t`Email`).concat('*')}/>
                                            {this.validator.message(i18n._(t`contact_email`), this.state.email, 'required|emailValidate')}
                                            { this.state.isEmailAlreadyRegistered ? <span style={{display: 'block'}} className="invalid-feedback"><Trans>This Email is already in use.</Trans></span> : '' }
                                        </div>
                                        <div key="phone" className="input-wrapper margin-bottom-10">
                                            <input
                                                className='signup-input-field'
                                                id="phone"
                                                name={i18n._(t`phone`)}
                                                type="text"
                                                value={this.state.phone}
                                                onChange={this.onChangeForm('phone')}
                                                onKeyPress={this.handleKeyPress}
                                                placeholder={i18n._(t`Contact Phone`).concat('*')} />
                                            {this.validator.message(i18n._(t`phone`), this.state.phone, 'required|phone')}		
                                        </div>
                                        <div key="company_name" className="input-wrapper margin-bottom-10">
                                            <input className='signup-input-field'
                                                id="company_name"
                                                name={i18n._(t`company_name`)}
                                                type="text"
                                                value={this.state.company_name}
                                                onChange={this.onChangeForm('company_name')}
                                                onKeyPress={this.handleKeyPress}
                                                placeholder={i18n._(t`Company Name`).concat('*')} />
                                            {this.validator.message(i18n._(t`company_name`), this.state.company_name, 'required')}		
                                        </div>
                                        <div key="website" className="input-wrapper margin-bottom-10">
                                            <input className='signup-input-field'
                                                id="website"
                                                name={i18n._(t`website`)}
                                                type="text"
                                                value={this.state.website}
                                                onChange={this.onChangeForm('website')}
                                                onKeyPress={this.handleKeyPress}
                                                placeholder={i18n._(t`Website`).concat('*')} />
                                            {this.validator.message(i18n._(t`website`), this.state.website, 'required')}		
                                        </div>
                                        <EnterprisePurchaseDetails 
                                            validator={this.validator}
                                            values={this.state}
                                            handleKeyPress={this.handleKeyPress}
                                            onChangeForm={this.onChangeForm}
                                            onHandleDateChange={this.onHandleDateChange}
                                            handleSelect={this.handleSelect}
                                        />
                                    </Form>
                                </Fragment>
                                }
                                </I18n>
                            </ModalBody>
                            <ModalFooter>
                                <div className="d-flex justify-content-center btn-trial-register">
                                    <Button 
                                        showSpinner={this.state.isLoading} 
                                        onClick={this.handleSubmit}>
                                        <Trans>Submit</Trans>
                                    </Button>
                                </div>
                            </ModalFooter>
                        </Modal>
                    }
                </Col>
            </Row>
        )
    }
}

export const EnterprisePurchaseDetails = ({ validator, values, handleKeyPress, onChangeForm, onHandleDateChange, handleSelect, openLogoFileUpload, detachLogoFiles }) => (
    <I18n>
    {({ i18n }) =>
        <Fragment>
            <Row>
                <div key="amount" className="input-wrapper margin-bottom-10 col-md-4">
                <Label for="amount"><Trans>Total Price</Trans>*</Label>
                    <input className='signup-input-field'
                        id="amount"
                        name={i18n._(t`amount`)}
                        type="number"
                        min="100"
                        value={values.amount}
                        onChange={onChangeForm('amount')}
                        onKeyPress={handleKeyPress}
                        placeholder={i18n._(t`Total Price`).concat('*')} />
                    {validator.message(i18n._(t`amount`), values.amount, 'required')}		
                </div>
                
                <div key="quantity" className="input-wrapper margin-bottom-10 col-md-4">
                    <Label for="quantity"><Trans>No of Licenses</Trans>*</Label>
                    <input className='signup-input-field'
                        id="quantity"
                        name={i18n._(t`quantity`)}
                        type="number"
                        min="1"
                        value={values.quantity}
                        onChange={onChangeForm('quantity')}
                        onKeyPress={handleKeyPress}
                        placeholder={i18n._(t`No of Licenses`).concat('*')} />
                    {validator.message(i18n._(t`quantity`), values.quantity, 'required')}
                </div>
                <div key="overage_cost" className="input-wrapper margin-bottom-10 col-md-4">
                    <Label for="overage_cost"><Trans>Overage Cost</Trans></Label>                                                   
                    <input className='signup-input-field'
                        id="overage_cost"
                        name="overage_cost"
                        type="number"
                        value={values.overage_cost}
                        onChange={onChangeForm('overage_cost')}
                        onKeyPress={handleKeyPress}
                        step="any"
                        placeholder={i18n._(t`Overage Cost`)} />		
                </div>
            </Row>
            <Row>
                <div className="input-wrapper margin-bottom-10 col-md-6">
                    <Label><Trans>Monthly Minutes</Trans>*</Label> 
                    <input className='signup-input-field'
                        id="monthly_minutes"
                        name={i18n._(t`monthly_minutes`)}
                        type="number"
                        value={values.monthly_minutes}
                        onChange={onChangeForm('monthly_minutes')}
                        onKeyPress={handleKeyPress}
                        placeholder={i18n._(t`Monthly Minutes`)} />
                    {validator.message(i18n._(t`monthly_minutes`), values.monthly_minutes, 'required')}
                </div> 
                {/* <div className="input-wrapper margin-bottom-10 col-md-6">
                <Label><Trans>Number of Experts per Group</Trans>*</Label> 
                    <input className='signup-input-field'
                        id="number_of_experts"
                        name={i18n._(t`number_of_experts`)}
                        type="number"
                        value={values.number_of_experts}
                        onChange={onChangeForm('number_of_experts')}
                        onKeyPress={handleKeyPress}
                        placeholder={i18n._(t`Number of Experts per Group`)} />	
                    {validator.message(i18n._(t`number_of_experts`), values.number_of_experts, 'required')}
                </div>  */}
            </Row>
            <Row>
                <div className="input-wrapper margin-bottom-10 col-md-6">
                    <FormGroup>
                        <Label><Trans>Start Date</Trans>*</Label> 
                        <input className='signup-input-field'
                            type="date"
                            name={i18n._(t`start_date`)}
                            id="start_date"
                            placeholder={i18n._(t`date placeholder`)}
                            onChange={onChangeForm('start_date')}
                            onKeyPress={handleKeyPress}
                            value={values.start_date}
                            disabled={true}
                        />	
                        {validator.message(i18n._(t`start_date`), values.start_date, 'required')}
                    </FormGroup>
                </div> 
                <div className="input-wrapper margin-bottom-10 col-md-6">
                    <FormGroup>
                    <Label><Trans>End Date</Trans>*</Label>
                    <DatePicker
                        selected={values.end_date}
                        onChange={onHandleDateChange('end_date')}
                        dateFormat="dd-MM-yyyy"
                        minDate={values.start_date}
                        locale={getDefaultLocale()}
                    />
                    {validator.message(i18n._(t`end_date`), values.end_date, 'required')}
                    </FormGroup>
                </div> 
            </Row>
            <Row>
                <div key="pass_code" className="input-wrapper margin-bottom-10 col-md-12">
                    <input className='signup-input-field'
                        id="pass_code"
                        name="pass_code"
                        type="text"
                        value={values.pass_code}
                        onChange={onChangeForm('pass_code')}
                        onKeyPress={handleKeyPress}
                        placeholder="Pass Code"
                    />
                    {validator.message(`pass_code`, values.pass_code, 'required')}		
                </div>
            </Row>
            {/*
            <FormGroup>
                <Label for="customizable" className="mr-3"><Trans>Streaming mode is customizable</Trans></Label>
                <CustomInput type="checkbox" id="customizable" label={values.is_customizable ? 'Yes' : 'No'} name="is_customizable" checked={values.is_customizable} value={values.is_customizable} onChange={onChangeForm('is_customizable')} inline />
            </FormGroup>
            <Row>
                <div className="input-wrapper margin-bottom-10 col-5">
                    <FormGroup>
                        <Label for="gps" className="mr-3"><Trans>Enable Jobs</Trans></Label>
                        <CustomInput type="checkbox" id="gps" label={values.is_gps_enabled ? 'Yes' : 'No'} name="is_gps_enabled" checked={values.is_gps_enabled} value={values.is_gps_enabled} onChange={onChangeForm('is_gps_enabled')} inline />
                    </FormGroup>
                </div>
                { values.is_gps_enabled === true ?
                    <div className="input-wrapper margin-bottom-10 col-7">
                        <FormGroup>
                            <Label for="offline_jobs" className="mr-3"><Trans>Enable Offline Jobs</Trans></Label>
                            <CustomInput type="checkbox" id="offline_jobs" label={values.is_offline_jobs_enabled ? 'Yes' : 'No'} name="is_offline_jobs_enabled" checked={values.is_offline_jobs_enabled} value={values.is_offline_jobs_enabled} onChange={onChangeForm('is_offline_jobs_enabled')} inline />
                        </FormGroup>
                    </div>
                    :
                    null
                }                
            </Row>
            <FormGroup>
                <Label for="assign_jobs" className="mr-3"><Trans>Assign Jobs To All Users</Trans></Label>
                <CustomInput type="checkbox" id="assign_jobs" label={values.assign_jobs_to_all_users ? 'Yes' : 'No'} 
                    name="assign_jobs_to_all_users" checked={values.assign_jobs_to_all_users} 
                    value={values.assign_jobs_to_all_users} onChange={onChangeForm('assign_jobs_to_all_users')} 
                    inline 
                />
            </FormGroup>
            <FormGroup>
                <Label for="asset_scan" className="mr-3"><Trans>Scan Asset</Trans></Label>
                <CustomInput type="checkbox" id="asset_scan" label={values.asset_scan ? 'Yes' : 'No'} 
                    name="asset_scan" checked={values.asset_scan} 
                    value={values.asset_scan} onChange={onChangeForm('asset_scan')} 
                    inline 
                />
            </FormGroup>
            <div>
                <Label><Trans>Streaming Medium</Trans>*</Label>
                <div style={{marginTop: 5, marginBottom: 20}}>
                    <Select
                        value={values.streaming_medium}
                        components={makeAnimated()}
                        onChange={handleSelect('streaming_medium')}
                        placeholder={i18n._(t`Select a streaming medium`)}
                        options={values.streamimgMediums}
                        noOptionsMessage={() => i18n._(t`No streaming medium available`) }
                    />
                </div> 
            </div>
            <FormGroup>
                <Label for="is_solo_program" className="mr-3"><Trans>Solo Program</Trans></Label>
                <CustomInput type="checkbox" id="is_solo_program" label={values.is_solo_program ? 'Yes' : 'No'} 
                    name="is_solo_program" checked={values.is_solo_program} 
                    value={values.is_solo_program} onChange={onChangeForm('is_solo_program')} 
                    inline 
                />
            </FormGroup>  
            <FormGroup>
                <Label for="guest_user_enabled" className="mr-3"><Trans>Allow Guest Users</Trans></Label>
                <CustomInput type="checkbox" id="guest_user_enabled" label={values.guest_user_enabled ? 'Yes' : 'No'} 
                    name="guest_user_enabled" checked={values.guest_user_enabled} 
                    value={values.guest_user_enabled} onChange={onChangeForm('guest_user_enabled')} 
                    inline 
                />
            </FormGroup>
            <FormGroup>
                <Label for="expert_ss_enabled" className="mr-3"><Trans>Allow Expert Screen Share</Trans></Label>
                <CustomInput type="checkbox" id="expert_ss_enabled" label={values.expert_ss_enabled ? 'Yes' : 'No'} 
                    name="expert_ss_enabled" checked={values.expert_ss_enabled} 
                    value={values.expert_ss_enabled} onChange={onChangeForm('expert_ss_enabled')} 
                    inline 
                />
            </FormGroup>
            <FormGroup>
                <Label for="technician_ss_enabled" className="mr-3"><Trans>Allow Remote User Screen Share</Trans></Label>
                <CustomInput type="checkbox" id="technician_ss_enabled" label={values.technician_ss_enabled ? 'Yes' : 'No'} 
                    name="technician_ss_enabled" checked={values.technician_ss_enabled} 
                    value={values.technician_ss_enabled} onChange={onChangeForm('technician_ss_enabled')} 
                    inline 
                />
            </FormGroup>          
            <FormGroup>
                <Label for="check_password_expiry" className="mr-3"><Trans>Check Password Expiry</Trans></Label>
                <CustomInput type="checkbox" id="check_password_expiry" label={values.check_password_expiry ? 'Yes' : 'No'} 
                    name="check_password_expiry" checked={values.check_password_expiry} 
                    value={values.check_password_expiry} onChange={onChangeForm('check_password_expiry')} 
                    inline 
                />
            </FormGroup>
            {values.check_password_expiry ?
            <Row>
                <div className="input-wrapper margin-bottom-10 col-md-12">
                        <input
                            type="number" 
                            className='signup-input-field'
                            id="password_expiry_duration"
                            name={i18n._(t`password_expiry_duration`)}
                            value={values.password_expiry_duration}
                            onChange={onChangeForm('password_expiry_duration')}
                            onKeyPress={handleKeyPress}
                            placeholder={i18n._(t`Password Expiry Duration`)}
                            min="1"
                        />	
                        {validator.message(i18n._(t`password_expiry_duration`), values.password_expiry_duration, 'required|numeric|min:1,num')}
                </div> 
            </Row>
            : ''}
            <FormGroup>
                <Label for="screen_recording_enabled" className="mr-3"><Trans>Allow Screen Recording</Trans></Label>
                <CustomInput type="checkbox" id="screen_recording_enabled" label={values.screen_recording_enabled ? 'Yes' : 'No'} 
                    name="screen_recording_enabled" checked={values.screen_recording_enabled} 
                    value={values.screen_recording_enabled} onChange={onChangeForm('screen_recording_enabled')} 
                    inline 
                />
            </FormGroup>
            <FormGroup>
                <Label for="zoom_enabled" className="mr-3"><Trans>Allow Zoom</Trans></Label>
                <CustomInput type="checkbox" id="zoom_enabled" label={values.zoom_enabled ? 'Yes' : 'No'} 
                    name="zoom_enabled" checked={values.zoom_enabled} 
                    value={values.zoom_enabled} onChange={onChangeForm('zoom_enabled')} 
                    inline 
                />
            </FormGroup>
            <FormGroup>
                <Label for="torch_enabled" className="mr-3"><Trans>Allow Torch</Trans></Label>
                <CustomInput type="checkbox" id="torch_enabled" label={values.torch_enabled ? 'Yes' : 'No'} 
                    name="torch_enabled" checked={values.torch_enabled} 
                    value={values.torch_enabled} onChange={onChangeForm('torch_enabled')} 
                    inline 
                />
            </FormGroup>
            <FormGroup>
                <Label for="annotation_color_enabled" className="mr-3"><Trans>Allow Annotation Color Change</Trans></Label>
                <CustomInput type="checkbox" id="annotation_color_enabled" label={values.annotation_color_enabled ? 'Yes' : 'No'} 
                    name="annotation_color_enabled" checked={values.annotation_color_enabled} 
                    value={values.annotation_color_enabled} onChange={onChangeForm('annotation_color_enabled')} 
                    inline 
                />
            </FormGroup>
            <FormGroup>
                <Label for="annotation_shape_enabled" className="mr-3"><Trans>Allow Annotation Shape Change</Trans></Label>
                <CustomInput type="checkbox" id="annotation_shape_enabled" label={values.annotation_shape_enabled ? 'Yes' : 'No'} 
                    name="annotation_shape_enabled" checked={values.annotation_shape_enabled} 
                    value={values.annotation_shape_enabled} onChange={onChangeForm('annotation_shape_enabled')} 
                    inline 
                />
            </FormGroup>
            <div>
                <Label><Trans>Audio Mode</Trans>*</Label>
                <div style={{marginTop: 5, marginBottom: 20}}>
                    <Select
                        value={values.audio_mode}
                        components={makeAnimated()}
                        onChange={handleSelect('audio_mode')}
                        placeholder={i18n._(t`Select a audio mode`)}
                        options={values.audioModes}
                        noOptionsMessage={() => i18n._(t`No audio mode available`) }
                    />
                </div> 
            </div>
            <Row>
                <div key="license_key" className="input-wrapper margin-bottom-10 col-md-12">
                    <Label for="license_key"><Trans>License Key</Trans></Label>
                    <input className='signup-input-field'
                        id="license_key"
                        name={i18n._(t`license_key`)}
                        type="text"
                        value={values.license_key}
                        onChange={onChangeForm('license_key')}
                        placeholder={i18n._(t`License Key`)} />
                </div>
            </Row>
            <Row>
                <div className="input-wrapper margin-bottom-10 col-md-6">
                    <FormGroup>
                        <Label><Trans>Install By Date</Trans></Label> 
                        <DatePicker
                            selected={values.install_by_date}
                            onChange={onHandleDateChange('install_by_date')}
                            dateFormat="dd-MM-yyyy"
                            locale={getDefaultLocale()}
                        />
                    </FormGroup>
                </div> 
            </Row>
            <Row>
                <div key="behaviour" className="input-wrapper margin-bottom-10 col-md-12">
                    <Label for="behaviour"><Trans>Behaviour</Trans></Label>
                    <input className='signup-input-field'
                        id="behaviour"
                        name={i18n._(t`behaviour`)}
                        type="text"
                        value={values.behaviour}
                        onChange={onChangeForm('behaviour')}
                        placeholder={i18n._(t`Behaviour`)} />
                </div>
            </Row>
            <FormGroup>
                <Label for="swap_camera_enabled" className="mr-3"><Trans>Allow Swap Camera</Trans></Label>
                <CustomInput type="checkbox" id="swap_camera_enabled" label={values.swap_camera_enabled ? 'Yes' : 'No'} 
                    name="swap_camera_enabled" checked={values.swap_camera_enabled} 
                    value={values.swap_camera_enabled} onChange={onChangeForm('swap_camera_enabled')} 
                    inline 
                />
            </FormGroup>
            <FormGroup>
                <Label for="expert_can_disconnect_user" className="mr-3"><Trans>Allow Experts to cancel users from session</Trans></Label>
                <CustomInput type="checkbox" id="expert_can_disconnect_user" label={values.expert_can_disconnect_user ? 'Yes' : 'No'} 
                    name="expert_can_disconnect_user" checked={values.expert_can_disconnect_user} 
                    value={values.expert_can_disconnect_user} onChange={onChangeForm('expert_can_disconnect_user')} 
                    inline 
                />
            </FormGroup>
            <FormGroup>
                <Label for="can_turn_off_user_video" className="mr-3"><Trans>Allow Users to turn off video</Trans></Label>
                <CustomInput type="checkbox" id="can_turn_off_user_video" label={values.can_turn_off_user_video ? 'Yes' : 'No'} 
                    name="can_turn_off_user_video" checked={values.can_turn_off_user_video} 
                    value={values.can_turn_off_user_video} onChange={onChangeForm('can_turn_off_user_video')} 
                    inline 
                />
            </FormGroup>
            <div>
                <Label><Trans>Reset Password</Trans></Label>
                <div style={{marginTop: 5, marginBottom: 20}}>
                    <Select
                        value={values.reset_user_password}
                        components={makeAnimated()}
                        onChange={handleSelect('reset_user_password')}
                        placeholder={i18n._(t`Select reset password`)}
                        options={values.reset_password_options}
                        noOptionsMessage={() => i18n._(t`No reset password option available`) }
                    />
                </div> 
            </div>
            <div>
                <Label><Trans>Download Usage</Trans></Label>
                <div style={{marginTop: 5, marginBottom: 20}}>
                    <Select
                        value={values.download_usage}
                        components={makeAnimated()}
                        onChange={handleSelect('download_usage')}
                        placeholder={i18n._(t`Select download usage`)}
                        options={values.download_usage_options}
                        noOptionsMessage={() => i18n._(t`No download usage option available`) }
                    />
                </div> 
            </div>
            <FormGroup>
                <Label for="invite_online_user" className="mr-3"><Trans>Invite Online User</Trans></Label>
                <CustomInput type="checkbox" id="invite_online_user" label={values.invite_online_user ? 'Yes' : 'No'} 
                    name="invite_online_user" checked={values.invite_online_user} 
                    value={values.invite_online_user} onChange={onChangeForm('invite_online_user')} 
                    inline 
                />
            </FormGroup>
            {values.id ? 
                <>
                    <FormGroup>
                        <Label for="allow_custom_logo" className="mr-3"><Trans>Allow Rebranding</Trans></Label>
                        <CustomInput type="checkbox" id="allow_custom_logo" label={values.allow_custom_logo ? 'Yes' : 'No'} 
                            name="allow_custom_logo" checked={values.allow_custom_logo} 
                            value={values.allow_custom_logo} onChange={onChangeForm('allow_custom_logo')} 
                            inline 
                        />
                    </FormGroup>
                    {values.allow_custom_logo ?
                        <Row className="form-group">
                            <div className="col-4"><button className={"btn btn-info"} onClick={openLogoFileUpload({fieldName:'jobs_logo_url'})} disabled={values && values.jobs_logo_url && values.jobs_logo_url !== ''}><Trans>Attach jobs logo</Trans></button></div>
                            <div className="col-7 mt-1">{values && values.jobs_logo_url && values.jobs_logo_url !== '' ? JSON.parse(values.jobs_logo_url).name : ''}</div>
                            <div className="col-1 mt-1">
                                {values.jobs_logo_url && values.jobs_logo_url !== ''? 
                                    <I18n>
                                        {({ i18n }) =>
                                            <span data-rh={i18n._(t`Delete logo`)} className="pointer" onClick={(e) => detachLogoFiles({e, fieldName:'jobs_logo_url'})}>
                                                <i className="far fa-trash-alt red-trash"></i>
                                            </span>
                                        }
                                    </I18n>
                                    : null
                                }
                            </div>
                        </Row>
                    : ''}
                </>
                            :''}*/}
        </Fragment>
    }
    </I18n>
);



export default EnterpriseRequestForm;