/**TP-3328 */
import React from 'react';
import { Row, Col, Modal, ModalBody, UncontrolledTooltip } from 'reactstrap';
import './ToggleCheckBox.css'
import { I18n} from "@lingui/react";
import { t } from '@lingui/macro';

const ToggleCheckBox = (props) => {
    return <div>
        <I18n>
        {({ i18n }) =>                                       
            <Row className="pd-1">
                <Col sm="8">
                    <label className={/*TP-3871*/props.disableCheckBox ? "col-xs-3 grayed-class" : "col-xs-3"} htmlFor={props.id}>{processLanguage(props.label, i18n)}&nbsp;</label>
                </Col>
                <Col sm="4">
                    <label className="col-xs-4 col-xs-offset-2 switch">
                        <input type="checkbox" id={props.id}
                        name={props.id}
                        onChange={props.toggleCheckBox}
                        checked={props.value}
                        disabled={props.disableCheckBox}                   
                        />
                        <span className="slider round"></span>
                    </label>                
                </Col>
            </Row>
        }
        </I18n>
  </div>
}

function processLanguage(status, i18n){
    if(status === "Allow My Video Feed") {
        return i18n._(t`Allow My Video Feed`); 
    } else if (status === "Allow Auto Connect") {
        return i18n._(t`Allow Auto Connect`); 
    } else if (status === "Disable Notifications") {
        return i18n._(t`Disable Notifications`); 
    }
}

export default ToggleCheckBox;