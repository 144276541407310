import React, { Fragment } from 'react';
import './Groups.scss';
import { printConsole, getHostAbbreviationForSocket } from '../helpers';
import ReactPaginate from 'react-paginate';
import AddGroup from './AddGroup';
import EditGroup from './EditGroup';
import {AccountConsumer} from"../AccountContext";
import SortIcons from './SortIcons';

import AddButton from'./Add.png';
import { NoDataFoundRow } from './TableRow';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { Modal, ModalHeader, ModalBody, 
    ListGroup, ListGroupItem, Button as ButtonB, UncontrolledTooltip,
    ModalFooter, Spinner } from 'reactstrap';
import ReactHintFactory from 'react-hint';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import QrReader from 'react-qr-reader';
import Button from '../Button/Button';
import Loader from 'react-loader-spinner';
import FileViewer from 'react-file-viewer';
import threeEntryPoint from "../Three/threeEntryPoint";
import * as fileDialog from 'file-dialog';
import {diagonastics, diagnostics} from "../DiagonasticHelper";
import ChatGroup from './ChatGroup';
const DetectRTC = require('detectrtc');

const ReactHint = ReactHintFactory(React);

const swapArrayElements = function(arr, indexA, indexB) {
  let temp = arr[indexA];
  arr[indexA] = arr[indexB];
  arr[indexB] = temp;
  return arr;
};

class Groups extends React.Component { //= ({ onRouteChange, route, email }) => {
    _isMounted = false;
    constructor(props){
        super(props);
        this.state = {
            bSubmitted: false,
            bResponded: false,
            errStrReturned: '...',
            retCode: 400,
            bEditGroup: false,
            mode: 'view',
            userCallResponse: '',
            isLoading: false,
            pageCount: 0,
            total: 0,
            pagination: {
                currentPage: 1,
                perPage: props.maxPerPage ? props.maxPerPage : 10, //TP-2883
            },
            searchStr: '',
            sortColumn: [],
            grpSearch: [],
            groupInfo: [{
                groupid: '',
                isadmin: '',
                deleted_on: '',
                group_name: '',
                description: '',
                isongoingnow: 'false',
                streamin_mode: 'routed',
                users: []               
            }], //data_array: [groupInfo]
            userdata: [{
                groupid: '',
                user_id: '',
                isadmin: false,
                isexpert: false,
                first_name: '',
                last_name: '',
                email: '',
                isonline: false
            }],
            selectedGroup: null,
            selectedExpert: [],
            popoverOpen: false,
            isOpenQRScanner: false,
            isOffline: false,
            isOpen3DModal: false,
            URLs: [],
            fileNameArr: [],
            showFileViewer: false,
            calleeCount: 0,
            acceptedCalleeCount: 0,
            usersInSession: [],
            isIOS: false,
            callTimedOut: false, //NS2-407,
            webcamAvailable: false,
            micAvailable: false,
            caleeDecList: [] //TP-5903
        }
        this.sceneManager = null;
    }
    popoverRefs = [];
    popoverWrapperRefs = [];

    setPopoverRef = (ref) => {
        this.popoverRefs.push(ref);
    };

    setPopoverWrapperRef = (ref) => {
        this.popoverWrapperRefs.push(ref);
    }
    
    onActionClick = async ({ selectedGroup, action }) => {
        const {isexpert, session_type} = this.props; //TP-2539
        //const selectedGroup = this.state.groupInfo[index];
        this.setState({
            selectedGroup
        });
        switch (action) {
            case 'edit':
                this.setState({mode: 'edit'});
                break;
            case 'delete':
                this.setState({mode: 'delete'});
                break;
            case 'play':
                //console.log(selectedGroup);
                /* diagonastics(this.props.authService,{
                    action: `${action} clicked on groups tab`,
                    next_step: 'initializing open tok',
                    data: {group_name: selectedGroup.group_name},
                    error: 'none'
                }) */
                /**TP-2539 */
                //TP-3119 & TP-3156
                if (isexpert && (session_type === "CM" || (session_type === "RM" && this.props.myCameraOn() === true /**TP-3419*/))) {
                    const cameraAvailable = await this.checkVideoAvialble();
                    if (!cameraAvailable){
                        this.props.setCameraResolution(0, 0); //TP-5429
                        //this.setState({bResponded: true, errStrReturned: i18nMark('DevicesNotFoundError')})// MB2-459
                        //return false;
                    }
                    //TP-5429 -- Only check if mic is connected or not
                    const micAvailable = await this.checkAudioAvailable();
                    if (!micAvailable) {
                        this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                        this.setState({ micAvailable: false }) //TP-5436
                        return false;
                    }
                    this.getSessionTokenForGroupId(selectedGroup, 'callUser');
                } else if(isexpert && session_type === "RM"){
                    // TP-3156 & TP-614
                    const micAvailable = await this.checkAudioAvailable();
                    if (!micAvailable) {
                        this.setState({ bResponded: true, errStrReturned: i18nMark('MicrophoneNotFoundError')})
                        this.setState({ micAvailable: false }) //TP-5436
                        return false;
                    }
                    
                    this.getSessionTokenForGroupId(selectedGroup, 'callUser')
                    /*this.props.loadSelectedGroup(selectedGroup.groupid, selectedGroup.group_name);
                    this.props.onRouteChange('session');
                    document.removeEventListener('mousedown', this.handleClick, false); */
                }else {
                    const cameraAvailable = await this.checkVideoAvialble();
                    if (!cameraAvailable){
                        this.setState({bResponded: true, errStrReturned: i18nMark('DevicesNotFoundError')})// MB2-459
                        this.setState({ webcamAvailable: false }) //TP-5436
                        return false;
                    }
                    //TP-3303 -- Implementation of the tech2tech call feature
                    if (this.props.audio_mode === "tech2Tech") {
                        const allusers = selectedGroup.users;
                        this.setState({ selectedGroupId: selectedGroup.groupid }); //TP-1764
                        const calleeCount = this.onlineGroupUsers(allusers).length;
                        if (calleeCount === 0) {
                            //TP-5023
                            const msg = i18nMark(`No users were able to be contacted, please try again later.`);
                            this.props.isUserCall(false); //TP-5036
                            this.props.updateCallDialogStatus(true); //TP-1462
                            this.setState({
                                mode: "callUser",
                                calleeCount, 
                                acceptedCalleeCount: calleeCount, 
                                callTimedOut: false,
                                userCallResponse: msg
                            });
                        } else {
                            this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), groupId: selectedGroup.groupid }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                            //printConsole("Calling all users");
                            //printConsole(this.onlineGroupUsers(allusers));
                            //TP-5903 -- Only send the connect-the call to the online users of the group
                            const onlineAllUsers = this.onlineGroupUsers(allusers);
                            onlineAllUsers.forEach((u, ii)=> {
                                //printConsole(`connect-the-call:  { recipientEmail: ${u.email}, callerName: ${this.props.adminname}, isCancelled: false, group_id: ${selectedGroup.groupid} }`)
                                this.props.authService.socket.emit('connect-the-call', {
                                    recipientEmail: u.email, 
                                    callerName: this.props.adminname, 
                                    isCancelled: false,
                                    group_id: selectedGroup.groupid
                                });
                                if (this.props.log_level >= 3) {
                                    diagonastics(this.props.authService,{
                                        action: `connect-the-call is emitted`,
                                        sequence: 16,
                                        next_step: '',
                                        data: `{ recipientEmail: ${u.email}, callerName: ${this.props.adminname}, isCancelled: false, group_id: ${selectedGroup.groupid} }`,
                                        error: 'none'
                                    })
                                }
                            }) 
                            this.props.isUserCall(false); //TP-5036
                            this.props.updateCallDialogStatus(true); //TP-1462          
                            this.setState({mode: 'callUser', calleeCount, acceptedCalleeCount: calleeCount, selectedExpert: allusers, userCallResponse: '', callTimedOut:false});
                        }
                    } else {
                        // there can be multiple experts
                        const experts = this.getExperts(selectedGroup);
                        this.setState({ selectedGroupId: selectedGroup.groupid }); //TP-1764
                        const onlineexperts = this.onlineGroupUsers(experts);
                        const calleeCount = this.onlineGroupUsers(experts).length;
                        if (calleeCount === 0) {
                            //TP-5023
                            const msg = i18nMark(`No users were able to be contacted, please try again later.`);
                            this.props.isUserCall(false); //TP-5036
                            this.props.updateCallDialogStatus(true); //TP-1462
                            this.setState({
                                mode: "callUser",
                                calleeCount, 
                                acceptedCalleeCount: calleeCount, 
                                callTimedOut: false,
                                userCallResponse: msg
                            });
                        } else {
                            this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), groupId: selectedGroup.groupid }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                            //printConsole("Calling experts");
                            //printConsole(onlineexperts);
                            //TP-5903 -- Only send the connect-the call to the online users of the group
                            const onlineExperts = this.onlineGroupUsers(experts);
                            onlineExperts.forEach((u, ii)=> {
                                //printConsole(`connect-the-call:  { recipientEmail: ${u.email}, callerName: ${this.props.adminname}, isCancelled: false, group_id: ${selectedGroup.groupid} }`)
                                this.props.authService.socket.emit('connect-the-call', {
                                    recipientEmail: u.email, 
                                    callerName: this.props.adminname, 
                                    isCancelled: false,
                                    group_id: selectedGroup.groupid
                                });
                                if (this.props.log_level >= 3) {
                                    diagonastics(this.props.authService,{
                                        action: `connect-the-call is emitted`,
                                        sequence: 16,
                                        next_step: '',
                                        data: `{ recipientEmail: ${u.email}, callerName: ${this.props.adminname}, isCancelled: false, group_id: ${selectedGroup.groupid} }`,
                                        error: 'none'
                                    })
                                }
                            }) 
                            this.props.isUserCall(false); //TP-5036
                            this.props.updateCallDialogStatus(true); //TP-1462          
                            this.setState({mode: 'callUser', calleeCount, acceptedCalleeCount: calleeCount, selectedExpert: experts, userCallResponse: '', callTimedOut:false});
                        }
                    }
                }
                break;
            case 'chat':
                /**TP-6771 */
                if (isexpert) {
                    const allusers = selectedGroup.users;
                    const onlineAllUsers = this.onlineGroupUsers(allusers);
                    this.setState({mode: 'chat', onlineAllUsers: onlineAllUsers});
                } else {
                    if (this.props.audio_mode === "tech2Tech") {
                        const allusers = selectedGroup.users;
                        this.setState({ selectedGroupId: selectedGroup.groupid }); //TP-1764
                        const calleeCount = this.onlineGroupUsers(allusers).length;
                        if (calleeCount === 0) {
                            //TP-5023
                            const msg = i18nMark(`No users were able to be contacted, please try again later.`);                            
                            this.setState({
                                mode: "callUser",
                                
                            });
                        } else {
                            //TP-5903 -- Only send the connect-the call to the online users of the group
                            const onlineAllUsers = this.onlineGroupUsers(allusers);
                            this.setState({mode: 'chat', onlineAllUsers});
                        }
                    } else {
                        // there can be multiple experts
                        const experts = this.getExperts(selectedGroup);
                        this.setState({ selectedGroupId: selectedGroup.groupid }); //TP-1764
                        const onlineexperts = this.onlineGroupUsers(experts);
                        const calleeCount = this.onlineGroupUsers(experts).length;
                        if (calleeCount === 0) {
                            //TP-5023
                            const msg = i18nMark(`No users were able to be contacted, please try again later.`);
                            
                            this.setState({
                                mode: "callUser",
                                
                            });
                        } else {
                            //TP-5903 -- Only send the connect-the call to the online users of the group
                            const onlineExperts = this.onlineGroupUsers(experts);
                            this.setState({mode: 'chat', onlineAllUsers: onlineExperts});
                        }
                    }
                }
                //this.setState({mode: 'chat'});
                break;
            default: 
                break;
        }        
    }

    getSessionTokenForGroupId = (selectedGroup, action) => {
        const {isexpert} = this.props;
        const groupId = selectedGroup.groupid;
        this.setState({ selectedGroupId: groupId }); //TP-1764
        let fetchString = 'sessiondataH364?groupID=';
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.userid;
        printConsole(`sessionData is called with payload ---> { groupID: ${groupId}, loggedInUserId: ${this.props.userid} }`)
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            if(response.status === 200) 
                return response.json();
            else
				throw response;
        })
        .then((data) => {
            printConsole(`getSessionTokenGroupId call is successful with payload ---> ${data.isongoingnow}, ${data.primary_expert_id}`);
            printConsole(`current user userid: ${this.props.userid}`);
            printConsole(data);
            if (this.props.log_level >= 3) {
                diagnostics(this.props.authService,{
                    action: `sessionData`,
                    sequence: 3,
                    next_step: '',
                    data: data,
                    error: 'none'
                })
            }
            this.props.loadSessionInfo(data);
            if (isexpert) {
                // if session is going in the group no need to do socket call NS2-135
                /* if(data.isongoingnow && (data.primary_expert_id !== 0 && data.primary_expert_id !== null && data.primary_expert_id !== undefined && data.primary_expert_id !== this.props.userid)){
                    const {groupInfo} = this.state;
                    let grpdata = [];
                    if (groupInfo !== undefined) {
                        [grpdata] = groupInfo.filter(g => 
                            g.groupid === parseInt(groupId)
                        )
                    }
                    printConsole("selected groups Info");
                    printConsole(grpdata);
                    printConsole("Session is ongoing!!");
                    this.props.loadGroupUserList(grpdata.users);
                    this.props.loadSelectedGroup(groupId, selectedGroup.group_name);
                    this.props.onRouteChange('session');
                    document.removeEventListener('mousedown', this.handleClick, false);
                } else */ if (data.isongoingnow && (data.primary_expert_id === 0 && data.primary_expert_id === null || data.primary_expert_id === undefined || data.primary_expert_id === this.props.userid)) {
                    //this.clearSessionToken(groupId);
                    //TP-4394 -- Conditionally during Tech2tech enabled sessions if expert (Primary) tries to join/re-join ongoing session
                    // then don't send the end-call & user-is-in-session "false" signals
                    if (data.primary_expert_id === this.props.userid && this.props.audio_mode === "tech2Tech") {
                        printConsole("In tech2tech mode end-call and user-is-in-session false is not to be sent");
                    } else {
                        const signal = selectedGroup.is_onprem ? `end-call`: `OT-end-call`;
                        this.props.authService.socket.emit( signal, {groupId: parseInt(groupId)});
                        this.props.authService.socket.emit('user-is-in-session', { groupId: parseInt(groupId), isInSession: false });
                    }
    
                    // starting the call to all users of that group
                    //this.setPrimaryExpertIDApi(selectedGroup);
                    const users = this.getAllUser(selectedGroup);
                    const onlineUsers = this.onlineGroupUsers(users);
                    this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), groupId }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                    //TP-2381 -- Set callee list so that Group call recipient's isBusy state can be turned true
                    const calleeCount = this.onlineGroupUsers(users).length;
                    if (calleeCount === 0) {
                        //TP-5023
                        const msg = i18nMark(`No users were able to be contacted, please try again later.`);
                        this.props.isUserCall(false); //TP-5036
                        this.props.updateCallDialogStatus(true); //TP-1462
                        this.setState({
                            mode: action,
                            calleeCount, 
                            acceptedCalleeCount: calleeCount, 
                            callTimedOut: false,
                            userCallResponse: msg
                        });
                    } else {
                        const userList = onlineUsers.map(oo => {
                            return oo.email;
                        })
                        this.props.setCalleeList(userList);
                        //printConsole("trying to call the group users");
                        //printConsole(onlineUsers);
                        //TP-5903 -- Only send the connect-the call to the online users of the group
                        const onlineUserList = this.onlineGroupUsers(users);
                        onlineUserList.forEach((u, ii)=> {
                            //printConsole(`connect-the-call:  { recipientEmail: ${u.email}, callerName: ${this.props.adminname}, isCancelled: false, group_id: ${selectedGroup.groupid}, source: "group" }`)
                            this.props.authService.socket.emit('connect-the-call', { recipientEmail: u.email, callerName: this.props.adminname, isCancelled: false, group_id: selectedGroup.groupid, source: "group" });
                            if (this.props.log_level >= 3) {
                                diagonastics(this.props.authService,{
                                    action: `connect-the-call is emitted`,
                                    sequence: 16,
                                    next_step: '',
                                    data: `{ recipientEmail: ${u.email}, callerName: ${this.props.adminname}, isCancelled: false, group_id: ${selectedGroup.groupid} }`,
                                    error: 'none'
                                })
                            }
                        })  
                        this.props.isUserCall(false); //TP-5036
                        this.props.updateCallDialogStatus(true); //TP-1462
                        this.setState({ mode: action, calleeCount, acceptedCalleeCount: calleeCount, userCallResponse: '', callTimedOut: false })// record the number of online user. Will be used by subscribetorespondtocall
                    }
                }else{
                    //this.setPrimaryExpertIDApi(selectedGroup);
                    const users = this.getAllUser(selectedGroup);
                    const onlineUsers = this.onlineGroupUsers(users);
                    this.timer = setTimeout(() => this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), groupId }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                    //TP-2381 -- Set callee list so that Group call recipient's isBusy state can be turned true
                    const calleeCount = this.onlineGroupUsers(users).length;
                    if (calleeCount === 0) {
                        //TP-5023
                        const msg = i18nMark(`No users were able to be contacted, please try again later.`);
                        this.props.isUserCall(false); //TP-5036
                        this.props.updateCallDialogStatus(true); //TP-1462
                        this.setState({
                            mode: action,
                            calleeCount, 
                            acceptedCalleeCount: calleeCount, 
                            callTimedOut: false,
                            userCallResponse: msg
                        });
                    } else {
                        const userList = onlineUsers.map(oo => {
                            return oo.email;
                        })
                        this.props.setCalleeList(userList);//TP-2381
                        //printConsole("trying to call the group users");
                        //TP-5903 -- Only send the connect-the call to the online users of the group
                        const onlineUserList = this.onlineGroupUsers(users);
                        //printConsole(onlineUserList);
                        onlineUserList.forEach((u, ii)=> {
                            printConsole(`connect-the-call:  { recipientEmail: ${u.email}, callerName: ${this.props.adminname}, isCancelled: false, group_id: ${selectedGroup.groupid}, source: "group" }`)
                            this.props.authService.socket.emit('connect-the-call', { recipientEmail: u.email, callerName: this.props.adminname, isCancelled: false, group_id: selectedGroup.groupid, source: "group"  });
                            if (this.props.log_level >= 3) {
                                diagonastics(this.props.authService,{
                                    action: `connect-the-call is emitted`,
                                    sequence: 16,
                                    next_step: '',
                                    data: `{ recipientEmail: ${u.email}, callerName: ${this.props.adminname}, isCancelled: false, group_id: ${selectedGroup.groupid} }`,
                                    error: 'none'
                                })
                            }
                        })        
                        this.props.isUserCall(false); //TP-5036
                        this.props.updateCallDialogStatus(true); //TP-1462
                        this.setState({ mode: action, calleeCount, acceptedCalleeCount: calleeCount, userCallResponse: '', callTimedOut: false })// record the number of online user. Will be used by subscribetorespondtocall

                    }
                }
            } else {
                // Technician Handling

            }
        })
        .catch((error) => {
            // output error in sessiondata
            this.setState({bResponded: true});
            if(error.status === 444) {
                this.setState({errStrReturned: i18nMark('Establishing the live call failed due to a server error (444). Please contact Telepresenz support if this problem persists.')});
            } else {
                this.setState({errStrReturned: i18nMark('The call could not proceed due to some issues.')});
            }
            printConsole(error)
            if (this.props.log_level >= 3) {
                diagonastics(this.props.authService,{
                    action: `sessiondataH364 has returned error`,
                    sequence: 1,
                    next_step: '',
                    data: `groupID: ${groupId}, loggedInUserId: ${this.props.userid}`,
                    error: `error code: ${error.status}`
                })
            }
        })
    }

    getSessionTokenForTechnician = (groupId, groupName, grpUsers) => {
        const {isexpert} = this.props;
        let fetchString = 'sessiondataH364?groupID=';
        fetchString += groupId;
        fetchString += '&loggedInUserId=';
        fetchString += this.props.userid;
        printConsole(`sessionData is called on respond-to-the-call with payload ---> { groupID: ${groupId}, loggedInUserId: ${this.props.userid} }`)
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => {
            if(response.status === 200) 
                return response.json();
            else
				throw response;
        })
        .then((data) => {
            printConsole(`sessionData call is successful with payload ---> ${data.isongoingnow}, ${data.primary_expert_id}`);
            printConsole(`current user userid: ${this.props.userid}`);
            printConsole(data);
            if (this.props.log_level >= 3) {
                diagnostics(this.props.authService,{
                    action: `sessionData`,
                    sequence: 3,
                    next_step: '',
                    data: data,
                    error: 'none'
                })
            }
            this.props.loadSessionInfo(data);
            if (!isexpert) {
                // Technician Handling
                this.props.updateCallDialogStatus(false); //TP-1462
                this.props.loadGroupUserList(grpUsers);
                this.props.loadSelectedGroup(groupId, groupName);
                this.props.onRouteChange('session');
            } else {
                //Expert Handling
                this.props.updateCallDialogStatus(false); //TP-1462
                this.props.loadGroupUserList(grpUsers);
                this.props.loadSelectedGroup(groupId, groupName);
                this.props.onRouteChange('session');
            }
        })
        .catch((error) => {
            // output error in sessiondata
            this.setState({bResponded: true});
            if(error.status === 444) {
                this.setState({errStrReturned: i18nMark('Establishing the live call failed due to a server error (444). Please contact Telepresenz support if this problem persists.')});
            } else {
                this.setState({errStrReturned: i18nMark('The call could not proceed due to some issues.')});
            }
            printConsole(error)
            if (this.props.log_level >= 3) {
                diagonastics(this.props.authService,{
                    action: `sessiondataH364 has returned error`,
                    sequence: 1,
                    next_step: '',
                    data: `groupID: ${groupId}, loggedInUserId: ${this.props.userid}`,
                    error: `error code: ${error.status}`
                })
            }
        })
    }

    //API to reset the primary_expert_id
    setPrimaryExpertIDApi = (selectedGroup, userID) => {
        printConsole(selectedGroup.groupid);
        let fetchString = 'setPrimaryExpertID';
        let primary_expert_id = this.props.userid;
        if (userID !== null && userID !== undefined) 
            primary_expert_id = userID;

        this.props.authService.fetch(fetchString, {
            method: 'put',
            body: JSON.stringify({
                groupID: selectedGroup.groupid,
                primary_expert_id
            })
        })
            .then(response => {
                if (response.status >= 200 && response.status < 300) {
                    //return response.json();
                } else {
                    throw response;
                }
            })
            .catch(err => {
                throw err;
            })
    }

    cancelCall = ({ mode = 'view', msg = '', groupId= '' }) => {
        const {isexpert} = this.props;
        const {selectedGroup} = this.state;
        if (this.props.log_level >= 3) {
            diagonastics(this.props.authService,{
                action: `cancelling the video call`,
                sequence: 3,
                next_step: '',
                data: msg,
                error: 'none'
            })
        }
        if (this.timer) clearTimeout(this.timer);
        this.setState({
            mode: mode,
            isLoading: false,
            userCallResponse: msg,
            calleeCount: 0,
            acceptedCalleeCount: 0,
            callTimedOut: true
        }, () => {
            this.props.updateCallDialogStatus(false); //TP-1462
        })
        // support for multiple expert when tech calls
        //const users = isexpert? this.onlineGroupUsers(this.getAllUser(selectedGroup)) : this.onlineGroupUsers(this.getExperts(selectedGroup))
        const users = isexpert? this.getAllUser(selectedGroup) : this.getExperts(selectedGroup);
        users.forEach((u, ii)=> {
            this.props.authService.socket.emit('connect-the-call', { recipientEmail: u.email, callerName: this.props.adminname, isCancelled: true, source: "group" });
        })
        this.props.setCalleeList(null);//TP-2381
        const isPassive = this.isAnotherExpertInSession(groupId) //TP-1550
        if (isexpert && !isPassive && (groupId !== '' || selectedGroup.groupid !== '' )){
            let group_id = groupId || selectedGroup.groupid
            this.clearSessionToken(group_id)
        }
        // TP-1129 -- Sending Missed Call Notification to all online users 
        // of a Group call if the call is timeout and no one accepts/declines the call
        if (mode === "callUser" || mode === "timeoutCall" /**TP-5891*/) {
            // get the emails of online users in the group who didn't join the call until timeout
            const onlineUsers = isexpert? this.onlineGroupUsers(this.getAllUser(selectedGroup)) : this.onlineGroupUsers(this.getExperts(selectedGroup))
            //TP-5903 -- Send the missed call notification emails only to the online users of the selected Group
            let onlineEmails = onlineUsers.map(o => {
                return o.email;
            });            
            const {caleeDecList} = this.state;
            //console.log(onlineEmails, caleeDecList);
            caleeDecList.forEach(dec => {
                onlineEmails = onlineEmails.filter(o => o !== dec);                
            });
            //console.log(onlineEmails);
            this.props.authService.socket.emit('send-missed-call-notification', { 
                emails: onlineEmails
            });
            this.setState({caleeDecList: []});
        }
    }

    // TP-1550 -- Close the timeout call Dialog box
    closeTimeoutDialog = () => {
        this.setState({
            mode: 'view',
            isLoading: false,
            userCallResponse: ''            
        }, () => {
            this.props.updateCallDialogStatus(false); 
        })
    }

    clearSessionToken = (groupId) => {
        this.props.authService.fetch('updateGroupToken', {
            method: 'post',
            body: JSON.stringify({
                groupId
            })
        })
        .then(response => response.json())
        .then(data => {
            console.info('token cleared from groups tab')
        })
        .catch(err => console.error(err.message))
    }


    onClickAdd = () => {
        // temporary arrangement
        this.setState({bTemporaryCount: this.state.bTemporaryCount+1});
        // add a new group pop-up
        this.setState({mode: 'add'});
    }

    addedGroup = (group) =>{
        this.setState(prevState => ({
            groupInfo: [
                ...prevState.groupInfo,
                group
            ]
        }), ()=>{
            this.props.updateGroupInfo({groupInfo: this.state.groupInfo});
            this.props.getMyGroups({ groupsData: this.state.groupInfo }); //TP-1738
        });
    }

    closeEditGroup = () => {
        this.setState({ mode: 'view', bResponded: false });
        if(this.state.searchStr && this.state.searchStr !== ''){
            this.searchGroups();//TP-2426,2266
        }
    }
    
    onUpdateSuccess = (updatedGroup) => {
        this.props.onUpdateGroup(updatedGroup);
        //TP-4814
        const isPartOfAGroup = updatedGroup.users.find(e => e.value === this.props.userid);
        if (isPartOfAGroup !== undefined) {
            this.props.changeIspartOfaGroup(true)
        } else 
            this.props.changeIspartOfaGroup(false)
        this.getMyGroups();
        //const {searchStr} = this.state;
        /*const groups = this.state.groupInfo.map(e => e.groupid === updatedGroup.groupid ? updatedGroup : e)
        this.setState({
            groupInfo: groups
        }, () => {
            if(searchStr){
                this.searchGroups();
            }
            /*if (this.state.grpSearch.length > 0) {
                this.setState({
                    grpSearch: this.state.grpSearch.filter(e => e.groupid !== this.state.selectedGroup.groupid)
                }) 
            }
        });*/
    }

    onConfirmDeleteGroup = () => {
        this.setState({mode: 'delete'})
    }

    getMyGroups = () => {
        const { perPage } = this.state.pagination;
        const {searchStr} = this.state;
        this.setState({ 
            isLoading: true
        });
        let fetchString = 'getGroupsByAccountId/';
        fetchString += this.props.accountid;
        fetchString += `/${this.props.userid}`;
        this.props.authService.fetch(fetchString, {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            const groups = data.map(e => {
                const [firstUser] = e.users;
                return {
                    ...e,
                    users: !firstUser.value ? [] : e.users
                }
            });
            const total = groups.length;
            this.setState({
                groupInfo:  groups,
                isLoading: false,
                total: total,
                pageCount: Math.ceil(total / perPage)
            }, () => {
                //printConsole(this.state.groupInfo)
                //this.props.updateGroupInfo({groupInfo: this.state.groupInfo});
                this.props.getMyGroups({ groupsData: this.state.groupInfo }); //
                //printConsole(`search string ${searchStr}`);
                if(searchStr){
                    this.searchGroups();
                }
            });
            // if(this.state.groupInfo.length > 0)
            //  this.getUsersByGroupId(this.state.groupInfo[0].groupid, 0);
        })
        .catch(err => printConsole(err.message))
    }

    getGroupsCurrentPage = (groupsData) => {
      const { perPage, currentPage } = this.state.pagination;
      let startIndex = Math.ceil(perPage*(currentPage-1));
      let endIndex = Math.ceil(perPage*(currentPage-1)) + perPage;
      let groupsCurrent = groupsData.slice(startIndex, endIndex);
      return groupsCurrent;
    }

    handlePaginationClick = (data) => {
        let selected = data.selected;
        const { pagination } = this.state;
        this.setState({ 
            pagination: {
                ...pagination,
                currentPage: selected + 1
            }
        }, () => {
            //this.fetchJobs();
        });
    };
    
    componentWillReceiveProps = (nextProps) => {
        if (nextProps.fetchLatest) {
            this.getMyGroups();
        }
        if (nextProps.activePage === 'Groups') {
            document.addEventListener('mousedown', this.handleClick, false);
        }
        if (nextProps.activePage !== 'Groups') {
            document.removeEventListener('mousedown', this.handleClick, false);
        }
        /**TP-2883 */
        if(nextProps.maxPerPage !== this.props.maxPerPage) {
            this.setState({ pagination: { currentPage: 1/* this.state.pagination.currentPage */, perPage: nextProps.maxPerPage }}, () => {
                const {perPage} = this.state.pagination;
                if (this.state.groupInfo && this.state.groupInfo.length > 1) {
                    const total = this.state.groupInfo.length
                    //console.log(total, perPage);
                    this.setState({
                        pageCount: Math.ceil(total / perPage)
                    }, () => {
                        this.getGroupsCurrentPage(this.state.groupInfo);
                        this.handlePaginationClick({selected: 0})
                    });
                } else {
                    this.getMyGroups();
                }
            });
        }
    }

    getUsersByGroupId = (groupID, iter) => {
        //printConsole('getusersbygroupid() called with groupid, iter = ', groupID, iter);
        if(groupID !== undefined && groupID !== null && groupID > 0){
            let fetchString = 'getusersbygroupid/';
            fetchString += groupID; //by user id

            this.props.authService.fetch(fetchString, {
                method: 'get',
            })
            .then(response => response.json())
            .then(data => {
                //printConsole('getusersbygroupid returned for groupid(iter): ', groupID, iter, data);
                if(data !== undefined && data !== null && data.length > 0){
                    if(iter === 0){
                        this.setState({userdata: data});
                    }
                    else
                        this.state.userdata.push.apply(this.state.userdata, data);

                    iter++;
                    if(iter < this.state.groupInfo.length)
                        this.getUsersByGroupId(this.state.groupInfo[iter].groupid, iter);
                    // else
                  //        this.populateGroups();
                }
            })
            .catch(err => printConsole(err.message))
        }
    }

    //TP-4511 -- inform back end session has started/ended
    updatedUsage = ({session_started, selectedGroupId}) => {
        let fetchString = 'webrtcSessionEndpoint';  
        //TP-4511  
        const record_id = sessionStorage.getItem("recordId");
        let payload = {
            groupId: selectedGroupId,
            session_started
        }   
        if (typeof(record_id) !== "undefined" && record_id !== null && record_id !== undefined) {
            payload = {
                groupId: selectedGroupId,
                session_started,
                record_id
            }
        }
        printConsole(`Calling the webrtcSessionEndpoint API...after session`)
        this.props.authService.fetch(fetchString, {
            method: 'post',
            body: JSON.stringify(payload)
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                //return response.json();
            } else {
                throw response;
            }
        })            
        .catch(err => {
            throw err; 
        })
    }

    // prepare the group data
    componentWillMount = async () => {
        this.getMyGroups();
        printConsole("Groups will mount...");
        const {isexpert, session_type} = this.props;
        if (isexpert && (session_type === "CM" || (session_type === "RM" && this.props.myCameraOn() === true /**TP-3419*/))) {
            //TP-5431
            const cameraAvailable = await this.checkVideoAvialble();
            if (!cameraAvailable){
                this.props.setCameraResolution(0, 0); //TP-5429            
            }
        }
        if (typeof(Storage) !== "undefined") {
            const selectedGroupId = sessionStorage.getItem("groupId"), isonprem = sessionStorage.getItem("isonprem");//FQ3-318,TP-991
            console.log("check isOnline 123 :", this.props.authService.socket.connected);
            if (this.props.authService.socket.connected === true && typeof(selectedGroupId) !== "undefined" && selectedGroupId !== null && selectedGroupId !== undefined){
                printConsole("Sending the end call & user-is-in-session socket signals if the session ended abruptly");
                this.props.authService.socket.emit('user-is-in-session', { groupId: selectedGroupId, isInSession: false });
                if(isonprem === 'true'){
                    this.props.authService.socket.emit('end-call', { groupId: selectedGroupId });
                    this.updatedUsage({session_started: false, selectedGroupId}); //TP-4511
                }else{
                    this.props.authService.socket.emit('OT-end-call', { groupId: selectedGroupId });
                }
                ['groupId', 'isonprem', 'recordId'].forEach(key =>  sessionStorage.removeItem(key)); //TP-4511
            }
        } else {
            printConsole("Sorry, your browser does not support Web Storage...");
        }
    }

    componentDidMount = ()=> {
        this._isMounted = true;
        const { isexpert, isadmin, onlineUsers } = this.props;
        this.props.authService.socket.on('respond-to-the-call', this.subscribeToRespondToTheCall)
        this.checkPlatform();
        if (isexpert === false) {
            DetectRTC.load(() => {
                this.setState({webcamAvailable: DetectRTC.hasWebcam && DetectRTC.hasMicrophone});
            });
        } else {
            DetectRTC.load(() => {
                this.setState({micAvailable: DetectRTC.hasMicrophone});
            });
        }

    }

    componentDidUpdate = (prevState, prevProps) => {
        if (this.props.onlineUsers !== prevProps.onlineUsers && this.state.mode === "callUser" && this.state.calleeCount > 0) {
            this.checkGroupCallOnlineUsers(); //TP-1764
        }
        if (this.props.openGroupChat !== prevProps.openGroupChat && this.props.openGroupChat === true && this.props.selectedGroup !== null) {
            if (this.state.groupInfo && this.state.groupInfo.length > 0) {
                this.state.groupInfo.forEach((group) => {
                    //if (this.state.mode !== 'chat'){
                        if (group.groupid === this.props.selectedGroup) this.onActionClick({ action: 'chat', selectedGroup: group })
                    /* } else if (this.state.mode === 'chat' && this.state.selectedUser.email !== this.props.selectedUserEmail && user.email === this.props.selectedUserEmail) {
                        //this.onCloseEditModal();
                        this.setState({ selectedUser: {}, mode: 'view'}, () => {
                            this.onActionClick({ action: 'chat', row: user });                            
                        })
                    } */
                }) 
            }
        }
    }

    // TP-614
    checkAudioAvailable = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({
                video: false,
                audio: true
            })
            .then((stream) => {
                stream.getTracks().forEach(track => track.stop());
                resolve(true);
            })
            .catch(err => {
                console.log('cannot access microphone');
                console.log(`Device detection Error :: ${err}`);
                resolve(false)
            });
        });
    }
    // MB2-459
    checkVideoAvialble = () => {
        return new Promise((resolve, reject) => {
            navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            })
            .then((stream) => {
                stream.getTracks().forEach(track => track.stop());
                resolve(true);
            })
            .catch(err => {
                console.log('cannot access camera');
                console.log(`Device detection Error :: ${err}`);
                resolve(false)
            });
        });
    };

    componentWillUnmount = () => {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.handleClick, false); 
        this.props.authService.socket.removeListener('respond-to-the-call', this.subscribeToRespondToTheCall);
        this.popoverRefs.length = 1;
        this.popoverWrapperRefs.length = 1;
    }

    // TP-1534 -- Check if there are other Expert users in that session
    isAnotherExpertInSession = (groupId) => {
        const {inSessionArr, isexpert} = this.props;
        const {groupInfo} = this.state;
        printConsole("in session array");
        printConsole(inSessionArr);
        let flag = false;
        const [particularGroup] = groupInfo.filter(g => g.groupid === groupId)
        if (particularGroup) {
            const expertUsers = this.getExperts(particularGroup);
            printConsole(expertUsers);
            for (let ii = 0; ii<inSessionArr.length; ii++) {
                if (expertUsers.some(ex => ex.email === inSessionArr[ii].userName) === true) {
                    flag = true;
                    break;
                }
                else
                    flag = false;
            }
        }
        printConsole(`is Passive expert ${flag}`);
        return flag;
    }

    // TP-1764 -- To set the calleeCount if any of the recipients go offline while the dialer is on
    // both for Expert & webapp technician side handling
    checkGroupCallOnlineUsers = () => {
        const { groupInfo, selectedGroupId } = this.state;
        let { onlineUsers, email, isexpert, audio_mode /**TP-3303*/ } = this.props;
        let [selGroup] = groupInfo.filter(g => g.groupid === selectedGroupId) //TP-2369
        onlineUsers = onlineUsers.filter(e => e !== email);
        //console.log(onlineUsers);
        let filteredUsers = []; //TP-2369
        //TP-3303 -- Implementation of the tech2tech call feature
        if (isexpert || (!isexpert && audio_mode === "tech2Tech")) {
            filteredUsers = selGroup && selGroup.users.filter(u => onlineUsers.includes(u.email) && u.email !== email)//TP-2369       
        } else {
            //TP-2369
            const experts = this.getExperts(selGroup);
            filteredUsers = experts && experts.filter(u => onlineUsers.includes(u.email));
        }
        //console.log(filteredUsers);
        //console.log(this.state.calleeCount);
        // TP-2369 -- changed the variable name that carries the online group users
        if (this.state.calleeCount > (filteredUsers.length)) {
            printConsole(`new calee count ?? ${filteredUsers.length}`);
            this.setState({ calleeCount: filteredUsers.length }, () => {
                if(this.state.calleeCount <= 0 && selectedGroupId) {
                    this.cancelCall({ mode: 'timeoutCall', msg: i18nMark(`All users went offline`), selectedGroupId })
                }
            });
        }
    }

    subscribeToRespondToTheCall = ({ action, groupName, groupId, recipient, userName }) => {
        // calltimedOut is needed to track whether response is coming beacuse of connect-to-call signal
        // being generated while cancelling the call after timeout
        if (!this._isMounted || this.state.callTimedOut) return;
        let msg;
        const {isexpert} = this.props;
        const {selectedGroup, groupInfo} = this.state;
        if (groupName === undefined && selectedGroup !== undefined && selectedGroup !== null) groupName = selectedGroup.group_name;//TP-1994
        let grpdata = [];
        if (groupInfo !== undefined) {
            [grpdata] = groupInfo.filter(g => 
                g.groupid === parseInt(groupId)
            )
        }
        printConsole("selected groups Info");
        printConsole(grpdata)
        if (this.props.log_level >= 3) {
            diagonastics(this.props.authService,{
                action: `response to the call is received`,
                sequence: 15,
                next_step: '',
                data: `{ action: ${action}, groupName: ${groupName}, groupId: ${groupId}, recipient: ${recipient}, userName: ${userName}}`,
                error: 'none'
            })
        }

        printConsole(`response to the call is received:: payload --> { ${action}, ${groupName}, ${groupId}, ${recipient}, ${userName} }`)
        let isPassive = false;
        // Clear the timer if there is already a timeout.
        // if (this.timer) clearTimeout(this.timer);
        if (action === 'accepted') {
            this.setState((prevState) => (
                {acceptedCalleeCount: prevState.acceptedCalleeCount -1}
            ), () => {
                printConsole(`accepted callee count ${this.state.acceptedCalleeCount}`);
                if (this.state.acceptedCalleeCount == 0 && this.timer) {
                    this.setState({caleeDecList: []}); //TP-5903
                    clearTimeout(this.timer);
                    if (isexpert) {
                        isPassive = this.isAnotherExpertInSession(groupId) //TP-1534
                        if (isPassive) 
                            this.getSessionTokenForTechnician(groupId, groupName, grpdata.users);
                        else {
                            this.props.updateCallDialogStatus(false); //TP-1462
                            this.props.loadGroupUserList(grpdata.users);
                            this.props.loadSelectedGroup(groupId, groupName);
                            this.props.onRouteChange('session');
                        }
                    } else {
                        this.getSessionTokenForTechnician(groupId, groupName, grpdata.users);
                    }
                    return;
                } else if (this.state.acceptedCalleeCount >= 1 && !this.timer) {
                    this.setState({caleeDecList: []}); //TP-5903
                    if (isexpert) {
                        isPassive = this.isAnotherExpertInSession(groupId) //TP-1534
                        if (isPassive)
                            this.getSessionTokenForTechnician(groupId, groupName, grpdata.users);
                        else {
                            this.props.updateCallDialogStatus(false); //TP-1462
                            this.props.loadGroupUserList(grpdata.users);
                            this.props.loadSelectedGroup(groupId, groupName);
                            this.props.onRouteChange('session');
                        }
                    } else {
                        this.getSessionTokenForTechnician(groupId, groupName, grpdata.users);
                    }
                    return;
                } else if (this.state.acceptedCalleeCount === 0 && !this.timer) {
                    this.setState({caleeDecList: []}); //TP-5903
                    if (isexpert) {
                        isPassive = this.isAnotherExpertInSession(groupId) //TP-1534
                        if (isPassive)
                            this.getSessionTokenForTechnician(groupId, groupName, grpdata.users);
                        else {
                            this.props.updateCallDialogStatus(false); //TP-1462
                            this.props.loadGroupUserList(grpdata.users);
                            this.props.loadSelectedGroup(groupId, groupName);
                            this.props.onRouteChange('session');
                        }
                    } else {
                        this.getSessionTokenForTechnician(groupId, groupName, grpdata.users);
                    }
                    return;
                } else if (this.timer) {
                    this.setState({caleeDecList: []}); //TP-5903
                    clearTimeout(this.timer);
                    if (isexpert) {
                        isPassive = this.isAnotherExpertInSession(groupId) //TP-1534
                        if (isPassive)
                            this.getSessionTokenForTechnician(groupId, groupName, grpdata.users);
                        else {
                            this.props.updateCallDialogStatus(false); //TP-1462
                            this.props.loadGroupUserList(grpdata.users);
                            this.props.loadSelectedGroup(groupId, groupName);
                            this.props.onRouteChange('session');
                        }
                    } else {
                        this.getSessionTokenForTechnician(groupId, groupName, grpdata.users);
                    }
                    return;
                }
            })            
        } else if(action === 'declined') {
            // Since there can be multiple experts in a group it is require to take care of callecount
            this.setState((prevState) => (
                {calleeCount: prevState.calleeCount - 1}
            ),() => {
                //TP-2946 -- Remove user who declined from callee list
                if (userName) {
                    //TP-5903 -- Keep track of the users who have declined the call, so they can be removed from the Missed call notification recipient list
                    let {caleeDecList} = this.state;
                    caleeDecList.push(userName);
                    this.setState({ caleeDecList });
                    this.props.removeFromCalleeList(userName);
                }
                if(this.state.calleeCount <= 0){
                    if (this.timer) {
                        clearTimeout(this.timer);
                        const {isexpert} = this.props;
                        const {selectedGroup} = this.state;
                        // get the emails of online users in the group who didn't join the call until timeout
                        const onlineUsers = isexpert? this.onlineGroupUsers(this.getAllUser(selectedGroup)) : this.onlineGroupUsers(this.getExperts(selectedGroup))
                        //TP-5903 -- Send the missed call notification emails only to the online users of the selected Group
                        let onlineEmails = onlineUsers.map(o => {
                            return o.email;
                        });            
                        const {caleeDecList} = this.state;
                        //console.log(onlineEmails, caleeDecList);
                        if (caleeDecList.length > 0) {
                            caleeDecList.forEach(dec => {
                                onlineEmails = onlineEmails.filter(o => o !== dec);                
                            });
                        }
                        //console.log(onlineEmails);
                        if (onlineEmails.length > 0) {
                            this.props.authService.socket.emit('send-missed-call-notification', { 
                                emails: onlineEmails
                            });
                        }
                        this.setState({caleeDecList: []});
                    }
                    this.props.updateCallDialogStatus(false); //TP-1462
                    msg = i18nMark('User has declined your call. Please try later.');
                    /*TP-5903 if (selectedGroup && selectedGroup.groupid !== '') {
                        // TP-1129 -- Sending Missed Call Notification to all online users 
                        // of a Group call if the call is timeout and no one accepts/declines the call
                        const onlineUsers = isexpert? this.onlineGroupUsers(this.getAllUser(selectedGroup)) : this.onlineGroupUsers(this.getExperts(selectedGroup))
                        // get the emails of online users in the group who didn't join the call until timeout
                        const onlineEmails = onlineUsers.map(o => {
                            return o.email;
                        });
                        this.props.authService.socket.emit('send-missed-call-notification', { 
                            emails: onlineEmails
                        });
                    } */
                    // here extra condition s required because selected group is not there.
                    // This gets called when user is called from user and call is declined.
                    if (isexpert && selectedGroup && selectedGroup.groupid !== ''){
                        this.clearSessionToken(selectedGroup.groupid)
                    }
                }
            })
        } else if (action === 'in_session') {
            if(groupId && selectedGroup && groupId === selectedGroup.groupid){
                if (this.timer) clearTimeout(this.timer);//TP-433, we have changed getSessionTokenForGroupId so we need to clear the timer
                if (isexpert) {
                    isPassive = this.isAnotherExpertInSession(groupId) //TP-1534
                    if (isPassive) {
                        this.getSessionTokenForTechnician(groupId, groupName, grpdata.users);
                        document.removeEventListener('mousedown', this.handleClick, false);
                    }
                    else {
                        this.props.loadGroupUserList(grpdata.users);
                        this.props.loadSelectedGroup(selectedGroup.groupid, selectedGroup.group_name);
                        this.props.onRouteChange('session');
                        document.removeEventListener('mousedown', this.handleClick, false);
                    }
                } else {
                    this.props.loadGroupUserList(grpdata.users);
                    this.props.loadSelectedGroup(selectedGroup.groupid, selectedGroup.group_name);
                    this.props.onRouteChange('session');
                    document.removeEventListener('mousedown', this.handleClick, false);
                }
            }else{
                // NS2-407 //wait for all the user is in session signal to come in before showing the message
                this.setState((prevState) => (
                    {calleeCount: prevState.calleeCount - 1}
                ),() => {
                    if(this.state.calleeCount <= 0){
                        if (this.timer) clearTimeout(this.timer);
                        this.props.updateCallDialogStatus(false); //TP-1462
                        msg = i18nMark('User is already on a call with another user, please try again later.');
                        if (isexpert && selectedGroup && selectedGroup.groupid !== ''){
                            this.clearSessionToken(selectedGroup.groupid)
                        }
                    }
                })
            }
        } else if(action === 'auto-decline') {
            //TP-6077 Since there can be multiple experts in a group it is require to take care of callecount
            this.setState((prevState) => (
                {calleeCount: prevState.calleeCount - 1}
            ),() => {
                //TP-2946 -- Remove user who declined from callee list
                if (userName) {
                    //TP-5903 -- Keep track of the users who have declined the call, so they can be removed from the Missed call notification recipient list
                    let {caleeDecList} = this.state;
                    caleeDecList.push(userName);
                    this.setState({ caleeDecList });
                    this.props.removeFromCalleeList(userName);
                }
                if(this.state.calleeCount <= 0){
                    if (this.timer) clearTimeout(this.timer);
                    this.props.updateCallDialogStatus(false); //TP-1462
                    msg = i18nMark('User has declined your call. Please try later.');
                    // here extra condition s required because selected group is not there.
                    // This gets called when user is called from user and call is auto-declined.
                    if (isexpert && selectedGroup && selectedGroup.groupid !== ''){
                        this.clearSessionToken(selectedGroup.groupid)
                    }
                }
            })
        } /* TP-2226 -- Commented out this part of the implementation
        else if (action === 'no_group_found') {
            msg = i18nMark('You are not currently setup to call {recipient}');
            this.props.updateCallDialogStatus(false); //TP-1462
        } */
        /* TP-5903 else if (action === 'timeout') {
            // TP-2344 -- When each of the users in the Group get timed out individually then reduce the calleeC
            // Since there can be multiple experts in a group it is required to take care of calleecount
            this.setState((prevState) => (
                { calleeCount: prevState.calleeCount - 1 }
            ), () => {
                //TP-2946 -- Remove from callee list the user who timed out
                if (userName)
                    this.props.removeFromCalleeList(userName);
                if (this.state.calleeCount <= 0) {
                    if (this.timer) clearTimeout(this.timer);
                    this.props.updateCallDialogStatus(false); //TP-1462
                    msg = i18nMark(`{recipient} did not pick up the call, please try again later.`);
                }
            })
        } */
        this.setState({
            userCallResponse: msg
        });
    }

    handleClick = (e) => {
        for(let i = 0; i<this.popoverWrapperRefs.length; i++) {
            if (this.popoverWrapperRefs[i] && this.popoverWrapperRefs[i].contains(e.target)) {
                continue;
            }
            this.popoverRefs[i] && this.popoverRefs[i].setState({ isOpen: false });
        }
    }

    handleClickOutside = (e) => {
        
    }

    findUserByGroup = (groupid, bFindExpert) => {
        for(let ii = 0; ii < this.state.userdata.length; ii++)
        {
            if(this.state.userdata[ii].groupid === groupid)
            {
                if((bFindExpert && this.state.userdata[ii].isexpert) || 
                    (!bFindExpert && !this.state.userdata[ii].isexpert && !this.state.userdata[ii].isadmin))
                {
                    let userName = this.state.userdata[ii].first_name + ' ' + this.state.userdata[ii].last_name;
                    return userName;
                }
            }
        }
    }

    onPlay(){
        printConsole('onPlay called');
    }

    onEdit(){
        this.setState({bEditGroup: true});
    }

    clearTableContents = () => {
        let groupsTable = document.getElementById("groupsTable");
        let tr = groupsTable.getElementsByTagName("tr");
        let i = 0;
        for (i = 1; i < tr.length; i++) {
            groupsTable.deleteRow(i);
        }
    }

    //TP-2426
    onChangeSearchButton = (e) => {
        this.setState({
            searchStr: e.target.value
        })
    }

    searchGroups = () => {
      const criteriaArr = ['group_name', 'description'];
      let input, filter, i, total;
      const { groupInfo, searchStr } = this.state;
      const { perPage } = this.state.pagination;
      let grpSearch = this.state.grpSearch.slice();
    //   input = document.getElementById("groupSearchBox");
    //   if (input === undefined || input === null) return;
    //   filter = input.value.toUpperCase();
      filter = searchStr ? searchStr.toUpperCase(): '';
      
      if (filter !== ''){
          for (i = 0; i < criteriaArr.length; i++) {
              grpSearch = groupInfo.filter((grps)=> {
                  return grps[criteriaArr[i]].toUpperCase().indexOf(filter) > -1;
              });

              if (grpSearch.length > 0) break; 
          }
      }else{
          grpSearch = [];//Clear the array when search string is ''
      }
      if(JSON.stringify(groupInfo)==JSON.stringify(grpSearch)) {
            // grpSearch = [];//TP-2266
            total = groupInfo.length;

      } else {
            total = grpSearch.length;
      }

      this.setState({
        grpSearch: grpSearch,
        pageCount: Math.ceil(total / perPage),
        // searchStr: filter//TP-2426
      })
    }

    sortGroups = (column) => {
        let x,y,shouldSwitch, i, grpList, searched;
        const { groupInfo, grpSearch } = this.state;
        if (grpSearch.length > 0 ) {
            grpList = grpSearch.slice();
            searched = true;
        } else if (groupInfo.length > 0) {
            grpList = groupInfo.slice();
            searched = false;
        }
        
        let switching = true;
        let switchcount = 0;
        ///Set the sorting direction to ascending:
        let dir = 'asc';
        while (switching) {
            switching = false;
            for (i = 0; i < (grpList.length - 1); i++) {
                //start by saying there should be no switching:
                shouldSwitch = false;
                /*Get the two elements you want to compare,
                one from current row and one from the next:*/
                switch (column) {
                    case 'experts': 
                        x = this.getExpertsCount(grpList[i]).toString();
                        y = this.getExpertsCount(grpList[i+1]).toString();
                    break;
                    case 'technicians':
                        x = this.getUsersCount(grpList[i]).toString();
                        y = this.getUsersCount(grpList[i+1]).toString();
                    break;
                    default:
                        x = grpList[i][column];
                        y = grpList[i + 1][column];
                }
                
                /*check if the two rows should switch place,
                based on the direction, asc or desc:*/
                  if (dir === 'asc') {
                    if (x.toLowerCase() > y.toLowerCase()) {
                      //if so, mark as a switch and break the loop:
                      shouldSwitch= true;
                      break;
                    }
                  } else if (dir === 'desc') {
                    if (x.toLowerCase() < y.toLowerCase()) {
                      //if so, mark as a switch and break the loop:
                      shouldSwitch = true;
                      break;
                    }
                  }
            }

            if (shouldSwitch) {
              /*If a switch has been marked, make the switch
              and mark that a switch has been done:*/
              grpList = swapArrayElements(grpList, i + 1, i);
              switching = true;
              //Each time a switch is done, increase this count by 1:
              switchcount ++;      
            } else {
              /*If no switching has been done AND the direction is "asc",
              set the direction to "desc" and run the while loop again.*/
              if (switchcount === 0 && dir === 'asc') {
                dir = "desc";
                switching = true;
              }
            }
        }

        const sortColumn = {
            [column]: dir
        }

        if (searched) {
            this.setState({
                grpSearch: grpList,
                sortColumn: sortColumn
            })
        } else {
            this.setState({
                groupInfo: grpList,
                sortColumn: sortColumn
            }, () => {
                this.props.updateGroupInfo({groupInfo: this.state.groupInfo});
            })
        }
    }
    
    getUsersCount = ({users}) => {
        return users.filter(u => !u.isexpert).length;
    }

    getExpertsCount = ({users}) => {
        return users.filter(u => u.isexpert).length;
    }

    onDeleteGroup = (e) => {
        e.preventDefault();
        const { selectedGroup, groupInfo, grpSearch } = this.state;
        let {currentPage, perPage} = this.state.pagination;
        let groupList = grpSearch.length > 0 ? grpSearch : groupInfo
        
        if(selectedGroup){
            //add the selected user to the selected group
            this.setState({bSubmitted: true, mode: 'view'});
            this.props.authService.fetch(`deletegroup/${selectedGroup.groupid}`, {
                method: 'delete'
            })
            .then(response => {
                this.setState({retCode: response.status});
                if(response.status === 200){
                    return response.json();
                } else {
                    throw new Error(response.body);
                }
            })
            .then(data => {
                this.setState({bResponded: true});
                const existingGroups = groupList.filter(e => e.groupid !== selectedGroup.groupid)
                let total = existingGroups.length;
                let pageCount = Math.ceil(total / perPage);
                if (grpSearch.length > 0) {
                    this.setState({
                        grpSearch: existingGroups,
                        groupInfo: groupInfo.filter(e => e.groupid !== selectedGroup.groupid),
                        pageCount
                    }, ()=> {
                        this.props.updateGroupInfo({groupInfo: this.state.groupInfo});
                        this.props.getMyGroups({ groupsData: this.state.groupInfo }); //TP-1738
                        if(pageCount !== 0 && pageCount < currentPage){
                             this.handlePaginationClick({selected: currentPage - 2})
                        }
                    }) 
                } else{
                    this.setState({
                        groupInfo: existingGroups,
                        pageCount
                    },() => {
                        this.props.updateGroupInfo({groupInfo: this.state.groupInfo});
                        this.props.getMyGroups({ groupsData: this.state.groupInfo }); //TP-1738
                        if(pageCount !== 0 && pageCount < currentPage){
                            this.handlePaginationClick({selected: currentPage - 2})
                        }
                    });
                }
                //this.getMyGroups();
                if(data !== null && data !== undefined){
                    this.setState({errStrReturned: i18nMark('Selected Group successfully deleted along with all user associations.!')});
                }
            })
            .catch((error) => {
                this.setState({bResponded: true});
                // output error in login
                if(this.state.retCode === 409) {
                    this.setState({errStrReturned: i18nMark('The selected Group could not be deleted due to a conflict.')});
                } else if(this.state.retCode === 404){
                    this.setState({errStrReturned: i18nMark('Selected Group not found.')});
                } else {
                    this.setState({errStrReturned: i18nMark('An internal server error prevented the selected Group from being deleted.')});
                }
            })
        }
    }

    toggle = () => {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        })
    }

    getExperts = (row) => {
        return row.users.filter(u => u.isexpert);
    }

    getUsers = (row) => {
        return row.users.filter(u => !u.isexpert);
    }
    // get the all the users including the expert and technician
    getAllUser = (row) => {
        return row.users.filter(u => u.value !== this.props.userid);
    }
    // get all the group users who are online
    onlineGroupUsers = (users) => {
        const {onlineUsers} = this.props;    
        return users.filter(u => onlineUsers.includes(u.email))
    }

    //TP-6771
    updateChatGroup = (user_email) => {
        this.state.onlineAllUsers.forEach(user => {
            
        })
    }

    checkPlatform = () => {
        let ua = navigator.userAgent.toLowerCase(); 
        let chromeAgent = ua.indexOf("chrome") > -1; 
        let safariAgent = ua.indexOf("safari") > -1; 
        if ((chromeAgent) && (safariAgent)) safariAgent = false; 

        let isIOS = /iPad|iPhone|iPod/.test(navigator.platform) || (safariAgent)
        this.setState({isIOS})
    };

    //TP-6771 -- Implementation of the Chat feature button
    renderChatIcon = ({ row, index }) => {
        const { isexpert, isadmin, onlineUsers, isSmallFormFactor, email, audio_mode /**TP-3303*/ } = this.props;
        const isViewable = row.users.find(e => e.value === this.props.userid);
        const is_onprem = row.is_onprem;
        let isOnlineUser = false;
        if (isexpert) {
            isOnlineUser = this.getUsers(row).find(e => onlineUsers.includes(e.email));
        } else {
            
            if (audio_mode === "tech2Tech")
                isOnlineUser = row.users.filter(u => u.email !== email).find(e => onlineUsers.includes(e.email));
            else
                isOnlineUser = this.getExperts(row).find(e => onlineUsers.includes(e.email));
        }
        if (isViewable && isOnlineUser && !this.props.noInternet && this.props.isConnected) {
            return (
                <I18n>
                    {({ i18n }) =>
                        <>
                            { isSmallFormFactor === false ?
                                <span data-rh={i18n._(t`Chat with group`)} className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'chat', selectedGroup: row})}>
                                    <i className="fas fa-comment-alt"></i>
                                </span>
                                :
                                <span className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'chat', selectedGroup: row})}>
                                    <i className="fas fa-comment-alt"></i>
                                </span>
                            }                                    
                        </>
                    }
                </I18n>
            );
        }else if (isViewable && !isOnlineUser) {
            return (
                <I18n>
                    {({ i18n }) =>
                        <>
                            { isSmallFormFactor === false ?
                                <span data-rh={i18n._(t`Chat with group`)} className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'chat', selectedGroup: row})}>
                                    <i className="fas fa-comment-alt fa-video-disabled"></i>
                                </span>
                                :
                                <span className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'chat', selectedGroup: row})}>
                                    <i className="fas fa-comment-alt fa-video-disabled"></i>
                                </span>
                            }                                    
                        </>                        
                    }
                </I18n>
            );
        }else if (isViewable /*TP-1165*/ && this.props.noInternet || !this.props.isConnected){
            return (
                <I18n>
                    {({ i18n }) =>
                        <>
                            { isSmallFormFactor === false ?
                                <span data-rh={i18n._(t`Chat with group`)} className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'chat', selectedGroup: row})}>
                                    <i className="fas fa-comment-alt fa-video-disabled"></i>
                                </span>
                                :
                                <span className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'chat', selectedGroup: row})}>
                                    <i className="fas fa-comment-alt fa-video-disabled"></i>
                                </span>
                            }                                    
                        </>
                    }
                </I18n>
            );
        }
    }

    renderVideoIcon = ({ row, index }) => {
        const { isexpert, isadmin, onlineUsers, isSmallFormFactor, email, audio_mode, /**TP-3303*/ allow_expert_to_expert_call } = this.props;
        const isViewable = row.users.find(e => e.value === this.props.userid);
        const is_onprem = row.is_onprem;
        let isOnlineUser = false;
        if (isexpert) {
            /**TZ-989 */
            if (allow_expert_to_expert_call === true) {
                isOnlineUser = row.users.find(e => e.email !== email && onlineUsers.includes(e.email))
            } else 
                isOnlineUser = this.getUsers(row).find(e => onlineUsers.includes(e.email));
        } else {
            //TP-3303 -- Implementation of the tech2tech call feature
            if (audio_mode === "tech2Tech")
                isOnlineUser = row.users.filter(u => u.email !== email).find(e => onlineUsers.includes(e.email));
            else
                isOnlineUser = this.getExperts(row).find(e => onlineUsers.includes(e.email));
        }
        //console.log (isOnlineUser);
        if (isViewable && ((this.state.webcamAvailable && !isexpert ) || (this.state.micAvailable && isexpert /* && is_onprem */)) && isOnlineUser && !this.props.noInternet && this.props.isConnected && !this.props.isMonthlyMinutesConsumed) {
            return (
                <I18n>
                    {({ i18n }) =>
                        <>
                            { isSmallFormFactor === false ?
                                <span className="icon-wrapper pointer" data-rh={i18n._(t`Start the session`)} onClick={() => this.onActionClick({action: 'play', selectedGroup: row})}>
                                    <i className="fas fa-video "></i>
                                </span>
                                :
                                <span className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'play', selectedGroup: row})}>
                                    <i className="fas fa-video "></i>
                                </span>
                            }
                        </>
                    }
                </I18n>
            );
        }else if (isViewable && !isOnlineUser) {
            return (
                <I18n>
                    {({ i18n }) =>
                        <>
                            { isSmallFormFactor === false ?
                                <span className="icon-wrapper pointer" data-rh={i18n._(t`No user is online`)}>
                                    <i className="fas fa-video fa-video-disabled "></i>
                                </span>
                                :
                                <span className="icon-wrapper pointer" >
                                    <i className="fas fa-video fa-video-disabled "></i>
                                </span>
                            }
                        </>
                    }
                </I18n>
            );
        }else if (isViewable /*TP-1165*/ && !this.state.webcamAvailable && !(isadmin && !isexpert)) {
            return (
                <I18n>
                    {({ i18n }) =>
                        <span className="icon-wrapper pointer" >
                            { isSmallFormFactor === false ?
                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'tdvideo' + index}>
                                    { isexpert ?
                                        <Trans>MicrophoneNotFoundError</Trans>
                                        :
                                        <Trans>DevicesNotFoundError</Trans>
                                    }
                                </UncontrolledTooltip>                            
                                :
                                ''
                            }
                            <i className="fas fa-video fa-video-disabled " id={'tdvideo' + index}></i>
                        </span>
                    }
                </I18n>
            );
        }else if (isViewable /*TP-1165*/ && this.props.noInternet || !this.props.isConnected){
            return (
                <I18n>
                    {({ i18n }) =>
                        <>
                            { isSmallFormFactor === false ?
                                <span className="icon-wrapper pointer" data-rh={i18n._(t`No user is online`)}>
                                    <i className="fas fa-video fa-video-disabled "></i>
                                </span>
                                :
                                <span className="icon-wrapper pointer" >
                                    <i className="fas fa-video fa-video-disabled "></i>
                                </span>
                            }
                        </>
                    }
                </I18n>
            );
        }else if (isViewable /*TP-1165*/ && this.props.isMonthlyMinutesConsumed){
            return (
                <I18n>
                    {({ i18n }) =>
                        <span className="icon-wrapper pointer">
                            { isSmallFormFactor === false ?
                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'tdvideo' + index}>
                                    {
                                        <Trans>Monthly minutes over</Trans>                                     
                                    }
                                </UncontrolledTooltip>
                                :
                                ''
                            }
                            <i className="fas fa-video fa-video-disabled " id={'tdvideo' + index}></i>
                        </span>
                    }
                </I18n>
            );
        }else if (!isViewable /*TP-1165*/ && !(isadmin && !isexpert) /**TP-4814 -- use case 2*/) {
            return (
                <I18n>
                    {({ i18n }) =>
                        <span className="icon-wrapper pointer">
                            { isSmallFormFactor === false ?
                                <UncontrolledTooltip placement="top" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'tdvideo' + index}>
                                    {
                                        <Trans>No common group found</Trans>                                     
                                    }
                                </UncontrolledTooltip>
                                :
                                ''
                            }
                            <i className="fas fa-video fa-video-disabled " id={'tdvideo' + index}></i>
                        </span>
                    }
                </I18n>
            );
        }else{
            return <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
        }
    }
    
    //TZ-176,TZ-1280
    getRandomPass = (e) => {
        e.preventDefault()
        const hostAbbr = getHostAbbreviationForSocket();//TZ-505
        this.props.authService.fetch('user/getSupportPass', {
            method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data){       
                window.open(`${window._env_.REACT_APP_SUPPORT_URL}/#/dashboards/honeywell/?data=${data}&referrer=${hostAbbr}`, '_blank');//TZ-204
            }
        })
        .catch(err => {
            printConsole(err.message)
        })
    }

    renderRow = ({ row, isAdmin, isExpert, index }) => {
        const { currentPage, perPage } = this.state.pagination;
        const { isSmallFormFactor, audio_mode /**TP-3303*/ } = this.props;
        return (
            <tr key={index}>
                <td>{((currentPage-1)*perPage)+(index + 1)}</td>
                <td>{row.group_name}</td>
                <td>{row.description}</td>
                <td>{this.getExpertsCount(row)}
                    <span style={{ marginLeft: 10 }}>   
                        {
                            this.getExpertsCount(row) ?
                            <Fragment>
                                { isSmallFormFactor === false ?
                                    <UncontrolledTooltip placement="bottom" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'tdexp' + index}>
                                        {
                                            this.getExperts(row).map((e, ii) => {
                                                return <div key={ii + 'popover'}>{e.label}</div>
                                            })
                                        }
                                    </UncontrolledTooltip>
                                    :
                                    ''
                                }
                                <i className="far fa-eye" id={'tdexp' + index}> </i>                                    
                            </Fragment>: null
                        }
                    </span>
                </td>
                {
                    (!isAdmin && !isExpert && audio_mode !== "tech2Tech" /**TP-3303*/) ? null : <td>
                        {this.getUsersCount(row)}
                        <span style={{ marginLeft: 10 }}>   
                            {
                                this.getUsersCount(row) ?
                                <Fragment>
                                    { isSmallFormFactor === false ?
                                        <UncontrolledTooltip placement="bottom" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target={'tduser' + index}>
                                            {
                                                this.getUsers(row).map((e, ii) => {
                                                    return <div key={ii + 'popover'}>{e.label}</div>
                                                })
                                            }
                                        </UncontrolledTooltip>
                                        :
                                        ''
                                    }
                                    <i className="far fa-eye" id={'tduser' + index}> </i>
                                </Fragment>: null
                            }
                        </span>
                    </td>
                }
                { /**TP-3298 & TP-4228*/ (this.props.enable_streaming /* && isExpert */) || this.props.isadmin ?
                    <td className="actions">
                        { this.props.isSmartAdmin === false && this.props.enable_streaming === true && isExpert /*TP-2790*/ ?
                            this.renderVideoIcon({row, index})
                            :
                            ''
                        }
                        { /**TP-6771
                            this.renderChatIcon({ row, index })                            
                        */}
                        { 
                            isAdmin ? (<Fragment>
                                <I18n>
                                    {({ i18n }) =>
                                        <>
                                            { isSmallFormFactor === false ?
                                                <span data-rh={i18n._(t`Edit group`)} className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'edit', selectedGroup: row})}>
                                                    <i className="fas fa-edit"></i>
                                                </span>
                                                :
                                                <span className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'edit', selectedGroup: row})}>
                                                    <i className="fas fa-edit"></i>
                                                </span>
                                            }                                    
                                        </>
                                    }
                                </I18n>
                                <I18n>
                                    {({ i18n }) =>
                                        <>
                                            { isSmallFormFactor === false ?
                                                <span data-rh={i18n._(t`Delete group`)} className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'delete', selectedGroup: row})}>
                                                    <i className="far fa-trash-alt"></i>
                                                </span>
                                                :
                                                <span className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'delete', selectedGroup: row})}>
                                                    <i className="far fa-trash-alt"></i>
                                                </span>
                                            }
                                        </>
                                    }
                                </I18n>
                            </Fragment>) : '' 
                        }
                    </td>
                    :
                    ''
                }
            </tr>
        );
    }

    renderSwitch = (mode) => {
        switch (mode) {
            case 'delete':
                return (
                    <I18n>

                        {({ i18n }) =>
                            <ConfirmModal 
                                primaryButton={i18n._(t`Delete`)}
                                secondaryButton={i18n._(t`Cancel`)}
                                headerText={i18n._(t`Delete Group`)}
                                message={i18n._(t`Are you sure you want to delete this group?`)}
                                onClickSencondary={(e) => this.closeEditGroup(e)}
                                onClickPrimary={(e) => this.onDeleteGroup(e)}
                            />
                        }
                    </I18n>
                    )
            case 'callUser':
                return <Modal size="sm" isOpen={true} className="call-notification-toast">
                    <ModalHeader className="text-center">
                        { this.state.userCallResponse ? <Trans>Call failed</Trans> : <Trans id="Calling {name}" values={{'name': `the Group ${this.state.selectedGroup.group_name}`}}></Trans> }
                    </ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center">
                    {this.state.userCallResponse ? '' : <Spinner className="align-self-center mr-2" type="grow" size="sm" />}
                    {
                        this.state.userCallResponse ?
                            <Trans id={this.state.userCallResponse} values={{recipient: `${this.state.selectedGroup.group_name}`}}></Trans>
                        :
                        <>
                            {this.props.isexpert ? 
                                <Trans id="Group Call" values={{'user_count': `${this.state.calleeCount}`}}></Trans>
                            :
                                <Trans id="Tech Group Call" values={{'user_count': `${this.state.calleeCount}`, 'role': `${this.props.customerRoles.expert}`}}></Trans>
                            }
                            {
                                !this.state.isIOS ?
                                    <audio autoPlay="true" loop="true" src="/incoming_call.mp3"/>
                                : ''
                            }
                        </>
                    }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <ButtonB size="sm" onClick={this.cancelCall}><Trans>Cancel</Trans></ButtonB>
                        </div>
                    </ModalFooter>
                </Modal>
            case 'timeoutCall': /**TP-1550 */
                return <Modal size="sm" isOpen={true} className="call-notification-toast">
                    <ModalHeader className="text-center">
                        { this.state.userCallResponse ? <Trans>Call failed</Trans> : '' }
                    </ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center">                    
                    {
                        this.state.userCallResponse ?
                            <Trans id={this.state.userCallResponse} values={{recipient: `${this.state.selectedGroup.group_name}`}}></Trans>
                        :
                        ''
                    }
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <ButtonB size="sm" onClick={this.closeTimeoutDialog}><Trans>Close</Trans></ButtonB>
                        </div>
                    </ModalFooter>
                </Modal>
            default:
                return '';
        }
    }

    handleQrScan = (e) => {
        if (!e) return;

        try {
            const qrObj = typeof e === 'object' ? e : JSON.parse(e);
            
            const selectedKey = Object.keys(qrObj).pop();
            const fileList = qrObj[selectedKey].constructor === Array ? qrObj[selectedKey]: [qrObj[selectedKey]];
            
            this.setState({
                URLs: fileList,
                isOffline: !navigator.onLine
            });
            
            if (!navigator.onLine) return;

            this.onClickScanQRScanner();
            this.onClickClose3Dmodal();

            const [mtlFile] = fileList.filter(e => e.endsWith('.mtl'));
            const [objFile] = fileList.filter(e => e.endsWith('.obj') || e.endsWith('.3ds') ||  e.endsWith('.3DS') || e.endsWith('.FBX') || e.endsWith('.fbx'));
            const textureFiles = fileList.filter(e => e.endsWith('.jpg') || e.endsWith('.png') || e.endsWith('.BMP') || e.endsWith('.bmp') | e.endsWith('.exr') || e.endsWith('.tga'));
            
            let twoDFile;
            if (!objFile) {
                twoDFile = { name: fileList[0].split('/').pop(), path: fileList[0] }
            }
            
            this.renderFileFromQrScanner({ mtlFile, objFile, textureFiles, twoDFile, objFileType: objFile && objFile.split('.').pop() });

        } catch(e) {
            // this.onClickClose3Dmodal();
            this.onFileViewError();
        }
    }

    renderFileFromQrScanner = async ({ mtlFile, objFile, textureFiles, twoDFile = {}, objFileType }) => {
        try { 
            if (!this._isMounted) return; 
            this.setState({ isLoading: true, showFileViewer: false });
            if(!objFile) {
                const { path } = twoDFile;
                const fileType = twoDFile.name.split('.').pop();
                const fileName = twoDFile.name;
                this.setState(prev => ({ URLs: [
                        ...prev.URLs,
                        path
                    ],
                    showFileViewer: true,
                    fileType: fileType,
                    filePath: path,
                    fileName: fileName,
                    isLoading: false
                }));
                return;
            }
            const closeIconText = this.props.i18n._('Click here to remove the 3d object');
            const paramElem = document.getElementById('videoElement');
            const { sceneManager } = threeEntryPoint({
                videoElement: paramElem,
                id: 'canavas-3d-container',
                fnSend3dRotation: () => {},
                fnClose3dCanvas: () => {},
                closeIconText,
                showCloseIcon: false
            });

            if (!sceneManager) return;
            
            this.sceneManager = sceneManager;
            
            // create object URL from the file object
            this.setState(prev => ({ 
                isLoading: true,
                URLs: [
                    ...prev.URLs,
                    objFile,
                    mtlFile,
                ]
            }));
            await sceneManager.createObject({ type: objFileType.toLowerCase(), mtlFilePath: mtlFile, objFilePath: objFile, textureFilePath: textureFiles})
            this.setState({ isLoading: false });
        } catch (error) {
            console.warn(error)
            // this.onClickClose3Dmodal();
            this.onFileViewError();
        }
    }

    onClickUploadFile = (e) => {
        e.preventDefault();
        fileDialog({ multiple: true, accept: '.xlsx, .mp3, .mp4, .csv, .mov, .jpg, .pdf, .png, .obj, .mtl, .docx, .3ds, .bmp, .tga, .exr, .fbx' })
        .then(files => {
            this.onClickScanQRScanner();
            this.onClickClose3Dmodal();
            const fileArr = Object.values(files);

            let mtlFile, objFile, textureFiles = [], twoDFile, objFileType; 
            for (let i=0; i< fileArr.length; i++) {
                const url = URL.createObjectURL(fileArr[i]);
                const e = fileArr[i];
                if (e.name.endsWith('.mtl')) {
                    mtlFile = url;
                }
                if (e.name.endsWith('.obj') || e.name.endsWith('.3ds') ||  e.name.endsWith('.3DS') || e.name.endsWith('.FBX') || e.name.endsWith('.fbx')) {
                    objFile = url;
                    objFileType = e.name.split('.').pop()
                }
                if (e.name.endsWith('.jpg') || e.name.endsWith('.png') || e.name.endsWith('.BMP') || e.name.endsWith('.bmp') | e.name.endsWith('.exr') || e.name.endsWith('.tga')) {
                    textureFiles.push(url)
                }
            }
            if (!objFile) {
                twoDFile = { name: fileArr[0].name, path: URL.createObjectURL(fileArr[0]) };
            }
            this.renderFileFromQrScanner({ mtlFile, objFile, textureFiles, twoDFile, objFileType });
        })
    }

    onClickClose3Dmodal = () => {
        this.setState({
            isOpen3DModal: !this.state.isOpen3DModal
        })
        if (this.sceneManager) {
            this.sceneManager.removeCanvas();
        }
    }

    showModalLoader = (isLoading) => {
        if (!isLoading) return '';
        return (
            <div className="modal-loader" style={{right: '48%'}}>
                <Loader
                    type="TailSpin"
                    color="#23c97d"
                    height="30" 
                    width="30"
                />
            </div>
        )
    }

    onFileViewError = (e) => {
        if (!this._isMounted) return;
        this.setState({
            bResponded: true,
            retCode: 500,
            showFileViewer: false,
            errStrReturned: i18nMark('File not available or File is corrupted.'),
            isOpen3DModal: false,
            URLs: [],
            isOpenQRScanner: false
        })
    }

    render3DModal = () => {
        const { showFileViewer, fileType, filePath, onFileViewError } = this.state;
        return <Modal size="lg" isOpen={true} toggle={this.onClickClose3Dmodal}>
            <ModalHeader toggle={this.onClickClose3Dmodal}><Trans>File Viewer</Trans></ModalHeader>
            <ModalBody>
                { this.showModalLoader(this.state.isLoading) }
                {
                    <div id="parent-container" style={{width: '100%', height: '400px', display: showFileViewer ? 'none': 'block' }} className="parent-3d-wrapper">
                        <div id="videoElement" style={{width: '100%', height: 'inherit'}}></div>
                    </div> 
                }
                {
                    showFileViewer && <FileViewer
                        // ref = { (f) => this.fileViewer = f } 
                        fileType={fileType}
                        filePath={filePath} 
                        onError={onFileViewError}
                    /> 
                }
            </ModalBody>
        </Modal>
    }

    handleOnLoadQRScanner = () => {
        this.setState({
            isLoading: false
        })
    }

    handleBackToQRSanner = () => {
        this.setState({
            isOffline: false,
            URLs: []
        })
    }

    renderQrScannerModal = (e) => {
        const { isOffline, URLs } = this.state;
        return <Modal size="md" isOpen={true} toggle={this.onClickScanQRScanner}>
            <ModalHeader toggle={this.onClickScanQRScanner}><Trans>Scan a QR code</Trans></ModalHeader>
            <ModalBody>
                { this.showModalLoader(this.state.isLoading) }
                <div className={this.state.isLoading ? 'opac-0-7' : 'opac-1'}>
                    <div className={`flex-column justify-content-center align-items-center ${(isOffline && URLs.length) ? "d-none" : "d-flex"}`}>
                        <QrReader
                            delay={500}
                            onLoad={this.handleOnLoadQRScanner}
                            onScan={this.handleQrScan}
                            onError={this.handleQRScannerError}
                            style={{ width: '300px' }}
                        />
                    </div>
                    <div className={`back-to-qr-code ${(isOffline && URLs.length) ? "block" : "hidden"}`}>
                        <ButtonB color="link" onClick={this.handleBackToQRSanner}>Back</ButtonB>
                    </div>
                    {
                        isOffline && <div className="offline-msg-wrapper mt-3 d-flex flex-column justify-content-center align-items-center">
                            <p><Trans>You are in offline mode. Please select the below file(s) from your local folder</Trans></p>
                            <ListGroup>
                                {
                                    URLs.map((e, i) => {
                                        return <ListGroupItem key={i}> {e} </ListGroupItem>
                                    })
                                }
                            </ListGroup>
                            <Button className="mt-3" onClick={this.onClickUploadFile}><Trans>Upload</Trans></Button>
                        </div> 
                    }
                </div>
            </ModalBody>
        </Modal>
    }

    renderFileDialog = () => {
        return true;
    }

    onClickScanQRScanner = () => {
        this.setState({
            isOpenQRScanner: !this.state.isOpenQRScanner,
            isLoading: true,
            URLs: [],
            isOffline: false
        })
    }
    
    handleQRScannerError = () => {
    }

    render() {
        const { currentPage, perPage } = this.state.pagination;
        const { isadmin, isexpert, isSmallFormFactor, customerRoles, behaviour, customer } = this.props;
        const noDataRawColspan = (!isadmin && !isexpert) ? 5 : 6;
        const { mode, bResponded, retCode, errStrReturned, groupInfo, grpSearch, searchStr } = this.state;
        let grpsList = [];
        if (grpSearch.length > 0 || searchStr !== '') {
            grpsList = this.getGroupsCurrentPage(grpSearch)
        } else if (groupInfo.length > 0) {
            grpsList = this.getGroupsCurrentPage(groupInfo)
        } 
        let colorText = '#485890';
        if(retCode !== 200) colorText = 'red';
        const iconStyle =  {};
        //TP-6771
        let {chatMsgInfoArr} = this.props;
        if (chatMsgInfoArr !== undefined && chatMsgInfoArr.length > 1 && this.state.onlineAllUsers !== undefined && this.state.onlineAllUsers.length > 1) {
            chatMsgInfoArr.forEach(chatMsgInfo => {
                if (chatMsgInfo.msgId) {
                    const [user] = this.state.onlineAllUsers.filter(user => user.email === chatMsgInfo.msgId);
                    if (user && user.myPicture) chatMsgInfo.myPicture = user.myPicture
                }
            })
        }
        if (mode === 'edit') {
            return (
                <AccountConsumer>
                    {({language}) =>
                        <EditGroup 
                            language={language}
                            groups={this.state.groupInfo}
                            group={this.state.selectedGroup}
                            closeEditGroup={this.closeEditGroup.bind(this)}
                            accountid={this.props.accountid}
                            updateSuccess={this.onUpdateSuccess.bind(this)}
                            authService={this.props.authService}
                            numberOfExperts={this.props.numberOfExperts}
                            isCustomizable={this.props.isCustomizable}
                            streaming_medium={this.props.streaming_medium}
                            subscription_status={this.props.subscription_status}
                            isMobileDevice={this.props.isMobileDevice} //TP-2892
                            customerRoles={this.props.customerRoles}
                        />
                    }
                </AccountConsumer>
            );
        } else if (mode === 'add') {
            return  (
                <AccountConsumer>
                    {({language}) =>
                        <AddGroup language={language} 
                            isadmin={this.props.isadmin}
                            addedGroup={this.addedGroup}
                            onFetchGroups={this.getMyGroups.bind(this)} 
                            adminname={this.props.adminname}
                            onClose={this.closeEditGroup.bind(this)}
                            userid={this.props.userid} 
                            accountid={this.props.accountid}
                            authService={this.props.authService}
                            isCustomizable={this.props.isCustomizable}
                            streaming_medium={this.props.streaming_medium}
                            subscription_status={this.props.subscription_status}
                        />
                    }
                </AccountConsumer>
            );
        } else if (mode === 'chat') {
            /**TP-6771 */
            return  (
                <AccountConsumer>
                    {({language}) =>
                        <ChatGroup language={language} 
                            audio_mode={this.props.audio_mode}
                            isExpert={this.props.isexpert } 
                            isAdmin={this.props.isadmin} 
                            isSmallFormFactor={isSmallFormFactor}
                            noInternet={this.props.noInternet}
                            adminemailid={this.props.email}
                            userid={this.props.userid}
                            isConnected={this.props.isConnected}
                            isMonthlyMinutesConsumed={this.props.isMonthlyMinutesConsumed}
                            webcamAvailable={this.state.webcamAvailable}
                            micAvailable={this.state.micAvailable}
                            isSmartAdmin={this.props.isSmartAdmin}
                            enable_streaming={this.props.enable_streaming}
                            adminname={this.props.adminname}
                            myPicture={this.props.myPicture}
                            accountid={this.props.accountid}
                            onlineAllUsers={this.state.onlineAllUsers}
                            onlineUsers={this.props.onlineUsers}
                            selectedGroup={this.state.selectedGroup}
                            onClose={this.closeEditGroup.bind(this)}
                            authService={this.props.authService}
                            onRouteChange={this.props.onRouteChange}
                            updateChatArr={this.props.updateChatArr} //TP-6222
                            chatMsgInfoArr={chatMsgInfoArr} //TP-6222
                            updateChatGroup={this.updateChatGroup} //TP-6222  
                            getExperts={this.getExperts}  
                            getUsers={this.getUsers}      
                            onClickGroupCall={this.onActionClick}                  
                        />
                    }
                </AccountConsumer>
            );
        } else {
            //TP-4814
            if (grpsList && grpsList.length > 0) {
                let grpFlg = false;
                for(let ii=0; ii<=grpsList.length; ii++){
                    if (grpsList[ii] !== undefined && grpsList[ii].users.length > 0) {
                        const isPartOfAGroup = grpsList[ii].users.find(e => e.value === this.props.userid);
                        if (isPartOfAGroup !== undefined) {
                            this.props.changeIspartOfaGroup(true)
                            grpFlg = true;
                            break;
                        }
                    }
                }
                if(grpFlg === false)
                    this.props.changeIspartOfaGroup(false)
            }
            //console.log(customerRoles);
            //TP-5931
            let expertType='', techType='', expertValues={}, techValues={};
            if (customerRoles.technician.toLowerCase() === "inspector" || customerRoles.technician.toLowerCase() === "observer" || customerRoles.technician.toLowerCase() === "technician") {
                techType = customerRoles.technician+'(s)'; techValues = {};
            }
            else {
                techType = 'Technician Default(s)'; techValues = {role: customerRoles.technician};
            }
            if (customerRoles.expert.toLowerCase() === "supervisor" || customerRoles.expert.toLowerCase() === "expert") {
                expertType = customerRoles.expert+'(s)'; expertValues = {};
            }
            else {
                expertType = 'Expert Default(s)'; expertValues = {role: customerRoles.expert};
            }
            return(
                <Fragment>
                    {/* <div style={{'overflowX': 'auto'}}> */}
                    <ReactHint autoPosition events />
                    <div className="flex-space-between">
                    <div>
                        {isadmin ?
                            <I18n>
                                {({ i18n }) =>
                                    <>
                                        <input type="image"
                                        id="addGroup"
                                        src={AddButton}
                                        className="btn-green-add"
                                        alt={i18n._(t`Add Group`)}
                                        //data-rh={i18n._(t`Click to add a new Group`)}
                                        onClick={() => this.onClickAdd()} />
                                        { isSmallFormFactor === false ?
                                            <UncontrolledTooltip placement="right" target="addGroup">
                                                <Trans>Click to add a new Group</Trans>
                                            </UncontrolledTooltip>
                                            :
                                            ''
                                        }
                                    </>                                    
                                }
                            </I18n>
                        :
                            <>
                                {
                                    // TZ-1280, TZ-1520
                                    !isexpert && (behaviour === "honeywell" || behaviour === "fda" || customer.enable_incident)?
                                        <span>
                                            <a href="#" target="_blank" rel="noreferrer" id="power-bi-3" className="white" onClick={(e) => this.getRandomPass(e)}>
                                                <i class="fas fa-chart-bar fa-2x"></i>
                                            </a>
                                            {
                                                isSmallFormFactor === false?
                                                    <UncontrolledTooltip placement="down" target="power-bi-3">
                                                        Dashboard
                                                    </UncontrolledTooltip>
                                                :                                           
                                                    null
                                            }
                                        </span>
                                    :
                                        <span></span>
                                }
                            </>
                        }
                        {/* <span className="scanQRCode">
                            <I18n>
                                {({ i18n }) =>
                                    <>
                                        <input id="scanQRCodeIcon" style={iconStyle} type="image" onClick={this.onClickScanQRScanner} src="/scan_qr_code.png"  alt={i18n._(t`Scan a QR code`)} />
                                        <UncontrolledTooltip placement="right" target="scanQRCodeIcon">
                                            <Trans>Scan a QR code</Trans>
                                        </UncontrolledTooltip>
                                    </>
                                }
                            </I18n>
                        </span> */}
                        </div>
                        <div className="search-input-wrapper relative">
                            <i className="fas fa-search" aria-hidden="true"></i>
                            <I18n>
                                {({ i18n }) => <input
                                        placeholder={i18n._(t`Search for Groups`) + '...'}
                                        type="text" 
                                        id="groupSearchBox" 
                                        onKeyUp={() => this.searchGroups()} 
                                        onChange={(e) => this.onChangeSearchButton(e)}//TP-2426
                                        value={searchStr}
                                    />
                                }
                            </I18n>
                        </div>
                    </div>
                    <div>
                    <div className="table-responsive">
                        { this.props.isSafari ? 
                            <table id="groupsTable">
                                <tbody>
                                    <tr>
                                        <th width="2%">#</th>
                                        <th onClick={() => this.sortGroups('group_name')} width="25%">{/**TP-245*/}
                                            <Trans>Group Name</Trans>
                                            { this.state.sortColumn.group_name !== undefined? <SortIcons direction={this.state.sortColumn.group_name} /> : ''} 
                                        </th>
                                        <th onClick={() => this.sortGroups('description')} width="25%">
                                            <Trans>Description</Trans>
                                            { this.state.sortColumn.description !== undefined? <SortIcons direction={this.state.sortColumn.description} /> : ''} 
                                        </th>
                                        <th onClick={() => this.sortGroups('experts')} width="15%">{/**TP-245*/}
                                            <Trans id={expertType} values={expertValues}></Trans>
                                            { this.state.sortColumn.experts !== undefined? <SortIcons direction={this.state.sortColumn.experts} /> : ''} 
                                        </th>
                                        { (!this.props.isexpert && !this.props.isadmin && this.props.audio_mode !== "tech2Tech" /**TP-3303*/) ? null : <th width="15%" onClick={() => this.sortGroups('technicians')}>
                                            <Trans id={techType} values={techValues}></Trans>
                                            { this.state.sortColumn.technicians !== undefined? <SortIcons direction={this.state.sortColumn.technicians} /> : ''} 
                                        </th> }
                                        { /*TP-3298 & TP-4228*/ (this.props.enable_streaming /* && isexpert */) || this.props.isadmin ?
                                            <th /**TP-245*/ width="15%"><Trans>Actions</Trans></th>
                                            :
                                            ''
                                        }
                                    </tr>
                                    {
                                        (grpsList.length > 0) ?
                                            grpsList.map((row, index) => {
                                                return this.renderRow({ row, index, isAdmin: isadmin, isExpert: isexpert });
                                            })
                                        :
                                        <NoDataFoundRow colspan={noDataRawColspan} />   
                                    }
                                </tbody>
                            </table>
                            :
                            <table id="groupsTable">
                                <tbody>
                                    <tr>
                                        <th>#</th>
                                        <th onClick={() => this.sortGroups('group_name')}>{/**TP-245*/}
                                            <Trans>Group Name</Trans>
                                            { this.state.sortColumn.group_name !== undefined? <SortIcons direction={this.state.sortColumn.group_name} /> : ''} 
                                        </th>
                                        <th onClick={() => this.sortGroups('description')}>
                                            <Trans>Description</Trans>
                                            { this.state.sortColumn.description !== undefined? <SortIcons direction={this.state.sortColumn.description} /> : ''} 
                                        </th>
                                        <th onClick={() => this.sortGroups('experts')}>{/**TP-245*/}
                                            <Trans id={expertType} values={expertValues}></Trans>
                                            { this.state.sortColumn.experts !== undefined? <SortIcons direction={this.state.sortColumn.experts} /> : ''} 
                                        </th>
                                        { (!this.props.isexpert && !this.props.isadmin && this.props.audio_mode !== "tech2Tech" /**TP-3303*/) ? null : <th onClick={() => this.sortGroups('technicians')}>
                                            <Trans id={techType} values={techValues}></Trans>
                                            { this.state.sortColumn.technicians !== undefined? <SortIcons direction={this.state.sortColumn.technicians} /> : ''} 
                                        </th> }
                                        { /**TP-4228*/ (this.props.enable_streaming /* && isexpert */) || this.props.isadmin ?
                                            <th /**TP-245*/ width="15%"><Trans>Actions</Trans></th>
                                            :
                                            ''
                                        }
                                    </tr>
                                    {
                                        (grpsList.length > 0) ?
                                            grpsList.map((row, index) => {
                                                return this.renderRow({ row, index, isAdmin: isadmin, isExpert: isexpert });
                                            })
                                        :
                                        <NoDataFoundRow colspan={noDataRawColspan} />   
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
    
                <div className="pagination-wrapper d-flex justify-content-end">
                    <I18n>
                        {({ i18n }) => 
                            <ReactPaginate
                                previousLabel={i18n._(t`previous`)}
                                nextLabel={i18n._(t`next`)}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={10}
                                onPageChange={this.handlePaginationClick}
                                containerClassName={'pagination'}
                                pageClassName={'page-item'}
                                subContainerClassName={'pages pagination'}
                                pageLinkClassName={'page-link'}
                                disabledClassName={'disabled'}
                                activeClassName={'active'}
                                previousClassName={'page-item'}
                                nextClassName={'page-item'}
                                previousLinkClassName={'page-link'}
                                nextLinkClassName={'page-link'}
                                breakLinkClassName={'page-link'}
                            />
                        }
                    </I18n>
                </div>
                    { this.state.isOpenQRScanner && this.renderQrScannerModal() }
                    { this.state.isOpen3DModal && this.render3DModal() }
                    { this.renderSwitch(mode) }
                    {(bResponded) ?
                        <div className='modale opened'>
                            <div className='__modal-dialog'>
                                <form>
                                    <div className="__modal-header">
                                        <h4 style={{color: colorText}}><Trans id={errStrReturned} /></h4>
                                    </div>
                                    <div className="__modal-footer flex-center">
                                        <button className="btn-green" onClick={(e) =>this.closeEditGroup(e)}><Trans id='OK'/></button>
                                    </div>
                                </form>
                            </div>
                        </div> : ''
                    }
                </Fragment>
            );
        }
    }
}

export default Groups;
