import {uploadFile} from 'react-s3';
const base64Img = require('base64-img');
const AWS = require('aws-sdk');
AWS.config.update({
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
});
const s3 = new AWS.S3();//instantiate s3
const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET,
    dirName: process.env.REACT_APP_S3_DIR, /* optional */
    region: process.env.REACT_APP_S3_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
};  

const calculateDownloadSpeed = async () => {
    try{
        let startTime= Date.now();
        const file = await s3.getObject({ 
            Bucket: `${process.env.REACT_APP_S3_BUCKET}/latency_test`,
            Key: 'Network-latency-test.zip',
            Range: 'bytes=0-20971520'
        }).promise();
        let timeDuration = (Date.now() - startTime) / 1000; 
        let loadedBits = 20971520 * 8; 
        
        let bps = (loadedBits / timeDuration).toFixed(2); 
        let speedInKbps = (bps / 1024).toFixed(2); 
        let speedInMbps = (speedInKbps / 1024).toFixed(2); 
        let speedInMBPS = (speedInMbps / 8).toFixed(2); 
        console.log('speed of download for', 20971520, 'in', bps, speedInKbps, speedInMbps, speedInMBPS)
        return speedInMBPS;
    }catch(error){
        console.log(error)
    }
}

// upload file to local network in LAN
const uploadAssetFile = async (localAssets) => {
    const externalAssets = [];
    let retdata;
    for (const file of localAssets) {
        retdata = await s3FileUpload(file);
        externalAssets.push({
            ...retdata
        });
    }
    return externalAssets;
}

// upload files to s3
const s3FileUpload = (file) => {
    return new Promise((resolve, reject) => {
        uploadFile(file, config)
        .then(retdata => {
            resolve({...retdata, type: 'AWS' });
        })
        .catch(err => {
            console.error(err);
        })
    });
}

const urltoFile = async (url, filename, mimeType) => {
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );    
};

// MB2-588
const getImageDataUrl = ({imageUrl}) =>{
    let imgDataUrl;
    return new Promise((resolve, reject) => {
        base64Img.requestBase64(imageUrl, function(err, res, body) {
            if(err || res.statusCode != 200){
                console.log('image not found');
            }else{
                imgDataUrl = body;
            }
            resolve(imgDataUrl);
        });
    })
};

// MB2-588
const calculateUploadSpeed = async () => {
    try {
        // get the image from AWS and upload
        const imgData = await getImageDataUrl({imageUrl: 'https://telepresenz-20-p2pfiles.s3.us-east-2.amazonaws.com/latency_test/PNG.png'});
        if(!imgData) return;
        const today = Math.floor(Date.now() / 1000);
        let sFileName = `${today}.png`;
        const file = await urltoFile(imgData, sFileName, 'image/png');
        let filearr = [];
        filearr.push(file);
        if (file.size !== 0){
            const startTime= Date.now();
            const ufiles = await uploadAssetFile(filearr);
            let timeDuration = (Date.now() - startTime) / 1000; 
            let loadedBits = file.size * 8; 
                    
            let bps = (loadedBits / timeDuration).toFixed(2); 
            let speedInKbps = (bps / 1024).toFixed(2); 
            let speedInMbps = (speedInKbps / 1024).toFixed(2); 
            let speedInMBPS = (speedInMbps / 8).toFixed(2); 
            console.log('speed of upload for', file.size, 'in', bps, speedInKbps, speedInMbps, speedInMBPS)
            s3.deleteObjects({
                Bucket: ufiles[0].bucket,
                Delete: {Objects: [{Key: ufiles[0].key}], Quiet: false }
                }, (err, data) => {
                    if(err){
                        // console.error(err, err.stack); // an error occurred
                    }else{
                        // console.info(`file deleted ${JSON.stringify(data)}`); // successful response
                    }
                });      
            return speedInMBPS;
        }
    }catch(e){
        console.log(e);
    }
}

export  {calculateDownloadSpeed, calculateUploadSpeed} ;
