import React, { Component, Fragment } from 'react';

//import Particles from 'react-particles-js';
import CacheBuster from './CacheBuster';
import Header from './Components/Header/Header';
import Signin from './Components/Signin/Signin';
import Register from './Components/Register/Register';
import Signup from './Components/Signup/Signup';
import AccountManager from './Components/Landing/AccountManager';
import Session from './Components/Session/OTSession';
import ErrorBoundary from './Components/ErrorBoundary';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import ChangePassword from './Components/ResetPassword/ChangePassword';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import RegisterTrialAccount from './Components/RegisterTrialAccount/RegisterTrialAccount';
import DeviceOrientation, { Orientation } from './Components/DeviceOrientation/DeviceOrientation';
import IdleTimer from 'react-idle-timer';
import { Toast, ToastBody, ToastHeader, Button } from 'reactstrap';
import Adminform from './Components/Signup/Adminform';
import AuthService from './Components/AuthService';
import EnterpriseRequestForm from './Components/EnterpriseRequestForm/EnterpriseRequestForm';
import ModifyEnterprisRequestForm from './Components/EnterpriseRequestForm/ModifyEnterprisRequestForm';

import { I18nProvider } from '@lingui/react';
import AccountProvider,{AccountConsumer} from"./Components/AccountContext";
import 'react-hint/css/index.css';
import './App.scss';
import "react-datepicker/dist/react-datepicker.css";
import { Trans } from '@lingui/macro';
import { setupI18n } from "@lingui/core"
import GuestLogin from './Components/GuestUser/GuestLogin';
import AutoCall from './Components/GuestUser/AutoCall';//TP-566
import WebrtcSession from './Components/Session/WebrtcSession';
import { printConsole, getProperRoles } from './Components/helpers';
import NotSupported from './Components/Signin/NotSupported';
import {Helmet} from "react-helmet";
import MyProfile from './Components/Landing/MyProfile'; //TP-3672

const defaultRoles = {
  "admin": "Admin", 
  "expert": "Expert", 
  "technician": "Technician"
}
  

//TP-4708 -- Different Resolution array ranges for different kinds of cameras
const ResolutionArrFor480p = [
  {width: {exact: 120}, height: {exact: 160}},
  {width: {exact: 320}, height: {exact: 240}},
  {width: {exact: 640}, height: {exact: 480}}
];

const ResolutionArrFor720p = [
  {width: {exact: 320}, height: {exact: 240}},
  {width: {exact: 640}, height: {exact: 480}},
  {width: {exact: 1280}, height: {exact: 720}}
];

const ResolutionArrFor1080p = [
  {width: {exact: 640}, height: {exact: 480}},
  {width: {exact: 1280}, height: {exact: 720}},
  {width: {exact: 1920}, height: {exact: 1080}}
];

const ResolutionArrFor4K = [
  {width: {exact: 1280}, height: {exact: 720}},
  {width: {exact: 1920}, height: {exact: 1080}},
  {width: {exact: 3840}, height: {exact: 2160}}
];

const ResolutionArrForMoreThan4K = [
  {width: {exact: 1280}, height: {exact: 720}},
  {width: {exact: 1920}, height: {exact: 1080}},
  {width: {exact: 3840}, height: {exact: 2160}},
  {width: {exact: 4096}, height: {exact: 2160}}
];

const ResolutionArrFor8K = [
  {width: {exact: 1280}, height: {exact: 720}},
  {width: {exact: 1920}, height: {exact: 1080}},
  {width: {exact: 3840}, height: {exact: 2160}},
  {width: {exact: 4096}, height: {exact: 2160}},
  {width: {exact: 7680}, height: {exact: 4320}}
];

//TP-3821
const ResolutionsToCheck = [
  {width: {exact: 120}, height: {exact: 160}},
  {width: {exact: 320}, height: {exact: 240}},
  {width: {exact: 640}, height: {exact: 480}},
  {width: {exact: 1280}, height: {exact: 720}},
  {width: {exact: 1920}, height: {exact: 1080}},
  {width: {exact: 3840}, height: {exact: 2160}},
  {width: {exact: 4096}, height: {exact: 2160}},
  {width: {exact: 7680}, height: {exact: 4320}}
];

var mid = ResolutionsToCheck.length-1;

/** Added localization support */
const App = () => {
    return(
        <AccountProvider>
            <AccountConsumer>
                {({language, noInternet, isConnected}) =>
                    <I18nProvider noInternet={noInternet} isConnected={isConnected} language={language} catalogs={{ [language]: require(`./locales/${language}/messages.js`) }}>
                        <>
                          <Helmet>
                              <title>telepresenz®</title>
                              <link rel="shortcut icon" type="image/png" href={window._env_.REACT_APP_ASSET_FOLDER+"/favicon.png"}/>
                          </Helmet>
                          <Home noInternet={noInternet} isConnected={isConnected} language={language} catalogs={{ [language]: require(`./locales/${language}/messages.js`) }} />
                        </>
                    </I18nProvider>
                }
            </AccountConsumer>
        </AccountProvider>
    )
};

class Home extends Component {
  constructor(props){
    super(props);
    this.state = {
      bRegister: true,
      sessionNewCmd: 'session_started',
      joineesCount: 0,
      RCStatus: false,
      CameraSwapStatus: false,
      selectedGroupName: '',
      signupStep: 'selectplan',
      selectedplan: 0,
      route: 'signout',
      isSignedIn: false,
      techStopSession: false,
      selectedGroupId: '',
      selectedColumnId: '',
      is_onprem: false,
      group_users: [],
      user: {
        id: '',
        firstname: '',
        email: '',
        geoLocation: '',
        isexpert: false,
        joined: '',
        last_accessed_on: new Date().toISOString(),
        isadmin: false,
        accountid: '',
        groups: [],
        behaviour: '',
        can_disconnect_user: false,
        can_turnoff_video: false,
        myPicture: null
      },
      chatUserCount: 0,
      chatNameArr: [],
      fileShare: false,
      sessionJoinees: [],
      chatAll: false,
      idleState: false,
      secondsRemaining: 10,
      showAlreadyOnlineToast: false,
      shouldStartStopChange: false,
      i18n: {},
      fromSocketUser: null,
      isGuest:false,
      creditsAvailable: 0,
      guest_user_enabled: false,
      expert_ss_enabled: false,
      screenShareAll: false,
      screenShareMaxDiv: false,
      fileShareMaxDiv: false,
      isSafari: false,
      isFirefox: false, //TP-2057
      isChanged: false,// to be used to refresh the group users NS2-116
      showHeaderActionButtons: false, // for NS2-137 show/Hide Header bar action buttons
      loginEmail:'', // email of user whose password has expired NS2-151
      hideExpertSSAll: true, // to hide the expert screen share Header button for NS2-282
      screen_recording_enabled: false, // NS2-293 enable screen recording
      cameraList: [],
      oldPassword: '',
      isiOS: false,
      isAndroid: false,
      isSmartAdmin: false,
      isPilot: false, //TP-2539
      forcedLogin: false, // FQ3-712
      userIdForAutocall: '',//TP-566
      autoCall: false, //TP-566
      displayMediaStream: null, // TP-633
      sessionInfo: null,
      usersInSession: [], //TP-1599
      isRespondToCall: false, //TP-2462
      calleeList: null, //TP-2381
      isMobileDevice: false, //TP-2892
      mandatory_assignee_jobs: false, //TP-2939
      idleTimeOut: 15, //TP-2145
      enable_streaming: false, //TP-2790
      audio_mode: "expert2tech", //TP-3156
      allow_expert_to_expert_call: false, //TZ-989
      enable_user_video: "my_camera_enabled",//TP-3419
      enable_auto_connect: false,
      session_type: "RM", //TP-3156
      isChangePassword: false, //TP-3213
      isSmallFormFactor: false, //TP-3672
      isImageUploaded: false,
      isVerySmallDevice: false, //TP-3746
      isUserCallDone: false, //TP-5036
      streamingOptions: [
        {value: "low", label: "Low", width: 320, height: 240},
        {value: "medium", label: "Medium", width: 640, height: 480},
        {value: "high", label: "High", width: 1280, height: 720}], /**TP-3821 */
      customerRoles: defaultRoles,
      chatMsgInfoArr: []
    }
    this.authService = new AuthService(this.state.isGuest);
    // console.log('App contructor called');
    this.idleTimer = null;
  }

  componentDidMount = () => {  
    /**TP-2892 */
    const isMobileDevice = this.checkIsMobileDevice();
    this.setState({ isMobileDevice });
    /**TP-3672 */
    const isSmallFormFactor = this.checkIsSmallFormFactorDevice();
    this.setState({ isSmallFormFactor });
    /**TP-3746 */
    const isVerySmallDevice = this.checkIsVerySmallDevice();
    this.setState({ isVerySmallDevice });
    this.checkUserAgent();  
    this.checkBrowser();
    this.checkOSVersion(); //TP-2235
    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    const isMobileDevice = this.checkIsMobileDevice();
    this.setState({ isMobileDevice });
    /**TP-3672 */
    const isSmallFormFactor = this.checkIsSmallFormFactorDevice();
    this.setState({ isSmallFormFactor });
    /**TP-3746 */
    const isVerySmallDevice = this.checkIsVerySmallDevice();
    this.setState({ isVerySmallDevice });
    this.checkUserAgent();  
    this.checkBrowser();
    this.checkOSVersion(); //TP-2235
  }

    componentDidUpdate = (prevProps, prevState) => {    
        if (this.props.noInternet !== prevProps.noInternet && !this.props.noInternet){
            window.location.reload();//MB2-577
        }
    }
    //   NS2-151 set email of user whose password has expired
    setLoginEmail = (email) => {
        this.setState({loginEmail: email})
    }

    //TP-2892
    checkIsMobileDevice = () => {
      //console.log(window.innerWidth, window.innerHeight);
      return (window.innerHeight <= 420) ? true : false;
    }

    //TP-3746
    checkIsVerySmallDevice = () => {
      return (window.innerWidth <= 752) ? true : false;
    }

    //TP-3672
    checkIsSmallFormFactorDevice = () => {
      var ua = navigator.userAgent.toLowerCase();
      var up = navigator.platform.toLowerCase();
      const mxp = navigator.maxTouchPoints;
      var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
      var isAndroid = /android/.test(ua);
      //printConsole(isiPad);
      let isSmallFormFactor = false;
      if (isiPad || isAndroid) {
          isSmallFormFactor = true;
      } 
      return isSmallFormFactor;
    }

    checkUserAgent = () => {
      var ua = navigator.userAgent.toLowerCase();
      var up = navigator.platform.toLowerCase();
      const mxp = navigator.maxTouchPoints;
      var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
      this.setState({ isiOS: isiPad });
      var isAndroid = /android/.test(ua);
      this.setState({ isAndroid });
        //printConsole(isiPad);
        
        /* if (isiPad || isAndroid) {
            //herer
        }  */
        //return hideButton;
    }

    //TP-2235 -- To check if a higher or lower version of Mac OS is installed in the 
    // Apple device or other OS device
    checkOSVersion = () => {
      const userAgent = navigator.userAgent;
      console.log(userAgent);
      /* let isHigherMac = false;
      if (/Mac OS X 10/.test(userAgent) === true) {
        isHigherMac = false;
      } else if (/Mac OS X 11/.test(userAgent) === true) {
        isHigherMac = false;
      } else if (/Mac OS X 12/.test(userAgent) === true) {
        isHigherMac = true;
      } else if (/Mac OS X 13/.test(userAgent) === true) {
        isHigherMac = true;
      } else if (/Mac OS X 14/.test(userAgent) === true) {
        isHigherMac = true;
      } else if (/Mac OS X 15/.test(userAgent) === true) {
        isHigherMac = true;
      } */
       
      //this.setState({ isHigherMac });
    }

  checkBrowser = () => {
    let ua = navigator.userAgent.toLowerCase(); 
    let chromeAgent = ua.indexOf("chrome") > -1; 
    let safariAgent = ua.indexOf("safari") > -1; 
    let firefoxAgent = ua.indexOf("firefox") > -1
    if ((chromeAgent) && (safariAgent)) safariAgent = false; 

    const isSafari = safariAgent;
    const isFirefox = firefoxAgent;
    this.setState({isSafari, isFirefox});//TP-2057
  };

  //TP-3419
  checkIfExpertCameraEnabled = () => {
    const {enable_user_video, user} = this.state;
    let flag = false;
    if (enable_user_video === "my_camera_enabled") {
        flag = true;
    } else if (enable_user_video === "my_camera_enabled_with_prefs" && user.my_camera_on === true ) {
        flag = true;
    }
    return flag;
  }

  loadSignupState = (data) => {
    printConsole('loadSignupState called with: ');
    printConsole(data);
    this.setState({signupStep: data.signupStep});
    this.setState({selectedPlan: data.selectedPlan});
  }

  //TP-3821
  setCurrentStreamingRes = (value) => {
    this.state.streamingOptions.forEach(op => {
      if(op.value === value){
          this.setCameraResolution(op.width, op.height);
      }
    }) 
  }

  //TP-3821
  setStreamingOptions = (options) => {
    this.setState({ streamingOptions: options });
  }

  //TP-3821
  setCameraResolution = (width, height) => {
    /* const tmp = value.split(" ")[2];
    const arr = tmp.split("X");
    const widthval = parseInt(arr[0].replace('p',''));
    const heightval = parseInt(arr[1].replace('p','')); */
    this.setState(prev => ({
      user: {
          ...prev.user,
          selectedWidth: {exact: width},
          selectedHeight: {exact: height}
      }
    }), () => {
      printConsole(this.state.user);
    })
  }

  //TP-4708
  initGetMedia = () => {
    let deviceId = "";
    var constraints = {
        "video": ResolutionsToCheck[mid]
    }
    console.log("Constraints ------>", constraints);
    this.getMedia(constraints); 
  } 

  getMedia = (constraints)=> {
    navigator.mediaDevices.getUserMedia(constraints)
        .then(this.checkSuccess)
        .catch(this.checkError);
  }

  checkSuccess = (stream) => {
    printConsole(`Success for --> ${mid} ${JSON.stringify(ResolutionsToCheck[mid])}`);
    for (let track of stream.getTracks()) {
        track.stop()
    }
    this.setStreamingQltyOptions(ResolutionsToCheck[mid].height, ResolutionsToCheck[mid].width);    
    mid = ResolutionsToCheck.length-1;
  }

  checkError = (error) => {
    printConsole(`Failed for --> ${mid} ${JSON.stringify(ResolutionsToCheck[mid])}, " ", ${error}`);
    mid = mid - 1;
    if (mid > 0) this.initGetMedia();
    else {
        printConsole(error);
        //TP-4664
        if (error.name === "NotFoundError" || error.name === "DevicesNotFoundError" || error.name === "NotAllowedError" || error.name === "PermissionDeniedError" /**TP-5432*/ ) {
            this.setCameraResolution(0, 0); //TP-5429
        } else {            
            mid = ResolutionsToCheck.length-1;        
            this.setStreamingQltyOptions({exact: 720}, {exact: 1280});            
        }
    }            
  }

  //TP-3821
  setStreamingQltyOptions = (maxResHeight, maxResWidth) => {
    //let maxResHeight = {exact: 2160}, maxResWidth = {exact: 3840};
    let maxIndex = ResolutionsToCheck.length-1;
    if (maxResHeight.exact <= 480)
        maxIndex = ResolutionArrFor480p.findIndex(rr => rr.height.exact === 480);
    if (maxResHeight.exact === 720)
        maxIndex = ResolutionArrFor720p.findIndex(rr => rr.height.exact === 720);
    else if (maxResHeight.exact === 1080) 
        maxIndex = ResolutionArrFor1080p.findIndex(rr => rr.height.exact === 1080);
    else if (maxResWidth.exact === 3840)
        maxIndex = ResolutionArrFor4K.findIndex(rr => rr.width.exact === 3840);
    else if (maxResWidth.exact === 4096)
        maxIndex = ResolutionArrForMoreThan4K.findIndex(rr => rr.width.exact === 4096);
    else if (maxResWidth.exact === 7680)
        maxIndex = ResolutionArrFor8K.findIndex(rr => rr.width.exact === 7680);

    //maxIndex = ResolutionsToCheck.findIndex(rr => rr.height.exact === maxResHeight.exact);
    //console.log(maxIndex);
    let minIndex = 0;
    const optionmappp = []
    const medium = Math.ceil(((maxIndex + 1) / 2) - 1) ;
    printConsole(`Medium index ${medium}`);

    function getOptions(rr, ii) {
        if (ii === minIndex){
            const optn = {value: "low", label: "Low Resolution: "+rr.height.exact+"p", width: rr.width.exact, height: rr.height.exact};
            optionmappp.push(optn);
        } else if (ii === medium) {
            let optn = {};
            if (rr.width.exact === 3840 || rr.width.exact === 4096)
                optn = {value: "medium", label: "Medium Resolution: 4K", width: rr.width.exact, height: rr.height.exact};
            else if (rr.width.exact === 7680)
                optn = {value: "medium", label: "Medium Resolution: 8K", width: rr.width.exact, height: rr.height.exact};
            else
                optn = {value: "medium", label: "Medium Resolution: "+rr.height.exact+"p", width: rr.width.exact, height: rr.height.exact};
            optionmappp.push(optn);
        } else if (ii == maxIndex) {
            let optn = {};
            if (rr.width.exact === 3840 || rr.width.exact === 4096)
                optn = {value: "high", label: "High Resolution: 4K", width: rr.width.exact, height: rr.height.exact};
            else if (rr.width.exact === 7680)
                optn = {value: "high", label: "High Resolution: 8K", width: rr.width.exact, height: rr.height.exact};
            else
                optn = {value: "high", label: "High Resolution: "+rr.height.exact+"p", width: rr.width.exact, height: rr.height.exact};
            optionmappp.push(optn);
        }
    }

    if (maxResHeight.exact <= 480)
        ResolutionArrFor480p.forEach((rr, ii) => getOptions(rr,ii));
    else if (maxResHeight.exact === 720)
        ResolutionArrFor720p.forEach((rr, ii) => getOptions(rr,ii));
    else if (maxResHeight.exact === 1080) 
        ResolutionArrFor1080p.forEach((rr, ii) => getOptions(rr,ii));
    else if (maxResWidth.exact === 3840)
        ResolutionArrFor4K.forEach((rr, ii) => getOptions(rr,ii));
    else if (maxResWidth.exact === 4096)
        ResolutionArrForMoreThan4K.forEach((rr, ii) => getOptions(rr,ii));
    else if (maxResWidth.exact === 7680)
        ResolutionArrFor8K.forEach((rr, ii) => getOptions(rr,ii));
    else
        ResolutionsToCheck.forEach((rr, ii) => getOptions(rr,ii))
    
        this.setStreamingOptions(optionmappp);       
    
  }

  //TP-3328
  loadUserProfile = (data) => {
    let {user} = this.state;
    user.firstname = data.first_name; //TP-3874
    user.lastname = data.last_name; //TP-3874
    user.my_camera_on = data.my_camera_on;
    user.streaming_quality = data.streaming_quality;
    user.image_quality = data.image_quality;
    user.allow_auto_connect = data.allow_auto_connect;
    user.disable_notifications = data.disable_notifications;
    this.setState({
      user: user
    })
  }

  loadUser = (data) => {
    this.setState({selectedGroupId: ''});
    this.setState({user: {
      id: data.id,
      firstname: data.first_name,
      lastname: data.last_name,
      email: data.email,
      joined: data.joined,
      last_accessed_on: new Date(data.last_accessed_on).toISOString(),
      geoLocation: data.geoLocation,
      isexpert: data.isexpert,
      isadmin: data.isadmin,
      accountid: data.accountid,
      groups: data.groups,
      behaviour: data.behaviour,
      can_disconnect_user: data.can_disconnect_user,
      can_turnoff_video: data.can_turnoff_video,
      can_reset_user_password:data.can_reset_user_password,
      log_level: data.log_level,
      user_info: data.user_info,
      verified: data.verified//TP-5751-to be used for showing pre-populated email
    }})
  }

  loadData = ({behaviour, accountid}) => {
    this.setState({
      user: {
        ...this.state.user,
        behaviour,
        accountid
      }
    })
  }

  //TP-6431
  updateUserDetails = () => {
    this.authService.getProfile()
    .then(response => {
        if(response.status === 200){
            return response.json();
        } else {
            throw new Error(response.body);
        }
    })
    .then(({user: updatedUser}) => {
      const {user} = this.state
      user.firstname = updatedUser.first_name; user.lastname= updatedUser.last_name
      this.setState({user})
      printConsole(user);
    })
    .catch((error) => {
      printConsole(`error in updateUserDetails ${error}`)
    });
  }

  loadPassword = ({password}) => {
    this.setState({ oldPassword: password });
  }

  loadLogMeIn = ({ logMeIn }) => {
    this.setState({ forcedLogin: logMeIn });
  }

  setSmartVision = ({ isSmartAdmin }) => {
    this.setState({ isSmartAdmin });
  }

  //TP-2539
  setPilot = ({ isPilot }) => {
    this.setState({ isPilot });
  }

  loadSelectedGroup = (data, name, fromSocketUser= null) => {
    //console.log(data, name);
    const {guest_user_enabled, user, isGuest} = this.state;
    let fetchString = 'getGroupById/';
    fetchString += data;
    this.authService.fetch(fetchString, {
        method: 'get'
    })
    .then(response => response.json())
    .then(group => {
        this.setState({
            is_onprem: group.is_onprem,
            selectedGroupId: data,
            selectedGroupName: name,
            selectedColumnId: 'group_name',
            fromSocketUser: fromSocketUser
        },() => {
            // calculate the available credits just before going in for a call
            // this will ensure we get the latest credits after all the guest are cleared out
            if(guest_user_enabled){
                this.setState({isChanged: false})// for refreshing the groups and users tab for both technician, expert so that guest user does not show up NS2-116
                if(user.isexpert && !isGuest){
                    this.refetchAccountDetails();
                }
            }
        });
    })
    .catch(err => console.log(err.message)) 
  }

  loadSessionInfo = (data) => {
    this.setState({ sessionInfo: data });
  } 

  //TP-2058
  onChangeUser = (value) => {
    this.setState({ isChanged: value });
  }

  //TP-566
  setUserForCall = ({userId}) => {
    this.setState({userIdForAutocall: userId, autoCall: true, isSignedIn: true})
  }

  loadGroupUserList = (data) => {
    printConsole(data);
    this.setState({group_users: data})
  }

  loadSelectedColumn = (data) => {
    this.setState({selectedColumnId: data});
  }

  changeStateforGuest = () => {
    this.setState({
        isSignedIn: true,
        isGuest: true
    },() => {
        // fetch the screen share related data for guest NS2-129
        this.initGetMedia(); //TP-4708
        this.refetchAccountDetails();
        this.refetchUserProfileSettings();//TP-4155
    })
  }

  //TP-3157
  changeonlyGuestState = () => {
    this.setState({ isGuest: true });
  }

  onRouteChange = (route) =>{
    if(route === 'signout')
    {
        this.setState({isSignedIn: false});
        if (this.state.isGuest) {
          //TP-3157
          //console.log("Here in guest signout");
          window.close();
        }  
    } 
    else if (route === 'home')
    {
      this.setState({isSignedIn: true});
    }
    else if (route === 'signin') 
    {
      window.history.replaceState(null, null, '/');
    }
    /** If Its a production and user click on signup then it will open up the production pricing page */
    if (window._env_.REACT_APP_ENV === 'production' && route === 'signup') {
      window.open(`${window._env_.REACT_APP_STATIC_SITE_URL}/pricing`, '_blank');
      return;
    }
    this.setState({
        route: route,
        showAlreadyOnlineToast: false,
    });
    if(this.state.selectedGroupId > 0 && route === 'session')
        this.onSessionCmdChange('session_started');
    
  }

  onClickBack = () =>{
    const {guest_user_enabled, user, isGuest} = this.state;
    if(this.state.route === 'session' || this.state.route === 'home'){
      this.setState({
        selectedGroupId: null,
        selectedColumnId: null,
        group_users: [],
        is_onprem: false,// for non-webrtc sessions this needs to be false so that header bar shows up
        sessionNewCmd: ''// set this empty string after sessions ends
      },()=> this.onRouteChange('home'));//'session'
      //TP-566 auto logout guest and auto call expert
      if(this.state.isGuest || this.state.autoCall){
        this.loadSessionInfo(null);
        this.autoLogout();
      }
      // addition of guest user enabled in customer
      if(guest_user_enabled){
        // refresh the group and user tab NS2-116
        setTimeout(() => this.setState({isChanged: true}, () => {
          this.setState({ isChanged: false }); //TP-2054
        }), 5000);
        // we need to reset the count else the headerjs state won't update 
        if(user.isexpert && !isGuest){
          this.setState({creditsAvailable: 0});
        }
      }
      //TP-633 -- In screen share mode if the session gets stopped then reload the browser
      if (this.state.route === 'session' && this.state.displayMediaStream !== null) {
        window.location.reload();
        this.setDisplayMedia({stream: null});
      }
    }
    //this.forceUpdate();
  }

  updateRCStatus = (val) => {
    this.setState({ RCStatus : val});
  }

  updateCameraSwapStatus = (val) => {
    this.setState({ CameraSwapStatus: val });
  }

  updateProfilePic = (data) => {
    data.forEach(v => {
      //TP-3506 -- handling of "" in profile picture post delete
      if(v.my_picture !== undefined && v.my_picture !== null && v.my_picture !== "" && v.my_picture.includes("base64") === false)      
        v.my_picture = 'data:image/jpg;base64,'+ v.my_picture;
    });
    this.setState({userPictures: data}, () => {
      //console.log(this.state.userPictures);
    });
  }

  updateIsImageUploaded = (val) => {
    this.setState({isImageUploaded: val});
  }

  //TP-3385
  updatePrfilePicInArray = (data) => {
    let {userPictures} = this.state;
    if (userPictures && userPictures.length > 0) {
      userPictures.forEach(u => {
        //TP-4794, TP-3506 -- handling of "" in profile picture post delete
        if(u.user_id === data.user_id && data.my_picture !== undefined && data.my_picture !== "" && data.my_picture !== null)
          u.my_picture = 'data:image/jpg;base64,'+ data.my_picture;
        else if (u.user_id === data.user_id && data.my_picture !== undefined && data.my_picture === "")
          u.my_picture = data.my_picture
      })
      this.setState({userPictures});
    }
  }

  updateMyProfilePicture = (val) => {
    this.setState(prev => ({
      user: {
          ...prev.user,
          myPicture: val
      }
  }))
  }

  //TP-5504
  openChangePasswordPage = (val) => {
    this.setState({ isOpenChangePassword: val });
  }

  /** TP-3672 -- New UI of the My Profile page and not a tab in home page */
  openMyProfile = (val) => {
    //this.onRouteChange('my-profile');
    this.setState({ isOpenMyProfile: val });
  }
  //TP-3672
  updateMyProfilePic = (fileUrl) =>{
    //TP-3497 & TP-3506
    try {
        if (fileUrl && fileUrl.includes("profile.png") === false && fileUrl.includes("base64") === false)
            fileUrl = 'data:image/jpg;base64,'+ fileUrl;
        else 
            fileUrl = "/profile.png";
        let myImage = document.getElementById("my-profile-pic");
        if (myImage && myImage !== null)
            myImage.src = fileUrl;
        else {
            this.myprofileTime = setTimeout(() => {
                let myImage = document.getElementById("my-profile-pic");
                if (myImage && myImage !== null)
                    myImage.src = fileUrl;
                clearTimeout(this.myprofileTime);
            }, 3000);
        }
    } catch (error) {
        printConsole(`error faced in updateMyProfilePic method ${error}`);
    }        
  }

  onCloseTechnician = (val) => {
    this.setState({ techStopSession : val });
    //if(val === true) this.stopThisSession();
  }

  onSessionCmdChange = (newCmd) =>{
    if(this.state.route === 'session' || this.state.route === 'home'){
      this.setState({sessionNewCmd: newCmd});
    }
    //this.forceUpdate();
  }

  onSetJoinees = (count) =>{
    this.setState({joineesCount: count});
  }

  stopThisSession = () => {
    const { user } = this.state;
    if (user) {
      this.resetInterval();
      if (user.isexpert) 
        this.onSetJoinees(0);
      else this.onCloseTechnician(true);
    }
  }

  closeRegistration = () => {
    this.setState({bRegister: false});
  }

  onUpdateAdminUser = (updatedDetails) => {
      this.setState(prev => ({
          user: {
              ...prev.user,
              ...updatedDetails
          }
      }))
  }

  onSetChatJoinees = (nameArr) => {    
        this.setState((prevState) => ({
          chatUserCount : nameArr.length,
          chatNameArr: [...nameArr]
          
        }),() => {
    });
  }

  onSetSessionJoinees = (nameArr) => {
        this.setState((prevState) => ({
          sessionJoinees: [...nameArr]
          
        }));
  }

  onChangeGroup = (selectedGroupId) => {
    this.setState({
      selectedGroupId
    });
  }

  onUpdateGroup = (updateGroup) => {
    this.authService.fetch(`getgroups/${this.state.user.email}`, {
        method: 'get'
    })
    .then(response => {
        return response.json();
    })
    .then((data) => {
        let groupData = data.map((g) => {return {'id': g.groupid, 'group_name': g.group_name}})
        this.setState(prev => ({
            user: {
              ...prev.user,
              groups: groupData
            }
        }))
    })
    .catch((error) => {
        console.log(error)
    })
}

// TP-633  
setDisplayMedia = ({stream}) => {
    //console.log("Set the Media stream");
    this.setState({displayMediaStream: stream});
  }

  updateFileShare = (value) => {
    this.setState({fileShare: value});
  }

  updateChatAll = (value) => {
    this.setState({chatAll: value});
  }

  closeAdminPopup = () => {
    window.history.replaceState('back', 'Home', '/');
    this.onRouteChange('signout'); //'session'å
  }

  setParams = ({ value = "", param}) => {
    const searchParams = new URLSearchParams();
    searchParams.set(param, value);
    return searchParams.toString();
  }
  
  getParams = (location, query) => {
    const searchParams = new URLSearchParams(location.search);
    return {
      [query]: searchParams.get(query) || '',
    };
  }

  onIdle = () => {
    this.warnInterval = setInterval(this.onWarn, 1000);
  }

  onWarn = () => {
    if(this.state.secondsRemaining > 0) {
      this.setState((prev) => ({ 
        secondsRemaining: prev.secondsRemaining - 1
      }));
    }
    if (this.state.secondsRemaining === 0) {
      this.stopThisSession();
      //this.autoLogout();
    }
  }

  resetInterval = () => {
    this.setState({
      secondsRemaining: 10
    },() => {
      this.warnInterval && clearInterval(this.warnInterval);
    });
  }

  autoLogout = () => {
    printConsole("Auto logout of User triggered after session ends for him");
    const user = this.authService.getDecodedUser();
    const closeWindow = (this.state.isGuest || this.state.autoCall) //TP-671
    printConsole(user);
    if (user) {
      printConsole(`calling signout API with payload user.id=${user.id} and closeWindow ${closeWindow}`);
      this.resetInterval();
      this.authService.signout(user.id, closeWindow);
    }
  }

  componentWillUnmount = () => {
    this.resetInterval();
    window.removeEventListener("resize", this.updateDimensions); //TP-2883
    this.authService.socket.disconnect(true);
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.language!==prevState.language){
      const i18n = setupI18n({ language: nextProps.language, catalogs: nextProps.catalogs });
      return {i18n};
    }
    else return null;
  }

  onBackToDashboard = () => {
    this.setState({
      route: 'home',
      selectedGroupId: 0,
      showAlreadyOnlineToast: true
    })
  }

  hideToast = () => {
    this.setState({ showAlreadyOnlineToast: false });
  }

  changeStartStop = () => {
    this.setState((prev) => ({ 
        shouldStartStopChange: !prev.shouldStartStopChange
    }));
  }
    // update available credits to handle inviting of guests.NS2-10
    updatedAvailableCredits = (purchases) => {
        const creditsAvailable = purchases.reduce(function(prev, cur) {
            return prev + cur.available_credits;
        }, 0);
        this.setState({creditsAvailable})
    }

    // update the guest_user_enabled & screen_share_mode flags for only Enterprise Accounts #NS--57
    updateCustomerFlag = (customer) => {
        //TP-3156, TP-2145, TP-2790
        this.setState({ enable_streaming: customer.enable_streaming, 
          idleTimeOut: customer.idle_timeout, 
          mandatory_assignee_jobs: customer.mandatory_assignee_jobs,
          audio_mode: customer.audio_mode, session_type: customer.session_type,
          enable_user_video: customer.enable_user_video, /**TP-3419 */
          enable_auto_connect: customer.enable_auto_connect, //TP-3672
          allow_expert_to_expert_call: true //TZ-989
        })
        this.setState({ 
            guest_user_enabled : customer.guest_user_enabled,
            screen_recording_enabled: customer.screen_recording_enabled, // NS2-293 enable screen record
        })
        let rolesArr = {};
        if (customer.generic_name === undefined || customer.generic_name === null) rolesArr = defaultRoles;
        else rolesArr = getProperRoles(customer.generic_name, defaultRoles)
          this.setState({ customerRoles: rolesArr });
        if (customer.screen_share_mode === 'screen_share_enabled' || customer.screen_share_mode === 'expert_screen_share_enabled')
        {
            this.setState({ expert_ss_enabled : true })
        } else {
            this.setState({ expert_ss_enabled : false })
        }
    }

    // update the state variable from inside Session Component
    updateScreenShareAll = (val) => {
      this.setState({ screenShareAll : val});
    }

    // Changes done for NS2-117
    // Update the state variable for when the Maxdiv screen shares/RC are ongoing or not
    updateScreenShareMaxdiv = (val) => {
      this.setState({ screenShareMaxDiv: val });
    }

    // Changes done for NS2-376
    // Update the state variable for when the Maxdiv RC is ongoing or not
    updateFileShareMaxdiv = (val) => {
      this.setState({ fileShareMaxDiv: val });
    }

    //TP-3213
    updateChangePassword = (val) => {
      this.setState({ isChangePassword: val });
    }

    showHideHeaderActionButtons = (val) => {
      this.setState({ showHeaderActionButtons: val });
    }
    
    // for NS2-282
    showScreenShareHeaderButton = (val) => {
      this.setState({ hideExpertSSAll: val });
    }

    updateChatMsgArr = (arr) => {
      this.setState({ chatMsgInfoArr: arr });
    }

    // TP-1599 -- usersInSession array setting Method
    setUsersInSession = (arr) => {
      this.setState({ usersInSession: arr }, () => {
        //console.log(this.state.usersInSession);
      });
    }

    //TP-2462
    setRespondToCall = (val) => {
      this.setState({ isRespondToCall: val });
    }

    //TP-5036
    isUserCall = (val) => {
      this.setState({ isUserCallDone: val});
    }

    //TP-2946
    removeFromCalleeList = (val) => {
      this.setState((prev) => ({
        calleeList: /*TP-4457*/(prev.calleeList && prev.calleeList !== null) ? prev.calleeList.filter(c => c !== val) : prev.calleeList
      }))
    } 
    
    //TP-2381 -- Set the callee list for a Group/User call made from webapp
    setCalleeList = (value) => {
      this.setState({ calleeList: value });
    }

    // refetech the account details to reset the buttons NS2-82 
    refetchAccountDetails = () => {
        this.authService.fetch(`getAccoutDetails/${this.state.user.accountid}`, {
            method: 'get'
        })
        .then(response => {
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(({purchases, plans, customer, paymentSources = { data: []}, defaultSource}) => {
            if (purchases && plans && customer) {
                this.updateCustomerFlag(customer);
                this.updatedAvailableCredits(purchases);
            }
        })
        .catch((error) => {
            console.error('the account details cannot be refetched')
        })
    }

    //TP-4155
    refetchUserProfileSettings = () => {
      if(this.state.user.id){
        let fetchString = 'user/getProfileData/';
        fetchString += this.state.user.id; //by account id
        fetchString += "/true"; //to fetch the image as well
  
        //TP-3672
        this.authService.fetch(fetchString, {
                  method: 'get'
        })
        .then(response => response.json())
        .then(data => {
            if(data !== undefined && data !== null){       
                      //console.log(data);
                      this.loadUserProfile(data); //TP-3328 
                      //TP-3735 & TP-3506 -- Handled the "" for image when deleted
                      if (data.my_picture !== undefined && data.my_picture !== "" && data.my_picture !== null) {
                          this.updateMyProfilePicture(data.my_picture);                         
                      }
                      //TP-5432 & TP-5429 -- If the resolution is already set to 0,0 then don't set the resolution again
                      if (this.state.user.selectedWidth && this.state.user.selectedWidth.exact !== 0 && this.state.user.selectedHeight && this.state.user.selectedHeight.exact !== 0) {
                        //TP-3821 -- Setting the Streaming resolution for Guest Users
                        this.state.streamingOptions.forEach(op => {
                            if(op.value === data.streaming_quality){
                              this.setCameraResolution(op.width, op.height);
                            }
                        })

                      }
                      //TP-4708
                      if ((this.state.user.isexpert && this.state.enable_user_video !== "my_camera_disabled") || this.state.user.isexpert === false)
                        this.guestRouteTimer = setTimeout(() => {
                          this.onRouteChange('session');//TP-4155    
                        }, 8000);//TP-5429
                      else
                        this.onRouteChange('session');//TP-4155  
            }
        })
        .catch(err => {
                  this.setState({ isLoading: false });
                  printConsole(err.message)
              })
      } else {
              printConsole("User profile data can't be fetched since user Id not known");
          }
    }

  //TP-4526
  getRandomPass = () => {
    this.authService.fetch('user/getSupportPass', {
              method: 'get'
    })
    .then(response => response.json())
    .then(data => {
      if(data){       
        window.open(`${window._env_.REACT_APP_SUPPORT_URL}?data=${data}`, '_blank');
      }
    })
    .catch(err => {
      this.setState({ isLoading: false });
      printConsole(err.message)
    })
  }
  renderOrientationWrapper = (consumerProps) => {
        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }
                    //console.log("isSmallDevice? ", this.state.isSmallFormFactor);
                    if (this.state.isSmallFormFactor) {
                      return (
                          <DeviceOrientation lockOrientation={'landscape'}>      
                              <Orientation orientation='landscape' alwaysRender={false}>
                              {
                                  (this.state.selectedGroupId > 0) ?
                                  (
                                      (this.state.selectedColumnId === 'group_name' ?
                                          (!this.state.is_onprem ? 
                                              <Session 
                                                  {...consumerProps}
                                                  onSetJoinees={this.onSetJoinees} 
                                                  updateRCStatus={this.updateRCStatus}
                                                  updateCameraSwapStatus={this.updateCameraSwapStatus}
                                                  groupUsers={this.state.group_users}
                                                  selectedGroupId={this.state.selectedGroupId} 
                                                  loggedInUserId={this.state.user.id} 
                                                  loggedInUserFirstName={this.state.user.firstname} 
                                                  user_info={this.state.user.user_info}
                                                  sessionCmd={this.state.sessionNewCmd}
                                                  onSetChatJoinees={this.onSetChatJoinees}
                                                  updateFileShare={this.updateFileShare}
                                                  onSetSessionJoinees={this.onSetSessionJoinees}
                                                  onSessionCmdChange={this.onSessionCmdChange}
                                                  onCloseTechnician={this.onCloseTechnician}
                                                  updateChatAll={this.updateChatAll}
                                                  updateScreenShareAll={this.updateScreenShareAll}
                                                  updateScreenShareMaxdiv={this.updateScreenShareMaxdiv}
                                                  updateFileShareMaxdiv={this.updateFileShareMaxdiv}
                                                  onBackToDashboard={this.onBackToDashboard}
                                                  changeStartStop={this.changeStartStop}
                                                  onClickBack={this.onClickBack}
                                                  i18n={this.state.i18n}
                                                  user={this.state.user}
                                                  chatUserCount = {this.state.chatUserCount}
                                                  chatNameArr = {this.state.chatNameArr}
                                                  selectedGroupName={this.state.selectedGroupName} 
                                                  fromSocketUser={this.state.fromSocketUser}
                                                  isSafari = {this.state.isSafari}
                                                  isFirefox = {this.state.isFirefox}
                                                  isHeaderFileShare = {this.state.fileShare}
                                                  chatAll={this.state.chatAll}
                                                  updateShowHideHeaderActionButtons={this.showHideHeaderActionButtons}
                                                  showScreenShareHeaderButton={this.showScreenShareHeaderButton}
                                                  screen_recording_enabled={this.state.screen_recording_enabled}
                                                  shouldStartStopChange={this.state.shouldStartStopChange}
                                                  creditsAvailable={this.state.creditsAvailable}
                                                  isGuest={this.state.isGuest}
                                                  guest_user_enabled={this.state.guest_user_enabled}
                                                  expert_ss_enabled={this.state.expert_ss_enabled}
                                                  screenShareAll={this.state.screenShareAll}
                                                  screenShareMaxDiv={this.state.screenShareMaxDiv}
                                                  fileShareMaxDiv={this.state.fileShareMaxDiv} 
                                                  showHeaderActionButtons={this.state.showHeaderActionButtons}
                                                  hideExpertSSAll={this.state.hideExpertSSAll}
                                                  RCStatus={this.state.RCStatus}
                                                  CameraSwapStatus={this.state.CameraSwapStatus}
                                                  isiOS = {this.state.isiOS}
                                                  isAndroid = {this.state.isAndroid}
                                                  sessionInfo={this.state.sessionInfo}
                                                  usersInSession={this.state.usersInSession} //TP-1599
                                                  setUsersInSession={this.setUsersInSession} //TP-1599
                                                  isRespondToCall={this.state.isRespondToCall} //TP-2462
                                                  calleeList={this.state.calleeList} //TP-2381
                                                  setCalleeList={this.setCalleeList} //TP-2381
                                                  session_type={this.state.session_type} //TP-3156
                                                  userPictures={this.state.userPictures}
                                                  isUserCallDone={this.state.isUserCallDone} //TP-5036
                                                  behaviour={this.state.user.behaviour} //TP-6373
                                                  customerRoles={this.state.customerRoles}
                                                  updateChatMsgArr={this.updateChatMsgArr}
                                                  chatMsgInfoArr={this.state.chatMsgInfoArr}
                                              />
                                              :
                                              <WebrtcSession
                                                  {...consumerProps}
                                                  onSetJoinees={this.onSetJoinees} 
                                                  user={this.state.user}
                                                  selectedGroupId={this.state.selectedGroupId} 
                                                  selectedGroupName={this.state.selectedGroupName} 
                                                  groupUsers={this.state.group_users}
                                                  fromSocketUser={this.state.fromSocketUser}
                                                  sessionCmd={this.state.sessionNewCmd}
                                                  onSetChatJoinees={this.onSetChatJoinees}                                                
                                                  changeStartStop={this.changeStartStop}
                                                  onClickBack={this.onClickBack}
                                                  updateFileShare={this.updateFileShare}
                                                  updateShowHideHeaderActionButtons={this.showHideHeaderActionButtons}
                                                  onSessionCmdChange={this.onSessionCmdChange}
                                                  creditsAvailable={this.state.creditsAvailable}
                                                  isGuest={this.state.isGuest}
                                                  guest_user_enabled={this.state.guest_user_enabled}
                                                  expert_ss_enabled={this.state.expert_ss_enabled}
                                                  fileShareMaxDiv={this.state.fileShareMaxDiv}                        
                                                  isSafari = {this.state.isSafari}
                                                  isFirefox = {this.state.isFirefox}
                                                  updateChatAll={this.updateChatAll}
                                                  isiOS = {this.state.isiOS}
                                                  setDisplayMedia = {this.setDisplayMedia}
                                                  isAndroid = {this.state.isAndroid}
                                                  sessionInfo={this.state.sessionInfo}
                                                  usersInSession={this.state.usersInSession} //TP-1599
                                                  setUsersInSession={this.setUsersInSession} //TP-1599
                                                  isRespondToCall={this.state.isRespondToCall} //TP-2462
                                                  calleeList={this.state.calleeList} //TP-2381
                                                  setCalleeList={this.setCalleeList} //TP-2381
                                                  session_type={this.state.session_type} //TP-3156
                                                  audio_mode={this.state.audio_mode} //TP-3156
                                                  allow_expert_to_expert_call={this.state.allow_expert_to_expert_call} //TZ-989
                                                  enable_user_video={this.state.enable_user_video} //TP-3419
                                                  userPictures={this.state.userPictures}
                                                  updateProfilePic={this.updateProfilePic} //	TP-3213	
                                                  updatePrfilePicInArray={this.updatePrfilePicInArray} //TP-3385
                                                  isImageUploaded={this.state.isImageUploaded}
                                                  updateIsImageUploaded={this.updateIsImageUploaded}
                                                  isUserCallDone={this.state.isUserCallDone} //TP-5036
                                                  behaviour={this.state.user.behaviour} //TP-6373
                                                  customerRoles={this.state.customerRoles}
                                                  updateChatMsgArr={this.updateChatMsgArr}
                                                  chatMsgInfoArr={this.state.chatMsgInfoArr}
                                              />
                                          )
                                      : '')
                                  ) 
                                  : <AccountManager 
                                      {...consumerProps}
                                      onUpdateAdminUser={this.onUpdateAdminUser} 
                                      isSmartAdmin ={this.state.isSmartAdmin}
                                      isPilot={this.state.isPilot} //TP-2539
                                      isexpert={this.state.user.isexpert} 
                                      isadmin={this.state.user.isadmin} 
                                      name={this.state.user.firstname + ' ' + this.state.user.lastname} 
                                      accountid={this.state.user.accountid} 
                                      email={this.state.user.email} 
                                      id={this.state.user.id}
                                      myPicture={this.state.user.myPicture}
                                      streaming_quality={this.state.user.streaming_quality} //TP-3794
                                      image_quality={this.state.user.image_quality} //TP-3794
                                      camera_on={this.state.user.my_camera_on} //TP-3794
                                      disable_notifications={this.state.user.disable_notifications} //TP-3794
                                      myCameraOn={this.checkIfExpertCameraEnabled} //TP-3419 & TP-3532
                                      behaviour={this.state.user.behaviour}
                                      forcedLogin={this.state.forcedLogin} // FQ3-712
                                      last_accessed_on={this.state.user.last_accessed_on} // FQ3-712
                                      log_level={this.state.user.log_level}
                                      user_info={this.state.user.user_info}
                                      allow_auto_connect={this.state.user.allow_auto_connect} //TP-3378
                                      loadSelectedGroup={this.loadSelectedGroup}
                                      loadGroupUserList={this.loadGroupUserList} 
                                      loadSelectedColumn={this.loadSelectedColumn} 
                                      loadSessionInfo={this.loadSessionInfo} 
                                      onRouteChange={this.onRouteChange}
                                      onUpdateGroup={this.onUpdateGroup}
                                      loadLogMeIn={this.loadLogMeIn} //FQ3-712
                                      i18n={this.state.i18n}
                                      updatedAvailableCredits={this.updatedAvailableCredits}
                                      updateCustomerFlag={this.updateCustomerFlag} 
                                      isChanged = {this.state.isChanged}
                                      canResetUserPassword={this.state.user.can_reset_user_password} 
                                      userIdForAutocall={this.state.userIdForAutocall}
                                      autoCall={this.state.autoCall}
                                      isSafari={this.state.isSafari}
                                      isFirefox = {this.state.isFirefox}
                                      setUsersInSession={this.setUsersInSession} //TP-1599
                                      usersInSession={this.state.usersInSession} //TP-1599
                                      onChangeUser={this.onChangeUser}//TP-2058
                                      isGuest={this.state.isGuest}//
                                      setRespondToCall={this.setRespondToCall}//TP-2462
                                      setCalleeList={this.setCalleeList} //TP-2381
                                      removeFromCalleeList={this.removeFromCalleeList} //TP-2946
                                      enable_streaming={this.state.enable_streaming} //TP-2790
                                      audio_mode={this.state.audio_mode} //TP-3156
                                      allow_expert_to_expert_call={this.state.allow_expert_to_expert_call}//TZ-989
                                      enable_user_video={this.state.enable_user_video} //TP-3419
                                      session_type={this.state.session_type} //TP-3156
                                      updateProfilePic={this.updateProfilePic} //	TP-3213	
                                      updatePrfilePicInArray={this.updatePrfilePicInArray} //TP-3385  
                                      updateMyProfilePicture={this.updateMyProfilePicture} //TP-3672 
                                      updateChangePassword={this.updateChangePassword} //TP-3213                                   
                                      loadUserProfile={this.loadUserProfile}//TP-3746
                                      isOpenMyProfile={this.state.isOpenMyProfile}
                                      openMyProfile={this.openMyProfile}
                                      updateMyProfilePic={this.updateMyProfilePic}
                                      isVerySmallDevice={this.state.isVerySmallDevice} //TP-3746
                                      setCameraResolution={this.setCameraResolution} //TP-3821
                                      setStreamingOptions={this.setStreamingOptions} //TP-3821
                                      setCurrentStreamingRes={this.setCurrentStreamingRes} //TP-3821
                                      isUserCall={this.isUserCall} //TP-5036
                                      closeAdminPopup={this.closeAdminPopup}//TP-5504
                                      oldPassword={this.state.oldPassword} //TP-5504
                                      isOpenChangePassword={this.state.isOpenChangePassword} //TP-5504
                                      openChangePasswordPage={this.openChangePasswordPage} //TP-5504
                                      updateUserDetails={this.updateUserDetails}//TP-6431
                                      customerRoles={this.state.customerRoles}
                                      updateChatMsgArr={this.updateChatMsgArr}
                                  />
                              }
                              </Orientation>
                              {/* Will stay in DOM, but is only visible in portrait */}
                              <Orientation orientation='portrait' alwaysRender={false}>
                                  <div className="div-center" style={{color: '#fff'}}>
                                      <p className="tm-semi-bold h2"><Trans>Please rotate your device to landscape mode.</Trans></p>
                                  </div>
                              </Orientation>
                          </DeviceOrientation>
                      )
                    } else {
                      return (                        
                          (this.state.selectedGroupId > 0) ?
                          (
                              (this.state.selectedColumnId === 'group_name' ?
                                  (!this.state.is_onprem ? 
                                      <Session 
                                          {...consumerProps}
                                          onSetJoinees={this.onSetJoinees} 
                                          updateRCStatus={this.updateRCStatus}
                                          updateCameraSwapStatus={this.updateCameraSwapStatus}
                                          groupUsers={this.state.group_users}
                                          selectedGroupId={this.state.selectedGroupId} 
                                          loggedInUserId={this.state.user.id} 
                                          loggedInUserFirstName={this.state.user.firstname} 
                                          user_info={this.state.user.user_info}
                                          sessionCmd={this.state.sessionNewCmd}
                                          onSetChatJoinees={this.onSetChatJoinees}
                                          updateFileShare={this.updateFileShare}
                                          onSetSessionJoinees={this.onSetSessionJoinees}
                                          onSessionCmdChange={this.onSessionCmdChange}
                                          onCloseTechnician={this.onCloseTechnician}
                                          updateChatAll={this.updateChatAll}
                                          updateScreenShareAll={this.updateScreenShareAll}
                                          updateScreenShareMaxdiv={this.updateScreenShareMaxdiv}
                                          updateFileShareMaxdiv={this.updateFileShareMaxdiv}
                                          onBackToDashboard={this.onBackToDashboard}
                                          changeStartStop={this.changeStartStop}
                                          onClickBack={this.onClickBack}
                                          i18n={this.state.i18n}
                                          user={this.state.user}
                                          chatUserCount = {this.state.chatUserCount}
                                          chatNameArr = {this.state.chatNameArr}
                                          selectedGroupName={this.state.selectedGroupName} 
                                          fromSocketUser={this.state.fromSocketUser}
                                          isSafari = {this.state.isSafari}
                                          isFirefox = {this.state.isFirefox}
                                          isHeaderFileShare = {this.state.fileShare}
                                          chatAll={this.state.chatAll}
                                          updateShowHideHeaderActionButtons={this.showHideHeaderActionButtons}
                                          showScreenShareHeaderButton={this.showScreenShareHeaderButton}
                                          screen_recording_enabled={this.state.screen_recording_enabled}
                                          shouldStartStopChange={this.state.shouldStartStopChange}
                                          creditsAvailable={this.state.creditsAvailable}
                                          isGuest={this.state.isGuest}
                                          guest_user_enabled={this.state.guest_user_enabled}
                                          expert_ss_enabled={this.state.expert_ss_enabled}
                                          screenShareAll={this.state.screenShareAll}
                                          screenShareMaxDiv={this.state.screenShareMaxDiv}
                                          fileShareMaxDiv={this.state.fileShareMaxDiv} 
                                          showHeaderActionButtons={this.state.showHeaderActionButtons}
                                          hideExpertSSAll={this.state.hideExpertSSAll}
                                          RCStatus={this.state.RCStatus}
                                          CameraSwapStatus={this.state.CameraSwapStatus}
                                          isiOS = {this.state.isiOS}
                                          isAndroid = {this.state.isAndroid}
                                          sessionInfo={this.state.sessionInfo}
                                          usersInSession={this.state.usersInSession} //TP-1599
                                          setUsersInSession={this.setUsersInSession} //TP-1599
                                          isRespondToCall={this.state.isRespondToCall} //TP-2462
                                          calleeList={this.state.calleeList} //TP-2381
                                          setCalleeList={this.setCalleeList} //TP-2381
                                          session_type={this.state.session_type} //TP-3156
                                          userPictures={this.state.userPictures}
                                          isUserCallDone={this.state.isUserCallDone} //TP-5036
                                          behaviour={this.state.user.behaviour} //TP-6373
                                          customerRoles={this.state.customerRoles}
                                          updateChatMsgArr={this.updateChatMsgArr}
                                          chatMsgInfoArr={this.state.chatMsgInfoArr}
                                      />
                                      :
                                      <WebrtcSession
                                          {...consumerProps}
                                          onSetJoinees={this.onSetJoinees} 
                                          user={this.state.user}
                                          selectedGroupId={this.state.selectedGroupId} 
                                          selectedGroupName={this.state.selectedGroupName} 
                                          groupUsers={this.state.group_users}
                                          fromSocketUser={this.state.fromSocketUser}
                                          sessionCmd={this.state.sessionNewCmd}
                                          onSetChatJoinees={this.onSetChatJoinees}                                                
                                          changeStartStop={this.changeStartStop}
                                          onClickBack={this.onClickBack}
                                          updateFileShare={this.updateFileShare}
                                          updateShowHideHeaderActionButtons={this.showHideHeaderActionButtons}
                                          onSessionCmdChange={this.onSessionCmdChange}
                                          creditsAvailable={this.state.creditsAvailable}
                                          isGuest={this.state.isGuest}
                                          guest_user_enabled={this.state.guest_user_enabled}
                                          expert_ss_enabled={this.state.expert_ss_enabled}
                                          fileShareMaxDiv={this.state.fileShareMaxDiv}                        
                                          isSafari = {this.state.isSafari}
                                          isFirefox = {this.state.isFirefox}
                                          updateChatAll={this.updateChatAll}
                                          isiOS = {this.state.isiOS}
                                          setDisplayMedia = {this.setDisplayMedia}
                                          isAndroid = {this.state.isAndroid}
                                          sessionInfo={this.state.sessionInfo}
                                          usersInSession={this.state.usersInSession} //TP-1599
                                          setUsersInSession={this.setUsersInSession} //TP-1599
                                          isRespondToCall={this.state.isRespondToCall} //TP-2462
                                          calleeList={this.state.calleeList} //TP-2381
                                          setCalleeList={this.setCalleeList} //TP-2381
                                          session_type={this.state.session_type} //TP-3156
                                          audio_mode={this.state.audio_mode} //TP-3156
                                          allow_expert_to_expert_call={this.state.allow_expert_to_expert_call} //TZ-989
                                          enable_user_video={this.state.enable_user_video} //TP-3419
                                          userPictures={this.state.userPictures}
                                          updateProfilePic={this.updateProfilePic} //	TP-3213	
                                          updatePrfilePicInArray={this.updatePrfilePicInArray} //TP-3385
                                          isImageUploaded={this.state.isImageUploaded}
                                          updateIsImageUploaded={this.updateIsImageUploaded}
                                          isUserCallDone={this.state.isUserCallDone} //TP-5036
                                          behaviour={this.state.user.behaviour} //TP-6373
                                          customerRoles={this.state.customerRoles}
                                          updateChatMsgArr={this.updateChatMsgArr}
                                          chatMsgInfoArr={this.state.chatMsgInfoArr}
                                      />
                                  )
                              : '')
                          ) 
                          : <AccountManager 
                              {...consumerProps}
                              onUpdateAdminUser={this.onUpdateAdminUser} 
                              isSmartAdmin ={this.state.isSmartAdmin}
                              isPilot={this.state.isPilot} //TP-2539
                              isexpert={this.state.user.isexpert} 
                              isadmin={this.state.user.isadmin} 
                              name={this.state.user.firstname + ' ' + this.state.user.lastname} 
                              accountid={this.state.user.accountid} 
                              email={this.state.user.email} 
                              id={this.state.user.id}
                              myPicture={this.state.user.myPicture}
                              streaming_quality={this.state.user.streaming_quality} //TP-3794
                              image_quality={this.state.user.image_quality} //TP-3794
                              camera_on={this.state.user.my_camera_on} //TP-3794
                              disable_notifications={this.state.user.disable_notifications} //TP-3794
                              myCameraOn={this.checkIfExpertCameraEnabled} //TP-3419 & TP-3532
                              behaviour={this.state.user.behaviour}
                              forcedLogin={this.state.forcedLogin} // FQ3-712
                              last_accessed_on={this.state.user.last_accessed_on} // FQ3-712
                              log_level={this.state.user.log_level}
                              user_info={this.state.user.user_info}
                              allow_auto_connect={this.state.user.allow_auto_connect} //TP-3378
                              loadSelectedGroup={this.loadSelectedGroup}
                              loadGroupUserList={this.loadGroupUserList} 
                              loadSelectedColumn={this.loadSelectedColumn} 
                              loadSessionInfo={this.loadSessionInfo} 
                              onRouteChange={this.onRouteChange}
                              onUpdateGroup={this.onUpdateGroup}
                              loadLogMeIn={this.loadLogMeIn} //FQ3-712
                              i18n={this.state.i18n}
                              updatedAvailableCredits={this.updatedAvailableCredits}
                              updateCustomerFlag={this.updateCustomerFlag} 
                              isChanged = {this.state.isChanged}
                              canResetUserPassword={this.state.user.can_reset_user_password} 
                              userIdForAutocall={this.state.userIdForAutocall}
                              autoCall={this.state.autoCall}
                              isSafari={this.state.isSafari}
                              isFirefox = {this.state.isFirefox}
                              setUsersInSession={this.setUsersInSession} //TP-1599
                              usersInSession={this.state.usersInSession} //TP-1599
                              onChangeUser={this.onChangeUser}//TP-2058
                              isGuest={this.state.isGuest}//
                              setRespondToCall={this.setRespondToCall}//TP-2462
                              setCalleeList={this.setCalleeList} //TP-2381
                              removeFromCalleeList={this.removeFromCalleeList} //TP-2946
                              enable_streaming={this.state.enable_streaming} //TP-2790
                              audio_mode={this.state.audio_mode} //TP-3156
                              allow_expert_to_expert_call={this.state.allow_expert_to_expert_call} //TZ-989
                              enable_user_video={this.state.enable_user_video} //TP-3419
                              session_type={this.state.session_type} //TP-3156
                              updateProfilePic={this.updateProfilePic} //	TP-3213	
                              updatePrfilePicInArray={this.updatePrfilePicInArray} //TP-3385  
                              updateMyProfilePicture={this.updateMyProfilePicture} //TP-3672 
                              updateChangePassword={this.updateChangePassword} //TP-3213                                   
                              loadUserProfile={this.loadUserProfile}//TP-3746
                              isOpenMyProfile={this.state.isOpenMyProfile}
                              openMyProfile={this.openMyProfile}
                              updateMyProfilePic={this.updateMyProfilePic}
                              isVerySmallDevice={this.state.isVerySmallDevice} //TP-3746
                              setCameraResolution={this.setCameraResolution} //TP-3821
                              setStreamingOptions={this.setStreamingOptions} //TP-3821
                              setCurrentStreamingRes={this.setCurrentStreamingRes} //TP-3821
                              isUserCall={this.isUserCall} //TP-5036
                              closeAdminPopup={this.closeAdminPopup} //TP-5504
                              oldPassword={this.state.oldPassword} //TP-5504
                              isOpenChangePassword={this.state.isOpenChangePassword} //TP-5504
                              openChangePasswordPage={this.openChangePasswordPage} //TP-5504
                              updateUserDetails={this.updateUserDetails}//TP-6431
                              customerRoles={this.state.customerRoles}
                              updateChatMsgArr={this.updateChatMsgArr}
                          />
                      
                      )
                    }
                }}
            </CacheBuster>
        )

    }
    
  

  render = () => {
    const {user, isAndroid, isiOS} = this.state;
    let suffix = '', iOsLink = 'https://apps.apple.com/in/app/telepresenz/id1489329097';
    /* if (window._env_.REACT_APP_ENV === 'test') { suffix = '_Dev'; iOsLink = 'https://tiny.app.link/qvYnVfpZFbb';}
    else if (window._env_.REACT_APP_ENV === 'demo') { suffix = '_Demo'; iOsLink = 'https://tiny.app.link/W51ss8t1Fbb';}
    else if (window._env_.REACT_APP_ENV === 'qa') { suffix = '_QA'; iOsLink = 'https://tiny.app.link/aiTxSkUYFbb';} */
        const clsName = this.state.isSignedIn ? 
                            this.state.route === 'session' ? "onprem_landing" : (sessionStorage.getItem('pageName') === 'MyProfile') ? "landing-my-profile" /**TP-3949*/ : "landing"
                        : (isAndroid || isiOS) ? "home home-background-md" : "home home-background-lg";// webrtc and OT sessions no header bar padding bottom
        const strUrl = window.location.href;
        const signinClsName = !this.state.isSignedIn && (this.state.route === 'signin' || this.state.route === 'signout') && !strUrl.includes('signup') ? "signin-clas": "";
        const planCode = this.getParams(window.location, 'plan').plan;
        const accountId = this.getParams(window.location, 'account_id').account_id;
        //guest user login parameters NS2-15
        const email = this.getParams(window.location, 'email_id').email_id;
        const groupId = this.getParams(window.location, 'group_id').group_id;
        const userId = this.getParams(window.location, 'user_id').user_id;//TP-566
        // console.log('strUrl', strUrl);
        let idleTimer;
        let footer;
        if (this.state.route === 'session') { 
          idleTimer = <Fragment>
            <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              element={document}
              onActive={this.onActive}
              onIdle={this.onIdle}
              onAction={this.onAction}
              debounce={250}
              timeout={this.state.idleTimeOut !== null ? this.state.idleTimeOut*60000 : parseInt(window._env_.REACT_APP_IDLE_TIME)} /**TP-2145 */
            />
            { 
              this.state.secondsRemaining < 10 && <IdleToast timer={this.state.secondsRemaining} onLogMeOut={this.autoLogout} stopThisSession={this.stopThisSession} onCancel={this.resetInterval} /> 
            }
          </Fragment>
        } else {
          idleTimer = '';
        }
        
        //TP-4028	& TP-3871
        const pageName = sessionStorage.getItem('pageName');
        if (this.state.route === 'home') {
          footer = <footer id="footer_bar" className="navbar-gradient footer footer-height" style={(pageName === "MyProfile") ? {display: "none"} : {display: "block"}}>            
                    <div className="row align-items-center"> 
                        {/* TZ-1049 */}
                        { !this.state.isSmartAdmin && window._env_.REACT_APP_APP_MODE !== "LAN"?
                            <div className="col-4 text-center" >                
                                {/* MB2-577 */}
                                <span className="white left">
                                  { /**TP-5267*/ window._env_.REACT_APP_ENV === "test" ?
                                    <a href="#" style={{ color: "white" }} onClick={() => this.getRandomPass() /**TP-5267*/}><Trans>Download App</Trans></a>
                                    :
                                    <Trans>Download App</Trans> 
                                  }
                                </span>
                                {
                                    !this.props.noInternet && window._env_.REACT_APP_APP_MODE !== "LAN"?
                                        <a target="_blank" href={'https://'+window._env_.REACT_APP_API_SOCKET_URL+'/apk/telepresenz.apk'}>
                                            <span className="app-icons">
                                                <i className="fab fa-android fa-lg" aria-hidden="true"></i>
                                            </span>
                                        </a>
                                    :
                                    <>
                                      {
                                        window._env_.REACT_APP_APP_MODE === "LAN" ?
                                          <a target="_blank" href={'https://'+window._env_.REACT_APP_API_SOCKET_URL+'/install/telepresenz.apk'}>
                                              <span className="app-icons">
                                                  <i className="fab fa-android fa-lg" aria-hidden="true"></i>
                                              </span>
                                          </a>
                                        :
                                            <span className="app-icons">
                                              <i className="fab fa-android fa-lg" aria-hidden="true"></i>
                                            </span>
                                      }
                                    </>
                                }
                                <span className="app-icons">|</span>   
                                {
                                    !this.props.noInternet && window._env_.REACT_APP_APP_MODE !== "LAN"?
                                            <a className="" target="_blank" href={iOsLink}>
                                                <span className="app-icons">
                                                    <i className="fab fa-apple fa-lg" aria-hidden="true"></i>
                                                </span>
                                            </a>
                                        :
                                            <span className="app-icons">
                                                <i className="fab fa-apple fa-lg" aria-hidden="true"></i>
                                            </span>
                                }                   
                            </div>
                          :
                            null
                        }
                        {this.state.user.isadmin ? 
                            <div className={(!this.state.isSmartAdmin && window._env_.REACT_APP_APP_MODE !== "LAN") ? "col-4 text-center" : "col-12 text-center"} >
                                {!this.props.noInternet?
                                    <a className={(!this.state.isSmartAdmin && window._env_.REACT_APP_APP_MODE !== "LAN") ? "middle": ""} target="_blank" href={this.state.isSmartAdmin ? "/SmartVision_terms&conditions.pdf" : window._env_.REACT_APP_ASSET_FOLDER+"/terms&conditions.pdf"}><Trans>Terms & Conditions</Trans></a>
                                    :
                                    <a className={(!this.state.isSmartAdmin && window._env_.REACT_APP_APP_MODE !== "LAN") ? "middle": ""} href="javascript:void(0)"><Trans>Terms & Conditions</Trans></a>
                                }
                            </div>
                            : 
                            <div className={!this.state.isSmartAdmin ? "col-4 text-center" : "col-6 text-center"}></div>
                        }
                        {
                          window._env_.REACT_APP_APP_MODE !== "LAN"?
                            <div className={ !this.state.isSmartAdmin ? "col-4 text-center" : "col-6 text-center"} >
                                {/*TP-2875 && TP-2871*/!this.props.noInternet?
                                    <>
                                      <a target="_blank" href={!this.state.isSmartAdmin ? "https://"+window._env_.REACT_APP_API_SOCKET_URL+"/userguide/Telepresenz%20User%20Guide.pdf" : "https://"+window._env_.REACT_APP_API_SOCKET_URL+"/userguide/Telepresenz%20User%20Guide_SV.pdf"}><Trans>User Guide</Trans></a>
                                      <span className="app-icons">|</span>
                                      <a target="_blank" href="https://training.telepresenz.com"><Trans>Training</Trans></a>
                                    </>
                                    :
                                    <a className={!this.state.isSmartAdmin ? "right":""} href="javascript:void(0)"><Trans>User Guide</Trans></a>
                                }
                            </div>
                            : null
                        }
                    </div>
                  </footer>
        } else {
          footer = '';
        }

        return (
        <div className={"App " + clsName + " " + signinClsName}>
          <ErrorBoundary>
            {this.props.noInternet? <div className="internet-status text-center font-weight-bolder"><Trans>You are Offline now</Trans></div> : ''}
          { idleTimer }
          {
            this.state.showAlreadyOnlineToast && 
            <OnlineWarningToast onHideToast={this.hideToast} />
          }
          
            <AccountConsumer>
                {({language, noInternet}) =>
                    <Header
                        onGoBack={this.onClickBack} 
                        isexpert={this.state.user.isexpert} 
                        route={this.state.route} 
                        joineescount={this.state.joineesCount} 
                        RCStatus={this.state.RCStatus}
                        techStopSession={this.state.techStopSession}
                        groupname={this.state.selectedGroupName} 
                        firstname={this.state.user.firstname} 
                        isSignedIn={this.state.isSignedIn}  
                        onSessionCmdChange={this.onSessionCmdChange} 
                        onRouteChange={this.onRouteChange} 
                        email={this.state.user.email} 
                        id={this.state.user.id}
                        myPicture={this.state.user.myPicture}
                        assignedGroups={this.state.user.groups} 
                        groupId={this.state.selectedGroupId}
                        chatUserCount = {this.state.chatUserCount}
                        chatNameArr = {this.state.chatNameArr}
                        headerFileShare = {this.state.fileShare}
                        updateFileShare={this.updateFileShare}
                        onChangeGroup={this.onChangeGroup}
                        sessionJoinees={this.state.sessionJoinees}
                        chatAll={this.state.chatAll}
                        shouldStartStopChange={this.state.shouldStartStopChange}
                        language={language}
                        creditsAvailable={this.state.creditsAvailable}
                        isGuest={this.state.isGuest}
                        guest_user_enabled={this.state.guest_user_enabled}
                        expert_ss_enabled={this.state.expert_ss_enabled}
                        screenShareAll={this.state.screenShareAll}
                        screenShareMaxDiv={this.state.screenShareMaxDiv}
                        fileShareMaxDiv={this.state.fileShareMaxDiv}                        
                        isSafari = {this.state.isSafari}
                        isFirefox = {this.state.isFirefox}
                        showHeaderActionButtons={this.state.showHeaderActionButtons}
                        hideExpertSSAll={this.state.hideExpertSSAll}
                        is_onprem={this.state.is_onprem}
                        noInternet={noInternet}
                        isiOS = {this.state.isiOS}
                        isMobileDevice={this.state.isMobileDevice} /*TP-2892*/
                        openMyProfile={this.openMyProfile}
                    />
                }
            </AccountConsumer>
            {
                strUrl.includes('signup') ? 
                    <AccountConsumer>
                        {({language}) =>
                            <Adminform language={language} closeAdminPopup={this.closeAdminPopup} planCode={planCode} />
                        }
                    </AccountConsumer> :
                strUrl.includes('registerTrial') ? 
                    <AccountConsumer>
                        {({language}) =>
                            <RegisterTrialAccount language={language} closeAdminPopup={this.closeAdminPopup} />
                        }
                    </AccountConsumer>:
                strUrl.includes('registerEnterpriseAccount') ? 
                    <AccountConsumer>
                        {({language}) =>
                            <EnterpriseRequestForm language={language} closeAdminPopup={this.closeAdminPopup} />
                        }
                    </AccountConsumer> :
                strUrl.includes('modifyEnterpriseAccount') ? 
                    <AccountConsumer>
                        {({language}) =>
                            <ModifyEnterprisRequestForm language={language} closeAdminPopup={this.closeAdminPopup} accountId={accountId} />
                        }
                    </AccountConsumer> :
                strUrl.includes('guest_login') ? 
                    <AccountConsumer>
                        {(consumertProps) =>
                            <GuestLogin {...consumertProps} email={email} loadUser={this.loadUser} onRouteChange={this.onRouteChange} 
                            loadSelectedGroup={this.loadSelectedGroup} loadSessionInfo={this.loadSessionInfo} groupid={groupId} 
                            changeStateforGuest={this.changeStateforGuest} changeonlyGuestState={this.changeonlyGuestState} />
                        }
                    </AccountConsumer>:
                strUrl.includes('live_session') ? 
                  <AccountConsumer>
                      {(consumertProps) =>
                          <AutoCall {...consumertProps} email={email} loadUser={this.loadUser} onRouteChange={this.onRouteChange} 
                          setUserForCall={this.setUserForCall} userid={userId} />
                      }
                  </AccountConsumer>:
                strUrl.includes('reset') ? 
                    <AccountConsumer>
                        {({language}) =>
                            <ResetPassword language={language} url={strUrl} 
                                onRouteChange={this.onRouteChange} onCloseModal={this.closeAdminPopup}
                                hasPasswordExpired={false}
                            />
                        }
                    </AccountConsumer>:
                (strUrl.includes('selfregister') && this.state.bRegister)?
                    <AccountConsumer>
                        {({language}) =>
                            <Register language={language} onRouteChange={this.onRouteChange} onRegistrationComplete={this.closeRegistration} inviteeURL={strUrl} />
                        }
                    </AccountConsumer> :
                (this.state.route === 'home' || this.state.route === 'session') ?
                    // session and account manager components come under the orientation check
                    <AccountConsumer>
                        {(consumerProps) =>
                            this.renderOrientationWrapper(consumerProps)
                        }
                    </AccountConsumer>
                : (this.state.route === 'resetExpiredPassword') ?
                    <AccountConsumer>
                        {({language}) =>
                            <ResetPassword language={language}
                                onCloseModal={this.closeAdminPopup}
                                loginEmail={this.state.loginEmail}
                                hasPasswordExpired={true}
                                behaviour={this.state.user.behaviour}
                                accountid={this.state.user.accountid}
                                id={this.state.user.id}
                            />
                        }
                    </AccountConsumer>
                /*: (TP-3672this.state.route === 'my-profile') ? 
                      <AccountConsumer>
                          {({language}) =>
                              <MyProfile language={language}
                                  id={this.state.user.id}
                                  first_name={this.state.user.firstname} /**TP-3794
                                  last_name={this.state.user.lastname} /**TP-3794
                                  email={this.state.user.email}
                                  isexpert={this.state.user.isexpert}
                                  myPicture={this.state.user.myPicture}
                                  streaming_quality={this.state.user.streaming_quality} /**TP-3794
                                  image_quality={this.state.user.image_quality} /**TP-3794
                                  camera_on={this.state.user.my_camera_on} /**TP-3794
                                  auto_connect={this.state.user.allow_auto_connect} /**TP-3794
                                  disable_notifications={this.state.user.disable_notifications} /**TP-3794
                                  enable_auto_connect={this.state.enable_auto_connect}
                                  enable_user_video={this.state.enable_user_video}
                                  enable_streaming={this.state.enable_streaming}
                                  isMobileDevice={this.state.isMobileDevice} //TP-3260
                                  isSmallFormFactor={this.state.isSmallFormFactor} //TP-3672
                                  onRouteChange={this.onRouteChange}
                                  updateMyProfilePicture={this.updateMyProfilePicture} //TP-3369
                                  updateChangePassword={this.updateChangePassword} //TP-3213
                                  loadUserProfile={this.loadUserProfile}
                                  updateMyProfilePic={this.updateMyProfilePic}
                                  isVerySmallDevice={this.state.isVerySmallDevice} //TP-3746
                              />
                          }
                      </AccountConsumer>*/
                :   (this.state.route === 'change-password' ? 
                      <AccountConsumer>
                          {({language}) =>
                              <ChangePassword language={language} 
                                  routeChange={this.onRouteChange} 
                                  onCloseModal={this.closeAdminPopup}
                                  behaviour={this.state.user.behaviour}
                                  accountid={this.state.user.accountid}
                                  id={this.state.user.id}
                                  oldPassword={this.state.oldPassword}
                                  email={this.state.user.email}
                                  isChangePassword={this.state.isChangePassword} //TP-3213
                                  last_accessed_on={this.state.user.last_accessed_on} //TP-5418
                                  loadLogMeIn={this.loadLogMeIn} //TP-5418
                              />
                          }
                      </AccountConsumer>
                :   (this.state.route === 'forgot-password' ? 
                    <AccountConsumer>
                        {({language}) =>
                            <ForgotPassword language={language} routeChange={this.onRouteChange}/>
                        }
                    </AccountConsumer>
                :((this.state.route === 'signin' || this.state.route === 'signout' /* && !this.state.isiOS */)
                    ? 
                    <AccountConsumer>
                        {(consumertProps) =>
                            <Signin {...consumertProps} email={this.state.user.email} verified={this.state.user.verified}
                                loadUser={this.loadUser} loadData={this.loadData} loadPassword={this.loadPassword} 
                                onRouteChange={this.onRouteChange} isGuest={this.state.isGuest} 
                                setLoginEmail={this.setLoginEmail} autoLogout = {this.autoLogout}
                                setSmartVision={this.setSmartVision} setPilot={this.setPilot} /**TP-2539 */ 
                                loadLogMeIn= {this.loadLogMeIn} /*FQ3-712*/ isMobileDevice={this.state.isMobileDevice} /*TP-2892*/
                            />
                        }
                    </AccountConsumer>
                    /* : this.state.isiOS ?
                        <NotSupported iOsLink={iOsLink}></NotSupported> */
                    :
                    <AccountConsumer>
                        {({language}) =>
                            <Signup language={language} loadSignupState={this.loadSignupState} onRouteChange={this.onRouteChange} loadUser={this.loadUser}/>
                        }
                    </AccountConsumer>
                )
                )
                )
            }
            { footer }
          </ErrorBoundary>
        </div>
        );
  }
}

const IdleToast = (props) => (
  <Toast className="toast-position">
    <ToastHeader>
      <Trans>Inactivity Timeout Warning</Trans>
    </ToastHeader>
    <ToastBody>
      <Trans>There has been no activity detected from you on this app. The application will automatically stop this session in</Trans> <b> {props.timer} </b>
      <div className="d-flex justify-content-end mt-3">
        <Button size="sm" className="mr-1" onClick={props.stopThisSession}><Trans>Stop the Session</Trans></Button>
        <Button size="sm" onClick={props.onCancel}><Trans>Cancel</Trans></Button>
      </div>
    </ToastBody>
  </Toast>
)

const OnlineWarningToast = (props) => (
  <Toast className="toast-position">
    <ToastHeader>
      <Trans>Cannot start the session</Trans>
    </ToastHeader>
    <ToastBody>
      <Trans>You are in a session on another device.</Trans>
      <div className="d-flex justify-content-end mt-3">
        <Button size="sm" className="mr-1" onClick={props.onHideToast}><Trans>Close</Trans></Button>
      </div>
    </ToastBody>
  </Toast>
)


export default App;
