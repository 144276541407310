import React, { Fragment } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import './Signin.scss';
import AuthService from '../AuthService';
import Loader from 'react-loader-spinner';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import {AccountConsumer} from"../AccountContext";
import Select from 'react-select';
import {Modal,ModalBody,ModalFooter,Button} from 'reactstrap';
import { encryptData, decryptData, printConsole, checkDummyEmail, formatMobile } from '../helpers';
import { version } from "../../../package.json";
import SigninForm from './SigninForm';
import { debounce } from 'lodash';

var login_attempts=10;
const waitTime = 300000;
class Signin extends React.Component {    
	constructor(props){
        super(props);
		this.authService = new AuthService();
		this.state = {
            signInEmail: !checkDummyEmail({verified: this.props.verified, email: this.props.email}) ? this.props.email: "",//TP-5751
			signInGeoLocation: '',
			signInPassword: '',
			isLoading: false,
            showLoggedInFlagSpinner: false,
            errorMsg: '',
            errorMsg2: '', //TP-2320
            language: '',
            languages: [
                {languageName:'English',code: 'en'},
                {languageName:'中文',code: 'zh-CN'},
                {languageName:'Français',code: 'fr'},
                {languageName:'Español',code: 'es'},
                {languageName:'Português',code: 'pt'},
                {languageName:'Italiana',code: 'it'},
                {languageName:'Deutsch',code: 'de'}, //TP-2305
                {languageName:'日本',code: 'ja-JP'},
                {languageName:'हिंदी',code: 'hi'},//TP-5907
                {languageName:'ଓଡିଆ',code: 'or'},//TP-5907
            ],
            isSignedIn:false,
            dialogMsg:'',
            haspasswordExpired:false, haspasswordExpiredForMobile: false,
            isAccountSuspended:false,
            loginFailed: false,
            accountAdmin: '',
            isSmartVision: false,
            isSmallFormFactor: false,
            reRender: false,
            isMobileDevice: props.isMobileDevice ? props.isMobileDevice : false, //TP-2892
            otp: new Array(7).join(" "), mobile: "", otpSend: false, isMobileRegistered: true,
            getOTPType: "login", displayMobileForm: false, smsCount: 0, canDoOTPLogin: true,
            canRequestOtp: false
            //old_email: ''
        }
        this._isMounted = false;
        // initialize validator
        this.validationRules(props.language);
		// if(this.state.signInEmail === '') this.state.signInEmail = 'email-id@domain';
    }
    //TP-1349 -- Function to refresh the Signin page only
    // when the Browser window is resized when in the Signin page
    reloadOnResize = () => {
        if (this.state.isSignedIn === false)
            this.setState({ reRender: true }, () => {
                this.setState({ reRender: false });
            });
            //window.location.reload();
    }
    
    componentDidMount = () => {
        this._isMounted = true;
        const {isGuest} = this.props;//guest user check NS2-15
        this.getLocation();
        const isSmall = this.checkUserAgent();
        this.setState({ isSmallFormFactor: isSmall }, () => {
            if (this.state.isSmallFormFactor === false)  {
                window.addEventListener("resize", this.reloadOnResize); //TP-1349
            }
        });
        const isSmartVision = this.getURL();
        if (typeof(Storage) !== "undefined") {
            //Set the session storage
            const loginatt = sessionStorage.getItem("login_attempts");
            if (loginatt === null) 
                sessionStorage.setItem("login_attempts", login_attempts);
            else
                login_attempts = parseInt(loginatt);//TP-4678
        } else {
            const message = "Sorry, your browser does not support Web Storage...";
            printConsole(message);
        }
        // Setting the state for Smartvision URL
        this.setState({ isSmartVision, isLoading: true });
		const isLoggedIn = this.authService.loggedIn();
        //console.log("isLoggedIn", isLoggedIn);
        if (isLoggedIn && !isGuest) {
			this.authService.getProfile()
				.then(response => {
					if(response.status === 200){
					return response.json();
				} else {
					throw new Error(response.body);
				}
			})
			.then(({user}) => {
				this.setState({isLoading: false});
                if (!user) return;
                // NS2-393 page refresh for guest should log him out 
                if(user.is_guest){
                    this.props.autoLogout()
                }
                printConsole(user);
				this.props.loadUser(user);
                if (user.behaviour === "smartvision" && user.isadmin === true) {
                    this.props.setSmartVision({ isSmartAdmin: true });
                    if (user.is_first_login && user.is_first_login === true)
                        this.props.onRouteChange('change-password');
                    else
                        this.props.onRouteChange('home');
                } else {
                    //TP-2539
                    if (user.behaviour === "peertopeer") {
                        this.props.setPilot({ isPilot: true });
                    }
                    this.props.onRouteChange('home');
                }
			})
			.catch((error) => {
				this.setState({isLoading: false});
				console.log(error)
			});
        } else {
            //TP-4709
            if (!isGuest)
			    this.setState({isLoading: false});
		}

        this.delayedCallback = debounce(this.canUserRequestOtp, 500);//TP-5943
    }

    componentWillUnmount() {
        this._isMounted = false;
        if (this.state.isSmallFormFactor === false)  {
            window.removeEventListener("resize", this.reloadOnResize, false);//TP-1349
        }
        if(this.otpRequestTimer){
            clearInterval(this.otpRequestTimer);
        }
        if(this.otpLoginTimer){
            clearInterval(this.otpLoginTimer);
        }
    }
    
    componentDidUpdate = (prevProps, prevState) =>{
        if (prevState.language !== this.state.language) {
            this.validationRules(this.state.language);
        }
    }
    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.language!==prevState.language){
           
          return {language : nextProps.language};
        } /**TP-2883 */
        else if(nextProps.isMobileDevice !== prevState.isMobileDevice) {
            return {isMobileDevice : nextProps.isMobileDevice};
        } 
        else return null;
    }

    checkUserAgent = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        this.setState({ isiOS: isiPad });
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let isSmallFormFactor = false;
        if (isiPad || isAndroid) {
            isSmallFormFactor = true;
        } 
        return isSmallFormFactor;
    }
    
    validationRules = (language) =>{
        SimpleReactValidator.addLocale(language, require(`../../locales/${language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: language,
        element: (message, className= "signin-validation-msg") => <div className={className}>{message.replace(/field|The/g, '').replace(/otp/g, 'OTP').capitalize()}{/*TP-6359,6473*/}</div>,
			validators: {
				emailValidate: {  // name the rule
					message: 'Please enter a valid email address',
					rule: (val, params, validator) => {
						const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
						return validator.helpers.testRegex(
							String(val).toLowerCase(),
							regex
						);
					},
					required: true  // optional
				},
                phone: {
                    message: 'Enter a valid phone number',
                    rule: (val,params,validator) => {
                        // let telRegex= /^[0-9\)\(+-\s]+$/g;
                        let telRegex= /^\d+$/;
                        return (validator.helpers.testRegex(val,telRegex));
                    }
                },
                otp_length: {
                    rule: (val,params,validator) => {
                        return (val.replace(" ", "").length === 6);//first replace the empty space with empty string
                    }
                }
			}
        });
    }

    onEmailChange = (value) => {
		// let elemError = document.getElementById("ShowError");
		// elemError.innerHTML = '';
		this.setState({
            errorMsg: '',
            signInEmail: value
        })
	}

	onPasswordChange = (value) => {
		// let elemError = document.getElementById("ShowError");
		// elemError.innerHTML = '';
		this.setState({
            errorMsg: '',
            signInPassword: value
        })
	}

    //TP-5751
    handleChange = name => event => {
        this.setState({
          [name]: name === "mobile" ? formatMobile(event.target.value): event.target.value,//TP-6548
        },() => {
            if(name === "mobile"){
                this.delayedCallback()//TP-6015
            }
        });
    };

    setOTP = (value) => {
        this.setState({otp: value})
    }

    //TP-5751 had to set to false to hide the fields
    hideMobileForm = (flag) => {
        this.setState({displayMobileForm: flag, errorMsg: "", errorMsg2: ""})
    }

    //TP-5751 
    showHideRegistrationForm = (flag) => {
        const getOTPType = flag ? "login" : "register"
        this.setState({isMobileRegistered: flag, getOTPType}, () => {
            this.canUserRequestOtp();//TP-6015
        })
    }

    onSetGeoLocation = (position) =>{
        if (this._isMounted) {
            this.setState({
                signInGeoLocation: `${position.coords.latitude},${position.coords.longitude}`
            });
        }
	}

    getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.onSetGeoLocation);
        } else { 
            if (this._isMounted) {
                this.setState({signInGeoLocation: ''});
            }
        }
	}

    // Method to fetch the URL domain of the webapp
    getURL = () => {
        let url = window.location.href;
        if (url.includes("pubsmartvision.com")) return true;
        return false;
    }
 	
	onLogmeIn = (e) => {
        const {displayMobileForm, mobile, otp} = this.state;
		this.setState({ showLoggedInFlagSpinner: true });
        this.props.loadLogMeIn({ logMeIn: true });
        let encryptedEmail = encryptData(this.state.signInEmail.toLowerCase()), encryptedPassword = encryptData(this.state.signInPassword),//FQ3-417
        encryptedMobile = encryptData(mobile), encryptedOtp = encryptData(otp), body;//TP-5751
        /* this.diagonastics({
            action: `updateSigninFlag API is called`,
            sequence: 1,
            next_step: '',
            data: `for user email: ${this.state.signInEmail.toLowerCase()}`,
            error: 'none'
        }) */
        if(displayMobileForm){
            body = JSON.stringify({
				mobile: encryptedMobile,
				otp: encryptedOtp,
			})
        }else{
            body = JSON.stringify({
				email: encryptedEmail,
				password: encryptedPassword,
			})
        }
		fetch(window._env_.REACT_APP_API_URL+'/updateSigninFlag', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body
		})
		.then(response => {
			this.setState({ status: response.status });
			if(response.status === 200) 
				return response.json();
			else
				throw response;
		})
		.then((res) => {
			this.setState({ 
                errorMsg: ''
            });
            /* this.diagonastics({
                action: `updateSigninFlag API call response success`,
                sequence: 1,
                next_step: '',
                data: `for user email: ${this.state.signInEmail.toLowerCase()}`,
                error: 'none'
            }) */
            if(displayMobileForm){
                this.mobileLogin("logmeIn");
            }else{
                this.onSubmitSignIn("logmeIn");
            }
			// let elemError = document.getElementById("ShowError");
			// elemError.innerHTML = '';
		})
		.catch((error) => {
            //TP-4706
            let errorMsg = i18nMark('Invalid Username or Password')
            if (error.status === 424) {
                errorMsg = i18nMark('User is already in session');
            }
			this.setState({ 
                errorMsg, /*TP-4706*/
                errorMsg2: '', //TP-2320
                showLoggedInFlagSpinner: false 
            });
			// output error in login
			// let elemError = document.getElementById("ShowError");
			// let strError = '<br>';
			// elemError.innerHTML = strError;
		})
	}

    submitLockUser = () => {
        const {displayMobileForm, mobile} = this.state;
        let encryptedEmail = encryptData(this.state.signInEmail.toLowerCase()),//FQ3-417
        encryptedMobile = encryptData(mobile), body;//TP-5751
		let elemSpin = document.getElementById("spinner_div");
		elemSpin.innerHTML = '<i class="fas fa-spinner fa-spin" style="font-size:60px;color:white;"></i>';
        if(displayMobileForm){
            body = JSON.stringify({
				mobile: encryptedMobile,
				flag: true
			})
        }else{
            body = JSON.stringify({
				email: encryptedEmail,
				flag: true
			})
        }
		fetch(window._env_.REACT_APP_API_URL+'/changePasswordLock', {
			method: 'put',
			headers: {'Content-Type': 'application/json'},
			body
		})
		.then(response => {
			this.setState({ status: response.status });
			if(response.status === 200) 
				return response.json();
			else
				throw response;
		})
        .then(() => {
			elemSpin.innerHTML = '';
			let strError = i18nMark('User Locked');
            let strError2 = (this.state.isSmartVision === true) ? i18nMark('Contact Admin') :  i18nMark('Contact Support'); //TP-4571
            this.setState({
                errorMsg: strError,
                errorMsg2: strError2
            })
		})
        .catch(error => {
            elemSpin.innerHTML = '';
			let strError = i18nMark('Password lock not work');
            if(error.status === 400){
                strError = i18nMark('Password lock failed');
            }
            if (error.status === 500) {
                strError = i18nMark('Password lock not work');
            }
            this.setState({
                errorMsg: strError
            })
        })
    }

    onSubmitSignout = (id) => {
        printConsole('signout called');
        const myLocation = "Bangalore North++East"; //getGeoLocation();
        const encryptedEmail = encryptData(this.state.signInEmail.toLowerCase());//FQ3-417
        this.authService.fetch('signout', { //TP-2028
          method: 'post',
          /* TP-2028 headers: {'Content-Type': 'application/json'}, */
          body: JSON.stringify({
            email: encryptedEmail,
            isencrypted: true,
            id: parseInt(id, 10),
            groupid: 0,
            geoLocation: myLocation
          })
        })
        .then(response => response.json())
        .then(data => {
            // adding this on success UQ3-208. Instead of the call adding it on success
            this.authService.logout();
            //this.props.onRouteChange('signout');
            let errorMsg = i18nMark('Admin only login');
            this.setState({errorMsg})
        })
        .catch(err => console.log(err.message))
    }

	onSubmitSignIn = (type="signIn") => {
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
		// this.onSetGeoLocation();
        let encryptedEmail = encryptData(this.state.signInEmail.toLowerCase()), encryptedPassword = encryptData(this.state.signInPassword);//FQ3-417
        let elemSpin = null;
        if (type !== "logmeIn") {
            elemSpin = document.getElementById("spinner_div");
            elemSpin.innerHTML = '<i class="fas fa-spinner fa-spin" style="font-size:60px;color:white;"></i>';
        } else if (type === "logmeIn") {
            /* this.diagonastics({
                action: `Signin API is called again`,
                sequence: 1,
                next_step: '',
                data: `for user email: ${this.state.signInEmail.toLowerCase()}`,
                error: 'none'
            }) */
        }
		fetch(window._env_.REACT_APP_API_URL+'/signin', {
			method: 'post',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({
				email: encryptedEmail,
				geoLocation: this.state.signInGeoLocation,
				password: encryptedPassword,
                isencrypted: true //FQ3-498
			})
		})
		.then(response => {
			this.setState({ status: response.status });
			if(response.status === 200) 
				return response.json();
			else
				throw response;
		})
		.then(({ user, token }) => {
            if (type !== "logmeIn") 
			    elemSpin.innerHTML = '';
			if(user && token){
                this.authService.setToken(token);
                this.authService.socketInit();
                this.props.subscribeToOnlineNotification();
                this.props.subscribeToNewImageUploadNotification();
                printConsole(user);
                this.props.loadUser(user);
                if (user.behaviour === "smartvision" && user.isadmin === true) {
                    //TP-4678
                    if (parseInt(sessionStorage.getItem("login_attempts")) === 0){
                        login_attempts = 10;
                        sessionStorage.setItem("login_attempts", login_attempts);
                    } 
                    this.props.setSmartVision({ isSmartAdmin: true }) 
                    if (user.is_first_login && user.is_first_login === true) {
                        this.props.loadPassword({password: this.state.signInPassword});
                        this.props.onRouteChange('change-password');
                    }
                    else 
                        this.props.onRouteChange('home');
                } else if (user.behaviour === "smartvision" && user.isadmin === false) {
                    this.onSubmitSignout(user.id);                    
                    return;
                } else /* if (user.behaviour !== "smartvision") */ {
                    //TP-4678
                    if (parseInt(sessionStorage.getItem("login_attempts")) === 0) {
                        login_attempts = 10;
                        sessionStorage.setItem("login_attempts", login_attempts);
                    }
                    this.props.loadPassword({password: this.state.signInPassword}); //TP-3483
                    //TP-2539
                    if (user.behaviour === "peertopeer") {
                        this.props.setPilot({ isPilot: true });
                    }
                    this.props.onRouteChange('home');
                }
                /* this.diagonastics({
                    action: `signin response payload`,
                    sequence: 1,
                    next_step: '',
                    data: `user email: ${user.email}, user_info: ${user.user_info}`,
                    error: 'none'
                }) */
			}
		})
		.catch((error) => {
            if (type !== "logmeIn") 
			    elemSpin.innerHTML = '';
			let strError = i18nMark('Invalid Username or Password');       
            let strError2 = ''; //TP-2320  
            if (error.status === 424) {
                //TP-4965
                this.toggleUserIsinSessionModal();
                return;
            } 
            if(error.status === 428){
                strError = i18nMark('Invalid user role');  
            }
            if(error.status === 409){
                /* this.diagonastics({
                    action: `Signin API has returned already logged in error code`,
                    sequence: 1,
                    next_step: '',
                    data: `error code: ${error.status}`,
                    error: 'none'
                }) */
                //TP-4965
                strError = '';
                strError2 = '';
                this.setState({
                    errorMsg: strError,
                    errorMsg2: strError2 
                })
                this.showHideLoggedInModal();
                return;
            }
            if(error.status === 403){
                error.text().then(data => {
                    data = JSON.parse(data);
                    console.log(data);
                    const {behaviour, accountid} = data;
                    //const accountid = data.accountid;
                    this.props.loadData({behaviour, accountid});
                    this.showHidePasswordExpiredModal();
                })
                .catch(err => {
                    console.log(err)
                })
                return;
            }
            if (error.status === 423) {
                error.text().then(data => {
                    data = JSON.parse(data);
                    const {behaviour, accountid} = data;
                    printConsole(data);
                    //const accountid = data.accountid;
                    this.props.loadData({behaviour, accountid});
                    //if (behaviour === "smartvision") {
                        this.toggleUserLockedModal();
                    //}
                })
                .catch(err => {
                    console.log(err)
                })
                return;
            }
            if(error.status === 503){
                // NS2-167 show the right message for the user whose account  is suspended
                error.text().then(errorMessage => {
                    let msg = errorMessage.replace(/"/g, '')
                    let name = msg.split(':::')
                    this.setState({accountAdmin: name[1]})
                    this.showHideAccountSuspendedModal();
                })
                .catch(err => {
                    console.log(err)
                })
            } else if (error.status !== 409 && error.status !== 403 && error.status !== 503 && error.status !== 423 && error.status !== 428) {
                error.text().then(data => {
                    data = JSON.parse(data);
                    printConsole(data);
                    const {behaviour, accountid} = data;
                    this.props.loadData({behaviour, accountid});
                    /**TP-4571 -- Commented out the comdition to check only for behaviour = "smartvision"*/
                    if (window._env_.REACT_APP_APP_MODE !== 'LAN') { 
                        //TP-4631 -- Only check and lock if not onprem link opened
                        if (typeof(Storage) !== "undefined") {
                            const old_email = decryptData(sessionStorage.getItem("old_email"));
                            if (old_email !== null && old_email !== this.state.signInEmail) {
                                login_attempts=9;
                            } else {
                                //TP-4678 only decrease the login_attempts counter if greater than 0
                                if (login_attempts > 0)
                                    login_attempts=login_attempts-1;
                            }
                            //Set the session storage
                            sessionStorage.setItem("login_attempts", login_attempts);
                            if (parseInt(sessionStorage.getItem("login_attempts")) > 0) {
                                this.toggleLoginFailedAttemptModal();
                            } else {
                                this.submitLockUser();
                            }
                            sessionStorage.setItem("old_email", encryptData(this.state.signInEmail.toLowerCase()));
                            //this.setState({ old_email: this.state.signInEmail });
                        } else {
                            const message = "Sorry, your browser does not support Web Storage...";
                            printConsole(message);
                        }
                        return;
                    }
                })
                .catch(err => {
                    console.log(err)
                })
            }            
            this.setState({
                errorMsg: strError,
                errorMsg2: strError2 //TP-2320
            })
		})
    }

    //TP-5751
    startOTPTimer = (timeDiff) => {
        const time = timeDiff ? timeDiff : waitTime
        this.otpRequestTimer = setTimeout(() => {
            this.stopOTPTimer();
            printConsole(`sms timmer started `)
        }, time);//5 minutes
    }

    stopOTPTimer = () => {
        clearTimeout(this.otpRequestTimer);
        //first set the storage then update state or else storage changed is delayed
        if (typeof(Storage) !== "undefined") {
            sessionStorage.removeItem("smsCount");
        } else {
            const message = "Sorry, your browser does not support Web Storage...";
            printConsole(message);
        }
        this.setState({smsCount: 0, errorMsg: "", errorMsg2: "", canRequestOtp: true});//TP-5805
    }

    //TP-5751
    startOTPLoginTimer = () => {
        this.otpLoginTimer = setTimeout(() => {
            this.stopOTPLoginTimer();
            printConsole(`otp login timmer started `)
        }, waitTime);//5 minutes
    }

    stopOTPLoginTimer = () => {
        clearTimeout(this.otpLoginTimer);
        this.setState({canDoOTPLogin: true, errorMsg: "", errorMsg2: ""});//TP-5805
    }

    //TP-6015
    canUserRequestOtp = async (e) => {
        //check only mobile validation, no need to show ui message, no need propmt validation message after few letter change
        if (!this.validator.fieldValid("mobile")) {
			this.forceUpdate();
			return;
		}
        let {mobile, getOTPType} = this.state;
        this.setState({ errorMsg: "", otpSend: false});
        //loader
        let elemSpin = null;
        elemSpin = document.getElementById("spinner_div");
        elemSpin.innerHTML = '<i class="fas fa-spinner fa-spin" style="font-size:60px;color:white;"></i>';
        //once getOTPType is set use that for resend otp also
        fetch(`${window._env_.REACT_APP_API_URL}/account_user/canRequestOTP`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                mobile, type: getOTPType, requested_from: "webapp"//TP-5815
            })
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
        .then((data) => {
            if(data){
                this.setState({ canRequestOtp: true });
            }
        })
        .catch (async (error) => {
            let strError = i18nMark('Not a valid number to register/login'), values;
            //TP-5813
            if(error.status === 425){
                const data = await error.json();
                const timeDiff = new Date() - new Date(data.otp_requested_on)
                const timeLeft = waitTime - timeDiff;
                printConsole(`${timeLeft} time is left`)
                const mins = Math.ceil(timeLeft/(1000*60))
                if(typeof(Storage) !== "undefined"){
                    values = {'mins': `${mins}`}
                    this.startOTPTimer(timeLeft);
                    strError = i18nMark(`Max. OTP requests reached`)
                    sessionStorage.setItem("smsCount", 3);
                    sessionStorage.setItem("old_mobile", encryptData(mobile));
                }else{
                    const message = "Sorry, your browser does not support Web Storage...";
                    printConsole(message);
                }
            }
            if(error.status === 452){
                strError = i18nMark("Unverified phone number")
            }
            if(error.status === 403){
                strError = i18nMark("Invalid user role")
            }
            printConsole(`error in canUserRequestOtp ${error}`)
            this.setState({ 
                canRequestOtp: false, errorMsg: strError, values
                //isMobileRegistered: true//TP-5805
            });
        })
        .finally(() => {
            elemSpin.innerHTML = '';
        })
    }

    //TP-5751
    onSubmitGetOtp = async (e) => {
        e.preventDefault();
        //check only mobile validation
        if (!this.validator.fieldValid("mobile")) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
		}
        let {mobile, getOTPType} = this.state, old_mobile, smsCount, strError;
        this.setState({ errorMsg: "", otpSend: false});
        //after page refresh go button is enabled. when clicked do this check
        if (typeof(Storage) !== "undefined") {
            old_mobile = decryptData(sessionStorage.getItem("old_mobile"));
            smsCount = sessionStorage.getItem("smsCount");
            if (old_mobile !== null && old_mobile === mobile) {
                if(smsCount >= 3){
                    this.startOTPTimer();
                    strError = i18nMark(`Max. OTP requests reached, try after some time`);
                    this.setState({ errorMsg: strError });
                    return;
                }
            }
        }else{
            const message = "Sorry, your browser does not support Web Storage...";
            printConsole(message);
        }

        //loader
        let elemSpin = null;
        elemSpin = document.getElementById("spinner_div");
        elemSpin.innerHTML = '<i class="fas fa-spinner fa-spin" style="font-size:60px;color:white;"></i>';
        //once getOTPType is set use that for resend otp also
        fetch(`${window._env_.REACT_APP_API_URL}/account_user/getOtp`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                mobile, type: getOTPType, requested_from: "webapp"//TP-5815
            })
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
        .then((data) => {
            strError = i18nMark('Enter OTP');       
            if(data){
                //first set the storage then update state or else storage changed is delayed
                if (typeof(Storage) !== "undefined") {
                    if (old_mobile !== null && old_mobile !== mobile) {
                        sessionStorage.setItem("smsCount", 1);//different mobile number so reset
                    } else {
                        const updatedSmsCount = smsCount ? parseInt(smsCount) + 1 : 1
                        sessionStorage.setItem("smsCount", updatedSmsCount);
                        if(updatedSmsCount >= 3){
                            this.startOTPTimer();
                            strError = i18nMark(`Max. OTP requests reached, try after some time`)
                        }
                    }
                    sessionStorage.setItem("old_mobile", encryptData(mobile));
                }else{
                    const message = "Sorry, your browser does not support Web Storage...";
                    printConsole(message);
                }
                this.setState((prevState) => ({ otpSend: true, isMobileRegistered: false, errorMsg: strError,
                    smsCount: prevState.smsCount + 1, otp: new Array(7).join(" ")//TP-5813
                }));
            }
        })
        .catch ((error) => {
            let strError = i18nMark('Not a valid number to register/login');
            //TP-5813
            if(error.status === 425){
                if(typeof(Storage) !== "undefined"){
                    this.startOTPTimer();
                    strError = i18nMark(`Max. OTP requests reached, try after some time`)
                    sessionStorage.setItem("smsCount", 3);
                    sessionStorage.setItem("old_mobile", encryptData(mobile));
                }else{
                    const message = "Sorry, your browser does not support Web Storage...";
                    printConsole(message);
                }
            }
            if(error.status === 403){
                strError = i18nMark("Invalid user role")
            }
            printConsole(`error in onSubmitGetOtp ${error}`)
            this.setState({ 
                otpSend: false, errorMsg: strError,
                //isMobileRegistered: true//TP-5805
            });
        })
        .finally(() => {
            elemSpin.innerHTML = '';
        })
    }

    //TP-5751
    registerMobile = async (e) => {
        e.preventDefault();
        if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
		}
        //loader
        let elemSpin = null;
        elemSpin = document.getElementById("spinner_div");
        elemSpin.innerHTML = '<i class="fas fa-spinner fa-spin" style="font-size:60px;color:white;"></i>';
        let {mobile, otp} = this.state;
        fetch(`${window._env_.REACT_APP_API_URL}/account_user/selfRegisterUsingPhone`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                mobile, otp: parseInt(otp)
            })
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
        .then((data) => {
            let strError = i18nMark('User registered successfully');       
            if (data) {
                this.setState({ otpSend: false, errorMsg: strError, isMobileRegistered: true,/* to login */
                otp: new Array(7).join(" ") , getOTPType: "login"});//auto populate mobile after registration
                //reset the count
                if (typeof(Storage) !== "undefined") {
                    sessionStorage.removeItem("smsCount");
                } else {
                    const message = "Sorry, your browser does not support Web Storage...";
                    printConsole(message);
                }
            }
        })
        .catch ((error) => {
            printConsole(` error in registerMobile ${error}`)
            let strError = i18nMark('Invalid OTP');
            if(error.status === 403){
                strError = i18nMark('Expired OTP');//TP-6004
            }
            this.setState({ 
                errorMsg: strError, //isMobileRegistered: true,//TP-5828
                errorMsg2: ""//TP-5805, case 6
            });
        })
        .finally(() => {
            elemSpin.innerHTML = '';
        })
    }

    //TP-5751
    mobileLogin = (type="signIn") => {
        if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
		}
        let {mobile, otp, signInGeoLocation} = this.state, encryptedMobile = encryptData(mobile), encryptedOtp = encryptData(otp);
        let elemSpin = null;
        if (type !== "logmeIn") {
            elemSpin = document.getElementById("spinner_div");
            elemSpin.innerHTML = '<i class="fas fa-spinner fa-spin" style="font-size:60px;color:white;"></i>';
        }
        fetch(`${window._env_.REACT_APP_API_URL}/signInWithPhone`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                mobile: encryptedMobile, otp: encryptedOtp,
                geoLocation: signInGeoLocation,
                isencrypted: true //FQ3-498
            })
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                throw response;
            }
        })
		.then(({ user, token }) => {
            if (type !== "logmeIn") 
			    elemSpin.innerHTML = '';
			if(user && token){
                this.authService.setToken(token);
                this.authService.socketInit();
                this.props.subscribeToOnlineNotification();
                this.props.subscribeToNewImageUploadNotification();
                printConsole(user);
                this.props.loadUser(user);
                //TP-4678
                // if(parseInt(sessionStorage.getItem("login_attempts")) === 0){
                    sessionStorage.removeItem("login_attempts");
                    sessionStorage.removeItem("smsCount");
                    sessionStorage.removeItem("otpLoginAttempt");
                // }
                this.props.onRouteChange('home');
                let strError = i18nMark("Login Successful");       
                this.setState({ otpSend: false, errorMsg: strError, otp: new Array(7).join(" ") , mobile: ""});
            }
        })
        .catch ((error) => {
            printConsole(` error in mobileLogin ${error}`)
            if (type !== "logmeIn"){
			    elemSpin.innerHTML = '';
            }
			let strError = "", strError2 = i18nMark('Invalid OTP'); //TP-2320  
            if (error.status === 430){
                strError2 = i18nMark('Expired OTP')//TP-6004
            }
            if (error.status === 424) {
                //TP-4965
                this.toggleUserIsinSessionModal();
                return;
            } 
            if(error.status === 409){
                //TP-4965
                strError = '';  strError2 = '';
                this.setState({
                    errorMsg: strError,
                    errorMsg2: strError2 
                })
                this.showHideLoggedInModal();
                return;
            }
            if(error.status === 403){
                error.text().then(data => {
                    data = JSON.parse(data);
                    printConsole(data);
                    const {behaviour, accountid} = data;
                    this.props.loadData({behaviour, accountid});
                    this.showHidePasswordExpiredModalForMobile();
                })
                .catch(err => {
                    printConsole(err)
                })
                return;
            }
            if (error.status === 423) {
                error.text().then(data => {
                    data = JSON.parse(data);
                    const {behaviour, accountid} = data;
                    printConsole(data);
                    this.props.loadData({behaviour, accountid});
                    this.toggleUserLockedModal();
                })
                .catch(err => {
                    printConsole(err)
                })
                return;
            }
            if(error.status === 503){
                // NS2-167 show the right message for the user whose account  is suspended
                error.text().then(errorMessage => {
                    let msg = errorMessage.replace(/"/g, '')
                    let name = msg.split(':::')
                    this.setState({accountAdmin: name[1]})
                    this.showHideAccountSuspendedModal();
                })
                .catch(err => {
                    printConsole(err)
                })
            } else if (error.status !== 409 && error.status !== 403 && error.status !== 503 && error.status !== 423) {
                error.text().then(data => {
                    data = JSON.parse(data);
                    printConsole(data);
                    const {behaviour, accountid} = data;
                    this.props.loadData({behaviour, accountid});
                    /**TP-4571 -- Commented out the comdition to check only for behaviour = "smartvision"*/
                    if (window._env_.REACT_APP_APP_MODE !== 'LAN') { 
                        //TP-4631 -- Only check and lock if not onprem link opened
                        if (typeof(Storage) !== "undefined") {
                            const old_mobile = decryptData(sessionStorage.getItem("old_mobile"));
                            if (old_mobile !== null && old_mobile !== this.state.mobile) {
                                login_attempts=9;//different mobile number so reset
                            } else {
                                //TP-4678 only decrease the login_attempts counter if greater than 0
                                if (login_attempts > 0){
                                    login_attempts=login_attempts-1;
                                }else if(login_attempts === 0){
                                    login_attempts=9// reset to start from beginning
                                }
                            }
                            //Set the session storage
                            sessionStorage.setItem("login_attempts", login_attempts);
                            if (parseInt(sessionStorage.getItem("login_attempts")) > 0) {
                                this.toggleLoginFailedAttemptModal();
                            } else {
                                const otpLoginAttempt = sessionStorage.getItem("otpLoginAttempt");
                                const updatedOtpLoginAttempt = otpLoginAttempt ? parseInt(otpLoginAttempt) + 1 : 1
                                sessionStorage.setItem("otpLoginAttempt", updatedOtpLoginAttempt);
                                
                                if(updatedOtpLoginAttempt >= 3 ){
                                    sessionStorage.removeItem("otpLoginAttempt");
                                    this.submitLockUser();
                                }else{
                                    strError = i18nMark('Too many invalid attempts, try after some time')
                                    this.startOTPLoginTimer();
                                }
                                this.setState({canDoOTPLogin: false, errorMsg: strError})//this setstate happens after the outer block state change beacuse of async promise
                            }
                            sessionStorage.setItem("old_mobile", encryptData(this.state.mobile));
                            //this.setState({ old_email: this.state.signInEmail });
                        } else {
                            const message = "Sorry, your browser does not support Web Storage...";
                            printConsole(message);
                        }
                        return;
                    }
                })
                .catch(err => {
                    printConsole(err)
                })
            }            
            this.setState({
                errorMsg: strError,
                errorMsg2: strError2, //TP-2320
                otpSend: true//show otp field
            })
        })
    }

	enterPressedFromPassword = (event) => {
    var code = event.keyCode || event.which;
    if(code === 13) { //13 is the enter keycode
            //TP-4678 -- To keep same behavior with enter keypress as that of clicking the Go button
            if (this.state.signInEmail && this.state.signInPassword && !this.props.noInternet /* && login_attempts === 0  */)
                this.onSubmitSignIn();
    	} 
	}

	enterPressedFromName = (event) => {
    var code = event.keyCode || event.which;
    if(code === 13) { //13 is the enter keycode
        let pwdElement = document.getElementById('password');
        pwdElement.focus();
    	} 
    }

    onClickForgotPassword = (e) => {
        e.preventDefault();
        if(this.props.noInternet) return;
        this.props.onRouteChange('forgot-password');
	}
	
    onClickChangePassword = (e) => {
        e.preventDefault();
        this.props.onRouteChange('change-password')
    }

    showLoader = () => {
        if (!this.state.isLoading) return '';
        return (
            <div className="loader">
                <Loader
                    type="ThreeDots"
                    color="#23c97d"
                    height="70"	
                    width="70"
                />
            </div>
        )
    }

    languageOptions = () =>{
        let {languages} = this.state;
        return languages.map(p => {
            return {
                value: p.code,
                label: `${p.languageName}`
            }
        });

    }

    selectedLanguage = (language) => {
        let {languages} = this.state;        
        let languageOption = languages.find(obj => {
            return obj.code === language
        });
        if (languageOption === undefined) {
            console.log("The default language is not within our supported languages...");
            language = 'en';
            languageOption = languages.find(obj => {
                return obj.code === language
            });
            console.log(languageOption);
        }
        return {
            value: languageOption.code,
            label: `${languageOption.languageName}`
        };
    }
    // https://care4d-telepresenz.atlassian.net/browse/NS2-320
    showHideAccountSuspendedModal = () => {
        let errorMsg;
        this.setState((prevState) => ({
            isAccountSuspended: !prevState.isAccountSuspended,
        }),() => {
            if(this.state.isAccountSuspended){
                errorMsg = i18nMark("Account Suspended")
                this.setState({dialogMsg: errorMsg})
            }
        });
    }
    // https://care4d-telepresenz.atlassian.net/browse/NS2-320
    showHidePasswordExpiredModal = () => {
        let errorMsg;
        this.setState((prevState) => ({
            haspasswordExpired: !prevState.haspasswordExpired,
        }),() => {
            if(this.state.haspasswordExpired){
                errorMsg = i18nMark("Your password has expired. Do you want to change it now")
                this.setState({dialogMsg: errorMsg})
            }else{
                errorMsg = i18nMark('Your password has expired');
                this.setState({errorMsg})
            }
        });
    }

    //TP-5751
    showHidePasswordExpiredModalForMobile = () => {
        let errorMsg;
        this.setState((prevState) => ({
            haspasswordExpiredForMobile: !prevState.haspasswordExpiredForMobile,
        }),() => {
            if(this.state.haspasswordExpiredForMobile){
                errorMsg = i18nMark('Your password has expired');
                this.setState({dialogMsg: errorMsg})
            }
        });
    }

    //https://care4d-telepresenz.atlassian.net/browse/NS2-320
    showHideLoggedInModal = () => {        
        let errorMsg;
        this.setState((prevState) => ({
            isSignedIn: !prevState.isSignedIn,
        }),() => {
            /* this.diagonastics({
                action: `logmein is triggered`,
                sequence: 1,
                next_step: '',
                data: `changed isSignedIn: ${this.state.isSignedIn}`,
                error: 'none'
            }) */
            if(this.state.isSignedIn){
                errorMsg = i18nMark("User is already logged in to Telepresenz. Do you want to log in now?")
                this.setState({dialogMsg: errorMsg})
            }else{
                errorMsg = i18nMark('User is already logged in to Telepresenz.');
                this.setState({errorMsg, errorMsg2: ''}) //TP-2320
            }
        });
    }

    toggleLoginFailedAttemptModal = () => {
        let errorMsg;
        let values;
        /* this.setState((prevState) => ({
            loginFailed: !prevState.loginFailed,
        }), () => { */
            //if(this.state.loginFailed){
                //errorMsg = i18nMark("Login Failed");
                errorMsg = "Login Failed";
                values= {'number': `${login_attempts}`};
                //this.setState({dialogMsg: errorMsg});
                this.setState({errorMsg, values});
            /* } else {
                errorMsg = i18nMark('Invalid Username or Password');
                this.setState({errorMsg})
            } */
       /*  }); */
    }

    toggleUserLockedModal = () => {
        const strError = i18nMark('User Locked');
        const strError2 = i18nMark('Contact Admin');
        this.setState({
            errorMsg: strError,
            errorMsg2: strError2
        })
    }

    //TP-4965
    toggleUserIsinSessionModal = () => {
        const strError = i18nMark('User is already in session');
        const strError2 = '';
        this.setState({
            errorMsg: strError,
            errorMsg2: strError2
        })
    }

    loggedInModal = () => {
        return (
            <>
                <Modal isOpen={this.state.isSignedIn} toggle={this.showHideLoggedInModal}>
                    <ModalBody>
                        <Trans id={this.state.dialogMsg}></Trans>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" className="btn-g" onClick={this.onLogmeIn}><Trans>Yes</Trans></Button>{' '}
                        <Button size="sm" className="btn-r" onClick={this.showHideLoggedInModal}><Trans>No</Trans></Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    passwordExpiredModal = () => {
        return (
            <>
                <Modal isOpen={this.state.haspasswordExpired} toggle={this.showHidePasswordExpiredModal}>
                    <ModalBody>
                        <Trans id={this.state.dialogMsg}></Trans>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" className="btn-g" onClick={() => {
                                this.props.setLoginEmail(this.state.signInEmail.toLowerCase())
                                this.props.onRouteChange('resetExpiredPassword')
                            }}>
                            <Trans>Yes</Trans>
                        </Button>{' '}
                        <Button size="sm" className="btn-r" onClick={this.showHidePasswordExpiredModal}><Trans>No</Trans></Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    passwordExpiredModalForMobile = () => {
        return (
            <>
                <Modal isOpen={this.state.haspasswordExpiredForMobile} toggle={this.showHidePasswordExpiredModalForMobile}>
                    <ModalBody className="align-center">
                        <Trans id={this.state.dialogMsg}></Trans>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" className="btn-r" onClick={this.showHidePasswordExpiredModalForMobile}><Trans>OK</Trans></Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    accountSuspendedModal = () => {
        return (
            <>
                <Modal isOpen={this.state.isAccountSuspended} toggle={this.showHideAccountSuspendedModal}>
                    <ModalBody>
                        <Trans id={this.state.dialogMsg} values={{'name': `${this.state.accountAdmin}`}}></Trans>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" className="btn-g" onClick={this.showHideAccountSuspendedModal}><Trans>OK</Trans></Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    loginFailedAttemptModal = () => {
        return (
            <>
                <Modal isOpen={this.state.loginFailed} toggle={this.toggleLoginFailedAttemptModal}>
                    <ModalBody>
                        <Trans id={this.state.dialogMsg} values={{'number': `${login_attempts}`}}></Trans>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" className="btn-g" onClick={this.toggleLoginFailedAttemptModal}><Trans>OK</Trans></Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    diagonastics = (data) => {
        let fetchString = 'logDiagnostics/';
        this.authService.fetch(fetchString, {
            method: 'post',
            body: JSON.stringify({
                data
            })
        })
        .then(response => response.json())
        .then(data => {
            // console.info(data)
            return data;
        })
        .catch(err => console.error(err.message))
        //return;
    }

	render() {
        try {
		    //const {onRouteChange} = this.props;
            const { isSmartVision, isSignedIn, isSmallFormFactor, reRender } = this.state;
            let bgImgCls = "w-60 center db";
            if (isSmallFormFactor === false && window.innerWidth > 1559) {
                bgImgCls = "w-90 center db";
            } else if(isSmallFormFactor === false && window.innerWidth > 1450 && window.innerWidth < 1559){
                bgImgCls = "w-70 center db";
            } else {
                bgImgCls = "w-60 center db";
            }
            if (isSignedIn === true) {
                return (
                    <>
                    <div className='center'>
                        <div className='log-me-center-dialog log-me-color log-me-min-width'>
                            { isSmartVision === false ?
                                <div className="bg-transparent center mw5">
                                    <a className="link dim mt-4 lh-title" href="https://www.telepresenz.com/" target="_blank" style={{outline:'none'}}>
                                    <img alt="Log into telepresenz!" className={bgImgCls} src={window._env_.REACT_APP_ASSET_FOLDER+"/signin_page_logo.png"} />
                                    </a>
                                </div>
                                :
                                <div className="row bg-transparent no-gutters align-items-center">
                                    <div className="col-6">
                                        <a className="link dim lh-title">
                                            <img alt="Log into telepresenz!" className="w-60 mw4 right logo-right" src={window._env_.REACT_APP_ASSET_FOLDER+"/signin_page_logo.png"} />
                                        </a>
                                    </div>
                                    
                                    <div className="col">
                                        <span className="">
                                            <span className="row no-gutters align-items-center link lh-title">
                                                <img alt="Log into smartvision!" className="logo-left w-60 mw4  mt2" src="publogo_final.png" />
                                            </span>
                                            <span className="row no-gutters align-items-center" style={{color: '#ffffff', fontWeight: "bold"}}>
                                                <div className="text-left">SMARTvision</div>
                                            </span>  
                                        </span>
                                    </div>
                                </div>
                            }
                            { this.state.showLoggedInFlagSpinner && <i className="fas fa-spinner fa-spin fa-3x white mr-1" style={{marginTop: '5%', marginLeft: '46%'}}></i> }
                            <div className="margin10 form-text-color" style={this.state.showLoggedInFlagSpinner ? {marginTop: '5%', fontSize: '20px'} : isSmallFormFactor === false && window.innerWidth < 1250 ? {marginTop: '6%', fontSize: '20px'} : {marginTop: '10%', fontSize: '20px'}}>
                                <Trans id={this.state.dialogMsg}></Trans>
                            </div>
                            <div className="flex-center" style={isSmallFormFactor === false && window.innerWidth < 1250 ? {marginTop: '6%'} : {marginTop: '10%'}}>
                                <button className="btn-green" onClick={this.onLogmeIn}><Trans>Yes</Trans></button>{' '}
                                <button className="margin10 btn-red" onClick={this.showHideLoggedInModal}><Trans>No</Trans></button>
                            </div>
                            {/**TP-4706*/}
                            <div id="ShowError" className="text-center mt4">
                                <Trans id={this.state.errorMsg} values={this.state.values ? this.state.values: ''}></Trans> <br/>
                                {/* <Trans id={this.state.errorMsg2 !== '' ? this.state.errorMsg2 : '' } values={this.state.values ? this.state.values: ''}></Trans> */}
                            </div>
                            <div id="spinner_div" className="text-center"></div>
                            <div className={isSmartVision === false ? "row col-12 center": "row col-12 center"} style={isSmallFormFactor === false && window.innerWidth < 1250 ? {marginTop: '6%'} : {marginTop: '10%'}}>
                                {this.props.noInternet ?
                                    <a href="javascript:void(0)" className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-link-font-md": ""} style={{color: '#ffffff'}}><Trans id="signin.privacyPolicy" /></a>
                                    :
                                    <a href={window._env_.REACT_APP_ASSET_FOLDER+"/privacy_policy.pdf"} target="_blank" className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-link-font-md": ""} rel="noopener noreferrer" style={{color: '#ffffff'}}>
                                        <Trans id="signin.privacyPolicy" />
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters align-items-center">
                        {/* MB2-577 */}
                        {/* isSmartVision === false ?
                            <div className="col-3 text-center">
                                {this.props.noInternet ?
                                    <Button className="logo-button" style={{ borderRadius: '40px' }}>
                                        <img alt="Go to Telepresenz!" src="Telepresenz_Logo_Purple.png" style={{width:'130px'}}/>
                                    </Button>
                                    :
                                    <a href="https://www.telepresenz.com/" target="_blank" rel="noopener noreferrer">
                                        <Button className="logo-button" style={{ borderRadius: '40px' }}>
                                            <img alt="Go to Telepresenz!" src="Telepresenz_Logo_Purple.png" style={{width:'130px'}}/>
                                        </Button>
                                    </a>
                                }
                            </div>
                            :
                            ''
                            */}
                        {/* MB2-577 */}                        
                        {/* MB2-577 */}
                       {/*  <div className="col-3 dim text-center">
                            {this.props.noInternet? 
                                <img alt="Go to Telepresenz Pricing!" src="Learn-More-bigger.png"  style={{width:'35%'}}/>
                                :
                                <a href="https://www.telepresenz.com/pricing" target="_blank" rel="noopener noreferrer">
                                    <img alt="Go to Telepresenz Pricing!" src="Learn-More-bigger.png"  style={{width:'35%'}}/>
                                </a>
                            }
                        </div> */}
                    </div>
                    </>
                );
            } else if (isSignedIn === false && reRender === true) {
                return(
                    this.state.isLoading ? this.showLoader() :
                        <AccountConsumer>
                            {({language,setLanguage}) =>
                                <Fragment>
                                    {   process.env.REACT_APP_VERSION !== "" && process.env.REACT_APP_VERSION !== null && process.env.REACT_APP_VERSION !== undefined ?
                                            <span className="position-absolute ml-2 mt-2 version-badge" >{process.env.REACT_APP_VERSION}</span>
                                            : 
                                            <span className="position-absolute ml-2 mt-2 version-badge">{version}</span>
                                    }
                                    <div className="">
                                        <Select
                                            value={this.selectedLanguage(language)}
                                            readonly="readonly"
                                            onChange={(selectedOption) => setLanguage(selectedOption.value)}
                                            options={this.languageOptions()}
                                            className={isSmallFormFactor ? "position-absolute signin-dropdown col-sm-3" : window.innerWidth > 1559 ? "position-absolute signin-dropdown col-sm-1" : window.innerWidth > 1450 && window.innerWidth < 1559 ? "position-absolute signin-dropdown col-sm-2"  : "position-absolute signin-dropdown col-md-2" }
                                            menuPlacement="bottom"
                                            isSearchable={this.state.isSmallFormFactor === true ? false : true} /**TP-2892 */
                                        />
                                    </div>
                                    {/**TP-1728 */}
                                    <div className='row text-center'>
                                        
                                    </div>
                                    <div className="center">
                                        <main className="black-80 relative">
                                            { isSmartVision === false ?
                                                <div className="bg-transparent center mw5">
                                                    <a className="link dim lh-title" href="https://www.telepresenz.com/" target="_blank" style={{outline:'none'}}>
                                                    <img alt="Log into telepresenz!" className={bgImgCls} src={window._env_.REACT_APP_ASSET_FOLDER+"/signin_page_logo.png"} />
                                                    </a>
                                                </div>
                                                :
                                                <div className="row bg-transparent no-gutters align-items-center">
                                                    <div className="col-6">
                                                        <a className="link dim lh-title">
                                                            <img alt="Log into telepresenz!" className="w-60 mw4 right logo-right" src={window._env_.REACT_APP_ASSET_FOLDER+"/signin_page_logo.png"} />
                                                        </a>
                                                    </div>
                                                    
                                                    <div className="col">
                                                        <span className="">
                                                            <span className="row no-gutters align-items-center link lh-title">
                                                                <img alt="Log into smartvision!" className="logo-left w-60 mw4  mt2" src="publogo_final.png" />
                                                            </span>
                                                            <span className="row no-gutters align-items-center" style={{color: '#ffffff', fontWeight: "bold"}}>
                                                                <div className="text-left">SMARTvision</div>
                                                            </span>  
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            <div className="signin-wrapper" style={{marginTop: '2%'}}>
                                                <div id="sign_up" className="ba b--transparent ph0 mh0">
                                                {/* <legend className="f3 fw6 ph0 mh0 tc center" style={{color: '#fff'}}>Sign in</legend> */}
                                                </div>
                                                
                                                    <SigninForm isSmallFormFactor={isSmallFormFactor} signInEmail={this.state.signInEmail} 
                                                        signInPassword={this.state.signInPassword} noInternet={this.props.noInternet} login_attempts={login_attempts}
                                                        onEmailChange={this.onEmailChange} enterPressedFromName={this.enterPressedFromName}
                                                        onPasswordChange={this.onPasswordChange} enterPressedFromPassword={this.enterPressedFromPassword}
                                                        onSubmitSignIn={this.onSubmitSignIn} validator={this.validator} onSubmitGetOtp={this.onSubmitGetOtp}
                                                        handleChange={this.handleChange} otp={this.state.otp} mobile={this.state.mobile} otpSend={this.state.otpSend} 
                                                        hideMobileForm= {this.hideMobileForm} showHideRegistrationForm= {this.showHideRegistrationForm}
                                                        isMobileRegistered = {this.state.isMobileRegistered} registerMobile = {this.registerMobile} isSmartVision = {isSmartVision}
                                                        onClickForgotPassword = {this.onClickForgotPassword} displayMobileForm = {this.state.displayMobileForm} getOTPType = {this.state.getOTPType} mobileLogin = {this.mobileLogin}
                                                        setOTP = {this.setOTP} smsCount = {this.state.smsCount} canDoOTPLogin = {this.state.canDoOTPLogin} canRequestOtp = {this.state.canRequestOtp} canUserRequestOtp = {this.canUserRequestOtp}
                                                    />
                                                <div id="ShowError" className="text-center ">
                                                    <Trans id={this.state.errorMsg} values={this.state.values ? this.state.values: ''}></Trans> <br/>
                                                    <Trans id={this.state.errorMsg2 !== '' ? this.state.errorMsg2 : '' } values={this.state.values ? this.state.values: ''}></Trans>
                                                </div>
                                                <div id="spinner_div" className="text-center"></div>
                                                <div className="row no-gutters" style={window.innerWidth > 1559 ? {marginTop: '2%'} : {marginTop: '2%'}}>
                                                    <div className="col-12 center-text">{/* TP-5751 */}
                                                        {this.props.noInternet ?
                                                            <a href="javascript:void(0)" className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-link-font-md": ""} style={{color: '#ffffff'}}><Trans id="signin.privacyPolicy" /></a>
                                                            :
                                                            <a href={window._env_.REACT_APP_ASSET_FOLDER+"/privacy_policy.pdf"} className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-link-font-md": ""} style={{color: '#ffffff'}} target="_blank" rel="noopener noreferrer">
                                                                <Trans id="signin.privacyPolicy" />
                                                            </a>
                                                        }
                                                    </div>
                                                </div>
                                                {/* { this.state.status === 409 && <div className={'text-center forget-password-link ' + (this.state.showLoggedInFlagSpinner ? '' : 'animate bounce')}>
                                                    { this.state.showLoggedInFlagSpinner && <i className="fas fa-spinner fa-spin white mr-1"></i> }
                                                    <a href="javascript:void(0);" onClick={(e) => this.onLogmeIn(e)} className="link dim lh-title"><Trans>Log me in now.</Trans></a>
                                                </div> } */}
                                                {/**TP-2320*/}
                                            </div>
                                        </main>
                                    </div>
                                    <div className={window.innerWidth > 1559 ? "row no-gutters align-items-center row-min-height-lg" : "row no-gutters align-items-center row-min-height-md"} style={window.innerWidth > 1559 ? {marginTop: '0'} : {marginTop: '-2.5%'}}>
                                        {/* MB2-577 */}
                                        {/* isSmartVision === false ?
                                            <div className="col-3 text-center">
                                                {this.props.noInternet ?
                                                    <Button className="logo-button" style={{ borderRadius: '40px' }}>
                                                        <img alt="Go to Telepresenz!" src="Telepresenz_Logo_Purple.png" style={{width:'130px'}}/>
                                                    </Button>
                                                    :
                                                    <a href="https://www.telepresenz.com/" target="_blank" rel="noopener noreferrer">
                                                        <Button className="logo-button" style={{ borderRadius: '40px' }}>
                                                            <img alt="Go to Telepresenz!" src="Telepresenz_Logo_Purple.png" style={{width:'130px'}}/>
                                                        </Button>
                                                    </a>
                                                }
                                            </div>
                                            :
                                            ''
                                            */}
                                        {/* MB2-577 */}                                        
                                        {/* MB2-577 */}
                                        {/* <div className="col-3 dim text-center">
                                            {this.props.noInternet? 
                                                <img alt="Go to Telepresenz Pricing!" src="Learn-More-bigger.png"  style={{width:'35%'}}/>
                                                :
                                                <a href="https://www.telepresenz.com/pricing" target="_blank" rel="noopener noreferrer">
                                                    <img alt="Go to Telepresenz Pricing!" src="Learn-More-bigger.png"  style={{width:'35%'}}/>
                                                </a>
                                            }
                                        </div> */}
                                    </div>                                                                
                                    { this.passwordExpiredModal()}
                                    { this.accountSuspendedModal()}
                                    {/*  this.loginFailedAttemptModal() */}
                                </Fragment>
                            }
                        </AccountConsumer>
                );
            } 
            else if (!this.props.isGuest) /**TP-4709*/ {
                return(
                    this.state.isLoading ? this.showLoader() :
                        <AccountConsumer>
                            {({language,setLanguage}) =>
                                <Fragment>
                                    {   process.env.REACT_APP_VERSION !== "" && process.env.REACT_APP_VERSION !== null && process.env.REACT_APP_VERSION !== undefined ?
                                            <span className="position-absolute ml-2 mt-2 version-badge">{process.env.REACT_APP_VERSION}</span>
                                            : 
                                            <span className="position-absolute ml-2 mt-2 version-badge">{version}</span>
                                    }
                                    <div className="">
                                        <Select
                                            value={this.selectedLanguage(language)}
                                            readonly="readonly"
                                            onChange={(selectedOption) => setLanguage(selectedOption.value)}
                                            options={this.languageOptions()}
                                            className={isSmallFormFactor ? "position-absolute signin-dropdown col-sm-4" : window.innerWidth > 1559 ? "position-absolute signin-dropdown col-sm-1" : window.innerWidth > 1450 && window.innerWidth < 1559 ? /*TP-1728*/"position-absolute signin-dropdown-lg col-sm-2" : "position-absolute signin-dropdown-lg col-md-2"}
                                            menuPlacement="bottom"
                                            isSearchable={this.state.isMobileDevice === true ? false : true} /**TP-2892 */
                                        />
                                    </div>
                                    {/**TP-1728 */}
                                    <div className='row text-center'>
                                        
                                    </div>
                                    <div className="center">
                                        <main className="black-80 relative">
                                            { isSmartVision === false ?
                                                <div className="bg-transparent center mw5">
                                                    <a className="link dim lh-title" href="https://www.telepresenz.com/" target="_blank" style={{outline:'none'}}>
                                                    <img alt="Log into telepresenz!" className={bgImgCls} src={window._env_.REACT_APP_ASSET_FOLDER+"/signin_page_logo.png"} />
                                                    </a>
                                                </div>
                                                :
                                                <div className="row bg-transparent no-gutters align-items-center">
                                                    <div className="col-6">
                                                        <a className="link dim lh-title">
                                                            <img alt="Log into telepresenz!" className="w-60 mw4 right logo-right" src={window._env_.REACT_APP_ASSET_FOLDER+"/signin_page_logo.png"} />
                                                        </a>
                                                    </div>
                                                    
                                                    <div className="col">
                                                        <span className="">
                                                            <span className="row no-gutters align-items-center link lh-title">
                                                                <img alt="Log into smartvision!" className="logo-left w-60 mw4  mt2" src="publogo_final.png" />
                                                            </span>
                                                            <span className="row no-gutters align-items-center" style={{color: '#ffffff', fontWeight: "bold"}}>
                                                                <div className="text-left">SMARTvision</div>
                                                            </span>  
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            <div className="signin-wrapper" style={{marginTop: '2%'}}>
                                                <div id="sign_up" className="ba b--transparent ph0 mh0">
                                                {/* <legend className="f3 fw6 ph0 mh0 tc center" style={{color: '#fff'}}>Sign in</legend> */}                                                
                                                </div>
                                                    <SigninForm isSmallFormFactor={isSmallFormFactor} signInEmail={this.state.signInEmail} 
                                                        signInPassword={this.state.signInPassword} noInternet={this.props.noInternet} login_attempts={login_attempts}
                                                        onEmailChange={this.onEmailChange} enterPressedFromName={this.enterPressedFromName}
                                                        onPasswordChange={this.onPasswordChange} enterPressedFromPassword={this.enterPressedFromPassword}
                                                        onSubmitSignIn={this.onSubmitSignIn} validator={this.validator} onSubmitGetOtp={this.onSubmitGetOtp}
                                                        handleChange={this.handleChange} otp={this.state.otp} mobile={this.state.mobile} otpSend={this.state.otpSend} 
                                                        hideMobileForm= {this.hideMobileForm} showHideRegistrationForm= {this.showHideRegistrationForm}
                                                        isMobileRegistered = {this.state.isMobileRegistered} registerMobile = {this.registerMobile} isSmartVision = {isSmartVision}
                                                        onClickForgotPassword = {this.onClickForgotPassword} displayMobileForm = {this.state.displayMobileForm} getOTPType = {this.state.getOTPType} mobileLogin = {this.mobileLogin}
                                                        setOTP = {this.setOTP} smsCount = {this.state.smsCount} canDoOTPLogin = {this.state.canDoOTPLogin} canRequestOtp = {this.state.canRequestOtp} canUserRequestOtp = {this.canUserRequestOtp}
                                                    />
                                                <div id="ShowError" className="text-center ">
                                                    <Trans id={this.state.errorMsg} values={this.state.values ? this.state.values: ''}></Trans> <br/>
                                                    <Trans id={this.state.errorMsg2 !== '' ? this.state.errorMsg2 : '' } values={this.state.values ? this.state.values: ''}></Trans>
                                                </div>
                                                <div id="spinner_div" className="text-center"></div>
                                                <div className="row no-gutters" style={window.innerWidth > 1559 ? {marginTop: '2%'} : {marginTop: '2%'}}>
                                                    <div className="col-12 center-text">{/* TP-5751 */}
                                                        {this.props.noInternet ?
                                                            <a href="javascript:void(0)" className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-link-font-md": ""}  style={{color: '#ffffff'}}><Trans id="signin.privacyPolicy" /></a>
                                                            :
                                                            <a href={window._env_.REACT_APP_ASSET_FOLDER+"/privacy_policy.pdf"} className={isSmallFormFactor === false && window.innerWidth > 1559 ? "signin-link-font-md": ""} target="_blank" rel="noopener noreferrer" style={{color: '#ffffff'}}>
                                                                <Trans id="signin.privacyPolicy" />
                                                            </a>
                                                        }
                                                    </div>
                                                </div>
                                                {/* { this.state.status === 409 && <div className={'text-center forget-password-link ' + (this.state.showLoggedInFlagSpinner ? '' : 'animate bounce')}>
                                                    { this.state.showLoggedInFlagSpinner && <i className="fas fa-spinner fa-spin white mr-1"></i> }
                                                    <a href="javascript:void(0);" onClick={(e) => this.onLogmeIn(e)} className="link dim lh-title"><Trans>Log me in now.</Trans></a>
                                                </div> } */}
                                                {/**TP-2320*/}
                                            </div>
                                        </main>
                                    </div>
                                    {/*
                                        <div className={window.innerWidth > 1559 ? "row no-gutters align-items-center row-min-height-lg" : "row no-gutters align-items-center row-min-height-md"} style={window.innerWidth > 1559 ? {marginTop: '0'} : {marginTop: '-2.5%'}}>
                                            {/* MB2-577 */}
                                            {/* isSmartVision === false ?
                                                <div className="col-3 text-center">
                                                    {this.props.noInternet ?
                                                        <Button className="logo-button" style={{ borderRadius: '40px' }}>
                                                            <img alt="Go to Telepresenz!" src="Telepresenz_Logo_Purple.png" style={{width:'130px'}}/>
                                                        </Button>
                                                        :
                                                        <a href="https://www.telepresenz.com/" target="_blank" rel="noopener noreferrer">
                                                            <Button className="logo-button" style={{ borderRadius: '40px' }}>
                                                                <img alt="Go to Telepresenz!" src="Telepresenz_Logo_Purple.png" style={{width:'130px'}}/>
                                                            </Button>
                                                        </a>
                                                    }
                                                </div>
                                                :
                                                ''
                                                */}
                                            {/* MB2-577 */}                                        
                                            {/* MB2-577 */}
                                            {/* <div className="col-3 dim text-center">
                                                {this.props.noInternet? 
                                                    <img alt="Go to Telepresenz Pricing!" src="Learn-More-bigger.png"  style={{width:'35%'}}/>
                                                    :
                                                    <a href="https://www.telepresenz.com/pricing" target="_blank" rel="noopener noreferrer">
                                                        <img alt="Go to Telepresenz Pricing!" src="Learn-More-bigger.png"  style={{width:'35%'}}/>
                                                    </a>
                                                }
                                            </div>}
                                        </div>  
                                    */}
                                    { this.passwordExpiredModal()}
                                    { this.accountSuspendedModal()}
                                    {this.passwordExpiredModalForMobile()}{/*TP-5751*/}
                                    {/*  this.loginFailedAttemptModal() */}
                                </Fragment>
                            }
                        </AccountConsumer>
                );
            } else {
                //TP-4709 -- Show only the Loader while Guest user joins the session
                return (
                    this.state.isLoading ? this.showLoader() : ''
                )
            }
            
        } catch (error) {
            /* this.diagonastics({
                action: `render Signin page returned error`,
                sequence: 1,
                next_step: '',
                data: `${error}`,
                error: 'none'
            }) */
        }
	}
}

export default Signin;