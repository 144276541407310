import React from 'react';
import './Groups.scss';
import SimpleReactValidator from 'simple-react-validator';
import CheckBox from '../CheckBox/CheckBox';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import _ from 'lodash';
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import Button from '../Button/Button';
import { printConsole, encryptData, checkDummyEmail, formatMobile } from '../helpers';

let sendSocketFlag = false;

class AddUser extends React.Component {
	constructor(props){
		super(props);
		
		this.state = {
			first_name: '',
			last_name: '',
			email: '',
			phonenumber: '',
			isexpert: false,
			geolocation: 'Bangalore East',
			bSubmitted: false,
			bResponded: false,
			errStrReturned: '',
            retCode: 400,
            purchases: [],
            assignedTo: '',
			purchase_id: '',
			isEmailAlreadyRegistered: false,
			serial_no: '',
			prevIsExpert: false,
			showExpertWarning: false,
			isLoading: false,
			isSerialNumberPresent: false,
			bLoggedOut: false,
			isMobileAlreadyRegistered: false
		}

		// initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({locale: props.language,
            element: message => <div className={ message.includes('purchase') ? 'groups-form-validation-msg' : 'add-users-validation-msg' }>{message.replace(/field|The/g, '').capitalize()}</div>,
            validators: {
                emailValidate: {  // name the rule
                    message: 'Please enter a valid email address',
                    rule: (val, params, validator) => {
                        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return validator.helpers.testRegex(
                            String(val).toLowerCase(),
                            regex
                        );
                    },
                    required: true  // optional
                },
                phone: {
                    message: 'Enter a valid phone number',
                    rule: (val,params,validator) => {
                        // let telRegex= /^(?=.*[0-9])[- +#()0-9a-zA-Z*]+$/;
                        let telRegex= /^[0-9\)\(+-\s]+$/g;//T32-472
                        return (validator.helpers.testRegex(val,telRegex));
                    }
                },
				serialNoCheck: {
					message: 'Please enter a serial number',
                    rule: (val, params, validator) => {
                        return (!val && !this.state.email) ? false : true; 
                    }
				},
				validatePurchase: {
					message: 'Please select a purchase',
					rule: (val, params, validator) => {
						const disable = (this.props.user && this.props.user.isadmin) && !this.state.isexpert;
						if (!disable) {
							return false;
						}
						if (val) {
							return false;
						}
						return true;
                    }
				},
                min_length: {  // name the rule
                    rule: (val, params, validator) => {
                        return val.trim().length === 10; 
                    },
                },
            }
		});
    }
    
    componentDidMount = () => {
        if (this.props.isEdit) {
            this.setState(prevState => ({
                ...prevState,
				...this.props.user,
				prevIsExpert: this.props.user.isexpert,
                assignedTo: this.props.purchases.find(e => e.value === this.props.user.purchase_id),
				isSerialNumberPresent: (this.props.user.serial_no !== null && this.props.user.serial_no !== '')//TP-309
			}))
        }
        this.delayedCallback = _.debounce(this.checkEmailExists, 500);
		this.delayedMobileCallback = _.debounce(this.checkMobileExists, 500);//TP-6154
	}

	onChangeForm = (name, field) => event => {
		event.persist();
		let {phonenumber, email} = this.state;
		const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		if (name === 'email') {
			this.delayedCallback(value,event.target.name)
		}
		//TP-6154
		if (name === "phonenumber") {
            phonenumber = formatMobile(value);//TP-6151
            if(value){
                this.delayedMobileCallback(phonenumber,field);//TP-6082
            }
			const {user: {verified}} = this.props
			if(checkDummyEmail({verified, email})){
				sendSocketFlag = true; //TP-6228
			}
        }
		if (name === 'isexpert') {
			sendSocketFlag = true; //TP-1988
		}

		const stateChanges = {
            [name]: value, phonenumber
		};
		this.setState(stateChanges);
	}

	onClickClose = (e) => {
        e.preventDefault();
		this.props.onClose();
    }
    
    onUpdateUser = (e) => {
        e.preventDefault();
		const {isMobileAlreadyRegistered} = this.state
		if (!this.validator.allValid() || isMobileAlreadyRegistered) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
        this.setState({ isLoading: true, showExpertWarning: false });
        const userDetails = {
            id: this.state.id,
            first_name: this.state.first_name,
            last_name: this.state.last_name, 
            email: this.state.email.toLowerCase(),
            phonenumber: this.state.phonenumber,
            isexpert: this.state.isexpert,
            inviteeid: this.state.inviteeid,
			purchase_id: this.state.purchase_id,
			serial_no: this.state.serial_no
        };
		// this.setState({errStrReturned: i18nMark('Processing user changes'), showExpertWarning: false});
		this.props.authService.fetch('updateUser', {
			method: 'put',
			body: JSON.stringify(userDetails)
		})
		.then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
                return response.json();
			} else {
                throw response;
			}
		})
		.then(data => {
            if(data !== null && data !== undefined){                
                if (this.state.isadmin && !this.state.isexpert) {
					userDetails.purchase_id = null;
					userDetails.serial_no = null;
                }
                this.props.addedUser(userDetails);
				const {user: { phonenumber, verified }} = this.props
				//console.log(userDetails);
				// TP-1988 -- Sending the log-me-out signal to the user whose 
				// isexpert flag was changed by Admin user
				printConsole(`send the log-me-out socket signal to this user ${sendSocketFlag}`);
				//TP-6233
				if (((userDetails.email !== this.props.adminemailid) || userDetails.phonenumber !== phonenumber) && sendSocketFlag === true) {
					//TP-6265 when admin is changing his own phone number
					if(userDetails.email === this.props.adminemailid){
						sendSocketFlag = false;					
						printConsole("logging out the admin's screen");
						this.onSubmitSignout();
					}else{
						const json = {
							userIsDeleted: false,
							email: userDetails.email,
							loginTimeStamp: new Date()
						}
						printConsole(json);
						this.props.authService.socket.emit('log-me-out', json);
						sendSocketFlag = false;
						this.setState({
							bSubmitted: false,
							isLoading: false,
							bResponded: true,
							errStrReturned: i18nMark('User details successfully updated!'),
							serial_no: (this.state.isadmin && !this.state.isexpert) ? null : this.state.serial_no
						});
					}
				} else if (sendSocketFlag === true) {
					// For Admin user changing his 
					// own isexpert flag just logout the Admin user
					sendSocketFlag = false;					
					printConsole("logging out the admin's screen");
					this.onSubmitSignout();
					//window.location.reload();
					/* sessionStorage.removeItem('id_token');
                	sessionStorage.removeItem('pageName');//TP-1579
                	window.location = '/'; */
				} else {
					//TP-6233
					const str = (userDetails.phonenumber !== phonenumber && ( verified === "both" || verified === "email" )) ? i18nMark('user details updated') : i18nMark('User details successfully updated!');
					this.setState({
						bSubmitted: false,
						isLoading: false,
						bResponded: true,
						errStrReturned: str,
						serial_no: (this.state.isadmin && !this.state.isexpert) ? null : this.state.serial_no
					});
				}
				this.props.authService.socket.emit('user-details-updated', {userId: this.state.id,	updateTimeStamp: new Date()});//TP-6431
			}
		})
		.catch((error) => {
			if (!error) {
                return;
			}
			if (error.status === 405) {
				this.setState({
					bSubmitted: false, bResponded: false, showExpertWarning: true, isLoading: false
                });
                return;
			}
			// output error in login
			if(error.status === 409){
				this.setState({errStrReturned: i18nMark('There is already a user registered with these details. Please resubmit with a different name.'), isLoading: false, bResponded: true });
			}
			else if(error.status === 404){
				this.setState({errStrReturned: i18nMark('Failed to update the user details.'), isLoading: false, bResponded: true});
			}
			else{
				this.setState({errStrReturned: i18nMark('Failed to update user details. Please review the entries and resubmit again.'), isLoading: false, bResponded: true});
			}
		})
	}

	// TP-1982 -- to handle the Admin converting to expert role and then starting a session
	onSubmitSignout = () => {
		//console.log('signout called');
		const myLocation = "Bangalore North++East"; //getGeoLocation();
		const encryptedEmail = encryptData(this.props.adminemailid);//FQ3-417
		this.props.authService.fetch('signout', { //TP-2028
		  method: 'post',
		  /* TP-2028 headers: {'Content-Type': 'application/json'}, */
		  body: JSON.stringify({
			email: encryptedEmail,
			isencrypted: true,
			id: parseInt(this.props.adminuserid, 10),
			groupid: parseInt(this.props.groupId, 10),
			geoLocation: myLocation
		  })
		})
		.then(response => response.json())
		.then(data => {
			// adding this on success UQ3-208. Instead of the call adding it on success
			this.props.authService.logout();
			this.setState({
				bSubmitted: false,
				isLoading: false,
				bLoggedOut: true,
				errStrReturned: i18nMark('Admin User Role changed successfully!'),
				serial_no: (this.state.isadmin && !this.state.isexpert) ? null : this.state.serial_no
			});
			this.logoutWaitTimer = setTimeout(() => {
				this.props.onRouteChange('signout');			
				clearTimeout(this.logoutWaitTimer);
			}, 3000);
		})
		.catch(err => console.log(err.message))
	}
	
	checkEmailExists = (email,emailField) => {
		if (!this.validator.fieldValid(emailField) || this.props.isEdit)  {
			this.setState({ isEmailAlreadyRegistered: false });
			return
		};
        fetch(window._env_.REACT_APP_API_URL+'/checkEmailExists/' + email, {
			method: 'GET',
			headers: {'Content-Type': 'application/json'}
		})
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
			this.setState({
				isEmailAlreadyRegistered: isExists
			});
		})
		.catch((error) => {
			console.log(error);
		})
    }

	//TP-6154
    checkMobileExists = (mobile,mobileField) => {
		if (!this.validator.fieldValid(mobileField)){
			this.validator.showMessages();
			this.setState({ isMobileAlreadyRegistered: false });
			return
		};
        fetch(window._env_.REACT_APP_API_URL+'/account_user/canRequestOTP/', {
			method: 'POST',
            body: JSON.stringify({
                mobile, type: "verify_mobile"
            }),
			headers: {'Content-Type': 'application/json'}
		})
		.then(response => {
			if(response.status === 200) 
				return response.json();
			else
				throw new Error(response.statusText);
		})
		.then(isExists => {
            this.setState({
				isMobileAlreadyRegistered: isExists && (this.props.user.phonenumber !== mobile),
			});
		})
		.catch((error) => {
			printConsole(`checkMobileExists error ${error}`);
		})
    }

	onAddUser = (e) => {
		e.preventDefault();
		if (!this.validator.allValid() || this.state.isEmailAlreadyRegistered) {
			this.validator.showMessages();
			// rerender to show messages for the first time
			this.forceUpdate();
			return;
		}
		this.setState({ isLoading: true, errStrReturned: i18nMark('Processing user details') });
		this.props.authService.fetch('addnewuser', {
			method: 'post',
			body: JSON.stringify({
				firstname: this.state.first_name,
				lastname: this.state.last_name, 
				email: this.state.email.toLowerCase(),
				phonenumber: this.state.phonenumber,
				subscriptionid: this.props.accountid,
				geolocation: this.state.geolocation,
				isexpert: this.state.isexpert,
				invitedbyfirstname: this.props.adminfirstname,
                adminemailid: this.props.adminemailid,
				purchase_id: this.state.purchase_id,
				serial_no: this.state.serial_no
			})
		})
		.then(response => {
			this.setState({retCode: response.status});
			if(response.status === 200){
				return response.json();
			} else {
				throw new Error(response.body);
			}
		})
		.then(data => {
			if(data !== null && data !== undefined){
				const updatedPurchases = this.props.purchases.map(p => {
					if (p.id === this.state.purchase_id) {
						return {
							...p,
                            available_credits: p.available_credits - 1
                        }
                    }
                    return p;
                });  
				
                this.setState({
					bSubmitted: false,
					bResponded: true,
					isLoading: false,
					errStrReturned: i18nMark('New user added successfully!')
                });
                this.props.addedUser({
					...data,
					purchase_id: this.state.purchase_id
				});
                this.props.onUpdatePurchases(updatedPurchases);
			}
		})
		.catch((error) => {
			this.setState({retCode: error.status, isLoading: false, bResponded: true});
			// output error in login
			if(this.state.retCode === 409)
			{
				this.setState({errStrReturned: i18nMark('There is already a user registered with these details. Please resubmit with a different name.')});
			}
			else if(this.state.retCode === 404)
			{
				this.setState({errStrReturned: i18nMark('New user registration failed since this feature is not supported.')});
			}
			else
			{
				this.setState({errStrReturned: i18nMark('Failed to add a new user. Please review the entries and resubmit again.')});
			}
		})
    }
    
    onChangeIsExpert = () => {
        this.setState(prevState => ({
            isexpert: !prevState.isexpert
        }))
    }

    handleSelectPurchase = (newValue) => {
        this.setState({ assignedTo: newValue, purchase_id: newValue.value });
    }

	render() {
		//TP-2980
        const { isEdit, user, purchases, numberOfExperts, isadmin, adminemailid, sms_support} = this.props;
		const showSelect = true;
		//const showSelect = (isadmin === true) ? true : false;
		//const isUserMe = user.email === adminemailid ? true : false;
		const showSerialNoField = (!this.state.isexpert && !this.state.isadmin);
		const disableSelect = (user && user.isadmin) && !this.state.isexpert;
		this.validator.purgeFields();
		if(this.state.bSubmitted || this.state.bResponded){
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
				<div className='modale opened'>
					<div className='__modal-dialog'>
						<form>
							<div className="__modal-header">
								<h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
							</div>
							<div className="__modal-footer flex-center">
								<button className="btn-green" onClick={(e) =>this.onClickClose(e)}><Trans id='OK'/></button>
							</div>
						</form>
					</div>
				</div>
			);
		} else if(this.state.bLoggedOut){
			let colorText = '#485890';
			if(this.state.retCode !== 200) colorText = 'red';
			return(
				<div className='modale opened'>
					<div className='__modal-dialog'>
						<form>
							<div className="__modal-header">
								<h4 style={{color: colorText}}><Trans id={this.state.errStrReturned}/></h4>
							</div>	
							<div className="__modal-footer flex-center">
								{/* <button className="btn-green" onClick={(e) =>this.onClickClose(e)}><Trans id='OK'/></button> */}
							</div>						
						</form>
					</div>
				</div>
			);
		} else {
			return(
				<div className='formmodule center'>
					<div className='dialog form-color width-min'>
	            		<form>
							<div className="formheader tabsColor flex-center row">
								<h2><Trans id={ isEdit ? 'Edit User' : 'Add User' }/></h2>								
							</div>
							<div className="formbody form-text-color">
                                {showSelect ? <label><Trans>Assign to</Trans>:
                                        <div style={{marginTop: 5, marginBottom: 20, color: "black"}} className="relative ml-3">
                                            <I18n>
                                                {({ i18n }) => 
                                                <>
                                                    <Select
                                                        value={this.state.assignedTo}
                                                        components={makeAnimated()}
                                                        isDisabled={disableSelect}
                                                        onChange={this.handleSelectPurchase}
                                                        placeholder={i18n._(t`Select a purchase`)}
                                                        options={purchases}
                                                        noOptionsMessage={() => {i18n._(t`No Purchases available`)} }
                                                        isOptionDisabled={(option) => option.available_credits === 0}
                                                    />
                                                    <input type="text" readOnly value={this.state.purchase_id} name="purchase" className="hidden"/>
                                                    { !disableSelect && this.validator.message(i18n._(t`purchase`), this.state.purchase_id, 'required') }
                                                </>
                                                }
                                            </I18n>
                                        </div> 
                                    </label>: ''
                                }
								<div className="input-wrapper margin-bottom-15">
                                    <I18n>
                                        {({ i18n }) => 
                                            <>
                                                <input className='signup-input-field' name={i18n._(t`first_name`)} type="text" placeholder={i18n._(t`First Name`) + '*'} value={this.state.first_name} onChange={this.onChangeForm('first_name')} />
                                                {this.validator.message(i18n._(t`first_name`), this.state.first_name, 'required|alpha_num_space')}
                                            </>
                                        }
                                    </I18n>
								</div>
								<div className="input-wrapper margin-bottom-15">
                                    <I18n>
                                        {({ i18n }) => 
                                        <>
									        <input className='signup-input-field' name={i18n._(t`last_name`)} type="text" placeholder={i18n._(t`Last Name`)+ '*'} value={this.state.last_name} onChange={this.onChangeForm('last_name')} />
                                            {this.validator.message(i18n._(t`last_name`), this.state.last_name, 'required|alpha_num_space')}
                                        </>
                                        }
                                    </I18n>
								</div>
								<div className="input-wrapper margin-bottom-15">
									{	!checkDummyEmail({verified: this.props.user.verified, email: this.state.email}) ?

											<I18n>
												{({ i18n }) => 
												<>
													<input disabled={isEdit}
													className='signup-input-field' name={i18n._(t`user_email`)} type="text" placeholder={i18n._(t`Email`)+ '*'} value={this.state.email} onChange={this.onChangeForm('email')} />
													{this.validator.message(i18n._(t`user_email`), this.state.email, 'required|emailValidate')}
													{ this.state.isEmailAlreadyRegistered ? <div className="add-users-validation-msg"><Trans>This email is already registered.</Trans></div> : '' }	
												</>											
												}
											</I18n>

										: 	null                                     
									}
								</div>
								{/**TP-2980 */ showSelect ?
									<div className="input-wrapper margin-bottom-15">
										<I18n>
											{({ i18n }) => 
											<>
												<input disabled={!sms_support} className='signup-input-field' name={i18n._(t`mobile`)} type="text" placeholder={i18n._(t`Mobile`) + (sms_support ? '*' : '')} value={this.state.phonenumber} onChange={this.onChangeForm('phonenumber',i18n._(t`mobile`))} />
												{
													sms_support?
														this.validator.message(i18n._(t`mobile`), this.state.phonenumber, 'required|phone|min_length' /*TP-6154*/)
													:
														null
												}
												{ this.state.isMobileAlreadyRegistered ? <div className="add-users-validation-msg"><Trans>This mobile is already registered</Trans></div> : '' }	
											</>
											}
										</I18n>
									</div>
									:
									''
								}
								{
									showSerialNoField ? <div className="input-wrapper margin-bottom-15">
                                        <I18n>
                                            {({ i18n }) => 
                                            <>
                                                <input className='signup-input-field' name={i18n._(t`serial_no`)} type="text" placeholder={i18n._(t`Serial Number (optional)`)} value={this.state.serial_no} onChange={this.onChangeForm('serial_no')} />
										        {this.validator.message(i18n._(t`serial_no`), this.state.serial_no, 'serialNoCheck|alpha_num_dash')}
                                            </>											
                                            }
                                        </I18n>
									</div> : ''
								}
								{/**TP-2980 */ showSelect ? 
									<label htmlFor="isexpert">
										<input className="checkbox" type="checkbox" name="isexpert" checked={this.state.isexpert} onChange={this.onChangeForm('isexpert')}></input>
										<Trans values={{role: this.props.customerRoles.expert}}>User is an expert</Trans>
									</label>  
									:                              
									''
								}
								{/**TP-2980  isUserMe && !(isadmin && !this.props.isexpert) ?
									<label htmlFor="isautoconnect" className="input-wrapper margin-bottom-15">
										<input className="checkbox" type="checkbox" name="isautoconnect" checked={this.state.isexpert} onChange={this.onChangeForm('isexpert')}></input>
										<Trans>User is allowed to auto-connect to a call</Trans>
									</label>  
									:                              
									''
								}
								{ isUserMe && !(isadmin && !this.props.isexpert) ?
									<label htmlFor="enablenotifywhileasleep" className="input-wrapper margin-bottom-15">
										<input className="checkbox" type="checkbox" name="enablenotifywhileasleep" checked={this.state.isexpert} onChange={this.onChangeForm('isexpert')}></input>
										<Trans>Enable notifications while device is sleeping</Trans>
									</label>  
									:                              
									''
								*/}
								{
									this.state.showExpertWarning && <div style={{ display: 'block' }} className="invalid-feedback">
										<Trans values={{role: this.props.customerRoles.expert}} id='You cannot add more than {numberOfExperts} expert(s) to the group.' values={{numberOfExperts:numberOfExperts}}/>
									</div> 
								}
								{/** TP-309 */}
								{
									!this.state.isexpert && this.state.id && this.state.isSerialNumberPresent && (this.state.serial_no === null || this.state.serial_no === '') && <div className="pl-4">
										<Trans>Note</Trans>: <Trans>This will generate a new password.</Trans>
									</div>

								}
							</div>
							<div className="flex-center" style={{marginTop: 80}}>
								<button className="btn-red"  onClick={(e) =>this.onClickClose(e)}><Trans>Close</Trans></button>
                                {
									isEdit ? <Button className="margin10 btn-green" showSpinner={this.state.isLoading} onClick={this.onUpdateUser}><Trans>Update</Trans></Button>:
									<Button className="margin10 btn-green" showSpinner={this.state.isLoading} onClick={this.onAddUser}><Trans>Add</Trans></Button>
								}
								{/* <button id='btnAddUser' className="btn-green" onClick={(e) =>this.onUpdateUser(e)}><Trans>Update</Trans></button> */}
								{/* <button id='btnAddUser' className="btn-green" onClick={(e) =>this.onAddUser(e)}><Trans>Add</Trans></button> */}
							</div>
						</form>
	            	</div>
	            </div>
	   		);
		}
	}
}

export default AddUser;