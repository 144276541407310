import React, { Fragment } from 'react';
import './AccountDetails.scss';
// import PricingPlan from '../PricingPlan/PricingPlan';
import Loader from 'react-loader-spinner';
import PurchaseUserLimit from '../PurchaseUserLimit/PurchaseUserLimit';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import {TableRow} from '../Landing/TableRow';
import AddButton from'../Landing/Add.png';
import Actions from '../Landing/Actions';
import ReactHintFactory from 'react-hint';
import CheckBox from '../CheckBox/CheckBox';
import { Collapse, Badge, Modal, ModalBody, ModalHeader, Table, UncontrolledTooltip, Form } from 'reactstrap';
import Button from '../Button/Button';
// import { StripeCardForm } from '../StripeCheckoutForm/StripeCheckoutForm'; 
import moment from 'moment';
import visa from 'payment-icons/min/flat/visa.svg';
import amex from 'payment-icons/min/flat/amex.svg';
import diners from 'payment-icons/min/flat/diners.svg';
import mastercard from 'payment-icons/min/flat/mastercard.svg';
import discover from 'payment-icons/min/flat/discover.svg';
import jcb from 'payment-icons/min/flat/jcb.svg';
import unionPay from 'payment-icons/min/flat/unionpay.svg';
import defaultCard from 'payment-icons/min/flat/default.svg'
import { Trans, t } from '@lingui/macro';
import { I18n,i18nMark } from "@lingui/react";
import {AccountConsumer} from"../../Components/AccountContext";
import SimpleReactValidator from 'simple-react-validator';

const ReactHint = ReactHintFactory(React);

const smButtonStyle = {
    padding: '0 10px',
    width: 'auto',
    height: '20px',
    minWidth: '70px',
    fontSize: '12px'
}

class AccountDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            currentPlan: {},
            purchases:[],
            customer: {},
            plans: [],
            bResponded: false,
            retCode: 400,
            errStrReturned: '',
            showUserLimitModal: false,
            mode: 'view',
            selectedPurchaseId: null,
            collapse: false,
            showEditCardModal: false,
            purchaseDetails: {
                name: '',
                licenses: 0
            },
            license_key: '',
            confirmation_id: '',
            pass_code: '',
            isLicenseValid: false,
            invalidCardMsg: '' //TP-685
        }
        // require(`moment/locale/${props.language.toLowerCase()}`);
        // moment.locale(props.language.toLowerCase());
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({
            locale: props.language,
			element: message => <div className="signin-validation-msg">{message.replace(/field/g, '').capitalize()}</div>,
            validators: {
                licenseExpiry: {  // name the rule
                    message: 'Expired license key entered',
                    rule: (val, params, validator) => {
                        try{
                            const today = new Date();
                            const install_by_date_string = val.slice(12, 18);
                            let day = install_by_date_string.slice(0, 2);
                            let month = install_by_date_string.slice(2, 4);
                            month = parseInt(month) - 1;
                            let year = install_by_date_string.slice(4);
                            year= parseInt("20"+ year);
                            let date= new Date(year, month, parseInt(day));
                            return(date.setHours(0,0,0,0) >= today.setHours(0,0,0,0));
                        }catch(error){
                            // console.log(error)
                        }
                    }
                },
                validateLicense: {
                    message: 'Enter the Telepresenz license provided',
                    rule: (val, params, validator)=>{
                        return(val !== null && val !== '')
                    },
                    required:true
                },
                validatePassCode: {
                    message: 'The passcode is needed to validate the license key',
                    rule: (val, params, validator)=>{
                        return(val !== null && val !== '')
                    },
                    required:true
                }
            }
        });
    }

    showModalLoader = (isLoading) => {
        if (!isLoading) return '';
        return (
            <div className="modal-loader">
                <Loader
                    type="TailSpin"
                    color="#23c97d"
                    height="30"	
                    width="30"
                />
            </div>
        )
    }

    cancelSubscription = (e) => {
        e.preventDefault();
        this.setState({ isLoading: true });
        this.props.authService.fetch(`cancelSubscriptionByPurchaseId`, {
            method: 'post',
            body: JSON.stringify({
                accountId: this.props.accountId,
                purchaseId: this.state.selectedPurchaseId,
                adminUserId: this.props.adminUserId
            })
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then((customer) => {
            if(customer !== null && customer !== undefined){
                const updatedPurchases = this.props.purchases.filter(p => p.id !== this.state.selectedPurchaseId);
                this.setState({
                    bResponded: true,
                    mode: 'view',
                    isLoading: false,
                    selectedPurchaseId: null,
                    errStrReturned: i18nMark('Cancelled the subscription successfully!')
                });
                this.props.onUpdatePurchases(updatedPurchases, customer);
            }
        })
        .catch((error) => {
            this.setState({
                bResponded: true,
                mode: 'view',
                selectedPurchaseId: null,
                retCode: error.status,
                isLoading: false,
                errStrReturned: i18nMark('Subscription cancellation failed!')
            });
        })
    }

    onClickClose = (e) => {
        e.preventDefault();
        this.setState({ bResponded: false, mode: 'view' });
    }

    increaseUserLimit = (e) => {
        e.preventDefault();
        this.setState({
            showUserLimitModal: true,
            mode: 'view'
        });
    }   

    onCloseUserLimitModal=(e)=> {
        e.preventDefault();
        this.setState({
            showUserLimitModal: false
        });
    }

    onPurchaseLiencences = ({plan, licenses}) => {
        this.setState({
            isLoading: true,
            purchaseDetails: {
                name: plan.name,
                licenses
            }
        })
        this.props.authService.fetch(`purchasePlan`, {
            method: 'post',
            body: JSON.stringify({
                accountId: this.props.accountId,
                planId: plan.value,
                totalLicences: licenses
            })
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(({ purchasedItem, customer }) => {
            this.setState({bResponded: true});
            if(purchasedItem !== null && purchasedItem !== undefined){
                const purchases = [...this.props.purchases, purchasedItem ];
                this.setState({
                    showUserLimitModal: false,
                    isLoading: false,
                    bResponded: true,
                    errStrReturned: i18nMark('You have successfully purchased {licenses} license(s) on a {name} plan.')
                })
                this.props.onUpdatePurchases(purchases, customer);
            }
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                bResponded: true,
                showUserLimitModal: false,
                errStrReturned: i18nMark('Something went wrong while purchasing new plan!')
            })
        })
    }

    getBillingAmount = (purchase, subscription_status) => {
        if(subscription_status === 'enterprise' && purchase.total_price && purchase.total_price !== 0){
            return purchase.total_price;//T32-433
        }else{
            return new Intl.NumberFormat('en-US', { 
                style: 'currency', 
                currency: 'USD' 
            }).format(purchase.total_licences * purchase.plan_price)
        }
    }

    getDateString = (_date) => {
        const date = _date ? new Date(_date) : new Date();
        return new Intl.DateTimeFormat('en-GB', { 
            year: 'numeric', 
            month: 'long', 
            day: '2-digit' 
        }).format(date);
    }

    closeNotificationModal = () => {
        this.setState({ bResponded: false, invalidCardMsg: '' });
    }

    onDeleteAccount = (e) => {
        e.preventDefault();
        if (this.props.purchases.length) {
            this.setState({
                mode: 'view',
                bResponded: true, 
                errStrReturned: i18nMark('Please cancel all your purchases before deleting your account!')
            });
            return;
        }
        this.setState({
            isLoading: true
        })
        const { accountId } = this.props;
        this.props.authService.fetch(`deleteAccount/${accountId}`, {
            method: 'delete'
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(response => {
            this.setState({bResponded: true});
            if(response !== null && response !== undefined){
                this.setState({
                    isLoading: false,
                    bResponded: true,
                    mode: 'view',
                    errStrReturned: i18nMark('Successfully Deleted your account! and will redirect to login page.')
                })
                this.props.authService.logout();
                setTimeout(() => {
                    window.location = '/';
                }, 1000);
            }
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                bResponded: true,
                mode: 'view',
                errStrReturned: i18nMark('Something went wrong while deleting your account!')
            })
        })
    }

    onChangeForm = name => event => {
        event.persist();
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
		this.setState({
            [name]: value
        });
    }
    
    onLoadCardToken = (tokenDetails) => {
        this.setState({
            isLoading: true
        })
        this.props.authService.fetch(`addPaymentSource`, {
            method: 'post',
            body: JSON.stringify({
                account_id: this.props.accountId,
                source_token: tokenDetails.token.id
            })
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw response;// TP-685
            }
        })
        .then(card => {
            this.setState({
                isLoading: false
            });
            this.props.onAddNewCard(card)
        })
        .catch((error) => {
            error.json().then(res => {
                this.setState({
                    isLoading: false,
                    bResponded: true,
                    mode: 'view',
                    errStrReturned: i18nMark(`invalid card`),
                    invalidCardMsg: res.message
                })
            })
        })
    }

    onMakeDefaultPaymentSource = sourceId => e => {
        e.preventDefault()
        this.setState({
            isLoading: true
        })
        const { accountId } = this.props;
        this.props.authService.fetch(`makeDefaultPaymentSource`, {
            method: 'post',
            body: JSON.stringify({
                account_id: this.props.accountId,
                source_id: sourceId
            })
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(({ purchasedItem, customer }) => {
            // if any pending invoice is paid then purchase list will be updated NS2-364
            if(purchasedItem !== null && purchasedItem !== undefined){
                const purchases = [...this.props.purchases, purchasedItem ];
                this.props.onUpdatePurchases(purchases, customer);
            }
            this.props.onMakeDefaultCard(sourceId);
            this.setState({
                isLoading: false,
                bResponded: true,
                mode: 'view',
                errStrReturned: i18nMark('Change Default Card')
            })
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                bResponded: true,
                mode: 'view',
                errStrReturned: i18nMark('Something went wrong while updating default payment source!')
            })
        })
    }

    onDeletePaymentSource = sourceId => e => {
        e.preventDefault()
        this.setState({
            isLoading: true
        })
        const { accountId } = this.props;
        this.props.authService.fetch(`deletePaymentSource`, {
            method: 'post',
            body: JSON.stringify({
                account_id: this.props.accountId,
                source_id: sourceId
            })
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(res => {
            this.setState({
                isLoading: false
            });
            this.props.onDeleteCard(sourceId);
        })
        .catch((error) => {
            this.setState({
                isLoading: false,
                bResponded: true,
                mode: 'view',
                errStrReturned: i18nMark('Something went wrong while deleting your card!')
            })
        })
    }

    randomNumber = (min, max) => {  
        return Math.floor(Math.random() * (max - min) + min); 
    }  
    
    getConfirmationCode = () => {
        let code = ''
        for(let i= 0; i< 3; i++){
            let number = this.randomNumber(1, 85).toString();//pass the range of the license key
            if(number.length < 2){
                number = '0' + number;
            }
            code += number;
        }
        return code;
    }

    onClickAddLicense = (e) => {
        e.preventDefault()
        if (!this.validator.allValid()) {
            this.forceUpdate();
            this.validator.showMessages();
            return;
        }
        const code = this.getConfirmationCode();
        this.setState({isLicenseValid: true, confirmation_id:code})
    }

    onClickSubmitLicense = (e) => {
        e.preventDefault()
        if (!this.validator.allValid()) {
            this.forceUpdate();
            this.validator.showMessages();
            return;
        }
        this.setState({isLoading: true})
        this.props.authService.fetch(`activateAccount`, {
            method: 'post',
            body: JSON.stringify({
                license_key: this.state.license_key, confirmation_id: this.state.confirmation_id,
                pass_code: this.state.pass_code, company_name: this.state.company_name
            })
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(({ purchasedItem, customer }) => {
            if(purchasedItem !== null && purchasedItem !== undefined){
                const purchases = [...this.props.purchases, purchasedItem ];
                this.props.onUpdatePurchases(purchases, customer);
            }
            this.setState({
                isLoading: false,
                bResponded: true,
                mode: 'view',
                license_key: '', confirmation_id: '',
                pass_code:'', isLicenseValid: false, company_name: '',
                errStrReturned: i18nMark('Account Activated Sucessfully')
            });
        })
        .catch((error) => {
            this.setState({
                license_key: '', confirmation_id: '',
                pass_code:'', isLicenseValid: false, company_name: '',
                isLoading: false,
                bResponded: true,
                mode: 'view',
                errStrReturned: i18nMark('Something went wrong while updating your license key')
            })
        })
    }

    renderConfirmation = (mode) => {
        switch (mode) {
            case 'cancelSubscription':
                return <I18n>
                {({ i18n }) =><ConfirmModal primaryButton={i18n._(t`Yes`)}
                        secondaryButton={i18n._(t`No`)}
                        headerText={i18n._(t`Cancel Subscription`)}
                        message=""
                        onClickSencondary={(e) => this.onClickClose(e)}
                        onClickPrimary={(e) => this.cancelSubscription(e)}
                        isLoading={this.state.isLoading}>   
                        {
                            this.props.customer.subscription_status ==='trialing' && this.props.purchases.length === 1 ?
                            <h4><span className="trial-period-end-text">
                                <Trans>Note: Trial period will end if you cancel this purchase.</Trans>
                            </span></h4> :
                            ''
                        }
                        <h4><Trans>Are you sure you want to cancel this purchase?</Trans></h4>
                    </ConfirmModal>
                }
                </I18n>
            case 'deleteAccount':
                return <I18n>
                {({ i18n }) => <ConfirmModal primaryButton={i18n._(t`Yes`)}
                        secondaryButton={i18n._(t`No`)}
                        headerText={i18n._(t`Confirm Delete Account`)}
                        onClickSencondary={(e) => this.onClickClose(e)}
                        onClickPrimary={(e) => this.onDeleteAccount(e)}
                        isLoading={this.state.isLoading}>
                            <h2><Trans>Are you sure?</Trans></h2>
                            <h4><Trans>Deleting this account will mean permanent removal of your account data. You will no longer have the ability to retrieve this data from the platform. Are you sure you want to proceed with the delete?</Trans></h4>
                    </ConfirmModal>
                }
                </I18n>
            case 'endTrialPeriod':
                return <I18n>
                        {({ i18n }) => <ConfirmModal primaryButton={i18n._(t`Proceed`)} 
                            secondaryButton={i18n._(t`Cancel`)}
                            headerText={i18n._(t`Note`)}
                            disablePrimaryButton={!this.state.agreeTerms}
                            onClickSencondary={(e) => this.onClickClose(e)}
                            onClickPrimary={(e) => this.increaseUserLimit(e)}
                            isLoading={this.state.isLoading}>
                                <h4><span className="trial-period-end-text"><Trans>Trial period will end if you add more users. For more details please refer to the</Trans></span>&nbsp;<a target="_blank" rel="noopener noreferrer" href={window._env_.REACT_APP_ASSET_FOLDER+"/terms&conditions.pdf"} className="term-service"><Trans>Terms and Conditions</Trans>.</a></h4>
                                <div style={{ marginTop: 10, marginBottom: 15 }}>
                                    <label htmlFor="agreeTerms">
                                <input className="checkbox" type="checkbox" name="agreeTerms" checked={this.state.agreeTerms} onChange={this.onChangeForm('agreeTerms')}></input>
                                        <Trans>I have read and agree to the Terms and Conditions.</Trans>
                                    </label>                                    
                                </div>
                        </ConfirmModal>
                    }</I18n>
            case 'editPaymentSource': 
                return <Modal size="md" isOpen={true} className="modal-edit-card">
                    <ModalHeader className="text-center" toggle={this.onClickPaymentModalClose}>
                        <Trans>Add / Remove Your Card(s)</Trans>
                    </ModalHeader>
                    <ModalBody>
                        <Fragment>
                            { this.showModalLoader(this.state.isLoading) }
                            <div className={this.state.isLoading ? 'opac-0-7' : 'opac-1'}>
							{/* TP-2422 */}
                            {/* <AccountConsumer>
                                {({language}) =>
                                    <StripeCardForm language={language} allowedNumOfCards={10} numOfCardsAvailable={this.props.paymentSources.length} onLoadToken={this.onLoadCardToken} isLoading={this.state.isLoading} />
                                }
                            </AccountConsumer> */}
                                <div className="mt-3">
                                    <label><Trans>Saved card(s)</Trans></label>
                                    <Table responsive className="tbl-card-list mt-1">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th><Trans>Brand</Trans></th>
                                                <th><Trans>Card No</Trans></th>
                                                <th><Trans>Actions</Trans></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.paymentSources.map((card, index) => {
                                                    return <tr className="text-white" key={index}>
                                                        <td>{index + 1}</td>
                                                        <td><CardBrand type={card.brand} /></td>
                                                        <td>XXXX-XXXX-XXXX-{card.last4}</td>
                                                        <td className="text-center">
                                                        { 
                                                            this.props.defaultSource === card.id ? 
                                                            <Badge className="tm-regular" color="info" pill style={{ fontSize: '12px' }}><Trans>Default</Trans></Badge> :
                                                            <span className="icon-wrapper pointer">
                                                                <I18n>
                                                                    {({ i18n }) =>
                                                                        <Fragment>
                                                                            <i onClick={this.onMakeDefaultPaymentSource(card.id)} className="fas fa-check-square mr-3" data-rh={i18n._(t`Make default source`)}></i>
                                                                            <i onClick={this.onDeletePaymentSource(card.id)} className="fas fa-times-circle" data-rh={i18n._(t`Remove this card`)}></i>
                                                                        </Fragment>
                                                                    }
                                                                </I18n>
                                                            </span>
                                                            // <Button showSpinner={this.state.isLoading} onClick={this.onMakeDefaultCard} style={smButtonStyle}>Make Default</Button>
                                                        }
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Fragment>
                    </ModalBody>
                </Modal>
            case 'addLicenseKey':
                return(
                    <Modal size="md" isOpen={true}>
                        <ModalHeader className="text-center" toggle={this.onClickLicenseModalClose}>
                            <Trans>Add</Trans> <Trans>License Key</Trans>
                        </ModalHeader>
                        <Form>
                            <div className="__modal-body pb-0">
                                <div className="input-wrapper margin-bottom-15">
                                    <I18n>
                                        {({ i18n }) => 
                                            <>
                                                <input className='signup-input-field' name={i18n._(t`license_key`)} type="text" placeholder={i18n._(t`Enter the Telepresenz license provided`) + '*'} value={this.state.license_key || ''} onChange={this.onChangeForm('license_key')} />
                                                {this.validator.message(i18n._(t`license_key`), this.state.license_key, 'numeric|min:91|max:91|licenseExpiry|validateLicense')}
                                            </>
                                        }
                                    </I18n>
                                </div>
                                { this.state.isLicenseValid ?
                                    <>
                                        <div className="input-wrapper margin-bottom-15">
                                            <p><Trans>Share this confirmation code with Telepresenz Team</Trans>: {this.state.confirmation_id}</p>
                                        </div>
                                        <div className="input-wrapper margin-bottom-15">
                                            <I18n>
                                                {({ i18n }) => 
                                                    <>
                                                        <input className='signup-input-field' name={i18n._(t`pass_code`)} type="text" placeholder={i18n._(t`Pass Code`)+ '*'} value={this.state.pass_code || ''} onChange={this.onChangeForm('pass_code')} />
                                                        {this.validator.message(i18n._(t`pass_code`), this.state.pass_code, 'validatePassCode|min:3|max:3')}
                                                    </>
                                                }
                                            </I18n>
                                        </div>
                                        {/* TZ-955 */}
                                        <div className="input-wrapper margin-bottom-15">
                                            <I18n>
                                                {({ i18n }) => 
                                                    <>
                                                        <input className='signup-input-field' name={i18n._(t`company_name`)} type="text" placeholder={i18n._(t`Company Name`)+ '*'} value={this.state.company_name || ''} onChange={this.onChangeForm('company_name')} />
                                                        {this.validator.message(i18n._(t`company_name`), this.state.company_name, 'required')}
                                                    </>
                                                }
                                            </I18n>
                                        </div>
                                    </>
                                    : null
                                }
                                <div className="__modal-footer flex-center">
                                    {this.state.isLicenseValid ?
                                        <Button onClick={this.onClickSubmitLicense}><Trans>Submit</Trans></Button>
                                        :
                                        <Button onClick={this.onClickAddLicense}><Trans>Add</Trans></Button>
                                    }
                                </div>
                            </div>			
                        </Form>
                    </Modal>
                );
            default: 
                return '';
        }
    }

    onMakeDefaultCard = (e) => {
        e.preventDefault();
    }

    onDeleteAccountConfirm = (e) => {
        e.preventDefault();
        this.setState({ mode: 'deleteAccount' });
    }

    onActionClick = ({action, row}) => {
        this.setState({ mode: action, selectedPurchaseId: row.id });
    }

    onAddNewPurchases = (e) => {
        if (this.props.customer.subscription_status ==='trialing') {
            this.setState({ mode:  'endTrialPeriod' });
        } else {
            this.checkPendingPurchase(e)
        }
    }

    onAddNewLicenseKey = (e) => {
        e.preventDefault();
        this.setState({ mode:  'addLicenseKey' });
    }

    getPaymentStatus = (status) => {
        return status || 'paid';
    }

    onEditPaymentSource = (e) => {
        e.preventDefault();
        this.setState({
            mode: 'editPaymentSource'
        })
    }

    onClickPaymentModalClose = (e) => {
        e.preventDefault();
        this.setState({
            mode: 'view'
        })
    }

    onClickLicenseModalClose = (e) => {
        e.preventDefault();
        this.setState({
            mode: 'view',
            license_key: '',
            confirmation_id: '',
            pass_code:'',
            isLicenseValid: false
        })
    }

    // check for pending invoice and then show purchase license pop up NS2-364 
    checkPendingPurchase = (e) => {
        e.preventDefault()
        this.setState({
            isLoading: true
        })
        const { accountId } = this.props;
        let fetchString = 'checkPendingPurchase?';
        fetchString += `accountId=${accountId}`;

        this.props.authService.fetch(fetchString, {
            method: 'get',
        })
        .then(response => {
            this.setState({retCode: response.status});
            if(response.status === 200){
                return response.json();
            } else {
                throw new Error(response.body);
            }
        })
        .then(data => {
            this.setState({
                isLoading: false
            },() => {
                if(!data || data.length === 0){
                    this.increaseUserLimit(e);
                }else{
                    this.setState({
                        bResponded: true,
                        mode: 'view',
                        errStrReturned: i18nMark('Pending Purchase')
                    })                    
                }
            });
        })
        .catch((error) => {
            console.log(error)
            this.setState({
                isLoading: false,
                bResponded: true,
                mode: 'view',
                errStrReturned: i18nMark('Something went wrong while purchasing new plan!')
            })
        })
    }

    render = () => {
        this.validator.purgeFields();
        const { bResponded, retCode, errStrReturned, showUserLimitModal, showEditCardModal, purchaseDetails } = this.state;
        const { subscription_status, subscription_end_date, subscription_start_date } = this.props.customer;
        const { isSmallFormFactor } = this.props;
        let colorText = '#485890';
        if(retCode !== 200) colorText = 'red';
        return(
            <Fragment>
                <ReactHint autoPosition events />
                <div style={{'overflowX': 'auto'}}>
                    <div className="flex-space-between">
                        <div className="d-flex align-items-center">
                            { subscription_status === 'enterprise' ? 
                                <>
                                    {window._env_.REACT_APP_APP_MODE === 'LAN' ? 
                                        <I18n>
                                            {({ i18n }) => 
                                                <input type="image" src={AddButton} alt="Add License" data-rh={i18n._(t`Click to add license key`)} onClick={(e) => this.onAddNewLicenseKey(e) } style={{position: 'relative', outline:'none', width:'50px', height:'50px'}}/>
                                            }
                                        </I18n>
                                        : <span></span>
                                    }
                                </>
                                :
                                <I18n>
                                    {({ i18n }) => 
                                        <input type="image" src={AddButton} alt="Add Credits" data-rh={i18n._(t`Click to add more user licenses`)} onClick={(e) => this.onAddNewPurchases(e) } style={{position: 'relative', outline:'none', width:'50px', height:'50px'}}/>
                                    }
                                </I18n>
                            }
                            {
                                (subscription_status==='trialing' &&  moment().diff(moment(subscription_end_date), 'days') <= 15) ?
                                <Badge className="tm-regular trial-badge" color="primary" pill><Trans values={{subscription_end_date: moment(subscription_end_date).from(moment()) }} id='Trial ends {subscription_end_date}' /></Badge> : ''
                            }
                        </div>
                        <div className="account-info">
                            <div className="d-flex justify-content-center align-items-center pointer summary-header" onClick={() => { this.setState(state => ({ collapse: !state.collapse })); }}>
                                <Trans>Account Summary</Trans>
                                {
                                    this.state.collapse ?
                                    <i className="fas fa-angle-up" aria-hidden="true"></i>:
                                    <i className="fas fa-angle-down" aria-hidden="true"></i> 
                                }
                            </div>
                            <Collapse isOpen={this.state.collapse}>
                                <div>
                                    <Trans>Account Id</Trans>:  {this.props.accountId} <br />
                                    <Trans>Start Date</Trans>: {this.getDateString(subscription_start_date)} <br />
                                    <Trans>End Date</Trans>: {this.getDateString(subscription_end_date)} <br />
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <div>
                    <table id="groupsTable">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th><Trans>Title</Trans></th>
                            <th><Trans>Purchase Date</Trans></th>
                            <th><Trans>User Licenses</Trans></th>
                            <th><Trans>Available Licenses</Trans></th>
                            <th><Trans>Billing Amount</Trans></th>
                            <th><Trans>Status</Trans></th>
                            { subscription_status !== 'enterprise' && <th><Trans>Actions</Trans></th> }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.purchases.map((row, index) => {
                                return <TableRow 
                                        key={index}
                                        row={row}
                                        index={index}
                                        hideActions={subscription_status === 'enterprise' ? true : false}
                                        isAdmin={true}
                                        isSmallFormFactor
                                        columns={['plan_name', this.getDateString(row.created_on), 'total_licences', 'available_credits', this.getBillingAmount(row, subscription_status), this.getPaymentStatus(row['payment_status'])]}>
                                            <Actions index={index} row={row}>
                                                <I18n>
                                                {({ i18n }) =>
                                                    <span data-rh={i18n._(t`Cancel this purchase`)} className="icon-wrapper pointer" onClick={() => this.onActionClick({action: 'cancelSubscription', row})}>
                                                        <i className="fas fa-times-circle"></i>
                                                    </span>
                                                }
                                                </I18n>
                                            </Actions>
                                    </TableRow>

                            })
                        }
                    </tbody>
                </table>
            </div>
            </div>
                {(bResponded) ?
                    <div className='modale opened'>
                        <div className='__modal-dialog'>
                            <form>
                                <div className="__modal-header">
                                    <h4 style={{color: colorText}}><Trans id={errStrReturned} values={purchaseDetails}></Trans></h4>
                                {this.state.invalidCardMsg !== '' ?
                                    <h4 style={{color: colorText}}><Trans>REASON</Trans>: {this.state.invalidCardMsg}</h4>
                                    : ''
                                }
                                </div>
                                <div className="__modal-footer flex-center">
                                    <button className="btn-green" onClick={(e) =>this.closeNotificationModal(e)}><Trans>OK</Trans></button>
                                </div>
                            </form>
                        </div>
                    </div> : ''
                }
                {
                    showUserLimitModal 
                    ? <I18n>
                    {({ i18n }) =><PurchaseUserLimit
                            plans={this.props.plans || []}
                            headerText={i18n._(t`Purchase licenses`)}
                            startDate={subscription_start_date}
                            endDate={subscription_end_date}
                            onClose={this.onCloseUserLimitModal}
                            onPurchase={this.onPurchaseLiencences}
                            isLoading={this.state.isLoading}
                    /> }</I18n>
                    : ''
                }
                { this.renderConfirmation(this.state.mode) }
                { 
                    subscription_status !== 'enterprise' &&
                    <div className="d-flex justify-content-start mt-3">
                        <button id="btn-edit-payment-source" className="ad-btn-green mr-3" onClick={this.onEditPaymentSource}><Trans>Add / Remove Card(s)</Trans></button>
                        <button id="btn-delete-account" className="ad-btn-green" onClick={this.onDeleteAccountConfirm}><Trans>Delete Account</Trans></button>
                        { isSmallFormFactor === false ?
                            <UncontrolledTooltip placement="top" target="btn-delete-account">
                                <Trans>Click to delete your account (All purchases need to be cancelled before account deletion)</Trans>
                            </UncontrolledTooltip>
                            :
                            ''
                        }
                        { isSmallFormFactor === false ? 
                            <UncontrolledTooltip placement="top" target="btn-edit-payment-source">
                                <Trans>Click to add or delete card(s)</Trans>
                            </UncontrolledTooltip>
                            :
                            ''
                        }
                    </div>
                }
            </Fragment>
        );
    }
}

const CardBrand = (props) => {
    let brand;
    switch (props.type) {
        case 'American Express':
            brand = <I18n>
                {({ i18n }) =>
                    <img src={amex} title={i18n._(t`American express`)} alt="logo" />
                }
                </I18n>
            break;
        case 'Visa':
            brand = 
            <I18n>
                {({ i18n }) =>
                    <img src={visa} title={i18n._(t`Visa`)} alt="logo" />
                }
                </I18n>
            break;
        case 'Diners Club':
            brand = <I18n>
            {({ i18n }) =>
                <img src={diners} title={i18n._(t`Diners`)} alt="logo" />
            }
            </I18n>
            break;
        case 'MasterCard':
            brand = <I18n>
            {({ i18n }) =>
                <img src={mastercard} title={i18n._(t`American express`)} alt="logo" />
            }
            </I18n>
            break;
        case 'UnionPay':
            brand = <I18n>
            {({ i18n }) =>
                <img src={unionPay} title={i18n._(t`Union Pay`)} alt="logo" />
            }
            </I18n>
            break;
        case 'Discover':
            brand = <I18n>
            {({ i18n }) =>
                <img src={discover} title={i18n._(t`Discover`)} alt="logo" />
            }
            </I18n>
            break;
        case 'JCB':
            brand = <I18n>
            {({ i18n }) =>
                <img src={jcb} title={i18n._(t`JCB`)} alt="logo" />
            }
            </I18n>
            break;
        default:
            brand = <I18n>
            {({ i18n }) =>
                <img src={defaultCard} title={i18n._(t`Unknown`)} alt="logo" />
            }
            </I18n>
            break;
    }
    return (<span className="brand-icon">{brand}</span>);
};

export default AccountDetails;

