import React from 'react';
import { fabric } from 'fabric';
import { base64StringToBlob } from 'blob-util';
import { deleteFile } from 'react-s3';
import { printConsole } from '../helpers';
import {diagnostics} from "../DiagonasticHelper";
import WebrtcErrorMessage from './WebrtcErrorMessage';
import SessionChat from './SessionChat';
import SessionSignal from './SessionSignal';
import './session.css';
import threeEntryPoint from "../Three/threeEntryPoint";
import * as THREE from 'three';
import FileViewer from 'react-file-viewer';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import map from 'lodash/map';
import SessionTimer from './SessionTimer';
//import diagonastics from "../DiagonasticHelper";
import { SketchPicker } from 'react-color';
import RecordRTC from 'recordrtc';
import AuthService from '../AuthService';
import html2canvas from 'html2canvas';

import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import OTSessionUser from './OTSessionUser';
import WebrtcSideBar from './WebrtcSideBar';
import FlashMessage from '../FlashMessage/FlashMessage';
import RangeSlider from '../RangeSlider/RangeSlider';
import SessionIcon from './SessionIcon.jsx';
import { indexOf } from 'lodash';

let AWS = require('aws-sdk');

const fileTransferTimeout = 1;
//const headerSize = 104;
//const footerSize = 0; // if needed we will factor the footer in
const borderYsize = 2; //23;
const clearAnnotateObj = "{\"version\":\"2.3.6\",\"objects\":[{\"originX\":\"left\",\"globalCompositeOperation\":\"source-over\",\"width\":0.01,\"type\":\"path\",\"strokeLineJoin\":\"round\",\"version\":\"2.3.6\",\"backgroundColor\":\"\",\"stroke\":\"grey\",\"transformMatrix\":null,\"scaleY\":1,\"height\":0.01,\"top\":471.49700000000001,\"originY\":\"top\",\"path\":[[\"M\",420.99700000000001,472.99700000000001],[\"L\",421.00299999999999,473.00299999999999]],\"left\":419.49700000000001,\"fillRule\":\"nonzero\",\"fill\":null,\"strokeWidth\":1,\"skewY\":0,\"strokeDashArray\":null,\"strokeLineCap\":\"round\",\"scaleX\":1,\"strokeMiterLimit\":10,\"angle\":0,\"visible\":true,\"flipX\":false,\"flipY\":false,\"opacity\":1,\"shadow\":null,\"clipTo\":null,\"paintFirst\":\"fill\",\"skewX\":0}]}";

let strCanvasJSON = null;
let dataList = [];
let g_bCleanSlate = false;
let g_subInfoArr = [];
let g_ss_subInfo = null;
let bCancelShare = false;
let base64image = "";
let frameNo = 0;
let clipNo = 0;
let audioNo = 0;
let ackClipNo = 0;
let ackFrameNo = 0;
let errAckFrameNo = 0;
let errAudioAckNo = 0;
let count = 0;
let isrecording_active = false; //TP-740
let isSilentRecording = false; //TP-740
let turn_off_frames_signal = false; //TP-2041
let framesPreviousState = 'ok'; //TP-2215
let audioPreviousState = 'ok'; //TP-2215
let pointerX = "-1"; //TP-2474
let pointerY = "-1"; //TP-2474


// Array which should hold the list of Expert connection data for Technician Users
let g_expertConnsArr = [];
// Variable that holds the total no of experts in a Session
//let g_camera_publisher = null;
let g_screen_publisher = null;
let g_subscriber = null;
let g_publisher = null;
let g_stream = null;
let g_maxDivId = null;

let propsSetJoineeCount = null;
let otExpObj = null;
let assetsArray = [];

const OT = {}; //TP-3988
const fileDialog = require('file-dialog');

const screenSettings = [
    { constraints: {width: 1280, height: 720, frameRate: 1}, fps: 1 , timer: 1000, waterMarkFont: "10px" }, //High
    { constraints: {width: 960, height: 540, frameRate: 6}, fps: 6, timer: 166, waterMarkFont: "10px" }, //Medium
    { constraints: {width: 640, height: 360, frameRate: 10}, fps: 10, timer: 100, waterMarkFont: "5px"}, //Low
]

// for NS2-148
const zoomLevelArray = [
    { id: '1', name: '1X' },
    { id: '2', name: '2X' },
    { id: '3', name: '3X' },
    { id: '4', name: '4X' },
    { id: '5', name: '5X' }
];

const zoomClassArray = [
    { id: 1, class: 'oneX' },
    { id: 2, class: 'twoX' },
    { id: 3, class: 'threeX' },
    { id: 4, class: 'fourX' },
    { id: 5, class: 'fiveX' },
]
let annotateShapesArray = [
    { class: "fas fa-signature", name: "session.freehand" },
    { class: "far fa-circle", name: "session.circle" },
    { class: "far fa-square", name: "session.square" },
    { class: "fas fa-mouse-pointer", name: "session.arrow" },
    { class: "fas fa-crosshairs", name: "session.pointer" }, /**TP-2491 */
    { class: "fas fa-eraser", name: "session.erasor" }, //TP-2474
    { class: "fas fa-signature", name: "session.freehand" }
]

const subscriberContainerDivId = 'flex-container';

//return a promise that resolves with a File instance
async function urltoFile(url, filename, mimeType){
    mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
}


// function to convert uploaded filebuffer to Base64 URL format
// Uint8array format
function arrayBufferToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

var worker;

function testWorker() {
    if (typeof(Worker) !== "undefined") {
        if (typeof(worker) === "undefined") {
            worker = new Worker("GridWorker.js");
        }
        worker.onmessage = function(event) {
            //console.log(event.data);
            if (event.data === "Grid cells Auto Detect Start"){                
                if (otExpObj.state.gridViewMode === true && otExpObj.state.showHideMaxDivControl) {
                    let userCount = 0;
                    g_subInfoArr.forEach( subInfo => {
                        if (subInfo.g_stream.hasVideo/*  && subInfo.disableVideo === false */) {
                            userCount ++;
                        }
                    })
                    //printConsole(g_subInfoArr.length - g_expertConnsArr.length);
                    //printConsole(userCount);
                    if ((g_subInfoArr.length - g_expertConnsArr.length) > userCount){
                        otExpObj.drawSubscriberUI(window.innerHeight, window.innerWidth, null, false);
                    }
                }
            }
        };
    } else {
        //console.log("Web Workers are not supported in your browser");
    }
}
function terminateWorker() { 
    worker.terminate();
    worker = undefined;
}

// NS2-502
function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
};

//NS2-482	
function sortConnArr(connArr) {
    let len = connArr.length;
    if (connArr.length > 1) {
        for (let i = 0; i < len; i++) {
            for (let j = 0; j < len; j++) {
                if (connArr[j + 1] !== undefined) {
                    if (connArr[j].data.split(':::')[6] > connArr[j + 1].data.split(':::')[6]) {
                        let tmp = connArr[j];
                        connArr[j] = connArr[j + 1];
                        connArr[j + 1] = tmp;
                    }
                }
            }
        }
    }
    return connArr;
}

function insertAt(array, index, str) {
    array.splice(index, 0, str);
    return array;
}

/* function lengthInUtf8Bytes(str) {
    // Matches only the 10.. bytes that are non-initial characters in a multi-byte sequence.
    var m = encodeURIComponent(str).match(/%[89ABab]/g);
    return str.length + (m ? m.length : 0);
} */

function chunkString(str, length) {
    return str.match(new RegExp('.{1,' + length + '}', 'g'));
}

function isDivExpert(divIsExpert) {
    let elemIsExpert = document.getElementById(divIsExpert);
    if (elemIsExpert !== null && elemIsExpert !== undefined) {
        let elemVdoPoster = elemIsExpert.getElementsByClassName('OT_video-poster');
        if (elemVdoPoster && elemVdoPoster.length > 0) {
            if (elemVdoPoster[0].style.display === 'block')
                return true;
        }
    }
    return false;
}

// To check if the div user is an expert or not
function checkExpertDiv(elemId) {
    const { g_subInfoArr } = otExpObj.state;
    g_subInfoArr.forEach(subInfo => {
        if (subInfo.g_divId === elemId) {
            const dataArr = subInfo.g_stream.connection.data.split(":::");
            if (dataArr[4] === "true") 
                return true;
        }
    })

    return false;
}

/* Annotation draw functions */

/* function redrawAnnotationCanvas(paramElem) {
    if (paramElem === null) {
        printConsole('invalid element passed to redrawAnnotationCanvas');
        //		alert('There is no window handle to draw annotations on. Please contact support.');
        return;
    }

    let drawElem = document.getElementById('canvas');
    //console.log('drawElem = ', drawElem);
    if( drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block'){
        let canvas = drawElem.fabric; 
        let dimX = canvas.width;
        let dimY = canvas.height;
        strCanvasJSON = canvas.toJSON();
        otExpObj.clearAnnotations();
        if (strCanvasJSON !== null)
            canvas.loadFromJSON(strCanvasJSON, function () {
                //fabric.log(o, object);
                let objList = canvas.getObjects();
                let index = 0;
                let multiX = canvas.getWidth() / dimX;
                let multiY = canvas.getHeight() / dimY;
                for (index = 0; index < objList.length; index++) {
                    objList[index].scaleX = objList[index].scaleX * multiX;
                    objList[index].scaleY = objList[index].scaleY * multiY;
                    objList[index].left = objList[index].left * multiX;
                    objList[index].top = objList[index].top * multiY;
                    objList[index].selectable = false;
                    objList[index].setCoords();
                }
            });
    }
} */

/* function onDrawCanvas(paramElem, bCapture, urlObj) {
    if (paramElem === null) {
        printConsole('invalid element passed to onDrawCanvas');
        //		alert('There is no window handle to draw annotations on. Please contact support.');
        return;
    }

    let nameDiv = document.getElementById(g_maxDivId+"_name");    

    // Make the Name Div background darker
    if (nameDiv !== null && nameDiv !== undefined){
        nameDiv.classList.add('annotate-class');
    }

    // create a canvas element
    let newCanvas = document.createElement('canvas');
    newCanvas.id = 'canvas';
    let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
    newCanvas.width = bCapture ? paramElem.offsetWidth : paramElemVdoChild[0].offsetWidth;
    newCanvas.height = bCapture ? paramElem.offsetHeight : paramElemVdoChild[0].offsetHeight;
    newCanvas.style.zIndex = '1';
    newCanvas.style.display = 'block';

    // insert canvas after video element
    if (paramElemVdoChild.length < 1)
        paramElem.appendChild(newCanvas);
    else
        paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);

    // add a fabric canvas element
    let canvas = new fabric.Canvas(newCanvas);
    canvas.selection = false;
    var rect, circle, line, triangle, origX, origY, activeObj;
    var isRectActive = otExpObj.state.isRectActive, isCircleActive = otExpObj.state.isCircleActive, isArrowActive = otExpObj.state.isArrowActive, isFreeDrawing = otExpObj.state.isFreeDrawing;
    let drawingObj = document.getElementById('canvas');
    //console.log(drawingObj);
    drawingObj.fabric = canvas;

    if (canvas === null) {
        printConsole('Fabric canvas could not be created!!!');
        return;
    }
    canvas.isDrawingMode = 1;
    if (isFreeDrawing) {
        canvas.freeDrawingBrush.color = otExpObj.state.pencolor; //"red" 
        canvas.freeDrawingBrush.width = 3;
    } else {
        canvas.freeDrawingBrush.color = "transparent";
        canvas.freeDrawingBrush.width = 1;
    }

    if (bCapture === true) {
        //console.log('loadImagebackground hit with: ', urlObj);
        fabric.Image.fromURL(urlObj, function (img) {
            // add background image
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
        });
    }
    else
        canvas.renderAll();

    canvas.on('mouse:down', function (option) {        
    });

    canvas.on('mouse:up', function () {
        canvas.off('mouse:move');
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
            canvas.enableRetinaScaling = true;
            
            if (g_maxDivId !== null) {
                let strDim = canvas.width+"px";
                strDim += canvas.height+"px";
                //console.log(strDim);
                //console.log(typeof(strDim));
                otExpObj.sendAnnotationToExpert(strDim);//NS2-345
                otExpObj.sendAnnotation(strDim, paramElem);
                //console.log(strDim);
            }
            otExpObj.sendAnnotationToExpert(canvas.toJSON()); //NS2-345
            otExpObj.sendAnnotation(canvas.toJSON(), paramElem);
            
        }
    });
} */

function onClickDraw(paramElem, bCapture, urlObj, flag = false) {
    if (paramElem === null) {
        printConsole('invalid element passed to onClickDraw');
        //		alert('There is no window handle to draw annotations on. Please contact support.');
        return;
    }

    let drawElem = document.getElementById('canvas');
    //console.log('drawElem = ', drawElem);
    let nameDiv = document.getElementById(g_maxDivId+"_name");
    //printConsole(otExpObj.state.pencolor);
    //printConsole(otExpObj.state.isFreeDrawing);
    //printConsole(flag);

    if (drawElem !== null && drawElem !== undefined && bCapture === false && otExpObj.state.pencolor === "red" && /**TP-2625 */ otExpObj.state.isFreeDrawing === false && flag === false) {
        if (drawElem.style.display === 'block') {
            printConsole("Clearing the Annotation canvas... for Primary expert");
            g_bCleanSlate = true;

            let canvas = drawElem.fabric;
            let canvasObjects = canvas.getObjects();
            let idx = 0;
            for (idx = 0; idx < canvasObjects.length; idx++)
                canvas.remove(canvasObjects[idx]);
            //canvasObjects = canvas.getObjects('path');
            otExpObj.sendAnnotation(clearAnnotateObj, null); //TP-2559
            otExpObj.sendAnnotationToExpert('');//TP-2833 & NS2-345
            drawElem.fabric.freeDrawingBrush.color = otExpObj.state.pencolor;
            drawElem.fabric.isDrawingMode = false;
            drawElem.fabric.backgroundImage = false;
            drawElem.style.display = 'none'; // hide the window
            //document.getElementById('canvas');
            let canvasContainers = document.getElementsByClassName('canvas-container');
            for (let i=canvasContainers.length-1; i>=0; i--) {
                canvasContainers[i].parentNode.removeChild(canvasContainers[i]);
                //canvasContainers[i].querySelectorAll('*').forEach(n => n.remove());                                
            }
            if (nameDiv !== null && nameDiv !== undefined){
                nameDiv.classList.remove('annotate-class');
            }
            strCanvasJSON = null;
            otExpObj.setState({
                expertDimX: 0,
                expertDimY: 0,
            });
        } else if (drawElem.style.display === '') {
            printConsole("Clearing the Annotation canvas... when Primary expert changes to Passive expert");
            g_bCleanSlate = true;

            //let canvas = drawElem.fabric;
            otExpObj.sendAnnotationToExpert('');//NS2-345
            otExpObj.sendAnnotation('', null);
            drawElem.style.display = 'none'; // hide the window
            //document.getElementById('canvas');
            let canvasContainers = document.getElementsByClassName('canvas-container');
            for (let i=canvasContainers.length-1; i>=0; i--) {
                canvasContainers[i].parentNode.removeChild(canvasContainers[i]);
                //canvasContainers[i].querySelectorAll('*').forEach(n => n.remove());                                
            }
            if (nameDiv !== null && nameDiv !== undefined){
                nameDiv.classList.remove('annotate-class');
            }
            strCanvasJSON = null;
            otExpObj.setState({
                expertDimX: 0,
                expertDimY: 0,
            });
        }
        else {
            if (bCapture === true) {
                fabric.Image.fromURL(urlObj, function (img) {
                    // add background image
                    canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                        scaleX: canvas.width / img.width,
                        scaleY: canvas.height / img.height
                    });
                });
            }
            // TP-301 -- To handle the crash issue happening when Screen share with Annotation is on
            // And transfer of controls happens
            if (drawElem.fabric !== undefined) {
                drawElem.fabric.freeDrawingBrush.color = otExpObj.state.pencolor;
                drawElem.fabric.isDrawingMode = true;
            }
            drawElem.style.display = 'block';
            
        }
        return;
    }

    // Make the Name Div background darker
    if (nameDiv !== null && nameDiv !== undefined){
        nameDiv.classList.add('annotate-class');
    }

    // create a canvas element
    let newCanvas = document.createElement('canvas');
    newCanvas.id = 'canvas';
    //console.log(paramElem.style.marginLeft);
    //console.log(paramElem.marginLeft);
    let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
    //console.log(paramElemVdoChild[0].style.marginLeft);
    //console.log(paramElemVdoChild[0].marginLeft);
    newCanvas.width = bCapture ? paramElem.offsetWidth : paramElemVdoChild[0].offsetWidth;
    newCanvas.height = bCapture ? paramElem.offsetHeight : paramElemVdoChild[0].offsetHeight;
    newCanvas.style.zIndex = '1';
    newCanvas.style.display = 'block';

    // insert canvas after video element
    if (paramElemVdoChild.length < 1)
        paramElem.appendChild(newCanvas);
    else
        paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);

    // add a fabric canvas element
    let canvas = new fabric.Canvas(newCanvas);
    canvas.selection = false;
    let isFreeDrawing = otExpObj.state.isFreeDrawing, isPointerActive = otExpObj.state.isPointerActive;
    let drawingObj = document.getElementById('canvas');
    //console.log(drawingObj);
    drawingObj.fabric = canvas;

    if (canvas === null) {
        printConsole('Fabric canvas could not be created!!!');
        return;
    }
    //TP-2586
    if (isFreeDrawing) {
        canvas.isDrawingMode = 1;
        canvas.freeDrawingBrush.color = otExpObj.state.pencolor; //"red" 
        canvas.freeDrawingBrush.width = 4; //TP-2720
    } else {
        canvas.isDrawingMode = 0;
        //canvas.freeDrawingBrush.color = "transparent";
        //canvas.freeDrawingBrush.width = 1;
    }

    let dimX = otExpObj.state.expertDimX;
    let dimY = otExpObj.state.expertDimY;
    printConsole(`dimX ${dimX}, dimY ${dimY}`);


    // If redrawing the New Primary expert's canvas who was previously a Passive expert
    if (strCanvasJSON !== null && dimX !== 0) {
        let dimX = otExpObj.state.expertDimX;
        let dimY = otExpObj.state.expertDimY;
        if (dimX === 0) {
            printConsole('dimensions of expert canvas did not come in');
            dimX = canvas.getWidth();
            dimY = canvas.getHeight();
            //return;
        }
        canvas.loadFromJSON(strCanvasJSON, function () {
            //fabric.log(o, object);
            let objList = canvas.getObjects();
            let index = 0;
            let multiX = canvas.getWidth() / dimX;
            let multiY = canvas.getHeight() / dimY;
            for (index = 0; index < objList.length; index++) {
                objList[index].scaleX = objList[index].scaleX * multiX;
                objList[index].scaleY = objList[index].scaleY * multiY;
                objList[index].left = objList[index].left * multiX;
                objList[index].top = objList[index].top * multiY;
                objList[index].selectable = false;
                objList[index].setCoords();
            }
        });
    }

    if (bCapture === true) {
        //console.log('loadImagebackground hit with: ', urlObj);
        fabric.Image.fromURL(urlObj, function (img) {
            // add background image
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
        });
    }
    else
        canvas.renderAll();

    //TP-2586
    if (isFreeDrawing) {
        canvas.on('mouse:down', function (option) {
            //console.log(option);
            // NS2-509
            /* if (option.target !== null) {
                return;
            } else {             
                        
                canvas.on('mouse:move', function (option) {
                                    
                });
    
            } */
        });
    
        canvas.on('mouse:up', function () {
            canvas.off('mouse:move');
            let drawElem = document.getElementById('canvas');
            if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
                canvas.enableRetinaScaling = true;
                let obj = canvas.getObjects();
                //TP-2600 -- Use case 2
                for(let index = 0; index < obj.length; index++){
                    if (obj[index].type === "path") obj[index].selectable = false;                    
                }
                //NS2-493
                /* if (lengthInUtf8Bytes(JSON.stringify(canvas.toJSON())) > 8192) {
                    //console.log('annotation data size exceeds limit');
                    if (g_maxDivId !== null) {
                        let strDim = canvas.width+"px";
                        strDim += canvas.height+"px";
                        otExpObj.sendMultipartAnnotationToExpert(strDim);//NS2-493 
                        //sendAnnotationToExpert(strDim);
                        otExpObj.sendAnnotationMultipart(strDim, paramElem);// NS2-455
                        //console.log(strDim);
                    }
                    let objSize = lengthInUtf8Bytes(JSON.stringify(canvas.toJSON()));
                    //sendAnnotationToExpert(canvas.toJSON()); //NS2-345
                    otExpObj.processMultiPartAnnotation(JSON.stringify(canvas.toJSON()), objSize, paramElem);// NS2-455
                } else { */
                    if (g_maxDivId !== null) {
                        // Setting the expert X & Y (Height & Width) values into the state
                        otExpObj.setState((prevState) =>({
                            expertDimX: canvas.width,
                            expertDimY: canvas.height
                        })
                        );
                        let strDim = canvas.width+"px";
                        strDim += canvas.height+"px";
                        //console.log(strDim);
                        //console.log(typeof(strDim));
                        otExpObj.sendAnnotationToExpert(strDim);//NS2-345
                        otExpObj.sendAnnotation(strDim, paramElem);
                        //console.log(strDim);
                    }
                    
                    strCanvasJSON = canvas.toJSON();
                    let json = canvas.toJSON();
                    if (json.backgroundImage !== undefined) delete json.backgroundImage;
                    otExpObj.sendAnnotationToExpert(json); //NS2-345
                    otExpObj.sendAnnotation(json, paramElem);
    
                    printConsole(`isPointerActive??? ${isPointerActive}`);
    
                    if (isPointerActive) {
    
                    } else {
                        pointerX = "-1";
                        pointerY = "-1";
                        const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + otExpObj.state.pencolor;
                        //otExpObj.sendAnnotationToExpert(string); //NS2-345
                        //otExpObj.sendAnnotation(string, paramElem);
                    }
                //}
                //redrawAnnotationCanvas(paramElem);
            }
        });
    }
}

// For implementation of the Colour, Shapes & Multipart Annotation object sending feature
// NS2-455
function removeCanvasEvents() {
    let drawElem = document.getElementById('canvas');
    if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
        let canvas = drawElem.fabric;
        canvas.off('mouse:down');
        canvas.off('mouse:move');
        canvas.off('mouse:up');
    }
}

function FabricCalcArrowAngle(x1, y1, x2, y2) {
    var angle = 0, x, y;
    x = (x2 - x1);
    y = (y2 - y1);
    if (x === 0) {
        angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
    } else if (y === 0) {
        angle = (x > 0) ? 0 : Math.PI;
    } else {
        angle = (x < 0) ? Math.atan(y / x) + Math.PI :
            (y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
    }
    return (angle * 180 / Math.PI + 90);
}

// NS2-455
function onClickChangeDraw(paramElem, bCapture, urlObj, flag = false) {
    if (paramElem === null) {
        printConsole('invalid element passed to onClickChangeDraw');
        return;
    }
    let canvas; //TP-2586
    
    let drawElem = document.getElementById('canvas');

    if (drawElem === null || drawElem === undefined) {
        printConsole("Canvas element is null/undefined.")
        return;
    }
    /* if (drawElem === null || drawElem === undefined) {
        
        let nameDiv = document.getElementById(g_maxDivId+"_name");

        // Make the Name Div background darker
        if (nameDiv !== null && nameDiv !== undefined){
            nameDiv.classList.add('annotate-class');
        }

        // create a canvas element
        let newCanvas = document.createElement('canvas');
        newCanvas.id = 'canvas';
        let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
        newCanvas.width = bCapture ? paramElem.offsetWidth : paramElemVdoChild[0].offsetWidth;
        newCanvas.height = bCapture ? paramElem.offsetHeight : paramElemVdoChild[0].offsetHeight;
        newCanvas.style.zIndex = '1';
        newCanvas.style.display = 'block';

        // insert canvas after video element
        if (paramElemVdoChild.length < 1)
            paramElem.appendChild(newCanvas);
        else
            paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);

        // add a fabric canvas element
        canvas = new fabric.Canvas(newCanvas);
        canvas.selection = false;
        let isFreeDrawing = otExpObj.state.isFreeDrawing, isPointerActive = otExpObj.state.isPointerActive;
        let drawingObj = document.getElementById('canvas');
        //console.log(drawingObj);
        drawingObj.fabric = canvas;        
        //return;
    } else {
    } */
    canvas = drawElem.fabric;
    canvas.selection = false;
    var rect, circle, line, triangle, origX, origY;
    var isRectActive = otExpObj.state.isRectActive, isCircleActive = otExpObj.state.isCircleActive, isArrowActive = otExpObj.state.isArrowActive, isFreeDrawing = otExpObj.state.isFreeDrawing, isPointerActive = otExpObj.state.isPointerActive;
    let hideButton = otExpObj.state.hideButton;
    if (canvas === null) {
        printConsole('Fabric canvas could not be created!!!');
        return;
    }
    canvas.isDrawingMode = 1;
    //TP-2586
    if (isFreeDrawing/*  || isPointerActive */) { //TP-2475
        canvas.freeDrawingBrush.color = otExpObj.state.pencolor;
        (hideButton !== "d-none" ) ? canvas.freeDrawingBrush.width = 4 : canvas.freeDrawingBrush.width = 2; //TP-2720 & TP-2475
    } else if (isRectActive || isCircleActive || isArrowActive ) {
        canvas.freeDrawingBrush.color = "transparent";
        canvas.freeDrawingBrush.width = 1;
    } else {
        canvas.isDrawingMode = 0;
    }

    //TP-2828 & TP-2586
    if (isFreeDrawing || isCircleActive || isArrowActive || isRectActive) {
        canvas.on('mouse:down', function (option) {
            //console.log(option);
            // NS2-509
            /* if (option.target !== null) {
                return;
            } else { */
            var pointer = canvas.getPointer(option.e);
            origX = pointer.x;
            origY = pointer.y;
            if (isRectActive) {
                if (circle !== null && circle !== undefined) {
                    circle = null;
                }
                if (line !== null && line !== undefined) {
                    line = null;
                }
                if (triangle !== null && triangle !== undefined) {
                    triangle = null;
                }
    
                rect = new fabric.Rect({
                    left: origX,
                    top: origY,
                    width: pointer.x - origX,
                    height: pointer.y - origY,
                    fill: '',
                    stroke: otExpObj.state.pencolor,
                    type: 'rect',
                    strokeWidth: hideButton === "d-none" ? 2 : 4, //TP-2720
                });
                canvas.add(rect);
            }
            else if (isCircleActive) {
                if (line !== null && line !== undefined) {
                    line = null;
                }
                if (triangle !== null && triangle !== undefined) {
                    triangle = null;
                }
                if (rect !== null && rect !== undefined) {
                    rect = null;
                }
                circle = new fabric.Circle({
                    left: origX,
                    top: origY,
                    originX: 'left',
                    originY: 'top',
                    radius: pointer.x - origX,
                    /*rx: pointer.x - origX,
                    ry: pointer.y - origY, */
                    angle: 0,
                    fill: '',
                    stroke: otExpObj.state.pencolor,
                    strokeWidth: hideButton === "d-none" ? 2 : 4, //TP-2720
                    type: 'circle'
                });
                canvas.add(circle);
            }
            else if (isArrowActive) {
                if (circle !== null && circle !== undefined) {
                    circle = null;
                }
                if (rect !== null && rect !== undefined) {
                    rect = null;
                }
                var points = [pointer.x, pointer.y, pointer.x, pointer.y];
                line = new fabric.Line(points, {
                    strokeWidth: hideButton === "d-none" ? 1.5 : 3, //TP-2720
                    fill: otExpObj.state.pencolor,
                    stroke: otExpObj.state.pencolor,
                    originX: 'center',
                    originY: 'center',
                    id: 'arrow_line',
                    type: 'line'
                });
                var centerX = (line.x1 + line.x2) / 2;
                var centerY = (line.y1 + line.y2) / 2;
                var deltaX = line.left - centerX;
                var deltaY = line.top - centerY;
    
                triangle = new fabric.Triangle({
                    left: line.get('x1') + deltaX,
                    top: line.get('y1') + deltaY,
                    originX: 'center',
                    originY: 'center',
                    selectable: false,
                    pointType: 'arrow_start',
                    angle: -45,
                    width: 15,
                    height: 25,
                    fill: otExpObj.state.pencolor,
                    id: 'arrow_triangle',
                    type: 'triangle'
                });
                canvas.add(line, triangle);
            }
            /* else if (isPointerActive) {
                //TP-2475 -- Remove the rest of the canvas drawings before pointer is drawn
                let obj = canvas.getObjects();
                for(let index = 0; index < obj.length; index++){
                    canvas.remove(obj[index]);
                }
                var context=canvas.getContext("2d");
                context.clearRect(0,0,canvas.width, canvas.height);
                const {pencolor} = otExpObj.state;
    
                otExpObj.pointerTimer = setInterval(()=>{
                    context.font='500 40px FontAwesome';
                    context.fillStyle = otExpObj.state.pencolor;
                    context.textAlign = 'center';
                    context.fillText('\uf05b',origX,origY+10);
                    clearInterval(otExpObj.pointerTimer);
                },1000)
            } */
            canvas.on('mouse:move', function (option) {
    
                var pointer = canvas.getPointer(option.e);
                if (isRectActive) {
    
                    if (origX > pointer.x) {
                        rect.set({ left: pointer.x });
                    }
                    if (origY > pointer.y) {
                        rect.set({ top: pointer.y });
                    }
                    rect.set({ width: Math.abs(origX - pointer.x) });
                    rect.set({ height: Math.abs(origY - pointer.y) });
                    canvas.renderAll();
                } else if (isCircleActive) {
                    if (circle === null) {
                        return;
                    }
                    var rx = Math.abs(origX - pointer.x) / 2;
                    var ry = Math.abs(origY - pointer.y) / 2;
                    if (rx > circle.strokeWidth) {
                        rx -= circle.strokeWidth / 2;
                    }
                    if (ry > circle.strokeWidth) {
                        ry -= circle.strokeWidth / 2;
                    }
                    circle.set({ radius: rx/*rx: rx, ry: ry */ });
    
                    if (origX > pointer.x) {
                        circle.set({ originX: 'right' });
                    } else {
                        circle.set({ originX: 'left' });
                    }
                    if (origY > pointer.y) {
                        circle.set({ originY: 'bottom' });
                    } else {
                        circle.set({ originY: 'top' });
                    }
                    canvas.renderAll();
                } else if (isArrowActive) {
                    line.set({
                        x2: pointer.x,
                        y2: pointer.y
                    });
                    triangle.set({
                        'left': pointer.x + deltaX,
                        'top': pointer.y + deltaY,
                        'angle': FabricCalcArrowAngle(line.x1,
                            line.y1,
                            line.x2,
                            line.y2)
                    });
                    canvas.renderAll();
                }  
            });
    
        });
    
        canvas.on('mouse:up', function (option) {
            canvas.off('mouse:move');
            var pointer = canvas.getPointer(option.e);
            let drawElem = document.getElementById('canvas');
            if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
                canvas.enableRetinaScaling = true;
                let obj = canvas.getObjects();
                //TP-2600 -- Use case 2
                for(let index = 0; index < obj.length; index++){
                    if (obj[index].type === "path") obj[index].selectable = false;                    
                }
                // NS2-493
               /*  if (lengthInUtf8Bytes(JSON.stringify(canvas.toJSON())) > 8192) {
                    //console.log('annotation data size exceeds limit');
                    if (g_maxDivId !== null) {
                        let strDim = canvas.width+"px";
                        strDim += canvas.height+"px";
                        otExpObj.sendMultipartAnnotationToExpert(strDim);//NS2-493
                        //sendAnnotationToExpert(strDim);
                        otExpObj.sendAnnotationMultipart(strDim, paramElem);// NS2-455
                        //console.log(strDim);
                    }
                    let objSize = lengthInUtf8Bytes(JSON.stringify(canvas.toJSON()));
                    //sendAnnotationToExpert(canvas.toJSON()); //NS2-345
                    otExpObj.processMultiPartAnnotation(JSON.stringify(canvas.toJSON()), objSize, paramElem);// NS2-455
                } else { */
                    if (g_maxDivId !== null) {
                        // Setting the expert X & Y (Height & Width) values into the state
                        otExpObj.setState((prevState) =>({
                            expertDimX: canvas.width,
                            expertDimY: canvas.height
                        })
                        );
                        let strDim = canvas.width+"px";
                        strDim += canvas.height+"px";
                        //console.log(strDim);
                        //console.log(typeof(strDim));
                        otExpObj.sendAnnotationToExpert(strDim);//NS2-345
                        otExpObj.sendAnnotation(strDim, paramElem);
                        //console.log(strDim);
                    }
                    
                    strCanvasJSON = canvas.toJSON();
                    let json = canvas.toJSON();
                    if (json.backgroundImage !== undefined) delete json.backgroundImage;
                    otExpObj.sendAnnotationToExpert(json); //NS2-345
                    otExpObj.sendAnnotation(json, paramElem);

                    //TP-2474
                    printConsole(`isPointerActive??? ${isPointerActive}`);

                    if (isPointerActive) {
                        pointerX = Math.round(pointer.x); //TP-2474
                        pointerY = Math.round(pointer.y); //TP-2474
                        const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + otExpObj.state.pencolor;
                        //otExpObj.sendAnnotationToExpert(string); //NS2-345
                        //otExpObj.sendAnnotation(string, paramElem);
                    } else {
                        pointerX = "-1";
                        pointerY = "-1";
                        const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + otExpObj.state.pencolor;
                        //otExpObj.sendAnnotationToExpert(string); //NS2-345
                        //otExpObj.sendAnnotation(string, paramElem);
                    }
                //}
                //redrawAnnotationCanvas(paramElem);
            }
        });
        /**TP-2853 */
        if (otExpObj.state.annotateLoader === true) {
            otExpObj.setState({ annotateLoader: false, isLoading: false })
        }
    }


}
//TP-2474
function onClickPointerTrack(paramElem) {
    printConsole("on Pointer tracking start/stop")
    if (paramElem === null) {
        printConsole('invalid element passed to onClickPointerTrack');
        return;
    }
    
    let drawElem = document.getElementById('canvas');
    if (drawElem === null) {
        printConsole("Canvas element is null.")
        return;
    }

    let canvas = drawElem.fabric;
    canvas.selection = false;
    const isPointerActive = otExpObj.state.isPointerActive;
    if (canvas === null) {
        printConsole('Fabric canvas could not be created!!!');
        return;
    }
    canvas.isDrawingMode = 1;
    if (isPointerActive) { //TP-2475
        canvas.isDrawingMode = 0; //TP-2586 -- Turn off the Drawing Mode of Primary expert canvas when Pointer is selected
        //canvas.freeDrawingBrush.color = otExpObj.state.pencolor;
        //canvas.freeDrawingBrush.width = 3;
    }

    if (isPointerActive) {
        triggerAnnotationTimer(); //TP-2474
        let lastevent = false;
        let r = 0;
        
        function animate() {
            let ctx = canvas.getContext("2d");
            //ctx.clearRect(0, 0, canvas.width, canvas.height);
            if (lastevent) {
                //ctx.strokeStyle = otExpObj.state.pencolor;
                var pointer = canvas.getPointer(lastevent);
                r += 8;
                if (r > 255) r = 0;
                //ctx.beginPath();
                //ctx.arc(pointer.x, pointer.y, 7, 0, 2 * Math.PI);
                //ctx.stroke();

                let drawElem = document.getElementById('canvas');
                if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
                    canvas.enableRetinaScaling = true;
                    
                        if (g_maxDivId !== null) {
                            // Setting the expert X & Y (Height & Width) values into the state
                            otExpObj.setState((prevState) =>({
                                expertDimX: canvas.width,
                                expertDimY: canvas.height
                            })
                            );
                            let strDim = canvas.width+"px";
                            strDim += canvas.height+"px";
                            //console.log(strDim);
                            //console.log(typeof(strDim));
                            //otExpObj.sendAnnotationToExpert(strDim);//NS2-345
                            //otExpObj.sendAnnotation(strDim, paramElem);
                            //console.log(strDim);
                        }
                        pointerX = Math.round(pointer.x); //TP-2474
                        pointerY = Math.round(pointer.y); //TP-2474
                        const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + otExpObj.state.pencolor;
                        //otExpObj.sendAnnotationToExpert(string); //NS2-345
                        //otExpObj.sendAnnotation(string, paramElem);

                        //strCanvasJSON = canvas.toJSON();
                        let json = canvas.toJSON();
                        if (json.backgroundImage !== undefined) delete json.backgroundImage;
                        //otExpObj.sendAnnotationToExpert(json); //NS2-345
                        //otExpObj.sendAnnotation(json, paramElem);
                
                }
                requestAnimationFrame(animate);
            }
        }
        canvas.on("mouse:move", function(option) {
            lastevent = option.e;
        });
        canvas.on("mouse:over", function(option) {
            lastevent = option.e;
            animate();
        });
        canvas.on("mouse:out", function(option) {
            lastevent = false;
        });
    } else {
        printConsole("Else block for POinter");
        canvas.off("mouse:over");
        canvas.off("mouse:out");
        stopAnnotationTimer(); //TP-2474

        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
            canvas.enableRetinaScaling = true;
                    
            if (g_maxDivId !== null) {
                // Setting the expert X & Y (Height & Width) values into the state
                otExpObj.setState((prevState) =>({
                    expertDimX: canvas.width,
                    expertDimY: canvas.height
                })
                );
                let strDim = canvas.width+"px";
                strDim += canvas.height+"px";
                //console.log(strDim);
                //console.log(typeof(strDim));
                //otExpObj.sendAnnotationToExpert(strDim);//NS2-345
                //otExpObj.sendAnnotation(strDim, paramElem);
                //console.log(strDim);
            }
            pointerX = "-1";
            pointerY = "-1";
            const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + otExpObj.state.pencolor;
            otExpObj.sendAnnotationToExpert(string); //NS2-345
            otExpObj.sendAnnotation(string, paramElem);

            //strCanvasJSON = canvas.toJSON();
            let json = canvas.toJSON();
            if (json.backgroundImage !== undefined) delete json.backgroundImage;
            //otExpObj.sendAnnotationToExpert(json); //NS2-345
            //otExpObj.sendAnnotation(json, paramElem);
            canvas.off("mouse:move");
        }

    }
}

//TP-2474 -- Annotation timer start/stop methods
function triggerAnnotationTimer () {
    otExpObj.aTimer = setInterval(() => {
        const {pencolor} = otExpObj.state;
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
            let canvas = drawElem.fabric;
            const paramElem = document.getElementById(g_maxDivId);
            const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + pencolor;
            otExpObj.sendAnnotationToExpert(string); //NS2-345
            otExpObj.sendAnnotation(string, paramElem);
        } else {
            clearInterval(otExpObj.aTimer);
        }
    }, 2000); //2 seconds time interval
}

function stopAnnotationTimer () {
    printConsole("Stop annotation Pointer timer!!!");
    clearInterval(otExpObj.aTimer);
}

function clearAnnotationsCanvas(paramElem, bCapture) {
    let canvasContainers = paramElem.getElementsByClassName('canvas-container');
    //TP-1616 -- Handling of the clear annotation canvas for all the canvas containers
    for (let i=canvasContainers.length-1; i>=0; i--) {
        canvasContainers[i].parentNode.removeChild(canvasContainers[i]);
        //canvasContainers[i].querySelectorAll('*').forEach(n => n.remove());                                
    }
}

// for NS2-345
function onDrawPassiveExperts(paramElem, bCapture, imgData, format) {
    if (paramElem === null) {
        printConsole('invalid element passed to onDrawPassiveExperts');
        //		alert('There is no window handle to draw annotations on. Please contact support.');
        return;
    }

    let drawElem = document.getElementById('canvas');
    let nameDiv = document.getElementById(g_maxDivId+"_name");

    if (drawElem !== null) {
        //NS2-511
        if (otExpObj.props.isSafari) {
            drawElem.width = 0;
            drawElem.height = 0;
        }
        drawElem.parentNode.removeChild(drawElem);
    }

    let canvasContainers = document.getElementsByClassName('canvas-container');
    for (let i=0; i<canvasContainers.length; i++) {
        //canvasContainers[i].querySelectorAll('*').forEach(n => n.remove());
        canvasContainers[i].parentNode.removeChild(canvasContainers[i]);
    }

    // Remove the Dark Name div background for Passive expert
    if (nameDiv !== null && nameDiv !== undefined){
        nameDiv.classList.remove('annotate-class');
    }

    // create a canvas element
    let newCanvas = document.createElement('canvas');
    const {isPointerActive} = otExpObj.state; //TP-2475
    newCanvas.id = 'canvas';
    let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
    //console.log(bCapture);
    if (paramElemVdoChild.length === 0) {
        printConsole('element passed to onDrawPassiveExperts doesnt have a video element');
        return;
    }
    //newCanvas.width = bCapture ? paramElem.offsetWidth : paramElemVdoChild[0].offsetWidth;
    //newCanvas.height = bCapture ? paramElem.offsetHeight : paramElemVdoChild[0].offsetHeight;
    newCanvas.width = paramElemVdoChild[0].offsetWidth;
    newCanvas.height = paramElemVdoChild[0].offsetHeight;
    // insert canvas after video element
    if (paramElemVdoChild.length < 1)
        paramElem.appendChild(newCanvas);
    else 
        paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);
    // add a fabric canvas element
    let canvas = new fabric.Canvas(newCanvas);
    document.getElementById("canvas").fabric = canvas; //TP-2545

    if (canvas === null) {
        printConsole('Fabric canvas could not be created!!!');
        return;
    }
    let dimX = otExpObj.state.expertDimX;
    let dimY = otExpObj.state.expertDimY;
    if (dimX === 0) {
        printConsole('dimensions of expert canvas did not come in');
        dimX = canvas.getWidth();
        dimY = canvas.getHeight();
        //return;
    }

    if (strCanvasJSON !== null) {
        //TP-2475
        /*TP-2474 if (isPointerActive === true) {
            canvas.loadFromJSON(strCanvasJSON, function () {
                //fabric.log(o, object);
                let objList = canvas.getObjects();
                let index = 0;
                let multiX = canvas.getWidth() / dimX;
                let multiY = canvas.getHeight() / dimY;
                for (index = 0; index < objList.length; index++) {
                    var context=canvas.getContext("2d");
                    context.clearRect(0,0,canvas.width, canvas.height);
                    let marginLeft = objList[index].left * multiX;
                    let marginTop = (objList[index].top * multiY)+10;                                       
            
                    setInterval(()=>{
                        context.font='500 40px FontAwesome';
                        context.fillStyle = otExpObj.state.pencolor;
                        context.textAlign = 'center';
                        context.fillText('\uf05b',marginLeft, marginTop);
                        clearInterval();
                    },1000)
                }
            });
        } else { */
            canvas.loadFromJSON(strCanvasJSON, function () {
                //fabric.log(o, object);
                let objList = canvas.getObjects();
                let index = 0;
                let multiX = canvas.getWidth() / dimX;
                let multiY = canvas.getHeight() / dimY;
                for (index = 0; index < objList.length; index++) {
                    objList[index].scaleX = objList[index].scaleX * multiX;
                    objList[index].scaleY = objList[index].scaleY * multiY;
                    objList[index].left = objList[index].left * multiX;
                    objList[index].top = objList[index].top * multiY;
                    objList[index].selectable = false;
                    objList[index].setCoords();
                }
            });
        //}
    }

    if (bCapture === true && imgData !== '' && imgData !== null) {
        //console.log('loadImagebackground hit with: ', imgData);
        let contentType = 'image/png';
        if (format !== "" && format !== undefined) {
            contentType = 'image/'+format;
            imgData = imgData.replace('data:'+contentType+';base64,', '').trim();
        } 
        const blob = (format === "" || format === undefined ) ? imgData : new base64StringToBlob(imgData, contentType);
        let urlObj = URL.createObjectURL(blob);
        fabric.Image.fromURL(urlObj, function (img) {
            // add background image
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
        });
    }
    else {
        canvas.backgroundImage = false;
        canvas.renderAll();
    }

}

//TP-2474 -- New Method to draw/remove the 
function displayPointerTrack (paramElem, pencolor, bCapture, imgData, format) {

    let drawElem = document.getElementById('canvas');
    //TP-2602
    if (drawElem !== null && otExpObj.props.isSafari && otExpObj.props.isiOS === true) {
        //NS2-511
        if (otExpObj.props.isSafari) {
            drawElem.width = 0;
            drawElem.height = 0;
        }
        drawElem.parentNode.removeChild(drawElem);
    }
    let canvas;

    //TP-2474 -- To handle the creation of the canvas when only the Pointer
    // Payload comes to the technician without there being any other annotation drawn
    if (drawElem === null || drawElem === undefined) {
        printConsole("canvas element not there so creating new canvas element");
        //return;

        let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
        //console.log(bCapture);
        if (paramElemVdoChild.length === 0) {
            printConsole("element passed to displayPointerTrack doesn't have a video element");
            return;
        }
        // TP-2545 -- create a canvas element
        let newCanvas = document.createElement('canvas');
        const {isPointerActive} = otExpObj.state; //TP-2475
        newCanvas.id = 'canvas';
        //newCanvas.width = bCapture ? paramElem.offsetWidth : paramElemVdoChild[0].offsetWidth;
        //newCanvas.height = bCapture ? paramElem.offsetHeight : paramElemVdoChild[0].offsetHeight;
        newCanvas.width = paramElemVdoChild[0].offsetWidth;
        newCanvas.height = paramElemVdoChild[0].offsetHeight;
        // insert canvas after video element
        if (paramElemVdoChild.length < 1)
            paramElem.appendChild(newCanvas);
        else 
            paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);
        // add a fabric canvas element
        canvas = new fabric.Canvas(newCanvas); //TP-2545
        document.getElementById("canvas").fabric = canvas; //TP-2545

        if (canvas === null) {
            printConsole('Fabric canvas could not be created!!!');
            return;
        }
    } else {
        // add a fabric canvas element
        canvas = drawElem.fabric; //TP-2545
    }
    
    let dimX = otExpObj.state.expertDimX;
    let dimY = otExpObj.state.expertDimY;
    if (dimX === 0) {
        printConsole('dimensions of expert canvas did not come in');
        dimX = canvas.getWidth();
        dimY = canvas.getHeight();
        //return;
    }
    let lineH, lineV, circleP; //TP-2720 & TP-2569
    
    //TP-2545
    let pointerDimX = otExpObj.state.pointerDimX;
    let pointerDimY = otExpObj.state.pointerDimY;
    printConsole(`pointerDimX = ${pointerDimX}, pointerDimY = ${pointerDimY}`)
    //console.log(strCanvasJSON);

    if (strCanvasJSON !== null) 
        canvas.loadFromJSON(strCanvasJSON, function () {
            //fabric.log(o, object);
            let objList = canvas.getObjects();
            let index = 0;
            let multiX = canvas.getWidth() / dimX;
            let multiY = canvas.getHeight() / dimY;
            for (index = 0; index < objList.length; index++) {
                objList[index].scaleX = objList[index].scaleX * multiX;
                objList[index].scaleY = objList[index].scaleY * multiY;
                objList[index].left = objList[index].left * multiX;
                objList[index].top = objList[index].top * multiY;
                objList[index].selectable = false;
                objList[index].setCoords();
            }
        });

    if (pointerDimX !== -1 && pointerDimY !== -1) { //TP-2545
        //Remove older pointer objects in the canvas
        let obj = canvas.getObjects();
        //TP-2569 -- Plus sign drawing for the Pointer's location insted of the Dot
        for(let index = 0; index < obj.length; index++){
            if (obj[index].type === "line" && obj[index].id === "pointer_line_h") canvas.remove(obj[index]);
            if (obj[index].type === "line" && obj[index].id === "pointer_line_y") canvas.remove(obj[index]);
            if (obj[index].type === "circle" && obj[index].id === "pointer_circle") canvas.remove(obj[index]); //TP-2828 & TP-2720
        }
        let multiX = canvas.getWidth() / dimX;
        let multiY = canvas.getHeight() / dimY;

        let xPos = Math.round(pointerDimX * multiX);
        let yPos = Math.round(pointerDimY * multiY);

        let deltaPos = 16; //TP-2720
        // Initiate a line instance
        let xPos1 = (xPos - deltaPos);
        let xPos2 = (xPos + deltaPos);
        /**TP-2720 -- New UI for the Pointer Crosshair 
        * Icon with changes to size and strokeWidth etc
        */
        lineH = new fabric.Line([xPos1, yPos-1, xPos2, yPos-1], {
                strokeWidth: 3.5,
                stroke: pencolor,
                selectable: false, //TP-2600 -- Use case 2
                id: 'pointer_line_h',
        });
        let yPos1 = (yPos - deltaPos);
        let yPos2 = (yPos + deltaPos);
        lineV = new fabric.Line([xPos-1.5, yPos1, xPos-1.5, yPos2], {
                strokeWidth: 3.5,
                stroke: pencolor,
                selectable: false, //TP-2600 -- Use case 2
                id: 'pointer_line_y',
        });
        circleP = new fabric.Circle({
            left: xPos,
            top: yPos,
            originX: 'center',
            originY: 'center',
            radius: 10,
            fill: '',
            stroke: pencolor,
            selectable: false, //TP-2600 -- Use case 2
            strokeWidth: 1.5,
            id: 'pointer_circle' //TP-2828
        });
        //console.log("xPos=" + xPos + " yPos=" + yPos + " xPos1=" + xPos1 + " xPos2=" + xPos2 + " yPos1=" + yPos1 + " yPos2=" + yPos2);
        canvas.add(lineH);
        canvas.add(lineV);
        canvas.add(circleP);//TP-2720

        /* pointer = new fabric.Circle({
            left: pointerDimX * multiX, //TP-2545
            top: pointerDimY * multiY, //TP-2545
            originX: 'center',
            originY: 'center',
            radius: 4,
            fill: pencolor,
            stroke: pencolor,
            strokeWidth: 2
        });

        canvas.add(pointer); */
    } else {
        if (canvas === undefined) return;
        //Remove older pointer objects in the canvas
        let obj = canvas.getObjects();
        //TP-2569
        for(let index = 0; index < obj.length; index++){
            if (obj[index].type === "line" && obj[index].id === "pointer_line_h") canvas.remove(obj[index]);
            if (obj[index].type === "line" && obj[index].id === "pointer_line_y") canvas.remove(obj[index]);
            if (obj[index].type === "circle" && obj[index].id === "pointer_circle") canvas.remove(obj[index]); //TP-2828 & TP-2720
        }        
    }

    //TP-2523-- Handling of Technician/Passive expert side RC image loading when pointer annotaion payload is received
    // When the RC Image is set
    if (bCapture === true && imgData !== '' && imgData !== null) {
        //console.log('loadImagebackground hit with: ', imgData);
        let contentType = 'image/png';
        if (format !== "" && format !== undefined) {
            contentType = 'image/'+format;
            imgData = imgData.replace('data:'+contentType+';base64,', '').trim();
        } 
        const blob = (format === "" || format === undefined ) ? imgData : new base64StringToBlob(imgData, contentType);
        let urlObj = URL.createObjectURL(blob);
        fabric.Image.fromURL(urlObj, function (img) {
            // add background image
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
        });
    }
}

function processTooltip(status, i18n){
    switch (status) {
        case "session.freehand":
            return i18n._(t`session.freehand`);
        case "session.circle":
            return i18n._(t`session.circle`);
        case "session.square":
            return i18n._(t`session.square`);
        case "session.arrow":
            return i18n._(t`session.arrow`);
        case "session.handIconDisabled":
            return i18n._(t`session.handIconDisabled`);
        case "session.expertControlsDisabled":
            return i18n._(t`session.expertControlsDisabled`);
        default:
            break;
    }
}

//Method for Language translate of Zoom levels
function processZoomLevels(level, i18n){
    switch (level) {
        case "1X":
            return i18n._(t`1X`);
        case "2X":
            return i18n._(t`2X`);
        case "3X":
            return i18n._(t`3X`);
        case "4X":
            return i18n._(t`4X`);
        case "5X":
            return i18n._(t`5X`);
        default:
            break;
    }
}

class OTExpert extends OTSessionUser {
    constructor(props) {
        super(props);
        this.fileViewer = null;
        this.active3dModel = null;
        this.state = {
            ...this.state,
            expertDimX: 0,
            expertDimY: 0,
            strFileName: '',
            rcImgObj: '',
            rcImgType: '',
            chatLeft: '0px',
            chatTop: '0px',
            chatWidth: '0px',
            chatHeight: '0px',
            chatMsgInfoArr: [{
                msgColor: '',
                messageID: '',
                firstName: '',
                lastName: '',
                message: '',
                timeStamp: '',
                msgType: 0 // 0 - indiv, 1 - group
            }],
            bMaxChat: false,
            bAllChat: false,
            bMuteAllAudio: false,
            bRemoteMute: false,
            bMuteMyMike: false,
            bRemoteMuteDisabled: false, //T32-413
            muteMikeForMaxDiv: false,
            openMikeDialog: false,
            bHideExpertSSButton: true,
            bHideTechnicianSSButton: true,
            bHideZoomButton: true,
            bHideTorchButton: true,
            bAnnotate: false,
            urlObj: null,
            bHideAnnotateColour: true,
            bHideAnnotateShapes: true,
            bHideAnnotationPointer: false, //TP-2528 -- set as false for now this will be changed later to true
            pencolor: "red",
            bSelectColor: false,
            bSelectShape: false,
            isPointerActive: false, //TP-2475
            isErasorActive: false, //TP-2474
            isRectActive: false,
            isCircleActive: false,
            isArrowActive: false,
            isFreeDrawing: false, //TP-2586
            bShareAsset: false,
            fileData: null,
            fileNameArr: [],
            assetsArray: [],
            shareOnDiv: '',
            showFileViewer: false,
            showAudioFileViewer: false, //TP-1857
            fileType: 'none',
            filePath: '',
            fileName: '',
            URLs: [],
            expertLoggedInId: null,
            bRemoteCapture: false,
            openStreamDestroyedDialog: false,
            imageCaptureTimer: 10,
            openCameraSwapDialog: false,
            cameraSwapTimer: 10,
            sRCTechDetails: '',
            bRCAnnotateOn: false,
            bZoomClick: false,
            bTorchClick: false,
            zoom_level: "1",
            expertShareScreenMaxDiv: false,
            techShareScreen: false,
            expertSSAll: false,
            expertSSMode: false,
            prepareExpertSS: false,
            requestDialogTimer: 10,
            clickHandSignal: false,
            startRecording: false, //NS2-274
            showRecordingBeacon:false, //state to control recording beacon NS2-360
            screenCaptureClicked: false, //NS2-367
            loggedinUserTimeStamp: "", //NS2-482
            currentPrimaryExpert: 0, // NS2-486
            muteExpertMikeArr: [], //N2-436
            registeredArr: [], //TP-552
            userInfoArr: [], // TP-263
            disabledMutedAll: false, //T32-413
            hideGridViewIcon: true,
            isOngoingMaxDivFeatures: false,
            gridViewMode: false,
            nAllChatNewMsgCounter: 0,
            flashMsgText: "",
            flashValues: {},
            showFlashMessage: false,
            flashLeft: "0px",
            flashTop: "0px",
            startChatTimer: false,
            disableHand: false,
            handIconHover: "session.expertControlsDisabled",
            swapTechCamera: false,
            confirmSwapCamera: false,
            swapTechEmail: '',
            swapCamTechData: '',
            swapTechDisableVideo: false, //T32-127
            maxDivCameraCount: 1,
            maxDivTechType: "WEB",
            sessionJoinees: [],
            g_session: null,
            g_subInfoArr: [],
            unMount: false,
            isParticipantChanged: false,
            userVideoCount: 0,
            onlineList: [],
            triggerCloseSlider: false,
            hideButton: 'd-blk',
            expertWaitTimer: 20,
            newMaxViewUser: null, //TP-758
            fileOpacity: "0.60", //TP-841
            fileArr: [],
            isPrimaryToPassive: false, //TP-1086
            rotationArray: '', //TP-934
            lastViewObj: null, //TP-1452
            stopState: '', //TP-740
            framesErrorTimeout: 0, //TP-1966
            fakeExpertList: [], //TP-1993
            yieldPositiveCase: false, //TP-2292
            calleeList: null, //TP-2381
            guestUserList: [] //TP-2349
        }  
        g_subInfoArr = [];//g_session = null; 
        g_expertConnsArr = [];
        this.config = {
            bucketName: process.env.REACT_APP_S3_BUCKET,
            dirName: process.env.REACT_APP_S3_DIR, /* optional */
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        };
        this.authService = new AuthService();
        this.closeSubscriber3dObject = null;
        g_maxDivId = null;
        otExpObj = this;
    }

    componentWillReceiveProps(nextProps) {
        /* if(nextProps.headerScreenShare!==this.props.headerScreenShare && nextProps.headerScreenShare !== this.state.expertSSAll){
            this.setState({
                expertSSAll: nextProps.headerScreenShare
            });
        }
        if(nextProps.bShareAll!==this.props.bShareAll && nextProps.bShareAll !== this.state.bShareAll){
            this.setState({
                bShareAll: nextProps.bShareAll
            });
        } */
        if(nextProps.onlineUsers!==this.props.onlineUsers){
            this.setState({onlineUsers: nextProps.onlineUsers});
        }
        if(nextProps.groupUsers!==this.props.groupUsers){
            this.setState({groupUsers: nextProps.groupUsers});
        }
    }

    getDeviceClassname = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let hideButton = "d-blk";
        if (isiPad || isAndroid) {
            hideButton = "d-none";
        } 
        return hideButton;
    }

    componentWillMount = () => {
        //printConsole('OT initialized from componentWillMount');      
        //printConsole(this.props.groupUsers);
        //printConsole(this.props.onlineUsers);  
        this.initMedia(); 
        window.addEventListener("resize", this.updateDimensions);        
        this.notifyUser();
        let session = this.initializeOTSession();
        //printConsole(session); 
        this.setState({g_session: session});
        if (this.state.g_subInfoArr === undefined || this.state.g_subInfoArr === [] || this.state.g_subInfoArr === null || this.state.g_subInfoArr.length === 0) {
            this.setState({
                expertWaitTimer : 30
            },
            () => {
                this.expertTimer = setInterval(this.decrementExpertCounter.bind(this), 1000)
            });
        } 
        //console.log(this.props.sessionInfo.primary_expert_id, this.props.loggedInUserId);
        if (this.props.loggedInUserId === this.props.sessionInfo.primary_expert_id && this.props.isRespondToCall === false /**TP-2462*/) {
            this.startMissedCallNotifyTimer(); // TP-1129
        }
        //TP-2381 
        this.setState({ calleeList: this.props.calleeList }, () => {
            if (this.props.groupUsers !== undefined)
                this.setOnlineUsers();
        });
        //this.props.changeGlobalSession(session); 
        const hideButton = this.getDeviceClassname();
        this.setState({hideButton});
        this.setAnnotationButtons();        
        const {user, loggedInUserId} = this.props;     
        let {selectedGroupId} = this.props;
        if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
        // MB2-90 & MB2-106
        const from = { userId: loggedInUserId, firstName: user.firstname, lastName: user.lastname, email: user.email, isexpert: user.isexpert };

        if (!this.props.isGuest) {
            printConsole("Socket signal has been connected, hence started listening to socket signals");
            this.sessionSignal.listener('end-group-call', this.subscribeToGlobalEndCall); //TP-2131
            this.authService.socket.on('isOnline', this.subscribeToOnlineNotification);
            this.authService.socket.on('isOffline',  this.checkUserOffline);
            this.authService.socket.on('respond-to-the-call', this.subscribeToRespondToTheCall);
            this.sessionSignal = new SessionSignal(from, false, selectedGroupId);                       
            this.sessionSignal.listenerWebsocket(this.subscribeToGroupSignal); 
            this.sessionSignal.listenerUserinsession(this.subscribeToUserIsInSession); 
        }
    }

    // TP-1129 -- Missed Call notification Implementation on the Primary expert side
    startMissedCallNotifyTimer = () => {
        printConsole("Start the missed call notify timer");
        this.missedCallTimer = setTimeout(() => {
            if (this.state.onlineList.length > 0) {
                // get the emails of users who didn't join the call yet
                let onlineEmails = this.state.onlineList.map(o => {
                    return o.email;
                });
                this.sessionSignal.sendSignalMissedCallNotification({ //TP-2462
                    emails: onlineEmails
                });
            }
            printConsole("Stop the missed call notify timer...");
            clearTimeout(this.missedCallTimer);
        }, 31000) // After 31 seconds of the call starting up missed call to be sent 
    }

    initializeOTSession() {
        const {isGuest, user} = this.props;
        let { selectedGroupId } = this.props;
        if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
        propsSetJoineeCount = this.props.onSetJoinees;
        //console.log('initializeOTSession called with sessionInfo:', this.props.sessionInfo);
        
        let session = OT.initSession(this.props.sessionInfo.APIKey, this.props.sessionInfo.sessionID, { ipWhitelist: true });
        let loggedInUserId = this.props.loggedInUserId;
        printConsole(`Logged in User Id: ${this.props.loggedInUserId}`);
        printConsole(`Primary expert id: ${this.props.sessionInfo.primary_expert_id}`);
        printConsole(`is ongoing session?? ${this.props.sessionInfo.isongoingnow}`);
        //console.log("Grid view mode: ", otExpObj.state.gridViewMode);
        //console.log("Expert SS on or not:  ", otExpObj.state.expertSSAll);
        let primary_expert_id = this.props.sessionInfo.primary_expert_id;   
        if (loggedInUserId !== primary_expert_id && this.props.sessionInfo.isongoingnow === false)     {
            //console.log("in a new session call the primary expert is not same as this expert");
            const message = "Primary Expert not same as user";
            otExpObj.setState({ flashMsgText: message, showFlashMessage: true, flashLeft: "", flashTop: "" });
        }
        if (loggedInUserId === primary_expert_id) {
            otExpObj.setState({ 
                showHideMaxDivControl: true, 
                currentPrimaryExpert: loggedInUserId
            });
            otExpObj.props.updateShowHideHeaderActionButtons(true);
            otExpObj.sendSignalSyncExperts({reason: "request_expert_sync"}, null); //TP-1534
        } else if (otExpObj.state.gridViewMode === false && otExpObj.state.expertSSAll === false){
            otExpObj.sendSignalSyncExperts({reason: "request_expert_sync"}, null);
            // Send the signal SYNC_EXPERTS with reason="registered_sync"
            // to announce to all other experts that this expert is not a registered user
            isGuest && otExpObj.sendSignalSyncExperts({
                isRegistered: false,
                reason: "registered_sync"},
            null);

            // Send the signal SYNC_EXPERTS with reason="registered_sync"
            // to announce to all other experts that this expert is a registered user
            !isGuest && otExpObj.sendSignalSyncExperts({
                isRegistered: true,
                reason: "registered_sync"},
            null);
        }

        if (session !== null) {
            if (isGuest) {
                const from = { userId: loggedInUserId, firstName: user.firstname, lastName: user.lastname, email: user.email, isexpert: user.isexpert };
                /**TP-2136 */
                printConsole("For Guest Users -- Socket signal has been connected, hence started listening to socket signals");
                printConsole(`socket connected state ?? ${otExpObj.authService.socket.connected}`)
                otExpObj.authService.socket.on('isOnline', otExpObj.subscribeToOnlineNotification);
                otExpObj.authService.socket.on('isOffline',  otExpObj.checkUserOffline);
                otExpObj.authService.socket.on('respond-to-the-call', otExpObj.subscribeToRespondToTheCall);
                otExpObj.sessionSignal = new SessionSignal(from, false, selectedGroupId);                       
                otExpObj.sessionSignal.listenerWebsocket(otExpObj.subscribeToGroupSignal); 
                otExpObj.sessionSignal.listenerUserinsession(otExpObj.subscribeToUserIsInSession); 
            }
            //NS2-482
            session.on("connectionCreated", function (event) {
                let userId = event.connection.data.split(':::')[0];
                if (loggedInUserId.toString() === userId) {
                    //console.log("connectionCreated: ", event.connection.data);
                    //console.log("loggedinUserTimeStamp = ", event.connection.data.split(':::')[6])
                    otExpObj.setState({ loggedinUserTimeStamp: event.connection.data.split(':::')[6] })
                }
            });
        }

        //console.log('session: ', session);
        
            // Subscribe to a newly created stream
            session.on("streamCreated", function (event) {
                printConsole("Stream Created again!!")
                printConsole(event.stream.streamId);
                printConsole(event.stream.connection.data);
                printConsole(event.stream.videoType);
                if (g_subInfoArr.length >= 0 && otExpObj.state.expertWaitTimer > 0) otExpObj.stopExpertCounter();
                let maxDiv = null;
                let data = event.stream.connection.data;
                let dataSplit = data.split(':::');                
                if (dataSplit[4] === 'true' && event.stream.videoType !== "screen") {
                    //this condition is true when another Expert joins the session
                    const expertObj = {
                        data: event.stream.connection.data,
                        isRegistered: true
                    }
                    g_expertConnsArr.push(expertObj);
                    g_expertConnsArr = sortConnArr(g_expertConnsArr);  // NS2-482  
                    if (primary_expert_id === parseInt(dataSplit[0]) /* && otExpObj.state.showHideMaxDivControl === false  TP-1534 */ && g_expertConnsArr.length > 0) {
                        otExpObj.sendSignalSyncExperts({reason: "request_expert_sync"}, null);
                    }                          
                    if (otExpObj.state.showHideMaxDivControl === true) { //loggedInUserId === primary_expert_id
                        otExpObj.setState({ currentPrimaryExpert: loggedInUserId });// NS2-486
                        //otExpObj.sendSignalCurrentPrimaryExpert(otExpObj.props.loggedInUserId);// NS2-486
                        //const param = false;
                        if (otExpObj.state.gridViewMode === true && otExpObj.state.expertSSAll === false){
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            otExpObj.sendSignalSyncExperts({
                                show_grid: true,
                                hand_icon_enabled: (otExpObj.state.showControlRequestDialog === true) ? false : true, //TP-1313
                                header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null) ? true : false, //TP-2378
                                reason: "show_action"}, 
                            dataSplit[3]);  // send the "show_grid" value as true for Grid View Mode                            
                            // This is to take care of the scenario of when Primary expert has turned of some User's video
                            // and the Passive expert who joins the call needs to be in sync with the Main expert
                            /* if (otExpObj.props.sessionInfo.can_turn_off_user_video){
                                let payload = [];
                                otExpObj.state.g_subInfoArr.forEach((subInfo) => {
                                    const dataArray = subInfo.g_stream.connection.data.split(":::")
                                    const obj = {
                                        flag: subInfo.disableVideo, 
                                        userId: parseInt(dataArray[0]),
                                        email: dataArray[3]
                                    }
                                    payload.push(obj);
                                });
                                otExpObj.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, dataSplit[3]);
                            } */
                        } else if (otExpObj.state.gridViewMode === false && otExpObj.state.fakeExpertList.length !== 0) {
                            //TP-1993 -- To handle those use cases where the streamCreated for a Passive expert is received 
                            //a long time after the Max-view of the Primary expert gets drawn
                            const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, isPointerActive} = otExpObj.state;
                            let shape_selected = "";
                            if (isFreeDrawing === true) shape_selected = "freehand";
                            else if (isRectActive === true) shape_selected = "rectangle";
                            else if (isCircleActive === true) shape_selected = "circle";
                            else if (isArrowActive === true) shape_selected = "arrow";
                            else if (isPointerActive === true) shape_selected = "pointer"; //TP-2475

                            otExpObj.state.g_subInfoArr.forEach((subInfo) => {
                                if (subInfo.g_divId === g_maxDivId) {
                                    // T32-463 -- New Multi-expert Signal trigger implementation
                                    const dddArr = subInfo.g_stream.connection.data.split(":::")
                                    if (dddArr[4] === "false")
                                        otExpObj.state.fakeExpertList.forEach((expert) => {
                                            if (expert === dataSplit[3]) {
                                                otExpObj.sendSignalSyncExperts({
                                                    show_grid: false,
                                                    max_view_technician: dddArr[3], 
                                                    annotation_active: otExpObj.state.bAnnotate, 
                                                    annotate_color: otExpObj.state.pencolor,
                                                    shape_selected,
                                                    torch_button_active: otExpObj.state.bTorchClick ? true : false,
                                                    zoom_button_active: (parseInt(otExpObj.state.zoom_level) > 1) ? true : false,
                                                    zoom_level: parseInt(otExpObj.state.zoom_level),
                                                    hand_icon_enabled: (otExpObj.state.showControlRequestDialog === true) ? false : true, //TP-1313
                                                    header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null) ? true : false, //TP-2378
                                                    reason: "show_action"}, 
                                                    expert);  // send the max div tech details    
                                                printConsole("Sending the updated Max-view technician to late streamCreated Passive expert(s)");
                                            }
                                        })
                                    
                                }
                            })
                            let filtered = otExpObj.state.fakeExpertList.filter((value) => { 
                                return value !== dataSplit[3];
                            });  
                            otExpObj.setState({ fakeExpertList: filtered });
                        }
                        //otExpObj.sendSignalYieldMaxDivControls(param, dataSplit[3]);
                        // TP-1590 -- Video if turned off then send the TURN_ON_MAX_VIEW Signal to the newly joining Passive expert
                        // only if Screen-cast (sideBar/Max-view) is ongoing
                        if ((otExpObj.state.expertSSAll || otExpObj.state.expertShareScreenMaxDiv) && otExpObj.props.sessionInfo.customer_can_turn_off_user_video) 
                        {
                            let payload = [];
                            otExpObj.state.g_subInfoArr.forEach((subInfo) => {
                                const dataArray = subInfo.g_stream.connection.data.split(":::")
                                const obj = {
                                        flag: subInfo.disableVideo, 
                                        userId: parseInt(dataArray[0]),
                                        email: dataArray[3]
                                }
                                payload.push(obj);
                            });
                            otExpObj.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, dataSplit[3]);
                        }
                    }
                    //console.log('Expert streamCreated!! ');
                    //console.log(g_expertConnsArr);

                    // Send the signal SYNC_EXPERTS with reason="registered_sync"
                    // to announce to all other experts that this expert is a registered user
                    isGuest && otExpObj.sendSignalSyncExperts({
                        isRegistered: false,
                        reason: "registered_sync"},
                    dataSplit[3]) && printConsole (`Sending the SYNC_EXPERTS signal to ${dataSplit[3]} with isRegistered = false`);

                    // Send the signal SYNC_EXPERTS with reason="registered_sync"
                    // to announce to all other experts that this expert is a registered user
                    !isGuest && otExpObj.sendSignalSyncExperts({
                        isRegistered: true,
                        reason: "registered_sync"},
                    dataSplit[3]) && printConsole (`Sending the SYNC_EXPERTS signal to ${dataSplit[3]} with isRegistered = true`);
                } //else if (event.stream.videoType === 'screen' && otExpObj.state.prepareExpertSS === false && otExpObj.state.showHideMaxDivControl === false) { //dataSplit[4] === 'true' &&
                    // For NS2-91 & NS2-118
                    //this condition is true when any screen type stream is recieved by an expert and techShareScreen flag is turned off
                    // this condition takes care of all the screen share issues when two or more experts are in a session 
                    //return;
                //}
                // Make a unique div id for this stream and create the div elements
                let divId = 'stream_' + event.stream.streamId;

                //var container = document.createElement("div");
                let subscriberBox = document.getElementById(subscriberContainerDivId);
                let divElem = document.createElement('div');
                divElem.setAttribute('id', divId);
                // divElem.classList.add('vdoDiv');
                // for NS2-372
                
                //TP-3201 -- set the profile picture div of Video/audio feed element
                let profileImg = document.createElement('img');
                profileImg.id = divId + "_pic";
                profileImg.src = (typeof(sessionStorage.getItem('my_profile_pic')) !== undefined 
                            && sessionStorage.getItem('my_profile_pic') !== undefined 
                            && sessionStorage.getItem('my_profile_pic') !== null) ? 
                            sessionStorage.getItem('my_profile_pic') : "/profile.png";
                profileImg.classList.add('session-profile-pic', 'logo-profile-pic');
                profileImg.alt = 'profile_logo';

                //set the name div of the video/audio feed element
                let nameDiv = document.createElement('div');
                nameDiv.id = divId + "_name";
                nameDiv.classList.add('position-absolute', 'w-100', 'nameDiv');
                nameDiv.style.color = 'white'; 
                //nameDiv.style.zIndex = '10';
                nameDiv.style.top = "1%";
                //TP-3201 -- append the profile picture image element beside the name
                nameDiv.appendChild(profileImg);
                const participantName = (dataSplit[4] === 'true') ? document.createTextNode("* "+dataSplit[1]+" "+dataSplit[2]) : document.createTextNode(dataSplit[1]+" "+dataSplit[2]);
                nameDiv.appendChild(participantName);
                divElem.appendChild(nameDiv);   
                
                //printConsole(`window innerwidth::: ${window.innerWidth}`);
                // Grid View control panel
                if (dataSplit[4] === 'false' && event.stream.videoType !== "screen") {
                    //Disconnect user button created
                    if (otExpObj.props.sessionInfo.can_disconnect_user === true) {
                        let disconnnectDiv = document.createElement('div');
                        disconnnectDiv.id = "disconnectuser_" + event.stream.streamId;
                        disconnnectDiv.classList.add('position-absolute', 'grid-cross-parent'); // 
                        disconnnectDiv.style.display = "none";
                        let disSpan = document.createElement('span');
                        if (window.innerWidth > 1299)
                            disSpan.classList.add('fa-stack', 'fa-sm', 'grid-cross-btn');                    
                        else
                            disSpan.classList.add('fa-stack', 'fa-xs', 'grid-cross-btn');
                        let disAnchor = document.createElement("a");
                        disAnchor.id = "stopSessionUser_" + event.stream.streamId;
                        //TP-2167 -- to make the tooltip text 
                        // of the Disconnect User button meaningfull
                        const firstName = dataSplit[1];
                        const lastName = dataSplit[2];
                        disAnchor.title = otExpObj.props.i18n._(t`Disconnect ${firstName} ${lastName}`);
                        //disAnchor.title = otExpObj.props.i18n._(t`Disconnect ${dataSplit[1]} ${dataSplit[2]}`);
                        disAnchor.onclick = function (e) {
                            otExpObj.onClickStopSessionUser(divId);
                        }
                        let disCycle = document.createElement('i');
                        disCycle.classList.add('fas', 'fa-circle', 'fa-stack-2x');
                        let disIcon = document.createElement('i');
                        disIcon.classList.add('fas', 'fa-times', 'fa-stack-1x', 'grid-cross-icon');
                        disAnchor.appendChild(disCycle);
                        disAnchor.appendChild(disIcon);
                        disSpan.appendChild(disAnchor);
                        disconnnectDiv.appendChild(disSpan);
                        divElem.appendChild(disconnnectDiv);
                    }
                    // Audio/Video Icons
                    let gridCntlDiv = document.createElement('div');
                    gridCntlDiv.id = "gridviewcontrol_" + event.stream.streamId;
                    gridCntlDiv.classList.add('position-absolute', 'w-100', 'grid-view-controls-parent');
                    gridCntlDiv.style.display = "none";
                    let gridSpan = "";
                    if (otExpObj.props.sessionInfo.torch_enabled === true) {
                        // Torch Icon
                        gridSpan = document.createElement('span');
                        if (window.innerWidth > 1299) 
                            gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-sm');
                        else
                            gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-xs');
                        let torchAnchor = document.createElement("a");
                        torchAnchor.id = "toggleTorch_" + event.stream.streamId;
                        torchAnchor.title = otExpObj.props.i18n._(t`session.flashLight`);
                        torchAnchor.onclick = function (e) {
                            otExpObj.toggleUserTorch(divId);
                        }
                        torchAnchor.style.display = "none";
                        
                        let torchCycle = document.createElement('i');
                        torchCycle.classList.add('fas', 'fa-circle', 'fa-stack-2x', 'red-shadow', 'grid-circle');
                        let torchIcon = document.createElement('i');
                        torchIcon.classList.add('fas', 'fa-bolt', 'fa-stack-1x', 'grid-icon');
                        
                        torchAnchor.appendChild(torchCycle);
                        torchAnchor.appendChild(torchIcon);
                        gridSpan.appendChild(torchAnchor);
                        gridCntlDiv.appendChild(gridSpan);
                    }

                    if (otExpObj.props.sessionInfo.zoom_enabled === true) {
                        // Zoom Icon
                        gridSpan = document.createElement('span');
                        if (window.innerWidth > 1299) 
                            gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-sm');
                        else
                            gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-xs');
                        let zoomAnchor = document.createElement("a");
                        zoomAnchor.id = "rotateZoom_" + event.stream.streamId;
                        zoomAnchor.title = otExpObj.props.i18n._(t`session.zoom`);
                        zoomAnchor.onclick = function (e) {
                            otExpObj.rotateZoomLevel(divId);
                        }
                        zoomAnchor.style.display = "none";
                        let zoomCycle = document.createElement('i');
                        zoomCycle.classList.add('fas', 'fa-circle', 'fa-stack-2x', 'red-shadow', 'grid-circle');
                        let zoomIcon = document.createElement('i');
                        zoomIcon.classList.add('fas', 'fa-binoculars', 'fa-stack-1x', 'grid-icon');
                        
                        zoomAnchor.appendChild(zoomCycle);
                        zoomAnchor.appendChild(zoomIcon);
                        gridSpan.appendChild(zoomAnchor);
                        gridCntlDiv.appendChild(gridSpan);
                    }

                    // Mike Icon
                    gridSpan = document.createElement('span');
                    if (window.innerWidth > 1299) 
                        gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-sm');
                    else
                        gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-xs');
                    let mikeAnchor = document.createElement("a");
                    mikeAnchor.id = "muteMikeEach_" + event.stream.streamId;
                    mikeAnchor.title = otExpObj.props.i18n._(t`session.muteMike`);
                    mikeAnchor.onclick = function (e) {
                        otExpObj.toggleUserMike(divId);
                    }
                    let mikeCycle = document.createElement('i');
                    mikeCycle.classList.add('fas', 'fa-circle', 'fa-stack-2x', 'red-shadow', 'grid-circle');
                    let mikeIcon = document.createElement('i');
                    mikeIcon.classList.add('fas', 'fa-microphone', 'fa-stack-1x', 'grid-icon');
                    
                    mikeAnchor.appendChild(mikeCycle);
                    mikeAnchor.appendChild(mikeIcon);
                    gridSpan.appendChild(mikeAnchor);
                    gridCntlDiv.appendChild(gridSpan);
                    
                    //Speaker Icon
                    gridSpan = document.createElement('span');
                    if (window.innerWidth > 1299) 
                        gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-sm');
                    else
                        gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-xs');
                    let speakerAnchor = document.createElement("a");
                    speakerAnchor.id = "toggleAudio_" + event.stream.streamId;
                    speakerAnchor.title = otExpObj.props.i18n._(t`session.toggleAudio`);
                    speakerAnchor.onclick = function (e) {
                        otExpObj.toggleUserSpeaker(divId);
                    }
                    let speakerCycle = document.createElement('i');
                    speakerCycle.classList.add('fas', 'fa-circle', 'fa-stack-2x', 'red-shadow', 'grid-circle');
                    let speakerIcon = document.createElement('i');
                    speakerIcon.classList.add('fas', 'fa-volume-up', 'fa-stack-1x', 'grid-icon');
                    
                    speakerAnchor.appendChild(speakerCycle);
                    speakerAnchor.appendChild(speakerIcon);
                    gridSpan.appendChild(speakerAnchor);
                    gridCntlDiv.appendChild(gridSpan);
                    
                    //Video turn off button
                    if (otExpObj.props.sessionInfo.can_turn_off_user_video === true) {
                        gridSpan = document.createElement('span');
                        if (window.innerWidth > 1299) 
                            gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-sm');
                        else
                            gridSpan.classList.add('fa-stack', 'maxdivcontrol-spacing', 'grid-view-controls', 'fa-xs');
                        let videoAnchor = document.createElement("a");
                        videoAnchor.id = "toggleVideo_" + event.stream.streamId;
                        videoAnchor.title = otExpObj.props.i18n._(t`session.toggleVideo`);
                        videoAnchor.onclick = function (e) {
                            otExpObj.toggleUserVideo(divId);
                        }
                        videoAnchor.style.display = "none";
                        let videoCycle = document.createElement('i');
                        videoCycle.classList.add('fas', 'fa-circle', 'fa-stack-2x', 'red-shadow', 'grid-circle');
                        let videoIcon = document.createElement('i');
                        videoIcon.classList.add('fas', 'fa-video', 'fa-stack-1x', 'font-awesome-green', 'grid-icon');
                        
                        videoAnchor.appendChild(videoCycle);
                        videoAnchor.appendChild(videoIcon);
                        gridSpan.appendChild(videoAnchor);
                        gridCntlDiv.appendChild(gridSpan);
                    }                   
                    
                    divElem.appendChild(gridCntlDiv);     
                }

                if (otExpObj.props.sessionInfo.zoom_enabled === true) {
                    let zoonLevelDiv = document.createElement('div');
                    zoonLevelDiv.id = "zoomLevelDiv_" + event.stream.streamId;
                    zoonLevelDiv.classList.add('position-absolute', 'zoom-level'); // 
                    zoonLevelDiv.style.display = "none";
                    let levelSpan = document.createElement('span');
                        
                    levelSpan.classList.add('zoom-text');
                    levelSpan.id = "zoomLevel_" + event.stream.streamId;
                    levelSpan.innerHTML = "1x";
                    zoonLevelDiv.appendChild(levelSpan);
                    divElem.appendChild(zoonLevelDiv);
                }

                //if (otExpObj.state.swapTechCamera === false)
                    otExpObj.props.updateRCStatus(false);

                // close the remote capture/swap camera countdown timer box
                otExpObj.closeStreamDestroyedDialog();
                //TP-3014
                if(subscriberBox.children.length < 1) {
                    subscriberBox.appendChild(divElem);// append it main div instead of samll div
                } else if (!event.stream.hasVideo && dataSplit[4] === 'true') {
                    subscriberBox.insertBefore(divElem, subscriberBox.children[0]); //.nextSibling
                } else {
                    subscriberBox.appendChild(divElem);// append it main div instead of samll div
                }
                
                // subscribe to this session and add this stream and subscriber to the list
                let subscriberProperties = { insertMode: 'append', width: '100%', height: '100%', subscribeToVideo: true, subscribeToAudio: true, fitMode: (event.stream.videoType === "screen") ? 'contain' : 'cover' /**TP-2265*/};
                if (!event.stream.hasVideo && dataSplit[4] === 'true') {
                    g_subscriber = session.subscribe(event.stream, divId, { insertMode: 'append', width: '0%', height: '0%', subscribeToVideo: false, subscribeToAudio: true });
                } else {
                    g_subscriber = session.subscribe(event.stream, divId, subscriberProperties); //'subscriberContainer'); //{insertDefaultUI: false});
                }
                g_subscriber.on('videoElementCreated', function(event) {
                    printConsole(`video element created --> `);
                    //console.log(event.target.stream);
                    //console.log("prepareExpertSS: ", otExpObj.state.prepareExpertSS, "is Primary expert", otExpObj.state.showHideMaxDivControl );
                    //printConsole(event);
                    const videoElem = event.element;
                    //printConsole(`video element`);
                    //printConsole(videoElem)
                    if (dataSplit[4] === 'false') {
                        //const videoElem = divElem.getElementsByClassName("OT_video-element")
                        if (videoElem !== null) {
                            videoElem.onclick = function (e) {
                                otExpObj.maximizeDiv(e, {divId});
                            }
                            videoElem.classList.add('hover-grid');
                        }
                    } else if (dataSplit[4] === 'true' && event.target.stream.videoType !== "screen"){
                        const widgetElem = divElem.getElementsByClassName("OT_widget-container")
                        if (widgetElem !== null) {
                            widgetElem[0].style.width = "0%";
                            widgetElem[0].style.height = "0%";
                        }
                        const audioLevelElem = divElem.getElementsByClassName("OT_audio-level-meter");
                        if (audioLevelElem !== null) {
                            audioLevelElem[0].style.display = "none";
                        }
                    } 
                    //TP-1881 -- New User joins while recording is ongoing
                    if (otExpObj.state.g_subInfoArr.length > 0 && otExpObj.state.startRecording === true) {
                        otExpObj.stopAndContinueRecording();// Trigger the stop and start of audio recorder
                    }
                })
                // T32-291 -- Listener for When the Video gets turned on after it was disabled
                // for a stream that the subscriber is subscribing to
                g_subscriber.on('videoEnabled', function(event) {
                    printConsole(`video enabled for reason --> ${event.reason}`);
                    let userVideoCount = 0;
                    //  -- To find the count of Remote users whose Video is still enabled
                    g_subInfoArr.forEach(subInfo => {
                        let divId = 'stream_' + event.target.streamId;
                        if (divId === subInfo.g_divId && g_expertConnsArr.includes(subInfo.g_stream.connection) === false) {
                            subInfo.disableVideo = false; 
                            if (event.reason === "publishVideo") subInfo.g_subscriber.subscribeToVideo(true); //TP-1996
                            //set the changed session Joinee in the array
                            otExpObj.updateEachSessionJoinee(subInfo);
                            //TP-3201
                            const profile_pic = document.getElementById(divId + "_pic");
                            if (profile_pic !== undefined && profile_pic !== null) {
                                profile_pic.classList.remove("d-none");
                            }
                        }
                        if (subInfo.g_stream.hasVideo === true && subInfo.disableVideo === false)//TP-1547                   
                            userVideoCount++;
                    })
                    // TP-241 -- When Video gets turned on for atleast one user, then on Max-view of the 
                    // Primary & Passive experts show the Max-view control buttons/indicators
                    printConsole(`userVideoCount ?? ${userVideoCount}`);
                    otExpObj.setState({ g_subInfoArr, userVideoCount }, () => {
                        if (userVideoCount > 0 && otExpObj.state.gridViewMode === false) {
                            otExpObj.setState({ showHideDivIconAudioOnly: false })
                        }
                        //TP-1547 & TP-2029
                        if (userVideoCount === 1 && event.reason === "publishVideo" && otExpObj.state.showHideMaxDivControl === true) {
                            const newParticipant = g_subInfoArr.find((subInfo) => 
                                (subInfo.g_divId === divId && subInfo.g_stream.hasVideo === true && subInfo.disableVideo === false)
                            )
                            printConsole("Draw the Max-view when only one user has Video enabled");
                            otExpObj.maximizeDiv(null, {divId: newParticipant.g_divId});
                        }
                        //TP-1547
                        otExpObj.setState({ isParticipantChanged: true }, () => {
                            otExpObj.setState({ isParticipantChanged: false });
                        })
                    });
                    if (event.reason === "publishVideo" && otExpObj.state.gridViewMode === true && otExpObj.state.prepareExpertSS === false) {
                        otExpObj.drawSubscriberUI(window.innerHeight, window.innerWidth, null);
                    } 
                    // TP-2029 
                    if (event.reason === "publishVideo" && otExpObj.state.showHideMaxDivControl === true && g_expertConnsArr.length > 0) {
                        let payload = [];
                        g_subInfoArr.forEach(subInfo => {
                            const dataArray = subInfo.g_stream.connection.data.split(":::")
                            const obj = {
                                flag: subInfo.disableVideo, 
                                userId: parseInt(dataArray[0]),
                                email: dataArray[3]
                            }
                            payload.push(obj);
                        })
                        //console.log("Payload is....", payload);
                        // Send the signal to other experts to keep Passive experts in sync with Main expert
                        if (g_expertConnsArr.length > 0) {
                            g_expertConnsArr.forEach((connectionObj) => {
                                const toArray = connectionObj.data.split(":::");
                                const toObject = toArray[3];
                                otExpObj.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, toObject);
                            });
                        }
                    }                
                })
                // T32-291
                g_subscriber.on('videoDisabled', function(event) {
                    printConsole(`video disabled for reason --> ${event.reason}`);
                    //printConsole(event.target.streamId);
                    let userVideoCount = 0;
                    //  -- To find the count of Remote users whose Video is still enabled
                    g_subInfoArr.forEach(subInfo => {
                        let divId = 'stream_' + event.target.streamId;
                        if (divId === subInfo.g_divId && g_expertConnsArr.includes(subInfo.g_stream.connection) === false) {
                            subInfo.disableVideo = true; 
                            const url = window.location.href;
                            //TP-3201
                            let imgURL = "";
                            if (typeof(sessionStorage.getItem('my_profile_pic')) !== undefined && sessionStorage.getItem('my_profile_pic') !== undefined && sessionStorage.getItem('my_profile_pic') !== null) {
                                imgURL = sessionStorage.getItem('my_profile_pic');
                            } else
                                imgURL = (url.includes("localhost:3000")) ? 'http://localhost:3000/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png' : 'https://'+window._env_.REACT_APP_API_SOCKET_URL+'/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png';
                            subInfo.g_subscriber.setStyle('backgroundImageURI', imgURL);
                            //set the changed session Joinee in the array
                            otExpObj.updateEachSessionJoinee(subInfo);
                            //TP-3201
                            const profile_pic = document.getElementById(divId + "_pic");
                            if (profile_pic !== undefined && profile_pic !== null) {
                                profile_pic.classList.add("d-none");
                            }
                        }
                        if (g_expertConnsArr.includes(subInfo.g_stream.connection) === false && subInfo.disableVideo === false)                       
                            userVideoCount++;
                    })
                    otExpObj.setState({ g_subInfoArr, userVideoCount });
                })
                g_subscriber.setStyle({ buttonDisplayMode: 'off' });
                g_subscriber.setStyle({ nameDisplayMode: 'off' }); // Turning off the OpenTok Name/Title Div

                g_stream = event.stream;
                let subscriberInfo = {
                    g_subscriber: g_subscriber,
                    g_stream: g_stream,
                    g_divId: divId,
                    canRC: true,
                    zoom_enabled: true, //TP-207
                    torch_enabled: true, // TP-207
                    screenshare_enabled: true, //TP-207
                    techSS_enabled: true, // TP-207
                    zoomLevel: 1,       // TP-207
                    torchActive: false, //TP-207
                    disableAudio: (otExpObj.state.bMuteAllAudio === true) ? true : false,
                    disableVideo: (dataSplit[3] === otExpObj.state.swapTechEmail && otExpObj.state.swapTechCamera === true && otExpObj.state.swapTechDisableVideo === true)? true : false, //T32-127
                    disableMike: (otExpObj.state.bMuteMyMike === true) ? true : false,
                    disableRemoteAudio: false, // T32-413
                    isRegistered: true //TP-552
                };                

                if (dataSplit[4] === "false" && event.stream.videoType !== "screen") {
                    // TP-359 -- New Socket signal to be sent to all technicians 
                    // announcing the current Primary expert 
                    otExpObj.sendSignalCurrentPrimaryExpert(dataSplit[3]);
                }
                if (dataSplit[4] === "false" && event.stream.connection.data !== otExpObj.state.sRCTechDetails && event.stream.videoType !== 'screen' && g_subInfoArr.length > 0) {
                    if (g_maxDivId !== null && otExpObj.state.bAnnotate === false && otExpObj.state.bRemoteCapture === false /**TP-3867*/) {
                        //TP-2810
                        if (document.getElementById('shapeselector') && document.getElementById('shapeselector') !== null)
                            subscriberBox.appendChild(document.getElementById('shapeselector'));
                        subscriberBox.appendChild(document.getElementById('controlpanel'));
                        //subscriberBox.appendChild(document.getElementById('actionRequestControls'));
                        //document.getElementById(g_maxDivId).style.display = 'none';
                    }
                    // find the corresponding old live stream and process
                    g_subInfoArr = otExpObj.switchOldCameraFeed2New(event.stream.connection.connectionId);
                    //console.log(g_subInfoArr);   
                    otExpObj.props.updateRCStatus(false);                 
                }
                else if (dataSplit[4] === "false" && dataSplit[3] === otExpObj.state.swapTechEmail && otExpObj.state.swapTechCamera === true && otExpObj.state.swapTechDisableVideo === true) {
                    //T32-127
                    // Handling the case where the camera swap has happened for the Max-view technician
                    // New camera stream has come to the expert and its Video is turned off from before
                    subscriberInfo.g_subscriber.subscribeToVideo(false);
                }

                // NS2-436
                // Process the Mute Expert Mike signal for a new joining expert
                if (dataSplit[4] === "true" && otExpObj.state.muteExpertMikeArr.length > 0) {
                    otExpObj.state.muteExpertMikeArr.forEach(obj => {
                        if (obj.id === dataSplit[0]) {
                            subscriberInfo.g_subscriber.subscribeToAudio(obj.data);
                        }
                    });
                }

                // TP-552 -- Process the Expert's "isRegistered" flag for a newly joining expert user
                if (dataSplit[4] === "true" && otExpObj.state.registeredArr.length > 0) {
                    otExpObj.state.registeredArr.forEach(obj => {
                        if (obj.userId === parseInt(dataSplit[0])) {
                            printConsole(`Expert joined is guest? ${obj.isRegistered}`);
                            subscriberInfo.isRegistered = obj.isRegistered;
                            for (let ii = 0; ii<= g_expertConnsArr.length; ii++) {
                                if(event.stream.connection.data === g_expertConnsArr[ii].data){
                                    g_expertConnsArr[ii].isRegistered = obj.isRegistered;
                                    break;
                                }
                            }
                        }
                    })
                }

                //TP-263 -- Process the user Info "IM" Signal payload for newly joining technician
                if (dataSplit[4] === "false" && otExpObj.state.userInfoArr.length > 0) {
                    otExpObj.state.userInfoArr.forEach(obj => {
                        if (obj.id === parseInt(dataSplit[0])) {
                            printConsole("Technician is in session");
                            printConsole(dataSplit[3]);
                            subscriberInfo.zoom_enabled = (obj.data.zoom_enabled && otExpObj.props.sessionInfo.zoom_enabled) ? obj.data.zoom_enabled : false;
                            subscriberInfo.torch_enabled = (obj.data.torch_enabled && otExpObj.props.sessionInfo.torch_enabled) ? obj.data.torch_enabled : false;
                            subscriberInfo.screenshare_enabled = (obj.data.screenshare_enabled) ? obj.data.screenshare_enabled : true;
                            subscriberInfo.techSS_enabled = (obj.data.techSS_enabled) ? obj.data.techSS_enabled : false; 
                        }

                    })
                }
                // process special for expert-to-expert screenshare stream being created
                if (event.stream.videoType === 'screen' && otExpObj.state.showHideMaxDivControl === false) { //otExpObj.state.prepareExpertSS === true && 
                    if (g_maxDivId !== null) {
                        //TP-2810
                        if (document.getElementById('shapeselector') && document.getElementById('shapeselector') !== null)
                            subscriberBox.appendChild(document.getElementById('shapeselector'));
                        subscriberBox.appendChild(document.getElementById('controlpanel'));
                        //subscriberBox.appendChild(document.getElementById('actionRequestControls'));
                        //document.getElementById(g_maxDivId).style.display = 'none';
                    }
                    maxDiv = divId;
                    //TP-2810
                    if (document.getElementById('shapeselector') && document.getElementById('shapeselector') !== null)
                        divElem.appendChild(document.getElementById('shapeselector'));
                    divElem.appendChild(document.getElementById('controlpanel'));
                    //divElem.appendChild(document.getElementById('actionRequestControls'));
                    // add to subscriber array
                    g_subInfoArr.push(subscriberInfo);
                }
                // process special if its a screenshare stream being created
                else if (event.stream.videoType === 'screen') {
                    if (g_maxDivId !== null) {
                        //TP-2810
                        if (document.getElementById('shapeselector') && document.getElementById('shapeselector') !== null)
                            subscriberBox.appendChild(document.getElementById('shapeselector'));
                        subscriberBox.appendChild(document.getElementById('controlpanel'));
                        //subscriberBox.appendChild(document.getElementById('actionRequestControls'));
                        document.getElementById(g_maxDivId).style.display = 'none';
                    }
                    maxDiv = divId;
                    //TP-2810
                    if (document.getElementById('shapeselector') && document.getElementById('shapeselector') !== null)
                        divElem.appendChild(document.getElementById('shapeselector'));
                    divElem.appendChild(document.getElementById('controlpanel'));
                    //divElem.appendChild(document.getElementById('actionRequestControls'));
                    // find the corresponding live stream and process
                    otExpObj.switchExpertLive2Screen(event.stream.connection.connectionId, subscriberInfo);
                }
                else { 
                    // NS2-502
                    // If a technician joins the session 
                    // and there are at least two participants in the session already
                    if (dataSplit[4] === 'false' && g_subInfoArr.length > 1) { // otExpObj.state.showHideMaxDivControl &&
                        // When at least one secondary expert is there in session
                        if (g_expertConnsArr.length > 0) {
                            // add to subscriber array right after the max-div participant
                            g_subInfoArr.splice(1, 0, subscriberInfo);

                        } else {
                            // add to subscriber array
                            g_subInfoArr.push(subscriberInfo);
                        }
                    } else if (dataSplit[4] === 'true' && g_subInfoArr.length > 1) {
                        // add to subscriber array
                        g_subInfoArr.push(subscriberInfo);
                    } else {
                        // add to subscriber array
                        g_subInfoArr.push(subscriberInfo);
                    }
                }
                // document.getElementById('small-divs-container').style.display = 'inline';// not being used for now
                if ((primary_expert_id === 0 || primary_expert_id === null || primary_expert_id === undefined) && g_subInfoArr.length > 0 && g_expertConnsArr.length === 0) {
                    otExpObj.props.setPrimaryExpertIDApi(); //NS2-490
                    otExpObj.setState({ currentPrimaryExpert: otExpObj.props.loggedInUserId }); //NS2-486
                    otExpObj.setState({ showHideMaxDivControl: true });
                    otExpObj.props.updateShowHideHeaderActionButtons(true);
                } 
                // 10 seconds after the expert joins the session
                // check if there are any other experts in that session
                // if not then make this expert as the Primary expert
                // Also send the MAX_VIEW signal to the technician from
                // this expert to show all the buttons correctly
                this.waitTimer = setTimeout(() => {
                    //printConsole(g_expertConnsArr.length);
                    if (primary_expert_id !== null && primary_expert_id !== loggedInUserId && g_expertConnsArr.length === 0) {
                        otExpObj.props.setPrimaryExpertIDApi(); //NS2-490
                        otExpObj.setState({
                            showHideMaxDivControl: true,
                            currentPrimaryExpert: loggedInUserId
                        }, () => {
                            if (this.props && this.props.isRespondToCall === false /**TP-2462*/)
                                otExpObj.startMissedCallNotifyTimer(); // TP-1129
                            // TP-359 -- New Socket signal to be sent to all technicians 
                            // announcing the current Primary expert 
                            otExpObj.sendSignalCurrentPrimaryExpert();
                            otExpObj.setState({isParticipantChanged : true}, () => {
                                otExpObj.setState({isParticipantChanged : false});
                            });// to change the Side Bar button state
                        });
                        if (otExpObj.state.gridViewMode === true){
                            for (let ii=0; ii< g_subInfoArr.length; ii++) {
                                //show the disconnect user button on top of the video feed elements
                                let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                                if (disconnectuser !== undefined && disconnectuser !== null) {
                                    disconnectuser.style.display = "block";                    
                                }
                
                                //show the gridcontrol icons on top of video element
                                let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                                if (gridcontrol !== undefined && gridcontrol !== null)
                                    gridcontrol.style.display = "block";
                                
                                // Show the Video Turn off Icon on Primary expert's Grid View
                                let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);
                                if (videoIcon !== undefined && videoIcon !== null) 
                                    videoIcon.style.display = "block";                                                                    
                            }
                        }
                        // T32-463 -- New Multi-expert Signal trigger implementation
                        otExpObj.sendSignalSyncExperts({
                            isPrimary: true,
                            expertId: otExpObj.props.user.id,
                            email: otExpObj.props.user.email,
                            firstname: otExpObj.props.user.firstname,
                            lastname: otExpObj.props.user.lastname,
                            joinTimeStamp: parseInt(otExpObj.state.loggedinUserTimeStamp),
                            reason: "primary_sync"
                            },
                        null);
                        otExpObj.props.updateShowHideHeaderActionButtons(true);
                        if (otExpObj.state.gridViewMode === false){
                            otExpObj.showHideRcForTech(true);
                        }
                    }
                    clearTimeout(this.waitTimer);
                }, 10000);
                if (g_subInfoArr.length === 1) {
                    maxDiv = divId;
                    let tmp = g_subInfoArr[0].g_stream.connection.data;
                    let tmpSplit = tmp.split(':::');
                    if (tmpSplit[4] === 'true' && g_subInfoArr[0].g_stream.videoType !== "screen" && g_subInfoArr[0].g_stream.hasVideo === false) {
                        otExpObj.setState({ showHideDivIconAudioOnly: true })
                    }
                    // if there is one tech then enable the rc button
                    // and the streamcreated is not of type 'screen'
                    // NS2-96
                    if (event.stream.videoType !== "screen") {
                        otExpObj.props.showScreenShareHeaderButton(true); // for NS2-282
                        otExpObj.setState({
                            bHideExpertSSButton: true,
                            bHideTechnicianSSButton: true,
                            bHideZoomButton: true,
                            bHideTorchButton: true,
                            maxDivTechType: "WEB"
                            /* bHideAnnotateColour: true,
                            bHideAnnotateShapes: true */
                        })
                        otExpObj.showHideRcForTech(true);
                    }
                } else if (g_subInfoArr.length > 1) {
                    //NS2-371
                    // If the maxdiv user is an expert and not a technician
                    // make the maxdiv switch to the next technician
                    // only for the main Expert
                    if (otExpObj.state.showHideMaxDivControl && dataSplit[4] === 'false') {
                        g_subInfoArr.forEach((subInfo, index) => {
                            if (subInfo.g_divId === g_maxDivId && subInfo.g_stream.hasVideo === false) {
                                // NS2-502
                                // add to subscriber array at the end of the array
                                g_subInfoArr = array_move(g_subInfoArr, index, g_subInfoArr.length - 1);
                                maxDiv = divId;
                            }
                        })
                    } else if (otExpObj.state.newMaxViewUser !== null && otExpObj.state.showHideMaxDivControl === false && dataSplit[4] === 'false') {
                        g_subInfoArr.forEach((subInfo, index) => {
                            const dataNewArr = subInfo.g_stream.connection.data.split(":::");
                            if (otExpObj.state.newMaxViewUser === dataNewArr[3] && dataNewArr[4] === "false") {
                                printConsole("The Max-view user is an expert for Passive expert. So syncing the Passive expert's screen with the Primary expert");
                                // NS2-502
                                // add to subscriber array at the end of the array
                                g_subInfoArr = array_move(g_subInfoArr, index, g_subInfoArr.length - 1);
                                maxDiv = subInfo.g_divId;
                                otExpObj.setState({newMaxViewUser: null});
                            }
                        })
                    }
                }
                otExpObj.setState({g_subInfoArr: g_subInfoArr}, () => {
                    if (g_subInfoArr.length > 0 && otExpObj.state.expertWaitTimer > 0) {
                        otExpObj.stopExpertCounter();                        
                    }
                    otExpObj.setOnlineUsers();
                    let userVideoCount = 0, userAudioEnabledCount = 0;
                    // T32-413 -- To find the count of Remote users who havn't
                    // Muted their own mikes
                    g_subInfoArr.forEach(subInfo => {
                        if (g_expertConnsArr.includes(subInfo.g_stream.connection) === false && subInfo.disableVideo === false) 
                            userVideoCount++;
                        if (subInfo.disableRemoteAudio === false)
                            userAudioEnabledCount++;
                    })
                    otExpObj.setState({ userVideoCount, userAudioEnabledCount }); //set the users with Video & Remote Audio count   
                    if (userAudioEnabledCount > 0 && otExpObj.state.disabledMutedAll === true) {                
                        otExpObj.setState({ disabledMutedAll: false });
                    } else if (userAudioEnabledCount === 0) {
                        otExpObj.setState({ disabledMutedAll: true });
                    }
                    // its a new stream and not a screen share div
                    if (event.stream.videoType !== 'screen') {
                        // add the joinees name
                        otExpObj.addSessionJoinees(g_stream, subscriberInfo);                        
                    }
                    //otExpObj.setState({isParticipantChanged : true}); // set the Side bar Silhouette Icon's states
                    //otExpObj.setState({isParticipantChanged : false});
                }); 
                
                // Close the new camera feed is loading only if the Max-div/Side Bar features aren't active
                // If the buttons are active then after handling of the button features then Loader is closed
                if (otExpObj.state.showHideMaxDivControl && otExpObj.isOngoingPrimaryExpertOperations() === false)
                    otExpObj.setState({ isLoading: false }); 
                else if (!otExpObj.state.showHideMaxDivControl && otExpObj.isOngoingPassiveExpertFeatures() === false)
                    otExpObj.setState({ isLoading: false }); 
                //Close the Camera Swap Dialog Box now
                otExpObj.closeCameraSwapDialog();
                // mute technician if already muted
                if (otExpObj.state.bMuteAllAudio) {
                    const speakerBtndiv = document.getElementById('toggleAudio_'+event.stream.streamId);
                    //change the mute Speaker icons on top of video element                            
                    if (speakerBtndiv !== undefined && speakerBtndiv !== null) {
                        let muteBtn = speakerBtndiv.getElementsByClassName("grid-icon");
                        muteBtn[0].classList.remove("fa-volume-up");
                        muteBtn[0].classList.add("fa-volume-mute");
                        muteBtn[0].style.color = "red"
                    }
                    subscriberInfo.g_subscriber.subscribeToAudio(false); //.setAudioVolume(0); - //audio off
                }
                // check the expert mute mike status and do as required
                const muteBtndiv = document.getElementById('muteMikeEach_'+event.stream.streamId)
                if (otExpObj.state.bMuteMyMike === true){
                    //change the mute Mike icons on top of video element                            
                    if (muteBtndiv !== undefined && muteBtndiv !== null) {
                        let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                        muteBtn[0].classList.remove("fa-microphone");
                        muteBtn[0].classList.add("fa-microphone-slash");
                        muteBtn[0].style.color = "red"
                    }
                } 
                otExpObj.muteExpertMikeForMaxDiv(!otExpObj.state.bMuteMyMike, event.stream.connection);
                //otExpObj.queryRCSupport(event.stream.connection);
                //console.log('g_subInfoArr: ', g_subInfoArr);
                propsSetJoineeCount(g_subInfoArr.length);
                //console.log(otExpObj.state.swapTechCamera);
                // if this is a reconnect of the Swapped camera mode technician & not screenshare
                if (dataSplit[3] === otExpObj.state.swapTechEmail && otExpObj.state.swapTechCamera === true  && g_subInfoArr.length > 0){
                    otExpObj.setState({ swapTechCamera: false, confirmSwapCamera: false, swapTechEmail: '', swapTechDisableVideo: false }, () => {
                        //if (otExpObj.state.showHideMaxDivControl)
                        maxDiv = divId;                        
                        if (otExpObj.state.showFileViewer) {
                            otExpObj.onCloseFileViewer(); 
                            otExpObj.setState({ isOngoingMaxDivFeatures: false })
                        }
                        //this.setState({ bShareAsset: false });
                    });
                } /* else if ( dataSplit[3] === otExpObj.state.swapTechEmail && otExpObj.state.swapTechCamera === true  && g_subInfoArr.length > 0) {
                    otExpObj.setState({ swapTechCamera: false, confirmSwapCamera: false, swapTechEmail: '' });
                } */
                // if this is a reconnect of a RC mode technician and not due to screenshare
                else if (event.stream.connection.data === otExpObj.state.sRCTechDetails && g_subInfoArr.length > 1) {
                    otExpObj.setState({ bRemoteCapture: true, isOngoingMaxDivFeatures: true }, () => {
                        if (otExpObj.state.showHideMaxDivControl)
                        maxDiv = divId;
                    });
                }
                else if (dataSplit[4] === "false" && otExpObj.state.userVideoCount === 1) { // T32-291
                    //T32-291
                    // To handle the cases where a new technician joins a session, where all Videos are turned off
                    // so userVideoCount = 0
                    maxDiv = divId;
                }
                //console.log(event.stream.connection.data)
                //console.log(otExpObj.state.sRCTechDetails);
                // To allow for the reconnect of an RC mode technician (Android)
                // MB2-501
                if (otExpObj.isOngoingMaxDivFeaturesExceptZoomAndTorch() === true && otExpObj.state.gridViewMode === false && event.stream.connection.data !== otExpObj.state.sRCTechDetails && event.stream.connection.data !== otExpObj.state.swapCamTechData && event.stream.videoType !== "screen" && otExpObj.state.showHideMaxDivControl) {
                    /* document.getElementById(divId).className = "";
                    document.getElementById(divId).style.display = 'none';  */ 
                    otExpObj.maximizeDiv(null, {divId: g_maxDivId}, true);                  
                    otExpObj.triggerMessage(event.stream.connection.data, "connect");
                } else if (otExpObj.state.showHideMaxDivControl === false && otExpObj.state.gridViewMode === false && event.stream.connection.data !== otExpObj.state.sRCTechDetails && event.stream.connection.data !== otExpObj.state.swapCamTechData && event.stream.videoType!== "screen" && otExpObj.isOngoingPassiveExpertFeatures() === true) {
                    document.getElementById(divId).className = "";
                    document.getElementById(divId).style.display = 'none'; 
                    //otExpObj.maximizeDiv(null, {divId: g_maxDivId}, true);                   
                    otExpObj.triggerMessage(event.stream.connection.data, "connect");
                }
                else {
                    //console.log(maxDiv);
                    //console.log(otExpObj.state.prepareExpertSS);
                    //console.log(event.stream.videoType);
                    //console.log("Grid View Mode: ",otExpObj.state.gridViewMode);
                    //console.log(otExpObj.state.showHideMaxDivControl);
                    if ((otExpObj.state.prepareExpertSS === true && event.stream.videoType === "screen" )|| (otExpObj.state.showHideMaxDivControl === false && event.stream.videoType === "screen")){
                        otExpObj.drawScreenShareUI( {divId: maxDiv } );
                    } else if (otExpObj.state.prepareExpertSS === true && event.stream.videoType !== "screen"){
                        otExpObj.drawScreenShareUI( {divId: g_maxDivId } );
                    }
                    else if (g_subInfoArr.length === 1 || otExpObj.state.gridViewMode === false) {
                        if (maxDiv !== null) {
                            if (dataSplit[4] === 'true') 
                                otExpObj.maximizeDiv(null, {divId: maxDiv}, true);
                            else
                                otExpObj.maximizeDiv(null, {divId: maxDiv});
                        }
                        else {
                            /* maxDiv = g_maxDivId;
                            g_maxDivId = null;
                            if (dataSplit[4] === 'true') 
                                otExpObj.maximizeDiv(null, {divId: maxDiv}, true);
                            else
                                otExpObj.maximizeDiv(null, {divId: maxDiv}); */
                            otExpObj.drawNonMaxDivTech({divId});
                        }
                    } else {
                        otExpObj.drawSubscriberUI(window.innerHeight, window.innerWidth, null);
                    }
                }

                // To trigger the Start Screen share signals to the newly joining technician into the session
                // When Screen Cast from Side Bar is active
                if (dataSplit[4] === "false" /* && subscriberInfo.disableVideo === false */ && event.stream.videoType !== 'screen' && otExpObj.state.expertSSAll === true && otExpObj.state.showHideMaxDivControl) {
                    otExpObj.sendSignalPrepareExpertSS(true, dataSplit[3]);
                    otExpObj.sendSignalExpertSS(true, dataSplit[3]);
                }
                // T32-624 -- To trigger the Start Share Asset signals to the newly joining technician into the session
                // When File share from Side Bar is active
                if (dataSplit[4] === "false" && (otExpObj.state.fileData !== null || otExpObj.state.assetsArray.length > 0) && otExpObj.state.shareOnDiv === null && event.stream.videoType !== 'screen' && otExpObj.state.bShareAsset === true && otExpObj.state.showHideMaxDivControl) {
                    if (otExpObj.state.fileData !== null)
                        otExpObj.sessionSignal.sendSignalShareArtifacts(otExpObj.state.fileData, dataSplit[3]);
                    else {
                        otExpObj.state.assetsArray.forEach(asset => {
                            otExpObj.sessionSignal.sendSignalShareArtifacts(asset, dataSplit[3]);
                        })
                    }

                    // If 3D file is shared then resend the last rotation change
                    const [closeIcon] = document.getElementsByClassName('asset-3d');
                    if (closeIcon && otExpObj.state.rotationArray !== null && otExpObj.state.rotationArray !== '') {
                        otExpObj.rotationTimer = setTimeout(() => {
                            otExpObj.sessionSignal.sendSignal3DRotation(otExpObj.state.rotationArray, dataSplit[3]);
                            clearTimeout(otExpObj.rotationTimer);
                        }, 3000);
                    } else if (otExpObj.state.fileOpacity) {
                        // If not 3D file share ongoing then send the current Opacity value to 
                        // newly joining technician user
                        //otExpObj.sessionSignal.sendSignalOpacity(otExpObj.state.fileOpacity, dataSplit[3]); //TP-841
                    }
                }

                /* diagonastics(otExpObj.authService, {
                    action: `open tok streamCreated`,
                    next_step: 'stream will go on until stopped',
                    data: event.stream.connection.data,
                    error: 'none'
                }) */
            })
            session.connect(this.props.sessionInfo.token, function (error) {
                if (!error) {
                    //publish the expert's audio
                    const streamName = otExpObj.props.sessionInfo.firstname + ' ' + otExpObj.props.sessionInfo.lastname;
                    var subPublisher = OT.initPublisher('myaudiopublisher', {
                        /* width: '100%',
                        height: '100%', */
                        name: streamName,
                        videoSource: null,
                        insertMode: 'append',
                        publishAudio: true,
                        publishVideo: false
                    }, function (error) {
                        if (error) {
                            printConsole(`There was an error initializing the audio publisher: ${error.name},
                                ${error.message}`);
                            return;
                        }
                        session.publish(subPublisher,
                            function (error) {
                                if (error) {
                                    printConsole(`There was an error publishing the audio: ${error.name}, ${error.message}`);
                                } else {
                                    otExpObj.props.changeStartStop();
                                    g_publisher = subPublisher;
                                    //g_camera_publisher = subPublisher;
                                }
                            });
                    });
                    subPublisher.on('videoElementCreated', function (event) {
                        //console.log('videoelementcreated event fired', event.element.parentElement.parentElement);
                        event.element.parentElement.parentElement.style.display = 'none';
                        //propsSetJoineeCount(g_subInfoArr.length + 1);
                    });

                }//if(!error)
                else if (error.code === 2001) {
                    otExpObj.setState({ isLoading: false });
                    const message = "Failed to establish connection";
                    otExpObj.setState({ flashMsgText: message, showFlashMessage: true, flashLeft: "", flashTop: "" });
                    printConsole(error.message); 
                    otExpObj.waitTimer = setTimeout(() => {
                        otExpObj.triggerStopSession();
                        otExpObj.props.changeStartStop();                
                        otExpObj.notifyGroupWhenDisconnected();                        
                    }, 10000)
                } else {
                    printConsole('Error while connecting subscriber');
                    printConsole(error);
                }
            });//.connect

            session.on("streamDestroyed", function (event) {
                printConsole(`Stream ${event.stream.name} ended. ${event.stream.videoType} hasVideo ${event.stream.hasVideo} hasAudio ${event.stream.hasAudio}`);
                printConsole(event.stream.connection.data); 
                const threeDCanvas = document.getElementById('canavas-3d-container');
                const threeClose = document.getElementById('closeIcon');
                let maxDiv = null;  
                let {g_subInfoArr, g_session} = otExpObj.state;         
                let shapeselector = document.getElementById('shapeselector'); //TP-2810
                let eleMaxDivCtrls = document.getElementById('controlpanel');
                //let actionReqCtrls = document.getElementById('actionRequestControls');
                const data = event.stream.connection.data;
                const dataSplit = data.split(':::');
                let divId = ''; // for NS2-344
                if (dataSplit[4] === 'false'){
                    //printConsole("Publisher stream got destroyed due to reason");
                    //printConsole(event.reason);
                }            
                // Only the expert who has the maxdiv controls is allowed to switch
                // Expert screen to live stream                
                if (event.stream.videoType === 'screen' && otExpObj.state.showHideMaxDivControl) { // NS2-174 
                    //TP-2810
                    if (shapeselector && shapeselector !== null) {
                        printConsole("removing the shape selector");
                        document.getElementById(subscriberContainerDivId).appendChild(shapeselector);
                    }
                    // TP-1585 -- Handling of the use case where when iOS Primary expert in Screen cast mode
                    // leaves the session, webapp Passive expert was loosing the Max-view controls bar
                    if (eleMaxDivCtrls) {
                        printConsole("removing the control panel");
                        //console.log(eleMaxDivCtrls);
                        if (eleMaxDivCtrls != null)
                        document.getElementById(subscriberContainerDivId).appendChild(eleMaxDivCtrls);
                    }
                    // add the live stream from technician back into the subscription info array
                    otExpObj.switchExpertScreen2Live();
                } else if (dataSplit[4] === 'true') {
                    divId = 'stream_' + event.stream.id;// for NS2-344
                    //g_expertsCounter -= 1;
                    for (let ii = 0; ii < g_expertConnsArr.length; ii++) {
                        if (event.stream.connection.data === g_expertConnsArr[ii].data && event.stream.videoType !== 'screen') {
                            g_expertConnsArr.splice(ii, 1);
                            otExpObj.checkIfRequestDialogisOpen(dataSplit[3]);//TP-2292
                        }
                    }
                }

                // TP-2349 -- Handling the removal of the Guest user when it gets disconnected
                if (otExpObj.state.showHideMaxDivControl && otExpObj.state.guestUserList.length > 0 && otExpObj.state.guestUserList.indexOf(dataSplit[3]) > -1) {
                    otExpObj.setState({ guestUser: dataSplit[3] });
                }

                let index = 0;
                for (index = 0; index < g_subInfoArr.length; index++) 
                {
                    if ((event.stream.videoType !== 'screen' && divId === g_subInfoArr[index].g_divId)
                    || (event.stream.videoType === 'screen' && g_subInfoArr[index].g_stream.videoType === 'screen')
                    || (dataSplit[4] === 'false' && event.stream.videoType !== 'screen' && g_subInfoArr[index].g_stream.name === event.stream.name)) {
                        try {
                            g_subInfoArr[index].g_subscriber.subscribeToVideo(false);
                            session.unsubscribe(g_subInfoArr[index].g_subscriber);
                        } catch (error) {
                            printConsole(error);
                            printConsole(`exception occured in unsubscribe to stream: ${event.stream.connection.data}`)
                        }
                        let divIdToRemove = g_subInfoArr[index].g_divId;
                        g_subInfoArr.splice(index, 1);
                        otExpObj.setState({g_subInfoArr: g_subInfoArr}, () => {
                            // TP-1546 -- Calculate the userVideoCount for the expert after a user leaves the session
                            const list = g_subInfoArr.filter(p => p.disableVideo === false && p.g_stream.hasVideo === true);
                            let length = 0;
                            //printConsole(`list --------------------------- ${list}`)
                            if (list !== undefined) {
                                //printConsole(`Length of Users with Video on -- ${list.length}`)
                                length = list.length;
                            }
                            otExpObj.setState({ userVideoCount: length });
                            // Do not remove session joinees when screen share is ended
                            // NS2-136
                            otExpObj.setOnlineUsers();
                            if (event.stream.videoType !== "screen") {
                                otExpObj.removeSessionJoinees(event.stream);
                            } 
                            // T32-413 -- To find the count of Remote users who havn't
                            // Muted their own mikes
                            let userAudioEnabledCount = 0;
                            g_subInfoArr.forEach(subInfo => {
                                if (subInfo.disableRemoteAudio === false)
                                    userAudioEnabledCount++;
                            })
                            if (userAudioEnabledCount > 0 && otExpObj.state.disabledMutedAll === true) {                
                                otExpObj.setState({ disabledMutedAll: false });
                            } else if (userAudioEnabledCount === 0) {
                                otExpObj.setState({ disabledMutedAll: true });
                            }

                            //otExpObj.setState({isParticipantChanged : true}); // To set the Side Bar Silhouette Icons states
                            //otExpObj.setState({isParticipantChanged : false});
                        });
                        //console.log("g_maxDivId: ", g_maxDivId);
                        //console.log("divIdToRemove: ", divIdToRemove);
                        if (g_maxDivId === divIdToRemove) {
                            if (event.stream.videoType !== 'screen') {
                                // NS2-125 To take care of Maxdiv Expert Screen Share button when the technician 
                                // stops the session without screen share being turned off
                                if (otExpObj.state.expertShareScreenMaxDiv && !otExpObj.state.expertSSAll) {
                                    otExpObj.setState({ expertShareScreenMaxDiv: false, isOngoingMaxDivFeatures: false }, () => {
                                        // change the video source to be screen
                                        let subscriberBox = document.getElementById(subscriberContainerDivId);
                                        // screen share has been turned off - do the needful
                                        document.getElementById('myTechScreenShareDiv') && subscriberBox.removeChild(document.getElementById('myTechScreenShareDiv'));                                        
                                        //TP-3673
                                        if (g_screen_publisher !== null && g_screen_publisher !== undefined)
                                            g_session.unpublish(g_screen_publisher); //TP-3673
                                        // to enable the expert to be bummed out after the screen share stops
                                        // for NS2-95
                                        otExpObj.props.updateRCStatus(false);
                                        //TP-3673
                                        if (g_screen_publisher !== null && g_screen_publisher !== undefined)
                                            g_screen_publisher.destroy(); //TP-3673
                                        g_screen_publisher = null;
                                        // for NS2-117
                                        otExpObj.props.updateScreenShareMaxdiv(false);
                                        if (g_expertConnsArr.length > 0 && otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null){
                                            //Send signal to all experts to show the shared artifacts
                                            g_expertConnsArr.forEach(connectionObj => {
                                                if (g_session.connection !== connectionObj) {
                                                    const toArray = connectionObj.data.split(":::");
                                                    const toObject = toArray[3];
                                                    if (otExpObj.state.fileData !== null)
                                                        otExpObj.sessionSignal.sendSignalShareArtifacts(
                                                            //from: g_session.connection.data,
                                                            otExpObj.state.fileData,
                                                            toObject
                                                            //type: "SHARE_ARTIFACTS"                
                                                        );
                                                    else {
                                                        otExpObj.state.assetsArray.forEach(asset => {
                                                            otExpObj.sessionSignal.sendSignalShareArtifacts(asset, toObject);
                                                        })
                                                    }                                        

                                                    //const [closeIcon] = document.getElementsByClassName('asset-3d');
                                                    if (otExpObj.state.showFileViewer && otExpObj.state.fileOpacity) {
                                                        // If not 3D file share ongoing then send the last Opacity value 
                                                        otExpObj.sessionSignal.sendSignalOpacity(otExpObj.state.fileOpacity, toObject); //TP-841
                                                    } 
                                                }
                                            });

                                            // If 3D file is shared then resend the last rotation change
                                            if (!otExpObj.state.showFileViewer && otExpObj.state.rotationArray !== null && otExpObj.state.rotationArray !== '') {
                                                otExpObj.rotationTimer = setTimeout(() => {
                                                    g_expertConnsArr.forEach(connectionObj => {
                                                        if (g_session.connection !== connectionObj) {
                                                            const toArray = connectionObj.data.split(":::");
                                                            const toObject = toArray[3];
                                                            otExpObj.sessionSignal.sendSignal3DRotation(otExpObj.state.rotationArray, toObject);
                                                        }
                                                    });
                                                    clearTimeout(otExpObj.rotationTimer)
                                                }, 3000);
                                            }
                                        }
                                    })
                                } 
                            }                            
                            if (otExpObj.state.bRemoteCapture && otExpObj.state.maxDivTechType === "IPK") {
                                otExpObj.props.updateRCStatus(false);
                                otExpObj.setState({ bRemoteCapture: false });
                                otExpObj.setState({ sRCTechDetails: '' });
                                otExpObj.setState({ swapTechEmail: '' });
                                otExpObj.setState({ swapCamTechData: '' });
                                otExpObj.setState({
                                    bAnnotate: false,
                                    isOngoingMaxDivFeatures: false
                                    /* bHideAnnotateColour: true,
                                    bHideAnnotateShapes: true */
                                })
                                if (otExpObj.state.bHideAnnotateColour === false) {
                                    let sketchPicker = document.getElementById('sketchPicker');
                                    if (sketchPicker)
                                    sketchPicker.style.display = 'none';
                                }
                                if (otExpObj.state.bHideAnnotateShapes === false) {
                                    let shapeselector = document.getElementById('shapeselector');
                                    if (shapeselector)
                                    shapeselector.style.display = 'none';
                                }
                                otExpObj.props.updateScreenShareMaxdiv(false); // NS2-376
                                otExpObj.props.updateFileShareMaxdiv(false); // NS2-376
                                // TP-1372 -- To take care of the scenario when Max-view user gets disconnected after image has been received
                                if (otExpObj.state.showHideMaxDivControl === true) {
                                    printConsole ("Current Max-view user is not the same user for whom the RC image data is received by Primary expert...");
                                    otExpObj.stopExpertRCAnnotate();
                                }
                            } else if (otExpObj.state.bRemoteCapture && otExpObj.state.bRCAnnotateOn) {
                                // open the dialog and start the timer  
                                /* otExpObj.setState({
                                    openStreamDestroyedDialog: true,
                                    imageCaptureTimer: 3
                                },//NS2-244 changed from 15 to 3
                                    () => {
                                        otExpObj.streamTimer = setInterval(otExpObj.decrementCounter.bind(otExpObj), 1000)
                                    });
                                let senderInfo = event.stream.connection.data;
                                //const senderInfoArr = typeof senderInfo === "string"?senderInfo.split(':::'):"";
                                otExpObj.setState({ sRCTechDetails: senderInfo });
                                otExpObj.setState({ bRCAnnotateOn: false }); */
                                otExpObj.onClickCamera();
                            } else if (otExpObj.state.swapTechCamera) {                                
                                otExpObj.props.updateRCStatus(true);
                                otExpObj.props.updateCameraSwapStatus(true);
                                //clearInterval(otExpObj.streamTimer);
                                // open the dialog and start the timer                                
                                otExpObj.setState({
                                    openCameraSwapDialog: true,
                                    cameraSwapTimer : 10
                                },
                                    () => {
                                        otExpObj.streamTimer = setInterval(otExpObj.decrementCntr.bind(otExpObj), 1000)
                                    });
                                let senderInfo = event.stream.connection.data.split(":::");                                
                                otExpObj.setState({ swapTechEmail: senderInfo[3] });
                                otExpObj.setState({ swapCamTechData: event.stream.connection.data });
                            } else if (otExpObj.state.maxDivCameraCount > 1 && otExpObj.state.gridViewMode === false && event.stream.videoType !== "screen") {
                                otExpObj.props.updateRCStatus(true);
                                otExpObj.props.updateCameraSwapStatus(true);                                
                                otExpObj.setState({ isLoading: true }, () => {
                                    otExpObj.showLoader('Swap to default camera');
                                });
                                //clearInterval(otExpObj.streamTimer);
                                // open the dialog and start the timer                                
                                otExpObj.setState({
                                    //openCameraSwapDialog: true,
                                    cameraSwapTimer : 10
                                },
                                    () => {
                                        otExpObj.streamTimer = setInterval(otExpObj.decrementCntr.bind(otExpObj), 1000)
                                    });
                                let senderInfo = event.stream.connection.data.split(":::");                                
                                otExpObj.setState({ swapTechEmail: senderInfo[3] });
                                otExpObj.setState({ swapCamTechData: event.stream.connection.data });
                            } else {
                                otExpObj.props.updateRCStatus(false);
                                otExpObj.setState({ bRemoteCapture: false });
                                otExpObj.setState({ sRCTechDetails: '' });
                                otExpObj.setState({ swapTechEmail: '' });
                                otExpObj.setState({ swapCamTechData: '' });
                                otExpObj.setState({
                                    bAnnotate: false,
                                    isOngoingMaxDivFeatures: false
                                    /* bHideAnnotateColour: true,
                                    bHideAnnotateShapes: true */
                                })
                                if (otExpObj.state.bHideAnnotateColour === false) {
                                    let sketchPicker = document.getElementById('sketchPicker');
                                    if (sketchPicker)
                                    sketchPicker.style.display = 'none';
                                }
                                if (otExpObj.state.bHideAnnotateShapes === false) {
                                    let shapeselector = document.getElementById('shapeselector');
                                    if (shapeselector)
                                    shapeselector.style.display = 'none';
                                }
                                otExpObj.props.updateScreenShareMaxdiv(false); // NS2-376
                                otExpObj.props.updateFileShareMaxdiv(false); // NS2-376
                                // TP-1372 -- To take care of the scenario when Max-view user gets disconnected before the image has been received
                                if (otExpObj.state.showHideMaxDivControl === true) {
                                    printConsole ("Current Max-view user is not the same user for whom the RC image data is received by Primary expert...");
                                    otExpObj.stopExpertRCAnnotate();
                                }
                            }
                            //TP-2810
                            if (shapeselector && shapeselector !== null)
                                document.getElementById(subscriberContainerDivId).appendChild(shapeselector);
                            //console.log(eleMaxDivCtrls);
                            if (eleMaxDivCtrls != null)
                                document.getElementById(subscriberContainerDivId).appendChild(eleMaxDivCtrls);
                            /* if (actionReqCtrls != null)
                                document.getElementById(subscriberContainerDivId).appendChild(actionReqCtrls); */
                            //(g_subInfoArr.length > 0) ? g_maxDivId = g_subInfoArr[0].g_divId : g_maxDivId = null;
                            const [divIdToReplace] = g_subInfoArr.filter(subInfo => subInfo.disableVideo === false && subInfo.g_stream.hasVideo === true); // TP-1596 -- find first tech with Video on
                            (g_subInfoArr.length > 0) ? (g_subInfoArr[0].disableVideo === false) ? maxDiv = g_subInfoArr[0].g_divId : (divIdToReplace) ? maxDiv = divIdToReplace.g_divId : maxDiv = g_subInfoArr[0].g_divId: maxDiv = null;
                            
                            if (maxDiv !== null) {                                
                                if (g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length === 1 || otExpObj.state.gridViewMode === false) {
                                    if (otExpObj.state.showHideMaxDivControl === true && !otExpObj.state.expertSSAll) {
                                        // T32-463 -- New Multi-expert Signal trigger implementation
                                        const dataArr = g_subInfoArr[0].g_stream.connection.data.split(":::")
                                        otExpObj.sendSignalSyncExperts({
                                            show_grid: (dataArr[4] === "false") ? false : true,
                                            max_view_technician: (dataArr[4] === "false") ? dataArr[3] : "", 
                                            hand_icon_enabled: true,
                                            header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null) ? true : false, //TP-2378
                                            reason: "show_action"}, 
                                        null);  // send the max div tech details                                        
                                    }
                                        
                                    // NS2-123
                                    // If the incoming feed in the maxdiv is coming from an Expert
                                    // to another Expert and doesn't have a video then the
                                    // Maxdiv controls/Hand Icon should not be shown on the
                                    // maxdiv of that Expert screen else should be shown.
                                    let tmp = g_subInfoArr[0].g_stream.connection.data;
                                    let tmpSplit = tmp.split(':::');
                                    if (tmpSplit[4] === 'true' && g_subInfoArr[0].g_stream.hasVideo === false) {                                        
                                        otExpObj.maximizeDiv(null, {divId: maxDiv}, true);
                                        //otExpObj.setState({ showHideDivIconAudioOnly: true })
                                        
                                    } else {
                                        otExpObj.maximizeDiv(null, {divId: maxDiv});
                                        //otExpObj.setState({ showHideDivIconAudioOnly: false })
                                    }
                                }

                                // when we switch the maxdiv we reset the Max-div upload
                                // file icon. This is done in edge cases where maxdiv
                                // tech signs out of the session and is replaced by another one
                                // But if the Side Bar File Share is on and Max-div technician 
                                // signs out of the session, then we keep on showing the Shared File
                                // on the Expert screens (Primary & Secondary)
                                // console.log(otExpObj.state.shareOnDiv);
                                if (dataSplit[4] === 'false' && otExpObj.props.loggedInUserId === otExpObj.state.currentPrimaryExpert && otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv !== null && otExpObj.state.shareOnDiv !== '') {
                                    otExpObj.setState({ bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: false });//NS2-457
                                    otExpObj.onCloseFileViewer();
                                    //if (otExpObj.closeSubscriber3dObject) {
                                        //otExpObj.closeSubscriber3dObject();
                                    //} else {
                                        //otExpObj.remove3dObject();
                                    //}
                                } else {                                    
                                    let paramElemVdoChild;
                                    if (threeClose !== undefined && threeClose !== null && otExpObj.state.gridViewMode === false && (g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length >= 1 || (g_subInfoArr.length === g_expertConnsArr.length && g_expertConnsArr.length !== 0))) {
                                        printConsole("redraw the 3D file for New Max-view technician");
                                        let tmp = g_subInfoArr[0].g_stream.connection.data;
                                        let tmpSplit = tmp.split(':::');
                                        if (tmpSplit[4] === 'true' && g_subInfoArr[0].g_stream.hasVideo === false) {                                             
                                            paramElemVdoChild = document.getElementById(maxDiv+'_name');
                                        } else {
                                            [paramElemVdoChild] = document.getElementById(maxDiv).getElementsByClassName('OT_video-element');
                                        }
                                        threeDCanvas.style.width = document.getElementById(maxDiv).style.width;
                                        threeDCanvas.style.left = '0px';
                                        threeClose.style.right = '49%';
                                        paramElemVdoChild.parentNode.insertBefore(threeDCanvas, paramElemVdoChild);
                                        paramElemVdoChild.parentNode.insertBefore(threeClose, threeDCanvas);
                                    }
                                }
                                // mute/unmute the incoming/new maxDiv tech mike as per the muteMikeForMaxDiv 
                                //otExpObj.muteExpertMikeInOutMaxDiv(!otExpObj.state.muteMikeForMaxDiv);
                                otExpObj.setState({ muteMikeForMaxDiv: g_subInfoArr[0].disableMike });
                                // show rc for incoming/new tech only for non screen share type of streams
                                // NS2-96
                                if (event.stream.videoType !== "screen") {
                                    otExpObj.props.showScreenShareHeaderButton(true); // for NS2-282
                                    otExpObj.setState({
                                        bHideExpertSSButton: true,
                                        bHideTechnicianSSButton: true,
                                        bHideZoomButton: true,
                                        bHideTorchButton: true,
                                        maxDivTechType: "WEB"
                                    })
                                    if (otExpObj.state.gridViewMode === false)
                                        otExpObj.showHideRcForTech(true);
                                }
                                if (otExpObj.state.muteMikeForMaxDiv !== otExpObj.state.bMuteMyMike) {
                                    //otExpObj.setState({ openMikeDialog: true });
                                }
                            }
                        }                        
                        if (divIdToRemove !== null && divIdToRemove !== undefined) {
                            let nodeToRemove = document.getElementById(divIdToRemove);
                            if(nodeToRemove !== null && nodeToRemove !== undefined)                                
                                nodeToRemove.parentElement.removeChild(nodeToRemove);
                        }
                        //console.log(maxDiv);
                        //console.log(g_maxDivId);
                        if (((g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length === 1) && otExpObj.state.swapTechCamera === false) || otExpObj.state.gridViewMode === false || (otExpObj.state.prepareExpertSS === true && event.stream.videoType === "screen")) {
                            if (maxDiv === null) {
                                if (/**TP-2462*/event.stream.videoType !== "screen" && /*TP-2292*/!otExpObj.state.yieldPositiveCase && !otExpObj.state.bRemoteCapture && !otExpObj.state.bRCAnnotateOn && !otExpObj.state.swapTechCamera && otExpObj.state.maxDivCameraCount < 2) 
                                    otExpObj.triggerMessage(event.stream.connection.data, "disconnect");
                                otExpObj.setState({ yieldPositiveCase: false }); //TP-2292
                                let tmp, tmpSplit;
                                let maxDivSub = {};
                                if (g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length === 1) {
                                    maxDiv = g_subInfoArr[0].g_divId;
                                    g_maxDivId = null;
                                    maxDivSub = g_subInfoArr[0];  
                                    tmp = maxDivSub.g_stream.connection.data;
                                    tmpSplit = tmp.split(':::');
                                    if (tmpSplit[4] === 'true' && maxDivSub.g_stream.hasVideo === false) {
                                        otExpObj.maximizeDiv(null, {divId: maxDiv}, true);
                                    } else {
                                        otExpObj.maximizeDiv(null, {divId: maxDiv});
                                    }                                  
                                }
                                /* else if (g_subInfoArr.length > 0) {
                                    if (otExpObj.state.showHideMaxDivControl){
                                        otExpObj.hideGridViewIcon(false);
                                    }
                                    /* maxDiv = g_maxDivId;
                                    g_maxDivId = null;                                    
                                    g_subInfoArr.forEach(val => {
                                        if (val.g_divId === divId) 
                                            maxDivSub = val;
                                    })                                    
                                    tmp = maxDivSub.g_stream.connection.data;
                                    tmpSplit = tmp.split(':::');
                                    if (tmpSplit[4] === 'true' && maxDivSub.g_stream.hasVideo === false) {
                                        otExpObj.maximizeDiv(null, {divId: maxDiv}, true);
                                    } else {
                                        otExpObj.maximizeDiv(null, {divId: maxDiv});
                                    } 
                                } */                                                            
                                if (otExpObj.state.bMaxChat === true) {
                                    otExpObj.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
                                }
                            } else { 
                                //console.log(otExpObj.state.prepareExpertSS);
                                //console.log(event.stream.videoType)
                                if(otExpObj.state.prepareExpertSS === true && event.stream.videoType === "screen"){
                                    //console.log(otExpObj.state.gridViewMode);
                                    //console.log(otExpObj.state.lastViewObj);
                                    if (/* otExpObj.state.gridViewMode === false || TP-1023 */ (otExpObj.state.lastViewObj !== null && otExpObj.state.lastViewObj.show_grid === false && otExpObj.state.lastViewObj.max_view_technician !== '')) {
                                        // TP-1023 -- Handling of the Expert view mode after screen cast is either stopped/Primary expert leaves
                                        // while in Screen cast mode (mainly  for iOS Primary expert)
                                        let tmp, tmpSplit;
                                        let maxDivSub = {};                                        
                                        g_maxDivId = null;                                    
                                        g_subInfoArr.forEach(val => {
                                            const tmpSplit = val.g_stream.connection.data.split(':::');
                                            if (tmpSplit[3] === otExpObj.state.lastViewObj.max_view_technician) 
                                                maxDivSub = val;
                                        })   
                                        if (maxDivSub.g_stream !== undefined) {
                                            tmp = maxDivSub.g_stream.connection.data;
                                            tmpSplit = tmp.split(':::');
                                            if (tmpSplit[4] === 'true' && maxDivSub.g_stream.hasVideo === false) {
                                                otExpObj.maximizeDiv(null, {divId: maxDiv}, true);
                                            } else {
                                                otExpObj.maximizeDiv(null, {divId: maxDiv});
                                            } 
                                        } else {
                                            otExpObj.maximizeDiv(null, {divId: maxDiv});
                                            g_subInfoArr.forEach( subInfo => {
                                                tmpSplit = subInfo.g_stream.connection.data.split(":::");
                                                if (tmpSplit[4] === 'false' /* && subInfo.disableVideo === false */)
                                                    otExpObj.sendSignalExpertSS(false, subInfo.g_stream.connection);
                                            })
                                        }                                
                                    } else {
                                        otExpObj.drawSubscriberUI(window.innerHeight, window.innerWidth, null);    
                                        g_subInfoArr.forEach( subInfo => {
                                            let tmpSplit;
                                            tmpSplit = subInfo.g_stream.connection.data.split(":::");
                                            if (tmpSplit[4] === 'false' /* && subInfo.disableVideo === false */)
                                                otExpObj.sendSignalExpertSS(false, subInfo.g_stream.connection);
                                        })
                                    }
                                }                           
                                /* if (otExpObj.state.bMaxChat === true) {
                                    otExpObj.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
                                }  */                           
                            }
                        } else if (otExpObj.state.prepareExpertSS === true && event.stream.videoType !== "screen"){
                            otExpObj.drawScreenShareUI( {divId: g_maxDivId } );
                        } else {
                            otExpObj.drawSubscriberUI(window.innerHeight, window.innerWidth, null);    
                            if (otExpObj.state.bAllChat === true)  {
                                otExpObj.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
                            }                      
                        }  
                    }
                }
                if (event.stream.videoType === 'screen') {
                    // NS2-126 & NS2-95
                    if (otExpObj.state.swapTechCamera === false/*  || otExpObj.state.maxDivCameraCount === 0 */)
                        otExpObj.props.updateRCStatus(false);
                    // NS2-125 To take care of Tech Screen Share button when the technician stops 
                    // the session without turning screen share off
                    if (otExpObj.state.techShareScreen) {
                        otExpObj.setState({ techShareScreen: false, isOngoingMaxDivFeatures: false })
                        // for NS2-117
                        otExpObj.props.updateScreenShareMaxdiv(false);
                    }
                    if (otExpObj.state.expertSSMode) {
                        otExpObj.setState({ expertSSMode: false, prepareExpertSS: false, isOngoingMaxDivFeatures: false });
                        otExpObj.props.updateScreenShareAll(false);
                    }
                }
                propsSetJoineeCount(g_subInfoArr.length);
                if (g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length === 1) {
                    otExpObj.hideGridViewIcon(true); 
                } else if (g_subInfoArr.length === 0) {                    
                    otExpObj.props.updateFileShare(false);
                    otExpObj.setState({ bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: false });//NS2-457
                } else if (g_subInfoArr.length - g_expertConnsArr.length > 1) {
                    const isOngoingMaxDivFeatures = otExpObj.isOngoingMaxDivFeaturesExceptZoomAndTorch();
                    otExpObj.setState({ isOngoingMaxDivFeatures });
                }
                //NS2-486 & NS2-490 To check whether the expert going out of the session is the current Primary Expert or not
                // & If condition added for eliminating the scenarios for RC & screen share
                //console.log("User ID of disconnecting user: ",parseInt(dataSplit[0]));
                //console.log("Current Main Expert user id: ",otExpObj.state.currentPrimaryExpert);
                if (dataSplit[4] === 'true' && parseInt(dataSplit[0]) === otExpObj.state.currentPrimaryExpert && event.stream.videoType !== "screen") {
                    //otExpObj.setState({ disableHand: false });
                    const {isGuest} = otExpObj.props;
                    printConsole(`is expert a Guest user ? ${isGuest}`);
                    let registeredExperts = g_expertConnsArr.filter(p => p.isRegistered);
                    printConsole("Current joined Registered Experts ------>")
                    printConsole(registeredExperts);
                    if (isGuest && registeredExperts && registeredExperts.length === 0) {
                        otExpObj.props.onSessionCmdChange('session_stopped');
                    } else if (!isGuest && registeredExperts.length === 0) {
                        // TP-2349 -- Handling the removal of the Guest user when the user that left is Primary expert who gets disconnected
                        if (otExpObj.state.guestUserList.length > 0 && otExpObj.state.guestUserList.indexOf(dataSplit[3]) > -1) {
                            otExpObj.setState({ guestUser: dataSplit[3] });
                        }
                        //console.log("No more experts in session ---");
                        //console.log("current Primary expert:", otExpObj.props.loggedInUserId);
                        otExpObj.props.setPrimaryExpertIDApi(); // NS2-490    
                        otExpObj.setState({ currentPrimaryExpert: otExpObj.props.loggedInUserId }); //NS2-486              
                        // T32-463 -- New Multi-expert Signal trigger implementation
                        otExpObj.sendSignalSyncExperts({
                            isPrimary: true,
                            expertId: otExpObj.props.user.id,
                            email: otExpObj.props.user.email,
                            firstname: otExpObj.props.user.firstname,
                            lastname: otExpObj.props.user.lastname,
                            joinTimeStamp: parseInt(otExpObj.state.loggedinUserTimeStamp),
                            reason: "primary_sync"
                            },
                        null);
                        //NS2-482	
                        otExpObj.setState({ showHideMaxDivControl: true }, () => {
                            // TP-359 -- New Socket signal to be sent to all technicians 
                            // announcing the current Primary expert 
                            otExpObj.sendSignalCurrentPrimaryExpert();
                            otExpObj.setState({isParticipantChanged : true}, () => {
                                otExpObj.setState({isParticipantChanged : false});
                            });// to change the Side Bar button state
                            if (otExpObj.state.lastViewObj !== null) {
                                otExpObj.triggerDrawExpertUI();//TP-1452
                            }
                            if (otExpObj.state.gridViewMode === false) {
                                otExpObj.showHideRcForTech(true);
                                //printConsole(`uservideocount: ${otExpObj.state.userVideoCount}`);
                                if (g_subInfoArr.length > 1 && otExpObj.state.userVideoCount > 1)                                     
                                    otExpObj.hideGridViewIcon(false);
                                if (otExpObj.state.hideButton === "d-none" && window.innerHeight <= 420) {
                                    for (let ii=0; ii< g_subInfoArr.length; ii++) {
                                        if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                                            //TP-2933
                                            let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                            if (zoomLevel) {
                                                let flag = g_subInfoArr[ii].zoomLevel;
                                                if (flag === 1){
                                                    // Change the zoom level text on top of Video element
                                                    zoomLevel.className = 'zoom-text';
                                                    zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                                    zoomLevel.innerHTML = processZoomLevels(flag + "X", otExpObj.props.i18n);
                                                    zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                                                                
                                                } else {
                                                    // Change the zoom level text on top of Video element                    
                                                    zoomLevel.className = 'zoom-text';
                                                    zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                                    zoomLevel.innerHTML = processZoomLevels(flag + "X", otExpObj.props.i18n);
                                                    zoomLevel.parentElement.style.display = "block";
                                                }
                                            }
                                        }
                                    }  
                                }                               
                            } else {
                                otExpObj.sendSignalMaxViewTech(false);
                                for (let ii=0; ii< g_subInfoArr.length; ii++) {
                                    //show the disconnect user button on top of the video feed elements
                                    let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                                    if (disconnectuser !== undefined && disconnectuser !== null) {
                                        disconnectuser.style.display = "block";                    
                                    }
                    
                                    //show the gridcontrol icons on top of video element
                                    let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                                    if (gridcontrol !== undefined && gridcontrol !== null)
                                        gridcontrol.style.display = "block";
                                    
                                    // Show the Video Turn off Icon on Primary expert's Grid View
                                    let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);
                                    if (videoIcon !== undefined && videoIcon !== null) 
                                        videoIcon.style.display = "block";

                                    if(g_subInfoArr[ii].zoom_enabled) {
                                        // enable the zoom Icon for Primary expert
                                        let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                                        if (zoomIcon !== undefined && zoomIcon !== null) {
                                            zoomIcon.style.display = "block";
                                            let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");
                                            zoomBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                            zoomIcon.onClick = function (e) {
                                                otExpObj.rotateZoomLevel(g_subInfoArr[ii].g_divId);
                                            }
                                        }
                                    }  
                                            
                                    if(g_subInfoArr[ii].torch_enabled) {
                                        // enable the torch Icon for Primary expert
                                        let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                                        if (torchIcon !== undefined && torchIcon !== null) {
                                            torchIcon.style.display = "block";
                                            let torchBtn = torchIcon.getElementsByClassName("grid-icon");                            
                                            torchBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                            torchIcon.onClick = function (e) {
                                                otExpObj.toggleUserTorch(g_subInfoArr[ii].g_divId);
                                            }
                                        }
                                    }
                                }
                            }
                                  
                            //TP-1621 -- Handling the new Primary expert's screen, if the RC had been triggered by the
                            //previous Primary expert, but it was disconnected before the RC image could reach 
                            // the new Primary expert
                            if (otExpObj.state.bRemoteCapture && otExpObj.state.bAnnotate === false){
                                otExpObj.stopExpertRCAnnotate(); // NS2-345
                                otExpObj.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
                                otExpObj.setState({bRemoteCapture: false, isOngoingMaxDivFeatures: false});
                            }

                            // T32-624 -- Handling of Seamless transfer of Feature (File share) if Primary expert leaves the session
                            if(otExpObj.state.assetsArray.length > 0) {                                 
                                //TP-2695
                                if (otExpObj.state.assetsArray[0].format === undefined) {
                                    otExpObj.handleArtifactsOnPrimaryExpert(otExpObj.state.assetsArray);
                                } else {
                                    const paramElem = document.getElementById(g_maxDivId);
                                    const isExpertDiv = isDivExpert(g_maxDivId);
                                    otExpObj.handleArtifactsNew({ assetsArray: otExpObj.state.assetsArray, paramElem, isExpertDiv, flag: true });
                                }
                            }

                            //TP-740 -- Implementing the seamless continuation of Recording feature when Control tranfer happens
                            if (isrecording_active === true && isSilentRecording === false && !otExpObj.props.isSafari && !otExpObj.props.isFirefox && otExpObj.state.hideButton !== 'd-none') { //TP-2262
                                otExpObj.sendSignalRecordingContinue(parseInt(dataSplit[0]));
                                otExpObj.onClickRecordScreen("continue"); //TP-1877
                            } else if (isrecording_active === true && isSilentRecording === true){
                                printConsole("Currently Silent recording isn't supported on Webapp Expert side");
                                /* otExpObj.sendSignalRecordingStop();
                                //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                                otExpObj.sendSignalSyncExperts({
                                    isrecording_active: false,
                                    is_silent_recording: isSilentRecording,
                                    recording_folder_name: otExpObj.state.recFileName,
                                    frames_per_seconds: screenSettings[1].fps,
                                    frame_count: count,
                                    reason: "recording_sync"},
                                null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                                otExpObj.setState({ recFileName: '' }); */
                            } else if (isrecording_active === true && isSilentRecording === false && (otExpObj.props.isSafari || otExpObj.props.isFirefox || otExpObj.state.hideButton === 'd-none')) {
                                // TP-2262 -- Handling the stopping of Recording if Expert who takes up control is on Safari/Firefox browsers
                                printConsole("Safari/Firefox browser doesn't support recording so stopping the previous recording")
                                otExpObj.sendSignalRecordingStop(null, dataSplit[3]);//TP-2095
                                //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                                otExpObj.sendSignalSyncExperts({
                                    isrecording_active: false,
                                    is_silent_recording: isSilentRecording,
                                    recording_folder_name: otExpObj.state.recFileName,
                                    frames_per_seconds: screenSettings[1].fps,
                                    frame_count: count,
                                    reason: "recording_sync"},
                                null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                                otExpObj.setState({ recFileName: '' });
                            }
                        });
                        // TP-247 --  Handling of automatic turning on the Remote user cameras
                        // If the current Primary expert doesn't have the Video turn on/off button enabled
                        // and if all remote user's Videos are turned off
                        const list = otExpObj.state.g_subInfoArr.filter(p => p.disableVideo === false && p.g_stream.hasVideo === true && p.g_stream.videoType !== 'screen');
                        //printConsole(`list ---------------------------`)
                        //printConsole(list);
                        let userVideoCount = 0;
                        if (list !== undefined) {
                            printConsole(`Length of Users with Video on -- ${list.length}`)
                            userVideoCount = list.length;
                        }
                        //console.log(userVideoCount);
                        //console.log(otExpObj.props.sessionInfo.can_turn_off_user_video);
                        if (userVideoCount === 0 && otExpObj.props.sessionInfo.can_turn_off_user_video === false) {
                            printConsole("userVideoCount is zero....");
                            for(let ii = 0; ii < g_subInfoArr.length; ii++)
                            {
                                g_subInfoArr[ii].g_subscriber.subscribeToVideo(true);
                                g_subInfoArr[ii].disableVideo = false;   
                                let tmp = g_subInfoArr[ii].g_stream.connection.data.split(":::");       
                                let to = tmp[3];
                                printConsole(`Turn on/off video signal being sent to user ----> ${to}`);
                                otExpObj.sessionSignal.sendSignalTurnOnUserVideo(true, to);
                            }
                            otExpObj.setState({ g_subInfoArr }, () => {
                                otExpObj.setState({ userVideoCount: g_subInfoArr.length }, () => {
                                    printConsole(`uservideocount: ${otExpObj.state.userVideoCount}`);
                                    if (otExpObj.state.userVideoCount > 1 && otExpObj.state.gridViewMode === false)
                                        otExpObj.hideGridViewIcon(false);
                                })
                            });
                        }
                        otExpObj.props.updateShowHideHeaderActionButtons(true);
                        // TP-1546 -- only turn on the Side Bar Screencast/Fileshare Icons if at least one tech has Video turned on
                        if (userVideoCount > 0) {
                            otExpObj.props.showScreenShareHeaderButton(false); // for NS2-282
                        }
                        otExpObj.clearPassiveExpertFeature();
                    } else if (!isGuest && registeredExperts.length > 0) {
                        //console.log("More experts are in session ---");
                        //NS2-482
                        if (otExpObj.state.loggedinUserTimeStamp < registeredExperts[0].data.split(":::")[6] && otExpObj.state.showHideMaxDivControl === false) {
                            // TP-2349 -- Handling the removal of the Guest user when the user that left is Primary expert who gets disconnected
                            if (otExpObj.state.guestUserList.length > 0 && otExpObj.state.guestUserList.indexOf(dataSplit[3]) > -1) {
                                otExpObj.setState({ guestUser: dataSplit[3] });
                            }
                            //console.log("current Primary expert:", otExpObj.props.loggedInUserId);
                            otExpObj.props.setPrimaryExpertIDApi(); //NS2-490
                            otExpObj.setState({ currentPrimaryExpert: otExpObj.props.loggedInUserId }); //NS2-486
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            otExpObj.sendSignalSyncExperts({
                                isPrimary: true,
                                expertId: otExpObj.props.user.id,
                                email: otExpObj.props.user.email,
                                firstname: otExpObj.props.user.firstname,
                                lastname: otExpObj.props.user.lastname,
                                joinTimeStamp: parseInt(otExpObj.state.loggedinUserTimeStamp),
                                reason: "primary_sync"
                                },
                            null);                            
                            otExpObj.setState({ showHideMaxDivControl: true }, () => {
                                // TP-359 -- New Socket signal to be sent to all technicians 
                                // announcing the current Primary expert 
                                otExpObj.sendSignalCurrentPrimaryExpert();
                                otExpObj.setState({isParticipantChanged : true}, () => {
                                    otExpObj.setState({isParticipantChanged : false});
                                });// to change the Side Bar button state
                                if (otExpObj.state.lastViewObj !== null) {
                                    otExpObj.triggerDrawExpertUI();//TP-1452
                                }
                                if (otExpObj.state.showHideDivIconAudioOnly === false && otExpObj.state.gridViewMode === false) {
                                    // In Max-view mode -- Sync experts reason="show_action" signal sent to all experts in session
                                    // with show_grid = false
                                    g_subInfoArr.forEach(subInfo => {
                                        if (subInfo.g_divId === g_maxDivId) {
                                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                                            // T32-463 -- New Multi-expert Signal trigger implementation
                                            otExpObj.sendSignalSyncExperts({
                                                show_grid: false,
                                                max_view_technician: dataArr[3], 
                                                annotation_active: otExpObj.state.bAnnotate, 
                                                zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                                zoom_level: subInfo.zoomLevel,
                                                torch_button_active: subInfo.torchActive,
                                                annotate_color: "red",
                                                shape_selected: "freehand",
                                                hand_icon_enabled: true,    
                                                header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null) ? true : false, //TP-2378           
                                                reason: "show_action" },
                                            null);
                                        }
                                    });
                                    otExpObj.showHideRcForTech(true); 
                                    if (g_subInfoArr.length > 1 && otExpObj.state.userVideoCount > 1)
                                        otExpObj.hideGridViewIcon(false);     
                                    if (otExpObj.state.hideButton === "d-none" && window.innerHeight <= 420) {
                                        for (let ii=0; ii< g_subInfoArr.length; ii++) {
                                            if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                                                //TP-2933
                                                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                                if (zoomLevel) {
                                                    let flag = g_subInfoArr[ii].zoomLevel;
                                                    if (flag === 1){
                                                        // Change the zoom level text on top of Video element
                                                        zoomLevel.className = 'zoom-text';
                                                        zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                                        zoomLevel.innerHTML = processZoomLevels(flag + "X", otExpObj.props.i18n);
                                                        zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                                                                        
                                                    } else {
                                                         // Change the zoom level text on top of Video element                    
                                                        zoomLevel.className = 'zoom-text';
                                                        zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                                        zoomLevel.innerHTML = processZoomLevels(flag + "X", otExpObj.props.i18n);
                                                        zoomLevel.parentElement.style.display = "block";
                                                    }
                                                }
                                            }
                                        }  
                                    }                                
                                } else if (otExpObj.state.gridViewMode === true) {
                                    otExpObj.sendSignalMaxViewTech(false);
                                    // In Grid View Mode -- Sync experts reason="show_action" signal sent to all experts in session
                                    // with show_grid = true
                                    // T32-463 -- New Multi-expert Signal trigger implementation
                                    otExpObj.sendSignalSyncExperts({
                                        show_grid: true,
                                        max_view_technician: "", 
                                        annotation_active: otExpObj.state.bAnnotate, 
                                        annotate_color: "red",
                                        shape_selected: "freehand",
                                        hand_icon_enabled: true,   
                                        header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null) ? true : false, //TP-2378            
                                        reason: "show_action" },
                                    null);
                                    for (let ii=0; ii< g_subInfoArr.length; ii++) {
                                        //show the disconnect user button on top of the video feed elements
                                        let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                                        if (disconnectuser !== undefined && disconnectuser !== null) {
                                            disconnectuser.style.display = "block";                    
                                        }
                        
                                        //show the gridcontrol icons on top of video element
                                        let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                                        if (gridcontrol !== undefined && gridcontrol !== null)
                                            gridcontrol.style.display = "block";
                                    
                                        // Show the Video Turn off Icon on Primary expert's Grid View
                                        let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);
                                        if (videoIcon !== undefined && videoIcon !== null) 
                                            videoIcon.style.display = "block"; 
                                            
                                        if(g_subInfoArr[ii].zoom_enabled) {
                                            // enable the zoom Icon for Primary expert
                                            let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                                            if (zoomIcon !== undefined && zoomIcon !== null) {
                                                zoomIcon.style.display = "block";
                                                let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");
                                                zoomBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                                zoomIcon.onClick = function (e) {
                                                    otExpObj.rotateZoomLevel(g_subInfoArr[ii].g_divId);
                                                }
                                            }
                                        }  
                                                    
                                        if(g_subInfoArr[ii].torch_enabled) {
                                            // enable the torch Icon for Primary expert
                                            let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                                            if (torchIcon !== undefined && torchIcon !== null) {
                                                torchIcon.style.display = "block";
                                                let torchBtn = torchIcon.getElementsByClassName("grid-icon");                            
                                                torchBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                                torchIcon.onClick = function (e) {
                                                    otExpObj.toggleUserTorch(g_subInfoArr[ii].g_divId);
                                                }
                                            }
                                        }                                       
                                    }
                                }
                                //TP-1621 -- Handling the new Primary expert's screen, if the RC had been triggered by the
                                //previous Primary expert, but it was disconnected before the RC image could reach 
                                // the new Primary expert
                                if (otExpObj.state.bRemoteCapture && otExpObj.state.bAnnotate === false){
                                    otExpObj.stopExpertRCAnnotate(); // NS2-345
                                    otExpObj.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
                                    otExpObj.setState({bRemoteCapture: false, isOngoingMaxDivFeatures: false});
                                }

                                // T32-624 -- Handling of Seamless transfer of Feature (File share) if Primary expert leaves the session
                                if(otExpObj.state.assetsArray.length > 0) {
                                    //TP-2695
                                    if (otExpObj.state.assetsArray[0].format === undefined) {
                                        otExpObj.handleArtifactsOnPrimaryExpert(otExpObj.state.assetsArray);
                                    } else {
                                        const paramElem = document.getElementById(g_maxDivId);
                                        const isExpertDiv = isDivExpert(g_maxDivId);
                                        otExpObj.handleArtifactsNew({ assetsArray: otExpObj.state.assetsArray, paramElem, isExpertDiv, flag: true });
                                    }
                                }

                                //TP-740 -- Implementing the seamless continuation of Recording feature when Control tranfer happens
                                if (isrecording_active === true && isSilentRecording === false && !otExpObj.props.isSafari && !otExpObj.props.isFirefox && otExpObj.state.hideButton !== 'd-none') { //TP-2262
                                    otExpObj.sendSignalRecordingContinue(parseInt(dataSplit[0]));
                                    otExpObj.onClickRecordScreen("continue"); //TP-1877
                                } else if (isrecording_active === true && isSilentRecording === true){
                                    printConsole("Currently Silent recording isn't supported on Webapp Expert side");
                                    /* otExpObj.sendSignalRecordingStop();
                                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                                    otExpObj.sendSignalSyncExperts({
                                        isrecording_active: false,
                                        is_silent_recording: isSilentRecording,
                                        recording_folder_name: otExpObj.state.recFileName,
                                        frames_per_seconds: screenSettings[1].fps,
                                        frame_count: count,
                                        reason: "recording_sync"},
                                    null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                                    otExpObj.setState({ recFileName: '' }); */
                                } else if (isrecording_active === true && isSilentRecording === false && (otExpObj.props.isSafari || otExpObj.props.isFirefox || otExpObj.state.hideButton === 'd-none')) {
                                    // TP-2262 -- Handling the stopping of Recording if Expert who takes up control is on Safari/Firefox browsers
                                    printConsole("Safari/Firefox browser doesn't support recording so stopping the previous recording")
                                    otExpObj.sendSignalRecordingStop(null, dataSplit[3]); //TP-2095
                                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                                    otExpObj.sendSignalSyncExperts({
                                        isrecording_active: false,
                                        is_silent_recording: isSilentRecording,
                                        recording_folder_name: otExpObj.state.recFileName,
                                        frames_per_seconds: screenSettings[1].fps,
                                        frame_count: count,
                                        reason: "recording_sync"},
                                    null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                                    otExpObj.setState({ recFileName: '' });
                                }
                            });
                            const list = otExpObj.state.g_subInfoArr.filter(p => p.disableVideo === false && p.g_stream.hasVideo === true && p.g_stream.videoType !== 'screen');                        
                            let userVideoCount = 0;
                            if (list !== undefined) {
                                printConsole(`Length of Users with Video on -- ${list.length}`)
                                userVideoCount = list.length;
                            }
                            otExpObj.props.updateShowHideHeaderActionButtons(true);
                            // TP-1546 -- only turn on the Side Bar Screencast/Fileshare Icons if at least one tech has Video turned on
                            if (userVideoCount > 0) {
                                otExpObj.props.showScreenShareHeaderButton(false); // for NS2-282
                            }
                            otExpObj.clearPassiveExpertFeature();
                        }
                    }
                }
                else if (dataSplit[4] === 'true') {
                    //TP-2090 -- Passive expert left the session call so check the current number of registered experts 
                    // that are currently there in the session and end the call if count of registered experts is 0
                    const {isGuest} = otExpObj.props;
                    printConsole(`is expert a Guest user ? ${isGuest}`);
                    let registeredExperts = g_expertConnsArr.filter(p => p.isRegistered);
                    printConsole("Current joined Registered Experts ------>")
                    printConsole(registeredExperts);
                    if (isGuest && registeredExperts && registeredExperts.length === 0) {
                        otExpObj.props.onSessionCmdChange('session_stopped');
                    }
                } 
                // To check whether the technician leaving the session makes this an only experts session
                // If true then expertSS should be stopped
                else if (dataSplit[4] === 'false' && otExpObj.state.showHideMaxDivControl ) {
                    if ((g_subInfoArr.length === g_expertConnsArr.length && g_expertConnsArr.length !== 0) || otExpObj.state.userVideoCount === 0){//TP-2447
                        //console.log("Only experts remaining in this session!!!");
                        if (otExpObj.state.expertSSAll) {
                            otExpObj.onClickExpertScreenShareForAll(false);
                            otExpObj.setState({ isOngoingMaxDivFeatures: false })
                        } //console.log(otExpObj.state.bShareAsset) 
                        if (otExpObj.state.bShareAsset) {
                            otExpObj.props.updateFileShare(false);
                            otExpObj.setState({ bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: false });
                            otExpObj.onCloseFileViewer();
                            if (otExpObj.closeSubscriber3dObject) {
                                otExpObj.closeSubscriber3dObject();
                            } else {
                                otExpObj.remove3dObject();
                            }
                            //otExpObj.onClickUploadFileForAll();
                        }
                        //if (g_expertConnsArr.length >= 2)
                            //otExpObj.gridView();
                            //otExpObj.drawSubscriberUI(window.innerHeight, window.innerWidth, null);    
                    }
                }
                /* diagonastics(otExpObj.authService, {
                    action: `in streamDestroyed for expert`,
                    next_step: '',
                    data: event.stream.connection.data,
                    error: 'none'
                }) */
            });

            session.on("sessionDisconnected", (event) => {
                printConsole("The one-to-one connection got disconnected!!");
                printConsole(event.reason);                
                //invoke this before other code
                if(typeof(Storage) !== "undefined"){
                    ['groupId', 'isonprem'].forEach(key =>  sessionStorage.removeItem(key));//FQ3-295, TP-991
                }else{
                    printConsole(`Sorry, your browser does not support Web Storage...`)
                }

                printConsole(`is startRecording -----> ${otExpObj.state.startRecording}`);
                printConsole(`expert count for this session ----> ${g_expertConnsArr.length}`);
                // if session is stopped while recording going on by expert //NS2-274
                if (this.state.startRecording && isSilentRecording === false) {
                    /* printConsole("Stop Recording when this expert ends the session");
                    this.stopRecordingCallback("end"); */
                    //this.stopScreenshotRecordingCb();
                } /* else if (this.state.startRecording && g_expertConnsArr > 0) {
                    printConsole("Continue Recording with some other Expert as Primary for this call");
                    this.stopRecordingCallback("continue");
                } */
                session.off();
                otExpObj.props.changeStartStop();                
                otExpObj.notifyGroupWhenDisconnected();
            });
        
        return session;
    }

    //TP-2824
    sendStopPointerAnnotation = () => {
        printConsole("send stop pointer annotation to all users");
        const {pencolor} = this.state;
        const paramElem = document.getElementById(g_maxDivId);
        if (paramElem !== null && paramElem !== undefined) {
            const string = paramElem.offsetWidth + ":::" + paramElem.offsetHeight + ":::-1:::-1:::" + pencolor;
            //console.log(string);
            this.sendAnnotationToExpert(string); //NS2-345
            this.sendAnnotation(string, paramElem);
        }
    }

    clearPassiveExpertFeature = () => {
        printConsole("Clear all passive expert features when this becomes Primary expert!!");
        const {g_subInfoArr} = this.state;        
        // To halt the RC process if Primary expert gets disconnected
        // after starting RC but before sending the image uploaded signal 
        //console.log(this.state.bRemoteCapture);
        //console.log(this.state.bRCAnnotateOn); 
        //console.log("bAnnotate: ", this.state.bAnnotate);
        //console.log(document.getElementById("canvas"));
        if (this.state.bRemoteCapture && document.getElementById("canvas") !== null) {
            //TP-2474 Before starting RC + Annotation for New Primary expert send the canvas string & JSON to all Passive experts
            this.triggerSendAnnotation();
            this.sendStopPointerAnnotation(); //TP-2824
            // Start RC+Annotatoion for New Primary Expert
            
            const {strFileName, format} = this.state;
            const contentType = 'image/'+format;
            const fileName = strFileName.replace('data:'+contentType+';base64,', '').trim();
            // Primary expert urlObj is processed
            const blob = new base64StringToBlob(fileName, contentType);// MB2-556
            //printConsole(blob);
            let urlObj = URL.createObjectURL(blob);
            //NS2-217
            this.setState({ urlObj, bAnnotate: true }, () => {
                this.startAnnotate();                
            });

            // TP-393 --  Handling of Zoom level panel when RC is turned on
            const zoomSelector = document.getElementById('zoomlevelselector');
            if (this.state.bZoomClick && zoomSelector) {
                zoomSelector.style.display = 'none';
            }
            // Clear the annotations on all users
            //this.sendAnnotation(clearAnnotateObj, null);
        }   
        // To Start Annotations on a fresh canvas if Primary expert gets disconnected 
        // while Annotations is ongoing
        else if (this.state.bAnnotate){
            //console.log("Annotation going on...");
            //TP-2474 -- Before starting Annotation for New Primary expert send the canvas string & JSON to all Passive experts
            this.triggerSendAnnotation();
            this.sendStopPointerAnnotation(); //TP-2824
            this.startAnnotate();
            // Clear the annotations on all users
            //this.sendAnnotation(clearAnnotateObj, null);
        } else {
            //If RC or annotation is not on then clear out the Color Picker & Shape Picker elements
            const shapeselector = document.getElementById('shapeselector');
            const sketchPicker = document.getElementById('sketchPicker');
            if (shapeselector !== undefined && shapeselector !== null)
                shapeselector.style.display = "none";
            if (sketchPicker !== undefined && sketchPicker !== null)
                sketchPicker.style.display = "none";
        }  
        /* if (this.state.bRemoteCapture === false && this.state.bRCAnnotateOn === true) {
            this.setState({ isLoading: false });
            //this.onClickCamera();
            this.stopExpertRCAnnotate();// NS2-345
            /* this.waitTimer = setTimeout(() => { 
                clearTimeout(this.waitTimer);
            }, 10000);            
            this.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
            
            this.setState({ bRCAnnotateOn: false });
            this.setState({ sRCTechDetails: '' });
            if (this.state.bAnnotate === true)
                this.turnOnAnnotate(false, null);
            else
                this.setState({ bAnnotate: false });
            this.props.updateRCStatus(false);
            this.props.updateScreenShareMaxdiv(false);// NS2-376    
            this.props.updateFileShareMaxdiv(false); // NS2-376
        } */ 
        
        
        // T32-624 -- To clear the Shared Asset canvas if Primary expert gets disconnected
        // while Share Asset is ongoing
        /* if (this.closeSubscriber3dObject) {
            this.closeSubscriber3dObject();
        } */ 
        //To clear the shared screen on the Max-div technician If Primary Expert gets disconnected
        // while Expert screen share is on
        // Sending the session signal for the Expert screen share on/off to maxdiv technician
        if (this.state.prepareExpertSS === true) {
            g_subInfoArr.forEach( subInfo => {
                //if (subInfo.disableVideo === false) {
                    this.sendSignalExpertSS(false, subInfo.g_stream.connection);
                //}
            })
        }

        // If Zoom levels is not turned on then clear out the Zoom Level elements
        const zoomSelector = document.getElementById("zoomlevelselector");
        if (!this.state.bZoomClick && zoomSelector !== null) {
            zoomSelector.style.display = 'none';
        }
        // TP-110
        if(this.state.bAllChat === true) {
            this.setState({ bAllChat: false }, () => {
                this.setState({ bAllChat: true });
            })
        }
        // To close the Max-chat window of the New Primary expert
        // who was before a Passive expert, and open the Side Bar Chat window 
        // for him after the user becomes a Primary expert
        if (this.state.bMaxChat === true) {
            this.setState({ bMaxChat: false }, () => {
                this.setState({ bAllChat: true }); //TP-110
                this.props.updateChatAll(true);
            })
        } 
    }

    // NS2-405
    setAnnotationButtons = () => {
        const { annotation_color_enabled, annotation_shape_enabled, annotation_pointer_enabled } = this.props.sessionInfo;
        if (annotation_color_enabled) this.setState({ bHideAnnotateColour: false });
        if (annotation_shape_enabled) this.setState({ bHideAnnotateShapes: false });
        if (annotation_pointer_enabled === false) this.setState({bHideAnnotationPointer: true}); //TP-2528
        else this.setState({bHideAnnotationPointer: false}); //TP-2528
    } 
    
    streamDisconnected = (i, reason) => {
        const threeDCanvas = document.getElementById('canavas-3d-container');
        const threeClose = document.getElementById('closeIcon');
        let maxDiv = null;  
        let {g_subInfoArr, g_session, showHideMaxDivControl} = this.state;     
        let shapeselector = document.getElementById('shapeselector'); //TP-2810
        let eleMaxDivCtrls = document.getElementById('controlpanel');
        //let actionReqCtrls = document.getElementById('actionRequestControls');
        const data = g_subInfoArr[i].g_stream.connection.data;
        const dataSplit = data.split(':::');
        let divId = ''; // for NS2-344
        if (dataSplit[4] === 'false'){
            printConsole("Publisher stream got destroyed due to reason");
            printConsole(reason);
            printConsole(data);
        }            
        // Only the expert who has the maxdiv controls is allowed to switch
        // Expert screen to live stream                
        if (g_subInfoArr[i].g_stream.videoType === 'screen' && showHideMaxDivControl) { // NS2-174 
            //TP-2810
            if (shapeselector && shapeselector !== null) {
                printConsole("removing the shape selector");
                document.getElementById(subscriberContainerDivId).appendChild(shapeselector);
            }
            // TP-1585 -- Handling of the use case where when iOS Primary expert in Screen cast mode
            // leaves the session, webapp Passive expert was loosing the Max-view controls bar
            if (eleMaxDivCtrls) {
                printConsole("removing the control panel");
                //console.log(eleMaxDivCtrls);
                if (eleMaxDivCtrls != null)
                document.getElementById(subscriberContainerDivId).appendChild(eleMaxDivCtrls);
            }
            // add the live stream from technician back into the subscription info array
            this.switchExpertScreen2Live();
        } else if (dataSplit[4] === 'true') {
            divId = 'stream_' + g_subInfoArr[i].g_divId;// for NS2-344
            //g_expertsCounter -= 1;
            for (let ii = 0; ii < g_expertConnsArr.length; ii++) {
                if (g_subInfoArr[i].g_stream.connection.data === g_expertConnsArr[ii].data && g_subInfoArr[i].g_stream.videoType !== 'screen') {
                    g_expertConnsArr.splice(ii, 1);
                    this.checkIfRequestDialogisOpen(dataSplit[3]);//TP-2292
                }
            }
        }

        // TP-2349 -- Handling the removal of the Guest user when it gets disconnected
        if (this.state.showHideMaxDivControl && this.state.guestUserList.length > 0 && this.state.guestUserList.indexOf(dataSplit[3]) > -1) {
            this.setState({ guestUser: dataSplit[3] });
        }

        /* let index = 0;
        for (index = 0; index < g_subInfoArr.length; index++) 
        {
            if ((event.stream.videoType !== 'screen' && divId === g_subInfoArr[index].g_divId)
            || (event.stream.videoType === 'screen' && g_subInfoArr[index].g_stream.videoType === 'screen')
            || (dataSplit[4] === 'false' && event.stream.videoType !== 'screen' && g_subInfoArr[index].g_stream.name === event.stream.name)) { */
                try {
                    g_subInfoArr[i].g_subscriber.subscribeToVideo(false);
                    g_session.unsubscribe(g_subInfoArr[i].g_subscriber);
                } catch (error) {
                    printConsole(error);
                    printConsole(`exception occured in unsubscribe to stream: ${g_subInfoArr[i].g_stream.connection.data}`)
                }
                let divIdToRemove = g_subInfoArr[i].g_divId;
                let g_subInfo = g_subInfoArr[i];
                g_subInfoArr.splice(i, 1);
                this.setState({g_subInfoArr: g_subInfoArr}, () => {
                    this.setOnlineUsers();
                    // T32-413 -- To find the count of Remote users who havn't
                    // Muted their own mikes
                    let userAudioEnabledCount = 0;
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.disableRemoteAudio === false)
                            userAudioEnabledCount++;
                    })
                    if (userAudioEnabledCount > 0 && this.state.disabledMutedAll === true) {                
                        this.setState({ disabledMutedAll: false });
                    } else if (userAudioEnabledCount === 0) {
                        this.setState({ disabledMutedAll: true });
                    }
                });
                //console.log("g_maxDivId: ", g_maxDivId);
                //console.log("divIdToRemove: ", divIdToRemove);
                if (g_maxDivId === divIdToRemove) {
                    if (g_subInfo.g_stream.videoType !== 'screen') {
                        printConsole("take care of Maxdiv Expert Screen Share button");
                        // NS2-125 To take care of Maxdiv Expert Screen Share button when the technician 
                        // stops the session without screen share being turned off
                        if (this.state.expertShareScreenMaxDiv && !this.state.expertSSAll) {
                            this.setState({ expertShareScreenMaxDiv: false, isOngoingMaxDivFeatures: false }, () => {
                                printConsole("change the video source to be screen");
                                // change the video source to be screen
                                let subscriberBox = document.getElementById(subscriberContainerDivId);
                                // screen share has been turned off - do the needful
                                document.getElementById('myTechScreenShareDiv') && subscriberBox.removeChild(document.getElementById('myTechScreenShareDiv'));                                        
                                //TP-3673
                                if (g_screen_publisher !== null && g_screen_publisher !== undefined)
                                    g_session.unpublish(g_screen_publisher); //TP-3673
                                // to enable the expert to be bummed out after the screen share stops
                                // for NS2-95
                                this.props.updateRCStatus(false);
                                //TP-3673
                                if (g_screen_publisher !== null && g_screen_publisher !== undefined)
                                    g_screen_publisher.destroy(); //TP-3673
                                g_screen_publisher = null;
                                // for NS2-117
                                this.props.updateScreenShareMaxdiv(false);
                                if (g_expertConnsArr.length > 0 && this.state.bShareAsset && this.state.shareOnDiv === null){
                                    //Send signal to all experts to show the shared artifacts
                                    g_expertConnsArr.forEach(connectionObj => {
                                        if (g_session.connection !== connectionObj) {
                                            const toArray = connectionObj.data.split(":::");
                                            const toObject = toArray[3];
                                            if (this.state.fileData !== null)
                                                this.sessionSignal.sendSignalShareArtifacts(
                                                    //from: g_session.connection.data,
                                                    this.state.fileData,
                                                    toObject
                                                    //type: "SHARE_ARTIFACTS"                
                                                );
                                            else {
                                                this.state.assetsArray.forEach(asset => {
                                                    this.sessionSignal.sendSignalShareArtifacts(asset, toObject);
                                                })
                                            }                                        

                                            if (this.state.showFileViewer && this.state.fileOpacity) {
                                                // If not 3D file share ongoing then send the last Opacity value 
                                                this.sessionSignal.sendSignalOpacity(this.state.fileOpacity, toObject); //TP-841
                                            } 
                                        }
                                    });

                                    // If 3D file is shared then resend the last rotation change
                                    if (!this.state.showFileViewer && this.state.rotationArray !== null && this.state.rotationArray !== '') {
                                        this.rotationTimer = setTimeout(() => {
                                            g_expertConnsArr.forEach(connectionObj => {
                                                if (g_session.connection !== connectionObj) {
                                                    const toArray = connectionObj.data.split(":::");
                                                    const toObject = toArray[3];
                                                    this.sessionSignal.sendSignal3DRotation(this.state.rotationArray, toObject);
                                                }
                                            });
                                            clearTimeout(this.rotationTimer)
                                        }, 3000);
                                    }
                                }
                            })
                        } 
                    }                            
                    if (this.state.bRemoteCapture && this.state.maxDivTechType === "IPK") {
                        this.props.updateRCStatus(false);
                        this.setState({ 
                            bRemoteCapture: false,
                            sRCTechDetails: '',
                            swapTechEmail: '',                            
                            swapCamTechData: ''
                        });                        
                        this.setState({
                            bAnnotate: false,
                            isOngoingMaxDivFeatures: false
                            /* bHideAnnotateColour: true,
                            bHideAnnotateShapes: true */
                        })
                        if (this.state.bHideAnnotateColour === false) {
                            let sketchPicker = document.getElementById('sketchPicker');
                            if (sketchPicker)
                                sketchPicker.style.display = 'none';
                        }
                        if (this.state.bHideAnnotateShapes === false) {
                            let shapeselector = document.getElementById('shapeselector');
                            if (shapeselector)
                                shapeselector.style.display = 'none';
                        }
                        this.props.updateScreenShareMaxdiv(false); // NS2-376
                        this.props.updateFileShareMaxdiv(false); // NS2-376
                        // TP-1372 -- To take care of the scenario when Max-view user gets disconnected before the image has been received
                        if (this.state.showHideMaxDivControl === true) {
                            printConsole ("Current Max-view user is not the same user for whom the RC image data is received by Primary expert...");
                            this.stopExpertRCAnnotate();                        
                        }
                    } else if (this.state.bRemoteCapture && this.state.bRCAnnotateOn) {
                        // open the dialog and start the timer  
                        /* this.setState({
                            openStreamDestroyedDialog: true,
                            imageCaptureTimer: 3
                        },//NS2-244 changed from 15 to 3
                            () => {
                                this.streamTimer = setInterval(this.decrementCounter.bind(this), 1000)
                            });
                        let senderInfo = g_subInfoArr[i].g_stream.connection.data;
                        //const senderInfoArr = typeof senderInfo === "string"?senderInfo.split(':::'):"";
                        this.setState({ 
                            sRCTechDetails: senderInfo,
                            bRCAnnotateOn: false
                        }); */
                        this.onClickCamera();
                    } else if (this.state.swapTechCamera) {                                
                        this.props.updateRCStatus(true);
                        this.props.updateCameraSwapStatus(true);
                        //clearInterval(this.streamTimer);
                        // open the dialog and start the timer                                
                        this.setState({
                            openCameraSwapDialog: true,
                            cameraSwapTimer : 10
                        },
                            () => {
                                this.streamTimer = setInterval(this.decrementCntr.bind(this), 1000)
                            });
                        let senderInfo = g_subInfoArr[i].g_stream.connection.data.split(":::");                                
                        this.setState({ 
                            swapTechEmail: senderInfo[3],
                            swapCamTechData: g_subInfoArr[i].g_stream.connection.data
                        });
                    } else if (this.state.maxDivCameraCount > 1 && this.state.gridViewMode === false && g_subInfo.g_stream.videoType !== "screen") {
                        this.props.updateRCStatus(true);
                        this.props.updateCameraSwapStatus(true);                                
                        this.setState({ isLoading: true }, () => {
                            this.showLoader('Swap to default camera');
                        });
                        //clearInterval(this.streamTimer);
                        // open the dialog and start the timer                                
                        this.setState({
                            //openCameraSwapDialog: true,
                            cameraSwapTimer : 10
                        },
                            () => {
                                this.streamTimer = setInterval(this.decrementCntr.bind(this), 1000)
                            });
                        let senderInfo = g_subInfoArr[i].g_stream.connection.data.split(":::");                                
                        this.setState({ 
                            swapTechEmail: senderInfo[3],
                            swapCamTechData: g_subInfoArr[i].g_stream.connection.data
                        });
                    } else {
                        this.props.updateRCStatus(false);
                        this.setState({ 
                            bRemoteCapture: false,
                            sRCTechDetails: '',
                            swapTechEmail: '',
                            swapCamTechData: ''
                        });
                        this.setState({
                            bAnnotate: false,
                            isOngoingMaxDivFeatures: false
                            /* bHideAnnotateColour: true,
                            bHideAnnotateShapes: true */
                        })
                        if (this.state.bHideAnnotateColour === false) {
                            let sketchPicker = document.getElementById('sketchPicker');
                            if (sketchPicker)
                                sketchPicker.style.display = 'none';
                        }
                        if (this.state.bHideAnnotateShapes === false) {
                            let shapeselector = document.getElementById('shapeselector');
                            if (shapeselector)
                                shapeselector.style.display = 'none';
                        }
                        this.props.updateScreenShareMaxdiv(false); // NS2-376
                        this.props.updateFileShareMaxdiv(false); // NS2-376
                        // TP-1372 -- To take care of the scenario when Max-view user gets disconnected before the image has been received
                        if (this.state.showHideMaxDivControl === true) {
                            printConsole ("Current Max-view user is not the same user for whom the RC image data is received by Primary expert...");
                            this.stopExpertRCAnnotate();                        
                        }
                    }
                    //TP-2810
                    if (shapeselector && shapeselector !== null)
                        document.getElementById(subscriberContainerDivId).appendChild(shapeselector);
                    if (eleMaxDivCtrls != null)
                        document.getElementById(subscriberContainerDivId).appendChild(eleMaxDivCtrls);
                    /* if (actionReqCtrls != null)
                        document.getElementById(subscriberContainerDivId).appendChild(actionReqCtrls); */
                    //(g_subInfoArr.length > 0) ? g_maxDivId = g_subInfoArr[0].g_divId : g_maxDivId = null;
                    const [divIdToReplace] = g_subInfoArr.filter(subInfo => subInfo.disableVideo === false && subInfo.g_stream.hasVideo === true); //TP-1596 -- find first tech with Video on
                    (g_subInfoArr.length > 0) ? (g_subInfoArr[0].disableVideo === false) ? maxDiv = g_subInfoArr[0].g_divId : (divIdToReplace) ? maxDiv = divIdToReplace.g_divId : maxDiv = g_subInfoArr[0].g_divId: maxDiv = null;
                    
                    if (maxDiv !== null) {                                
                        if (g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length === 1 || this.state.gridViewMode === false) {
                            if (this.state.showHideMaxDivControl === true && !this.state.expertSSAll){
                                // T32-463 -- New Multi-expert Signal trigger implementation
                                const dataArr = g_subInfoArr[0].g_stream.connection.data.split(":::")
                                this.sendSignalSyncExperts({
                                    show_grid: false,
                                    max_view_technician: dataArr[3], 
                                    hand_icon_enabled: true,
                                    header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378
                                    reason: "show_action"}, 
                                null);  // send the max div tech details                                
                            }
                            // NS2-123
                            // If the incoming feed in the maxdiv is coming from an Expert
                            // to another Expert and doesn't have a video then the
                            // Maxdiv controls/Hand Icon should not be shown on the
                            // maxdiv of that Expert screen else should be shown.
                            let tmp = g_subInfoArr[0].g_stream.connection.data;
                            let tmpSplit = tmp.split(':::');
                            if (tmpSplit[4] === 'true' && g_subInfoArr[0].g_stream.hasVideo === false) {                                        
                                this.maximizeDiv(null, {divId: maxDiv}, true);
                                //this.setState({ showHideDivIconAudioOnly: true })
                                
                            } else {
                                this.maximizeDiv(null, {divId: maxDiv});
                                //this.setState({ showHideDivIconAudioOnly: false })
                            }
                        }

                        // when we switch the maxdiv we reset the Max-div upload
                        // file icon. This is done in edge cases where maxdiv
                        // tech signs out of the session and is replaced by another one
                        // But if the Side Bar File Share is on and Max-div technician 
                        // signs out of the session, then we keep on showing the Shared File
                        // on the Expert screens (Primary & Secondary)
                        //console.log(this.state.shareOnDiv);
                        //console.log(this.state.bShareAsset);
                        if (dataSplit[4] === 'false' && this.props.loggedInUserId === this.state.currentPrimaryExpert && this.state.bShareAsset && this.state.shareOnDiv !== null && this.state.shareOnDiv !== '') {
                            this.setState({ bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: false });//NS2-457
                            this.onCloseFileViewer();
                        } else {                                    
                            let paramElemVdoChild;
                            if (threeClose !== undefined && threeClose !== null && this.state.gridViewMode === false && (g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length >= 1 || (g_subInfoArr.length === g_expertConnsArr.length && g_expertConnsArr.length !== 0))) {
                                printConsole("offline flow -- redraw the 3D file for New Max-view technician");
                                let tmp = g_subInfoArr[0].g_stream.connection.data;
                                let tmpSplit = tmp.split(':::');
                                if (tmpSplit[4] === 'true' && g_subInfoArr[0].g_stream.hasVideo === false) {                                             
                                    paramElemVdoChild = document.getElementById(maxDiv+'_name');
                                } else {
                                    [paramElemVdoChild] = document.getElementById(maxDiv).getElementsByClassName('OT_video-element');
                                }
                                threeDCanvas.style.width = document.getElementById(maxDiv).style.width;
                                threeDCanvas.style.left = '0px';
                                threeClose.style.right = '49%';
                                paramElemVdoChild.parentNode.insertBefore(threeDCanvas, paramElemVdoChild);
                                paramElemVdoChild.parentNode.insertBefore(threeClose, threeDCanvas);
                            }
                        }
                        // mute/unmute the incoming/new maxDiv tech mike as per the muteMikeForMaxDiv 
                        //this.muteExpertMikeInOutMaxDiv(!this.state.muteMikeForMaxDiv);
                        this.setState({ muteMikeForMaxDiv: g_subInfoArr[0].disableMike });
                        // show rc for incoming/new tech only for non screen share type of streams
                        // NS2-96
                        if (g_subInfo.g_stream.videoType !== "screen") {
                            this.props.showScreenShareHeaderButton(true); // for NS2-282
                            this.setState({
                                bHideExpertSSButton: true,
                                bHideTechnicianSSButton: true,
                                bHideZoomButton: true,
                                bHideTorchButton: true,
                                maxDivTechType: "WEB"
                            })
                            if (this.state.gridViewMode === false)
                                this.showHideRcForTech(true);
                        }
                        if (this.state.muteMikeForMaxDiv !== this.state.bMuteMyMike) {
                            //this.setState({ openMikeDialog: true });
                        }
                    }
                }                        
                if (divIdToRemove !== null && divIdToRemove !== undefined) {
                    let nodeToRemove = document.getElementById(divIdToRemove);                                
                    nodeToRemove.parentElement.removeChild(nodeToRemove);
                }
                //console.log(maxDiv);
                //console.log(g_maxDivId);
                if (((g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length === 1) && this.state.swapTechCamera === false) || this.state.gridViewMode === false || (this.state.prepareExpertSS === true && g_subInfo.g_stream.videoType === "screen")) {
                    if (maxDiv === null) {
                        if (/**TP-2462*/g_subInfo.g_stream.videoType !== "screen" &&  /*TP-2292*/ !this.state.yieldPositiveCase && !this.state.bRemoteCapture && !this.state.bRCAnnotateOn && !this.state.swapTechCamera && this.state.maxDivCameraCount < 2) 
                            this.triggerMessage(g_subInfo.g_stream.connection.data, "disconnect");
                        this.setState({ yieldPositiveCase: false }); //TP-2292
                        let tmp, tmpSplit;
                        let maxDivSub = {};
                        if (g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length === 1) {
                            maxDiv = g_subInfoArr[0].g_divId;
                            g_maxDivId = null;
                            maxDivSub = g_subInfoArr[0];  
                            tmp = maxDivSub.g_stream.connection.data;
                            tmpSplit = tmp.split(':::');
                            if (tmpSplit[4] === 'true' && maxDivSub.g_stream.hasVideo === false) {
                                this.maximizeDiv(null, {divId: maxDiv}, true);
                            } else {
                                this.maximizeDiv(null, {divId: maxDiv});
                            }                                  
                        }
                        /* else if (g_subInfoArr.length > 0) {
                            if (otExpObj.state.showHideMaxDivControl){
                                otExpObj.hideGridViewIcon(false);
                            }
                            /* maxDiv = g_maxDivId;
                            g_maxDivId = null;                                    
                            g_subInfoArr.forEach(val => {
                                if (val.g_divId === divId) 
                                    maxDivSub = val;
                            })                                    
                            tmp = maxDivSub.g_stream.connection.data;
                            tmpSplit = tmp.split(':::');
                            if (tmpSplit[4] === 'true' && maxDivSub.g_stream.hasVideo === false) {
                                otExpObj.maximizeDiv(null, {divId: maxDiv}, true);
                            } else {
                                otExpObj.maximizeDiv(null, {divId: maxDiv});
                            } 
                        } */                                                            
                        if (this.state.bMaxChat === true) {
                            this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
                        }
                    } else { 
                        if(this.state.prepareExpertSS === true && g_subInfo.g_stream.videoType === "screen"){
                            if (this.state.gridViewMode === false) {
                                let tmp, tmpSplit;
                                let maxDivSub = {};                                        
                                g_maxDivId = null;                                    
                                g_subInfoArr.forEach(val => {
                                    if (val.g_divId === divId) 
                                        maxDivSub = val;
                                })   
                                if (maxDivSub.g_stream !== undefined) {
                                    tmp = maxDivSub.g_stream.connection.data;
                                    tmpSplit = tmp.split(':::');
                                    if (tmpSplit[4] === 'true' && maxDivSub.g_stream.hasVideo === false) {
                                        this.maximizeDiv(null, {divId: maxDiv}, true);
                                    } else {
                                        this.maximizeDiv(null, {divId: maxDiv});
                                    } 
                                } else {
                                    this.maximizeDiv(null, {divId: maxDiv});
                                    g_subInfoArr.forEach( subInfo => {
                                        tmpSplit = subInfo.g_stream.connection.data.split(":::");
                                        if (tmpSplit[4] === 'false' /* && subInfo.disableVideo === false */)
                                            this.sendSignalExpertSS(false, subInfo.g_stream.connection);
                                    })
                                }                                
                            } else {
                                this.drawSubscriberUI(window.innerHeight, window.innerWidth, null);    
                                g_subInfoArr.forEach( subInfo => {
                                    let tmpSplit;
                                    tmpSplit = subInfo.g_stream.connection.data.split(":::");
                                    if (tmpSplit[4] === 'false' /* && subInfo.disableVideo === false */)
                                        this.sendSignalExpertSS(false, subInfo.g_stream.connection);
                                })
                            }
                        }                           
                        /* if (this.state.bMaxChat === true) {
                            this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
                        }  */                           
                    }
                } else if (this.state.prepareExpertSS === true && g_subInfo.g_stream.videoType !== "screen"){
                    this.drawScreenShareUI( {divId: g_maxDivId } );
                } else {
                    this.drawSubscriberUI(window.innerHeight, window.innerWidth, null);    
                    if (this.state.bAllChat === true)  {
                        this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
                    }                      
                }
                
                // Do not remove session joinees when screen share is ended
                // NS2-136
                if (g_subInfo.g_stream.videoType !== "screen") {
                    this.removeSessionJoinees(g_subInfo.g_stream);
                }    
            //}
        //}
        if (g_subInfo.g_stream.videoType === 'screen') {
            // NS2-126 & NS2-95
            if (this.state.swapTechCamera === false/*  || this.state.maxDivCameraCount === 0 */)
                this.props.updateRCStatus(false);
            // NS2-125 To take care of Tech Screen Share button when the technician stops 
            // the session without turning screen share off
            if (this.state.techShareScreen) {
                this.setState({ techShareScreen: false, isOngoingMaxDivFeatures: false })
                // for NS2-117
                this.props.updateScreenShareMaxdiv(false);
            }
            if (this.state.expertSSMode) {
                this.setState({ expertSSMode: false, prepareExpertSS: false, isOngoingMaxDivFeatures: false });
                this.props.updateScreenShareAll(false);
            }
        }
        propsSetJoineeCount(g_subInfoArr.length);
        if (g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length === 1) {
            this.hideGridViewIcon(true); 
        } else if (g_subInfoArr.length === 0) {                    
            this.props.updateFileShare(false);
            this.setState({ bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: false });//NS2-457
        };
        //NS2-486 & NS2-490 To check whether the expert going out of the session is the current Primary Expert or not
        // & If condition added for eliminating the scenarios for RC & screen share
        //console.log("User ID of disconnecting user: ",parseInt(dataSplit[0]));
        //console.log("Current Main Expert user id: ",this.state.currentPrimaryExpert);
        if (dataSplit[4] === 'true' && parseInt(dataSplit[0]) === this.state.currentPrimaryExpert && g_subInfo.g_stream.videoType !== "screen") {
            const {isGuest} = this.props;
            printConsole(`is expert a Guest user ? ${isGuest}`);
            let registeredExperts = g_expertConnsArr.filter(p => p.isRegistered);
            printConsole("Current joined Registered Experts ------>")
            printConsole(registeredExperts);
            if (isGuest && registeredExperts && registeredExperts.length === 0) {
                this.props.onSessionCmdChange('session_stopped');
            }
            else if (!isGuest && registeredExperts.length === 0) {
                // TP-2349 -- Handling the removal of the Guest user when the user that left is Primary expert who gets disconnected
                if (this.state.guestUserList.length > 0 && this.state.guestUserList.indexOf(dataSplit[3]) > -1) {
                    this.setState({ guestUser: dataSplit[3] });
                }
                //console.log("No more experts in session ---");
                //console.log("current Primary expert:", this.props.loggedInUserId);
                this.props.setPrimaryExpertIDApi(); // NS2-490    
                this.setState({ currentPrimaryExpert: this.props.loggedInUserId }); //NS2-486 
                // T32-463 -- New Multi-expert Signal trigger implementation
                this.sendSignalSyncExperts({
                    isPrimary: true,
                    expertId: this.props.user.id,
                    email: this.props.user.email,
                    firstname: this.props.user.firstname,
                    lastname: this.props.user.lastname,
                    joinTimeStamp: parseInt(this.state.loggedinUserTimeStamp),
                    reason: "primary_sync"
                    },
                null);             
                //NS2-482	
                this.setState({ showHideMaxDivControl: true }, () => {
                    // TP-359 -- New Socket signal to be sent to all technicians 
                    // announcing the current Primary expert 
                    this.sendSignalCurrentPrimaryExpert();            
                    this.setState({isParticipantChanged : true}, () => {
                        this.setState({isParticipantChanged : false});
                    });// to change the Side Bar button state
                    if (this.state.lastViewObj !== null) {
                        this.triggerDrawExpertUI();//TP-1452
                    }
                    if (this.state.gridViewMode === false) {
                        this.showHideRcForTech(true);
                        if (g_subInfoArr.length > 1 && this.state.userVideoCount > 1) {                            
                            this.hideGridViewIcon(false); 
                        }
                        if (this.state.hideButton === "d-none" && window.innerHeight <= 420) {
                            for (let ii=0; ii< g_subInfoArr.length; ii++) {
                                if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                                    //TP-2933
                                    let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                    if (zoomLevel) {
                                        let flag = g_subInfoArr[ii].zoomLevel;
                                        if (flag === 1){
                                            // Change the zoom level text on top of Video element
                                            zoomLevel.className = 'zoom-text';
                                            zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                            zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                            zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                                                            
                                        } else {
                                             // Change the zoom level text on top of Video element                    
                                            zoomLevel.className = 'zoom-text';
                                            zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                            zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                            zoomLevel.parentElement.style.display = "block";
                                        }
                                    }
                                }
                            }  
                        }
                    } else {
                        this.sendSignalMaxViewTech(false);
                        for (let ii=0; ii< g_subInfoArr.length; ii++) {
                            //show the disconnect user button on top of the video feed elements
                            let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                            if (disconnectuser !== undefined && disconnectuser !== null) {
                                disconnectuser.style.display = "block";                    
                            }
            
                            //show the gridcontrol icons on top of video element
                            let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                            if (gridcontrol !== undefined && gridcontrol !== null)
                                gridcontrol.style.display = "block";
                                    
                            // Show the Video Turn off Icon on Primary expert's Grid View
                            let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);
                            if (videoIcon !== undefined && videoIcon !== null) 
                                videoIcon.style.display = "block";

                            if(g_subInfoArr[ii].zoom_enabled) {
                                    // enable the zoom Icon for Primary expert
                                    let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                                    if (zoomIcon !== undefined && zoomIcon !== null) {
                                        zoomIcon.style.display = "block";
                                        let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");
                                        zoomBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                        zoomIcon.onClick = function (e) {
                                            this.rotateZoomLevel(g_subInfoArr[ii].g_divId);
                                        }
                                    }
                            }  
                                    
                            if(g_subInfoArr[ii].torch_enabled) {
                                    // enable the torch Icon for Primary expert
                                    let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                                    if (torchIcon !== undefined && torchIcon !== null) {
                                        torchIcon.style.display = "block";
                                        let torchBtn = torchIcon.getElementsByClassName("grid-icon");                            
                                        torchBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                        torchIcon.onClick = function (e) {
                                            this.toggleUserTorch(g_subInfoArr[ii].g_divId);
                                        }
                                    }
                            }    
                        }
                    }
                    
                    //TP-1621 -- Handling the new Primary expert's screen, if the RC had been triggered by the
                    //previous Primary expert, but it was disconnected before the RC image could reach 
                    // the new Primary expert
                    if (this.state.bRemoteCapture && this.state.bAnnotate === false){
                        this.stopExpertRCAnnotate(); // NS2-345
                        this.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
                        this.setState({bRemoteCapture: false, isOngoingMaxDivFeatures: false});
                    }

                    // T32-624 -- Handling of Seamless transfer of Feature (File share) if Primary expert leaves the session
                    if(this.state.assetsArray.length > 0) {
                        //TP-2695
                        if (this.state.assetsArray[0].format === undefined) {
                            this.handleArtifactsOnPrimaryExpert(this.state.assetsArray);
                        } else {
                            const paramElem = document.getElementById(g_maxDivId);
                            const isExpertDiv = isDivExpert(g_maxDivId);
                            this.handleArtifactsNew({ assetsArray: this.state.assetsArray, paramElem, isExpertDiv, flag: true });
                        }
                    }

                    //TP-740 -- Implementing the seamless continuation of Recording feature when Control tranfer happens
                    if (isrecording_active === true && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                        this.sendSignalRecordingContinue(parseInt(dataSplit[0]));
                        this.onClickRecordScreen("continue"); //TP-1877
                    } else if (isrecording_active === true && isSilentRecording === true){
                        printConsole("Currently Silent recording isn't supported on Webapp Expert side");
                        /* this.sendSignalRecordingStop();
                        //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                        this.sendSignalSyncExperts({
                            isrecording_active: false,
                            is_silent_recording: isSilentRecording,
                            recording_folder_name: this.state.recFileName,
                            frames_per_seconds: screenSettings[1].fps,
                            frame_count: count,
                            reason: "recording_sync"},
                        null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                        this.setState({ recFileName: '' }); */
                    } else if (isrecording_active === true && isSilentRecording === false && (this.props.isSafari || this.props.isFirefox || this.state.hideButton === 'd-none')) {
                        // TP-2262 -- Handling the stopping of Recording if Expert who takes up control is on Safari/Firefox browsers
                        printConsole("Safari/Firefox browser doesn't support recording so stopping the previous recording")
                        this.sendSignalRecordingStop(null, dataSplit[3]);//TP-2095
                        //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                        this.sendSignalSyncExperts({
                            isrecording_active: false,
                            is_silent_recording: isSilentRecording,
                            recording_folder_name: this.state.recFileName,
                            frames_per_seconds: screenSettings[1].fps,
                            frame_count: count,
                            reason: "recording_sync"},
                        null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                        this.setState({ recFileName: '' });
                    }
                });
                // TP-247 --  Handling of automatic turning on the Remote user cameras
                // If the current Primary expert doesn't have the Video turn on/off button enabled
                // and if all remote user's Videos are turned off
                if (this.state.userVideoCount === 0 && this.props.sessionInfo.can_turn_off_user_video === false) {
                    for(let ii = 0; ii < g_subInfoArr.length; ii++)
                    {
                        g_subInfoArr[ii].g_subscriber.subscribeToVideo(true);
                        g_subInfoArr[ii].disableVideo = false;   
                        let tmp = g_subInfoArr[ii].g_stream.connection.data.split(":::");       
                        let to = tmp[3];
                        printConsole(`Turn on/off video signal being sent to user ----> ${to}`);
                        this.sessionSignal.sendSignalTurnOnUserVideo(true, to);
                    }
                    this.setState({ g_subInfoArr }, () => {
                        this.setState({ userVideoCount: g_subInfoArr.length }, () => {
                            printConsole(`uservideocount: ${this.state.userVideoCount}`);
                            if (this.state.userVideoCount > 1 && this.state.gridViewMode === false)
                                this.hideGridViewIcon(false);
                        })
                    });
                }
                this.props.updateShowHideHeaderActionButtons(true);
                // TP-1546 -- only turn on the Side Bar Screencast/Fileshare Icons if at least one tech has Video turned on
                if (this.state.userVideoCount > 0) {
                    this.props.showScreenShareHeaderButton(false); // for NS2-282
                }
                this.clearPassiveExpertFeature();
            } else if (!isGuest && registeredExperts.length > 0) {
                //console.log("More experts are in session ---");
                //NS2-482
                if (this.state.loggedinUserTimeStamp < registeredExperts[0].data.split(":::")[6] && this.state.showHideMaxDivControl === false) {
                    // TP-2349 -- Handling the removal of the Guest user when the user that left is Primary expert who gets disconnected
                    if (this.state.guestUserList.length > 0 && this.state.guestUserList.indexOf(dataSplit[3]) > -1) {
                        this.setState({ guestUser: dataSplit[3] });
                    }
                    //console.log("current Primary expert:", this.props.loggedInUserId);
                    this.props.setPrimaryExpertIDApi(); //NS2-490
                    this.setState({ currentPrimaryExpert: this.props.loggedInUserId }); //NS2-486
                    // T32-463 -- New Multi-expert Signal trigger implementation
                    this.sendSignalSyncExperts({
                        isPrimary: true,
                        expertId: this.props.user.id,
                        email: this.props.user.email,
                        firstname: this.props.user.firstname,
                        lastname: this.props.user.lastname,
                        joinTimeStamp: parseInt(this.state.loggedinUserTimeStamp),
                        reason: "primary_sync"
                        },
                    null);                    
                    this.setState({ showHideMaxDivControl: true }, () => {
                        // TP-359 -- New Socket signal to be sent to all technicians 
                        // announcing the current Primary expert 
                        this.sendSignalCurrentPrimaryExpert();
                        this.setState({isParticipantChanged : true}, () => {
                            this.setState({isParticipantChanged : false});
                        });// to change the Side Bar button state
                        if (this.state.lastViewObj !== null) {
                            this.triggerDrawExpertUI();//TP-1452
                        }
                        if (this.state.showHideDivIconAudioOnly === false && this.state.gridViewMode === false) {
                            // In Max-view mode -- Sync experts reason="show_action" signal sent to all experts in session
                            // with show_grid = false
                            g_subInfoArr.forEach(subInfo => {
                                if (subInfo.g_divId === g_maxDivId) {
                                    const dataArr = subInfo.g_stream.connection.data.split(":::")
                                    // T32-463 -- New Multi-expert Signal trigger implementation
                                    this.sendSignalSyncExperts({
                                        show_grid: false,
                                        max_view_technician: dataArr[3], 
                                        annotation_active: this.state.bAnnotate, 
                                        zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                        zoom_level: subInfo.zoomLevel,
                                        torch_button_active: subInfo.torchActive,
                                        annotate_color: "red",
                                        shape_selected: "freehand",
                                        hand_icon_enabled: true,  
                                        header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                        reason: "show_action" },
                                    null);
                                }
                            });                            
                            this.showHideRcForTech(true); 
                            if (g_subInfoArr.length > 1 && this.state.userVideoCount > 1)
                                this.hideGridViewIcon(false);         
                            if (this.state.hideButton === "d-none" && window.innerHeight <= 420) {
                                for (let ii=0; ii< g_subInfoArr.length; ii++) {
                                    if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                                        //TP-2933
                                        let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                        if (zoomLevel) {
                                            let flag = g_subInfoArr[ii].zoomLevel;
                                            if (flag === 1){
                                                // Change the zoom level text on top of Video element
                                                zoomLevel.className = 'zoom-text';
                                                zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                                zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                                zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                                                                    
                                            } else {
                                                // Change the zoom level text on top of Video element                    
                                                zoomLevel.className = 'zoom-text';
                                                zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                                zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                                zoomLevel.parentElement.style.display = "block";
                                            }
                                        }
                                    }
                                }  
                            }                            
                        } else if (this.state.gridViewMode === true) {
                            this.sendSignalMaxViewTech(false);
                            // In Grid View Mode -- Sync experts reason="show_action" signal sent to all experts in session
                            // with show_grid = true
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            this.sendSignalSyncExperts({
                                show_grid: true,
                                max_view_technician: "", 
                                annotation_active: this.state.bAnnotate, 
                                annotate_color: "red",
                                shape_selected: "freehand",
                                hand_icon_enabled: true,        
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378                    
                                reason: "show_action" },
                            null);
                            for (let ii=0; ii< g_subInfoArr.length; ii++) {
                                //show the disconnect user button on top of the video feed elements
                                let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                                if (disconnectuser !== undefined && disconnectuser !== null) {
                                    disconnectuser.style.display = "block";                    
                                }
                
                                //show the gridcontrol icons on top of video element
                                let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                                if (gridcontrol !== undefined && gridcontrol !== null)
                                    gridcontrol.style.display = "block";
                                        
                                // Show the Video Turn off Icon on Primary expert's Grid View
                                let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);
                                if (videoIcon !== undefined && videoIcon !== null) 
                                    videoIcon.style.display = "block";
                                    
                                if(g_subInfoArr[ii].zoom_enabled) {
                                        // enable the zoom Icon for Primary expert
                                        let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                                        if (zoomIcon !== undefined && zoomIcon !== null){
                                            zoomIcon.style.display = "block";
                                            let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");
                                            zoomBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                            zoomIcon.onClick = function (e) {
                                                this.rotateZoomLevel(g_subInfoArr[ii].g_divId);
                                            }
                                        }
                                }  
                                        
                                if(g_subInfoArr[ii].torch_enabled) {
                                        // enable the torch Icon for Primary expert
                                        let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                                        if (torchIcon !== undefined && torchIcon !== null) {
                                            torchIcon.style.display = "block";
                                            let torchBtn = torchIcon.getElementsByClassName("grid-icon");                            
                                            torchBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                            torchIcon.onClick = function (e) {
                                                this.toggleUserTorch(g_subInfoArr[ii].g_divId);
                                            }
                                        }
                                }
                            }
                        }
                        //TP-1621 -- Handling the new Primary expert's screen, if the RC had been triggered by the
                        //previous Primary expert, but it was disconnected before the RC image could reach 
                        // the new Primary expert
                        if (this.state.bRemoteCapture && this.state.bAnnotate === false){
                            this.stopExpertRCAnnotate(); // NS2-345
                            this.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
                            this.setState({bRemoteCapture: false, isOngoingMaxDivFeatures: false});
                        }

                        // T32-624 -- Handling of Seamless transfer of Feature (File share) if Primary expert leaves the session
                        if(this.state.assetsArray.length > 0) {
                            //TP-2695
                            if (this.state.assetsArray[0].format === undefined) {
                                this.handleArtifactsOnPrimaryExpert(this.state.assetsArray);
                            } else {
                                const paramElem = document.getElementById(g_maxDivId);
                                const isExpertDiv = isDivExpert(g_maxDivId);
                                this.handleArtifactsNew({ assetsArray: this.state.assetsArray, paramElem, isExpertDiv, flag: true });
                            }
                        }

                        //TP-740 -- Implementing the seamless continuation of Recording feature when Control tranfer happens
                        if (isrecording_active === true && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                            this.sendSignalRecordingContinue(parseInt(dataSplit[0]));
                            this.onClickRecordScreen("continue"); //TP-1877
                        } else if (isrecording_active === true && isSilentRecording === true){
                            printConsole("Currently Silent recording isn't supported on Webapp Expert side");
                            /* this.sendSignalRecordingStop();
                            //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                            this.sendSignalSyncExperts({
                                isrecording_active: false,
                                is_silent_recording: isSilentRecording,
                                recording_folder_name: this.state.recFileName,
                                frames_per_seconds: screenSettings[1].fps,
                                frame_count: count,
                                reason: "recording_sync"},
                            null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                            this.setState({ recFileName: '' }); */
                        } else if (isrecording_active === true && isSilentRecording === false && (this.props.isSafari || this.props.isFirefox || this.state.hideButton === 'd-none')) {
                            // TP-2262 -- Handling the stopping of Recording if Expert who takes up control is on Safari/Firefox browsers
                            printConsole("Safari/Firefox browser doesn't support recording so stopping the previous recording")
                            this.sendSignalRecordingStop(null, dataSplit[3]);//TP-2095
                            //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                            this.sendSignalSyncExperts({
                                isrecording_active: false,
                                is_silent_recording: isSilentRecording,
                                recording_folder_name: this.state.recFileName,
                                frames_per_seconds: screenSettings[1].fps,
                                frame_count: count,
                                reason: "recording_sync"},
                            null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                            this.setState({ recFileName: '' });
                        }
                    });
                    this.props.updateShowHideHeaderActionButtons(true);
                    // TP-1546 -- only turn on the Side Bar Screencast/Fileshare Icons if at least one tech has Video turned on
                    if (this.state.userVideoCount > 0) {
                        this.props.showScreenShareHeaderButton(false); // for NS2-282
                    }
                    this.clearPassiveExpertFeature();
                }
            }
        } else if (dataSplit[4] === 'true') {
            //TP-2090 -- Passive expert left the session call so check the current number of registered experts 
            // that are currently there in the session and end the call if count of registered experts is 0
            const {isGuest} = this.props;
            printConsole(`is expert a Guest user ? ${isGuest}`);
            let registeredExperts = g_expertConnsArr.filter(p => p.isRegistered);
            printConsole("Current joined Registered Experts ------>")
            printConsole(registeredExperts);
            if (isGuest && registeredExperts && registeredExperts.length === 0) {
                this.props.onSessionCmdChange('session_stopped');
            }
        }
        // To check whether the technician leaving the session makes this an only experts session
        // If true then expertSS should be stopped
        else if (dataSplit[4] === 'false' && this.state.showHideMaxDivControl ) {
            if ((g_subInfoArr.length === g_expertConnsArr.length && g_expertConnsArr.length !== 0) || this.state.userVideoCount === 0){//TP-2447
                //console.log("Only experts remaining in this session!!!");
                if (this.state.expertSSAll) {
                    this.onClickExpertScreenShareForAll(false);
                    this.setState({ isOngoingMaxDivFeatures: false })
                } //console.log(this.state.bShareAsset) 
                if (this.state.bShareAsset) {
                    this.props.updateFileShare(false);
                    this.setState({ bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: false });
                    this.onCloseFileViewer();
                    if (this.closeSubscriber3dObject) {
                        this.closeSubscriber3dObject();
                    } else {
                        this.remove3dObject();
                    }
                    //this.onClickUploadFileForAll();
                }
                //if (g_expertConnsArr.length >= 2)
                    //this.gridView();
                    //this.drawSubscriberUI(window.innerHeight, window.innerWidth, null);    
            }
        }
    }

    //TP-2292 -- Control the hide of Request Dialog box if the expert who has requested controls
    // goes out of session
    checkIfRequestDialogisOpen = (expertEmail) => {
        if (this.state.expertEmail === expertEmail && this.state.showControlRequestDialog) {
            const { expertFirstName, expertLastName } = this.state;
            this.sendSignalYieldDisableHandIcon(false, `${expertFirstName} ${expertLastName}`, null);
            this.setState({ yieldPositiveCase: true }, () => {
                this.hideRequestDialog();
                this.triggerMessage(`${expertFirstName} ${expertLastName}`, "yieldpositive");
            });
        }
    }

    //TP-1452 --  New Expert UI redraw method
    // This is triggered when a Passive expert becomes Primary while in Screen share mode
    triggerDrawExpertUI = () => {
        printConsole("trigger change in Primary expert view");
        const {show_grid, max_view_technician} = this.state.lastViewObj;
        let {g_subInfoArr} = this.state;
        if (show_grid === true && this.state.gridViewMode === false) {
            printConsole("Switching the new Primary expert's view to Grid View");
            this.gridView();    
        } else if (show_grid === false && max_view_technician !== "") {
            g_subInfoArr.forEach((subInfo, index) => {
                let connData = subInfo.g_stream.connection.data.split(":::");
                if (connData[3] === max_view_technician) { 
                    // add to subscriber array at the begining of the array                    
                    /* if (index !== 0)
                        g_subInfoArr = array_move(g_subInfoArr, index, 0);
                    this.setState({g_subInfoArr: g_subInfoArr}, () => { */                        
                        if (subInfo.g_divId !== g_maxDivId || this.state.gridViewMode === true) {
                            //const maxViewMessage = "Switching to full screen mode " + connData[1] + " " + connData[2];
                            const name = connData[1] + " " + connData[2];
                            printConsole(`Switching to full screen mode ${name}`);                            
                            this.maximizeDiv(null, {divId: subInfo.g_divId});
                        }
                    //});
                }
            });
        }
        //this.setState({ lastViewObj: null }); TP-1023 (for iOS Primary expert leaving the session in Screen cast mode)
    }

    decrementExpertCounter = () => {
        if (this.state.g_subInfoArr.length === 0){
            this.setState((prevState) =>({
                expertWaitTimer: prevState.expertWaitTimer - 1
            }),() => {
                if(this.state.expertWaitTimer === 0){
                    this.closeExpertWindow();
                }
            });
        } else if (this.state.g_subInfoArr.length >= 1) {
            this.stopExpertCounter();
        }
    } 

    stopExpertCounter = () => {
        printConsole("Stop the 30 second timer if the first user's feed comes in");
        this.setState({
            expertWaitTimer: 0
        },() => {
            clearInterval(this.expertTimer);
        });
    }

    // Method to close the Expert's session screen
    closeExpertWindow = () => {
        const message = "Ending the call due to failure to establish a connection with the callee(s). Please try calling again.";   
        console.log(message);
        this.setState({ flashMsgText: "Ending call", showFlashMessage: true, flashLeft: "", flashTop: "" });
        this.timeot = setTimeout(() => {
            this.triggerStopSession();
            this.setState({
                expertWaitTimer: 0
            },() => {
                if(typeof(Storage) !== "undefined"){
                    printConsole(`setting refresh page session storage to "true"}`)
                    sessionStorage.setItem("refresh-page", "true");
                }else{
                    printConsole(`Sorry, your browser does not support Web Storage...`)
                }
                clearInterval(this.expertTimer);
                this.setState({ isLoading: false });
                this.props.clearSessionToken();//T32-420 clear the session token                
                this.props.updateSessionTimer(false);
                this.props.changeStartStop();                
                this.notifyGroupWhenDisconnected();
                this.props.onClickBack();
            });
        }, 4000)
    }

    setOnlineUsers = (usernames = []) => {
        //console.log("Here...")
        const {g_subInfoArr} = this.state;
        let {calleeList} = this.state;
        let {groupUsers, onlineUsers, user} = this.props;
        let flag = false;
        if (usernames.length > 0) {
            onlineUsers = usernames;
            flag = true;
        }
        //printConsole(onlineUsers);
        let len = groupUsers.length;
        let {onlineList} = this.state;
        for (let ii = 0; ii<len; ii++) {
            if (user.email !== groupUsers[ii].email && 
                onlineUsers.includes(groupUsers[ii].email) === true && 
                !(groupUsers[ii].isadmin === true && !groupUsers[ii].isexpert) && 
                onlineList.some(on => on.email === groupUsers[ii].email) === false)
            {
                onlineList.push(groupUsers[ii]);
            }
        }

        let online = onlineList;
        // Exclude from online users the participants list
        if (g_subInfoArr.length > 0) {
            for (let i=0; i<g_subInfoArr.length; i++) {
                const dataArr = g_subInfoArr[i].g_stream.connection.data.split(":::");
                let [tmp] = onlineList.filter (o => o.email === dataArr[3]);
                if (tmp !== undefined) {
                    let index = onlineList.indexOf(tmp);
                    onlineList.splice(index, 1);
                }
            }
            /* online = onlineList.filter(on => g_subInfoArr.some(subInfo => {
                const dataArr = subInfo.g_stream.connection.data.split(":::");
                return dataArr[3] !== on.email 
            })) */
        }        
        printConsole(`Online users:`);
        printConsole(online);
        this.setState({ onlineList: online }, () => {
            //TP-2381 -- Handling of showing the invite users phone icons as busy
            // when the session has started due to a Group call instead if a user call
            //console.log(calleeList);
            if (calleeList === null) {
                online.forEach(o => {
                    if(o.isBusy === undefined)
                        o.isBusy = false;
                    else if (flag === true)
                        o.isBusy = false;
                })
            } else {
                for (let jj = 0; jj< online.length; jj++){
                    if(calleeList.indexOf(online[jj].email) > -1) {
                        //console.log("isBusy..........",online[jj].email)
                        online[jj].isBusy = true;
                        this.setState({ onlineList: online });
                        //calleeList = calleeList.splice(calleeList.indexOf(online[jj].email), 1);
                    } else {
                        online[jj].isBusy = false;
                        this.setState({ onlineList: online });
                    }
                }
            }
            //console.log("changed online user list: ",online);
            //console.log(calleeList);
            this.setState({calleeList: null});
        })
    }

    //TP-2474 -- Handling of UI on Passive expert side when, Primary expert does Erase canvas feature
    triggerCanvasErase = (json) => {
        if (this.state.bAnnotate) {
            const { from } = json;
            const expertName = from.firstName + " " + from.lastName;
            this.triggerMessage(expertName,"eraseCanvas");
        }
    }

    // TP-2349 -- set the guest user list on the Passive expert's
    setGuestUserList = (json) => {
        const {guestUserList} = json.data;
        this.setState({ guestUserList });
    }

    // TP-2349 -- Add user into the guest user list for Primary expert
    addGuestUserList = (val) => {
        this.setState({ guestUserList: [...this.state.guestUserList, val]}, () => {
            printConsole(`Add the user ${val} into the guest user list...`);
            this.sendSignalSyncExperts({
                guestUserList: this.state.guestUserList,
                reason: "guest_user_sync"},
            null);
        });
    }
  
    // TP-2349 -- Remove user from the guest user list for Primary expert
    removeGuestUserList = (val) => {
        let {guestUserList} = this.state;
        if (guestUserList.indexOf(val) > -1) {
            printConsole(`Remove the email id ${val} from guest user list `);
            this.setState({ guestUser: '' });
            guestUserList = guestUserList.splice(guestUserList.indexOf(val), 1);
            this.setState({ guestUserList }, ()=> {
                this.sendSignalSyncExperts({
                    guestUserList: this.state.guestUserList,
                    reason: "guest_user_sync"},
                null);
            });
        }
    }
    
    addSessionJoinees = (gStream, subInfo) => {
        //printConsole(`Add session joinee: ${gStream}`);
        let { data } = gStream.connection;
        let token_data = data.split(':::');
        let isExpert = token_data[4];
        let name = isExpert === 'true' ? ('* ' + token_data[1] + ' ' + token_data[2]) :
        (token_data[1] + ' ' + token_data[2]);
        const userId = token_data[0];
        let joineeObj = {
            userId,
            disableVideo: subInfo.disableVideo,
            disableAudio: subInfo.disableAudio,
            disableMike: subInfo.disableMike,
            disableRemoteAudio: subInfo.disableRemoteAudio, //T32-413
            name
        }
        this.setState(prev => ({
            sessionJoinees: [
                ...prev.sessionJoinees,
                joineeObj
            ]
        }), () => {            
            //printConsole(this.state.sessionJoinees);
            this.props.onSetSessionJoinees(this.state.sessionJoinees);
        });

    }

    removeSessionJoinees = (gStream) => {
        //printConsole(`Remove session joinee: ${gStream}`);
        let { data } = gStream.connection;
        let token_data = data.split(':::');
        let isExpert = token_data[4];
        let name = isExpert === 'true' ? ('* ' + token_data[1] + ' ' + token_data[2]) :
            (token_data[1] + ' ' + token_data[2]);
        let { sessionJoinees } = this.state;
        let valueToRemove = name;
        let names = sessionJoinees.filter(n => n.name !== valueToRemove);
        this.setState({ sessionJoinees: names }, () => {
            this.props.onSetSessionJoinees(this.state.sessionJoinees);
        });

    }

    hideGridViewIcon = (val) => {
        this.setState({ hideGridViewIcon: val });
    }

    //Screen recording methods
    startRecording = async () => {
        try {
            // calling the screen capture
            this.captureScreen((screen) => {
                this.screen = screen
                // calling the camera feed containing only the audio
                this.captureCamera((camera) => {
                    this.recorder = RecordRTC([screen, camera], {
                        type: 'video',
                        mimeType: 'video/webm',
                        video: { width: 1280, height: 720 }
                    });

                    this.recorder.startRecording();
                    this.setState({ showRecordingBeacon: true }) // show the beacon
                });
            });
        }catch(error){
            console.error(error);
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording, isOngoingMaxDivFeatures: !prevState.startRecording
            }))
        }
    }

    startScreenshotRecording = async () => {
        try {
            const outputStream = this.captureAudioStreams();
                console.log(outputStream);
                console.log("mimeType audio webm supported? ",MediaRecorder.isTypeSupported('audio/webm'));
                this.recorder = new MediaRecorder(outputStream, { mimeType: "audio/webm" })
                this.chunks = []
    
                this.recorder.ondataavailable = event => {
                    if (event.data.size > 0) {
                        this.chunks.push(event.data)
                    }
                }
                this.recorder.start();
            this.captureScreenShots();
            this.screenshotsTimer = setInterval(() => {
                this.captureScreenShots();
            }, 1000); // duration of each screenshot set as 1 sec          
                               
            this.setState({ showRecordingBeacon: true }) // show the beacon 
        } catch (error) {
            printConsole(error);
        }
    }

    captureScreenShots = () => {
        frameNo += 1;
        let screenshotTargetParent;
        if (this.state.gridViewMode === false) {
            screenshotTargetParent = document.getElementById(g_maxDivId);
            if (screenshotTargetParent === null) return;
            let screenshotTarget = screenshotTargetParent.getElementsByClassName('OT_video-element');
            if (screenshotTargetParent.getElementsByTagName('canvas')[0] !== undefined && screenshotTargetParent.getElementsByTagName('canvas')[0] !== null) {
                //screenshotTarget[0].parentNode.removeChild(document.getElementsByTagName('canvas')[0]);
                const canvasArr = screenshotTargetParent.getElementsByTagName('canvas'); 
                if (canvasArr.length > 0) {
                    for (let ii = 0; ii < canvasArr.length; ii++) {
                        console.log(canvasArr[ii].id);
                        if (canvasArr[ii].id === 'canvas' || canvasArr[ii].id === 'canavas-3d-container') 
                            continue;
                        else
                            canvasArr[ii].remove();
                    }
                }
            }

            const rect = screenshotTarget[0].getBoundingClientRect();

            console.log(rect);
            var canvas = document.createElement('canvas');
            canvas.id = 'recording_frame_'+g_maxDivId;
            canvas.width = rect.width;
            canvas.height = rect.height;
            var ctx = canvas.getContext('2d');
            //draw image to canvas. scale to target dimensions
            ctx.drawImage(screenshotTarget[0], 0, 0, canvas.width, canvas.height);
            // insert canvas after video element
            if (screenshotTarget.length < 1)
                screenshotTargetParent.appendChild(canvas);
            else 
                screenshotTarget[0].parentNode.insertBefore(canvas, screenshotTarget[0].nextSibling);
        }
        else {
            const {g_subInfoArr} = this.state;
            g_subInfoArr && g_subInfoArr.forEach(subInfo => {
                screenshotTargetParent = document.getElementById(subInfo.g_divId);
                if (screenshotTargetParent === null) return;
                let screenshotTarget = screenshotTargetParent.getElementsByClassName('OT_video-element');
                if (screenshotTargetParent.getElementsByTagName('canvas')[0] !== undefined && screenshotTargetParent.getElementsByTagName('canvas')[0] !== null) {
                    //screenshotTarget[0].parentNode.removeChild(document.getElementsByTagName('canvas')[0]);
                    const canvasArr = screenshotTargetParent.getElementsByTagName('canvas'); 
                    if (canvasArr.length > 0) {
                        for (let ii = 0; ii < canvasArr.length; ii++) {
                            console.log(canvasArr[ii].id);
                            if (canvasArr[ii].id === 'canvas' || canvasArr[ii].id === 'canavas-3d-container') 
                                continue;
                            else
                                canvasArr[ii].remove();
                        }
                    }
                }
        
                const rect = screenshotTarget[0].getBoundingClientRect();
        
                console.log(rect);
                var canvas = document.createElement('canvas');
                canvas.id = 'recording_frame_'+subInfo.g_divId;
                canvas.width = rect.width;
                canvas.height = rect.height;
                var ctx = canvas.getContext('2d');
                //draw image to canvas. scale to target dimensions
                ctx.drawImage(screenshotTarget[0], 0, 0, canvas.width, canvas.height);
                // insert canvas after video element
                if (screenshotTarget.length < 1)
                    screenshotTargetParent.appendChild(canvas);
                else 
                    screenshotTarget[0].parentNode.insertBefore(canvas, screenshotTarget[0].nextSibling);
            })
        }
        

        html2canvas(document.body).then((canvas) => {
            console.log("New screenshot is created: ",Date.now() - this.state.recFileName);
            const { loggedInUserId, selectedGroupName, user } = this.props;
            let {selectedGroupId} = this.props;

            // create the watermark on the image frames
            const text = "Recording Details: "+selectedGroupName+" - "+user.firstname+" "+user.lastname+" - "+this.getDateStringForWatermark();
            var tempCtx=canvas.getContext('2d');
            tempCtx.font="15px Titillium Web";
            var textWidth=tempCtx.measureText(text).width;
            tempCtx.globalAlpha=.75;
            tempCtx.fillStyle='white'
            tempCtx.fillText(text,canvas.width-textWidth-10,canvas.height-50);
            tempCtx.fillStyle='black'
            tempCtx.fillText(text,canvas.width-textWidth-10+2,canvas.height-50+2);
            
            //convert to desired file format
            /* var dataURI = canvas.toDataURL('image/jpeg'); 
            console.log(dataURI)
            console.log("Downloading the image jpg file: ",Date.now() - otExpObj.state.recFileName);
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = dataURI;
            a.download = `Telepresenz_${this.getDateString()}.jpeg`;
            a.click();
            window.URL.revokeObjectURL(dataURI); */
            
            console.log("Converting canvas image to base 64 format: ",Date.now() - this.state.recFileName)
            base64image = canvas.toDataURL("image/jpeg").replace('data:image/jpeg;base64,', '');
            console.log("Finished converting image to base 64 format: ",Date.now() - this.state.recFileName);
            //console.log(base64image);   
            //window.location.href = base64image; return;            
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            const screensPayload = {
                groupId: selectedGroupId,
                userId: loggedInUserId,
                folderName: selectedGroupId+"_"+loggedInUserId+"_"+recFileName,
                sequenceId: Date.now(),                                    
                from: user.email,                                   
                imgData: base64image
            }
            console.log("sending the screen shots data as socket signal payload: ",Date.now() - this.state.recFileName);
            this.sessionSignal.sendSignalScreenShotImage(screensPayload); 
			console.log("current frameNo:  ", frameNo);
            console.log("total number of frames --->", this.state.totalFrames)
                if (frameNo >= this.state.totalFrames) {
                    console.log("sending the audio as socket signal payload when recording is stopped: ",Date.now() - this.state.recFileName);
                    this.sessionSignal.sendSignalAudioData(this.state.audioDataPayload);
                    clearInterval(this.screenshotsTimer1);
                    this.setState({ audioDataPayload: null })
                }
            const {g_subInfoArr} = this.state;
            // removing all the individual canvas created for getting the recording image frames 
            g_subInfoArr && g_subInfoArr.forEach(subInfo => {
                if (document.getElementById('recording_frame_'+subInfo.g_divId) !== undefined && document.getElementById('recording_frame_'+subInfo.g_divId) !== null) {
                    //screenshotTarget[0].parentNode.removeChild(document.getElementsByTagName('canvas')[0]);
                    const old_canvas = document.getElementById('recording_frame_'+subInfo.g_divId);
                    old_canvas.remove();                
                }
            })
        });    
    }

    onRecorderStopEachClip = (stopClipNo) => {
        printConsole(`New chunk of audio recording created: ${performance.now()}`);
        //console.log(this.chunks);
        printConsole(`Converting audio webm to m4a file: ${performance.now()}`);
        const blob = new Blob(this.chunks, {
            type: 'audio/m4a'
        })
        printConsole(`Finished converting audio webm to m4a file: ${performance.now()}`);
        this.chunks = [];
    
        printConsole(`Stopping Audio Clip # ${stopClipNo}`);
        let reader = new FileReader();
        printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
    
        reader.onload = function() {            
            var srcData = reader.result;
            // use byteLength to check the size
            const bytes = srcData.byteLength;
            printConsole(`byte size of Audio file: ${bytes}`);

            //if (bytes > 0) {
                printConsole(`sending the audio clip data No: ${audioNo} as socket signal payload: ${performance.now()}`);
                // Sending the audio clips as Socket Signal payload to the Server-side
                otExpObj.sendSignalRecordingUploadAudio(srcData, otExpObj.callBackForAudioRecording); 
            //}
        };
    }

    onRecorderStopInterval = (stopClipNo) => {
        //clipNo += 1
        printConsole(`New chunk of audio recording created: ${performance.now()}`);
        //console.log(this.chunks);
        printConsole(`Converting audio webm to m4a file: ${performance.now()}`);
        const blob = new Blob(this.chunks, {
            type: 'audio/m4a'
        })
        printConsole(`Finished converting audio webm to m4a file: ${performance.now()}`);
        this.chunks = [];
        this.recorder[stopClipNo] = null;
    
        printConsole(`Stopping Audio Clip # ${stopClipNo}`);
        let reader = new FileReader();
        printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
    
        reader.onload = function() {
            var srcData = reader.result;
            // use byteLength to check the size
            const bytes = srcData.byteLength;
            printConsole(`byte size of Audio file: ${bytes}`);

            //if (bytes > 0) {
                printConsole(`sending the audio clip data No. ${audioNo} as socket signal payload: ${performance.now()}`);
                // Sending the audio clips as Socket Signal payload to the Server-side
                otExpObj.sendSignalRecordingUploadAudio(srcData, otExpObj.callBackForAudioRecording);
            //}                                
        };


        /* console.log("Creating the object URL: ",performance.now());
        const blobUrl = URL.createObjectURL(blob);
                            
        console.log(blobUrl)
        console.log("Downloading the m4a clip: ",performance.now());
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = blobUrl;
        a.download = `Telepresenz_Audio_${this.getDateString()}.m4a`;
        a.click();
        window.URL.revokeObjectURL(blobUrl); */
        
    }

    onRecorderDataAvailable = (event) => {
        printConsole(`data available for Audio Clip # ${clipNo}`);
        if (event.data.size > 0) {
            this.chunks.push(event.data)
        }
    }

    addTelepresenzWatermark = (canvas) => {
        const { selectedGroupName, user } = this.props;
        // create the watermark on the image frames
        const text = "Recording Details: "+selectedGroupName+" - "+user.firstname+" "+user.lastname+" - "+this.getDateStringForWatermark();
        var tempCtx=canvas.getContext('2d');
        tempCtx.font=screenSettings[1].waterMarkFont+" Titillium Web";
        var textWidth=tempCtx.measureText(text).width;
        tempCtx.globalAlpha=.75;
        tempCtx.fillStyle='white'
        tempCtx.fillText(text,canvas.width-textWidth-60,canvas.height-60);
        tempCtx.fillStyle='black'
        tempCtx.fillText(text,canvas.width-textWidth-60+2,canvas.height-60+2);
    }

    // TP-740 -- new start recording
    startRecordingNew = async (state) => {        
        try {
            // calling the screen capture
            printConsole(`Start screen capture ${performance.now()}`);
            this.captureScreen((screen) => {
                ackFrameNo = 0;
                ackClipNo = 0;
                errAckFrameNo = 0; //TP-1966
                errAudioAckNo = 0;
                framesPreviousState = 'ok'; //TP-2215
                audioPreviousState = 'ok'; //TP-2215
                this.screen = screen;
                printConsole(`User permission for screen capture obtained ${performance.now()}`);
                this.setState({recFileName : (isrecording_active && state === "continue" && this.state.recFileName !== '') ? this.state.recFileName : Date.now()}, () => {
                    clipNo = 0;
                    audioNo = 0;
                    //TP-1974
                    this.sendSignalSyncExperts({
                        isrecording_active: true,
                        is_silent_recording: false,
                        recording_folder_name: this.state.recFileName,
                        frames_per_seconds: screenSettings[1].fps,
                        frame_count: count,
                        reason: "recording_sync"},
                    null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = true`);
                    // TP-1877
                    if (state === "start") {
                        this.sendSignalRecordingStart(this.callBackForStartRecording); // Start the recording at this point
                    }
                    try{
                        let imageCapture = [];
                        //video.srcObject = screen;
                        this.frameThread = setInterval(() => {
                            this.frameTimer = setTimeout(() => {                                
                                frameNo += 1;
                                printConsole(`trigger new frame thread ${performance.now()}`);
                                const canvas = document.createElement("canvas");
                                canvas.id = "fake";
                                canvas.style.display = "none";
                                document.body.appendChild(canvas);
                                // get correct video track
                                const track = this.screen.getVideoTracks()[0]
                                printConsole(`frame grab of screen track started: ${performance.now()}`);
                                // init Image Capture and not Video stream
                                imageCapture[frameNo] = new ImageCapture(track);
                                // take a single frame only
                                imageCapture[frameNo].grabFrame()
                                    .then(function(imageBitmap){
                                        printConsole(`current frame ----> ${frameNo}`);
                                        printConsole(`frame grab of screen track done with new Bitmap generated ${performance.now()}`);
                                        //printConsole(imageBitmap);
                                        
                                        const canvas = document.getElementById('fake') 
                                        if (canvas !== null) {
                                            // this could be a document.createElement('canvas') if you want
                                            // draw weird image type to canvas so we can get a useful image
                                            printConsole(`Draw canvas with the Screen grab ${performance.now()}`);
                                            canvas.width = imageBitmap.width
                                            canvas.height = imageBitmap.height
                                            const context = canvas.getContext('2d')
                                            context.drawImage(imageBitmap, 0, 0, imageBitmap.width, imageBitmap.height)
                                            
                                            otExpObj.addTelepresenzWatermark(canvas); // Add the watermark for Telepresenz 
                                            
                                            printConsole(`Convert canvas to base64 image format ${performance.now()}`)
                                            const frameRaw = canvas.toDataURL('image/jpeg', 0.5);
                                            const frame = frameRaw.replace('data:image/jpeg;base64,', '');
                                            canvas.parentNode.removeChild(canvas);
                                            printConsole(`Canvas changed to base64 image format done: ${performance.now()}`)
                                            
                                            /* if (frameNo >= 242) {
                                                printConsole(`sending the image frame # ${frameNo} as socket signal payload: ${performance.now()}`);
                                                // Sending the frame as Socket Signal payload to the Server-side
                                                otExpObj.sendSignalRecordingUploadImages(frame, otExpObj.callBackForFramesSent, 1);
                                            } else { */
                                            if (turn_off_frames_signal === false) {
                                                printConsole(`sending the image frame as socket signal payload: ${performance.now()}`);
                                                // Sending the frame as Socket Signal payload to the Server-side
                                                otExpObj.sendSignalRecordingUploadImages(frame, otExpObj.callBackForFramesSent);
                                            } else {
                                                printConsole("Pausing the frame creation and sending")
                                            }
                                            //}
    
                                            /* if (frameNo >= 30) {
                                                console.log("Downloading the image jpg file: ",performance.now());
                                                var a = document.createElement("a");
                                                document.body.appendChild(a);
                                                a.style = "display: none";
                                                a.href = frameRaw;
                                                a.download = `Telepresenz_${otExpObj.getDateString()}.jpeg`;
                                                a.click();
                                                window.URL.revokeObjectURL(frame);
                                            } */
                                        } else {
                                            frameNo -= 1;
                                        }
        
                                    })
                                    .catch(error => {
                                        console.log("the frame grab promise returned an error ",error);
                                    })
                                clearTimeout(this.frameTimer);
                            }, 1)
                            //clearInterval(otExpObj.frameThread);
                        }, screenSettings[1].timer)
                    } catch(err) {
                        console.log("Error: " + err);
                    }

                    //TP-1877 Setting the looped Audio Recording feature for intervals of 15 secs each
                    this.recorder = [];
                    this.chunks = [];
                    this.triggerRecordingInterval();
                    this.setState({ showRecordingBeacon: true }) // show the beacon

                    /* this.recStartInterval = setInterval(() => {
                        this.recStartTimer = setTimeout(() => {
                            clipNo += 1;
                            this.triggerRecordingInterval();                            
                            clearTimeout(this.recStartTimer);
                        }, 1);
                    }, 14970); */ // start a new recorder for Audio at the 14th sec mark       
                    
                    this.recStopInterval = setInterval(() => {
                        this.recStopTimer = setTimeout(() => {
                            printConsole(`Trigger Stop Recorder for current recording ${performance.now()}`);                        
                            printConsole(`now the ClipNo: ${clipNo}`);
                            this.recorder[clipNo].onstart = event => {
                                audioNo += 1;
                                printConsole(`Current audio file no: ${audioNo}`);
                            }
                            this.recorder[clipNo].ondataavailable = event => {
                                this.onRecorderDataAvailable(event);
                            }
                            this.recorder[clipNo].onstop = event => {
                                this.recorder[clipNo].start();
                                this.chunkTimer = setTimeout(() => {
                                    this.onRecorderStopEachClip(clipNo);
                                    clearTimeout(this.chunkTimer);                           
                                }, 1);                            
                            }
                            this.recorder[clipNo] && this.recorder[clipNo].state !== "inactive" && this.recorder[clipNo].stop();
                            clearTimeout(this.recStopTimer);
                        }, 1);
                    }, 15000) //stop the audio then start it again at the 15th sec mark 
                });
            });
        }catch(error){
            console.error(error);
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording, isOngoingMaxDivFeatures: !prevState.startRecording
            }))
        }
    }

    //TP-1881 
    stopAndContinueRecording = () => {
        printConsole(`Start a new audio recording and stop the old recording ${performance.now()}`);
        clipNo += 1;
        this.triggerRecordingInterval();
                        
        printConsole(`###Trigger Stop Recorder for previous recording ${performance.now()}`);                        
        printConsole(`now the ClipNo: ${clipNo}`);
        this.recorder[clipNo-1].ondataavailable = event => {
            this.onRecorderDataAvailable(event);
        }
        this.recorder[clipNo-1].onstop = event => {
            //this.recorder.start();
            this.chunkTimer = setTimeout(() => {
                this.onRecorderStopInterval(clipNo-1);
                clearTimeout(this.chunkTimer);                           
            }, 1);                            
        }
        this.recorder[clipNo-1] && this.recorder[clipNo-1].state !== "inactive" && this.recorder[clipNo-1].stop();
    }

    //TP-1877
    triggerRecordingInterval = () => {
        console.log("Trigger new Audio recorder for 15 seconds",performance.now());
        console.log("current clipNo: ", clipNo);
        const outputStream = this.captureStreams();
        console.log(outputStream);
        console.log("mimeType audio webm supported? ",MediaRecorder.isTypeSupported('audio/webm'));
        this.recorder[clipNo] = new MediaRecorder(outputStream)
        //this.chunks = [];
        this.recorder[clipNo].onstart = event => {
            audioNo += 1;
            console.log("Current audio file no: ", audioNo);
        }
        this.recorder[clipNo].start();
    }

    captureAudioStreams = () => {
        try {
            const {g_subInfoArr} = this.state;
            let isExpertAudioMuted = false;
            g_subInfoArr.forEach(subInfo => {
                if (subInfo.disableMike) isExpertAudioMuted = true;
            })

            const audioContext = new AudioContext();
            const destination = audioContext.createMediaStreamDestination();
            printConsole(isExpertAudioMuted);
            if (isExpertAudioMuted === false) {
                console.log(g_publisher.element.querySelector('video').captureStream);
                const cameraStream = g_publisher.element.querySelector('video').captureStream(3);
                console.log(cameraStream);
                //this.camera = stream;
                let source1;
                if(cameraStream){
                    source1 = audioContext.createMediaStreamSource(cameraStream);
                }
                //connect sources to destination 
                source1 && source1.connect(destination);

            }
                //cameraStream.getAudioTracks().forEach(track => track.enabled = true);
                const outputStream= new MediaStream();
                
                g_subInfoArr.forEach( subInfo => {
                    console.log(subInfo.g_subscriber.element.querySelector('video'));
                    let mediaStream;
                    const fps = 3;
                    if (subInfo.g_subscriber.element.querySelector('video').captureStream) {
                        mediaStream = subInfo.g_subscriber.element.querySelector('video').captureStream(fps);
                    } else if (subInfo.g_subscriber.element.querySelector('video').mozCaptureStream) {
                        mediaStream = subInfo.g_subscriber.element.querySelector('video').mozCaptureStream(fps);
                    } else {
                        console.error('Stream capture is not supported');
                        mediaStream = null;
                    }
                    //mediaStream.getAudioTracks().forEach(track => track.enabled = true);
                    console.log(mediaStream);
                    //mediaTrackArr.push(mediaStream.getAudioTracks()[0]);
                    let source2;
                    if(mediaStream){
                        source2 = audioContext.createMediaStreamSource(mediaStream);
                    }
                    source2 && source2.connect(destination);
                });
                //mediaTrackArr.push(cameraStream.getAudioTracks()[0]);
                //mediaTrackArr.push(this.screen.getTracks()[0]);
                //console.log(mediaTrackArr);
                //outputStream = new MediaStream(mediaTrackArr);
                console.log(destination.stream.getAudioTracks());
                outputStream.addTrack(destination.stream.getAudioTracks()[0]);
                return outputStream;
        } catch (error) {
            printConsole(error);
        }
    }

    captureStreams = () => {
        try {    
            /* navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream)=>{ */

                const {g_subInfoArr} = this.state;
                let isExpertAudioMutedCounter = 0;
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.disableMike) isExpertAudioMutedCounter += 1;
                })

                printConsole(g_publisher.element.querySelector('video').captureStream);
                const audioContext = new AudioContext();
                const destination = audioContext.createMediaStreamDestination();
                const cameraStream = g_publisher.element.querySelector('video').captureStream(3);
                printConsole(cameraStream);
                //this.camera = stream;
                let source1;
                printConsole(`Current Expert's Muted Mic count: ${isExpertAudioMutedCounter}`);
                printConsole(`total no of users subscribing to in session: ${g_subInfoArr.length}`);
                if(cameraStream && isExpertAudioMutedCounter < g_subInfoArr.length) { //TP-1918
                    source1 = audioContext.createMediaStreamSource(cameraStream);
                    //connect sources to destination 
                    source1 && source1.connect(destination);
                }
                //cameraStream.getAudioTracks().forEach(track => track.enabled = true);
                const outputStream= new MediaStream();
                
               g_subInfoArr.forEach( subInfo => {
                    printConsole(subInfo.g_subscriber.element.querySelector('video'));
                    let mediaStream;
                    const fps = 3;
                    if (subInfo.g_subscriber.element.querySelector('video').captureStream) {
                        mediaStream = subInfo.g_subscriber.element.querySelector('video').captureStream(fps);
                    } else if (subInfo.g_subscriber.element.querySelector('video').mozCaptureStream) {
                        mediaStream = subInfo.g_subscriber.element.querySelector('video').mozCaptureStream(fps);
                    } else {
                        console.error('Stream capture is not supported');
                        mediaStream = null;
                    }
                    //mediaStream.getAudioTracks().forEach(track => track.enabled = true);
                    printConsole(mediaStream);
                    //mediaTrackArr.push(mediaStream.getAudioTracks()[0]);
                    let source2;
                    if(mediaStream){
                        source2 = audioContext.createMediaStreamSource(mediaStream);
                    }
                    source2 && source2.connect(destination);
                });
                //mediaTrackArr.push(cameraStream.getAudioTracks()[0]);
                //mediaTrackArr.push(this.screen.getTracks()[0]);
                //console.log(mediaTrackArr);
                //outputStream = new MediaStream(mediaTrackArr);
                printConsole(destination.stream.getAudioTracks());
                outputStream.addTrack(destination.stream.getAudioTracks()[0]);
                //outputStream.addTrack(this.screen.getTracks()[0]);
                return outputStream;
            /* }).then(cb) */
        } catch(error) {
            printConsole(error);
        }
    }

    invokeGetDisplayMedia(success, error) {
        try {
            let displaymediastreamconstraints = {
                //TP-740 -- set the frame rate as 5 per second
                video: screenSettings[1].constraints,
                preferCurrentTab: true
            };

            if (navigator.mediaDevices.getDisplayMedia) {
                navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success)
                .catch(error => {
                    // TP-1974
                    printConsole(`the recording permission has been denied ${error}`);
                    // change the recording button icon
                    this.setState((prevState) => ({
                        startRecording: !prevState.startRecording
                    }))                    
                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                    this.sendSignalSyncExperts({
                        isrecording_active: false,
                        is_silent_recording: false,
                        recording_folder_name: this.state.recFileName,
                        frames_per_seconds: screenSettings[1].fps,
                        frame_count: (count) ? count: 0,
                        reason: "recording_sync"},
                    null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                    if (isrecording_active === true) {
                        this.sendSignalRecordingStop();
                        isrecording_active = false;
                        this.setState({ recFileName: ''});
                    }
                });
            } else {
                navigator.getDisplayMedia(displaymediastreamconstraints).then(success)
                .catch(error => {
                    // TP-1974
                    printConsole(`the recording permission has been denied ${error}`);
                    // change the recording button icon
                    this.setState((prevState) => ({
                        startRecording: !prevState.startRecording
                    }))
                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                    this.sendSignalSyncExperts({
                        isrecording_active: false,
                        is_silent_recording: false,
                        recording_folder_name: this.state.recFileName,
                        frames_per_seconds: screenSettings[1].fps,
                        frame_count: (count) ? count : 0,
                        reason: "recording_sync"},
                    null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                    if (isrecording_active === true) {
                        this.sendSignalRecordingStop();
                        isrecording_active = false;
                        this.setState({ recFileName: '' });
                    }
                });
            }
        }catch(error){
            printConsole(error)
        }
    }

    captureCamera = (cb) => {
        try {
            navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream)=>{
                this.camera = stream;
                let maxdivtech = g_subInfoArr.find( subInfo => g_maxDivId === subInfo.g_divId);
                // const mediaStream = maxdivtech.g_subscriber._.webRtcStream()
                const mediaStream = maxdivtech.g_subscriber.element.querySelector('video').srcObject;
                let source1, source2;
                // merge audio from remote stream and micStream
                const audioContext = new AudioContext();
                if(mediaStream){
                    source1 = audioContext.createMediaStreamSource(mediaStream);
                }
                source2 = audioContext.createMediaStreamSource(stream);
                //connect sources to destination 
                const destination = audioContext.createMediaStreamDestination();
                source1 && source1.connect(destination);
                source2 && source2.connect(destination);

                const outputStream= new MediaStream();
                outputStream.addTrack(destination.stream.getAudioTracks()[0]);
                return outputStream;
            })
            .then(cb);
        }catch(error){
            printConsole(error)
        }
    }

    captureScreen = (callback) => {
        try{
            this.invokeGetDisplayMedia((screen) => {
                if (isSilentRecording === false){
                    // when screen capture is directly stopped by clicking on the popup then this execute  
                    this.addStreamStopListener(screen, this.stopRecordingCallback);
                    callback(screen);
                }
            }, (error) => {
                console.error(error);
                this.setState((prevState) => ({
                    startRecording: !prevState.startRecording/* , isOngoingMaxDivFeatures: !prevState.startRecording */
                }))
            });
        }catch(error){
            printConsole(error)
        }
    }

    callBackForAudioRecording = (response) => {
        printConsole("************Received the acknowledgement for each audio clip socket signal************")
        printConsole(response); // ok
        if (response) {
            ackClipNo += 1;
            audioPreviousState = response.status;
            printConsole(`is recording ongoing: ${this.state.startRecording}`)
            if (response.status === "error" && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                printConsole(" Audio Clip sent has thrown error ");
                
                errAudioAckNo += 1;
                // TP-2215 -- Log Diagnostics for error acknowledgement during upload each frame for recording
                if (errAudioAckNo === 1 && this.props.user.log_level >= 3) {
                    diagnostics(this.authService,{
                        action: `recordingError`,
                        sequence: 3,
                        next_step: '',
                        data: `{ folder_name: ${response.folder_name}, group_id: ${response.group_id}, sequence_id: ${response.sequence_id}, status: ${response.status}, type_of_audio: ${response.type_of_audio}, user_id: ${response.user_id} }`,
                        error: 'none'
                    })
                }
                this.handleErrorRecordingCallbackStop();
                //this.stopRecordingCallback();
                //this.triggerMessage("","errorRecording");
                //this.onClickRecordScreen() // Start the recording again
            } else if (isSilentRecording === false){
                printConsole(` Audio clip sent successfully to server side for clip NO: ${ackClipNo}`);
            }
        }
    }

    callBackForFinalAudioRecording = (response) => {
        printConsole("************Received the acknowledgement for final audio clip socket signal************")
        printConsole(response); // ok
        if (response) {
            ackClipNo += 1;
            audioPreviousState = response.status;
            printConsole(`is recording ongoing: ${this.state.startRecording}`)
            if (response.status === "error" && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                
                errAudioAckNo += 1;
                // TP-2215 -- Log Diagnostics for error acknowledgement during upload each frame for recording
                if (errAudioAckNo === 1 && this.props.user.log_level >= 3) {
                    diagnostics(this.authService,{
                        action: `recordingError`,
                        sequence: 3,
                        next_step: '',
                        data: `{ folder_name: ${response.folder_name}, group_id: ${response.group_id}, message: ${response.message}, sequence_id: ${response.sequence_id}, status: ${response.status}, type_of_audio: ${response.type_of_audio}, user_id: ${response.user_id} }`,
                        error: 'none'
                    })
                }
                frameNo = 0;
                printConsole(this.state.stopState);
                if (this.state.stopState === "stop"){
                    this.handleErrorRecordingCallbackStop();
                }
                else if (this.state.stopState === "pause") {
                    this.handleErrorRecordingCallbackPause();
                } else {
                    this.handleErrorRecordingCallbackStop();
                    /* this.sendSignalRecordingStop();
                    this.setState({ recFileName: '', stopState: '' }); */
                }
                //this.stopRecordingCallback();
                //this.triggerMessage("","errorRecording");
                //this.onClickRecordScreen() // Start the recording again
            } else if (response.status === "ok" && isSilentRecording === false) {
                // Send the stop Recording signal only after the final Audio signal is successfully uploaded
                if (this.state.startRecording === false /* && ackFrameNo === frameNo */) {
                    frameNo = 0;
                    printConsole(this.state.stopState);
                    if (this.state.stopState === "stop"){
                        this.sendSignalRecordingStop();
                        this.setState({ recFileName: '', stopState: '' });
                    }
                    else if (this.state.stopState === "pause") {
                        /* const passive_user_id = parseInt(this.state.expertUserId);
                        const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
                        this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
                        this.setState({ stopState: '' }); */
                    }
                }
            }
        }
    }

    callBackForFramesSent = (response) => {
        printConsole("************Received the acknowledgement for each frame's socket signal************")
        printConsole(response); // ok
        if (response) {
            ackFrameNo += 1;
            framesPreviousState = response.status;
            printConsole(`acknowledgement received for frame # ${ackFrameNo}`);
            if (response.status === "error" && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262          
                // TP-1966 -- Implementation of triggering stop recording if at least one error acknowledgement
                // is received from the server-side 
                errAckFrameNo += 1;
                
                // TP-2215 -- Log Diagnostics for error acknowledgement during upload each frame for recording
                if (errAckFrameNo === 1 && this.props.user.log_level >= 3) {
                    diagnostics(this.authService,{
                        action: `recordingError`,
                        sequence: 3,
                        next_step: '',
                        data: `{ folder_name: ${response.folder_name}, group_id: ${response.group_id}, message: ${response.message}, sequence_id: ${response.sequence_id}, status: ${response.status}, type_of_image: ${response.type_of_image}, user_id: ${response.user_id} }`,
                        error: 'none'
                    })
                }
                if (errAckFrameNo === 1) {
                    this.handleErrorRecordingCallbackStop();
                    //this.stopRecordingCallback();
                    // Start the error acknowledgement timer to check if multiple error acknowledgements are received
                    // from server-side or only "one"
                    printConsole(" trigger the error acknowledgement counter ");
                    this.setState({
                        framesErrorTimeout : 2
                    },
                    () => {
                        this.ackTimer = setInterval(this.decrementAckCounter.bind(this), 1000)
                    });
                } else if (errAckFrameNo > 1) {
                    printConsole("close the wait timer for multiple acknowledgements");
                    this.closeAckCounter();
                }                
            }
            /* console.log("current frame no ",frameNo);
            console.log("is startRecording: ",this.state.startRecording)
            if (this.state.startRecording === false && ackFrameNo === frameNo) {
                frameNo = 0;
                console.log(this.state.stopState);
                if (this.state.stopState === "stop"){
                    this.sendSignalRecordingStop();
                    this.setState({ recFileName: '', stopState: '' });
                }
                else if (this.state.stopState === "pause") {
                    const passive_user_id = parseInt(this.state.expertUserId);
                    const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
                    this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
                    this.setState({ stopState: '' });
                }
            } */            
        }
    }

    callBackForStartRecording = (response) => {
        printConsole("************Received the acknowledgement for Start Recording socket signal************")
        printConsole(response);
    }

    callBackForPauseRecording = (response) => {
        printConsole("************Received the acknowledgement for Pause Recording socket signal************")
        printConsole(response);
    }

    //TP-1966 -- Acknowledgement error handling timer
    decrementAckCounter = () => {
        this.setState((prevState) =>({
            framesErrorTimeout: prevState.framesErrorTimeout - 1
        }),() => {
            if(this.state.framesErrorTimeout === 0 && errAckFrameNo === 1){
                printConsole(" timer ended and error acknowledgement count is still 1 ");
                this.triggerNewRecording();
            }
        });
    }

    closeAckCounter = () => {
        printConsole("Close the rcording signal acknowledgement timer")
        this.setState({
            framesErrorTimeout : 0
        },() => {
            clearInterval(this.ackTimer);
        })
    }

    triggerNewRecording = () => {
        clearInterval(this.ackTimer);
        this.triggerMessage("","errorRecording");
        this.newRecordingInterval = setInterval(() => {
            this.onClickRecordScreen() // Start the recording again
            clearInterval(this.newRecordingInterval);
        }, 1000);
    }

    handleErrorRecordingCallbackStop = () => {
        printConsole(`handle the stop scenario when the error acknowledgement comes: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`)
        //clearInterval(this.recStartInterval);
        clearInterval(this.recStopInterval);
        //clearInterval(this.recTimeInterval);
        clearInterval(this.frameThread);
        this.recorder[clipNo] = null;
    
        [this.screen].forEach(function (stream) {
            stream.getTracks().forEach(function (track) {
                            track.stop();
            });
        });
        const canvasArr = document.querySelectorAll('[id=fake]');
        if (canvasArr !== undefined && canvasArr.length > 0) {
            canvasArr.forEach(canvas => {
                canvas.parentNode.removeChild(canvas);
            })
        }

        printConsole(`Total number of frames = ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[2].timer)}`);
        printConsole(`Frames sent to the server side ${count}`);

        // if instead of clicking on the icon user clicks the popup the state will
        // change from here
        if (this.state.startRecording) {
            isrecording_active = false; 
            isSilentRecording = false;
            // Turn on the frames sent to server
            turn_off_frames_signal = false;
            this.setState({ startRecording: false })
        } else {
            isrecording_active = false;
            isSilentRecording = false;
            // Turn on the frames sent to server
            turn_off_frames_signal = false;
        }
        this.setState({ showRecordingBeacon: false })// hide the beacon
        //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
        this.sendSignalSyncExperts({
            isrecording_active: false,
            is_silent_recording: false,
            recording_folder_name: this.state.recFileName,
            frames_per_seconds: screenSettings[1].fps,
            frame_count: count,
            reason: "recording_sync"},
        null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
        count = 0;
        this.sendSignalRecordingStop();
        this.setState({ recFileName: '', stopState: '' });
    }

    handleErrorRecordingCallbackPause = () => {
        printConsole(`handle the pause scenario when the error acknowledgement comes: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`);
        //clearInterval(this.recStartInterval);
        clearInterval(this.recStopInterval);
        //clearInterval(this.recTimeInterval);
        clearInterval(this.frameThread);
        this.recorder[clipNo] = null;

        [this.screen].forEach(function (stream) {
            stream.getTracks().forEach(function (track) {
                track.stop();
            });
        });
        const canvasArr = document.querySelectorAll('[id=fake]');
        if (canvasArr !== undefined && canvasArr.length > 0) {
            canvasArr.forEach(canvas => {
                canvas.parentNode.removeChild(canvas);
            })
        }
        // if instead of clicking on the icon user clicks the popup the state will
        // change from here
        if (this.state.startRecording) {
            isrecording_active = false;
            isSilentRecording = false;
            // Turn on the frames sent to server
            turn_off_frames_signal = false;
            this.setState({ startRecording: false })
        } else {
            isrecording_active = false;
            isSilentRecording = false;
            // Turn on the frames sent to server
            turn_off_frames_signal = false;
        }
        this.setState({ showRecordingBeacon: false })// hide the beacon

        if (count !== 0) {
            //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
            this.sendSignalSyncExperts({
                isrecording_active: true,
                is_silent_recording: false,
                recording_folder_name: this.state.recFileName,
                frames_per_seconds: screenSettings[1].fps,
                frame_count: count,
                reason: "recording_sync"},
            null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
        }
        count = 0;
        const passive_user_id = parseInt(this.state.expertUserId);
        const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
        this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
        this.setState({ stopState: '' });
    }

    //TP-2041-- Pause handler for features that are causing audio-video sync issues on recording
    handlePauseRecordingDuringAnyFeature = () => {
        if (count !== 0){
            //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
            this.sendSignalSyncExperts({
                isrecording_active: true,
                is_silent_recording: false,
                recording_folder_name: this.state.recFileName,
                frames_per_seconds: screenSettings[1].fps,
                frame_count: count,
                reason: "recording_sync"},
            null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
        }
        printConsole(`Pause the ongoing recording audio ${performance.now()}`);
        if(this.recorder[clipNo] && this.recorder[clipNo].state === "recording") {
            this.recorder[clipNo].pause(); //recording paused
        }
        //this.onRecorderStopEachClip(clipNo);
        printConsole(`Stop the frames and audio recorder timers ${performance.now()}`);
        clearInterval(this.recStopInterval);
        // Turn off the frames sent to server
        turn_off_frames_signal = true;
        //frameNo = 0;
        //clearInterval(this.frameThread);
        const canvasArr = document.querySelectorAll('[id=fake]');
        if (canvasArr !== undefined && canvasArr.length > 0) {
            canvasArr.forEach(canvas => {
                canvas.parentNode.removeChild(canvas);
            })
        }
        printConsole(`Paused Audio Clip #  ${clipNo}`);  
    }

    //TP-2041-- Resume handler for features that are causing audio-video sync issues on recording
    handleResumeRecordingAfterPause = () => {
        printConsole(`Resume the frame capture after the Pause ${performance.now()}`);
        // Turn on the frames sent to server
        turn_off_frames_signal = false;
        printConsole(`Current Audio Recorder count #${clipNo}`)
        printConsole(`Resume the audio recorder after the Pause ${performance.now()}`);
        if(this.recorder[clipNo] && this.recorder[clipNo].state === "paused") {
            this.recorder[clipNo].resume();
        }
        //this.recorder[clipNo].start();
        this.recStopInterval = setInterval(() => {
            this.recStopTimer = setTimeout(() => {
                printConsole(`Trigger Stop Recorder for current recording ${performance.now()}`);                        
                printConsole(`now the ClipNo: ${clipNo}`);
                this.recorder[clipNo].onstart = event => {
                    audioNo += 1;
                    printConsole(`Current audio file no: ${audioNo}`);
                }
                this.recorder[clipNo].ondataavailable = event => {
                    this.onRecorderDataAvailable(event);
                }
                this.recorder[clipNo].onstop = event => {
                    this.recorder[clipNo].start();
                    this.chunkTimer = setTimeout(() => {
                        this.onRecorderStopEachClip(clipNo);
                        clearTimeout(this.chunkTimer);                           
                    }, 1);                            
                }
                this.recorder[clipNo] && this.recorder[clipNo].state !== "inactive" && this.recorder[clipNo].stop();
                clearTimeout(this.recStopTimer);
            }, 1);
        }, 15000) //stop the audio then start it again at the 15th sec mark 
    }
 
    // recorder is stopped
    stopRecordingCallback = async (state="stop") => {
        try {
            printConsole("the recorder object ?");
            printConsole(this.recorder[clipNo]);
            printConsole(`current recorder state >>>>>>>`);
            printConsole(state);
            if (typeof state === "object") 
                state = "stop";
            if (this.recorder[clipNo] && this.recorder[clipNo] !== null) {
                // TP-740
                this.recorder[clipNo].ondataavailable = event => {
                    this.onRecorderDataAvailable(event);
                }
                this.recorder[clipNo].onstop = () => {
                    //clipNo += 1;
                    printConsole(`close the recording file. Last chunk of audio file creating: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`);
                    //clearInterval(this.recStartInterval);
                    clearInterval(this.recStopInterval);
                    //clearInterval(this.recTimeInterval);
                    clearInterval(this.frameThread);
                    this.recorder[clipNo] = null;
    
                    [this.screen].forEach(function (stream) {
                        stream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                    });
                    const canvasArr = document.querySelectorAll('[id=fake]');
                    if (canvasArr !== undefined && canvasArr.length > 0) {
                        canvasArr.forEach(canvas => {
                            canvas.parentNode.removeChild(canvas);
                        })
                    }
                    if (state !== "end") {
                        this.setState({ stopState: state }, () => {
                            //frameNo = 0;
                            printConsole(`Total number of frames =  ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[1].timer)}`);
                            printConsole(`Frames sent to the server side ${count}`);
        
                            // if instead of clicking on the icon user clicks the popup the state will
                            // change from here
                            if (this.state.startRecording) {
                                isrecording_active = false;
                                isSilentRecording = false;
                                // Turn on the frames sent to server
                                turn_off_frames_signal = false;
                                this.setState({ startRecording: false })
                            } else {
                                isrecording_active = false;
                                isSilentRecording = false;
                                // Turn on the frames sent to server
                                turn_off_frames_signal = false;
                            }
                            this.setState({ showRecordingBeacon: false })// hide the beacon

                            if (this.state.stopState === "stop") {
                                //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                                this.sendSignalSyncExperts({
                                    isrecording_active: false,
                                    is_silent_recording: false,
                                    recording_folder_name: this.state.recFileName,
                                    frames_per_seconds: screenSettings[1].fps,
                                    frame_count: count,
                                    reason: "recording_sync"},
                                null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                            } else if (this.state.stopState === "pause") {
                                if (count !== 0){
                                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                                    this.sendSignalSyncExperts({
                                        isrecording_active: true,
                                        is_silent_recording: false,
                                        recording_folder_name: this.state.recFileName,
                                        frames_per_seconds: screenSettings[1].fps,
                                        frame_count: count,
                                        reason: "recording_sync"},
                                    null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                                }
                                // Adding this to resolve TP-2251
                                const passive_user_id = parseInt(this.state.expertUserId);
                                const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
                                this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
                                this.setState({ stopState: '' });
                            }
        
                            count = 0;
                            printConsole(`Final Audio Clip #  ${clipNo}`);                        
                            printConsole(`Converting audio blob to m4a file: ${performance.now()}`)
                            const blob = new Blob(this.chunks, {
                                    type: 'audio/m4a'
                            })
                            printConsole(`Finished converting blob to m4a file: ${performance.now()}`);
        
                            let reader = new FileReader();
                            printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
                            reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
        
                            reader.onload = function() {
                                /* console.log("Starting base64 encryption of mp4 file: ",Date.now() - otExpObj.state.recFileName)
                                var srcData = arrayBufferToBase64(reader.result);
                                console.log("ending base64 encryption of mp4 file: ",Date.now() - otExpObj.state.recFileName) */
                                var srcData = reader.result;
                                // use byteLength to check the size
                                const bytes = srcData.byteLength;
                                printConsole(`byte size of Audio file: ${bytes}`);

                                //if (bytes > 0) {
                                    printConsole(`sending the audio clip data No. ${audioNo} as socket signal payload: ${performance.now()}`);
                                    // Sending the audio clips as Socket Signal payload to the Server-side
                                    otExpObj.sendSignalRecordingUploadAudio(srcData, otExpObj.callBackForFinalAudioRecording);
                                //}
                                /* if(typeof(Storage) !== "undefined"){
                                    printConsole("Clearing the session storage");
                                    ['chunk_data', 'folder_name', 'user_id', 'group_id', 'sequence_id'].forEach(key =>  sessionStorage.removeItem(key));
                                }else{
                                    printConsole(`Sorry, your browser does not support Web Storage...`)
                                } */
                                if (state === "stop"){
                                    //otExpObj.sendSignalRecordingStop();                                    
                                    //otExpObj.setState({ recFileName: '' });
                                }
                                else if (state === "pause") {
                                    /* const passive_user_id = parseInt(otExpObj.state.expertUserId);
                                    const passive_user_name = otExpObj.state.expertFirstName.replace(/ /g,"_")+"_"+otExpObj.state.expertLastName.replace(/ /g,"_");
                                    otExpObj.sendSignalRecordingPause(passive_user_id, passive_user_name, otExpObj.callBackForPauseRecording); */
                                }
                                clipNo = 0;
                                audioNo = 0;
                            };
        
                            /* console.log("Creating the object URL: ",performance.now());
                            const blobUrl = URL.createObjectURL(blob)
                        
                            console.log(blobUrl)
                            console.log("Downloading the final m4a audio clip: ",performance.now());
                            var a = document.createElement("a");
                            document.body.appendChild(a);
                            a.style = "display: none";
                            a.href = blobUrl;
                            a.download = `Telepresenz_audio_${this.getDateString()}.m4a`;
                            a.click();
                            window.URL.revokeObjectURL(blobUrl); */                        
                        });
                    } else {
                        printConsole(`Total number of frames =  ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[1].timer)}`);
                        printConsole(`Frames sent to the server side ${count}`);

                        count = 0;
                        printConsole(`Final Audio Clip #  ${clipNo}`);                        
                        printConsole(`Converting audio blob to m4a file: ${performance.now()}`)
                        const blob = new Blob(this.chunks, {
                                    type: 'audio/m4a'
                        })
                        printConsole(`Finished converting blob to m4a file: ${performance.now()}`);
        
                        let reader = new FileReader();
                        printConsole(`reading the mp4 file as arrayBuffer: ${performance.now()}`)
                        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload
        
                        reader.onload = function() {
                            var srcData = reader.result;
                            // use byteLength to check the size
                            const bytes = srcData.byteLength;
                            printConsole(`byte size of Audio file: ${bytes}`);
                            
                            //if (bytes > 0) {
                                printConsole(`sending the audio clip data No. ${audioNo} as socket signal payload: ${performance.now()}`);
                                // Sending the audio clips as Socket Signal payload to the Server-side
                                otExpObj.sendSignalRecordingUploadAudio(srcData, otExpObj.callBackForFinalAudioRecording);
                            //}
                                /* if(typeof(Storage) !== "undefined"){
                                    printConsole("Clearing the session storage");
                                    ['chunk_data', 'folder_name', 'user_id', 'group_id', 'sequence_id'].forEach(key =>  sessionStorage.removeItem(key));
                                }else{
                                    printConsole(`Sorry, your browser does not support Web Storage...`)
                                } */
                                
                            clipNo = 0;
                            audioNo = 0;
                        };

                    }
                }
                this.recorder[clipNo] && this.recorder[clipNo].state !== "inactive" && this.recorder[clipNo].stop();
                
            } else if ((this.recorder[clipNo] === null || this.recorder[clipNo] === '' ) && state === "pause") {
                printConsole(`Pausing the recording due to control transfer and audio is not there: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`);
                //clearInterval(this.recStartInterval);
                clearInterval(this.recStopInterval);
                //clearInterval(this.recTimeInterval);
                clearInterval(this.frameThread);
                this.recorder[clipNo] = null;
    
                [this.screen].forEach(function (stream) {
                    stream.getTracks().forEach(function (track) {
                            track.stop();
                    });
                });
                const canvasArr = document.querySelectorAll('[id=fake]');
                if (canvasArr !== undefined && canvasArr.length > 0) {
                    canvasArr.forEach(canvas => {
                            canvas.parentNode.removeChild(canvas);
                    })
                }

                printConsole(`Total number of frames = ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[2].timer)}`);
                printConsole(`Frames sent to the server side ${count}`);

                // if instead of clicking on the icon user clicks the popup the state will
                // change from here
                if (this.state.startRecording) {
                    isrecording_active = false;
                    isSilentRecording = false;
                    // Turn on the frames sent to server
                    turn_off_frames_signal = false;
                    this.setState({ startRecording: false })
                } else {
                    isrecording_active = false;
                    isSilentRecording = false;
                    // Turn on the frames sent to server
                    turn_off_frames_signal = false;
                }
                this.setState({ showRecordingBeacon: false })// hide the beacon

                if (count !== 0){
                    //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                    this.sendSignalSyncExperts({
                        isrecording_active: true,
                        is_silent_recording: false,
                        recording_folder_name: this.state.recFileName,
                        frames_per_seconds: screenSettings[1].fps,
                        frame_count: count,
                        reason: "recording_sync"},
                    null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                }
                count = 0;
                const passive_user_id = parseInt(this.state.expertUserId);
                const passive_user_name = this.state.expertFirstName.replace(/ /g,"_")+"_"+this.state.expertLastName.replace(/ /g,"_");
                this.sendSignalRecordingPause(passive_user_id, passive_user_name, this.callBackForPauseRecording);
                this.setState({ recFileName: '' });
            } /* else if ((this.recorder[clipNo] === null || this.recorder[clipNo] === '' ) && state === "stop") {
                printConsole(`Stopping the image frames being sent if the audio recording is already stopped ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`)
                //clearInterval(this.recStartInterval);
                clearInterval(this.recStopInterval);
                //clearInterval(this.recTimeInterval);
                clearInterval(this.frameThread);
                this.recorder[clipNo] = null;
    
                [this.screen].forEach(function (stream) {
                    stream.getTracks().forEach(function (track) {
                            track.stop();
                    });
                });
                const canvasArr = document.querySelectorAll('[id=fake]');
                if (canvasArr !== undefined && canvasArr.length > 0) {
                    canvasArr.forEach(canvas => {
                            canvas.parentNode.removeChild(canvas);
                    })
                }

                printConsole(`Total number of frames = ${Math.ceil((Date.now() - this.state.recFileName) / screenSettings[2].timer)}`);
                printConsole(`Frames sent to the server side ${count}`);

                // if instead of clicking on the icon user clicks the popup the state will
                // change from here
                if (this.state.startRecording) {
                    isrecording_active = false;
                    isSilentRecording = false;
                    // Turn on the frames sent to server
                    turn_off_frames_signal = false;
                    this.setState({ startRecording: false })
                } else {
                    isrecording_active = false;
                    isSilentRecording = false;
                    // Turn on the frames sent to server
                    turn_off_frames_signal = false;
                }
                this.setState({ showRecordingBeacon: false })// hide the beacon
                //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                this.sendSignalSyncExperts({
                    isrecording_active: false,
                    is_silent_recording: false,
                    recording_folder_name: this.state.recFileName,
                    frames_per_seconds: screenSettings[1].fps,
                    frame_count: count,
                    reason: "recording_sync"},
                null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                this.sendSignalRecordingStop();
                this.setState({ recFileName: '' });
            } */
        } catch (error) {
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording/* , isOngoingMaxDivFeatures: !prevState.startRecording */
            }))
        }
    }

    stopScreenshotRecordingCb = () => {
        try {
            if (this.recorder) {
                this.recorder.onstop = () => {
                    console.log(`close the recording file. Last chunk of Video creating: ${Math.ceil((Date.now() - this.state.recFileName) / 1000)} seconds`);
                    this.setState({ totalFrames: Math.ceil((Date.now() - this.state.recFileName) / 1000)}, () => {
                        console.log("Total number of frames = ", this.state.totalFrames);                        
                        // if instead of clicking on the icon user clicks the popup the state will change from here
                        if (this.state.startRecording) {
                            this.setState({ startRecording: false })
                        } else {
                            isrecording_active = false;
                            isSilentRecording = false;
                            // Turn on the frames sent to server
                            turn_off_frames_signal = false;
                        }
                        this.setState({ showRecordingBeacon: false })// hide the beacon
                        //console.log(this.chunks);
                        console.log("Converting audio blob to m4a file: ",Date.now() - this.state.recFileName)
                        const blob = new Blob(this.chunks, {
                            type: 'audio/m4a'
                        })
                        console.log("Finished converting blob to m4a file: ",Date.now() - this.state.recFileName);
                        this.chunks = []

                        let reader = new FileReader();
                        console.log("reading the m4a file as arrayBuffer: ",Date.now() - this.state.recFileName)
                        reader.readAsArrayBuffer(blob); // converts the blob to base64 and calls onload

                        reader.onload = function() {
                            //console.log("Starting base64 encryption of mp3 audio file: ",Date.now() - otExpObj.state.recFileName)
                            //var srcData = arrayBufferToBase64(reader.result);
                            var srcData = reader.result;
                            //console.log("ending base64 encryption of mp3 audio file: ",Date.now() - otExpObj.state.recFileName)
                            //console.log(srcData);
                            const { loggedInUserId, selectedGroupName, user } = otExpObj.props;
                            let { selectedGroupId } = otExpObj.props;
                            const { recFileName } = otExpObj.state;
                            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
                            const audioDataPayload = {
                                groupId: selectedGroupId,
                                userId: loggedInUserId,
                                folderName: selectedGroupId+"_"+loggedInUserId+"_"+recFileName,
                                fps: 1,
                                from: user.email,
                                groupName: selectedGroupName.replace(/ /g,"_"),
                                audioData: srcData,
                                audioType: "m4a",
                                userName: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_")
                            }
                            console.log("frameNo --->", frameNo);
                            console.log("set the audio data payload in state");
                            otExpObj.setState({ audioDataPayload });

                            if (frameNo >= otExpObj.state.totalFrames)
                            clearInterval(otExpObj.screenshotsTimer);
                            //console.log("sending the audio as socket signal payload: ",Date.now() - otExpObj.state.recFileName);
                            //otExpObj.sessionSignal.sendSignalAudioData(audioDataPayload);
                        };

                        /* console.log("Creating the object URL: ",Date.now() - otExpObj.state.recFileName);
                        const blobUrl = URL.createObjectURL(blob)
                    
                        console.log(blobUrl)
                        console.log("Downloading the mp4 clip: ",Date.now() - otExpObj.state.recFileName);
                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = blobUrl;
                        a.download = `Telepresenz_${this.getDateString()}.m4a`;
                        a.click();
                        window.URL.revokeObjectURL(blobUrl); */
                        this.recorder = null;
                    })
                    
                }
                this.recorder.stop();
            }
            
        } catch (error) {
            this.setState((prevState) => ({
                startRecording: !prevState.startRecording/* , isOngoingMaxDivFeatures: !prevState.startRecording */
            }))
        }
    }


    addStreamStopListener = (stream, callback) => {
        try {
            stream.addEventListener('ended', function () {
                callback();
                callback = function () { };
            }, false);
            stream.addEventListener('inactive', function () {
                callback();
                callback = function () { };
            }, false);
            stream.getTracks().forEach(function (track) {
                track.addEventListener('ended', function () {
                    callback();
                    callback = function () { };
                }, false);
                track.addEventListener('inactive', function () {
                    callback();
                    callback = function () { };
                }, false);
            });
        }catch(error){
            printConsole(error)
        }
    }

    /** Methods to handle emit Socket signals for recording feature*/
    sendSignalRecordingStart = (callback=null) => {
        try {
            const { loggedInUserId, user, sessionInfo } = this.props;
            let { selectedGroupId, selectedGroupName } = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            const json = {
                session_id: sessionInfo.sessionID,
                group_id: selectedGroupId,
                user_id: loggedInUserId,
                fps: screenSettings[1].fps,
                images_per_second: screenSettings[1].fps,
                type_of_image: "jpeg",
                type_of_audio: "m4a",
                folder_name: recFileName,
                group_name: selectedGroupName.replace(/ /g,"_"),
                user_name: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_"),
                is_silent: false
            }
            this.sessionSignal.sendSignalRecordingStart(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingStart method ", error)
        }
    }

    sendSignalRecordingUploadImages = (image_data, callback=null, fll=0) => {
        try {
            let { selectedGroupId, loggedInUserId } = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            count += 1;
            //if (count >= 50) selectedGroupId = 0; loggedInUserId = 190;
            printConsole(`Frame count for sequence_id: ${count}`);
            const json = {
                folder_name: recFileName,
                sequence_id: recFileName.toString()+count.toString(), //Date.now(), // change this later to timestamp changed to secs
                group_id: selectedGroupId,
                user_id: loggedInUserId,
                type_of_image: "jpeg",
                image_data,
                previous_status: framesPreviousState                                
            }
            this.sessionSignal.sendSignalRecordingUploadImages(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingUploadImages method ", error)
        }
    }

    sendSignalRecordingUploadAudio = (audio_data, callback=null) => {
        try {
            let { selectedGroupId, loggedInUserId } = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            //if (audioNo >= 3) selectedGroupId = 0; loggedInUserId = 190;
            const json = {
                folder_name: recFileName,
                sequence_id: Date.now(), // change this later to timestamp changed to secs
                group_id: selectedGroupId,
                user_id: loggedInUserId,
                type_of_audio: "m4a",
                audio_data,
                previous_status: audioPreviousState
            }
            this.sessionSignal.sendSignalRecordingUploadAudio(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingUploadAudio method ", error)
        }
    }

    sendSignalRecordingStop = (callback=null, user_email='') => {
        try {
            let { selectedGroupId, loggedInUserId, selectedGroupName, user, sessionInfo } = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            let json;
            //TP-2095 -- Sending the expert user email id who needs to be sent the recording email
            if (user_email !== '') {
                json = {
                    session_id: sessionInfo.sessionID,
                    folder_name: recFileName,
                    group_name: selectedGroupName.replace(/ /g,"_"),
                    group_id: selectedGroupId,
                    user_id: loggedInUserId,
                    type_of_image: "jpeg",
                    type_of_audio: "m4a",
                    fps: screenSettings[1].fps,
                    user_email,
                    user_name: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_")
                }
            } else {
                json = {
                    session_id: sessionInfo.sessionID,
                    folder_name: recFileName,
                    group_name: selectedGroupName.replace(/ /g,"_"),
                    group_id: selectedGroupId,
                    user_id: loggedInUserId,
                    type_of_image: "jpeg",
                    type_of_audio: "m4a",
                    fps: screenSettings[1].fps,
                    user_name: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_")
                }
            }
            this.sessionSignal.sendSignalRecordingStop(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingStop method ", error)
        }
    }

    sendSignalRecordingPause = (passive_user_id, passive_user_name, callback=null) => {
        try {
            let { selectedGroupId, loggedInUserId, sessionInfo } = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            const json = {
                session_id: sessionInfo.sessionID,
                group_id: selectedGroupId,
                user_id: loggedInUserId,
                folder_name: recFileName,
                passive_user_id,
                passive_user_name                 
            }
            this.sessionSignal.sendSignalRecordingPause(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingPause method ", error)
        }
    }

    sendSignalRecordingContinue = (previous_user_id, callback=null) => {
        try {
            let { selectedGroupId, loggedInUserId, user, sessionInfo } = this.props;
            const { recFileName } = this.state;
            if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
            const json = {
                session_id: sessionInfo.sessionID,
                group_id: selectedGroupId,
                user_id: loggedInUserId,
                folder_name: recFileName,
                previous_user_id,
                user_name: user.firstname.replace(/ /g,"_")+"_"+user.lastname.replace(/ /g,"_")                
            }
            this.sessionSignal.sendSignalRecordingContinue(json, callback);
        } catch (error) {
            console.log("error in sendSignalRecordingContinue method ", error)
        }
    }

    /* static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.onlineUsers!==prevState.onlineUsers){
            return {onlineUsers: nextProps.onlineUsers};
        }
        if(nextProps.groupUsers!==prevState.groupUsers){
            return {groupUsers: nextProps.groupUsers};
        }
        else return null;
    } */
    
    componentDidMount = () => {
        this.setState({ chatUserEmails: [], chatUserNames: [] });
        testWorker();
        // TP-991, FQ3-318,295
        if(typeof(Storage) !== "undefined"){
            printConsole(`setting the group id in session storage =======================================>${this.props.selectedGroupId}`)
            sessionStorage.setItem("groupId", this.props.selectedGroupId); sessionStorage.setItem("isonprem", false);
        }else{
            printConsole(`Sorry, your browser does not support Web Storage...`)
        }
    }

    componentDidUpdate = (prevState, prevProps)=> {
        if (this.props.sessionCmd === 'session_stopped' && this.props.bSessionStarted === true){
            if (prevProps.sessionCmd !== 'session_stopped')
                this.onClose();            
        }
        if (this.props.sendSyncExperts !== prevProps.sendSyncExperts && this.props.sendSyncExperts === true) {
            this.triggerSyncExpertsShowAction();
        }
        if (this.state.isPointerActive !== prevState.isPointerActive && prevState.isPointerActive !== undefined && this.state.bAnnotate == true && this.state.user.isexpert && this.state.showHideMaxDivControl === true){
            /* strCanvasJSON = "";
            // TP-2475 -- Remove the rest of the canvas drawings before pointer is drawn
            // After pointer is closed
            let drawElem = document.getElementById('canvas');
            let canvas = drawElem.fabric;
            let obj = canvas.getObjects();
            for(let index = 0; index < obj.length; index++){
                canvas.remove(obj[index]);
            } */
        }
    }

    // Onclick functions for Expert Side Bar Features

    // Side Bar Mute Mike on/off function for all participants
    onClickMuteMikeForAll = (mikeState) => {
        // mute all users
        const {g_subInfoArr} = this.state;
        let nLen = g_subInfoArr.length;
        let ii = 0;
        for (ii = 0; ii < nLen; ii++) {            
            g_subInfoArr[ii].disableMike = !mikeState;
            const muteBtndiv = document.getElementById('muteMikeEach_'+g_subInfoArr[ii].g_stream.streamId)
            //set the changed session Joinee in the array
            this.updateEachSessionJoinee(g_subInfoArr[ii]);
            
            if (g_subInfoArr[ii].disableMike === true){
                //change the mute Mike icons on top of video element                            
                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                    muteBtn[0].classList.remove("fa-microphone");
                    muteBtn[0].classList.add("fa-microphone-slash");
                    muteBtn[0].style.color = "red"
                }
            } else {
                //change the mute Mike icons on top of video element                            
                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                    muteBtn[0].classList.add("fa-microphone");
                    muteBtn[0].classList.remove("fa-microphone-slash");
                    muteBtn[0].style.color = "black"
                }
            }
        }
        
        this.setState({ g_subInfoArr, bMuteMyMike: !mikeState, muteMikeForMaxDiv: !mikeState }, () => {
            this.setState({isParticipantChanged : true}, () => {                
                this.setState({isParticipantChanged : false});
            }); // Set the Side Bar Silhouette Icon's states
            this.sendExpertMuteAllCmd(mikeState);
            if (this.state.startRecording === true) {
                this.stopAndContinueRecording(); //TP-1881 stop the old recording and start a new recording when Side Bar Mute Mike is clicked
            }
        });
    }

    // Side Bar Mute Speaker on/off function for all participants
    onClickMuteSpeakerOfAll = (speakerState) => {
        // mute all users
        const {g_subInfoArr} = this.state;
        let nLen = g_subInfoArr.length;
        let ii = 0;
        for (ii = 0; ii < nLen; ii++) {
            g_subInfoArr[ii].g_subscriber.subscribeToAudio(speakerState); //.setAudioVolume(0); - //audio off            
            g_subInfoArr[ii].disableAudio = !speakerState;
            const muteBtndiv = document.getElementById('toggleAudio_'+g_subInfoArr[ii].g_stream.streamId)
            //set the changed session Joinee in the array
            this.updateEachSessionJoinee(g_subInfoArr[ii]);
            //T32-474
            if (g_subInfoArr[ii].disableAudio === true && g_subInfoArr[ii].disableRemoteAudio === false){ 
                //change the mute Speaker icons on top of video element                            
                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                    muteBtn[0].classList.remove("fa-volume-up");
                    muteBtn[0].classList.add("fa-volume-mute");
                    muteBtn[0].style.color = "red"
                }
            } else if (g_subInfoArr[ii].disableRemoteAudio === false) {
                //T32-474
                //change the mute Speaker icons on top of video element                            
                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                    muteBtn[0].classList.add("fa-volume-up");
                    muteBtn[0].classList.remove("fa-volume-mute");
                    muteBtn[0].style.color = "black"
                }
            }
        }       
        this.setState({ g_subInfoArr }, () => {
            this.setState({isParticipantChanged : true}, () => {
                this.setState({isParticipantChanged : false});
            }); // Set the Side Bar Silhouette Icon states
        });      
        this.setState({ bMuteAllAudio: !speakerState });
        this.setState({ bRemoteMute: !speakerState });       
    }

    // Side Bar Expert File Share start function to all participants
    onClickUploadFileForAll = () => {
        const {g_subInfoArr} = this.state; //TP-2438
        if (this.state.gridViewMode === true) {
            this.sendSignalSyncExperts({
                show_grid: true,
                hand_icon_enabled: true,
                header_fileShare_active: (!this.state.bShareAsset) ? true : false, //TP-2378             
                reason: "show_action"},
            null);  // send the "show_grid" value as true for Grid View Mode  
        }else {
            g_subInfoArr.forEach(subInfo => {
                if (subInfo.g_divId === g_maxDivId) {
                    // T32-463 -- New Multi-expert Signal trigger implementation
                    const dataArr = subInfo.g_stream.connection.data.split(":::")
                    this.sendSignalSyncExperts({
                        show_grid: false,
                        max_view_technician: dataArr[3], 
                        annotation_active: this.state.bAnnotate,                             
                        zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                        zoom_level: subInfo.zoomLevel,
                        torch_button_active: subInfo.torchActive,
                        annotate_color: "red",
                        shape_selected: "freehand",
                        hand_icon_enabled: true,
                        header_fileShare_active: (!this.state.bShareAsset) ? true : false, //TP-2378             
                        reason: "show_action"}, 
                    null);  // send the max div tech details                        
                }
            })
        }
        if (!this.state.bShareAsset) {
            if (this.state.startRecording === true && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                this.handlePauseRecordingDuringAnyFeature();
            }
            this.onClickUploadFile(null);
        }
    }

    // Side bar Expert Screen share on/off function to all technicians
    // called on Expert Webapp only
    onClickExpertScreenShareForAll = (buttonState, flag=false, sendArtifacts=true) => {
        const {g_subInfoArr} = this.state;
        // Turning on/off the Publisher object on the expert screen with UI changes
        this.setState({ expertSSAll: buttonState }, () => {
            this.props.updateScreenShareAll(buttonState);
            this.props.updateRCStatus(true);
            this.setState({ expertShareScreenMaxDiv: this.state.expertSSAll });
            if  (this.state.expertSSAll === false && this.state.bAnnotate === true) {
                this.onClickAnnotate();
                //if (this.isOngoingMaxDivFeatures() === false)
                    this.setState({isOngoingMaxDivFeatures: false});
            } else if (this.state.expertSSAll === true && this.state.bAnnotate === true) {
                //Clearing the old annotation drawings
                //this.sendAnnotationToExpert('');//NS2-345
                //this.sendAnnotation('', null);
                //this.clearAnnotations(); commected this code out for TP-1365
            }
            //TP-2861
            if (this.state.expertSSAll === true)
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.zoomLevel > 1) 
                        this.resetUserZoom(subInfo.g_divId);
                    if (subInfo.torchActive === true)
                        this.toggleUserTorch(subInfo.g_divId);
                });
            if (this.state.expertSSAll === true && this.state.bZoomClick === true)
                document.getElementById('zoomlevelselector').style.display = 'none';
            else if (this.state.expertSSAll === false && this.state.bZoomClick === true)
                document.getElementById('zoomlevelselector').style.display = 'block';

            if (this.state.expertSSAll === true && this.state.bShareAsset) {
                const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, isPointerActive, pencolor} = this.state;
                let shape_selected = "";
                if (isFreeDrawing === true) shape_selected = "freehand";
                else if (isRectActive === true) shape_selected = "rectangle";
                else if (isCircleActive === true) shape_selected = "circle";
                else if (isArrowActive === true) shape_selected = "arrow";
                else if (isPointerActive === true) shape_selected = "pointer"; //TP-2475
                if (this.state.shareOnDiv === null) {
                    /**************Side Bar Share Asset ongoing************** */
                    
                    //Send signal to all users to remove the shared artifacts
                    g_subInfoArr.forEach(subInfo => {
                        const toArray = subInfo.g_stream.connection.data.split(":::");
                        const toObject = toArray[3];
                            this.sessionSignal.sendSignalRemoveArtifacts(
                                true,
                                toObject
                                //type: "REMOVE_ARTIFACTS"
                            );
                        //printConsole("remove artifacts signal sent...");
                    })
                    // Send signal to all experts to enable the Hand Icon
                    if (this.state.gridViewMode === true){
                        // T32-463 -- New Multi-expert Signal trigger implementation                    
                        this.sendSignalSyncExperts({
                            show_grid: true,
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"},
                        null);  // send the "show_grid" value as true for Grid View Mode                        
                    } else {                        
                        g_subInfoArr.forEach((subInfo) => {
                            if (subInfo.g_divId === g_maxDivId) {
                                // T32-463 -- New Multi-expert Signal trigger implementation
                                const dataArr = subInfo.g_stream.connection.data.split(":::")
                                this.sendSignalSyncExperts({
                                    show_grid: false,
                                    max_view_technician: dataArr[3], 
                                    annotation_active: this.state.bAnnotate,
                                    annotate_color: pencolor,
                                    shape_selected,
                                    hand_icon_enabled: true,
                                    header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                    reason: "show_action"}, 
                                null);  // send the max div tech details                                  
                            }
                        })
                    }
                } else {
                    /**********************Max-View Technician Share Asset ongoing**************** */
                    //Send signal to only Max-div user to remove the shared artifacts
                    /* g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId) {
                            const toArray = subInfo.g_stream.connection.data.split(":::");
                            const toObject = toArray[3];
                            this.sessionSignal.sendSignalRemoveArtifacts(
                                true,
                                toObject
                                //type: "REMOVE_ARTIFACTS"
                            );
                        }
                    }); */
                    //Send signal to all experts to remove the shared artifacts
                    /* g_expertConnsArr.forEach(connectionObj => {
                        if (g_session.connection !== connectionObj) {
                            const toArray = connectionObj.data.split(":::");
                            const toObject = toArray[3];
                            this.sessionSignal.sendSignalRemoveArtifacts(
                                true,
                                toObject
                                //type: "REMOVE_ARTIFACTS"
    
                            );
                        }
                    }); */  
                    
                    //Send signal to all users to remove the shared artifacts
                    g_subInfoArr.forEach(subInfo => {
                        const toArray = subInfo.g_stream.connection.data.split(":::");
                        const toObject = toArray[3];
                            this.sessionSignal.sendSignalRemoveArtifacts(
                                true,
                                toObject
                                //type: "REMOVE_ARTIFACTS"
                            );
                        //printConsole("remove artifacts signal sent...");
                    })
                    // Send signal to all experts to enable the Hand Icon   
                    if (this.state.gridViewMode === true){
                        // T32-463 -- New Multi-expert Signal trigger implementation                    
                        this.sendSignalSyncExperts({
                            show_grid: true,
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"},
                        null);  // send the "show_grid" value as true for Grid View Mode                        
                    } else {         
                        g_subInfoArr.forEach((subInfo) => {
                            if (subInfo.g_divId === g_maxDivId) {
                                // T32-463 -- New Multi-expert Signal trigger implementation
                                const dataArr = subInfo.g_stream.connection.data.split(":::")
                                this.sendSignalSyncExperts({
                                    show_grid: false,
                                    max_view_technician: dataArr[3], 
                                    annotation_active: this.state.bAnnotate,
                                    annotate_color: pencolor,
                                    shape_selected,
                                    hand_icon_enabled: true,
                                    header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                    reason: "show_action"}, 
                                null);  // send the max div tech details                                  
                            }
                        })
                    }
                }
            } 
            // change the video source to be screen
            this.rePublishExpertStream(this.state.expertSSAll, null, flag, sendArtifacts);
        });        
    }

    // Side Bar Chat on/off function to all participants
    onClickChatForAll = (buttonState) => {
        if (buttonState === true) {
            this.setState({ bAllChat: buttonState }, () => {
                if (this.state.bMaxChat === true && this.state.bAllChat === true) {
                    this.onClickMaxChat();
                } else {
                    this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
                }
            });
        }
        else {
            this.setState({
                bAllChat: buttonState,
                chatUserEmails: [],
                chatUserNames: []
            }, () => {
                this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
            });
        }
    }

    sendChatMsg = (msgToSend, elemId) => {
        // save message in list
        const {g_session, g_subInfoArr} = this.state; 
        let timeStamp = this.props.getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: this.props.sessionInfo.firstname,
            lastName: this.props.sessionInfo.lastname, message: msgToSend, timeStamp: timeStamp, msgType: 1
        };

        this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}))
        this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
        let sixdigitsrandom = 0; //MB2-95

        // send this message out
        // NS2-347
        if (g_session !== null && elemId !== null && g_subInfoArr.length > 0) {
            sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
            g_subInfoArr.forEach(subInfo => {
                if (subInfo.g_divId === elemId) {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalChat(

                        //from: g_session.connection.data,
                        sixdigitsrandom, //MB2-95
                        msgToSend,
                        toObject
                        //type:"IM"

                    );
                    //console.log("signal sent.");
                    return;
                }
            })
        }        
        else if (g_session !== null && elemId === null) {
            sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); // MB2-95
            this.sessionSignal.sendSignalChat(

                //from: g_session.connection.data,
                sixdigitsrandom,  // MB2-95
                msgToSend
                //type:"IM"

            );
            //console.log("signal sent.");
        }
        return;
    }

    // Onclick functions for Passive Expert Max-div Buttons (Hand Icon)
    // on Click Function for Hand Icon beside the Timer component
    onClickHandIcon = () => {
        const { clickHandSignal } = this.state;
        const { user } = this.props;
        //console.log(g_expertConnsArr);
        if (clickHandSignal === false) {
            this.setState({ clickHandSignal: true }, () => {
                const nLen = g_expertConnsArr.length;                
                for (let ii = 0; ii < nLen; ii++) {
                    let connData = g_expertConnsArr[ii].data;
                    let connArray = connData.split(':::');
                    if (parseInt(connArray[0]) === this.state.currentPrimaryExpert) {
                        const expertDivConnObj = g_expertConnsArr[ii];
                        const data = { 'firstName': user.firstname, 'lastName': user.lastname };
                        this.sendSignalRequestMaxDivControl(data, expertDivConnObj);
                        break;
                    }
                }

                this.waitTimer = setTimeout(() => { 
                    if (otExpObj.state.disableHand === false && otExpObj.state.clickHandSignal === true){
                        if (this.props.sessionInfo.primary_expert_id !== otExpObj.props.loggedInUserId && otExpObj.state.g_subInfoArr.length > 0 && g_expertConnsArr.length === 0) {
                            //console.log("exception accured!!");
                            otExpObj.props.setPrimaryExpertIDApi(); //NS2-490
                            otExpObj.setState({ currentPrimaryExpert: otExpObj.props.loggedInUserId }); //NS2-486
                            otExpObj.setState({ showHideMaxDivControl: true }, () => {
                                // TP-359 -- New Socket signal to be sent to all technicians 
                                // announcing the current Primary expert 
                                otExpObj.sendSignalCurrentPrimaryExpert();
                                otExpObj.setState({isParticipantChanged : true}, () => {
                                    otExpObj.setState({isParticipantChanged : false});
                                });// to change the Side Bar button state
                            });
                            otExpObj.props.updateShowHideHeaderActionButtons(true);
                            if (otExpObj.state.gridViewMode === false)
                                otExpObj.showHideRcForTech(true);
                        }
                    }
                    clearTimeout(this.waitTimer);
                }, 48000); 
            });
        }
    }
    
    // Onclick functions for the Request Max-div Dialog Box
    onClickAcceptMaxDivControl = () => {
        const { expertFirstName, expertLastName, g_subInfoArr, bShareAsset, bRemoteCapture, bAnnotate, bZoomClick, zoom_level, bTorchClick, startRecording, expertShareScreenMaxDiv, expertSSAll } = this.state;
        const nLen = g_expertConnsArr.length;
        let flg = false;
        let senderEmail = "";
        if (nLen > 0) {
            for (let ii = 0; ii < nLen; ii++) {
                let connData = g_expertConnsArr[ii].data;
                let connArray = connData.split(':::');
                if (connArray[1] === expertFirstName && connArray[2] === expertLastName) {
                    const data = true;
                    if (expertSSAll) {
                        this.onClickExpertScreenShareForAll(false, false, false); //TP-1451
                        if (bShareAsset)
                            this.closeFileViewer();
                    } else if (expertShareScreenMaxDiv) {
                        this.expertScreenShareClick(false);
                        if (bShareAsset)
                            this.closeFileViewer();
                    }
                    if (bRemoteCapture) {
                        // turn on RC+Annotation as passive Expert
                        this.setState({ rcImgObj: this.state.strFileName, rcImgType: this.state.format }, () => {
                            //strCanvasJSON = "";
                            if (this.state.isPointerActive) this.clearPointerAnnotation(); //TP-2474 & TP-2553
                            let expertMaxdivElement = document.getElementById(g_maxDivId);
                            onDrawPassiveExperts(expertMaxdivElement, bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                        });
                    }
                    else if (bAnnotate) { 
                        // turn on annotation as passive Experts
                        //TP-1179 -- Clear off the old annotation 
                        // drawings if shareAsset is on along with
                        // annotations
                        if (bShareAsset)
                            strCanvasJSON = "";
                        if (this.state.isPointerActive) this.clearPointerAnnotation(); //TP-2474 & TP-2553
                        let expertMaxdivElement = document.getElementById(g_maxDivId);
                        onDrawPassiveExperts(expertMaxdivElement, bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                    }                    
                    if ((bZoomClick || bTorchClick) && this.state.gridViewMode === false) {
                        g_subInfoArr.forEach(subInfo => {
                            if (g_maxDivId === subInfo.g_divId){
                                const dataArr = subInfo.g_stream.connection.data.split(":::");
                                this.sendSignalSyncExperts({
                                    show_grid: false,
                                    max_view_technician: dataArr[3],                            
                                    hand_icon_enabled: true, 
                                    zoom_button_active: bZoomClick,
                                    zoom_level: parseInt(zoom_level), 
                                    torch_button_active: bTorchClick,     
                                    header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378                   
                                    reason: "show_action"
                                }, connArray[3]); 
                            }
                        })
                    }
                    // Pausing the Screen recording when the View Controls
                    // are transferred  
                    if (startRecording && isSilentRecording === false) {
                        this.stopRecordingCallback("pause");
                        this.recordingTimer = setTimeout(() => {
                            this.sendSignalYieldMaxDivControls(data, connArray[3]);
                            clearTimeout(this.recordingTimer);
                        }, 2000);
                        //this.stopScreenshotRecordingCb();                        
                    } else {
                        //TP-2809 -- Send the yield signal a second later than the clear Pointer Annotation signal
                        if (bAnnotate || bRemoteCapture) {
                            this.yieldTime = setTimeout(() => {
                                this.sendSignalYieldMaxDivControls(data, connArray[3]);
                                clearTimeout(this.yieldTime);
                            }, 1000);
                        } else 
                            this.sendSignalYieldMaxDivControls(data, connArray[3]);
                    }                   
                    this.setState({ isPrimaryToPassive: true });
                    //this.props.setPrimaryExpertIDApi(parseInt(connArray[0]));//NS2-490
                    //this.setState({ currentPrimaryExpert: parseInt(connArray[0]) }); //NS2-486
                    //this.sendSignalCurrentPrimaryExpert(parseInt(connArray[0])); //NS2-486
                    senderEmail = connArray[3];
                    flg = true;
                    break;
                }
            }
            if (flg === true) {
                //this.setState({ showHideMaxDivControl: false, disableHand: false });
                //this.hideGridViewIcon(true);
                for (let ii=0; ii< g_subInfoArr.length; ii++) {
                    //hide the disconnect user button on top of the video feed elements
                    let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                    if (disconnectuser !== undefined && disconnectuser !== null) {
                        disconnectuser.style.display = "none";                    
                    }

                    //show the gridcontrol icons on top of video element
                    let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                    if (gridcontrol !== undefined && gridcontrol !== null)
                        gridcontrol.style.display = "block";
                            
                    // hide the Video Turn off Icon on Passive expert's Grid View
                    let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);
                    if (videoIcon !== undefined && videoIcon !== null) 
                        videoIcon.style.display = "none";
                }
                
                //NS2-372
                //this.sendSignalSyncExperts({reason: "request_expert_sync"}, null);
                // for NS2-137
                this.props.updateShowHideHeaderActionButtons(false);
                this.hideRequestDialog();
                this.sendSignalYieldDisableHandIcon(false, `${expertFirstName} ${expertLastName}`, senderEmail);
            } else {
                this.hideRequestDialog();
                this.sendSignalYieldDisableHandIcon(false, `${expertFirstName} ${expertLastName}`, null);
                this.triggerMessage(`${expertFirstName} ${expertLastName}`, "yieldpositive");
                //console.log(`The expert ${expertFirstName} ${expertLastName} can't be granted Max-div controls since he has left this call!!`)
            }
        } else {
            this.hideRequestDialog();
            this.triggerMessage(`${expertFirstName} ${expertLastName}`, "yieldpositive");
            //console.log("No other experts are currently in session!!");
        }
    }

    onClickDenyMaxDivControl = () => {
        const { expertFirstName, expertLastName } = this.state;        
        const nLen = g_expertConnsArr.length;
        let flg = false;
        let senderEmail = "";
        if (nLen > 0) {
            for (let ii = 0; ii < nLen; ii++) {                
                let connData = g_expertConnsArr[ii].data;
                let connArray = connData.split(':::');
                if (connArray[1] === expertFirstName && connArray[2] === expertLastName) {
                    const data = false;
                    this.sendSignalYieldMaxDivControls(data, connArray[3]);
                    senderEmail = connArray[3];
                    flg = true;
                    break;
                }
            }
            if (flg === true) {
                this.hideRequestDialog();
                const isOngoingMaxDivFeatures = this.isOngoingMaxDivFeaturesForDenyControl();
                this.setState({ isOngoingMaxDivFeatures });
                this.sendSignalYieldDisableHandIcon(false, `${expertFirstName} ${expertLastName}`, null);
            } else {
                this.hideRequestDialog();
                const isOngoingMaxDivFeatures = this.isOngoingMaxDivFeaturesForDenyControl();
                this.setState({ isOngoingMaxDivFeatures });
                this.sendSignalYieldDisableHandIcon(false, `${expertFirstName} ${expertLastName}`, null);
                this.triggerMessage(`${expertFirstName} ${expertLastName}`, "yieldnegative");
                //console.log(`The expert ${expertFirstName} ${expertLastName} has left this call!!`)
            }

        }else {
            this.hideRequestDialog();
            this.triggerMessage(`${expertFirstName} ${expertLastName}`, "yieldnegative");
            //console.log("No other experts are currently in session!!");
        }
    }


    // Onclick functions for Expert Max-div Features

    onClickAnnotate = async () => {
        let {bAllChat, bMaxChat, bRemoteCapture, bAnnotate} = this.state;
        /* diagonastics(this.authService,{
            action: `Annotate button clicked`,
            next_step: '',
            data: `bRemoteCapture:${bRemoteCapture}, bAnnotate:${bAnnotate}`,
            error: 'none'
        }) */
        if (bAllChat === true) {
            //this.props.updateChatAll(false); // for T32-48
            //this.onClickChatForAll(false);
        } else if (bMaxChat === true) {
            this.onClickMaxChat();
        }
        if(bRemoteCapture === true){
            this.stopExpertRCAnnotate(); // NS2-345
            this.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
            this.setState({bRemoteCapture: false, isOngoingMaxDivFeatures: false});
            this.props.updateScreenShareMaxdiv(false); // NS2-376
            this.props.updateFileShareMaxdiv(false); // NS2-376
            this.setState({sRCTechDetails: ''});
            if(bAnnotate === true)
                this.turnOnAnnotate(false, null);
            else
                this.setState({bAnnotate: false});

            // TP-905 --  Handling of Zoom level panel when RC is turned off by clicking on Annotate button
            const zoomSelector = document.getElementById('zoomlevelselector');
            if (parseInt(this.state.zoom_level) > 1 && zoomSelector) {
                zoomSelector.style.display = 'block';
            } else if (parseInt(this.state.zoom_level) === 1 && zoomSelector && this.state.bZoomClick) {
                this.setState({bZoomClick: false}) // Make the Zoom button inactive
            }
        }
        else{
            //TP-2833
            if (bAnnotate === true) {
                this.setState({ isOngoingMaxDivFeatures: true }); 
                await this.turnOnAnnotate(false, null);
            }
            if (this.state.showHideMaxDivControl && !(this.state.expertSSAll && g_expertConnsArr.length >0)) {
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.g_divId === g_maxDivId) {
                        const dataArr = subInfo.g_stream.connection.data.split(":::")
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            annotation_active: !bAnnotate, //TP-2833
                            zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                            zoom_level: subInfo.zoomLevel,
                            torch_button_active: subInfo.torchActive,
                            annotate_color: "red",
                            shape_selected: "freehand",
                            hand_icon_enabled: true,      
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378                      
                            reason: "show_action"},  
                        null);
                    }
                });                        
            }
            //TP-2833
            if (bAnnotate === false) {
                this.setState({ isOngoingMaxDivFeatures: true }); 
                await this.turnOnAnnotate(false, null);
            }
        }
    } 

    // NS2-258
    onClickRectIcon = () => {
        const { isRectActive, pencolor, g_subInfoArr, isPointerActive } = this.state;
        if (isRectActive === false) {
            annotateShapesArray[6] = annotateShapesArray[2];
            this.setState({
                isFreeDrawing: false,
                isRectActive: true,
                isCircleActive: false,
                isArrowActive: false,
                isPointerActive: false //TP-2586 && TP-2475
            }, () => {
                //TP-2586
                if (isPointerActive)
                    onClickPointerTrack(document.getElementById(g_maxDivId));
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);

                const shape_selected = "rectangle";
                if (this.state.showHideMaxDivControl && !(this.state.expertSSAll && g_expertConnsArr.length >0)) {
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId) {
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                annotation_active: this.state.bAnnotate, 
                                zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                zoom_level: subInfo.zoomLevel,
                                torch_button_active: subInfo.torchActive,
                                annotate_color: pencolor,
                                shape_selected,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            null);  // send the max div tech details                        
                        }
                    })
                }
            });
        }
    }
    onClickCircleIcon = () => {
        const { isCircleActive, pencolor, g_subInfoArr, isPointerActive } = this.state;
        if (isCircleActive === false) {
            annotateShapesArray[6] = annotateShapesArray[1];
            this.setState({
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: true,
                isArrowActive: false,
                isPointerActive: false //TP-2586 & TP-2475
            }, () => {
                //TP-2586
                if (isPointerActive)
                    onClickPointerTrack(document.getElementById(g_maxDivId));
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);

                const shape_selected = "circle";
                if (this.state.showHideMaxDivControl && !(this.state.expertSSAll && g_expertConnsArr.length >0)) {
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId) {
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                annotation_active: this.state.bAnnotate, 
                                zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                zoom_level: subInfo.zoomLevel,
                                torch_button_active: subInfo.torchActive,
                                annotate_color: pencolor,
                                shape_selected,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            null);  // send the max div tech details                        
                        }
                    })
                }
            });
        }
    }
    onClickCrossHairsIcon = () => {
        const { isFreeDrawing, pencolor, g_subInfoArr, isPointerActive } = this.state;
        if (isFreeDrawing === false) {
            annotateShapesArray[6] = annotateShapesArray[0];
            this.setState({
                isFreeDrawing: true,
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false,
                isPointerActive: false //TP-2586 & TP-2475
            }, () => {
                //TP-2586
                if (isPointerActive)
                    onClickPointerTrack(document.getElementById(g_maxDivId));
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
                const shape_selected = "freehand";

                if (this.state.showHideMaxDivControl && !(this.state.expertSSAll && g_expertConnsArr.length >0)) {
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId) {
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                annotation_active: this.state.bAnnotate, 
                                zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                zoom_level: subInfo.zoomLevel,
                                torch_button_active: subInfo.torchActive,
                                annotate_color: pencolor,
                                shape_selected,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            null);  // send the max div tech details                        
                        }
                    })
                }
            });
        }
    }

    onClickArrowIcon = () => {
        const { isArrowActive, pencolor, g_subInfoArr, isPointerActive } = this.state;
        if (isArrowActive === false) {
            annotateShapesArray[6] = annotateShapesArray[3];
            this.setState({
                isArrowActive: true,
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: false,
                isPointerActive: false //TP-2586 & TP-2475
            }, () => {
                //TP-2586
                if (isPointerActive)
                    onClickPointerTrack(document.getElementById(g_maxDivId));
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
                const shape_selected = "arrow";
                if (this.state.showHideMaxDivControl && !(this.state.expertSSAll && g_expertConnsArr.length >0)) {
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId) {
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                annotation_active: this.state.bAnnotate, 
                                zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                zoom_level: subInfo.zoomLevel,
                                torch_button_active: subInfo.torchActive,
                                annotate_color: pencolor,
                                shape_selected,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            null);  // send the max div tech details                        
                        }
                    })
                }
            });
        }
    }

    //TP-2475 -- Pointer annotation onclick method
    onClickPointerIcon = () => {
        const { isPointerActive, pencolor, g_subInfoArr } = this.state;
        if (isPointerActive === false) {
            annotateShapesArray[6] = annotateShapesArray[4];
            this.setState({
                isPointerActive: true,
                isArrowActive: false,
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: false
            }, () => {
                //TP-2586
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
                onClickPointerTrack(document.getElementById(g_maxDivId));
                // TP-2474 -- Commented out all of this for now
                const shape_selected = "pointer";
                if (this.state.showHideMaxDivControl && !(this.state.expertSSAll && g_expertConnsArr.length >0)) {
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId) {
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                annotation_active: this.state.bAnnotate, 
                                zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                zoom_level: subInfo.zoomLevel,
                                torch_button_active: subInfo.torchActive,
                                annotate_color: pencolor,
                                shape_selected,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            null);  // send the max div tech details                        
                        }
                    })
                }
            });
        } /* else {
            this.setState({
                isPointerActive: false,
                /* TP-2474 -- isArrowActive: false,
                isFreeDrawing: false,
                isRectActive: false,
                isCircleActive: false 
            }, () => {
                onClickPointerTrack(document.getElementById(g_maxDivId));
                // TP-2474 -- Commented out all of this for now
                // removeCanvasEvents();
                //onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
                //const shape_selected = "pointer";
                /* if (this.state.showHideMaxDivControl && !(this.state.expertSSAll && g_expertConnsArr.length >0)) {
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId) {
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                annotation_active: this.state.bAnnotate, 
                                zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                zoom_level: subInfo.zoomLevel,
                                torch_button_active: subInfo.torchActive,
                                annotate_color: pencolor,
                                shape_selected,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            null);  // send the max div tech details                        
                        }
                    })
                } 
            });
        } */
    }

    // TP-2474 -- Erase Annotation Icon onclick feature implementation
    onClickErasorIcon = () => {
        const { isErasorActive, pencolor } = this.state;
        if (isErasorActive === false) {
            this.setState({ 
                isErasorActive : true,
                isArrowActive: false,
                isFreeDrawing: false, //TP-2586
                isRectActive: false,
                isCircleActive: false,
                isPointerActive: false
            }, () => {
                //removeCanvasEvents();
                //clearAnnotationsCanvas(document.getElementById(g_maxDivId), this.state.bRemoteCapture);
                this.clearAnnotations();
                strCanvasJSON = ""; //TP-2474
                this.sendAnnotationToExpert('');
                this.sendAnnotation(clearAnnotateObj, null);
                this.setState({ isErasorActive: false });

                removeCanvasEvents();
                onClickPointerTrack(document.getElementById(g_maxDivId));
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
                const shape_selected = "freehand";

                if (this.state.showHideMaxDivControl && !(this.state.expertSSAll && g_expertConnsArr.length >0)) {
                    //TP-2545 -- Send the default Annotation settings to the Passive expert when canvas is erased
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId) {
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                annotation_active: this.state.bAnnotate, 
                                zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                zoom_level: subInfo.zoomLevel,
                                torch_button_active: subInfo.torchActive,
                                annotate_color: pencolor,
                                shape_selected,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            null);  // send the max div tech details                        
                        }
                    })                    
                    //TP-2474 -- Multi expert handling for Erasor feature being turned on by Primary expert
                    this.sendSignalSyncExperts({                            
                        reason: "canvas_erased"}, 
                    null);  // send the erasor turned on status to the Passive experts currently in session                        
                }
            })
        }
    }

    onClickShape = () => {
        const { bSelectShape } = this.state;
        const shapeselector = document.getElementById('shapeselector');
        const sketchPicker = document.getElementById('sketchPicker');
        if (bSelectShape === true) {
            this.setState({ bSelectShape: false });
            shapeselector.style.display = 'none';
        } else {
            this.setState({ 
                bSelectColor: false,
                bSelectShape: true 
            });
            // T32-532
            if (sketchPicker !== null && sketchPicker !== undefined)
                sketchPicker.style.display = 'none';
            shapeselector.style.display = 'block';
        }
    }

    //NS2-217
    onClickPenIcon = () => {
        const { bSelectColor } = this.state;
        const sketchPicker = document.getElementById('sketchPicker');
        const shapeselector = document.getElementById('shapeselector');
        if (bSelectColor === true) {
            this.setState({ bSelectColor : false });
            sketchPicker.style.display = 'none';
        } else {
            this.setState({ 
                bSelectColor : true,
                //bSelectShape: false /**TP-2491 */
            });
            // T32-532
            // TP-2491 -- Commeted out for UI changes done to Annotation shapes
            /* if (shapeselector !== null && shapeselector !== undefined)
                shapeselector.style.display = 'none'; */
            sketchPicker.style.display = 'block';
        }
    }

    // NS2-217
    onChangeColor = (color) => {
        const {g_subInfoArr, isArrowActive, isFreeDrawing, isRectActive, isCircleActive, isPointerActive} = this.state;
        let shape_selected = "";
        if (isFreeDrawing === true) shape_selected = "freehand";
        else if (isRectActive === true) shape_selected = "rectangle";
        else if (isCircleActive === true) shape_selected = "circle";
        else if (isArrowActive === true) shape_selected = "arrow";
        else if (isPointerActive === true) shape_selected = "pointer"; //TP-2586 & TP-2475
        this.setState({ pencolor: color.hex }, () => {
            //console.log(this.state.bRemoteCapture, this.state.urlObj);
            //TP-2739
            if (!isPointerActive || isPointerActive === false) {
                printConsole("Color change so canvas events are to be removed only if Pointer is not turned on!!");
                removeCanvasEvents();
                onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
            }
            /* const sketchPicker = document.getElementById('sketchPicker');
            this.setState({ bSelectColor: false });
            sketchPicker.style.display = 'none'; */
            if (this.state.showHideMaxDivControl && !(this.state.expertSSAll && g_expertConnsArr.length >0)) {
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.g_divId === g_maxDivId) {
                        // T32-463 -- New Multi-expert Signal trigger implementation
                        const dataArr = subInfo.g_stream.connection.data.split(":::")
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            annotation_active: this.state.bAnnotate, 
                            zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                            zoom_level: subInfo.zoomLevel,
                            torch_button_active: subInfo.torchActive,
                            annotate_color: color.hex,
                            shape_selected,
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"}, 
                        null);  // send the max div tech details                        
                        //break;
                    }
                })
            }
        });
    };
    
    onClickMaxChat = () => {
        this.setState((prevState) => ({
            bMaxChat: !prevState.bMaxChat
        }), () => {
            //let flg = false;  
            if(this.state.bMaxChat){
                if(this.state.bRemoteCapture === true) this.onClickCamera();
                else if (this.state.bAnnotate === true) this.onClickAnnotate();// NS2-333
                if(this.state.bAllChat === true){
                    // while resetting the chat all flag we need to reset the header
                    // bar togglechat also and change the state of chatuseremails,
                    // chatusernames
                    this.setState({
                        bAllChat: false,
                        chatUserEmails: [],
                        chatUserNames: []
                    });
                    this.props.onSessionCmdChange('session_chat_off');
                    this.props.updateChatAll(false);
                }                
            }          
            //this.drawSubscriberUI(document.documentElement.clientHeight, window.innerWidth, null, flg);
            this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
        });
    }

    onClickCamera = (flag = false) => {
        let { bRemoteCapture, bAnnotate, g_subInfoArr, isLoading } = this.state;
        /* diagonastics(this.authService, {
            action: `RC button clicked`,
            next_step: '',
            data: `bRemoteCapture:${bRemoteCapture}, bAnnotate:${bAnnotate}`,
            error: 'none'
        }) */
        if (bRemoteCapture === true) {
            //printConsole("close RC");
            //printConsole(`Loader is on ${isLoading}`);
            if(isLoading === true) this.setState({ isLoading: false });
            this.stopExpertRCAnnotate();// NS2-345
            this.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
            this.setState({ bRemoteCapture: false, isOngoingMaxDivFeatures: false });
            this.setState({ bRCAnnotateOn: false });
            this.setState({ sRCTechDetails: '' });
            this.setState({ strFileName: '' });
            if (bAnnotate === true)
                this.turnOnAnnotate(false, null);
            else
                this.setState({ bAnnotate: false });
            this.props.updateRCStatus(false);
            this.props.updateScreenShareMaxdiv(false);// NS2-376    
            this.props.updateFileShareMaxdiv(false); // NS2-376
            // TP-393 --  Handling of Zoom level panel when RC is turned off 
            const zoomSelector = document.getElementById('zoomlevelselector');
            if (parseInt(this.state.zoom_level) > 1 && zoomSelector) {
                zoomSelector.style.display = 'block';
            } else if (parseInt(this.state.zoom_level) === 1 && zoomSelector && this.state.bZoomClick) {
                this.setState({bZoomClick: false}) // Make the Zoom button inactive
            }
            if (flag === true && document.getElementById('canvas') !== undefined && document.getElementById('canvas') !== null && document.getElementById('canvas') !== '') {
                printConsole("clearing Annotations while cancelling RC.....")
                let sketchPicker, shapeselector;
                if (this.state.bHideAnnotateColour === false) {
                    sketchPicker = document.getElementById('sketchPicker');
                }
                if (this.state.bHideAnnotateShapes === false) {
                    shapeselector = document.getElementById('shapeselector');
                }
                if (this.state.bHideAnnotateColour === false)
                    sketchPicker.style.display = 'none';
                if (this.state.bHideAnnotateShapes === false)
                    shapeselector.style.display = 'none';
                this.setState({
                        bSelectColor: false,
                        bSelectShape: false,
                        pencolor: "red",
                        isFreeDrawing: false, /**TP-2586 */
                        isRectActive: false,
                        isCircleActive: false,
                        isArrowActive: false,
                        isPointerActive: false, /**TP-2475 */
                        isErasorActive: false //TP-2474
                }, () => {
                    printConsole("Clear the Annotations after Cancel RC");
                    strCanvasJSON = "";
                    onClickDraw(document.getElementById(g_maxDivId), false, null);
                    //this.setState({ bAnnotate: false, isOngoingMaxDivFeatures: (this.state.expertShareScreenMaxDiv) ? true : false });
                });
            }
        }
        else {
            //printConsole("STart rc");
            this.setState({ isOngoingMaxDivFeatures: true })
            if (this.state.bAllChat === true) {
                this.props.updateChatAll(false);
                this.onClickChatForAll(false);
            } else if (this.state.bMaxChat === true) {
                this.onClickMaxChat();
            }
            //MB2-502
            if (this.state.bAnnotate === true) {
                this.setState({ bAnnotate: false });
                //TP-2543 -- Use case #5
                if (this.state.isPointerActive) {
                    this.setState({ isPointerActive: false }, () => {      
                        printConsole("turn off Pointer before RC starts");               
                        onClickPointerTrack(document.getElementById(g_maxDivId));
                    })
                }
                let sketchPicker = document.getElementById('sketchPicker');
                let shapeselector = document.getElementById('shapeselector');
                if (sketchPicker)
                    sketchPicker.style.display = 'none';
                if (shapeselector)
                    shapeselector.style.display = 'none';
            }

            // TP-393 --  Handling of Zoom level panel when RC is turned on
            const zoomSelector = document.getElementById('zoomlevelselector');
            if (this.state.bZoomClick && zoomSelector) {
                zoomSelector.style.display = 'none';
            }
            this.props.updateScreenShareMaxdiv(true);
            this.props.updateFileShareMaxdiv(true);
            this.props.updateRCStatus(true);
            g_subInfoArr.forEach(subInfo => {
                if (subInfo.g_divId === g_maxDivId) {
                    if (subInfo.canRC === true) {
                        this.setState({ bRemoteCapture: true });
                        this.setState({ bRCAnnotateOn: true });
                        this.sendSignalExpertRemoteClick('', null);// for NS2-345
                        this.remoteClick('', document.getElementById(g_maxDivId));
                    }
                }
            })
        }
    }

    onClickCancelShareAsset = () => {
        const {bAnnotate, bRemoteCapture} = this.state; //TP-2438
        if (this.state.bShareAsset === true) {
            bCancelShare = true;
            this.props.updateFileShare(false);
            this.setState({bShareAsset: false, fileData: null, isLoading: false, shareOnDiv: '', isOngoingMaxDivFeatures: false});
            //this.onCloseFileViewer();
            const {g_subInfoArr} = this.state;
            this.setState({ assetsArray: [], bShareAsset: false }, () => {
                const shapeselector = document.getElementById('shapeselector')
                if (shapeselector !== undefined && shapeselector !== null && bAnnotate && !bRemoteCapture) {
                    shapeselector.style.display = "block";
                }
            });
            if (g_subInfoArr.length > 0) {
                g_subInfoArr.forEach(subInfo => {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3];
                        this.sessionSignal.sendSignalRemoveArtifacts(
                            true,
                            toObject
                            //type: "REMOVE_ARTIFACTS"
                        );
                    //printConsole("remove artifacts signal sent...");
                    // T32-592 -- Enable the Passive expert's Hand Icons when 
                    // File share is closed
                    if (this.state.gridViewMode === false && subInfo.g_divId === g_maxDivId) {
                        const dataArr = subInfo.g_stream.connection.data.split(":::");
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"}, 
                        null);
                    } else if (this.state.gridViewMode === true) {
                        this.sendSignalSyncExperts({
                            show_grid: true,
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"}, 
                        null);
                    }
                });
            }
            if (bCancelShare === true) bCancelShare = false;
        }
    }

    onClickUpload = () => {
        const { g_subInfoArr } = this.state; //TP-2438
        g_subInfoArr.forEach(subInfo => {
            if (subInfo.g_divId === g_maxDivId) {
                // T32-463 -- New Multi-expert Signal trigger implementation
                const dataArr = subInfo.g_stream.connection.data.split(":::")
                this.sendSignalSyncExperts({
                    show_grid: false,
                    max_view_technician: dataArr[3], 
                    annotation_active: this.state.bAnnotate,                             
                    zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                    zoom_level: subInfo.zoomLevel,
                    torch_button_active: subInfo.torchActive,
                    annotate_color: "red",
                    shape_selected: "freehand",
                    hand_icon_enabled: true,
                    header_fileShare_active: false, //TP-2378             
                    reason: "show_action"}, 
                null);  // send the max div tech details                        
            }
        })
        //this.setState({ bShareAsset: (this.state.bShareAsset === true) ? false : true });
        /* diagonastics(this.authService, {
            action: `on click of max upload`,
            next_step: '',
            data: `bShareAsset:${this.state.bShareAsset}, bAnnotate:${this.state.bAnnotate}, bRemoteCapture:${this.state.bRemoteCapture},`,
            error: 'none'
        }) */
        if (this.state.bShareAsset) {
            /* this.setState({ isOngoingMaxDivFeatures: (this.state.expertShareScreenMaxDiv) ? true : false });
            this.setState({ bAnnotate: false });
            this.setState({ bRemoteCapture: false });
            this.setState({ sRCTechDetails: '' });            
            if (this.state.expertSSAll === true && this.state.expertShareScreenMaxDiv === true)
                this.props.updateScreenShareMaxdiv(false); // NS2-376
            this.props.updateFileShareMaxdiv(false); */ // NS2-376
        } else {
            if (this.state.startRecording === true && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                this.handlePauseRecordingDuringAnyFeature();
            }
            this.onClickUploadFile(document.getElementById(g_maxDivId));
        }
    }

    onClickUploadFile = (paramElem) => {
        fileDialog({ multiple: true, accept: '.xlsx, .mp3, .mp4, .csv, .mov, .jpg, .pdf, .png, .obj, .mtl, .docx, .3ds, .bmp, .tga, .exr, .fbx' })
            .then(files => {
                this.setState({ shareOnDiv: paramElem }, () => {
                    if (this.state.bAnnotate && !this.state.bRemoteCapture) {
                        //this.resetAnnotationShapesColors(true);   
                        //TP-2838
                        if (this.state.isPointerActive)
                            this.turnOffPointerAnnotation(document.getElementById(g_maxDivId));
                        strCanvasJSON = '';
                        this.startAnnotate();
                        //Clear annotation drawings on Primary expert user
                        let drawElem = document.getElementById('canvas');
                        if (drawElem !== null && drawElem !== undefined) {
                            g_bCleanSlate = true;
        
                            let canvas = drawElem.fabric;
                            let canvasObjects = canvas.getObjects();
                            let idx = 0;
                            for (idx = 0; idx < canvasObjects.length; idx++)
                                canvas.remove(canvasObjects[idx]);
                            drawElem.fabric.freeDrawingBrush.color = this.state.pencolor;
                            //TP-1179
                            clearAnnotationsCanvas(document.getElementById(g_maxDivId), this.state.bRemoteCapture);
                            g_subInfoArr.forEach(subInfo => {
                                if (subInfo.g_divId === g_maxDivId) {
                                    // T32-463 -- New Multi-expert Signal trigger implementation
                                    const dataArr = subInfo.g_stream.connection.data.split(":::")
                                    this.sendSignalSyncExperts({
                                        show_grid: false,
                                        max_view_technician: dataArr[3], 
                                        annotation_active: this.state.bAnnotate,                             
                                        zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                        zoom_level: subInfo.zoomLevel,
                                        torch_button_active: subInfo.torchActive,
                                        annotate_color: "red",
                                        shape_selected: "freehand",
                                        hand_icon_enabled: true,
                                        header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                        reason: "show_action"}, 
                                    null);  // send the max div tech details                        
                                    //break;
                                }
                            })
                        }
                        // Clear the annotations on all users
                        this.sendAnnotation(clearAnnotateObj, null);
                    }
                });
                if (paramElem === null) {
                    this.props.updateFileShare(true);
                    this.setState({ bShareAsset: true, fileData: null, isOngoingMaxDivFeatures: true, 
                        //TP-1444:Rs Added to fix opacity value should be reset default when new file shared
                        fileOpacity:this.state.fileOpacity !=="0.60" && files && files[0] ? "0.60":this.state.fileOpacity,
                        /*TP-1380*/rotationArray: this.state.rotationArray !== '' && files && files[0] ? '':this.state.rotationArray, }, () => {
                        const shapeselector = document.getElementById('shapeselector')
                        if (shapeselector !== undefined && shapeselector !== null && this.state.bAnnotate && !this.state.bRemoteCapture) {
                            shapeselector.style.display = "none";
                        }
                    });//NS2-457
                } else {
                    this.props.updateFileShareMaxdiv(true);
                    this.setState({ bShareAsset: true, fileData: null, isOngoingMaxDivFeatures: true, 
                        /*TP-1380*/rotationArray: this.state.rotationArray !== '' && files && files[0] ? '':this.state.rotationArray, }, () => {
                        const shapeselector = document.getElementById('shapeselector')
                        if (shapeselector !== undefined && shapeselector !== null && this.state.bAnnotate && !this.state.bRemoteCapture) {
                            shapeselector.style.display = "none";
                        }
                    });
                } 
                const fileArr = Object.values(files);
                // const fileSizeExceed = fileArr.filter(e => (e.size / 1048576) > 40 );
                // if (fileSizeExceed.length) {
                //     this.setState({ error: 'File size should be below 40 MB' });
                //     this.props.updateFileShare(false);
                //     return;
                // }
                this.setState({ isLoading: true });
                if (bCancelShare === false) {
                    let i = 0;
                    for (const file of fileArr) {
                        // convert the file to Base64 format and send each
                        //printConsole(file);
                        let reader = new FileReader();
                        reader.onloadend = function() {
                            i++;
                            var srcData = arrayBufferToBase64(reader.result);
                            if (otExpObj.state.startRecording === true && turn_off_frames_signal === true && isSilentRecording === false && !otExpObj.props.isSafari && !otExpObj.props.isFirefox && otExpObj.state.hideButton !== 'd-none') { //TP-2262
                                otExpObj.handleResumeRecordingAfterPause();
                            }
                            //console.log("Base 64 image url: ", srcData);
                            if(!srcData) {
                                printConsole("Image isn't available for sharing");
                                return;
                            } 
                            const splitedArray = file.name.split('.');
                            const format = splitedArray[splitedArray.length - 1].toLowerCase();
                            let name = splitedArray[0];
                            if (splitedArray.length > 2) {
                                splitedArray.pop();
                                name = splitedArray.join(".");
                            }   
                            //console.log(name);
                            const fileData = {
                                data: srcData,
                                format,
                                type: (format === "mtl") ? "application/octet-stream" : file.type,
                                sequence: i,
                                total: fileArr.length,
                                name
                            }
                            //filearr.push(fileData);
                            //console.log("fileData================>",fileData);
                            otExpObj.setState({ fileData });
                            otExpObj.sendAttachmentNew(fileData, paramElem);
                        }
                        //reader.readAsDataURL(localAssets[i]);
                        reader.readAsArrayBuffer(file);                    
                    }
                    this.setState({ fileArr, assetsArray: fileArr });
                    this.handleArtifactsOnPrimaryExpert(fileArr);
                } else {
                    bCancelShare = false;
                }
                //if(this.state.bShareAsset === true) this.setState({bShareAsset: false});//NS2-457
            })
            .catch( error => {
                printConsole(error);
                if (this.state.startRecording === true && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                    this.handleResumeRecordingAfterPause();
                }
            })
    }

    //TP-2838
    turnOffPointerAnnotation = (paramElem) => {
        if (paramElem === null || paramElem === undefined) {
            printConsole('invalid element passed to turnOffPointerAnnotation');
            return;
        }
        
        let drawElem = document.getElementById('canvas');
        if (drawElem === null) {
            printConsole("Canvas element is null.")
            return;
        }
    
        let canvas = drawElem.fabric;
        canvas.selection = false;
        if (canvas === null) {
            printConsole('Fabric canvas could not be created!!!');
            return;
        }
        canvas.off("mouse:over");
        canvas.off("mouse:out");
        stopAnnotationTimer(); //TP-2474        
        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
            canvas.enableRetinaScaling = true;
                    
            if (g_maxDivId !== null) {
                // Setting the expert X & Y (Height & Width) values into the state
                this.setState((prevState) =>({
                    expertDimX: canvas.width,
                    expertDimY: canvas.height
                })
                );
                let strDim = canvas.width+"px";
                strDim += canvas.height+"px";
                //console.log(strDim);
                //console.log(typeof(strDim));
                //otExpObj.sendAnnotationToExpert(strDim);//NS2-345
                //otExpObj.sendAnnotation(strDim, paramElem);
                //console.log(strDim);
            }
            pointerX = "-1";
            pointerY = "-1";
            const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::" + otExpObj.state.pencolor;
            this.sendAnnotationToExpert(string); //NS2-345
            this.sendAnnotation(string, paramElem);

            //strCanvasJSON = canvas.toJSON();
            let json = canvas.toJSON();
            if (json.backgroundImage !== undefined) delete json.backgroundImage;
            //otExpObj.sendAnnotationToExpert(json); //NS2-345
            //otExpObj.sendAnnotation(json, paramElem);
            canvas.off("mouse:move");
        }
    }

    // The Expert screen share is turned on/off for MaxDiv
    // called on Expert Webapp only
    expertScreenShareClick = (sendArtifacts=true) => {
        // NS2-117
        if (this.state.expertSSAll) {
            printConsole(" Expert Screen share all is ongoing. Please close that before trying to do expert screen share on Maxdiv. ");
            return;
        } else if (this.state.techShareScreen) {
            printConsole(" Technician screen share is going on. Please close that before trying to do expert screen share. ")
            return;
        }

        const {bZoomClick, bTorchClick, zoom_level} = this.state;
        //TP-2861 -- Handling closing Zoom & Torch features when Screen cast (Max view controls)
        // is triggered by Primary expert
        if (bZoomClick && parseInt(zoom_level) > 1) this.onClickZoomLevel('1');
        if (bTorchClick) this.onClickTorchIcon();

        const {g_subInfoArr, g_session} = this.state;
    	let nLen = g_subInfoArr.length;
        let ii = 0;
        for(ii = 0; ii < nLen; ii++)
        {
            if( g_subInfoArr[ii].g_divId === g_maxDivId  && g_publisher !== null /* && g_subInfoArr[ii].disableVideo === false */)
            {
                let maxDivConObj = g_subInfoArr[ii].g_stream.connection;
                this.setState({expertShareScreenMaxDiv: !this.state.expertShareScreenMaxDiv},() => {
                    // Turning on/off the expert video publishing 
		    		if (this.state.expertShareScreenMaxDiv) {
                        this.setState({isOngoingMaxDivFeatures: this.state.expertShareScreenMaxDiv}); //TP-1370
                        if (this.state.bShareAsset) {
                            //if (this.state.shareOnDiv === null) {
                                //Send signal to all users to remove the shared artifacts
                                /* g_subInfoArr.forEach(subInfo => {
                                    const toArray = subInfo.g_stream.connection.data.split(":::");
                                    const toObject = toArray[3];
                                        this.sessionSignal.sendSignalRemoveArtifacts(
                                            true,
                                            toObject
                                            //type: "REMOVE_ARTIFACTS"
                                        );
                                    //printConsole("remove artifacts signal sent...");
                                }) */
                            //} else {
                                //Send signal to only Max-div user to remove the shared artifacts
                                const toArray = maxDivConObj.data.split(":::");
                                const toObject = toArray[3];
                                this.sessionSignal.sendSignalRemoveArtifacts(
                                    true,
                                    toObject
                                    //type: "REMOVE_ARTIFACTS"
                                );
                                //Send signal to all experts to remove the shared artifacts
                                g_expertConnsArr.forEach(connectionObj => {
                                    if (g_session.connection !== connectionObj) {
                                        const toArray = connectionObj.data.split(":::");
                                        const toObject = toArray[3];
                                        this.sessionSignal.sendSignalRemoveArtifacts(
                                            true,
                                            toObject
                                            //type: "REMOVE_ARTIFACTS"
                
                                        );
                                    }
                                });
                            //}
                        }
                        if (this.state.bZoomClick === true)
                            document.getElementById('zoomlevelselector').style.display = 'none';

                        if (this.state.bAnnotate === true) {
                            //Clearing the old annotation drawings
                            //this.sendAnnotationToExpert('');//NS2-345
                            //this.sendAnnotation('', null);
                            //this.clearAnnotations(); commented this code out for TP-1365
                        }
                        // NS2-117
                        this.props.updateScreenShareMaxdiv(true);
	           	       	this.props.updateRCStatus(true);
	                	// change the video source to be screen
                        this.rePublishExpertStream(true, maxDivConObj, false, sendArtifacts);                         
  		    		} else {                        
	           	       	this.props.updateRCStatus(true);
                        if (this.state.bAnnotate === true) {
                            this.onClickAnnotate();
                        }
                        if (this.state.bZoomClick === true)
                            document.getElementById('zoomlevelselector').style.display = 'block';
                        // change the video source to be screen
                        this.rePublishExpertStream(false, maxDivConObj, false, sendArtifacts);
                        // NS2 - 117
                        this.props.updateScreenShareMaxdiv(false);
                        //TP-1370 -- handling of the Grid View icon when screen cast is closed while
                        // File share ongoing
                        if (!this.state.bShareAsset)
                            this.setState({isOngoingMaxDivFeatures: this.state.expertShareScreenMaxDiv})
		    		}
                });
            }
        }
    }

    // function triggered when Torch Icon is clicked
    // Only on the Expert side
    onClickTorchIcon = () => {
        const { bTorchClick } = this.state;
        let { g_subInfoArr } = this.state;
        const nLen = g_subInfoArr.length;
        let expertDivConnObj = '';
        let data = "";
        for (let ii = 0; ii < nLen; ii++) {
            if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
                g_subInfoArr[ii].torchActive = !bTorchClick;
            }
        }
        this.setState({ g_subInfoArr });
        if (bTorchClick === true && expertDivConnObj !== '') {
            this.setState({ bTorchClick: false, /* isOngoingMaxDivFeatures: false */ }, () => {
                data = false;
                // T32-495 -- Syncing all the Passive expert's torch state
                g_subInfoArr.forEach(subInfo => {
                    if (g_maxDivId === subInfo.g_divId){
                        const dataArr = subInfo.g_stream.connection.data.split(":::");
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                            zoom_level: subInfo.zoomLevel,
                            torch_button_active: data,
                            annotation_active: this.state.bAnnotate,
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"},  
                        null); 
                    }
                });
                this.sendSignalTechnicianTorch(data, expertDivConnObj);
            })
        } else if (expertDivConnObj !== '') {
            this.setState({ bTorchClick: true/* , isOngoingMaxDivFeatures: true */ }, () => {
                data = true;
                // T32-495 -- Syncing all the Passive expert's torch state
                g_subInfoArr.forEach(subInfo => {
                    if (g_maxDivId === subInfo.g_divId){
                        const dataArr = subInfo.g_stream.connection.data.split(":::");
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                            zoom_level: subInfo.zoomLevel,
                            torch_button_active: data,
                            annotation_active: this.state.bAnnotate,
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"},  
                        null);
                    }
                });
                this.sendSignalTechnicianTorch(data, expertDivConnObj);
            })
        } else {
            printConsole("Exception occured: The torch icon was clicked for video stream that isn't on the Maxdiv")
        }
    }

    onClickZoomIcon = () => {
        const { bZoomClick } = this.state;
        if (bZoomClick === false) {
            const zoomSelector = document.getElementById('zoomlevelselector');
            this.setState({ bZoomClick: !bZoomClick/* , isOngoingMaxDivFeatures: !bZoomClick */ }, () => {
                if (this.state.bZoomClick && !this.state.bRemoteCapture) {
                    zoomSelector.style.display = 'block';
                } else {
                    zoomSelector.style.display = 'none';
                }
            })
        }
    }

    onClickZoomLevel = (val) => {
        let {g_subInfoArr} = this.state;
        this.setState({ zoom_level: val }, () => {
            if(parseInt(this.state.zoom_level) === 1) {
                const zoomSelector = document.getElementById('zoomlevelselector');
                if (zoomSelector !== undefined && zoomSelector !== null) {
                    this.setState({bZoomClick : false});
                    zoomSelector.style.display = 'none';
                }
            }
            const nLen = g_subInfoArr.length;
            for (let ii = 0; ii < nLen; ii++) {
                if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                    const expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
                    g_subInfoArr[ii].zoomLevel = parseInt(this.state.zoom_level);
                    this.sendSignalRemoteZoomLevel(parseInt(this.state.zoom_level), expertDivConnObj);
                }
            }
            this.setState({ g_subInfoArr });
            g_subInfoArr.forEach(subInfo => {
                if (g_maxDivId === subInfo.g_divId){
                    const dataArr = subInfo.g_stream.connection.data.split(":::");
                    this.sendSignalSyncExperts({
                        show_grid: false,
                        max_view_technician: dataArr[3], 
                        zoom_button_active: parseInt(val) > 1 ? true : false,
                        zoom_level: parseInt(val),
                        torch_button_active: this.state.bTorchClick,
                        hand_icon_enabled: true,
                        header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                        reason: "show_action"},  
                    null);
                }
            });
        });
    }

    // The Technician Screen Share is turned on/off on the expert's maxdiv 
    // called on expert webapp ONLY
    onTechScreenShareClick = () => {
        // these below conditions are both for NS2-117
        if (this.state.expertSSAll) {
            printConsole(" Expert Screen share all is ongoing. Please close that before trying to do expert screen share on Maxdiv. ");
            return;
        } else if (this.state.expertShareScreenMaxDiv) {
            printConsole(" Expert screen share is going on. Please close that before trying to do technician screen share. ")
            return;
        } else if (this.state.bAnnotate && !this.state.bRemoteCapture) {
            if (this.state.techShareScreen) {
                this.onClickAnnotate();
            }
        }
        const { g_subInfoArr } = this.state;
        let nLen = g_subInfoArr.length;
        let ii = 0;
        for (ii = 0; ii < nLen; ii++) {
            if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                let maxDivConObj = g_subInfoArr[ii].g_stream.connection;
                this.setState({ techShareScreen: !this.state.techShareScreen, isOngoingMaxDivFeatures: !this.state.techShareScreen }, () => {
                    // for NS2-117
                    this.props.updateScreenShareMaxdiv(this.state.techShareScreen);
                    // for NS2-126
                    this.props.updateRCStatus(true);
                    this.sendSignalTechnicianScreenShare(this.state.techShareScreen, maxDivConObj);
                });
            }
        }
    }

    onClickMaxMute = () => {
        let {g_subInfoArr} = this.state;
        this.setState({bRemoteMute: (this.state.bRemoteMute === true)? false:true},() =>{

            // mute max user
            let nLen = g_subInfoArr.length;
            let ii = 0;
            for(ii = 0; ii < nLen; ii++)
            {
                if( g_subInfoArr[ii].g_divId === g_maxDivId )
                {
                    const muteBtndiv = document.getElementById('toggleAudio_'+g_subInfoArr[ii].g_stream.streamId)
                    if(this.state.bRemoteMute) {
                        g_subInfoArr[ii].g_subscriber.subscribeToAudio(false); //audio off
                        g_subInfoArr[ii].disableAudio = true;                        
                        //change the mute Speaker icons on top of video element                            
                        if (muteBtndiv !== undefined && muteBtndiv !== null) {
                            let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                            muteBtn[0].classList.remove("fa-volume-up");
                            muteBtn[0].classList.add("fa-volume-mute");
                            muteBtn[0].style.color = "red"
                        }                        
                    }
                    else {
                        g_subInfoArr[ii].g_subscriber.subscribeToAudio(true); // audio on
                        g_subInfoArr[ii].disableAudio = false;
                        //change the mute Speaker icons on top of video element                            
                        if (muteBtndiv !== undefined && muteBtndiv !== null) {
                            let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                            muteBtn[0].classList.add("fa-volume-up");
                            muteBtn[0].classList.remove("fa-volume-mute");
                            muteBtn[0].style.color = "black"
                        }                         
                    }

                    //set the changed session Joinee in the array
                    this.updateEachSessionJoinee(g_subInfoArr[ii]);
                }
            }
        });
        this.setState({g_subInfoArr: g_subInfoArr}, ()=> {
            this.setState({isParticipantChanged : true}, () => {
                this.setState({isParticipantChanged : false});
            }); // to set the Side Bar Silhouette Icon states
        });
    }

    // Find the the maxDiv tech to send the mute/unmute expert mike signal
    onClickMaxMuteMicrophone = () => {
        const {g_subInfoArr} = this.state;
        //TP-1918 -- To get the previous unMuted Expert Mike count
        let isExpertAudioUnMutedCounterPrev = 0;
        g_subInfoArr.forEach(subInfo => {
            if (!subInfo.disableMike) isExpertAudioUnMutedCounterPrev += 1;
        }) 
        this.setState({muteMikeForMaxDiv: !this.state.muteMikeForMaxDiv},() => {
            let nLen = g_subInfoArr.length;
            let ii = 0;
            for(ii = 0; ii < nLen; ii++) 
            {
                if( g_subInfoArr[ii].g_divId === g_maxDivId ) 
                {
                    g_subInfoArr[ii].disableMike = this.state.muteMikeForMaxDiv;
                    const muteBtndiv = document.getElementById('muteMikeEach_'+g_subInfoArr[ii].g_stream.streamId)
                    
                    //set the changed session Joinee in the array
                    this.updateEachSessionJoinee(g_subInfoArr[ii]);

                    if (g_subInfoArr[ii].disableMike === true){
                        //change the mute Mike icons on top of video element                            
                        if (muteBtndiv !== undefined && muteBtndiv !== null) {
                            let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                            muteBtn[0].classList.remove("fa-microphone");
                            muteBtn[0].classList.add("fa-microphone-slash");
                            muteBtn[0].style.color = "red"
                        }
                    } else {
                        //change the mute Mike icons on top of video element                            
                        if (muteBtndiv !== undefined && muteBtndiv !== null) {
                            let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                            muteBtn[0].classList.add("fa-microphone");
                            muteBtn[0].classList.remove("fa-microphone-slash");
                            muteBtn[0].style.color = "black"
                        }
                    }
                    let maxDivConObj = g_subInfoArr[ii].g_stream.connection;                    
                    this.muteExpertMikeForMaxDiv(!this.state.muteMikeForMaxDiv, maxDivConObj);
                }
            }
        });

        // TP-1918 -- To get the latest unMuted Expert Mike count
        let isExpertAudioUnMutedCounterLatest = 0;
        g_subInfoArr.forEach(subInfo => {
            if (!subInfo.disableMike) isExpertAudioUnMutedCounterLatest += 1;
        })
        let recordingFlag = false;
        printConsole(`Prev unmuted Mike counter: ${isExpertAudioUnMutedCounterPrev} current unMuted mike counter: ${isExpertAudioUnMutedCounterLatest}`);
        if (this.state.startRecording === true && isExpertAudioUnMutedCounterPrev !== isExpertAudioUnMutedCounterLatest && ((isExpertAudioUnMutedCounterPrev === 0 && isExpertAudioUnMutedCounterLatest > 0) || (isExpertAudioUnMutedCounterPrev > 0 && isExpertAudioUnMutedCounterLatest === 0))) {
            printConsole("The Expert's individual Mikes count changed while recording is ongoing")
            recordingFlag = true;                
        }

        this.setState({ g_subInfoArr }, () => {
            // TP-1918
            if (recordingFlag === true) {
                printConsole("Stop and re-start a new recording due to Mute/un-Mute of Max-div control Mic icon");
                this.stopAndContinueRecording(); //TP-1881 stop the old recording and start a new recording when Side Bar Mute Mike is clicked
            }
            this.setState({isParticipantChanged : true}, () => {
                this.setState({isParticipantChanged : false});
            });// To set the Side Bar Silhouette Icon's state
        });
    }

    // Take screen shot of the canvas of remote capture NS2-367
    onClickScreenShot = () => {
        this.setState({screenCaptureClicked: true}, () => {
            const canvas = document.getElementById('canvas');
            if(canvas){
                const dataURL = canvas.toDataURL('image/jpeg', 1.0);
                const fileName = `Telepresenz_RC_${this.getDateString()}`;
                const element = document.createElement('a');
                element.setAttribute('href', dataURL);
                element.setAttribute('download', fileName);
              
                element.style.display = 'none';
                document.body.appendChild(element);
              
                element.click();
              
                document.body.removeChild(element);
    
                this.setState({screenCaptureClicked: false})
            }
        })
    }

    // start recording NS2-274
    onClickRecordScreen = (state="start") => {
        this.setState((prevState) => ({
            startRecording: !prevState.startRecording
        }),async () =>{
            if(this.state.startRecording){                
                printConsole(`Start recording clicked: ${performance.now()}`);
                if (document.getElementById('record-beacon') !== null && document.getElementById(g_maxDivId) !== null && document.getElementById('record-beacon') !== undefined){
                    document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('record-beacon'));
                }
                await this.startRecordingNew(state); //TP-740
                //this.startScreenshotRecording();
            }else{
                printConsole(`Stop recording clicked: ${performance.now()}`);
                if (document.getElementById('record-beacon') !== null && document.getElementById('record-beacon') !== undefined){
                    document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('record-beacon'));
                }
                this.stopRecordingCallback();
                //this.stopScreenshotRecordingCb();
            }
        })
    }
    // Onclick functions end
    
    /** Draw UI layout methods begin */
    updateDimensions = () => {
        //console.log('updateDimensions called with ', window.innerHeight, window.innerWidth);
        this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
    }
    
    recalcLayout = (heightDim, widthDim, bExtra, bFlag = false) => {
        if (this.state.gridViewMode === true) {
            this.drawSubscriberUI(heightDim, widthDim, bExtra, bFlag);
            if (this.state.bShareAsset && this.state.showFileViewer) {
                this.setState({ showFileViewer: false }, () => {
                    this.setState({ showFileViewer: true });
                })
            } else if (this.state.bShareAsset) {
                if (this.state.showHideMaxDivControl) {
                    this.handleArtifactsOnPrimaryExpert(this.state.fileArr);
                    //TP-2085 -- Redrawing with the rotations in Primary expert's screen, when resized
                    if (this.state.rotationArray !== '' && this.state.showHideMaxDivControl === true) {
                        const { _x, _y, _z, _w } = JSON.parse(this.state.rotationArray).quaternion
                        const eulerRotation = new THREE.Quaternion().copy({ x: _x, y: _y, z: _z, w: _w });
                        this.active3dModel && this.active3dModel.quaternion.copy(eulerRotation);
                    }
                } else {
                    const paramElem = document.getElementById(g_maxDivId);
                    const isExpertDiv = isDivExpert(g_maxDivId);
                    const flag = false;
                    this.handleArtifactsNew({ assetsArray: this.state.assetsArray, paramElem, isExpertDiv, flag });
                }
            }
        }
        else {
            //this.drawSubscriberUI(heightDim, widthDim, bExtra, bFlag);
            let maxDiv = g_maxDivId;
            g_maxDivId = null; 
            this.maximizeDiv(null, {divId: maxDiv}, true);
            if (this.state.bShareAsset && this.state.showFileViewer) {
                this.setState({ showFileViewer: false }, () => {
                    this.setState({ showFileViewer: true });
                })
            } else if (this.state.bShareAsset) {
                if (this.state.showHideMaxDivControl) {
                    this.handleArtifactsOnPrimaryExpert(this.state.fileArr);
                    //TP-2085 -- Redrawing with the rotations in Primary expert's screen, when resized
                    if (this.state.rotationArray !== '' && this.state.showHideMaxDivControl === true) {
                        const { _x, _y, _z, _w } = JSON.parse(this.state.rotationArray).quaternion
                        const eulerRotation = new THREE.Quaternion().copy({ x: _x, y: _y, z: _z, w: _w });
                        this.active3dModel && this.active3dModel.quaternion.copy(eulerRotation);
                    }
                } else {
                    const paramElem = document.getElementById(g_maxDivId);
                    const isExpertDiv = isDivExpert(g_maxDivId);
                    const flag = false;
                    this.handleArtifactsNew({ assetsArray: this.state.assetsArray, paramElem, isExpertDiv, flag });
                }
            } else if (this.state.bAnnotate && !this.state.bRemoteCapture) {
                //TP-2288 -- The annotation canvas needs to be resized when the Window is resized for Primary expert
                if (this.state.showHideMaxDivControl) {
                    if (this.state.isFreeDrawing === true) {
                        //Freehand drawing
                        onClickDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, null, true);
                    }
                    else {
                        //For shapes
                        onClickDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, null, true);
                        onClickChangeDraw(document.getElementById(g_maxDivId), this.state.bRemoteCapture, this.state.urlObj);
                    }
                } else {
                    //for Passive experts
                    onDrawPassiveExperts(document.getElementById(g_maxDivId), this.state.bRemoteCapture, null, '');
                }
            }
        }
        //TP-87 -- Handling of resize of the chat window
        if (this.state.bAllChat || this.state.bMaxChat) {
            this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
        }
    }

    drawSubscriberUI = (heightVal, widthVal, maxElem, flg = false)=> {
        printConsole("Redrawing the expert's Grid View");
       // this.setState({gridViewMode: true});
        // if drawing on a phone, special rendering
        const {g_subInfoArr} = this.state;
        /* if (widthVal <= 1024) {
             this.drawSmallSubscriberUI(heightVal, widthVal, maxElem, flg);
             return;
        } */
        
        let Height = heightVal - 1; //window.innerHeight;
        let Width = widthVal - 1; //window.innerWidth;
        let gapX = 0; // default
        let gapY = 0;
        let nGaps = 3;

        // Height -= (headerSize + footerSize);

        // Set the size of the container
        let subscriberBox = document.getElementById(subscriberContainerDivId); //flex-container
        if (subscriberBox === null) {
            printConsole('no div layout for videos yet!');
            return;
        }
        // subscriberBox.style.position = "relative";
         subscriberBox.style.width = Width + "px";
         subscriberBox.style.height = Height + "px";
        subscriberBox.style.backgroundColor = '#1c2238';

        //printConsole(`subscriber box height::width ${subscriberBox.style.height} ${subscriberBox.style.width}`)

        // the big div
        let maxHeight = 0;
        let maxWidth = 0;

        // if(document.getElementById('maxdivcontrols') !== null){
        // 	document.getElementById('maxdivcontrols').style.display = 'block';
        // }

        if ((widthVal <= 1200 || this.state.hideButton === "d-none") && (this.state.bMaxChat === true || this.state.bAllChat === true || g_subInfoArr.length > 1)) {
            maxHeight = Math.floor((Height - (Height*2 * (borderYsize/100))) * 2 / 3);
            maxWidth = Math.floor((maxHeight * 16) / 9);
            gapY = (Height - maxHeight) / 2;//borderYsize;
        }
        else {
            maxHeight = Math.floor(Height - (Height*(borderYsize/100)));
            maxWidth = Math.floor((maxHeight * 16) / 9);
            gapY = borderYsize;
        }

        let count = g_subInfoArr.length;
        // the small divs
        let smallWidth = Math.floor(maxWidth / 3);
        //let smallHeight = Math.floor((smallWidth * 9) / 16);

        // the gaps
        if (count > 3) nGaps += 1; // for the scrollbar
        gapX = (Width - maxWidth - smallWidth) / nGaps;
        gapX = Math.floor(gapX);

        smallWidth = Math.floor(smallWidth);
        //smallHeight = Math.floor(smallHeight);
        gapY = Math.floor(gapY);

        //console.log('Inside drawSubscriberUI: Width, maxWidth, smallWidth, gapX = ', Width, maxWidth, smallWidth, gapX);

        this.setState({ chatLeft: (maxWidth + (2 * gapX)) + 'px' });
        this.setState({ chatTop: (gapY) + 'px' });
        this.setState({ chatWidth: (smallWidth + (1 * gapX)) + 'px' });
        this.setState({ chatHeight: (maxHeight) + 'px' });

        // Loop through each subscribed stream and place it inside container
        //let x = 0;
        //let y = 0;

        let userCount = 0; let expertsCount = 0;
        if (this.state.g_subInfoArr.length < 1) return;
        this.state.g_subInfoArr.forEach( subInfo => {
            if (subInfo && subInfo.g_stream.hasVideo && subInfo.disableVideo === false && subInfo.g_stream.videoType !== 'screen') {
                userCount ++;
            }
        })
        //console.log("No of Technicians: ", g_subInfoArr.length - g_expertConnsArr.length);
        //console.log("Video User Count: ", userCount);

        this.removeElementByClass({class_name: 'div-seperator'});// always remove the existing seprator class to redrawn later
        //	console.log('xx, yy, maxWidth, maxHeight, x, y, smallWidth, smallHeight', 
        //		gapX, gapY, maxWidth, maxHeight, x, y, smallWidth, smallHeight);
        const [ newVdoheight, newVdowidth, numRows, numCells ] = this.getGridViewDim(userCount);// dimension of the grid
        const [ newVdowidthLastRow, lastRowNumCells ] = this.getLastRowViewDim(userCount, numRows, numCells); // TP-2973 -- dimension of the last row's grid
        let ii = 0;
        for (ii = 0; ii < count; ii++) {
            let parent = document.getElementById(g_subInfoArr[ii].g_divId);
            let nameDiv = document.getElementById(g_subInfoArr[ii].g_divId+'_name');
            let dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::"); //TP-300
            if (parent === null) return;
            // if max divid draw the max window
            // if (g_subInfoArr.length === 1 || g_subInfoArr[ii].g_divId === g_maxDivId) {
            // will be executed each time  there is one particiapant
            if (g_subInfoArr.length === 1 || g_subInfoArr.length - g_expertConnsArr.length === 1 ||(userCount === 1 /* && !this.state.showHideMaxDivControl */)) {
                printConsole("Only one user left in session");
                if (userCount > 1)
                    this.hideGridViewIcon(false);
                else 
                    this.hideGridViewIcon(true);
                this.setState({ gridViewMode: false, g_maxDivId: g_subInfoArr[ii].g_divId, userVideoCount: userCount }, () => {
                    if (g_subInfoArr[ii] && g_subInfoArr[ii].disableVideo === false && g_subInfoArr[ii].g_stream.hasVideo === true) {
                        //let xx = gapX;
                        //let yy = gapY;
                        //let maxDivCtrlsOffset = '64%';//30%
                        //if (maxWidth <= 450) maxDivCtrlsOffset = '15%';
                        //else if (maxWidth <= 900 && maxWidth > 450) maxDivCtrlsOffset = '64%';//30%
                        if (this.state.bMaxChat === false && this.state.bAllChat === false) {
                            //xx = Math.floor((Width - maxWidth) / 2);
                            /* if (document.getElementById('small-divs-container') !== null)
                                document.getElementById('small-divs-container').style.display = 'none';
                             *///maxDivCtrlsOffset = '40%';
                        }                
                        parent.style.position = 'absolute';
                        //parent.style.left = xx + 'px';
                         //parent.style.top = yy + 'px';
                        parent.style.width = maxWidth + 'px';
                        parent.style.display= 'block';// When max div participant leaves other takes his place and need to change style from hidden to display
                        parent.style.height = maxHeight + 'px';
                        parent.style.borderRadius = '5%';
                        parent.className = "text-center";// change all the previous classes and assign new
                        //parent.classList.add('');
                        if (g_maxDivId !== null) {
                            //let maxDivParent = document.getElementById(g_maxDivId);                    
                        } else {
                            g_maxDivId = g_subInfoArr[ii].g_divId;
                        }
                    }
                    else {
                        parent.style.display = 'none'// Hiding all participants with Video turned off
                    }
                    if (g_subInfoArr[ii]) {
                        //hide the disconnect user button on top of the video feed elements
                        let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                        if (disconnectuser !== undefined && disconnectuser !== null) {
                            disconnectuser.style.display = "none";                    
                        }
        
                        //hide the gridcontrol icons on top of video element
                        let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                        if (gridcontrol !== undefined && gridcontrol !== null)
                            gridcontrol.style.display = "none";
                        
                        //hide the zoom levels text on top of Video element
                        let zoomLevel = document.getElementById('zoomLevel_'+g_subInfoArr[ii].g_stream.streamId)
                        if (zoomLevel !== undefined && zoomLevel !== null)
                            zoomLevel.parentElement.style.display = "none";
                    }
                });

            }
            else // now draw the other sub videos if they exist
            {
                printConsole("More than one Video enabled user in session");
                this.hideGridViewIcon(true);
                g_maxDivId = null;
                this.setState({ gridViewMode: true, g_maxDivId });
                // All streams placed in position "" to the layout container                
                parent.className = "";// change all the previous classes and assign new                
                parent.style.height = newVdoheight + 'px';// fix the height. Also, The video that is max div has a height, override it.
                //TP-3014
                console.log(`${dataArr[3]} Has Video Tracks in its stream ${g_subInfoArr[ii].g_stream.hasVideo === true && g_subInfoArr[ii].disableVideo === false}`)
                /**TP-2973 */
                let flag = false;
                console.log(lastRowNumCells)
                if (newVdowidthLastRow !== 0 && lastRowNumCells > 0 && g_subInfoArr[ii].g_stream.hasVideo === true && g_subInfoArr[ii].disableVideo === false) {
                    let newIndex = ii;
                    if (expertsCount > 0 && ii > 0) {
                        newIndex = ii - expertsCount;
                    }
                    console.log(newIndex);
                    console.log("total count....?",userCount);
                    if(newIndex >= userCount - lastRowNumCells) flag = true;                        
                    /* if(ii >= userCount - lastRowNumCells) flag = true; */
                        
                } else if (g_subInfoArr[ii].g_stream.hasVideo === false || g_subInfoArr[ii].disableVideo === true) {
                    expertsCount ++;
                }
                    console.log("flag for resizing the tile??",flag);
                parent.style.width = (flag === true) ? newVdowidthLastRow + 'px' : newVdowidth + 'px'; //fix the width of the video in the last row 
                parent.style.position = 'relative'; // fix the position if video that was in max div has a position, override it.
                //printConsole(`Video tile height::width ${parent.style.height} ${parent.style.width}`)
                //parent.className = `col-${columnWidth}`;
                parent.classList.add('text-center', 'grid-card', 'paddingVdo'); // Setting the nameDiv element centered
                parent.style.display = (dataArr[4] === "false" && g_subInfoArr[ii].g_stream.hasVideo === true && g_subInfoArr[ii].disableVideo === false) ? 'block' : 'none'; //TP-300                
                //printConsole(`Video tile display:: ${parent.style.display}`);
                nameDiv.style.position = 'absolute';
                nameDiv.style.left = '0';

                //show the gridcontrol icons on top of video element
                let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)                
                if (gridcontrol !== undefined && gridcontrol !== null){
                    // Resizing the Grid Controls based on screen Scale layout & resolution
                    let c = gridcontrol.getElementsByClassName('grid-view-controls')
                    for (let i = 0; i < c.length; i++) {
                        printConsole("Updating Grid View Icon's size");
                        if (window.innerWidth > 1299) {
                            c[i].classList.remove('fa-xs');
                            c[i].classList.add('fa-sm');
                        }
                        else {
                            c[i].classList.remove('fa-sm');
                            c[i].classList.add('fa-xs');
                        }
                        
                    }
                    gridcontrol.style.display = "block";

                    if(g_subInfoArr[ii].zoom_enabled) {
                        let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                        
                        if (zoomIcon !== undefined && zoomIcon !== null) {
                            zoomIcon.style.display = "block";
                            let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");
                            if (this.state.showHideMaxDivControl) {
                                // enable the zoom Icon for Primary expert
                                zoomBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                zoomIcon.onClick = function (e) {
                                    this.rotateZoomLevel(g_subInfoArr[ii].g_divId);
                                }
                            }   
                            else {
                                // disable the zoom Icon for Passive expert (Indicator only)
                                zoomBtn[0].previousSibling.classList.add("font-awesome-grey");
                                //zoomIcon.onClick = () => {};
                            }
                        }

                        if (g_subInfoArr[ii].zoomLevel === 1){
                            //change the zoom icons on top of video element                            
                            if (zoomIcon !== undefined && zoomIcon !== null) {   
                                let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");                             
                                //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                // Add the new changed Zoom level Icon
                                /* let zoomSymbol = document.createElement('i');
                                zoomSymbol.classList.add('fas', 'fa-binoculars', 'fa-stack-1x', 'grid-icon');
                                zoomIcon.appendChild(zoomSymbol); */
                                zoomBtn[0].style.color = "black";

                                // Change the zoom level text on top of Video element
                                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                zoomLevel.className = 'zoom-text';
                                zoomLevel.classList.add(zoomClassArray[g_subInfoArr[ii].zoomLevel-1].class);
                                zoomLevel.innerHTML = processZoomLevels(g_subInfoArr[ii].zoomLevel + "X", this.props.i18n);
                                zoomLevel.parentElement.style.display = "none"; // Hide the zoom level if 1X
                            }
                        } else {
                            //change the zoom icons on top of video element                            
                            if (zoomIcon !== undefined && zoomIcon !== null) {     
                                let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");                           
                                //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                // Add the new changed Zoom level Icon
                                /* let zoomSymbol = document.createElement('span');
                                zoomSymbol.classList.add('fa-stack-1x', 'fa-zoom-text', 'grid-icon');
                                zoomSymbol.innerHTML = processZoomLevels(g_subInfoArr[ii].zoomLevel + "X", this.props.i18n);
                                zoomIcon.appendChild(zoomSymbol); */
                                zoomBtn[0].style.color = "red";

                                // Change the zoom level text on top of Video element
                                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                zoomLevel.className = 'zoom-text';
                                zoomLevel.classList.add(zoomClassArray[g_subInfoArr[ii].zoomLevel-1].class);
                                zoomLevel.innerHTML = processZoomLevels(g_subInfoArr[ii].zoomLevel + "X", this.props.i18n);
                                zoomLevel.parentElement.style.display = "block";
                            }
                        }
                        
                    } else {
                        let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                        if (zoomIcon !== undefined && zoomIcon !== null)
                            zoomIcon.style.display = "none";

                        //hide the zoom levels text on top of Video Element
                        let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                        if (zoomLevel !== undefined && zoomLevel !== null)
                            zoomLevel.parentElement.style.display = 'none';
                    }
                    if(g_subInfoArr[ii].torch_enabled) {
                        let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                        if (torchIcon !== undefined && torchIcon !== null) {
                            torchIcon.style.display = "block";
                            let torchBtn = torchIcon.getElementsByClassName("grid-icon");
                            if (this.state.showHideMaxDivControl) {
                                // enable the torch Icon for Primary expert
                                torchBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                torchIcon.onClick = function (e) {
                                    this.toggleUserTorch(g_subInfoArr[ii].g_divId);
                                }
                            }
                            else {  
                                // disable the torch Icon for Passive expert (Indicator only)                         
                                torchBtn[0].previousSibling.classList.add("font-awesome-grey");
                                //torchIcon.onClick = () => {};
                            }
                        }
                        if (g_subInfoArr[ii].torchActive === true){
                            //change the Torch icon on top of video element                            
                            if (torchIcon !== undefined && torchIcon !== null) {   
                                let torchBtn = torchIcon.getElementsByClassName("grid-icon");                             
                                torchBtn[0].style.color = "red"
                            }
                        } else {
                            //change the Torch icon on top of video element                            
                            if (torchIcon !== undefined && torchIcon !== null) {    
                                let torchBtn = torchIcon.getElementsByClassName("grid-icon");                            
                                torchBtn[0].style.color = "black"
                            }
                        }
                    } else {
                        let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                        if (torchIcon !== undefined && torchIcon !== null)
                            torchIcon.style.display = "none";
                    }
                }
                
                /* //show the zoom levels text on top of Video element
                let zoomlevel = document.getElementById('zoomLevelDiv_'+g_subInfoArr[ii].g_stream.streamId)
                if (zoomlevel !== undefined && zoomlevel !== null)
                    zoomlevel.style.display = "block"; */

                // Only if current expert is Primary expert
                if (this.state.showHideMaxDivControl === true) {
                    //show the disconnect user button on top of the video feed elements
                    let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                    if (disconnectuser !== undefined && disconnectuser !== null) {
                        // Resizing the Cross Icon based on screen Scale layout & resolution
                        let disSpan = disconnectuser.firstElementChild;
                        if (window.innerWidth > 1299){
                            disSpan.classList.remove('fa-xs');
                            disSpan.classList.add('fa-sm');                    
                        }
                        else {
                            disSpan.classList.remove('fa-sm');
                            disSpan.classList.add('fa-xs');
                        }
                        disconnectuser.style.display = "block";                    
                    }
    
                    // Show the Video Turn off Icon on Primary expert's Grid View
                    let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);                    
                    if (videoIcon !== undefined && videoIcon !== null) 
                        videoIcon.style.display = "block";
                    
                    // Enable the Primary expert Side bar Buttons during Grid View
                    // TP-1546 -- only turn on the Side Bar Screencast/Fileshare Icons if at least one tech has Video turned on
                    if (this.state.userVideoCount > 0)
                        this.props.showScreenShareHeaderButton(false);
                } else {
                    
                    // Hide the Video Turn off Icon on Passive expert's Grid View
                    let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);                    
                    if (videoIcon !== undefined && videoIcon !== null) 
                        videoIcon.style.display = "none";
                    
                    //document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('actionRequestControls'));
                }
                //TP-2810
                const shapeselector = document.getElementById('shapeselector');  
                if (shapeselector !== null && shapeselector !== undefined) {
                    document.getElementById(subscriberContainerDivId).appendChild(shapeselector);
                }
                // TP-1328 -- Before the Grid View is drawn, the controlpanel needs to be shifted its
                // parent to the Main subscriber Parent element
                const controlPanel = document.getElementById('controlpanel');
                if (controlPanel !== null && controlPanel !== undefined) {
                    document.getElementById(subscriberContainerDivId).appendChild(controlPanel);
                }
            }
        }
    }

    drawChatWindow = (heightVal, widthVal, maxElem) => {
        const {g_subInfoArr} = this.state;
                
        let Height = heightVal - 1; //window.innerHeight;
        let Width = widthVal - 1; //window.innerWidth;
        let gapX = 0; // default
        let gapY = 0;
        let nGaps = 3;

        // Set the size of the container
        let subscriberBox = document.getElementById(subscriberContainerDivId); //flex-container
        if (subscriberBox === null) {
            printConsole('no div layout for videos yet!');
            return;
        }
        
         subscriberBox.style.width = Width + "px";
         subscriberBox.style.height = Height + "px";
        subscriberBox.style.backgroundColor = '#1c2238';

        // the big div
        let maxHeight = 0;
        let maxWidth = 0;

        if ((widthVal <= 1200 || this.state.hideButton === "d-none") && g_subInfoArr.length > 1 ) {
            maxHeight = Math.floor((Height - (Height*2 * (borderYsize/100))) * 2 / 3);
            maxWidth = Math.floor((maxHeight * 16) / 9);
            gapY = (Height - maxHeight) / 2;//borderYsize;
        }
        else {
            maxHeight = Math.floor(Height - (Height*2 * (borderYsize/100)));
            maxWidth = Math.floor((maxHeight * 16) / 9);
            gapY = borderYsize;
        }

        let count = g_subInfoArr.length;
        // the small divs
        let smallWidth = Math.floor(maxWidth / 3);
        //let smallHeight = Math.floor((smallWidth * 9) / 16);

        // the gaps
        if (count > 3) nGaps += 1; // for the scrollbar
        gapX = (Width - maxWidth - smallWidth) / nGaps;
        gapX = Math.floor(gapX);
        
        smallWidth = Math.floor(smallWidth);
        //smallHeight = Math.floor(smallHeight);
        gapY = Math.floor(gapY);

        //console.log('Inside drawChatWindow: Width, maxWidth, smallWidth, gapX = ', Width, maxWidth, smallWidth, gapX);

        this.setState({ chatLeft: (maxWidth + (2 * gapX)) + 'px' });
        this.setState({ chatTop: (gapY) + 'px' });
        this.setState({ chatWidth: (smallWidth + (1 * gapX)) + 'px' });
        this.setState({ chatHeight: (maxHeight) + 'px' });
        document.getElementsByClassName("textareaIM")[0].focus(); // MB2-482
    }

    removeElementByClass = ({class_name}) => {
        let elements = document.getElementsByClassName(class_name);
        if(elements.length > 0){
            for (let index = 0; index < elements.length; index++){
                elements[index].remove();
            }
        }
    }
    // back to grid view
    gridView = (e) => {
        const isOngoingMaxDivFeatures = this.isOngoingMaxDivFeaturesExceptZoomAndTorch(); // NS2-375
        this.setState({ isOngoingMaxDivFeatures: isOngoingMaxDivFeatures });
        //console.log(g_subInfoArr.length - g_expertConnsArr.length);
        if (isOngoingMaxDivFeatures === true || g_subInfoArr.length - g_expertConnsArr.length <=1 ) return;
        this.hideGridViewIcon(true);
        this.setState({gridViewMode: true});
        this.resetMaxDivFeatures();
        this.removeElementByClass({class_name: 'div-seperator'});
        if (this.state.showHideMaxDivControl === true && g_expertConnsArr.length >0 /* !(this.state.expertSSAll && g_expertConnsArr.length >0) */) {
            // T32-463 -- New Multi-expert Signal trigger implementation
            this.sendSignalSyncExperts({
                show_grid: true,
                hand_icon_enabled: true,
                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                reason: "show_action"}, 
            null);  // send the "show_grid" value as true for Grid View Mode
        } 
        this.drawSubscriberUI(document.documentElement.clientHeight, window.innerWidth, null);
    }
    // maximize a div
    maximizeDiv = (e, {divId}, flg=false) => {
        printConsole(`maximize user isexpert ? ${flg}`);
        //console.log(divId);
        //console.log(e);
        if (e !== null && e.type === "click" && !this.state.showHideMaxDivControl) return;        
        // set the gridViewMode to false 
        if (flg === true) {
            this.setState({gridViewMode: false});
            g_maxDivId = divId;
        }
        const {g_subInfoArr, showHideMaxDivControl} = this.state;
        // Calculate the no. of users with Video turned on ---
        const list = g_subInfoArr.filter(p => p.disableVideo === false && p.g_stream.hasVideo === true);
        
        if ((g_subInfoArr.length - g_expertConnsArr.length) > 1 && list !== undefined && list.length > 1 && showHideMaxDivControl === true) 
            this.hideGridViewIcon(false);
        else 
            this.hideGridViewIcon(true);
        const sideBarWidth = 80;
        const Height = window.innerHeight - 1;
        const Width = window.innerWidth - 1;
        let maxHeight = 0;
        let maxWidth = 0;
        if (Width <= 1200 || this.state.hideButton === "d-none") {
            //this.hideGridViewIcon(true);
            maxHeight = Math.floor((Height - (Height*2*(borderYsize/100))));
            maxWidth = Math.floor((maxHeight * 16) / 9);  
            if (maxWidth > Width) {
                maxWidth = Math.floor(Width - sideBarWidth);
                maxHeight = Math.floor((maxWidth * 9) / 16);
            }          
        }
        else {
            maxHeight = Math.floor(Height - (Height*(borderYsize/100)));
            maxWidth = Math.floor((maxHeight * 16) / 9);
            if (Math.floor(Width-maxWidth) < 100) {
                maxWidth = Math.floor(Width - sideBarWidth);
                maxHeight = Math.floor((maxWidth * 9) / 16);
            }
        }
        const logoHeight = Math.floor(maxHeight - ((maxHeight * 10)/100));
        //console.log(`Height = ${Height}, maxHeight = ${maxHeight}, Width= ${Width} maxWidth = ${maxWidth}`);
        for (let ii = 0; ii < g_subInfoArr.length; ii++) {
            let parent = document.getElementById(g_subInfoArr[ii].g_divId);
            if (parent === null || parent === undefined) return;

            // if max divid draw the max window
            if (g_subInfoArr[ii].g_divId === divId) {
                let connData = g_subInfoArr[ii].g_stream.connection.data;
                let connArr = connData.split(':::');
                let subscriberBox = document.getElementById(subscriberContainerDivId); //flex-container
                // Set the size of the container
                if (subscriberBox !== null) {
                    subscriberBox.style.width = Width + "px";
                    subscriberBox.style.height = Height + "px";
                }    
                //TP-2810          
                let shapeselector = document.getElementById('shapeselector');  
                let controlPanel = document.getElementById('controlpanel');
                //let actionReqControlDiv = document.getElementById('actionRequestControls');
                let imageElem, divElem;
                if (connArr[4] === 'true') {
                    if (document.getElementById('logoBackground') === null 
                        || document.getElementById('logoBackground') === undefined) {
                        divElem = document.createElement('div');
                        divElem.classList.add('position-absolute', 'nameDiv');
                        divElem.setAttribute('id', 'logoBackground');
                        parent.appendChild(divElem);
                        imageElem = document.createElement('img');
                        /**TP-3201 */   
                        if (typeof(sessionStorage.getItem('my_profile_pic')) !== undefined && sessionStorage.getItem('my_profile_pic') !== undefined && sessionStorage.getItem('my_profile_pic') !== null) {
                            imageElem.setAttribute('src',sessionStorage.getItem('my_profile_pic'));
                            imageElem.classList.add('session-profile-pic');
                            const profile_pic = document.getElementById(divId + "_pic");
                            if (profile_pic !== undefined && profile_pic !== null) {
                                profile_pic.classList.add("d-none");
                            }
                        } else {
                            imageElem.setAttribute('src','/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png');
                            const profile_pic = document.getElementById(divId + "_pic");
                            if (profile_pic !== undefined && profile_pic !== null) {
                                profile_pic.classList.remove("d-none");
                            }
                        } 
                        divElem.appendChild(imageElem);                         
                    } else {
                        const logoDiv = document.getElementById('logoBackground');
                        document.getElementById(g_subInfoArr[ii].g_divId).appendChild(logoDiv);
                    }
                    this.setState({ showHideDivIconAudioOnly: (g_subInfoArr[ii].g_stream.videoType !== "screen") ? true : false }, () => {
                        //TP-2810
                        if (shapeselector && shapeselector !== null)
                            parent.appendChild(shapeselector);
                        parent.appendChild(controlPanel);
                        //parent.appendChild(actionReqControlDiv);
                    });                        
                } else 
                {  
                    // T32-127
                    // Hiding the Max-view controls for User with video disabled
                    // Showing the Max-view controls for all else cases 
                    let flg = false;
                    if (g_subInfoArr[ii].disableVideo === true)
                        flg = true;
                    this.setState({showHideDivIconAudioOnly: flg}, ()=> {
                        //TP-2810
                        if (flg === false && shapeselector && shapeselector !== null) {
                            parent.appendChild(shapeselector);
                        }
                        if (flg === false && controlPanel && controlPanel !== null){
                            // T32-485
                            parent.appendChild(controlPanel);
                            //parent.appendChild(actionReqControlDiv);
                        } 
                    });                    
                }
                parent.style.position = 'absolute';
                parent.className = "text-center"; // Setting the nameDiv Element centered
                parent.style.height = maxHeight + 'px';
                parent.style.width = maxWidth + 'px';
                if (divElem !== null && divElem !== undefined){
                    divElem.style.height = logoHeight + 'px';
                    divElem.style.width = maxWidth + 'px';
                    divElem.style.paddingTop = Math.floor((maxHeight * 5)/100) + 'px';
                    //imageElem.style.height = maxHeight + 'px';
                    imageElem.style.height = logoHeight + 'px' // Change the height of the New Logo
                    //imageElem.style.paddingTop = Math.floor((maxHeight * 10)/100) + 'px';
                    //imageElem.style.width = maxHeight + 'px'; 
                    //imageElem.style.top = '20px';
                }
                parent.style.borderRadius = '5%'; 
                if (parent.style.display === 'none')
                    parent.style.display = 'block';  
                //hide the disconnect user button on top of the video feed elements
                let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                if (disconnectuser !== undefined && disconnectuser !== null) {
                    disconnectuser.style.display = "none";                    
                }    

                //hide the gridcontrol icons on top of video element
                let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                if (gridcontrol !== undefined && gridcontrol !== null)
                    gridcontrol.style.display = "none"; 
                    
                //hide the zoom levels text on top of Video Element
                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                if (zoomLevel !== undefined && zoomLevel !== null) {
                    //TP-2933 -- only show the zoomlevel when it is a mobile device else hide it
                    if (this.state.hideButton === "d-none" && window.innerHeight <= 420 /* && showHideMaxDivControl === true */ && parseInt(this.state.zoom_level) > 1) 
                        zoomLevel.parentElement.style.display = 'block';
                    else 
                        zoomLevel.parentElement.style.display = 'none';    
                }
            }else{
                parent.className = "text-center"; // Setting the nameDiv Element centered
                parent.style.display = 'none'
                //hide the disconnect user button on top of the video feed elements
                let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                if (disconnectuser !== undefined && disconnectuser !== null) {
                    disconnectuser.style.display = "none";                    
                }

                //hide the gridcontrol icons on top of video element
                let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                if (gridcontrol !== undefined && gridcontrol !== null)
                    gridcontrol.style.display = "none";

                //hide the zoom levels text on top of Video Element
                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                if (zoomLevel !== undefined && zoomLevel !== null)
                    zoomLevel.parentElement.style.display = 'none';
            }
        }
        if (flg === false)
            this.selectNewDiv(divId, e);
        /* else 
            this.selectNewDivPassiveExperts(divId); */
    }

    drawNonMaxDivTech = ({divId}) => {
        printConsole("draw the non Max View Technician's View");
        this.setState({gridViewMode: false});
        const {g_subInfoArr, showHideMaxDivControl} = this.state;
        // Calculate the no. of users with Video turned on ---
        const list = g_subInfoArr.filter(p => p.disableVideo === false && p.g_stream.hasVideo === true);

        if ((g_subInfoArr.length - g_expertConnsArr.length) > 1 && list !== undefined && list.length > 1 && showHideMaxDivControl === true) 
            this.hideGridViewIcon(false);
        else 
            this.hideGridViewIcon(true);
        if (this.isOngoingMaxDivFeaturesExceptZoomAndTorch() === true) {
            this.setState({ isOngoingMaxDivFeatures: true })
        }
        
        const sideBarWidth = 80;
        const Height = window.innerHeight - 1;
        const Width = window.innerWidth - 1;
        let maxHeight = 0;
        let maxWidth = 0;
        if (Width <= 1200 || this.state.hideButton === "d-none") {
            //this.hideGridViewIcon(true);
            maxHeight = Math.floor((Height - (Height*2*(borderYsize/100))));
            maxWidth = Math.floor((maxHeight * 16) / 9);  
            if (maxWidth > Width) {
                maxWidth = Math.floor(Width - sideBarWidth);
                maxHeight = Math.floor((maxWidth * 9) / 16);
            }          
        }
        else {
            maxHeight = Math.floor(Height - (Height*(borderYsize/100)));
            maxWidth = Math.floor((maxHeight * 16) / 9);
            if (Math.floor(Width-maxWidth) < 100) {
                maxWidth = Math.floor(Width - sideBarWidth);
                maxHeight = Math.floor((maxWidth * 9) / 16);
            }
        }
        const logoHeight = Math.floor(maxHeight - ((maxHeight * 10)/100));
        printConsole(`max-div user: ${g_maxDivId}`);
        for (let ii = 0; ii < g_subInfoArr.length; ii++) {
            let parent = document.getElementById(g_subInfoArr[ii].g_divId);
            if (parent === null) return;
            // if max divid draw the max window
            if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                let connData = g_subInfoArr[ii].g_stream.connection.data;
                let connArr = connData.split(':::');
                let subscriberBox = document.getElementById(subscriberContainerDivId); //flex-container
                // Set the size of the container
                if (subscriberBox !== null) {
                    subscriberBox.style.width = Width + "px";
                    subscriberBox.style.height = Height + "px";
                }   
                //TP-2810
                let shapeselector = document.getElementById('shapeselector');             
                let controlPanel = document.getElementById('controlpanel');
                //let actionReqControlDiv = document.getElementById('actionRequestControls');
                let imageElem, divElem;
                if (connArr[4] === 'true') {
                    if (document.getElementById('logoBackground') === null 
                        || document.getElementById('logoBackground') === undefined) {
                        divElem = document.createElement('div');
                        divElem.classList.add('position-absolute', 'nameDiv');
                        divElem.setAttribute('id', 'logoBackground');
                        parent.appendChild(divElem);
                        imageElem = document.createElement('img');
                        /**TP-3201 */
                        if (typeof(sessionStorage.getItem('my_profile_pic')) !== undefined && sessionStorage.getItem('my_profile_pic') !== undefined && sessionStorage.getItem('my_profile_pic') !== null) {
                            imageElem.setAttribute('src',sessionStorage.getItem('my_profile_pic'));
                            imageElem.classList.add('session-profile-pic');
                            const profile_pic = document.getElementById(divId + "_pic");
                            if (profile_pic !== undefined && profile_pic !== null) {
                                profile_pic.classList.add("d-none");
                            }
                        } else {
                            imageElem.setAttribute('src','/'+window._env_.REACT_APP_ASSET_FOLDER+'/session_bg_logo.png');
                            const profile_pic = document.getElementById(divId + "_pic");
                            if (profile_pic !== undefined && profile_pic !== null) {
                                profile_pic.classList.remove("d-none");
                            }
                        }
                        divElem.appendChild(imageElem);                         
                    }
                    this.setState({ showHideDivIconAudioOnly: (g_subInfoArr[ii].g_stream.videoType !== "screen") ? true : false }, () => {
                        //TP-2810
                        if (shapeselector)
                            parent.appendChild(shapeselector);
                        if (controlPanel)
                            parent.appendChild(controlPanel);
                        //parent.appendChild(actionReqControlDiv);
                    });                        
                } else 
                {  
                    // T32-127
                    // Hiding the Max-view controls for User with video disabled
                    // Showing the Max-view controls for all else cases 
                    let flg = false;
                    if (g_subInfoArr[ii].disableVideo === true)
                        flg = true;
                    this.setState({showHideDivIconAudioOnly: flg}, ()=> {
                        //TP-2810
                        if (flg === false && shapeselector)
                            parent.appendChild(shapeselector);
                        if (flg === false && controlPanel){
                            // T32-485
                            parent.appendChild(controlPanel);
                            //parent.appendChild(actionReqControlDiv);
                        } 
                    });                    
                }
                parent.style.position = 'absolute';
                parent.className = "text-center"; // Setting the nameDiv Element centered
                parent.style.height = maxHeight + 'px';
                parent.style.width = maxWidth + 'px';
                if (divElem !== null && divElem !== undefined){
                    divElem.style.height = logoHeight + 'px';
                    divElem.style.width = maxWidth + 'px';
                    divElem.style.paddingTop = Math.floor((maxHeight * 5)/100) + 'px';
                    //imageElem.style.height = maxHeight + 'px';
                    imageElem.style.height = logoHeight + 'px' // Change the height of the New Logo
                    //imageElem.style.paddingTop = Math.floor((maxHeight * 10)/100) + 'px';
                    //imageElem.style.width = maxHeight + 'px'; 
                    //imageElem.style.top = '20px';
                }
                parent.style.borderRadius = '5%'; 
                if (parent.style.display === 'none')
                    parent.style.display = 'block';  
                //hide the disconnect user button on top of the video feed elements
                let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                if (disconnectuser !== undefined && disconnectuser !== null) {
                    disconnectuser.style.display = "none";                    
                }    

                //hide the gridcontrol icons on top of video element
                let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                if (gridcontrol !== undefined && gridcontrol !== null)
                    gridcontrol.style.display = "none";   
                    
                //hide the zoom levels text on top of Video element
                let zoomLevel = document.getElementById('zoomLevel_'+g_subInfoArr[ii].g_stream.streamId)
                if (zoomLevel !== undefined && zoomLevel !== null)
                    //TP-2933 -- only show the zoomlevel when it is a mobile device else hide it
                    if (this.state.hideButton === "d-none" && window.innerHeight <= 420 /* && showHideMaxDivControl === true */ && parseInt(this.state.zoom_level) > 1) 
                        zoomLevel.parentElement.style.display = 'block';
                    else 
                        zoomLevel.parentElement.style.display = 'none';    

            }else{                
                parent.className = "text-center"; // Setting the nameDiv Element centered
                parent.style.display = 'none'
                    
                //hide the disconnect user button on top of the video feed elements
                let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                if (disconnectuser !== undefined && disconnectuser !== null) {
                    disconnectuser.style.display = "none";                    
                }
    
                //hide the gridcontrol icons on top of video element
                let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                if (gridcontrol !== undefined && gridcontrol !== null)
                    gridcontrol.style.display = "none";

                //hide the zoom levels text on top of Video element
                let zoomLevel = document.getElementById('zoomLevel_'+g_subInfoArr[ii].g_stream.streamId)
                if (zoomLevel !== undefined && zoomLevel !== null)
                    zoomLevel.parentElement.style.display = "none";
            }
        }
    }

    drawScreenShareUI = ({divId}) => {
        this.setState({gridViewMode: false});
        g_maxDivId = divId;
        const {g_subInfoArr} = this.state;
        const Height = window.innerHeight;
        const Width = window.innerWidth;
        let maxHeight = 0;
        let maxWidth = 0;
        if (Width <= 1200 || this.state.hideButton === "d-none") {
            maxHeight = Math.floor((Height - (Height*2 * (borderYsize/100))) * 2 / 3);
            maxWidth = Math.floor((maxHeight * 16) / 9);            
        }
        else {
            maxHeight = Math.floor(Height - (Height*2 * (borderYsize/100)));
            maxWidth = Math.floor((maxHeight * 16) / 9);
        }
        //console.log(`maxHeight = ${maxHeight}, maxWidth = ${maxWidth}`);
        for (let ii = 0; ii < g_subInfoArr.length; ii++) {
            let parent = document.getElementById(g_subInfoArr[ii].g_divId);
            if (parent === null) return;

            // if max divid draw the max window
            if (g_subInfoArr[ii].g_divId === divId) {
                //let connData = g_subInfoArr[ii].g_stream.connection.data;
                /* let connArr = connData.split(':::');
                let subscriberBox = document.getElementById(subscriberContainerDivId); //flex-container
                let controlPanel = document.getElementById('controlpanel');
                let actionReqControlDiv = document.getElementById('actionRequestControls'); */
                
                //this.setState({ showHideDivIconAudioOnly: false });                        
                if (this.state.userVideoCount === 0) this.setState({ showHideDivIconAudioOnly: true });
                else this.setState({ showHideDivIconAudioOnly: false });
                
                parent.style.position = 'absolute';
                parent.className = "text-center"; // Setting the nameDiv Element centered
                parent.style.height = maxHeight + 'px';
                parent.style.width = maxWidth + 'px';
                parent.style.borderRadius = '5%'; 
                if (parent.style.display === 'none')
                    parent.style.display = 'block';              
            }else{
                parent.className = "text-center"; // Setting the nameDiv Element centered
                parent.style.display = 'none'
            }
        }
    }

    drawSmallSubscriberUI = (hVal, wVal, maxElem, flag) => {
        const { g_maxDivId } = this.props;
        //const sideBarWidth = 80;
        let Height = hVal; //window.innerHeight;
        //let Width = wVal - sideBarWidth; //window.innerWidth;Height -= 40;
        Height -= 40;

        // Set the size of the container
        let subscriberBox = document.getElementById(subscriberContainerDivId); //'flex-container'
        if (subscriberBox === null) {
            printConsole('no div layout for videos yet!');
            return;
        }
        // subscriberBox.style.position = "relative";
        // subscriberBox.style.width = Width + "px";
        // subscriberBox.style.height = Height + "px";
        subscriberBox.style.backgroundColor = '#1c2238';
      //subscriberBox.style.left = sideBarWidth + "px";

        // 	console.log(`inside drawSmallSubscriberUI, Box.width = ${subscriberBox.style.width}, 
        // 		Box.height = ${subscriberBox.style.height})`)

        // NS2-441
        // The small divs on side panel
        /* let divContainerSmall = document.getElementById('small-divs-container');
        if (divContainerSmall === null) {
            // create a container div to hold the smaller videos
            divContainerSmall = document.createElement('div');
            divContainerSmall.setAttribute('className', 'small-container');
            divContainerSmall.setAttribute('id', 'small-divs-container');
        }
        divContainerSmall.style.display = 'none';
        subscriberBox.appendChild(divContainerSmall); */
        let actionReqControlDiv = document.getElementById('actionRequestControls');
        if (actionReqControlDiv !== null) {
            actionReqControlDiv.style.left = '49%';
            // NS2-476
            if (this.props.isSafari)
                actionReqControlDiv.style.bottom = '100px';
            else 
                actionReqControlDiv.style.bottom = '10%';
        }



        // for iPad the factor should be more
        // NS2-476 & NS2-512
        /* let factor = 0;
        if (this.props.isSafari) {
            factor = 80;
        } */
        // the big div
        let maxHeight = Math.floor(Height - (Height*2 * (borderYsize/100)));
        let maxWidth = Math.floor((maxHeight * 16) / 9); 
        let parent = null;
        let ii = 0;
        //alert('Inside drawSmallSubscriberUI: Width, maxWidth, Height, maxHeight = ', Width, maxWidth, Height, maxHeight);
        /// Big div and small divs to be added to the respective video elements
        // NS2-441
        let count = g_subInfoArr.length;
        for (ii = 0; ii < count; ii++) {
            parent = document.getElementById(g_subInfoArr[ii].g_divId);
            if (parent === null) return;

            // if max divid draw the max window
            if (g_subInfoArr.length === 1 || g_subInfoArr[ii].g_divId === g_maxDivId) {
                //let xx = sideBarWidth;
                //let yy = 2;
                parent.style.position = 'absolute';
                //parent.style.left = xx + 'px';
                //parent.style.top = yy + 'px';
                parent.style.width = (maxWidth) + 'px';
                parent.style.height = (maxHeight) + 'px';
                if (g_maxDivId !== null) {
                    let maxDivParent = document.getElementById(g_maxDivId);
                    if (maxDivParent !== null) {
                        //TP-2810
                        if (document.getElementById('shapeselector') && document.getElementById('shapeselector') !== null)
                            maxDivParent.appendChild(document.getElementById('shapeselector')); 
                        maxDivParent.appendChild(document.getElementById('controlpanel'));
                        //maxDivParent.appendChild(document.getElementById('actionRequestControls'));
                    }
                }
            }
            else // now draw the other sub videos if they exist
            {
                // All streams placed in absolute position relative to the layout container
                //parent.style.position = 'absolute';
                parent.className = "text-center"; // Setting the nameDiv Element centered
                parent.style.display = 'none'
            }
        }
    }

    resetMaxDivFeatures() {
        // for NS2-117
        if (this.state.techShareScreen) { // NS2-303
            printConsole("Screen share is ongoing. Please close before selecting a new technician");
            return;
        }
        if (this.state.showHideMaxDivControl === false) return; // NS2-372
        // for NS2-303
        if (this.state.expertShareScreenMaxDiv && !this.state.expertSSAll) {
            this.expertScreenShareClick();
        }


        // 2. reset all active max action buttons
        if (this.state.bMaxChat) this.setState({ bMaxChat: false });
        if (this.state.bAnnotate && !this.state.bRemoteCapture) {// NS2-525	& NS2-202
            this.setState({ bAnnotate: false, isOngoingMaxDivFeatures: false });
            
            let sketchPicker = document.getElementById('sketchPicker');
            let shapeselector = document.getElementById('shapeselector');
            if (sketchPicker)
                sketchPicker.style.display = 'none';
            if (shapeselector)
                shapeselector.style.display = 'none';
            this.setState({
                bSelectColor: false,
                bSelectShape: false,
                pencolor: "red",
                isFreeDrawing: false, /**TP-2586 */
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false,
                isPointerActive: false, /**TP-2475 */
                isErasorActive: false //TP-2474
            });
        }
        if (this.state.bShareAsset) this.setState({ bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: false });
        if (this.state.bRemoteCapture && this.state.sRCTechDetails === '') {
            this.stopExpertRCAnnotate();// NS2-345
            this.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
            this.setState({ bRemoteCapture: false, isOngoingMaxDivFeatures: false });
            this.setState({ bRCAnnotateOn: false });
            if (this.state.bAnnotate === true)
                this.setState({ bAnnotate: false }, () => {
                    if (document.getElementById('canvas') === null) {
                        printConsole('There is no window to handle annotation!!');
                        return;
                    } else {
                        // NS2-217
                        let sketchPicker = document.getElementById('sketchPicker');
                        let shapeselector = document.getElementById('shapeselector');
                        if (sketchPicker)
                            sketchPicker.style.display = 'none';
                        if (shapeselector)
                            shapeselector.style.display = 'none';
                        this.setState({
                            bSelectColor: false,
                            bSelectShape: false,
                            pencolor: "red",
                            isFreeDrawing: false, /**TP-2586 */
                            isRectActive: false,
                            isCircleActive: false,
                            isArrowActive: false,
                            isPointerActive: false, /**TP-2475 */
                            isErasorActive: false //TP-2474
                        }, () => {
                            //onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj);
                            removeCanvasEvents();
                            document.getElementById('canvas').parentElement.removeChild(document.getElementById('canvas'));
                        });
                    }
                });
            else
                this.setState({ bAnnotate: false });
            this.props.updateRCStatus(false);
            this.props.updateScreenShareMaxdiv(false);// NS2-376    
            this.props.updateFileShareMaxdiv(false); // NS2-376	
            //return;
        }
        if (this.state.bZoomClick) this.onClickZoomIcon();

        // 3. If annotations is on - close and remove from old max
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined) {
            this.sendAnnotationToExpert('');//NS2-345
            this.sendAnnotation('', null);
            this.clearAnnotations();
            if (drawElem.fabric) drawElem.fabric.isDrawingMode = false;
            drawElem.parentNode.removeChild(drawElem);
        }

        // 4. Set the recording to false if screen recording is ongoing
        if (this.state.startRecording && document.getElementById('record-beacon') !== null && document.getElementById('record-beacon') !== undefined) {
            document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('record-beacon'));
            //this.onClickRecordScreen();
        }

        // 5. Turn off the Local capture button if Main expert
        if (this.state.showHideMaxDivControl) {
            this.showHideRcForTech(false);
        }
        
    }

    selectNewDiv(divId, event) {
        const {g_subInfoArr} = this.state;
        // 1. Ignore click handling if click is on max div or on another expert div
        if ((divId === g_maxDivId && event !== null && event.type === "click" && this.state.gridViewMode === false) || checkExpertDiv(divId) === true) return;
        printConsole("Send the relevant Max-view signals");
        //2. set the gridViewMode to false
        this.setState({gridViewMode: false});

        // 3. Set the Speaker for the new Maxview technician        
        let maxDivSub = {};
        g_subInfoArr.forEach(val => {
            if (val.g_divId === divId) 
                maxDivSub = val;
        })
        //console.log(maxDivSub.g_divId);
        //printConsole(maxDivSub.disableAudio);
        //console.log(this.state.sRCTechDetails);
        //let maxDivSubVolume = (maxDivSub !== undefined) ? maxDivSub.g_subscriber.getAudioVolume() : 0;
        //((this.state.bMuteAllAudio && maxDivSubVolume === 0) || (this.state.bMuteAllAudio === false && maxDivSubVolume === 0)){
        if (this.state.sRCTechDetails === '' && maxDivSub.disableAudio === true) { //NS2-524
            // Set true if all is muted / sub has been muted earlier
            this.setState({ bRemoteMute: true });
        } else if (this.state.sRCTechDetails === '') { //NS2-524
            this.setState({ bRemoteMute: false });
        }
        //T32-413
        //Set the Max-view Speaker as clickable/disabled based on the flag 
        if (maxDivSub.disableRemoteAudio === true) {
            this.setState({ bRemoteMuteDisabled: true });
        } else {
            this.setState({ bRemoteMuteDisabled: false });
        }                       
         // 3. Make audio & Max-View signals reset
        // first mute/unmute the outgoing/old maxDiv tech mike as per the bMuteMyMike
        if (this.state.muteMikeForMaxDiv !== this.state.bMuteMyMike) { 
            //this.muteExpertMikeInOutMaxDiv(!this.state.bMuteMyMike);
        }
        // first remove the rc button from outgoing/previous div
        //if (this.state.techShareScreen !== true)
        if (this.state.showHideMaxDivControl === true)
            this.showHideRcForTech(false);


        // 4. Reset the Zoom & Torch buttons with that user's corresponding value
        if (this.state.bHideZoomButton === false) {
            this.setState({ zoom_level: (maxDivSub.zoomLevel).toString, bZoomClick: (maxDivSub.zoomLevel > 1) ? true : false, isOngoingMaxDivFeatures: false });
        }    
        if (this.state.bHideTorchButton === false) {
            this.setState({ bTorchClick: maxDivSub.torchActive, isOngoingMaxDivFeatures: false });
        }
       
        // 5. Trigger the Max-View signal & switch maxdiv technician signal
        // Also set the Max-div id as the divId.
        //if (g_maxDivId !== null) {
            
            /* if (otExpObj.state.showHideDivIconAudioOnly === true)
                otExpObj.setState({ showHideDivIconAudioOnly: false }) */
            g_maxDivId = divId;
            // for NS2-342
            if (this.state.showHideMaxDivControl && g_expertConnsArr.length > 0 /* !(this.state.expertSSAll && g_expertConnsArr.length >0) */) {
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.g_divId === g_maxDivId) {
                        // T32-463 -- New Multi-expert Signal trigger implementation
                        const dataArr = subInfo.g_stream.connection.data.split(":::")
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            annotation_active: this.state.bAnnotate,                             
                            zoom_button_active:  (maxDivSub.zoomLevel > 1) ? true : false,
                            zoom_level: maxDivSub.zoomLevel,
                            torch_button_active: maxDivSub.torchActive,
                            annotate_color: "red",
                            shape_selected: "freehand",
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"}, 
                        null);  // send the max div tech details                        
                        //break;
                    }
                })
            }

            // mute/unmute the incoming/new maxDiv tech mike as per the muteMikeForMaxDiv
            /* if (this.state.bMuteMyMike === true && this.state.muteMikeForMaxDiv !== this.state.bMuteMyMike)     
                this.muteExpertMikeInOutMaxDiv(!this.state.muteMikeForMaxDiv);
            else if (this.state.muteMikeForMaxDiv !== this.state.bMuteMyMike)
                this.onClickMaxMuteMicrophone(); */  
            this.setState({ muteMikeForMaxDiv: maxDivSub.disableMike });
            this.props.showScreenShareHeaderButton(true); // for NS2-282
            this.setState({
                bHideExpertSSButton: true,
                bHideTechnicianSSButton: true,
                bHideZoomButton: true,
                bHideTorchButton: true,
                maxDivTechType: "WEB"
                /* bHideAnnotateColour: true,
                bHideAnnotateShapes: true */
            })
            // show the rc button for incoming/new maxdiv 
            //if (this.state.techShareScreen !== true)
            if (this.state.showHideMaxDivControl === true) {
                this.showHideRcForTech(true);
                // Send the MAX_VIEW Signal to all non Maxdiv technician with data = false
                if (g_subInfoArr.length > 1) {
                    g_subInfoArr.forEach(subInfo => {
                        let userConObj = subInfo.g_stream.connection;
                        let dataArr = userConObj.data.split(":::");
                        if (subInfo.g_divId !== g_maxDivId && dataArr[4] === "false") {
                            this.sendSignalMaxViewTech(false, userConObj); 
                        }
                    })
                }
            }

            //put the recording beacon into the max-view parent
            if (this.state.startRecording && document.getElementById('record-beacon') !== null && document.getElementById('record-beacon') !== undefined) {
                document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('record-beacon'));
            }
            
            if (this.state.muteMikeForMaxDiv !== this.state.bMuteMyMike && this.state.bMuteMyMike === true) {
                //this.setState({ openMikeDialog: true });
            }
            /* // remove timer element from the max vdo div so that it can be added again while 
            // switching between screens
            let timerElement = document.getElementById("timer-span");
            if (timerElement) {
                timerElement.remove();
            } */

            //this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
        //}
    }

    // NS2-372
    selectNewDivPassiveExperts(divId) 
    {
        //console.log(`passive expert's maxdiv switch to tech div id: ${divId}`);
        this.hideGridViewIcon(true);
        const {g_subInfoArr} = this.state;
        // 1. Ignore click handling if click is on max div or on another expert div
        //if (divId === g_maxDivId || isDivExpert(divId) === true) return;
        // for NS2-117
        if (this.state.techShareScreen) { // NS2-303
            printConsole("Screen share is ongoing. Please close before selecting a new technician");
            return;
        }
        if (this.state.showHideMaxDivControl === true) return;
        // for NS2-303
        if (this.state.expertShareScreenMaxDiv && !this.state.expertSSAll) {
            this.expertScreenShareClick();
        }


        // 2. reset all active max action buttons
        if (this.state.bMaxChat) this.setState({ bMaxChat: false });
        if (this.state.bAnnotate) {//NS2-202
            this.setState({ bAnnotate: false, isOngoingMaxDivFeatures: false });
            //const controlpanel = document.getElementById('controlpanel');
            /* const penPicker = document.getElementById('penpicker');
            const shapespicker = document.getElementById('shapespicker'); */
            const sketchPicker = document.getElementById('sketchPicker');
            const shapeselector = document.getElementById('shapeselector');
            /*  penPicker.style.display = 'none';
             shapespicker.style.display = 'none'; */
            sketchPicker.style.display = 'none';
            shapeselector.style.display = 'none';
            //controlpanel.style.left = '57%';
            this.setState({
                bSelectColor: false,
                bSelectShape: false,
                pencolor: (this.state.pencolor !== "red" && (this.state.pencolor).toLowerCase() !== "ff0000" ) ? this.state.pencolor : "red",
                isFreeDrawing: false, /**TP-2586 */
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false,
                isPointerActive: false, /**TP-2475 */
                isErasorActive: false //TP-2474
            });
        }
        //if (this.state.bShareAsset && !this.state.isPrimaryToPassive) this.setState({ isPrimaryToPassive: false, bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: false });
        if (this.state.bRemoteCapture && this.state.sRCTechDetails === '') {
            // this.setState({bRemoteCapture: false});// onClickCamera will take care
            //this.onClickCamera();            
            this.setState({ bRemoteCapture: false, isOngoingMaxDivFeatures: false });
            this.setState({ bRCAnnotateOn: false });
            if (this.state.bAnnotate === true)
                this.setState({ bAnnotate: false }, () => {
                    if (document.getElementById('canvas') === null) {
                        printConsole('There is no window to handle annotation!!');
                        return;
                    } else {
                        // NS2-217                        
                        this.setState({
                            bSelectColor: false,
                            bSelectShape: false,
                            pencolor: (this.state.pencolor !== "red" && (this.state.pencolor).toLowerCase() !== "ff0000" ) ? this.state.pencolor : "red",
                            isFreeDrawing: false, /**TP-2586 */
                            isRectActive: false,
                            isCircleActive: false,
                            isArrowActive: false,
                            isPointerActive: false, /**TP-2475 */
                            isErasorActive: false //TP-2474
                        }, () => {
                            //onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj);
                            document.getElementById('canvas').parentElement.removeChild(document.getElementById('canvas'));
                        });
                    }
                });            
            this.props.updateRCStatus(false);
            this.props.updateScreenShareMaxdiv(false);// NS2-376    
            this.props.updateFileShareMaxdiv(false); // NS2-376
        }
        //if (this.state.bZoomClick) this.onClickZoomIcon();

        // 2. Set the Mike for the new Maxview technician        
        let maxDivSub = {};
        g_subInfoArr.forEach(val => {
            if (val.g_divId === divId) 
                maxDivSub = val;
        })
        //let maxDivSubVolume = maxDivSub.g_subscriber.getAudioVolume();
        //((this.state.bMuteAllAudio && maxDivSubVolume === 0) || (this.state.bMuteAllAudio === false && maxDivSubVolume === 0)){
        if (maxDivSub.disableAudio === true) {
            // Set true if all is muted / sub has been muted earlier
            this.setState({ bRemoteMute: true });
        } else {
            this.setState({ bRemoteMute: false });
        }
        // T32-413
        //Set the Max-view Speaker as clickable/disabled based on the flag 
        if (maxDivSub.disableRemoteAudio === true) {
            this.setState({ bRemoteMuteDisabled: true });
        } else {
            this.setState({ bRemoteMuteDisabled: false });
        }

        // 3. If annotations is on - close and remove from old max
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined) {
            /* this.sendAnnotationToExpert('');//NS2-345
            this.sendAnnotation('', null);
            this.clearAnnotations(); */
            if (drawElem.fabric) drawElem.fabric.isDrawingMode = false;
            drawElem.parentNode.removeChild(drawElem);
        }

        // 4. Make the switch ^-^
        //if (g_maxDivId !== null ) {
            // first mute/unmute the outgoing/old maxDiv tech mike as per the bMuteMyMike 
            //this.muteExpertMikeInOutMaxDiv(!this.state.bMuteMyMike);
            
            this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
            
            /* if (g_subInfoArr.length > 1) 
                this.hideGridViewIcon(false);
            else */ 
                this.hideGridViewIcon(true);
            this.maximizeDiv(null, {divId}, true)

            /* if (this.state.showHideDivIconAudioOnly === true)
                this.setState({ showHideDivIconAudioOnly: false }) */
            g_maxDivId = divId;            

            // mute/unmute the incoming/new maxDiv tech mike as per the muteMikeForMaxDiv
            //this.muteExpertMikeInOutMaxDiv(!this.state.muteMikeForMaxDiv);
            this.setState({ muteMikeForMaxDiv: maxDivSub.disableMike });
        // 5. TP-1482 -- Check if 3D file share is open then change the parent of the 3D file to the new Max-view technician
            const threeDCanvas = document.getElementById('canavas-3d-container');
            const threeClose = document.getElementById('closeIcon');
            if (threeClose !== undefined && threeClose !== null) {
                printConsole("re-draw the 3D file viewer for Passive expert");
                let paramElemVdoChild;
                let tmp = maxDivSub.g_stream.connection.data;
                let tmpSplit = tmp.split(':::');
                if (tmpSplit[4] === 'true' && maxDivSub.g_stream.hasVideo === false) {                                             
                    paramElemVdoChild = document.getElementById(divId+'_name');
                } else {
                    [paramElemVdoChild] = document.getElementById(divId).getElementsByClassName('OT_video-element');
                }
                threeDCanvas.style.width = document.getElementById(divId).style.width;
                threeDCanvas.style.left = '0px';
                threeClose.style.right = '49%';
                paramElemVdoChild.parentNode.insertBefore(threeDCanvas, paramElemVdoChild);
                paramElemVdoChild.parentNode.insertBefore(threeClose, threeDCanvas);
            }

        // 6. Reset the Zoom & Torch button states based on that user's corresponding values
            this.setState({ zoom_level: maxDivSub.zoomLevel, bZoomClick: (maxDivSub.zoomLevel > 1) ? true : false, isOngoingMaxDivFeatures: false });
            this.setState({ bTorchClick: maxDivSub.torchActive, isOngoingMaxDivFeatures: false });
            
            this.props.showScreenShareHeaderButton(true); // for NS2-282
            this.setState({
                bHideExpertSSButton: true,
                bHideTechnicianSSButton: true,
                bHideZoomButton: true,
                bHideTorchButton: true,
                maxDivTechType: "WEB"
                /* bHideAnnotateColour: true,
                bHideAnnotateShapes: true */
            })
            // show the rc button for incoming/new maxdiv 
            //this.showHideRcForTech("true");
            if (this.state.muteMikeForMaxDiv !== this.state.bMuteMyMike) {
                //this.setState({ openMikeDialog: true });
            }
            // remove timer element from the max vdo div so that it can be added again while 
            // switching between screens
            /* let timerElement = document.getElementById("timer-span");
            if (timerElement) {
                timerElement.remove();
            } */

        //}
    }
    
    // Handling of Technician functions when camera is swapped
    handleFeaturesForTech(techDetailsObj) {
        //console.log(techDetailsObj);
        if (this.state.showHideMaxDivControl === false) return;
        const {g_session, g_subInfoArr} = this.state;
        //console.log("Handle features for technician!!!");
        //console.log(g_subInfoArr.length);
        if (g_session !== null && g_subInfoArr.length > 0) {
           g_subInfoArr.forEach(subInfo => {
                //const connArr = subInfo.g_stream.connection.data.split(":::");
                //if (connArr[3] === techDetailsObj.email) {
                   // If Expert is in Grid View Mode
                   if (this.state.gridViewMode === true) {
                        if (this.state.expertShareScreenMaxDiv && this.state.expertSSAll /* && subInfo.disableVideo === false */) {                            
                            this.sendSignalExpertSS(false, subInfo.g_stream.connection);
                        }
                        if (this.state.bShareAsset === true) {
                            this.setState({ assetsArray: [] });
                            this.sessionSignal.sendSignalRemoveArtifacts(
                                true,
                                techDetailsObj.email
                                //type: "REMOVE_ARTIFACTS"
                            );
                            // T32-592 -- Enable the Passive expert's Hand Icons when 
                            // File share is closed
                            this.sendSignalSyncExperts({
                                show_grid: true,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            null);
                        }
                   }
                   // If the technician that swapped its camera is the Max-view technician?
                   else /* if (subInfo.g_divId === g_maxDivId) */ {
                        this.setState({isOngoingMaxDivFeatures: false});
                        // 1. Screenshare features needs to be reset for the Max-view tech    
                        if (this.state.expertShareScreenMaxDiv && !this.state.expertSSAll) {
                            this.expertScreenShareClick();
                        }
                        if (this.state.techShareScreen) { 
                           this.onTechScreenShareClick(); 
                        }
                        if (this.state.expertShareScreenMaxDiv && this.state.expertSSAll) {
                            this.props.updateScreenShareMaxdiv(false); 
                            this.onClickExpertScreenShareForAll(false);                            
                        }
                        // 2. reset all other active max action buttons
                        if (this.state.bMaxChat) this.setState({ bMaxChat: false });
                        if (this.state.bAnnotate && !this.state.bRemoteCapture) {// NS2-525	& NS2-202
                            this.setState({ bAnnotate: false });
                            
                            let sketchPicker = document.getElementById('sketchPicker');
                            let shapeselector = document.getElementById('shapeselector');
                            if (sketchPicker)
                                sketchPicker.style.display = 'none';
                            if (shapeselector)
                                shapeselector.style.display = 'none';
                            this.setState({
                                bSelectColor: false,
                                bSelectShape: false,
                                pencolor: "red",
                                isFreeDrawing: false, /**TP-2586 */
                                isRectActive: false,
                                isCircleActive: false,
                                isArrowActive: false,
                                isPointerActive: false, /**TP-2475 */
                                isErasorActive: false //TP-2474
                            });
                        }
                        if (this.state.bShareAsset) {
                            this.onCloseFileViewer();
                            this.setState({ isOngoingMaxDivFeatures: false });
                        }
                        //this.setState({ bShareAsset: false });
                        if (this.state.bRemoteCapture && this.state.sRCTechDetails === '') {
                            this.stopExpertRCAnnotate();// NS2-345
                            this.stopRemoteCaptureAnnotation(document.getElementById(g_maxDivId));
                            this.setState({ bRemoteCapture: false });
                            this.setState({ bRCAnnotateOn: false });
                            if (this.state.bAnnotate === true)
                                this.setState({ bAnnotate: false }, () => {
                                    if (document.getElementById('canvas') === null) {
                                        printConsole('There is no window to handle annotation!!');
                                        return;
                                    } else {
                                        // NS2-217
                                        let sketchPicker = document.getElementById('sketchPicker');
                                        let shapeselector = document.getElementById('shapeselector');
                                        if (sketchPicker)
                                            sketchPicker.style.display = 'none';
                                        if (shapeselector)
                                            shapeselector.style.display = 'none';
                                        this.setState({
                                            bSelectColor: false,
                                            bSelectShape: false,
                                            pencolor: "red",
                                            isFreeDrawing: false, /**TP-2586 */
                                            isRectActive: false,
                                            isCircleActive: false,
                                            isArrowActive: false,
                                            isPointerActive: false, /**TP-2475 */
                                            isErasorActive: false //TP-2474
                                        }, () => {
                                            //onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj);
                                            removeCanvasEvents();
                                            document.getElementById('canvas').parentElement.removeChild(document.getElementById('canvas'));
                                        });
                                    }
                                });
                            else
                                this.setState({ bAnnotate: false });
                            this.props.updateRCStatus(false);
                            this.props.updateScreenShareMaxdiv(false);// NS2-376    
                            this.props.updateFileShareMaxdiv(false); // NS2-376	
                            //return;
                        }
                        if (this.state.bZoomClick) this.onClickZoomIcon();

                        // 3. If annotations is on - close and remove from old max
                        let drawElem = document.getElementById('canvas');
                        if (drawElem !== null && drawElem !== undefined) {
                            this.sendAnnotationToExpert('');//NS2-345
                            this.sendAnnotation('', null);
                            this.clearAnnotations();
                            if (drawElem.fabric) drawElem.fabric.isDrawingMode = false;
                            drawElem.parentNode.removeChild(drawElem);
                        }

                        // 4. Set the recording to false if screen recording is ongoing
                        /* if (this.state.startRecording)
                            this.onClickRecordScreen(); */

                        // 5. Turn off the Local capture button if Main expert
                        /* if (this.state.showHideMaxDivControl) {
                            this.showHideRcForTech(false);
                        } */
                   } 
                   // If the technician that swapped its camera isn't the Max-view Technician?
                   /* else {
                        if (this.state.expertShareScreenMaxDiv && this.state.expertSSAll) {                            
                            this.sendSignalExpertSS(false, subInfo.g_stream.connection);
                        }
                        if (this.state.bShareAsset === true) {
                            this.sessionSignal.sendSignalRemoveArtifacts(
                                true,
                                techDetailsObj.email
                                //type: "REMOVE_ARTIFACTS"
                            );
                        } 
                   } */
                   this.setState({ isLoading: false });
                   return;
                //}
           }); 
        }
    }

    /* New Camera feed rendering methods* */
    // called from initializeOTSession::streamCreated event of the session object
    // called only when the older camera feed of the same technician needs to be
    // replaced on Expert screen by the new camera feed
    switchOldCameraFeed2New(connectionId) {
        let {g_session, g_subInfoArr} = this.state;
        let ii = 0;
        //let maxdiv;
        // find the corresponding live streaming div if it exists when created stream is with the screen share
        if (g_session !== null && g_subInfoArr.length > 0) {
            for (ii = 0; ii < g_subInfoArr.length; ii++) {
                if (g_subInfoArr[ii].g_stream.connection.connectionId === connectionId) {
                    //console.log(g_subInfoArr[ii].g_stream.connection.data);
                    this.removeSessionJoinees(g_subInfoArr[ii].g_stream);
                    g_subInfoArr[ii].g_subscriber.subscribeToVideo(false);
                    g_session.unsubscribe(g_subInfoArr[ii].g_subscriber);
                    let divIdToRemove = g_subInfoArr[ii].g_divId;
                    g_subInfoArr.splice(ii, 1);
                    if (divIdToRemove === g_maxDivId)
                        (g_subInfoArr.length > 0) ? g_maxDivId = g_subInfoArr[0].g_divId : g_maxDivId = null;
                                                 
                    if (divIdToRemove !== null && divIdToRemove !== undefined) {
                        let nodeToRemove = document.getElementById(divIdToRemove);                                
                        nodeToRemove.parentElement.removeChild(nodeToRemove);
                    }                                                                                                                                                                                                                                                                                                                                                                       
                }
            }
            //this.setState({g_subInfoArr: g_subInfoArr});
            return g_subInfoArr;
        }
    }

    /* Screen Share rendering methods */
    // called from initializeOTSession::streamCreated event of the session object
    // called in the expert flavor of webapp only
    switchExpertLive2Screen(connectionId, subInfo) {
        let {g_session, g_subInfoArr} = this.state;
        let ii = 0;
        // find the corresponding live streaming div if it exists when created stream is with the screen share
        if (g_session !== null && g_subInfoArr.length > 0) {
            for (ii = 0; ii < g_subInfoArr.length; ii++) {
                if (g_subInfoArr[ii].g_stream.connection.connectionId === connectionId) {
                    g_ss_subInfo = g_subInfoArr[ii];
                    g_subInfoArr.splice(ii, 1, subInfo);
                    //return;
                }
            }
            this.setState({g_subInfoArr: g_subInfoArr});
        }
    }

    // called from expert session::streamDestroyed event - add back the live streaming subInfo to the array
    // called in the expert flavor of webapp only
    switchExpertScreen2Live() {
        let {g_subInfoArr} = this.state;
        //console.log(g_ss_subInfo);
        if (g_ss_subInfo !== null) {
            let subscriberInfo = {
                g_subscriber: g_ss_subInfo.g_subscriber,
                g_stream: g_ss_subInfo.g_stream,
                g_divId: g_ss_subInfo.g_divId,
                canRC: true
            };
            document.getElementById(subscriberInfo.g_divId).style.display = 'block';
            // for NS2-94
            g_subInfoArr.splice(0, 0, subscriberInfo);
            this.setState({g_subInfoArr: g_subInfoArr});
        }
    }

    /** File Upload renderer */
    
    getSceneManager = () => {
        const closeIconText = this.props.i18n._('Click here to remove the 3d object');
        const {gridViewMode} = this.state;
        const paramElem = document.getElementById(g_maxDivId);

        let paramElemVdoChild;
        if (gridViewMode) {
            paramElemVdoChild = document.getElementById(subscriberContainerDivId);
        }
        else if (isDivExpert(g_maxDivId)) {
            if (!paramElem) return;
            [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-poster');
        } else {
            if (!paramElem) return;
            [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-element');
        }
        if (!paramElemVdoChild) return;
        const { sceneManager } = threeEntryPoint({
            videoElement: paramElemVdoChild,
            id: 'canavas-3d-container',
            fnSend3dRotation: this.send3dRotation,
            fnClose3dCanvas: this.onCloseFileViewer,
            closeIconText
        });

        return sceneManager;
    }

    // NS2-366
    trackClickOn3dCanvas = () => {
        document.addEventListener('click', (event) => {
            this.canvasListner(event)
        });
    }
    // NS2-366
    canvasListner = (event) => {
        const canvas = document.getElementById('canavas-3d-container');
        if (canvas) {
            const isClickInsideElement = canvas.contains(event.target);
            if (!isClickInsideElement) {
                const closeButton = document.getElementById('closeIcon');
                if (closeButton) {
                    closeButton.click();
                }
            }
        }
    }
    // NS2-366
    remove3dCanvasListner = () => {
        document.removeEventListener('click', (event) => {
            this.canvasListner(event)
        });
    }
    
    async render3dModelPublisher(filesArr, s3Files) {
        try {
            const [mtlFile] = filesArr.filter(e => e.name.endsWith('.mtl'));
            const [objFile] = filesArr.filter(e => e.name.endsWith('.obj') || e.name.endsWith('.3ds') || e.name.endsWith('.3DS') || e.name.endsWith('.FBX') || e.name.endsWith('.fbx'));
            /* diagonastics(this.authService, {
                action: `rendering 3d model for publisher`,
                next_step: '',
                data: `filesArr:${JSON.stringify(filesArr)}, s3Files:${JSON.stringify(s3Files)}`,
                error: 'none'
            }) */
            if (!objFile) {
                const [file] = filesArr;
                const splitedArray = file.name.split('.');
                const fileUrl = URL.createObjectURL(file);
                this.setState({
                    showFileViewer: true,
                    fileType: splitedArray[splitedArray.length - 1],
                    filePath: fileUrl,
                    fileName: file.name,
                    expertLoggedInId: this.props.loggedInUserId
                    // Using this we can identify which expert was uploaded the file
                });
                this.setState(prev => ({
                    URLs: [
                        ...prev.URLs,
                        fileUrl
                    ]
                }));
                return;
            }
            const textureFiles = s3Files.filter(e => e.key.endsWith('.jpg') || e.key.endsWith('.png') || e.key.endsWith('.BMP') || e.key.endsWith('.bmp') | e.key.endsWith('.exr') || e.key.endsWith('.tga'));
            const sceneManager = this.getSceneManager();
            if (!sceneManager) return

            // create object URL from the file object
            const objectURL = URL.createObjectURL(objFile);
            const mtlURL = mtlFile ? URL.createObjectURL(mtlFile) : undefined;
            const textureURLs = map(textureFiles, 'location'); //textureFiles ? textureFiles.map(e => URL.createObjectURL(e)) : [];
            this.setState(prev => ({
                isLoading: true,
                URLs: [
                    ...prev.URLs,
                    objectURL,
                    mtlURL,
                ],
                expertLoggedInId: this.props.loggedInUserId
            }));
            const fileType = objFile.name.split('.').pop();
            await sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlURL, objFilePath: objectURL, textureFilePath: textureURLs })
            this.setState({ isLoading: false });
            // NS2-366 Initialise the event listner to close the canvas when clicked outside
            this.trackClickOn3dCanvas();
        } catch (error) {
            let msg = i18nMark('Something went wrong. Please try again later.');
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.props.updateFileShare(false);
            this.setState({ bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: (this.state.expertShareScreenMaxDiv) ? true : false }, () => {
                const shapeselector = document.getElementById('shapeselector')
                if (shapeselector !== undefined && shapeselector !== null && this.state.bAnnotate && !this.state.bRemoteCapture) {
                    shapeselector.style.display = "block";
                }
            });// NS2-457
            this.setState({ isLoading: false, error: msg });
        }
    }

    /** Method to render 3d models in expert view */
    handleArtifactsOnPrimaryExpert = async (filesArr) => {
        const { loggedInUserId } = this.props;
        //printConsole(`Here--------------------> ${filesArr}`);
        if (!filesArr.length) return;
        
        try { 
            const [mtlFile] = filesArr.filter(e => e.name.endsWith('.mtl'));
            const [objFile] = filesArr.filter(e => e.name.endsWith('.obj') || e.name.endsWith('.3ds') ||  e.name.endsWith('.3DS') || e.name.endsWith('.FBX') || e.name.endsWith('.fbx'));
            const [audioFile] = filesArr.filter(e => e.name.endsWith('.mp3'));
            
            if (!objFile) {
                const [file] = filesArr;
                const splitedArray = file.name.split('.');
                const fileUrl = URL.createObjectURL(file);
                if (bCancelShare === true) {
                    this.setState({ isLoading: false });
                    bCancelShare = false;
                    return;
                }
                this.setState((prev) =>({
                    showFileViewer: true,
                    fileType: splitedArray[splitedArray.length - 1],
                    filePath: fileUrl,
                    fileName: file.name,
                    expertLoggedInId: loggedInUserId, // Using this we can identify which expert has uploaded the file
                    URLs: [
                        ...prev.URLs,
                        fileUrl
                    ],
                    isLoading: false
                }));
                if (audioFile) this.setState({ showAudioFileViewer: true });
                return;
            }
            const textureFiles = filesArr.filter(e => e.name.endsWith('.jpg') || e.name.endsWith('.png') || e.name.endsWith('.BMP') || e.name.endsWith('.bmp') | e.name.endsWith('.exr') || e.name.endsWith('.tga'));
            const sceneManager = this.getSceneManager();
            
            if (!sceneManager) return
            if (bCancelShare === true) {
                this.setState({ isLoading: false });
                bCancelShare = false;
                return;
            }
        
            // create object URL from the file object
            const objectURL = URL.createObjectURL(objFile);
            const mtlURL = mtlFile ? URL.createObjectURL(mtlFile) : undefined;
            const textureURLs = textureFiles ? textureFiles.map(e => URL.createObjectURL(e)) : []; ////map(textureFiles, 'location'); 
            /** Set all state variables here */
            this.setState(prev => ({ 
                isLoading: true,
                URLs: [
                    ...prev.URLs,
                    objectURL,
                    mtlURL,
                ],
                expertLoggedInId: loggedInUserId
            }));

            const fileType = objFile.name.split('.').pop();
            await sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlURL, objFilePath: objectURL, textureFilePath: textureURLs })
                    .then(e => {
                        this.setState({ isLoading: false });
                        this.active3dModel = e;
                        //TP-1116: Rs Added to resolve retain the rotational value
                        if (this.state.rotationArray !== '') {
                            const rotationChanges = JSON.parse(this.state.rotationArray);
                            printConsole("rotating the rendered 3d Model");
                            const { _x, _y, _z, _w } = rotationChanges.quaternion
                            const eulerRotation = new THREE.Quaternion().copy({ x: _x, y: _y, z: _z, w: _w });
                            this.active3dModel && this.active3dModel.quaternion.copy(eulerRotation);
                        }
                    })
                    .catch(err => {
                        this.setState({ isLoading: false });
                        console.warn(err)
                    });
            this.setState({ isLoading: false });

            // NS2-366 Initialise the event listner to close the canvas when clicked outside
            //this.trackClickOn3dCanvas();
        } catch (error) {
            bCancelShare = false;
            let msg = 'Something went wrong while uploading file.';
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false,  error: msg}); 
        }
    }

    //TP-1426-- Close file viewer for 3D/non-3D files
    closeFileViewer = () => {
        const [closeIcon, canvas] = document.getElementsByClassName('asset-3d');
        if (closeIcon) {                
            window.removeEventListener('mousemove', function(){});
            window.removeEventListener('touchmove', function(){});
                
            window.removeEventListener('mouseup', function(){});
            window.removeEventListener('touchend', function(){});

            window.removeEventListener('mousedown', function(){});
            window.removeEventListener('touchstart', function(){});

            /** remove both element from dom */
            closeIcon && closeIcon.parentNode && closeIcon.parentNode.removeChild(closeIcon);
            canvas && canvas.parentNode.removeChild(canvas);
        }
        this.onCloseFileViewer();        
    }

    onCloseFileViewer = (e, isGridView=false) => {
        printConsole("Close the shared file");
        let isClicked = false
        if (e && e.type && e.type === "click") isClicked = true;        
        if (e) e.preventDefault();
        const {g_session, g_subInfoArr, assetsArray} = this.state;
        let files = this.state.fileNameArr;
        this.state.URLs.forEach(url => {
            if (url) window.URL.revokeObjectURL(url);
        });
        this.setState(prev => ({
            showFileViewer: false,
            showAudioFileViewer: false,
            fileType: 'none',
            filePath: '',
            fileName: '',
            fileOpacity: (e !== null && isClicked === true && this.state.showHideMaxDivControl) ?  "0.60": prev.fileOpacity,
            rotationArray: (this.state.showHideMaxDivControl) ?  '' : prev.rotationArray,    
            // expertLoggedInId: null
        }), () => {
            this.props.updateFileShareMaxdiv(false);
                if (this.state.bShareAsset === true) {
                    this.props.updateFileShare(false);
                    this.setState({ bShareAsset: false, fileData: null, fileArr: [] }, () => {
                        // Commented out due to TP-981
                        /* const shapeselector = document.getElementById('shapeselector')
                        if (shapeselector !== undefined && shapeselector !== null && this.state.bAnnotate && !this.state.bRemoteCapture) {
                            shapeselector.style.display = "block";
                        } */

                        const isOngoingMaxDivFeatures = this.isOngoingMaxDivFeaturesExceptZoomAndTorch();
                        this.setState({ isOngoingMaxDivFeatures });
                    })
                }
                if (this.state.shareOnDiv === null) {
                    this.props.updateFileShare(false);
                    this.setState({ bShareAsset: false, fileData: null, isOngoingMaxDivFeatures: false }, () => {
                        // Commented out due to TP-981
                        /* const shapeselector = document.getElementById('shapeselector')
                        if (shapeselector !== undefined && shapeselector !== null && this.state.bAnnotate && !this.state.bRemoteCapture) {
                            shapeselector.style.display = "block";
                        } */
                    });//NS2-457
                }
                //TP-1187
                if(this.state.fileOpacity === "0.60"){
                    if (g_expertConnsArr.length > 0){
                        g_expertConnsArr.forEach(connectionObj => {
                            if (g_session.connection) {
                                const toArray = connectionObj.data.split(":::");
                                const toObject = toArray[3];
                                this.sessionSignal.sendSignalOpacity(this.state.fileOpacity,toObject);
                            }
                        });
                    }
                }
                this.deleteS3Files(files);
                // remove the event listner of 3d canvas which closes it when clicked outside //Ns2-366
                this.remove3dCanvasListner();
                this.setState({ assetsArray: (this.state.showHideMaxDivControl) ? [] : assetsArray, fileData: null }); //TP-1423               
                //TP-2838
                if (this.state.bAnnotate && !this.state.bRemoteCapture) {
                    this.setAnnotationButtons();
                    if (this.props.sessionInfo.annotation_shape_enabled) {
                        const shapeselector = document.getElementById('shapeselector')
                        if (shapeselector !== undefined && shapeselector !== null) {
                            shapeselector.style.display = "block";
                        }
                    }
                }
        });
        if (g_session !== null && g_subInfoArr.length > 0 && this.state.showHideMaxDivControl === true) {
            let maxSubInfo = null;
            g_subInfoArr.forEach(subInfo => {
                const toArray = subInfo.g_stream.connection.data.split(":::");
                const toObject = toArray[3];
                //if (subInfo.disableVideo === false) {
                    this.sessionSignal.sendSignalRemoveArtifacts(
                        true,
                        toObject
                        //type: "REMOVE_ARTIFACTS"
                    );
                //}
                //console.log("artifacts signal sent...");
                //return;

                if (subInfo.g_divId === g_maxDivId) {
                    maxSubInfo = subInfo;
                }
            });
            if (isGridView === true) return;
            // T32-592 -- Enable the Passive expert's Hand Icons when 
            // File share is closed
            if (this.state.gridViewMode === false && maxSubInfo !== null) {
                const dataArr = maxSubInfo.g_stream.connection.data.split(":::");
                this.sendSignalSyncExperts({
                    show_grid: false,
                    max_view_technician: dataArr[3], 
                    zoom_button_active:  (maxSubInfo.zoomLevel > 1) ? true : false,
                    zoom_level: maxSubInfo.zoomLevel,
                    torch_button_active: maxSubInfo.torchActive, 
                    annotation_active: this.state.bAnnotate,
                    hand_icon_enabled: true,
                    header_fileShare_active: false, //TP-2378             
                    reason: "show_action"}, 
                null);
            } else if (this.state.gridViewMode === true) {
                this.sendSignalSyncExperts({
                    show_grid: true,
                    hand_icon_enabled: true,
                    header_fileShare_active: false, //TP-2378             
                    reason: "show_action"}, 
                null);
            }
        }
    }

    deleteS3Files = (files) => {
        deleteFile(files, this.config)
            .then(response => {
                // console.log(response);
                this.setState({
                    fileNameArr: []
                });
            })
            .catch(err => {
                // console.error(err)
            })

    }

    onClickCloseErrorModal = (e) => {
        e.preventDefault();
        this.setState({ error: false });
    } 

    /** Share Artifacts for Secondary expert methods */
    handleArtifactsOnSubscribers = ({ data, paramElem, isExpertDiv }) => {
        const closeIconText = this.props.i18n._('Click here to remove the 3d object')
        const {gridViewMode} = this.state;
        try {
            const [objFile] = data.filter(e => e.key.endsWith('.obj') || e.key.endsWith('.3ds') || e.key.endsWith('.3DS') || e.key.endsWith('.FBX') || e.key.endsWith('.fbx'));
            const [mtlFile = {}] = data.filter(e => e.key.endsWith('.mtl'));
            const textureFiles = data.filter(e => e.key.endsWith('.jpg') || e.key.endsWith('.png') || e.key.endsWith('.BMP') || e.key.endsWith('.bmp') | e.key.endsWith('.exr') || e.key.endsWith('.tga'));
            const [audioFile] = data.filter(e => e.key.endsWith('.mp3'));

            if (objFile) {
                this.setState({ isLoading: true });
                if (!paramElem) return;
                let paramElemVdoChild;
                if (gridViewMode) {
                    paramElemVdoChild = document.getElementById(subscriberContainerDivId);
                }
                else if (isExpertDiv) {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-poster');
                } else {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-element');
                }

                if (!paramElemVdoChild) return;

                const { sceneManager } = threeEntryPoint({
                    videoElement: paramElemVdoChild,
                    id: 'canavas-3d-container',
                    fnSend3dRotation: () => { },
                    fnClose3dCanvas: () => { },
                    closeIconText
                });
                const fileType = objFile.key.split('.').pop();
                sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlFile.location, objFilePath: objFile.location, textureFilePath: map(textureFiles, 'location') })
                    .then(e => {
                        this.setState({ isLoading: false });
                        this.active3dModel = e;
                    })
                    .catch(err => {
                        this.setState({ isLoading: false });
                        console.warn(err)
                    });
                this.closeSubscriber3dObject = sceneManager.removeCanvas;
            } else {
                const [file] = data;
                this.closeSubscriber3dObject = null;
                const splitedArray = file.key.split('.');
                this.setState({
                    showFileViewer: true,
                    fileType: splitedArray[splitedArray.length - 1],
                    filePath: file.location,
                    fileName: file.key.replace('boo/', ''),
                    expertLoggedInId: file.loggedInUserId
                });
                if (audioFile) this.setState({ showAudioFileViewer: true });
            }
        } catch (error) {
            let msg = 'Something went wrong while uploading file.';
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false, error: msg });
        }
    }

    // New Handler method for Shared asset files on Secondary expert side
    handleArtifactsNew = async ({ assetsArray, paramElem, isExpertDiv, flag }) => {
        const closeIconText = this.props.i18n._('Click here to remove the 3d object')
        const {gridViewMode} = this.state;

        //console.log("Passive expert assets Array: ", assetsArray);
        try {
            const [objFile] = assetsArray.filter(e => e.format === 'obj' || e.format === '3ds' || e.format === '3DS'|| e.format === 'FBX' || e.format === 'fbx');
            const [mtlFile = {}] = assetsArray.filter(e => e.format === 'mtl');
            const [pdfFile] = assetsArray.filter(e => e.format === 'pdf');
            const textureFiles = assetsArray.filter(e => e.format === 'jpg' || e.format === 'png' || e.format === 'BMP' || e.format === 'bmp' | e.format === 'exr' || e.format === 'tga');
            const [audioFile] = assetsArray.filter(e => e.format === 'mp3');
    
            if (objFile) {
                let paramElemVdoChild;
                if (gridViewMode) {
                    paramElemVdoChild = document.getElementById(subscriberContainerDivId);
                }
                else if (isExpertDiv) {
                    if (!paramElem) return;
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-poster');
                } else {
                    if (!paramElem) return;
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-element');
                }

                if (!paramElemVdoChild) return;

                if (flag === false) {
                    if (objFile.data.indexOf("data:") === -1) {
                        objFile.data = "data:"+objFile.type+";base64,"+objFile.data;
                    }
                    if (mtlFile !== undefined) {
                        mtlFile.data = (mtlFile.data !== undefined) ? "data:"+mtlFile.type+";base64,"+mtlFile.data : undefined;
                    }
                    if (textureFiles.length > 0) {
                        textureFiles.forEach(element => {
                            if (element.data.indexOf("data:") === -1) {
                                element.data = "data:"+element.type+";base64,"+element.data;
                            }
                        });
                    }                    
                }
                //console.log("isPrimary: ", this.state.showHideMaxDivControl);
                const { sceneManager } = threeEntryPoint({
                    videoElement: paramElemVdoChild,
                    id: 'canavas-3d-container',
                    fnSend3dRotation: (this.state.showHideMaxDivControl) ? this.send3dRotation : () => { },
                    fnClose3dCanvas: (this.state.showHideMaxDivControl) ? (e) => this.onCloseFileViewer(e) : () => { },
                    closeIconText
                });
                const fileType = objFile.format;
                sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlFile.data, objFilePath: objFile.data, textureFilePath: map(textureFiles, 'data') })
                    .then(e => {
                        this.setState({ isLoading: false });
                        this.active3dModel = e;
                        if (this.state.rotationArray !== '') {
                            const rotationChanges = JSON.parse(this.state.rotationArray);
                            printConsole("rotating the rendered 3d Model");
                            const { _x, _y, _z, _w } = rotationChanges.quaternion
                            const eulerRotation = new THREE.Quaternion().copy({ x: _x, y: _y, z: _z, w: _w });
                            this.active3dModel && this.active3dModel.quaternion.copy(eulerRotation);
                        }
                    })
                    .catch(err => {
                        this.setState({ isLoading: false });
                        console.warn(err)
                    });
                this.closeSubscriber3dObject = sceneManager.removeCanvas; 
                //printConsole("3D file rendering...");
                //printConsole(this.closeSubscriber3dObject);               
            } else {
                const [file] = assetsArray;
                this.closeTechnician3dObject = null;
                //printConsole(this.closeSubscriber3dObject);
                //const splitedArray = file.name.split('.');
                let fileUrl;
                if (pdfFile) {
                    const contentType = 'application/pdf';
                    let urlObj;
                    if (file.type === undefined) urlObj = "data:application/pdf;base64,"+file.data;
                    else urlObj = "data:"+file.type+";base64,"+file.data;
                    const newFile = await urltoFile(urlObj, file.name+'.'+file.format, contentType);
                    //console.log(newFile);
                    //const blob = new base64StringToBlob(urlObj, contentType);// MB2-556
                    fileUrl = URL.createObjectURL(newFile);
                    printConsole(fileUrl);
                }
                this.setState({
                    showFileViewer: true,
                    fileType: file.format,
                    filePath: (file.format === 'pdf') ? fileUrl : "data:"+file.type+";base64,"+file.data,
                    fileName: file.name+'.'+file.format,
                    //expertLoggedInId: file.loggedInUserId,
                    isLoading: false
                });
                if (audioFile) this.setState({ showAudioFileViewer: true });
            }

        } catch (error) {
            let msg = 'Something went wrong while uploading file.';
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false,  error: msg}); 
        }
    }

    remove3dObject = () => {
        this.setState({
            showFileViewer: false,
            fileType: 'none',
            filePath: '',
            fileName: '',
            showAudioFileViewer: false
        });

        this.state.URLs.forEach(url => {
            if (url) window.URL.revokeObjectURL(url);
        });

        const [closeIcon, canvas] = document.getElementsByClassName('asset-3d');
        window.removeEventListener('mousemove', function () { });
        window.removeEventListener('mouseup', function () { });
        window.removeEventListener('mousedown', function () { });
        /** remove both element from dom */
        if (!canvas) return;
        closeIcon && closeIcon.parentNode && closeIcon.parentNode.removeChild(closeIcon);
        canvas.parentNode.removeChild(canvas);
    };

    hideRequestDialog = () => {
        this.setState({
            showControlRequestDialog: false,
            isOngoingMaxDivFeatures: false,
            requestDialogTimer: 0
        }, () => {
            clearInterval(this.transferControlTimer);
        });
    }

    decrementCountr = () => {
        this.setState((prevState) => ({
            requestDialogTimer: prevState.requestDialogTimer - 1
        }), () => {
            if (this.state.requestDialogTimer === 0) {
                this.onClickAcceptMaxDivControl();
            }
        });
    }

    closeMikeDialog = () =>{
        this.setState({openMikeDialog: false});
    }

    closeStreamDestroyedDialog = () => {
        this.setState({
            openStreamDestroyedDialog: false,
            imageCaptureTimer: 0
        },() => {
            clearInterval(this.streamTimer);
        });
    }
    
    decrementCounter = () => {
        this.setState((prevState) =>({
            imageCaptureTimer: prevState.imageCaptureTimer - 1
        }),() => {
            if(this.state.imageCaptureTimer === 0){
                this.closeStreamDestroyedDialog();
            }
        });
    }

    closeCameraSwapDialog = () => {
        this.setState({
            openCameraSwapDialog: false,
            cameraSwapTimer: 0
        },() => {
            clearInterval(this.streamTimer);
            this.props.updateRCStatus(false);
            this.props.updateCameraSwapStatus(false);
        });
    }

    decrementCntr = () => {
        this.setState((prevState) =>({
            cameraSwapTimer: prevState.cameraSwapTimer - 1
        }),() => {
            if(this.state.cameraSwapTimer === 0){
                this.closeCameraSwapDialog();
            }
        });
    }

    // mute/unmute mike modal
    muteMikeForTechDialog = (openMikeDialog) =>{
        return (
            <div>
                <Modal isOpen={this.state.openMikeDialog} toggle={this.closeMikeDialog}>
                    <ModalHeader toggle={this.closeMikeDialog} cssModule={{'modal-title': 'w-100 text-center'}}><Trans>Audio Alert</Trans></ModalHeader>
                    <ModalBody>
                        <div>
                            {this.state.maxDivTechName} {this.state.muteMikeForMaxDiv? <Trans>cannot hear you. Do you want to unmute your mike?</Trans>: <Trans>can hear you. Do you want to mute your mike?</Trans>}
                        </div>
                        <div>
                            <Button color="primary" onClick={this.muteUnmuteMaxDiv} className="pull-right">
                                {this.state.muteMikeForMaxDiv? <Trans>Unmute Mike</Trans>: <Trans>Mute Mike</Trans>}
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    // dialog for stream destroyed during Remote Capture
    streamDestroyedDialog = () =>{
        const senderInfoArr = this.state.sRCTechDetails.split(':::');
        return (
            <div>
                <Modal centered={true} backdrop={false} isOpen={this.state.openStreamDestroyedDialog} toggle={this.closeStreamDestroyedDialog}>
                    <ModalHeader className="p-2" toggle={this.closeStreamDestroyedDialog} cssModule={{'modal-title': 'w-100 text-center'}}><Trans>Image Capture Mode</Trans></ModalHeader>
                    <ModalBody>
                        <div>
                            <h3>
                                <Trans>RC Countdown Msg1</Trans>
                                {senderInfoArr[1]}
                                <Trans>RC Countdown Msg2</Trans>
                            </h3>
                        </div>
                        <div className="text-center" style={{fontSize:"40px", fontWeight: "bold"}}>
                            {this.state.imageCaptureTimer}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    // dialog for stream destroyed during camera swap of Max-div technician
    cameraSwapDialog = () => {
        const senderInfoArr = this.state.swapCamTechData.split(':::');
        return (
            <div>
                <Modal centered={true} backdrop={false} isOpen={this.state.openCameraSwapDialog} toggle={this.closeCameraSwapDialog}>
                    <ModalHeader className="p-2" toggle={this.closeCameraSwapDialog} cssModule={{'modal-title': 'w-100 text-center'}}><Trans>Camera Swap Mode</Trans></ModalHeader>
                    <ModalBody>
                        <div>
                            <h3>
                                {senderInfoArr[1]+" "+senderInfoArr[2]}
                                <Trans>Camera Swap Countdown Msg</Trans>
                            </h3>
                        </div>
                        <div className="text-center" style={{fontSize:"40px", fontWeight: "bold"}}>
                            {this.state.cameraSwapTimer}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    /** Request Max-div controls dialog box */
    requestMaxDivControlDialog = () => {
        const isMobileDevice = window.innerHeight <= 420 ? true : false; //TP-2966
        // NS2-128 
        // changed the toggle function from this.hideRequestDialog to this.onClickDenyMaxDivControl
        // This is to ensure that even when clicked outside the Dialog box, the deny signal is sent
        // to the Expert who requested for Maxdiv Controls
        /**TP-2966 */
        if (isMobileDevice === true) {
            return (
                <>
                    <Modal id="request-dialog" size="sm" /**TP-2966 */ contentClassName={isMobileDevice === true ? "custom-modal-style": ""} isOpen={this.state.showControlRequestDialog} toggle={this.onClickDenyMaxDivControl}>
                        <ModalHeader toggle={this.onClickDenyMaxDivControl} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Control Request</Trans></ModalHeader>
                        <ModalBody className="d-flex justify-content-start align-items-center row">
                            <div className="col-sm-12">
                                {this.props.user.isexpert ?
                                    <Trans id="Maxdiv Control" values={{ 'firstName': `${this.state.expertFirstName}`, 'lastName': `${this.state.expertLastName}` }}></Trans>
                                    : ''
                                }
                            </div>
                            <div className='row' style={{ width: '100%'}}>
                                <div className="col-sm-6 align-self-center" >
                                    <span style={{ fontSize: "30px", fontWeight: "bold", paddingLeft: "20px" }}>
                                        {this.state.requestDialogTimer}
                                    </span>
                                </div>
                                <div className="col-sm-6">
                                    <div className='row'>
                                        <span className='col-sm-7'><Button size="sm" className="btn-r" style={{marginLeft: "86%"}} onClick={this.onClickDenyMaxDivControl}><Trans>No</Trans></Button></span>
                                        <span className='col-sm-4'><Button size="sm" className=" btn-g" onClick={this.onClickAcceptMaxDivControl}><Trans>Yes</Trans></Button></span>
                                    </div>
                                </div>
                            </div>
    
                            <audio autoPlay="true" loop="true" src="/beep.mp3" />
                        </ModalBody>
                    </Modal>
                </>
            );
        } else {
            return (
                <>
                    <Modal id="request-dialog" size="sm" /**TP-2966 */ contentClassName={isMobileDevice === true ? "custom-modal-style": ""} isOpen={this.state.showControlRequestDialog} toggle={this.onClickDenyMaxDivControl}>
                        <ModalHeader toggle={this.onClickDenyMaxDivControl} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Control Request</Trans></ModalHeader>
                        <ModalBody className="d-flex justify-content-start align-items-center row">
                            <div className="col-sm-12">
                                {this.props.user.isexpert ?
                                    <Trans id="Maxdiv Control" values={{ 'firstName': `${this.state.expertFirstName}`, 'lastName': `${this.state.expertLastName}` }}></Trans>
                                    : ''
                                }
                            </div>
                            <div className="col-md-6 align-self-center" style={{ fontSize: "40px", fontWeight: "bold" }}>
                                {this.state.requestDialogTimer}
                            </div>
    
                            <audio autoPlay="true" loop="true" src="/beep.mp3" />
                        </ModalBody>
                        <ModalFooter>
                            <div className="d-flex justify-content-end">
                                <Button size="sm" className="mr-1 btn-r" onClick={this.onClickDenyMaxDivControl}><Trans>No</Trans></Button>
                                <Button size="sm" className=" btn-g" onClick={this.onClickAcceptMaxDivControl}><Trans>Yes</Trans></Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                </>
            );
        }
    }

    subscribeToOnlineNotification = (userNames) => {    
        //printConsole(`This user came online -----> ${userNames}`);
        this.setOnlineUsers(userNames);            
    }

    checkUserOffline = (userName) => {
        printConsole(`This user has gone offline ====> ${userName}`)
        const {g_subInfoArr, bRemoteCapture} = this.state;
        //printConsole(bRemoteCapture);
        printConsole(g_subInfoArr.length);       
        if (bRemoteCapture && g_subInfoArr.length === 0) {
            this.setState({ isLoading: false });
            this.triggerStopSession();
        } else if (g_subInfoArr.length === 0){
            //this.props.onSessionCmdChange('session_stopped');        
            //this.closeExpertWindow();
            this.triggerStopSession();
        } else if (g_subInfoArr.length > 0) {
            for (let i=0; i<g_subInfoArr.length; i++) {
                let dataSplit = g_subInfoArr[i].g_stream.connection.data.split(":::");
                if (dataSplit[3] === userName && g_subInfoArr[i].g_stream.videoType !== "screen") {
                    this.streamDisconnected(i, "user went offline"); 
                    if (g_subInfoArr.length === 0){
                        //printConsole("Here");
                        //this.props.onSessionCmdChange('session_stopped');  
                        //this.closeExpertWindow();
                        this.triggerStopSession();
                    }
                }
                                  
            }
            // Handling of online users list when a user goes offline            
            const { onlineList } = this.state;
            if (onlineList !== undefined) {
                this.setState((prev) => ({
                    onlineList: prev.onlineList.filter(e => e.email !== userName)
                }))
            }
            
        }
    }

    // response to the call by users while session is ongoing
    subscribeToRespondToTheCall = ({ action, groupName, groupId, recipient, userName }) => {
        //if (!this.isMount) return;
        //printConsole(`response-to-the-call { ${action}, ${groupName}, ${groupId}, ${recipient}, ${userName} }`);
        let {onlineList, selectedUser} = this.state;
        // turn off the call button for selected User
        for (let i=0; i<onlineList.length; i++) {
            if((userName && onlineList[i].email === userName) || onlineList[i] === selectedUser) {
                onlineList[i].isBusy = false;
                // Clear the timer if there is already a timeout.
                if (this.timer) clearTimeout(this.timer);
                if (action === 'accepted') {
                    // Code for when call gets accepted
                } else if(action === 'declined') {
                    let onlineEmails = [];
                    onlineEmails.push(onlineList[i].email); 
                    this.authService.socket.emit('send-missed-call-notification', { 
                        emails: onlineEmails
                    });                    
                } else if (action === 'in_session') {
                    // Code for when the call is already ongoing
                } else if (action === 'no_group_found') {
                    // Code for when call (group_id) is not found
                }
                break;
            } 
        }
        this.setState({
            onlineList,
            selectedUser: {}
        });
    }
    

    subscribeToUserIsInSession = ({ isInSession, userName, caller }) => {
        //console.log("user-is-in-session-inside-sessions", isInSession, userName, caller);
        let { g_subInfoArr, onlineList } = this.state;
        let { usersInSession } = this.props;//TP-1599
        //const { maxDivTechType } = this.state;
        //printConsole(userName);
        
        if(isInSession) {
            if (userName) usersInSession.push(userName);   
            this.props.setUsersInSession(usersInSession); //TP-1599
            if (g_subInfoArr.length === 0)
                this.setState({ isLoading: true }); 
            if (onlineList.length > 0) {
                let [tmp] = onlineList.filter (o => o.email === userName);
                if (tmp !== undefined) {
                    let index = onlineList.indexOf(tmp);
                    //printConsole("Seems user is in another call");
                    onlineList[index].isBusy = true;
                }
            }           
        } else { 
            //printConsole("User went out of the call");
            if (userName) usersInSession = usersInSession.filter(e => ![userName].includes(e)); 
            this.props.setUsersInSession(usersInSession); //TP-1599
            this.closeCameraSwapDialog();
            this.setState({ isLoading: false });  
            if (g_subInfoArr.length > 0) {
                for (let i=0; i<g_subInfoArr.length; i++) {
                    let dataSplit = g_subInfoArr[i].g_stream.connection.data.split(":::");
                    if (dataSplit[3] === userName && g_subInfoArr[i].g_stream.videoType !== "screen") 
                        this.streamDisconnected(i, "user is in session False");
                }
            }
            if (onlineList.length > 0) {
                let [tmp] = onlineList.filter (o => o.email === userName);
                if (tmp !== undefined) {
                    let index = onlineList.indexOf(tmp);
                    if (onlineList[index].isBusy === true) {
                        //printConsole("Seems busy user has comeback..");
                        onlineList[index].isBusy = false;
                        /* let onlineEmails = [];
                        onlineEmails.push(onlineList[index].email); 
                        this.authService.socket.emit('send-missed-call-notification', { 
                            emails: onlineEmails
                        }); */
                    }
                }
            }
        }
        this.setState({onlineList});
    }   

    subscribeToGroupSignal = (json) => {
        let { selectedGroupId } = this.props;
        if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
        if (json.groupId === selectedGroupId) {
            printConsole("*************Receiving Signal on Expert side **********");
            printConsole(json);

            /* diagonastics(this.authService, {
                action: `websocket signal received in the Expert side`,
                next_step: 'the signal will be processed',
                sequence: 25,
                data: json,
                error: 'none'
            }) */

            switch (json.type) {
                case "MAX_DIV_SHOW_BUTTONS":
                    this.processSignalMaxDivShowButtons(json);
                    break;
                case "IM":
                    if (typeof json.data === 'string')
                        this.processSignalIM(json);
                    else
                        this.processSignalUserInfo(json);
                    break;
                //T32-413
                case 'MUTE_USER_AUDIO': 
                    this.processSignalMuteRemoteUserAudio(json);
                    break;
                case "NOSUPPORTFORREMOTECAPTURE":
                    this.processSignalNoSupportRC(json);
                    break;
                case "REMOTECLICKUPLOADED":
                    this.processSignalRemoteClickUploaded(json);
                    break;
                case "REMOTECLICKDATA":
                    this.timer = setTimeout(()=> {
                        this.processSignalRemoteClickData(json);
                        clearTimeout(this.timer);
                    }, fileTransferTimeout);                     
                    break;
                case "START_LOCAL_RC":
                    this.processSignalStartLocalRC(json);
                    break;
                case "LOCAL_TORCH":
                    this.processSignalLocalTorch(json);
                    break;
                case "LOCAL_ZOOM_LEVEL":
                    this.processSignalLocalZoomLevel(json);
                    break;
                case "CANCEL_TECHNICIAN_SS":
                    this.processSignalCancelTechniciaSS(json);
                    break;
                case "MUTE_EXPERT_AUDIO":
                    this.processSignalMuteExpertAudio(json);
                    break;
                case "REQUEST_MAXDIV_CONTROL":
                    this.processSignalRequestMaxDivControl(json);
                    break;
                case "SWAP_CAMERA":
                    this.processSignalSwapCamera(json);
                    break;
                case "CONFIRM_SWAP_CAMERA":
                    this.processSignalConfirmSwapCamera(json);
                    break;
                /** For Secondary Experts */
                case "SYNC_EXPERTS":
                    this.processSignalSyncExperts(json);
                    break;
                /* case "CURRENT_PRIMARY_EXPERT":
                    this.processSignalCurrentPrimaryExpert(json);
                    break;
                case "EXPERT_QUERY_MAXDIV":
                    this.processSignalExpertQueryMaxDiv(json);
                    break; */
                case "YIELD_MAXDIV_CONTROL":
                    this.processSignalYieldMaxDivControl(json);
                    break;
                case "YIELD_DISABLE_HAND_ICON":
                    this.processSignalYieldDisableHandIcon(json);
                    break;
                /* case "EXPERT_CHANGE_MAX_DIV":
                    this.processSignalExpertChangeMaxDiv(json);
                    break; */
                case "EXPERT_RC_CLICKED":
                    this.processSignalExpertRCClicked(json)
                    break;
                case "EXPERT_RC_IMAGE_UPLOADED":
                    this.processSignalExpertRCImageUploaded(json);
                    break;
                case "EXPERT_RC_IMAGE_DATA":
                    this.timer = setTimeout(()=> {
                        this.processSignalExpertRCImageUploaded(json);
                        clearTimeout(this.timer);
                    }, fileTransferTimeout);                    
                    break;
                case "ANNOTATE":
                    this.processSignalAnnotate(json);
                    break;
                case "ANNOTATE_MULTI_PART":
                    this.processSignalAnnotateMultiPart(json);
                    break;
                case "REMOTECAPTUREANNOTATE":
                    this.processSignalRCAnnotate(json);
                    break;
                case "ROTATION":
                    this.processSignalRotationForExpert(json);
                    break;
                case "OPACITY":
                    this.processSignalOpacity(json);
                    break;
                case "SHARE_ARTIFACTS":
                    this.timer = setTimeout(()=> {
                        this.processSignalShareArtifactsForExpert(json);
                        clearTimeout(this.timer);
                    }, fileTransferTimeout);                    
                    break;
                case "ARTIFACTS":
                    this.processSignalArtifactsForExpert(json);
                    break;
                case "REMOVE_ARTIFACTS":
                    this.processSignalRemoveArtifactsForExpert(json);
                    break;
                case "PREPARE_EXPERT_SS":
                    this.processSignalPrepareExpertSS(json);
                    break;
                case "START_EXPERT_SS":
                    this.processSignalStartExpertSS(json);
                    break;
                case "STOP_EXPERT_SS":
                    this.processSignalStopExpertSS(json);
                    break;
                case "TURN_ON_MAX_VIEW_USER_VIDEO":
                    this.processSignalTurnOnMaxViewUserVideo(json);    
                    break;    
                default:
                    break; 
            }

        } else {
            printConsole("Ignored groupId..");
        }
    }

    /** Processor Methods for Expert role */
    // These methods are triggered when the different websocket signals are received
    // based on the signal type the methods are named

    //NS2-209
    processSignalMaxDivShowButtons = (json) => {
        //"tech_ss:::expert_ss:::zoom:::torch:::camera_count-2:::type-WEB";
        //console.log(`Show the following buttons for the maxdiv controls -- ${json.data}`);
        //NS2-119
        const { screen_share_mode, torch_enabled, zoom_enabled} = this.props.sessionInfo;
        //console.log("screen_share_mode ",screen_share_mode);
        const { showHideMaxDivControl, g_subInfoArr, userVideoCount} = this.state;
        // When the maxdiv controls div is hidden then just ignore this signal.
        if (showHideMaxDivControl === false) return;
        const data = json.data;
        let dataArr = data.split(":::");
        // This if condition is a combination of NS2-99 & NS2-77 
        if (screen_share_mode !== 'screen_share_disabled' && dataArr.length > 0 && !(this.props.isiOS && this.props.isSafari) && userVideoCount > 0) {
            if (dataArr.includes("tech_ss") && (screen_share_mode === 'screen_share_enabled' || screen_share_mode === 'tech_screen_share_enabled')) {
                this.setState({ bHideTechnicianSSButton: false });
                this.props.showScreenShareHeaderButton(false); // for T32-74
            } else this.setState({ bHideTechnicianSSButton: true });
            if (dataArr.includes("expert_ss") && (screen_share_mode === 'screen_share_enabled' || screen_share_mode === 'expert_screen_share_enabled')) {
                this.setState({ bHideExpertSSButton: false });
                this.props.showScreenShareHeaderButton(false); // for NS2-282
            } else this.setState({ bHideExpertSSButton: true });
        } else if(userVideoCount > 0) {
            //To enable the Side Bar File Share Icon for Prepaid accounts/Safari Browsers
            this.props.showScreenShareHeaderButton(false); // for T32-74
        }
        // If statement for only the zoom, torch buttons and the cameraCount of the Max-view technician
        if (dataArr.length > 0) {
            if (dataArr.includes("zoom") && zoom_enabled === true && userVideoCount > 0)
                this.setState({ bHideZoomButton: false }, () => {
                    // Setting the Max-view Zoom level with the corresponding user's ZoomLevel
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId){
                            //For the Max-view technician
                            this.setState({ zoom_level: subInfo.zoomLevel.toString(), bZoomClick: (subInfo.zoomLevel > 1)? true: false }, () => {
                                const zoomSelector = document.getElementById("zoomlevelselector");
                                if (this.state.bZoomClick && zoomSelector !== null && !this.state.bRemoteCapture) {
                                    zoomSelector.style.display = 'block';
                                } else if (zoomSelector !== null) {
                                    zoomSelector.style.display = 'none';
                                }
                                if (this.state.hideButton === "d-none" && window.innerHeight <= 420) {
                                    //TP-2933
                                    let zoomLevel = document.getElementById("zoomLevel_" + subInfo.g_stream.streamId);
                                    if (zoomLevel) {
                                        let flag = this.state.zoom_level;
                                        if (flag === 1){
                                            // Change the zoom level text on top of Video element
                                            zoomLevel.className = 'zoom-text';
                                            zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                            zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                            zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                                                        
                                        } else {
                                            // Change the zoom level text on top of Video element                    
                                            zoomLevel.className = 'zoom-text';
                                            zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                            zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                            zoomLevel.parentElement.style.display = "block";
                                        }
                                    }
                                }
                            })
                        }
                    })

                });
            else this.setState({ bHideZoomButton: true });
            if (dataArr.includes("torch") && torch_enabled === true && userVideoCount > 0)
                this.setState({ bHideTorchButton: false }, () => {
                    // Setting the Max-view Torch Icon state with the corresponding user's TorchActive
                    g_subInfoArr.forEach(subInfo => {
                        if (subInfo.g_divId === g_maxDivId){
                            //For the Max-view technician
                            this.setState({ bTorchClick: subInfo.torchActive })
                        }
                    })
                });
            else this.setState({ bHideTorchButton: true });
            const ind = dataArr.findIndex((dt) => {
                return dt.includes("camera_count-");
            });
            const i = dataArr.findIndex((dt) => {
                return dt.includes("type-");
            });
            //console.log(i);
            if (ind > -1) {                
                this.setState({ maxDivCameraCount: parseInt(dataArr[ind].split("-")[1])})
            } else 
                this.setState({ maxDivCameraCount: 1 });
            if (i > -1) {
                this.setState({ maxDivTechType: dataArr[i].split("-")[1] });
            }
        } else {
            //console.log("Don't show any of new the maxdiv buttons..")                    
            this.setState({
                bHideExpertSSButton: true,
                bHideTechnicianSSButton: true,
                bHideZoomButton: true,
                bHideTorchButton: true,
                maxDivCameraCount: 1,
                maxDivTechType: "WEB"
            })
        }
    }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
    processSignalIM = (json) => {
        // Process the event.data property, if there is any data.
        if (json.data.techtype !== undefined) {
            this.processSignalUserInfo(json);
            return;
        }
        const {g_subInfoArr} = this.state;
        let strNewMsg = json.data;
        let messageID = json.messageID; //MB2-95
        let bIsDuplicate = false;
        let { from } = json;
        if (this.state.gridViewMode === false && this.state.prepareExpertSS === false) {
            this.checkChatUser(from, g_subInfoArr, g_maxDivId);
        }

        this.state.chatMsgInfoArr.forEach(msgInfo => {
            /* if (strNewMsg === msgInfo.message)
                bIsDuplicate = true;
            else */ if (messageID === msgInfo.messageID) //MB2-95
                bIsDuplicate = true;
        })
        if (bIsDuplicate === false) {
            g_subInfoArr.forEach(subInfo => {
                const subInforArr = subInfo.g_stream.connection.data.split(":::");
                if (subInforArr[3] === json.from.email && subInfo.g_stream.videoType !== "screen") {
                    //TP-2222 -- Handled this use case only for Android/iOS devices
                    if (/* window.innerWidth < 1300 || */ this.state.hideButton === "d-none") {
                        //let chatMsg = json.from.firstName + " " + json.from.lastName + " says: \n" +  strNewMsg;
                        let chatMsg = "{name} says: {text}";
                        let values = {name: json.from.firstName + " " + json.from.lastName, text: strNewMsg}
                        this.setState({ flashMsgText: chatMsg, flashValues: values, showFlashMessage: true, flashLeft: "", flashTop: "" });

                    }  else {
                        let timeStamp = this.props.getTimeStamp();
                        let senderInfo = json.from;
                        let chatMsgInfo = {
                            msgId: subInfo.g_divId, messageID: messageID, firstName: senderInfo.firstName,
                            lastName: senderInfo.lastName, message: strNewMsg, timeStamp: timeStamp, msgType: 0
                        };
    
                        this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}))
                        //this.state.chatMsgInfoArr.push(chatMsgInfo);
                        if (this.state.gridViewMode === true) {
                            this.onClickChatForAll(true);
                            this.props.updateChatAll(true);
                        } else {
                            if (this.state.bAllChat !== true && (subInfo.g_divId === g_maxDivId)) {
                                this.setState({ bMaxChat: true });
                                if (this.state.bAnnotate || this.state.bRemoteCapture || (this.state.showHideMaxDivControl === false && document.getElementById('canvas') !== null)) {
                                    this.setState({ startChatTimer: true });
                                }
                            } else if (this.state.bAllChat !== true && subInfo.g_divId !== g_maxDivId && (this.state.bAnnotate || this.state.bRemoteCapture || (this.state.showHideMaxDivControl === false && document.getElementById('canvas') !== null))) {
                                this.props.updateChatAll(true);
                                if (this.state.bMaxChat === true) this.setState({ bMaxChat: false });
                                this.setState({ 
                                    bAllChat: true, 
                                    startChatTimer: true, 
                                    chatUserEmails: [],
                                    chatUserNames: []  
                                }, () => {
                                    this.setChatJoinees(this.state.chatUserNames);
                                });                            
                            } else if (this.state.bAllChat !== true && subInfo.g_divId !== g_maxDivId && this.state.prepareExpertSS === true){
                                this.onClickChatForAll(true);
                                this.props.updateChatAll(true);
                            } else if (this.state.bAllChat !== true && (from.isexpert === true || from.isExpert === true)) {
                                this.onClickChatForAll(true);
                                this.props.updateChatAll(true);
                            }
                            else if (this.state.bAllChat !== true)
                                this.incrementAllChatBadge(); // chat from a stream that is minimized and chatall is not open
                        }
                        
                        this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
                    }
                    return;
                }
            })
        }
    }

    // TP-207
    processSignalUserInfo = (json) => {
        printConsole("IM signal received with JSON data");
        const {data, from} =  json;
        let {g_subInfoArr} = this.state;
        const { torch_enabled, zoom_enabled } = this.props.sessionInfo;
        //TP-263
        const userObj = {
            data,
            id: json.from.userId
        }
        let userInfoArray = this.state.userInfoArr;
        userInfoArray.push(userObj);
        this.setState({ userInfoArr: userInfoArray });
        
        g_subInfoArr.forEach(subInfo => {
            const dataArr = subInfo.g_stream.connection.data.split(":::");
            if (from.email === dataArr[3]) {
                printConsole(`Technician is in session ${from.email}`);
                subInfo.zoom_enabled = (data.zoom_enabled && zoom_enabled) ? data.zoom_enabled : false;
                subInfo.torch_enabled = (data.torch_enabled && torch_enabled) ? data.torch_enabled : false;
                subInfo.screenshare_enabled = (data.screenshare_enabled) ? data.screenshare_enabled : true;
                subInfo.techSS_enabled = (data.techSS_enabled) ? data.techSS_enabled : false; 
            }
        })

        this.setState({g_subInfoArr}, () => {
            if (this.state.gridViewMode === true) {
                this.drawSubscriberUI(window.innerHeight, window.innerWidth, null);
            }
        })
    }

    processSignalNoSupportRC = (json) => {
        g_subInfoArr.forEach(subInfo => {
            if (subInfo.g_stream.connection.data === json.from) {
                subInfo.canRC = false;
            }
        })
    }

    processSignalRemoteClickUploaded = (json) => {
        // Process the json.data property, if there is any data.
        if (this.state.strFileName === '') {
            //console.log(json.from)
            //console.log(g_maxDivId);  
            const {g_session, g_subInfoArr} = this.state;                                           
            let strFileName = json.data;

            /* diagonastics(this.authService,{
                action: `REMOTECLICKUPLOADED signal recieved`,
                next_step: '',
                data: strFileName,
                error: 'none'
            }) */
            this.setState({ bRCAnnotateOn: false }); // NS2-252
            this.setState({ strFileName: strFileName }); // NS2-252
            if (strFileName.length <= 0) {
                printConsole('remoteclickuploaded came back with empty filename');
                // close remote click mode
                //NS2-244
                this.setState({ isLoading: false });
                this.onClickCamera();
                return;
            }

            // inform other passive experts that the RC image is now ready to be downloaded
            // for NS2-345
            if (this.state.showHideMaxDivControl) {
                g_expertConnsArr.forEach(connectionObj => {

                    if (g_session.connection !== connectionObj) {
                        const toArray = connectionObj.data.split(":::");
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalExpertRCUploaded(

                            //from: g_session.connection.data,
                            strFileName,
                            toObject
                            //type: "EXPERT_RC_IMAGE_DATA"

                        );
                        //console.log('signal sent = ', 'EXPERT_RC_IMAGE_DATA');
                    }
                });
            }
            g_subInfoArr.forEach(subInfo => {
                const toArray = subInfo.g_stream.connection.data.split(":::");
                if (toArray[3] === json.from.email && subInfo.g_divId === g_maxDivId && this.state.bRemoteCapture === true) {
                    this.processRemoteClickImage(document.getElementById(subInfo.g_divId), strFileName);
                    return;
                }
            })
        } else {
            printConsole("RC image has been uploaded already!");
        }
    }

    processSignalRemoteClickData = (json) => {
        // Process the json.data property, if there is any data.
        if (this.state.strFileName === '' && this.state.showHideMaxDivControl === true) {
            //printConsole(json)
            //console.log(g_maxDivId);  
            const {g_session, g_subInfoArr} = this.state;                                           
            let strFileName = json.assetData.data;
            const { format }= json.assetData;

            /* diagonastics(this.authService,{
                action: `REMOTECLICKUPLOADED signal recieved`,
                next_step: '',
                data: strFileName,
                error: 'none'
            }) */
            this.setState({ bRCAnnotateOn: false }); // NS2-252
            this.setState({ strFileName, format }); // NS2-252
            if (strFileName === "" || strFileName === null) {
                printConsole('remoteclickuploaded came back with empty filename');
                // close remote click mode
                //NS2-244
                this.setState({ isLoading: false });
                this.onClickCamera();
                return;
            }

            // inform other passive experts that the RC image is now ready to be downloaded
            // for NS2-345
            if (this.state.showHideMaxDivControl) {
                g_subInfoArr.forEach(subInfo => {
                    // TP-1372 -- Check if the image data received from the technician 
                    // is currently the Max-view tech or not
                    if (subInfo.g_divId === g_maxDivId) {
                        const connArr = subInfo.g_stream.connection.data.split(":::");
                        if (json.from.email === connArr[3]) {
                            g_expertConnsArr.forEach(connectionObj => {            
                                if (g_session.connection !== connectionObj) {
                                    const toArray = connectionObj.data.split(":::");
                                    const toObject = toArray[3];
                                    this.sessionSignal.sendSignalExpertRCUploaded(
            
                                        //from: g_session.connection.data,
                                        strFileName,
                                        toObject,
                                        format
                                        //type: "EXPERT_RC_IMAGE_DATA"
            
                                    );
                                    //console.log('signal sent = ', 'EXPERT_RC_IMAGE_DATA');
                                }
                            });
                        } else {
                            // TP-1372 -- To take care of the scenario when Max-view user gets disconnected before the image has been received
                            printConsole ("Current Max-view user is not the same user for whom the RC image data is received by Primary expert...");
                            this.stopExpertRCAnnotate();
                        }
                    }
                })
            }
            g_subInfoArr.forEach(subInfo => {
                const toArray = subInfo.g_stream.connection.data.split(":::");
                if (toArray[3] === json.from.email && subInfo.g_divId === g_maxDivId && this.state.bRemoteCapture === true) {
                    this.processRemoteClickImageNew(document.getElementById(subInfo.g_divId), strFileName, format);
                    return;
                }
            })
        } else {
            printConsole("RC image has been uploaded already!");
        }
    }

    processSignalStartLocalRC = (json) => {
        if (this.state.showHideMaxDivControl === true) {
            //const data = JSON.parse(json.data);
            //printConsole(json.data);
            if (json.data === true) {
                //NS2-204 
                g_subInfoArr.forEach(subInfo => {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    if (toArray[3] === json.from.email && subInfo.g_divId === g_maxDivId) {
                        if (this.state.bRemoteCapture === false) {
                            this.onClickCamera()
                        }
                        return;
                    }
                });
            } else if (json.data === false) {
                //NS2-204 
                if (g_subInfoArr.length > 0) {
                    g_subInfoArr.forEach(subInfo => {
                        const toArray = subInfo.g_stream.connection.data.split(":::");
                        if (toArray[3] === json.from.email && subInfo.g_divId === g_maxDivId) {
                            //printConsole("Is Maxdiv technician")
                            if (this.state.bRemoteCapture === true) {
                                //printConsole("RC is on..");
                                this.onClickCamera()
                            }
                            return;
                        } else if (subInfo.g_divId === g_maxDivId) {
                            //printConsole("Some other Maxdiv technician is in session")
                            if (this.state.bRemoteCapture === true) {
                                //printConsole("RC is on..");
                                this.onClickCamera()
                                if (this.state.isLoading === true) this.setState({ isLoading: false });
                            }
                            return;
                        }
                    });
                } else {
                    //printConsole("Since the subcriber array is empty..");
                    if (this.state.bRemoteCapture === true) {
                        //printConsole("RC is on..");
                        this.onClickCamera();
                        if (this.state.isLoading === true) this.setState({ isLoading: false });
                    }
                }
            } else {
                // NS2-285
                this.onClickCamera();
            }
        } else {
            printConsole("Passive expert is receiving Local RC signal!!");
        }
    }

    // NS2-216
    processSignalLocalTorch = (json) => {
        if (this.state.showHideMaxDivControl === true) {
            const {data, from} = json;
            let {g_subInfoArr} = this.state;
            if (this.state.gridViewMode === false) {
                //if ((data === true && this.state.bTorchClick === false) || (data === false && this.state.bTorchClick === true)) {
                    g_subInfoArr.forEach(subInfo => {
                        const dataArr = subInfo.g_stream.connection.data.split(":::");
                        if (g_maxDivId === subInfo.g_divId && from.email === dataArr[3]){
                            // The technician that sent this signal is currently on Max-view
                            this.setState({ bTorchClick: data }) // Set the Max-view Torch Icon                
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                torch_button_active: data,
                                zoom_button_active: this.state.bZoomClick,
                                zoom_level: parseInt(this.state.zoom_level),
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"},  
                            null);
                            subInfo.torchActive = data;
                        }
                        else if (g_maxDivId !== subInfo.g_divId && from.email === dataArr[3]) {
                            // The technician that sent this signal is currently NOT on the Max-view
                            this.sendSignalSyncExperts({
                                show_grid: false, 
                                email: dataArr[3], 
                                torch_button_active: data,
                                zoom_level: subInfo.zoomLevel,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "update_action"},  
                            null);
                            subInfo.torchActive = data;
                        }
                    });
                this.setState({ g_subInfoArr });
                //}
            } else {
                g_subInfoArr.forEach(subInfo => {
                    const dataArr = subInfo.g_stream.connection.data.split(":::");
                    if (dataArr[3] === from.email) {
                        this.sendSignalSyncExperts({
                            show_grid: true, 
                            email: dataArr[3],                                    
                            torch_button_active: data,
                            zoom_level: subInfo.zoomLevel,
                            hand_icon_enabled: true,
                            reason: "update_action"},  
                        null);
                        subInfo.torchActive = data;

                        let toggleBtndiv = document.getElementById('toggleTorch_'+subInfo.g_stream.streamId)

                        if (data === true){
                            //change the Torch icon on top of video element                            
                            if (toggleBtndiv !== undefined && toggleBtndiv !== null) {
                                let toggleIcon = toggleBtndiv.getElementsByClassName("grid-icon");
                                toggleIcon[0].style.color = "red"
                            }
                        } else {
                            //change the Torch icon on top of video element                            
                            if (toggleBtndiv !== undefined && toggleBtndiv !== null) {
                                let toggleIcon = toggleBtndiv.getElementsByClassName("grid-icon");                                    
                                toggleIcon[0].style.color = "black"
                            }
                        }
                    }
                })
                this.setState({ g_subInfoArr });
            }
        } else {
            printConsole("Passive expert is receiving Local Torch signal!!");
        }
    }

    // NS2-216
    processSignalLocalZoomLevel = (json) => {
        if (this.state.showHideMaxDivControl === true) {
            const data = json.data.toString();
            const {from} = json;
            let { g_subInfoArr } = this.state;            
                if (this.state.gridViewMode === false) {
                    g_subInfoArr.forEach(subInfo => {
                        const dataArr = subInfo.g_stream.connection.data.split(":::");
                        if (g_maxDivId === subInfo.g_divId && from.email === dataArr[3]){
                            // The technician that sent this signal is currently on Max-view
                            this.setState({ zoom_level: data }, () => {
                                if (this.state.bZoomClick === false && data !== "1")
                                    this.onClickZoomIcon();
                                else if (this.state.bZoomClick === true && data === "1") {
                                    const zoomSelector = document.getElementById('zoomlevelselector');
                                    this.setState({ bZoomClick: false });
                                    if (zoomSelector !== undefined && zoomSelector !== null) 
                                        zoomSelector.style.display = 'none';
                                }
                                if (this.state.hideButton === "d-none" && window.innerHeight <= 420) {
                                    //TP-2933
                                    let zoomLevel = document.getElementById("zoomLevel_" + subInfo.g_stream.streamId);
                                    if (zoomLevel) {
                                        let flag = this.state.zoom_level;
                                        if (flag === 1){
                                            // Change the zoom level text on top of Video element
                                            zoomLevel.className = 'zoom-text';
                                            zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                            zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                            zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                                                        
                                        } else {
                                            // Change the zoom level text on top of Video element                    
                                            zoomLevel.className = 'zoom-text';
                                            zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                            zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                            zoomLevel.parentElement.style.display = "block";
                                        }
                                    }
                                }
                            })
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                zoom_button_active: parseInt(data) > 1 ? true : false,
                                zoom_level: parseInt(data),
                                torch_button_active: this.state.bTorchClick,
                                hand_icon_enabled: true,
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"},  
                            null);
                            subInfo.zoomLevel = parseInt(data);
                        } else if (g_maxDivId !== subInfo.g_divId && from.email === dataArr[3]) {
                            // The technician that sent this signal is currently NOT on the Max-view
                            this.sendSignalSyncExperts({
                                show_grid: false, 
                                email: dataArr[3], 
                                torch_button_active: this.state.bTorchClick,
                                zoom_level: parseInt(data),
                                hand_icon_enabled: true,
                                reason: "update_action"},  
                            null);
                            subInfo.zoomLevel = parseInt(data);
                        }
                    });
                } else {
                    g_subInfoArr.forEach(subInfo => {
                        const dataArr = subInfo.g_stream.connection.data.split(":::");
                        if (dataArr[3] === from.email) {
                            this.sendSignalSyncExperts({
                                show_grid: true, 
                                email: dataArr[3],                                    
                                torch_button_active: subInfo.torchActive,
                                zoom_level: parseInt(data),
                                hand_icon_enabled: true,
                                reason: "update_action"},  
                            null);
                            subInfo.zoomLevel = parseInt(data);

                            let zoomBtndiv = document.getElementById('rotateZoom_'+subInfo.g_stream.streamId)

                            if (subInfo.zoomLevel === 1){
                                //change the zoom icons on top of video element                            
                                if (zoomBtndiv !== undefined && zoomBtndiv !== null) {
                                        let zoomBtn = zoomBtndiv.getElementsByClassName("grid-icon");
                                        //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                        // Add the new changed Zoom level Icon
                                        /* let zoomIcon = document.createElement('i');
                                        zoomIcon.classList.add('fas', 'fa-binoculars', 'fa-stack-1x', 'grid-icon');
                                        zoomBtndiv.appendChild(zoomIcon); */
                                        zoomBtn[0].style.color = "black";

                                        // Change the zoom level text on top of Video element
                                        let zoomLevel = document.getElementById("zoomLevel_" + subInfo.g_stream.streamId);
                                        zoomLevel.className = 'zoom-text';
                                        zoomLevel.classList.add(zoomClassArray[subInfo.zoomLevel-1].class);
                                        zoomLevel.innerHTML = processZoomLevels(subInfo.zoomLevel + "X", this.props.i18n);
                                        zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                                }
                            } else {
                                //change the zoom icons on top of video element                            
                                if (zoomBtndiv !== undefined && zoomBtndiv !== null) {
                                        let zoomBtn = zoomBtndiv.getElementsByClassName("grid-icon");
                                        //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                        // Add the new changed Zoom level Icon
                                        /* let zoomIcon = document.createElement('span');
                                        zoomIcon.classList.add('fa-stack-1x', 'fa-zoom-text', 'grid-icon');
                                        zoomIcon.innerHTML = processZoomLevels(subInfo.zoomLevel + "X", this.props.i18n);
                                        zoomBtndiv.appendChild(zoomIcon); */
                                        zoomBtn[0].style.color = "red";

                                        // Change the zoom level text on top of Video element
                                        let zoomLevel = document.getElementById("zoomLevel_" + subInfo.g_stream.streamId);
                                        zoomLevel.className = 'zoom-text';
                                        zoomLevel.classList.add(zoomClassArray[subInfo.zoomLevel-1].class);
                                        zoomLevel.innerHTML = processZoomLevels(subInfo.zoomLevel + "X", this.props.i18n);
                                        zoomLevel.parentElement.style.display = "block";
                                }
                            }
                        }
                    })
                }
                this.setState({ g_subInfoArr });                
                /* if (this.state.gridViewMode === false && this.state.bZoomClick === false && data !== "1") {
                    this.onClickZoomIcon();
                } else if (this.state.gridViewMode === false && this.state.bZoomClick === true && data === "1") {
                    this.onClickZoomIcon();
                } */
        } else {
            printConsole("Passive expert is receiving Local Zoom signal!!");
        }
    }

    processSignalCancelTechniciaSS = (json) => {
        const {g_subInfoArr} = this.state;
        //console.info('Technician has cancelled the screen share request');
        g_subInfoArr.forEach(subInfo => {
            const toArray = subInfo.g_stream.connection.data.split(":::");
            if (toArray[3] === json.from.email && subInfo.g_divId === g_maxDivId) {
                this.setState({
                    techShareScreen: false,
                    isOngoingMaxDivFeatures: false
                });
                // for NS2-95
                this.props.updateRCStatus(false);
                // for NS2-117
                this.props.updateScreenShareMaxdiv(false);
            }
        });
    }

    // for NS2-103
    processSignalMuteExpertAudio = (json) => {
        const data = json.data;
        //console.log(data);
        //console.log(json.from.email);
        //console.log(json.from);
        //NS2-436
        let {g_subInfoArr} = this.state;
        const muteObject = {
            data,
            id: json.from.userId
        }
        let muteMikeArr = this.state.muteExpertMikeArr;
        muteMikeArr.push(muteObject);
        this.setState({ muteExpertMikeArr: muteMikeArr }, () => {
            //console.log(otExpObj.state.muteExpertMikeArr);
        });
        let toArray;
        //console.log(g_subInfoArr);
        if (g_subInfoArr.length >= 1) {
            g_subInfoArr.forEach(subInfo => {
                toArray = subInfo.g_stream.connection.data.split(":::");
                if (toArray[3] === json.from.email && subInfo.g_stream.videoType !== "screen") {
                    //console.log("Matched!!");
                    if (g_maxDivId === subInfo.g_divId)
                        this.setState({bRemoteMuteDisabled: data});
                    subInfo.disableRemoteAudio = !data;
                    if (!(this.state.mutedAll === true && data === true) && !(subInfo.disableAudio === true && data === true)) 
                        subInfo.g_subscriber.subscribeToAudio(data);
                    //return;

                    //set the changed session Joinee in the array
                    this.updateEachSessionJoinee(subInfo);
                }
            })

            this.setState({g_subInfoArr}, () => {
                this.setState({isParticipantChanged : true}, () => {
                    this.setState({isParticipantChanged : false});
                });// to change the Side Bar button state                    
                const list = g_subInfoArr.filter(p => p.disableRemoteAudio === false);
                let length = 0;
                //printConsole(`list --------------------------- ${list}`)
                if (list !== undefined) {
                    //printConsole(`Length of Users with Video on -- ${list.length}`)
                    length = list.length;
                }
                
                this.setState({ userAudioEnabledCount: length }, () => {
                    if (this.state.userAudioEnabledCount === 0) {
                        this.setState({/* mutedAll: data, */ disabledMutedAll: !data})
                    } else if (this.state.userAudioEnabledCount > 0) {
                        this.setState({/* mutedAll: false, */ disabledMutedAll: false})
                    }
                });

            });
        }
        //subscriberInfo && subscriberInfo.subscribeToAudio(event.data);
    }

    // FOR NS2-342
    processSignalExpertChangeMaxDiv = (data) => {
        const {expertSSMode, bRemoteCapture, bAnnotate} = this.state;
        printConsole(`expertSSMode: ${expertSSMode}, bRemoteCapture: ${bRemoteCapture}, bAnnotate: ${bAnnotate}`);
        if (!expertSSMode && this.state.lastViewObj !== null) this.setState({ lastViewObj: null })// TP-2015
        if (bRemoteCapture) return;
        if (bAnnotate && data.annotation_active) return;//TP-2445
        if (expertSSMode){
            // TP-1452
            const lastViewObj = {
                show_grid: data.show_grid,
                max_view_technician: data.max_view_technician !== undefined ? data.max_view_technician : ''
            }
            this.setState({ lastViewObj });
            return;
        }
        //console.log("Sync the Passive expert view: ", data);
        let {g_subInfoArr} = this.state;
        if (data.show_grid === true && this.state.gridViewMode === false) {
            const gridViewMessage = "Switching to grid view";
            this.gridView();
            this.setState({ flashMsgText: gridViewMessage, showFlashMessage: true, flashLeft: "", flashTop: "" });
        } else if (data.show_grid === false && data.max_view_technician !== "") {
            g_subInfoArr.forEach((subInfo, index) => {
                let connData = subInfo.g_stream.connection.data.split(":::");
                if (connData[3] === data.max_view_technician) { // (connData[3] === json.data && subInfo.g_divId === g_maxDivId && this.state.gridViewMode === true)
                    // NS2-502
                    // add to subscriber array at the begining of the array                    
                    if (index !== 0)
                        g_subInfoArr = array_move(g_subInfoArr, index, 0);
                    this.setState({g_subInfoArr: g_subInfoArr}, () => {                        
                        if (subInfo.g_divId !== g_maxDivId || this.state.gridViewMode === true) {
                            //const maxViewMessage = "Switching to full screen mode " + connData[1] + " " + connData[2];
                            const maxViewMessage = "Switching to full screen mode {name}";
                            const values = {name: connData[1] + " " + connData[2]};
                            this.setState({ flashMsgText: maxViewMessage, flashValues: values, showFlashMessage: true, flashLeft: "", flashTop: "" });
                            this.selectNewDivPassiveExperts(subInfo.g_divId);// for NS2-372
                        }
                    });
                } else {
                    printConsole(`The new Max-view technician is ${data.max_view_technician}`);
                    this.setState({ newMaxViewUser: data.max_view_technician });
                }
            })
        } else {
            return;
        }
    }

    processSignalRequestMaxDivControl = (json) => {
        //console.info(`request for Max Div controls tab enabled is sent by ${json.data.firstName} ${json.data.lastName}`);
        const isBusy = this.isOngoingPrimaryExpertOperationsExceptionGridView(); // NS2-375
        if (this.state.showHideMaxDivControl === true) {            
            if (isBusy === false) {
                this.sendSignalYieldDisableHandIcon(true, `${json.data.firstName} ${json.data.lastName}`, json.from.email);                
                clearInterval(this.transferControlTimer);
                this.setState({
                    showControlRequestDialog: true,
                    isOngoingMaxDivFeatures: true,
                    expertFirstName: json.data.firstName,
                    expertLastName: json.data.lastName,
                    expertEmail: json.from.email,
                    expertUserId: json.from.userId,
                    requestDialogTimer: 45
                }, () => {
                    this.transferControlTimer = setInterval(this.decrementCountr.bind(this), 1000)
                });
            }
            else {
                const data = false;
                this.sendSignalYieldMaxDivControls(data, json.from.email);  // Deny the request for maxdiv controls                      
            }
        } else {
            //console.info("Request for Maxdiv controls can't be processed by Expert who doen't have maxdivcontrols tab!")
        }
    }

    processSignalSwapCamera = (json) => {
        const {data, eventType} = json;
        const {g_subInfoArr} = this.state;
        /* if (data === 0 && this.state.swapTechCamera === false) {
            console.log("Automatic swap the Technician camera started!!");
            this.setState({ swapTechCamera: true });
            this.props.updateRCStatus(true);
        }
        else */ 
        //console.log(this.state.swapTechCamera);
        //console.log(eventType);
        if (data > 0 && this.state.swapTechCamera === false && this.state.showHideMaxDivControl === true) {
            printConsole("Swap the Technician camera for Primary Expert!!");
            this.setState({ swapTechCamera: true, maxDivCameraCount: data, swapTechEmail: json.from.email }, () => {
                // T32-127
                // Storing the last state of the disableVideo flag for the Max-view technician,
                // before the camera is swapped for that user
                //console.log("from email:", json.from.email);
                g_subInfoArr.forEach(subInfo => {
                    let dataArr = subInfo.g_stream.connection.data.split(":::");
                    //console.log(dataArr[3]);
                    if (g_maxDivId === subInfo.g_divId){
                        this.setState({ swapTechDisableVideo: subInfo.disableVideo });
                    } else if (dataArr[3] === json.from.email) {
                        // T32-485
                        this.setState({ swapTechDisableVideo: subInfo.disableVideo });
                    }
                })
                if (this.state.showHideMaxDivControl)
                    this.sendSignalSwapCameraReceived(json.data, eventType, json.from.email); 
            });
            this.props.updateRCStatus(true);
            this.props.updateCameraSwapStatus(true); 

            //TP-902 -- New "SYNC_EXPERTS" signal for Camera Swap state sync among all Passive experts
            this.sendSignalSyncExperts({
                data,
                max_view_technician: json.from.email,
                reason: "swap_camera_sync"},  
            null);

        } /* else if (data > 0 && this.state.swapTechCamera === true) {
            console.log(" Swap camera received signal to be sent to technician ");
            if (this.state.showHideMaxDivControl)
                this.sendSignalSwapCameraReceived(json.data, eventType, json.from.email);
        } */ 
        else if (data === 0 && this.state.swapTechCamera === true && this.state.showHideMaxDivControl === true) {
            this.setState({ swapTechCamera: false, confirmSwapCamera: false, maxDivCameraCount: data, swapTechEmail: '', swapCamTechData: '', swapTechDisableVideo: false });
            this.props.updateRCStatus(false);
            this.props.updateCameraSwapStatus(false);
        } 
    }

    processSignalConfirmSwapCamera = (json) => {
        //printConsole("swapTechCamera: ", this.state.swapTechCamera);
        if (this.state.swapTechCamera === true) {
            const {data, eventType} = json;
            //printConsole("confirmSwapCamera: ", this.state.confirmSwapCamera);
            if (data === true && this.state.showHideMaxDivControl && this.state.confirmSwapCamera === false){
                this.setState({ confirmSwapCamera: true });
                if (eventType === "auto") {
                    //printConsole(eventType);
                    this.waitTimer = setTimeout(() => { 
                        this.handleFeaturesForTech(json.from); 
                        clearTimeout(this.waitTimer);
                    }, 10000);
                    
                } else if (eventType === "click") {
                    //printConsole(eventType);
                    this.handleFeaturesForTech(json.from); 
                    this.props.updateRCStatus(true);
                    this.props.updateCameraSwapStatus(true); 
                    this.sendSignalSwapCameraReceived(json.data, eventType, json.from.email); 
                }
            } else if (data === false) {
                this.setState({ swapTechCamera: data, confirmSwapCamera: false, swapTechEmail: '', swapCamTechData: '', swapTechDisableVideo: false });
                this.props.updateRCStatus(data);
                //printConsole(this.state.swapTechCamera); 
            }
        }
    }

    /* T32-413 -- Disabled Speaker of Remote user implementation */
    processSignalMuteRemoteUserAudio = (json) => {
        try {
            let {g_subInfoArr} = this.state;
            const {data, from} = json;
            // Making the Speaker button for that participant not-clickable/clickable again
            let nLen = g_subInfoArr.length;
            let ii = 0;
            for(ii = 0; ii < nLen; ii++)
            {
                let muteBtndiv;
                const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                if( dataArr[3] === from.email )
                { 
                    if (g_maxDivId === g_subInfoArr[ii].g_divId)
                        this.setState({bRemoteMuteDisabled: data});
                    g_subInfoArr[ii].disableRemoteAudio = data;   
                    muteBtndiv = document.getElementById('toggleAudio_'+g_subInfoArr[ii].g_stream.streamId)

                    if (data === true){
                        //change the mute Speaker icons on top of video element                            
                        if (muteBtndiv !== undefined && muteBtndiv !== null) {
                            let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                            muteBtn[0].classList.remove("fa-volume-up");
                            muteBtn[0].classList.add("fa-volume-mute");
                            muteBtn[0].style.color = "red";
                            muteBtn[0].previousSibling.classList.add("font-awesome-grey");
                        }
                    } else {
                        //change the mute Speaker icons on top of video element                            
                        if (muteBtndiv !== undefined && muteBtndiv !== null) {
                            let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                            //T32-474
                            if (g_subInfoArr[ii].disableAudio === false) { 
                                muteBtn[0].classList.add("fa-volume-up");
                                muteBtn[0].classList.remove("fa-volume-mute");
                            }
                            muteBtn[0].style.color = g_subInfoArr[ii].disableAudio ? "red" : "black";
                            muteBtn[0].previousSibling.classList.remove("font-awesome-grey");
                        }
                    }
                    //set the changed session Joinee in the array
                    this.updateEachSessionJoinee(g_subInfoArr[ii]);
                }
            }


            this.setState({g_subInfoArr}, () => {
                this.setState({isParticipantChanged : true}, () => {
                    this.setState({isParticipantChanged : false});
                });// to change the Side Bar button state                    
                const list = g_subInfoArr.filter(p => p.disableRemoteAudio === false);
                let length = 0;
                //printConsole(`list --------------------------- ${list}`)
                if (list !== undefined) {
                    //printConsole(`Length of Users with Video on -- ${list.length}`)
                    length = list.length;
                }
                
                this.setState({ userAudioEnabledCount: length }, () => {
                    if (this.state.userAudioEnabledCount === 0) {
                        this.setState({/* mutedAll: data, */ disabledMutedAll: data})
                    } else if (this.state.userAudioEnabledCount > 0) {
                        this.setState({/* mutedAll: false, */ disabledMutedAll: false})
                    }
                });

            });  
        } catch (error) {
            printConsole(error);
        }
    }

    /** Processor of received signals for Secondary Experts begins */

    // New signal received on expert end which sends the Primary Expert ID as payload
    // All Secondary Experts will receive this signal
    // The current Primary Expert ID is set as a state variable
    // NS2-486
    setPrimaryExpert = (json) => {
        const {data, from} = json;
        printConsole(`set the current Primary expert ID as ${data.expertId}, email ${from.email}`)
        const { g_subInfoArr } = this.state;
        if (this.state.showHideMaxDivControl === false) {
            //If joining the session as a Passive expert
            // And the missed call alert timer is on for user
            // then reset the timer
            if (this.missedCallTimer) {
                clearTimeout(this.missedCallTimer);
            }
            this.setState({ currentPrimaryExpert: data.expertId });
            this.setState({isParticipantChanged : true}, () => {
                this.setState({isParticipantChanged : false});
            });// to change the Side Bar button state
        } else {
            if (data.expertId !== this.props.loggedInUserId) {
                this.setState({
                    showHideMaxDivControl: false,
                    currentPrimaryExpert: data.expertId,
                    disableHand: false
                }, () => {
                    //If joining the session as a Passive expert
                    // And the missed call alert timer is on for user
                    // then reset the timer
                    if (this.missedCallTimer) {
                        clearTimeout(this.missedCallTimer);
                    }
                });
                this.hideGridViewIcon(true);   // Disable the Grid View Icon for Passive expert             
                for (let ii=0; ii< g_subInfoArr.length; ii++) {
                    //hide the disconnect user button on top of the video feed elements
                    let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                    if (disconnectuser !== undefined && disconnectuser !== null) {
                        disconnectuser.style.display = "none";                    
                    }
    
                    if (this.state.gridViewMode === false) {
                        //hide the gridcontrol icons on top of video element
                        let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                        if (gridcontrol !== undefined && gridcontrol !== null)
                            gridcontrol.style.display = "none";

                        //hide the zoom levels text on top of Video element
                        let zoomLevel = document.getElementById('zoomLevel_'+g_subInfoArr[ii].g_stream.streamId)
                        if (zoomLevel !== undefined && zoomLevel !== null)
                            //TP-2933 -- only show the zoomlevel when it is a mobile device else hide it
                            if (this.state.hideButton === "d-none" && window.innerHeight <= 420 /* && showHideMaxDivControl === true */ && parseInt(this.state.zoom_level) > 1) 
                                zoomLevel.parentElement.style.display = 'block';
                            else 
                                zoomLevel.parentElement.style.display = 'none';

                    } else {
                        //show the gridcontrol icons on top of video element
                        let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                        if (gridcontrol !== undefined && gridcontrol !== null)
                            gridcontrol.style.display = "block";
                        
                        // Hide the Video Turn off Icon on Passive expert's Grid View
                        let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);                    
                        if (videoIcon !== undefined && videoIcon !== null) 
                            videoIcon.style.display = "none";

                        if(g_subInfoArr[ii].zoom_enabled) {
                            // disable the zoom Icon for Passive expert (Indicator only)
                            let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                            if (zoomIcon !== undefined && zoomIcon !== null) {
                                zoomIcon.style.display = "block";
                                let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");
                                zoomBtn[0].previousSibling.classList.add("font-awesome-grey");
                                //zoomIcon.onClick = () => {};
                            }
                        }

                        if(g_subInfoArr[ii].torch_enabled) {
                            // disable the torch Icon for Passive expert (Indicator only)                         
                            let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                            if (torchIcon !== undefined && torchIcon !== null) {
                                torchIcon.style.display = "block";
                                let torchBtn = torchIcon.getElementsByClassName("grid-icon");                            
                                torchBtn[0].previousSibling.classList.add("font-awesome-grey");
                                //torchIcon.onClick = () => {};
                            }
                        }
                            
                    }
                }
                //this.sendSignalCurrentPrimaryExpert(data);
            } else if (data.expertId === this.props.loggedInUserId) {
                this.setState({
                    showHideMaxDivControl: true,
                    currentPrimaryExpert: data.expertId,
                    disableHand: false
                })
                // Show/Hide the Grid View Icon for Primary expert
                // Based on whether in Max-view mode or Grid View Mode
                if (this.state.gridViewMode === false)
                    this.hideGridViewIcon(false);
                else
                    this.hideGridViewIcon(true);
                for (let ii=0; ii< g_subInfoArr.length; ii++) {
                    //show the disconnect user button on top of the video feed elements
                    let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                    if (disconnectuser !== undefined && disconnectuser !== null) {
                        disconnectuser.style.display = "block";                    
                    }

                    if (this.state.gridViewMode === false) {
                        //hide the gridcontrol icons on top of video element
                        let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                        if (gridcontrol !== undefined && gridcontrol !== null)
                            gridcontrol.style.display = "none";

                        //hide the zoom levels text on top of Video element
                        let zoomLevel = document.getElementById('zoomLevel_'+g_subInfoArr[ii].g_stream.streamId)
                        if (zoomLevel !== undefined && zoomLevel !== null)
                            //TP-2933 -- only show the zoomlevel when it is a mobile device else hide it
                            if (this.state.hideButton === "d-none" && window.innerHeight <= 420 /* && showHideMaxDivControl === true */ && parseInt(this.state.zoom_level) > 1) 
                                zoomLevel.parentElement.style.display = 'block';
                            else 
                                zoomLevel.parentElement.style.display = 'none';
                    } else {
                        //show the gridcontrol icons on top of video element
                        let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                        if (gridcontrol !== undefined && gridcontrol !== null)
                            gridcontrol.style.display = "block";

                        // Show the Video Turn off Icon on Primary expert's Grid View
                        let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);                    
                        if (videoIcon !== undefined && videoIcon !== null) 
                            videoIcon.style.display = "block";                            
                        
                        if(g_subInfoArr[ii].zoom_enabled) {
                            // enable the zoom Icon for Primary expert
                            let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                            if (zoomIcon !== undefined && zoomIcon !== null) {
                                zoomIcon.style.display = "block";
                                let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");
                                zoomBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                zoomIcon.onClick = function (e) {
                                    this.rotateZoomLevel(g_subInfoArr[ii].g_divId);
                                }
                            }
                        }  
                        
                        if(g_subInfoArr[ii].torch_enabled) {
                            // enable the torch Icon for Primary expert
                            let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                            if (torchIcon !== undefined && torchIcon !== null) {
                                torchIcon.style.display = "block";
                                let torchBtn = torchIcon.getElementsByClassName("grid-icon");                            
                                torchBtn[0].previousSibling.classList.remove("font-awesome-grey");
                                torchIcon.onClick = function (e) {
                                    this.toggleUserTorch(g_subInfoArr[ii].g_divId);
                                }
                            }
                        }
                    }    
                }
            }
        }
    }

    processSignalYieldMaxDivControl = (json) => {
        //console.info('Main expert has responded to Max Div control request with: ', json.data);
        const {data, from} = json;
        const {g_subInfoArr} = this.state;
        this.setState({
            showHideMaxDivControl: data,
            clickHandSignal: false
        }, () => {
            if (data === true) {
                // TP-359 -- New Socket signal to be sent to all technicians 
                // announcing the current Primary expert 
                this.sendSignalCurrentPrimaryExpert();
            }
            this.setState({isParticipantChanged : true}, () => {
                this.setState({isParticipantChanged : false});
            });// to change the Side Bar button state
        });
        // for NS2-137
        this.props.updateShowHideHeaderActionButtons(data);
        if (data === false) {
            this.hideGridViewIcon(true);
            for (let ii=0; ii< g_subInfoArr.length; ii++) {
                //hide the disconnect user button on top of the video feed elements
                let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                if (disconnectuser !== undefined && disconnectuser !== null) {
                    disconnectuser.style.display = "none";                    
                }

                if (this.state.gridViewMode === false) {
                    //hide the gridcontrol icons on top of video element
                    let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                    if (gridcontrol !== undefined && gridcontrol !== null)
                        gridcontrol.style.display = "none";

                    //hide the zoom levels text on top of Video element
                    let zoomLevel = document.getElementById('zoomLevel_'+g_subInfoArr[ii].g_stream.streamId)
                    if (zoomLevel !== undefined && zoomLevel !== null)
                        //TP-2933 -- only show the zoomlevel when it is a mobile device else hide it
                        if (this.state.hideButton === "d-none" && window.innerHeight <= 420 /* && showHideMaxDivControl === true */ && parseInt(this.state.zoom_level) > 1) 
                            zoomLevel.parentElement.style.display = 'block';
                        else 
                            zoomLevel.parentElement.style.display = 'none';
                } else {
                    //show the gridcontrol icons on top of video element
                    let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                    if (gridcontrol !== undefined && gridcontrol !== null)
                        gridcontrol.style.display = "block";
                    
                    // Hide the Video Turn off Icon on Passive expert's Grid View
                    let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);                    
                    if (videoIcon !== undefined && videoIcon !== null) 
                        videoIcon.style.display = "none";

                    if(g_subInfoArr[ii].zoom_enabled) {
                        // disable the zoom Icon for Passive expert (Indicator only)
                        let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                        if (zoomIcon !== undefined && zoomIcon !== null) {
                            zoomIcon.style.display = "block";
                            let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");
                            zoomBtn[0].previousSibling.classList.add("font-awesome-grey");
                            //zoomIcon.onClick = () => {};
                        }
                    }

                    if(g_subInfoArr[ii].torch_enabled) {
                        // disable the torch Icon for Passive expert (Indicator only)                         
                        let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                        if (torchIcon !== undefined && torchIcon !== null) {
                            torchIcon.style.display = "block";
                            let torchBtn = torchIcon.getElementsByClassName("grid-icon");                            
                            torchBtn[0].previousSibling.classList.add("font-awesome-grey");
                            //torchIcon.onClick = () => {}; 
                        }
                    }
                }
            }

            //console.log("Your request for Maxdiv control buttons has been denied by the Main Expert");
            //const isBusy = this.isOngoingPassiveExpertFeatures();
            //console.log("Expert is busy: ", isBusy);
            //console.log(this.state.gridViewMode);
            /* if (isBusy === false && this.state.gridViewMode === false)
                this.sendSignalSyncExperts({reason: "request_expert_sync"}, json.from.email); */            
        } else {
            const list = this.state.g_subInfoArr.filter(p => p.disableVideo === false && p.g_stream.hasVideo === true && p.g_stream.videoType !== 'screen');
            //printConsole(`list ---------------------------`)
            //printConsole(list);
            let userVideoCount = 0;
            if (list !== undefined) {
                //printConsole(`Length of Users with Video on -- ${list.length}`)
                userVideoCount = list.length;
            }
            this.setState({ userVideoCount });
            printConsole(`uservideocount: ${userVideoCount}`);
            printConsole(`gridViewMode: ${this.state.gridViewMode}`);
            if (userVideoCount > 1 && this.state.gridViewMode === false)
                this.hideGridViewIcon(false); 
            const isOngoingMaxDivFeatures = this.isOngoingMaxDivFeaturesExceptZoomAndTorch();
            this.setState({ isOngoingMaxDivFeatures });
            if (this.state.lastViewObj !== null) {
                // TP-1363 -- If Screencast was ongoing then change the New Primary expert's view to the previous view
                // of the last Primary expert
                this.triggerDrawExpertUI();//TP-1452
            }
            if (this.state.gridViewMode === true) {
                //document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('actionRequestControls'))
                for (let ii=0; ii< g_subInfoArr.length; ii++) {
                    //show the disconnect user button on top of the video feed elements
                    let disconnectuser = document.getElementById('disconnectuser_'+g_subInfoArr[ii].g_stream.streamId);
                    if (disconnectuser !== undefined && disconnectuser !== null) {
                        disconnectuser.style.display = "block";                    
                    }
    
                    
                    //show the gridcontrol icons on top of video element
                    let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                    if (gridcontrol !== undefined && gridcontrol !== null)
                        gridcontrol.style.display = "block";

                    // Show the Video Turn off Icon on Primary expert's Grid View
                    let videoIcon = document.getElementById('toggleVideo_'+g_subInfoArr[ii].g_stream.streamId);                    
                    if (videoIcon !== undefined && videoIcon !== null) 
                        videoIcon.style.display = "block";

                    if(g_subInfoArr[ii].zoom_enabled) {
                        // enable the zoom Icon for Primary expert
                        let zoomIcon = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId);
                        if (zoomIcon !== undefined && zoomIcon !== null) {
                            zoomIcon.style.display = "block";
                            let zoomBtn = zoomIcon.getElementsByClassName("grid-icon");
                            zoomBtn[0].previousSibling.classList.remove("font-awesome-grey");
                            zoomIcon.onClick = function (e) {
                                this.rotateZoomLevel(g_subInfoArr[ii].g_divId);
                            }
                        }
                    }  
                        
                    if(g_subInfoArr[ii].torch_enabled) {
                        // enable the torch Icon for Primary expert
                        let torchIcon = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId);
                        if (torchIcon !== undefined && torchIcon !== null) {
                            torchIcon.style.display = "block";
                            let torchBtn = torchIcon.getElementsByClassName("grid-icon");                            
                            torchBtn[0].previousSibling.classList.remove("font-awesome-grey");
                            torchIcon.onClick = function (e) {
                                this.toggleUserTorch(g_subInfoArr[ii].g_divId);
                            }
                        }
                    }
                } 
            } else {
                for (let ii=0; ii< g_subInfoArr.length; ii++) {
                    //hide the gridcontrol icons on top of video element
                    let gridcontrol = document.getElementById('gridviewcontrol_'+g_subInfoArr[ii].g_stream.streamId)
                    if (gridcontrol !== undefined && gridcontrol !== null)
                        gridcontrol.style.display = "none";

                    //hide the zoom levels text on top of Video element
                    let zoomLevel = document.getElementById('zoomLevel_'+g_subInfoArr[ii].g_stream.streamId)
                    if (zoomLevel !== undefined && zoomLevel !== null)
                        //TP-2933 -- only show the zoomlevel when it is a mobile device else hide it
                        if (this.state.hideButton === "d-none" && window.innerHeight <= 420 /* && showHideMaxDivControl === true */ && parseInt(this.state.zoom_level) > 1) 
                            zoomLevel.parentElement.style.display = 'block';
                        else 
                            zoomLevel.parentElement.style.display = 'none';
                }
                let flag = false;
                const len = this.state.g_subInfoArr.length;
                for (let ii = 0; ii < len; ii++) {                
                    if (this.state.g_subInfoArr[ii].g_divId === g_maxDivId && (this.state.g_subInfoArr[ii].disableVideo === true || this.state.g_subInfoArr[ii].g_stream.hasVideo === false) && userVideoCount > 0) {
                        flag = true;
                        break;
                    }
                }
                printConsole(flag);
                if (flag === true) {
                    // TP-1284 -- to put the user whose Video is turned on onto Max-view 
                    // only when the current Max-view technician has Video turned off
                    for (let ii = 0; ii < len; ii++) {
                        if ((this.state.g_subInfoArr[ii].disableVideo === true || this.state.g_subInfoArr[ii].g_stream.hasVideo === false) && userVideoCount > 0) {
                            continue;
                        } else {
                            let tmp = g_subInfoArr[ii].g_stream.connection.data;
                            let tmpSplit = tmp.split(':::');
                            if (tmpSplit[4] === 'false' && this.state.g_subInfoArr[ii].g_stream.hasVideo === true && this.state.g_subInfoArr[ii].disableVideo === false) {
                                this.maximizeDiv(null, {divId: g_subInfoArr[ii].g_divId});
                            } 
                            break;
                        }
                    }
                }
            }
            this.props.setPrimaryExpertIDApi(null, true);//NS2-490
            this.setState({ currentPrimaryExpert: this.props.loggedInUserId }); //NS2-486
            // T32-463 -- New Multi-expert Signal trigger implementation
            this.sendSignalSyncExperts({
                isPrimary: true,
                expertId: this.props.user.id,
                email: this.props.user.email,
                firstname: this.props.user.firstname,
                lastname: this.props.user.lastname,
                joinTimeStamp: parseInt(this.state.loggedinUserTimeStamp),
                reason: "primary_sync"
                },
            null); 
            
            if (this.state.bRemoteCapture) {
                //TP-2474 -- Before starting RC + Annotation for New Primary expert send the canvas string & JSON to all Passive experts
                this.triggerSendAnnotation();
                // Start RC+Annotatoion for New Primary Expert
                const {strFileName, format} = this.state;
                const contentType = 'image/'+format;
                const fileName = strFileName.replace('data:'+contentType+';base64,', '').trim();
                // Primary expert urlObj is processed
                const blob = new base64StringToBlob(fileName, contentType);// MB2-556
                //printConsole(blob);
                let urlObj = URL.createObjectURL(blob);
                //NS2-217
                this.setState({ urlObj, bAnnotate: true }, () => {
                    this.startAnnotate();
                    if (this.aTimer) stopAnnotationTimer(); //TP-2824
                });

                // TP-393 --  Handling of Zoom level panel when RC is turned on
                const zoomSelector = document.getElementById('zoomlevelselector');
                if (this.state.bZoomClick && zoomSelector) {
                    zoomSelector.style.display = 'none';
                }

                // Clear the annotations on all users                
                //this.sendAnnotation(clearAnnotateObj, null);
            }
            else if (this.state.bAnnotate === true) {
                //TP-2474 -- Before starting Annotation for New Primary expert send the canvas string & JSON to all Passive experts
                this.triggerSendAnnotation();   
                //TP-1179 -- Clear the old annotation drawings on the New Primary expert
                //if shareAsset is also turned on
                if (this.state.bShareAsset)
                    strCanvasJSON = "";
                this.startAnnotate();
                if (this.aTimer) stopAnnotationTimer(); //TP-2824
                // Clear the annotations on all users
                //this.sendAnnotation(clearAnnotateObj, null);
            } else {
                //If RC or annotation is not on then clear out the Color Picker & Shape Picker elements
                const shapeselector = document.getElementById('shapeselector');
                const sketchPicker = document.getElementById('sketchPicker');
                if (shapeselector !== undefined && shapeselector !== null)
                    shapeselector.style.display = "none";
                if (sketchPicker !== undefined && sketchPicker !== null)
                    sketchPicker.style.display = "none";
            }
            //console.log("assets array::",this.state.assetsArray.length, " & bShareAsset? ",this.state.bShareAsset, " & assetFile format?? ", this.state.assetsArray[0].format);
            if(this.state.assetsArray.length > 0 && this.state.bShareAsset) {
                //TP-2553 & TP-2606
                if (this.state.assetsArray[0].format === undefined) {
                    this.handleArtifactsOnPrimaryExpert(this.state.assetsArray);
                } else {
                    const paramElem = document.getElementById(g_maxDivId);
                    const isExpertDiv = isDivExpert(g_maxDivId);
                    this.handleArtifactsNew({ assetsArray: this.state.assetsArray, paramElem, isExpertDiv, flag: true });
                }
            } else if (this.state.assetsArray.length > 0 && !this.state.bShareAsset)    {
                //Send signal to all users to remove the shared artifacts
                g_subInfoArr.forEach(subInfo => {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3];
                        this.sessionSignal.sendSignalRemoveArtifacts(
                            true,
                            toObject
                            //type: "REMOVE_ARTIFACTS"
                        );
                    printConsole("remove artifacts signal sent...");
                })
                this.setState({ assetsArray: [] });
            }
            if(this.state.bAllChat === true) {
                this.setState({ bAllChat: false }, () => {
                    this.setState({ bAllChat: true });
                })
            }

            printConsole(`recording active ? ${isrecording_active} isSilent: ${isSilentRecording} isSafari Browser: ${this.props.isSafari} isFirefox Browser: ${this.props.isFirefox}`);
            //TP-740 -- Implementing the seamless continuation of Recording feature when Control tranfer happens
            if (isrecording_active === true && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
                this.onClickRecordScreen("continue"); //TP-1877
            } else if (isrecording_active === true && isSilentRecording === true){
                printConsole("Currently Silent recording isn't supported on Webapp Expert side");
                /* this.sendSignalRecordingStop();
                //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                this.sendSignalSyncExperts({
                    isrecording_active: false,
                    is_silent_recording: isSilentRecording,
                    recording_folder_name: this.state.recFileName,
                    frames_per_seconds: screenSettings[1].fps,
                    frame_count: count,
                    reason: "recording_sync"},
                null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                this.setState({ recFileName: '' }); */
            } else if (isrecording_active === true && isSilentRecording === false && (this.props.isSafari || this.props.isFirefox || this.state.hideButton !== 'd-none')) {
                // TP-2262 -- Handling the stopping of Recording if Expert who takes up control is on Safari/Firefox browsers
                printConsole("Safari/Firefox browser doesn't support recording so stopping the previous recording")
                this.sendSignalRecordingStop(null, from.email);//TP-2095
                //Send the SYNC_EXPERTS Signal to all Passive experts when recording is stopped
                this.sendSignalSyncExperts({
                    isrecording_active: false,
                    is_silent_recording: isSilentRecording,
                    recording_folder_name: this.state.recFileName,
                    frames_per_seconds: screenSettings[1].fps,
                    frame_count: count,
                    reason: "recording_sync"},
                null) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = false`);
                this.setState({ recFileName: '' });
            }
            // To close the Max-chat window of the New Primary expert
            // who was before a Passive expert, and open the Side Bar Chat window 
            // for him after the user becomes a Primary expert
            if (this.state.bMaxChat === true) {
                this.setState({ bMaxChat: false }, () => {
                    this.setState({ bAllChat: true }); //TP-110
                    this.props.updateChatAll(true);
                })
            } 
            this.props.showScreenShareHeaderButton(true); // for NS2-282            
            this.setState({
                bHideExpertSSButton: true,
                bHideTechnicianSSButton: true,
                bHideZoomButton: true,
                bHideTorchButton: true,
                maxDivTechType: "WEB"
            })
            if (this.state.gridViewMode === false)
                this.showHideRcForTech(true); // for NS2-271
            else{
                // TP-1546 -- only turn on the Side Bar Screencast/Fileshare Icons if at least one tech has Video turned on
                if(this.state.userVideoCount > 0)    {
                    this.props.showScreenShareHeaderButton(false);
                }
                this.props.updateShowHideHeaderActionButtons(true);
                //this.showHideRcForTech(false);
                this.sendSignalMaxViewTech(false); // for NS2-271
            }
        }        
    }

    triggerSyncExpertsShowAction = () => {
        // Sync experts reason="show_action" signal sent to all experts in session
            // with show_grid = false           
            if (this.state.gridViewMode === false && g_expertConnsArr.length > 0){
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.g_divId === g_maxDivId) {
                        const dataArr = subInfo.g_stream.connection.data.split(":::")
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            annotation_active: this.state.bAnnotate, 
                            zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                            zoom_level: subInfo.zoomLevel,
                            torch_button_active: subInfo.torchActive,
                            annotate_color: "red",
                            shape_selected: "freehand",
                            hand_icon_enabled: true, 
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378                           
                            reason: "show_action" },
                        null);
                    }
                });
            }
            else if (this.state.gridViewMode === true && g_expertConnsArr.length > 0) {
                // Sync experts reason="show_action" signal sent to all experts in session
                // with show_grid = true
                this.sendSignalSyncExperts({
                    show_grid: true,
                    max_view_technician: "", 
                    annotation_active: this.state.bAnnotate, 
                    annotate_color: "red",
                    shape_selected: "freehand",
                    hand_icon_enabled: true,         
                    header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378                   
                    reason: "show_action" },
                null);
            }
    }

    processSignalYieldDisableHandIcon = (json) => {
        printConsole(`Disable ${json.data} Hand Icon since the expert ${json.expertName} is already interacting with Primary Expert!!`);
        this.setState({ disableHand: json.data, clickHandSignal: false }, () => {
            if (this.state.disableHand === true) this.setState({ handIconHover: "session.expertControlsDisabled"})
        });
        if (json.data === true) 
            this.triggerMessage(json.expertName, "disableHand");
    }

    processSignalSyncExperts = (json) => {
        const {data} = json;
        switch (data.reason) {
            case "request_expert_sync":
                this.triggerExpertQueryMaxDiv(json);// Mb2-500
                break;
            case "primary_sync":
                this.setPrimaryExpert(json);
                break;
            case "show_action":
                this.triggerExpertViewControls(json);
                break;
            case "update_action":
                this.triggerExpertGridViewControls(json);
                break;
            case "registered_sync":
                this.setRegisteredExpert(json);
                break;
            case "swap_camera_sync":
                this.triggerSwapCamera(json);
                break;
            case "recording_sync":
                this.setRecordingStatus(json);
                break;
            // TP-2349 -- guest user list syncing signal
            case "guest_user_sync":
                this.setGuestUserList(json);
                break;
            //TP-2474 -- handling of UI when Erase Annotate is clicked
            case "canvas_erased":
                this.triggerCanvasErase(json);//TP-2474
                break;
            default:                
        }
    }

    triggerExpertQueryMaxDiv = (json) => {
        //console.log(json.data.reason);
        //const dataSplit = json.from.split(':::');
        if (json.from.isexpert === true && this.state.showHideMaxDivControl === true) {
            const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, /**TP-2475 */ isPointerActive} = this.state;
            let shape_selected = "";
            if (isFreeDrawing === true) shape_selected = "freehand";
            else if (isRectActive === true) shape_selected = "rectangle";
            else if (isCircleActive === true) shape_selected = "circle";
            else if (isArrowActive === true) shape_selected = "arrow";
            else if (isPointerActive === true) shape_selected = "pointer"; /**TP-2586 & TP-2475 */
            // T32-463 -- New Multi-expert Signal trigger implementation
            this.sendSignalSyncExperts({
                isPrimary: true,
                expertId: this.props.user.id,
                email: this.props.user.email,
                firstname: this.props.user.firstname,
                lastname: this.props.user.lastname,
                joinTimeStamp: parseInt(this.state.loggedinUserTimeStamp),
                reason: "primary_sync"},
            json.from.email); // Send the current Primary expert's details

            // TP-1313 && TP-1199 -- This is to take care of scenarios where a Passive expert newly joins a session
            // while another Passive expert has asked for taking over the Max-div controls from the Primary expert
            // hence the Hand Icon for this new Expert needs to be disabled while the Request Dialog box is open
            // on the Primary expert's screen
            if (this.state.showControlRequestDialog === true && this.state.expertEmail !== '') {
                //console.log(this.state.expertEmail, json.from.email);
                this.sendSignalYieldDisableHandIcon(true, `${this.state.expertFirstName} ${this.state.expertLastName}`, this.state.expertEmail, json.from.email); 
            }

            // NS2-345 
            // These conditions are to take care of the situation when a Passive expert joins a session
            // with an already running RC in different stages.
            if (this.state.bRemoteCapture && this.state.bRCAnnotateOn && !this.state.bAnnotate) {
                g_subInfoArr.forEach((subInfo) => {
                    if (subInfo.g_divId === g_maxDivId) {
                        // T32-463 -- New Multi-expert Signal trigger implementation
                        const dataArr = subInfo.g_stream.connection.data.split(":::")
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"}, 
                        json.from.email);  // send the max div tech details
                    }
                })
                this.sendSignalExpertRemoteClick('', json.from.email);
            }
            else if (this.state.bRemoteCapture && !this.state.bRCAnnotateOn && !this.state.bAnnotate) {
                g_subInfoArr.forEach((subInfo) => {
                    if (subInfo.g_divId === g_maxDivId) {
                        // T32-463 -- New Multi-expert Signal trigger implementation
                        const dataArr = subInfo.g_stream.connection.data.split(":::")
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"}, 
                        json.from.email);  // send the max div tech details                        
                    }
                })
                this.sendSignalExpertRemoteClick('', json.from.email);


                //if (g_session.connection !== connectionObj) {
                this.sessionSignal.sendSignalExpertRCUploaded(

                    //from: g_session.connection.data,
                    this.state.strFileName,
                    json.from.email,
                    'image/png'
                    //type: "EXPERT_RC_IMAGE_DATA"

                );
                //console.log('signal sent = ', 'EXPERT_RC_IMAGE_DATA');                                        
                //}
            }
            else if (this.state.bRemoteCapture && this.state.bAnnotate) {
                g_subInfoArr.forEach((subInfo) => {
                    if (subInfo.g_divId === g_maxDivId) {
                        // T32-463 -- New Multi-expert Signal trigger implementation
                        const dataArr = subInfo.g_stream.connection.data.split(":::")
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            annotation_active: this.state.bAnnotate, 
                            annotate_color: this.state.pencolor,
                            shape_selected,
                            hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"}, 
                        json.from.email);  // send the max div tech details                        
                    }
                })
                this.sendSignalExpertRemoteClick('', json.from.email);


                //if (g_session.connection !== connectionObj) {
                this.sessionSignal.sendSignalExpertRCUploaded(

                    //from: g_session.connection.data,
                    this.state.strFileName,
                    json.from.email,
                    'image/png'
                    //type: "EXPERT_RC_IMAGE_DATA"

                );
                //console.log('signal sent = ', 'EXPERT_RC_IMAGE_DATA');
                //}
            } 
            else if (this.state.expertSSAll || this.state.expertShareScreenMaxDiv) {                
                this.sendSignalPrepareExpertSS(true, json.from.email);
                this.sendSignalExpertSS(true, json.from.email);
                // TP-1023 -- To handle the Passive expert view when the New Passive expert
                // joins the session in Screencast mode
                if (this.state.gridViewMode === true) {
                    // T32-463 -- New Multi-expert Signal trigger implementation                    
                    this.sendSignalSyncExperts({
                        show_grid: true,
                        hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                        header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                        reason: "show_action"},
                    json.from.email);  // send the "show_grid" value as true for Grid View Mode
                    
                } else {
                    g_subInfoArr.forEach((subInfo) => {
                        if (subInfo.g_divId === g_maxDivId) {
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                annotation_active: this.state.bAnnotate, 
                                annotate_color: this.state.pencolor,
                                shape_selected,
                                torch_button_active: this.state.bTorchClick ? true : false,
                                zoom_button_active: (parseInt(this.state.zoom_level) > 1) ? true : false,
                                zoom_level: parseInt(this.state.zoom_level),
                                hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            json.from.email);  // send the max div tech details                             
                        }
                    })
                }

                //TP-1367 -- Send the updated status of the Zoom & torch buttons for each user in Case of Side bar 
                // screencast during Grid View Mode is ongoing and a new Passive expert joins the session
                if (this.props.sessionInfo.zoom_enabled === true || this.props.sessionInfo.torch_enabled === true) {
                    g_subInfoArr.forEach((subInfo) => {
                        if (subInfo.torchActive === true || subInfo.zoomLevel > 1) {
                            const dataArr = subInfo.g_stream.connection.data.split(":::");
                            const obj = {                            
                                show_grid: true, 
                                email: dataArr[3],                                    
                                torch_button_active: (subInfo.torch_enabled) ? subInfo.torchActive : false,
                                zoom_level: (subInfo.zoom_enabled) ? parseInt(subInfo.zoomLevel) : 1,
                                hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                                reason: "update_action"
                            }
                            this.sendSignalSyncExperts(obj, json.from.email); // send the zoom & torch button states to the new Expert
                        }
                    })
                }
            } else if (this.state.bShareAsset &&(this.state.assetsArray.length > 0 || this.state.fileData !== null)) {
                // this condition is to take care of the scenario when an new passive Expert joins a session
                // while Share Assets is ongoing (from Side Bar/Max-view)
                // When a new Passive expert joins his Hand Icon should be off
                if (this.state.gridViewMode === true) {
                    // T32-463 -- New Multi-expert Signal trigger implementation                    
                    this.sendSignalSyncExperts({
                        show_grid: true,
                        hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                        header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                        reason: "show_action"},
                    json.from.email);  // send the "show_grid" value as true for Grid View Mode
                    
                } else {
                    g_subInfoArr.forEach((subInfo) => {
                        if (subInfo.g_divId === g_maxDivId) {
                            // T32-463 -- New Multi-expert Signal trigger implementation
                            const dataArr = subInfo.g_stream.connection.data.split(":::")
                            this.sendSignalSyncExperts({
                                show_grid: false,
                                max_view_technician: dataArr[3], 
                                hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                                header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                reason: "show_action"}, 
                            json.from.email);  // send the max div tech details                             
                        }
                    })
                }
                if (this.state.fileData !== null)
                    this.sessionSignal.sendSignalShareArtifacts(this.state.fileData, json.from.email);
                else {
                    this.state.assetsArray.forEach(asset => {
                        this.sessionSignal.sendSignalShareArtifacts(asset, json.from.email);
                    })
                }
                const [closeIcon] = document.getElementsByClassName('asset-3d');
                if (closeIcon && this.state.rotationArray !== null && this.state.rotationArray !== '') {
                    // If 3D file is shared then resend the last rotation change
                    this.rotationTimer = setTimeout(() => {
                        this.sessionSignal.sendSignal3DRotation(this.state.rotationArray, json.from.email);
                        clearTimeout(this.rotationTimer)
                    }, 3000);
                } else if (this.state.fileOpacity) {
                    // If not 3D file share ongoing then send the current Opacity value to 
                    // newly joining Passive expert
                    this.sessionSignal.sendSignalOpacity(this.state.fileOpacity, json.from.email); //TP-841
                }
            } else {                
                // this condition is to take care of the scenario when an new passive Expert joins a session
                // but RC isn't running.
                if (this.state.gridViewMode === true) {
                    // T32-463 -- New Multi-expert Signal trigger implementation                    
                    this.sendSignalSyncExperts({
                        show_grid: true,
                        hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                        header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                        reason: "show_action"},
                    json.from.email);  // send the "show_grid" value as true for Grid View Mode
                    
                } else {
                    //TP-1993
                    if (g_subInfoArr.length > 0) {
                        g_subInfoArr.forEach((subInfo) => {
                            if (subInfo.g_divId === g_maxDivId) {
                                // T32-463 -- New Multi-expert Signal trigger implementation
                                const dataArr = subInfo.g_stream.connection.data.split(":::")
                                this.sendSignalSyncExperts({
                                    show_grid: (dataArr[4] === "false") ? false : true,
                                    max_view_technician: (dataArr[4] === "false") ? dataArr[3]: "", 
                                    annotation_active: this.state.bAnnotate, 
                                    annotate_color: this.state.pencolor,
                                    shape_selected,
                                    torch_button_active: this.state.bTorchClick ? true : false,
                                    zoom_button_active: (parseInt(this.state.zoom_level) > 1) ? true : false,
                                    zoom_level: parseInt(this.state.zoom_level),
                                    hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                                    header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                    reason: "show_action"}, 
                                json.from.email);  // send the max div tech details    
                                //TP-1993 -- Adding the Passive expert's identity into a seperate array state
                                // to send the "SYNC_EXPERTS" Socket signal again to them
                                if (dataArr[4] === "true") {
                                    this.setState({ fakeExpertList: [...this.state.fakeExpertList, json.from.email] }, () => {
                                        printConsole("setting the Passive expert's identity into a dummy array of experts");
                                    });
                                }                         
                            }
                        })
                    } else {
                        printConsole("subscriber list is empty for Primary expert...");
                        //TP-1993 -- Adding the Passive expert's identity into a seperate array state
                        // to send the "SYNC_EXPERTS" Socket signal again to them
                        this.setState({ fakeExpertList: [...this.state.fakeExpertList, json.from.email] }, () => {
                            printConsole("setting the Passive expert's identity into a dummy array of experts");
                        });
                    }
                }

                // This is to take care of the scenario of when Primary expert has turned off some User's video
                // and the Passive expert who joins the call needs to be in sync with the Main expert
                if (this.props.sessionInfo.customer_can_turn_off_user_video){
                    let payload = [];
                    g_subInfoArr.forEach((subInfo) => {
                        const dataArray = subInfo.g_stream.connection.data.split(":::")
                        const obj = {
                            flag: subInfo.disableVideo, 
                            userId: parseInt(dataArray[0]),
                            email: dataArray[3]
                        }
                        payload.push(obj);
                    });
                    this.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, json.from.email);
                }

                if (this.props.sessionInfo.zoom_enabled === true || this.props.sessionInfo.torch_enabled === true) {
                    g_subInfoArr.forEach((subInfo) => {
                        if (subInfo.torchActive === true || subInfo.zoomLevel > 1) {
                            const dataArr = subInfo.g_stream.connection.data.split(":::");
                            const obj = {                            
                                show_grid: true, 
                                email: dataArr[3],                                    
                                torch_button_active: (subInfo.torch_enabled) ? subInfo.torchActive : false,
                                zoom_level: (subInfo.zoom_enabled) ? parseInt(subInfo.zoomLevel) : 1,
                                hand_icon_enabled: (this.state.showControlRequestDialog === true) ? false : true, //TP-1313
                                reason: "update_action"
                            }
                            this.sendSignalSyncExperts(obj, json.from.email); // send the zoom & torch button states to the new Expert
                        }
                    })
                }

                printConsole(`started recording: ${this.state.startRecording} isSilent: ${isSilentRecording} isSafari Browser: ${this.props.isSafari} isFirefox Browser: ${this.props.isFirefox}`);
                //TP-1906 -- check if currently screen recording is active or not
                if (this.state.startRecording && isSilentRecording === false && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262                    
                    this.sessionSignal.sendSignalSyncExperts({
                        isrecording_active: true,
                        is_silent_recording: isSilentRecording,
                        recording_folder_name: this.state.recFileName,
                        frames_per_seconds: screenSettings[1].fps,
                        frame_count: count,
                        reason: "recording_sync"},
                    json.from.email) && printConsole (`Sending the SYNC_EXPERTS signal to all Passive experts with recording_active = true`);
                }
            }
        }
    }

    // T32-592 -- Handling the View Controls (start/stop) in the Passive expert
    // to Sync with the Primary expert
    triggerExpertViewControls = (json) => {
        this.processSignalExpertChangeMaxDiv(json.data);//TP-2454
        const {annotation_active, zoom_button_active, zoom_level, torch_button_active, hand_icon_enabled, max_view_technician, annotate_color, shape_selected, header_fileShare_active} = json.data;
        if (this.state.bShareAsset && header_fileShare_active === true) this.setState({ shareOnDiv: null });
        else if (this.state.bShareAsset && header_fileShare_active === false) this.setState({ shareOnDiv: document.getElementById(g_maxDivId) });
        if (hand_icon_enabled === true) {
            this.setState({ disableHand: false });
        } else {
            this.setState({ disableHand: true, handIconHover: "session.handIconDisabled" });
        } 
        if (annotation_active !== undefined && this.state.prepareExpertSS === false) {
            if (this.state.bAnnotate === false && annotation_active === true) {
                this.setState({ bAnnotate: true, isOngoingMaxDivFeatures: true });
            } else if (this.state.bAnnotate === true && annotation_active === false) {
                this.setState({ bAnnotate: false, isOngoingMaxDivFeatures: false });
            }
            if (annotation_active === true) {                
                // Setting the Color & Shape selector Icon state in Passive expert
                if (annotate_color !== undefined && annotate_color !== ""){
                    this.setState({ pencolor: annotate_color },() => {
                        if (shape_selected !== undefined && shape_selected !== "") {
                            switch (shape_selected) {
                                case "arrow":
                                    annotateShapesArray[6] = annotateShapesArray[3];
                                    this.setState({
                                        isArrowActive: true,
                                        isFreeDrawing: false,
                                        isRectActive: false,
                                        isCircleActive: false,
                                        isPointerActive: false /**TP-2475 */
                                    }, () => {
                                        const expertMaxdivElement = document.getElementById(g_maxDivId);
                                        onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                                    });
                                    break;
                                case "freehand":
                                    annotateShapesArray[6] = annotateShapesArray[0];
                                    this.setState({
                                        isFreeDrawing: true,
                                        isRectActive: false,
                                        isCircleActive: false,
                                        isArrowActive: false,
                                        isPointerActive: false /**TP-2475 */
                                    }, () => {
                                        //TP-1179
                                        if (this.state.bShareAsset) //TP-2322
                                            strCanvasJSON = "";
                                        const expertMaxdivElement = document.getElementById(g_maxDivId);
                                        onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                                    });
                                    break;
                                case "rectangle":
                                    annotateShapesArray[6] = annotateShapesArray[2];
                                    this.setState({
                                        isFreeDrawing: false,
                                        isRectActive: true,
                                        isCircleActive: false,
                                        isArrowActive: false,
                                        isPointerActive: false /**TP-2475 */
                                    }, () => {
                                        const expertMaxdivElement = document.getElementById(g_maxDivId);
                                        onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                                    });
                                    break;
                                case "circle":
                                    annotateShapesArray[6] = annotateShapesArray[1];
                                    this.setState({
                                        isFreeDrawing: false,
                                        isRectActive: false,
                                        isCircleActive: true,
                                        isArrowActive: false,
                                        isPointerActive: false /**TP-2475 */
                                    }, () =>{
                                        const expertMaxdivElement = document.getElementById(g_maxDivId);
                                        onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                                    });
                                    break;
                                //TP-2586 & TP-2475
                                case "pointer":
                                    annotateShapesArray[6] = annotateShapesArray[4];
                                    this.setState({
                                        isArrowActive: false,
                                        isFreeDrawing: false,
                                        isRectActive: false,
                                        isCircleActive: false,
                                        isPointerActive: true
                                    }, () => {
                                        const expertMaxdivElement = document.getElementById(g_maxDivId);
                                        onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                                    });
                                    break;
                                default:
                                    //TP-2586
                                    annotateShapesArray[6] = annotateShapesArray[0];
                                    this.setState({
                                        isArrowActive: false,
                                        isFreeDrawing: false,
                                        isRectActive: false,
                                        isCircleActive: false,
                                        isPointerActive: false
                                    }, () => {
                                        const expertMaxdivElement = document.getElementById(g_maxDivId);
                                        onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                                    });
                                    break;
                            }
                        }

                    });
                }
            } else {
                // Setting the Color & Shape selector Icon state back to default when Annotation is stopped in Passive expert
                if (annotate_color !== undefined && annotate_color !== ""){
                    this.setState({ pencolor: annotate_color },() => {
                        if (shape_selected !== undefined && shape_selected !== "") {
                            annotateShapesArray[6] = annotateShapesArray[0];
                            this.setState({
                                isFreeDrawing: false, /**TP-2586 */
                                isRectActive: false,
                                isCircleActive: false,
                                isArrowActive: false,
                                isPointerActive: false, /**TP-2475 */
                                isErasorActive: false //TP-2474
                            }, () => {
                                strCanvasJSON = '';
                            });                                    
                        }
                    });
                }
            }
        } 
        if (zoom_button_active === undefined && zoom_level === undefined && torch_button_active === undefined) return printConsole("zoom & torch data undefined");
        else {
            try {
                if (zoom_level !== undefined && parseInt(zoom_level) > 1) this.setState({ bZoomClick: true, zoom_level: zoom_level.toString() }, () => {
                    //this.setState({isOngoingMaxDivFeatures: true})
                    if (this.state.hideButton === "d-none" && window.innerHeight <= 420) {
                        //TP-2933
                        this.state.g_subInfoArr.forEach(subInfo => {
                            const maxtechnician = subInfo.g_stream.connection.data.split(":::")[3];
                            if (json.data.max_view_technician && json.data.max_view_technician !== '' && json.data.max_view_technician === maxtechnician) {
                                let zoomLevel = document.getElementById("zoomLevel_" + subInfo.g_stream.streamId);
                                if (zoomLevel) {
                                    let flag = zoom_level;
                                    // Change the zoom level text on top of Video element
                                    zoomLevel.className = 'zoom-text';
                                    zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                    zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                    zoomLevel.parentElement.style.display = "block";
                                }
                            }
                        })
                    }
                });
                else this.setState({bZoomClick: false, zoom_level: "1"}, () =>{
                    //this.setState({isOngoingMaxDivFeatures: false})
                    if (this.state.hideButton === "d-none" && window.innerHeight <= 420) {
                        //TP-2933
                        this.state.g_subInfoArr.forEach(subInfo => {
                            const maxtechnician = subInfo.g_stream.connection.data.split(":::")[3];
                            if (json.data.max_view_technician && json.data.max_view_technician !== '' && json.data.max_view_technician === maxtechnician) {
                                let zoomLevel = document.getElementById("zoomLevel_" + subInfo.g_stream.streamId);
                                if (zoomLevel) {
                                    let flag = 1;
                                    // Change the zoom level text on top of Video element
                                    zoomLevel.className = 'zoom-text';
                                    zoomLevel.classList.add(zoomClassArray[flag-1].class);
                                    zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                    zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                                }
                            }
                        })
                    }
                });
                
                if (torch_button_active !== undefined)
                    this.setState({ bTorchClick: torch_button_active }, () => {
                        //if (torch_button_active) this.setState({isOngoingMaxDivFeatures: true})
                    });
                else
                    this.setState({ bTorchClick: false }, () => {
                        //this.setState({isOngoingMaxDivFeatures: false})
                    });
                
                let {g_subInfoArr} = this.state;
                // rotating the remote user's zoom level value
                let nLen = g_subInfoArr.length;
                let ii = 0;
                for(ii = 0; ii < nLen; ii++)
                {
                    const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                    if( max_view_technician && max_view_technician === dataArr[3] )
                    {
                        // Rotating the zoom level value for that participant                            
                        g_subInfoArr[ii].zoomLevel = (zoom_level) ? zoom_level: g_subInfoArr[ii].zoomLevel;                        
                        // Toggle the torch icon for that participant                            
                        g_subInfoArr[ii].torchActive = (torch_button_active !== undefined) ? torch_button_active : g_subInfoArr[ii].torchActive;                         
                    }
                }

                // Update the subscriber list of Passive experts
                this.setState({g_subInfoArr});
                
            } catch (error) {
                printConsole ("Exception occured in the triggerExpertSyncFeatures method");
                printConsole(error);
            }
        }
        // this.processSignalExpertChangeMaxDiv(json.data); //TP-2445
    }

    // TP-207 --  Handing the Sync of Grid View Icons for Passive Experts
    triggerExpertGridViewControls = (json) => {
        //this.processSignalExpertChangeMaxDiv(json.data);
        const {email, annotation_active, zoom_button_active, zoom_level, torch_button_active, hand_icon_enabled} = json.data;
        if (hand_icon_enabled === true) {
            this.setState({ disableHand: false });
        } else {
            this.setState({ disableHand: true, handIconHover: "session.handIconDisabled" });
        } 
        if (annotation_active !== undefined) {
            if (this.state.bAnnotate === false && annotation_active === true) {
                this.setState({ bAnnotate: true, isOngoingMaxDivFeatures: true });
            }
            if (annotation_active === true) {
                const expertMaxdivElement = document.getElementById(g_maxDivId);
                onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
            }
        }
        if (zoom_level === undefined && torch_button_active === undefined) return printConsole("zoom & torch data undefined");
        else {
            try {
                let {g_subInfoArr} = this.state;
                // rotating the remote user's zoom level value
                let nLen = g_subInfoArr.length;
                let ii = 0;
                for(ii = 0; ii < nLen; ii++)
                {
                    const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                    if( email === dataArr[3] )
                    {
                        // Rotating the zoom level value for that participant                            
                        g_subInfoArr[ii].zoomLevel = zoom_level;     
                        // Set the Max-div controls Zoom button & level
                        if (g_maxDivId === g_subInfoArr[ii].g_divId) this.setState({ zoom_level, bZoomClick: (zoom_level > 1) ? true : false });                   
                        let zoomBtndiv = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId)
                        
                        if (zoom_level === 1){
                            //change the zoom icons on top of video element                            
                            if (zoomBtndiv !== undefined && zoomBtndiv !== null) {
                                let zoomBtn = zoomBtndiv.getElementsByClassName("grid-icon");
                                //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                // Add the new changed Zoom level Icon
                                /* let zoomIcon = document.createElement('i');
                                zoomIcon.classList.add('fas', 'fa-binoculars', 'fa-stack-1x', 'grid-icon');
                                zoomBtndiv.appendChild(zoomIcon); */
                                zoomBtn[0].style.color = "black";

                                // Change the zoom level text on top of Video element
                                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                zoomLevel.className = 'zoom-text';
                                zoomLevel.classList.add(zoomClassArray[zoom_level-1].class);
                                zoomLevel.innerHTML = processZoomLevels(zoom_level + "X", this.props.i18n);
                                zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                            }
                        } else {
                            //change the zoom icons on top of video element                            
                            if (zoomBtndiv !== undefined && zoomBtndiv !== null) {
                                let zoomBtn = zoomBtndiv.getElementsByClassName("grid-icon");
                                //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                // Add the new changed Zoom level Icon
                                /* let zoomIcon = document.createElement('span');
                                zoomIcon.classList.add('fa-stack-1x', 'fa-zoom-text', 'grid-icon');
                                zoomIcon.innerHTML = processZoomLevels(zoom_level + "X", this.props.i18n);
                                zoomBtndiv.appendChild(zoomIcon); */
                                zoomBtn[0].style.color = "red";

                                // Change the zoom level text on top of Video element
                                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                zoomLevel.className = 'zoom-text';
                                zoomLevel.classList.add(zoomClassArray[zoom_level-1].class);
                                zoomLevel.innerHTML = processZoomLevels(zoom_level + "X", this.props.i18n);
                                zoomLevel.parentElement.style.display = "block";
                            }
                        }
                        
                        // Toggle the torch icon for that participant                            
                        g_subInfoArr[ii].torchActive = torch_button_active; 
                        // Set the Max-div controls Torch button
                        if (g_maxDivId === g_subInfoArr[ii].g_divId) this.setState({ bTorchClick: torch_button_active});
                        let torchBtndiv = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId)

                        if (torch_button_active === true){
                            //change the torch icons on top of video element                            
                            if (torchBtndiv !== undefined && torchBtndiv !== null) {
                                let torchBtn = torchBtndiv.getElementsByClassName("grid-icon");
                                torchBtn[0].style.color = "red"
                            }
                        } else {
                            //change the mute Speaker icons on top of video element                            
                            if (torchBtndiv !== undefined && torchBtndiv !== null) {
                                let torchBtn = torchBtndiv.getElementsByClassName("grid-icon");                                    
                                torchBtn[0].style.color = "black"
                            }
                        }
                        //set the changed session Joinee in the array
                        //this.updateEachSessionJoinee(g_subInfoArr[ii]);
                    }
                }

                // Update the subscriber list of Passive experts
                this.setState({g_subInfoArr});
                
            } catch (error) {
                printConsole("exception in triggerExpertGridViewControls method")
                printConsole(error);
            }
        }
    }

    //TP-552 -- set the Registered Expert flag for an Expert user in Guest expert user flow
    setRegisteredExpert = (json) =>{
        const { from, data } = json;
        printConsole(`the isRegistered flag is ${data.isRegistered} from ${from.email}`);
        let {g_subInfoArr} = this.state;
        let registeredArr = [];
        let nLen = g_subInfoArr.length;
        let ii = 0;
        for(ii = 0; ii < nLen; ii++)
        {
            const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
            if( from.email === dataArr[3] )
            {
                printConsole(`found expert ----> ${dataArr[3]}`);
                printConsole(data.isRegistered);   
                if (g_subInfoArr[ii].isRegistered !== data.isRegistered) {
                    g_subInfoArr[ii].isRegistered = data.isRegistered;
                    this.setState({ g_subInfoArr });
                } else 
                    printConsole("the isRegistered flag is same as currently set for this Expert");

                for (let i = 0; i<= g_expertConnsArr.length; i++) {
                    const datarr = g_expertConnsArr[i].data.split(":::");
                    if(from.email === datarr[3]){
                        g_expertConnsArr[i].isRegistered = data.isRegistered;
                        break;
                    }
                }             
            } else {
                const registeredObj = {
                    userId: from.userId,
                    isRegistered: data.isRegistered
                }
                registeredArr.push(registeredObj);
            }
        }
        
        if (registeredArr.length > 0) 
            this.setState({registeredArr});
    }

    //TP-902 -- set the Swap Camera flag for Max-view Technician for Passive experts in that session
    triggerSwapCamera = (json) => {
        const {data, max_view_technician} = json.data;
        if (data > 0 && this.state.swapTechCamera === false && this.state.showHideMaxDivControl === false) {
            printConsole("Swap the Technician camera for Passive expert!!");
            this.setState({ swapTechCamera: true, maxDivCameraCount: data, swapTechEmail: max_view_technician }, () => {
            });
        }
    }

    //TP-740 -- setting the recording feature button and related parameters on Passive expert 
    //when Primary expert has recording ongoing
    setRecordingStatus = (json) => {
        const {recording_folder_name, is_silent_recording, frame_count} = json.data;
        if (json.data.isrecording_active === true && !this.props.isSafari && !this.props.isFirefox && this.state.hideButton !== 'd-none') { //TP-2262
            //let countString = frame_count.replace(recording_folder_name, ''); //frame_count.substr(0)
            //console.log(frame_count, countString);
            count = parseInt(frame_count);
            isrecording_active = json.data.isrecording_active;
            isSilentRecording = is_silent_recording;
            this.setState({ recFileName: recording_folder_name });
        } else {
            count = 0;
            isrecording_active = json.data.isrecording_active;
            // TP-2262 -- Handling the stopping of Recording if Expert who takes up control is on Safari/Firefox browsers
            isSilentRecording = is_silent_recording;
            if (json.data.isrecording_active === true && (this.props.isSafari || this.props.isFirefox || this.state.hideButton === 'd-none')) {
                this.setState({ recFileName: recording_folder_name }, () => {
                    printConsole("Set the recording file name for platforms which do not support recording");//TP-2098
                });
            } else
                this.setState({ recFileName: ''});
        }
    }

    // remote capture signals
    processSignalExpertRCClicked = (json) => {
        //console.log("EXPERT_RC_CLICKED");
        // for NS2-141
        this.setState({ bRemoteCapture: true, isOngoingMaxDivFeatures: true }, () => {
            this.setState({ isLoading: true, bRCAnnotateOn: true });
        });
    }

    //NS2-345
    // For remote capture signals
    processSignalExpertRCImageUploaded = (json) => {
        // Process the json.data property, if there is any data.
        //console.log(json.from)
        //printConsole("EXPERT_RC_IMAGE_DATA")
        //console.log(g_maxDivId); 
        const {g_subInfoArr} = this.state;                                           
        let strFileName = json.assetData.data;
        const {format} = json.assetData;

        this.setState({ bRCAnnotateOn: false }); // NS2-252
        if (strFileName.length <= 0) {
            printConsole('Expert RC uploaded came back with empty filename');
            // close remote click mode
            //NS2-244
            this.setState({ isLoading: false });
            //this.onClickCamera();
            return;
        }
        this.setState({ strFileName, format });
        //console.log(g_subInfoArr);
        //console.log(this.state.bRemoteCapture);
        g_subInfoArr.forEach(subInfo => {
            if (subInfo.g_divId === g_maxDivId && this.state.bRemoteCapture === true) {
                this.processRemoteClickImageNew(document.getElementById(subInfo.g_divId), strFileName, format);
                return;
            }
        })
    }

    // NS2-493
    // annotation signals
    processSignalAnnotate = (json) => {
        //console.log('signal:ANNOTATE json data: ', json, json.data);
        //TP-2833
        if (this.state.bAnnotate === true || (this.state.bRemoteCapture && this.state.bAnnotate === true)) {
            const dimData = json.data;
            //TP-2474
            const expertDims = typeof dimData === "string" && dimData.includes(":::") === false ? dimData.split('px') : "";
            const pointerDims = typeof dimData === "string" && dimData.includes(":::") === true ? dimData.split(":::") : ""; //TP-2474
            if (this.state.prepareExpertSS === false) {
                if (expertDims.length > 1) {
                    /* if (this.state.bAnnotate === false) {
                        this.setState({ bAnnotate: true, isOngoingMaxDivFeatures: true });
                    } */
                    this.setState({ expertDimX: parseInt(expertDims[0]) });
                    this.setState({ expertDimY: parseInt(expertDims[1]) });
                    //console.log(dimData, this.state.expertDimX, this.state.expertDimY);
                } /**TP-2474*/ else if (pointerDims.length > 1) {
                    this.setState({ 
                        expertDimX: parseInt(pointerDims[0]),
                        expertDimY: parseInt(pointerDims[1]),
                        pointerDimX: parseInt(pointerDims[2]),
                        pointerDimY: parseInt(pointerDims[3])
                    }, () => {
                        //TP-2545
                        let expertMaxdivElement = document.getElementById(g_maxDivId);
                        displayPointerTrack(expertMaxdivElement, pointerDims[4], this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                    });
                }
                else {
                    strCanvasJSON = json.data;
                    let expertMaxdivElement = document.getElementById(g_maxDivId);
                    if (json.data === "") {
                        /* if (this.state.bAnnotate === true) {
                            this.setState({ bAnnotate: false, isOngoingMaxDivFeatures: false });
                        } */
                        clearAnnotationsCanvas(expertMaxdivElement, this.state.bRemoteCapture);
                    } else {
                        onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                    }
                }
            } else {
                //TP-2451 -- Handling of clearing out the Passive canvas if Screen cast is stopped
                if (json.data === "") {
                    strCanvasJSON = json.data;
                    let expertMaxdivElement = document.getElementById(g_maxDivId);
                    clearAnnotationsCanvas(expertMaxdivElement, this.state.bRemoteCapture);
                }                
            }
        } else {
            printConsole("Annotate/RC/LC is not turned on");
        }
    }
    // NS2-493
    // annotation signals
    processSignalAnnotateMultiPart = (json) => {
        try {
            let expertMaxdivElement = document.getElementById(g_maxDivId);
            if (this.state.prepareExpertSS === false) {
                if (json.data === "") {
                    if (this.state.bAnnotate === true) {
                        this.setState({ bAnnotate: false, isOngoingMaxDivFeatures: false });
                    }
                    strCanvasJSON = json.data;
                    clearAnnotationsCanvas(expertMaxdivElement, this.state.bRemoteCapture);
                } else if (json.data.includes("px") === true && json.data.includes(":::") === false) {
                    //console.log("signal:ANNOTATE_MULTI_PART json data: ", json.data);
                    const expertDims = json.data.split('px');
                    if (this.state.bAnnotate === false) {
                        this.setState({ bAnnotate: true, isOngoingMaxDivFeatures: true });
                    }
                    this.setState({ expertDimX: parseInt(expertDims[0]) });
                    this.setState({ expertDimY: parseInt(expertDims[1]) });
                    dataList = [];
                    strCanvasJSON = "";
                } else if (json.data.includes(":::") === true && json.data.includes("px") === false) {
                    const dimData = json.data.split(":::");
                    const maxSize = parseInt(dimData[1]);
                    let currentIndex = parseInt(dimData[0]);
                    if (dataList.length < maxSize && typeof dataList[currentIndex] === 'undefined') //MB2-99
                        dataList = insertAt(dataList, currentIndex, dimData[2]);
                    if (dataList.length === maxSize) {
                        strCanvasJSON = dataList.join('');//NS2-501
                        //console.log(strCanvasJSON);
                        dataList = [];                        
                        onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, this.state.rcImgType);
                    }
                }
            }
        } catch (exception) {
            printConsole('exception occured in Expert side Annotation Multipart listener');
            printConsole(exception);
        }
    }

    // remote capture signals
    processSignalRCAnnotate = (json) => {
        const data = json.data;
        if (data === false ) { //&& this.state.rcImgObj !== ''
            if (this.state.isLoading === true) this.setState({ isLoading: false });
            this.setState({ 
                rcImgObj: '', rcImgType: '', strFileName: '', bRemoteCapture: false,  
                isOngoingMaxDivFeatures: false, pencolor: "red", isFreeDrawing: false, /**TP-2586 */
                isRectActive: false, isCircleActive: false, isArrowActive: false, /**TP-2475 */ isPointerActive: false, isErasorActive: false /*TP-2474*/ }, () => {
                if (this.state.bAnnotate === true) {
                    annotateShapesArray[6] = annotateShapesArray[0];
                    this.setState({ bAnnotate: false });
                }
                strCanvasJSON = "";
                let expertMaxdivElement = document.getElementById(g_maxDivId);
                clearAnnotationsCanvas(expertMaxdivElement, this.state.bRemoteCapture);
                //onDrawPassiveExperts(expertMaxdivElement, otExpObj.state.bRemoteCapture, otExpObj.state.rcImgObj, this.state.rcImgType)
            });// need to reset bRemotecapture for passive experts 
            this.props.updateScreenShareMaxdiv(false); // NS2-376
            this.props.updateFileShareMaxdiv(false); // NS2-376                  
        }
        else if (data === true) {
            this.setState({ pencolor: "red", isFreeDrawing: false, /**TP-2586 */
             isRectActive: false, isCircleActive: false, isArrowActive: false, /**TP-2475 */ isPointerActive: false, isErasorActive: false /*TP-2474*/ })
            if (this.state.bAnnotate === true) {
                annotateShapesArray[6] = annotateShapesArray[0]; //TP-2474
            }
        }
    }

    processSignalRotationForExpert = (json) => {
        const data = JSON.parse(json.data);
        if (this.state.prepareExpertSS === false) {
            this.setState({ rotationArray: json.data });
            const { _x, _y, _z, _w } = data.quaternion
            const eulerRotation = new THREE.Quaternion().copy({ x: _x, y: _y, z: _z, w: _w });
            this.active3dModel && this.active3dModel.quaternion.copy(eulerRotation);
        }
    }

    // TP-841 -- New signal sent to set the opacity of the Technician's File Viewer
    processSignalOpacity = (json) => {
        const data = JSON.parse(json.data);
        if (this.state.prepareExpertSS === false) {
            this.setState({ fileOpacity: data });
        }
    }

    processSignalArtifactsForExpert = (json) => {
        const data = JSON.parse(json.data);
        /* diagonastics(this.authService, {
            action: `artifact signal recieved`,
            next_step: '',
            data,
            error: 'none'
        }) */
        const paramElem = document.getElementById(g_maxDivId);
        const isExpertDiv = isDivExpert(g_maxDivId);
        if (this.state.prepareExpertSS === false)
            this.handleArtifactsOnSubscribers({ data, paramElem, isExpertDiv });
    }

    processSignalShareArtifactsForExpert = (json) => {
        const {assetData} = json
        /* diagonastics(this.authService, {
            action: `artifact signal recieved`,
            next_step: '',
            data,
            error: 'none'
        }) */
        const paramElem = document.getElementById(g_maxDivId);
        const isExpertDiv = isDivExpert(g_maxDivId);
        if (this.state.prepareExpertSS === true) return;
        if (parseInt(assetData.total) > 0) {
            assetsArray.push(assetData);
        } if (/* parseInt(assetData.sequence) === parseInt(assetData.total)-1 || */ assetsArray.length === parseInt(assetData.total)) {
            this.setState({ assetsArray, bShareAsset: true, isOngoingMaxDivFeatures: true,
                //TP-1380 Added to avoid same assets previously roated 3d assets shows for passive 
                rotationArray: (assetData &&  this.state.rotationArray) ? '':this.state.rotationArray,
                fileOpacity: (assetData &&  this.state.rotationArray) ?  "0.60": this.state.fileOpacity }, () => {                
                this.props.updateFileShareMaxdiv(true);
                this.props.updateFileShare(true);
            });
            /* if (!this.state.showHideMaxDivControl && this.state.assetsArray.length > 0) {
                if (this.closeSubscriber3dObject !== null) {
                    this.closeSubscriber3dObject();
                } else {
                    this.remove3dObject();
                }
            } */ 
            const flag = false;
            this.handleArtifactsNew({ assetsArray, paramElem, isExpertDiv, flag });
            assetsArray = [];
        }
    }

    processSignalRemoveArtifactsForExpert = (json) => {
        //printConsole("REMOVE_ARTIFACTS signal received on the Passive Expert");
        //const {data} = json;
        //const data = JSON.parse(json.data);
        if (this.state.prepareExpertSS === false) {  
            //printConsole("Close the open file...");  
            this.setState({ assetsArray: [], fileData: null, /*TP-1423*/bShareAsset: false, rotationArray: '' }, () => {
                this.props.updateFileShareMaxdiv(false);
                this.props.updateFileShare(false);
            });
            if (this.closeSubscriber3dObject) {
                //printConsole("Close 3D file");
                this.closeSubscriber3dObject();
                this.remove3dObject(); //T32-53
            } else {
                //printConsole("Close other files");
                this.remove3dObject();
            }
        }
        const isOngoingMaxDivFeatures = this.isOngoingMaxDivFeaturesExceptZoomAndTorch();
        if (isOngoingMaxDivFeatures === false) this.setState({ isOngoingMaxDivFeatures });
    }

    //Prepare for expert screen share 
    processSignalPrepareExpertSS = (json) => {
        const data = json.data;
        const {g_subInfoArr} = this.state;
        //console.log(data);
        // check if already in SS mode
        if ((this.state.prepareExpertSS && data === true) || (!this.state.prepareExpertSS && data === false))
            return;
        this.setState({ prepareExpertSS: data, isOngoingMaxDivFeatures: data });
        // TP-1452
        let lastViewObj = {}
        if (this.state.gridViewMode === true) {
            lastViewObj = {
                show_grid: this.state.gridViewMode,
                max_view_technician: ''
            }
        } else if (g_maxDivId) {
            g_subInfoArr.forEach(subInfo => {
                if (subInfo.g_divId === g_maxDivId) {
                    const dataSplit = subInfo.g_stream.connection.data.split(":::");
                    lastViewObj = {
                        show_grid: false,
                        max_view_technician: dataSplit[3]
                    }
                }
            })
        }
        this.setState({ lastViewObj });
    }

    //Expert screen share signals
    processSignalStartExpertSS = (json) => {
        // check if already in SS mode
        if (this.state.expertSSMode)
            return;
        this.setState({ expertSSMode: true /* showRc: false */ });        
    }

    // expert screen share stop signal processor method
    processSignalStopExpertSS = (json) => {
        if (!this.state.expertSSMode)
            return;
        this.setState({ expertSSMode: false, prepareExpertSS: false, isOngoingMaxDivFeatures: false });        
    }

    // expert side ui changes for Max-view user's video turned off/on
    processSignalTurnOnMaxViewUserVideo = (json) => {
        const {data} = json;
        //console.log(data);
        let {g_subInfoArr} = this.state;
        let len = g_subInfoArr.length;
        let userVideoCount = 0;
        for(let ii=0; ii<len; ii++) {
            const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
            const [datObj] = data.filter(d => d.userId === parseInt(dataArr[0]));
            //printConsole(datObj);
            if (datObj !== undefined && datObj !== null && g_subInfoArr[ii].g_stream.videoType !== 'screen') {
                /*TP-1996*/if (datObj.flag === true) g_subInfoArr[ii].g_subscriber.subscribeToVideo(!datObj.flag);
                g_subInfoArr[ii].disableVideo = datObj.flag;                
            }            
        }
        printConsole(`changed subscriber list:`);
        printConsole(g_subInfoArr)
        const list = g_subInfoArr.filter(p => p.disableVideo === false && p.g_stream.hasVideo === true && p.g_stream.videoType !== 'screen');
        //printConsole(`list ---------------------------`)
        //printConsole(list);
        if (list !== undefined) {
            printConsole(`Length of Users with Video on -- ${list.length}`)
            userVideoCount = list.length;
        }
        this.updateSessionJoinees(g_subInfoArr);

        this.setState({g_subInfoArr, userVideoCount}, () => {
            /* this.setState({isParticipantChanged : true}, () => {
                this.setState({isParticipantChanged : false});
            }); */// to change the Side Bar button state 
            if (this.state.prepareExpertSS === true) {
                this.drawScreenShareUI( {divId: g_maxDivId } );
            }
            else if (this.state.gridViewMode === true){
                this.drawSubscriberUI(window.innerHeight, window.innerWidth, null);
            }
            else {
                //const maxdivUser = g_subInfoArr.filter(subInfo => subInfo.g_divId === g_maxDivId);
                //this.setState({ showHideDivIconAudioOnly: !maxdivUser.disableVideo }); 
                if (userVideoCount === 0) this.setState({ showHideDivIconAudioOnly: true });
                else this.setState({ showHideDivIconAudioOnly: false });
            }
        })
    }


    /** Processor Methods for Secondary Experts role ends */

    //Handler for refreshing the Expert side Participant Names list
    updateSessionJoinees = (g_subInfoArr) => {
        //const {g_subInfoArr} = this.state;
        //printConsole("Update session joinees ");
        let sessionJoinees = [];
        let joineeObj = {};
        g_subInfoArr.forEach(subInfo => {
            if (subInfo.g_stream.videoType !== 'screen') {
                let { data } = subInfo.g_stream.connection;
                const token_data = data.split(':::');
                const isExpert = token_data[4];
                const name = isExpert === 'true' ? ('* ' + token_data[1] + ' ' + token_data[2]) :
                (token_data[1] + ' ' + token_data[2]);
                const userId = token_data[0];
                joineeObj = {
                    userId,
                    disableVideo: subInfo.disableVideo,
                    disableAudio: subInfo.disableAudio,
                    disableMike: subInfo.disableMike,
                    disableRemoteAudio: subInfo.disableRemoteAudio,//T32-413
                    name
                }
                sessionJoinees.push(joineeObj);
            }
        })
        this.setState({
            sessionJoinees
        }, () => {
            this.props.onSetSessionJoinees(this.state.sessionJoinees);
        });
    }

    // update each of the session Joinee elements in the array
    updateEachSessionJoinee = (subInfo) => {
        let { data } = subInfo.g_stream.connection;
        const token_data = data.split(':::');
        const isExpert = token_data[4];
        const name = isExpert === 'true' ? ('* ' + token_data[1] + ' ' + token_data[2]) :
        (token_data[1] + ' ' + token_data[2]);
        const userId = token_data[0];
        const joineeObj = {
                userId,
                disableVideo: subInfo.disableVideo,
                disableAudio: subInfo.disableAudio,
                disableMike: subInfo.disableMike,
                disableRemoteAudio: subInfo.disableRemoteAudio, //T32-413
                name
            }
        const key = this.state.sessionJoinees.findIndex(e => e.name === name);
        let { sessionJoinees } = this.state;
        sessionJoinees[key] = joineeObj;
        
        // Set the new Joinees array
        this.setState({
            sessionJoinees
        }, () => {
            this.props.onSetSessionJoinees(this.state.sessionJoinees);
        });
    }

    isOngoingPrimaryExpertOperationsExceptionGridView = () => {
        //const { g_subInfoArr, gridViewMode, bAnnotate, bRemoteCapture, bShareAsset, bZoomClick, bTorchClick, techShareScreen, expertShareScreenMaxDiv, startRecording } = this.state;
        let flag = false;
        //if (gridViewMode && g_subInfoArr.length > 1) return true;        
        //if (bAnnotate) flag = true;
        //if (bRemoteCapture) flag = true;
        //if (bShareAsset) flag = true;//NS2-457
        //if (bZoomClick) flag = true;
        //if (bTorchClick) flag = true;
        //if (techShareScreen) flag = true;
        //if (expertShareScreenMaxDiv) flag = true;//NS2-457
        //if (startRecording) flag = true;
        return flag;
    }

    // for NS2-375
    isOngoingPrimaryExpertOperations = () => {
        const { g_subInfoArr, gridViewMode, bAnnotate, bRemoteCapture, bShareAsset, bZoomClick, bTorchClick, techShareScreen, expertShareScreenMaxDiv, startRecording } = this.state;
        let flag = false;
        if (gridViewMode && g_subInfoArr.length > 1) return true;        
        if (bAnnotate) flag = true;
        if (bRemoteCapture) flag = true;
        if (bShareAsset) flag = true;//NS2-457
        if (bZoomClick) flag = true;
        if (bTorchClick) flag = true;
        if (techShareScreen) flag = true;
        if (expertShareScreenMaxDiv) flag = true;//NS2-457
        if (startRecording) flag = true;
        return flag;
    }

    isOngoingMaxDivFeatures = () => {
        const { showControlRequestDialog, bAnnotate, bRemoteCapture, bShareAsset, bZoomClick, bTorchClick, techShareScreen, expertShareScreenMaxDiv, expertSSAll, prepareExpertSS, screenCaptureClicked, startRecording } = this.state;
        let flag = false;
        if (showControlRequestDialog) return true;
        if (expertShareScreenMaxDiv) flag = true;//NS2-457        
        if (expertSSAll) flag = false;
        if (bAnnotate) flag = true;
        if (bRemoteCapture) flag = true;
        if (bShareAsset) flag = true;//NS2-457
        if (bZoomClick) flag = true;
        if (bTorchClick) flag = true;
        if (techShareScreen) flag = true;
        if (prepareExpertSS) flag = true;
        if (screenCaptureClicked) flag = true;
        if (startRecording) flag = true;
        return flag;
    }

    isOngoingMaxDivFeaturesExceptZoomAndTorch = () => {
        const { showControlRequestDialog, bAnnotate, bRemoteCapture, bShareAsset, techShareScreen, expertShareScreenMaxDiv, expertSSAll, prepareExpertSS, screenCaptureClicked } = this.state;
        let flag = false;
        if (showControlRequestDialog) return true;
        if (expertShareScreenMaxDiv) flag = true;//NS2-457        
        if (expertSSAll) flag = false;
        if (bAnnotate) flag = true;
        if (bRemoteCapture) flag = true;
        if (bShareAsset) flag = true;//NS2-457        
        if (techShareScreen) flag = true;
        if (prepareExpertSS) flag = true;
        if (screenCaptureClicked) flag = true;
        /* if (startRecording) flag = true; */
        return flag;
    }

    isOngoingMaxDivFeaturesForDenyControl = () => {
        const { bAnnotate, bRemoteCapture, bShareAsset, techShareScreen, expertShareScreenMaxDiv, expertSSAll, prepareExpertSS, screenCaptureClicked } = this.state;
        let flag = false;
        if (expertShareScreenMaxDiv) flag = true;//NS2-457        
        if (expertSSAll) flag = false;
        if (bAnnotate) flag = true;
        if (bRemoteCapture) flag = true;
        if (bShareAsset) flag = true;//NS2-457        
        if (techShareScreen) flag = true;
        if (prepareExpertSS) flag = true;
        if (screenCaptureClicked) flag = true;
        /* if (startRecording) flag = true; */
        return flag;
    }

    isOngoingPassiveExpertFeatures = () => {
        const { bRemoteCapture, bZoomClick, bTorchClick } = this.state;
        let flag = false;
        if (bRemoteCapture) flag = true;
        if (bZoomClick) flag = true;
        if (bTorchClick) flag = true;
        if (document.getElementById('canvas') !== null && document.getElementById('canvas') !== undefined) flag = true;
        if (document.getElementById('canavas-3d-container') !== null) flag = true;
        return flag;
    }

    // Expert side private Methods and Signal emitters
    checkChatUser = (from, g_subInfo, g_maxDivId) => {
        let pubData = from;
        if (this.state.bAllChat !== true) {
            if (g_subInfo.length > 1) {
                g_subInfo.forEach(subInfo => {
                    const subInforArr = subInfo.g_stream.connection.data.split(":::");
                    if (this.state.gridViewMode === false && subInforArr[3] === from.email && (from.isexpert === false || from.isExpert === false) && subInfo.g_divId !== g_maxDivId) { 
                        let pubDataEmail = pubData.email;
                        let pubDataName = pubData.firstName + ' ' + pubData.lastName;
                        let chatUserEmailArr = this.state.chatUserEmails;
                        if ((chatUserEmailArr.indexOf(pubDataEmail)) < 0) {
                            this.setState(prev => ({
                                chatUserEmails: [
                                    ...prev.chatUserEmails,
                                    pubDataEmail
                                ]
                            }), () => {
                                if (this.state.chatUserEmails.length > 0) {
                                    //printConsole(`chat user emails length ${this.state.chatUserEmails.length}`);
                                    this.setState(prev => ({
                                        chatUserNames: [
                                            ...prev.chatUserNames,
                                            pubDataName
                                        ]
                                    }), () => {
                                        this.setChatJoinees(this.state.chatUserNames);
                                    });
                                }
                            });
                        } else {
                            printConsole("User already added in the list of previously sent chats");
                        }
                    }
                })

            }
        }
    }

    setChatJoinees = (nameArr) => {
        this.props.onSetChatJoinees(nameArr);
    }

    updateChatFlags = (val) => {
        this.props.updateChatAll(false);
        this.setState({ 
            bMaxChat: val, 
            bAllChat: val, 
            startChatTimer: val, 
            chatUserEmails: [],
            chatUserNames: [] 
        });
    }

    turnOnAnnotate = (bRemoteCap, urlObj) => {
        //console.log("turn on/off Annotate..");
        //const controlpanel = document.getElementById('controlpanel');
        let sketchPicker, shapeselector;
        if (this.state.bHideAnnotateColour === false) {
            sketchPicker = document.getElementById('sketchPicker');
        }
        if (this.state.bHideAnnotateShapes === false) {
            shapeselector = document.getElementById('shapeselector');
        }
        if (urlObj !== null && this.state.bAnnotate === true)
            return; // avoid dupes
        if (this.state.bAnnotate === true)
            this.setState({ bAnnotate: false, isOngoingMaxDivFeatures: (this.state.expertShareScreenMaxDiv && this.state.expertSSAll === false) ? true : false }, () => {
                if (document.getElementById('canvas') === null) {
                    printConsole('There is no window to handle annotation!!');
                    return;
                } else {
                    printConsole("Close the Annotations");
                    // NS2-217
                    if (this.state.bHideAnnotateColour === false && sketchPicker !== null && sketchPicker !== undefined)
                        sketchPicker.style.display = 'none';
                    if (this.state.bHideAnnotateShapes === false && shapeselector !== null && shapeselector !== undefined)
                        shapeselector.style.display = 'none';
                    const {isPointerActive} = this.state;
                    this.setState({
                        bSelectColor: false,
                        bSelectShape: false,
                        pencolor: "red",
                        isFreeDrawing: false, /**TP-2586 */
                        isRectActive: false,
                        isCircleActive: false,
                        isArrowActive: false,
                        isPointerActive: false, /**TP-2475 */
                        isErasorActive: false //TP-2474
                    }, () => {
                        printConsole("Clear the Annotations");
                        //TP-2961
                        if (isPointerActive)
                            onClickPointerTrack(document.getElementById(g_maxDivId)); //TP-2474
                        strCanvasJSON = "";
                        onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj);
                        removeCanvasEvents() //TP-2474
                    });
                }
            });
        else {  //if(this.state.bAnnotate === false)
            annotateShapesArray[6] = annotateShapesArray[0]; //TP-2475
            this.setState({ bAnnotate: true, isOngoingMaxDivFeatures: true  }, () => {
                // NS2-217                
                this.setState({
                    bSelectColor: false,
                    bSelectShape: false,
                    pencolor: "red",
                    isFreeDrawing: false, /**TP-2586 */
                    isRectActive: false,
                    isCircleActive: false,
                    isArrowActive: false,
                    isPointerActive: false, /**TP-2475 */
                    isErasorActive: false //TP-2474
                }, () => {
                    if (!this.props.sessionInfo.annotation_shape_enabled) {
                        //TP-2853
                        if (bRemoteCap === true) {
                            strCanvasJSON = "";
                            onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj, true);
                            this.rcLoaderTimer = setTimeout(() => {
                                this.setState({ isLoading: true, annotateLoader: true }, () => {
                                        this.annTimer = setTimeout(() => {                                        
                                            this.onClickCrossHairsIcon(); 
                                            clearTimeout(this.annTimer);
                                        }, 2000);
                                });
                                clearTimeout(this.rcLoaderTimer);                               
                            }, 20);

                        } else {
                            strCanvasJSON = "";
                            onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj, true);
                        }
                    } else {
                        strCanvasJSON = "";
                        onClickDraw(document.getElementById(g_maxDivId), bRemoteCap, urlObj, true);
                    }
                    //onClickPointerTrack(document.getElementById(g_maxDivId)); //TP-2474
                    //TP-2823
                    if (!this.props.sessionInfo.annotation_shape_enabled) {
                        //TP-2853
                        if (bRemoteCap === false) {
                            this.setState({ annotateLoader: true, isLoading: true });
                            this.annTimer = setTimeout(() => {
                                this.onClickCrossHairsIcon(); 
                                clearTimeout(this.annTimer);
                            }, 2000);
                        }
                    }
                });
            });
        }
    }

    startAnnotate = () => {
        const {bRemoteCapture, bHideAnnotateColour, bHideAnnotateShapes, urlObj} = this.state;
        let sketchPicker, shapeselector;
        if (bHideAnnotateColour === false) {
            sketchPicker = document.getElementById('sketchPicker');
        }
        if (bHideAnnotateShapes === false) {
            shapeselector = document.getElementById('shapeselector');
        }
        annotateShapesArray[6] = annotateShapesArray[0]; //TP-2475
        this.setState({ isOngoingMaxDivFeatures: true  }, () => {
            // NS2-217                
            this.setState({
                bSelectColor: false,
                bSelectShape: false,
                pencolor: "red",
                isFreeDrawing: false, /**TP-2586 */
                isRectActive: false,
                isCircleActive: false,
                isArrowActive: false,
                isPointerActive: false, /**TP-2475 */
                isErasorActive: false //TP-2474
            }, () => {
                if (sketchPicker)
                    sketchPicker.style.display = 'none';
                //TP-2491
                    /* if (shapeselector)
                    shapeselector.style.display = 'none'; */
                onClickDraw(document.getElementById(g_maxDivId), bRemoteCapture, urlObj, true);
                //TP-2823
                if (!this.props.sessionInfo.annotation_shape_enabled) {
                    //TP-2853
                    this.setState({ annotateLoader: true, isLoading: true });
                    this.annTimer = setTimeout(() => {
                        this.onClickCrossHairsIcon(); 
                        clearTimeout(this.annTimer);
                    }, 2000);
                }
            });
        });
    }

    processRemoteClickImage = (paramElem, fileName) => {
        // download the s3 file
        AWS.config.update(
            {
                bucketName: 'telepresenz-20-p2pfiles',
                dirName: 'boo', /* optional */
                region: 'us-east-2',
                accessKeyId: 'dummy-aws-access-key',//TP-6973
                secretAccessKey: 'dummy-aws-secret-access-key',//TP-6973
            }
        );

        //let elemRemoteCaptureImg = getRemoteCaptureElement(paramElem);
        let s3 = new AWS.S3();
        //console.log('s3 = ', s3);
        s3.getObject(
            { Bucket: "telepresenz-20-p2pfiles/boo", Key: fileName },
            function (error, data) {
                if (error != null) {
                    printConsole(`Failed to retrieve a remote image object: ${error}`);
                } else {
                    if (otExpObj.state.bRemoteCapture === true) {
                        let blob = new Blob([data.Body], { type: data.ContentType });
                        let urlObj = URL.createObjectURL(blob);
                        if (otExpObj.state.showHideMaxDivControl === true) {
                            //NS2-217
                            otExpObj.setState({ urlObj: urlObj });
                            otExpObj.startRemoteCaptureAnnotation(paramElem);
                            otExpObj.turnOnAnnotate(true, urlObj);

                        } else {
                            // get the frame image from the publisher stream
                            // NS2-345
                            otExpObj.setState({ rcImgObj: [data.Body], rcImgType: data.ContentType }, () => { //urlObj
                                // turn on annotation on passive Experts
                                strCanvasJSON = "";
                                let expertMaxdivElement = document.getElementById(g_maxDivId);
                                onDrawPassiveExperts(expertMaxdivElement, otExpObj.state.bRemoteCapture, otExpObj.state.rcImgObj);                                
                            });
                        }
                    }
                }
                otExpObj.setState({ isLoading: false });
                otExpObj.setState({ sRCTechDetails: '' });
            }
        );
    } 
    
    processRemoteClickImageNew = (paramElem, fileName, format) => {
        if (this.state.bRemoteCapture === true) {
            
            if (this.state.showHideMaxDivControl === true) {
                const contentType = 'image/'+format;
                fileName = fileName.replace('data:'+contentType+';base64,', '').trim();
                // Primary expert urlObj is processed
                const blob = new base64StringToBlob(fileName, contentType);// MB2-556
                //printConsole(blob);
                let urlObj = URL.createObjectURL(blob);
                //NS2-217
                this.setState({ urlObj: urlObj });
                this.startRemoteCaptureAnnotation(paramElem);
                this.turnOnAnnotate(true, urlObj);

            } else {
                // get the frame image from the publisher stream
                // NS2-345
                this.setState({ rcImgObj: fileName, rcImgType: format, bAnnotate: this.state.bRemoteCapture, 
                    pencolor: "red", isFreeDrawing: false, /**TP-2586 */ isRectActive: false, isCircleActive: false, 
                    isArrowActive: false, /**TP-2475 */ isPointerActive: false, isErasorActive: false /*TP-2474*/ }, () => { //urlObj
                    // turn on annotation on passive Experts
                    annotateShapesArray[6] = annotateShapesArray[0]; //TP-2474
                    strCanvasJSON = "";
                    let expertMaxdivElement = document.getElementById(g_maxDivId);
                    onDrawPassiveExperts(expertMaxdivElement, this.state.bRemoteCapture, this.state.rcImgObj, format);                                
                });
            }
            this.setState({ isLoading: false });
            this.setState({ sRCTechDetails: '' });
        }
    }

    remoteClick = (msgToSend, elemId) => {
        const {g_session, g_subInfoArr} = this.state; 
        // send this message out
        if (g_session !== null && g_subInfoArr.length > 0) {
            g_subInfoArr.forEach(subInfo => {
                if (elemId === null || subInfo.g_divId === elemId.id) {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3];
                    this.setState({ isLoading: true });
                    //this.showLoader('Capturing...');

                    //console.log('divId matched up');
                    this.sessionSignal.sendSignalRemoteCaptureClick(
                        msgToSend,
                        toObject
                        //type:"REMOTECLICK"                        
                    );
                    //console.log("remote click signal sent...");                    
                    return;
                }
            })
        }

    }

    stopRemoteCaptureAnnotation = (elemId) => {
        const {g_session, g_subInfoArr} = this.state;
        if (g_session !== null && g_subInfoArr.length > 0) {
            g_subInfoArr.forEach(subInfo => {
                if (elemId === null || subInfo.g_divId === elemId.id) {
                    //console.log('divId matched up');
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalRCAnnotation(
                        false,
                        toObject
                        //type:"STOPREMOTECAPTUREANNOTATE"                        
                    );
                    //console.log("STOPREMOTECAPTUREANNOTATE signal sent to technician...");
                    return;
                }
            })
        }
    }   

    startRemoteCaptureAnnotation = (elemId) => {
        const {g_session, g_subInfoArr} = this.state;
        if (g_session !== null && g_subInfoArr.length > 0) {
            g_subInfoArr.forEach(subInfo => {
                if (elemId === null || subInfo.g_divId === elemId.id) {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3] ;

                    //console.log('divId matched up');
                    this.sessionSignal.sendSignalRCAnnotation(
                        true,
                        toObject
                        //type:"STARTREMOTECAPTUREANNOTATE"
                    );
                    //console.log("STARTREMOTECAPTUREANNOTATE signal sent to technician...");
                    return;
                }
            })
        }
    }

    
    // send show/hide tech rc button signal
    showHideRcForTech = (flag) => {
        let nLen = g_subInfoArr.length;
        let ii = 0;
        for (ii = 0; ii < nLen; ii++) {
            if (g_subInfoArr[ii].g_divId === g_maxDivId && this.state.showHideMaxDivControl) //NS2-342
            {
                let maxDivStream = g_subInfoArr[ii].g_stream
                let maxDivConObj = maxDivStream.connection;
                this.techRcShowSignal(flag, maxDivConObj);
                return;
            }
        }
    }

    // send signal "MAX_VIEW" to all or specific technician
    sendSignalMaxViewTech = (flag, techConObj=null) => {
        if (techConObj === null) {
            let nLen = g_subInfoArr.length;
            let ii = 0;
            for (ii = 0; ii < nLen; ii++) {
                if (this.state.showHideMaxDivControl) //NS2-342
                {
                    let maxDivStream = g_subInfoArr[ii].g_stream
                    let maxDivConObj = maxDivStream.connection;
                    this.techRcShowSignal(flag, maxDivConObj);                
                }
            }
        } else {
            if (this.state.showHideMaxDivControl) //NS2-342
            {
                this.techRcShowSignal(flag, techConObj);    
                return;            
            }
        }
    }

    // send the rc show hide signal
    // added data as true/false NS2-120
    techRcShowSignal = (flag, connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = toArray[3];
            this.sessionSignal.sendSignalShowLocalRC(flag, toObject);
            //console.log(`signal sent - for = MAX_VIEW to ${toObject}`);
        } catch (exception) {
            printConsole(exception);
        }
    }

    queryRCSupport = (connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = toArray[3];
            this.sessionSignal.sendSignalQueryRCSupport(
                "",
                toObject
                //type:"CONFIRMRCSUPPORT"
            );
            //console.log("remote click capability query sent...");
        }
        catch (exception) {
            printConsole(exception);
            alert('exception occured');
        }
    }

    // send the individual mute/unmute expert mike signal
    muteExpertMikeForMaxDiv = (muteMikeFortech, connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = toArray[3];
            this.sessionSignal.sendSignalMuteExpertMike(
                muteMikeFortech,
                toObject
                //type:"MUTE_EXPERT_AUDIO"
            );
            //console.log("Expert mute command sent...");
        }
        catch (exception) {
            printConsole(exception);
            alert('exception occured in ExpertMute');
        }
    }

    // To be called from the Side bar "Mute Mike" for all
    sendExpertMuteAllCmd = (muteMikeFortech) => {
        try {
            this.sessionSignal.sendSignalMuteExpertMike(

                //from: g_session.connection.data,
                muteMikeFortech,
                //type:"MUTE_EXPERT_AUDIO"

            );
            // console.log("Expert mute command sent...",muteMikeFortech);
        }
        catch (exception) {
            printConsole('exception occured in ExpertMuteAll');
            printConsole(exception);
        }
    }

    muteUnmuteMaxDiv = () => {
        this.setState(prevState => ({
            openMikeDialog: false,
            muteMikeForMaxDiv: !prevState.muteMikeForMaxDiv
        }),() => {
            this.muteExpertMikeInOutMaxDiv(!this.state.muteMikeForMaxDiv);
        });
    }

    // mute/unmute expert mike for single tech
    muteExpertMikeInOutMaxDiv = (muteMicFlag) => {
        let nLen = g_subInfoArr.length;
        let ii = 0;
        for (ii = 0; ii < nLen; ii++) {
            if (g_subInfoArr[ii].g_divId === g_maxDivId) {
                g_subInfoArr[ii].disableMike = muteMicFlag;
                let maxDivStream = g_subInfoArr[ii].g_stream
                let maxDivConObj = maxDivStream.connection;
                //set the changed session Joinee in the array
                this.updateEachSessionJoinee(g_subInfoArr[ii]);

                this.setState({ g_subInfoArr, maxDivTechName: maxDivStream.name }, () => {
                    this.setState({isParticipantChanged : true}); // to Set the Side bar Silhouette Icon's states
                    this.setState({isParticipantChanged : false});
                });
                this.muteExpertMikeForMaxDiv(muteMicFlag, maxDivConObj);
            }
        }
    } 
    
    processMultiPartAnnotation = (annotateString, objSize, elemId) => {

        let dataList = chunkString(annotateString, 7000);
        let param = "";
        for (let i = 0; i < dataList.length; i++) {
            param = (i + 1) + ":::" + (dataList.length) + ":::" + dataList[i];
            //console.log(param)
            this.sendAnnotationMultipart(param, elemId);
            this.sendMultipartAnnotationToExpert(param); //NS2-493
        }
    }

    // "#&artify&#5537331063634575687238455everyone5537331063634575687238455CarCheck1.jpg"
    sendAnnotation = (annotationObj, elemId) => {
        //console.log(JSON.stringify(annotationObj));       
        //console.log(lengthInUtf8Bytes(JSON.stringify(annotationObj)));
        if (typeof annotationObj === "string"){
            //console.log("Expert screen dimensions", annotationObj);
        }
        // send this message out 
        const {g_session, g_subInfoArr} =this.state;
        /* if (lengthInUtf8Bytes(JSON.stringify(annotationObj)) > 8192) {
            console.log('annotation data size exceeds limit');            
            return;
        } */
        if (g_session !== null && g_subInfoArr.length > 0) {
            let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
            g_subInfoArr.forEach(subInfo => {
                if (elemId === null || subInfo.g_divId === elemId.id) {
                    //console.log('divId matched up');
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalAnnotate(                        
                        annotationObj,
                        toObject,
                        sixdigitsrandom
                        //type:"ANNOTATE"
                    );
                    //console.log("annotation signal sent...");                            
                    return;
                }
            })
        }
    }

    // for NS2-345
    sendAnnotationToExpert = (annotationObj) => {
        //console.log(annotationObj);
        // send this message out
        const {g_session} = this.state;
        let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); //MB2-95
        /* if (lengthInUtf8Bytes(JSON.stringify(annotationObj)) > 8192) {
            console.log('annotation data size exceeds limit');
            return;
        } */
        if (this.state.showHideMaxDivControl === true && g_session !== null && g_expertConnsArr.length > 0) {
            g_expertConnsArr.forEach(expertInfo => {
                //TP-2833
                if (g_session.connection !== expertInfo) {
                    const toArray = expertInfo.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalAnnotate(
                        annotationObj,
                        toObject,
                        sixdigitsrandom
                        //type: "ANNOTATE"
                    );
                    //console.log("expert annotate signal sent...");
                    //return;
                }
            })
        }
    }

    clearAnnotations = () => {
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
            g_bCleanSlate = true;

            let canvas = drawElem.fabric;
            //let canvasObjects = canvas.getObjects('path');
            let canvasObjects = canvas.getObjects();
            //console.log('path objects = ', canvasObjects.length);
            //console.log(canvasObjects);
            let idx = 0;
            for (idx = 0; idx < canvasObjects.length; idx++)
                canvas.remove(canvasObjects[idx]);
            //canvasObjects = canvas.getObjects('path');
            return true;
        }
        return false;
    }

    //TP-2474 -- Function to trigger sending annotation string/JSON again to all Experts only
    // When Passive becomes Primary expert
    triggerSendAnnotation = () => {
        //console.log("Trigger send Annotations...");
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined && drawElem.fabric !== undefined) { //TP-2545                       
            const canvas = drawElem.fabric;
            if (g_maxDivId !== null) {
                let strDim = canvas.width+"px";
                strDim += canvas.height+"px";
                //console.log(strDim);
                //console.log(typeof(strDim));
                this.sendAnnotationToExpert(strDim);
            }              
    
            //strCanvasJSON = canvas.toJSON();
            let obj = canvas.getObjects();
            //TP-2809 -- Remove the POinter object from the canvas Json before sending it to all other experts
            for(let index = 0; index < obj.length; index++){
                if (obj[index].type === "line" && obj[index].id === "pointer_line_h") canvas.remove(obj[index]);
                if (obj[index].type === "line" && obj[index].id === "pointer_line_y") canvas.remove(obj[index]);
                if (obj[index].type === "circle" && obj[index].id === "pointer_circle") canvas.remove(obj[index]); //TP-2828
            }
            let json = canvas.toJSON();
            //TP-2794
            let finalJson = {
                objects: json.objects, //TP-2809
                version: json.version
            }
            this.sendAnnotationToExpert(finalJson);
        }
    }

    //TP-2474 -- Function to clear out the Pointer Annotations when Primary gives up control and becomes Passive expert
    clearPointerAnnotation = () => {
        let drawElem = document.getElementById('canvas');
        if (drawElem !== null && drawElem !== undefined && drawElem.style.display === 'block') {
            const paramElem = document.getElementById(g_maxDivId);
            let canvas = drawElem.fabric;   
            canvas.off("mouse:over");
            canvas.off("mouse:out");
            stopAnnotationTimer();
            canvas.off("mouse:move");
            pointerX = "-1";
            pointerY = "-1";
            const string = canvas.width + ":::" + canvas.height + ":::" + pointerX + ":::" + pointerY + ":::red" ;
            this.sendAnnotationToExpert(string); //NS2-345
            this.sendAnnotation(string, paramElem);
        }
    }

    sendAnnotationMultipart = (annotationObj, elemId)=> {
        const {g_session, g_subInfoArr} = this.state;
        if (g_session !== null && g_subInfoArr.length > 0) {
            g_subInfoArr.forEach(subInfo => {
                if (elemId === null || subInfo.g_divId === elemId.id) {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalAnnotateMultipart(
                        annotationObj,
                        toObject
                        //type: "ANNOTATE_MULTI_PART"
                    );
                    //console.log("annotation multipart signal sent...");
                    return;
                }
            })
        }
    }

    // NS2-493
    sendMultipartAnnotationToExpert = (annotationObj)=> {
        //console.log(annotationObj);
        const {g_session} = this.state;
        // send this message out
        if (this.state.showHideMaxDivControl === true && g_session !== null && g_expertConnsArr.length > 0) {
            g_expertConnsArr.forEach(expertInfo => {
                const toArray = expertInfo.data.split(":::");
                const toObject = toArray[3];
                this.sessionSignal.sendSignalAnnotateMultipart(
                    annotationObj,
                    toObject
                    //type: "ANNOTATE_MULTI_PART"
                );
                //console.log("expert annotate multipart signal sent...");
                return;
            })
        }
    }
    /*  End of the Multipart Annotation Implementation **/

    // function to send the Technician Torch on/off signal
    // Only on the Expert side
    sendSignalTechnicianTorch = (data, connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = toArray[3];
            this.sessionSignal.sendSignalRemoteTorch(
                data,
                toObject
                //type: "TECHNICIAN_TORCH_ON"                
            );
            //console.log(" Technician torch command sent with data:", data);
        }
        catch (exception) {
            printConsole(exception);
            printConsole('exception occured in send technician torch on/off function');
        }
    }

    sendSignalRemoteZoomLevel = (data, connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = toArray[3];
            this.sessionSignal.sendSignalRemoteZoom(
                data,
                toObject
                //type: "REMOTE_ZOOM_LEVEL"
            );
            //console.log(" Remote Zoom Level command sent with data:", data);
        }
        catch (exception) {
            printConsole(exception);
            printConsole('exception occured in send remote zoom level function');
        }
    }    

    sendAttachment = (msgToSend, elemId) => {
        const {g_session, g_subInfoArr} = this.state;
        //console.log('sendAttachment called with: ', msgToSend, elemId, g_subInfoArr, g_session, g_pubConnsArr);
        // send this message out
        if (g_session !== null && g_subInfoArr.length > 0) {
            if (!elemId) {                    
                this.sessionSignal.sendSignalArtifacts(
                    msgToSend
                    //type: "ARTIFACTS"
                );
                //console.log("artifacts signal sent...");
                return;
            } else  {
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.g_divId === elemId.id) {
                        const toArray = subInfo.g_stream.connection.data.split(":::");
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalArtifacts(
                            msgToSend,
                            toObject
                            //type: "ARTIFACTS"
                        );
                        //console.log("artifacts signal sent...");
                        return;
                    }
                })
            }
        }
    }

    sendAttachmentNew = (fileData, elemId) => {
        const {g_session, g_subInfoArr} = this.state;
        this.setState({fileData}); // Setting the current Share asset payload
        //console.log('sendAttachmentNew called with: ', fileData, elemId, g_subInfoArr, g_session);
        // send this message out
        if (bCancelShare === false && g_session !== null && g_subInfoArr.length > 0) {            
            if (elemId) {
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.g_divId === elemId.id){
                        const toArray = subInfo.g_stream.connection.data.split(":::");
                        const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                        this.sessionSignal.sendSignalShareArtifacts(
                            //from: g_session.connection.data,
                            fileData,
                            toObject.email
                            //type: "SHARE_ARTIFACTS"

                        );
                        //printConsole(`share artifacts signal sent to user... ${toObject.email}`);
                        //break;
                    }
                });
                g_expertConnsArr.forEach(expertInfo => {
                    const toArray = expertInfo.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalShareArtifacts(
                        //from: g_session.connection.data,
                        fileData,
                        toObject
                        //type: "SHARE_ARTIFACTS"

                    );
                    printConsole(`share artifacts signal sent to all experts... `);
                })
            } else if (!elemId) {
                g_subInfoArr.forEach(subInfo => {
                    //if (subInfo.disableVideo === false){
                        const toArray = subInfo.g_stream.connection.data.split(":::");
                        const toObject = { userId: parseInt(toArray[0]), firstName: toArray[1], lastName: toArray[2], email: toArray[3], isexpert: JSON.parse(toArray[4]) };
                        this.sessionSignal.sendSignalShareArtifacts(
                            //from: g_session.connection.data,
                            fileData,
                            toObject.email
                            //type: "SHARE_ARTIFACTS"

                        );
                        //printConsole("share artifacts signal sent to user... ", toObject.email);                        
                    //}
                });
                //printConsole("share artifacts signal sent to all participants whose Video is turned on...");                
            }
            // T32-592 -- Syncing of the Passive expert's view controls based while File share is active
            // Disable the Hand Icons while file share is active
            if (this.state.gridViewMode === true){
                this.sendSignalSyncExperts({
                    show_grid: true,
                    hand_icon_enabled: true,
                    header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                    reason: "show_action"}, 
                null);
            } else {
                const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, /**TP-2475 */ isPointerActive, pencolor} = this.state;
                let shape_selected = "";
                if (isFreeDrawing === true) shape_selected = "freehand";
                else if (isRectActive === true) shape_selected = "rectangle";
                else if (isCircleActive === true) shape_selected = "circle";
                else if (isArrowActive === true) shape_selected = "arrow";
                else if (isPointerActive === true) shape_selected = "pointer"; /**TP-2586 & TP-2475 */
                g_subInfoArr.forEach(subInfo => {
                    if (subInfo.g_divId === g_maxDivId){
                        const dataArr = subInfo.g_stream.connection.data.split(":::");
                        this.sendSignalSyncExperts({
                            show_grid: false,
                            max_view_technician: dataArr[3], 
                            annotation_active: this.state.bAnnotate, 
                            zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                            zoom_level: subInfo.zoomLevel,
                            torch_button_active: subInfo.torchActive,
                            annotate_color: pencolor,
                            shape_selected,
                            hand_icon_enabled: true,
                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                            reason: "show_action"}, 
                        null);
                    }
                });
            }
        } else {
            bCancelShare = false;
            this.setState({ isLoading: false });
            return;
        }       
    }

    send3dRotation = (rotationsObj) => {
        const {g_session, g_subInfoArr, shareOnDiv} = this.state;
        if (this.state.showHideMaxDivControl && g_session !== null && g_subInfoArr.length > 0) {
            this.setState({ rotationArray: rotationsObj });
            g_subInfoArr.forEach(subInfo => {
                if (shareOnDiv === null || shareOnDiv === '' || shareOnDiv.id === subInfo.g_divId) {
                    // if (subInfo.g_divId === elemId) {
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignal3DRotation(
                        rotationsObj,
                        toObject,
                        //type: "ROTATION"
                    );
                    //console.log("signal sent.");
                    //return;
                    // }
                }
            })

            if (shareOnDiv !== null && g_expertConnsArr.length > 0) {
                g_expertConnsArr.forEach(expertInfo => {
                    const toArray = expertInfo.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignal3DRotation(
                        rotationsObj,
                        toObject,
                        //type: "ROTATION"
                    );
                })
            }
        }
    }

    // TP-841 -- to send the current File Viewer Opacity value too all the other users
    // in that OT session
    sendOpacity = (opacityValue) => {
        const {g_session, g_subInfoArr} = this.state;
        if (this.state.showHideMaxDivControl && g_session !== null && g_subInfoArr.length > 0) {            
            /* g_subInfoArr.forEach(subInfo => {
                if (shareOnDiv === null || shareOnDiv === '' || shareOnDiv.id === subInfo.g_divId) {
                    //console.log(typeof(opacityValue));
                    const toArray = subInfo.g_stream.connection.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalOpacity(
                        opacityValue,
                        toObject,
                        //type: "OPACITY"
                    );
                    //console.log("signal sent.");
                    //return;
                }
            }) */

            if (/* shareOnDiv !== null && */ g_expertConnsArr.length > 0) {
                g_expertConnsArr.forEach(expertInfo => {
                    const toArray = expertInfo.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalOpacity(
                        opacityValue,
                        toObject,
                        //type: "OPACITY"
                    );
                })
            }
        }
    }

    
    // publish the right kinda stream based on the flag (true=screen, false=camera)
    // called from Expert Webapp only
    rePublishExpertStream = (bScreen, maxDivConObj, flag=false, sendArtifacts=true) => {
        try {
            let {g_session, g_subInfoArr} = this.state;
            let subscriberBox = document.getElementById(subscriberContainerDivId);
            if (bScreen) {
                // create the screen publisher
                let div = document.createElement('div');
                div.setAttribute('id', 'myTechScreenShareDiv');
                subscriberBox.appendChild(div);
                // NS2-113
                if (maxDivConObj === null) {
                    /* g_subInfoArr.forEach(subInfo => {
                        if (subInfo.disableVideo === false){
                            const connObj = subInfo.g_stream.connection;
                        }
                    }) */
                    this.sendSignalPrepareExpertSS(true, maxDivConObj);
                } else 
                    this.sendSignalPrepareExpertSS(true, maxDivConObj);
                printConsole({                    
                    resolution: '1280x720',
                    frameRate: 15,
                    width: '100%',
                    height: '100%',
                    publishAudio: false,
                    publishVideo: true,
                    videoSource: 'screen',
                    mirror: false, 
                    fitMode: 'contain'
                }) //TP-2235
                g_screen_publisher = OT.initPublisher('myTechScreenShareDiv', {
                    /* facingMode: 'environment', Removed for TP-2235 */
                    resolution: '1280x720',
                    frameRate: 7, //TP-2318
                    width: '100%',
                    height: '100%',
                    publishAudio: false,
                    publishVideo: true,
                    videoSource: 'screen',
                    mirror: false, 
                    /* fitMode: 'contain' Removed for TP-2318*/
                }, function (error) {
                    if (error) {
                        printConsole("The user has cancelled the Screen share. Hence closing...")
                        console.log(error)
                        if (maxDivConObj === null) {
                            otExpObj.props.onSessionCmdChange("session_screen_share_off")
                            otExpObj.setState({ expertSSAll: false });
                            otExpObj.setState({ expertShareScreenMaxDiv: false });
                            otExpObj.setState({ isOngoingMaxDivFeatures: false });                            
                            otExpObj.props.updateScreenShareAll(false);
                        } else {
                            otExpObj.setState({ expertShareScreenMaxDiv: false });
                            otExpObj.setState({ isOngoingMaxDivFeatures: false });                            
                        }
                        // for NS2-113
                        if (maxDivConObj === null) {
                            /* g_subInfoArr.forEach(subInfo => {
                                if (subInfo.disableVideo === false){
                                    const connObj = subInfo.g_stream.connection;
                                }
                            }) */
                            otExpObj.sendSignalPrepareExpertSS(false, maxDivConObj);
                            // Send signal to all experts to enable the Hand Icon
                            if (otExpObj.state.gridViewMode === true){
                                // T32-463 -- New Multi-expert Signal trigger implementation                    
                                otExpObj.sendSignalSyncExperts({
                                    show_grid: true,
                                    hand_icon_enabled: true,
                                    header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null) ? true : false, //TP-2378             
                                    reason: "show_action"},
                                null);  // send the "show_grid" value as true for Grid View Mode                        
                            } else {  
                                // TP-1616 -- Use case-2
                                const {isArrowActive, isFreeDrawing, isRectActive, isCircleActive, /**TP-2475 */ isPointerActive, pencolor} = otExpObj.state;
                                let shape_selected = "";
                                if (isFreeDrawing === true) shape_selected = "freehand";
                                else if (isRectActive === true) shape_selected = "rectangle";
                                else if (isCircleActive === true) shape_selected = "circle";
                                else if (isArrowActive === true) shape_selected = "arrow";   
                                else if (isPointerActive === true) shape_selected = "pointer"; /**TP-2586 & TP-2475 */               
                                g_subInfoArr.forEach((subInfo) => {
                                    if (subInfo.g_divId === g_maxDivId) {
                                        // T32-463 -- New Multi-expert Signal trigger implementation
                                        const dataArr = subInfo.g_stream.connection.data.split(":::")
                                        otExpObj.sendSignalSyncExperts({
                                            show_grid: false,
                                            max_view_technician: dataArr[3], 
                                            annotation_active: otExpObj.state.bAnnotate,
                                            zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                            zoom_level: subInfo.zoomLevel,
                                            torch_button_active: subInfo.torchActive,
                                            annotate_color: pencolor, //TP-1616
                                            shape_selected, //TP-1616
                                            hand_icon_enabled: true,
                                            header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null) ? true : false, //TP-2378             
                                            reason: "show_action"}, 
                                        null);  // send the max div tech details                                  
                                    }
                                })
                            }
                        } else 
                            otExpObj.sendSignalPrepareExpertSS(false, maxDivConObj);
                        // for NS2-95
                        otExpObj.props.updateRCStatus(false);
                        // for NS2-117
                        otExpObj.props.updateScreenShareMaxdiv(false);

                        if (otExpObj.state.bZoomClick === true)
                            document.getElementById('zoomlevelselector').style.display = 'block';

                        if (otExpObj.state.bShareAsset && otExpObj.state.fileData !== null) {
                            if (otExpObj.state.shareOnDiv === null) {
                                //Send signal to all users to show the shared artifacts again
                                if (maxDivConObj === null) {
                                    g_subInfoArr.forEach(subInfo => {
                                        const toArray = subInfo.g_stream.connection.data.split(":::");
                                        const toObject = toArray[3];
                                        otExpObj.sessionSignal.sendSignalShareArtifacts(
                                            //from: g_session.connection.data,
                                            otExpObj.state.fileData,
                                            toObject
                                            //type: "SHARE_ARTIFACTS"            
                                        );
                                    })
                                } else {
                                    //Send signal to only Max-div user to show the shared artifacts again
                                    const toArray = maxDivConObj.data.split(":::");
                                    const toObject = toArray[3];
                                    otExpObj.sessionSignal.sendSignalShareArtifacts(
                                        //from: g_session.connection.data,
                                        otExpObj.state.fileData,
                                        toObject
                                        //type: "SHARE_ARTIFACTS"        
                                    );
                                    //Send signal to all experts to remove the shared artifacts
                                    g_expertConnsArr.forEach(connectionObj => {
                                        if (g_session.connection !== connectionObj) {
                                            const toArray = connectionObj.data.split(":::");
                                            const toObject = toArray[3];
                                            otExpObj.sessionSignal.sendSignalShareArtifacts(
                                                //from: g_session.connection.data,
                                                otExpObj.state.fileData,
                                                toObject
                                                //type: "SHARE_ARTIFACTS"                
                                            );
                                        }
                                    });
                                }
                            } else {
                                //Send signal to only Max-div user to show the shared artifacts again
                                if (maxDivConObj === null) {
                                    g_subInfoArr.forEach(subInfo => {
                                        if (subInfo.g_divId === g_maxDivId){
                                            maxDivConObj = subInfo.g_stream.connection;
                                        }
                                    })
                                }
                                const toArray = maxDivConObj.data.split(":::");
                                const toObject = toArray[3];
                                otExpObj.sessionSignal.sendSignalShareArtifacts(
                                    //from: g_session.connection.data,
                                    otExpObj.state.fileData,
                                    toObject
                                    //type: "SHARE_ARTIFACTS"        
                                );
                                //Send signal to all experts to remove the shared artifacts
                                g_expertConnsArr.forEach(connectionObj => {
                                    if (g_session.connection !== connectionObj) {
                                        const toArray = connectionObj.data.split(":::");
                                        const toObject = toArray[3];
                                        otExpObj.sessionSignal.sendSignalShareArtifacts(
                                            //from: g_session.connection.data,
                                            otExpObj.state.fileData,
                                            toObject
                                            //type: "SHARE_ARTIFACTS"                
                                        );
                                    }
                                });
                            }
                        }
                        return;
                    } else {
                        // TP-1283 -- Send the clear Annotate signal only after the Screen Cast
                        // has successfully started
                        if (otExpObj.state.bAnnotate === true) {
                            //Clearing the old annotation drawings
                            otExpObj.sendAnnotationToExpert('');//NS2-345
                            otExpObj.sendAnnotation('', null);
                            //this.clearAnnotations(); commented this code out for TP-1365
                        }
                        // publish the screen
                        g_screen_publisher.on('streamCreated', function(event) {
                            /* console.log('Stream resolution: ' +
                              event.stream.videoDimensions.width +
                              'x' + event.stream.videoDimensions.height); */
                        });
                        g_session.publish(g_screen_publisher, function (error) {
                            if (error) {
                                otExpObj.handleError(error);
                                alert('Screen Publish call gave an error: ', error);
                            } else {
                                // Sending the session signal for the Expert screen share on/off to all the technicians
                                if (maxDivConObj === null) {
                                    /* g_subInfoArr.forEach(subInfo => {
                                        if (subInfo.disableVideo === false){
                                            const connObj = subInfo.g_stream.connection;
                                        }
                                    }) */                                    
                                    otExpObj.sendSignalExpertSS(otExpObj.state.expertSSAll, maxDivConObj);
                                } else {
                                    // Sending the session signal for the Expert screen share on/off to maxdiv technician
                                    otExpObj.sendSignalExpertSS(otExpObj.state.expertShareScreenMaxDiv, maxDivConObj);
                                }
                            }
                        });
                        otExpObj.setState({ g_session : g_session });
                        //for NS2-95
                        // To Make the Stop Session enabled for the case where the Expert side
                        // screen share publish fails/succeeds hence moved the RC status update call 
                        // after the publish function                       
                        otExpObj.props.updateRCStatus(false);
                    }
                }); // this.handleError

                // hide the technician screen share Div - we continue to show the live stream on publisher side
                document.getElementById('myTechScreenShareDiv').style.display = "none";
            }
            else {
                // Sending the session signal for the Expert screen share on/off to the technicians
                if (maxDivConObj === null) {
                    /* g_subInfoArr.forEach(subInfo => {
                        if (subInfo.disableVideo === false){
                            const connObj = subInfo.g_stream.connection;
                        }
                    }) */                    
                    otExpObj.sendSignalExpertSS(otExpObj.state.expertSSAll, maxDivConObj);
                    if (otExpObj.state.gridViewMode === true){
                        // T32-463 -- New Multi-expert Signal trigger implementation                    
                        otExpObj.sendSignalSyncExperts({
                            show_grid: true,
                            hand_icon_enabled: true,
                            header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null && sendArtifacts === true) ? true : false, //TP-2378             
                            reason: "show_action"},
                        null);  // send the "show_grid" value as true for Grid View Mode                        
                    } else if (flag === false) {
                        g_subInfoArr.forEach((subInfo) => {
                            if (subInfo.g_divId === g_maxDivId) {
                                // T32-463 -- New Multi-expert Signal trigger implementation
                                const dataArr = subInfo.g_stream.connection.data.split(":::")
                                otExpObj.sendSignalSyncExperts({
                                    show_grid: false,
                                    max_view_technician: dataArr[3],
                                    zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                    zoom_level: subInfo.zoomLevel,
                                    torch_button_active: subInfo.torchActive, 
                                    annotation_active: false,
                                    hand_icon_enabled: true,
                                    header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null && sendArtifacts === true) ? true : false, //TP-2378             
                                    reason: "show_action"}, 
                                null);  // send the max div tech details                                  
                            }
                        })
                    }
                } else {
                    // Sending the session signal for the Expert screen share on/off to maxdiv technician
                    otExpObj.sendSignalExpertSS(otExpObj.state.expertShareScreenMaxDiv, maxDivConObj);
                }
                // screen share has been turned off - do the needful
                document.getElementById('myTechScreenShareDiv') && subscriberBox.removeChild(document.getElementById('myTechScreenShareDiv'));
                /* g_screen_publisher.publishVideo(false);
                g_screen_publisher.publishAudio(true); */
                //TP-3673
                if (g_screen_publisher !== null && g_screen_publisher !== undefined)
                    g_session.unpublish(g_screen_publisher); //TP-3673
                this.setState({ g_session : g_session });
                // to enable the expert to be bummed out after the screen share stops
                // for NS2-95
                otExpObj.props.updateRCStatus(false);
                //TP-3673
                if (g_screen_publisher !== null && g_screen_publisher !== undefined)
                    g_screen_publisher.destroy(); //TP-3673
                g_screen_publisher = null;

                if (this.state.bShareAsset && sendArtifacts === true && (this.state.assetsArray.length > 0 || this.state.fileData !== null)) {
                    this.threadTimer = setTimeout(() => {
                        /* console.log(this.state.shareOnDiv);
                        console.log(this.state.rotationArray);
                        console.log(this.state.showFileViewer);
                        console.log(maxDivConObj); */
                        if (this.state.shareOnDiv === null) {
                            //Send signal to all users to show the shared artifacts again
                            if (maxDivConObj === null) {
                                g_subInfoArr.forEach(subInfo => {
                                    const toArray = subInfo.g_stream.connection.data.split(":::");
                                    const toObject = toArray[3];
                                    if (this.state.fileData !== null)
                                        this.sessionSignal.sendSignalShareArtifacts(
                                            //from: g_session.connection.data,
                                            this.state.fileData,
                                            toObject
                                            //type: "SHARE_ARTIFACTS"            
                                        );
                                    else {
                                        this.state.assetsArray.forEach(asset => {
                                            this.sessionSignal.sendSignalShareArtifacts(asset, toObject);
                                        })
                                    }
                                })
                                
                                if (this.state.showFileViewer && this.state.fileOpacity) {
                                    // If not 3D file share ongoing then send the last Opacity value 
                                    //this.sessionSignal.sendSignalOpacity(this.state.fileOpacity, ''); //TP-841
                                    this.sendOpacity(this.state.fileOpacity);
                                }
                                else if (!this.state.showFileViewer && this.state.rotationArray !== null && this.state.rotationArray !== '') {
                                    // If 3D file is shared then resend the last rotation change
                                    this.rotationTimer = setTimeout(() => {
                                        this.sessionSignal.sendSignal3DRotation(this.state.rotationArray, '');
                                        clearTimeout(this.rotationTimer)
                                    }, 3000);
                                }
                            } else {
                                //Send signal to only Max-div user to show the shared artifacts again
                                const toArray = maxDivConObj.data.split(":::");
                                const toObject = toArray[3];
                                if (this.state.fileData !== null)
                                    this.sessionSignal.sendSignalShareArtifacts(
                                        //from: g_session.connection.data,
                                        this.state.fileData,
                                        toObject
                                        //type: "SHARE_ARTIFACTS"        
                                    );
                                else {
                                    this.state.assetsArray.forEach(asset => {
                                        this.sessionSignal.sendSignalShareArtifacts(asset, toObject);
                                    })
                                }

                                if (this.state.showFileViewer && this.state.fileOpacity) {
                                    // If not 3D file share ongoing then send the last Opacity value 
                                    //this.sessionSignal.sendSignalOpacity(this.state.fileOpacity, toObject); //TP-841
                                } else if (!this.state.showFileViewer && this.state.rotationArray !== null && this.state.rotationArray !== '') {
                                    // If 3D file is shared then resend the last rotation change
                                    this.rotationTimer = setTimeout(() => {
                                        this.sessionSignal.sendSignal3DRotation(this.state.rotationArray, toObject);
                                        clearTimeout(this.rotationTimer)
                                    }, 3000);
                                }
                                //Send signal to all experts to show the shared artifacts
                                g_expertConnsArr.forEach(connectionObj => {
                                    if (g_session.connection !== connectionObj) {
                                        const toArray = connectionObj.data.split(":::");
                                        const toObject = toArray[3];
                                        if (this.state.fileData !== null)
                                            this.sessionSignal.sendSignalShareArtifacts(
                                                //from: g_session.connection.data,
                                                this.state.fileData,
                                                toObject
                                                //type: "SHARE_ARTIFACTS"                
                                            );
                                        else {
                                            this.state.assetsArray.forEach(asset => {
                                                this.sessionSignal.sendSignalShareArtifacts(asset, toObject);
                                            })
                                        }   
                                        
                                        if (this.state.showFileViewer && this.state.fileOpacity) {
                                            // If not 3D file share ongoing then send the last Opacity value 
                                            this.sessionSignal.sendSignalOpacity(this.state.fileOpacity, toObject); //TP-841
                                        } 
                                    }
                                });

                                // If 3D file is shared then resend the last rotation change
                                if (!this.state.showFileViewer && this.state.rotationArray !== null && this.state.rotationArray !== '') {
                                    this.expertRotationTimer = setTimeout(() => {
                                        g_expertConnsArr.forEach(connectionObj => {
                                            if (g_session.connection !== connectionObj) {
                                                const toArray = connectionObj.data.split(":::");
                                                const toObject = toArray[3];
                                                this.sessionSignal.sendSignal3DRotation(this.state.rotationArray, toObject);
                                            }
                                        });
                                        clearTimeout(this.expertRotationTimer)
                                    }, 4000);
                                }
                            }
                        } else {
                            //Send signal to only Max-div user to show the shared artifacts again
                            if (maxDivConObj === null) {
                                g_subInfoArr.forEach(subInfo => {
                                    if (subInfo.g_divId === g_maxDivId){
                                        maxDivConObj = subInfo.g_stream.connection;
                                    }
                                })
                            }
                            const toArray = maxDivConObj.data.split(":::");
                            const toObject = toArray[3];
                            if (this.state.fileData !== null)
                                this.sessionSignal.sendSignalShareArtifacts(
                                    //from: g_session.connection.data,
                                    this.state.fileData,
                                    toObject
                                    //type: "SHARE_ARTIFACTS"        
                                );
                            else {
                                this.state.assetsArray.forEach(asset => {
                                    this.sessionSignal.sendSignalShareArtifacts(asset, toObject);
                                })
                            }                             

                            // If 3D file is shared then resend the last rotation change
                            //const [closeIcon] = document.getElementsByClassName('asset-3d');
                            if (this.state.showFileViewer && this.state.fileOpacity) {
                                // If not 3D file share ongoing then send the last Opacity value 
                                //this.sessionSignal.sendSignalOpacity(this.state.fileOpacity, toObject); //TP-841
                            } else if (!this.state.showFileViewer && this.state.rotationArray !== null && this.state.rotationArray !== '') {
                                this.rotationTimer = setTimeout(() => {
                                    this.sessionSignal.sendSignal3DRotation(this.state.rotationArray, toObject);
                                    clearTimeout(this.rotationTimer)
                                }, 3000);
                            }
                            //Send signal to all experts to show the shared artifacts again
                            g_expertConnsArr.forEach(connectionObj => {
                                if (g_session.connection !== connectionObj) {
                                    const toArray = connectionObj.data.split(":::");
                                    const toObject = toArray[3];
                                    if (this.state.fileData !== null)
                                        this.sessionSignal.sendSignalShareArtifacts(
                                            //from: g_session.connection.data,
                                            this.state.fileData,
                                            toObject
                                            //type: "SHARE_ARTIFACTS"                
                                        );
                                    else {
                                        this.state.assetsArray.forEach(asset => {
                                            this.sessionSignal.sendSignalShareArtifacts(asset, toObject);
                                        })
                                    }
                                    
                                    // If 3D file is shared then resend the last rotation change
                                    //const [closeIcon] = document.getElementsByClassName('asset-3d');
                                    if (this.state.showFileViewer && this.state.fileOpacity) {
                                        // If not 3D file share ongoing then send the last Opacity value 
                                        this.sessionSignal.sendSignalOpacity(this.state.fileOpacity, toObject); //TP-841
                                    } 
                                }
                            });

                            if (!this.state.showFileViewer && this.state.rotationArray !== null && this.state.rotationArray !== '') {
                                this.expertRotationTimer = setTimeout(() => {
                                    g_expertConnsArr.forEach(connectionObj => {
                                        if (g_session.connection !== connectionObj) {
                                            const toArray = connectionObj.data.split(":::");
                                            const toObject = toArray[3];
                                            this.sessionSignal.sendSignal3DRotation(this.state.rotationArray, toObject);
                                        }
                                    });
                                    clearTimeout(this.expertRotationTimer)
                                }, 4000);
                            }
                        }
                        if (otExpObj.state.gridViewMode === true){
                            // T32-463 -- New Multi-expert Signal trigger implementation                    
                            otExpObj.sendSignalSyncExperts({
                                show_grid: true,
                                hand_icon_enabled: true,
                                header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null) ? true : false, //TP-2378          
                                reason: "show_action"},
                            null);  // send the "show_grid" value as true for Grid View Mode                        
                        } else if (flag === false) {
                            g_subInfoArr.forEach((subInfo) => {
                                if (subInfo.g_divId === g_maxDivId) {
                                    // T32-463 -- New Multi-expert Signal trigger implementation
                                    const dataArr = subInfo.g_stream.connection.data.split(":::")
                                    otExpObj.sendSignalSyncExperts({
                                        show_grid: false,
                                        max_view_technician: dataArr[3],
                                        zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                        zoom_level: subInfo.zoomLevel,
                                        torch_button_active: subInfo.torchActive, 
                                        annotation_active: false,
                                        hand_icon_enabled: true,
                                        header_fileShare_active: (otExpObj.state.bShareAsset && otExpObj.state.shareOnDiv === null) ? true : false, //TP-2378          
                                        reason: "show_action"}, 
                                    null);  // send the max div tech details                                  
                                }
                            })
                        }
                        clearTimeout(this.threadTimer);
                    }, 1000);                    
                }
                else if (this.state.bShareAsset && sendArtifacts === false) {
                    if (otExpObj.state.gridViewMode === true){
                        // T32-463 -- New Multi-expert Signal trigger implementation                    
                        otExpObj.sendSignalSyncExperts({
                            show_grid: true,
                            hand_icon_enabled: true,
                            header_fileShare_active: false, //TP-2378             
                            reason: "show_action"},
                        null);  // send the "show_grid" value as true for Grid View Mode                        
                    } else if (flag === false) {
                        g_subInfoArr.forEach((subInfo) => {
                            if (subInfo.g_divId === g_maxDivId) {
                                // T32-463 -- New Multi-expert Signal trigger implementation
                                const dataArr = subInfo.g_stream.connection.data.split(":::")
                                otExpObj.sendSignalSyncExperts({
                                    show_grid: false,
                                    max_view_technician: dataArr[3],
                                    zoom_button_active:  (subInfo.zoomLevel > 1) ? true : false,
                                    zoom_level: subInfo.zoomLevel,
                                    torch_button_active: subInfo.torchActive, 
                                    annotation_active: false,
                                    hand_icon_enabled: true,
                                    header_fileShare_active: false, //TP-2378             
                                    reason: "show_action"}, 
                                null);  // send the max div tech details                                  
                            }
                        })
                    }
                }
            }
        } catch (exception) {
            alert(`${exception}`);
        }
    }

    // To be called when the Expert Screen share Start/Stop signals are sent
    // called on Expert Webapp only
    sendSignalExpertSS = (expertShareScreenMaxDiv, connObj) => {
        try {

            //let signalName = "START_EXPERT_SS";
            //let data = "moderator";

            if (expertShareScreenMaxDiv === false) {
                //signalName = "STOP_EXPERT_SS";
                //	data = "publisher";        		

                if (connObj === null) {
                    this.sessionSignal.sendSignalStopExpertSS();
                }
                else if (connObj !== null) {                    
                    let toObject = connObj;
                    if (typeof connObj === "object") {
                        const toArray = connObj.data.split(":::");
                        toObject = toArray[3];
                    }
                    this.sessionSignal.sendSignalStopExpertSS(
                        toObject                        
                    );
                    //console.log("sent Expert Screen Stop share signal to :", connObj);

                    // Sending the expertSS signals to the Secondary Experts currently in session
                    const {g_session} = this.state;
                    if (g_session !== null && g_expertConnsArr.length > 0) {
                        g_expertConnsArr.forEach(expertInfo => {
                            if (this.state.showHideMaxDivControl) {
                                const toArrayExperts = expertInfo.data.split(":::");
                                toObject = toArrayExperts[3];
                                this.sessionSignal.sendSignalStopExpertSS(
                                    toObject
                                );
                                //console.log("sent Expert Screen Stop share signal to :", toObject);
                            }
                        })
                    }
                }
            } else if (expertShareScreenMaxDiv === true) {
                if (connObj === null) {
                    this.sessionSignal.sendSignalStartExpertSS();
                }
                else if (connObj !== null) {
                    let toObject = connObj;
                    let toArray;
                    if (typeof connObj === "object") {
                        toArray = connObj.data.split(":::");
                        toObject = toArray[3];
                    }
                    this.sessionSignal.sendSignalStartExpertSS(
                        toObject
                    );
                    //console.log("sent Expert Screen Start share signal to :", toObject)
                    if (toArray !== undefined && toArray[4] === "false") {
                        // Sending the expertSS signals to the Secondary Experts currently in session
                        const {g_session} = this.state;
                        if (g_session !== null && g_expertConnsArr.length > 0) {
                            g_expertConnsArr.forEach(expertInfo => {
                                if (this.state.showHideMaxDivControl) {
                                    const toArrayExperts = expertInfo.data.split(":::");
                                    toObject = toArrayExperts[3];
                                    this.sessionSignal.sendSignalStartExpertSS(
                                        toObject
                                    );
                                    //console.log("sent Expert Screen Start share signal to :", toObject);
                                }
                            })
                        }
                    }
                }
            }

        }
        catch (exception) {
            printConsole('exception occured in sendSignalExpertSS');
            printConsole(exception);
        }
    }

    // Send signal function for the Prepare Expert SS 
    // For NS2-113
    // Handling the Expert Screen share on APK side
    sendSignalPrepareExpertSS = (bflag, connObj) => {
        try {

            if (connObj === null) {
                this.sessionSignal.sendSignalPrepareExpertSS(
                    bflag
                    //type: "PREPARE_EXPERT_SS"
                );
                //console.log(" sendSignal PrepareExpert SS sent to all the participating technicians ");
            }
            else if (connObj !== null) {
                let toObject = connObj;
                let toArray;
                if (typeof connObj === "object") {
                    toArray = connObj.data.split(":::");
                    toObject = toArray[3];
                }
                this.sessionSignal.sendSignalPrepareExpertSS(
                    bflag,
                    toObject
                    //type: "PREPARE_EXPERT_SS"
                );
                //console.log("sent Prepare Expert Screen share signal to :", toObject)
                if (toArray !== undefined && toArray[4] === "false") {
                    // If the user passed as a parameter to the method is a technician user only then
                    // Sending the expertSS signals to the Secondary Experts currently in session
                    const {g_session} = this.state;
                    if (g_session !== null && g_expertConnsArr.length > 0) {
                        g_expertConnsArr.forEach(expertInfo => {
                            if (this.state.showHideMaxDivControl) {
                                const toArrayExperts = expertInfo.data.split(":::");
                                toObject = toArrayExperts[3];
                                this.sessionSignal.sendSignalPrepareExpertSS(
                                    bflag,
                                    toObject
                                    //type: "PREPARE_EXPERT_SS"
                                );
                                //console.log("sent Prepare Expert Screen share signal to other expert :", toObject)
                            }
                        })
                    }
                }
            }
        }
        catch (exception) {
            printConsole('exception occured in sendSignalPrepareExpertSS');
            printConsole(exception);
        }
    }

    // To be called when the Technician Screen share Start/Stop signals are sent from expert to technician
    // called on Expert Webapp only
    sendSignalTechnicianScreenShare = (techShareScreen, connObj) => {
        try {
            //let signal = "STOP_TECHNICIAN_SS";
            const toArray = connObj.data.split(":::");
            const toObject = toArray[3];
            if (techShareScreen === true) {
                //signal = "START_TECHNICIAN_SS";
                this.sessionSignal.sendSignalStartTechnicianSS(
                    toObject
                );
                // console.log("Technician Screen Share command sent...",techShareScreen);
            } else if (techShareScreen === false) {
                this.sessionSignal.sendSignalStopTechnicianSS(
                    toObject
                );
                // console.log("Technician Screen Share command sent...",techShareScreen);
            }
        }
        catch (exception) {
            printConsole('exception occured in techShareScreen');
            printConsole(exception);
        }
    }

    sendSignalSwapCameraReceived = (data, eventType, recipient) => {
        try {
            this.sessionSignal.sendSignalSwapCameraReceived (
                data,
                eventType,
                recipient
                //type: "SWAP_CAMERA_RECEIVED"
            )
            //console.log("Swap camera signal command sent... to: ",recipient);
        } catch (exception) {
            printConsole('exception occured in sendSignalSwapCameraReceived');
            printConsole(exception); 
        }
    }
    

    // for NS2-345
    /** Secondary Experts signal emitter methods */
    sendSignalExpertRemoteClick = (msgToSend, connData) => {
        const {g_session} = this.state;
        //send this message out to other experts
        if (g_session !== null) {
            printConsole(g_expertConnsArr.length);
            if (connData === null && g_expertConnsArr.length > 0) {
                g_expertConnsArr.forEach(expertInfo => {
                    if (this.state.showHideMaxDivControl) {
                        const toArray = expertInfo.data.split(":::");
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalExpertRCClicked(
                            msgToSend,
                            toObject
                            //type: "EXPERT_RC_CLICKED"                            
                        );
                        //console.log("expert RC button click signal sent...");
                        return;
                    }
                })
            } else if (connData !== null) {
                this.sessionSignal.sendSignalExpertRCClicked(

                    //from: g_session.connection.data,
                    msgToSend,
                    connData
                    //type: "EXPERT_RC_CLICKED"

                );
                //console.log("expert RC button click signal sent to...", connData);
            } else if (g_expertConnsArr.length > 0) {
                if (this.state.showHideMaxDivControl) {
                    g_expertConnsArr.forEach(expertInfo => {
                        const toArray = expertInfo.data.split(":::");
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalExpertRCClicked(
                            //from: g_session.connection.data,
                            msgToSend,
                            toObject
                            //type: "EXPERT_RC_CLICKED"
                        );
                        //console.log("expert RC button click signal sent...");
                    });
                    //return;
                }
            }
        }
    }

    // for NS2-345
    stopExpertRCAnnotate = () => {
        const {g_session} = this.state;
        if (g_session !== null && g_expertConnsArr.length > 0) {
            g_expertConnsArr.forEach(subInfo => {
                if (this.state.showHideMaxDivControl === true) {
                    const toArray = subInfo.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalRCAnnotation(
                        false,
                        toObject
                        //type: "REMOTECAPTUREANNOTATE"
                    );
                    //console.log("REMOTECAPTUREANNOTATE signal sent to other experts...with payload", false);
                    return;
                }
            })
        }
    }
    // for NS2-342
    sendSignalExpertSwitchMaxdiv = (data, connData) => {
        try {
            if (this.state.expertSSAll) return;
            //const signal = "EXPERT_CHANGE_MAX_DIV";            
            let sendData = "";
            let dataArr = [];
            if (data !== "")  {
                dataArr = data.split(":::");
                sendData = dataArr[3];
            }
            if (connData === null) {
                g_expertConnsArr.forEach(expertInfo => {
                    const toArray = expertInfo.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalExpertSwitchMaxdiv(
                        sendData,
                        toObject
                        //type: signal
                    );
                    //console.log(`Expert to switch maxdiv Technician sent to ${expertInfo.data}, with Data: ${sendData}`);                        
                });
            } else {
                this.sessionSignal.sendSignalExpertSwitchMaxdiv(
                    sendData,
                    connData
                    //type: signal
                );
                //console.log(`Expert to switch maxdiv Technician sent to ${connData}`);                        
            }
        } catch (exception) {
            printConsole('exception occured in sendSignalExpertSwitchMaxdiv');
            printConsole(exception);
        }
    }

    //TP-359 -- Signal to reset the Primary Expert ID for all/one of
    // the remote users (only Technicians) currently in the session
    sendSignalCurrentPrimaryExpert = (email=null) => {
        try {
            if (this.state.showHideMaxDivControl === true) {
                const {g_subInfoArr} = this.state;
                if (email === null) {
                    if (g_subInfoArr.length > 0) {
                        g_subInfoArr.forEach(subInfo => {
                            const toArray = subInfo.g_stream.connection.data.split(":::");
                            const isExpert = toArray[4];
                            const toObject = toArray[3];
                            if (isExpert === "false") {
                                this.sessionSignal.sendSignalCurrentPrimaryExpert(
                                    0,
                                    toObject
                                    //type: "CURRENT_PRIMARY_EXPERT"
                                );
                            }
                        });
                        printConsole(`Current Primary Expert sent to all technicians`);
                    }
                } else {
                    const toObject = email;
                    this.sessionSignal.sendSignalCurrentPrimaryExpert(
                        0,
                        toObject
                        //type: "CURRENT_PRIMARY_EXPERT"
                    );
                    printConsole(`Current Primary Expert sent to user ${toObject}`);
                    return;
                }
            }
        } catch (exception) {
            printConsole('exception occured in sendSignalCurrentPrimaryExpert');
            printConsole(exception);
        }
    }

    // for NS2-342
    sendSignalQueryExpertSwitchMaxdiv = () => {
        try {
            //const signal = "EXPERT_QUERY_MAXDIV";
            g_expertConnsArr.forEach(expertInfo => {
                const toArray = expertInfo.data.split(":::");
                const toObject = toArray[3];
                this.sessionSignal.sendSignalQueryExpertMaxdivSwitch(
                    toObject
                    //data: dataArr[3],
                    //type: signal                    
                );
                //console.log(`Passive Expert asks main expert to switch maxdiv Technician sent to ${expertInfo.data}`);                        
            })
        } catch (exception) {
            printConsole('exception occured in sendSignalQueryExpertSwitchMaxdiv');
            printConsole(exception);
        }
    }

    sendSignalSyncExperts = (data, email) => {
        try {
            if (email === null) {
                if (g_expertConnsArr && g_expertConnsArr.length > 0) {
                    g_expertConnsArr.forEach(expertInfo => {
                        const toArray = expertInfo.data.split(":::");
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalSyncExperts(
                            data,
                            toObject
                        );
                    });
                }
            } else {
                this.sessionSignal.sendSignalSyncExperts(
                    data,
                    email                   
                );
            }
        } catch (exception) {
            printConsole('exception occured in sendSyncExpert');
            printConsole(exception);
        }
    }

    
    // send the Signal for requesting to make Max Div Controls tab visible 
    // for second expert in the session
    sendSignalRequestMaxDivControl = (data, connObj) => {
        try {
            const toArray = connObj.data.split(":::");
            const toObject = toArray[3];
            this.sessionSignal.sendSignalRequestMaxDivControl(
                data,
                toObject
                //type: "REQUEST_MAXDIV_CONTROL"
            );
            //console.log(`Other Expert request for maxdiv control command sent.. to ${connObj.data}.`);
        }
        catch (exception) {
            printConsole(exception);
            printConsole('exception occured in send Request for MaxDiv Control function');
        }
    }

    // send the Yield Max Div Control Request to the second expert
    sendSignalYieldMaxDivControls = (data, connObj) => {
        try {
            //console.log(connObj.data);
            let toObject = connObj;
            /* if (connObj.data === undefined) {
                toObject = connObj;
            } else {
                const toArray = connObj.data.split(":::");
                toObject = toArray[3];
            } */
            this.sessionSignal.sendSignalYieldMaxDivControls(
                data,
                toObject
                //type: "YIELD_MAXDIV_CONTROL"                
            );
            //console.log("Main Expert response Yield for maxdiv control command sent... with data ", data);
        }
        catch (exception) {
            printConsole(exception);
            printConsole('exception occured in send Yield for MaxDiv Control function');
        }
    }

    sendSignalYieldDisableHandIcon = (data, senderName, senderEmail, toExpert=null) => {
        try {
            //const signal = "YIELD_DISABLE_HAND_ICON";
            //TP-1313 -- Handling of use case when new expert joins while request for control dialog is open
            if (toExpert !== null) {
                //console.log(toExpert);
                this.sessionSignal.sendSignalYieldDisableHandIcon(
                    data,
                    senderName,
                    toExpert
                    //type: signal                    
                );
                //console.log(`Disable Passive Expert's HandIcons ${data} sent to: ${toExpert}`);
                    
            } else if (senderEmail === null) {
                g_expertConnsArr.forEach(expertInfo => {
                    const toArray = expertInfo.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalYieldDisableHandIcon(
                        data,
                        senderName,
                        toObject
                        //type: signal                    
                    );
                    //console.log(`Disable all Passive Expert's HandIcons ${data} except: ${senderEmail}`);
                    
                })
            } else {
                g_expertConnsArr.forEach(expertInfo => {
                    const toArray = expertInfo.data.split(":::");
                    if (senderEmail !== toArray[3]) {
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalYieldDisableHandIcon(
                            data,
                            senderName,
                            toObject
                            //type: signal                    
                        );
                        //console.log(`Disable all Passive Expert's HandIcons ${data} except: ${senderEmail}`);
                    }
                })
            }
        } catch (exception){
            printConsole(exception);
            printConsole('exception occured in send Yield disable Hand Icon function');
        }
    }

    /** Secondary Expert signal emitter methods ends */    
    incrementAllChatBadge = () => {
        this.setState({ nAllChatNewMsgCounter: (this.state.nAllChatNewMsgCounter + 1) })
    }

    componentWillUnmount = () => {
        //const { user } = this.props; 
        terminateWorker(); 
        strCanvasJSON = ""; //TP-2545
        if (this.aTimer) stopAnnotationTimer();
        //this.props.onCloseTechnician(false);        
        this.state.URLs.forEach(url => {
            if (url) window.URL.revokeObjectURL(url);
        });
        window.removeEventListener("resize", this.updateDimensions, false);
        if (this.screenshotsTimer) clearInterval(this.screenshotsTimer);
        /** Unsubscribe / remove the event listeners while component unmounts */
        // MB2-106
        this.sessionSignal.removeWebsocket(this.subscribeToGroupSignal);
        this.sessionSignal.removeUserinsession(this.subscribeToUserIsInSession);     
        this.authService.socket.removeListener('isOnline', this.subscribeToOnlineNotification);
        this.authService.socket.removeListener('isOffline', this.checkUserOffline); //MB2-661 
        this.authService.socket.removeListener('respond-to-the-call', this.subscribeToRespondToTheCall);  
        this.onClose();
        if (g_expertConnsArr.length === 0) {
            //this.props.clearSessionToken();//T32-420 clear the session token API removed
        }
        printConsole(`is startRecording -----> ${this.state.startRecording}`);
        if (this.state.startRecording && isSilentRecording === false) {
            printConsole("Stop Recording when this expert ends the session abruptly");
            //this.stopScreenshotRecordingCb();
            this.stopRecordingCallback("end");
        }
    }

    onClose = () => {
        //printConsole("Close this session window..");
        this.setState({ unMount: true });
        const {g_subInfoArr, g_session} = this.state;
        if (this.state !== undefined && g_session !== undefined && this.props.sessionInfo !== undefined) {
            if (g_subInfoArr !== null && g_session !== null) {
                let index = 0;
                if (g_subInfoArr.length > 0) {
                    //console.log(document.getElementById(subscriberContainerDivId));
                    //console.log(document.getElementById('controlpanel'));
                    //TP-2810
                    if (document.getElementById('shapeselector') !== undefined && document.getElementById('shapeselector') !== null) {
                        //document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('shapeselector'));
                        document.getElementById('shapeselector').parentElement.removeChild(document.getElementById('shapeselector'));//TP-3867
                    }
                    if (document.getElementById('controlpanel') !== undefined && document.getElementById('controlpanel') !== null) {
                        document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('controlpanel'));
                    }
                    //document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('actionRequestControls'))
                    for (index = 0; index < g_subInfoArr.length; index++) {
                        if (g_subInfoArr[index].g_stream.publisher !== null) g_subInfoArr[index].g_subscriber.subscribeToVideo(false);
                        g_session.unsubscribe(g_subInfoArr[index].g_subscriber);
                        document.getElementById(g_subInfoArr[index].g_divId).parentElement.removeChild(document.getElementById(g_subInfoArr[index].g_divId));
                        g_subInfoArr.splice(index, 1);
                    }
                    let elemRemnantList = document.getElementsByClassName('vdoDiv');
                    for (index = 0; index < elemRemnantList.length; index++)
                        elemRemnantList[index].parentElement.removeChild(elemRemnantList[index]);
                    //let elemSmallDivContainer = document.getElementById('small-divs-container');
                    //elemSmallDivContainer.parentElement.removeChild(elemSmallDivContainer);
                }
            }
            // NS2-100
            if (g_screen_publisher !== null && g_session !== null) {
                if (g_screen_publisher !== null && g_screen_publisher !== undefined) {
                    g_session.unpublish(g_screen_publisher);
                    g_screen_publisher.destroy();
                    g_screen_publisher = null;
                }
            }
            if (g_publisher !== null && g_session !== null) {
                //   if(document.getElementById('maxdivcontrols') !== null)
                // document.getElementById('maxdivcontrols').style.display = 'none';
                //g_publisher.publishVideo(false);
                if (g_publisher !== null && g_publisher !== undefined) {
                    g_session.unpublish(g_publisher);
                    g_publisher.destroy();
                    g_publisher = null;
                }
                // NS2-117
                this.props.updateScreenShareMaxdiv(false);
            }
            // NS2-100
            /* if (g_camera_publisher !== null && g_session !== null) {
                if (g_camera_publisher !== null && g_camera_publisher !== undefined) {
                    g_session.unpublish(g_camera_publisher);
                    g_camera_publisher.destroy();
                    g_camera_publisher = null;
                }
            } */
            if (g_session !== null && g_session.connection !== null) {
                this.state.g_session.off("streamCreated");
                this.state.g_session.disconnect();
                this.setState({ g_session: null });
            }

            // set mute all flag to false
            
                if (g_expertConnsArr.length === 0 && this.state.bRemoteCapture === true)
                    this.onClickCamera();
                this.setState({
                    bMuteAllAudio: false,
                    bRemoteMute: false,
                    bRemoteMuteDisabled: false, //T32-413
                    bMuteMyMike: false,
                    muteMikeForMaxDiv: false,
                    sessionJoinees: []
                });
            this.props.updateScreenShareAll(false);
            this.props.updateScreenShareMaxdiv(false);
            this.props.updateFileShareMaxdiv(false);
            this.props.updateFileShare(false);
        }
        //g_maxDivId = null; //TP-1378
        this.setState({g_session : null});
        this.props.updateSessionTimer(false); 
    }    

    getGridViewDim = (userCount) => {
        const {g_subInfoArr} = this.state;
        const Height = window.innerHeight;   
        // As per review 
        const maxHeight = Math.floor((Height - (Height*2 * (borderYsize/100)))) - 2; // getRowDim & getCellDim should return the least rounded whole number
        const sideBarWidth = 80;
        const Width = window.innerWidth;
        // As per review 
        const maxWidth = Math.floor(Width - sideBarWidth) - 2;  // getRowDim & getCellDim should return the least rounded whole number      
        //printConsole(`No user tiles: ${userCount}`)
        let numRows, numCells, newVdoheight, newVdowidth;
        if(userCount && userCount > 1){
            if (userCount === 2) {
                numRows = 1;
                numCells = 2;                
            }
            else if(userCount <= 6 && userCount > 2) {
                if (userCount <= 4) {
                    numRows = 2;
                    numCells = 2;
                    
                }else{
                    numRows = 2;
                    numCells = 3;                    
                }
            } 
            else if(userCount <= 12 && userCount > 6) {
                if (userCount <= 9) {
                    numRows = 3;
                    numCells = 3; 
                } else {
                    numRows = 3;
                    numCells = 4;
                }
            } 
            /* else if(userCount <= 24 && userCount > 12) {
                if (userCount <= 16) {
                    numRows = 4;
                    numCells = 4;
                } else if (userCount <= 20) {
                    numRows = 4;
                    numCells = 5;
                }
                 else {
                    numRows = 4;
                    numCells = 6;
                }
            } */
            else if(userCount > 12) {
                numRows = 4;
                numCells = Math.ceil((userCount)/4);                
            }
            newVdoheight = this.getRowDim(numRows,maxHeight);
            newVdowidth = this.getCellDim(numCells,maxWidth);
        }else if(g_subInfoArr && (g_subInfoArr.length === 1 || userCount === 1)){
            numRows = 0;
            newVdoheight= maxHeight;
            newVdowidth= maxWidth;
        }
        //printConsole(`num rows:: ${numRows} num cols:: ${numCells}, tile height:: ${newVdoheight} tile width ${newVdowidth}`)

        return [newVdoheight, newVdowidth, numRows, numCells]; //TP-2973
    }

    getRowDim = (numRows, maxHeight) => {
        //const Height = window.innerHeight;        
        //const maxHeight = Math.floor((Height - (2 * borderYsize)));
        //printConsole(` maxHeight::${maxHeight}`)
        const yGap = 10;
        const numPad = numRows + 1;

        let newVdoheight;
        newVdoheight = Math.floor((maxHeight - (yGap * numPad))/numRows);

        return newVdoheight;
    }

    getCellDim = (numCells, maxWidth) => {
        //const sideBarWidth = 80;
        //const Width = window.innerWidth;
        //const maxWidth = Math.floor(Width - sideBarWidth);
        //printConsole(`maxWidth::${maxWidth}`)
        const xGap = 6;
        const numPad = numCells + 1;

        let newVdowidth;
        newVdowidth = Math.floor((maxWidth - (xGap * numPad))/numCells);

        return newVdowidth;
    }

    // TP-2973 -- to calculate the width of Video tiles in the last row of Grid
    // only when more than one row is there
    getLastRowViewDim = (userCount, numRows, numCellsMax) => {
        const lastRowNumCells = userCount % numCellsMax;
        //console.log(`total user count ${userCount}, Max no of cells ${numCellsMax}, number of rows ${numRows}`);
        if (numRows > 1 && lastRowNumCells > 0 && lastRowNumCells < numCellsMax) {
            const sideBarWidth = 80;
            const Width = window.innerWidth;
            const maxWidth = Math.floor(Width - sideBarWidth) - 2;
            const xGap = 6;
            //const xMargin = 10;
            const numPad = lastRowNumCells + 1;

            let newVdowidthLastRow = Math.floor((maxWidth - (xGap * numPad))/lastRowNumCells);

            return [newVdowidthLastRow, lastRowNumCells];
        } else {
            return [0, lastRowNumCells];
        }
    }

    updateShowMessage = (val) => {
        if (val === false)
            this.setState({ flashMsgText: "", showFlashMessage: val, flashLeft: "", flashTop: "" });
    }

    triggerMessage = (connData, status) => {
        let connArr = [];
        let values = {};
        let message = "";
        let left = "";
        let top = "";
        switch (status) {
            case "connect":
                connArr = connData.split(":::");
                //message = connArr[1]+" "+connArr[2]+" has joined the call";
                message = "{name} has joined the call";
                values = {name: connArr[1]+" "+connArr[2]}
                left = "60px";
                top = "50px";
                break;
            case "disconnect":
                connArr = connData.split(":::");
                //message = connArr[1]+" "+connArr[2]+" has left the call";
                message = "{name} has left the call";
                values = {name: connArr[1]+" "+connArr[2]}
                left = "60px";
                top = "50px";
                break;
            case  "yieldpositive":
                //message = connData+" has left the call. Control remains with the same expert.";
                message = "{name} has left the call so control remains with the same expert."; //TP-1305
                values = {name: connData};
                break;
            case "yieldnegative":
                //message = connData+" has left the call";
                message = "{name} has left the call";
                values = {name: connData};
                break;
            case "disableHand":
                //message = connData+" has requested control for Max-div Icons already.";
                message = "{name} has requested control for Max-div Icons already."
                values = {name: connData};
                break;
            case "errorRecording":
                message = "recording error";
                break;
            //TP-2474 -- Message to be shown for when Primary expert erases the canvas of the Passive expert
            case "eraseCanvas":
                message = "Erase Passive canvas";
                values = {name: connData};
                break;
            default:
                break;
        }  
        this.setState({ /**TP-1305*/ flashValues: values, flashMsgText: message, showFlashMessage: true, flashLeft: left, flashTop: top });
    }

    // call users while in session 
    callAndInviteUser = (flag, key) => {
        //printConsole(flag);
        try {
            if (this.state.showHideMaxDivControl && flag === false) {
                key = parseInt(key); // TP-2170
                //printConsole(`element index ${key}`);
                let {onlineList} = this.state;                
                const [to] = onlineList.filter((e, i) => key === i);
                //printConsole(to);
                to.isBusy = !flag;
                onlineList[key] = to;
                this.setState({onlineList, selectedUser: to}, () => {
                    this.timer = setTimeout(() => this.cancelCall({ mode: 'callUser', msg: i18nMark(`{recipient} did not pick up the call, please try again later.`), key }), window._env_.REACT_APP_API_CALL_TIME_OUT);
                    this.authService.socket.emit('connect-the-call',{ recipientEmail: to.email, callerName: this.props.user.firstname + " " +this.props.user.lastname, isCancelled: false, group_id: this.props.selectedGroupId });                    
                })
            }
        } catch (exception) {
            printConsole("exception in callAndInviteUser Method ");
            printConsole(exception);
        }
    }

    // cancel call to users online
    cancelCall = ({ mode = 'view', msg = '', key = null }) => {
        printConsole("Call timed out");
        const {first_name, last_name} = this.props.user;
        const {selectedGroupId} = this.props;
        let {onlineList} = this.state;
        //printConsole(onlineList);
        if (key!== null && onlineList[key]) {
            printConsole(onlineList[key]);
            let onlineEmails = [];
            onlineEmails.push(onlineList[key].email); 
            this.authService.socket.emit('send-missed-call-notification', { 
                emails: onlineEmails
            });
        } 
        /* let to = selectedUser;
        if (key === null) 
            onlineList.forEach((o,k) => {
                if (o === to )
                    key = k;
                    return;
            });
        else    
            [to] = onlineList.filter((e, i) => key === i); */
        //if (to === undefined) {
            onlineList.forEach(o => {
                if (o.isBusy === true) {
                    o.isBusy = false;
                    let to = o;
                    this.authService.socket.emit('connect-the-call',
                    { recipientEmail: to.email, 
                        isCancelled: true, 
                        callerName: first_name + last_name, 
                        group_id: selectedGroupId
                    });
                }
            })
        /* } else {
            to.isBusy = false;
            console.log(key);
            onlineList[key] = to;
        } */
        //printConsole(`cancel call ${onlineList}`);
        if (this.timer) clearTimeout(this.timer);
        this.setState({
            onlineList
        })
    }

    // click method for remotely disconnecting users in a session
    onClickStopSessionUser = (divId) => {
        //printConsole(divId);
        const {g_subInfoArr} = this.state;
        let ii =0;
        let toObject = "";
        for (ii=0; ii< g_subInfoArr.length; ii++) {
            const crossbtn = document.getElementById("disconnectuser_"+g_subInfoArr[ii].g_stream.streamId);            
            if (g_subInfoArr[ii].g_divId === divId) {  
                if (crossbtn !== null)
                    crossbtn.parentNode.removeChild(crossbtn);
                const dataArray = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                toObject = dataArray[3];
            }
        }
        this.sessionSignal.sendSignalStopSessionUser(toObject); //send the signal to bump out users
    }

    // turn on/off the Expert's mike audio
    toggleUserMike = (divId, flag=null) => {
        try {
            //if(this.state.showHideMaxDivControl) {
                //printConsole(`Flag: ${flag}`);
                let fromGridTile = false;
                //if (flag === null) fromGridTile = true;
                if (divId.includes("stream_") === true) fromGridTile = true;
                //printConsole(`element divId ${divId}`)
                const {g_subInfoArr} = this.state;   
                //TP-1918 -- To get the previous unMuted Expert Mike count
                let isExpertAudioUnMutedCounterPrev = 0;
                g_subInfoArr.forEach(subInfo => {
                    if (!subInfo.disableMike) isExpertAudioUnMutedCounterPrev += 1;
                })            
                
                let divConObj;
                // mute Expert mike for remote user
                let nLen = g_subInfoArr.length;
                let ii = 0;
                for(ii = 0; ii < nLen; ii++) 
                {
                    let muteBtndiv;
                    if (fromGridTile) {
                        if( g_subInfoArr[ii].g_divId === divId ) 
                        {
                            if (g_maxDivId === divId)
                                this.setState({muteMikeForMaxDiv: !this.state.muteMikeForMaxDiv});
                            // Making the audio track for that participant enabled/disabled
                            if (flag === null) {
                                flag = !g_subInfoArr[ii].disableMike;
                            }
                            g_subInfoArr[ii].disableMike = flag;
                            muteBtndiv = document.getElementById('muteMikeEach_'+g_subInfoArr[ii].g_stream.streamId)
                            divConObj = g_subInfoArr[ii].g_stream.connection;

                            if (flag === true){
                                //change the mute Mike icons on top of video element                            
                                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                                    muteBtn[0].classList.remove("fa-microphone");
                                    muteBtn[0].classList.add("fa-microphone-slash");
                                    muteBtn[0].style.color = "red"
                                }
                            } else {
                                //change the mute Mike icons on top of video element                            
                                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                                    muteBtn[0].classList.add("fa-microphone");
                                    muteBtn[0].classList.remove("fa-microphone-slash");
                                    muteBtn[0].style.color = "black"
                                }
                            }

                            //set the changed session Joinee in the array
                            this.updateEachSessionJoinee(g_subInfoArr[ii]);
                        }
                    } else {
                        const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                        if(divId === dataArr[0]) {
                            if (g_maxDivId === g_subInfoArr[ii].g_divId)
                                this.setState({muteMikeForMaxDiv: !this.state.muteMikeForMaxDiv});
                            
                                flag = !g_subInfoArr[ii].disableMike;
                            /* } else
                                flag = !flag; */
                            g_subInfoArr[ii].disableMike = flag;
                            muteBtndiv = document.getElementById('muteMikeEach_'+g_subInfoArr[ii].g_stream.streamId)
                            divConObj = g_subInfoArr[ii].g_stream.connection;

                            if (flag === true){
                                //change the mute Mike icons on top of video element                            
                                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                                    muteBtn[0].classList.remove("fa-microphone");
                                    muteBtn[0].classList.add("fa-microphone-slash");
                                    muteBtn[0].style.color = "red"
                                }
                            } else {
                                //change the mute Mike icons on top of video element                            
                                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                                    muteBtn[0].classList.add("fa-microphone");
                                    muteBtn[0].classList.remove("fa-microphone-slash");
                                    muteBtn[0].style.color = "black"
                                }
                            }

                            //set the changed session Joinee in the array
                            this.updateEachSessionJoinee(g_subInfoArr[ii]);
                        }
                    }                                        
                }
                this.muteExpertMikeForMaxDiv(!flag, divConObj);

                //TP-1918 -- To get the latest unMuted Expert Mike count
                let isExpertAudioUnMutedCounterLatest = 0;
                g_subInfoArr.forEach(subInfo => {
                    if (!subInfo.disableMike) isExpertAudioUnMutedCounterLatest += 1;
                })
                let recordingFlag = false;
                printConsole(`Prev unmuted Mike counter: ${isExpertAudioUnMutedCounterPrev} current unMuted mike counter: ${isExpertAudioUnMutedCounterLatest}`);
                if (this.state.startRecording === true && isExpertAudioUnMutedCounterPrev !== isExpertAudioUnMutedCounterLatest && ((isExpertAudioUnMutedCounterPrev === 0 && isExpertAudioUnMutedCounterLatest > 0) || (isExpertAudioUnMutedCounterPrev > 0 && isExpertAudioUnMutedCounterLatest === 0))) {
                    printConsole("The Expert's individual Mikes count changed while recording is ongoing")
                    recordingFlag = true;
                }

                this.setState({ g_subInfoArr }, ()=>{
                    // TP-1918
                    if (recordingFlag === true) {
                        printConsole("Stop and re-start a new recording due to Mute/un-Mute Slider/Grid View Mic icon");
                        this.stopAndContinueRecording(); //TP-1881 stop the old recording and start a new recording when Side Bar Mute Mike is clicked
                    }
                    //if (fromGridTile)
                        this.setState({isParticipantChanged : true}, () => {
                            this.setState({isParticipantChanged : false});
                        }); // To set the Side Bar Participant list Icons
                });
            //}
            
        } catch (error) {
            printConsole(error)
        }
    }

    // turn on/off the remote user's audio
    toggleUserSpeaker = (divId, flag=null) => {
        try {
            //if (this.state.showHideMaxDivControl) {  
                //printConsole(`Flag: ${flag}`);
                let fromGridTile = false;
                //if (flag === null) fromGridTile = true;    
                if (divId.includes("stream_") === true) fromGridTile = true;          
                //printConsole(`element divId ${divId}`);
                let {g_subInfoArr} = this.state;                

                // mute remote user
                let nLen = g_subInfoArr.length;
                let ii = 0;
                for(ii = 0; ii < nLen; ii++)
                { 
                    let muteBtndiv;
                    if (fromGridTile) {
                        if( g_subInfoArr[ii].g_divId === divId && g_subInfoArr[ii].disableRemoteAudio === false)
                        {
                            if (g_maxDivId === divId)
                                this.setState({bRemoteMute: (this.state.bRemoteMute === true)? false:true});
                            // Making the audio track for that participant enabled/disabled
                            if (flag === null) {
                                flag = !g_subInfoArr[ii].disableAudio;
                            }
                            g_subInfoArr[ii].g_subscriber.subscribeToAudio(!flag);
                            g_subInfoArr[ii].disableAudio = flag;   
                            muteBtndiv = document.getElementById('toggleAudio_'+g_subInfoArr[ii].g_stream.streamId)

                            if (flag === true){
                                //change the mute Speaker icons on top of video element                            
                                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                                    muteBtn[0].classList.remove("fa-volume-up");
                                    muteBtn[0].classList.add("fa-volume-mute");
                                    muteBtn[0].style.color = "red"
                                }
                            } else {
                                //change the mute Speaker icons on top of video element                            
                                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                                    muteBtn[0].classList.add("fa-volume-up");
                                    muteBtn[0].classList.remove("fa-volume-mute");
                                    muteBtn[0].style.color = "black"
                                }
                            }

                            //set the changed session Joinee in the array
                            this.updateEachSessionJoinee(g_subInfoArr[ii]);
                        } else if (g_subInfoArr[ii].g_divId === divId && g_subInfoArr[ii].disableRemoteAudio === true) {
                            return;
                        }
                    } else {
                        const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                        if(divId === dataArr[0]) {
                            if (g_maxDivId === g_subInfoArr[ii].g_divId)
                                this.setState({bRemoteMute: (this.state.bRemoteMute === true)? false:true});
                            //flag = !flag;
                            flag = !g_subInfoArr[ii].disableAudio;
                            g_subInfoArr[ii].g_subscriber.subscribeToAudio(!flag);
                            g_subInfoArr[ii].disableAudio = flag;   
                            muteBtndiv = document.getElementById('toggleAudio_'+g_subInfoArr[ii].g_stream.streamId)

                            if (flag === true){
                                //change the mute Speaker icons on top of video element                            
                                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                                    muteBtn[0].classList.remove("fa-volume-up");
                                    muteBtn[0].classList.add("fa-volume-mute");
                                    muteBtn[0].style.color = "red"
                                }
                            } else {
                                //change the mute Speaker icons on top of video element                            
                                if (muteBtndiv !== undefined && muteBtndiv !== null) {
                                    let muteBtn = muteBtndiv.getElementsByClassName("grid-icon");
                                    muteBtn[0].classList.add("fa-volume-up");
                                    muteBtn[0].classList.remove("fa-volume-mute");
                                    muteBtn[0].style.color = "black"
                                }
                            }

                            //set the changed session Joinee in the array
                            this.updateEachSessionJoinee(g_subInfoArr[ii]);
                        }
                    }                   
                }
                
                              
                this.setState({g_subInfoArr}, () => {
                    //if (fromGridTile)
                        this.setState({isParticipantChanged : true}, () => {
                            this.setState({isParticipantChanged : false});
                        });
                });
                
            //}
            
        } catch (error) {
            printConsole(error);
        }
    }

    // turn on/off the user's remote video
    toggleUserVideo = (divId, flag=null) => {
        try {
            if (this.state.showHideMaxDivControl) {   
                //printConsole(`Flag: ${flag}`);             
                let fromGridTile = false;
                if (divId.includes("stream_") === true) fromGridTile = true;
                //printConsole(`element divId ${divId}`);
                let {g_subInfoArr} = this.state;
                let to = "";                

                // turn on/off remote user video
                let nLen = g_subInfoArr.length;
                let ii = 0;
                let payload = [];
                for(ii = 0; ii < nLen; ii++)
                {
                    if (fromGridTile) {
                        if( g_subInfoArr[ii].g_divId === divId )
                        {
                            // Making the video track for that participant enabled/disabled
                            /* if (flag === null) { */
                                flag = !g_subInfoArr[ii].disableVideo;
                            /* } else {
                                flag = !flag;
                            } */
                            /**	TP-1996	*/
                            if (flag === true) {
                                g_subInfoArr[ii].g_subscriber.subscribeToVideo(!flag);
                                g_subInfoArr[ii].disableVideo = flag;
                            } 
                            let tmp = g_subInfoArr[ii].g_stream.connection.data.split(":::");       
                            to = tmp[3];
                            
                            //set the changed session Joinee in the array
                            /**	TP-1996	*/ if (flag === true) this.updateEachSessionJoinee(g_subInfoArr[ii]);
                        }                                           
                    } else {
                        const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                        if(divId === dataArr[0]) {
                            /* if (flag === null) { */
                                flag = !g_subInfoArr[ii].disableVideo;
                            /* } else {
                                flag = !flag;
                            }   */                          
                            /**	TP-1996	*/
                            if (flag === true) {
                                g_subInfoArr[ii].g_subscriber.subscribeToVideo(!flag);
                                g_subInfoArr[ii].disableVideo = flag; 
                            }                                  
                            to = dataArr[3];
                            divId = g_subInfoArr[ii].g_divId;

                            //set the changed session Joinee in the array
                            /**	TP-1996	*/if (flag === true) this.updateEachSessionJoinee(g_subInfoArr[ii]);
                        }
                    }
                    const dataArray = g_subInfoArr[ii].g_stream.connection.data.split(":::")
                    const obj = {
                        flag: g_subInfoArr[ii].disableVideo, 
                        userId: parseInt(dataArray[0]),
                        email: dataArray[3]
                    }
                    payload.push(obj); 
                }

                //printConsole(`Turn on/off video signal being sent to user ----> ${to}`);
                this.sessionSignal.sendSignalTurnOnUserVideo(!flag, to);

                // Send the signal to other experts to keep Passive experts in sync with Main expert
                if (/*TP-2029*/flag === true && g_expertConnsArr.length > 0) {
                    g_expertConnsArr.forEach((connectionObj) => {
                        const toArray = connectionObj.data.split(":::");
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalTurnOnMaxViewUserVideo(payload, toObject);
                    });
                }
                
                this.setState({g_subInfoArr}, () => {
                    //if (fromGridTile)
                        this.setState({isParticipantChanged : true}, () => {
                            this.setState({isParticipantChanged : false});
                        });// to change the Side Bar button state                    
                    const list = g_subInfoArr.filter(p => p.disableVideo === false && p.g_stream.hasVideo === true);
                    let length = 0;
                    //printConsole(`list --------------------------- ${list}`)
                    if (list !== undefined) {
                        //printConsole(`Length of Users with Video on -- ${list.length}`)
                        length = list.length;
                    }
                    this.setState({ userVideoCount: length }, () => {
                        /* if (length > 1) this.setState({ disableGridView : false })
                        else if (length <= 1) this.setState({ disableGridView: true }); */
                        //printConsole(`Changed participant list ----> ${g_subInfoArr}`);
                        if (this.state.gridViewMode === false && divId === g_maxDivId && this.state.g_subInfoArr.length - g_expertConnsArr.length === 1) {
                            //printConsole(`Only one video user's video was turned ${flag}`);
                            if (flag === false)
                                this.maximizeDiv(null, {divId});
                            this.setState({ showHideDivIconAudioOnly: flag });
                            this.props.showScreenShareHeaderButton(flag)
                            
                        } else if (this.state.gridViewMode === false && divId === g_maxDivId && g_subInfoArr.length - g_expertConnsArr.length > 1) {
                            const newParticipant = g_subInfoArr.find((subInfo) => 
                                (subInfo.g_divId !== divId && subInfo.g_stream.hasVideo === true && subInfo.disableVideo === false)
                            )
                            if (newParticipant) {
                                //printConsole(`Showing the new Maxview Expert ----------->`);
                                //printConsole(newParticipant);                                   
                                this.maximizeDiv(null, {divId: newParticipant.g_divId});
                                        
                                //this.sendMaxViewSignal({flag: false});// disable max view for outgoing
                                //this.onClickMaximise({participant: newParticipant})
                                if (this.state.showHideDivIconAudioOnly === true) {
                                    this.setState({ showHideDivIconAudioOnly: false})
                                    this.props.showScreenShareHeaderButton(false)
                                }
                            } else {
                                //printConsole(`No video user's video was turned ${flag}`);
                                //this.maximizeDiv(null, {divId: g_maxDivId});

                                

                                this.setState({ showHideDivIconAudioOnly: flag });
                                this.props.showScreenShareHeaderButton(flag)
                            }
                        } else if (this.state.gridViewMode === false && divId !== g_maxDivId) {
                            //printConsole(`Behind user's video was turned ${flag}`);
                            //printConsole(this.state.userVideoCount);
                            if (flag === true && this.state.userVideoCount === 1) {
                                //printConsole(`Find new technician with enabled video and place it on Max-view`);
                                const newParticipant = g_subInfoArr.find((subInfo) => 
                                    (subInfo.g_divId !== divId && subInfo.g_stream.hasVideo === true && subInfo.disableVideo === false)
                                )
                                if (newParticipant) {
                                    //printConsole(`Showing the new Maxview user ----------->`);
                                    //printConsole(newParticipant);
                                    this.maximizeDiv(null, {divId: newParticipant.g_divId});
                                    if (this.state.showHideDivIconAudioOnly === true) {
                                        this.setState({ showHideDivIconAudioOnly: false })
                                        this.props.showScreenShareHeaderButton(false)
                                    }
                                } else 
                                    this.maximizeDiv(null, {divId: g_maxDivId});
                            } else if (flag === false && this.state.userVideoCount === 0) {
                                //printConsole(`Place the user whose video is getting turned on now on Max-view`);
                                //printConsole(`Showing the new Maxview user's divId -----------> ${divId}`);
                                this.maximizeDiv(null, {divId});
                                g_subInfoArr.forEach(subInfo => {
                                    if (subInfo.g_divId === divId) {
                                        g_maxDivId = divId;
                                        //if (!(this.state.expertSSAll && g_expertConnsArr.length >0))
                                        // T32-463 -- New Multi-expert Signal trigger implementation
                                        const dataArr = subInfo.g_stream.connection.data.split(":::")
                                        this.sendSignalSyncExperts({
                                            show_grid: false,
                                            max_view_technician: dataArr[3], 
                                            hand_icon_enabled: true,
                                            header_fileShare_active: (this.state.bShareAsset && this.state.shareOnDiv === null) ? true : false, //TP-2378             
                                            reason: "show_action"}, 
                                        null);  // send the max div tech details                                        
                                        //break;
                                    }
                                })
                                if (this.state.showHideDivIconAudioOnly === true) {
                                    this.setState({ showHideDivIconAudioOnly: false })
                                    this.props.showScreenShareHeaderButton(false)
                                }
                            
                            } else {
                                if (flag === false) {
                                    //printConsole(`check if the last Max-view user still has Video turned on or not`)
                                    if (this.state.userVideoCount === 1) this.hideGridViewIcon(false);
                                    const maxParticipant = g_subInfoArr.find((subInfo) => 
                                        (subInfo.g_divId === g_maxDivId && subInfo.g_stream.hasVideo === true && subInfo.disableVideo === false)
                                    )
                                    if (maxParticipant) {
                                        //printConsole(`Showing the old Maxview user ----------->`);
                                        //printConsole(maxParticipant);
                                        this.maximizeDiv(null, {divId: g_maxDivId});
                                        if (this.state.userVideoCount >= 1) this.hideGridViewIcon(false);
                                        if (this.state.showHideDivIconAudioOnly === true) {
                                            this.setState({ showHideDivIconAudioOnly: false })
                                            this.props.showScreenShareHeaderButton(false)
                                        }
                                    } else {
                                        //printConsole(`No the old Max-view user has video turned off ----------->`);                                        
                                        const newParticipant = g_subInfoArr.find((subInfo) => 
                                            (subInfo.g_divId !== g_maxDivId && subInfo.g_stream.hasVideo === true && subInfo.disableVideo === false)
                                        )
                                        if (newParticipant) {
                                            //printConsole(`Showing the new Maxview user ----------->`);
                                            //printConsole(newParticipant);
                                            this.maximizeDiv(null, {divId: newParticipant.g_divId});
                                            if (this.state.showHideDivIconAudioOnly === true) {
                                                this.setState({ showHideDivIconAudioOnly: false })
                                                this.props.showScreenShareHeaderButton(false)
                                            }
                                        }
                                    }
                                        //this.maximizeDiv(null, {divId: g_maxDivId});
                                } else 
                                    this.maximizeDiv(null, {divId: g_maxDivId});
                            } 
                        } else if (this.state.gridViewMode === true) {
                            //printConsole(this.state.userVideoCount);
                            if (this.state.userVideoCount > 1) {
                                this.drawSubscriberUI(window.innerHeight, window.innerWidth, null);
                                
                            } else {
                                //printConsole(`Showing the new Maxview Expert ----------->`);
                                let nLen = g_subInfoArr.length;
                                let ii = 0;
                                for(ii = 0; ii < nLen; ii++)
                                {
                                    if( g_subInfoArr[ii].disableVideo === false && g_subInfoArr[ii].g_stream.hasVideo === true )
                                    {
                                        this.maximizeDiv(null, {divId: g_subInfoArr[ii].g_divId});
                                    }
                                }
                                if (this.state.showHideDivIconAudioOnly === true) this.setState({ showHideDivIconAudioOnly: false })
                            }
                        }
                        
                    });
                })
                
            }
            
        } catch (error) {
            printConsole(error);
        }
    }

    //TP-2861
    resetUserZoom = (divId, flag=null) => {
        try {
            if (this.state.showHideMaxDivControl) {
                let {g_subInfoArr} = this.state;
                // turn on/off remote user video
                let nLen = g_subInfoArr.length;
                let ii = 0;
                for(ii = 0; ii < nLen; ii++)
                {
                    if( g_subInfoArr[ii].g_divId === divId )
                    {
                        // Rotating the zoom level value for that participant                            
                        flag = 1;                            
                        g_subInfoArr[ii].zoomLevel = flag;   
                        if (g_subInfoArr[ii].g_divId === g_maxDivId) 
                            this.setState({ zoom_level: flag.toString(), bZoomClick: flag > 1 ? true : false });
                        
                            const expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
                            this.sendSignalRemoteZoomLevel(flag, expertDivConnObj);

                            const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                            this.sendSignalSyncExperts({
                                show_grid: true, 
                                email: dataArr[3],                                    
                                torch_button_active: g_subInfoArr[ii].torchActive,
                                zoom_level: flag,
                                hand_icon_enabled: true,
                                reason: "update_action"},  
                            null);
                        
                        let zoomBtndiv = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId)
                        
                        if (flag === 1){
                            //change the zoom icons on top of video element                            
                            if (zoomBtndiv !== undefined && zoomBtndiv !== null) {
                                let zoomBtn = zoomBtndiv.getElementsByClassName("grid-icon");
                                //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                // Add the new changed Zoom level Icon
                                /* let zoomIcon = document.createElement('i');
                                zoomIcon.classList.add('fas', 'fa-binoculars', 'fa-stack-1x', 'grid-icon');
                                zoomBtndiv.appendChild(zoomIcon);*/
                                zoomBtn[0].style.color = "black";

                                // Change the zoom level text on top of Video element
                                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                zoomLevel.className = 'zoom-text';
                                zoomLevel.classList.add(zoomClassArray[g_subInfoArr[ii].zoomLevel-1].class);
                                zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                            }
                        } else {
                            //change the zoom icons on top of video element                            
                            if (zoomBtndiv !== undefined && zoomBtndiv !== null) {
                                let zoomBtn = zoomBtndiv.getElementsByClassName("grid-icon");
                                //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                // Add the new changed Zoom level Icon
                                /* let zoomIcon = document.createElement('span');
                                zoomIcon.classList.add('fa-stack-1x', 'fa-zoom-text', 'grid-icon');
                                zoomIcon.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                zoomBtndiv.appendChild(zoomIcon);*/
                                zoomBtn[0].style.color = "red";

                                // Change the zoom level text on top of Video element
                                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                zoomLevel.className = 'zoom-text';
                                zoomLevel.classList.add(zoomClassArray[g_subInfoArr[ii].zoomLevel-1].class);
                                zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                zoomLevel.parentElement.style.display = "block";
                            }
                        }
                        //set the changed session Joinee in the array
                        //this.updateEachSessionJoinee(g_subInfoArr[ii]);
                    }
                }

                this.setState({g_subInfoArr}, () => {
                    //if (fromGridTile)
                        this.setState({isParticipantChanged : true}, () => {
                            this.setState({isParticipantChanged : false});
                        });
                });
            }            
        } catch (error) {
            printConsole(error);
        }
    }

    // rotate the Zoom level Icon on Grid View
    rotateZoomLevel = (divId, flag=null) => {
        try {
            if (this.state.showHideMaxDivControl) {
                let {g_subInfoArr} = this.state;
                // turn on/off remote user video
                let nLen = g_subInfoArr.length;
                let ii = 0;
                for(ii = 0; ii < nLen; ii++)
                {
                    if( g_subInfoArr[ii].g_divId === divId )
                    {
                        // Rotating the zoom level value for that participant                            
                        flag = (g_subInfoArr[ii].zoomLevel > 0 && g_subInfoArr[ii].zoomLevel < 5) ? g_subInfoArr[ii].zoomLevel + 1: 1;                            
                        g_subInfoArr[ii].zoomLevel = flag;   
                        if (g_subInfoArr[ii].g_divId === g_maxDivId) 
                            this.setState({ zoom_level: flag.toString(), bZoomClick: flag > 1 ? true : false });
                        
                            const expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
                            this.sendSignalRemoteZoomLevel(flag, expertDivConnObj);

                            const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                            this.sendSignalSyncExperts({
                                show_grid: true, 
                                email: dataArr[3],                                    
                                torch_button_active: g_subInfoArr[ii].torchActive,
                                zoom_level: flag,
                                hand_icon_enabled: true,
                                reason: "update_action"},  
                            null);
                        
                        let zoomBtndiv = document.getElementById('rotateZoom_'+g_subInfoArr[ii].g_stream.streamId)
                        
                        if (flag === 1){
                            //change the zoom icons on top of video element                            
                            if (zoomBtndiv !== undefined && zoomBtndiv !== null) {
                                let zoomBtn = zoomBtndiv.getElementsByClassName("grid-icon");
                                //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                // Add the new changed Zoom level Icon
                                /* let zoomIcon = document.createElement('i');
                                zoomIcon.classList.add('fas', 'fa-binoculars', 'fa-stack-1x', 'grid-icon');
                                zoomBtndiv.appendChild(zoomIcon);*/
                                zoomBtn[0].style.color = "black";

                                // Change the zoom level text on top of Video element
                                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                zoomLevel.className = 'zoom-text';
                                zoomLevel.classList.add(zoomClassArray[g_subInfoArr[ii].zoomLevel-1].class);
                                zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                zoomLevel.parentElement.style.display = "none";// Hide the zoom level if 1X
                            }
                        } else {
                            //change the zoom icons on top of video element                            
                            if (zoomBtndiv !== undefined && zoomBtndiv !== null) {
                                let zoomBtn = zoomBtndiv.getElementsByClassName("grid-icon");
                                //zoomBtn[0].parentNode.removeChild(zoomBtn[0]);
                                // Add the new changed Zoom level Icon
                                /* let zoomIcon = document.createElement('span');
                                zoomIcon.classList.add('fa-stack-1x', 'fa-zoom-text', 'grid-icon');
                                zoomIcon.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                zoomBtndiv.appendChild(zoomIcon);*/
                                zoomBtn[0].style.color = "red";

                                // Change the zoom level text on top of Video element
                                let zoomLevel = document.getElementById("zoomLevel_" + g_subInfoArr[ii].g_stream.streamId);
                                zoomLevel.className = 'zoom-text';
                                zoomLevel.classList.add(zoomClassArray[g_subInfoArr[ii].zoomLevel-1].class);
                                zoomLevel.innerHTML = processZoomLevels(flag + "X", this.props.i18n);
                                zoomLevel.parentElement.style.display = "block";
                            }
                        }
                        //set the changed session Joinee in the array
                        //this.updateEachSessionJoinee(g_subInfoArr[ii]);
                    }
                }

                this.setState({g_subInfoArr}, () => {
                    //if (fromGridTile)
                        this.setState({isParticipantChanged : true}, () => {
                            this.setState({isParticipantChanged : false});
                        });
                });
            }            
        } catch (error) {
            printConsole(error);
        }
    }

    // turn on/off the user's torch remotely
    toggleUserTorch = (divId, flag=null) => {
        try {
            if (this.state.showHideMaxDivControl) {
                let {g_subInfoArr} = this.state;
                // turn on/off remote user video
                let nLen = g_subInfoArr.length;
                let ii = 0;
                for(ii = 0; ii < nLen; ii++)
                {
                    if( g_subInfoArr[ii].g_divId === divId )
                    {
                        // Toggle the torch icon for that participant                            
                        flag = !g_subInfoArr[ii].torchActive;                            
                        g_subInfoArr[ii].torchActive = flag; 
                        if (g_subInfoArr[ii].g_divId === g_maxDivId) 
                            this.setState({ bTorchClick: flag });
                            const expertDivConnObj = g_subInfoArr[ii].g_stream.connection;
                            this.sendSignalTechnicianTorch(flag, expertDivConnObj);

                            const dataArr = g_subInfoArr[ii].g_stream.connection.data.split(":::");
                            this.sendSignalSyncExperts({
                                show_grid: true, 
                                email: dataArr[3],                                    
                                torch_button_active: flag,
                                zoom_level: g_subInfoArr[ii].zoomLevel,
                                hand_icon_enabled: true,
                                reason: "update_action"},  
                            null);
                        

                        let toggleBtndiv = document.getElementById('toggleTorch_'+g_subInfoArr[ii].g_stream.streamId)

                        if (flag === true){
                            //change the Torch icon on top of video element                            
                            if (toggleBtndiv !== undefined && toggleBtndiv !== null) {
                                let toggleIcon = toggleBtndiv.getElementsByClassName("grid-icon");
                                toggleIcon[0].style.color = "red"
                            }
                        } else {
                            //change the Torch icon on top of video element                            
                            if (toggleBtndiv !== undefined && toggleBtndiv !== null) {
                                let toggleIcon = toggleBtndiv.getElementsByClassName("grid-icon");                                    
                                toggleIcon[0].style.color = "black"
                            }
                        }

                        //set the changed session Joinee in the array
                        //this.updateEachSessionJoinee(g_subInfoArr[ii]);
                    }
                }

                this.setState({g_subInfoArr}, () => {
                    //if (fromGridTile)
                        this.setState({isParticipantChanged : true}, () => {
                            this.setState({isParticipantChanged : false});
                        });
                });
            }
        } catch (error) {
            printConsole(error);
        }
    }

    //TP-841 -- Set the current opacity value for Primary expert
    setAssetOpacity = (val) => {
        const number = val/100;
        const opacity = number.toFixed(2);
        this.setState({fileOpacity: opacity}, () => {
            //console.log("new set opacity is =====>", this.state.fileOpacity);
            // Send the signal to all users in the current session
            // to set the same opacity value on their end
            this.sendOpacity(opacity);
        });
    }

    checkSmallScreenDevice = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let flag = false;
        if (isiPad || isAndroid) {
            flag = true;
        }
        return flag;
    }

    render =() => {        
        if (this.props.sessionInfo === undefined || this.props.sessionInfo === null)
            return (<div>Loading AV cards...</div>);
        let maxDivSub = {};
        //console.log(window.innerWidth);
        //printConsole(`isiOS device ? ${this.props.isiOS} && isSafari ? ${this.props.isSafari} && isFirefox ? ${this.props.isFirefox}`);
        if (this.state.gridViewMode === false && this.state.showHideMaxDivControl === false && this.state.g_subInfoArr.length > 0) {
            this.state.g_subInfoArr.forEach(val => {
                if (val.g_divId === g_maxDivId) 
                    maxDivSub = val;
            })
        }
        const { hideButton } = this.state;
        const { screen_share_mode, torch_enabled, zoom_enabled, enable_remote_capture } = this.props.sessionInfo;
        //let currWidth = window.innerWidth;
        //let currHeight = window.innerHeight - headerSize;
        let annotateStyle = { color: 'black' };
        if (this.state.bAnnotate) annotateStyle = { color: 'red' };
        let shareStyle = { color: 'black' };
        if (this.state.bShareAsset) shareStyle = { color: 'red' };
        let cameraStyle = { color: 'black' };
        if (this.state.bRemoteCapture) cameraStyle = { color: 'red' };
        let chatStyle = { color: 'black' };
        if (this.state.bMaxChat) chatStyle = { color: 'red' };
        let muteStyle = { color: 'black' };
        if (this.state.bRemoteMute) muteStyle = { color: 'red' };
        //let styleDivMaxBtnContainer = { position: 'absolute', left: '25%', top: '90%', backgroundColor: 'transparent' };
        //if (g_subInfoArr && g_subInfoArr.length === 1) styleDivMaxBtnContainer = { position: 'absolute', left: '25%', top: '90%', backgroundColor: 'transparent' };
        let maxDivMuteMicColor = this.state.muteMikeForMaxDiv ? 'red' : 'black';
        let techShareStyle = { color: 'black' };
        if (this.state.techShareScreen) techShareStyle = { color: 'red' };
        let expertShareStyle = { color: 'black' };
        if (this.state.expertShareScreenMaxDiv) expertShareStyle = { color: 'red' };
        let passiveExpertShareStyle = { color: 'black' };
        if (this.state.expertSSMode) passiveExpertShareStyle = { color: 'red' };
        let getExpertControlStyle = { color: 'black' };
        if (this.state.clickHandSignal === true) getExpertControlStyle = { color: 'red' };
        else if (this.state.clickHandSignal === false) getExpertControlStyle = { color: 'black' };
        let zoomStyle = { color: 'black' };
        if (this.state.bZoomClick) zoomStyle = { color: 'red' };
        let torchStyle = { color: 'black' };
        if (this.state.bTorchClick) {
            torchStyle = { color: 'red' };
        }
        let penStyle = { color: this.state.pencolor }; //'red'
        let shapeStyle = { color: this.state.pencolor };
        let crossStyle = { color: 'black' };
        if (this.state.isFreeDrawing) crossStyle = { color: this.state.pencolor };
        let circleStyle = { color: 'black' };
        if (this.state.isCircleActive) circleStyle = { color: this.state.pencolor };
        let rectStyle = { color: 'black' };
        if (this.state.isRectActive) rectStyle = { color: this.state.pencolor };
        let arrowStyle = { color: 'black' };
        if (this.state.isArrowActive) arrowStyle = { color: this.state.pencolor };
        let pointerStyle = { color: 'black' };
        if (this.state.isPointerActive) pointerStyle = { color: this.state.pencolor };
        //TP-2474
        let erasorStyle = { color: 'black' };
        if (this.state.isErasorActive) erasorStyle = { color: this.state.pencolor };
        // NS2-274
        let maxDivRecordScreenColor = this.state.startRecording ? 'red' : 'black';
        let maxDivRecordScreenIcon = this.state.startRecording ? 'fa-stop' : 'fa-record-vinyl';
        // NS2-367
        let captureScreenClass = this.state.screenCaptureClicked ? 'no-pointer-events disable-image' : '';
        let buttonSize = (window.innerWidth > 1299) ? "fa-lg" : "fa-sm";
        let handSize = (window.innerWidth > 1299) ? "fa-2x" : "fa-lg";
        let actionReqControlStyle = 'hideDiv';
        let maxdivBtnControlStyle = 'hideDiv';
        let gridTileBtnCntStyle = 'hideDiv';
        let widthClassName = '';
        if (this.state.gridViewMode === false)
            widthClassName = 'w-100';
        if (this.state.showHideDivIconAudioOnly === false && this.state.gridViewMode === false) {
            if (this.state.showHideMaxDivControl === true) {
                actionReqControlStyle = 'hideDiv';
                maxdivBtnControlStyle = 'showDiv';
                gridTileBtnCntStyle = 'hideDiv';
            } else {
                actionReqControlStyle = 'showDiv';
                maxdivBtnControlStyle = 'hideDiv';
                gridTileBtnCntStyle = 'showIcon';
            }
        } else if (this.state.gridViewMode === true) {
            gridTileBtnCntStyle = 'hideDiv';
            if (this.state.showHideMaxDivControl === true) 
                actionReqControlStyle = 'hideDiv';
            else    
                actionReqControlStyle = 'showDiv';
        }
        // NS2-507
        let handIconStyle = { bottom: '5%' };
        if (this.state.gridViewMode === true)
            handIconStyle = { width: '100%', bottom: '8%' };
        //if (g_subInfoArr.length > 1) handIconStyle = { left: '35%', zIndex: '20', backgroundColor: 'transparent' };
        //const closeBtn = ();
        /* let maxWidth = '100px',  */let fileLeft = '10px', filewidth = '0px', /* fileheight = '0px', */ fileStyle = {}, bodyStyle = {};
        let embedHeight, bodyHeight, bodyWidth, sliderRight, sliderHeight, closeLeft;
        const sideBar = document.getElementById("sideBar");
        const paramElem = document.getElementById(g_maxDivId);
        //TP-2553
        if (sideBar !== undefined && sideBar !== null && this.state.showFileViewer === true) {
            //maxWidth = Math.floor(window.innerWidth - sideBar.offsetWidth - 20) + 'px';
            fileLeft = Math.floor(sideBar.offsetWidth - 20) + 'px';
            let paramElemVdoChild;
            if (this.state.gridViewMode) {
                paramElemVdoChild = document.getElementById(subscriberContainerDivId);
            }
            else if (paramElem) {
                if (isDivExpert(g_maxDivId)) {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-poster');
                } else {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-element');
                }
            }
            if (paramElemVdoChild) {
                filewidth = Math.floor(paramElemVdoChild.offsetWidth) + 'px';
                /* fileheight = Math.floor(paramElemVdoChild.offsetHeight) + 'px'; */
                let heightOffset = (!this.state.gridViewMode) ? 9 : 11;
                if (window.innerWidth > 1559) heightOffset = 6.5;
                bodyHeight = Math.floor(paramElemVdoChild.offsetHeight - (heightOffset * paramElemVdoChild.offsetHeight)/100);
                bodyWidth  = Math.floor(paramElemVdoChild.offsetWidth)
                embedHeight = Math.floor(paramElemVdoChild.offsetHeight - (8 * bodyHeight)/100);
                //console.log("bodyWidth  =>", bodyWidth);
                if (!this.state.gridViewMode) {
                    sliderRight = Math.floor((window.innerWidth - sideBar.offsetWidth - paramElemVdoChild.offsetWidth)/2 + 30 /**For the scrollbar */) + 'px';
                    closeLeft = "";
                    //console.log("sliderright --- ", sliderRight);
                } else {
                    sliderRight = "";
                    closeLeft = "94%";
                }
                sliderHeight = Math.floor((paramElemVdoChild.offsetHeight)* 3/5);
            }
            //TP-2606
            if (this.state.fileType && this.state.fileType.toLowerCase() === 'pdf' && this.props.isiOS === true) {
                fileStyle = {opacity: this.state.fileOpacity.toString(), maxWidth: filewidth, left: fileLeft, top: '-2%'};
                bodyStyle = {'maxHeight':'100%', 'maxWidth': '100%', 'align': 'center', 'overflowX': 'auto'};
            }
            else {
                fileStyle = {opacity: this.state.fileOpacity.toString(), maxWidth: filewidth, left: fileLeft, top: '-2%'};
                bodyStyle = {'height': bodyHeight+'px', 'width': bodyWidth+'px','maxHeight':'100%', 'maxWidth': '100%', 'align': 'center', 'overflowX': 'auto'};
            }
        }
        let fileNameTopMargin = (window.innerWidth > 1299 && window.innerWidth <= 1559) ? "13%" : (window.innerWidth > 1599) ? "10%" : "15%";
        let uploadSpeedTopMargin = (window.innerWidth > 1559) ? (!this.state.gridViewMode) ? "7.5%" : "4%" : (!this.state.gridViewMode) ? "7.5%" : "4%"; //TP-3201
        //TP-2606
        if (this.state.fileType && this.state.fileType !== '' && this.state.fileType.toLowerCase() === 'pdf') {
            fileNameTopMargin = (window.innerWidth > 1559) ? "12%" : "15%";
        } 
        //if (this.props.startTimer === true) {
            return (
                <div className="d-flex">
                    { this.state.showHideMaxDivControl && this.state.bRemoteCapture && this.state.isLoading ?
                        <span className={"stop-rc fa-stack fa-3x "} >
                            <SessionIcon id="stopRC" circleClass="fas fa-circle fa-stack-2x"
                                iconClass="fas fa-times fa-stack-1x" tooltipPlament="top"
                                innerClass="tip-max no-pointer-events" tooltip="session.stopRC" tooltipStyle="Trans"
                                onClickMethod = {() => this.onClickCamera(true)} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                    
                            {/* <a href="#!" id="stopRC" onClick={() => this.onClickCamera(true)}>
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fas fa-times fa-stack-1x" ></i>
                                <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="stopRC">                                            
                                    <Trans>session.stopRC</Trans>                                            
                                </UncontrolledTooltip>
                            </a> */} 
                        </span>
                        :
                        ''
                    }
                    { this.state.showHideMaxDivControl && this.state.bShareAsset && this.state.isLoading ?
                        <span className={"stop-rc fa-stack fa-3x "} >
                            <SessionIcon id="stopFileShare" circleClass="fas fa-circle fa-stack-2x"
                                iconClass="fas fa-times fa-stack-1x" tooltipPlament="top"
                                innerClass="tip-max no-pointer-events" tooltip="session.stopFileShare" tooltipStyle="Trans"
                                onClickMethod = {() => this.onClickCancelShareAsset()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>
                            {/* <a href="#!" id="stopFileShare" onClick={() => this.onClickCancelShareAsset()}>
                                <i className="fas fa-circle fa-stack-2x"></i>
                                <i className="fas fa-times fa-stack-1x" ></i>
                                <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="stopFileShare">                                            
                                    <Trans>session.stopFileShare</Trans>                                            
                                </UncontrolledTooltip>
                            </a> */} 
                        </span>
                        :
                        ''
                    }                    
                    <WebrtcErrorMessage showWebrtcErrorMessage={this.state.showCameraErrorMessage} text={this.state.cameraErrorType} onClickClose={() => this.triggerStopSession()}/>
                    {/* side bar */}
                    <div className='flex-shrink-1' id="sideBar">
                        <WebrtcSideBar
                            {...this.props}
                            participants={this.state.g_subInfoArr}
                            participantNames={this.state.sessionJoinees}
                            isChanged={this.state.isParticipantChanged}
                            technicianCount={(g_subInfoArr) ? g_subInfoArr.length - g_expertConnsArr.length : 0} 
                            userVideoCount={this.state.userVideoCount}
                            toggleUserVideo={this.toggleUserVideo}
                            toggleUserSpeaker={this.toggleUserSpeaker}
                            toggleUserMike={this.toggleUserMike}
                            muteHeaderMikeForAll={this.onClickMuteMikeForAll}
                            muteHeaderSpeakerOfAll={this.onClickMuteSpeakerOfAll}
                            disabledMutedAll={this.state.disabledMutedAll} // T32-413
                            openFileUpload={this.onClickUploadFileForAll}
                            onClose3dCanvas={this.onCloseFileViewer}
                            openExpertScreenShare={this.onClickExpertScreenShareForAll}
                            openExpertChatWindow={this.onClickChatForAll}
                            disableGridView={this.state.hideGridViewIcon}
                            showMaxDivControls={this.state.showHideMaxDivControl}
                            onClickMultiGridView={this.gridView}
                            isOngoingMaxDivFeatures={this.state.isOngoingMaxDivFeatures}
                            bAnnotate={this.state.bAnnotate}//MB2-471
                            bRemoteCapture={this.state.bRemoteCapture}//MB2-471
                            triggerStopSession={this.state.triggerStopSession}
                            isOnprem={false}
                            canTurnOffUserVideo={this.props.sessionInfo.can_turn_off_user_video}
                            inviteOnlineUser={this.props.sessionInfo.invite_online_user}
                            onlineUsers={this.state.onlineList}
                            callAndInviteUser={this.callAndInviteUser}
                            triggerCloseSlider={this.state.triggerCloseSlider}
                            isHeaderFileShare={this.state.bShareAsset}
                            addGuestUserList={this.addGuestUserList} //TP-2349
                            removeGuestUserList={this.removeGuestUserList} //TP-2349
                            guestUser={this.state.guestUser} //TP-2349
                            isSmallFormFactor={this.state.hideButton === "d-none" ? true : false}
                            isAccountLevelExpertVideoDisabled={true}//TP-3865
                            maxView={!this.state.gridViewMode} //TP-3865
                        />
                    </div>
                    <div className='w-100 p-2 row min-vh-100 justify-content-center align-items-center' id='flex-container' style={{ backgroundColor: '#1c2238' }}>
                        {(!(/* this.props.isiOS && */ this.props.isSafari) && !this.props.isFirefox && this.props.screen_recording_enabled) ?
                            <>
                                <span style={/**TP-1870**/(window.innerWidth > 1299) ? {bottom: "2%", right: "0.5%"} : {bottom: "2%", right: "0.5%"}} className={!(this.props.isiOS && this.props.isSafari)? "fa-stack position-absolute rec-button "+buttonSize+" "+hideButton : "fa-stack position-absolute rec-button "+buttonSize}>                                        
                                    <SessionIcon id="recordScreen" circleClass={(this.state.showHideMaxDivControl) ? this.state.startRecording ? "fas fa-circle fa-stack-2x Blink": "fas fa-circle fa-stack-2x" : "fas fa-circle font-awesome-grey fa-stack-2x"}
                                        iconClass={'fas ' + maxDivRecordScreenIcon + ' fa-stack-1x'} iconStyle={{ color: maxDivRecordScreenColor }} tooltipPlament="left"
                                        innerClass="tip-max no-pointer-events" tooltip={this.state.startRecording ? "session.stopRecordScreen" : "session.recordScreen"} tooltipStyle="Trans"
                                        onClickMethod = {(this.state.showHideMaxDivControl) ? () => this.onClickRecordScreen(): () => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                        
                                </span>
                                <span id="record-beacon" style={/**TP-1870**/(window.innerWidth > 1559) ? {bottom: "8%", right: "1.25%", fontSize: '1.5em'} : {bottom: "9%", right: "1.25%", fontSize: '1em'}} className={`position-absolute nameDiv rec Blink ${this.state.showRecordingBeacon ? '' : 'd-none'}`}>
                                    REC{/* <span style={{ fontSize: `1em`, color: `red` }}><i className="fas fa-circle Blink"></i></span> */}
                                </span>
                            </>
                            :
                            ''
                        }                       
                        <div className="position-absolute w-100 text-center nameDiv" style={{ color: 'white', top: uploadSpeedTopMargin}}>
                            {this.props.downLoadSpeed ? <>{this.props.downLoadSpeed+' MBPS'}&darr;:</> : ''}
                            {this.props.uploadSpeed ? <>{this.props.uploadSpeed+' MBPS'}&uarr;</> : ''}
                        </div>
                        <div className="position-absolute nameDiv" style={this.state.gridViewMode === true ? {left: '51%', top: '7%'} : { left: '50%', bottom: '2%'}}>
                            <SessionTimer 
                                credit_limits={this.props.sessionInfo.credit_limits}
                                startTimer={this.props.startTimer}                                
                            />
                        </div>
                        { this.state.showFileViewer ?
                            <div className="position-absolute w-100 file-name" style={{ color: 'white', zIndex: '1060', background: '#D3D3D300', top: fileNameTopMargin}}>
                                {this.state.fileName}
                            </div>
                            :
                            ''
                        }
                        { this.state.showFileViewer ?
                            <div className="position-absolute" style={{ zIndex: '1060'}}>
                                <span className={(window.innerWidth > 1299) ? "fa-stack fa-lg close-modal ": "fa-stack fa-sm close-modal "}
                                    style={{ right: sliderRight, left: closeLeft, top: fileNameTopMargin }}>
                                        <SessionIcon id="closeAsset" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="fas fa-times fa-stack-1x close-file-icon" tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="Close Asset" tooltipStyle="Trans"
                                         onClickMethod = {(e) => this.onCloseFileViewer(e)} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        {/* <a href="#!" id="closeAsset" onClick={(e) => this.onCloseFileViewer(e)}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-times fa-stack-1x close-file-icon" ></i>                    
                                            <UncontrolledTooltip innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="closeAsset">                                            
                                                <Trans id="Close Asset" ></Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}
                                </span>
                            </div>
                            :
                            ''
                        }
                        { (this.state.showHideMaxDivControl === true && this.state.showFileViewer && !this.state.showAudioFileViewer) ?
                            <div id="slider-parent" style={(this.state.showHideMaxDivControl === true && this.state.showFileViewer && !this.state.showAudioFileViewer) ? { display: 'block'} : { display: 'none' }}>                                    
                                <RangeSlider startPosition={(this.state.fileOpacity !== "0.60") ? (parseFloat(this.state.fileOpacity) * 100) : 60} 
                                    minimum={0} maximum={100} setCurrentValue={this.setAssetOpacity} 
                                    sliderTop= {(this.state.gridViewMode) ? "25%" : "20%"}
                                    sliderLeft={(this.state.gridViewMode)? "94%": ""} 
                                    sliderRight={(!this.state.gridViewMode)? sliderRight : ""} 
                                    sliderHeight={sliderHeight}
                                />
                            </div>
                            :
                            ''
                        }
                        {/* show the recording beacon NS2-360 */}                       

                        <div id="actionRequestControls" style={handIconStyle} className={actionReqControlStyle+" "+widthClassName}>
                            <div id="actioncontrols">
                                { /*TP-2823*/this.state.bHideAnnotateColour === false && this.state.bAnnotate ?
                                    <span className={"fa-stack  "+buttonSize+" "+hideButton+" "+gridTileBtnCntStyle} >
                                        <SessionIcon id="colourpicker" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                            iconClass="fas fa-palette fa-stack-1x" iconStyle={penStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.colourpicker" tooltipStyle="Trans" 
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        {/* <a href="#!" id="colourpicker">
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-palette fa-stack-1x" style={penStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="colourpicker">                                            
                                                <Trans>session.colourpicker</Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}
                                                
                                    </span>
                                    :
                                    ''
                                }
                                { /*TP-2823*/this.state.bHideAnnotateShapes === false && this.state.bAnnotate ?
                                    <span className={"fa-stack maxdivcontrol-spacing "+buttonSize+" "+hideButton+" "+gridTileBtnCntStyle}> 
                                        
                                        <I18n>
                                            {/*TP-2474*/({ i18n }) => 
                                                <SessionIcon id="shapepicker" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                                    iconClass={annotateShapesArray[6].class + " fa-stack-1x"} iconStyle={shapeStyle} tooltipPlament="top"
                                                    innerClass="tip-max no-pointer-events" tooltip={processTooltip(annotateShapesArray[6].name, i18n)} /**TP-2491 */
                                                    tooltipStyle="i18n" onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} /> 
                                            }
                                        </I18n>
                                    </span>
                                    :
                                    ''
                                }
                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>                                    
                                    <SessionIcon id="telestrate" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                        iconClass="fas fa-edit fa-stack-1x" iconStyle={annotateStyle} tooltipPlament="top"
                                        innerClass="tip-max no-pointer-events" tooltip="session.telestrate" tooltipStyle="Trans" 
                                        onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                    {/* <a href="#!" id="telestrate" >
                                        <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                        <i className="fas fa-edit fa-stack-1x" style={annotateStyle}></i>
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="telestrate">                                            
                                            <Trans>session.telestrate</Trans>                                            
                                        </UncontrolledTooltip>
                                    </a> */}
                                </span>
                                { enable_remote_capture === undefined || enable_remote_capture === true ?
                                    <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle} >                                        
                                        <SessionIcon id="remoteCapture" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                            iconClass="fas fa-camera fa-stack-1x" iconStyle={cameraStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.remoteCapture" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>
                                        {/* <a href="#!" id="remoteCapture" >
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-camera fa-stack-1x" style={cameraStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="remoteCapture">                                            
                                                <Trans>session.remoteCapture</Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}    
                                    </span>
                                    :
                                    ''
                                }
                                    
                                {( maxDivSub.torch_enabled && torch_enabled) ?
                                    <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>                                        
                                        <SessionIcon id="flashLight" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                            iconClass="fas fa-bolt fa-stack-1x" iconStyle={torchStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.flashLight" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>
                                        {/* <a href="#!" id="flashLight">
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-bolt fa-stack-1x" style={torchStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="flashLight">                                            
                                                <Trans>session.flashLight</Trans>                                            
                                            </UncontrolledTooltip>                                            
                                        </a> */}                                            
                                    </span>
                                    :
                                    ''
                                }
                                {(maxDivSub.zoom_enabled && zoom_enabled) ?
                                    <span className={"fa-stack  maxdivcontrol-spacing rel "+buttonSize+" "+gridTileBtnCntStyle}>
                                        <SessionIcon id="zoom" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                            iconClass="fas fa-binoculars fa-stack-1x" iconStyle={zoomStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.zoom" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>
                                        {/* <a href="#!" id="zoom">
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-binoculars fa-stack-1x" style={zoomStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="zoom">                                            
                                                <Trans>session.zoom</Trans>                                            
                                            </UncontrolledTooltip>                                                 
                                        </a> */}
                                    </span>
                                    :
                                    ''
                                }
                                { maxDivSub.screenshare_enabled && (screen_share_mode === "screen_share_enabled" || screen_share_mode === "expert_screen_share_enabled") ? 
                                    <span className={!(this.props.isiOS && this.props.isSafari)? "fa-stack  maxdivcontrol-spacing "+buttonSize+" "+hideButton : "fa-stack  maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>
                                        <SessionIcon id="expertSS" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                            iconClass="fab fa-chromecast fa-stack-1x" iconStyle={passiveExpertShareStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.expertSS" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>
                                        {/* <a href="#!" id="expertSS" >
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fab fa-chromecast fa-stack-1x" style={passiveExpertShareStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="expertSS">                                            
                                                <Trans>session.expertSS</Trans>                                            
                                            </UncontrolledTooltip> 
                                        </a> */}     
                                    </span>
                                    :
                                    ''
                                }
                                {maxDivSub.techSS_enabled && (screen_share_mode === "screen_share_enabled" || screen_share_mode === "tech_screen_share_enabled") ?
                                    <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+hideButton+" "+gridTileBtnCntStyle}>
                                        <SessionIcon id="techSS" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                            iconClass="fas fa-tablet-alt fa-stack-1x" iconStyle={techShareStyle} tooltipPlament="top" /**TP-2318 */
                                            innerClass="tip-max no-pointer-events" tooltip="session.techSS" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>
                                        {/* <a href="#!" id="techSS">
                                            <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                            <i className="fas fa-tablet-alt fa-stack-1x" style={techShareStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="techSS">                                            
                                                <Trans>session.techSS</Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */} 
                                    </span>
                                    :
                                    ''
                                }                            
                                <span className={"fa-stack "+handSize}>
                                    { this.state.disableHand === false ?         
                                        <SessionIcon id="getExpertControl" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="far fa-hand-paper fa-stack-1x" iconStyle={getExpertControlStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.getExpertControl" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickHandIcon()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>     
                                    :  
                                        <I18n>
                                            {({ i18n }) =>
                                                <SessionIcon id="getExpertControl" circleClass="fas fa-circle fa-stack-2x"
                                                    iconClass="far fa-hand-paper font-awesome-grey fa-stack-1x" tooltipPlament="top"
                                                    innerClass="tip-max no-pointer-events" tooltip={processTooltip(this.state.handIconHover, i18n)}    
                                                    tooltipStyle="i18n" onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false}/>                                              
                                            }
                                        </I18n>                                      
                                    }
                                </span>

                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>                                                                           
                                    <SessionIcon id="shareAsset" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                        iconClass="fas fa-upload fa-stack-1x" iconStyle={shareStyle} tooltipPlament="top"
                                        innerClass="tip-max no-pointer-events" tooltip="session.shareAsset" tooltipStyle="Trans"
                                        onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                    {/* <a href="#!" id="shareAsset" >
                                                <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                                <i className="fas fa-upload fa-stack-1x" style={shareStyle}></i>
                                                <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="shareAsset">                                            
                                                    <Trans>session.shareAsset</Trans>                                            
                                                </UncontrolledTooltip>
                                    </a>  */}                                           
                                </span>
                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+hideButton+" "+gridTileBtnCntStyle}>
                                    <SessionIcon id="chat" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                        iconClass="far fa-comments fa-stack-1x" iconStyle={chatStyle} tooltipPlament="top"
                                        innerClass="tip-max no-pointer-events" tooltip="session.chat" tooltipStyle="Trans"
                                        onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                           
                                            {/* <a href="#!" id="chat">
                                                <i className="fas fa-circle font-awesome-grey fa-stack-2x"></i>
                                                <i className="far fa-comments fa-stack-1x" style={chatStyle}></i>
                                                <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chat">                                            
                                                    <Trans>session.chat</Trans>                                            
                                                </UncontrolledTooltip>
                                            </a>  */}                                           
                                        
                                </span>
                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>
                                    { /**T32-413 */ this.state.bRemoteMuteDisabled === false ?
                                        <SessionIcon id="toggleAudio" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="fas fa-volume-up fa-stack-1x" iconStyle={muteStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.toggleAudio" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickMaxMute()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        :
                                        <SessionIcon id="toggleAudio" circleClass="fas font-awesome-grey fa-circle fa-stack-2x"
                                            iconClass="fas fa-volume-mute fa-stack-1x" iconStyle={{ color: 'red' }} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.toggleAudio" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} /> 
                                    }
                                </span>
                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+gridTileBtnCntStyle}>
                                    <SessionIcon id="muteMikeEach" circleClass="fas fa-circle fa-stack-2x" tooltipPlament="top"
                                        iconClass="fas fa-microphone fa-stack-1x" iconStyle={{ color: maxDivMuteMicColor }}
                                        innerClass="tip-max no-pointer-events" tooltip="session.muteMike" tooltipStyle="Trans"
                                        onClickMethod = {() => this.onClickMaxMuteMicrophone()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />

                                        {/* <a href="#!" id="muteMikeEach" onClick={() => this.onClickMaxMuteMicrophone()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-microphone fa-stack-1x" style={{ color: maxDivMuteMicColor }}></i>
                                        </a>
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteMikeEach">                                            
                                            <Trans>session.muteMike</Trans>                                            
                                        </UncontrolledTooltip> */}
                                </span>
                            </div>
                            {/* <div id="recording_inactive">
                                {(!(/* this.props.isiOS &&  this.props.isSafari) && !this.props.isFirefox && this.props.screen_recording_enabled && !this.state.showHideMaxDivControl)  ?
                                    <span style={(window.innerWidth > 1299) ? (this.state.gridViewMode === false) ? {top: "8%", right: "20%"} : {top: "8%", right: "10%"} : {top: "14%", right: "15%"}} className={!(this.props.isiOS && this.props.isSafari)? "fa-stack position-absolute rec-button "+buttonSize+" "+hideButton : "fa-stack position-absolute rec-button "+buttonSize}>                                        
                                        <SessionIcon id="recordScreen" circleClass={"fas fa-circle font-awesome-grey fa-stack-2x"}
                                            iconClass={'fas ' + maxDivRecordScreenIcon + ' fa-stack-1x'} iconStyle={{ color: maxDivRecordScreenColor }} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip={this.state.startRecording ? "session.stopRecordScreen" : "session.recordScreen"} tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                    </span>
                                    :
                                    ''
                                }
                            </div> */}
                        </div>
                        {/*TP-2927 & TP-2810 & TP-2491*/ 
                        <div id='shapeselector' className={maxdivBtnControlStyle+" shapeselector shapeselector-spacing"} style={this.state.bHideAnnotateShapes === false && this.state.bAnnotate ? { display: 'block', left: '0.5%' } : { display: 'none', left: '0.5%'}}>
                            {/**TP-2508 */}
                            <div className='shape-selector shape-selector-left'>
                                { /**TP-2474 */ annotateShapesArray[5].class ?
                                    <span className={"fa-stack  shapeselector-spacing "+buttonSize}>
                                        <SessionIcon id="erasor" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass={annotateShapesArray[5].class + " fa-stack-1x rotate90"} iconStyle={erasorStyle} tooltipPlament="right"
                                            innerClass="tip-max no-pointer-events" tooltip="session.erasor" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickErasorIcon()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                    </span>
                                    :
                                    ''        
                                }
                                {/*TP-2491*/ !this.state.bHideAnnotationPointer && annotateShapesArray[4].class ?
                                    <span className={"fa-stack  shapeselector-spacing "+buttonSize}>  
                                        <SessionIcon id="pointer" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass={annotateShapesArray[4].class + " fa-stack-1x rotate90"} iconStyle={pointerStyle} tooltipPlament="right"
                                            innerClass="tip-max no-pointer-events" tooltip="session.pointer" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickPointerIcon()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />  
                                    </span>
                                    :
                                    ''
                                }
                                {annotateShapesArray[0].class ?
                                    <span className={"fa-stack shapeselector-spacing "+buttonSize}> 
                                        <SessionIcon id="freehand" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass={annotateShapesArray[0].class + " fa-stack-1x rotate90"} iconStyle={crossStyle} tooltipPlament="right"
                                            innerClass="tip-max no-pointer-events" tooltip="session.freehand" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickCrossHairsIcon()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                                                                      
                                        {/* <a href="#!" id="freehand" onClick={() => this.onClickCrossHairsIcon()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className={annotateShapesArray[0].class + " fa-stack-1x"} style={crossStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="freehand">                                            
                                                <Trans>session.freehand</Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}                                               
                                    </span>
                                    :
                                    ''
                                }
                                {/*TP-2491*/ annotateShapesArray[3].class ?
                                    <span className={"fa-stack  shapeselector-spacing "+buttonSize}>  
                                        <SessionIcon id="arrow" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass={annotateShapesArray[3].class + " fa-stack-1x rotate90"} iconStyle={arrowStyle} tooltipPlament="right"
                                            innerClass="tip-max no-pointer-events" tooltip="session.arrow" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickArrowIcon()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                          
                                        {/* <a href="#!" id="arrow" onClick={() => this.onClickArrowIcon()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className={annotateShapesArray[3].class + " fa-stack-1x"} style={arrowStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="arrow">                                            
                                                <Trans>session.arrow</Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}                                                
                                    </span>
                                    :
                                    ''
                                }
                                {/*TP-2491*/ annotateShapesArray[1].class ?
                                    <span className={"fa-stack  shapeselector-spacing "+buttonSize}> 
                                        <SessionIcon id="circle" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass={annotateShapesArray[1].class + " fa-stack-1x rotate90"} iconStyle={circleStyle} tooltipPlament="right"
                                            innerClass="tip-max no-pointer-events" tooltip="session.circle" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickCircleIcon()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                           
                                        {/* <a href="#!" id="circle" onClick={() => this.onClickCircleIcon()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className={annotateShapesArray[1].class + " fa-stack-1x"} style={circleStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="circle">                                            
                                                <Trans>session.circle</Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}                                                
                                    </span>
                                    :
                                    ''
                                }
                                { /*TP-2491*/ annotateShapesArray[2].class ?
                                    <span className={"fa-stack  shapeselector-spacing "+buttonSize}>   
                                        <SessionIcon id="square" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass={annotateShapesArray[2].class + " fa-stack-1x rotate90"} iconStyle={rectStyle} tooltipPlament="right"
                                            innerClass="tip-max no-pointer-events" tooltip="session.square" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickRectIcon()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                          
                                        {/* <a href="#!" id="square" onClick={() => this.onClickRectIcon()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className={annotateShapesArray[2].class + " fa-stack-1x"} style={rectStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="square">                                            
                                                <Trans>session.square</Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}                                                
                                    </span>
                                    :
                                    ''
                                }
                            </div>
                        </div>
                        }
                        <div id="controlpanel" className={maxdivBtnControlStyle}>
                            {/* NS2-217 */ this.state.bHideAnnotateColour === false && !this.state.bShareAsset ?
                                <div id='sketchPicker' style={{ display: 'none' }}>
                                    <SketchPicker color={this.state.pencolor} onChangeComplete={this.onChangeColor}/>
                                </div>
                                :
                                null
                            }
                            {this.state.bHideZoomButton === false ?
                                <div id='zoomlevelselector' className={/*TP-2933*/hideButton === "d-none" && window.innerHeight <= 420 ? "zoomSelector "+hideButton : "zoomSelector d-blk"} style={{ display: 'none', right: '40%' }} >
                                    {
                                        zoomLevelArray.map(g => {
                                            return (
                                                <span key={g.id + '-level'} className={"fa-stack  maxdivcontrol-spacing "+buttonSize}>
                                                    <I18n>
                                                        {({ i18n }) =>
                                                            <a href="javascript:void(0);" onClick={(!this.state.bShareAsset) ? () => this.onClickZoomLevel(g.id) : () => {}}>
                                                                <i className={(this.state.bShareAsset) ? "fas fa-circle font-awesome-grey fa-stack-2x" : "fas fa-circle fa-stack-2x"}></i>
                                                                <span className="fa-stack-1x fa-zoom-text" value={g.id} 
                                                                    style={ (this.state.zoom_level === g.id) ? { fontWeight: '600', color: 'red' } 
                                                                            : { fontWeight: '600', color: 'black' }}>
                                                                    {processZoomLevels(g.name, i18n)}
                                                                </span>
                                                            </a>
                                                        }
                                                    </I18n>
                                                </span>
                                            )
                                        })
                                    }
                                    {/* <div className="arrow-down"></div> */}
                                </div>
                                :
                                null
                            }
                            <div id='maxdivcontrols'>
                                {this.state.bHideAnnotateColour === false && this.state.bAnnotate ? 
                                    <span className={"fa-stack  "+buttonSize+" "+hideButton}>
                                        { this.state.bShareAsset ? /* TP-1178 */
                                            <SessionIcon id="colourpicker" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                                iconClass="fas fa-palette fa-stack-1x" tooltipPlament="top" iconStyle={penStyle}
                                                innerClass="tip-max no-pointer-events" tooltip="session.colourpicker" tooltipStyle="Trans"
                                                onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                            :
                                            <SessionIcon id="colourpicker" circleClass="fas fa-circle fa-stack-2x"
                                                iconClass="fas fa-palette fa-stack-1x" iconStyle={penStyle} tooltipPlament="top"
                                                innerClass="tip-max no-pointer-events" tooltip="session.colourpicker" tooltipStyle="Trans"
                                                onClickMethod = {() => this.onClickPenIcon()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                            
                                        }
                                    </span>
                                    :
                                    ''
                                }
                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize}>
                                    { this.state.bShareAsset ?
                                        <SessionIcon id="telestrate" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                            iconClass="fas fa-edit fa-stack-1x" tooltipPlament="top" iconStyle={annotateStyle}
                                            innerClass="tip-max no-pointer-events" tooltip="session.telestrate" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        
                                        :
                                        <SessionIcon id="telestrate" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="fas fa-edit fa-stack-1x" iconStyle={annotateStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.telestrate" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickAnnotate()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                    }                                    
                                </span>
                                {(enable_remote_capture && enable_remote_capture === true && this.state.expertSSAll !== true && this.state.expertShareScreenMaxDiv !== true && this.state.techShareScreen !== true) ?
                                    <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize}>  
                                        { this.state.bShareAsset ?
                                            <SessionIcon id="remoteCapture" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                                iconClass="fas fa-camera fa-stack-1x" tooltipPlament="top" iconStyle={cameraStyle}
                                                innerClass="tip-max no-pointer-events" tooltip="session.remoteCapture" tooltipStyle="Trans"
                                                onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                            :
                                            <SessionIcon id="remoteCapture" circleClass="fas fa-circle fa-stack-2x"
                                                iconClass="fas fa-camera fa-stack-1x" iconStyle={cameraStyle} tooltipPlament="top"
                                                innerClass="tip-max no-pointer-events" tooltip="session.remoteCapture" tooltipStyle="Trans"
                                                onClickMethod = {() => this.onClickCamera()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                                                                        
                                        }   
                                    </span>
                                    :
                                    ''
                                }
                                {(this.state.bHideTorchButton === false && this.state.expertSSAll !== true && this.state.expertShareScreenMaxDiv !== true && this.state.techShareScreen !== true) ?
                                    <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize}>                                        
                                        { this.state.bRemoteCapture || this.state.bShareAsset ?
                                            <SessionIcon id="flashLight" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                                iconClass="fas fa-bolt fa-stack-1x" tooltipPlament="top" iconStyle={torchStyle}
                                                innerClass="tip-max no-pointer-events" tooltip="session.flashLight" tooltipStyle="Trans"
                                                onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                            
                                            :
                                            <SessionIcon id="flashLight" circleClass="fas fa-circle fa-stack-2x"
                                                iconClass="fas fa-bolt fa-stack-1x" iconStyle={torchStyle} tooltipPlament="top"
                                                innerClass="tip-max no-pointer-events" tooltip="session.flashLight" tooltipStyle="Trans"
                                                onClickMethod = {() => this.onClickTorchIcon()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                                                                        
                                        }
                                    </span>
                                    :
                                    ''
                                }
                                {(this.state.bHideZoomButton === false && this.state.expertSSAll !== true && this.state.expertShareScreenMaxDiv !== true && this.state.techShareScreen !== true) ?
                                    <span className={"fa-stack  maxdivcontrol-spacing rel "+buttonSize}>
                                        {this.state.bRemoteCapture || this.state.bShareAsset ?
                                            <SessionIcon id="zoom" circleClass="fas fa-circle font-awesome-grey fa-stack-2x"
                                                iconClass="fas fa-binoculars fa-stack-1x" tooltipPlament="top" iconStyle={zoomStyle}
                                                innerClass="tip-max no-pointer-events" tooltip="session.zoom" tooltipStyle="Trans"
                                                onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                                                                            
                                            :
                                            <SessionIcon id="zoom" circleClass="fas fa-circle fa-stack-2x"
                                                iconClass="fas fa-binoculars fa-stack-1x" iconStyle={zoomStyle} tooltipPlament="top"
                                                innerClass="tip-max no-pointer-events" tooltip="session.zoom" tooltipStyle="Trans"
                                                onClickMethod = {/*TP-2933*/hideButton === 'd-none' && window.innerHeight <= 420 ? () => this.rotateZoomLevel(g_maxDivId) : () => this.onClickZoomIcon()} 
                                                isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                                
                                        }
                                    </span>
                                    :
                                    ''
                                }
                                {!(this.props.isiOS && this.props.isSafari) && this.state.bHideExpertSSButton === false && (
                                    <span className={!(this.props.isiOS && this.props.isSafari)? "fa-stack  maxdivcontrol-spacing "+buttonSize+" "+hideButton : "fa-stack  maxdivcontrol-spacing "+buttonSize}>
                                        {this.state.bRemoteCapture || (this.state.bShareAsset && !this.state.expertShareScreenMaxDiv && !this.state.expertSSAll) /**TP-1426 */ /*TP-1255*/?                                            
                                            <SessionIcon id="expertSS" circleClass="fas fa-circle fa-stack-2x"
                                                iconClass="fab fa-chromecast font-awesome-grey fa-stack-1x" tooltipPlament="top"
                                                innerClass="tip-max no-pointer-events" tooltip="session.expertSS" tooltipStyle="Trans"
                                                onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />   
                                            :     
                                            <SessionIcon id="expertSS" circleClass="fas fa-circle fa-stack-2x"
                                                iconClass="fab fa-chromecast fa-stack-1x" iconStyle={expertShareStyle} tooltipPlament="top"
                                                innerClass="tip-max no-pointer-events" tooltip="session.expertSS" tooltipStyle="Trans"
                                                onClickMethod = {() => this.expertScreenShareClick()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        }
                                    </span>)}
                                {this.state.bHideTechnicianSSButton === false && (
                                    <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+hideButton}>
                                        {this.state.bRemoteCapture ?   
                                            <SessionIcon id="techSS" circleClass="fas fa-circle fa-stack-2x"
                                                iconClass="fas fa-tablet-alt font-awesome-grey fa-stack-1x" tooltipPlament="top"
                                                innerClass="tip-max no-pointer-events" tooltip="session.techSS" tooltipStyle="Trans"
                                                onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                         
                                                                                            
                                            :                                            
                                            <SessionIcon id="techSS" circleClass="fas fa-circle fa-stack-2x"
                                                iconClass="fas fa-tablet-alt fa-stack-1x" iconStyle={techShareStyle} tooltipPlament="top"
                                                innerClass="tip-max no-pointer-events" tooltip="session.techSS" tooltipStyle="Trans"
                                                onClickMethod = {() => this.onTechScreenShareClick()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                      
                                        }
                                    </span>)}
                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize}>
                                    {this.state.bRemoteCapture /* || this.state.expertShareScreenMaxDiv || this.state.expertSSAll TP-1426 */ /*TP-1255*/?                                        
                                        <SessionIcon id="shareAsset" circleClass="fas fa-circle fa-stack-2x" anchorName="disabled"
                                            iconClass="fas fa-upload font-awesome-grey fa-stack-1x" tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.shareAsset" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                                 
                                        :  
                                        <SessionIcon id="shareAsset" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="fas fa-upload fa-stack-1x" iconStyle={shareStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.shareAsset" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickUpload()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />   
                                    }
                                </span>
                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize+" "+hideButton}>
                                    {this.state.bRemoteCapture || this.state.bAnnotate ?                                        
                                        <SessionIcon id="chat" circleClass="fas fa-circle fa-stack-2x" anchorName="disabled"
                                            iconClass="fas fa-comments font-awesome-grey fa-stack-1x" tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.chat" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                         
                                        :                                        
                                        <SessionIcon id="chat" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="far fa-comments fa-stack-1x" iconStyle={chatStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.chat" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickMaxChat()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                            
                                    }
                                </span>
                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize}>
                                    { /**T32-413 */ this.state.bRemoteMuteDisabled === false ?
                                        <SessionIcon id="toggleAudio" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="fas fa-volume-up fa-stack-1x" iconStyle={muteStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.toggleAudio" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickMaxMute()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        :
                                        <SessionIcon id="toggleAudio" circleClass="fas font-awesome-grey fa-circle fa-stack-2x"
                                            iconClass="fas fa-volume-mute fa-stack-1x" iconStyle={{ color: 'red' }} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.toggleAudio" tooltipStyle="Trans"
                                            onClickMethod = {() => {return null}} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                            
                                    }
                                </span>
                                <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize}>
                                    <SessionIcon id="muteMikeEach" circleClass="fas fa-circle fa-stack-2x"
                                        iconClass="fas fa-microphone fa-stack-1x" iconStyle={{ color: maxDivMuteMicColor }} tooltipPlament="top"
                                        innerClass="tip-max no-pointer-events" tooltip="session.muteMike" tooltipStyle="Trans"
                                        onClickMethod = {() => this.onClickMaxMuteMicrophone()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                    {/* <a href="#!" id="muteMikeEach" onClick={() => this.onClickMaxMuteMicrophone()}>
                                        <i className="fas fa-circle fa-stack-2x"></i>
                                        <i className="fas fa-microphone fa-stack-1x" style={{ color: maxDivMuteMicColor }}></i>
                                    </a>
                                    <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="muteMikeEach">                                            
                                        <Trans>session.muteMike</Trans>                                            
                                    </UncontrolledTooltip> */}
                                </span>  
                                { (!this.props.isSafari && this.state.bRemoteCapture && this.props.screen_recording_enabled) ? 
                                    <span className={"fa-stack  maxdivcontrol-spacing "+buttonSize}>
                                        <SessionIcon id="captureScreen" circleClass="fas fa-circle fa-stack-2x" anchorName={captureScreenClass} 
                                            iconClass="far fa-save fa-stack-1x" tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.captureScreen" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickScreenShot()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        {/* <a href="#!" id="captureScreen" className={captureScreenClass} onClick={() => this.onClickScreenShot()}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="far fa-save fa-stack-1x" ></i>
                                        </a>
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="captureScreen">                                            
                                            <Trans>session.captureScreen</Trans>                                            
                                        </UncontrolledTooltip> */}
                                    </span>                              
                                    :
                                    ''
                                }
                            </div>
                            {/*(!(/* this.props.isiOS && this.props.isSafari) && !this.props.isFirefox && this.props.screen_recording_enabled && this.state.showHideMaxDivControl) ?
                                <div id='recording_button' className=''>
                                    <span style={(window.innerWidth > 1299) ? {top: "8%"} : {top: "14%"}} className={!(this.props.isiOS && this.props.isSafari)? "fa-stack position-absolute rec-button "+buttonSize+" "+hideButton : "fa-stack position-absolute rec-button "+buttonSize}>                                        
                                        <SessionIcon id="recordScreen" circleClass={ "fas fa-circle fa-stack-2x"}
                                            iconClass={'fas ' + maxDivRecordScreenIcon + ' fa-stack-1x'} iconStyle={{ color: maxDivRecordScreenColor }} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip={this.state.startRecording ? "session.stopRecordScreen" : "session.recordScreen"} tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickRecordScreen()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        
                                    </span>
                                </div>
                                :
                                ''
                            */}
                        </div>
                        <SessionChat
                            onSendChatMsg={this.sendChatMsg}
                            bPrivate={this.state.bMaxChat}
                            bShowChat={this.state.bMaxChat || this.state.bAllChat}
                            chatMsgInfoArr={this.state.chatMsgInfoArr}
                            elemId={g_maxDivId}
                            startTimer={this.state.startChatTimer}
                            updateChatFlags={this.updateChatFlags}
                        />    
                        {(this.state.showHideMaxDivControl) ?
                           /**TP-2853*/ (this.state.annotateLoader) ? this.showLoader('Annotation Canvas is starting up')
                            :
                            (this.state.bRemoteCapture) ? this.showLoader('Downloading image') : (this.state.bShareAsset) ? this.showLoader('Uploading....') : this.showLoader('Swap to default camera') 
                            :
                            (this.state.swapTechCamera) ? this.showLoader('Swap to default camera') : (this.state.bRemoteCapture) ? this.showLoader('Downloading image') : this.showLoader('Uploading....') 
                        }
                        { /*(this.state.bRemoteCapture) ? this.showLoader('Downloading image') : (this.state.bShareAsset) ? this.showLoader('Uploading....') : this.showLoader('Swap to default camera') */}            
                        <Modal id="file-viewer" size="lg" isOpen={this.state.showFileViewer} style={fileStyle}>
                            {/* <ModalHeader toggle={this.onCloseFileViewer} close={closeBtn}></ModalHeader> */}
                            <ModalBody className="fileBody" style={bodyStyle}>
                                {/*<h1>PARENT VALUE: {this.state.fileOpacity}</h1>*/}                                
                                {/* <div className="row middle-row w-100" style={(window.innerWidth > 1559) ? {height: '12%'} : {height: '10%'}}></div> */}
                                { this.state.fileType.toLowerCase() === 'pdf' ?                                    
                                    (this.props.isSafari || this.props.isAndroid) ? 
                                        <div className="row" style={(window.innerWidth > 1559) ? {height: '100%', width: '100%'} : {height: '100%', width: ''}}>
                                            <FileViewer
                                                ref = { (f) => this.fileViewer = f } 
                                                fileType={this.state.fileType}
                                                filePath={this.state.filePath}
                                            /> 
                                        </div>                                   
                                        :
                                        <div className="row" style={{height: '100%'}}>
                                            <embed className="pg-viewer-wrapper" type="application/pdf" width={filewidth} height={embedHeight} src={this.state.filePath+"#toolbar=1&navpanes=1&scrollbar=1"} />
                                        </div>
                                    :
                                    (/*TP-1539*/this.state.fileType.toLowerCase() !== "none") ? 
                                        <div className="row" style={(window.innerWidth > 1559) ? {height: '100%', width: '100%'} : {height: '100%', width: ''}}>
                                            <FileViewer
                                                ref = { (f) => this.fileViewer = f } 
                                                fileType={this.state.fileType}
                                                filePath={this.state.filePath}
                                            />
                                        </div>
                                        :
                                        ''
                                }
                            </ModalBody>
                        </Modal>
                        { this.muteMikeForTechDialog() }
                        { this.streamDestroyedDialog() }
                        { this.cameraSwapDialog() }
                        { this.requestMaxDivControlDialog() }
                        <FlashMessage 
                            flashMsgText={this.state.flashMsgText} 
                            flashValues={this.state.flashValues}
                            showMessage={this.state.showFlashMessage} 
                            flashLeft={this.state.flashLeft}
                            flashTop={this.state.flashTop}
                            updateShowMessage={this.updateShowMessage}
                        ></FlashMessage>
                    </div>
                </div>
            );
        //}
    }
}

export default OTExpert;