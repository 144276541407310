import React from 'react';
import './showAllJobs.css'
import { I18n} from "@lingui/react";
import { t } from '@lingui/macro';

export const ShowAllJobs = (props) => {
    return <div>
        <I18n>
        {({ i18n }) =>                                       
            <div className="pd-1">
               <label className="col-xs-3" htmlFor={props.id}>{processLanguage(props.label, i18n, props.customer)}&nbsp;</label>
                <label className="col-xs-4 col-xs-offset-2 switch">
                    <input type="checkbox" id={props.id}
                    name={props.id}
                    onChange={props.toggleShowAllJobs}
                    checked={props.value}
                    disabled={props.showTemplateCheckbox && props.showTemplateCheckbox.value && props.is_template_enabled}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
        }
        </I18n>
  </div>
}

function processLanguage(status, i18n, customer){
    if(status === "Show Finished Jobs") {
        if(customer.behaviour === "pndsafety"){
            return i18n._(t`Show Finished Work Orders`); 
        }else{
            return i18n._(t`Show Finished Jobs`); 
        }
    }
}