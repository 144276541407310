import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { Trans } from '@lingui/macro';
import './OnlineIndicator.scss';

//TP-3423 -- For showing online symbol inside bubble element
const OnlineIndicator = ({index, isSmallFormFactor, circleTop, ringTop}) => (
    <div className="ring-container d-inline">
        <div className="ringring" style={{top: ringTop}}></div>
        <div className="circle" id={"indicator"+ index || 0 } style={{top: circleTop}}></div>
        { isSmallFormFactor === false ?
            <UncontrolledTooltip placement="top" target={"indicator"+ index || 0 }>
                <Trans>Online</Trans>
            </UncontrolledTooltip>
            :
            ''
        }
    </div>
);

export default OnlineIndicator;