import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';

const _introduction = async ({name}) => {
    let introData = [];    
    introData.push(
        {
            text:[

                {text: `ASSET QR CODE OF ${name}\n\n`, style:'header', fontSize: 15, alignment: 'center', margin: [ 150, 10, 0, 0 ]},
            ],
            margin: [ 0, 10, 0, 0 ]
        }        
    )
    return introData;
}

const _summary = async ({qrCode}) => {
    let summaryData = [];
    summaryData.push(
        {
            qr: qrCode,
            fit: '200',
            alignment: 'center',
            margin: [ 5, 10, 0, 0 ]
        }
    )
    return summaryData;
}

export default async ({name, qrCode, company_name, account_id, env}) => {
    const {vfs} = vfsFonts.pdfMake;
	pdfMake.vfs = vfs;

    const introData = await _introduction({name});

    const summaryData = await _summary({qrCode});

    const year = new Date().getFullYear();

    const documentDefinition = {
        pageSize: 'A4',
        pageMargins: [ 40, 60, 40, 60 ],
        pageOrientation: 'portrait',
        header: function() {
            /* if(currentPage != 1 && currentPage != 2){ */
                return([
                    { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 800, y2: 40, lineWidth: 0.5 }] },
                    {
                        alignment: 'justify',
                        columns: [
                            { text: 'Proprietary & Confidential', style:'tableHeader', margin: [40, 5, 0, 0],fontSize: 10 },
                            { text: 'Generated By Telepresenz ®', margin: [0, 5, 40, 0], alignment: 'center',fontSize: 10},
                            { text: `${account_id}`, alignment: 'right' , margin: [0, 5, 40, 0],fontSize: 10 },
                        ]
                    },
                ])
            /* }else{
                return null;
            } */
        },
        footer: function() {
                return ([
                    { canvas: [{ type: 'line', x1: 40, y1: 10, x2: 800, y2: 10, lineWidth: 0.5 }] },
                    {
                        alignment: 'justify',
                        columns: [
                            { text: `Copyright © ${year}`, margin: [20, 5, 0, 0],fontSize: 10 },
                            { text: `${company_name} , ${env}`, alignment: 'right' , margin: [0, 5, 40, 0],fontSize: 10 },
                        ],
                    },

                ])
        },        
        content: [
            ...introData,
            ...summaryData
        ],
        styles: {
            header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
            color:'#2d3858'
        }        
        }
    };

    const timestamp = new Date().toISOString();

    pdfMake.createPdf(documentDefinition).download(`${name}_${timestamp}-QRcode.pdf`);
}