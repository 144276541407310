import React, { Fragment } from 'react';
import WebrtcErrorMessage from './WebrtcErrorMessage';
import SessionChat from './SessionChat';
import { fabric } from 'fabric';
import { uploadFile, deleteFile } from 'react-s3';
import { printConsole } from '../helpers';
import SessionSignal from './SessionSignal';
import './session.css';
import threeEntryPoint from "../Three/threeEntryPoint";
import * as THREE from 'three';
import FileViewer from 'react-file-viewer';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import map from 'lodash/map';
import { base64StringToBlob } from 'blob-util';
import SessionTimer from './SessionTimer';
import {diagonastics} from "../DiagonasticHelper";
import WebrtcSideBar from './WebrtcSideBar';
import AuthService from '../AuthService';

import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import OTDevices from './OTDevices';
import OTSessionUser from './OTSessionUser';
import FlashMessage from '../FlashMessage/FlashMessage';
import SessionIcon from './SessionIcon.jsx';

const fileTransferTimeout = 1;
const headerSize = 104;
const footerSize = 0; // if needed we will factor the footer in
const borderYsize = 2 //23;

let strCanvasJSON = null;
let dataList = [];

let g_subInfoArr = [];
let g_pubConnsArr = [];

let g_expertsCounter = 0;
let from = null;
// Array which should hold the list of Expert connection data for Technician Users
let g_expertConnsArr = [];
let g_subscriber = null;
let g_publisher = null;
let g_stream = null;
let g_session = null;
let g_camera_publisher = null;
let g_new_camera_publisher = null;
let g_screen_publisher = null;
let otTechObj = null;
let assetsArray = [];
const OT = {}; //TP-3988

const subscriberContainerDivId = 'flex-container';
/* const urltoFile = (url, filename, mimeType) => {
    return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
}; */

//return a promise that resolves with a File instance
async function urltoFile(url, filename, mimeType){
    mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
}

function insertAt(array, index, str) {
    array.splice(index, 0, str);
    return array;
}

//TP-2475 -- Checking method for when Expert's json payload is a pointer
// compared to other types of Annotations
function checkForPointerTypeJSON (){
    if (strCanvasJSON.objects !== undefined && strCanvasJSON.objects.length === 1 && strCanvasJSON.objects[0].type === "path") {
        const xDistance = strCanvasJSON.objects[0].path[1][1] - strCanvasJSON.objects[0].path[0][1];
        const yDistance = strCanvasJSON.objects[0].path[1][2] - strCanvasJSON.objects[0].path[0][2];
        console.log("xDistance ", xDistance, " yDistance ", yDistance);
        if (xDistance < 1 && yDistance < 1)
            return true;
        else return false;
    } else {
        printConsole("Other types of annotation is received");
        return false;
    }
}

function displayAnnotation(paramElem, imgData) {
    printConsole('inside displayAnnotation!');
    let drawElem = document.getElementById('canvas');

    if (drawElem !== null)
        drawElem.parentNode.removeChild(drawElem);

    // create a canvas element
    let newCanvas = document.createElement('canvas');
    newCanvas.id = 'canvas';
    let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
    if (paramElemVdoChild === undefined || paramElemVdoChild.length === 0) {
        printConsole("The Technician camera feed is not there");
        return;
    }
    newCanvas.width = paramElemVdoChild[0].offsetWidth;
    newCanvas.height = paramElemVdoChild[0].offsetHeight;

    // insert canvas after video element
    paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);

    // add a fabric canvas element
    let canvas = new fabric.Canvas(newCanvas);

    let dimX = otTechObj.state.expertDimX;
    let dimY = otTechObj.state.expertDimY;
    if (dimX === 0) {
        printConsole('dimensions of expert canvas did not come in');
        dimX = canvas.getWidth();
        dimY = canvas.getHeight();
        //return;
    }

    if (strCanvasJSON !== null) {
        //TP-2475
        /* if (otTechObj.state.pointerDimX === true) {
            canvas.loadFromJSON(strCanvasJSON, function () {
                //fabric.log(o, object);
                let objList = canvas.getObjects();
                let index = 0;
                let multiX = canvas.getWidth() / dimX;
                let multiY = canvas.getHeight() / dimY;
                for (index = 0; index < objList.length; index++) {
                    var context=canvas.getContext("2d");
                    context.clearRect(0,0,canvas.width, canvas.height);
                    let marginLeft = objList[index].left * multiX;
                    let marginTop = (objList[index].top * multiY)+10; 
                    let strokeColor = objList[index].stroke;
                    context.strokeStyle = strokeColor;                    
                    context.arc(marginLeft, marginTop, 7, 0, 2 * Math.PI);
                    /* setInterval(()=>{
                        context.font='500 40px FontAwesome';
                        context.fillStyle = strokeColor;
                        context.textAlign = 'center';
                        context.fillText('\uf05b',marginLeft, marginTop);
                        //clearInterval(annotateTimer);
                    },1000) 
                }
            });
        } else { */
            canvas.loadFromJSON(strCanvasJSON, function () {
                //fabric.log(o, object);
                let objList = canvas.getObjects();
                let index = 0;
                let multiX = canvas.getWidth() / dimX;
                let multiY = canvas.getHeight() / dimY;
                for (index = 0; index < objList.length; index++) {
                    objList[index].scaleX = objList[index].scaleX * multiX;
                    objList[index].scaleY = objList[index].scaleY * multiY;
                    objList[index].left = objList[index].left * multiX;
                    objList[index].top = objList[index].top * multiY;
                    objList[index].selectable = false;
                    objList[index].setCoords();
                }
            });
        //}
    }

    if (imgData !== '') {
        const contentType = 'image/png';
        const blob = new base64StringToBlob(imgData, contentType);
        let urlObj = URL.createObjectURL(blob);

        fabric.Image.fromURL(urlObj, function (img) {
            // add background image
            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                scaleX: canvas.width / img.width,
                scaleY: canvas.height / img.height
            });
        });
    }
    else {
        canvas.backgroundImage = false;
        canvas.renderAll();
    }
}

//TP-2523
function displayPointerTrack (paramElem, pencolor, imgData) {

    let drawElem = document.getElementById('canvas');
    let canvasContainer = paramElem.getElementsByClassName('canvas-container');
    let canvas;
    /**TP-2572 */
    if (drawElem !== null)
        drawElem.parentNode.removeChild(drawElem);
    if (canvasContainer && canvasContainer.length > 0) {
        for (let i=0; i<canvasContainer.length; i++) {
            canvasContainer[i].parentNode.removeChild(canvasContainer[i]);
        }
    }

    // TP-2572 & TP-2523 -- To handle the creation of the canvas when only the Pointer
    // Payload comes to the technician without there being any other annotation drawn
    if (document.getElementById('canvas') === null || document.getElementById('canvas') === undefined) {
        printConsole("canvas element not there so creating new canvas element");
        //return;

        // create a canvas element
        let newCanvas = document.createElement('canvas');
        newCanvas.id = 'canvas';
        if (paramElem === undefined) return; //TP-2720
        let paramElemVdoChild = paramElem.getElementsByClassName('OT_video-element');
        if (paramElemVdoChild === undefined || paramElemVdoChild.length === 0) {
            printConsole("The Technician camera feed is not there");
            return;
        }
        newCanvas.width = paramElemVdoChild[0].offsetWidth;
        newCanvas.height = paramElemVdoChild[0].offsetHeight;

        // insert canvas after video element
        paramElemVdoChild[0].parentNode.insertBefore(newCanvas, paramElemVdoChild[0].nextSibling);

        // add a fabric canvas element
        canvas = new fabric.Canvas(newCanvas);
    } /* TP-2572-- else {
        // add a fabric canvas element
        canvas = new fabric.Canvas(drawElem);
    } */

    let dimX = otTechObj.state.expertDimX;
    let dimY = otTechObj.state.expertDimY;
    if (dimX === 0) {
        printConsole('dimensions of expert canvas did not come in');
        dimX = canvas.getWidth();
        dimY = canvas.getHeight();
        //return;
    }
    let lineH, lineV, circleP;//TP-2720 & TP-2569
    
    let pointerX = otTechObj.state.pointerDimX;
    let pointerY = otTechObj.state.pointerDimY;

    if (strCanvasJSON !== null) 
        canvas.loadFromJSON(strCanvasJSON, function () {
            //fabric.log(o, object);
            let objList = canvas.getObjects();
            let index = 0;
            let multiX = canvas.getWidth() / dimX;
            let multiY = canvas.getHeight() / dimY;
            for (index = 0; index < objList.length; index++) {
                objList[index].scaleX = objList[index].scaleX * multiX;
                objList[index].scaleY = objList[index].scaleY * multiY;
                objList[index].left = objList[index].left * multiX;
                objList[index].top = objList[index].top * multiY;
                objList[index].selectable = false;
                objList[index].setCoords();
            }
        });

    if (pointerX !== -1 && pointerY !== -1) {
        //Remove older pointer objects in the canvas
        let obj = canvas.getObjects();
        //TP-2569 -- Plus sign drawing for the Pointer's location insted of the Dot
        for(let index = 0; index < obj.length; index++){
            if (obj[index].type === "line" && obj[index].id === "pointer_line_h") canvas.remove(obj[index]);
            if (obj[index].type === "line" && obj[index].id === "pointer_line_y") canvas.remove(obj[index]);
            if (obj[index].type === "circle" && obj[index].id === "pointer_circle") canvas.remove(obj[index]); //TP-2720
        }
        let multiX = canvas.getWidth() / dimX;
        let multiY = canvas.getHeight() / dimY;

        let xPos = Math.round(pointerX * multiX);
        let yPos = Math.round(pointerY * multiY);

        let deltaPos = 16; //TP-2720
        // Initiate a line instance
        let xPos1 = (xPos - deltaPos);
        let xPos2 = (xPos + deltaPos);
        /**TP-2720 -- New UI for the Pointer Crosshair 
        * Icon with changes to size and strokeWidth etc
        */
        lineH = new fabric.Line([xPos1, yPos-1, xPos2, yPos-1], {
                strokeWidth: 3.5,
                stroke: pencolor,
                selectable: false, //TP-2600 -- Use case 2
                id: 'pointer_line_h',
        });
        let yPos1 = (yPos - deltaPos);
        let yPos2 = (yPos + deltaPos);
        lineV = new fabric.Line([xPos-1.5, yPos1, xPos-1.5, yPos2], {
                strokeWidth: 3.5,
                stroke: pencolor,
                selectable: false, //TP-2600 -- Use case 2
                id: 'pointer_line_y',
        });
        circleP = new fabric.Circle({
            left: xPos,
            top: yPos,
            originX: 'center',
            originY: 'center',
            radius: 10,
            fill: '',
            stroke: pencolor,
            selectable: false, //TP-2600 -- Use case 2
            strokeWidth: 1.5,
            id: "pointer_circle"
        });

        //console.log("xPos=" + xPos + " yPos=" + yPos + " xPos1=" + xPos1 + " xPos2=" + xPos2 + " yPos1=" + yPos1 + " yPos2=" + yPos2);
        canvas.add(lineH);
        canvas.add(lineV);
        canvas.add(circleP); //TP-2720

        /* pointer = new fabric.Circle({
            left: pointerX * multiX,
            top: pointerY * multiY,
            originX: 'center', //TP-2523
            originY: 'center',
            radius: 4,
            fill: pencolor,
            stroke: pencolor,
            strokeWidth: 2
        });

        canvas.add(pointer); */
    } else {
        if (canvas === undefined) return;
        //Remove older pointer objects in the canvas
        let obj = canvas.getObjects();
        //TP-2569
        for(let index = 0; index < obj.length; index++){
            if (obj[index].type === "line" && obj[index].id === "pointer_line_h") canvas.remove(obj[index]);
            if (obj[index].type === "line" && obj[index].id === "pointer_line_y") canvas.remove(obj[index]);
            if (obj[index].type === "circle" && obj[index].id === "pointer_circle") canvas.remove(obj[index]); //TP-2720
        }
        let multiX = canvas.getWidth() / dimX;
        let multiY = canvas.getHeight() / dimY;
    }

    //TP-2523-- Handling of Technician/Passive expert side RC image loading when pointer annotaion payload is received
    // When the RC Image is set
        if(imgData !== ''){
            const contentType = 'image/png';
            const blob = new base64StringToBlob(imgData, contentType);
            let urlObj = URL.createObjectURL(blob);

            fabric.Image.fromURL(urlObj, function (img) {
                // add background image
                canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
                    scaleX: canvas.width / img.width,
                    scaleY: canvas.height / img.height
                });
            })
        }
}

function sendChatOut(msgToSend, elemId) {
    //printConsole('sendChatOut called with: ', msgToSend, elemId, g_subInfoArr, g_session, g_pubConnsArr);
    let sixdigitsrandom = 0;//MB2-95
    // send this message out
    if (g_session !== null && elemId !== null && g_subInfoArr.length > 0) {
        sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);//MB2-95
        g_subInfoArr.forEach(subInfo => {
            if (subInfo.g_divId === elemId) {
                const toArray = subInfo.g_stream.connection.data.split(":::");
                const toObject = toArray[3];
                otTechObj.sessionSignal.sendSignalChat(

                    //from: g_session.connection.data,
                    sixdigitsrandom, //MB2-95
                    msgToSend,
                    toObject
                    //type:"IM"

                );
                //printConsole("signal sent.");
                return;
            }
        })
    }
    else if (g_session !== null && elemId !== null && g_pubConnsArr.length > 0) {
        // send to all subscribers ---- 
        sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);//MB2-95
        g_pubConnsArr.forEach(connectionObj => {
            if (g_session.connection !== connectionObj) {
                const toArray = connectionObj.data.split(":::");
                const toObject = toArray[3];
                otTechObj.sessionSignal.sendSignalChat(

                    //from: g_session.connection.data,
                    sixdigitsrandom, //MB2-95
                    msgToSend,
                    toObject
                    //type:"IM"

                );
                //printConsole("signal sent.");
                return;
            }
        })
    }    
    return;
}

class OTTechnician extends OTSessionUser {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            chatLeft: '0px',
            chatTop: '0px',
            chatWidth: '0px',
            chatHeight: '0px',
            chatMsgInfoArr: [{
                msgColor: '',
                messageID: '',
                firstName: '',
                lastName: '',
                message: '',
                timeStamp: '',
                msgType: 0 // 0 - indiv, 1 - group
            }],
            maxView: false,
            maxViewExpert: "",
            maxViewExpertName: "",
            showRc: false,
            expertDimX: 0,
            expertDimY: 0,
            rcImgObj: '',
            rcImgType: '',
            bRemoteCapture: false,
            bMaxChat: false,
            showFileViewer: false,
            fileNameArr: [],
            fileType: 'none',
            filePath: '',
            fileName: '',
            URLs: [],
            expertShareScreenMaxDiv: false,
            videoSource: "",
            audioSource: "",
            defAudioId: "", 
            defVideoId: "",
            changeCamera: true,
            cameraSwitched: false,
            cameraCount: 1,
            showCameraSelectionDecisionBox: false,
            triggerCameraDetect: false,
            flashMsgText: "",
            showFlashMessage: false,
            flashLeft: "0px",
            flashTop: "0px",
            cameraSwapEventType: "",
            hideButton: 'd-blk',
            publishVideo: true,
            bMuteMyMike: false,
            fileOpacity: "1.0", //"0.6", //TP-841
            assetsArray: [],
            rotationArray: '' //TP-959
        }  
        g_session = null; 
        //g_subInfoArr = [];
        this.config = {
            bucketName: process.env.REACT_APP_S3_BUCKET,
            dirName: process.env.REACT_APP_S3_DIR, /* optional */
            region: process.env.REACT_APP_S3_REGION,
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        }; 
        this.fileViewer = null;
        this.closeSubscriber3dObject = null;  
        this.active3dModel = null; 
        this.authService = new AuthService(); 
        otTechObj = this;
    }

    getDeviceClassname = () => {
        var ua = navigator.userAgent.toLowerCase();
        var up = navigator.platform.toLowerCase();
        const mxp = navigator.maxTouchPoints;
        var isiPad = /ipad/i.test(ua) || /iphone/i.test(ua) || /iphone /i.test(ua) || (/macintel/i.test(up) && mxp > 1);
        var isAndroid = /android/.test(ua);
        //printConsole(isiPad);
        let hideButton = "d-blk";
        if (isiPad || isAndroid) {
            hideButton = "d-none";
        } 
        return hideButton;
    }    
    
    componentWillMount = () => {
        //printConsole('OT initialized from componentWillMount');
        this.initMedia(); 
        window.addEventListener("resize", this.updateDimensions);
        this.notifyUser();
        /* let session = this.initializeOTSession();
        //this.props.changeGlobalSession(session);
        g_session = session; */ 
        const hideButton = this.getDeviceClassname();
        this.setState({hideButton});
        const {user, loggedInUserId} = this.props;
        let { selectedGroupId } = this.props;
        if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);        
        // MB2-90 & MB2-106
        const from = { userId: loggedInUserId, firstName: user.firstname, lastName: user.lastname, email: user.email, isexpert: user.isexpert };
        this.sessionSignal = new SessionSignal(from, false, selectedGroupId);                       
        this.sessionSignal.listenerWebsocket(this.subscribeToGroupSignal);
        this.sessionSignal.listenerUserinsession(this.subscribeToUserIsInSession);//TP-2832  
    }
    
    componentDidUpdate = (prevProps, prevState) => {
        /* if (prevProps.sessionInfo.credit_limits !== this.props.sessionInfo.credit_limits){
            let session = this.initializeOTSession();
            g_session = session;
        } */
        if (prevProps.newConnObj !== this.props.newConnObj && this.props.newConnObj !== null) {
            this.joiningTimer = setTimeout(()=> {
                this.sendTechnicianMuteAllCmd(this.state.bMuteMyMike, this.props.newConnObj); //T32-413
                this.sendUserInfoToUser(this.props.newConnObj) // TP-226
                clearTimeout(this.joiningTimer);
            }, 7000);
        }
        if (this.props.expertShareScreenMaxDiv !== prevProps.expertShareScreenMaxDiv && this.props.expertShareScreenMaxDiv === false) {
            this.processSignalStopExpertSS();
        }
        if (this.props.disconnectExpertEmail !== prevProps.disconnectExpertEmail && this.props.disconnectExpertEmail === this.state.maxViewExpert) {
            /* const threeDElem = document.getElementById('canavas-3d-container');
            if (threeDElem !== null) { */
                //this.processSignalRemoveArtifacts();
            //}
        }
        else if (this.props.cameraSwapEventType !== prevProps.cameraSwapEventType && this.props.cameraSwapEventType !== this.state.cameraSwapEventType){
            this.setState({ cameraSwapEventType: this.props.cameraSwapEventType });
        }
        else if ((this.state.videoSource !== prevState.videoSource && this.state.videoSource !== "") /* || (this.state.audioSourcecameraSwapEventType !== prevState.audioSource && this.state.audioSource !== "") */) {
            //printConsole("Swap camera sent signal")
            //printConsole("Camera Swap event type::::", this.state.cameraSwapEventType);
            this.sendSignalSwapCamera(this.state.cameraCount, );            
        }
        else if (this.props.triggerSwapCamera !== prevProps.triggerSwapCamera && this.props.triggerSwapCamera === true) {
            //printConsole("Auto Swap camera sent signal")
            //this.sendSignalSwapCamera(this.state.cameraCount);
            //this.openChangeCameraDialog();
            this.startCameraDetection();
            this.props.updateTriggerSwapCamera(false);
            this.setState({ triggerCameraDetect: false });
        } 
        else if (this.state.cameraCount !== prevState.cameraCount && this.state.cameraCount > prevState.cameraCount){
            this.triggerMessage("", "connect");
        }
        else if (this.state.cameraCount !== prevState.cameraCount && this.state.cameraCount < prevState.cameraCount){
            this.triggerMessage("", "disconnect");
        }
    }

    updateCameraSelected = (val) => {
        this.setState({ videoSource: val }) 
    }
     
    updateMicSelected = (val) => {
        this.setState({ audioSource: val });
    }

    updateDefaultDevices = (audioId, videoId) => {
        this.setState({ defAudioId: audioId, defVideoId: videoId });
    }

    updateCameraDeviceCount = (val) => {
        this.setState({ cameraCount: val });
        this.props.updateCameraCount(val);
    }

    startCameraDetection = () => {
        this.setState({ triggerCameraDetect: true });
    }

    openChangeCameraDialog = () => {        
        this.setState({ cameraSwapEventType: "click" });        
        //printConsole("change camera? true ");
        this.setState({ changeCamera: false });
        this.props.updateCameraSwapEventType("click");
    }

    closeChangeCameraDialog = () => {       
        //printConsole("change camera? false");
        this.setState({ changeCamera: true });
        //this.props.updateChangeCamera(true);
    }

        
    /*initializeOTSession = () => {
        let session = OT.initSession(this.props.sessionInfo.APIKey, this.props.sessionInfo.sessionID, { ipWhitelist: true });
        if (session !== null) {
            session.on("connectionCreated", function (event) {
                g_pubConnsArr.push(event.connection);
                printConsole('connectionCreated: ', event.connection);
                
            });
        }
        
        //let publisher = this.publishStream();
        const streamName = this.props.sessionInfo.firstname + ' ' + this.props.sessionInfo.lastname;
        
        let div = document.createElement('div');
        div.setAttribute('id', 'publisherContainer');
        let subscriberInfo = '';
        let subscriberBox = document.getElementById(subscriberContainerDivId);

        //set the name div of the camera feed element
        let nameDiv = document.createElement('div');
        nameDiv.classList.add('position-absolute', 'w-100', 'nameDiv');
        nameDiv.style.color = 'white'; 
        nameDiv.style.zIndex = '10';
        nameDiv.style.top = "2%";
        nameDiv.innerHTML = streamName;
        div.className = "text-center";
        div.appendChild(nameDiv);
        subscriberBox.appendChild(div);
        printConsole(subscriberBox.innerHTML);

        // Create a camera publisher
        let publisher = OT.initPublisher('publisherContainer', {
            insertMode: 'append',
            name: streamName,
            facingMode: 'environment',
            resolution: '1280x720',
            frameRate: 15,
            width: '100%',
            height: '100%',
            mirror: false,
            fitMode: 'contain', // 'cover' = cropped by default
            style: {
                nameDisplayMode: 'off'
            }
        }, this.handleError);        
        
        if (publisher === null)
        printConsole('publisher is a null!!!');
        else {
            publisher.setStyle({ buttonDisplayMode: 'off' });
            // Connect to the session
            session.connect(this.props.sessionInfo.token, function (error) {
                // If the connection is successful, publish to the session
                if (error) {
                    this.handleError(error);
                    printConsole('error in calling publisher -> session.connect, error: ', error);
                } else {
                    session.publish(publisher, function (error) {
                        if (error) {
                            this.handleError(error);
                            printConsole('Publish call gave an error: ', error);
                        } else {
                            otTechObj.props.changeStartStop();
                        }
                    });
                }
            });
            //this.setState ( {publisher: publisher} );
            publisher.on({
                'accessDenied': function () {
                    printConsole('Please allow access to the Camera and Microphone and try publishing again.');
                    alert('Please allow access to the Camera and Microphone and try streaming again.');
                }
                ,
                'streamCreated': function (event) {
                }
                ,
                'streamDestroyed': function (event) {
                    if (!otTechObj.state.expertShareScreenMaxDiv)
                        return;

                        let subscriberBox = document.getElementById(subscriberContainerDivId);
                        subscriberBox.appendChild(document.getElementById('maxdivcontrols'));
                        //subscriberBox.appendChild(document.getElementById('controlpanel'));
                        let pubElem = document.getElementById('publisherContainer');
                        if (pubElem !== null && pubElem !== undefined) {
                            subscriberBox.removeChild(document.getElementById('publisherContainer'));
                        }
                        // hide the rc button when stopping the session for tech
                        otTechObj.setState({ showRc: false })
                        
                }
            });
                
                //this.setState({ publisher: publisher });
                g_publisher = publisher;
                g_camera_publisher = publisher;
                
                session.on("streamDestroyed", function (event) {
                    //printConsole(event.stream);
                    // data format is 1:::fname:::lname:::email:::true:::opentok webhook url:::logged in user timestamp
                    let data = event.stream.connection.data;
                    let dataSplit = data.split(':::');

                    if (dataSplit[4] === 'true') {
                        // this for loop and if condition is added for NS2-122
                        // g_expertConnsArr is an array of experts connection data
                        // which is set inside the streamCreated of the technician
                        // and here it is getting unset
                        for (let ii = 0; ii < g_expertConnsArr.length; ii++) {
                            if (event.stream.connection.data === g_expertConnsArr[ii] && event.stream.videoType !== 'screen') {
                                g_expertConnsArr.splice(ii, 1);
                            }
                        }
                        
                        //When the expertScreenShare is turned on and the expert stops the session
                        // But there are other Experts in the session then the technician live feed
                        // should get turned on
                        // NS2-121
                        if (event.stream.videoType === 'screen' && otTechObj.state.expertShareScreenMaxDiv) {
                            otTechObj.setState({ expertShareScreenMaxDiv: false });
                            // hide the incoming expert screen share Div to the container
                            document.getElementById('expertscreenshare').style.display = "none";
                            // show the self-published Div from the container
                            document.getElementById('publisherContainer').style.display = "block";
                            // for NS2-90
                            for (let i = 0; i < g_subInfoArr.length; i++) {
                                if (event.stream.connection.data === g_subInfoArr[i].g_stream.connection.data) {
                                    g_subInfoArr.splice(i, 1);
                                }
                            }
                            // for NS2-98
                            otTechObj.drawPublisherUI(window.innerHeight, window.innerWidth, null, false);
                        }
                        // Only when the no. of Experts in the session becomes zero,
                        // should the technicians be taken out of the session.
                        if (g_expertConnsArr.length === 0) {
                            if (!otTechObj.state.expertShareScreenMaxDiv && document.getElementById('expertscreenshare') === null) {
                                otTechObj.props.onCloseTechnician(true);
                            } else if ((event.stream.videoType !== 'screen' && otTechObj.state.expertShareScreenMaxDiv) || (event.stream.videoType !== 'screen' && otTechObj.state.techShareScreen)) {
                                // for NS2-100 -- Handling on the technician side
                                otTechObj.props.onCloseTechnician(true);
                            }
                        }
                        // this condition is added for NS2-96
                        if (event.stream.videoType !== 'screen') {
                            otTechObj.setState({ showRc: false });
                        }
                    }
                    /* diagonastics(otTechObj.authService, {
                        action: `in streamDestroyed for technician`,
                        next_step: '',
                        data,
                        error: 'none'
                    }) 
                });

                session.on("streamCreated", function (event) {
                    //let subProps = {insertDefaultUI: false};
                    //printConsole(event.stream.streamId)
                    //printConsole(event.stream.videoType)
                    let senderInfo = event.stream.connection.data;
                    //printConsole(senderInfo);
                    const senderInfoArr = typeof senderInfo === "string" ? senderInfo.split(':::') : "";
                    if (senderInfoArr[4] === 'true' || otTechObj.state.sessionInfo.audio_mode === 'tech2Tech') {
                        // for NS2-122                      
                        if (senderInfoArr[4] === 'true' && event.stream.videoType !== 'screen') {
                            g_expertConnsArr.push(event.stream.connection.data);
                            //printConsole('Expert streamCreated: ', event.stream.connection.data);
                        }
                        if (senderInfoArr[4] === 'true' && event.stream.videoType === 'screen') {
                            // subscribe to the expert screen share stream when its available **
                            let subscriberBox = document.getElementById(subscriberContainerDivId);
                            let divElem = document.createElement('div');
                            divElem.setAttribute('id', 'expertscreenshare');
                            subscriberBox.appendChild(divElem);
                            let subProps = { appendMode: 'append', width: '100%', height: '100%' }; //insertMode: 'append', };                            
                            g_subscriber = session.subscribe(event.stream, "expertscreenshare", subProps);
                            document.getElementById('expertscreenshare').style.display = 'none';
                        }
                        
                        // ** else subscribe to expert audio only
                        g_subscriber = session.subscribe(event.stream, "", { insertDefaultUI: false });
                        g_stream = event.stream;
                        subscriberInfo = {
                            g_stream: g_stream,
                            g_subscriber: g_subscriber
                        }
                        g_subInfoArr.push(subscriberInfo);
                    }

                    let pubContainerElem = document.getElementById('publisherContainer');
                    if (document.getElementById('maxdivcontrols') !== null && pubContainerElem !== null && pubContainerElem !== document.getElementById('maxdivcontrols').parentElement) {
                        pubContainerElem.appendChild(document.getElementById('maxdivcontrols'));
                    }
                    
                });
                
                session.on("sessionDisconnected", (event) => {
                    session.off();
                    otTechObj.props.changeStartStop();
                    this.authService.socket.emit('user-is-in-session', { groupId: this.props.selectedGroupId, isInSession: false });
                });

                this.drawPublisherUI(document.documentElement.clientHeight, window.innerWidth, null);
        }
        return session;
    } */ 
    
    componentDidMount = () => {
        this.setState({ chatUserEmails: [], chatUserNames: [] });
        if (typeof(Storage) !== "undefined") {
            // Store
            printConsole(`setting the group id in session storage =======================================>${this.props.selectedGroupId}`)
            sessionStorage.setItem("groupId", this.props.selectedGroupId); sessionStorage.setItem("isonprem", false);
        }else{
            printConsole(`Sorry, your browser does not support Web Storage...`)
        }
    }
    
    /* publishStream = () => {
        const streamName = this.props.sessionInfo.firstname + ' ' + this.props.sessionInfo.lastname;
        
        let div = document.createElement('div');
        div.setAttribute('id', 'publisherContainer');
        let subscriberInfo = '';
        let subscriberBox = document.getElementById(subscriberContainerDivId);

        //set the name div of the camera feed element
        let nameDiv = document.createElement('div');
        nameDiv.classList.add('position-absolute', 'w-100', 'nameDiv');
        nameDiv.style.color = 'white'; 
        nameDiv.style.zIndex = '10';
        nameDiv.style.top = "2%";
        nameDiv.innerHTML = streamName;
        div.className = "text-center";
        div.appendChild(nameDiv);
        subscriberBox.appendChild(div);

        // Create a camera publisher
        let publisher = OT.initPublisher('publisherContainer', {
            insertMode: 'append',
            name: streamName,
            facingMode: 'environment',
            resolution: '1280x720',
            frameRate: 15,
            width: '100%',
            height: '100%',
            mirror: false,
            fitMode: 'contain', // 'cover' = cropped by default
            style: {
                nameDisplayMode: 'off'
            }
        }, this.handleError);

        return publisher;
    } */    
    
    sendChatMsg = (msgToSend, elemId) => {
        // save message in list
        const {g_session, g_pubConnsArr} = this.props;
        let timeStamp = this.props.getTimeStamp();
        let chatMsgInfo = {
            msgId: elemId, firstName: this.props.sessionInfo.firstname,
            lastName: this.props.sessionInfo.lastname, message: msgToSend, timeStamp: timeStamp, msgType: 1
        };

        this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}))
        //this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
        this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);

        let sixdigitsrandom = 0; //MB2-95

        // send this message out
        // NS2-347
        if (g_session !== null && elemId !== null && g_pubConnsArr.length > 0) {
            // send to all subscribers ---- 
            sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); // MB2-95
            g_pubConnsArr.forEach(connectionObj => {
                if (g_session.connection !== connectionObj) {
                    const toArray = connectionObj.data.split(":::");
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalChat(

                        //from: g_session.connection.data,
                        sixdigitsrandom, //MB2-95
                        msgToSend,
                        toObject
                        //type:"IM"

                        //printConsole('signal sent - error = ', error);
                    );
                    return;
                }
            })
        }
        else if (g_session !== null && elemId === null) {
            sixdigitsrandom = Math.floor(100000 + Math.random() * 900000); // MB2-95
            this.sessionSignal.sendSignalChat(

                //from: g_session.connection.data,
                sixdigitsrandom,  // MB2-95
                msgToSend
                //type:"IM"

            );
            //printConsole("signal sent.");
        }
        return;
    }

    // To find out the user Info about this technician to send to experts
    // 
    sendUserInfoToUser = (connObj) => {
        const {user_info} = this.props;
        let info = JSON.parse(user_info);
        // If the technician is in the Max view of the Expert
        // and is on a safari browser/Android device then remove tech_ss
        // into the signal payload else dont
        // for the signal is True  
        const ua = navigator.userAgent.toLowerCase();
        //printConsole(ua);

        let data = {
            zoom_enabled: false,
            torch_enabled: false,
            screenshare_enabled: true,
            techSS_enabled: true,
            techtype: "WEB",
            browser_name: info.browser_name.toLowerCase(),
            browser_version: info.browser_version,
            os_name: info.os_name.toLowerCase(),
            os_version: info.os_version,
            deviceip: info.ip,
            coordinates: info.coordinates
        }
        
        if (/chrome/.test(ua)) {
            if (/android/.test(ua)) {
                data.techSS_enabled = false; 
            }
        } else if (/safari/.test(ua)) {
            data.techSS_enabled = false; 
        }
        this.sendSignalIMWhenJoined(data, connObj); 
    }

    sendSignalIMWhenJoined = (data, connObj) => {
        try {
            const {g_pubConnsArr} = this.props;
            if (connObj !== null) {                
                const toArray = connObj.data.split(":::");
                const toObject = toArray[3];
                //console.log("data------>", data);
                this.sessionSignal.sendSignalChat(
                            0,
                            data,
                            toObject
                            //type:"IM"
                );
                printConsole(`signal sent to ${toObject}`);
                    
            } else {
                this.sessionSignal.sendSignalChat(
                    0,
                    data
                    //type:"IM"    
                );
                printConsole("signal sent to all experts");
            }

            return;
        } catch (error) {
            printConsole("exception occured in sendSignalIMWhenJoined method...")
            printConsole(error)
        }
    }

    // Onclick functions for Side Bar Technician screen Features
    // Header Mute Mike on/off function for all participants
    onClickMuteMikeForAll = (mikeState) => {
        let {g_camera_publisher} = this.props;
        this.setState({ bMuteMyMike: !mikeState }, () => {
            //g_camera_publisher.publishAudio(mikeState);
            this.props.updateAudioPublisher(!this.state.bMuteMyMike)
            this.sendTechnicianMuteAllCmd(!mikeState, null); //T32-413
        });
    }

    // T32-413 -- To be called from the Side bar "Mute Mike" for all experts
    sendTechnicianMuteAllCmd = (muteMyMike, connection) => {
        try {
            const {g_session, g_pubConnsArr} = this.props;
            if (connection === null) {
                if (g_pubConnsArr.length > 0) {
                    g_pubConnsArr.forEach(connectionObj => {
                        if (g_session && g_session.connection !== connectionObj) {
                            const toArray = connectionObj.data.split(":::");
                            const toObject = toArray[3];
                            this.sessionSignal.sendSignalMuteUserAudio(
    
                                //from: g_session.connection.data,
                                muteMyMike,
                                toObject
                                //type:"MUTE_USER_AUDIO"
    
                            );
                            printConsole(`User mute command sent with flag : ${muteMyMike}`);
                        }
                    });
                }
            } else {
                if (g_pubConnsArr.length > 0) {
                    g_pubConnsArr.forEach(connObj => {
                        if (connection === connObj) {
                            const toArray = connObj.data.split(":::");
                            const toObject = toArray[3];
                            this.sessionSignal.sendSignalMuteUserAudio(
    
                                //from: g_session.connection.data,
                                muteMyMike,
                                toObject
                                //type:"MUTE_USER_AUDIO"
    
                            );
                            printConsole(`User mute command sent with flag : ${muteMyMike} to user: ${toObject}`);
                        }
                    })
                }
            }
        }
        catch (exception) {
            printConsole('exception occured in technicianMuteAll');
            printConsole(exception);
        }
    }

    // Onclick functions for Technician Max-div Features
    
    onClickMaxChat = () => {
        this.setState((prevState) => ({
            bMaxChat: !prevState.bMaxChat
        }), () => {
            let flg = false;  
            this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null, flg);
        });
    }

    // send signal to initiate rc from expert 
    onClickexpertRcSignal = () => {
        let { bRemoteCapture } = this.state;
        const {g_session, g_expertConnsArr} = this.props;
        /* diagonastics(this.authService, {
            action: `Annotate button clicked by technician`,
            next_step: '',
            data: `bRemoteCapture-${bRemoteCapture}`,
            error: 'none'
        }) */
        this.setState((prevState) => ({ bRemoteCapture: !prevState.bRemoteCapture }), () => {
            if (g_session !== null && g_expertConnsArr.length > 0) {
                g_expertConnsArr.forEach(subInfo => {            
                    const toArray = subInfo.split(":::");
                    if (this.state.maxViewExpert === toArray[3]) {                    
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalLocalRC(
                            this.state.bRemoteCapture,
                            toObject
                            //type:"START_LOCAL_RC"
                        );
                        //printConsole(`signal sent - for = START_LOCAL_RC to ${toObject}`);
                    }
                });
            }    
        })
    }

    // publish the right kinda stream based on the flag (true=screen, false=camera)
    // called from Technician Webapp only
    rePublishTechnicianStream = (bScreen, connData) => {
        const {g_session} = this.props;
        let subscriberBox = document.getElementById(subscriberContainerDivId);
        if (bScreen) {
            // create the screen publisher
            let div = document.createElement('div');
            div.setAttribute('id', 'myTechScreenShareDiv');
            let retNode = subscriberBox.appendChild(div);
            g_screen_publisher = OT.initPublisher('myTechScreenShareDiv', {
                facingMode: 'environment',
                resolution: '1280x720',
                frameRate: 15,
                width: '100%',
                height: '100%',
                publishAudio: false,
                videoSource: 'screen',
                mirror: false,
                fitMode: 'contain'
            }, function (error) {
                if (error) {
                    //printConsole("The user has cancelled the Screen Share, hence closing it..")
                    otTechObj.sendSignalCancelTechScreenShare(connData);
                    otTechObj.setState({ techShareScreen: false });
                    otTechObj.setState({ showRc: true });
                    // for NS2-100
                    otTechObj.props.updateRCStatus(false);
                    return;
                } else {
                    otTechObj.props.updateRCStatus(false);
                    // publish the screen
                    g_session.publish(g_screen_publisher, function (error) {
                        if (error) {
                            otTechObj.handleError(error);
                            printConsole('Technician Screen share Publishing call gave an error: ', error);
                        }
                    });
                }
            }); // otTechObj.handleError

            // hide the technician live stream Div
            document.getElementById('myTechScreenShareDiv').style.display = "none";
        }
        else {
            otTechObj.props.updateRCStatus(false);
            subscriberBox.removeChild(document.getElementById('myTechScreenShareDiv'));
            g_session.unpublish(g_screen_publisher);
            g_screen_publisher.destroy();
            g_screen_publisher = null;
            //	   		g_publisher = g_camera_publisher;
        }
    }

    //To be called when the Technician clicks on "cancel" button of Screen Share pop-up
    //called from Technician Webapp only
    sendSignalCancelTechScreenShare = (connData) => {
        try {
            this.sessionSignal.sendSignalCancelTechnicianSS(
                connData
                //type: "CANCEL_TECHNICIAN_SS"
            );
            //printConsole("Technician Screen Share cancelled sent to...", connData);
        }
        catch (exception) {
            printConsole('exception occured in sendSignalCancelTechScreenShare');
            printConsole(exception);
        }
    } 
    
    // publish the new camera stream the one selected by the User
    rePublishTechnicianCameraStream = () => { 
        const { cameraSwitched }  = this.state;
        if (cameraSwitched === false) {
            this.setState({ cameraSwitched: true }, () => {
                //this.sendSignalSwapCamera(cameraSwitched);
            });
        } else {
            //this.sendSignalSwapCamera(cameraSwitched);
        }      
        let { g_session } = this.props;
        let { g_publisher, g_camera_publisher } = this.props;
        //printConsole(g_session);
        //printConsole('Inside technician loop - trying to start publishing again');
        let subscriberBox = document.getElementById(subscriberContainerDivId);
        //Removing the old Publisher element from the technician screen
        if (document.getElementById('sessionTimer') !== null)
            subscriberBox.appendChild(document.getElementById('sessionTimer')); // T32-473
        if (document.getElementById('maxdivcontrols') !== null)
            subscriberBox.appendChild(document.getElementById('maxdivcontrols'));
        if (document.getElementById('publisherContainer') !== null) 
            subscriberBox.removeChild(document.getElementById('publisherContainer'));
            /* return;
        else */ 
        if (g_new_camera_publisher !== null) {
            g_session.unpublish(g_new_camera_publisher);
            g_new_camera_publisher.destroy();
            g_new_camera_publisher = null;
        }
        
        // Creating the new Publisher Div with the new Camera/Mic specifications
        const streamName = this.props.sessionInfo.firstname + ' ' + this.props.sessionInfo.lastname;
        
        let div = document.createElement('div');
        div.setAttribute('id', 'publisherContainer');
        let subscriberInfo = '';
        
        //let div = document.getElementById('publisherContainer');
        
        //set the name div of the camera feed element
        let nameDiv = document.createElement('div');
        nameDiv.classList.add('position-absolute', 'w-100', 'nameDiv');
        nameDiv.style.color = 'white'; 
        //nameDiv.style.zIndex = '10';
        nameDiv.style.top = "2%";
        nameDiv.style.left = "0";
        nameDiv.innerHTML = streamName;
        div.className = "text-center";
        div.appendChild(nameDiv);
        let retNode = subscriberBox.appendChild(div);
        //printConsole(`Audio device: ${this.state.audioSource}`);
        //printConsole(`Camera device: ${this.state.videoSource}`);
        
        // Set the Publisher Properties
        let pubProps = {
            insertMode: 'append',
            name: streamName,
            facingMode: 'environment',
            resolution: '1280x720',
            frameRate: 15,
            width: '100%',
            height: '100%',
            videoSource: this.state.videoSource,
            audioSource: this.state.audioSource,
            mirror: false,
            fitMode: 'contain', // 'cover' = cropped by default
            style: {
                nameDisplayMode: 'off',
                buttonDisplayMode: 'off'
            }
        }
        //printConsole("Pubslisher Properties-----");
        //printConsole(pubProps);
        // Create a camera publisher
        let publisher1 = null;
        let publisher = OT.initPublisher('publisherContainer', pubProps, function (error) {
                if (error) {
                    if (error.code === 1500) {
                        printConsole("The default camera set in the cookies is not connected any more");
                        otTechObj.deleteCookie("camera");
                        if (otTechObj.state.defAudioId !== "" && otTechObj.state.defVideoId !== "") {
                            pubProps = {
                                insertMode: 'append',
                                name: streamName,
                                facingMode: 'environment',
                                resolution: '1280x720',
                                frameRate: 15,
                                width: '100%',
                                height: '100%',
                                videoSource: otTechObj.state.defVideoId,
                                audioSource: otTechObj.state.defAudioId,
                                mirror: false,
                                fitMode: 'contain', // 'cover' = cropped by default
                                style: {
                                    nameDisplayMode: 'off',
                                    buttonDisplayMode: 'off'
                                }
                            };                            
                        } else {
                            pubProps = {
                                insertMode: 'append',
                                name: streamName,
                                facingMode: 'environment',
                                resolution: '1280x720',
                                frameRate: 15,
                                width: '100%',
                                height: '100%',
                                mirror: false,
                                fitMode: 'contain', // 'cover' = cropped by default
                                style: {
                                    nameDisplayMode: 'off',
                                    buttonDisplayMode: 'off'
                                }
                            };
                        }
                        publisher1 = OT.initPublisher('publisherContainer', pubProps, function (error) {
                            if (error && error.code === 1500) {
                                printConsole(error.message);
                            } else if (error) {
                                otTechObj.handleError();
                            }
                        });
                    } else {
                        otTechObj.handleError(error);
                    }
                }
            });
        
        if (publisher === null)
        printConsole('publisher is a null!!!');
        else {
            if (publisher1 !== null) {
                publisher = publisher1;
            }
            //publisher.setStyle({ buttonDisplayMode: 'off' });
            g_session.publish(publisher, function (error) {
                if (error) {
                    if (error.code === 1010) {
                        printConsole("The default camera & microphone selected previously in session is not detected any more, please restart the session again");
                        // Connect to the session
                        //printConsole(g_session);
                        g_session.connect(otTechObj.props.sessionInfo.token, function (error) {
                            // If the connection is successful, publish to the session
                            if (error) {
                                otTechObj.handleError(error);
                                printConsole('error in calling publisher -> session.connect, error: ', error);
                            } else {
                                g_session.publish(publisher, function (error) {
                                    if (error) {
                                        otTechObj.handleError(error);
                                        printConsole('Publish call gave an error: ', error);
                                    } else {
                                        //otTechObj.props.changeStartStop();
                                    }
                                });
                            }
                        });
                    } else if (error.code === 1500) { 
                        printConsole('Publish call gave an error: ', error);
                        publisher.setStyle({ buttonDisplayMode: 'off' });
                        g_session.publish(publisher, function (error) {
                            if (error) {
                                otTechObj.handleError(error);
                                printConsole('Publish call gave an error: ', error);
                            }
                            else {
                                //otTechObj.props.changeStartStop();
                            }
                        });
                    }else {
                        otTechObj.handleError(error);
                        printConsole('Publish call gave an error: ', error);
                    }
                } else {   
                    
                    //otTechObj.props.changeStartStop();
                }
            });
            
            //this.setState ( {publisher: publisher} );
            publisher.on({
                'accessDenied': function () {
                    printConsole('Please allow access to the Camera and Microphone and try publishing again.');
                    alert('Please allow access to the Camera and Microphone and try streaming again.');
                }
                ,
                'streamCreated': function (event) {
                }
                ,
                'streamDestroyed': function (event) {
                    //printConsole("Publisher stream got destroyed due to reason");
                    //printConsole(event.reason);
                    if (!otTechObj.state.expertShareScreenMaxDiv && otTechObj.state.cameraCount == 1)
                    return;
                    //printConsole("Camera Count: ",otTechObj.state.cameraCount);
                    //printConsole("change the camera state: ",otTechObj.state.changeCamera);
                    //printConsole("camera swap event type: ",otTechObj.state.cameraSwapEventType);
                    if (otTechObj.state.cameraCount > 1 && otTechObj.state.cameraSwapEventType === "" ){ /* event.reason === "mediaStopped" */
                    //if (event.reason === "mediaStopped"){
                        //printConsole("Auto Swap camera sent signal")
                        //otTechObj.sendSignalSwapCamera(0);
                        //otTechObj.openChangeCameraDialog();
                        //otTechObj.startCameraDetection();                        
                        //otTechObj.setState({ triggerCameraDetect: false });
                    }
                    otTechObj.setState({ cameraSwapEventType: "" });
                    otTechObj.props.updateCameraSwapEventType("");
                    let subscriberBox = document.getElementById(subscriberContainerDivId);
                    subscriberBox.appendChild(document.getElementById('maxdivcontrols'));
                    //subscriberBox.appendChild(document.getElementById('controlpanel'));
                    let pubElem = document.getElementById('publisherContainer');
                    if (pubElem !== null && pubElem !== undefined) {
                        subscriberBox.removeChild(document.getElementById('publisherContainer'));
                    }
                    // hide the rc button when stopping the session for tech
                    otTechObj.setState({ showRc: false })
                     
                }
            });

            g_new_camera_publisher = publisher;
            
            g_session.unpublish(g_publisher);
            g_session.unpublish(g_camera_publisher);            
            g_publisher.destroy();
            g_camera_publisher.destroy();
            g_publisher = null;
            g_camera_publisher = null;
            
            g_publisher = publisher;
            g_camera_publisher = publisher;            

            this.props.updatePublishers(publisher, g_session);
            this.drawPublisherUI(document.documentElement.clientHeight, window.innerWidth, null);
            // 
            if (this.state.publishVideo === false)
                this.waitTimer = setTimeout(() => {
                    g_new_camera_publisher.publishVideo(false);
                    clearTimeout(this.waitTimer);
                }, 5000);
        }
    }

    //To be called when the Technician camera gets switched to another device connected
    sendSignalSwapCamera = (data) => {
        try {
            let { g_session, g_expertConnsArr } = this.props;
            //printConsole(g_expertConnsArr.length);
            const { cameraSwapEventType, maxView } = this.state;
            if (g_session !== null && g_expertConnsArr.length > 0 && maxView === true){
                g_expertConnsArr.forEach(subInfo => {            
                    const toArray = subInfo.split(":::");
                    if (this.state.maxViewExpert === toArray[3]) {
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalSwapCamera(

                            //from: g_session.connection.data,
                            data,
                            cameraSwapEventType === "" ? "auto" : cameraSwapEventType,
                            toObject
                            //type:"SWAP_CAMERA"

                        );
                        printConsole(`signal sent for camera switch to ${toObject}`);
                        return;     
                    }       
                })
            }
            
        } catch (exception) {
            printConsole('exception occured in sendSignalSwapCamera');
            printConsole(exception);
        }
    }

    //To be called when the Technician camera switch gets confirmed by the user
    sendSignalConfirmSwapCamera = (data, eventType) => {
        try {
            let { g_session, g_expertConnsArr } = this.props;
            const { maxView } = this.state;
            if (g_session !== null && g_expertConnsArr.length > 0 && maxView === true){
                //printConsole(g_subInfoArr);
                //printConsole(g_expertConnsArr);
                g_expertConnsArr.forEach(subInfo => {            
                    const toArray = subInfo.split(":::");
                    if (this.state.maxViewExpert === toArray[3]) {
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalConfirmSwapCamera(

                            //from: g_session.connection.data,
                            data,
                            eventType,
                            toObject
                            //type:"CONFIRM_SWAP_CAMERA"

                        );
                        //printConsole("signal sent for confirm camera switch");
                        return; 
                    }           
                })
            }
            
        } catch (exception) {
            printConsole('exception occured in sendSignalConfirmSwapCamera');
            printConsole(exception);
        }
    }

    handleError(error) {
        if (error) {
            //printConsole(error.code);
            if (error.message !== "The publisher was unpublished before it could be published" && error.name !== "OT_CANCEL") {
                alert(error.message);
                printConsole(error.message);
            }
        }
    }
    
    recalcLayout = (heightDim, widthDim, bExtra, bFlag = false) => {
        const { assetsArray } = this.state;
        this.drawPublisherUI(heightDim, widthDim, bExtra, bFlag);
        const [closeIcon] = document.getElementsByClassName('asset-3d');
        if (assetsArray.length > 0 && closeIcon) {
            const [paramElem] = document.getElementsByClassName('OT_publisher');
            let isExpertDiv;
            if (this.state.publishVideo) isExpertDiv = false;
            else isExpertDiv= true;
            this.handleArtifactsNew({ assetsArray : this.state.assetsArray, paramElem, isExpertDiv }); 
        }
        else if (assetsArray.length > 0) {
            this.setState({ showFileViewer: false }, () => {
                this.setState({ showFileViewer: true });
            })
        }
    }  
    
    
    drawPublisherUI = (heightDim, widthDim, bExtra, flg = false) => {
        // Set the size of the container
        //printConsole(`Window Height ${heightDim} & Window Width ${widthDim}`);
        let Height = Math.floor(heightDim - heightDim*(borderYsize/100) - 6); 
        let Width = Math.floor(widthDim); 
        const sideBarWidth = 70;
        let containerBox = document.getElementById(subscriberContainerDivId); //flex-container
        if (containerBox === null) {
            printConsole('no div layout for videos yet!');
            return;
        }
        containerBox.style.position = "relative";//side bar related changes
        //containerBox.style.left = '0px';
        //containerBox.style.top = headerSize + 'px';
        //containerBox.style.width = Math.floor((heightDim * 16) / 9) + "px";
        //containerBox.style.height = Math.floor(heightDim - (borderYsize)) + "px";
        containerBox.style.width = Width + "px";
        containerBox.style.height = Height + "px";
        containerBox.style.backgroundColor = '#1c2238';

        let pubContainerElem = null;
        // draw the shared screen if expert is publishing
        if (document.getElementById('publisherContainer') !== null && window.getComputedStyle(document.getElementById('publisherContainer')).display === 'none') {
            pubContainerElem = document.getElementById('expertscreenshare');
        } else {
            pubContainerElem = document.getElementById('publisherContainer');
        }

        if (pubContainerElem !== null) {
            let yDim = Math.floor(Height - (Height*(borderYsize/100)));
            let xDim = Math.floor((yDim * 16) / 9);
            let xGap = Math.floor((Width - xDim - sideBarWidth) / 4);
            let yGap = 0;

            if (Width > 1300 && Width <= 1400)
                xGap = Math.floor((Width - xDim - sideBarWidth) / 6);
            else if (Width > 1500 && Width <= 1600)
                xGap = Math.floor((Width - xDim - sideBarWidth) / 8);

            else if (Width <= 1300) {
                yDim = Math.floor(Height - (Height*2*(borderYsize/100)));
                xDim = Math.floor((yDim * 16) / 9);
                xGap = Math.floor((Width - xDim - sideBarWidth) / 4) ;
                if (xDim > Width) {
                    xDim = Math.floor(Width - sideBarWidth);
                    yDim = Math.floor((xDim * 9) / 16);
                    yGap = Math.floor((Height - yDim) / 3);
                    xGap = 0; 
                }
            }

            if (this.state.bMaxChat === true) {
                this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null, flg);
            } 
            // T32-473 Setting the x axis margin from the left for 
            // Video Element
            let xMargin = Math.floor((Width - xDim - sideBarWidth) / 2);
            if (xDim >= Math.floor(Width - sideBarWidth)) xMargin = 0;
            //printConsole(`yGap = ${yGap}, xGap = ${xGap}`);
            pubContainerElem.style.height = (yDim) + 'px';
            pubContainerElem.style.width = xDim + 'px';
            pubContainerElem.style.left = xMargin + 'px'; // T32-473
            pubContainerElem.style.top = yGap + 'px';
            pubContainerElem.style.position = 'absolute';
            if (this.state.bMaxChat === true || this.state.bAllChat === true) {
                //pubContainerElem.style.left = xGap + 'px';
                //pubContainerElem.style.top = yGap + 'px';
            } else {
                /* pubContainerElem.style.left = '0px';
                pubContainerElem.style.top = '0px'; */
            }
            if (this.state.bMaxChat !== true) {
                this.setState({ chatLeft: '0px' });
                this.setState({ chatTop: '0px' });
                this.setState({ chatWidth: '0px' });
                this.setState({ chatHeight: '0px' });
            }

            //			alert(`Elem.style.height = ${yDim}, width = ${xDim}, left = ${xGap}, top = ${yGap}`);


            /* if (this.state.bMaxChat === true || this.state.bAllChat === true) {
                pubContainerElem.style.left = '10' + 'px';
                this.setState({ chatLeft: (xDim + 20) + 'px' });
                this.setState({ chatTop: (yGap) + 'px' });
                this.setState({ chatWidth: ((xGap - 100) * 2 + 'px') });
                this.setState({ chatHeight: (yDim + 'px') });
            } */

            // NS2-98
            //if (flg === true) pubContainerElem.style.display = 'none';

            if (document.getElementById('maxdivcontrols') !== null && flg === false) {
                //    	document.getElementById('maxdivcontrols').style.display = 'block';
                pubContainerElem.appendChild(document.getElementById('maxdivcontrols'));
                document.getElementById('maxdivcontrols').style.left = '46%'; // NS2-173
            } // NS2-98
            else if (document.getElementById('maxdivcontrols') !== null && flg === true) {
                document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('maxdivcontrols'));
            }
            // NS2-173
            // This if condition is to take care of the Max-div control buttons when screen share is ongoing
            if (document.getElementById('maxdivcontrols') !== null && this.state.expertShareScreenMaxDiv === true) {
                document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('maxdivcontrols'));
                document.getElementById('maxdivcontrols').style.left = '46%';
                /* if (this.state.bMaxChat === true || this.state.bAllChat === true) {
                    document.getElementById('maxdivcontrols').style.left = '35%';
                } */
            }
        }
        // T32-473 setting the position of the session timer
        // relative to the Video Element
        let sessionTimer = document.getElementById('sessionTimer');
        if (sessionTimer !== null) {
            pubContainerElem.appendChild(sessionTimer); // to center the timestamp w.r.t the Video Element
        }
    }    

    drawChatWindow = (heightDim, widthDim, bExtra) => {
        //const {g_subInfoArr} = this.state;
        let Height = heightDim; //window.innerHeight;
        let Width = widthDim; //window.innerWidth;
        let gapX = 0; // default
        let gapY = 0;
        let nGaps = 3; 

        let maxHeight = 0;
        let maxWidth = 0;

        if (widthDim <= 1200 && g_subInfoArr.length > 1) {
            maxHeight = Math.floor((Height - (2 * borderYsize)) * 2 / 3);
            maxWidth = Math.floor((maxHeight * 16) / 9);
            gapY = (Height - maxHeight) / 2;//borderYsize;
        }
        else {
            maxHeight = Math.floor(heightDim - (2*borderYsize));
            maxWidth = Math.floor((maxHeight * 16) / 9);
            gapY = borderYsize;
        }

        let count = 1;
        // the small divs
        let smallWidth = Math.floor(maxWidth / 3);
        let smallHeight = Math.floor((smallWidth * 9) / 16);

        // the gaps
        if (count > 3) nGaps += 1; // for the scrollbar
        gapX = (Width - maxWidth - smallWidth) / nGaps;
        gapX = Math.floor(gapX);
        let countSmall = Math.floor(Height / (smallHeight + (2 * borderYsize)));

        smallWidth = Math.floor(smallWidth);
        smallHeight = Math.floor(smallHeight);
        gapY = Math.floor(gapY);
        this.setState({ chatLeft: (0) + 'px' });
        this.setState({ chatTop: (0) + 'px' });
        this.setState({ chatWidth: (smallWidth + (1 * gapX)) + 'px' });
        this.setState({ chatHeight: maxHeight + 'px' });
        document.getElementsByClassName("textareaIM")[0].focus(); // MB2-482
    } 
    
    componentWillUnmount = () => {
        const { user, g_session } = this.props;
        window.removeEventListener("resize", this.updateDimensions, false);
        if (g_new_camera_publisher !== null && g_session !== null) {
            if (g_new_camera_publisher !== null && g_new_camera_publisher !== undefined) {
                g_session.unpublish(g_new_camera_publisher);
                g_new_camera_publisher.destroy();
                g_new_camera_publisher = null;
            }
        }
        this.props.onCloseTechnician(false);
        /* this.state.URLs.forEach(url => {
            if (url) window.URL.revokeObjectURL(url);
        }); */        
        /** Unsubscribe / remove the event listeners while component unmounts */
        // MB2-106
        this.sessionSignal.removeWebsocket(this.subscribeToGroupSignal);
        this.sessionSignal.removeUserinsession(this.subscribeToUserIsInSession);//TP-2832       
    }

    updateDimensions = () => {
        //printConsole('updateDimensions called with ', window.innerHeight, window.innerWidth);
        this.recalcLayout(document.documentElement.clientHeight, window.innerWidth, null);
    }

    subscribeToGroupSignal = (json) => {
        let { selectedGroupId } = this.props;
        if (typeof selectedGroupId === "string") selectedGroupId = parseInt(selectedGroupId);
        if (json.groupId === selectedGroupId) {
            //TP-2474
            //this.timer = setTimeout(()=> {
                printConsole("*************Receiving Signal on Technician side :: SessionOT JS**********");
                printConsole(json);
                switch (json.type) {
                    case "IM":
                        this.processSignalIM(json);
                        break;
                    case "MAX_VIEW":
                        this.processSignalMaxView(json);
                        break;
                    case "CURRENT_PRIMARY_EXPERT": //TP-359
                        this.setPrimaryExpertForTech(json);
                        break;
                    case "ANNOTATE":
                        this.processSignalAnnotate(json);
                        break;
                    case "ANNOTATE_MULTI_PART":
                        this.processSignalAnnotateMultiPart(json);
                        break;
                    case "REMOTECLICK":                    
                        this.processSignalRemoteClick(json);                                           
                        break;
                    case "REMOTECAPTUREANNOTATE":
                        this.processSignalRCAnnotate(json);
                        break;
                    /* case "STOPREMOTECAPTUREANNOTATE":
                        this.processSignalStopRemoteCaptureAnnotate(json);
                        break; */
                    case "MUTE_EXPERT_AUDIO":
                        this.processSignalMuteExpertAudio(json);
                        break;
                    /* case "OPACITY":
                        this.processSignalOpacity(json);
                        break; */
                    case "ROTATION":
                        this.processSignalRotation(json);
                        break;
                    case "SHARE_ARTIFACTS":
                        this.processSignalShareAssets(json);
                        break;
                    case "ARTIFACTS":
                        this.processSignalArtifacts(json);
                        break;
                    case "REMOVE_ARTIFACTS":
                        this.processSignalRemoveArtifacts(json);
                        break;
                    case "START_EXPERT_SS":
                        this.processSignalStartExpertSS(json);
                        break;
                    case "STOP_EXPERT_SS":
                        this.processSignalStopExpertSS(json);
                        break;
                    case "START_TECHNICIAN_SS":
                        this.processSignalStartTechnicianSS(json);
                        break;
                    case "STOP_TECHNICIAN_SS":
                        this.processSignalStopTechnicianSS(json);
                        break;
                    case "SWAP_CAMERA_RECEIVED":
                        this.processSignalSwapCameraReceived(json);                
                        break;
                    case "TURN_ON_USER_VIDEO":
                        this.processSignalTurnOnUserVideo(json);    
                        break;
                    case "STOP_SESSION_USER":
                        this.processSignalStopSessionUser(json);                
                        break;
                    default:
                        break;
                }
                /* clearTimeout(this.timer);
            }, fileTransferTimeout); */ 
        } else {
            printConsole("Ignored groupId..");
        }
    }

    //TP-2832 -- start listening to user-is-in-session signal to keep the usersInSession array updated
    subscribeToUserIsInSession = ({ isInSession, userName, caller }) => {
        //console.log("user-is-in-session-inside-sessions", isInSession, userName, caller);
        let { usersInSession } = this.props;//TP-1599
        
        if(isInSession) {
            if (userName) usersInSession.push(userName);   
            this.props.setUsersInSession(usersInSession); //TP-1599                    
        } else { 
            printConsole("User went out of the call");
            if (userName) usersInSession = usersInSession.filter(e => ![userName].includes(e)); 
            this.props.setUsersInSession(usersInSession); //TP-1599            
        }
    }

    // These methods are triggered when the different websocket signals are received 
    // based on the signal type the methods are named

    processSignalIM = (json) => {
        // Process the json.data property, if there is any data.
        let strNewMsg = json.data;
        let messageID = json.messageID; //MB2-95        
        let bIsDuplicate = false;

        this.state.chatMsgInfoArr.forEach(msgInfo => {
            /* if (strNewMsg === msgInfo.message)
                bIsDuplicate = true;
            else */ if (messageID === msgInfo.messageID) // MB2-95
                bIsDuplicate = true;
        })
        let senderInfo = json.from;
        //const senderInfoArr = typeof senderInfo === "string"?senderInfo.split(':::'):"";
        if (bIsDuplicate === false && senderInfo.isexpert === true) {
            //TP-2222 -- Handled this use case only for Android/iOS devices
            if (this.state.hideButton === "d-none") {
                //let chatMsg = json.from.firstName + " " + json.from.lastName + " says: \n" +  strNewMsg;
                let chatMsg = "{name} says: {text}";
                let values = {name: json.from.firstName + " " + json.from.lastName, text: strNewMsg};
                this.setState({ flashMsgText: chatMsg, flashValues: values, showFlashMessage: true, flashLeft: "", flashTop: "" });

            } else {
                let timeStamp = otTechObj.props.getTimeStamp();
                let chatMsgInfo = {
                    msgId: 'publisherContainer', messageID: messageID, firstName: senderInfo.firstName,
                    lastName: senderInfo.lastName, message: strNewMsg, timeStamp: timeStamp, msgType: 0
                };
    
                this.setState((prevState) => ({chatMsgInfoArr: [...prevState.chatMsgInfoArr, chatMsgInfo]}))
                this.setState({ bMaxChat: true });            
                this.drawChatWindow(document.documentElement.clientHeight, window.innerWidth, null);
            }
            return;
        }
    }

    // when tech is in max view he/she will get this button
    processSignalMaxView = (json) => {
        //printConsole('signal for max view')
        const {g_session, g_pubConnsArr} = this.props;
        const current_state = json.data;
        const current_expert = json.from.email;
        const curr_exp_name = json.from.firstName + " " + json.from.lastName;
        let sendRCUploadedFlag = false;
        if (this.state.maxView === current_state && this.state.maxViewExpert !== current_expert && this.state.bRemoteCapture) {
            sendRCUploadedFlag = true;
        }
        this.setState({ maxView: current_state, maxViewExpert: current_expert, maxViewExpertName: curr_exp_name });// related  to NS2-296
        //TP-1329 Rs:Added to assetsArray length condition to check if share assets array has data then hide remote capture icon for technician
        if (current_state && (this.state.expertShareScreenMaxDiv || this.state.techShareScreen ||  this.state.assetsArray.length > 0)) {
            this.setState({ showRc: false });
        } else
            this.setState((prevState) => ({ showRc: current_state }));

        // NS2-119
        let data = "tech_ss:::expert_ss"; // NS2-210
        // If the technician is in the Max view of the Expert
        // and is on a safari browser/Android device then remove tech_ss
        // into the signal payload else dont
        // for the signal is True  
        const ua = navigator.userAgent.toLowerCase();
        //printConsole(ua);

        if (current_state && /chrome/.test(ua)) {
            if (/android/.test(ua)) {
                data = "expert_ss"; // NS2-210
            }
        } else if (current_state && /safari/.test(ua)) {
            data = "expert_ss"; // NS2-210
        }
        if (this.state.cameraCount !== "" && this.state.cameraCount !== null)
            data = data + ":::camera_count-"+this.state.cameraCount;
        data = data + ":::type-WEB";
        // This condition was added for NS2-202
        if (current_state === true) {
            g_pubConnsArr.forEach(connectionObj => {
                /** Payload info: "tech_ss:::expert_ss:::zoom:::torch:::camera_count-2:::type-WEB"
                 * tech_ss for when technician Screen share supported by technician
                 * expert_ss for when expert Screen share can be subscribed by technician 
                 * zoom for when increasing/decreasing zoom levels is supported by the technician
                 * torch for when turning the remote torch on/off on that technician is supported
                 * camera_count will be the current no of cameras connected to this technician
                 */
                // condition added into the if statement, to send the signal to only 
                // the sender of the MAX_VIEW signal
                // for NS2-119
                const toArray = connectionObj.data.split(":::");
                if (g_session.connection !== connectionObj && toArray[3] === json.from.email) {
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalMaxDivShowButtons(
                        data,
                        toObject
                        //type: "MAX_DIV_SHOW_BUTTONS" // NS2-210
                    )
                    //printConsole('signal sent = ', 'MAX_DIV_SHOW_BUTTONS'); // NS2-210
                }
            });
        }
        if (sendRCUploadedFlag === true) {
            /* g_pubConnsArr.forEach(connectionObj => {
                // condition added into the if statement, to send the signal to only that
                // expert who has sent the REMOTECLICK signal to the technician
                // for NS2-130
                const toArray = connectionObj.data.split(":::");
                if (g_session.connection !== connectionObj && toArray[3] === json.from.email) {
                    const toObject = toArray[3];
                    this.sessionSignal.sendSignalRCUploaded(
                        this.state.rcImgObj,
                        toObject,
                        fileFormat
                        //type:"REMOTECLICKUPLOADED"
                    )
                    //printConsole('signal sent = ', 'REMOTECLICKUPLOADED');
                }
            }); */
        }
    }

    // TP-359 -- When the technician receives the Current Primary expert's Identity
    // Updates the same
    setPrimaryExpertForTech = (json) => {
        const current_expert = json.from.email;
        const curr_exp_name = json.from.firstName + " " + json.from.lastName;
        if (current_expert && this.state.maxViewExpert && this.state.maxViewExpert !== current_expert) {
            printConsole(`Primary expert ID has been changed for technician`);
            printConsole(current_expert);            
            this.setState({ maxViewExpert: current_expert, maxViewExpertName: curr_exp_name });
        }
    }

    processSignalMuteExpertAudio = (json) => {
        const data = JSON.parse(json.data);
        const {g_subInfoArr} = this.props;
        //printConsole(g_subInfoArr);
        if (g_subInfoArr.length >= 1) {
            g_subInfoArr.forEach(subInfo => {
                const toArray = subInfo.g_stream.connection.data.split(":::");
                if (toArray[3] === json.from.email && subInfo.g_stream.videoType !== "screen") {
                    subInfo.g_subscriber.subscribeToAudio(data);
                }
            })
        } /* else {
            subscriberInfo && subscriberInfo.subscribeToAudio(data);
        } */
    }

    processSignalAnnotate = (json) => {
        //printConsole('signal:ANNOTATE json data: ', json, json.data);
        const dimData = json.data;
        const expertDims = typeof dimData === "string" && dimData.includes(":::") === false ? dimData.split('px') : "";
        const pointerDims = typeof dimData === "string" && dimData.includes(":::") === true ? dimData.split(":::") : ""; //TP-2523
        if (expertDims.length > 1) {
            this.setState({ expertDimX: parseInt(expertDims[0]) });
            this.setState({ expertDimY: parseInt(expertDims[1]) });
            //printConsole(dimData, this.state.expertDimX, this.state.expertDimY);
        } /**TP-2523 */ else if (typeof dimData !== "string") {
            strCanvasJSON = json.data;
            let PublisherElemList = document.getElementsByClassName('OT_publisher');
            /* if (this.state.expertShareScreenMaxDiv === true) 
            PublisherElemList = document.getElementsByClassName('OT_subscriber');     */        
            displayAnnotation(PublisherElemList[0], this.state.rcImgObj);
            
        } /**TP-2523 */ else if (pointerDims.length > 1) {
            this.setState({ 
                expertDimX: parseInt(pointerDims[0]),
                expertDimY: parseInt(pointerDims[1]),
                pointerDimX: parseInt(pointerDims[2]),
                pointerDimY: parseInt(pointerDims[3])
            }, () => {
                let PublisherElemList = document.getElementsByClassName('OT_publisher');
                displayPointerTrack(PublisherElemList[0], pointerDims[4], this.state.rcImgObj); //TP-2523
            });
        } else {
            strCanvasJSON = json.data;
            let PublisherElemList = document.getElementsByClassName('OT_publisher');
            //Clear the annotation if any other payload is received or payload is null
            displayAnnotation(PublisherElemList[0], this.state.rcImgObj);
        }
    }

    processSignalAnnotateMultiPart = (json) => {
        try {

            if (json.data.includes("px") === true && json.data.includes(":::") === false) {
                //printConsole("signal:ANNOTATE_MULTI_PART json data: ", json.data);
                const expertDims = json.data.split('px');
                this.setState({ expertDimX: parseInt(expertDims[0]) });
                this.setState({ expertDimY: parseInt(expertDims[1]) });
                dataList = [];
                strCanvasJSON = '';
            } else if (json.data.includes(":::") === true && json.data.includes("px") === false) {
                const dimData = json.data.split(":::");
                const maxSize = parseInt(dimData[1]);
                let currentIndex = parseInt(dimData[0]);
                if (dataList.length < maxSize && typeof dataList[currentIndex] === 'undefined') //MB2-99
                    dataList = insertAt(dataList, currentIndex, dimData[2]);
                if (dataList.length === maxSize) {
                    strCanvasJSON = dataList.join('');//NS2-501
                    //printConsole(strCanvasJSON);
                    dataList = [];
                    let PublisherElemList = document.getElementsByClassName('OT_publisher');
                    /* if (this.state.expertShareScreenMaxDiv === true) 
                        PublisherElemList = document.getElementsByClassName('OT_subscriber'); */ 
                    displayAnnotation(PublisherElemList[0], this.state.rcImgObj);
                }
            }
        } catch (exception) {
            printConsole('exception occured in technician Annotation Multipart listener');
            printConsole(exception);
        }
    }

    processSignalRemoteClick = async (json) => {
        try {
            // upload the image as a file to s3
            this.setState({ bRemoteCapture: true });
            this.setState({ isLoading: true });
            const {g_session, g_publisher, g_pubConnsArr} = this.props;
            const imgData = (this.state.cameraSwitched) ? g_new_camera_publisher.getImgData() : g_publisher.getImgData();
            const fileFormat = 'image/png';
            //const today = Math.floor(Date.now() / 1000);
            //let sFileName = `remote-capture-${today}.png`;
            //this.setState({ sFileName: sFileName });
            //let fileFormat = '[{"bucket": "telepresenz-20-p2pfiles","key": "remote-capture-1598291244.png,"location": "https://telepresenz-20-p2pfiles.s3.amazonaws.com/boo/IPhone 4Gs _5.mtl","result": {},"type": "AWS"}]';
            //const file = await urltoFile("data:image/png;base64," + imgData, sFileName, 'image/png');
            //const uploadedFileInfo = await uploadFile(file, this.config);

            // get the frame image from the publisher stream
            this.setState({ rcImgObj: imgData });
            // for NS2-141

            /*                        diagonastics(this.authService,{
                                        action: `Remote click signal recieved`,
                                        next_step: '',
                                        data: '',
                                        error: 'none'
                                    })*/
            // inform expert that the RC image is now ready to be downloaded
            //if (this.state.maxViewExpert === json.from.email) {
                g_pubConnsArr.forEach(connectionObj => {
                    // condition added into the if statement, to send the signal to only that
                    // expert who has sent the REMOTECLICK signal to the technician
                    // for NS2-130
                    const toArray = connectionObj.data.split(":::");
                    if (g_session.connection !== connectionObj && toArray[3] === json.from.email) {
                        const toObject = toArray[3];
                        this.sessionSignal.sendSignalRCUploaded(
                            imgData,
                            toObject,
                            fileFormat
                            //type:"REMOTECLICKDATA"
                        )
                        //printConsole('signal sent = ', 'REMOTECLICKDATA');
                    }
                });

                // turn on annotation on publisher
                strCanvasJSON = "";
                let PublisherElemList = document.getElementsByClassName('OT_publisher');
                displayAnnotation(PublisherElemList[0], this.state.rcImgObj); 
            //}
            this.setState({ isLoading: false });
        }
        catch (e) {
            printConsole(e);
        }
    }

    processSignalRCAnnotate = (json) => {
        const data = json.data;
        if (data === false) {
            this.processSignalStopRemoteCaptureAnnotate();
        } else {
            strCanvasJSON = "";
            let PublisherElemList = document.getElementsByClassName('OT_publisher');
            displayAnnotation(PublisherElemList[0], this.state.rcImgObj);
        }
    }

    processSignalStopRemoteCaptureAnnotate = () => {
        //printConsole(this.state.rcImgObj);
        //printConsole(this.state.bRemoteCapture);
        if (this.state.rcImgObj !== '') {
            //URL.revokeObjectURL(this.state.rcUrlObj);
            this.setState({ rcImgObj: '', bRemoteCapture: false });// need to reset bRemotecapture for tech

            strCanvasJSON = "";
            let PublisherElemList = document.getElementsByClassName('OT_publisher');
            displayAnnotation(PublisherElemList[0], null); 
        } else /* if (this.state.bRemoteCapture === true) */ {
            this.setState({ bRemoteCapture: false });// need to reset bRemotecapture for tech

            strCanvasJSON = "";
            let PublisherElemList = document.getElementsByClassName('OT_publisher');
            displayAnnotation(PublisherElemList[0], null);
        }
    }

    processSignalRotation = (json) => {
        const data = JSON.parse(json.data);
        if (this.state.expertShareScreenMaxDiv === false) {
            this.setState({ rotationArray: data });
            const { _x, _y, _z, _w } = data.quaternion;
            const eulerRotation = new THREE.Quaternion().copy({ x: _x, y: _y, z: _z, w: _w });
            this.active3dModel && this.active3dModel.quaternion.copy(eulerRotation);
        }
    }

    // TP-841 -- New signal sent to set the opacity of the Technician's File Viewer
    processSignalOpacity = (json) => {
        const data = JSON.parse(json.data);
        if (this.state.expertShareScreenMaxDiv === false) {
            this.setState({ fileOpacity: data });
        }
    }

    processSignalArtifacts = (json) => {
        const data = JSON.parse(json.data);
        /*                    diagonastics(this.authService,{
                                action: `artifact signal recieved`,
                                next_step: '',
                                data,
                                error: 'none'
                            })*/
        const [paramElem] = document.getElementsByClassName('OT_publisher');
        if (this.state.expertShareScreenMaxDiv === false)
            this.handleArtifactsOnSubscribers({ data, paramElem, isExpertDiv: false });
    }

    // New signal for Share assets method
    processSignalShareAssets = (json) => {
        const { assetData } = json;
        // New implementation --
        if (this.state.expertShareScreenMaxDiv === true) return; 
        this.setState({ showRc: false });
        const [paramElem] = document.getElementsByClassName('OT_publisher');
        if (parseInt(assetData.total) > 0) {
            assetsArray.push(assetData);
        } if (/* parseInt(assetData.sequence) === parseInt(assetData.total)-1 || */ assetsArray.length === parseInt(assetData.total)) {
            if (!this.closeSubscriber3dObject) this.remove3dObject();  
            this.setState({ assetsArray });  
            let isExpertDiv;
            if (this.state.publishVideo) isExpertDiv = false;
            else isExpertDiv= true;           
            this.handleArtifactsNew({ assetsArray, paramElem, isExpertDiv});            
            assetsArray = [];
        } 
    }

    processSignalRemoveArtifacts = (json) => {
        //const data = JSON.parse(json.data);
        if (this.state.expertShareScreenMaxDiv === false) {
            if (this.closeSubscriber3dObject) {
                this.closeSubscriber3dObject();
                this.remove3dObject(); //TP-751
            } else {
                this.remove3dObject();
            }
            if (this.state.maxView === true)
                this.setState({ showRc: true });
            this.setState({ assetsArray: [], rotationArray: '' });
        }
    }

    // expert screen share start signal processor method
    processSignalStartExpertSS = (json) => {
        // check if already in SS mode
        if (this.state.expertShareScreenMaxDiv)
            return;
        this.setState({ expertShareScreenMaxDiv: true, showRc: false });
        this.props.updateScreenShareState(true);
        // hide the self-published Div from the container
        document.getElementById('publisherContainer').style.display = "none";

        const streamName = this.props.sessionInfo.firstname + ' ' + this.props.sessionInfo.lastname;
        let screenShareEle = document.getElementById('expertscreenshare');
        //set the name div of the camera feed element
        let nameDiv = document.createElement('div');
        nameDiv.classList.add('position-absolute', 'w-100', 'nameDiv');
        nameDiv.style.color = 'white'; 
        //nameDiv.style.zIndex = '10';
        nameDiv.style.top = "2%";
        nameDiv.style.left = "0";
        nameDiv.innerHTML = streamName;
        screenShareEle.className = "text-center";
        screenShareEle.appendChild(nameDiv);
        // show the incoming expert screen share Div to the container
        screenShareEle.style.display = "block";
        // This if condition is to take care of the Max-div control buttons when screen share is ongoing
        if (document.getElementById('sessionTimer') !== null)
            document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('sessionTimer')); //T32-473
        if (document.getElementById('maxdivcontrols') !== null) {
            document.getElementById(subscriberContainerDivId).appendChild(document.getElementById('maxdivcontrols'));            
        }
        this.drawPublisherUI(document.documentElement.clientHeight, document.documentElement.clientWidth, null, true);
    }

    // expert screen share stop signal processor method
    processSignalStopExpertSS = (json) => {
        if (!this.state.expertShareScreenMaxDiv)
            return;
        this.setState({ expertShareScreenMaxDiv: false });
        if (this.state.maxView === true) //NS2-296
            this.setState({ showRc: true });
        if (this.state.publishVideo === true) { 
            //TP-1547 -- Starting up the Technician Video publishing if Screen cast is stopped
            //after the Technician video was turned back on
            this.props.updatePublishVideo(true);
            g_new_camera_publisher !== null && g_new_camera_publisher.publishVideo(true);
        }
            
        // hide the incoming expert screen share Div to the container
        window.onload = function() {
            document.getElementById('expertscreenshare').style.display = "none";
        }
        // show the self-published Div from the container
        if (document.getElementById('publisherContainer') !== null)
            document.getElementById('publisherContainer').style.display = "block";
        // for NS2-98
        this.drawPublisherUI(document.documentElement.clientHeight, document.documentElement.clientWidth, null, false);
    }

    // technician screen share signals
    processSignalStartTechnicianSS = (json) => {
        // check if already in SS mode
        if (this.state.techShareScreen === true)
            return;
        // do not bump out even after stream_destroyed
        this.props.updateRCStatus(true);
        this.setState({ showRc: false });
        // technician side flag set
        this.setState({ techShareScreen: true });
        // change the video source to be screen
        this.rePublishTechnicianStream(true, json.from.email);
    }

    // technician screen share signals
    processSignalStopTechnicianSS = (json) => {
        // check if already out of SS mode
        if (this.state.techShareScreen === false)
            return;
        // do not bump out even after stream_destroyed
        this.props.updateRCStatus(true);
        this.setState({ showRc: true });
        // change the video source to be camera
        this.rePublishTechnicianStream(false, json.from.email);
        // technician side flag reset
        this.setState({ techShareScreen: false });
    }

    processSignalSwapCameraReceived = (json) => {
        const { eventType } = json;
        //printConsole(eventType);
        //printConsole("is ongoing session Features? ", this.isOngoingSessionFeatures());
        if (this.isOngoingSessionFeatures() === true && eventType === "click"){
            this.setState({ showCameraSelectionDecisionBox: true });
        } else if (eventType === "auto") {
            //printConsole("starting re-publishing!!")
            this.rePublishTechnicianCameraStream();
            this.sendSignalConfirmSwapCamera(true, eventType); 
        } else {
            this.rePublishTechnicianCameraStream();
        }
    }

    // When the turn on/off Technician Video signal is received
    processSignalTurnOnUserVideo = (json) => {
        printConsole(json);
        const {data} = json;
        this.setState({publishVideo: data});       
        this.props.updatePublishVideo(data);
        if(g_new_camera_publisher !== null)
            g_new_camera_publisher.publishVideo(data);
    }

    // handler for STOP_SESSION_USER - FQ3-30
    processSignalStopSessionUser = (json) => {
        const {user} = this.props;
        if (user.email === json.to) {
            this.triggerMessage(json.from.firstName+" "+json.from.lastName, "stopsession");
            this.timeOut = setTimeout(() => {
                this.props.onSessionCmdChange('session_stopped');
                clearTimeout(this.timeOut);
            }, 2000);
        }
    }

    // Handler methods related to shared assets
    handleArtifactsOnSubscribers = ({ data, paramElem, isExpertDiv }) => {
        const closeIconText = this.props.i18n._('Click here to remove the 3d object')
        try {
            const [objFile] = data.filter(e => e.key.endsWith('.obj') || e.key.endsWith('.3ds') || e.key.endsWith('.3DS') || e.key.endsWith('.FBX') || e.key.endsWith('.fbx'));
            const [mtlFile = {}] = data.filter(e => e.key.endsWith('.mtl'));
            const textureFiles = data.filter(e => e.key.endsWith('.jpg') || e.key.endsWith('.png') || e.key.endsWith('.BMP') || e.key.endsWith('.bmp') | e.key.endsWith('.exr') || e.key.endsWith('.tga'));

            if (objFile) {
                this.setState({ isLoading: true });
                if (!paramElem) return;
                let paramElemVdoChild;
                if (isExpertDiv) {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-poster');
                } else {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-element');
                }

                if (!paramElemVdoChild) return;

                const { sceneManager } = threeEntryPoint({
                    videoElement: paramElemVdoChild,
                    id: 'canavas-3d-container',
                    fnSend3dRotation: () => { },
                    fnClose3dCanvas: () => { },
                    closeIconText
                });
                const fileType = objFile.key.split('.').pop();
                sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlFile.location, objFilePath: objFile.location, textureFilePath: map(textureFiles, 'location') })
                    .then(e => {
                        this.setState({ isLoading: false });
                        this.active3dModel = e;                        
                    })
                    .catch(err => {
                        this.setState({ isLoading: false });
                        console.warn(err)
                    });
                this.closeSubscriber3dObject = sceneManager.removeCanvas;
            } else {
                const [file] = data;
                this.closeSubscriber3dObject = null;
                const splitedArray = file.key.split('.');
                this.setState({
                    showFileViewer: true,
                    fileType: splitedArray[splitedArray.length - 1],
                    filePath: file.location,
                    fileName: file.key.replace('boo/', ''),
                    //expertLoggedInId: file.loggedInUserId
                });
            }
        } catch (error) {
            let msg = 'Something went wrong while uploading file.';
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false, error: msg });
        }
    }

    // New Handler method for shared assets with S3
    handleArtifactsNew = async ({ assetsArray, paramElem, isExpertDiv }) => {
        //console.log(`{assetsArray : ${JSON.stringify(assetsArray)}, paramElem: ${JSON.stringify(paramElem)}, isExpertDiv: ${isExpertDiv}}`)        
        const closeIconText = this.props.i18n._('Click here to remove the 3d object')
        try {
            const [objFile] = assetsArray.filter(e => e.format === 'obj' || e.format === '3ds' || e.format === '3DS'|| e.format === 'FBX' || e.format === 'fbx');
            const [mtlFile = {}] = assetsArray.filter(e => e.format === 'mtl');
            const [pdfFile] = assetsArray.filter(e => e.format === 'pdf');
            const textureFiles = assetsArray.filter(e => e.format === 'jpg' || e.format === 'png' || e.format === 'BMP' || e.format === 'bmp' | e.format === 'exr' || e.format === 'tga');
    
            if (objFile) {
                //console.log(objFile.data.indexOf("data:"));
                if (!paramElem) return;
                let paramElemVdoChild;
                if (isExpertDiv) {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-poster');
                } else {
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-element');
                }

                if (!paramElemVdoChild) return;
                if (objFile.data.indexOf("data:") === -1) {
                    objFile.data = "data:"+objFile.type+";base64,"+objFile.data;
                }
                if (mtlFile !== undefined) {
                    mtlFile.data = (mtlFile.data !== undefined) ? "data:"+mtlFile.type+";base64,"+mtlFile.data : undefined;
                }
                if (textureFiles.length > 0) {
                    textureFiles.forEach(element => {
                        element.data = "data:"+element.type+";base64,"+element.data;
                    });
                }
                const { sceneManager } = threeEntryPoint({
                    videoElement: paramElemVdoChild,
                    id: 'canavas-3d-container',
                    fnSend3dRotation: () => { },
                    fnClose3dCanvas: () => { },
                    closeIconText
                });
                const fileType = objFile.format;
                sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlFile.data, objFilePath: objFile.data, textureFilePath: map(textureFiles, 'data') })
                    .then(e => {
                        this.setState({ isLoading: false });
                        this.active3dModel = e;
                        if (this.state.rotationArray !== '') {
                            printConsole("rotating the rendered 3d Model");
                            const { _x, _y, _z, _w } = this.state.rotationArray.quaternion
                            const eulerRotation = new THREE.Quaternion().copy({ x: _x, y: _y, z: _z, w: _w });
                            this.active3dModel && this.active3dModel.quaternion.copy(eulerRotation);
                        }
                    })
                    .catch(err => {
                        this.setState({ isLoading: false });
                        console.warn(err)
                    });
                this.closeSubscriber3dObject = sceneManager.removeCanvas;                
            } else {
                const [file] = assetsArray;
                this.closeTechnician3dObject = null;
                const splitedArray = file.name.split('.');
                let fileUrl;
                if (pdfFile) {
                    const contentType = 'application/pdf';
                    let urlObj;
                    if (file.type === undefined) urlObj = "data:application/pdf;base64,"+file.data;
                    else urlObj = "data:"+file.type+";base64,"+file.data;
                    const newFile = await urltoFile(urlObj, file.name+'.'+file.format, contentType);
                    //console.log(newFile);
                    //const blob = new base64StringToBlob(urlObj, contentType);// MB2-556
                    fileUrl = URL.createObjectURL(newFile);
                    printConsole(fileUrl);
                }
                this.setState({
                    showFileViewer: true,
                    fileType: file.format,
                    filePath: (file.format === 'pdf') ? fileUrl : "data:"+file.type+";base64,"+file.data,
                    fileName: file.name+'.'+file.format,
                    //expertLoggedInId: file.loggedInUserId,
                    isLoading: false
                });

            }

        } catch (error) {
            let msg = 'Something went wrong while uploading file.';
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false,  error: msg}); 
        }
    }

    onCloseFileViewer = (e) => {
        if (e) e.preventDefault();
        let files = this.state.fileNameArr;
        this.state.URLs.forEach(url => {
            if (url) window.URL.revokeObjectURL(url);
        });
        this.setState(prev => ({
            showFileViewer: false,
            fileType: 'none',
            filePath: '',
            fileName: ''
            // expertLoggedInId: null
        })
        );         
    }

    remove3dObject = () => {
        this.setState({
            showFileViewer: false,
            fileType: 'none',
            filePath: '',
            fileName: ''
        });

        this.state.URLs.forEach(url => {
            if (url) window.URL.revokeObjectURL(url);
        });

        const [closeIcon, canvas] = document.getElementsByClassName('asset-3d');
        window.removeEventListener('mousemove', function () { });
        window.removeEventListener('mouseup', function () { });
        window.removeEventListener('mousedown', function () { });
        /** remove both element from dom */
        if (!canvas) return;
        closeIcon && closeIcon.parentNode && closeIcon.parentNode.removeChild(closeIcon);
        canvas.parentNode.removeChild(canvas);
    };

    setDeviceCookie = (name, val) => {
        // "username=John Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/";
        let cookieText = name+"="+val+"; path=/";
        //printConsole(cookieText);
        document.cookie = cookieText;
    }
    
    deleteCookie = (cname) => {
        var name = cname + "=";
        document.cookie = name+"; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    isOngoingSessionFeatures = () => {
        const { expertShareScreenMaxDiv, techShareScreen, bRemoteCapture, } = this.state;
        let flag = false;
        if (bRemoteCapture) flag = true;
        if (techShareScreen) flag = true;
        if (expertShareScreenMaxDiv) flag = true;//NS2-457      
        if (document.getElementById('canvas') !== null && strCanvasJSON !== null && strCanvasJSON !== "") flag = true;  
        if (document.getElementById('canavas-3d-container') !== null) flag = true;
        return flag;
    }

    confirmSwapCamera = (val) => {
        if (val === false) {
            this.setState({ videoSource: "", audioSource: "" }) 
            this.setDeviceCookie("camera", "");
            this.setDeviceCookie("microphone", "");
        }         
        this.sendSignalConfirmSwapCamera(val, "click");
        this.hideCameraSelectionDecisionBox();
    }

    hideCameraSelectionDecisionBox = () => {
        this.setState({ showCameraSelectionDecisionBox: false });
    }

    /** Camera Selection Decision box */
    cameraSelectionDecisionDialog = () => {        
        return (
            <>
                <Modal size="sm" isOpen={this.state.showCameraSelectionDecisionBox} toggle={() => this.confirmSwapCamera(false)}>
                    <ModalHeader toggle={() => this.confirmSwapCamera(false)} cssModule={{ 'modal-title': 'w-100 text-center' }}><Trans>Camera is swapped!</Trans></ModalHeader>
                    <ModalBody className="d-flex justify-content-start align-items-center row">
                        <div className="col-sm-12">
                            <Trans id="Camera Selection Decision" values={{ 'name': `${this.state.maxViewExpertName}`}}></Trans>                                
                        </div>                        
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-end">
                            <Button size="sm" className="mr-1 btn-r" onClick={() => this.confirmSwapCamera(false)}><Trans>No</Trans></Button>
                            <Button size="sm" className=" btn-g" onClick={() => this.confirmSwapCamera(true)}><Trans>Yes</Trans></Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

    //Show Flash Message related mothods
    updateShowMessage = (val) => {
        if (val === false)
            this.setState({ flashMsgText: "", showFlashMessage: val, flashLeft: "", flashTop: "" });
    }

    triggerMessage = (connData, status) => {        
        let message = "";
        let left = "";
        let top = "";
        switch (status) {
            case "connect":
                message = "New external camera has been connected";
                left = "60px";
                top = "33%";
                break;
            case "disconnect":
                message = "External camera has been disconnected";
                left = "60px";
                top = "33%";
                break;
            case "stopsession":
                //message = connData+" has disconnected this user remotely";
                message = "Remote Disconnection";
                left = "42%";
                top = "50%";
                break;
            default:
                break;
        }        
        this.setState({ flashMsgText: message, showFlashMessage: true, flashLeft: left, flashTop: top });
    }

    render = () => {        
        const { hideButton } = this.state;
        let chatStyle = { color: 'black' };
        if (this.state.bMaxChat) chatStyle = { color: 'red' };
        let cameraStyle = { color: 'black' };
        if (this.state.bRemoteCapture) cameraStyle = { color: 'red' };
        let pubDivId = 'publisherContainer';
        let styleDivMaxBtnContainer = { position: 'absolute', left: '46%', top: '87%', backgroundColor: 'transparent' };        
        let buttonSize = (window.innerWidth > 1299) ? "fa-lg" : "fa-sm";
        let maxWidth = '100px', fileLeft = '10px', filewidth = '0px', fileheight = '0px', fileStyle = {}, bodyStyle = {};
        let embedHeight, bodyHeight, bodyWidth, sliderRight;
        const sideBar = document.getElementById("sideBar");  
        const [paramElem] = document.getElementsByClassName('OT_publisher');      
        if (sideBar) {
            maxWidth = Math.floor(window.innerWidth - sideBar.offsetWidth - 20) + 'px';
            fileLeft = Math.floor(sideBar.offsetWidth - 25) + 'px';
            if (paramElem) { 
                let paramElemVdoChild;
                if (this.state.publishVideo)               
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-element');
                else
                    [paramElemVdoChild] = paramElem.getElementsByClassName('OT_video-poster');
                if (paramElemVdoChild) {
                    filewidth = Math.floor(paramElemVdoChild.offsetWidth) + 'px';
                    fileheight = Math.floor(paramElemVdoChild.offsetHeight) + 'px';
                    bodyHeight = Math.floor(paramElemVdoChild.offsetHeight - (5 * paramElemVdoChild.offsetHeight)/100);
                    bodyWidth  = Math.floor(paramElemVdoChild.offsetWidth)
                    embedHeight = Math.floor(paramElemVdoChild.offsetHeight - (8 * bodyHeight)/100);
                    let subsciberContainer = document.getElementById(subscriberContainerDivId);
                    let publisherConatiner = document.getElementById("publisherContainer");
                    if (subsciberContainer && publisherConatiner) {
                        //console.log("subscriberContainer ----> ", subsciberContainer.offsetWidth, " Video element", publisherConatiner.style.width);
                        let videoWidth = publisherConatiner.style.width.split("p")[0];
                        //console.log(videoWidth);
                        sliderRight = Math.floor((subsciberContainer.offsetWidth - videoWidth + 60)/2) + 'px';
                        //console.log("sliderRight:  ", sliderRight);
                    }
                }
            }
            if (this.state.fileType.toLowerCase() === 'pdf' && this.props.isiOS === true) {
                fileStyle = {opacity: this.state.fileOpacity.toString(), maxWidth: filewidth, left: fileLeft, top: '-2%'};
                bodyStyle = {'maxHeight':'100%', 'maxWidth': '100%', 'align': 'center', 'overflowX': 'auto'};
            }                
            else {
                fileStyle = {opacity: this.state.fileOpacity.toString(), maxWidth: filewidth, left: fileLeft, top: '-2%'};
                bodyStyle = {'height': bodyHeight+'px', 'width': bodyWidth+'px', 'maxHeight':'100%', 'maxWidth': '100%', 'align': 'center', 'overflowX': 'auto'};
            }
        }
        let fileNameTopMargin = (window.innerWidth > 1299 && window.innerWidth <= 1559) ? "13%" : (window.innerWidth > 1599) ? "10%" : "15%";
        if (this.state.fileType !== '' && this.state.fileType.toLowerCase() === 'pdf') {
            fileNameTopMargin = (window.innerWidth > 1559) ? "12%" : "15%";
        }
        if (this.props.sessionInfo.APIKey !== "") {
            return (
                <>
                { this.props.sessionInfo.swap_camera_enabled ? 
                    <OTDevices updateCameraSelected = {this.updateCameraSelected}
                            updateMicSelected = {this.updateMicSelected}
                            changeCamera = {this.state.changeCamera}
                            triggerCameraDetect={this.state.triggerCameraDetect}
                            openChangeCameraDialog={this.openChangeCameraDialog} 
                            closeChangeCameraDialog = {this.closeChangeCameraDialog}
                            updateCameraDeviceCount={this.updateCameraDeviceCount}
                            updateDefaultDevices={this.updateDefaultDevices}
                        ></OTDevices>
                    :
                    null
                }
                <div className="d-flex">
                    <WebrtcErrorMessage showWebrtcErrorMessage={this.state.showCameraErrorMessage} text={this.state.cameraErrorType} onClickClose={() => this.triggerStopSession()}/>
                    {/* side bar */}
                    <div className='flex-shrink-1' id="sideBar">
                        <WebrtcSideBar
                            {...this.props}
                            participantNames={this.props.sessionJoinees}
                            cameraCount={this.state.cameraCount} 
                            muteHeaderMikeForAll={this.onClickMuteMikeForAll}
                            changeCamera={this.state.changeCamera}
                            openChangeCameraDialog={this.openChangeCameraDialog}
                            triggerStopSession={this.state.triggerStopSession} 
                            isOnprem={false}
                            swap_camera_enabled={this.props.sessionInfo.swap_camera_enabled}
                            maxView={this.state.maxView}
                            publishVideo={this.state.publishVideo}
                            isSmallFormFactor={this.state.hideButton === "d-none" ? true : false}
                        />
                    </div>
                    <div className="w-100 p-2 row min-vh-100 no-gutters justify-content-center align-items-center" id="allVideos">    
                        <div className='flex-container' id='flex-container' style={{ backgroundColor: '#1c2238' }}>
                            <div className="position-absolute nameDiv" style={{ color: 'white', right: '8%', bottom: '1%'}}>
                                {this.props.downLoadSpeed ? <>{this.props.downLoadSpeed+' MBPS'}&darr;:</> : ''}
                                {this.props.uploadSpeed ? <>{this.props.uploadSpeed+' MBPS'}&uarr;</> : ''}
                            </div>
                            <div id="sessionTimer" className="position-absolute nameDiv" style={{ color: 'white', left: '49%', zIndex:1, bottom: '1%'}}>
                                <SessionTimer
                                    credit_limits={this.props.sessionInfo.credit_limits}
                                    startTimer={this.props.startTimer}
                                />
                            </div>
                            { this.state.showFileViewer ?
                                <div className="position-fixed w-100 file-name" style={{ color: 'white', zIndex: '1060', background: '#D3D3D300', top: fileNameTopMargin}}>
                                    {this.state.fileName}
                                </div>
                                :
                                ''
                            }
                            { this.state.showFileViewer ?
                                <div className="position-absolute" style={{ zIndex: '1060'}}>
                                    <span className={(window.innerWidth > 1299) ? "fa-stack fa-lg close-modal ": "fa-stack fa-sm close-modal "}
                                        style={{ right: sliderRight, top: fileNameTopMargin }}>
                                        <SessionIcon id="closeAsset" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="fas fa-times fa-stack-1x close-file-icon" tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="Close Asset" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onCloseFileViewer()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        {/* <a id="closeAsset" onClick={this.onCloseFileViewer}>
                                            <i className="fas fa-circle fa-stack-2x"></i>
                                            <i className="fas fa-times fa-stack-1x close-file-icon" ></i>                    
                                            <UncontrolledTooltip innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="closeAsset">                                            
                                                <Trans id="Close Asset" ></Trans>                                            
                                            </UncontrolledTooltip>
                                        </a> */}
                                    </span>
                                </div>
                                :
                                ''
                            }
                            {/* NS2-363 React is expecting this empty node */}
                            <span style={{ color: `white` }} className={`float-left pr-2 pt-1 ${this.state.showRecordingBeacon ? '' : 'd-none'}`}>
                            </span>
                            <div id='maxdivcontrols' style={styleDivMaxBtnContainer}>
                                <span className={"fa-stack "+buttonSize+" "+hideButton}>   
                                    <SessionIcon id="chat" circleClass="fas fa-circle fa-stack-2x"
                                        iconClass="far fa-comments fa-stack-1x" iconStyle={chatStyle} tooltipPlament="top"
                                        innerClass="tip-max no-pointer-events" tooltip="session.chat" tooltipStyle="Trans"
                                        onClickMethod = {() => this.onClickMaxChat()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />                                 
                                    {/* <a id="chat" onClick={() => this.onClickMaxChat()}>
                                        <i className="fas fa-circle fa-stack-2x "></i>
                                        <i className="far fa-comments fa-stack-1x" style={chatStyle}></i>
                                        <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="chat">                                            
                                            <Trans>session.chat</Trans>                                            
                                        </UncontrolledTooltip>                                        
                                    </a> */}                                        
                                </span>
                                { this.props.sessionInfo.enable_remote_capture === undefined || this.props.sessionInfo.enable_remote_capture === true ?
                                    this.state.publishVideo && this.state.showRc  &&
                                    <span className={"fa-stack maxdivcontrol-spacing "+buttonSize}>                                        
                                        <SessionIcon id="localCapture" circleClass="fas fa-circle fa-stack-2x"
                                            iconClass="fas fa-camera fa-stack-1x" iconStyle={cameraStyle} tooltipPlament="top"
                                            innerClass="tip-max no-pointer-events" tooltip="session.localCapture" tooltipStyle="Trans"
                                            onClickMethod = {() => this.onClickexpertRcSignal()} isSmallFormFactorDevice={this.state.hideButton === "d-none" ? true : false} />
                                        {/* <a id="localCapture" onClick={() => this.onClickexpertRcSignal()}>
                                            <i className="fas fa-circle fa-stack-2x "></i>
                                            <i className="fas fa-camera fa-stack-1x " style={cameraStyle}></i>
                                            <UncontrolledTooltip placement="bottom" innerClassName="tip-max" modifiers={{preventOverflow: {boundariesElement: "viewport"}}} target="localCapture">                                            
                                                <Trans>session.localCapture</Trans>                                            
                                            </UncontrolledTooltip>
                                        </a>  */}                                           
                                    </span>
                                    :
                                    ''
                                }
                            </div>
                            { (this.state.bRemoteCapture) ? this.showLoader('Uploading....') : this.showLoader('Downloading...')}
                            <Modal id="file-viewer" size="lg" isOpen={this.state.showFileViewer} style={fileStyle}>
                                {/* <ModalHeader toggle={this.onCloseFileViewer} close={closeBtn}></ModalHeader> */}
                                <ModalBody className="fileBody" style={bodyStyle}>
                                    {/* <div className="row middle-row w-100" style={(window.innerWidth > 1299) ? {height: '12%'} : {height: '10%'}}></div> */}
                                    { this.state.fileType.toLowerCase() === 'pdf' ?
                                        (this.props.isSafari || this.props.isAndroid) ?
                                            <div className="row" style={(window.innerWidth > 1299) ? {height: '100%', width: '100%'} : {height: '100%', width: ''}}>
                                                <FileViewer
                                                    ref={(f) => this.fileViewer = f}
                                                    fileType={this.state.fileType}
                                                    filePath={this.state.filePath}
                                                />
                                            </div>
                                            :
                                            <div className="row" style={{height: '100%'}}>
                                                <embed className="pg-viewer-wrapper" width={filewidth} height={embedHeight} src={this.state.filePath+"#toolbar=1&navpanes=1&scrollbar=1"} />
                                            </div>
                                        :
                                        ( /*TP-1539*/ this.state.fileType.toLowerCase() !== "none") ? 
                                            <div className="row" style={(window.innerWidth > 1299) ? {height: '100%', width: '100%'} : {height: '100%', width: ''}}>
                                                <FileViewer
                                                    ref={(f) => this.fileViewer = f}
                                                    fileType={this.state.fileType}
                                                    filePath={this.state.filePath}
                                                />
                                            </div>
                                            :
                                            ''
                                    }
                                </ModalBody>
                            </Modal>
                            { this.cameraSelectionDecisionDialog() }
                        </div>
                        <SessionChat
                            onSendChatMsg={this.sendChatMsg}
                            bPrivate={this.state.bMaxChat}
                            bShowChat={this.state.bMaxChat}
                            chatMsgInfoArr={this.state.chatMsgInfoArr}
                            elemId={pubDivId}
                        />
                        <FlashMessage 
                            flashMsgText={this.state.flashMsgText} 
                            flashValues={this.state.flashValues}
                            showMessage={this.state.showFlashMessage} 
                            flashLeft={this.state.flashLeft}
                            flashTop={this.state.flashTop}
                            updateShowMessage={this.updateShowMessage}
                        ></FlashMessage>
                    </div>
                </div>
                </>
            );
        }
    }
    
}

export default OTTechnician;