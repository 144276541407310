import React from 'react';
import PropTypes from 'prop-types';
import threeEntryPoint from "../Three/threeEntryPoint";
import map from 'lodash/map';
import FileViewer from 'react-file-viewer';
import * as THREE from 'three';
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from 'reactstrap';
import Loader from '../Loader/Loader';
import { printConsole } from '../helpers';
import { Trans } from '@lingui/macro';

const { URL } = window;

//return a promise that resolves with a File instance
async function urltoFile(url, filename, mimeType){
    mimeType = mimeType || (url.match(/^data:([^;]+);/)||'')[1];
    return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename, {type:mimeType});})
    );
}

class AssetsLoader extends React.Component {
    state = {
        isLoading: false,
        showFileViewer: false,
        fileType: 'none',
        filePath: '',
        fileName: '',
        fileOpacity: (this.props.user.isexpert) ? "0.6" : "1.0",
        URLs: []
    }
    /** File Viewer object for future reference**/
    fileViewer = null;
    /** Active loaded 3d object */
    active3dModel = null;
    /** 3d asset close method reference for future use. */
    closeTechnician3dObject = null;

    componentDidMount = async () => {
        await this.init();
        window.addEventListener("resize", async () => {
            const [closeIcon] = document.getElementsByClassName('asset-3d');
            if (closeIcon) {
                await this.init();
                //TP-2085 -- Redrawing with the rotations in Primary expert's screen, when resized
                if (this.props.rotationArray !== '' && this.props.hasMaxDivControls === true) {
                    this.subscribeToRotation(this.props.rotationArray);
                }
            } else {
                this.setState({ showFileViewer: false }, () => {
                    this.setState({ showFileViewer: true });
                })
            }
        });
        if (this.props.rotationChanges !== '') {
            this.subscribeToRotation(this.props.rotationChanges);
        }
    }

    componentWillUnmount = () => {
        this.remove3dCanvasListner();// remove the canvas outside click listener
        //this.props.updateCancelShare(false);
    }

    getSceneManager = () => {
        const { i18n, fnSend3dRotation, fnClose3dCanvas, g_maxDivId, isSafari } = this.props;
        const closeIconText = i18n._('Click here to remove the 3d object');
        const paramElem = document.getElementById(g_maxDivId);

        try{
            const { sceneManager } = threeEntryPoint({
                videoElement: paramElem,
                id: 'canavas-3d-container',
                isSafari,
                fnSend3dRotation,
                fnClose3dCanvas,
                closeIconText
            });
            return sceneManager;
        }catch(error){
            console.log('scene manager error', error)
            return null;
        }
    }

    init = async () => {
        const { user, assetRecipient, hasMaxDivControls, redraw } = this.props;
        if (user.isexpert) {
            if (hasMaxDivControls && redraw) {
                await this.handleArtifactsNew(); // New Primary expert after role change during share asset
            }
            if(hasMaxDivControls){
                await this.handleArtifactsOnPrimaryExpert();
            }else{
                await this.handleArtifactsNew();// passive expert
            }
        } else {
            if (assetRecipient !== '' && assetRecipient !== undefined) {
                if (user.email === assetRecipient){
                    await this.handleArtifactsNew();
                }
            } else {
                await this.handleArtifactsNew();
            }
        }
    }
    
    componentDidUpdate = async (prevProps, prevState) => {
        const { localAssets, externalAssets, rotationChanges, opacityChanges, removeArtifacts, assetRecipient } = this.props;
        if ((localAssets.length !== prevProps.localAssets.length && localAssets.length > 0) || externalAssets.length !== prevProps.externalAssets.length || (externalAssets && externalAssets[0] && externalAssets[0].name !== prevProps.externalAssets[0].name)) {
            await this.init();
        } else if (localAssets.length !== prevProps.localAssets.length && localAssets.length === 0) {
            this.props.updateCancelShare(false);
        }
        if (rotationChanges && (rotationChanges !== prevProps.rotationChanges)) {
            this.subscribeToRotation(rotationChanges);  
            /*if (assetRecipient !== '' && assetRecipient !== undefined && user.email === assetRecipient) {
                this.subscribeToRotation(rotationChanges);
            } else if (assetRecipient === '' || assetRecipient === undefined) {
                this.subscribeToRotation(rotationChanges);
            }*/
        } //fnSendFileOpacity={props.fnSendFileOpacity}
        if (opacityChanges && opacityChanges !== prevProps.opacityChanges) {
            // change the opacity of the File Viewer
            printConsole("Changed opacity value");
            this.subscribeToOpacity(opacityChanges);
        } else if (opacityChanges && opacityChanges !== this.state.fileOpacity) {
            // change the opacity of File Viewer
            printConsole("opacity isn't the same as the saved opacity");
            this.subscribeToOpacity(opacityChanges);
        }
        /** remove artifacts from technician/passive expert */
        if (removeArtifacts) {
            if (this.closeTechnician3dObject){
                this.closeTechnician3dObject();
            }else{
                this.props.fnClose3dCanvas();
            }
        }
    }

    /** Method to render 3d models in expert view */
    handleArtifactsOnPrimaryExpert = async () => {
        const { localAssets, externalAssets, user, bCancelShare, g_maxDivId } = this.props;
        //console.log("Here-------------------->", localAssets);
        if (!localAssets.length) return;
        
        try { 
            const [mtlFile] = localAssets.filter(e => e.name.endsWith('.mtl'));
            const [objFile] = localAssets.filter(e => e.name.endsWith('.obj') || e.name.endsWith('.3ds') ||  e.name.endsWith('.3DS') || e.name.endsWith('.FBX') || e.name.endsWith('.fbx'));
            const [pdfFile] = localAssets.filter(e => e.name.endsWith('.pdf') || e.name.endsWith('.PDF'));
        
            if (!objFile) {
                const [file] = localAssets;
                const splitedArray = file.name.split('.');
                const fileUrl = URL.createObjectURL(file);
                if (bCancelShare === true) {
                    this.setState({ isLoading: false });
                    this.props.updateCancelShare(false);
                    return;
                }
                this.setState((prev) =>({
                    showFileViewer: true,
                    fileType: splitedArray[splitedArray.length - 1].toLowerCase(), //TP-4058
                    filePath: fileUrl,
                    fileName: file.name,
                    expertLoggedInId: user.id, // Using this we can identify which expert has uploaded the file
                    URLs: [
                        ...prev.URLs,
                        fileUrl
                    ]
                }));
                this.closeTechnician3dObject = null;
                return;
            }
            const textureFiles = localAssets.filter(e => e.name.endsWith('.jpg') || e.name.endsWith('.png') || e.name.endsWith('.BMP') || e.name.endsWith('.bmp') | e.name.endsWith('.exr') || e.name.endsWith('.tga'));
            const sceneManager = this.getSceneManager();
            
            if (!sceneManager) return
            if (bCancelShare === true) {
                this.setState({ isLoading: false });
                this.props.updateCancelShare(false);
                return;
            }
        
            // create object URL from the file object
            const objectURL = URL.createObjectURL(objFile);
            const mtlURL = mtlFile ? URL.createObjectURL(mtlFile) : undefined;
            const textureURLs = textureFiles ? textureFiles.map(e => URL.createObjectURL(e)) : []; ////map(textureFiles, 'location'); 
            /** Set all state variables here */
            this.setState(prev => ({ 
                isLoading: true,
                URLs: [
                    ...prev.URLs,
                    objectURL,
                    mtlURL,
                ],
                expertLoggedInId: user.id
            }));

            const fileType = objFile.name.split('.').pop();
            this.active3dModel = await sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlURL, objFilePath: objectURL, textureFilePath: textureURLs });
            this.setState({ isLoading: false });
            this.closeTechnician3dObject = sceneManager.removeCanvas;

            // NS2-366 Initialise the event listner to close the canvas when clicked outside
            //this.trackClickOn3dCanvas();            
            
        } catch (error) {
            //this.props.updateCancelShare(false);
            let msg = 'Something went wrong while uploading file.';
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false,  error: msg}); 
        }
    }

    /** Method to render 3d models in technician/passve expert view */
    handleArtifactsforOthers = async () => {
        const { externalAssets } = this.props;
        try {
            const [objFile] = externalAssets.filter(e => e.key.endsWith('.obj') || e.key.endsWith('.3ds') || e.key.endsWith('.3DS')|| e.key.endsWith('.FBX') || e.key.endsWith('.fbx'));
            const [mtlFile = {} ] = externalAssets.filter(e => e.key.endsWith('.mtl'));
            const textureFiles = externalAssets.filter(e => e.key.endsWith('.jpg') || e.key.endsWith('.png') || e.key.endsWith('.BMP') || e.key.endsWith('.bmp') | e.key.endsWith('.exr') || e.key.endsWith('.tga'));
    
            if (objFile) {
                this.setState({ isLoading: true });
    
                const sceneManager = this.getSceneManager();

                const fileType = objFile.key.split('.').pop();
                this.active3dModel = await sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlFile.location, objFilePath: objFile.location, textureFilePath:  map(textureFiles, 'location') })
                this.setState({ isLoading: false });
                this.closeTechnician3dObject = sceneManager.removeCanvas;
                return;
            }

            const [file] = externalAssets;
            this.closeTechnician3dObject = null;
            const splitedArray = file.key.split('.');
            this.setState({
                showFileViewer: true,
                fileType: splitedArray[splitedArray.length - 1],
                filePath: file.location,
                fileName: file.key.replace('boo/', ''),
                expertLoggedInId: file.loggedInUserId,
                isLoading: false
            });
        } catch (error) {
            let msg = 'Something went wrong while uploading file.';
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false,  error: msg}); 
        }
    }

    handleArtifactsNew = async () => {
        const { externalAssets, g_maxDivId } = this.props;      
        //console.log(externalAssets);  
        try {
            const [objFile] = externalAssets.filter(e => e.format === 'obj' || e.format === '3ds' || e.format === '3DS'|| e.format === 'FBX' || e.format === 'fbx');
            const [mtlFile = {}] = externalAssets.filter(e => e.format === 'mtl');
            const [pdfFile] = externalAssets.filter(e => e.format === 'pdf');
            const textureFiles = externalAssets.filter(e => e.format === 'jpg' || e.format === 'png' || e.format === 'BMP' || e.format === 'bmp' | e.format === 'exr' || e.format === 'tga');
    
            if (objFile) {
                this.setState({ isLoading: true });
                if (objFile.data.indexOf("data:") === -1) {
                    objFile.data = "data:"+objFile.type+";base64,"+objFile.data;
                }
                if (mtlFile !== undefined) {
                    mtlFile.data = (mtlFile.data !== undefined) ? "data:"+mtlFile.type+";base64,"+mtlFile.data : undefined;
                }
                if (textureFiles.length > 0) {
                    textureFiles.forEach(element => {
                        element.data = "data:"+element.type+";base64,"+element.data;
                    });
                }

                //Clear out the previous non 3D image/document shared
                this.setState({ 
                    showFileViewer: false,
                    fileType: 'none',
                    filePath: '',
                    fileName: '',
                    URLs: []
                });
                const sceneManager = this.getSceneManager();
                const fileType = objFile.format;
                this.active3dModel = await sceneManager.createObject({ type: fileType.toLowerCase(), mtlFilePath: mtlFile.data, objFilePath: objFile.data, textureFilePath:  map(textureFiles, 'data') })
                this.setState({ isLoading: false });
                this.closeTechnician3dObject = sceneManager.removeCanvas;
                return;
            }

            const [file] = externalAssets;
            this.closeTechnician3dObject = null;
            const splitedArray = file.name.split('.');
            let fileUrl;
            if (pdfFile) {
                const contentType = 'application/pdf';
                let urlObj;
                if (file.type === undefined) urlObj = "data:application/pdf;base64,"+file.data;
                else urlObj = "data:"+file.type.toLowerCase()+";base64,"+file.data; /**TP-5035*/
                const newFile = await urltoFile(urlObj, file.name+'.'+file.format, contentType);
                //console.log(newFile);
                //const blob = new base64StringToBlob(urlObj, contentType);// MB2-556
                fileUrl = URL.createObjectURL(newFile);
                printConsole(fileUrl);
            }
            this.setState({ 
                showFileViewer: false,
                fileType: 'none',
                filePath: '',
                fileName: '',
                URLs: []
            }, () => {
                this.setState({
                    showFileViewer: true,
                    fileType: file.format.toLowerCase(), /**TP-5035*/
                    filePath: (file.format === 'pdf') ? fileUrl : (file.format === 'mp4' || file.format === 'MP4') ? "data:video/mp4;base64,"+file.data :((file.format === 'mp3' || file.format === 'MP3')) ?  "data:audio/mp3;base64,"+file.data : "data:"+file.type+";base64,"+file.data, /**TP-5035*/
                    fileName: file.name+'.'+file.format,
                    //expertLoggedInId: file.loggedInUserId,
                    isLoading: false
                });
            })
        } catch (error) {
            let msg = 'Something went wrong while uploading file.';
            if (error.code === 'not_supported') {
                msg = error.msg;
            }
            this.setState({ isLoading: false,  error: msg}); 
        }
    }

    subscribeToRotation = (rotationChanges) => {
        try{
            const { _x, _y, _z, _w } = JSON.parse(rotationChanges).quaternion
            const eulerRotation = new THREE.Quaternion().copy({ x: _x, y: _y, z: _z, w: _w });
            if(this.active3dModel){
                this.active3dModel.quaternion.copy(eulerRotation);
            }else{
                console.log('no active3dModel')
            }
        }catch(error){
            console.log('error in subscribeToRotation', error)
        }
    }

    subscribeToOpacity = (opacityChanges) => {
        try{
            const {user, hasMaxDivControls} = this.props;
            if (this.state.showFileViewer) {
                this.setState({ fileOpacity: opacityChanges });
                if (user.isexpert && hasMaxDivControls) {
                    this.props.fnSendFileOpacity(opacityChanges);
                }
            } else {
                console.log('no FileViewer open');
                this.setState({ fileOpacity: opacityChanges });
            }
        }catch(error) {
            console.log('error in subcribeToOpacity ', error);
        }
    }

    /*showLoader = (text) => {
        if (!this.state.isLoading) return '';
        return (
            <div className="loader">
                <span className="d-flex flex-column justify-content-center align-items-center">
                    <i className="fa fa-spin fa-circle-o-notch fa-2" aria-hidden="true"></i>
                    <Trans id={text}></Trans>
                </span>
            </div>
        )
    }*/
    
    trackClickOn3dCanvas = () => {
        document.addEventListener('click', (event) => {
            //this.canvasListner(event)
        });
    }
    // NS2-366
    canvasListner = (event) => {
        const canvas = document.getElementById('canavas-3d-container');
        if(canvas){
            const isClickInsideElement = canvas.contains(event.target);
            if (!isClickInsideElement) {
                const closeButton = document.getElementById('closeIcon');
                if(closeButton){
                    closeButton.click();
                }
            }
        }
    }
    // NS2-366
    remove3dCanvasListner = () => {
        document.removeEventListener('click', (event) => {
            this.canvasListner(event)
        });
    }

    render = () => {
        const { showFileViewer, fileType, filePath, isLoading } = this.state;
        //console.log(filePath);
        let {user, g_maxDivId, isSafari, isiOS, isAndroid} = this.props;
        //printConsole(`isiOS device ?? ${isiOS} && isSafari ?? ${isSafari}`);        
        let fileViewer = '';
        /** Only shows for 2d assets */
        if (showFileViewer) {            
            let maxWidth = '100px', fileLeft = '10px', width = '0px', height = '0px', style = {}, bodyStyle = {};
            let embedHeight, bodyHeight;
            const sideBar = document.getElementById("sideBar");
            const paramElem = document.getElementById(g_maxDivId);
            if (sideBar) {
                maxWidth = Math.floor(window.innerWidth - sideBar.offsetWidth - 20) + 'px';
                fileLeft = Math.floor(sideBar.offsetWidth - 35) + 'px';
                //console.log(paramElem, paramElem.offsetHeight, paramElem.style.height);
                if (paramElem && (paramElem.offsetHeight !== 0 || paramElem.style.height !== null) ) { //TP-2446
                    //TP-4648
                    if (paramElem.offsetHeight !== 0) {
                        width = Math.floor(paramElem.offsetWidth) + 'px';
                        height = Math.floor(paramElem.offsetHeight) + 'px';
                        let heightOffset = (g_maxDivId !== "allVideos") ? 9 : 11;
                        if (window.innerWidth > 1559) heightOffset = 6.5;
                        bodyHeight = Math.floor(paramElem.offsetHeight - (heightOffset * paramElem.offsetHeight)/100);
                        embedHeight = Math.floor(paramElem.offsetHeight - (8 * bodyHeight)/100);
                    } else if (paramElem.style.height !== "0px")  {
                        //TP-4785
                        width = paramElem.style.width;
                        height = paramElem.style.height;
                        let heightOffset = (g_maxDivId !== "allVideos") ? 9 : 11;
                        if (window.innerWidth > 1559) heightOffset = 6.5;
                        const tmpWidth = paramElem.style.width.replace('px','');
                        const tmpHeight = paramElem.style.height.replace('px','');
                        bodyHeight = Math.floor(tmpHeight - (heightOffset * tmpHeight)/100);
                        embedHeight = Math.floor(tmpHeight - (8 * bodyHeight)/100);
                    } else {
                        //TP-4785
                        const parentNode = document.getElementById("allVideos");
                        width = Math.floor(parentNode.offsetWidth) + 'px';
                        height = Math.floor(parentNode.offsetHeight) + 'px';
                        let heightOffset = (g_maxDivId !== "allVideos") ? 9 : 11;
                        if (window.innerWidth > 1559) heightOffset = 6.5;
                        bodyHeight = Math.floor(parentNode.offsetHeight - (heightOffset * parentNode.offsetHeight)/100);
                        embedHeight = Math.floor(parentNode.offsetHeight - (8 * bodyHeight)/100);
                    }   
                } else {
                    //TP-2446
                    const parentNode = document.getElementById("allVideos");
                    width = Math.floor(parentNode.offsetWidth) + 'px';
                    height = Math.floor(parentNode.offsetHeight) + 'px';
                    let heightOffset = (g_maxDivId !== "allVideos") ? 9 : 11;
                    if (window.innerWidth > 1559) heightOffset = 6.5;
                    bodyHeight = Math.floor(parentNode.offsetHeight - (heightOffset * parentNode.offsetHeight)/100);
                    embedHeight = Math.floor(parentNode.offsetHeight - (8 * bodyHeight)/100);
                }
                //console.log("Dimensions of Modal ",width, height, bodyHeight, embedHeight);
            }
            
            if (fileType.toLowerCase() === 'pdf' && isiOS === true) {
                style = {opacity: this.state.fileOpacity.toString(), maxWidth: width, left: fileLeft, top: '-2%'};
                bodyStyle = {'maxHeight':'100%', 'maxWidth': '100%', 'align': 'center', 'overflowX': 'auto', 'overflowY': 'scroll'}
            }
            else {
                style = {opacity: this.state.fileOpacity.toString(), maxWidth: width, maxHeight: height, left: fileLeft, top: '-2%'};
                bodyStyle = {'height': bodyHeight + 'px', 'maxHeight':'100%', 'maxWidth': '100%', 'align': 'center', 'overflowX': 'auto'}
            }
            //console.log(this.state.fileOpacity);
            fileViewer = 
                <Modal id="file-viewer" size="lg" isOpen={this.state.showFileViewer} style={style}>
                    {/* <ModalHeader className="fileHeader" toggle={this.props.fnClose3dCanvas} close={closeBtn}></ModalHeader> */}
                        <ModalBody className="fileBody" style={bodyStyle}>
                            {/* <div className="row">
                                <div className={(this.state.fileName.length < 40) ? "col-7": "col-8"}>
                                    <h3>{this.state.fileName}</h3>
                                </div>
                                <div className="col">
                                    {closeBtn}
                                </div>                                
                            </div> */}       
                            {/* <div className="row middle-row w-100" style={(window.innerWidth > 1559) ? {height: '12%'} : {height: '10%'}} ></div>  */}                    
                            { fileType.toLowerCase() === 'pdf' ?
                                (isSafari) || isAndroid ?
                                    <div className="row" style={(window.innerWidth > 1559) ? {height: '100%', width: '100%'} : {height: '100%', width: ''}}>
                                        <FileViewer
                                            ref = { (f) => this.fileViewer = f } 
                                            fileType={fileType}
                                            filePath={filePath} 
                                            containerWidth="700"
                                            zoom="100"
                                        />
                                    </div>
                                    :
                                    <div className="row" style={{height: '100%'}}>
                                        <embed className="pg-viewer-wrapper" type="application/pdf" width={width} height={embedHeight} src={filePath+"#statusbar=1&view=fitH&toolbar=1&navpanes=0&scrollbar=1"} />
                                    </div>
                                :
                                (/*TP-1539*/fileType.toLowerCase() !== "none") ? 
                                    <div className="row" style={(window.innerWidth > 1559) ? {height: '100%', width: '100%'} : {height: '100%', width: ''}}>
                                        <FileViewer
                                            ref = { (f) => this.fileViewer = f } 
                                            fileType={fileType}
                                            filePath={filePath} 
                                            containerWidth="700"
                                            zoom="100"
                                        />
                                    </div>
                                    :
                                    ''
                            }
                        </ModalBody>
                </Modal>

        }
        return (<>
            <Loader text={(user.isexpert) ? "Uploading...." : "Downloading..."} isLoading={isLoading} />
            { fileViewer }
        </>);
    }

    /*onError(e) {
        console.log(e, 'error in file-viewer');
    }*/
}

AssetsLoader.propTypes = {
    assetRecipient: PropTypes.string,
    localAssets: PropTypes.array,
    externalAssets: PropTypes.array
}

export default AssetsLoader;