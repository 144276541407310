const encryptionAlgo = 'aes-128-cbc';
const encryptionPassword = '619bf494133645e2';
const encryptionIv = 'c5d7d8f5d59fd3e4';
const crypto = require('crypto');

export const tabVisibility = (function(){
    var stateKey, eventKey, keys = {
        hidden: "visibilitychange",
        webkitHidden: "webkitvisibilitychange",
        mozHidden: "mozvisibilitychange",
        msHidden: "msvisibilitychange"
    };
    for (stateKey in keys) {
        if (stateKey in document) {
            eventKey = keys[stateKey];
            break;
        }
    }
    return function(c) {
        if (c) document.addEventListener(eventKey, c);
        return !document[stateKey];
    }
})();

export const printConsole = (text) => {
    if (window._env_.REACT_APP_ENV === 'test' && window._env_.REACT_APP_APP_MODE === 'ONLINE')
        console.log(text);
    /* else if (window._env_.REACT_APP_ENV === 'local')
        console.log(text); */
    else if (window._env_.REACT_APP_ENV === 'qa')
        console.log(text);
}

export const printDevConsole = (text) => {
    if (window._env_.REACT_APP_ENV === 'test' && window._env_.REACT_APP_APP_MODE === 'ONLINE')
        console.log(text);
}

export const encryptData = (text) => {
    try{
        const cipher = crypto.createCipheriv(encryptionAlgo, encryptionPassword, encryptionIv)//FQ3-417
        let crypted = cipher.update(text,'utf8','hex')
        crypted += cipher.final('hex');
        return crypted;
    }catch(error){
        console.error(error);
        return ''
    }
}

export const decryptData = (text) => {
    try{
        let decipher = crypto.createDecipheriv(encryptionAlgo, encryptionPassword, encryptionIv)//MB2-712
        let dec = decipher.update(text,'hex','utf8')
        dec += decipher.final('utf8');
        return dec.trim();
    }catch(error){
        console.error('error decrypted data')
    }
}
//T32-292
export const showOriginalFileName = ({fileName}) => {
    try{
        let originalFileName = '';
        if (fileName){
            let splitedFileName = fileName.split(/_(.+)/);//TP-1058
            // splitedFileName.shift();originalFileName = splitedFileName.join('-');
            originalFileName = splitedFileName[1];
        }
        return originalFileName;
    }catch(error){
        printConsole('showOriginalFileName error', error)
        return ''
    }
}

export const isFileAllowed = ({localAssets, allowed_file_type=['application/pdf', 'image/png', 'image/jpeg', 'video/mp4']}) =>{
    let flag = false;
    for (const file of localAssets) {
        if(allowed_file_type.includes(file.type)){
            flag = true;
        }
    }
    return flag;
}

export const getWeekdayCountBetweenDates = (startDate, endDate, days_of_week) => {
    let totalDays = 0, i = new Date(startDate), modifiedEndDate = new Date(endDate);
    modifiedEndDate.setHours(23, 59, 59, 999);//set the end of the day
    for(i; i <= modifiedEndDate; i.setDate(i.getDate()+1)){
        if(days_of_week.includes(i.getDay())){
            totalDays++;
        }
    }
    return totalDays;
}
    

export const getMonthCountBetweenDates = (startDate, endDate) => {
    let totalMonths = 0, modifiedEndDate = new Date(endDate), i= new Date(startDate);
    let dayOfMonth = i.getDate(); modifiedEndDate.setHours(23, 59, 59, 999);//set the end of the day

    for(i; i <= modifiedEndDate; i.setDate(i.getDate()+1)){
        if(i.getDate() === dayOfMonth){
            totalMonths++;
        }
    }
    return totalMonths;
}    

//TP-4992
export const getHostAbbreviationForSocket = () => {
    printConsole(`the app url name is ${window._env_.REACT_APP_API_SOCKET_URL}`);
    try{
        const abbr = window._env_.REACT_APP_ENV === 'local' ? 'localhost' : window._env_.REACT_APP_API_SOCKET_URL.split('.')[0];
        return abbr;
    }catch(error){
        printConsole(`error in getHostAbbreviationForSocket ${error}`)
        return ''
    }
}

export const checkDummyEmail = (row) => {
    try{
        const {verified, email} = row
        const splittedEmail = email.split("@");
        let isnum = /^\d+$/.test(splittedEmail[0]);
        // printConsole(`new user email has only phone number ${isnum}`)
        return isnum;//TP-6212
        // return (row.verified === "email" || row.verified === "both")
    }catch(error){
        // printConsole(`error in checkDummyEmail ${error}`)
        return false;
    }
}

//extract number from string
export const formatMobile = (str) => {
    try{
        let newStr = str;
        if(str[0] == 0){
            newStr = str.slice(1);//should not start with 0
        }
        return newStr.replace(/[^0-9]/g, "");
    }catch(error){
        printConsole(`error in formatMobile ${error}`)
        return ""
    }
}
//TP-6222 -- Timestamp for the new Chat feature
const getMonthString = (mo) => {
    switch (mo) {
        case 0: return 'Jan';
        case 1: return 'Feb';
        case 2: return 'Mar';
        case 3: return 'Apr';
        case 4: return 'May';
        case 5: return 'Jun';
        case 6: return 'Jul';
        case 7: return 'Aug';
        case 8: return 'Sep';
        case 9: return 'Oct';
        case 10: return 'Nov';
        case 11: return 'Dec';
        default: return '';
    }
}

//TP-6222 -- Timestamp for the new Chat feature
export const getTimeStamp = () => {
    let strTimeBlob = '';
    let today = new Date();

    strTimeBlob = getMonthString(today.getMonth());
    strTimeBlob += ' ';
    strTimeBlob += today.getDate();
    strTimeBlob += ' ';
    strTimeBlob += today.getHours();
    strTimeBlob += ':';
    strTimeBlob += today.getMinutes();

    return strTimeBlob;
}

export const getProperRoles = (generic_name, defaultArr) => {
    try{
        let resArr = {};
        let parsedJson = JSON.parse(generic_name);
        for (var key in defaultArr) {
            if (defaultArr.hasOwnProperty(key)) {                
                if (parsedJson[key] === "" || parsedJson[key] === null) {
                    let tmp = defaultArr[key];   
                    resArr[key] = tmp[0].toUpperCase() + tmp.slice(1)
                }                         
                else
                    resArr[key] = parsedJson[key];                
            }
        }   
        return resArr;
    }catch(error){
        printConsole(`error in getProperRoles ${error}`)
        return defaultArr
    }
}

export const replaceGenericName = (generic_name, key, defaultValue) => {
    try{
        const defaultObj = {
            "admin": "Admin", 
            "expert": "Expert", 
            "technician": "Technician"
        };
        const customNames = getProperRoles(generic_name, defaultObj);
        const value = customNames[key]
        if(value){
            return value
        }else{
            return defaultValue
        }
    }catch(error){
        console.error(`error in replaceGenericName ${error}`)
        return defaultValue
    }
}
//TZ-1454 Method to fetch the URL domain of the webapp
export const getURL = (value) => {
    let url = window.location.href;
    if (url.includes(value)) return true;
    return false;
}