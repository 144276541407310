import React, { Component } from 'react';
import './ResetPassword.scss';
import SimpleReactValidator from 'simple-react-validator';
import AuthService from '../AuthService';
import { Trans, t } from '@lingui/macro';
import { I18n, i18nMark } from "@lingui/react";
import { encryptData, printConsole } from '../helpers';

const noOfPatterns = 2;
const noOfCharacters = 12;


const pattern = {
	special_character: { pattern: /[`!@#$%^&*;:|<>?]/, errMsg: "Please enter atleast one special case" },
	uppercase: { pattern: /[A-Z]/, errMsg: "Please enter at least one uppercase character" },
	lowercase: { pattern: /[a-z]/, errMsg: "Please enter at least one lowercase character" },
	number: { pattern: /[0-9]/, errMsg: "Please enter at least one number" }
}

export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.authService = new AuthService();
        this.state = {
            submited: false,
            isLoading: false,
            error: false,
            email: props.email ? props.email : '', //TP-3447
            old_password: props.oldPassword ? props.oldPassword : '', //TP-3447
			password: '',
            repeatPassword:'',
            msgColor: '#485890',
            language:'',
            isChangePassword: props.isChangePassword ? props.isChangePassword : false //TP-3447
        }

        // initialize validator
        this.validationRules(props.language);

    }
    componentDidMount = () => {
        //TP-5418 -- Adding listener for the socket signal
        //this.authService.socket.on('log-me-out', this.subscribeToLogMeOut);TP-5504
    }
    componentDidUpdate = (prevProps, prevState) =>{
        if (prevState.language !== this.state.language) {
            this.validationRules(this.state.language)
        }
    }

    //TP-3447
    componentWillReceiveProps = (nextProps) => {
        if(nextProps.language!==this.props.language){           
            this.setState({language : nextProps.language});
        }
        if (nextProps.isChangePassword !== this.props.isChangePassword) {
            this.setState({isChangePassword: nextProps.isChangePassword});
        }
        if (nextProps.email !== this.props.email) {
            this.setState({email: nextProps.email});
        }
        if (nextProps.oldPassword !== this.props.oldPassword) {
            this.setState({old_password: nextProps.oldPassword});
        }
    }
    /* static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.language!==prevState.language){
           
          return {language : nextProps.language};
        } /* else if (nextProps.oldPassword!==prevState.oldPassword) {
            return { old_password: nextProps.oldPassword };
        } else if (nextProps.email!==prevState.email) {
            return {email : nextProps.email};
        } 
        else if (nextProps.isChangePassword !== prevState.isChangePassword) {
            return {isChangePassword: nextProps.isChangePassword};
        }
        else if (nextProps.email !== prevState.email) {
            return {email: nextProps.email};
        }
        else return null;
    } */

    componentWillUnmount = () => {
        //TP-5418 -- removing listener for socket signal
        //this.authService.socket.removeListener('log-me-out', this.subscribeToLogMeOut); TP-5504
    }
    
    validationRules = (language) => {
        SimpleReactValidator.addLocale(language, require(`../../locales/${language}/messages.json`));
        this.validator = new SimpleReactValidator({locale: language,
            element: message => <div className="register-form-validation-msg">{message.replace(/The|field/g, '').capitalize()}</div>,
            validators: {
                confirmPassword: {  // name the rule
                    message: 'Password and Confirm Password do not match.',
                    rule: (val, params, validator) => {
                        return val === this.state.password ? true : false; 
                    },
                    required: true  // optional
                },
                emailValidate: {  // name the rule
                    message: 'Please enter a valid email address',
                    rule: (val, params, validator) => {
                        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return validator.helpers.testRegex(
                            String(val).toLowerCase(),
                            regex
                        );
                    },
                    required: true  // optional
                },
                notEqualToOldPassword: {  // name the rule
                    message: 'New password and old password cannot be same.',
                    rule: (val, params, validator) => {
                        return val === this.state.old_password ? false : true; 
                    },
                },
                passwordSpace: { //name the rule
                    message: 'Password can not contain white spaces',
                    rule: (val, params, validator) => {
            		    return String(val).includes(" ") === true ? false : true;
                    },
                },
                passwordLength: { //name the rule
                    message: 'Password should be at least '+noOfCharacters+' characters long',
                    rule: (val, params, validator) => {
                        return val.length < noOfCharacters ? false: true;
                    },

                },
                passwordOtherValidate: { //name of the rule
                    message: 'Password must contain a character from at least '+noOfPatterns+' of the Password reset guidelines\' categories.',
                    rule: (val, params, validator) => {
                        let res = false;
                        for (let k in pattern) {                            
                            for (let h in pattern) {
                                if (k!==h){
                                    res = validator.helpers.testRegex(String(val), pattern[k].pattern) && validator.helpers.testRegex(String(val), pattern[h].pattern);
                                    if (res == true) break;
                                }
                            }
                            if (res == true) break;
                        } 
                        return res;
                    }
                },
                passwordNotSameAsUserIdOrAccId: { // name of the rule
                    message: 'Password cannot be the same as user id or account id.',
                    rule: (val, params, validator) => {
                        return (this.props.accountid && val.includes(this.props.accountid)) || String(val) === String(this.state.email) ? false : true; 
                        //return val.toLowerCase().includes(this.props.accountid) || val.toLowerCase().includes(this.props.id.toString()) ? true : false; 
                    }
                }
            }
        });
    }

    // Validator methods:
    // // Check the length of the input
    passwordValidate = ({password, type}) => {
        const updatedChanges = {};
        printConsole(this.props.accountid);
        switch (type) {
            case "length":
                updatedChanges.charNumberValid = password.length >= noOfCharacters ? true : false;
                break;
            case "accountIduserId":
                updatedChanges.accIdUserIdValid = (this.props.accountid && !password.includes(this.props.accountid)) && password !== this.state.email ? true : false;
                break;
            case "special_character":
                updatedChanges.specialCharValid = password.match(new RegExp(pattern[type].pattern)) ? true : false;
                break;
            case "uppercase":
                updatedChanges.uppercaseValid = password.match(new RegExp(pattern[type].pattern)) ? true : false;
                break;
            case "lowercase":
                updatedChanges.lowercaseValid = password.match(new RegExp(pattern[type].pattern)) ? true : false;
                break;
            case "number":
                updatedChanges.numberValid = password.match(new RegExp(pattern[type].pattern)) ? true : false;
        }
        this.setState((prev) => ({...prev,...updatedChanges}));
    }

    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
        if (name === "password" && this.props.behaviour === "smartvision") {
            this.passwordValidate({password: event.target.value, type: 'length'});
            this.passwordValidate({password: event.target.value, type: 'accountIduserId'});
            this.passwordValidate({password: event.target.value, type: 'special_character'});
            this.passwordValidate({password: event.target.value, type: 'uppercase'});
            this.passwordValidate({password: event.target.value, type: 'lowercase'});
            this.passwordValidate({password: event.target.value, type: 'number'});
        }
    };

    //TP-5418
    subscribeToLogMeOut = ({ userIsDeleted, email, loginTimeStamp }) => {
        printConsole("log-me-out signal received with payload");
        printConsole({ userIsDeleted, email, loginTimeStamp });
        const {groupInfo} = this.state;
        if (userIsDeleted === true && this.props.email === email) {
            sessionStorage.removeItem('id_token');
            sessionStorage.removeItem('pageName');//TP-1579
            window.location = '/';
            return;
        } /* else if (userIsDeleted === true && this.props.email !== email){
            printConsole("checking if the user is part of any groups of current user");
            groupInfo.map(g => {
                g.users.forEach( u => {
                    if(u.email === email) {
                        printConsole(`${email} user is part of a group`);
                        printConsole("refreshing the page...");
                        window.location = '/';
                        return;
                    }
                })
            })   
        } */ else if (userIsDeleted === false && this.props.email === email) {
            const {forcedLogin} = this.props;            
            if (forcedLogin === true && new Date(loginTimeStamp) === new Date(this.props.last_accessed_on)) {
                this.props.loadLogMeIn({ logMeIn: false }); 
                return;
            } else if (new Date(loginTimeStamp) > new Date(this.props.last_accessed_on)) {
                this.onSubmitSignout(); //TP-2028
                return;
            }
        }
    }

    onSubmitSignout = () => {
        //console.log('signout called');
        const myLocation = "Bangalore North++East"; //getGeoLocation();
        const encryptedEmail = encryptData(this.state.email.toLowerCase());//FQ3-417
        this.authService.fetch('signout', { //TP-2028
          method: 'post',
          /* TP-2028 headers: {'Content-Type': 'application/json'}, */
          body: JSON.stringify({
            email: encryptedEmail,
            isencrypted: true,
            id: parseInt(this.props.id, 10),
            groupid: 0,//parseInt(this.props.groupId, 10),
            geoLocation: myLocation
          })
        })
        .then(response => response.json())
        .then(data => {
            // adding this on success UQ3-208. Instead of the call adding it on success
            this.authService.logout();
            this.props.routeChange('signout');
        })
        .catch(err => console.log(err.message))
    }


    // submit reset password form NS2-143
    changePassword = (e) => {
        e.preventDefault();
        console.log(this.validator);
		if (!this.validator.allValid()) {
			this.validator.showMessages();
			this.forceUpdate();
			return;
        }
        //console.log("submit is success"); 
        this.setState({ isLoading: true });
        let encryptedEmail = encryptData(this.state.email.toLowerCase()), oldencryptedPassword = encryptData(this.state.old_password.trim()), encryptedPassword = encryptData(this.state.repeatPassword.trim());//FQ3-417
        fetch(`${window._env_.REACT_APP_API_URL}/resetExpiredPassword`, {
            method: 'put',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                old_password: oldencryptedPassword,
                password: encryptedPassword,
                email: encryptedEmail
            })
        })
        .then(response => {
            if(response.status >= 200 && response.status < 300){
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response
                throw error;
            }
        })
        .then((data) => {
            if (data) {
                this.setState({
                    isLoading: false,
                    //message: i18nMark('Your password has been successfully changed to {password}'),
                    message: i18nMark('Your password has been successfully reset, please try logging in again.'),
                    token: '',
                    submited: true,
                    msgColor: '#485890'
                }, () => {
                    if (this.props.behaviour === "smartvision" || this.state.isChangePassword)
                        this.onSubmitSignout();
                });
            }
        })
        .catch ((error) => {
            let errorStr, status = error.response.status;
            if(status === 400){
                errorStr = i18nMark('Old password does not match')
            } else if (status === 417) {
                errorStr = i18nMark('Password matches with old password')
            } else{
                errorStr = i18nMark('Password cannot be reset')
            }
            this.setState({
                isLoading: false,
                message: errorStr,
                token: '',
                submited: false,
                msgColor: 'red'
            });
        })
    }

    onClickCloseMessage = (e) => {
        e.preventDefault();
        this.props.onCloseModal();
    }

    goToSignin = (e) => {
        e.preventDefault();
        if (this.state.isChangePassword === true) {
            //this.props.routeChange('home'); //TP-3871 & TP-3672
            this.props.updateOpenMyProfile(); // TP-5504
            this.setState({password: "", repeatPassword: "", old_password: ""})//TP-6147
        } else 
            this.props.onCloseModal();
        if (this.props.behaviour === "smartvision")
            this.onSubmitSignout();
    }

    toggleCollapse = (e) => {
        e.preventDefault();
        var btn = document.getElementsByClassName("collapse"); 
        var content = btn[0].nextElementSibling; 
        var arrow = btn[0].childNodes;
        //console.log(arrow);
        if (content.style.display === "block") { 
            content.style.display = "none";
            //arrow.className = "fa-angle-up"; 
            //arrow.classList.remove("fa-angle-down")
        } else { 
            content.style.display = "block"; 
            //arrow.className = "fa-angle-down";
            //arrow.classList.remove("fa-angle-up");
        } 
    }

    toggleEllipsis = (e) => {
        e.preventDefault();
        var element = document.getElementById("ellipsis-ex");
        element.classList.toggle("text-truncate");
    }

    render() {
        const { isLoading, message, msgColor, charNumberValid, accIdUserIdValid, uppercaseValid, lowercaseValid, numberValid, specialCharValid } = this.state;

        if (message) {
            return (<div className="modale opened">
                <div className="__modal-dialog">
                    <form>
                        <div className='__modal-header'>
                            <h4 style={{ color: msgColor }}><Trans values={{password: `${this.state.password}`}} id={this.state.message}/></h4> 
                        </div>
                        <div className="__modal-footer flex-center">
                            <button className="btn-green" onClick={this.onClickCloseMessage}><Trans>Close</Trans></button>
                        </div>
                    </form>
                </div>
            </div>);
        } else if (isLoading) {
            return (
                <div className="modale opened">
					<div className="__modal-dialog">
						<form>
							<div className='__modal-header'>
                                <i className="fas fa-spinner fa-spin" style={{'fontSize': 24 }}></i>
							</div>
							<div className="__modal-footer flex-center">
                                &nbsp;
							</div>
						</form>
					</div>
				</div>
            )
        } else {
            //console.log(this.state.isChangePassword, this.state.email);
            //TP-3516
            const maxheight = (window.innerHeight > 660) ? window.innerHeight - (25 * (window.innerHeight/100)) : (window.innerHeight > 600) ?  window.innerHeight - (30 * (window.innerHeight/100)) : window.innerHeight;
            return (
                <div className="formmodule center" style={{height: maxheight+"px"}}>
					<div className={(window.innerHeight > 660) ? "dialog center form-color width-min" : "center form-color width-min"} style={{width: "100%"}}>
						<form style={{width: "50%"}}>
							<div className="formheader tabsColor flex-center row form-text-color">
								<h2>
                                    { this.state.isChangePassword ?
                                        <Trans>Change Password</Trans>
                                        :
                                        <Trans>Reset Password</Trans>
                                    }
                                </h2>
							</div>		
							<div className="formbody form-text-color mt-5">
                                { /* 1==1 */ this.props.behaviour === "smartvision" ? 
                                    <div className="input-wrapper" >
                                    <h3 className="collapse" style={{ display: "block"}} onClick={this.toggleCollapse}>Password reset guidelines <i className="fas fa-angle-down"></i></h3>
                                    <span className="collapse-text" style={{ display: "none"}}>
                                    <ul>
                                        <li>
                                        <h4>
                                            { charNumberValid ?
                                                <span className="fa-icons check">&#10003;</span>
                                                :
                                                <span className="fa-icons times">&#10799;</span>
                                            }
                                            {/* <i
                                                className={
                                                charNumberValid
                                                    ? "fas fa-check "
                                                    : "fas fa-times  "
                                                }
                                            ></i> */}
                                            Password must contain at least {noOfCharacters} alphanumeric characters. 
                                        </h4>  
                                        </li>
                                        <li>
                                            <h4>Satisfy at least {noOfPatterns} of the following four categories:</h4>
                                            <ul>
                                                <li>
                                                    { uppercaseValid ?
                                                        <span className="fa-icons check">&#10003;</span>
                                                        :
                                                        <span className="fa-icons times">&#10799;</span>
                                                    }
                                                    {/* <i
                                                        className={
                                                        uppercaseValid
                                                            ? "fas fa-check"
                                                            : "fas fa-times  "
                                                        }
                                                    ></i> */}
                                                    Upper case (A through Z) 
                                                    
                                                </li>
                                                <li>
                                                    { lowercaseValid ?
                                                        <span className="fa-icons check">&#10003;</span>
                                                        :
                                                        <span className="fa-icons times">&#10799;</span>
                                                    }
                                                    {/* <i
                                                        className={
                                                        lowercaseValid
                                                            ? "fas fa-check"
                                                            : "fas fa-times  "
                                                        }
                                                    ></i> */}
                                                    Lower case (a through z) 
                                                </li>
                                                <li>
                                                    { numberValid ?
                                                        <span className="fa-icons check">&#10003;</span>
                                                        :
                                                        <span className="fa-icons times">&#10799;</span>
                                                    }
                                                    {/* <i
                                                        className={
                                                        numberValid
                                                            ? "fas fa-check"
                                                            : "fas fa-times "
                                                        }
                                                    ></i> */}
                                                    Digits (0-9) 
                                                </li>
                                                <li>
                                                <span id="ellipsis-ex" className="text-truncate" style={{maxWidth: '150px'}} onClick={this.toggleEllipsis}>
                                                    { specialCharValid ?
                                                        <span className="fa-icons check">&#10003;</span>
                                                        :
                                                        <span className="fa-icons times">&#10799;</span>
                                                    }
                                                    {/* <i
                                                        className={
                                                        specialCharValid
                                                            ? "fas fa-check"
                                                            : "fas fa-times  "
                                                        }
                                                    ></i> */}
                                                    Special Characters (!,@,$,#,%,^,&#38;,*,?,|,:,;,&#60;,&#62;)</span>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <h4>
                                            { accIdUserIdValid ?
                                                <span className="fa-icons check">&#10003;</span>
                                                :
                                                <span className="fa-icons times">&#10799;</span>
                                            }
                                            {/* <i
                                                className={
                                                accIdUserIdValid
                                                    ? "fas fa-check "
                                                    : "fas fa-times  "
                                                }
                                            ></i> */}
                                            Can't be the same as account id or user id.</h4>
                                        </li>
                                        <li>
                                            <h4>
                                            * Can't be the same as the last three passwords.
                                            </h4>
                                        </li>
                                    </ul>
                                    </span>
                                    </div>
                                    :
                                    ''
                                }
                                {/* <label><Trans>Email</Trans>: */}
                                    <div className="input-wrapper mb-2">
                                        <I18n>
                                            { ({ i18n }) =>
                                            <>
                                                <input className="signup-input-field" name={i18n._(t`user_email`)} disabled={this.state.isChangePassword} type="text" placeholder={i18n._(t`Enter your email`)} value={this.state.email} onChange={this.handleChange('email')}/>
                                                { this.state.isChangePassword === false ?
                                                    this.validator.message(i18n._(t`user_email`), this.state.email, 'required|emailValidate') : 
                                                    this.validator.message(i18n._(t`user_email`), this.state.email, 'required') 
                                                }	
                                            </>									
                                            }
                                        </I18n>
                                    </div>
                                {/* </label>
                                <label><Trans id='Old Password' />: */}
                                    <div className="input-wrapper mt-3 mb-2">
                                        <I18n>
                                            {({ i18n }) =>
                                            <>
                                                <input className='signup-input-field' name={i18n._(t`old_password`)} type="password" placeholder={i18n._(t`Enter your old password here`)} value={this.state.old_password} onChange={this.handleChange('old_password')} />
                                                {this.validator.message(i18n._(t`old_password`), this.state.old_password, 'required')}
                                            </>
                                            }
                                        </I18n>
                                    </div>
                                {/* </label>                                
                                <label><Trans id='New Password' />: */}
									<div className="input-wrapper mt-3 mb-2">
                                        <I18n>
                                            {({ i18n }) =>
                                            <>
                                                <input className='signup-input-field' name={i18n._(t`password`)} type="password" placeholder={i18n._(t`Enter your new password here`)} value={this.state.password} onChange={this.handleChange('password')} />
                                                { /* 1==1 */ this.props.behaviour === "smartvision" || this.state.isChangePassword === false ? 
                                                    this.validator.message(i18n._(t`password`), this.state.password, 'required|notEqualToOldPassword|passwordSpace|passwordLength|passwordOtherValidate|passwordNotSameAsUserIdOrAccId')
                                                    :
                                                    this.validator.message(i18n._(t`password`), this.state.password, 'required|notEqualToOldPassword')
                                                }
                                            </>
                                            }
                                        </I18n>
									</div>
								{/* </label>
                                <br/>
								<label><Trans id='Re-enter Password' />: */}
									<div className="input-wrapper mt-3 mb-2">
                                        <I18n>
                                            {({ i18n }) =>
                                            <>
										        <input className='signup-input-field margin-bottom-0' name={i18n._(t`repeatpassword`)} type="password" placeholder={i18n._(t`Re-enter the new password here`)} value={this.state.repeatPassword} onChange={this.handleChange('repeatPassword')} />
                                                {this.validator.message(i18n._(t`confirm_password`), this.state.repeatPassword, 'required|confirmPassword')}
                                            </>
                                            }
                                        </I18n>
									</div>
								{/* </label> */}
							</div>
							<div className="flex-center" style={{marginTop: "10%"}}>
                                <button className="btn-red"  style={{marginRight: '15%'}} onClick={(e) => this.goToSignin(e)}><Trans>Close</Trans></button>
                                <button className="btn-green" onClick={(e) => this.changePassword(e) /**TP-5952*/}><Trans>Apply</Trans></button>
							</div>
						</form>
					</div>
	            </div>
            )
        }
    }
}