import { indexOf } from 'lodash';
import { WebGLRenderer, Clock } from 'three';
import SceneManager from './SceneManager';

// initializing the animation function
const requestAnimFrame = (function(){
    return  window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function(callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();

export default ({ videoElement, id, isSafari, fnSend3dRotation, fnClose3dCanvas, closeIconText, showCloseIcon = true }) => {
    const canvas = createCanvas(document, videoElement, isSafari);
    let frameId;
    const sceneManager = new SceneManager({ 
        canvas,
        isSafari,
        fnSend3dRotation,
        fnSetAnimationMixer,
        videoElement,
        fnClose3d,
        closeIconText,
        showCloseIcon
    });
    const clock = new Clock();
    let mixer;

    bindEventListeners();
    render();

    function createCanvas(document, videoElement, isSafari) {
        const height = videoElement.style.height.substring(0, videoElement.style.height.indexOf("px"));
        const width = videoElement.style.width ? videoElement.style.width.substring(0, videoElement.style.width.indexOf("px")) : videoElement.offsetWidth;
        console.log("isSafari: ", isSafari);
        const existingCanvas = document.getElementById(id);
        /** remove the the 3d canvas if already exists and create new */
        if (existingCanvas) {
            existingCanvas.parentNode.removeChild(existingCanvas);
        }
        const renderer = new WebGLRenderer({ antialias : true, alpha : true });
        /** Create a renderer using  WebGLRenderer()*/
        renderer.setPixelRatio(window.devicePixelRatio);
        if (videoElement.hasAttribute("id") !== false && videoElement.id !== 'flex-container' && videoElement.id !== 'allVideos') {
            renderer.setSize(
                Math.floor(width/1),
                Math.floor(height/1),
                true
            );
        } else {
            renderer.setSize(
                Math.floor(videoElement.offsetWidth/1),
                Math.floor(videoElement.offsetHeight/1),
                true
            );
        }
        renderer.setClearColor(0xffffff, 0);
        /** 3d model canvas */
        const canvas = renderer.domElement;
        canvas.setAttribute('id', id);
        canvas.setAttribute('class', 'asset-3d');
        /** Add position relative to the parent container */
        videoElement.parentNode.style.position = 'relative';
        /** Styles for canvas element */
        canvas.style.backgroundColor = 'transparent';
        canvas.style.zIndex = 3;        
        if (videoElement.hasAttribute("id") === false || videoElement.id === 'flex-container' || videoElement.id === 'allVideos') {
            canvas.style.left= (videoElement.id === 'flex-container'|| videoElement.id === 'allVideos') ? '70px': '0px';
        } else if (isSafari) {
            canvas.style.left= '70px';
        }
        canvas.style.position = (isSafari === true) ? 'fixed' : 'absolute';
        if (videoElement.hasAttribute("id") !== false && videoElement.id !== 'flex-container' && videoElement.id !== 'allVideos') {
            canvas.width = width;
            canvas.height = height;
        } else {
            canvas.width = videoElement.offsetWidth;
            canvas.height = videoElement.offsetHeight;
        }
        videoElement.parentNode.insertBefore(canvas, videoElement);
        return canvas;
    }

    function fnClose3d() {
        /** Cancell the previous animation frame */
        frameId && cancelAnimationFrame(frameId);
        /** Call close3dCanvass callback method */
        fnClose3dCanvas();
    }

    function fnSetAnimationMixer(mixerVal) {
        /** This is mainly used for FBX animation */
        mixer = mixerVal;
    }

    function bindEventListeners() {
        window.onresize = resizeCanvas;
        resizeCanvas();	
    }

    function resizeCanvas() {        
        canvas.width  = canvas.offsetWidth;
        canvas.height = canvas.offsetHeight;
        sceneManager.onWindowResize()
    }

    function render(time) {
        frameId = requestAnimFrame(render);
        const delta = clock.getDelta();
		if ( mixer ) mixer.update( delta );
        sceneManager.update();
    }

    return {
        sceneManager
    }
}