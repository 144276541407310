import React from 'react';
import SimpleReactValidator from 'simple-react-validator';
import './Signup.scss';
import Adminform from './Adminform';
import PricingPlan from '../PricingPlan/PricingPlan';
import {AccountConsumer} from"../AccountContext";
import { Trans } from '@lingui/macro';

class Signup extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			inviteeinfoarray: [{
				inviteeemailid: '',
		  		inviteefirstname: '', 
		  		inviteelastname: '',
		  		subscriptionid: ''
			}],
			subID: 'Subscription:willbegeneratedafterpayment',
			planSelected: {},
			signupStep: 'selectplan',
			inviteeFirstName: '',
			inviteeLastName: '',
			inviteeEmail: '',
			companyName: '',
            invitedByName: 'S PAL',
            plans: []
		}
		this.isDuplicateInviteeEmail = this.isDuplicateInviteeEmail.bind(this);
		this.processDuplicateEmail = this.processDuplicateEmail.bind(this);
		// initialize validator
        SimpleReactValidator.addLocale(props.language, require(`../../locales/${props.language}/messages.json`));
		this.validator = new SimpleReactValidator({locale:props.language,
			element: message => <div className="signup-form-validation-msg">{message.replace(/The|field/g, '').capitalize()}</div>
		});
	}

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.language!==prevState.language){
           
          return {language : nextProps.language};
        }
        else return null;
    }

	onSubmitSignup = (event) => {
		if (!event.target.checkValidity()) {
		    // form is invalid! so we do nothing
		    return;}

		event.preventDefault();

		if(this.state.inviteeinfoarray.length >= 1)
		{
			this.state.inviteeinfoarray.splice(0, 1);

	  		let reqData = [];
			let index = 0;
			this.state.inviteeinfoarray.forEach( function (item){
				reqData[index] = item;
				index++;
			});

			fetch(window._env_.REACT_APP_API_URL + '/signup', {
				method: 'post',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({reqData})
			})
			/*
			.then(response => response.json(){
				console.log(response);
			})
			*/
			.then(returnData => {
				//console.log(returnData);
				this.props.onRouteChange('signin');
				})
		}
		else
		{
			// indicate error message to user about passwords not matching up
			alert("Please invite users to the platform");
		}
	}

	isDuplicateInviteeEmail(){
		//return this.processSubmitNewInvitee();
		let fetchString = window._env_.REACT_APP_API_URL + '/getinviteeemails?subscriptionid=';
		fetchString += this.state.subID;
		//console.log(fetchString);
		fetch(fetchString, {
            method: 'get',
            headers: {'Content-Type': 'application/json'},
		})
		.then(response => response.json())
		.then(data => {
			let bDuplicate = false;
			if(data !== undefined && data !== null)
			{
			 	let strEmail = this.state.inviteeEmail;
				data.forEach( function (item){
					if(strEmail === item.email){
						bDuplicate = true;
					}
				});
				let index = 0;
				for(index = 0; index < this.state.inviteeinfoarray.length; index++)
				{
					if(this.state.inviteeinfoarray[index].inviteeEmail === this.state.inviteeEmail){
						bDuplicate = true;
					}
				}
				if(bDuplicate === true) this.processDuplicateEmail();
				else this.processSubmitNewInvitee();
			}	
		})
		.catch(err => console.log(err.message))		
	}

	clearOutInviteeUI(){
		document.getElementById('invitee_form').reset();
		// clear out the fields for new invitee entry
	}

	processDuplicateEmail(){
		let strMessage = 'Duplicate email id entered.';
		alert(strMessage);
		this.clearOutInviteeUI();
	}

	processSubmitNewInvitee(){
		let inviteeInfo = {
	  		inviteefirstname: this.state.inviteeFirstName, 
	  		inviteelastname: this.state.inviteeLastName,
	  		inviteeemailid: this.state.inviteeEmail,
	  		subscriptionid: this.state.subID,
	  		companyname: this.state.companyName,
	  		invitedbyname: this.state.invitedByName
	  	};

	  	// add to invitee array
	  	this.state.inviteeinfoarray.push(inviteeInfo);


		// if count is 5, hide the "Add user" button
		let element = null;

		if(this.state.inviteeinfoarray.length === 3){
			//console.log(this.inviteeinfoarray);
			element = document.getElementById("SubmitSignupButton");
			element.className="f6 no-underline ph3 pv2 mb2 dib white grow bg-dark-green mr4";
			element.disabled = false;
		}
		/*
		if(this.state.inviteeinfoarray.length >= 6){
			element = document.getElementById("InviteNewUserButton");
			element.className="f6 no-underline ph3 pv2 mb2 dib white bg-gray";
			element.disabled = true;
		}*/

		this.clearOutInviteeUI();

		this.setState({signupStep: 'fillform'});
	}

	onSubmitNewInvitee = (event) => {
		event.preventDefault();
		if(this.state.planSelected.id > 0) {
		  	// check for duplicate email id
		  	this.isDuplicateInviteeEmail();
		}
		else {
			// indicate error message to user about passwords not matching up
			alert("Select a plan first");
		}
	}

	onFirstNameChange = (event) => {
		this.setState({inviteeFirstName: event.target.value});
	}

	onLastNameChange = (event) => {
		this.setState({inviteeLastName: event.target.value});
	}

	onEmailChange = (event) => {
		this.setState({inviteeEmail: event.target.value});
	}

	onCompanyChange = (event) => {
		//console.log('Invitee list length = ', this.state.inviteeinfoarray.length);
		this.setState({companyName: event.target.value});

		let element = document.getElementById("InviteNewUserButton");
		//console.log('event.target.value = , element.disabled = , element.className = , this.state.companyName = ', event.target.value, element.disabled, element.className, this.state.companyName);
		if(element.disabled === true && event.target.value !== '' && this.state.inviteeinfoarray.length < 12){ 
			//console.log('inside enable button block');
			element.className="f6 no-underline ph3 pv2 mb2 dib white grow bg-purple";
			element.disabled = false;
			//element.style.pointerEvents = 'auto';
			element.onClick=this.onSubmitNewInvitee;
		}
		/*else if(element.disabled === false && (event.target.value === '' || this.state.inviteeinfoarray.length >= 6)){
			console.log('inside disable button block');
			element.className="f6 no-underline ph3 pv2 mb2 dib white bg-gray";
			//element.style.pointerEvents = 'none';
			element.disabled = true;
		}*/
	}

	componentDidUpdate = (prevProps, prevState) => {
		let element = document.getElementById("SubmitSignupButton");
		if(element !== null && this.state.inviteeinfoarray.length < 3){
			element.className = 'f6 no-underline ph3 pv2 mb2 dib white bg-gray mr4';
			element.disabled = true;
        }
        if (prevState.language !== this.state.language) {
            SimpleReactValidator.addLocale(this.state.language, require(`../../locales/${this.state.language}/messages.json`));
            this.validator = new SimpleReactValidator({locale: this.state.language,
                element: message => <div className="signup-form-validation-msg">{message.replace(/The|field/g, '').capitalize()}</div>
            });
        }

	}

//	enterPressed = (event) => {
//	    var code = event.keyCode || event.which;
//	    if(code === 13) { //13 is the enter keycode
//	        this.onSubmitSignup();
//	    } 
//	}

	closeAdminPopup = () => {
		window.history.replaceState(null, null, '/');
		this.setState({signupStep: 'selectplan'});
	}

	onPlanSelected = (plan) => {
		this.setState({planSelected: plan});
		window.history.pushState(null, '', '/signup?plan='+plan.code);
		this.setState({signupStep: 'admindetails'});
		// window.history.go(0);
    }
    
    getAllPlans = (plans) => {
        this.setState({
            plans
        });
	}
	
	getParams = (location, query) => {
		const searchParams = new URLSearchParams(location.search);
		return {
		  [query]: searchParams.get(query) || '',
		};
	}

	render(){
    const planCode = this.getParams(window.location, 'plan').plan;
		if(this.state.signupStep === 'selectplan'){
			return(
				<div className="pricing-container">
                    <div id="Step 1" className="text-center">
                        <h2 style={{color:'white'}}><Trans>Select a plan</Trans></h2>
                    </div>
                    <PricingPlan onGetAllPlans={this.getAllPlans} onPlanSelect={this.onPlanSelected} buttonText="SIGNUP" />
                    {(this.state.signupStep === 'admindetails')?
                        <AccountConsumer>
                            {({language}) =>
                                <Adminform language={language} closeAdminPopup={this.closeAdminPopup} planCode={planCode} />
                            }
                        </AccountConsumer>
                        : null
                    }				
				</div>
				);
		}
		else if(this.state.signupStep === 'admindetails'){
			return(
				<div className="pricing-container">
                    
                    {(this.state.signupStep === 'admindetails')?
                        <AccountConsumer>
                            {({language}) =>
                                <Adminform language={language} closeAdminPopup={this.closeAdminPopup} planCode={planCode} />
                            }
                        </AccountConsumer>
                        : null
                    }				
				</div>
				);
		}
		else if(this.state.signupStep === 'makepayment')
		{
			return(null);
		}
		else if(this.state.signupStep === 'fillform')
		{
			return(
				<article className="br3 ba dark-gray b--black-10 mv4 w-auto w-50-m w-25-l mw6 shadow-5 center">
					<main className="pa4 black-80 w-400">
					  <form id="invitee_form" className="measure w-400">
					    <fieldset id="sign_up" className="ba b--transparent ph0 mh0 w-400">
					      <legend className="f3 fw6 ph0 mh0 mb2">Step 3. Provide user details</legend>
					      <div className="cf mb2">
			                    <input type="text" 
			                        className="input-reset ba bg-transparent b hover-bg-black hover-white pa2 mb2 db w-80" 
			                        name="firstName"
			                        id="inviteefirstname" 
			                        placeholder="First Name"
			                        autoFocus={true}
			                        onChange = {this.onFirstNameChange}
			                        required
			                        />
			              </div>

			              <div className="cf mb2">
			                    <input type="text" 
			                        className="input-reset ba bg-transparent b hover-bg-black hover-white pa2 mb2 db w-80" 
			                        name="inviteelastName" 
			                        placeholder="Last Name"
			                        id="inviteelastname"
			                        onChange = {this.onLastNameChange}
			                        required
			                        />
			               </div>

			               <div className="cf mb2">
						        <input 
						        	className="b pa2 input-reset ba bg-transparent hover-bg-black hover-white mb2 db w-80" 
						        	type="email" 
						        	name="email-address"  
						        	id="inviteeemail"
						        	placeholder="Email For Registration Invite"
						        	onChange={this.onEmailChange}
						        	required
						        	/>
						    </div>

						    <div className="cf mb2">
						        <input type="text"
						        	className="input-reset ba bg-transparent b hover-bg-black hover-white pa2 mb2 db w-80" 
						        	name="companyname"  
						        	id="companyname"
						        	placeholder="Enter Company Name"
						        	onChange={this.onCompanyChange}
						        	required
						        	/>
					      </div>

					      <div className="cf mb2">
		 			        <legend className="f5 fw6 ph0 mh0">{this.state.subID}</legend>
					      </div>
					     </fieldset>
					    <div className="flex flex-row">
					      <input
					      	onClick={this.onSubmitSignup}
					      	className="f6 no-underline ph3 pv2 mb2 dib white grow bg-dark-green mr4"
					      	type="submit"
					      	id="SubmitSignupButton"
					      	value="Signup "
					      />
					      
					      <input
					      	onClick={this.onSubmitNewInvitee}
					      	className="f6 no-underline ph3 pv2 mb2 dib white grow bg-purple" 
					      	type="submit"
					      	id="InviteNewUserButton"
					      	value="Add Another User"
					      />
					    </div>
					   </form>
					</main>
				</article>
			);
		}
		else{
			return null;
		}		
	}
}

export default Signup;